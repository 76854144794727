import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, filterOption = {}) => async ({ Api, getState }) => {
    let pagingData = paging || getState().consents.paging;
    let { result, status } = await Api.post(`Consents/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption
    });
    status === 200 && consentDispatcher.getDataSuccess(result);
  },
  getAllConsents: callback => async ({ Api }) => {
    const { result, status } = await Api.post(`Consents/filter`, {});
    status === 200 && callback && callback(result.list);
  },
  createConsent: (data, callback) => async ({ Api }) => {
    consentDispatcher.setState('loading', true);
    const { status } = await Api.post(`Consents`, data);
    consentDispatcher.setState('loading', false);
    if (status === 200) {
      callback && callback();
    }
  },
  editConsent: (id, data, callback) => async ({ Api }) => {
    consentDispatcher.setState('loading', true);
    const { status } = await Api.put(`Consents/${id}`, data);
    consentDispatcher.setState('loading', false);
    if (status === 200) {
      callback && callback();
    }
  },
  deleteConsent: (id, callback) => async ({ Api, customToast }) => {
    const response = await Api.delete(`Consents/${id}`);
    if (response.status === 200) {
      callback && callback();
      customToast('success', 'Deleted successful');
    }
  },
  setState: (state, value) => ({ state, value })
};

const consentDispatcher = synthesize('consents', mapDispatchToAC);
export default consentDispatcher;
