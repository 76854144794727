import profileDispatcher from '../actions/index';
const initialState = {
  userInfo: null
};

const profileReducer = profileDispatcher(initialState, {
  [profileDispatcher.getUserInfoSuccess]: (state, payload) => ({
    userInfo: payload
  })
});

export default profileReducer;
