/* eslint-disable no-unused-expressions */
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles
} from '@material-ui/core';
import { startCase } from 'lodash';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'red'
  },
  formControl: {
    margin: theme.spacing(1),
    width: 300,
    maxWidth: 300
  }
}));

const MutipleSelection = ({
  open,
  onChange,
  data,
  value,
  disabled = false,
  isMultiple,
  children,
  absolutePosition = true,
  onClose,
  inputLabel,
  labelId,
  renderValue,
  formClasses,
  isClinic,
  useSelectAll,
  checkedAll,
  error,
  key,
  renderValidItem,
  label,
  labelSelectAll = 'Select All'
}) => {
  const classes = useStyles();
  const MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null,
    Mui: {
      selected: {}
    }
  };
  return (
    <div
      style={
        formClasses
          ? {}
          : {
              width: 0,
              height: 0,
              overflow: 'hidden',
              position: absolutePosition ? 'absolute' : 'relative',
              top: -50
            }
      }
    >
      <FormControl className={classes.formControl} style={formClasses}>
        {inputLabel && <InputLabel id={labelId}>{inputLabel}</InputLabel>}
        <Select
          labelId={labelId}
          multiple={isMultiple}
          open={open}
          onChange={e => {
            if (disabled) {
              return;
            }
            isMultiple && e.stopPropagation();
            onChange?.(e);
          }}
          value={value}
          MenuProps={MenuProps}
          onClose={onClose}
          renderValue={renderValue}
        >
          {useSelectAll && (
            <MenuItem value="selectAll">
              <Checkbox
                color="primary"
                checked={checkedAll}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: 0
                }}
              />
              {labelSelectAll}
            </MenuItem>
          )}
          {children ||
            data?.map(item => (
              <MenuItem
                key={item.key || item.id}
                value={item[key] || item.key || item.id || item}
                style={{ fontSize: 14 }}
                disabled={item.isDisabled || disabled}
              >
                {isMultiple ? (
                  <Checkbox
                    color="primary"
                    checked={
                      value.includes(item[key]) ||
                      value.includes(item.key) ||
                      value.includes(item.id) ||
                      value.includes(item)
                    }
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingLeft: 0
                    }}
                  />
                ) : (
                  item.name || item.locationAddress
                )}
                {isClinic
                  ? `${item.name}, ${item.address}, ${item.unitNumber}`
                  : item[label] ||
                    startCase(item.value) ||
                    (typeof item === 'string' && item)}
              </MenuItem>
            ))}
          {renderValidItem && renderValidItem}
        </Select>
        {error && <Typography color="error">{error}</Typography>}
      </FormControl>
    </div>
  );
};

export default MutipleSelection;
