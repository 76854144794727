import { synthesize } from 'redux-dispatcher';
const mapDispatchToAC = {
  changePassword: (data, callback) => async ({ Api, customToast }) => {
    const { status } = await Api.put(`Users/changePassword`, data);
    if (status === 200) {
      callback(true);
      customToast('success', 'Change Password Success');
    } else {
      callback(false);
    }
  }
};

const profileDispatcher = synthesize('profile', mapDispatchToAC);
export default profileDispatcher;
