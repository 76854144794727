import React, { useEffect, useState, useRef } from 'react';
import {
  TableCell,
  Dialog,
  Menu,
  TextField,
  InputAdornment,
  Button,
  withStyles,
  IconButton
} from '@material-ui/core';
import { Visibility, Edit, MoreVertRounded } from '@material-ui/icons';
import styled from 'styled-components';

import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import packageDispatcher from '@/module/package-management/action/package';
// import { renderPackageAction } from '../../renderAction';

import validateData from '@/helpers/validationHelpers/validationSchema';
import customToast from '@/new-components/CustomNotification';
import { TableWrapped } from '@/module/checkin-patient-information/styled';
import PriceConfirmationModal from '../PriceConfirmationModal';
import ServicesTable from '../ServicesTable';
import CustomAutocompleteModal from '@/new-components/CustomAutocompleteModal';
import {
  BILLING_TAB,
  COLUMN_PROFILE,
  COLUMN_PROFILE_BILLING
} from '../../../constants';
import PackageDetail from './PackageDetail';
import { CloseIcon, EyeIcon, RemoveIcon } from '@/assets/svg';
import { cloneDeep, findIndex, isEmpty } from 'lodash';
import { mergeArrayByKey } from '@/helpers';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import CustomTooltip from '@/new-components/CustomTooltip';
import { isBillableProject } from '@/module/setup/utils';

const StyledWrapModalContent = styled.div`
  padding: 16px;
  h1 {
    text-align: center;
    margin-top: 16px;
  }

  p {
    text-align: center;
    margin-bottom: 32px;
  }
  min-width: 500px;
`;

const StyledModalFooter = styled.div`
  display: flex;
  justify-content: center;
`;

export const TABLE_STATE = {
  Outside: 'Outside',
  Modal: 'Modal'
};

const CustomActionButton = withStyles({
  root: {
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 16,
    textTransform: 'unset'
  }
})(Button);

const CustomTextFieldPrice = withStyles({
  root: {
    fontSize: 20,

    '& .MuiInputBase-input': {
      marginLeft: 0
    },

    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 6
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 36
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const Profiles = ({ formValue, setFormValue, errors, setErrors }) => {
  const newSelected = useRef([]);

  const [showModal, setShowModal] = useState(false);
  const [showPriceConfirmationModal, setShowPriceConfirmationModal] = useState(
    false
  );
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [itemSelected, setItemSelected] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentValue, setCurrentValue] = useState(0);
  const [currentState, setCurrentState] = useState(TABLE_STATE.Outside);
  const [modal, setModal] = useState({});

  const [isViewModal, setIsViewModal] = useState(false);
  const [totalErrors, setTotalErrors] = useState(0);
  const [listProfiles, setListProfiles] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  const [billingTab, setBillingTab] = useState(BILLING_TAB.Default);

  const [
    openModalConfirmDefaultPrice,
    setOpenModalConfirmDefaultPrice
  ] = useState(false);
  const [
    modalConfirmDefaultPriceData,
    setModalConfirmDefaultPriceData
  ] = useState();
  const [numberPackageEdited, setNumberPackageEdited] = useState(0);
  const [isPriceChanged, setIsPriceChanged] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
    setIsViewModal(true);
    setTotalErrors(0);
  };

  const onAddNewProfile = newSelected => {
    const selected = selectedList.concat(newSelected);
    let newList = [];
    newList = mergeArrayByKey(selected, listProfiles, 'id');
    newList = mergeArrayByKey(newList, formValue.lstProfiles, 'id');
    setFormValue({ ...formValue, lstProfiles: newList });
    setShowModal(false);
    setIsViewModal(false);
    setCurrentState(TABLE_STATE.Outside);
    setSelectedList(selected);
  };

  const handleClosePriceConfirmationModal = () => {
    setShowPriceConfirmationModal(false);
    newSelected.current = [];
  };

  const handleConfirmPriceConfirmationModal = () => {
    onAddNewProfile(newSelected.current);

    setShowPriceConfirmationModal(false);
    newSelected.current = [];
  };

  const handleClickAddPackages = data => {
    if (totalErrors !== 0) {
      customToast('error', 'Please enter the price again.');
      return;
    }
    const itemsEdited = data.filter(item => item.isEdit === true);

    if (numberPackageEdited === data.length && numberPackageEdited === 0) {
      onAddNewProfile(data);
      setNumberPackageEdited(0);
      return;
    }
    setNumberPackageEdited(itemsEdited.length);
    setOpenModalConfirmDefaultPrice(true);
    setModalConfirmDefaultPriceData(data);
  };

  const handleClickConfirmDefaultPrice = () => {
    onAddNewProfile(modalConfirmDefaultPriceData);
    setOpenModalConfirmDefaultPrice(false);
  };

  const handleClickCancelDefaultPrice = () => {
    setOpenModalConfirmDefaultPrice(false);
  };

  const onDeleteProfile = () => {
    let newListProfiles = cloneDeep(formValue.lstProfiles).filter(
      it => it.id !== selectedItem.id
    );
    let newSelectedItem = cloneDeep(selectedList).map(item =>
      item.id === selectedItem.id ? { ...item, isChecked: false } : item
    );
    const index = findIndex(newListProfiles, it => it.id === selectedItem.id);
    newSelectedItem.splice(index, 1);

    setIsViewModal(false);
    setSelectedList(newSelectedItem);
    setFormValue({ ...formValue, lstProfiles: newListProfiles });
  };

  const onChangePrice = (
    tableOnly,
    position,
    tableValue,
    setTableValue
  ) => value => {
    const formatValue = Number(value);
    if (tableOnly) {
      let lstProfiles = [...formValue.lstProfiles];
      lstProfiles[position].price = formatValue;
      setFormValue({ ...formValue, lstProfiles });
    } else {
      let newVal = [...tableValue];
      newVal[position].price = formatValue;
      setTableValue(newVal);
    }
  };
  const calculateData = (position, callback) => {
    const isOutside = currentState === TABLE_STATE.Outside;
    return {
      position,
      values: isOutside ? formValue : modal.value,
      setValues: isOutside ? setFormValue : modal.setValue,
      key: isOutside && 'lstProfiles',
      callback
    };
  };

  const submitChangeData = async (
    tableOnly,
    row,
    tableValue,
    setTableValue
  ) => {
    let newVal = [];
    try {
      await validateData(
        'priceSchema',
        {
          price: currentValue
        },
        data => {
          if (tableValue) {
            newVal = [...tableValue].map(item =>
              item.id === row.id
                ? {
                    ...item,
                    isEdit: item.id === row.id
                  }
                : item
            );
            const index = tableValue.indexOf(row);
            const itemsEdited = newVal.filter(item => item.isEdit === true);
            const totalErrors = newVal.filter(item => item.errs).length;

            onChangePrice(
              tableOnly,
              index,
              newVal,
              setTableValue,
              row
            )(data.price);
            setTableValue(newVal);
            setNumberPackageEdited(itemsEdited.length);
            setTotalErrors(totalErrors);
          } else setIsViewModal(true);
          setCurrentValue(0);
        }
      );
    } catch (errs) {
      newVal = [...tableValue].map(item =>
        item.id === row.id
          ? {
              ...item,
              errs: errs.price
            }
          : item
      );
      const totalErrors = newVal.filter(item => item.errs).length;
      setTableValue(newVal);
      setTotalErrors(totalErrors);
    }
  };

  const handleChangePrice = (rowId, rowIndex, value) => {
    const isOutside = currentState === TABLE_STATE.Outside;
    // Regex test number contain decimal. Maximum length number of decimal is 2
    const regexNumber = /^([\d]{0,9})(\.[\d]{1,2})?$/g;
    if (isOutside) {
      let newListProfiles = cloneDeep(formValue.lstProfiles);

      if (regexNumber.test(value)) {
        const price = parseFloat(value);
        newListProfiles[rowIndex].price = Number.isNaN(price) ? value : price;
      }

      setFormValue({
        ...formValue,
        lstProfiles: newListProfiles
      });
    } else {
      let newListProfiles = cloneDeep(listProfiles);
      const index = findIndex(newListProfiles, it => it.id === rowId);

      if (regexNumber.test(value)) {
        const price = parseFloat(value);
        newListProfiles[index].price = Number.isNaN(price) ? value : price;
        newListProfiles[index].billableAmount = isBillableProject(
          formValue.billingType
        )
          ? newListProfiles[index].price
          : 0;
      }

      setListProfiles(newListProfiles);
    }
  };

  const handleClearPrice = (rowId, rowIndex) => {
    const isOutside = currentState === TABLE_STATE.Outside;

    if (isOutside) {
      let newListTests = cloneDeep(formValue.lstProfiles);
      newListTests[rowIndex].price = '';
      setFormValue({
        ...formValue,
        lstProfiles: newListTests
      });
    } else {
      let newListTests = cloneDeep(listProfiles);
      const index = findIndex(newListTests, it => it.id === rowId);
      newListTests[index].price = '';
      newListTests[index].billableAmount = '';
      setListProfiles(newListTests);
    }
  };

  const handleChangeBillableAmount = (rowId, rowIndex, value) => {
    // Regex test number contain decimal. Maximum length number of decimal is 2
    const regexNumber = /^([\d]{0,9})(\.[\d]{1,2})?$/g;
    const newListProfiles = cloneDeep(formValue.lstProfiles);

    if (regexNumber.test(value)) {
      const billableAmount = parseFloat(value);
      newListProfiles[rowIndex].billableAmount = Number.isNaN(billableAmount)
        ? value
        : billableAmount;
    }

    setFormValue({
      ...formValue,
      lstProfiles: newListProfiles
    });
  };

  const fetchProfileData = () =>
    packageDispatcher.getAllProfiles(result => {
      setListProfiles(
        result.map(i => ({
          ...i,
          price: i.price || 0,
          billableAmount: isBillableProject(formValue.billingType)
            ? i.price || 0
            : 0
        }))
      );
      setOriginalList(
        result.map(i => ({
          ...i,
          price: i.price || 0,
          billableAmount: isBillableProject(formValue.billingType)
            ? i.price || 0
            : 0
        }))
      );
    });

  useEffect(() => {
    if (!isEmpty(formValue?.lstProfiles)) {
      const newSelected = [...formValue?.lstProfiles].map(i => i.id);
      setSelectedList(newSelected);
    }
  }, [formValue]);

  useEffect(() => {
    if (!showModal && currentState !== TABLE_STATE.Outside) {
      setCurrentState(TABLE_STATE.Outside);
    }
  }, [currentState, showModal]);

  const renderRow = (row, rowIndex) => {
    return (
      <>
        <TableCell style={{ fontSize: 16 }}>
          <CustomTooltip content={row.name} />
        </TableCell>
        <TableCell style={{ fontSize: 16 }}>{row.totalProfiles}</TableCell>
        <TableCell style={{ fontSize: 16 }}>{row.totalTests}</TableCell>
        <TableCell style={{ fontSize: 16 }}>
          <CustomTextFieldPrice
            type="number"
            variant="outlined"
            value={row.price}
            onChange={e => handleChangePrice(row.id, rowIndex, e.target.value)}
            error={!!errors?.lstProfiles?.[row.id]?.price}
            helperText={errors?.lstProfiles?.[row.id]?.price?.trim()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{
                      visibility:
                        row.price && !isEmpty(String(row.price))
                          ? 'visible'
                          : 'hidden'
                    }}
                    onClick={() => handleClearPrice(row.id, rowIndex)}
                  >
                    <CloseIcon
                      height={20}
                      width={20}
                      style={{
                        color: '#666666'
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </TableCell>
        <TableCell>
          {showModal ? (
            <CustomActionButton
              color="primary"
              startIcon={<Visibility />}
              onClick={() => {
                onViewDetail(row.id);
                setIsViewModal(true);
              }}
            >
              View
            </CustomActionButton>
          ) : (
            <IconButton
              style={{ width: 20, height: 20 }}
              onClick={e => {
                setSelectedItem(row);
                setAnchorEl(e.currentTarget);
              }}
            >
              <MoreVertRounded />
            </IconButton>
          )}
        </TableCell>
      </>
    );
  };

  const renderBillingRow = (row, rowIndex) => {
    return (
      <>
        <TableCell style={{ fontSize: 16 }}>
          <CustomTooltip content={row.name} />
        </TableCell>
        <TableCell style={{ fontSize: 16 }}>{row.totalProfiles}</TableCell>
        <TableCell style={{ fontSize: 16 }}>{row.totalTests}</TableCell>
        <TableCell style={{ fontSize: 16 }}>
          <CustomTextFieldPrice
            type="number"
            variant="outlined"
            value={row.billableAmount}
            onChange={e =>
              handleChangeBillableAmount(row.id, rowIndex, e.target.value)
            }
            error={!!errors?.lstProfiles?.[row.id]?.billableAmount}
            helperText={errors?.lstProfiles?.[row.id]?.billableAmount?.trim()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{
                      visibility:
                        row.billableAmount &&
                        !isEmpty(String(row.billableAmount))
                          ? 'visible'
                          : 'hidden'
                    }}
                    onClick={() =>
                      handleChangeBillableAmount(row.id, rowIndex, '')
                    }
                  >
                    <CloseIcon
                      height={20}
                      width={20}
                      style={{
                        color: '#666666'
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </TableCell>
      </>
    );
  };

  const onViewDetail = id => {
    setAnchorEl(null);
    setShowDetailModal(true);
    packageDispatcher.getProfileById(id, result => setItemSelected(result));
  };

  const listActions = [
    {
      key: 'view',
      icon: EyeIcon,
      label: 'View details',
      onClick: () => {
        onViewDetail(selectedItem.id);
        setShowModal(true);
        setIsViewModal(true);
        setCurrentValue({});
        setAnchorEl(null);
      }
    },
    {
      key: 'delete',
      icon: RemoveIcon,
      label: 'Remove',
      color: '#DB3D49',
      onClick: () => {
        onDeleteProfile();
        setCurrentValue({});
        setAnchorEl(null);
      }
    }
  ];

  const checkIsPriceChanged = newSelected => {
    const newList = mergeArrayByKey(newSelected, listProfiles, 'id');

    return newList.some(
      newItem =>
        newItem.price !==
        originalList.find(originalItem => originalItem.id === newItem.id)?.price
    );
  };

  /**
   * Function to double check 2 cases:
   *  1. Submit data: data !== undefined
   *  2. Cancel edit: data === undefined
   */
  const doubleCheckBeforeCloseDialog = data => {
    if (data) {
      newSelected.current = data;

      setIsPriceChanged(checkIsPriceChanged(newSelected.current));
      setShowPriceConfirmationModal(true);
    } else {
      setShowModal(false);
      setIsViewModal(false);
      fetchProfileData();
    }
  };

  return (
    <TableWrapped>
      <ServicesTable
        title="Available profiles"
        labelButton="Add profile"
        columns={COLUMN_PROFILE}
        renderRow={renderRow}
        data={formValue.lstProfiles}
        onClick={e => {
          e && e.preventDefault();
          fetchProfileData();
          setCurrentState(TABLE_STATE.Modal);
          setShowModal(!showModal);
        }}
        billingColumns={COLUMN_PROFILE_BILLING}
        renderBillingRow={renderBillingRow}
        billingTab={billingTab}
        setBillingTab={setBillingTab}
        showBillingTab={isBillableProject(formValue.billingType)}
        hasBillingErrors={!!Object.keys(errors?.lstProfiles || {}).length}
      />
      <CustomAutocompleteModal
        open={showModal}
        onClose={doubleCheckBeforeCloseDialog}
        onSubmit={doubleCheckBeforeCloseDialog}
        data={listProfiles}
        submitLabel="Add"
        title="profile"
        columns={COLUMN_PROFILE}
        searchPlaceholder="Search by profile name..."
        onViewDetail={onViewDetail}
        selectedItem={selectedList}
        setSelectedItem={setSelectedList}
        renderRow={renderRow}
        isViewModal={isViewModal}
        setIsViewModal={setIsViewModal}
        ViewModal={() => {
          return <PackageDetail data={itemSelected} />;
        }}
      />
      <Menu
        style={{ zIndex: 1301 }}
        id="menu-location"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && <CustomMenuActions listActions={listActions} />}
      </Menu>

      <Dialog
        maxWidth="lg"
        open={openModalConfirmDefaultPrice}
        onClose={handleClickCancelDefaultPrice}
        disableEnforceFocus
      >
        <StyledWrapModalContent>
          {numberPackageEdited > 0 ? (
            <h1>There are some prices have been changed!</h1>
          ) : (
            <h1>Default profile prices were used!</h1>
          )}

          <p>Click "OK" to add, or click "Cancel" to edit</p>
          <StyledModalFooter>
            <ButtonEnhance
              background="#E9E9E9"
              color="black"
              backgroundHover="#ccc"
              onClick={handleClickCancelDefaultPrice}
            >
              Cancel
            </ButtonEnhance>

            <ButtonEnhance onClick={handleClickConfirmDefaultPrice}>
              OK
            </ButtonEnhance>
          </StyledModalFooter>
        </StyledWrapModalContent>
      </Dialog>

      <PriceConfirmationModal
        open={showPriceConfirmationModal}
        onClose={handleClosePriceConfirmationModal}
        onConfirm={handleConfirmPriceConfirmationModal}
        isPriceChanged={isPriceChanged}
      />
    </TableWrapped>
  );
};

export default Profiles;
