import { urlLabel } from '@/enum/PermissionEnum';
import { Categories } from '@/module/category';
import LivestreamListPage from '@/module/livestream/pages/LivestreamListPage/LivestreamListPage';
import SessionDetail from '@/module/livestream/pages/LivestreamListPage/SessionDetail';
import OnDemandListPage from '@/module/on-demand/pages/OnDemandListPage';
import { StudioManagement } from '@/module/studio';
import StudioClassListPage from '@/module/studio-classes/pages/StudioClassListPage';
import TrainerManagement from '@/module/trainer-management';
import { UpcomingWorkout } from '@/module/upcoming-workout';
import UserManagement from '@/module/user-management';

export const telefitRoutes = [
  {
    path: `/${urlLabel.categoryManagement}`,
    component: Categories,
    exact: false,
  },
  {
    path: `/${urlLabel.livestreamManagement}`,
    component: LivestreamListPage,
    exact: true,
  },
  {
    path: `/${urlLabel.livestreamSectionDetail}`,
    component: SessionDetail,
    exact: false,
  },
  {
    path: `/${urlLabel.onDemandManagement}`,
    component: OnDemandListPage,
    exact: false,
  },
  {
    path: `/${urlLabel.studioClassesManagement}`,
    component: StudioClassListPage,
    exact: false,
  },

  {
    path: `/${urlLabel.upcomingWorkoutManagement}`,
    component: UpcomingWorkout,
    exact: true,
  },
  {
    path: `/${urlLabel.studioManagement}`,
    component: StudioManagement,
    exact: true,
  },
  {
    path: `/${urlLabel.trainerManagement}`,
    component: TrainerManagement,
    exact: true,
  },

  {
    path: `/${urlLabel.userManagement}`,
    component: UserManagement,
    exact: true,
  },
];
