import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Grid, TextField, Typography, withStyles } from '@material-ui/core';
import { ArrowDown, CreateNewIcon } from '@/assets/svg';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const CustomAutocomplete = withStyles(theme => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"]': {
      padding: 0
    }
  },
  option: {
    '& span[class="max-line"]': {
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      display: '-webkit-inline-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2
    }
  }
}))(Autocomplete);

const MultipleAutocomplete = ({
  label,
  options,
  optionLabel,
  onChange,
  value,
  disabled = false,
  error,
  helperText,
  disableCloseOnSelect = false,
  multiple = true,
  optionSelected = 'id',
  placeholder,
  height
}) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [existValue, setExistValue] = useState([]);

  useEffect(() => {
    if (!!options.length) {
      let idValues = value.map(val => val?.id);
      //filter options have been chosen
      let filterArr = [...options];
      filterArr = options.filter(item => !idValues.includes(item?.id));
      //filter value not exist in options
      const idOptions = options.map(val => val?.id);
      const existVal = value.filter(item => idOptions.includes(item?.id));

      setExistValue(existVal);
      setFilterOptions(filterArr);
    }
  }, [options, value]);
  return (
    <>
      <CustomAutocomplete
        options={filterOptions}
        getOptionLabel={option => option[optionLabel] || ''}
        getOptionSelected={(option, value) =>
          option[optionSelected] === value[optionSelected]
        }
        value={existValue}
        filterSelectedOptions
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
        autoComplete
        disableCloseOnSelect={disableCloseOnSelect}
        popupIcon={<ArrowDown />}
        renderInput={params => (
          <CustomTextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            label={label}
            style={{ height }}
          />
        )}
        renderOption={option => (
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="body2">
                <span className="max-line">{option[optionLabel]}</span>
              </Typography>
            </Grid>
            <Grid item>
              <CreateNewIcon style={{ color: '#AD5E99' }} />
            </Grid>
          </Grid>
        )}
      />
      {error && (
        <Typography color="error" style={{ marginBottom: 8, marginTop: 8 }}>
          {helperText}
        </Typography>
      )}
    </>
  );
};

export default MultipleAutocomplete;
