import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const DividerHeader = ({ title, style }) => {
  return (
    <GroupTitle>
      <Divider />
      <Typography style={style} variant="h6">
        {title}
      </Typography>
    </GroupTitle>
  );
};

export default DividerHeader;

const Divider = styled.div`
  width: 4px;
  height: 22px;
  background: #ad5e99;
`;

const GroupTitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  h6 {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
  }
`;
