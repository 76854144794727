import upcomingDispatcher from '../action/upcomingWorkout';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0,
    isMuteAudio: false,
    isMuteVideo: false,
    isSharing: false,
    isSharingAudio: false,
    showChat: false
  },
  calling: false,
  stream: null,
  channelData: null,
  videoMode: false,
  waitingSetupLs: false
};

const upcomingReducer = upcomingDispatcher(initialState, {
  [upcomingDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value
  }),
  [upcomingDispatcher.updateLocalStream]: (state, payload) => ({
    localStream: [...payload.streams]
  }),
  [upcomingDispatcher.updateRemoteStream]: (state, payload) => ({
    remoteStreams: [...payload.streams]
  }),
  [upcomingDispatcher.getLivestreamListSuccess]: (state, payload) => ({
    list: payload.list,
    paging: {
      ...payload,
      list: undefined
    }
  }),
  [upcomingDispatcher.updateStatusOnEndStreamSuccess]: (state, payload) => {
    const updatedList = state.list.map(item => {
      return item.id === payload.id
        ? { ...item, liveStreamStatus: payload.status }
        : item;
    });
    return { list: updatedList };
  }
});

export default upcomingReducer;
