/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import './styles.scss';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import AgoraLiveStream from '../../../agora/agoraLivestream';
import { ModalUI } from '../../common';
import {
  switchHost,
  endLiveStream,
  leaveLiveStream
} from '../../../helpers/liveStreamSignalR';
import ChatPage from '../../livestream/pages/LivestreamPage/ChatPage';
import { Fab, Modal, Typography } from '@material-ui/core';
import {
  VideoIcon,
  MicIcon,
  SpeakerIcon,
  SpeakerOffIcon,
  MicOffIcon,
  VideoOffIcon,
  ShareScreenIcon,
  MessageIcon,
  NewMessageIcon,
  AssignIcon
} from '../../../assets/svg';
import {
  CallRounded,
  CallEndRounded,
  VideoCallRounded,
  MovieRounded
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import upcomingDispatcher from '../action/upcomingWorkout';
import ModalPresenter from '../../../components/ModalPresenter';
import {
  joinMultiChannel,
  formatChannels,
  setAttributeBasedOnID,
  leaveAllChannel
} from '../../livestream/helpers';
import AssignPopover from './AssignPopover';
import LiveCounter from './LiveCounter';
import RtmInstance from '../../../agora/rtm-instance';
import { debounced } from '../../../helpers/CommonHelper';
import { get, find, isEmpty } from 'lodash';
import livestreamDispatcher from '../../livestream/actions';
import {
  convertMessageFromAgora,
  convertMessageFileFromAgora
} from '../../livestream/utils';
import ReassignModal from './ReassignModal';
import { LIVE_STREAM_OPTION_VALUE } from '../../livestream/constants';
import VideoEnhanced from '../../common/component/VideoEnhanced';
import customToast from '@/new-components/CustomNotification';
import { formatUsername } from '../../livestream/helpers';
import ShareScreenMenu from './ShareScreenMenu';

const useStyles = makeStyles({
  paper: {
    width: props => `calc(100% - ${props.modalWidth}px)`,
    height: '100%',
    top: 0,
    left: 0,
    transition: 'width 0.2s'
  },

  chatContainer: {
    width: 360
  }
});

const rtm = RtmInstance.instance;

const LivestreamScreen = ({ channelData, userId, selectedLivestream }) => {
  const [modalWidth] = useState(0);
  const classes = useStyles({ modalWidth });
  const {
    isMuteAudio,
    isMuteVideo,
    isMuteSpeaker,
    isSharing,
    isSharingAudio,
    showChat,
    TotalUser,
    videoMode
  } = useSelector(state => state.upcoming);

  const isAdmin = useMemo(
    () => channelData.Admin.Id === userId,
    [channelData, userId],
    [channelData]
  );
  const isCurrentHost = useMemo(
    () => channelData.Host.Id === userId,
    [channelData, userId],
    [channelData]
  );
  const isUploadedVideo = useMemo(
    () =>
      selectedLivestream.liveStreamOption ===
      LIVE_STREAM_OPTION_VALUE.UploadPrerecord,
    [selectedLivestream]
  );

  const { listMember } = useSelector(state => state.livestream);
  // ref for listMember
  let listMemberRef = useRef();
  listMemberRef.current = listMember;
  const publicRoom = `${channelData.ChannelId},publicRoom`;
  const { userInfo } = useSelector(state => state.auth);

  const username = formatUsername(
    userInfo.firstName + ' ' + userInfo.lastName || userInfo.fullName,
    userInfo.id
  );
  let loaded = useRef(false);
  const [listRoom, setListRoom] = useState([
    {
      id: `${
        channelData ? channelData.ChannelId : selectedLivestream.id
      },publicRoom`,
      title: 'Public Chat',
      message: '',
      hasNewChat: false
    },
    {
      id: `${
        channelData ? channelData.ChannelId : selectedLivestream.id
      },cohostRoom`,
      title: 'Cohost Chat',
      message: '',
      hasNewChat: false
    }
  ]);
  //defined ref for list room
  let roomRef = useRef();
  roomRef.current = listRoom;

  const [endModal, setEndModal] = useState(false);

  const [openAssign, setOpenAssign] = useState(false);
  const [openSharing, setOpenSharing] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [assigneeId, setAssigneeId] = useState('');
  const [readyToPlay, setReady] = useState({
    isReady: false
  });

  useEffect(() => upcomingDispatcher.setState('waitingSetupLs', false), []);

  useEffect(() => {
    if (readyToPlay.isReady || !isUploadedVideo) {
      AgoraLiveStream.instance.joinChannel(
        channelData.ChannelId,
        false,
        isCurrentHost,
        isUploadedVideo && isCurrentHost,
        channelData.RtmpLink,
        userInfo.id
      );
      if (isCurrentHost) {
        isUploadedVideo
          ? upcomingDispatcher.setState('videoMode', true)
          : upcomingDispatcher.setState('videoMode', false);
      }
      joinMultiChannel(
        username,
        formatChannels(channelData.ChannelId, userInfo.roleName)
      );

      registerEvent();
      return () => {
        rtm.off('ChannelMessage', onChannelMessage);
        rtm.off('MemberJoined', onMemberJoined);
        rtm.off('MemberLeft', onMemberLeft);
        rtm.off('MessageFromPeer', onPeerMessage);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToPlay]);

  useEffect(() => {
    if (loaded.current && !showChat) {
      setTimeout(() => {
        AgoraLiveStream.instance.rtc.stream &&
          AgoraLiveStream.instance.rtc.stream.play('main_livestream', {
            fit: 'contain'
          });
      }, 0);
    } else loaded.current = true;
  }, [showChat]);

  const registerEvent = () => {
    rtm.on('ChannelMessage', onChannelMessage);
    rtm.on('MemberJoined', onMemberJoined);
    rtm.on('MemberLeft', onMemberLeft);
    rtm.on('MessageFromPeer', onPeerMessage);
  };

  const onChannelMessage = async ({ channelName, args }) => {
    debounced(() => {
      if (get(args, '[0].messageType') === 'RAW') {
        const contentMessage = convertMessageFileFromAgora(args, userInfo.id);
        livestreamDispatcher.sendMessageSuccess({
          contentMessage,
          channelName
        });
        receiveNewMessage(channelName);
        return;
      }
      const contentMessage = convertMessageFromAgora(args, userInfo.id);
      livestreamDispatcher.sendMessageSuccess({
        contentMessage,
        channelName
      });
      receiveNewMessage(channelName, contentMessage.text);
    }, 50);
  };

  const onPeerMessage = (...args) => {
    debounced(() => {
      const user = args[1];
      if (get(args, '[0].messageType') === 'RAW') {
        const contentMessage = convertMessageFileFromAgora(args, userInfo.id);
        livestreamDispatcher.sendMessageSuccess({
          contentMessage,
          channelName: user
        });
        receiveChat(user.split(',')[0], user, true);
      } else {
        const contentMessage = convertMessageFromAgora(args, userInfo.id);
        livestreamDispatcher.sendMessageSuccess({
          contentMessage,
          channelName: user
        });
        receiveChat(
          user.split(',')[0],
          user,
          contentMessage.text,
          get(contentMessage, 'user.roleType'),
          receiveNewMessage(user, contentMessage.text)
        );
      }
    }, 50);
  };

  const onMemberLeft = ({ channelName, args }) => {
    const contentMessage = {
      position: 'left',
      text: `${args[0].split(',')[0]} left channel`,
      type: 'TEXT',
      user: {
        _id: args[0],
        name: 'toAllMember'
      }
    };
    livestreamDispatcher.sendMessageSuccess({
      contentMessage,
      channelName
    });
  };

  const onMemberJoined = ({ channelName, args }) => {
    const contentMessage = {
      position: 'left',
      text: `${args[0].split(',')[0]} joined channel`,
      type: 'TEXT',
      user: {
        _id: args[0],
        name: 'toAllMember'
      }
    };
    livestreamDispatcher.sendMessageSuccess({
      contentMessage,
      channelName
    });
  };

  const receiveChat = (name, user, message, roleType, callback) => {
    const existPeerChat = find(roomRef.current, room => room.id === user);
    if (!existPeerChat) {
      setListRoom([
        ...roomRef.current,
        {
          id: user,
          title: name,
          message: message || '',
          hasNewChat: !!message,
          roleType: roleType
        }
      ]);
    } else {
      setListRoom(
        setAttributeBasedOnID(roomRef.current, user, [
          { attr: 'hasNewChat', value: !!message }
        ])
      );
    }
    callback && callback();
  };

  const receiveNewMessage = (id, message) => {
    const listAction = [
      { attr: 'message', value: message || '' },
      { attr: 'hasNewChat', value: true }
    ];
    setListRoom(setAttributeBasedOnID(roomRef.current, id, listAction));
  };

  const onAssigneeChanged = assigneeId => {
    if (
      channelData.Admin.Id !== assigneeId &&
      !listMemberRef.current.find(member => member.Id === assigneeId)
    ) {
      return customToast('error', 'This co-host is not online');
    }

    setOpenAssign(false);
    setAssigneeId(assigneeId);
    setOpenAssignModal(true);
  };

  const onEndCall = () => {
    isCurrentHost ? endLiveStream() : leaveLiveStream();
  };

  const handleShowChat = () => {
    upcomingDispatcher.setState('showChat', true);
    AgoraLiveStream.instance.rtc.stream &&
      AgoraLiveStream.instance.rtc.stream.stop();
  };

  const handleCloseLivestream = () => {
    isCurrentHost &&
      upcomingDispatcher.updateStatusOnEndStream(selectedLivestream.id);
    leaveAllChannel();
    livestreamDispatcher.clearMember();
    onEndCall();
  };

  const hasNewMessage = useMemo(() => find(listRoom, room => room.hasNewChat), [
    listRoom
  ]);
  const messageRead = useCallback(
    channelName => {
      const channel = find(listRoom, room => channelName === room.id);
      if (channel && channel.hasNewChat) {
        setListRoom(
          setAttributeBasedOnID(listRoom, channelName, [
            { attr: 'hasNewChat', value: false }
          ])
        );
      }
    },
    [listRoom]
  );

  const onShare = mode => {
    if (mode === 'isSharing') AgoraLiveStream.instance.publishSharingStream();
    else AgoraLiveStream.instance.publishAudioSharing();
  };

  const LeftGroupBtn = () => {
    return isCurrentHost ? (
      <>
        <Fab
          className={
            isMuteAudio ? 'float-button' : 'float-button float-button-selected'
          }
          aria-label="mute-audio"
          onClick={() =>
            AgoraLiveStream.instance.handleAudio(
              isMuteAudio,
              upcomingDispatcher.setState
            )
          }
        >
          {isMuteAudio ? (
            <MicOffIcon style={{ width: 28, height: 28 }} />
          ) : (
            <MicIcon style={{ width: 28, height: 28 }} />
          )}
        </Fab>
        <Fab
          className={
            isMuteVideo ? 'float-button' : 'float-button float-button-selected'
          }
          aria-label="enable-video"
          onClick={() =>
            AgoraLiveStream.instance.handleVideo(
              isMuteVideo,
              upcomingDispatcher.setState
            )
          }
        >
          {isMuteVideo ? (
            <VideoOffIcon style={{ width: 28, height: 28 }} />
          ) : (
            <VideoIcon style={{ width: 28, height: 28 }} />
          )}
        </Fab>
      </>
    ) : (
      <Fab
        className={
          isMuteSpeaker ? 'float-button' : 'float-button float-button-selected'
        }
        aria-label="mute-speaker"
        onClick={() =>
          AgoraLiveStream.instance.handleSpeaker(
            isMuteSpeaker,
            upcomingDispatcher.setState
          )
        }
      >
        {isMuteSpeaker ? (
          <SpeakerOffIcon style={{ width: 28, height: 28 }} />
        ) : (
          <SpeakerIcon style={{ width: 28, height: 28 }} />
        )}
      </Fab>
    );
  };

  const RightGroupBtn = useCallback(() => {
    return (
      <>
        {isUploadedVideo && isAdmin && isCurrentHost && (
          <Fab
            className={'float-button'}
            onClick={() => {
              videoMode
                ? AgoraLiveStream.instance.initStream()
                : AgoraLiveStream.instance.initStream(undefined, true);
              upcomingDispatcher.setState('videoMode', !videoMode);
            }}
          >
            {videoMode ? (
              <VideoCallRounded style={{ fontSize: 40 }} />
            ) : (
              <MovieRounded style={{ fontSize: 28 }} />
            )}
          </Fab>
        )}
        {isCurrentHost && (
          <Fab
            className={
              !(isSharing || isSharingAudio)
                ? 'float-button'
                : 'float-button float-button-selected'
            }
            aria-label="sharing"
            onClick={event => {
              if (!(isSharing || isSharingAudio)) setOpenSharing(true);
              else {
                setOpenSharing(false);
                if (isSharing) {
                  upcomingDispatcher.setState('isSharing', false);
                  !videoMode
                    ? AgoraLiveStream.instance.initStream()
                    : AgoraLiveStream.instance.initStream(undefined, true);
                } else {
                  upcomingDispatcher.setState('isSharingAudio', false);
                  AgoraLiveStream.instance.unPublishAudioSharing();
                }
              }
            }}
          >
            <ShareScreenIcon style={{ width: 28, height: 28 }} />
          </Fab>
        )}
        {isAdmin && (
          <Fab
            className="float-button"
            aria-label="assign"
            onClick={event => {
              setOpenAssign(true);
            }}
          >
            <AssignIcon style={{ width: 28, height: 28 }} />
          </Fab>
        )}
        <ShareScreenMenu
          open={openSharing}
          setOpen={setOpenSharing}
          isSharing={isSharing}
          isSharingAudio={isSharingAudio}
          onShare={mode => {
            onShare(mode);
            setOpenSharing(false);
          }}
        />
        <AssignPopover
          title="ASSIGN CAMERA TO:"
          open={openAssign}
          setOpen={setOpenAssign}
          channelData={channelData}
          onAssigneeChanged={onAssigneeChanged}
        />

        <ReassignModal
          showModal={openAssignModal}
          onClose={() => setOpenAssignModal(false)}
          onAccept={() => {
            switchHost(assigneeId);
            setOpenAssignModal(false);
          }}
        />
      </>
    );
  }, [
    openAssign,
    openAssignModal,
    openSharing,
    isAdmin,
    isCurrentHost,
    videoMode,
    isSharing,
    isSharingAudio
  ]);

  return (
    <>
      <ModalUI {...{ open: true, classes }}>
        <Modal open={!!endModal} onClose={() => setEndModal(false)}>
          <div>
            <ModalPresenter
              Icon={
                <CallEndRounded style={{ fontSize: 80, color: '#EA6B75' }} />
              }
              title="End Call"
              onClick={handleCloseLivestream}
              onClose={() => setEndModal(false)}
            >
              <Typography>
                {isCurrentHost
                  ? `Click “Continue” to end stream`
                  : `Click “Continue” to leave stream`}
              </Typography>
            </ModalPresenter>
          </div>
        </Modal>
        <div className="stream-group-btn">
          <div>
            <LeftGroupBtn />
          </div>
          <Fab
            style={{
              backgroundColor: '#EA6B75',
              color: 'white',
              width: 80,
              height: 80
            }}
            aria-label="add"
            onClick={() => {
              setEndModal(true);
            }}
          >
            <CallRounded style={{ fontSize: 32 }} />
          </Fab>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <RightGroupBtn />
            <Fab
              className="float-button"
              aria-label="chat"
              onClick={handleShowChat}
            >
              {hasNewMessage ? (
                <NewMessageIcon style={{ width: 28, height: 28 }} />
              ) : (
                <MessageIcon style={{ width: 28, height: 28 }} />
              )}
            </Fab>
          </div>
        </div>
        <VideoEnhanced
          videoMode={videoMode}
          isSharing={isSharing}
          isCurrentHost={isCurrentHost}
          onError={err => {
            customToast('error', 'Cannot play this video');
          }}
          onReady={err => {
            !err.getCurrentTime() &&
              setReady({
                isReady: true
              });
          }}
          videoUrl={selectedLivestream.videoUrl}
          display={
            videoMode && isCurrentHost && !isSharing ? undefined : 'none'
          }
        />
        <StyledContainer
          key="main_livestream"
          id="main_livestream"
          display={
            !(videoMode && isCurrentHost && !isSharing) ? undefined : 'none'
          }
        />
        <div
          key="audio_livestream"
          id="audio_livestream"
          style={{ width: 0, height: 0 }}
        ></div>
        <LiveCounter
          count={TotalUser || channelData.TotalUser || 1}
          fileList={selectedLivestream.mp3Urls}
          isCurrentHost={isCurrentHost}
          isSharing={isSharing}
        />
      </ModalUI>
      <Modal open={showChat}>
        <ChatPage
          showChat={showChat}
          channelName={publicRoom}
          username={username}
          roleName={userInfo.roleName}
          selectedLivestream={selectedLivestream}
          channelData={channelData}
          receiveChat={receiveChat}
          listRoom={listRoom}
          setListRoom={setListRoom}
          receiveNewMessage={receiveNewMessage}
          onFocusInput={messageRead}
        />
      </Modal>
    </>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  video {
    transform: ${props => props.transform};
  }
  display: ${props => props.display};
`;

export default LivestreamScreen;
