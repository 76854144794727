import React from 'react';
import { COLUMN_RADIOLOGY } from '@/module/services/constants';
import ServiceManagement from '../../common/ServiceManagement';
import RadiologyModalContainer from './RadiologyModalContainer';

const RadiologyManagement = props => (
  <ServiceManagement
    {...{
      ...props,
      prefixName: 'Radiology',
      category: 'Radiologies',
      noDataHelperText: 'No radiology',
      noDataHelperSubText: 'Please create a new radiology',
      columns: COLUMN_RADIOLOGY,
      ModalContainer: RadiologyModalContainer
    }}
  />
);

export default RadiologyManagement;
