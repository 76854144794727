import React from 'react';
import { COLUMN_VACCINATION } from '@/module/services/constants';
import ServiceManagement from '../../common/ServiceManagement';
import VaccinationModalContainer from './VaccinationModalContainer';

const VaccinationManagement = props => (
  <ServiceManagement
    {...{
      ...props,
      prefixName: 'Vaccination',
      category: 'Vaccinations',
      noDataHelperText: 'No vaccination',
      noDataHelperSubText: 'Please create a new vaccination',
      columns: COLUMN_VACCINATION,
      ModalContainer: VaccinationModalContainer
    }}
  />
);

export default VaccinationManagement;
