import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import customToast from '@/new-components/CustomNotification';
import { doctorStatus } from '@/enum/RequestEnum';
import minkConfig from '@/mink.config.json';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import NRCPanel from './NRCPanel';
import { AppointmentType } from '../../../../appointment-management/constants';
import {
  Radio,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  Box
} from '@material-ui/core';
import SelectChildrenParticipants from './../../../../waiting-room/SelectChildrenParticipants';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    marginTop: 40,
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    maxHeight: 780,
    border: 'solid 1px #ffffff',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    color: '#192637',
    fontSize: 18,
    lineHeight: '27px'
  },
  callIcon: {
    fontSize: 20,
    lineHeight: '27px',
    marginRight: '14px'
  },
  body: {
    padding: '20px 20px 0',
    borderBottom: '1px solid #C0C0C0',
    color: '#192637',
    fontSize: 14,
    flex: 1,
    boxSizing: 'border-box'
  },
  userInfo: {
    lineHeight: '21px',
    marginBottom: 20
  },
  teleInfo: {
    width: 100,
    height: 60,
    fontSize: 14,
    backgroundColor: '#34AA7f',
    padding: '4px 8px',
    color: '#fff',
    borderRadius: 16,
    textAlign: 'center',
    marginLeft: 8
  },
  colorTitleText: {
    color: '#8f9ba9'
  },
  symptoms: {
    color: '#8f9ba9',
    background: 'rgba(232, 232, 232, 0.6)',
    borderRadius: '3px 3px 0 0',
    boxShadow: '0 1px 1px rgba(232, 232, 232, 0.6)',
    marginBottom: 29
  },
  symptomsTitle: {
    margin: 0
  },
  symptomsValue: {
    padding: '10px 12px',
    margin: 0
  },
  medication: {
    color: '#8f9ba9',
    fontSize: 14,
    lineHeight: '21px'
  },
  medicationTitle: {
    margin: 0,
    marginBottom: 10
  },
  medicationValue: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  medicationOptions: {
    width: 160,
    height: 40,
    borderRadius: 4,
    border: '2px solid #e9e9e9',
    display: 'flex',
    alignItems: 'center'
  },
  medicationOptionsLabel: {
    lineHeight: '21px',
    textAlign: 'center'
  },
  footer: {
    height: 60,
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  footerButton: {
    width: 160,
    height: 40,
    borderRadius: 20
  }
}));

const Timer = ({ time, fullName }) => {
  const [duration, setDuration] = useState(
    time + minkConfig.DOCTOR_REJOIN_TIMEOUT - new moment().unix()
  );
  useEffect(() => {
    const interval = setInterval(() => {
      if (duration <= 0) {
        customToast('info', `Your consult is expired`);
        clearInterval(interval);
      }
      setDuration(duration => duration - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [duration]);

  return (
    <div>
      <Typography
        align="center"
        style={{ color: '#8F9BA9', marginTop: 12, fontSize: 15 }}
      >
        {`Your call with ${fullName} was disconnected. Would you like to rejoin? Call automatically ends in ${moment
          .utc(moment.duration(duration, 'seconds').asMilliseconds())
          .format('mm:ss')}`}
      </Typography>
    </div>
  );
};

const ModalAppointmentCall = ({
  fullName,
  symptoms,
  open,
  anyMedication,
  anyDrugAllergy,
  onRejectCall,
  isRejoinCall,
  onCancelCall,
  callProblemTime,
  handleAcceptOrRejoinCall,
  appointmentDate,
  promotionCode,
  identityNumber,
  frontIdentityUrl,
  backIdentityUrl,
  corperatePlan,
  frontCorperatePlan,
  backCorperatePlan,
  approveStatus,
  appointmentType
}) => {
  const classes = useStyles();

  const isNotVerify = approveStatus === doctorStatus.Pending;

  const renderBtnText = () => {
    if (isNotVerify) return 'Verify ID';
    if (isRejoinCall) return 'Rejoin';
    return 'Start';
  };

  const isShowSymptoms = symptoms && anyDrugAllergy && anyMedication;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      className={classes.modal}
      open={open}
      disableBackdropClick
    >
      <DialogTitle>
        <div className={classes.header}>
          <div className={classes.title}>
            Verify Identification{' '}
            <span className={classes.teleInfo}>
              {AppointmentType[appointmentType]}
            </span>
          </div>

          <IconButton
            aria-label="delete"
            color="primary"
            onClick={onCancelCall}
          >
            <HighlightOffIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item xs={isShowSymptoms ? 6 : 12}>
            <div className={classes.userInfo}>
              <strong>{fullName}</strong> is present for the appointment at{' '}
              <strong>{moment(appointmentDate).format('hh:mm A')}.</strong>{' '}
              Please verify if the photos uploaded by the patient match our
              record to begin.
            </div>
            {isRejoinCall && (
              <Timer
                time={moment(callProblemTime).unix()}
                fullName={fullName}
              />
            )}
          </Grid>

          {isShowSymptoms && (
            <Grid item xs={6} md={6}>
              <div className={classes.symptoms}>
                <p className={classes.symptomsTitle}>Symptoms</p>
                <p className={classes.symptomsValue}>{symptoms}</p>
              </div>
              <div className={classes.medication}>
                <p className={classes.medicationTitle}>
                  Is {fullName} on any medication?
                </p>
                <div className={classes.medicationValue}>
                  <div className={classes.medicationOptions}>
                    <Radio
                      checked={!anyMedication}
                      disabled
                      name="anyMedication"
                      size="small"
                    />
                    <span className={classes.medicationOptionsLabel}>No</span>
                  </div>
                  <div className={classes.medicationOptions}>
                    <Radio
                      checked={anyMedication}
                      disabled
                      name="anyMedication"
                      size="small"
                    />
                    <span className={classes.medicationOptionsLabel}>Yes</span>
                  </div>
                </div>
              </div>
              <div className={classes.medication}>
                <p className={classes.medicationTitle}>
                  Does {fullName} have any drug allergy?
                </p>
                <div className={classes.medicationValue}>
                  <div className={classes.medicationOptions}>
                    <Radio
                      checked={!anyDrugAllergy}
                      disabled
                      name="anyDrugAllergy"
                      size="small"
                    />
                    <span className={classes.medicationOptionsLabel}>No</span>
                  </div>
                  <div className={classes.medicationOptions}>
                    <Radio
                      checked={anyDrugAllergy}
                      disabled
                      name="anyDrugAllergy"
                      size="small"
                    />
                    <span className={classes.medicationOptionsLabel}>Yes</span>
                  </div>
                </div>
              </div>
            </Grid>
          )}
          {promotionCode && (
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                display="block"
                className={classes.colorTitleText}
              >
                Promotion code: <strong>{promotionCode}</strong>
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <NRCPanel imgFront={frontIdentityUrl} imgBack={backIdentityUrl}>
              <Typography
                variant="caption"
                display="block"
                className={classes.colorTitleText}
              >
                NRIC: <strong>{identityNumber}</strong>
              </Typography>
            </NRCPanel>
          </Grid>
          {corperatePlan && (
            <Grid item xs={12}>
              <NRCPanel
                imgFront={frontCorperatePlan}
                imgBack={backCorperatePlan}
              >
                <Typography
                  variant="caption"
                  display="block"
                  className={classes.colorTitleText}
                >
                  Corporate Name: <strong>{corperatePlan}</strong>
                </Typography>
              </NRCPanel>
            </Grid>
          )}
        </Grid>
        {!isRejoinCall ? (
          <Box marginTop={2} marginLeft={2} marginRight={2}>
            <SelectChildrenParticipants isAppt />
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button
          variant="contained"
          className={classes.footerButton}
          style={{
            marginRight: 16,
            backgroundColor: isNotVerify ? 'rgb(234, 107, 117)' : '#e0e0e0',
            color: isNotVerify ? '#fff' : 'black'
          }}
          onClick={onRejectCall}
        >
          {isNotVerify ? 'Reject' : 'Cancel'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.footerButton}
          onClick={handleAcceptOrRejoinCall}
        >
          {renderBtnText()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalAppointmentCall.defaultProps = {
  callProblemTime: null,
  promotionCode: null,
  corperatePlan: null,
  frontCorperatePlan: null,
  backCorperatePlan: null
};

ModalAppointmentCall.propTypes = {
  open: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  symptoms: PropTypes.string.isRequired,
  anyMedication: PropTypes.bool.isRequired,
  anyDrugAllergy: PropTypes.bool.isRequired,
  patientId: PropTypes.string.isRequired,
  callProblemTime: PropTypes.string,
  appointmentDate: PropTypes.string.isRequired,
  promotionCode: PropTypes.string,
  identityNumber: PropTypes.string.isRequired,
  frontIdentityUrl: PropTypes.string.isRequired,
  backIdentityUrl: PropTypes.string.isRequired,
  corperatePlan: PropTypes.string,
  frontCorperatePlan: PropTypes.string,
  backCorperatePlan: PropTypes.string,
  approveStatus: PropTypes.string.isRequired,

  onRejectCall: PropTypes.func.isRequired
};

export default ModalAppointmentCall;
