import { countries } from '../../countries';
import { ArrowDown } from '@/assets/svg';
import { MenuItem, Box, withStyles, TextField } from '@material-ui/core';
import React from 'react';

const initCountry = 'SG';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
  },
})(TextField);

const CustomCountryCodeInput = ({
  country = initCountry,
  onChange,
  disabled,
}) => {
  return (
    <Box>
      <CustomTextField
        select
        value={country}
        onChange={onChange}
        SelectProps={{
          IconComponent: ArrowDown,
          renderValue: (value) => (
            <Box width={100} display="flex" alignItems="center">
              <img
                src={countries[value]?.flag}
                alt={countries[value]?.name?.common || ''}
                style={{ maxWidth: 20, marginRight: 5 }}
              />
              {`+${countries[value]?.callingCode[0] || ''}`}
            </Box>
          ),
        }}
        disabled={disabled}
        variant="outlined"
      >
        {Object.keys(countries).map((item) => (
          <MenuItem key={item} value={item}>
            <img
              src={countries[item].flag}
              alt={countries[item].name.common}
              style={{ maxWidth: 20, marginRight: 5 }}
            />
            {countries[item].name.common}
          </MenuItem>
        ))}
      </CustomTextField>
    </Box>
  );
};

export default CustomCountryCodeInput;
