import { DialogActions, TextField, withStyles } from '@material-ui/core';
import styled from 'styled-components';

const ModalPage = styled.div`
  width: 480px;
  hr {
    background: #cacfd3;
    margin: 0;
  }
  .MuiDialogContent-root {
    padding: 0;
    &:first-child {
      padding-top: 0;
    }
  }
  .test-header {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #192637;
    border-bottom: 1px solid rgb(202, 207, 211);
  }
  .test-body {
    padding: 0 20px 20px 20px;
  }
`;

export const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 10px 0px;
  .primary {
    margin: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    font-size: 16px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
`;

export const InputLabel = styled.div`
  margin: 10px 0 5px 0;
  color: #666;
  font-weight: 600;
  margin-top: ${p => p.mt};
`;

export const CustomSearchInput = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8
    },
    '& .Mui-disabled': {
      background: '#f6f6f6'
    },
    '& input': {
      minWidth: 335,
      height: 48,
      padding: 0
    }
  }
}))(TextField);

export const AddLocationContainer = styled.div`
  padding: 20px;
`;
export default ModalPage;
