import React from 'react';
import PropTypes from 'prop-types';
function PlusIcon({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <g
        id="🌈-Style-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icons"
          transform="translate(-704.000000, -200.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="Colors-Copy" transform="translate(22.000000, 20.000000)">
            <path
              d="M692,180 C694.76348,180 697.120989,180.978081 699.0726,182.934272 C701.02421,184.890464 702,187.245683 702,190 C702,192.754317 701.02421,195.109536 699.0726,197.065728 C697.120989,199.021919 694.76348,200 692,200 C689.23652,200 686.879011,199.021919 684.9274,197.065728 C682.97579,195.109536 682,192.754317 682,190 C682,187.245683 682.967984,184.898288 684.903981,182.957746 C686.871204,180.985906 689.23652,180 692,180 Z M692,181.971831 C689.798584,181.971831 687.91726,182.754296 686.355972,184.319249 C684.794684,185.884202 684.014052,187.777767 684.014052,190 C684.014052,192.222233 684.794684,194.115798 686.355972,195.680751 C687.91726,197.245704 689.798584,198.028169 692,198.028169 C694.201416,198.028169 696.08274,197.245704 697.644028,195.680751 C699.205316,194.115798 699.985948,192.222233 699.985948,190 C699.985948,187.777767 699.205316,185.884202 697.644028,184.319249 C696.08274,182.754296 694.201416,181.971831 692,181.971831 Z M693.007026,184.976526 L693.007026,189.014085 L696.98829,189.014085 L696.98829,190.985915 L693.007026,190.985915 L693.007026,195.023474 L690.992974,195.023474 L690.992974,190.985915 L687.01171,190.985915 L687.01171,189.014085 L690.992974,189.014085 L690.992974,184.976526 L693.007026,184.976526 Z"
              id="plus-/-circle"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

PlusIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

PlusIcon.defaultProps = {
  width: '20px',
  height: '20px',
  color: '#ccc'
};

export default PlusIcon;
