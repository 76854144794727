import React, { useState } from 'react';
import TableContainer from '../../../components/Table/TableContainer';
import meetingHistoryDispatcher from '../action/meetingHistory';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  ListItemIcon,
  Dialog,
  MenuItem,
  Typography,
  Checkbox
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  MoreVertRounded,
  RepeatRounded,
  PaymentRounded,
  VisibilityRounded,
  ArtTrackOutlined
} from '@material-ui/icons';
import {
  ConsultStatus,
  ConsultStatusDisplay,
  StatusColor,
  ConsultType
} from '../../../enum/ConsultEnum';
import { DATE_FORMAT_2 } from '../../common';
import ConsultDetail from '../../../components/ConsultDetail';
import CustomSelect from '../../../components/CustomSelect';
import { get, startCase, isEmpty } from 'lodash';
import { ChipStatus } from '../../common/componentUI/commonStyleComponents';
import CustomDateRange from '../../../components/CustomDateRange';
import MutipleSelection from '../../../components/CustomSelect/MutipleSelection';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import { durationFormatted, momentFormatted } from '../../../helpers';
const MeetingHistory = () => {
  const data = useSelector(state => state.meetingHistory.list);
  const paging = useSelector(state => state.meetingHistory.paging);
  const userInfo = useSelector(state => state.auth.userInfo);

  const [selectedItem, setSelectedItem] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, handleDateChange] = useState([null, null]);
  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({
    consultTypes: userInfo.roleName === 'Swabber' ? ['TeleArt'] : []
  });
  const [detailModal, setDetailModal] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [typeOpen, setTypeOpen] = useState(false);
  const [typeFilter, setTypeFilter] = useState([]);
  const [hiddenRows, setHiddenRows] = useState({});

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const Header = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper
          label="Date"
          stateValue="consultantDate"
          style={{ paddingLeft: 20 }}
        />
        <TableCellFiltedWrapper label="Consult" stateValue="consult" />
        <TableCellFiltedWrapper label="Doctor" stateValue="doctorFullName" />
        <TableCellFiltedWrapper label="Patient" stateValue="patient" />
        <TableCellFiltedWrapper label="Diagnosis" stateValue="diagnosis" />
        <TableCellFiltedWrapper label="Duration" stateValue="duration" />
        <TableCellFiltedWrapper label="Total Cost" stateValue="totalCost" />
        <TableCellFiltedWrapper label="Status" stateValue="consultStatus" />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const handleClick = item => event => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const renderMenuItem = () => {
    if (!selectedItem) return [];
    let options = [
      {
        key: 'view',
        icon: VisibilityRounded,
        label: 'View Details',
        onClick: () => {
          setDetailModal(true);
          handleClose();
        }
      }
    ];
    if (selectedItem.consultStatus === ConsultStatus.InProgress) {
      options.push({
        key: 'cancel',
        icon: RepeatRounded,
        label: 'Cancel',
        onClick: () => {
          handleClose();
          meetingHistoryDispatcher.cancelConsult(
            selectedItem.consultId,
            searchKey,
            filterOption
          );
        }
      });
      options.push({
        key: 'complete',
        icon: PaymentRounded,
        label: 'Complete',
        onClick: () => {
          handleClose();
          meetingHistoryDispatcher.completeConsult(
            selectedItem.consultId,
            searchKey,
            filterOption
          );
        }
      });
    }
    return options.map(d => {
      let Icon = d.icon;
      return (
        <MenuItem
          key={d.key}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Source Sans Pro',
            color: '#192637'
          }}
          onClick={d.onClick}
        >
          <ListItemIcon style={{ minWidth: 34 }}>
            <Icon style={{ color: '#CACFD3' }} />
          </ListItemIcon>
          {d.label}
        </MenuItem>
      );
    });
  };
  return (
    <>
      <Dialog
        open={!!detailModal}
        onClose={() => setDetailModal(false)}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        disableEnforceFocus
      >
        <ConsultDetail
          isDoctor
          selectedItem={selectedItem}
          detailModal={detailModal}
          onClose={() => setDetailModal(false)}
        />
      </Dialog>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderMenuItem()}
      </Menu>
      <TableContainer
        title="Consult List"
        query={meetingHistoryDispatcher.getData}
        header={Header}
        paging={paging}
        data={data}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        ToolbarComponent={
          <>
            <CustomDateRange
              selectedDate={selectedDate}
              onChange={(startDate, endDate) => {
                if (startDate && endDate) {
                  handleDateChange([startDate, endDate]);
                  setFilterOption({
                    ...filterOption,
                    fromDate: startDate.toISOString(),
                    toDate: endDate.toISOString()
                  });
                } else {
                  handleDateChange([null, null]);
                  setFilterOption({
                    ...filterOption,
                    fromDate: null,
                    toDate: null
                  });
                }
              }}
            />
            <CustomSelect
              onClick={() => {
                setStatusOpen(!statusOpen);
              }}
              clearable={!isEmpty(statusFilter)}
              onClear={() => {
                setStatusFilter([]);
                setFilterOption({
                  ...filterOption,
                  consultStatuses: []
                });
              }}
              prefixIcon={
                <ArtTrackOutlined
                  style={{
                    fontSize: 20,
                    color: '#E9E9E9',
                    marginRight: 8
                  }}
                />
              }
              renderValue={
                <>
                  {statusFilter
                    .map(item => ConsultStatusDisplay[item])
                    .join(', ') || 'Select Status'}
                </>
              }
            >
              <MutipleSelection
                value={statusFilter}
                data={ConsultStatus}
                open={statusOpen}
                onChange={e => {
                  setFilterOption({
                    ...filterOption,
                    consultStatuses: e.target.value
                  });
                  setStatusFilter(e.target.value);
                }}
              >
                {ConsultStatusDisplay.map(item => (
                  <MenuItem
                    key={item.key || item.id}
                    value={item.value}
                    style={{ fontSize: 14 }}
                  >
                    <Checkbox
                      color="primary"
                      checked={statusFilter.includes(item.value)}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0
                      }}
                    />
                    {item.key}
                  </MenuItem>
                ))}
              </MutipleSelection>
            </CustomSelect>
            {userInfo.roleName !== 'Swabber' && (
              <CustomSelect
                onClick={() => {
                  setTypeOpen(!typeOpen);
                }}
                clearable={!isEmpty(typeFilter)}
                onClear={() => {
                  setTypeFilter([]);
                  setFilterOption({
                    ...filterOption,
                    consultTypes: []
                  });
                }}
                prefixIcon={
                  <ArtTrackOutlined
                    style={{
                      fontSize: 20,
                      color: '#E9E9E9',
                      marginRight: 8
                    }}
                  />
                }
                renderValue={
                  <>
                    {typeFilter.map(type => ConsultType[type]).join(', ') ||
                      'Select Consult Type'}
                  </>
                }
              >
                <MutipleSelection
                  value={typeFilter}
                  data={ConsultType}
                  open={typeOpen}
                  onChange={e => {
                    setFilterOption({
                      ...filterOption,
                      consultTypes: e.target.value
                    });
                    setTypeFilter(e.target.value);
                  }}
                >
                  {ConsultType.map(item => (
                    <MenuItem
                      key={item.key || item.id}
                      value={item.value}
                      style={{ fontSize: 14 }}
                    >
                      <Checkbox
                        color="primary"
                        checked={typeFilter.includes(item.value)}
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingLeft: 0
                        }}
                      />
                      {item.key}
                    </MenuItem>
                  ))}
                </MutipleSelection>
              </CustomSelect>
            )}
          </>
        }
        // onAddNew={caConsultDispatcher.createCA}
        renderRow={d => (
          <>
            <TableCell
              style={{
                paddingLeft: 20
              }}
            >
              {!hiddenRows.consultantDate &&
                momentFormatted(d.consultantDate, DATE_FORMAT_2)}
            </TableCell>
            <TableCell>
              {!hiddenRows.consult && (
                <>
                  <Typography>{ConsultType[d.consultType]}</Typography>
                  <Typography style={{ color: '#8F9BA9' }}>
                    {d.consultShortId}
                  </Typography>
                </>
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.doctorFullName && d.doctorFullName}
            </TableCell>
            <TableCell>
              {!hiddenRows.patient && (
                <>
                  <Typography>{d.patientFullName}</Typography>
                  <Typography style={{ color: '#8F9BA9' }}>
                    {d.patientShortId}
                  </Typography>
                </>
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.diagnosis &&
                (get(d, 'diagnosis', []) || []).map(i => <p>{i}</p>)}
            </TableCell>
            <TableCell>
              {!hiddenRows.duration &&
                durationFormatted(d.startCallTime, d.endCallTime)}
            </TableCell>
            <TableCell>
              {!hiddenRows.totalCost && `$${d.totalCost || '0'}`}
            </TableCell>
            <TableCell>
              {!hiddenRows.consultStatus &&
              d.consultStatus === ConsultStatusDisplay.Cancelled ? (
                <ChipStatus color={d?.cancelledReason ? '#db3d49' : '#8F9BA9'}>
                  {ConsultStatusDisplay[d.consultStatus] || 'No data'}
                </ChipStatus>
              ) : (
                <ChipStatus color={StatusColor[d.consultStatus]}>
                  {ConsultStatusDisplay[d.consultStatus] || 'No data'}
                </ChipStatus>
              )}
            </TableCell>
            <TableCell align="right">
              <IconButton onClick={handleClick(d)}>
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
      />
    </>
  );
};

export default MeetingHistory;
