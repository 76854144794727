import clinicDispatcher from '../actions/index';
import {
  handleUpdateStatusClinicSuccess,
  updateOperatingSuccess
} from './utils';

const initialState = {
  loading: false,
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  }
};

const clinicReducer = clinicDispatcher(initialState, {
  [clinicDispatcher.showHideLoadingAction]: (state, payload) => ({
    loading: payload.loading
  }),
  [clinicDispatcher.getClinicListSuccess]: (state, payload) => ({
    list: payload.list,
    paging: {
      ...payload,
      list: undefined
    }
  }),
  [clinicDispatcher.updateStatusClinicSuccess]: (state, payload) => ({
    list: handleUpdateStatusClinicSuccess(state.list, payload)
  }),
  [clinicDispatcher.onUpdateOperatingSuccess]: (state, payload) => ({
    list: updateOperatingSuccess(state.list, payload)
  })
});

export default clinicReducer;
