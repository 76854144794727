import React from 'react';
import { COLUMN_REVIEW } from '@/module/services/constants';
import ServiceManagement from '../../common/ServiceManagement';
import ReviewModalContainer from './ReviewModalContainer';

const ReviewManagement = props => (
  <ServiceManagement
    {...{
      ...props,
      prefixName: 'Review',
      category: 'Reviews',
      noDataHelperText: 'No review',
      noDataHelperSubText: 'Please create a new review',
      columns: COLUMN_REVIEW,
      ModalContainer: ReviewModalContainer
    }}
  />
);

export default ReviewManagement;
