import CompanyLogo from '@/assets/image/company-logo.png';
import React from 'react';
import styled from 'styled-components';

const AuthLayout = (props) => {
  return (
    <AuthStyled>
      <div className="logo">
        <img
          id="company-logo"
          src={CompanyLogo}
          width="360"
          alt="company-logo"
        />
      </div>
      <div className="info-container">
        <div className="info-children">{props.children}</div>
      </div>
    </AuthStyled>
  );
};

const AuthStyled = styled.div`
  display: flex;
  height: 100vh;
  .logo {
    align-items: center;
    display: flex;
    flex-basis: 50%;
    justify-content: center;
    @media (max-width: 850px) {
      display: none;
    }
  }
  .info-container {
    flex-basis: 50%;
    background-color: #f6f7fa;
    @media (max-width: 850px) {
      flex-basis: 100%;
    }
    .info-children {
      height: 100%;
      width: 100%;
      padding: 100px 50px 60px 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 850px) {
        margin: auto;
      }
    }
  }
`;

export default AuthLayout;
