import React, { useEffect, useMemo, useState } from 'react';
import { CreateNewIcon, RemoveIcon, UseTemplateIcon } from '@/assets/svg';
import SelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';

import moment from 'moment';

import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { cloneDeep, findIndex, isEmpty, isNull } from 'lodash';
import { SmallCheckIconDate } from '../../../../assets/svg';
import { TIME_SELECT } from '../../constants';
import {
  ClosingDateContainer,
  ClosingHeader,
  ClosingRow,
  CustomGridItem,
  CustomSearchInput,
  FlexContainer,
  InputLabel,
  SetupRightContainer,
  SetupTimeContainer,
  SetupTimeTitle,
  Tab,
  TabsLeftContainer,
  UseTemplate
} from '../../createAndEditLocation.styled';
import { getTimeSlots } from '../../utils';
import { IOSSwitch } from '../mainScreen.styled';

const getMinTimeSelected = (data, minTime) => {
  if (data.length === 0) {
    return minTime;
  }

  let newMinTime = data[data.length - 1].endTime;

  if (moment(newMinTime).diff(moment(minTime), 'minute') <= 0) {
    return minTime;
  }
  return newMinTime;
};

// Example usage

const SetupTime = ({
  dayOfWeeks,
  currentSubTab,
  onSwitchTimeSlotsTab,
  onOpenModalImportTemplate,

  onDeleteTimeSlot,
  handleChangeForm,
  dataTimeSlots,

  onSaveDataTimeSlotsNew,
  data,
  minTime,
  maxTime,
  dataTimeSlotsAllWeek,
  errors,
  setDataTimeSlot
}) => {
  const defaultValue = {
    interval: 5,
    quotaBase: '',
    isActive: true,
    startTime: getMinTimeSelected(data, minTime),
    endTime: moment(getMinTimeSelected(data, minTime)).add(
      Number(TIME_SELECT[0]),
      'minutes'
    ),
    locationId: '',
    dayOfWeek: 1
  };

  const [currentValue, setCurrentValue] = useState(null);
  const [showFormAddTime, setShowFormAddTime] = useState(false);
  const [isDisableAddForm, setIsDisableAddForm] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);

  const [formValue, setFormValue] = useState(defaultValue);
  const { interval, quotaBase, startTime, endTime } = formValue;
  const isHasLastedEndTime = !isEmpty(data) && data[data.length - 1]?.endTime;
  const timeSlotsStartOpening = getTimeSlots(7, 21).filter(obj =>
    moment(obj.value, 'h:mm A')
  );
  const timeSlotsEndClosing = getTimeSlots(7, 21).filter(obj =>
    moment(obj.value, 'h:mm A')
  );
  const timeSlotsStartDefault = getTimeSlots(7, 21, interval).filter(obj =>
    moment(obj.value, 'h:mm A').isSameOrAfter(dataTimeSlots?.startTime)
  );
  const timeSlotsEndDefault = getTimeSlots(7, 21, interval).filter(obj =>
    moment(obj.value, 'h:mm A')
  );

  const timeSlotsStartItem = useMemo(() => {
    let ignoreStartTime = [];
    if (!isEmpty(data)) {
      const holdStartTime = data.map(it =>
        moment(it.startTime).format('h:mm A')
      );
      const listTimes = timeSlotsStartDefault.map(it => it.value);
      ignoreStartTime = listTimes.filter(it => !holdStartTime.includes(it));
    }
    return ignoreStartTime;
  }, [timeSlotsStartDefault, data]);

  const onDelete = row => {
    onDeleteTimeSlot(row);
    setCurrentValue(null);
  };

  const handleChangeInput = (key, value) => {
    let endTimeValue = endTime;
    if (key === 'startTime') {
      endTimeValue = moment(value).add(Number(formValue.interval), 'minute');
      setFormValue({ ...formValue, endTime: endTimeValue, [key]: value });
    } else if (key === 'interval') {
      endTimeValue = moment(formValue.startTime).add(Number(value), 'minute');
      setFormValue({ ...formValue, endTime: endTimeValue, [key]: value });
    } else setFormValue({ ...formValue, [key]: value });
  };

  const showFormAddTimeSlot = () => {
    setShowFormAddTime(true);
    setIsDisableAddForm(true);
    setIsAddItem(true);
  };

  const submitFormDataNew = () => {
    const values = {
      isActive: formValue.isActive,
      id: undefined,
      quotaBase: formValue.quotaBase,
      startTime: formValue.startTime,
      endTime: formValue.endTime,
      interval: formValue.interval
    };
    onSaveDataTimeSlotsNew(values);
    setIsDisableAddForm(false);
    setShowFormAddTime(false);
    setCurrentValue(null);
    setFormValue(defaultValue);
  };

  const handleDeleteFormDataNew = e => {
    e && e.preventDefault();
    setShowFormAddTime(false);
    setIsDisableAddForm(false);
    setIsAddItem(false);
    setCurrentValue(null);
    setFormValue(defaultValue);
  };

  const handleOnChangeNumber = (key, value) => {
    if (!value) setFormValue({ ...formValue, [key]: '' });

    if (value && !value.match(/\D/)) {
      setFormValue({ ...formValue, [key]: value });
    }
  };

  useEffect(() => {
    if (showFormAddTime) {
      // const minTime = getMinTimeSelected();
      setFormValue({
        ...defaultValue
        // minTime
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFormAddTime]);

  useEffect(() => {
    if (currentValue && !showFormAddTime) {
      setFormValue(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  useEffect(() => {
    setShowFormAddTime(false);
    setIsDisableAddForm(false);
    setIsAddItem(false);
    setCurrentValue(null);
    setFormValue(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubTab]);

  useEffect(() => {
    if (data.length === 0) return;

    const lastData = data[data.length - 1];
    const firstData = data[0];
    if (
      moment(maxTime).diff(moment(lastData.endTime), 'minute') <= 0 &&
      moment(minTime).diff(moment(firstData.startTime), 'minute') >= 0
    ) {
      setIsDisableAddForm(true);
    } else setIsDisableAddForm(false);

    // Check data is editing
    const index = findIndex(data, item => item.isEdit);
    if (index >= 0) {
      setIsDisableAddForm(true);
    }
  }, [data, maxTime]);

  useEffect(() => {
    // Auto insert timeslot if those keys in filterKeys are entered
    const filterKeys = ['startTime', 'endTime', 'interval', 'quotaBase'];
    const isChecked = filterKeys.every(key => !isEmpty(String(formValue[key])));

    if (isAddItem && isChecked) {
      const timer = setTimeout(() => {
        submitFormDataNew();
        setIsAddItem(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [formValue]);

  const onChangeTimeSlot = (key, value, index) => {
    let newDataTimeSlots = cloneDeep(dataTimeSlotsAllWeek);
    newDataTimeSlots[currentSubTab].timeSlots[index][key] = value;
    setDataTimeSlot(newDataTimeSlots);
  };

  return (
    <SetupTimeContainer>
      <TabsLeftContainer>
        {dayOfWeeks.map((day, index) => (
          <Tab
            key={day.label}
            onClick={() => onSwitchTimeSlotsTab(index)}
            className={clsx(index === currentSubTab && 'active')}
          >
            {day.label}
            {!isEmpty(dataTimeSlotsAllWeek[index]?.timeSlots) && (
              <SmallCheckIconDate />
            )}
          </Tab>
        ))}
      </TabsLeftContainer>
      <SetupRightContainer>
        <Grid container spacing={3} style={{ width: '100%' }}>
          {errors.dayOfWeeks && (
            <Typography color="error">{errors.dayOfWeeks}</Typography>
          )}

          <Grid item xs={12}>
            <SetupTimeTitle>Set up time</SetupTimeTitle>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <InputLabel>Opening hour</InputLabel>
            <SelectContainer
              height="48px"
              data={timeSlotsStartOpening}
              isMutiple={false}
              disabled={isEmpty(timeSlotsStartOpening)}
              renderValue={
                <>
                  {dataTimeSlots?.startTime
                    ? moment(dataTimeSlots?.startTime)?.format('h:mm A')
                    : 'Select opening hour'}
                </>
              }
              filterValue={moment(dataTimeSlots?.startTime).format('h:mm A')}
              onChange={e => {
                handleChangeForm('startTime', moment(e.target.value, 'h:mm A'));
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <InputLabel>Closing hour</InputLabel>
            <SelectContainer
              height="48px"
              data={timeSlotsEndClosing}
              isMutiple={false}
              renderValue={
                <>
                  {dataTimeSlots?.endTime
                    ? moment(dataTimeSlots?.endTime)?.format('h:mm A')
                    : 'Select closing hour'}
                </>
              }
              filterValue={moment(dataTimeSlots?.endTime).format('h:mm A')}
              onChange={e =>
                handleChangeForm('endTime', moment(e.target.value, 'h:mm A'))
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Divider light />
          </Grid>

          <Grid item xs={12}>
            <FlexContainer>
              <SetupTimeTitle>List of time slots</SetupTimeTitle>
              <UseTemplate onClick={onOpenModalImportTemplate}>
                <UseTemplateIcon /> Use time slot template
              </UseTemplate>
            </FlexContainer>
          </Grid>

          <Grid item xs={12}>
            <ClosingDateContainer>
              <ClosingHeader container>
                <CustomGridItem className="grid-2">Start time</CustomGridItem>
                <CustomGridItem className="grid-2">End time</CustomGridItem>
                <CustomGridItem className="grid-2">
                  Time interval
                </CustomGridItem>
                <CustomGridItem className="grid-2">Quota</CustomGridItem>
                <CustomGridItem className="grid-1">Status</CustomGridItem>
                <CustomGridItem className="grid-1">Action</CustomGridItem>
              </ClosingHeader>
              {data?.map((item, index) => (
                <ClosingRow container key={index}>
                  <CustomGridItem className="grid-2">
                    <CustomSearchInput
                      placeholder="00:00  "
                      fullWidth
                      variant="outlined"
                      allowClear
                      disabled
                      InputProps={{
                        endAdornment: null
                      }}
                      value={moment(item?.startTime).format('h:mm A')}
                      onChange={e =>
                        handleChangeInput('startTime', e.target.value)
                      }
                    />
                  </CustomGridItem>
                  <CustomGridItem className="grid-2">
                    <CustomSearchInput
                      placeholder="00:00  "
                      fullWidth
                      variant="outlined"
                      disabled
                      allowClear
                      InputProps={{
                        endAdornment: null
                      }}
                      value={moment(item?.endTime).format('h:mm A')}
                    />
                  </CustomGridItem>
                  <CustomGridItem className="grid-2">
                    <CustomSearchInput
                      disabled
                      placeholder="00:00  "
                      fullWidth
                      variant="outlined"
                      allowClear
                      value={item?.interval + ' min'}
                      InputProps={{
                        endAdornment: null
                      }}
                    />
                  </CustomGridItem>
                  <CustomGridItem className="grid-2">
                    {item.isEdit ? (
                      <CustomSearchInput
                        placeholder="Enter number"
                        fullWidth
                        variant="outlined"
                        type="number"
                        allowClear
                        value={formValue.quotaBase}
                        onChange={e => {
                          handleOnChangeNumber('quotaBase', e.target.value);
                        }}
                        InputProps={{
                          endAdornment: null
                        }}
                      />
                    ) : (
                      <CustomSearchInput
                        disabled={isAddItem}
                        placeholder="Enter number"
                        fullWidth
                        type="number"
                        onChange={e =>
                          onChangeTimeSlot('quotaBase', e.target.value, index)
                        }
                        variant="outlined"
                        allowClear
                        value={item?.quotaBase}
                        InputProps={{
                          endAdornment: null
                        }}
                      />
                    )}
                  </CustomGridItem>
                  <CustomGridItem className="grid-1">
                    <IOSSwitch
                      disabled={isAddItem}
                      checked={item.isActive}
                      onChange={e =>
                        onChangeTimeSlot('isActive', e.target.checked, index)
                      }
                    />
                  </CustomGridItem>
                  <CustomGridItem className="grid-1">
                    <IconButton
                      onClick={() => onDelete(item)}
                      disabled={isAddItem}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </CustomGridItem>
                </ClosingRow>
              ))}
              {showFormAddTime && (
                <ClosingRow container>
                  <CustomGridItem className="grid-2">
                    <SelectContainer
                      data={
                        isHasLastedEndTime
                          ? timeSlotsStartItem
                          : timeSlotsStartDefault
                      }
                      isMutiple={false}
                      renderValue={
                        <>
                          {startTime
                            ? moment(startTime)?.format('h:mm A')
                            : '00:00'}
                        </>
                      }
                      filterValue={[moment(startTime).format('h:mm A')] || ''}
                      onChange={e => {
                        handleChangeInput(
                          'startTime',
                          moment(e.target.value, 'h:mm A')
                        );
                      }}
                    />
                  </CustomGridItem>
                  <CustomGridItem className="grid-2">
                    <SelectContainer
                      disabled
                      data={timeSlotsEndDefault}
                      isMutiple={false}
                      renderValue={
                        <>
                          {endTime
                            ? moment(endTime)?.format('h:mm A')
                            : '00:00'}
                        </>
                      }
                      filterValue={[moment(endTime).format('h:mm A')] || ''}
                    />
                  </CustomGridItem>
                  <CustomGridItem className="grid-2">
                    <SelectContainer
                      data={TIME_SELECT}
                      isMutiple={false}
                      renderValue={
                        <>{interval ? interval + ' min' : 'Select'}</>
                      }
                      filterValue={'' + interval}
                      onChange={e => {
                        handleChangeInput('interval', e.target.value);
                      }}
                    />
                  </CustomGridItem>
                  <CustomGridItem className="grid-2">
                    <CustomSearchInput
                      placeholder="Enter number"
                      fullWidth
                      variant="outlined"
                      allowClear
                      InputProps={{
                        endAdornment: null
                      }}
                      value={quotaBase}
                      onChange={e =>
                        handleChangeInput('quotaBase', e.target.value)
                      }
                    />
                  </CustomGridItem>
                  <CustomGridItem className="grid-1">
                    <IOSSwitch
                      checked={formValue.isActive}
                      onChange={e =>
                        handleChangeInput('isActive', !formValue.isActive)
                      }
                    />
                  </CustomGridItem>
                  <CustomGridItem className="grid-1">
                    <IconButton onClick={handleDeleteFormDataNew}>
                      <RemoveIcon />
                    </IconButton>
                  </CustomGridItem>
                </ClosingRow>
              )}

              <Box p={1}>
                <Button
                  size="small"
                  color="primary"
                  startIcon={<CreateNewIcon />}
                  onClick={showFormAddTimeSlot}
                  disabled={isDisableAddForm}
                >
                  Add time slot
                </Button>
              </Box>
            </ClosingDateContainer>
          </Grid>
        </Grid>
      </SetupRightContainer>
    </SetupTimeContainer>
  );
};

export default SetupTime;
