import React from 'react';
import { Typography, MenuItem, Menu, Select } from '@material-ui/core';
import MutipleSelection from '../../../components/CustomSelect/MutipleSelection';
import { DoneRounded } from '@material-ui/icons';

const ShareScreenMenu = ({
  open,
  setOpen,
  onShare,
  isSharing,
  isSharingAudio
}) => {
  return (
    <MutipleSelection
      id="share-screen-menu"
      open={open}
      value={[]}
      asolutePosition={true}
      onClose={() => {
        setOpen(false);
      }}
      onChange={() => null}
      style={{
        minWidth: 200,
        width: 200
      }}
    >
      <Typography style={{ margin: '8px 16px 8px 16px', color: '#8F9BA9' }}>
        SELECT SHARING OPTION:
      </Typography>
      <MenuItem
        key={'sharing-1'}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Source Sans Pro',
          color: '#192637',
          justifyContent: 'space-between'
        }}
        onClick={() => {
          if (isSharing) return;
          onShare('isSharing');
        }}
      >
        {'Share screen only'}
        {isSharing && (
          <DoneRounded style={{ color: '#62CAA4', fontSize: 14 }} />
        )}
      </MenuItem>
      <MenuItem
        key={'sharing-2'}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Source Sans Pro',
          color: '#192637',
          justifyContent: 'space-between'
        }}
        onClick={() => {
          if (isSharingAudio) return;
          onShare('isSharingAudio');
        }}
      >
        {'Share sound only'}
        {isSharingAudio && (
          <DoneRounded style={{ color: '#62CAA4', fontSize: 14 }} />
        )}
      </MenuItem>
    </MutipleSelection>
  );
};

export default ShareScreenMenu;
