import { synthesize } from 'redux-dispatcher';
import { formatUTCDate } from '@/helpers';
import customToast from '@/new-components/CustomNotification';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, filterOption = {}, key) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().siteClosingReport.paging;
    let { result, status } = await Api.post(`HealthScreenings/filter-${key}`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption,
      date: filterOption?.date
        ? formatUTCDate(filterOption?.date, { isStarOfDay: true })
        : undefined
    });

    status === 200 && siteClosingReportDispatcher.getDataSuccess(result);
  },
  resetData: () => ({}),
  notifyCosingReport: (paxScreeningId, type) => async ({ Api }) => {
    const { status } = await Api.post(
      `HealthScreenings/notify-closing-report`,
      {
        paxScreeningId,
        notificationType: type
      }
    );

    status === 200 && customToast('success', 'Notify to patient success');
  }
};

const siteClosingReportDispatcher = synthesize(
  'siteClosingReport',
  mapDispatchToAC
);

export default siteClosingReportDispatcher;
