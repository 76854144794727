import React from 'react';
import ServiceForm from '../../common/ServiceForm';

const RadiologyForm = props => (
  <ServiceForm
    {...{
      ...props,
      category: 'Radiologies',
      prefixName: 'Radiology'
    }}
  />
);

export default RadiologyForm;
