export const COLUMN_MARKETING_MESSAGES = [
  {
    label: 'Title',
    stateValue: 'title'
  },
  {
    label: 'Content',
    stateValue: 'content'
  },
  {
    label: 'URL',
    stateValue: 'url',
    minWidth: 112
  },
  {
    label: 'Date & Time',
    stateValue: 'date',
    sortBy: 'Date',
    minWidth: 112
  },
  {
    label: 'Status',
    stateValue: 'status'
  },
  {
    label: 'Clicks',
    stateValue: 'clicks'
  },
  {
    label: 'Action',
    width: 100
  }
];

export const MARKETING_MESSAGES_STATUS = {
  Sent: 'Sent',
  Scheduled: 'Scheduled'
};

export const MARKETING_MESSAGES_STATUS_LIST = [
  {
    value: 'Sent',
    key: 'Sent'
  },
  {
    value: 'Scheduled',
    key: 'Scheduled'
  }
];
