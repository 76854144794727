import React, { useState, useEffect } from 'react';
import ModalPage, { ModalFooter } from './styled';
import {
  DialogContent,
  TextField,
  Grid,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { ClearRounded, Visibility, VisibilityOff } from '@material-ui/icons';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import LoadingButton from '../../../components/LoadingButton';
import validateData from '../../../helpers/validationHelpers/validationSchema';
import { TYPE_MODAL } from '../../common/constants/typeModal';
import PhoneInput from '../../../components/PhoneInput';

const MHSModal = ({ onClose, selectedItem, typeModal, onSubmit, loading }) => {
  const [formValue, setFormValue] = useState({
    password: '',
    confirmPassword: '',
    countryCode: 'SG'
  });
  const [errors, setErrors] = useState({});
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (typeModal === TYPE_MODAL.Edit) {
      setFormValue({ ...selectedItem, password: '', confirmPassword: '' });
    }
  }, [typeModal, selectedItem]);

  const handleClickShowPassword = type => {
    if (type === 'password') {
      setIsShowPassword(prevState => !prevState);
    } else if (type === 'confirmPassword') {
      setShowConfirmPassword(prevState => !prevState);
    }
  };

  const validateOnCreate = errs => {
    const hasNoPassword =
      typeModal === TYPE_MODAL.Create && !formValue.password;
    if (errs) {
      hasNoPassword
        ? setErrors({
            ...errs,
            password: 'Password is required'
          })
        : setErrors(errs);
    } else
      hasNoPassword
        ? setErrors({ password: 'Password is required' })
        : onSubmit(formValue);
  };

  const handleSubmit = async () => {
    try {
      await validateData('mhsSchema', formValue, () => {
        validateOnCreate();
      });
    } catch (errs) {
      validateOnCreate(errs);
    }
  };

  const handleChangeForm = key => e => {
    setFormValue({ ...formValue, [key]: e.target.value });
  };

  return (
    <ModalPage>
      <DialogContent>
        <div className="test-header">
          {typeModal === TYPE_MODAL.Create && 'Create New MHS'}
          {typeModal === TYPE_MODAL.Edit &&
            selectedItem.firstName + ' ' + selectedItem.lastName}
          <ClearRounded
            style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
            onClick={onClose}
          />
        </div>
        <div className="test-body">
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12}>
              <TextField
                error={errors.firstName}
                helperText={errors.firstName}
                label="First name"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.firstName}
                onChange={handleChangeForm('firstName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.lastName}
                helperText={errors.lastName}
                label="Last name"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.lastName}
                onChange={handleChangeForm('lastName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.email}
                helperText={errors.email}
                label="Email"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.email}
                onChange={handleChangeForm('email')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.mobile}
                helperText={errors.mobile}
                label="Phone"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.mobile}
                onChange={handleChangeForm('mobile')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneInput
                        country={formValue.countryCode}
                        disabled={false}
                        onChange={handleChangeForm('countryCode')}
                      />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            {typeModal === TYPE_MODAL.Create && (
              <>
                <Grid item xs={12}>
                  <TextField
                    type={isShowPassword ? 'text' : 'password'}
                    error={errors.password}
                    helperText={errors.password}
                    label="Password"
                    style={{ width: '100%', marginTop: 10 }}
                    value={formValue.password}
                    onChange={handleChangeForm('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('password')}
                          >
                            {isShowPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={showConfirmPassword ? 'text' : 'password'}
                    error={errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    label="Confirm Password"
                    style={{ width: '100%', marginTop: 10 }}
                    value={formValue.confirmPassword}
                    onChange={handleChangeForm('confirmPassword')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword('confirmPassword')
                            }
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
        <ModalFooter>
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={onClose}
          >
            Cancel
          </ButtonEnhance>

          <LoadingButton onClick={handleSubmit} showLoader={loading}>
            {typeModal === TYPE_MODAL.Create ? 'Create' : 'Update'}
          </LoadingButton>
        </ModalFooter>
      </DialogContent>
    </ModalPage>
  );
};

export default MHSModal;
