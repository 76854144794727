import { mergeArrayByKey } from '../../../helpers';

export const renderHeader = [
  {
    key: 'name',
    label: 'Clinic'
  },
  {
    key: 'monday',
    label: 'Monday'
  },
  {
    key: 'tuesday',
    label: 'Tuesday'
  },
  {
    key: 'wednesday',
    label: 'Wednesday'
  },
  {
    key: 'thursday',
    label: 'Thursday'
  },
  {
    key: 'friday',
    label: 'Friday'
  },
  {
    key: 'saturday',
    label: 'Saturday'
  },
  {
    key: 'sunday',
    label: 'Sunday'
  },
  {
    key: 'status',
    label: 'Status'
  },
  {
    key: null,
    label: 'action'
  }
];

export const renderDays = listDay => {
  const initialList = [
    { dayOfWeek: 1, items: [], name: 'monday' },
    { dayOfWeek: 2, items: [], name: 'tuesday' },
    { dayOfWeek: 3, items: [], name: 'wednesday' },
    { dayOfWeek: 4, items: [], name: 'thursday' },
    { dayOfWeek: 5, items: [], name: 'friday' },
    { dayOfWeek: 6, items: [], name: 'saturday' },
    { dayOfWeek: 0, items: [], name: 'sunday' }
  ];
  return mergeArrayByKey(initialList, listDay, 'dayOfWeek');
};
