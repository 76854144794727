import { synthesize } from 'redux-dispatcher';
import { uploadImage } from '../../../firebase';
const mapDispatchToAC = {
  getAllClinic: () => async ({ Api }) => {
    const { result, status } = await Api.post('Clinics/filter', {});
    status === 200 && clinicDispatcher.getClinicListSuccess(result);
  },
  getClinicList: (
    search,
    paging,
    sortOption = {},
    filterOption = {}
  ) => async ({ Api, getState }) => {
    const pagingData = paging || getState().clinic.paging;
    const { pageIndex, pageSize } = pagingData;

    const { result, status } = await Api.post(`Clinics/filter`, {
      pageIndex,
      pageSize,
      search,
      ...sortOption,
      ...filterOption
    });
    status === 200 && clinicDispatcher.getClinicListSuccess(result);
  },
  getClinicListSuccess: payload => payload,

  editClinic: (rawData, id, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    clinicDispatcher.showHideLoadingAction({ loading: true });
    let body = { ...rawData };
    let storageUrl;
    const listBanner = [];
    if (rawData.pictureProfile?.constructor === File) {
      storageUrl = await uploadImage(rawData.pictureProfile);
      body.pictureProfile = storageUrl;
    }

    for (let i = 0; i < rawData.lstBanner.length; i++) {
      let url = '';
      const banner = rawData.lstBanner[i];
      if (banner?.constructor === File) {
        url = await uploadImage(banner);
        listBanner.push(url);
      } else {
        banner && listBanner.push(banner);
      }
    }

    body.lstBanner = listBanner;
    const { status } = await Api.put(`Clinics/${id}`, body);

    clinicDispatcher.showHideLoadingAction({ loading: false });
    if (status === 200) {
      callbackSuccess();
      return customToast('success', 'Success');
    }
  },
  showHideLoadingAction: payload => payload,
  addClinic: (rawData, callbackSuccess) => async ({ Api, customToast }) => {
    clinicDispatcher.showHideLoadingAction({ loading: true });
    let body = { ...rawData };
    let storageUrl;
    const listBanner = [];
    if (rawData.pictureProfile?.constructor === File) {
      storageUrl = await uploadImage(rawData.pictureProfile);
      body.pictureProfile = storageUrl;
    }
    for (let i = 0; i < rawData.lstBanner.length; i++) {
      let url = '';
      const banner = rawData.lstBanner[i];
      if (banner?.constructor === File) {
        url = await uploadImage(banner);
        listBanner.push(url);
      } else {
        banner && listBanner.push(banner);
      }
    }

    body.lstBanner = listBanner;
    const { status } = await Api.post(`Clinics`, body);

    clinicDispatcher.showHideLoadingAction({ loading: false });
    if (status === 200) {
      callbackSuccess();
      return customToast('success', 'Success');
    }
  },
  updateStatusClinic: (id, body) => async ({ Api, customToast }) => {
    const { status } = await Api.put(`Clinics/${id}/status`, body);
    if (status === 200) {
      clinicDispatcher.updateStatusClinicSuccess({ id, status: body.status });
      return customToast('success', 'Update status success');
    }
  },
  updateStatusClinicSuccess: payload => payload,
  deleteClinic: (id, searchKey) => async ({ Api, customToast, getState }) => {
    const { status } = await Api.delete(`Clinics/${id}`);
    if (status === 200) {
      let { paging } = getState().clinic;
      clinicDispatcher.getClinicList(searchKey, paging);
      return customToast('success', 'Delete clinic success');
    }
  },
  getOperatingHours: (clinicId, callback) => async ({ Api }) => {
    const { result, status } = await Api.get(
      `ClinicOperatingHours/${clinicId}`
    );
    status === 200 && callback(result);
  },
  createOperatingHours: (data, callback) => async ({ Api }) => {
    const { result, status } = await Api.post('ClinicOperatingHours', data);
    status === 200 && callback(result);
  },
  updateOperatingHours: (payload, callback) => async ({ Api }) => {
    const { data, id } = payload;
    const { status } = await Api.put(`ClinicOperatingHours/${id}`, data);
    status === 200 && callback();
  },
  deleteOperatingHours: (id, callback) => async ({ Api }) => {
    const { status } = await Api.delete(`ClinicOperatingHours/${id}`);
    status === 200 && callback();
  },
  onCreateOperatingSuccess: payload => payload,
  onUpdateOperatingSuccess: payload => payload
};

const clinicDispatcher = synthesize('clinic', mapDispatchToAC);
export default clinicDispatcher;
