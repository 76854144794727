import React, { useState, useEffect } from 'react';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import TableContainer from '../../components/Table/TableContainer';
import styled from 'styled-components';
import {
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  Menu,
  IconButton,
  CardActionArea,
  CardMedia
} from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import MenuAction from '../../components/MenuAction';
import DeleteModal from '../../components/DeleteModal';
import trainerDispatcher from './action';
import TrainerModal from './TrainerModal';
import renderColumn from './renderColumn';
import renderListAction from './renderListAction';
import { COLOR_STATUS } from './constants';

const TrainerManagement = () => {
  const tableColumn = renderColumn();
  const { paging, list: data, loading } = useSelector(
    state => state.trainerManagement
  );

  const [trainerSelected, setTrainerSelected] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onShowDeleteModal = () => {
    setDeleteModal(true);
    setAnchorEl(null);
  };

  const handleDeleteTrainer = () => {
    trainerDispatcher.deleteData(trainerSelected.id, searchKey);
    setDeleteModal(false);
  };

  const onHandleModal = (type, status) => {
    switch (type) {
      case 'delete':
        onShowDeleteModal();
        break;
      case 'edit': {
        setShowModal(true);
        setAnchorEl(null);
        break;
      }
      case 'approve':
      case 'deactive':
        trainerDispatcher.updateStatus(
          {
            userId: trainerSelected.id,
            isApprove: status
          },
          searchKey
        );
        setAnchorEl(null);
        break;
      default:
        break;
    }
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(({ label, stateValue }) => {
          if (!stateValue) return <TableCell key={label}></TableCell>;
          return (
            <TableCellFilted
              key={label}
              label={label}
              stateValue={stateValue}
              hiddenRows={hiddenRows}
              setHiddenRows={setHiddenRows}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );

  const listActions = renderListAction(
    onHandleModal,
    trainerSelected?.approveStatus
  );

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {<MenuAction listActions={listActions} />}
      </Menu>
      <TableContainer
        title="Trainer Management"
        paging={paging}
        data={data}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={trainerDispatcher.getData}
        renderRow={row => (
          <React.Fragment key={row.id}>
            <TableCell>
              {!hiddenRows.fullName &&
                (row.firstName + ' ' + row.lastName || row.fullName)}
            </TableCell>
            <TableCell>{!hiddenRows.email && row.email}</TableCell>
            {/* <TableCell>
              {!hiddenRows.praticisingCertificate &&
                row.praticisingCertificate && (
                  <CardActionArea style={{ width: '100%', height: 180 }}>
                    <CardMedia
                      style={{
                        borderRadius: 4,
                        width: 280,
                        height: 180,
                        objectFit: 'contain'
                      }}
                      component="img"
                      alt="Image cert"
                      image={row.praticisingCertificate}
                      title={row.praticisingCertificate}
                    />
                  </CardActionArea>
                )}
            </TableCell> */}
            <TableCell>
              {!hiddenRows.approveStatus && (
                <StastusInfo status={row.approveStatus}>
                  {row.approveStatus}
                </StastusInfo>
              )}
            </TableCell>
            <TableCell align="right">
              <IconButton
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  setTrainerSelected(row);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </React.Fragment>
        )}
      />
      <Dialog maxWidth="md" open={showModal} onClose={onCloseModal}>
        <TrainerModal
          onClose={onCloseModal}
          selectedItem={trainerSelected}
          loading={loading}
          searchKey={searchKey}
        />
      </Dialog>
      {deleteModal && (
        <DeleteModal
          keyTitle="fullName"
          showModal={deleteModal}
          selectedItem={trainerSelected}
          onClose={() => setDeleteModal(false)}
          onDelete={handleDeleteTrainer}
          modalName="Trainer"
        />
      )}
    </>
  );
};

const StastusInfo = styled.div`
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  width: fit-content;
  background-color: ${props => COLOR_STATUS[props.status]};
`;

export default TrainerManagement;
