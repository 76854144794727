/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { CustomTextField, getErrorMessage } from './index';

const CustomTypography = withStyles({
  root: {
    color: '#333333',
    marginBottom: 8
  }
})(Typography);

const ModalEditTestWaist = ({ formValue, setFormValue, errors }) => {
  const onChangeTestValue = (name, value = '') => {
    if (!value) setFormValue(prevState => ({ ...prevState, [name]: '' }));
    // Regex test number contain decimal. Maximum length number of decimal is 9
    const regexNumber = /^(\d+\.?\d{0,9}|\.\d{1,9})$/g;
    if (regexNumber.test(value)) {
      setFormValue(prevState => ({ ...prevState, [name]: value }));
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <CustomTypography variant="subtitle2">
          Waist Hip Radio Waist (cm)
        </CustomTypography>
        <CustomTextField
          required
          size="small"
          type="number"
          name="ratioWaist"
          placeholder="Enter value"
          value={formValue?.ratioWaist}
          variant="outlined"
          fullWidth
          error={!!errors.ratioWaist}
          helperText={getErrorMessage(errors.ratioWaist)}
          onChange={e => onChangeTestValue('ratioWaist', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTypography variant="subtitle2">
          Waist Hip Radio Hip (cm)
        </CustomTypography>
        <CustomTextField
          required
          size="small"
          type="number"
          name="ratioHip"
          placeholder="Enter value"
          value={formValue?.ratioHip}
          variant="outlined"
          fullWidth
          error={!!errors.ratioHip}
          helperText={getErrorMessage(errors.ratioHip)}
          onChange={e => onChangeTestValue('ratioHip', e.target.value)}
        />
      </Grid>
    </>
  );
};

export default ModalEditTestWaist;
