import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

import { PaperWrapped, HeaderWrapped } from '../lab-result/component/styled';
import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { isEmpty, get } from 'lodash';
import customToast from '@/new-components/CustomNotification';
import importHSAppointmentDispatcher from './action';
import { convertToFormData, dummyRequest } from '../lab-result/utils';

const ImportHSAppointments = () => {
  const [files, setFiles] = useState({});

  const handleUploadFiles = () => {
    if (isEmpty(files)) return customToast('error', 'File must not be empty');

    // Code here
    importHSAppointmentDispatcher.uploadHSAppointments(
      convertToFormData(files.originFileObj, 'file')
    );
  };

  const handleChangeFiles = e => {
    setFiles(e.file);
  };

  const handleClearFiles = () => setFiles({});

  return (
    <PaperWrapped>
      <HeaderWrapped className="no-tabs-header">
        <Typography style={{ fontSize: 18, fontWeight: 600 }}>
          Import Health Screening Appointments
        </Typography>

        <div className="personal-btn">
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={handleClearFiles}
          >
            Clear
          </ButtonEnhance>
          <ButtonEnhance onClick={handleUploadFiles}>Upload</ButtonEnhance>
        </div>
      </HeaderWrapped>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Upload.Dragger
          customRequest={dummyRequest}
          onChange={handleChangeFiles}
          showUploadList={false}
          accept={'.xlsx'}
          file={files}
        >
          <div className="upload-btn">
            <div className="group">
              <InboxOutlined />
              <div className="text">
                {isEmpty(files)
                  ? 'Click or drag file to this area to upload'
                  : `${files.name}`}
              </div>
            </div>
          </div>
        </Upload.Dragger>
      </Box>
    </PaperWrapped>
  );
};

export default ImportHSAppointments;
