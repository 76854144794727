const isValidNewDate = (list, newValue) => {
  let result = true;

  for (let i = 0; i < list.length; i++) {
    const oldValue = list[i];
    if (
      !oldValue.newValue &&
      ((oldValue.startTime > newValue.startTime &&
        oldValue.startTime < newValue.endTime) ||
        (oldValue.endTime > newValue.startTime &&
          oldValue.endTime < newValue.endTime) ||
        (oldValue.startTime < newValue.startTime &&
          oldValue.endTime > newValue.endTime) ||
        (oldValue.startTime > newValue.startTime &&
          oldValue.endTime < newValue.endTime) ||
        newValue.startTime >= newValue.endTime)
    ) {
      result = false;
      break;
    }
  }
  return result;
};

export default isValidNewDate;
