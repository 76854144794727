/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CustomSearchInput } from './styled';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
  withStyles
} from '@material-ui/core';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import useDebounce from '@/helpers/useDebounce';
import locationDispatcher from '@/module/setup/action';

import { parseDataTimeMoment } from '@/module/setup/utils';
import validateData from '@/helpers/validationHelpers/validationSchema';
import CustomModalTitle from '@/new-components/CustomModal/CustomModalTitle';
import CustomAutoComplete from '@/new-components/CustomAsyncAutoComplete/CustomAutoComplete';
import CustomDate from '@/new-components/CustomDate';
import { isEmpty } from 'lodash';

const defaultFilter = {
  filterOption: {
    statuses: ['Active']
  },
  paging: {}
};

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 8,
    fontWeight: 600
  }
})(InputLabel);

const CustomDialogContent = withStyles(theme => ({
  root: {
    padding: '24px 16px'
  }
}))(DialogContent);

const CustomDialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    justifyContent: 'flex-end'
  }
}))(DialogActions);

const CustomButton = withStyles(theme => ({
  label: {
    minWidth: 120
  }
}))(Button);

const AddLocationModal = ({
  onClose,
  onSaveData,
  value,
  resetCurrentValue,
  minDate,
  maxDate,
  listOrganisationCodes
}) => {
  const [locations, setLocations] = useState([]);
  const [locationSelected, setLocationSelected] = useState({
    id: '',
    address: '',
    name: ''
  });
  const [search, setSearch] = useState('');
  const [loadingAutocomplete, setLoadingAutoComplete] = useState(false);
  const debounceSearch = useDebounce(search);
  const [isCurrentDay, setIsCurrentDay] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({ startTime: '', endTime: '' });

  const checkIsCurrentDay = valueStartDate => {
    const startTimeCompare = moment(valueStartDate)
      .startOf('date')
      .unix();
    const currentDayCompare = moment()
      .startOf('date')
      .unix();
    return startTimeCompare === currentDayCompare;
  };

  const [formValue, setFormValue] = useState({
    // startTime: checkIsCurrentDay(minDate) ? moment() : MIN_TIME_PICK,
    // endTime: MAX_TIME_PICK,
    startTime: '',
    endTime: '',
    startDate: moment(minDate).startOf('D'),
    endDate: moment(maxDate).endOf('D'),
    locationId: '',
    address: '',
    idTemp: uuidV4(),
    pmCode: ''
  });

  const { startDate } = formValue;

  useEffect(() => {
    return () => {
      resetCurrentValue();
    };
  }, []);

  useEffect(() => {
    if (checkIsCurrentDay(startDate)) {
      setIsCurrentDay(true);
    } else {
      setIsCurrentDay(false);
    }
  }, [startDate]);

  useEffect(() => {
    if (value) {
      const { startDate, endDate } = value;
      setIsEdit(true);
      setFormValue({
        ...value,
        address: value.locationAddress,
        startTime: startDate,
        endTime: endDate
      });
      setLocationSelected({
        id: value?.locationId,
        address: value?.locationAddress,
        name: value?.locationName
      });
    }
  }, [value]);

  useEffect(() => {
    setLoadingAutoComplete(true);
    locationDispatcher.getLocation(
      debounceSearch,
      defaultFilter.paging,
      {},
      defaultFilter.filterOption,
      data => {
        const newData = data.map(item => ({
          ...item,
          startTime: parseDataTimeMoment(item.startTime),
          endTime: parseDataTimeMoment(item.endTime)
        }));
        setLocations(newData);
        setLoadingAutoComplete(false);
      }
    );
  }, [debounceSearch]);

  const onHandleChangeForm = (key, value) => {
    if (key === 'locationId') {
      if (!value) {
        setFormValue({ ...formValue, [key]: '', address: '' });
        setLocationSelected({
          id: '',
          address: ''
        });
        return;
      }
      const { id, address, startTime, endTime, name } = value;
      setFormValue({
        ...formValue,
        [key]: id,
        address,
        startTime,
        endTime,
        locationName: name,
        startDate: moment(minDate)
      });
      setLocationSelected({
        id,
        address,
        name
      });
    } else {
      setFormValue({ ...formValue, [key]: value });
    }
  };

  const onSubmitSaveData = async () => {
    try {
      await validateData(
        'dateScreeningSchema',
        { ...formValue, minDate, maxDate },
        () => {
          const { startTime, endTime, startDate, endDate } = formValue;
          const hourStartTime = moment(startTime).get('hour');
          const minStartTime = moment(startTime).get('minute');
          const hourEndTime = moment(endTime).get('hour');
          const minEndTime = moment(endTime).get('minute');
          onSaveData({
            ...formValue,
            locationAddress: formValue.address,
            startDate: moment(startDate).set({
              hour: hourStartTime,
              minute: minStartTime,
              second: 0
            }),
            endDate: moment(endDate).set({
              hour: hourEndTime,
              minute: minEndTime,
              second: 0,
              millisecond: 0
            }),
            startTime: undefined,
            endTime: undefined
          });
          onClose();
        }
      );
    } catch (err) {
      setErrors({ ...err });
    }
  };

  return (
    <>
      <CustomModalTitle onClose={onClose}>
        {isEdit ? 'Edit location' : 'Add location'}
      </CustomModalTitle>

      <CustomDialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomInputLabel>Location</CustomInputLabel>
            <CustomAutoComplete
              variant="outlined"
              value={locationSelected}
              data={locations}
              onSearch={value => setSearch(value)}
              onSelected={value => {
                onHandleChangeForm('locationId', value);
              }}
              loading={loadingAutocomplete}
              optionKey="id"
              optionLabel="name"
              placeholder="Select or type location"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Address</CustomInputLabel>
            <CustomSearchInput
              placeholder="Auto-generated"
              disabled
              value={formValue.address || ''}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Organization code</CustomInputLabel>
            <CustomAutoComplete
              variant="outlined"
              value={
                listOrganisationCodes.find(
                  item => item.key === formValue.pmCode
                ) || null
              }
              optionKey="key"
              optionLabel="key"
              data={listOrganisationCodes}
              onSearch={() => {}}
              onSelected={value => {
                onHandleChangeForm('pmCode', value?.key || '');
              }}
              placeholder="Select code"
              disabled={value?.hasOnGoingPaxScreening}
              disableClearable
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Organization name</CustomInputLabel>
            <CustomSearchInput
              placeholder="Auto-generated"
              disabled
              value={
                listOrganisationCodes.find(
                  item => item.key === formValue.pmCode
                )?.value || ''
              }
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Start date</CustomInputLabel>
            <CustomDate
              clearable={false}
              placeholder="Select date"
              height="48px"
              selectedDate={moment(formValue?.startDate).startOf('D')}
              onChange={value => onHandleChangeForm('startDate', value)}
              minDate={moment(minDate)}
              maxDate={formValue?.endDate}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>End date</CustomInputLabel>
            <CustomDate
              clearable={false}
              placeholder="Select date"
              height="48px"
              selectedDate={moment(formValue?.endDate).startOf('D')}
              onChange={value => onHandleChangeForm('endDate', value)}
              minDate={formValue?.startDate}
              maxDate={moment(maxDate)}
            />
          </Grid>
        </Grid>
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton
          color="primary"
          variant="contained"
          onClick={onSubmitSaveData}
          disabled={
            isEmpty(formValue?.address) ||
            isEmpty(formValue?.startDate) ||
            isEmpty(formValue?.endDate) ||
            isEmpty(formValue?.pmCode)
          }
        >
          {isEdit ? 'Save' : 'Submit'}
        </CustomButton>
      </CustomDialogActions>
    </>
  );
};

export default AddLocationModal;
