import BreadcrumbAnt from '../../../components/BreadcrumbAnt';
import { urlLabel } from '../../../enum/PermissionEnum';
import fetchHelper from '../../../helpers/FetchHelper';
import authDispatcher from '../../auth/action/auth';
import userPermissionDispatcher from '../../user-permission/action';
import ChangePasswordForm from '../profile/component/ChangePasswordForm';
import AppointmentCall from './AppointmentCall/AppointmentCall';
import Notification from './Notification';
import './private.scss';
import { ROLES_NAMES_SPECIAL } from '@/constants/roles';
import roleManagementDispatcher from '@/module/role-management/action';
import SidebarMenuContainer from '@/new-components/CustomSidebarMenu';
import globalDispatcher from '@/redux/global/actions';
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  withStyles,
  Paper,
} from '@material-ui/core';
import { Menu as AntMenu } from 'antd';
import { Spin } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const drawerWidth = 256;
const headerHeight = 64;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    width: `calc(100% - 80px)`,
    minHeight: headerHeight,
    marginLeft: drawerWidth,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'white',
    boxShadow: 'none',
    borderBottom: '1px solid #e9e9e9',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerRoot: {
    backgroundColor: '#192637',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    background: '#FFFFFF',
    marginTop: headerHeight,
  },

  chartContainer: {
    marginLeft: -22,
  },

  h5: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    width: 24,
    height: 24,
  },
  dropDown: {
    width: 11,
    height: 11,
  },
  wrapperAction: {
    display: 'flex',
  },
  wrapperImgLogo: {
    alignSelf: 'center',
    marginTop: 16,
    marginBottom: 16,
  },
});

class PrivateLayout extends React.Component {
  state = {
    open: true,
    anchorMenuEl: null,
    isShowNotification: null,
    showChangePW: false,
    showChangeEmail: false,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: !this.state.open });
  };

  handleMenuClose = () => {
    this.setState({ anchorMenuEl: null });
  };
  handleNotificationClose = () => {
    this.setState({ isShowNotification: null });
  };

  handleMenuOpen = (event) => {
    this.setState({ anchorMenuEl: event.currentTarget });
  };

  componentDidMount() {
    const { accessToken } = this.props;
    if (accessToken) {
      fetchHelper.addDefaultHeader('Authorization', `Bearer ${accessToken}`);
      authDispatcher.getUserInfo(() => {
        userPermissionDispatcher.getUserPermission(() => {
          globalDispatcher.getListCategories();
        });
        roleManagementDispatcher.getUserPermissions();
      });
    }
  }

  goToProfile = () => {
    this.props.history.push(`/${urlLabel.profileManagement}`);
  };

  renderDropdownOption = () => {
    return (
      <AntMenu className="header-account-menu">
        <AntMenu.Item onClick={this.goToProfile}>
          <Typography>Profile</Typography>
        </AntMenu.Item>
        <AntMenu.Item onClick={() => this.setState({ showChangePW: true })}>
          <Typography>Change Password</Typography>
        </AntMenu.Item>
      </AntMenu>
    );
  };

  render() {
    const { classes, reloadPageRequest, userInfo, accessToken, privilege } =
      this.props;

    return (
      <div className={classes.root}>
        {/* {ROLES_NAMES_SPECIAL.includes(userInfo?.roleName) && (
          <AppointmentCall token={accessToken} userInfo={userInfo} />
        )} */}
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(
            classes.appBar,
            this.state.open && classes.appBarShift
          )}
        >
          <Toolbar
            disableGutters={!this.state.open}
            className={classes.toolbar}
          >
            <BreadcrumbAnt />

            <div className={classes.wrapperAction}>
              <Notification />
            </div>
          </Toolbar>
        </AppBar>

        <SidebarMenuContainer
          open={this.state.open}
          onClose={this.handleDrawerClose}
          userInfo={userInfo}
        />
        <Drawer
          anchor="right"
          width="50%"
          open={this.state.showChangePW}
          onClose={() => this.setState({ showChangePW: false })}
          PaperProps={{
            style: {
              width: 720,
            },
          }}
        >
          <ChangePasswordForm
            onClose={() => this.setState({ showChangePW: false })}
          />
        </Drawer>
        <main className={classes.content}>
          {!privilege.length ? (
            <Spin />
          ) : (
            <Paper square elevation={0}>
              {this.props.children}
            </Paper>
          )}
        </main>
      </div>
    );
  }
}

PrivateLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    userInfo: state.auth.userInfo,
    privilege: state.userPermission.privilege,
  };
};
export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(PrivateLayout))
);
