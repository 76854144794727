import { uploadImage } from '@/firebase';
import { FORMAT_YYYY_MM_DD } from '@/module/telecounsel-appointment-configuration/constants';
import { cloneDeep, get } from 'lodash';
import moment from 'moment';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  resetData: () => ({}),
  getData: (search, paging, sortOption = {}, filterOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().counsellors.paging;
    let { result, status } = await Api.post(`Counsellors/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption,
      ...sortOption
    });
    status === 200 && counsellorsManagementDispatcher.getDataSuccess(result);
  },
  editCounsellor: (id, payload, onSuccess, onFail) => async ({ Api }) => {
    const data = cloneDeep(payload);
    if (data.imageUrl?.constructor === File) {
      const timeStamp = new Date().getTime();
      data.fileName = timeStamp + get(data, 'imageUrl.name');

      const storageUrl = await uploadImage(data.imageUrl);
      data.imageUrl = storageUrl;
    }
    if (data.counsellingLicense?.constructor === File) {
      const timeStamp = new Date().getTime();
      data.fileName = timeStamp + get(data, 'counsellingLicense.name');

      const storageUrl = await uploadImage(data.counsellingLicense);
      data.counsellingLicense = storageUrl;
    }
    delete data?.fullName;

    let { status, result } = await Api.put(`Counsellors/${id}/info`, data);

    status === 200 ? onSuccess(result) : onFail();
  },
  createCounsellor: (payload, onSuccess, onFail) => async ({ Api }) => {
    const data = cloneDeep(payload);
    if (data.counsellingLicense?.constructor === File) {
      const timeStamp = new Date().getTime();
      data.fileName = timeStamp + get(data, 'counsellingLicense.name');

      const storageUrl = await uploadImage(data.counsellingLicense);
      data.counsellingLicense = storageUrl;
    }

    let { status } = await Api.post(`Counsellors/add-new`, {
      ...data,
      fullName: `${data.firstName} ${data.lastName}`
    });
    status === 200 ? onSuccess() : onFail();
  },
  getDetailCounsellor: (id, onSuccess) => async ({ Api }) => {
    const { status, result } = await Api.get(`Counsellors/${id}/info`);
    if (status === 200) {
      onSuccess && onSuccess(result);
    }
  },
  approveCounsellor: (userId, isApprove = false, onSuccess) => async ({
    Api,
    customToast
  }) => {
    const { status } = await Api.post(`Doctor/approve`, {
      userId,
      isApprove
    });
    if (status === 200) {
      customToast(
        'success',
        `${isApprove ? 'Approve' : 'Reject'} successfully`
      );
      onSuccess();
    }
  },
  activateCounsellor: (id, activeStatus = false, onSuccess) => async ({
    Api,
    customToast
  }) => {
    const { status } = await Api.put(`Counsellors/${id}/active`, {
      activeStatus
    });
    if (status === 200) {
      customToast(
        'success',
        `${
          activeStatus === 'InActive' ? 'Deactivate' : 'Activate'
        } successfully`
      );
      onSuccess();
    }
  },
  deleteCounsellor: (id, onSuccess) => async ({ Api, customToast }) => {
    let { status } = await Api.delete(`Users/${id}`);
    if (status === 200) {
      customToast('success', 'Deleted successfully');
      onSuccess();
    }
  },
  getDataBooking: params => async ({ Api }) => {
    const { date, counsellorId } = params;
    const startDateDefault =
      moment(date).format(FORMAT_YYYY_MM_DD) + 'T00:00:00Z';
    const endDateDefault =
      moment(date).format(FORMAT_YYYY_MM_DD) + 'T23:59:59Z';

    const response = await Api.post('BookingTimeConfigurations/filter', {
      fromDate: startDateDefault,
      toDate: endDateDefault,
      type: 'TeleCounsel',
      counsellorId
    });

    const responseDetail = await Api.get(`Counsellors/${counsellorId}/info`);
    if (!response || !responseDetail) return;
    if (response && responseDetail) {
      const filterDay = moment(startDateDefault).get('day');
      const activatedDayOfWeek = cloneDeep(
        responseDetail.result?.availabilities
      )
        .filter(d => d.isActivated)
        .map(d => d.dayOfWeek);
      // Filter to re-check unvailable data get from api
      const filterDataActived = activatedDayOfWeek?.includes(filterDay)
        ? cloneDeep(response.result?.list)
        : [];

      const data = filterDataActived.map(d => ({
        key: moment(d.startDate).format('h:mm A'),
        value: moment(d.startDate).format('h:mm A')
      }));

      counsellorsManagementDispatcher.getDataBookingSuccess(data);
    }
  },
  getDataBookingSuccess: timeBooking => ({ timeBooking })
};

const counsellorsManagementDispatcher = synthesize(
  'counsellors',
  mapDispatchToAC
);
export default counsellorsManagementDispatcher;
