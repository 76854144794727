import { uploadImage } from '@/firebase';
import { cloneDeep } from 'lodash';
import { synthesize } from 'redux-dispatcher';

export const API_SIGNALR_URL = process.env.REACT_APP_API_SIGNALR;

const mapDispatchToAC = {
  // Service
  setState: (state, value) => ({ state, value }),
  resetData: () => ({}),
  getSymptomsList:
    () =>
    async ({ Api }) => {
      const { result, status } = await Api.get(`SymptomSuggestions`);
      status === 200 && teleConsultNowDispatcher.setState('symptoms', result);
    },

  getCorporatePlans:
    () =>
    async ({ Api }) => {
      const { result, status } = await Api.get(`CorporatePlans`);
      status === 200 &&
        teleConsultNowDispatcher.setState('corporatePlans', result);
    },

  getAllClinics:
    () =>
    async ({ Api }) => {
      const { result, status } = await Api.post(
        'Clinics/filter?clinicStatus=Open',
        {}
      );
      status === 200 &&
        teleConsultNowDispatcher.setState('clinics', result?.list);
    },

  checkValidPromotionsCode:
    (code, onSuccess) =>
    async ({ Api }) => {
      const { status, result } = await Api.get('Promotions/is-valid', {
        code,
      });
      if (status === 200) {
        onSuccess && onSuccess(result);
      }
    },
  checkWaitingRoomStatus:
    () =>
    async ({ Api }) => {
      const { status, rawData } = await Api.get(
        'Patients/waiting-room-status',
        {},
        false,
        true,
        API_SIGNALR_URL
      );
      if (status === 200) {
        teleConsultNowDispatcher.setState('waitingRoomStatus', rawData);
      }
    },

  checkAvailableService:
    (onSuccess) =>
    async ({ Api }) => {
      const { status, result } = await Api.get(
        'FunctionsConfigurations?function=TeleConsult'
      );
      if (status === 200) {
        onSuccess && onSuccess(result);
      }
    },

  joinPTWaitingRoom:
    (payload, onSuccess) =>
    async ({ Api }) => {
      const body = cloneDeep(payload);

      if (payload.frontCorperatePlan.constructor === File) {
        const fontUrl = await uploadImage(
          payload.frontCorperatePlan,
          false,
          true
        );
        body.frontCorperatePlan = fontUrl;
      }
      if (payload.backCorperatePlan.constructor === File) {
        const backCurl = await uploadImage(
          payload.backCorperatePlan,
          false,
          true
        );
        body.backCorperatePlan = backCurl;
      }
      const { status } = await Api.post(
        'Patients/join-waiting-room',
        body,
        '',
        false,
        true,
        false,
        API_SIGNALR_URL
      );
      if (status === 200) {
        onSuccess && onSuccess();
      }
    },

  leaveWaitingRoom:
    (onSuccess) =>
    async ({ Api }) => {
      const { status } = await Api.delete(
        'Patients/leave-waiting-room',
        '',
        false,
        true,
        API_SIGNALR_URL
      );
      if (status === 200) {
        onSuccess && onSuccess();
      }
    },
  updateLocalStream: (streams) => ({ streams }),
  updateRemoteStream: (streams) => ({ streams }),
  addCorporatePlan:
    (payload, onSuccess, onFail) =>
    async ({ Api }) => {
      const body = cloneDeep(payload);

      if (payload.frontCorperatePlan.constructor === File) {
        const fontUrl = await uploadImage(
          payload.frontCorperatePlan,
          false,
          true
        );
        body.frontCorperatePlan = fontUrl;
      }
      if (payload.backCorperatePlan.constructor === File) {
        const backCurl = await uploadImage(
          payload.backCorperatePlan,
          false,
          true
        );
        body.backCorperatePlan = backCurl;
      }
      const { status } = await Api.post('CorporatePlans', {
        name: body.corperatePlan,
        frontPhoto: body.frontCorperatePlan,
        backPhoto: body.backCorperatePlan,
      });
      if (status === 200) {
        onSuccess && onSuccess();
      } else {
        onFail && onFail();
      }
    },
};

const teleConsultNowDispatcher = synthesize('teleConsultNow', mapDispatchToAC);
export default teleConsultNowDispatcher;
