import React, { useMemo } from 'react';
import { isEmpty, lowerCase } from 'lodash';
import { Editor } from '@tinymce/tinymce-react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core';

import { CONSENT_SERVICE, CATEGORY_LIST } from '../../constants';
import {
  TINY_API_KEY,
  TINY_INIT_CONFIGURATION
} from '@/constants/editorConfig';
import { ArrowDown } from '@/assets/svg';
import { DEFAULT_COMPULSORY_TAGS } from '@/module/configuration/constants';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { IOSSwitch } from '@/module/setup/components/mainScreen.styled';
import { handleSelectAll } from '@/helpers';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 4,
    color: '#666666'
  }
})(Typography);

const CustomAutocomplete = withStyles({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"]': {
      padding: 0
    }
  },
  option: {
    '& span[class="max-line"]': {
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      display: '-webkit-inline-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2
    }
  }
})(Autocomplete);

const ServiceForm = ({
  formValue,
  setFormValue,
  errors,
  category,
  prefixName,
  requireLabCode = false,
  requireLoinc = false,
  requirePE = false,
  listTags
}) => {
  const { stations: listStations = [] } = useSelector(
    state => state.globalState
  );
  const isConsentService = CONSENT_SERVICE.includes(category);
  const isAddGrid = !['Tests', 'Measurements'].includes(category);

  const compulsoryTag = useMemo(() => {
    let list = [];
    if (!isEmpty(listTags)) {
      list = listTags.filter(spec =>
        DEFAULT_COMPULSORY_TAGS.some(el => spec.includes(el))
      );
    }
    return list;
  }, [listTags]);

  const optionalTags = useMemo(() => {
    let list = [];
    if (!isEmpty(listTags)) {
      list = listTags.filter(
        spec => !DEFAULT_COMPULSORY_TAGS.some(el => spec.includes(el))
      );
    }
    return list;
  }, [listTags]);

  const onChange = key => e => {
    if (!setFormValue) return;

    let value;
    switch (key) {
      case 'optionalTags':
        value = handleSelectAll(
          e.target.value,
          optionalTags,
          formValue?.optionalTags
        );
        break;
      case 'isMandatory':
      case 'hasPhysicalExamination':
        value = e.target.checked;
        break;

      case 'description':
      case 'shortDesc':
        value = e;
        break;

      case 'loinc':
      case 'euroFinLOINC':
        value = e.target.value?.trim();
        break;

      default:
        value = e.target.value;
        break;
    }

    setFormValue({
      ...formValue,
      [key]: value
    });
  };

  return (
    <Box px={2.25} py={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomTitleLabel>{prefixName} name</CustomTitleLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter name"
            error={errors.name}
            helperText={errors.name}
            value={formValue.name || ''}
            onChange={onChange('name')}
          />
        </Grid>
        {requireLabCode && (
          <Grid item xs={6}>
            <CustomTitleLabel>{prefixName} code</CustomTitleLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Enter code"
              error={errors.labHeader}
              helperText={errors.labHeader}
              value={formValue.labHeader || ''}
              onChange={onChange('labHeader')}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <CustomTitleLabel>Station</CustomTitleLabel>
          <CustomAutocomplete
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name || ''}
            options={listStations || []}
            value={{
              id: formValue.stationId,
              name: formValue.stationName
            }}
            autoComplete
            onChange={(e, newValue) => {
              newValue
                ? setFormValue({
                    ...formValue,
                    stationId: newValue.id,
                    stationName: newValue.name
                  })
                : setFormValue({
                    ...formValue,
                    stationId: null,
                    stationName: null
                  });
            }}
            renderInput={params => (
              <CustomTextField
                {...params}
                variant="outlined"
                placeholder="Select station"
                error={errors.stationId}
                helperText={errors.stationId}
              />
            )}
            renderOption={option => (
              <span className="max-line">{option.name}</span>
            )}
            popupIcon={<ArrowDown />}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTitleLabel>Compulsory tag</CustomTitleLabel>
          <CustomSelectContainer
            isFullWidth
            filterValue={formValue.compulsoryTag || []}
            data={compulsoryTag}
            renderValue={<>{formValue.compulsoryTag || 'Select tag'}</>}
            height="48px"
            fontSize="16px"
            isMutiple={false}
            onChange={onChange('compulsoryTag')}
            onClear={() => {
              setFormValue({ ...formValue, compulsoryTag: '' });
            }}
          />
          {errors.tags && <Typography color="error">{errors.tags}</Typography>}
        </Grid>
        <Grid item xs={6}>
          <CustomTitleLabel>Optional tags</CustomTitleLabel>
          <CustomSelectContainer
            isFullWidth
            filterValue={formValue?.optionalTags || []}
            data={optionalTags}
            renderValue={
              <>{formValue?.optionalTags?.join(', ') || 'Select tag'}</>
            }
            height="48px"
            fontSize="16px"
            onChange={onChange('optionalTags')}
            onClear={() =>
              setFormValue({
                ...formValue,
                optionalTags: []
              })
            }
            useSelectAll
            checkedAll={
              optionalTags?.length === formValue?.optionalTags?.length
            }
          />

          {errors.optionalTags && (
            <Typography color="error">{errors.optionalTags}</Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <CustomTitleLabel>Category</CustomTitleLabel>
          <CustomAutocomplete
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name || ''}
            options={CATEGORY_LIST}
            value={{
              id: formValue.group,
              name: CATEGORY_LIST.find(it => it.id === formValue.group)?.name
            }}
            autoComplete
            onChange={(e, newValue) => {
              newValue
                ? setFormValue({
                    ...formValue,
                    group: newValue.id
                  })
                : setFormValue({
                    ...formValue,
                    group: 'None'
                  });
            }}
            renderInput={params => (
              <CustomTextField
                {...params}
                variant="outlined"
                placeholder="Select category"
                error={errors.group}
                helperText={errors.group}
              />
            )}
            renderOption={option => (
              <span className="max-line">{option.name}</span>
            )}
            popupIcon={<ArrowDown />}
          />
        </Grid>
        {isAddGrid && <Grid item xs={6}></Grid>}
        {requireLoinc && (
          <>
            <Grid item xs={6}>
              <CustomTitleLabel>LOINC</CustomTitleLabel>
              <CustomTextField
                fullWidth
                variant="outlined"
                placeholder="Enter code"
                error={errors.loinc}
                helperText={errors.loinc}
                value={formValue.loinc || ''}
                onChange={onChange('loinc')}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTitleLabel>EUROFINS LOINC</CustomTitleLabel>
              <CustomTextField
                fullWidth
                variant="outlined"
                placeholder="Enter code"
                error={errors.euroFinLOINC}
                helperText={errors.euroFinLOINC}
                value={formValue.euroFinLOINC || ''}
                onChange={onChange('euroFinLOINC')}
              />
            </Grid>
          </>
        )}

        {isConsentService && (
          <>
            <Grid item xs={12}>
              <CustomTitleLabel>Short description</CustomTitleLabel>
              <Editor
                apiKey={TINY_API_KEY}
                init={TINY_INIT_CONFIGURATION}
                value={formValue?.shortDesc || ''}
                onEditorChange={onChange('shortDesc')}
                cloudChannel="stable"
                placeholder="Enter content"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTitleLabel>Long description</CustomTitleLabel>
              <Editor
                apiKey={TINY_API_KEY}
                init={TINY_INIT_CONFIGURATION}
                value={formValue?.description || ''}
                onEditorChange={onChange('description')}
                cloudChannel="stable"
                placeholder="Enter content"
              />
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomTitleLabel>
              Mandatory {lowerCase(prefixName)}
            </CustomTitleLabel>

            <IOSSwitch
              color="primary"
              checked={!!formValue.isMandatory}
              onChange={onChange('isMandatory')}
            />
          </Box>
        </Grid>

        {requirePE && (
          <>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <CustomTitleLabel>Physical examination</CustomTitleLabel>
                <IOSSwitch
                  color="primary"
                  checked={!!formValue.hasPhysicalExamination}
                  onChange={onChange('hasPhysicalExamination')}
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
export default ServiceForm;
