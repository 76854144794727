import Api from '../../helpers/Api';
import { debounced } from '../../helpers/CommonHelper';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { ExpandMore } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get } from 'lodash';
import React from 'react';

const PAGING_OPTIONS = {
  pageIndex: 0,
  pageSize: 10,
};

const CustomPaper = withStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))(Paper);

const AsyncAutoComplete = ({
  label,
  endpoint,
  method = 'get',
  defaultFilter,
  value,
  setValue,
  error,
  getOptionLabel,
  hasPayload = true,
  nameKey,
  renderInput,
  renderOption,
  disabled = false,
  disableClearable = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [pageOptions, setPageOptions] = React.useState(PAGING_OPTIONS);
  const [totalItem, setTotalItem] = React.useState(0);

  React.useEffect(() => {
    if (!open) return;
    !loading && setLoading(true);
    debounced(async () => {
      const { result, status } = await Api[method](
        endpoint,
        hasPayload && {
          ...defaultFilter,
          ...pageOptions,
          search: inputValue,
        }
      );

      setLoading(false);
      setOptions(get(result, 'list', []));
      setTotalItem(get(result, 'totalItem', 0));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, open]);

  const loadMoreResults = async () => {
    if (totalItem === options.length) return;
    setPageOptions((pageOptions) => ({
      ...pageOptions,
      pageSize: pageOptions.pageSize + 10,
    }));
    debounced(async () => {
      const { result, status } = await Api[method](
        endpoint,
        hasPayload && {
          ...defaultFilter,
          ...pageOptions,
          search: inputValue,
        }
      );
      setLoading(false);
      setOptions(get(result, 'list', []));
    }, 500);
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
      setPageOptions(PAGING_OPTIONS);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setPageOptions(PAGING_OPTIONS);
      }}
      value={value}
      onChange={(event, newValue) => setValue(newValue)}
      renderInput={
        renderInput ||
        ((params) => (
          <TextField
            {...params}
            label={label}
            error={!!error}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        ))
      }
      ListboxProps={{
        style: { maxHeight: 330 },
        onScroll: (event) => {
          const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
          const scroll = scrollHeight - scrollTop - clientHeight;

          if (scroll < 1) loadMoreResults();
        },
      }}
      renderOption={
        renderOption
          ? renderOption
          : (option) => (nameKey ? option[nameKey] : option.fullName)
      }
      popupIcon={<ExpandMore />}
      PaperComponent={({ children, ...other }) => (
        <CustomPaper {...other}>{children}</CustomPaper>
      )}
      disabled={disabled}
      disableClearable={disableClearable}
    />
  );
};

export default AsyncAutoComplete;
