import stationDispatcher from './action';
import ModalContainerForm from './component/ModalContainerForm';
import { COLUMN_STATIONS } from './constants';
import {
  AddIcon,
  CreateNewIcon,
  DeleteModalIcon,
  RemoveIcon,
} from '@/assets/svg';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { debounce } from 'lodash';
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const Stations = () => {
  const { list: data, paging, loading } = useSelector((state) => state.station);

  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({});

  const [openDrawer, setOpenDrawer] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    filterOptionData = filterOption
  ) => {
    stationDispatcher.getData(
      search,
      pagingData,
      ...[filterOptionData].filter((i) => i)
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize,
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  const onDeleteStation = () => {
    stationDispatcher.deleteStation(selectedItem.id, () => {
      stationDispatcher.getData(searchKey, {
        pageIndex: 0,
        pageSize: paging.pageSize,
      });
      setDeleteModal(false);
    });
  };

  useEffect(() => {
    debounceLoadData(searchKey, paging, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, filterOption]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {COLUMN_STATIONS.map((item) => (
          <TableCell
            key={item.stateValue || item.label}
            style={{ minWidth: item.minWidth, width: item.width }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (row) => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.name} />
        </TableCell>
        <TableCell>{row.totalItems}</TableCell>
        <TableCell>{row.totalTest}</TableCell>
        <TableCell>{row.totalMeasurement}</TableCell>
        <TableCell>{row.totalProcedure}</TableCell>
        <TableCell>{row.totalRadiology}</TableCell>
        <TableCell>{row.totalVaccination}</TableCell>
        <TableCell>{row.totalReview}</TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              setIsCreate(false);
              setOpenDrawer(true);
              setSelectedItem(row);
            }}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeleteModal(true);
              setSelectedItem(row);
            }}
          >
            <RemoveIcon />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          style: {
            width: '50%',
            minWidth: 768,
          },
        }}
        disableBackdropClick
      >
        <ModalContainerForm
          loading={loading}
          selectedItem={selectedItem}
          isCreate={isCreate}
          onClose={() => {
            setOpenDrawer(false);
            fetchData();
          }}
        />
      </Drawer>
      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder="Search by station name"
            title="Stations"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            fetchData={fetchData}
            renderButton={() => (
              <Button
                color="primary"
                variant="contained"
                startIcon={<CreateNewIcon />}
                onClick={() => {
                  setOpenDrawer(true);
                  setIsCreate(true);
                }}
              >
                Create new
              </Button>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          noDataHelperText="No station"
          noDataHelperSubText="Please create a new station."
          totalCols={COLUMN_STATIONS.length}
          fetchData={fetchData}
          style={{ height: 'calc(100vh - 207px)' }}
        />
      </CustomPaperContainer>

      {deleteModal && (
        <ModalConfirmation
          confirmLabel="Delete"
          mainContent="Delete station"
          subContent="Are you sure you want to delete this station ?"
          open={deleteModal}
          confirmColor="red"
          Icon={<DeleteModalIcon />}
          onClose={() => setDeleteModal(false)}
          onClick={onDeleteStation}
        />
      )}
    </>
  );
};

export default Stations;
