import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  title: {
    fontSize: 18,
    fontFamily: 'Lato',
    fontWeight: 600,
    color: '#333'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  }
});

const CustomDialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles(theme => ({
  paper: {
    borderRadius: 16,
    minWidth: 400
  },
  paperWidthXs: {
    maxWidth: 400
  }
}))(Dialog);

const CustomButton = withStyles({
  root: {
    width: 'auto',
    minWidth: 160,
    height: 40,
    borderRadius: 8,
    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset',
    marginLeft: 15
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const CustomDialogActions = withStyles({
  root: {
    margin: '0 0 10px 0',
    border: '1px solid #f1f1f1',
    padding: '20px ',
    display: 'block',
    '& .custom-select': {
      margin: 0,
      '&:disabled': {
        background: '#f6f6f6'
      }
    }
  }
})(DialogActions);

const CustomDialogFooter = withStyles({
  root: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginBottom: 10
  }
})(DialogActions);

const CustomModalWithForm = ({
  maxWidth = 'md',
  open,
  onClose,
  title,
  children,
  footerButtons,
  width,
  disabled = false
}) => {
  return (
    <CustomDialog
      maxWidth={maxWidth}
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
    >
      {title && (
        <CustomDialogTitle onClose={onClose}>{title}</CustomDialogTitle>
      )}

      <CustomDialogActions style={{ paddingBottom: !!title ? 16 : 40, width }}>
        {children}
      </CustomDialogActions>
      <CustomDialogFooter>
        {footerButtons.map((item, index) => (
          <CustomButton
            key={index}
            onClick={item.action}
            variant={item.variant}
            color="primary"
            disabled={disabled}
          >
            {item.name}
          </CustomButton>
        ))}
      </CustomDialogFooter>
    </CustomDialog>
  );
};

export default CustomModalWithForm;
