import CustomModalTitle from './CustomModalTitle';
import teleQueueRoomDispatcher from '@/module/telecounsel-queue/action';
import CustomButton from '@/new-components/CustomButton';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 400,
  },
})(Dialog);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end',
  },
})(DialogActions);

const CustomTitleSubLabel = withStyles({
  root: {
    fontWeight: 600,
    color: '#666666',
  },
})(Typography);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#999999',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
  },
})(TextField);

const ModalEditInvoice = ({
  open,
  onClose,
  onSubmit,
  selectedItem,
  loading,
}) => {
  const [formValue, setFormValue] = useState({
    counsellingFee: '',
    deduction: '',
    addition: '',
  });

  useEffect(() => {
    if (selectedItem?.consultId) {
      teleQueueRoomDispatcher.getTeleCounsellingById(
        selectedItem.consultId,
        (data) => {
          setFormValue({
            duration: data.duration,
            remark: data.remark,
            counsellingType: selectedItem.counsellingType,

            counsellingFee: data.counsellingFee,
            deduction: data.discountAmount,
            addition: data.additionalCost,
          });
        }
      );
    }
  }, []);

  const handleChangeForm = (key) => (e) => {
    setFormValue({ ...formValue, [key]: Number(e.value) });
  };

  const handleSubmit = async () => {
    // try {
    //   await validateData('editCounsellingPaymentSchema', formValue, () => {
    //
    //     onSubmit(selectedItem.id, formValue);
    //     setErrors({});
    //   });
    // } catch (errs) {
    //   setErrors(errs);
    // }
    onSubmit(selectedItem.consultId, formValue);
  };

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
      maxWidth="lg"
    >
      <CustomModalTitle onClose={onClose}>Edit invoice </CustomModalTitle>
      <CustomDialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomTitleSubLabel>Counselling fee</CustomTitleSubLabel>
            <NumberFormat
              fullWidth
              customInput={CustomTextField}
              variant="outlined"
              placeholder="0"
              onValueChange={handleChangeForm('counsellingFee')}
              allowNegative={false}
              value={formValue.counsellingFee || ''}
              decimalScale={2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTitleSubLabel>Deduction</CustomTitleSubLabel>
            <NumberFormat
              fullWidth
              customInput={CustomTextField}
              variant="outlined"
              placeholder="0"
              onValueChange={handleChangeForm('deduction')}
              allowNegative={false}
              value={formValue.deduction || ''}
              decimalScale={2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTitleSubLabel>Addition</CustomTitleSubLabel>
            <NumberFormat
              fullWidth
              customInput={CustomTextField}
              variant="outlined"
              placeholder="0"
              onValueChange={handleChangeForm('addition')}
              allowNegative={false}
              value={formValue.addition || ''}
              decimalScale={2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </CustomDialogContent>

      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          Save
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

ModalEditInvoice.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedItem: PropTypes.object,
};

ModalEditInvoice.defaultProps = {
  open: false,
  onClose: () => console.warn('Not implemented yet'),
  onSubmit: () => console.warn('Not implemented yet'),
  selectedItem: null,
};

export default ModalEditInvoice;
