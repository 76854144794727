import authDispatcher from '../../action/auth';
import { CloseCircle, HideIcon, LogoSmall, UnHideIcon } from '@/assets/svg';
import { urlLabel } from '@/enum/PermissionEnum';
import validateData from '@/helpers/validationHelpers/validationSchema';
import { ENTER_KEY } from '@/module/common';
import CustomLoadingButton from '@/new-components/CustomLoadingButton';
import ModalConfirmationMobile from '@/new-components/CustomModal/ModalConfirmationMobile';
import customToast from '@/new-components/CustomNotification';
import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';

const CustomLink = withStyles({
  root: {
    fontWeight: 600,
    color: '#AD5E99',
    cursor: 'pointer',
  },
})(Typography);

const CustomTypography = withStyles({
  root: {
    color: '#666666',
  },
})(Typography);

const CustomTitle = withStyles({
  root: {
    fontSize: 32,
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#191919',
  },
})(Typography);

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,
    fontWeight: 600,
  },
})(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
  },
})(TextField);

const PatientLogin = ({ history }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showLoginLoading, setLoginLoading] = useState(false);
  const [showNoticeModal, setShowNoticeModal] = useState(false);
  const [loginInfo, setLoginInfo] = useState(false);

  const [errors, setErrors] = useState({});

  const [formValue, setFormValue] = useState({
    email: '',
    password: '',
  });

  const handleChangeForm = (key) => (e) => {
    setFormValue({ ...formValue, [key]: get(e, 'target.value') });
  };

  const handleLogin = async () => {
    const { email, password } = formValue;

    try {
      setLoginLoading(true);
      const correctEmail = email.trim();

      await validateData(
        'loginSchema',
        { email: correctEmail, password },
        () => {
          setErrors({});

          authDispatcher.loginPatient(
            correctEmail,
            password,
            (result, email) => {
              if (!email) {
                setLoginLoading(false);
                return customToast('error', result);
              } else {
                setLoginLoading(false);
                setLoginInfo(result);
                setShowNoticeModal(true);
                // authDispatcher.loginSuccess(result, email);
                // history.push(`/${urlLabel.teleconsultNow}`);
              }
            }
          );
        }
      );
    } catch (errs) {
      setErrors(errs);
      setLoginLoading(false);
    }
  };

  const goForgotPassword = () => {
    history.push(`/${urlLabel.patientForgotPassword}`);
  };
  const goRegister = () => {
    history.push(`/${urlLabel.patientRegister}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p={2.5}
      height={'100vh'}
    >
      <Box>
        <Box mb={5}>
          <LogoSmall />
        </Box>

        <Box textAlign="center">
          <CustomTitle>Sign in</CustomTitle>

          <form>
            <Box textAlign="left" mt={5}>
              <CustomInputLabel>Email</CustomInputLabel>
              <CustomTextField
                fullWidth
                variant="outlined"
                placeholder="email@address.com"
                autoComplete="email"
                value={formValue.email || ''}
                onChange={handleChangeForm('email')}
                error={!!errors.email}
                helperText={errors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{
                          visibility: !isEmpty(formValue.email)
                            ? 'visible'
                            : 'hidden',
                        }}
                        onClick={() =>
                          setFormValue({ ...formValue, email: '' })
                        }
                      >
                        <CloseCircle
                          height={20}
                          width={20}
                          style={{
                            color: '#666666',
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <CustomInputLabel style={{ marginTop: 24 }}>
                Password
              </CustomInputLabel>
              <CustomTextField
                fullWidth
                variant="outlined"
                placeholder="12345678a"
                autoComplete="password"
                value={formValue.password || ''}
                onChange={handleChangeForm('password')}
                type={showPassword ? '' : 'password'}
                error={!!errors.password}
                helperText={errors.password}
                onKeyDown={(e) => e.keyCode === ENTER_KEY && handleLogin()}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            visibility: !isEmpty(formValue.password)
                              ? 'visible'
                              : 'hidden',
                          }}
                          onClick={() =>
                            setFormValue({ ...formValue, password: '' })
                          }
                        >
                          <CloseCircle
                            height={20}
                            width={20}
                            style={{
                              color: '#666666',
                            }}
                          />
                        </IconButton>
                      </InputAdornment>

                      <InputAdornment position="start">
                        <>
                          <HideIcon
                            style={{ cursor: 'pointer' }}
                            hidden={showPassword}
                            onClick={() => setShowPassword(true)}
                          />
                          <UnHideIcon
                            style={{ cursor: 'pointer' }}
                            hidden={!showPassword}
                            onClick={() => setShowPassword(false)}
                          />
                        </>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            </Box>

            <CustomLoadingButton
              onClick={handleLogin}
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                showLoginLoading ||
                isEmpty(formValue.email) ||
                isEmpty(formValue.password)
              }
              loading={showLoginLoading}
              style={{ marginTop: 24, width: '100%' }}
            >
              Sign in
            </CustomLoadingButton>

            <CustomLink style={{ marginTop: 24 }} onClick={goForgotPassword}>
              Forgot your password?
            </CustomLink>
          </form>
        </Box>
      </Box>
      <Box display="flex" gridGap={6} alignSelf="center" py={3}>
        <CustomTypography>Don't have an account?</CustomTypography>
        <CustomLink onClick={goRegister}>Sign up</CustomLink>
      </Box>
      {showNoticeModal && (
        <ModalConfirmationMobile
          open={showNoticeModal}
          onClose={() => setShowNoticeModal(false)}
          onClick={() => {
            authDispatcher.loginSuccess(loginInfo, formValue.email);
            setShowNoticeModal(false);
            setLoginLoading(false);
            history.push(`/${urlLabel.teleconsultNow}`);
          }}
          title={
            <Box
              height={40}
              width="100%"
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <LogoSmall style={{ position: 'absolute', left: 0 }} />
              <CustomTitle style={{ fontSize: 18 }}>Notice</CustomTitle>
            </Box>
          }
          subContent={
            <Box color="#292929" fontSize={16} px={2.5} mt={8} textAlign="left">
              <p>
                Telemedicine is suitable for non-emergency conditions. There are
                limitations and the consult may be cancelled without charge.
              </p>
              <p>
                Find out more in our{' '}
                <a
                  className="custom-link"
                  target="blank"
                  href="https://minmed.sg/teleconsult-doctor/#faq"
                >
                  FAQs
                </a>
                . Please proceed to the nearest Accident and Emergency facility
                if you need urgent medical attention.
              </p>
            </Box>
          }
        />
      )}
    </Box>
  );
};

export default PatientLogin;
