import * as loadingAnimation from '../../assets/animation/loading.json';
import LottieControl from '../../components/LottieControl';
import ModalPresenter from '../../components/ModalPresenter';
import StreamPanel from '../../components/StreamPanel';
import { doctorStatus } from '../../enum/RequestEnum';
import {
  setCurrentUserId,
  getListChannels,
  listenChannels,
} from '../../firebase';
import {
  rejoinCall,
  joinCall,
  rejectPatient,
  initHubConnection,
} from '../../helpers/waitingRoomSignalR';
import RejoinCallPaper from './RejoinCallPaper';
import VerifyID from './VerifyID';
import WaitingItem from './WaitingItem';
import waitingRoomDispatcher from './action/waitingRoom';
import { ROLES_NAMES_SPECIAL } from '@/constants/roles';
import { Typography, Dialog, Modal } from '@material-ui/core';
import {
  CheckCircleRounded,
  CancelRounded,
  CallEndRounded,
} from '@material-ui/icons';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

const WaitingRoom = () => {
  let waitingList = useSelector((state) => state.waitingRoom.waitingList);
  const calling = useSelector((state) => state.waitingRoom.calling);
  const videoChannelId = useSelector((state) => state.waitingRoom.channelId);
  const patientId = useSelector((state) => state.waitingRoom.patientId);
  const currentUser = useSelector((state) => state.auth.userInfo);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const isReconnectRejoinCall = useSelector(
    (state) => state.waitingRoom.reconnectRejoinCall
  );
  const consConfirmVisibility = useSelector(
    (state) => state.waitingRoom.consConfirmVisibility
  );
  const openModal = useSelector(
    (state) => state.waitingRoom.verifyModalVisibility
  );
  const verifyingUserData = useSelector(
    (state) => state.waitingRoom.verifyingUserData
  );
  const waitingModalVisibility = useSelector(
    (state) => state.waitingRoom.waitingModalVisibility
  );

  const rejoinCallData = useSelector(
    (state) => state.waitingRoom.rejoinCallData
  );
  const { roleName } = useSelector((state) => state.auth.userInfo);

  const [listChannels, setListchannels] = useState([]);
  const listChannelsRef = useRef();
  listChannelsRef.current = listChannels;
  const [partner, setPartner] = useState(null);
  const [rejectModal, setRejectModal] = useState(false);
  const [endModal, setEndModal] = useState(false);

  const orderWaitingList = useMemo(() => {
    let newList = [];
    newList = [...waitingList].sort(
      (a, b) => moment(a?.JoinedTime).unix() - moment(b?.JoinedTime).unix()
    );
    return newList;
  }, [waitingList]);

  useEffect(() => {
    if (currentUser.id) {
      initFirebase();
    }
    if (
      ROLES_NAMES_SPECIAL.includes(currentUser.roleName) &&
      currentUser.approveStatus !== doctorStatus.Approved
    )
      return;

    setTimeout(() => initHubConnection(accessToken, currentUser.id), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.id]);

  const doVerify = ({ data }) => {
    waitingRoomDispatcher.getMemberAppointment(data?.UserId, () => {
      waitingRoomDispatcher.setState('verifyModalVisibility', true);
      waitingRoomDispatcher.setState('verifyingUserData', data);
    });
  };

  const doResetVerifyData = () => {
    waitingRoomDispatcher.setState('verifyModalVisibility', false);
    // waitingRoomDispatcher.setState('verifyingUserData', {});
  };

  const doCalling = async ({ data }) => {
    waitingRoomDispatcher.setState('waitingModalVisibility', true);
    waitingRoomDispatcher.setState('verifyingUserData', data);
    await joinCall(data.UserId);
  };

  const initFirebase = () => {
    setCurrentUserId(currentUser.id, currentUser);
    updateListChannels();
  };

  const updateListChannels = async () => {
    let listChannels = await getListChannels(currentUser.id);
    listenChannels(currentUser.id, (data) => {
      if (
        [...listChannelsRef.current].find((channel) => channel.id === data.id)
      )
        setListchannels(
          [...listChannelsRef.current].map((i) => (i.id === data.id ? data : i))
        );
      else {
        setListchannels([...listChannelsRef.current, data]);
      }
    });
    setListchannels(listChannels);
  };

  const getPatientChannel = () => {
    return listChannels.find((channel) =>
      channel?.members?.includes(partner.id)
    );
  };

  return (
    <div>
      <Modal open={!!rejectModal} onClose={() => setRejectModal(false)}>
        <div>
          <ModalPresenter
            onClose={() => setRejectModal(false)}
            Icon={<CancelRounded style={{ fontSize: 80, color: '#EA6B75' }} />}
            title="Reject Patient"
            onClick={() => {
              setRejectModal(false);
              rejectPatient(verifyingUserData.UserId);
            }}
          >
            <Typography>
              Are you sure you want to reject
              <Typography style={{ fontWeight: 600 }} display={'inline'}>
                {verifyingUserData.fullName}
              </Typography>
              ’s profile.
            </Typography>
            <Typography>Click “Continue” to reject</Typography>
          </ModalPresenter>
        </div>
      </Modal>
      <Modal open={!!waitingModalVisibility}>
        <div>
          <ModalPresenter
            style={{
              padding: 30,
              paddingLeft: 50,
              paddingRight: 50,
            }}
            showFooter={false}
            onClose={() => {}}
            Icon={
              <LottieControl
                animationData={loadingAnimation}
                width={80}
                height={80}
              />
            }
            title="Waiting for Acceptance"
            onClick={() => {}}
          >
            <Typography>Please wait for patient to accept an</Typography>
            <Typography>appointment with you.</Typography>
          </ModalPresenter>
        </div>
      </Modal>
      <Modal
        open={!!consConfirmVisibility && !openModal}
        onClose={() =>
          waitingRoomDispatcher.setState('consConfirmVisibility', false)
        }
      >
        <div>
          <ModalPresenter
            confirmLabel="Complete"
            onClose={() => {
              waitingRoomDispatcher.setState('consConfirmVisibility', false);
              waitingRoomDispatcher.cancelConsult();
            }}
            Icon={
              <CheckCircleRounded style={{ fontSize: 80, color: '#62CAA4' }} />
            }
            title="Consult Complete?"
            onClick={() => {
              waitingRoomDispatcher.completeConsult();
              waitingRoomDispatcher.setState('consConfirmVisibility', false);
            }}
          >
            <Typography>
              If you have given notes and medication for the consult,
            </Typography>
            <Typography>please click ‘Complete’ to proceed.</Typography>
          </ModalPresenter>
        </div>
      </Modal>
      <Modal open={!!endModal} onClose={() => setEndModal(false)}>
        <div>
          <ModalPresenter
            onClose={() => setEndModal(false)}
            Icon={<CallEndRounded style={{ fontSize: 80, color: '#EA6B75' }} />}
            title="End Call"
            onClick={() => {
              rejoinCall(false);
              setEndModal(false);
              waitingRoomDispatcher.setState('rejoinCallData', null);
            }}
          >
            <Typography>Click “Continue” to end call</Typography>
          </ModalPresenter>
        </div>
      </Modal>
      {rejoinCallData &&
        ROLES_NAMES_SPECIAL.includes(currentUser.roleName) &&
        currentUser?.approveStatus === doctorStatus.Approved && (
          <RejoinCallPaper
            rejoinCallData={rejoinCallData}
            onJoin={(data) => {
              rejoinCall();
              waitingRoomDispatcher.setState('patientId', data.PatientId);
              waitingRoomDispatcher.setState('verifyingUserData', data);
              setPartner({
                id: data.PatientId,
                name: `${data.PatientFullName}`,
                imageUrl: null,
              });
            }}
            isReconnectRejoinCall={isReconnectRejoinCall}
            onCancel={() => setEndModal(true)}
          />
        )}
      {isEmpty(rejoinCallData) &&
        orderWaitingList.map((l) => (
          <WaitingItem
            roleName={roleName}
            key={l.ConnectionId}
            data={l}
            doVerify={doVerify}
            doCalling={doCalling}
            doResetVerifyData={doResetVerifyData}
            setPartner={setPartner}
          />
        ))}
      {calling && (
        <StreamPanel
          patientId={patientId}
          videoChannelId={videoChannelId}
          currentUser={currentUser}
          channelInfo={getPatientChannel()}
          partner={partner}
          updateListChannels={updateListChannels}
          verifyingUserData={verifyingUserData}
          doVerify={doVerify}
        />
      )}
      <Dialog
        open={!!openModal}
        onClose={() =>
          waitingRoomDispatcher.setState('verifyModalVisibility', false)
        }
        scroll="body"
        fullWidth
        maxWidth="lg"
      >
        <VerifyID
          setRejectModal={setRejectModal}
          verifyingUserData={verifyingUserData}
          doCalling={doCalling}
          closeModal={(shouldCleanUp) => {
            waitingRoomDispatcher.setState('verifyModalVisibility', false);
            if (shouldCleanUp) {
              // clean up memberAppointment
              waitingRoomDispatcher.setState('memberAppointment', null);
            }
          }}
          setVerifyingUserData={(data) =>
            waitingRoomDispatcher.setState('verifyingUserData', data)
          }
        />
      </Dialog>
    </div>
  );
};

export default WaitingRoom;
