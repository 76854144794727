import React, { useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { ENTER_KEY } from '../../../common';
import styled from 'styled-components';
import { StyledButton } from '../../../common/componentUI/commonStyleComponents';
import { EmailIcon } from '../../../../assets/svg';
import authDispatcher from '../../action/auth';

import validateData from '@/helpers/validationHelpers/validationSchema';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    try {
      await validateData('forgotPasswordSchema', { email }, ({ email }) => {
        authDispatcher.sendResetPasswordEmail(email);
      });
    } catch (errors) {
      setError(errors?.email || '');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 348,
        width: 380,
        borderRadius: 10,
        backgroundColor: 'white',
        padding: '40px 20px'
      }}
    >
      <Typography
        style={{
          fontSize: 24,
          letterSpacing: 0,
          fontWeight: 600
        }}
      >
        Forgot Password?
      </Typography>
      <Typography
        style={{
          fontSize: 14,
          letterSpacing: 0,
          color: '#8F9BA9',
          marginTop: 20
        }}
      >
        Please enter the email registered to received the reset password link.
      </Typography>
      <div
        style={{
          display: 'flex',
          marginTop: 30,
          marginBottom: 60,
          alignItems: 'center',
          height: 40
        }}
      >
        <EmailIcon style={{ marginRight: 13, marginTop: 30 }} />
        <TextFieldStyle
          error={!!error}
          helperText={error}
          name="Email"
          margin="normal"
          label="Email"
          autoComplete="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          onKeyDown={e => e.keyCode === ENTER_KEY && handleSubmit()}
        />
      </div>
      <StyledButton onClick={handleSubmit}>Send Link</StyledButton>
    </div>
  );
};

const TextFieldStyle = styled(TextField)`
  height: 40px;
  width: 308px;
`;

export default ForgotPassword;
