import { CloseIcon } from '@/assets/svg';
import { withStyles, Box, Typography, IconButton } from '@material-ui/core';
import React from 'react';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    border: '1px solid #f1f1f1',
  },
  title: {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: 18,
    color: '#333',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500],
  },
});
const CustomModalTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, styleButton, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          style={styleButton}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

export default CustomModalTitle;
