import deletionRequestDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  }
};

const deletionRequestReducer = deletionRequestDispatcher(initialState, {
  [deletionRequestDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [deletionRequestDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value
  }),
  [deletionRequestDispatcher.resetData]: (state, _) => ({
    ...initialState
  })
});

export default deletionRequestReducer;
