import React from 'react';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  ClipboardIcon,
  TimeIcon,
  MapIcon,
  LayersIcon,
  MedkitIcon,
  ShieldCheckmarkIcon,
  MegaphoneIcon,
  AppIcon
} from '@/assets/svg';
import { useHistory } from 'react-router-dom';
import { urlLabel } from '@/enum/PermissionEnum';

const CustomCard = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    minHeight: 112,
    cursor: 'pointer',

    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',

    '& .card-icon': {
      width: 40,
      height: 40,
      color: theme.palette.grey.subText
    },

    '& .card-text': {
      fontSize: 16,
      fontFamily: 'OpenSans',
      fontWeight: 600
    },

    '&:hover': {
      '& .card-icon': {
        color: theme.palette.primary.main
      },

      '&.MuiPaper-outlined': {
        border: `1px solid ${theme.palette.primary.main}`
      }
    }
  }
}))(Card);

const CustomHeader = withStyles(theme => ({
  root: {
    marginBottom: 20,
    color: theme.palette.grey.mainText,
    fontSize: 20,
    fontFamily: 'Lato',
    fontWeight: 700
  }
}))(Typography);

const Configuration = () => {
  const history = useHistory();
  const handleClickTab = key => {
    switch (key) {
      case 'timeslotTemplate':
        history.push(`/${urlLabel.configTimeslotTemplate}`);
        break;
      case 'consentForm':
        history.push(`/${urlLabel.configConsentForm}`);
        break;
      case 'lifestyleSurvey':
        history.push(`/${urlLabel.configLifestyleSurvey}`);
        break;
      case 'packages':
        history.push(`/${urlLabel.configPackages}`);
        break;
      case 'profiles':
        history.push(`/${urlLabel.configProfiles}`);
        break;
      case 'otherServices':
        history.push(`/${urlLabel.configOtherServices}`);
        break;
      case 'stations':
        history.push(`/${urlLabel.configStations}`);
        break;
      case 'motherhood':
        history.push(`/${urlLabel.configMotherhood}`);
        break;

      default:
        break;
    }
  };

  const LIST_CONFIGURATION = [
    {
      title: 'Templates',
      children: [
        {
          key: 'timeslotTemplate',
          title: 'Timeslot template',
          icon: TimeIcon,
          onClick: handleClickTab
        },
        {
          key: 'consentForm',
          title: 'Consent form',
          icon: ShieldCheckmarkIcon,
          onClick: handleClickTab
        },
        {
          key: 'lifestyleSurvey',
          title: 'Lifestyle survey',
          icon: ClipboardIcon,
          onClick: handleClickTab
        }
      ]
    },
    {
      title: 'Services',
      children: [
        {
          key: 'packages',
          title: 'Packages',
          icon: MedkitIcon,
          onClick: handleClickTab
        },
        {
          key: 'profiles',
          title: 'Profiles',
          icon: LayersIcon,
          onClick: handleClickTab
        },
        {
          key: 'otherServices',
          title: 'Other services',
          icon: AppIcon,
          onClick: handleClickTab
        },
        {
          key: 'stations',
          title: 'Stations',
          icon: MapIcon,
          onClick: handleClickTab
        }
      ]
    },
    {
      title: 'Reports',
      children: [
        {
          key: 'motherhood',
          title: 'Motherhood statements',
          icon: MegaphoneIcon,
          onClick: handleClickTab
        }
      ]
    }
  ];

  return (
    <CustomPaperContainer title="Configuration">
      <Box p={3} borderTop="1px solid #f1f1f1">
        {LIST_CONFIGURATION.map(row => (
          <Box key={row.title} mb={3}>
            <CustomHeader>{row.title}</CustomHeader>
            <Grid container spacing={3}>
              {row.children.map(child => {
                const Icon = child.icon;
                return (
                  <Grid
                    item
                    lg={3}
                    xs={6}
                    key={child.key}
                    onClick={() => child.onClick(child.key)}
                  >
                    <CustomCard variant="outlined">
                      <Icon className="card-icon" />
                      <Typography className="card-text">
                        {child.title}
                      </Typography>
                    </CustomCard>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ))}
      </Box>
    </CustomPaperContainer>
  );
};

export default Configuration;
