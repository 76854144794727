import React, { useState, useEffect, useMemo } from 'react';
import {
  Checkbox,
  Grid,
  FormControlLabel,
  Chip,
  Card
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import { packageDispatcher } from '@/module/package-management';
import { isEmpty } from 'lodash';
import { StyledAccordion } from '@/new-components/CustomCollapseCard/styled';
import { formatData } from '@/module/package-management/utils';
import { formatTotalPrice } from '@/module/checkin-patient-information/util';
import CustomTooltip from '@/new-components/CustomTooltip';
import { UncheckCircleIcon } from '@/assets/svg';
import Box from '@material-ui/core/Box';
import { returnOptionalPackageTests } from '../../utils';

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 16,
    color: '#333333',
    fontWeight: 600
  }
})(InputLabel);

const CustomBox = withStyles({
  root: {
    marginTop: 4,
    marginBottom: 4,
    '&last-child': {
      marginBottom: 0
    }
  }
})(Box);

const CustomPrice = withStyles({
  root: {
    color: '#333333',
    fontWeight: 600,
    fontSize: 16,

    '&.free': {
      fontSize: 14,
      color: '#999999'
    }
  }
})(Typography);
const CustomCard = withStyles(theme => ({
  root: {
    marginTop: 8,
    marginBottom: 8,
    padding: '0 16px',
    height: 48,
    width: props => (props.width ? props.width : 'unset'),

    boxShadow: 'none',
    borderRadius: 12,
    backgroundColor: props => (props.color ? props.color : '#FFFFFF'),
    border: `1px solid #e1e1e1`
  }
}))(Card);

const itemStyle = makeStyles({
  iconUncheck: {
    margin: 1,
    width: 16,
    height: 16,
    color: '#F1F1F1',
    borderRadius: '50%'
  },
  iconChecked: {
    width: 20,
    height: 20,
    color: '#0B8E5E'
  },
  formControl: {
    pointerEvents: 'none'
  }
});
const CustomItem = ({ data, availableData, title }) => {
  const classes = itemStyle();

  return (
    !isEmpty(data) && (
      <>
        <Grid item xs={12} style={{ marginBottom: 8 }}>
          {title}
        </Grid>
        <Grid container item xs={12} style={{ marginBottom: 16 }}>
          {availableData.map(item => (
            <Grid item xs={6} key={item.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.isChecked}
                    color="primary"
                    icon={<UncheckCircleIcon className={classes.iconUncheck} />}
                    checkedIcon={
                      <CheckCircleIcon className={classes.iconChecked} />
                    }
                    name={item.name}
                    style={{ padding: '4px 8px' }}
                  />
                }
                label={<CustomTooltip content={item.name} />}
                className={classes.formControl}
              />
            </Grid>
          ))}
        </Grid>
      </>
    )
  );
};

const RowItem = ({ children, price }) => {
  return (
    <CustomBox
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography>{children}</Typography>
      <CustomPrice className={price || price > 0 ? '' : 'free'}>
        {!price ? 'Free' : formatTotalPrice(price, '$')}
      </CustomPrice>
    </CustomBox>
  );
};

const PackageItem = ({
  listData,
  ignoredTests = [],
  ignoredProfiles,
  isRegisterPatient,
  isFollowUp,
  dataInfor,
  restrictedTestIds
}) => {
  const [packageDetail, setPackageDetail] = useState({
    name: '',
    packageType: '',
    lstTests: [],
    lstProfiles: [],
    lstMeasurements: [],
    lstProcedures: [],
    lstRadiologies: [],
    lstVaccinations: [],
    lstReviews: []
  });

  const [expanded, setExpanded] = useState('panel');

  const [profiles, setProfiles] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [radiologies, setRadiologies] = useState([]);
  const [lstOptionalTests, setLstOptionalTests] = useState([]);
  const [hasOffSiteTest, setHasOffSiteTest] = useState(true);

  const availableProcedures = useMemo(
    () => procedures.filter(item => !restrictedTestIds.includes(item.id)),
    [procedures, restrictedTestIds]
  );
  const availableRadiologies = useMemo(
    () => radiologies.filter(item => !restrictedTestIds.includes(item.id)),
    [radiologies, restrictedTestIds]
  );

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const packageId = listData.values().next().value?.id;
    const pricePackage = listData.values().next().value?.price;
    const doneFollowUpTests = dataInfor?.doneFollowUpTests;
    let lstOptionalTests = [];
    let newTotalMandatoryProcedure = 0;
    let newTotalMandatoryRadiology = 0;

    packageDispatcher.getPackageById(packageId, result => {
      const {
        lstTests,
        lstProfiles,
        totalMandatoryProcedure,
        totalMandatoryRadiology,
        totalMandatoryProfile
      } = result;
      const newData = formatData(result);
      let lstProcedures = [];
      let lstRadiologies = [];
      let newListTest = [];
      if (isFollowUp && !isEmpty(doneFollowUpTests)) {
        newListTest = lstTests.map(item => {
          const holdDoneFollowUpTests = doneFollowUpTests.filter(
            it => it.category === item.category && !it.isAddon
          );

          return {
            ...item,
            items: item.items.filter(
              test =>
                holdDoneFollowUpTests.findIndex(
                  item => item.testId === test.id
                ) === -1
            )
          };
        });
      } else newListTest = lstTests;

      lstTests.forEach(item => {
        if (item.category === 'Procedures') lstProcedures = item.items;
        if (item.category === 'Radiologies') lstRadiologies = item.items;
      });

      // Filter test restricted by location
      // if (!isEmpty(restrictedTestIds)) {
      //   lstProcedures = lstProcedures?.filter(
      //     it => !restrictedTestIds?.includes(it.id)
      //   );
      //   lstRadiologies = lstRadiologies?.filter(
      //     it => !restrictedTestIds?.includes(it.id)
      //   );
      // }

      const unMandatoryProcedures = [...lstProcedures]?.filter(
        it => !it.isRequired
      );
      const unMandatoryRadiologies = [...lstRadiologies]?.filter(
        it => !it.isRequired
      );

      if (isFollowUp) {
        lstOptionalTests = returnOptionalPackageTests({
          lstProcedures,
          lstRadiologies,
          ignoredTests
        });
      } else
        lstOptionalTests = returnOptionalPackageTests({
          lstProfiles,
          lstProcedures,
          lstRadiologies,
          ignoredProfiles,
          ignoredTests
        });

      const newProcedures = newData?.lstProcedures
        .filter(test => !test.isRequired)
        .map(item =>
          ignoredTests.every(test => {
            return item.id !== test.testId;
          })
            ? { ...item, isChecked: true }
            : item
        );
      const newRadiologies = newData.lstRadiologies
        .filter(test => !test.isRequired)
        .map(item =>
          ignoredTests.every(test => {
            return item.id !== test.testId;
          })
            ? { ...item, isChecked: true }
            : item
        );

      const newProfiles = lstProfiles
        .filter(test => !test.isRequired)
        .map(item =>
          ignoredProfiles.every(test => {
            return item.id !== test.profileId;
          })
            ? { ...item, isChecked: true }
            : item
        );

      // Test
      if (isFollowUp) {
        if (!isEmpty(doneFollowUpTests)) {
          // Procedure
          const holdDoneFollowUpTestsProcedures = unMandatoryProcedures.filter(
            it => doneFollowUpTests.find(item => item.testId === it.id)
          );
          // Radiologies
          const holdDoneFollowUpTestsRadiologies = unMandatoryRadiologies.filter(
            it => doneFollowUpTests.find(item => item.testId === it.id)
          );

          newTotalMandatoryProcedure =
            totalMandatoryProcedure - holdDoneFollowUpTestsProcedures.length;

          newTotalMandatoryRadiology =
            totalMandatoryRadiology - holdDoneFollowUpTestsRadiologies.length;
        } else {
          newTotalMandatoryProcedure = totalMandatoryProcedure;
          newTotalMandatoryRadiology = totalMandatoryRadiology;
        }
      } else {
        newTotalMandatoryProcedure = totalMandatoryProcedure;
        newTotalMandatoryRadiology = totalMandatoryRadiology;
      }

      if (
        (newTotalMandatoryProcedure === 0 &&
          newTotalMandatoryRadiology === 0) ||
        (unMandatoryProcedures.length === 0 &&
          unMandatoryRadiologies.length === 0)
      ) {
        setHasOffSiteTest(false);
      }

      setLstOptionalTests(lstOptionalTests);
      setProcedures(newProcedures);
      setRadiologies(newRadiologies);
      setProfiles(newProfiles);
      setPackageDetail({
        ...newData,
        status: listData[0].status,
        price: pricePackage,
        totalMandatoryProcedure,
        totalMandatoryRadiology,
        totalMandatoryProfile
      });
    });
  }, [dataInfor, listData, ignoredTests, ignoredProfiles, restrictedTestIds]);

  return (
    <Box px={2}>
      <CustomInputLabel>Package</CustomInputLabel>
      {hasOffSiteTest ? (
        <StyledAccordion
          expanded={expanded === 'panel'}
          onChange={handleChange('panel')}
          className={clsx(expanded === 'panel' ? 'expanded' : '')}
          style={{ border: '1px solid #e1e1e1' }}
        >
          <AccordionSummary
            aria-controls="panel-content"
            id="panel-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Box display="flex" alignItems="center" gridGap={8}>
              <Typography>{packageDetail?.name}</Typography>
              {packageDetail?.status && (
                <Chip
                  style={{
                    color: '#0B8E5E',
                    backgroundColor: 'rgba(21,164,112,0.16)'
                  }}
                  size="small"
                  label={packageDetail?.status}
                />
              )}
            </Box>

            <CustomPrice
              className={
                packageDetail?.price || packageDetail?.price > 0 ? '' : 'free'
              }
            >
              {packageDetail?.price || packageDetail?.price > 0
                ? formatTotalPrice(packageDetail?.price, '$')
                : 'Free'}
            </CustomPrice>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {isRegisterPatient ? (
                !isEmpty(lstOptionalTests) && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        style={{ fontSize: 12, fontWeight: 600 }}
                        color="textSecondary"
                        gutterBottom
                      >
                        Optional tests
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        {lstOptionalTests.map(test => (
                          <RowItem key={test.id} price={test.price}>
                            {test.name}
                          </RowItem>
                        ))}
                      </Box>
                    </Grid>
                  </>
                )
              ) : (
                <>
                  <CustomItem
                    data={profiles}
                    availableData={profiles}
                    title={`Profiles (Choose only ${packageDetail.totalMandatoryProfile} tests)`}
                  />
                  <CustomItem
                    data={procedures}
                    availableData={availableProcedures}
                    title={`Procedures (Choose only ${packageDetail.totalMandatoryProcedure} tests)`}
                  />
                  <CustomItem
                    data={radiologies}
                    availableData={availableRadiologies}
                    title={`Radiologies (Choose only ${packageDetail.totalMandatoryRadiology} tests)`}
                  />
                </>
              )}
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      ) : (
        <CustomCard>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={1.5}
          >
            <Box display="flex" alignItems="center" gridGap={8}>
              <Typography>{packageDetail?.name}</Typography>
              {packageDetail?.status && (
                <Chip
                  style={{
                    color: '#0B8E5E',
                    backgroundColor: 'rgba(21,164,112,0.16)'
                  }}
                  size="small"
                  label={packageDetail?.status}
                />
              )}
            </Box>
            <CustomPrice
              className={
                packageDetail?.price || packageDetail?.price > 0 ? '' : 'free'
              }
            >
              {packageDetail?.price || packageDetail?.price > 0
                ? formatTotalPrice(packageDetail?.price, '$')
                : 'Free'}
            </CustomPrice>
          </Box>
        </CustomCard>
      )}
    </Box>
  );
};

export default PackageItem;
