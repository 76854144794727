import { createDownloadableLink, formatUTCDate } from '@/helpers';
import { synthesize } from 'redux-dispatcher';
import customToast from '@/new-components/CustomNotification';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, filterOption = {}, sortOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().backroomModule.paging;
    let { result, status } = await Api.post(`Backrooms/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption,
      startTime: filterOption?.startTime
        ? formatUTCDate(filterOption?.startTime, { isStarOfDay: true })
        : undefined,
      endTime: filterOption?.endTime
        ? formatUTCDate(filterOption?.endTime, { isEndOfDay: true })
        : undefined,
      ...sortOption
    });

    status === 200 && backroomModuleDispatcher.getDataSuccess(result);
  },
  resetData: () => ({}),
  getBackroomDetails: (id, callback) => async ({ Api }) => {
    const { result, status } = await Api.get(`Backrooms/${id}/detail`);
    status === 200 && callback && callback(result);
  },
  uploadBackroom: (id, data, callback) => async ({ Api }) => {
    const { status } = await Api.post(
      `Backrooms/${id}/upload-results`,
      data,
      '',
      undefined,
      true,
      true
    );

    if (status === 200) {
      customToast('success', 'Upload successs');
      callback && callback(true);
    }
  },
  downloadBackroom: (paxScreeningId, moduleId) => async ({ Api }) => {
    const { result, status } = await Api.get(
      `Backrooms/${paxScreeningId}/download-results/${moduleId}`
    );
    if (status === 200) {
      createDownloadableLink(result, moduleId, 'pdf');
      customToast('success', 'Download module successs');
    }
  },
  getLinkDownloadBackroom: (paxScreeningId, moduleId, callback) => async ({
    Api
  }) => {
    const { result, status } = await Api.get(
      `Backrooms/${paxScreeningId}/download-results/${moduleId}`
    );
    if (status === 200) callback && callback(result);
  }
};

const backroomModuleDispatcher = synthesize('backroomModule', mapDispatchToAC);

export default backroomModuleDispatcher;
