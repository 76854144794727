import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { ClearRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import {
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormHelperText
} from '@material-ui/core';
import styled from 'styled-components';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import { TYPE_MODAL, FORM_VALUE, LIST_REQUIRED } from '../constants';
import LoadingButton from '../../../components/LoadingButton';
import { UploadSingle } from '../../common/component';
import { AddImageOutlineIcon } from '../../../assets/svg';
import { uploadImage } from '../../../firebase';
import studioClassDispatcher from '../actions/index';
import { LocalizationProvider, DateTimePicker } from '@material-ui/pickers';
import MomentFns from '@material-ui/pickers/adapter/moment';
import moment from 'moment';
import './styles.scss';
import StudioClassDispatcher from '../actions/index';
import Autocomplete from '@material-ui/lab/Autocomplete';

const StudioClassFormPage = ({
  StudioClassDetail,
  typeModal,
  onCloseModal,
  reGetStudioClassList
}) => {
  const {
    loading,
    listCategoryStudioClass,
    listAvailableTrainer,
    listStudio,
    listIntensity,
    listDuration
  } = useSelector(state => state.studioClass);

  const [idSelected, setIdSelected] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [formValue, setFormValue] = useState(FORM_VALUE);

  useEffect(() => {
    if (typeModal === TYPE_MODAL.Edit) {
      getStudioClassById(StudioClassDetail.id);
      setIdSelected(StudioClassDetail.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStudioClassById = async id => {
    await StudioClassDispatcher.getStudioClassById(id, result => {
      setFormValue({ ...result, intensity: result.intensityValue });
    });
  };

  const handleChange = (data, field) => {
    if (field === 'categoryId') {
      const cateSelected = listCategoryStudioClass.find(
        item => item.id === data
      );
      setFormValue({
        ...formValue,
        categoryId: data,
        intensity: cateSelected.intensityValue,
        calories: cateSelected.calories,
        description: cateSelected.description,
        equipment: cateSelected.equipment
      });
    } else
      setFormValue({
        ...formValue,
        [field]: data
      });
  };

  const onCheckValidForm = () => {
    let valid = true;
    let errors = {};
    setIsSubmitted(true);

    LIST_REQUIRED.forEach(field => {
      if (typeModal === TYPE_MODAL.Edit && field === 'video') return;
      if (!formValue[field]) {
        errors = { ...errors, [field]: 'Required' };
        valid = false;
      }
    });
    setErrors(errors);
    return valid;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!onCheckValidForm()) {
      return;
    }
    let data = {
      ...formValue
    };
    studioClassDispatcher.showHideLoadingAction({ loading: true });
    //add thumbnail
    if (data.thumbnail?.constructor === File) {
      let storageUrl = await uploadImage(formValue.thumbnail);
      data.thumbnail = storageUrl;
    }
    data.workoutType = 'StudioClass';
    if (idSelected) {
      studioClassDispatcher.editStudioClass(
        {
          id: idSelected,
          data
        },
        () => {
          reGetStudioClassList();
          onCloseModal();
        }
      );
      return;
    }
    studioClassDispatcher.addStudioClass(data, () => {
      reGetStudioClassList();
      onCloseModal();
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <HeaderModal>
        <div className="title">
          {typeModal === TYPE_MODAL.Create && 'Create New Workout'}
          {typeModal === TYPE_MODAL.Edit && 'Update Workout'}
        </div>
        <ClearRounded onClick={() => onCloseModal()} className="icon-close" />
      </HeaderModal>

      <DialogContent>
        <WrapperForm>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <UploadSingle
                typeUpload="normal"
                onChange={data => handleChange(data, 'thumbnail')}
                imageUrlSelected={formValue.thumbnail}
                name="thumbnail"
                accept=".png,.jpg,.jpeg"
                uploadButton={
                  <WrapperButtonUpload>
                    <span className="icon">
                      <AddImageOutlineIcon color="#ccc" />
                    </span>{' '}
                    <span className="text">Add Programme Picture</span>
                  </WrapperButtonUpload>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <WrapperInput
                    error={isSubmitted && errors.title ? true : false}
                    variant="standard"
                    value={formValue.title}
                    onChange={e => handleChange(e.target.value, 'title')}
                    label="Workout Title"
                    name="title"
                    helperText={isSubmitted && errors.title}
                  />
                </Grid>
                <Grid item xs={12}>
                  <WrapperFormControl
                    error={isSubmitted && errors.categoryId ? true : false}
                  >
                    <InputLabel id="workout-select-label">
                      Workout Type
                    </InputLabel>
                    <WrapperSelect
                      className="select-custom"
                      labelId="workout-select-label"
                      value={formValue.categoryId || ''}
                      onChange={e => handleChange(e.target.value, 'categoryId')}
                    >
                      {listCategoryStudioClass.map((item, index) => (
                        <MenuItem key={item.id + index} value={item.id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </WrapperSelect>
                    {isSubmitted && (
                      <FormHelperText>{errors.categoryId}</FormHelperText>
                    )}
                  </WrapperFormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <WrapperInput
                label="Calories"
                error={isSubmitted && errors.calories ? true : false}
                value={formValue.calories || ''}
                disabled={true}
                helperText={isSubmitted && errors.calories}
              />
            </Grid>
            <Grid item xs={6}>
              <WrapperFormControl
                error={isSubmitted && errors.intensity ? true : false}
              >
                <InputLabel id="intensity-label">Intensity</InputLabel>
                <WrapperSelect
                  disabled={true}
                  className="select-custom"
                  labelId="intensity-label"
                  value={formValue.intensity || ''}
                >
                  {listIntensity.map((item, index) => (
                    <MenuItem key={item.value + index} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </WrapperSelect>
                {isSubmitted && (
                  <FormHelperText>{errors.intensity}</FormHelperText>
                )}
              </WrapperFormControl>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={MomentFns}>
                <DateTimePicker
                  label="Start Date Time"
                  minDate={moment()}
                  renderInput={props => (
                    <TextField
                      className="date-picker-custom"
                      variant="standard"
                      {...props}
                      helperText=""
                    />
                  )}
                  value={formValue.date ? moment(formValue.date) : null}
                  onChange={date => handleChange(date, 'date')}
                  ampm={false}
                  autoOk
                />
                {isSubmitted && (
                  <FormHelperText error>{errors.date}</FormHelperText>
                )}
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <WrapperInput
                error={isSubmitted && errors.equipment ? true : false}
                variant="standard"
                value={formValue.equipment || ''}
                onChange={e => handleChange(e.target.value, 'equipment')}
                name="equipment"
                label="Equipment"
                helperText={isSubmitted && errors.equipment}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                style={{ marginTop: '5px' }}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={option => option.fullName || ''}
                options={listAvailableTrainer || []}
                value={{
                  id: formValue.trainerStudioClassId,
                  fullName: formValue.trainerStudioClassName
                }}
                autoComplete
                onChange={(e, newValue) => {
                  newValue &&
                    setFormValue({
                      ...formValue,
                      trainerStudioClassId: newValue.id,
                      trainerStudioClassName: newValue.fullName
                    });
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Trainer"
                    style={{ width: '100%', marginTop: 10 }}
                    error={errors.trainerStudioClassId}
                    helperText={errors.trainerStudioClassId}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                style={{ marginTop: '5px' }}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={option => option.name || ''}
                options={listStudio || []}
                value={{ id: formValue.studioId, name: formValue.studioName }}
                autoComplete
                onChange={(e, newValue) => {
                  newValue &&
                    setFormValue({
                      ...formValue,
                      studioId: newValue.id,
                      studioName: newValue.name
                    });
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Studio"
                    style={{ width: '100%', marginTop: 10 }}
                    error={errors.studioId}
                    helperText={errors.studioId}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <WrapperInput
                error={isSubmitted && errors.price ? true : false}
                variant="standard"
                value={formValue.price}
                onChange={e => handleChange(e.target.value, 'price')}
                name="price"
                type="number"
                label="Price"
                helperText={isSubmitted && errors.price}
              />
            </Grid>
            <Grid item xs={6}>
              <WrapperInput
                error={isSubmitted && errors.slot ? true : false}
                variant="standard"
                value={formValue.slot}
                onChange={e => handleChange(e.target.value, 'slot')}
                name="slot"
                type="number"
                label="Slot"
                helperText={isSubmitted && errors.slot}
              />
            </Grid>
            <Grid item xs={6}>
              <WrapperFormControl
                error={isSubmitted && errors.duration ? true : false}
              >
                <InputLabel id="duration-label">Duration</InputLabel>
                <WrapperSelect
                  className="select-custom"
                  labelId="duration-label"
                  value={formValue.duration || ''}
                  onChange={e => handleChange(e.target.value, 'duration')}
                >
                  {(listDuration || []).map((item, index) => (
                    <MenuItem key={item.value + index} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </WrapperSelect>
                {isSubmitted && (
                  <FormHelperText>{errors.duration}</FormHelperText>
                )}
              </WrapperFormControl>
            </Grid>
            <Grid item xs={12}>
              <WrapperInput
                error={isSubmitted && errors.description ? true : false}
                variant="standard"
                value={formValue.description || ''}
                onChange={e => handleChange(e.target.value, 'description')}
                name="description"
                label="Description"
                helperText={isSubmitted && errors.description}
              />
            </Grid>
          </Grid>
        </WrapperForm>
      </DialogContent>

      <ModalFooter>
        <ButtonEnhance
          background="#E9E9E9"
          color="black"
          backgroundHover="#ccc"
          onClick={() => onCloseModal()}
        >
          Cancel
        </ButtonEnhance>

        <button className="submit-btn" type="submit">
          <LoadingButton showLoader={loading}>Save</LoadingButton>
        </button>
      </ModalFooter>
    </form>
  );
};

const WrapperButtonUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    color: #ccc;
  }
`;

const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 20px 0px;
  margin-top: 30px;
  .submit-btn {
    border: none;
    border-radius: 35px;
    background: none;
    outline: none;
  }
`;

const WrapperForm = styled.div`
  font-size: 14;
  flex-grow: 1;
  overflow: hidden;
`;
const HeaderModal = styled.div`
  border-bottom: 1px solid rgb(202, 207, 211);
  font-weight: 600;
  color: rgb(25, 38, 55);
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  .icon-close {
    cursor: pointer;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
  }
`;

const WrapperFormControl = styled(FormControl)`
  width: 100%;
`;

const WrapperSelect = styled(Select)`
  width: 100%;
`;
const WrapperInput = styled(TextField)`
  width: 100%;
  label {
    color: #8f9ba9;
  }
`;
export default StudioClassFormPage;
