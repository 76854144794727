import CustomButton from '../CustomButton';
import { NoticeIcon } from '@/assets/svg';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';

const CustomDialogContent = withStyles({
  root: {
    height: (props) => (props.height ? props.height : 'calc(100vh - 152px )'),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& .main-content': {
      fontFamily: 'Lato',
      marginTop: 30,
      fontSize: 32,
      fontWeight: 700,
    },
  },
})(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: '16px 24px',
    justifyContent: 'center',
  },
})(DialogActions);

const ModalConfirmationMobile = ({
  open,
  onClose,
  onClick,
  title,
  Icon = <NoticeIcon style={{ marginTop: 48 }} />,
  mainContent,
  subContent,
  otherActionsContent,
  cancelLabel = 'Cancel',
  confirmLabel = 'Accept',
  height,
}) => {
  return (
    <Dialog
      open={open}
      scroll="body"
      disableEnforceFocus
      disableBackdropClick
      fullScreen
    >
      {title && <DialogTitle>{title}</DialogTitle>}

      <CustomDialogContent height={height}>
        {Icon && Icon}

        {mainContent && (
          <Typography className="main-content">{mainContent}</Typography>
        )}
        {subContent && subContent}
      </CustomDialogContent>
      <CustomDialogActions>
        {otherActionsContent ? (
          otherActionsContent()
        ) : (
          <>
            <CustomButton
              style={{ width: '100%', height: 48 }}
              onClick={onClose}
              variant="outlined"
              color="primary"
            >
              {cancelLabel}
            </CustomButton>
            <CustomButton
              style={{ width: '100%', height: 48 }}
              onClick={onClick}
              variant="contained"
              color="primary"
            >
              {confirmLabel}
            </CustomButton>
          </>
        )}
      </CustomDialogActions>
    </Dialog>
  );
};

export default ModalConfirmationMobile;
