import React from 'react';
import ServiceForm from '../../common/ServiceForm';

const VaccinationForm = props => (
  <ServiceForm
    {...{
      ...props,
      category: 'Vaccinations',
      prefixName: 'Vaccination'
    }}
  />
);

export default VaccinationForm;
