import Enum from '@/enum/Enum';

export const TELE_COLUMN_QUEUE = [
  {
    label: 'No',
    stateValue: 'number',
  },
  {
    label: 'Name',
    stateValue: 'name',
  },
  {
    label: 'ID number',
    stateValue: 'idNumber',
    minWidth: 112,
  },
  {
    label: 'Age',
    stateValue: 'age',
  },
  {
    label: 'Gender',
    stateValue: 'gender',
  },
  {
    label: 'Appointment time',
    stateValue: 'appointmentTime',
  },
  {
    label: 'Wait time',
    stateValue: 'waitTime',
  },
  {
    label: 'Assigned counsellor',
    stateValue: 'counsellor',
  },
  {
    label: 'Status',
    stateValue: 'status',
  },
  {
    label: 'Action',
    stateValue: 'action',
    minWidth: 170,
  },
];

export const renderTeleQueueCol = (isCounsellor) => {
  let list = [...TELE_COLUMN_QUEUE];

  if (isCounsellor) {
    list = list.filter((it) => it.stateValue !== 'counsellor');
  } else {
    list = list.filter((it) => it.stateValue !== 'action');
  }
  return list;
};

export const TELECOUNSEL_STATUS = {
  Join: 'Join',
  Leave: 'Leave',
  Waiting: 'Waiting',
  Calling: 'Calling',
  EndCall: 'EndCall',
  ApproveIdentity: 'ApproveIdentity',
  RejectIdentity: 'RejectIdentity',
  AcceptCall: 'AcceptCall',
  RejectCall: 'RejectCall',
  WaitingRejoinCall: 'WaitingRejoinCall',
  SuccessRejoinCall: 'SuccessRejoinCall',
  ExpiredRejoinCall: 'ExpiredRejoinCall',
  CancelRejoinCall: 'CancelRejoinCall',
  PatientUnavailable: 'PatientUnavailable',
  PatientRejoinCall: 'PatientRejoinCall',
  CallInfo: 'CallInfo',
  CallProblem: 'CallProblem',
  PlatoError: 'PlatoError',
};

export const TELECOUNSEL_DISPLAY_STATUS = {
  Join: 'In call',
  Waiting: 'In queue',
};

export const TELECOUNSEL_STATUS_COLORS = {
  [TELECOUNSEL_DISPLAY_STATUS.Waiting]: {
    txt: '#AD5E99',
    background: '#FFE9FB',
  },
  [TELECOUNSEL_DISPLAY_STATUS.Join]: {
    txt: '#23A6CC',
    background: '#23a6cc29',
  },
};

export const DOCTOR_STATUS = Enum({
  Pending: 'Pending',
  Reject: 'Reject',
  Approved: 'Approved',
});

export const COUNSELLING_TYPES = [
  {
    key: 'Individual',
    value: 'Individual',
  },
  // {
  //   key: 'Couple',
  //   value: 'Couple',
  // },
  // {
  //   key: 'Family',
  //   value: 'Family',
  // },
];
