// import { ButtonSecondary, UploadAction } from './styled';
import { ButtonSecondary, UploadAction } from './StreamCall/styled';
import { CloseIcon, ImageOutlineIcon } from '@/assets/svg';
import Image from '@/components/Image';
import UploadCompAnt from '@/components/UploadCompAnt';
import { blobUrl } from '@/helpers';
import {
  Box,
  Button,
  Typography,
  withStyles,
  DialogContent,
  makeStyles,
  Dialog,
  IconButton,
} from '@material-ui/core';
import React, { useState } from 'react';

const UploadText = withStyles({
  root: {
    marginBottom: 0,
    fontSize: 16,
    color: '#666666',
  },
})(Typography);

const CustomDialogContent = withStyles({
  root: {
    padding: 40,
    position: 'relative',
    '& .icon': {
      position: 'absolute',
      top: 10,
      right: 10,
    },

    '&:first-child': {
      paddingTop: 40,
    },
  },
})(DialogContent);

const useStyles = makeStyles({
  image: {
    width: 44,
    height: 30,
    borderRadius: 4,
  },
});

const UploadImageContainer = ({
  keyName,
  imageUrl,
  setFormValue,
  formValue,
  handleChangeForm,
  label = 'Image',
}) => {
  const classes = useStyles();

  const [isHovered, setIsHovered] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
    handleMouseLeave();
  };
  const handlePreviewClose = () => setPreviewOpen(false);

  return (
    <Box
      mt={1.25}
      borderRadius={8}
      bgcolor={imageUrl ? '#ffffff' : '#f6f6f6'}
      border={'1px solid #666666'}
      p={1.5}
    >
      <Box
        display="flex"
        gridGap={10}
        justifyContent="space-between"
        alignItems="center"
        position="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {imageUrl ? (
          <Box width={44}>
            <Image
              src={
                imageUrl
                  ? `${
                      typeof imageUrl === 'object'
                        ? blobUrl(imageUrl)
                        : imageUrl
                    }`
                  : null
              }
              alt={label}
              className={classes.image}
              onClick={handlePreviewOpen}
            />
            {/* {isHovered && (
              <PreviewContent onClick={handlePreviewOpen}>
                <EyeIcon color="white" width={24} height={24} />
              </PreviewContent>
            )} */}
          </Box>
        ) : (
          <Box
            width={24}
            height={24}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ImageOutlineIcon />
          </Box>
        )}

        <Box
          flex={1}
          display="flex"
          justifyContent="space-between"
          alignItems="cennter"
          gridGap={16}
        >
          <UploadText>{label}</UploadText>
          {imageUrl ? (
            <ButtonSecondary
              onClick={() => setFormValue({ ...formValue, [keyName]: '' })}
            >
              Remove
            </ButtonSecondary>
          ) : (
            <UploadCompAnt
              accept=".jpg,.png,.jpeg"
              isAddNew={true}
              fileData={imageUrl}
              onChange={handleChangeForm(keyName)}
              editable={true}
              ActionComponent={
                <UploadAction>
                  <ButtonSecondary>Upload</ButtonSecondary>
                </UploadAction>
              }
            />
          )}
        </Box>
      </Box>

      {previewOpen && (
        <Dialog
          disableBackdropClick
          open={previewOpen}
          onClose={handlePreviewClose}
          maxWidth="lg"
        >
          <CustomDialogContent>
            <IconButton className="icon" onClick={handlePreviewClose}>
              <CloseIcon />
            </IconButton>

            <Image
              src={
                imageUrl
                  ? `${
                      typeof imageUrl === 'object'
                        ? blobUrl(imageUrl)
                        : imageUrl
                    }`
                  : null
              }
              alt="Preview"
            />
          </CustomDialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default UploadImageContainer;
