import React, { useEffect, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import { isMobile } from '@/helpers';

const AppBridge = () => {
  const isMobileBrowser = useMemo(isMobile, []);

  return (
    <Box style={{ padding: 20 }}>
      <div style={{ maxWidth: 400 }}>
        <p>Please make new appointment via app</p>
        <p>
          <a href="https://apps.apple.com/sg/app/minmed-connect/id1514570975">
            <noscript>
              <img
                src="https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://minmed.sg/wp-content/uploads/2020/04/download-2.png"
                width="55%"
              />
            </noscript>
            <img
              class="lazyloaded"
              src="https://cdn.shortpixel.ai/client/to_avif,q_glossy,ret_img/https://minmed.sg/wp-content/uploads/2020/04/download-2.png"
              data-src="https://cdn.shortpixel.ai/client/to_avif,q_glossy,ret_img/https://minmed.sg/wp-content/uploads/2020/04/download-2.png"
              width="55%"
            />
          </a>
        </p>
        <p>
          <a href="https://play.google.com/store/apps/details?id=com.minmed.mink">
            <noscript>
              <img
                src="https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://minmed.sg/wp-content/uploads/2020/04/download-1.png"
                width="55%"
              />
            </noscript>
            <img
              class="lazyloaded"
              src="https://cdn.shortpixel.ai/client/to_avif,q_glossy,ret_img/https://minmed.sg/wp-content/uploads/2020/04/download-1.png"
              data-src="https://cdn.shortpixel.ai/client/to_avif,q_glossy,ret_img/https://minmed.sg/wp-content/uploads/2020/04/download-1.png"
              width="55%"
            />
          </a>
        </p>
      </div>
    </Box>
  );
};

export default AppBridge;
