import React, { useRef } from 'react';
import { LocalizationProvider, DateRangePicker } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import CustomSelect from '../CustomSelect';
import { TextField } from '@material-ui/core';
import { EventRounded } from '@material-ui/icons';
import { DATE_FORMAT_2 } from '../../module/common';
import moment from 'moment';
const CustomDateRange = ({ selectedDate, onChange }) => {
  const dateRangeRef = useRef();
  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <DateRangePicker
        value={selectedDate}
        onChange={([startDate, endDate]) => {
          if (startDate && endDate) {
            onChange(startDate, moment(endDate).endOf('date'));
          }
        }}
        renderInput={(startProps, endProps) => (
          <CustomSelect
            onClick={() => {
              dateRangeRef.current.focus();
            }}
            clearable={selectedDate[0] && selectedDate[1]}
            onClear={onChange}
            prefixIcon={
              <EventRounded
                style={{
                  fontSize: 20,
                  color: '#E9E9E9',
                  marginRight: 8
                }}
              />
            }
            renderValue={
              <>
                {`${
                  selectedDate[0]
                    ? selectedDate[0].format(DATE_FORMAT_2)
                    : ' From'
                } - ${
                  selectedDate[1] ? selectedDate[1].format(DATE_FORMAT_2) : 'To'
                }`}
              </>
            }
          >
            <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
              <TextField {...startProps} inputRef={dateRangeRef} />
              <TextField {...endProps} />
            </div>
          </CustomSelect>
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDateRange;
