/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { CustomTextField, getErrorMessage } from './index';

const CustomTypography = withStyles({
  root: {
    color: '#333333',
    marginBottom: 8
  }
})(Typography);

const ModalEditTestBlood = ({ formValue, setFormValue, errors }) => {
  const onChangeTestValue = (name, value = '') => {
    if (!value) setFormValue(prevState => ({ ...prevState, [name]: '' }));
    // Regex test number contain decimal. Maximum length number of decimal is 9
    const regexNumber = /^(\d+\.?\d{0,9}|\.\d{1,9})$/g;
    if (regexNumber.test(value)) {
      setFormValue(prevState => ({ ...prevState, [name]: value }));
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <CustomTypography variant="subtitle2">Systolic (mmHg)</CustomTypography>
        <CustomTextField
          size="small"
          required
          type="number"
          name="systolic"
          placeholder="Enter value"
          value={formValue.systolic}
          variant="outlined"
          fullWidth
          error={!!errors.systolic}
          helperText={getErrorMessage(errors.systolic)}
          onChange={e => onChangeTestValue('systolic', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTypography variant="subtitle2">
          Diastolic (mmHg)
        </CustomTypography>
        <CustomTextField
          size="small"
          required
          type="number"
          name="diastolic"
          placeholder="Enter value"
          value={formValue?.diastolic}
          variant="outlined"
          fullWidth
          error={!!errors.diastolic}
          helperText={getErrorMessage(errors.diastolic)}
          onChange={e => onChangeTestValue('diastolic', e.target.value)}
        />
      </Grid>
    </>
  );
};

export default ModalEditTestBlood;
