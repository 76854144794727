import React from 'react';
import styled from 'styled-components';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { capitalize } from 'lodash';

const TabsUI = ({
  listTab,
  value,
  onChange,
  renderLabel,
  renderKey,
  variant = 'scrollable',
  errorsIndex = [],
  isCapitalize = false,
  ...props
}) => {
  const onShowLabel = (item, index) => {
    let label = item[renderLabel] || item;
    if (isCapitalize) label = capitalize(label);
    const error = errorsIndex.includes(index);
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            marginRight: 4,
            lineHeight: '27px',
            color: error ? 'red' : 'inherit'
          }}
        >
          {label}
        </span>
        {error && (
          <span style={{ paddingTop: 6 }}>
            <ErrorOutlineIcon color="error" fontSize="small" />
          </span>
        )}
      </div>
    );
  };

  return (
    <AppBarWrapped {...props}>
      <AppBar position="static" color="transparent">
        <Tabs
          value={value}
          onChange={onChange}
          indicatorColor="primary"
          textColor="primary"
          variant={variant}
        >
          {listTab.map((item, index) => (
            <Tab
              label={onShowLabel(item, index)}
              key={item[renderKey] || item}
              style={{ color: '#333333' }}
            />
          ))}
        </Tabs>
      </AppBar>
    </AppBarWrapped>
  );
};
export default TabsUI;

const AppBarWrapped = styled.div`
  .MuiPaper-elevation4 {
    box-shadow: none;
    border-bottom: 0.5px solid #ecedf0;
  }
  .MuiTab-wrapper {
    text-transform: none;
  }
  .MuiTab-root {
    min-width: unset;
  }
  button.Mui-selected {
    color: #ad5e99 !important;
  }
`;
