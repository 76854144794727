/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { lowerCase } from 'lodash';
import { CustomFormHelperText, getErrorMessage } from './index';

const CustomToggleButtonGroup = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16
  },
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderLeft: '1px solid #E1E1E1',
      borderRadius: 8
    },
    '&:first-child': {
      borderRadius: 8
    }
  }
}))(ToggleButtonGroup);

const CustomToggleButton = withStyles(theme => ({
  root: {
    flex: 1,
    border: '1px solid #E1E1E1',
    color: '#3333333',
    textTransform: 'unset',
    fontSize: 16,
    height: 40,

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.light
    },
    '&.error': {
      border: `1px solid ${theme.palette.feedback.error}`
    }
  }
}))(ToggleButton);

const ModalEditTestPupilReflex = ({
  isAPR = false,
  formValue,
  setFormValue,
  errors
}) => {
  const onChangeTestValue = (name, value = '') => {
    if (!value) setFormValue(prevState => ({ ...prevState, [name]: '' }));

    setFormValue(prevState => ({
      ...prevState,
      [name]: lowerCase(value)
    }));
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Left eye</Typography>
        <CustomToggleButtonGroup
          exclusive
          value={formValue?.left}
          onChange={(e, newValue) => onChangeTestValue('left', newValue)}
        >
          <CustomToggleButton
            value="normal"
            className={errors.left ? 'error' : ''}
          >
            Normal
          </CustomToggleButton>
          <CustomToggleButton
            value="abnormal"
            className={errors.left ? 'error' : ''}
          >
            Abnormal
          </CustomToggleButton>
          {isAPR && (
            <CustomToggleButton
              value="not applicable"
              className={errors.left ? 'error' : ''}
            >
              Not applicable
            </CustomToggleButton>
          )}
        </CustomToggleButtonGroup>
        {errors.left && (
          <CustomFormHelperText error>
            {getErrorMessage(errors.left)}
          </CustomFormHelperText>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Right eye</Typography>
        <CustomToggleButtonGroup
          exclusive
          value={formValue?.right}
          onChange={(e, newValue) => onChangeTestValue('right', newValue)}
        >
          <CustomToggleButton
            value="normal"
            className={errors.right ? 'error' : ''}
          >
            Normal
          </CustomToggleButton>
          <CustomToggleButton
            value="abnormal"
            className={errors.right ? 'error' : ''}
          >
            Abnormal
          </CustomToggleButton>
          {isAPR && (
            <CustomToggleButton
              value="not applicable"
              className={errors.right ? 'error' : ''}
            >
              Not applicable
            </CustomToggleButton>
          )}
        </CustomToggleButtonGroup>
        {errors.right && (
          <CustomFormHelperText error>
            {getErrorMessage(errors.right)}
          </CustomFormHelperText>
        )}
      </Grid>
    </>
  );
};

export default ModalEditTestPupilReflex;
