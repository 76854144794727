import React from 'react';
import {
  DialogContent,
  TextField,
  Grid,
  Typography,
  withStyles,
  Dialog,
  Box
} from '@material-ui/core';
import CustomModalTitle from '@/new-components/CustomModal/CustomModalTitle';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 4,
    color: '#666666'
  }
})(Typography);

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16
  },
  paperWidthMd: {
    maxWidth: 800,
    minHeight: 500
  }
})(Dialog);

const CustomTypography = withStyles({
  root: {
    fontFamily: 'OpenSans',
    fontWeight: 600,
    fontSize: 16
  }
})(Typography);

const ConsentModal = ({ itemSelected, open, onClose }) => {
  return (
    <CustomDialog
      maxWidth={'md'}
      fullWidth
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
    >
      <CustomModalTitle onClose={onClose}>View consent form</CustomModalTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTitleLabel>Consent form name</CustomTitleLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Enter code"
              value={itemSelected?.title || ''}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTitleLabel>Description</CustomTitleLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Enter code"
              value={itemSelected?.description || ''}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTitleLabel>Content</CustomTitleLabel>
            <Box
              p={1}
              border={'1px solid #00000042'}
              bgcolor="#f6f6f6"
              color="#00000061"
              borderRadius={12}
            >
              <CustomTypography
                style={{ height: 492, overflow: 'auto' }}
                dangerouslySetInnerHTML={{
                  __html: itemSelected?.htmlContent || ''
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </CustomDialog>
  );
};

export default ConsentModal;
