import moment from 'moment';

export const COLUMN_COUNSELLORS = [
  {
    label: 'Name',
    stateValue: 'name'
  },
  {
    label: 'Phone number',
    stateValue: 'phoneNumber'
  },
  {
    label: 'Email',
    stateValue: 'email'
  },
  {
    label: 'Rate per hour',
    stateValue: 'ratePerHour'
  },
  {
    label: 'Appt. count',
    stateValue: 'counselCount'
  },
  {
    label: 'Last appt.',
    stateValue: 'lastCounsel'
  },
  {
    label: 'Status',
    stateValue: 'status'
  },
  {
    label: 'Action',
    minWidth: 60
  }
];

export const COLUMN_QUALIFICATION = [
  {
    label: 'Degree / Certificate',
    stateValue: 'degree',
    width: '30%'
  },
  {
    label: 'Institution',
    stateValue: 'institution',
    width: '30%'
  },
  {
    label: 'Time',
    stateValue: 'time'
  },
  {
    label: '',
    minWidth: 60
  }
];

export const TYPE_MODAL = {
  Create: 'Create',
  Edit: 'Edit',
  Verify: 'Verify'
};

export const ACTIVE_STATUSES = {
  IN_ACTIVE: 'InActive',
  ACTIVE: 'Active',
  BANNED: 'Banned'
};

export const APPROVE_STATUSES = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECT: 'Reject'
};

export const COUNSELLOR_STATUS = {
  Pending: 'Pending',
  Reject: 'Rejected',
  Active: 'Active',
  InActive: 'Inactive'
};

export const COUNSELLOR_STATUS_COLORS = {
  [COUNSELLOR_STATUS.Pending]: {
    txt: '#333333',
    background: '#f1f1f1'
  },
  [COUNSELLOR_STATUS.Reject]: {
    txt: '#E37F2A',
    background: '#e37f2a29'
  },
  [COUNSELLOR_STATUS.InActive]: {
    txt: '#DB3D49',
    background: '#db3d4929'
  },
  [COUNSELLOR_STATUS.Active]: {
    txt: '#0B8E5E',
    background: '#15a47029'
  }
};

export const ALL_COUNSELLOR_STATUSES = [
  {
    value: 'Pending',
    key: 'Pending'
  },
  {
    value: 'Rejected',
    key: 'Reject'
  },
  {
    value: 'Active',
    key: 'Active'
  },
  {
    value: 'Inactive',
    key: 'InActive'
  }
];

export const GENDERS = [
  {
    value: 'Male',
    key: 'Male'
  },
  {
    value: 'Female',
    key: 'Female'
  }
];

export const DAYS_OF_WEEK = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0
};

export const DAYS_OF_WEEK_LIST = [
  { key: 'Sun', value: 'Sunday' },
  { key: 'Mon', value: 'Monday' },
  { key: 'Tue', value: 'Tuesday' },
  { key: 'Wed', value: 'Wednesday' },
  { key: 'Thu', value: 'Thursday' },
  { key: 'Fri', value: 'Friday' },
  { key: 'Sat', value: 'Saturday' }
];

export const DAYS_OF_WEEK_FORMAT = [
  { dayOfWeek: 1, label: 'monday' },
  { dayOfWeek: 2, label: 'tuesday' },
  { dayOfWeek: 3, label: 'wednesday' },
  { dayOfWeek: 4, label: 'thursday' },
  { dayOfWeek: 5, label: 'friday' },
  { dayOfWeek: 6, label: 'saturday' },
  { dayOfWeek: 0, label: 'sunday' }
];

export const FORMAT_DATE_TIME = 'DD-MM-YYYY:HH:mm';
export const MIN_TIME_PICK = moment().startOf('date');
export const MAX_TIME_PICK = moment().endOf('date');
