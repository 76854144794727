import { Box, makeStyles, Button } from '@material-ui/core';
import React from 'react';
import {
  CloseIcon,
  UndoIcon,
  ErrorOutlinedIcon,
  CheckmarkCircleIcon,
  InfoOutlinedIcon
} from '../../assets/svg';

import { Title } from './styled';

const toastColorStyle = makeStyles(theme => ({
  error: {
    color: theme.palette.feedback.error
  },
  success: {
    color: theme.palette.feedback.success
  },
  info: {
    color: theme.palette.secondary.main
  }
}));

const renderIcon = (type, className, isAction = false) => {
  let Icon;
  switch (type) {
    case 'error':
      Icon = ErrorOutlinedIcon;
      break;
    case 'success':
      Icon = CheckmarkCircleIcon;
      break;
    case 'info':
      Icon = isAction ? UndoIcon : InfoOutlinedIcon;
      break;

    default:
      Icon = null;
  }
  return <Icon className={className} style={{ marginRight: 8 }} />;
};

const CustomNotificationContent = ({
  type,
  title,
  description,
  isAction,
  labelBtn = 'View',
  isClose = true,
  onClick
}) => {
  const classes = toastColorStyle();
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {title ? (
        <>
          <Box display="flex" alignItems="center" style={{ color: '#333333' }}>
            {renderIcon(type, classes[type], isAction)}
            <div>
              <Title>{title}</Title>
              {description}
            </div>
          </Box>
          {isAction ? (
            <Button
              onClick={onClick}
              style={{ minWidth: 50, fontSize: 14, color: '#333333' }}
            >
              {labelBtn}
            </Button>
          ) : (
            <CloseIcon className="close" />
          )}
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center" className={classes[type]}>
            {renderIcon(type, classes[type])}
            {description}
          </Box>
          {isClose && <CloseIcon className="close" />}
        </>
      )}
    </Box>
  );
};

export default CustomNotificationContent;
