import React from 'react';
import styled from 'styled-components';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

const FileMessage = ({ file, onRemove }) => {
  return (
    <ImageContainer>
      <FileItem>
        {file.name}
        <CancelOutlinedIcon className="icon-remove" onClick={onRemove} />
      </FileItem>
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  height: 60px;
  position: relative;
  margin-right: 10px;
  transition: all 1s;
  display: flex;
  align-items: center;
`;

const FileItem = styled.div`
  display: flex;
  align-items: center;
  border-radius: 22px;
  padding: 10px 15px;
  font-size: 14px;
  background: #bd7ead;
  color: white;
  font-weight: 600;
  .icon-remove {
    display: none;
    position: absolute;
    top: 1px;
    right: 3px;
    cursor: pointer;
  }
  &:hover .icon-remove {
    display: initial;
    color: black;
  }
`;

export default FileMessage;
