import { formatUTCDate } from '@/helpers';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getBroadcastData: (paging, sortOption = {}, filterOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().marketingBroadcast.paging;
    const { result, status } = await Api.post(`Broadcasts/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      fromDate: filterOption?.fromDate
        ? formatUTCDate(filterOption?.fromDate, { isStarOfDay: true })
        : undefined,
      toDate: filterOption?.toDate
        ? formatUTCDate(filterOption?.toDate, { isEndOfDay: true })
        : undefined,
      ...filterOption,
      ...sortOption
    });
    if (status === 200) marketingBroadcastDispatcher.getDataSuccess(result);
  },
  pushMarketingBroadcasts: (payload, callbackSuccess) => async ({ Api }) => {
    const { status } = await Api.post(`Broadcasts/marketing`, payload);
    if (status === 200) {
      callbackSuccess && callbackSuccess();
    }
  },
  deleteMarketingBroadcasts: (id, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    const { status } = await Api.delete(`Broadcasts/${id}`);
    if (status === 200) {
      customToast('success', 'Delete message success');
      callbackSuccess && callbackSuccess();
    }
  },
  setState: (state, value) => ({ state, value })
};

const marketingBroadcastDispatcher = synthesize(
  'marketingBroadcast',
  mapDispatchToAC
);
export default marketingBroadcastDispatcher;
