/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { lowerCase } from 'lodash';
import { CustomFormHelperText, getErrorMessage } from './index';

const CustomToggleButtonGroup = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16
  },
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderLeft: '1px solid #E1E1E1',
      borderRadius: 8
    },
    '&:first-child': {
      borderRadius: 8
    }
  }
}))(ToggleButtonGroup);

const CustomToggleButton = withStyles(theme => ({
  root: {
    flex: 1,
    border: '1px solid #E1E1E1',
    color: '#3333333',
    textTransform: 'unset',
    fontSize: 16,
    height: 40,

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.light
    },
    '&.error': {
      border: `1px solid ${theme.palette.feedback.error}`
    }
  }
}))(ToggleButton);

const ModalEditTestUrineDipstick = ({ formValue, setFormValue, errors }) => {
  const onChangeTestValue = (name, value = '') => {
    if (!value) setFormValue(prevState => ({ ...prevState, [name]: '' }));

    setFormValue(prevState => ({
      ...prevState,
      [name]: lowerCase(value)
    }));
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle2">1. Blood</Typography>
        <CustomToggleButtonGroup
          exclusive
          value={formValue?.urineDipstickBlood}
          onChange={(e, newValue) =>
            onChangeTestValue('urineDipstickBlood', newValue)
          }
        >
          <CustomToggleButton
            value="yes"
            className={errors.urineDipstickBlood ? 'error' : ''}
          >
            Yes
          </CustomToggleButton>
          <CustomToggleButton
            value="no"
            className={errors.urineDipstickBlood ? 'error' : ''}
          >
            No
          </CustomToggleButton>
        </CustomToggleButtonGroup>
        {errors.urineDipstickBlood && (
          <CustomFormHelperText error>
            {getErrorMessage(errors.urineDipstickBlood)}
          </CustomFormHelperText>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">2. Glucose</Typography>
        <CustomToggleButtonGroup
          exclusive
          value={formValue?.urineDipstickGlucose}
          onChange={(e, newValue) =>
            onChangeTestValue('urineDipstickGlucose', newValue)
          }
        >
          <CustomToggleButton
            value="yes"
            className={errors.urineDipstickGlucose ? 'error' : ''}
          >
            Yes
          </CustomToggleButton>
          <CustomToggleButton
            value="no"
            className={errors.urineDipstickGlucose ? 'error' : ''}
          >
            No
          </CustomToggleButton>
        </CustomToggleButtonGroup>
        {errors.urineDipstickGlucose && (
          <CustomFormHelperText error>
            {getErrorMessage(errors.urineDipstickGlucose)}
          </CustomFormHelperText>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">3. Protein</Typography>
        <CustomToggleButtonGroup
          exclusive
          value={formValue?.urineDipstickProtein}
          onChange={(e, newValue) =>
            onChangeTestValue('urineDipstickProtein', newValue)
          }
        >
          <CustomToggleButton
            value="yes"
            className={errors.urineDipstickProtein ? 'error' : ''}
          >
            Yes
          </CustomToggleButton>
          <CustomToggleButton
            value="no"
            className={errors.urineDipstickProtein ? 'error' : ''}
          >
            No
          </CustomToggleButton>
        </CustomToggleButtonGroup>
        {errors.urineDipstickProtein && (
          <CustomFormHelperText error>
            {getErrorMessage(errors.urineDipstickProtein)}
          </CustomFormHelperText>
        )}
      </Grid>
    </>
  );
};

export default ModalEditTestUrineDipstick;
