import React, { useState, useEffect, useMemo } from 'react';
import {
  Checkbox,
  Grid,
  FormControlLabel,
  Typography,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { cloneDeep, findIndex, isEmpty } from 'lodash';
import { formatData } from '@/module/package-management/utils';
import CustomTooltip from '@/new-components/CustomTooltip';
import {
  CheckboxActive,
  CheckboxDefault,
  CheckboxDisabledActive
} from '@/assets/svg';

const useStyle = makeStyles({
  iconUncheck: {
    margin: 1,
    width: 22,
    height: 22,
    color: '#F1F1F1',
    borderRadius: '50%'
  },
  iconChecked: {
    color: '#0B8E5E'
  },
  iconDisabled: {
    opacity: 1
    // color: '#AD5E994D'
  },
  textPrice: {
    fontWeight: 600
  },
  title: {
    fontSize: 12,
    color: '#666666'
  }
});

const PackageItem = ({
  packageDetail,
  projectId,
  setProfiles,
  profiles,
  procedures,
  setProcedures,
  radiologies,
  setRadiologies,
  formValue,
  setFormValue,
  currentPackage,
  setCurrentPackage,
  restrictedTestIds
}) => {
  const classes = useStyle();
  const [unMandatoryProfiles, setUnMandatoryProfiles] = useState([]);
  const [unMandatoryProcedures, setUnMandatoryProcedures] = useState([]);
  const [unMandatoryRadiologies, setUnMandatoryRadiologies] = useState([]);

  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [selectedRadiologies, setSelectedRadiologies] = useState([]);

  const availableProcedures = useMemo(
    () => procedures.filter(item => !restrictedTestIds.includes(item.id)),
    [procedures, restrictedTestIds]
  );
  const availableRadiologies = useMemo(
    () => radiologies.filter(item => !restrictedTestIds.includes(item.id)),
    [radiologies, restrictedTestIds]
  );

  const updateCheckedData = (dataUpdated = [], testExists = []) => {
    const newDataLocal = [...dataUpdated];

    testExists.forEach(test => {
      const indexProject = findIndex(dataUpdated, it => it.id === test.id);

      if (indexProject < 0) return;
      const updatedProject = newDataLocal[indexProject];

      const indexTestChange = findIndex(
        newDataLocal,
        it => it.id === updatedProject.id
      );
      if (indexTestChange < 0) return;
      newDataLocal[indexTestChange] = { ...test };
    });

    return newDataLocal;
  };

  const onChangeValueCheckbox = (value, row, data, setData, setSelected) => {
    const newData = { ...row, isChecked: value };
    const dataLocalUpdated = updateCheckedData(data, [newData]);
    const newListSelected = [...dataLocalUpdated].filter(
      item => item.isChecked
    );
    setCurrentPackage && setCurrentPackage({});
    setData(dataLocalUpdated);
    setSelected(newListSelected);
  };

  useEffect(() => {
    const ignoredProcedures = procedures.filter(
      it => !it.isChecked && !it.isRequired
    );
    const ignoredRadiologies = radiologies.filter(
      it => !it.isChecked && !it.isRequired
    );
    const newIgnoredProcedures = formatIgnoredTests(ignoredProcedures);
    const newIgnoredRadiologies = formatIgnoredTests(ignoredRadiologies);

    const newIgnoredTests = mergeArray(
      newIgnoredRadiologies,
      newIgnoredProcedures,
      'testId'
    );

    setFormValue &&
      setFormValue({ ...formValue, ignoredTests: newIgnoredTests });
  }, [procedures, radiologies]);

  useEffect(() => {
    const ignoredProfiles = profiles.filter(
      it => !it.isChecked && !it.isRequired
    );

    const newIgnoredProfiles = formatIgnoredProfiles(ignoredProfiles);
    setFormValue &&
      setFormValue({ ...formValue, ignoredProfiles: newIgnoredProfiles });
  }, [profiles]);

  const mergeArray = (a, b, key) => {
    const reduced = a.filter(
      aitem => !b.find(bitem => aitem[key] === bitem[key])
    );
    return reduced.concat(b);
  };

  const formatIgnoredTests = data => {
    const newData = data.map(item => ({
      testId: item.id,
      packageId: packageDetail.id,
      projectId
    }));
    return newData;
  };
  const formatIgnoredProfiles = data => {
    const newData = data.map(item => ({
      profileId: item.id,
      packageId: packageDetail.id,
      projectId
    }));
    return newData;
  };

  useEffect(() => {
    const unMandatoryProfiles = [...profiles].filter(it => !it.isRequired);
    const unMandatoryProcedures = [...procedures].filter(it => !it.isRequired);
    const unMandatoryRadiologies = [...radiologies].filter(
      it => !it.isRequired
    );
    setUnMandatoryProfiles(unMandatoryProfiles);
    setUnMandatoryProcedures(unMandatoryProcedures);
    setUnMandatoryRadiologies(unMandatoryRadiologies);
    // eslint-disable-next-line
  }, [profiles, procedures, radiologies]);

  useEffect(() => {
    if (currentPackage && !isEmpty(currentPackage)) {
      const {
        lstRadiologies,
        lstProcedures,
        lstProfiles,
        totalMandatoryProcedure,
        totalMandatoryProfile,
        totalMandatoryRadiology
      } = currentPackage;
      let selectedProfiles = [];
      let selectedProcedures = [];
      let selectedRadiologies = [];

      let newLstRadiologies = cloneDeep(lstRadiologies);
      let newLstProcedures = cloneDeep(lstProcedures);

      // Filter test restricted by location
      // if (!isEmpty(restrictedTestIds)) {
      //   newLstProcedures = newLstProcedures?.filter(
      //     it => !restrictedTestIds?.includes(it.id)
      //   );
      //   newLstRadiologies = newLstRadiologies?.filter(
      //     it => !restrictedTestIds?.includes(it.id)
      //   );
      // }

      const unMandatoryProfiles = cloneDeep(lstProfiles)?.filter(
        it => !it.isRequired
      );
      const unMandatoryProcedures = cloneDeep(newLstProcedures)?.filter(
        it => !it.isRequired
      );
      const unMandatoryRadiologies = cloneDeep(newLstRadiologies)?.filter(
        it => !it.isRequired
      );
      // Profile
      if (unMandatoryProfiles.length === totalMandatoryProfile) {
        selectedProfiles = unMandatoryProfiles;
      } else {
        if (isEmpty(currentPackage?.ignoredProfiles)) {
          selectedProfiles = unMandatoryProfiles?.filter(value => {
            return currentPackage?.ignoredProfiles?.some(test => {
              return value.id === test.profileId;
            });
          });
        } else {
          selectedProfiles = unMandatoryProfiles?.filter(value => {
            return currentPackage?.ignoredProfiles?.every(test => {
              return value.id !== test.profileId;
            });
          });
        }
      }

      const newUnMandatoryProfiles = cloneDeep(lstProfiles)
        .map(it => {
          if (findIndex(selectedProfiles, test => test.id === it.id) !== -1)
            return { ...it, isChecked: true };
          return it;
        })
        ?.sort((a, b) => b.isRequired - a.isRequired);

      // Procedures
      if (unMandatoryProcedures.length === totalMandatoryProcedure) {
        selectedProcedures = unMandatoryProcedures;
      } else {
        if (isEmpty(currentPackage?.ignoredTests)) {
          selectedProcedures = unMandatoryProcedures?.filter(value => {
            return currentPackage?.ignoredTests?.some(test => {
              return value.id === test.testId;
            });
          });
        } else {
          selectedProcedures = unMandatoryProcedures?.filter(value => {
            return currentPackage?.ignoredTests?.every(test => {
              return value.id !== test.testId;
            });
          });
        }
      }

      const newUnMandatoryProcedures = cloneDeep(newLstProcedures)
        ?.map(it => {
          if (findIndex(selectedProcedures, test => test.id === it.id) !== -1) {
            return { ...it, isChecked: true };
          }

          return it;
        })
        ?.sort((a, b) => b.isRequired - a.isRequired);

      // Radiologies
      if (unMandatoryRadiologies.length === totalMandatoryRadiology) {
        selectedRadiologies = unMandatoryRadiologies;
      } else {
        if (isEmpty(currentPackage?.ignoredTests)) {
          selectedRadiologies = unMandatoryRadiologies?.filter(value => {
            return currentPackage?.ignoredTests?.some(test => {
              return value.id === test.testId;
            });
          });
        } else {
          selectedRadiologies = unMandatoryRadiologies?.filter(value => {
            return currentPackage?.ignoredTests?.every(test => {
              return value.id !== test.testId;
            });
          });
        }
      }

      const newUnMandatoryRadiologies = cloneDeep(newLstRadiologies)
        ?.map(it => {
          if (findIndex(selectedRadiologies, test => test.id === it.id) !== -1)
            return { ...it, isChecked: true };
          return it;
        })
        ?.sort((a, b) => b.isRequired - a.isRequired);

      setSelectedProfiles(selectedProfiles);
      setProfiles(newUnMandatoryProfiles);
      setSelectedProcedures(selectedProcedures);
      setProcedures(newUnMandatoryProcedures);
      setSelectedRadiologies(selectedRadiologies);
      setRadiologies(newUnMandatoryRadiologies);
    }
  }, [currentPackage, restrictedTestIds]);

  return (
    <>
      <Grid container>
        {!isEmpty(profiles) && !isEmpty(unMandatoryProfiles) && (
          <>
            <Grid item xs={12}>
              <Typography className={classes.title}>
                {`Profiles (Choose only ${packageDetail.totalMandatoryProfile} tests)`}
              </Typography>
            </Grid>
            {profiles.map(item => (
              <Grid item xs={6} key={item.id}>
                {item.isRequired ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={
                          item.isRequired ? classes.iconDisabled : null
                        }
                        icon={<CheckboxDefault />}
                        checkedIcon={<CheckboxDisabledActive />}
                        checked={item.isRequired}
                        disabled={item.isRequired}
                        color="primary"
                        name={item.name}
                      />
                    }
                    label={<CustomTooltip content={item.name} />}
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckboxDefault />}
                        checkedIcon={<CheckboxActive />}
                        checked={item.isChecked}
                        disabled={
                          !item.isChecked &&
                          selectedProfiles.length ===
                            packageDetail.totalMandatoryProfile
                        }
                        color="primary"
                        name={item.name}
                        onChange={e =>
                          onChangeValueCheckbox(
                            e.target.checked,
                            item,
                            profiles,
                            setProfiles,
                            setSelectedProfiles
                          )
                        }
                      />
                    }
                    label={<CustomTooltip content={item.name} />}
                  />
                )}
              </Grid>
            ))}
          </>
        )}
        {!isEmpty(procedures) && !isEmpty(unMandatoryProcedures) && (
          <>
            <Grid item xs={12}>
              <Typography className={classes.title}>
                {`Procedures (Choose only ${packageDetail.totalMandatoryProcedure} tests)`}
              </Typography>
            </Grid>

            {availableProcedures.map(item => (
              <Grid item xs={6} key={item.id}>
                {item.isRequired ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={
                          item.isRequired ? classes.iconDisabled : null
                        }
                        icon={<CheckboxDefault />}
                        checkedIcon={<CheckboxDisabledActive />}
                        checked={item.isRequired}
                        disabled={item.isRequired}
                        color="primary"
                        name={item.name}
                      />
                    }
                    label={<CustomTooltip content={item.name} />}
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckboxDefault />}
                        checkedIcon={<CheckboxActive />}
                        checked={item.isChecked}
                        disabled={
                          !item.isChecked &&
                          selectedProcedures.length ===
                            packageDetail.totalMandatoryProcedure
                        }
                        color="primary"
                        name={item.name}
                        onChange={e =>
                          onChangeValueCheckbox(
                            e.target.checked,
                            item,
                            procedures,
                            setProcedures,
                            setSelectedProcedures
                          )
                        }
                      />
                    }
                    label={<CustomTooltip content={item.name} />}
                  />
                )}
              </Grid>
            ))}
          </>
        )}

        {!isEmpty(radiologies) && !isEmpty(unMandatoryRadiologies) && (
          <>
            <Grid item xs={12}>
              <Typography className={classes.title}>
                {`Radiologies (Choose only ${packageDetail.totalMandatoryRadiology} tests)`}
              </Typography>
            </Grid>
            {availableRadiologies.map(item => (
              <Grid item xs={6} key={item.id}>
                {item.isRequired ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={
                          item.isRequired ? classes.iconDisabled : null
                        }
                        icon={<CheckboxDefault />}
                        checkedIcon={<CheckboxDisabledActive />}
                        checked={item.isRequired}
                        disabled={item.isRequired}
                        color="primary"
                        name={item.name}
                      />
                    }
                    label={<CustomTooltip content={item.name} />}
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckboxDefault />}
                        checkedIcon={<CheckboxActive />}
                        checked={item.isChecked}
                        disabled={
                          !item.isChecked &&
                          selectedRadiologies.length ===
                            packageDetail.totalMandatoryRadiology
                        }
                        color="primary"
                        name={item.name}
                        onChange={e =>
                          onChangeValueCheckbox(
                            e.target.checked,
                            item,
                            radiologies,
                            setRadiologies,
                            setSelectedRadiologies
                          )
                        }
                      />
                    }
                    label={<CustomTooltip content={item.name} />}
                  />
                )}
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};

export default PackageItem;
