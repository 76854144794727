/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  container: {
    color: '#192637',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #e9e9e9'
  },
  head: {
    fontWeight: 600,
    padding: '10px 20px',
    fontSize: '18px',
    lineHeight: '30px'
  },
  mainBody: {
    margin: 20
  }
});

const DetailLocation = props => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {props.title && (
        <header className={classes.headerContainer}>
          <div className={classes.head}>{props.title}</div>
        </header>
      )}
      <main className={classes.mainBody}>{props.children}</main>
    </div>
  );
};

DetailLocation.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default DetailLocation;
