import { isArray, find, isInteger } from 'lodash';

export const generateKeyCompare = (projectItemId, id) => {
  return `${projectItemId}-${id}`;
};
export const transformDataTest = dataTransform => {
  const Tests = [];
  const Measurements = [];
  const Procedures = [];
  const Radiologies = [];
  const Vaccinations = [];
  const Reviews = [];
  dataTransform.forEach(it => {
    switch (it.category) {
      case 'Tests':
        Tests.push(it);
        break;
      case 'Measurements':
        Measurements.push(it);
        break;
      case 'Procedures':
        Procedures.push(it);
        break;
      case 'Radiologies':
        Radiologies.push(it);
        break;
      case 'Vaccinations':
        Vaccinations.push(it);
        break;
      case 'Reviews':
        Reviews.push(it);
        break;
      default:
        break;
    }
  });

  const newListTest = {
    Tests,
    Measurements,
    Procedures,
    Radiologies,
    Vaccinations,
    Reviews
  };

  return newListTest;
};

export const formatCustomCategories = (
  lstProjectCustomFields,
  fieldArrValue
) => {
  return (lstProjectCustomFields = lstProjectCustomFields
    .map(item =>
      fieldArrValue.includes(item.customFieldType)
        ? {
            ...item,
            value: JSON.parse(item.value)
          }
        : item
    )
    .map(item => ({
      ...item,
      value: isArray(item.value) ? item.value.join(', ') : item.value
    })));
};

export const formatListTest = (listTests, subServices) => {
  let filteredTests = {};
  subServices.forEach(item => {
    filteredTests[item] = find(listTests, ['category', item])?.items || [];
  });
  return filteredTests;
};

export const formatTotalPrice = (price, currencySymbol = '$') => {
  const newPrice = isInteger(price)
    ? parseInt(price)
    : parseFloat(price).toFixed(2);
  if (currencySymbol && newPrice > 0) return `${currencySymbol}${newPrice}`;
  else return `${currencySymbol}0`;
};

export const generateStaffType = (staffType, totalCost) => {
  let newTotal = totalCost;
  if (staffType)
    staffType.forEach(item => {
      switch (item.staffValueType) {
        case 'Percent':
          newTotal = totalCost - totalCost * (item.subsidy / 100);
          break;
        case 'Value':
          newTotal = totalCost - item.subsidy;
          break;
        default:
          break;
      }
    });

  return newTotal > 0 ? newTotal : 0;
};

export const revertTotalCostStaffType = (staffType, totalCost) => {
  let newTotal = totalCost;
  if (staffType)
    staffType.forEach(item => {
      switch (item.staffValueType) {
        case 'Percent':
          newTotal = Number(totalCost) * (1 / (Number(item.subsidy) / 100));
          break;
        case 'Value':
          newTotal = Number(totalCost) + Number(item.subsidy);
          break;
        default:
          break;
      }
    });

  return newTotal > 0 ? newTotal : 0;
};

export const formatQueueNumber = queueNo => {
  const newQueueNo = queueNo?.toString().padStart(4, '0');
  return newQueueNo;
};

export const generateTestListData = data => {
  let newData = [];
  data.forEach(item => {
    item.total ? (newData = newData.concat(item.total)) : newData.push(item);
  });
  return newData;
};
