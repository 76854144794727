import { AddFile, AddImage, SendMessage } from '../../assets/svg';
import {
  sendMessage,
  getMessageHistory,
  formatDataMessage,
  receiveMessage,
  uploadToFirebase,
  createChannel,
} from '../../firebase';
import { PreSendImage, PreSendDoc } from '../../module/chat/PreSendFiles';
import { ENTER_KEY } from '../../module/common';
import { ChatContainer } from './styled';
import customToast from '@/new-components/CustomNotification';
import { Input } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { includes, isEmpty } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { MessageList } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { useSelector } from 'react-redux';

const ChatDrawer = ({
  isOpen,
  channelInfo,
  onClose,
  setHasNewMessage,
  partner,
  setChatChannel,
  updateListChannels,
}) => {
  const [messages, setMessages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [chatMessage, setChatMessage] = useState('');

  const messagesRef = useRef();
  messagesRef.current = messages;
  const callId = useSelector((state) => state.waitingRoom.callId);
  const startCallTime = useSelector((state) => state.waitingRoom.startCallTime);
  const [isListenNewMess, setIsListen] = useState(false);

  const onReceiveNewMess = (data) => {
    if (!isOpen && includes(channelInfo.members, data.senderId))
      setHasNewMessage(true);
    let newMess = formatDataMessage(data);
    setMessages([newMess, ...messagesRef.current]);
    scrollToBottom();
  };

  const getMessage = async () => {
    if (!isEmpty(channelInfo)) {
      receiveMessage(channelInfo.id, onReceiveNewMess);
      let rs = await getMessageHistory(
        channelInfo.id,
        formatDataMessage,
        callId,
        startCallTime
      );
      setMessages([...rs]);
      scrollToBottom();
    }
  };

  const getReverseMessageList = () => {
    const newMessages = [...messages];
    return newMessages.reverse();
  };

  const scrollToBottom = () => {
    const child = document.getElementById('container');
    const chatContainer = child ? child.parentElement : null;
    if (!chatContainer) return;
    chatContainer.scrollTop = chatContainer.scrollHeight;
  };

  useEffect(() => {
    if (channelInfo.id && !isListenNewMess) {
      getMessage();
      setIsListen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelInfo]);

  const onClickAddDocumentIcon = () => {
    document.getElementById('add_file').value = null;
    document.getElementById('add_file').click();
  };

  const onUploadDocument = () => {
    const doc = document.getElementById('add_file').files[0];
    if (!doc) return;
    setUploadedFiles([
      ...uploadedFiles,
      {
        file: doc,
        type: 'file',
      },
    ]);
  };

  const onClickAddImageIcon = () => {
    document.getElementById('add_image').value = null;
    document.getElementById('add_image').click();
  };

  const onUploadImage = () => {
    const image = document.getElementById('add_image').files[0];
    if (!image) return;
    if (!['jpeg', 'jpg', 'png'].includes(image.type.split('/')[1]))
      return customToast('error', 'Invalid support file');

    let fileSource;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      fileSource = fileReader.result;
      setUploadedFiles([
        ...uploadedFiles,
        {
          file: image,
          type: 'photo',
          fileSource,
        },
      ]);
    };
    fileReader.readAsDataURL(image);
  };

  const removeAttatchMent = (index) => {
    const newArray = [...uploadedFiles];
    newArray.splice(index, 1);
    setUploadedFiles(newArray);
  };

  const handleSendMessage = () => {
    if (chatMessage.trim() || uploadedFiles.length !== 0) {
      onSend(chatMessage);
    }
    setChatMessage('');
    setUploadedFiles([]);
  };

  const onSend = async (message) => {
    uploadedFiles.forEach((file) => {
      uploadToFirebase({
        id: channelInfo.id,
        uploadedFile: file,
        callBack: async (downloadUrl) => {
          let info = channelInfo;
          if (isEmpty(info))
            info = await createChannel(
              partner.id,
              partner.name,
              partner.imageUrl
            );
          updateListChannels();

          setChatChannel(info);
          sendMessage(
            info.id,
            file.type === 'photo' ? null : file.file.name,
            info.members,
            {
              fileName: file.file.name,
              type: file.type,
              fileUrl: file.type === 'file' ? downloadUrl : '',
              imageUrl: file.type === 'photo' ? downloadUrl : '',
              mime: file.file.type,
            },
            callId
          );
        },
      });
    });
    if (message.trim() !== '') {
      let info = channelInfo;
      if (isEmpty(info))
        info = await createChannel(partner.id, partner.name, partner.imageUrl);
      updateListChannels();
      setChatChannel(info);
      sendMessage(info.id, message, info.members, undefined, callId);
    }
    scrollToBottom();
  };

  return (
    <ChatContainer id="container">
      <div
        style={{
          display: 'flex',
          padding: 20,
          justifyContent: 'space-between',
          borderBottom: '1px #E9E9E9 solid',
          position: 'sticky',
          top: 0,
          zIndex: 2,
          backgroundColor: 'white',
        }}
      >
        <span style={{ fontSize: 18, fontWeight: 600 }}>Chat</span>
        <ClearIcon style={{ cursor: 'pointer' }} onClick={onClose} />
      </div>
      <MessageList
        className="message-list"
        toBottomHeight={'100%'}
        downButtonBadge={true}
        dataSource={getReverseMessageList()}
        onClick={(item) => {
          if (item.data.uri) window.open(item.data.uri);
        }}
      />
      <div
        style={{
          height: uploadedFiles.length > 0 ? 150 : 65,
          borderTop: '1px #E9E9E9 solid',
          position: 'sticky',
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            backgroundColor: 'white',
            alignItems: 'center',
            padding: '5px 15px 0px 15px',
            overflow: 'auto',
          }}
          hidden={uploadedFiles.length === 0}
        >
          {uploadedFiles.map((file, index) => {
            switch (file.type) {
              case 'photo':
                return (
                  <PreSendImage
                    key={index}
                    image={file.file}
                    fileSource={file.fileSource}
                    removeAttatchment={() => removeAttatchMent(index)}
                  />
                );
              case 'file':
                return (
                  <PreSendDoc
                    key={index}
                    doc={file.file}
                    removeAttatchment={() => removeAttatchMent(index)}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
        <div
          style={{
            display: 'flex',
            padding: '9px 20px 12px 21px',
            justifyContent: 'center',
            backgroundColor: 'white',
            alignItems: 'center',
          }}
        >
          <AddFile
            style={{ marginRight: 20, cursor: 'pointer' }}
            onClick={onClickAddDocumentIcon}
          />
          <input
            id="add_file"
            type="file"
            accept=".pdf,.doc,.docx"
            hidden
            onChange={onUploadDocument}
          />
          <AddImage
            style={{ marginRight: 20, cursor: 'pointer' }}
            onClick={onClickAddImageIcon}
          />
          <input
            id="add_image"
            type="file"
            accept=".jpeg,.jpg,.png"
            hidden
            onChange={onUploadImage}
          />
          <Input
            placeholder="Type Something..."
            className="input-message"
            multiline={false}
            autoFocus={true}
            disableUnderline={true}
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
            onKeyPress={(e) => {
              // Press Shift with Enter
              if (e.shiftKey && e.charCode === ENTER_KEY) {
                return true;
              }
              if (e.charCode === ENTER_KEY) {
                handleSendMessage();
              }
            }}
          />
          <SendMessage
            style={{ marginLeft: 30, cursor: 'pointer' }}
            onClick={handleSendMessage}
          />
        </div>
      </div>
    </ChatContainer>
  );
};

export default ChatDrawer;
