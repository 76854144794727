import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14
  }
}))(Tooltip);

const CustomTooltip = ({ content, maxLength, placement, className }) => {
  if (!content) return null;

  if (content.length <= maxLength) {
    return <span>{content}</span>;
  }

  const shortContent = content.substring(0, maxLength) + '...';

  return (
    <LightTooltip
      title={content}
      placement={placement}
      style={{ cursor: 'pointer' }}
      className={className}
    >
      <span>{shortContent}</span>
    </LightTooltip>
  );
};

CustomTooltip.defaultProps = {
  content: '',
  maxLength: 30,
  placement: 'top'
};

CustomTooltip.propTypes = {
  content: PropTypes.string,
  maxLength: PropTypes.number,
  placement: PropTypes.string
};

export default CustomTooltip;
