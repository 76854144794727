import React from 'react';
import { capitalize } from 'lodash';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import clsx from 'clsx';
import { CheckmarkCircleIcon } from '@/assets/svg';

const useStyles = makeStyles({
  root: {
    borderRight: '1px solid  #f1f1f1'
  },
  tabs: {
    height: '100%',
    padding: '20px 16px'
  },
  tabLabel: {
    marginRight: 4,
    marginBottom: 0,
    lineHeight: '27px'
  },
  tabNumber: {
    minWidth: 20,
    borderRadius: 7,
    backgroundColor: '#F6F6F6'
  },
  taChecked: {
    minWidth: 20,
    verticalAlign: 'middle'
  },
  checkedIcon: {
    color: '#0B8E5E',
    width: 24,
    height: 24,
    verticalAlign: 'middle'
  }
});

const CustomTabItem = withStyles(theme => ({
  root: {
    width: 170,
    height: 44,
    borderRadius: 12,
    padding: '12px 13px',

    color: '#999999',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,

    '& .MuiTab-wrapper': {
      alignItems: 'flex-start'
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      '& .number-selected': {
        backgroundColor: '#FFFFFF'
      }
    }
  }
}))(props => <Tab disableRipple {...props} />);

const CustomTabs = ({
  listTab,
  value,
  onChange,
  renderLabel,
  renderKey,
  renderChipLabel = 'chipLabel',
  renderChipCustomSurvey = 'chipCustomSurvey',
  variant = 'scrollable',
  errorsIndex = [],
  isCapitalize = false,
  orientation,
  ...props
}) => {
  const classes = useStyles();

  const onShowLabel = (item, index) => {
    let label = item[renderLabel] || item;
    if (isCapitalize) label = capitalize(label);
    const error = errorsIndex.includes(index);

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <p
          className={classes.tabLabel}
          style={{
            color: error ? 'red' : 'inherit'
          }}
        >
          {label}
        </p>
        {item[renderChipLabel] > 0 && (
          <div className={clsx(classes.tabNumber, 'number-selected')}>
            {item[renderChipLabel]}
          </div>
        )}
        {item[renderChipCustomSurvey] > 0 && (
          <div className={clsx(classes.taChecked)}>
            <CheckmarkCircleIcon className={classes.checkedIcon} />
          </div>
        )}

        {error && (
          <span style={{ paddingTop: 6 }}>
            <ErrorOutlineIcon color="error" fontSize="small" />
          </span>
        )}
      </Box>
    );
  };

  return (
    <Box className={classes.root} {...props}>
      <Tabs
        value={value}
        onChange={onChange}
        textColor="primary"
        variant={variant}
        orientation={orientation}
        className={classes.tabs}
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
      >
        {listTab.map((item, index) => (
          <CustomTabItem
            label={onShowLabel(item, index)}
            key={item[renderKey] || item}
          />
        ))}
      </Tabs>
    </Box>
  );
};
export default CustomTabs;
