import serviceDispatcher from '@/module/services/action';
import React from 'react';
import ServiceModalContainer from '../../common/ServiceModalContainer';
import ProcedureForm from './ProcedureForm';
import { PROCEDURE_SERVICES_STEPS } from '@/module/services/constants';

const ProcedureModalContainer = props => (
  <ServiceModalContainer
    {...{
      ...props,
      prefixName: 'Procedure',
      category: 'Procedures',
      createDispatcher: serviceDispatcher.createTest,
      editDispatcher: serviceDispatcher.editTest,
      getServiceByID: serviceDispatcher.getServiceByID,
      schema: 'serviceSchema',
      requireLabCode: false,
      requireLoinc: false,
      ServicesForm: ProcedureForm,
      listValidKeys: ['name'],
      steps: PROCEDURE_SERVICES_STEPS
    }}
  />
);

export default ProcedureModalContainer;
