import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { withStyles } from '@material-ui/core';

const CustomTab = withStyles(theme => ({
  root: {
    minWidth: 130,
    minHeight: 'unset',
    height: 'auto',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(15),

    marginRight: 0,
    borderRadius: 6,
    backgroundColor: theme.palette.grey.background,

    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF'
    },
    '&:focus': {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);

const CustomTabs = withStyles(theme => ({
  root: {
    padding: 4,
    borderRadius: 6,
    backgroundColor: theme.palette.grey.background
  }
}))(Tabs);

const GroupToolbar = ({ tabs, setCurrentTab, currentTab }) => {
  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gridGap={12}>
          <CustomTabs
            value={currentTab}
            onChange={(e, val) => setCurrentTab(val)}
            indicatorColor="none"
            variant="scrollable"
          >
            {tabs.map(item => (
              <CustomTab
                label={item['category'] || item}
                key={item['category'] || item}
              />
            ))}
          </CustomTabs>
        </Box>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;
