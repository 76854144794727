import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  InputAdornment,
  IconButton,
  Box,
  CircularProgress
} from '@material-ui/core';
import {
  CustomSearchInputModal,
  FilterContainer,
  PackageTableBody,
  PackageTableHeader,
  PackageTableRow,
  RestrictPackageContainer,
  RestrictPackageDescription,
  RestrictPackageTitle,
  RestrictPackageWrapper
} from '../../createAndEditLocation.styled';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import CustomTooltip from '@/new-components/CustomTooltip';
import { CloseCircle, SearchIcon } from '@/assets/svg';
import { IOSSwitch } from '../mainScreen.styled';

import { FILTER_RESTRICT_STATUSES } from '../../constants';
import { capitalize, isEmpty, lowerCase } from 'lodash';
import allScreeningsDispatcher from '@/module/all-screenings/action';

import { FixedSizeList, areEqual } from 'react-window';

const Row = memo(function Row({ data, index, style }) {
  const { items, relatedPackages, selectedList, setSelectedList } = data;
  const item = items[index];

  return (
    <PackageTableRow container key={item.id} style={style}>
      <Grid item xs={10}>
        <CustomTooltip content={item.name} />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1}>
        <IOSSwitch
          color="primary"
          size="medium"
          disabled={relatedPackages?.includes(item.id)}
          checked={
            selectedList?.includes(item.id) ||
            relatedPackages?.includes(item.id)
          }
          onChange={() => {
            if (selectedList?.includes(item.id))
              setSelectedList(
                selectedList?.filter(selected => selected !== item.id)
              );
            else setSelectedList([...selectedList, item.id]);
          }}
        />
      </Grid>
    </PackageTableRow>
  );
}, areEqual);

const RestrictServicesForm = ({
  isPackage = false,
  name,
  setName,
  status,
  setStatus,
  listData,
  selectedList,
  setSelectedList,
  prefix,
  prefixPlural,
  placeholder,
  restrictedTestIds = []
}) => {
  const [relatedPackages, setRelatedPackages] = useState([]);
  const [loadingRelatedPackages, setLoadingRelatedPackages] = useState(false);

  const listDataFilter = useMemo(() => {
    if (status === 'Restricted')
      return listData
        .filter(
          item =>
            selectedList?.includes(item.id) ||
            relatedPackages?.includes(item.id)
        )
        .filter(item => lowerCase(item.name).includes(lowerCase(name)));
    return listData.filter(item =>
      lowerCase(item.name).includes(lowerCase(name))
    );
  }, [status, listData, selectedList, name]);

  useEffect(() => {
    if (isPackage) {
      setLoadingRelatedPackages(true);
      allScreeningsDispatcher.getRelatedPackagesByIds(
        {
          isRequired: true,
          testIds: restrictedTestIds
        },
        relatedPackagesData => {
          setLoadingRelatedPackages(false);
          setRelatedPackages(relatedPackagesData);
        }
      );
    }
  }, [isPackage, restrictedTestIds]);

  const itemData = useMemo(
    () => ({
      items: listDataFilter,
      relatedPackages,
      selectedList,
      setSelectedList
    }),
    [listDataFilter, relatedPackages, selectedList]
  );

  return (
    <RestrictPackageWrapper>
      <RestrictPackageTitle>Restrict {prefix}</RestrictPackageTitle>
      <RestrictPackageDescription>
        Restricted {prefixPlural} will not be available at this location.
      </RestrictPackageDescription>
      <FilterContainer>
        <Box minWidth={144}>
          <CustomSelectContainer
            isFullWidth
            data={FILTER_RESTRICT_STATUSES}
            isMutiple={false}
            renderValue={<>{status || 'Status'}</>}
            filterValue={status}
            onChange={e => setStatus(e.target.value)}
            height={'40px'}
          />
        </Box>
        <CustomSearchInputModal
          placeholder={placeholder}
          value={name || ''}
          fullWidth
          onChange={e => setName(e.target.value)}
          variant="outlined"
          allowClear
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton
                    style={{
                      visibility: !isEmpty(name) ? 'visible' : 'hidden'
                    }}
                    onClick={() => setName('')}
                  >
                    <CloseCircle
                      height={20}
                      width={20}
                      style={{
                        color: '#666666'
                      }}
                    />
                  </IconButton>
                </InputAdornment>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </>
            )
          }}
        />
      </FilterContainer>

      <RestrictPackageContainer>
        <PackageTableHeader container>
          <Grid item xs={10}>
            {capitalize(prefix)}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            Status
          </Grid>
        </PackageTableHeader>
        <PackageTableBody>
          {loadingRelatedPackages && <CircularProgress color="primary" />}
          {!loadingRelatedPackages && !isEmpty(listDataFilter) ? (
            <FixedSizeList
              itemCount={listDataFilter.length}
              itemData={itemData}
              itemSize={47}
              height={500}
              width={630}
            >
              {Row}
            </FixedSizeList>
          ) : (
            <PackageTableRow container>
              <Grid item xs={10}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
            </PackageTableRow>
          )}
        </PackageTableBody>
      </RestrictPackageContainer>
    </RestrictPackageWrapper>
  );
};

export default RestrictServicesForm;
