/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import customToast from '@/new-components/CustomNotification';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import authDispatcher from '../../action/auth';
import { countries } from '../../../../countries';
import { get, startsWith } from 'lodash';

import LoadingButton from '../../../../components/LoadingButton';
import { ENTER_KEY, BACK_KEY, DEFAULT_INPUT_OTP } from '../../../common';
import { getNumberInfo } from '@/helpers';
import Progress from 'react-progress-2';

const LoginOTP = ({
  loginInfo = {},
  didSendOTP,
  reSendOTP,
  isGoogleLogin,
  phone,
  countryCode,
  onUpdateInfo,
  id = '1'
}) => {
  const [firstChar, setFirstChar] = useState(null);
  const [secondChar, setSecondChar] = useState(null);
  const [thirdChar, setThirdChar] = useState(null);
  const [fourthChar, setFourthChar] = useState(null);
  const [fifthChar, setFifthChar] = useState(null);
  const [sixthChar, setSixthChar] = useState(null);
  const [showVerifyLoading, setVerifyLoading] = useState(false);

  useEffect(() => {
    if (isInit()) document.getElementById('first-char' + id).focus();
    else if (firstChar) document.getElementById('second-char' + id).focus();
  }, [firstChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (secondChar) document.getElementById('third-char' + id).focus();
    else document.getElementById('first-char' + id).focus();
  }, [secondChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (thirdChar) document.getElementById('fourth-char' + id).focus();
    else document.getElementById('second-char' + id).focus();
  }, [thirdChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (fourthChar) document.getElementById('fifth-char' + id).focus();
    else document.getElementById('third-char' + id).focus();
  }, [fourthChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (fifthChar) document.getElementById('sixth-char' + id).focus();
    else document.getElementById('fourth-char' + id).focus();
  }, [fifthChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (!sixthChar) document.getElementById('fifth-char' + id).focus();
  }, [sixthChar]);

  const formatMobile = isGoogleLogin =>
    isGoogleLogin ? getNumberInfo(phone, countryCode)[1] : loginInfo.mobile;

  const isInit = () =>
    !(
      firstChar ||
      secondChar ||
      thirdChar ||
      fourthChar ||
      fifthChar ||
      sixthChar
    );

  const verifyOTP = () => {
    if (!didSendOTP) return;
    Progress.show();
    setVerifyLoading(true);
    const inputOTP = `${firstChar}${secondChar}${thirdChar}${fourthChar}${fifthChar}${sixthChar}`;
    window.confirmationResult
      .confirm(inputOTP)
      .then(() => {
        Progress.hide();
        setVerifyLoading(false);
        if (!isGoogleLogin)
          authDispatcher.loginSuccess(loginInfo, loginInfo.email);
        else onUpdateInfo();
      })
      .catch(err => {
        Progress.hide();
        setVerifyLoading(false);
        customToast('error', 'Wrong OTP');
        resetInput();
      });

    // Twilio otp
    // if (
    //   process.env.REACT_APP_SECRET_NAME &&
    //   process.env.REACT_APP_SECRET_NAME === 'localtest'
    // ) {
    //   if (inputOTP === DEFAULT_INPUT_OTP) {
    //     Progress.hide();
    //     setVerifyLoading(false);
    //     if (!isGoogleLogin)
    //       authDispatcher.loginSuccess(loginInfo, loginInfo.email);
    //     else onUpdateInfo();
    //   } else {
    //     Progress.hide();
    //     setVerifyLoading(false);
    //     customToast('error','Wrong OTP');
    //     resetInput();
    //   }
    // } else
    //   authDispatcher.verifyOTP(
    //     {
    //       otpPhoneNumber: formatMobile(isGoogleLogin),
    //       otp: inputOTP
    //     },
    //     result => {
    //       if (result) {
    //         Progress.hide();
    //         setVerifyLoading(false);
    //         if (!isGoogleLogin)
    //           authDispatcher.loginSuccess(loginInfo, loginInfo.email);
    //         else onUpdateInfo();
    //       } else {
    //         Progress.hide();
    //         setVerifyLoading(false);
    //         customToast('error','Wrong OTP');
    //         resetInput();
    //       }
    //     }
    //   );
  };

  const resetInput = () => {
    setFirstChar('');
    setSecondChar('');
    setThirdChar('');
    setFourthChar('');
    setFifthChar('');
    setSixthChar('');
    document.getElementById('first-char' + id).focus();
  };

  const onChangeValue = setState => event => {
    if (event.target.value.length <= 1) setState(event.target.value);
    else setState(event.target.value.substring(1));
  };

  const onBackKey = setState => e => {
    if (!e.target.value) setState(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 395,
        width: 380,
        borderRadius: 10,
        backgroundColor: 'white',
        padding: '40px 20px'
      }}
    >
      <Typography
        style={{
          fontSize: 24,
          letterSpacing: 0,
          fontWeight: 600
        }}
      >
        OTP Verification
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
        <Typography
          style={{
            fontSize: 14,
            letterSpacing: 0,
            color: '#8F9BA9',
            marginRight: 5
          }}
        >
          Enter the OTP code that was sent to
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            letterSpacing: 0,
            color: '#192637',
            fontWeight: 600
          }}
        >
          {formatMobile(isGoogleLogin)}
        </Typography>
      </div>
      <div style={{ marginTop: 40, display: 'flex', alignItems: 'center' }}>
        <TextField
          id={'first-char' + id}
          value={firstChar || ''}
          onChange={onChangeValue(setFirstChar)}
          inputProps={{
            style: {
              fontSize: 24,
              fontWeight: 600,
              width: 40,
              textAlign: 'center',
              marginLeft: 0
            }
          }}
        />
        <TextField
          id={'second-char' + id}
          value={secondChar || ''}
          style={{ marginLeft: 10 }}
          onChange={onChangeValue(setSecondChar)}
          onKeyUp={e => e.keyCode === BACK_KEY && onBackKey(setSecondChar)(e)}
          inputProps={{
            style: {
              fontSize: 24,
              fontWeight: 600,
              width: 40,
              textAlign: 'center',
              marginLeft: 0
            }
          }}
        />
        <TextField
          id={'third-char' + id}
          value={thirdChar || ''}
          style={{ marginLeft: 10 }}
          onChange={onChangeValue(setThirdChar)}
          onKeyUp={e => e.keyCode === BACK_KEY && onBackKey(setThirdChar)(e)}
          inputProps={{
            style: {
              fontSize: 24,
              fontWeight: 600,
              width: 40,
              textAlign: 'center',
              marginLeft: 0
            }
          }}
        />
        <TextField
          id={'fourth-char' + id}
          value={fourthChar || ''}
          style={{ marginLeft: 10 }}
          onChange={onChangeValue(setFourthChar)}
          onKeyUp={e => e.keyCode === BACK_KEY && onBackKey(setFourthChar)(e)}
          inputProps={{
            style: {
              fontSize: 24,
              fontWeight: 600,
              width: 40,
              textAlign: 'center',
              marginLeft: 0
            }
          }}
        />
        <TextField
          id={'fifth-char' + id}
          value={fifthChar || ''}
          style={{ marginLeft: 10 }}
          onChange={onChangeValue(setFifthChar)}
          onKeyUp={e => e.keyCode === BACK_KEY && onBackKey(setFifthChar)(e)}
          inputProps={{
            style: {
              fontSize: 24,
              fontWeight: 600,
              width: 40,
              textAlign: 'center',
              marginLeft: 0
            }
          }}
        />
        <TextField
          id={'sixth-char' + id}
          value={sixthChar || ''}
          style={{ marginLeft: 10 }}
          onChange={onChangeValue(setSixthChar)}
          onKeyUp={e => e.keyCode === BACK_KEY && onBackKey(setSixthChar)(e)}
          onKeyDown={e => {
            if (e.keyCode === ENTER_KEY) verifyOTP();
          }}
          inputProps={{
            style: {
              fontSize: 24,
              fontWeight: 600,
              width: 40,
              textAlign: 'center',
              marginLeft: 0
            }
          }}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 20 }}>
        <Typography
          style={{
            fontSize: 14,
            letterSpacing: 0,
            color: '#8F9BA9',
            marginRight: 7
          }}
        >
          Didn't receive OTP?
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            letterSpacing: 0,
            color: '#AD5E99',
            fontWeight: 600,
            cursor: 'pointer'
          }}
          onClick={() => {
            reSendOTP();
            resetInput();
          }}
        >
          Resend
        </Typography>
      </div>
      <LoadingButton
        key={didSendOTP}
        showLoader={showVerifyLoading}
        style={{ marginTop: 60 }}
        onClick={verifyOTP}
        disabled={
          !(
            firstChar &&
            secondChar &&
            thirdChar &&
            fourthChar &&
            fifthChar &&
            sixthChar
          )
        }
      >
        Verify
      </LoadingButton>
    </div>
  );
};

export default LoginOTP;
