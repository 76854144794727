import bookingAppointmentDispatcher from './actions';

const transformToCamelCase = obj => {
  if (!obj) return null;
  let newObj = {};
  for (const [key, value] of Object.entries(obj)) {
    const newKey = key[0].toLowerCase() + key.slice(1);
    newObj[newKey] = value;
  }
  return newObj;
};

const initialState = {
  openModalAcceptCall: false,
  calling: false,
  isMuteAudio: false,
  isMuteVideo: false,
  isMuteSpeaker: false,
  isSharing: false,
  showChat: false,
  showNRIC: false,
  patientId: null,
  memberAppointment: null,
  isInitSuccess: false,
  isRejoinCall: false,
  isInitLocalStream: false,
  openConsultModal: false,
  openLoadingAcceptance: false,
  reconnectRejoinCall: false
};

const appointmentCallReducer = bookingAppointmentDispatcher(initialState, {
  [bookingAppointmentDispatcher.setState]: (_, payload) => {
    const { name, value } = payload;
    return {
      [name]: value
    };
  },
  [bookingAppointmentDispatcher.updateDataAppointment]: (_, payload) => {
    return { ...transformToCamelCase(payload.data) };
  },
  [bookingAppointmentDispatcher.resetState]: () => {
    const { openConsultModal, ...restParams } = initialState;
    return { ...restParams };
  },
  [bookingAppointmentDispatcher.getMemberAppointmentSuccess]: (
    state,
    payload
  ) => ({
    memberAppointment: payload.data
  })
});

export default appointmentCallReducer;
