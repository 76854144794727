import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import React, { useState } from 'react';

export const TYPE_CHART_DATA = [
  { key: 'Total', value: 'Total' },
  { key: 'Average', value: 'Average' }
];

const FilterConsultAverageChart = ({ filterOptions, setFilterOptions }) => {
  const [currentType, setCurrentType] = useState('Total');

  return (
    <CustomSelectContainer
      isFullWidth
      filterValue={currentType}
      data={TYPE_CHART_DATA}
      renderValue={currentType}
      isMutiple={false}
      placeholder="Select"
      colorInput="#000000de"
      onChange={e => {
        setFilterOptions({
          ...filterOptions,
          isAverage: e.target.value === 'Average'
        });
        setCurrentType(e.target.value);
      }}
      clearable={false}
      width={112.5}
    />
  );
};

export default FilterConsultAverageChart;
