import { synthesize } from 'redux-dispatcher';
import { createDownloadableLink } from '../../../helpers';

const mapDispatchToAC = {
  showHideLoadingAction: payload => payload,
  getStudioClassList: (
    search,
    paging,
    sortOption = {},
    filterOption = {}
  ) => async ({ Api, getState }) => {
    const pagingData = paging || getState().studioClass.paging;
    const { pageIndex, pageSize } = pagingData;

    const { result, status } = await Api.post(`Workouts/filter`, {
      pageIndex,
      pageSize,
      search,
      ...sortOption,
      ...filterOption
    });
    status === 200 && StudioClassDispatcher.getStudioClassListSuccess(result);
  },
  getStudioClassListSuccess: payload => payload,

  getDurationIntensity: () => async ({ Api }) => {
    const { result, status } = await Api.get(`Workouts/duration_intensity`);
    status === 200 && StudioClassDispatcher.getDurationIntensitySuccess(result);
  },
  getDurationIntensitySuccess: payload => payload,

  getCategoryStudioClass: () => async ({ Api }) => {
    const { result, status } = await Api.post(`Categories/filter`, {
      categoryType: 'StudioClass'
    });
    status === 200 &&
      StudioClassDispatcher.getCategoryStudioClassSuccess(result);
  },
  getCategoryStudioClassSuccess: payload => payload,

  getAvailableTrainer: () => async ({ Api }) => {
    const { result, status } = await Api.get(`Workouts/trainer`);
    status === 200 && StudioClassDispatcher.getAvailableTrainerSuccess(result);
  },
  getAvailableTrainerSuccess: payload => payload,

  getStudio: () => async ({ Api }) => {
    const { result, status } = await Api.post(`Studios/filter`, {});
    status === 200 && StudioClassDispatcher.getStudioSuccess(result);
  },
  getStudioSuccess: payload => payload,

  getStudioClassById: (id, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.get(`Workouts/${id}`);
    status === 200 && callbackSuccess(result);
  },

  addStudioClass: (payload, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    const { result, status, message } = await Api.post(
      `Workouts/live-stream`,
      payload
    );
    StudioClassDispatcher.showHideLoadingAction({ loading: false });
    if (status === 200) {
      callbackSuccess(result);
      return customToast('success', message || 'Success');
    }
  },

  editStudioClass: (payload, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    const { data, id } = payload;

    const { result, status } = await Api.put(
      `Workouts/live-stream/${id}`,
      data
    );
    StudioClassDispatcher.showHideLoadingAction({ loading: false });
    if (status === 200) {
      callbackSuccess(result);
      return customToast('success', 'Success');
    }
  },
  editStudioClassSuccess: payload => payload,

  deleteStudioClass: (workoutId, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    const { status } = await Api.delete(`Workouts/${workoutId}`);
    if (status === 200) {
      customToast('success', 'Deleted successfully');
      callbackSuccess();
    }
  },
  getExportExcel: () => async ({ Api }) => {
    let { result, status } = await Api.get(`WorkoutReports/studio-class-csv`);
    status === 200 && createDownloadableLink(result);
  }
};

const StudioClassDispatcher = synthesize('studioClass', mapDispatchToAC);
export default StudioClassDispatcher;
