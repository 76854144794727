import React, { useState } from 'react';
import AccountInfo from './AccountInfo';
import PersonalInfo from './PersonalInfo';
import customToast from '@/new-components/CustomNotification';
import Slide from '@material-ui/core/Slide';
import validateData from '../../../../helpers/validationHelpers/validationSchema';
import authDispatcher from '../../action/auth';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
// import VerifyOTP from '../Register/VerifyOTP';
import {
  setUpRecaptchaThenSendOTP,
  sendOTP,
  uploadImage
} from '../../../../firebase';
import { getNumberInfo, getNationalNumber } from '../../../../helpers';
import { StyledButton } from '../../../common/componentUI/commonStyleComponents';

const createAccountBtnId = 'btn-register-internal';
const processSteps = {
  accountInfo: 1,
  personalInfo: 2
  // verifyOTP: 3
};

const InternalRegister = ({ history }) => {
  const { loading } = useSelector(state => state.auth);
  const [step, setStep] = useState(processSteps.accountInfo);
  const [userInfo, setUserInfo] = useState({
    countryCode: 'SG',
    lstCertificates: []
  });
  const [errors, setErrors] = useState({});
  const [OTPSent, setOPTSent] = useState(false);

  const toNextStep = async () => {
    try {
      await validateData('registerAccount', userInfo, () => {
        authDispatcher.checkEmailExist(userInfo.email, 'Trainer', () =>
          setStep(processSteps.personalInfo)
        );
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const handleChangeInfo = key => e => {
    let value = [...userInfo.lstCertificates];
    if (key === 'lstCertificates') {
      value.push(e.file.originFileObj);
    } else {
      value = get(e, 'target.value');
    }
    setUserInfo({ ...userInfo, [key]: value });
  };

  const handleDeleteCertificate = (item, index) => {
    let lstCertificates = [...userInfo.lstCertificates];
    lstCertificates.splice(index, 1);
    setUserInfo({ ...userInfo, lstCertificates });
  };

  const onRegister = async () => {
    authDispatcher.setState('loading', true);
    return Promise.all(
      userInfo.lstCertificates.map(async file => {
        const urlPath = await uploadImage(file);
        return urlPath;
      })
    ).then(data => {
      authDispatcher.registerInternal(
        { ...userInfo, lstCertificates: data },
        history
      );
    });
  };

  const handleSubmit = async () => {
    try {
      await validateData('registerPersonal', userInfo, () => {
        // authDispatcher.checkMobileExist(
        //   getNationalNumber(userInfo.mobile, userInfo.countryCode),
        //   userInfo.countryCode,
        //   'Trainer',
        //   () => {
        //     toVerifyOTP();
        //   }
        // );
        onRegister();
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const reSendOTP = () => {
    const { mobile, countryCode } = userInfo;
    setOPTSent(false);
    sendOTP({
      phone: getNationalNumber(mobile, countryCode, true),
      ifSuccess: () => {
        customToast('success', 'OTP resent');
        setOPTSent(true);
      },
      ifFailed: err => {
        customToast('error', err.message);
        return;
      }
    });
  };

  const toVerifyOTP = () => {
    const { mobile, countryCode } = userInfo;
    setUpRecaptchaThenSendOTP({
      phone: getNumberInfo(mobile, countryCode)[1],
      buttonId: createAccountBtnId,
      ifSuccess: () => setOPTSent(true),
      ifFailed: err =>
        customToast('error', err.message || 'Something went wrong')
    });
    setStep(processSteps.verifyOTP);
  };

  return (
    <>
      {' '}
      <StyledButton
        id={createAccountBtnId}
        style={{
          display: 'none'
        }}
      >
        Sign Up
      </StyledButton>
      <Slide direction="left" in={step === processSteps.accountInfo}>
        <div
          key={step}
          style={{
            display: step === processSteps.accountInfo ? 'static' : 'none'
          }}
        >
          <AccountInfo
            onChange={handleChangeInfo}
            values={userInfo}
            toNextStep={toNextStep}
            errors={errors}
          />
        </div>
      </Slide>
      <Slide direction="left" in={step === processSteps.personalInfo}>
        <div
          key={step}
          style={{
            display: step === processSteps.personalInfo ? 'static' : 'none'
          }}
        >
          <PersonalInfo
            onSubmit={handleSubmit}
            onChange={handleChangeInfo}
            values={userInfo}
            errors={errors}
            loading={loading}
            onDelete={handleDeleteCertificate}
          />
        </div>
      </Slide>
      {/* <Slide direction="left" in={step === processSteps.verifyOTP}>
        <div
          key={step}
          style={{
            display: step === processSteps.verifyOTP ? 'static' : 'none'
          }}
        >
          <VerifyOTP
            didSendOTP={OTPSent}
            phone={userInfo.mobile}
            countryCode={userInfo.countryCode}
            reSendOTP={reSendOTP}
            doRegister={onRegister}
            loading={loading}
          />
        </div>
      </Slide> */}
    </>
  );
};

export default InternalRegister;
