import React, { useState, useEffect } from 'react';
import categoryDispatcher from '../action/category';
import { useSelector } from 'react-redux';
import {
  Dialog,
  TableHead,
  TableRow,
  TableCell,
  Menu,
  IconButton
} from '@material-ui/core';
import TableContainer from '../../../components/Table/TableContainer';
import MenuAction from '../../../components/MenuAction';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import { TYPE_MODAL } from '../../common/constants/typeModal';
import {
  DeleteRounded,
  CreateRounded,
  MoreVertRounded
} from '@material-ui/icons';
import DeleteModal from '../../../components/DeleteModal';
import CategoryModal from './CategoryModal';
import onDemandDispatcher from '../../on-demand/actions';

const Categories = () => {
  const { list, paging, loading } = useSelector(state => state.category);
  const { listIntensity } = useSelector(state => state.onDemand);

  const [selectedItem, setSelectedItem] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [hiddenRows, setHiddenRows] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    onDemandDispatcher.getDurationIntensity();
  }, []);

  const onShowModal = type => {
    setShowModal(true);
    setTypeModal(type);
    setAnchorEl(null);
  };

  const onCloseModal = () => {
    setShowModal(false);
    setTypeModal(null);
  };

  const onShowDeleteModal = () => {
    setDeleteModal(true);
    setAnchorEl(null);
  };

  const handleDeleteCategory = () => {
    categoryDispatcher.deleteCategory(selectedItem.id, () => {
      categoryDispatcher.getData();
      setDeleteModal(false);
    });
  };

  const onSubmitSuccess = () => {
    categoryDispatcher.getData();
    setShowModal(false);
    setTypeModal(null);
  };

  const submitCategory = formValue => {
    categoryDispatcher.setState('loading', true);
    if (typeModal === TYPE_MODAL.Create) {
      categoryDispatcher.createCategory(formValue, onSubmitSuccess);
    } else if (typeModal === TYPE_MODAL.Edit) {
      categoryDispatcher.editCategory(
        { id: selectedItem.id, data: formValue },
        onSubmitSuccess
      );
    }
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper label="Title" stateValue="title" />
        <TableCellFiltedWrapper label="Intensity" stateValue="intensity" />
        <TableCellFiltedWrapper label="Calories" stateValue="calories" />
        <TableCellFiltedWrapper label="Description" stateValue="description" />
        <TableCellFiltedWrapper label="Equipment" stateValue="equipment" />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const listActions = [
    {
      key: 'edit',
      icon: CreateRounded,
      label: 'Edit',
      onClick: () => onShowModal(TYPE_MODAL.Edit)
    },
    {
      key: 'delete',
      icon: DeleteRounded,
      label: 'Delete',
      onClick: onShowDeleteModal
    }
  ];
  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && <MenuAction listActions={listActions} />}
      </Menu>
      <TableContainer
        title="Categories"
        paging={paging}
        data={list}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={categoryDispatcher.getData}
        onAddNew={() => onShowModal(TYPE_MODAL.Create)}
        renderRow={row => (
          <>
            <TableCell>{!hiddenRows.title && row.title}</TableCell>
            <TableCell>{!hiddenRows.intensity && row.intensity}</TableCell>
            <TableCell>{!hiddenRows.calories && row.calories}</TableCell>
            <TableCell>{!hiddenRows.description && row.description}</TableCell>
            <TableCell>{!hiddenRows.equipment && row.equipment}</TableCell>
            <TableCell align="right">
              <IconButton
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  setSelectedItem(row);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
      />
      <Dialog
        disableEnforceFocus
        maxWidth="xl"
        open={showModal}
        onClose={onCloseModal}
      >
        <CategoryModal
          onClose={onCloseModal}
          selectedItem={selectedItem}
          typeModal={typeModal}
          onSubmit={submitCategory}
          loading={loading}
          listIntensity={listIntensity}
        />
      </Dialog>
      {deleteModal && (
        <DeleteModal
          showModal={deleteModal}
          selectedItem={selectedItem}
          onClose={() => setDeleteModal(false)}
          onDelete={handleDeleteCategory}
          modalName="Category"
          keyTitle="title"
        />
      )}
    </>
  );
};

export default Categories;
