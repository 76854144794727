import React, { useMemo, useState } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Divider,
  Paper,
  Box
} from '@material-ui/core';
import { ClipboardIcon } from '@/assets/svg';
import { isEmpty } from 'lodash';
import AddOnsItem from '../ViewPatientRegistration/AddOnsItem';
import Invoice from '@/module/all-screenings/component/Invoice';
import ViewPackageItem from '../ViewPatientRegistration/ViewPackageItem';

const useStyles = makeStyles({
  container: {
    display: 'flex'
  },

  leftContainer: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '20px 40px',
    flex: '1',
    borderRight: '1px solid #F1F1F1',
    height: 'calc(100vh - 282px)'
  },

  rightContainer: {
    overflow: 'scroll',
    padding: '16px 40px',
    display: 'flex',
    height: 'calc(100vh - 282px)'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    fontSize: 20,
    gap: 8
  },
  disabledSwitch: {
    pointerEvents: 'none',
    cursor: 'not-allowed'
  }
});

const SelectServiceStep = ({
  apptData,
  dataInfo,
  projectInfo,
  paymentStatus,
  packageInfo,
  totalCost,
  totalAmountDefault,
  lstOptionalTests,
  restrictedTestIds
}) => {
  const classes = useStyles();
  const newListProfiles = useMemo(() => {
    let newList = [];
    newList = [
      {
        category: 'Profiles',
        items: dataInfo.lstProfiles
      }
    ];
    return newList.concat(dataInfo?.lstTests);
  }, [dataInfo]);

  return (
    <Paper square elevation={0} className={classes.container}>
      <Box
        flexDirection="column"
        className={classes.leftContainer}
        gridGap={16}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className={classes.title}>
              <ClipboardIcon style={{ color: '#666666' }} />
              Selected packages
            </Typography>
          </Grid>
          {!isEmpty(dataInfo?.lstPackages) && (
            <Grid item xs={12}>
              <ViewPackageItem
                packageDetail={packageInfo}
                ignoredTests={dataInfo?.ignoredTests}
                ignoredProfiles={dataInfo?.ignoredProfiles}
                restrictedTestIds={restrictedTestIds}
              />
            </Grid>
          )}

          {!isEmpty(dataInfo.lstPackages) && !isEmpty(dataInfo.lstProfiles) && (
            <Grid item xs={12}>
              <Divider light variant="middle" />
            </Grid>
          )}
          {!isEmpty(dataInfo.lstProfiles) ||
          (!isEmpty(dataInfo.lstProfiles) && !isEmpty(dataInfo.lstTests)) ? (
            <Grid item xs={12}>
              <AddOnsItem listData={newListProfiles} />
            </Grid>
          ) : !isEmpty(dataInfo.lstTests) ? (
            <Grid item xs={12}>
              <AddOnsItem listData={dataInfo.lstTests} />
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <Box className={classes.rightContainer}>
        <Invoice
          apptData={apptData}
          lstTests={dataInfo.lstTests}
          lstProfiles={dataInfo?.lstProfiles}
          lstPackages={dataInfo?.lstPackages}
          ignoredProfiles={dataInfo?.ignoredProfiles}
          ignoredTests={dataInfo?.ignoredTests}
          lstOptionalTests={lstOptionalTests}
          staffType={dataInfo?.staffType}
          projectInfo={projectInfo}
          paymentStatus={paymentStatus}
        />
      </Box>
    </Paper>
  );
};

export default SelectServiceStep;
