import React, { useEffect, useCallback, useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  Table,
  Paper,
  TableContainer,
  TableBody,
  Typography,
  TableRow
} from '@material-ui/core';
import { debounce } from 'lodash';
import { renderIf } from '../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyles = makeStyles({
  contained: {
    boxShadow: 'none'
  },
  head: {
    fontWeight: 600
  }
});

const OnePageTable = ({
  header: Header,
  renderRow,
  query = () => {},
  data = [],
  setSearchKey,
  searchKey,
  title = '',
  toolbar = true,
  ToolbarComponent,
  className,
  tableBody,
  footer: Footer,
  setData,
  stickyHeader,
  filterData,
  setFilterData
}) => {
  const classes = useStyles();
  const onChangeSearch = e => {
    setSearchKey(e.target.value);
  };

  const fetchData = async (search = searchKey) => {
    // query();

    const filteredData = data.filter(item => {
      if (item.child) {
        for (const i in item.child) {
          return item.child[i].title
            .toLowerCase()
            .includes(search.toLowerCase());
        }
      } else {
        return item.title.toLowerCase().includes(search.toLowerCase());
      }
    });

    setFilterData(filteredData);
  };

  useEffect(() => {
    fetchData(searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  useEffect(() => {
    query();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableContainer>
      {/* Table toolbar */}
      <ToolsBar>
        <div style={{ display: 'flex' }} className={className}>
          <SearchWrapped>
            <Input
              allowClear
              maxLength="100%"
              value={searchKey}
              onChange={onChangeSearch}
              placeholder="Search"
              prefix={
                <SearchOutlined
                  style={{ color: 'rgba(0,0,0,.25)', fontSize: 16 }}
                  type="search"
                />
              }
            />
          </SearchWrapped>
          {ToolbarComponent}
        </div>
      </ToolsBar>
      <TableWrapped>
        <Table stickyHeader={stickyHeader}>
          {Header && <Header />}
          {tableBody ? (
            tableBody
          ) : (
            <TableBody>
              {(data || []).map((d, i) => (
                <TableRow
                  key={d.id}
                  hover
                  classes={{
                    head: classes.head
                  }}
                >
                  {renderRow(d, i)}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableWrapped>
      {Footer && <Footer />}
    </TableContainer>
  );
};

export default OnePageTable;

const ToolsBar = styled.div`
  .justify-between {
    justify-content: space-between;
  }
`;

const TableWrapped = styled.div`
  overflow: scroll;
  height: calc(75vh - 182px);
  margin-top: 40px;
`;

const SearchWrapped = styled.div`
  margin: 20px 5px 0px;
  width: 100%;
  border-style: solid;
  border-color: #e9e9e9;
  border-width: 0px;
  border-bottom-width: 1px;
  background: white;
  .ant-input-affix-wrapper {
    border: 0px;
  }
`;
