import { DialogActions, Grid, TextField, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import styled from 'styled-components';

export const PaperWrapped = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
  min-height: calc(100vh - 200px);
  .no-tabs-header {
    border-bottom: 0.5px solid #ecedf0;
  }
  .secondary {
    margin: 5px 5px 5px 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    background: #fff;
    color: #ad5e99;
    border: 1px solid #ad5e99;
    svg {
      margin-right: 5px;
    }
  }
  .primary {
    margin: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    font-size: 16px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
  .MuiAutocomplete-root {
    margin-bottom: 15px !important;
    border-radius: 8px !important;
    border: 1px solid #e1e1e1 !important;
    height: 48px;
  }
  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }
  .MuiAutocomplete-root
    .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
    height: 100%;
    > div {
      height: 100%;
    }
  }
  .delete-icon {
    padding: 0;
  }
  .MuiDivider-root {
    margin: 10px 0 20px;
  }
`;

export const HeaderWrapped = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  .title {
    color: #192637;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Lato';
  }
  .personal-btn {
    display: flex;
  }
`;

export const DetailWrapped = styled.div`
  width: 766px;
  margin: 0 auto;
  .MuiGrid-root.grid {
    padding: 5px 10px !important;
  }
  padding-bottom: 88px; // app bar height
`;

export const TableWrapped = styled.div`
  padding: 0 20px;
  .action-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    div {
      margin: 0;
    }
  }
`;

export const ModalPage = styled.div`
  width: 400px;
  hr {
    background: #cacfd3;
    margin: 0;
  }
  .MuiInput-root {
    width: 100%;
  }
  .MuiFormControl-root {
    width: 100%;
    margin-top: 10px;
  }
  .MuiDialogContent-root {
    padding: 0;
    &:first-child {
      padding-top: 0;
    }
  }
  .test-header {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #192637;
    border-bottom: 1px solid rgb(202, 207, 211);
  }
  .test-body {
    padding: 0 20px 50px 20px;
    .options-label {
      font-size: 16px;
      font-weight: 600;
      color: #192637;
      margin-top: 25px;
    }
    .custom-divider {
      margin-left: 5px;
    }
    .select-adornment {
      display: flex;
      .MuiInput-underline:before {
        border: none;
      }
    }
    .drag-list {
      input {
        cursor: pointer;
      }
    }
  }
  .MuiTypography-colorError {
    font-size: 12px;
    font-weight: 500;
  }
`;

export const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 10px 0px;
`;

export const ValueItem = styled.div`
  cursor: pointer;
  padding: 5px 8px;
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;
`;

export const SectionTitle = styled.div`
  font-family: 'Lato';
  font-size: 20px;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const InputLabel = styled.div`
  margin: 10px 0 5px 0;
  color: #666;
  font-weight: 600;
  margin-top: ${p => p.mt};
`;

export const CustomSearchInput = withStyles(theme => ({
  root: {
    color: theme.palette.grey.mainText,
    '& .MuiOutlinedInput-root': {
      borderRadius: 8
    },
    '& .Mui-disabled': {
      color: theme.palette.grey.mainText,
      pointerEvents: 'none'
    },
    '& input': {
      minWidth: 335,
      height: 48,
      padding: 0
    }
  }
}))(TextField);

export const CustomGridItem = styled.div`
  display: flex;
  align-items: center;
  &.grid-3 {
    flex-grow: 0;
    max-width: 26.4%;
    flex-basis: 26.4%;
    > div {
      width: 93%;
      &.disabled {
        background-color: white;
        > div {
          color: #8f9ba9;
        }
      }
    }
  }
  &.grid-2 {
    flex-grow: 0;
    max-width: 13.2%;
    flex-basis: 13.2%;
    display: flex;
    align-items: center;
  }
  &.grid-1 {
    flex-grow: 0;
    max-width: 7.1%;
    flex-basis: 7.1%;
    display: flex;
    align-items: center;
  }
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl {
    /* width: 90%; */
    input {
      height: 36px;
      min-width: 50px;
    }
  }
  .Mui-disabled {
    background: white !important;
    pointer-events: none;
  }
  > div {
    margin-bottom: 0;
  }
`;

export const ProjectsContainer = styled.div`
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
`;

export const ProjectsHeader = styled(Grid)`
  background: #f1f1f1;
  font-size: 12px;
  color: #333;
  width: 100%;
  padding: 10px 0 10px 15px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const ProjectRow = styled(Grid)`
  padding: 2px 0 2px 15px;
  color: #333;
  font-size: 14px;
  border-bottom: 1px solid #e1e1e1;
  min-height: 30px;
  svg {
    cursor: pointer;
  }
  &:last-child {
    border: none;
  }
  .MuiGrid-item {
    display: flex;
    align-items: center;
  }
`;

export const AddProject = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 15px;
  color: #ad5e99;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
`;

export const CheckIconContainer = styled.div`
  width: 20px;
  heigh: 20px;
  border-radius: 50%;
`;

export const CustomAutocomplete = withStyles({
  option: {
    '& span[class="max-line"]': {
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      display: '-webkit-inline-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2
    }
  }
})(Autocomplete);
