import React, { useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  Dialog,
  Modal,
  Typography
} from '@material-ui/core';
import { MoreVertRounded, DeleteRounded } from '@material-ui/icons';
import TableContainer from '../../../components/Table/TableContainer';
import { useSelector } from 'react-redux';
import { SEARCH_PARAM_CLINIC, TYPE_MODAL } from '../constants';
import { MenuAction, SearchClinic } from '../components';
import ClinicFormPage from './ClinicFormPage';
import clinicDispatcher from '../actions/index';
import { StatusWrapper } from '../../common/componentUI/commonStyleComponents';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import './styles.scss';
import ModalPresenter from '../../../components/ModalPresenter';
import OperatingHours from './OperatingHours';
import { renderHeader, renderDays } from './renderTableCell';
import { formatOperatingHours } from '../../../helpers';
import { isEmpty } from 'lodash';

const ClinicListPage = () => {
  const listClinic = useSelector(state => state.clinic.list);
  const paging = useSelector(state => state.clinic.paging);

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState(SEARCH_PARAM_CLINIC);

  const [clinicSelected, setClinicSelected] = useState(null);

  const [showModalForm, setShowModalForm] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});
  const [showOperatingHours, setShowOperatingHours] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const reGetListClinic = () => {
    setFilterOption({ ...filterOption });
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {renderHeader.map(({ label, key }) => {
          if (!key) return <TableCell key={label}></TableCell>;
          return (
            <TableCellFiltedWrapper key={key} label={label} stateValue={key} />
          );
        })}
      </TableRow>
    </TableHead>
  );

  const onShowModalForm = type => {
    setTypeModal(type);
    setShowModalForm(true);
    setAnchorEl(null);
  };

  const onCloseModalForm = () => {
    setTypeModal(null);
    setShowModalForm(false);
    setClinicSelected(null);
  };

  const onShowOperatingHours = () => {
    setShowOperatingHours(true);
    setAnchorEl(null);
  };

  const renderColumnStatus = status => {
    if (status) {
      return (
        <StatusWrapper status={status}>
          {status === 'Temp' ? 'Temporarily Unavailable' : status}
        </StatusWrapper>
      );
    }
  };

  const updateStatusClinic = payload => {
    clinicDispatcher.updateStatusClinic(clinicSelected.id, payload);
    setAnchorEl(null);
  };

  return (
    <>
      <Modal open={!!deleteModal} onClose={() => setDeleteModal(false)}>
        <div>
          <ModalPresenter
            onClose={() => setDeleteModal(false)}
            Icon={<DeleteRounded style={{ fontSize: 80, color: '#EA6B75' }} />}
            title="Delete Clinic?"
            onClick={() => {
              setDeleteModal(false);
              clinicDispatcher.deleteClinic(clinicSelected.id, searchKey);
            }}
          >
            <Typography>Click “Continue” to delete this clinic</Typography>
          </ModalPresenter>
        </div>
      </Modal>
      {showModalForm && (
        <Dialog
          open={showModalForm}
          onClose={() => setShowModalForm(false)}
          maxWidth="md"
          scroll="body"
        >
          <ClinicFormPage
            typeModal={typeModal}
            onCloseModal={onCloseModalForm}
            clinicDetail={clinicSelected}
            reGetListClinic={reGetListClinic}
          />
        </Dialog>
      )}
      {showOperatingHours && (
        <Dialog
          open={showOperatingHours}
          onClose={() => setShowOperatingHours(false)}
          maxWidth="md"
          scroll="body"
          disableEnforceFocus
        >
          <OperatingHours
            clinicSelected={clinicSelected}
            onClose={() => setShowOperatingHours(false)}
          />
        </Dialog>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && (
          <MenuAction
            clinicSelected={clinicSelected}
            onShowModal={onShowModalForm}
            updateStatus={updateStatusClinic}
            setDeleteModal={() => {
              setDeleteModal(true);
              setAnchorEl(null);
            }}
            onShowOperatingHours={onShowOperatingHours}
          />
        )}
      </Menu>

      <TableContainer
        title="Clinic Set Up"
        paging={paging}
        data={listClinic}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        query={clinicDispatcher.getClinicList}
        onAddNew={() => {
          setClinicSelected(null);
          onShowModalForm(TYPE_MODAL.Create);
        }}
        ToolbarComponent={
          <SearchClinic
            onSearch={data => {
              setFilterOption({ ...filterOption, ...data });
            }}
          />
        }
        renderRow={row => (
          <>
            <TableCell>
              {!hiddenRows.name && (
                <>
                  <Typography>{row.name}</Typography>
                  <Typography style={{ color: '#8F9BA9' }}>
                    {`#${row.unitNumber}, ${row.address}${
                      !isEmpty(row.postalCode) ? `, S${row.postalCode}` : ''
                    }`}
                  </Typography>
                </>
              )}
            </TableCell>
            {renderDays(row.operationHours).map(day => (
              <TableCell>
                {!hiddenRows[day.name] &&
                  day.items.map(
                    item =>
                      !item.error && (
                        <Typography
                          style={{ width: '120px' }}
                        >{`${formatOperatingHours(
                          item.startTime
                        )} - ${formatOperatingHours(
                          item.endTime
                        )}`}</Typography>
                      )
                  )}
              </TableCell>
            ))}
            <TableCell>
              {!hiddenRows.status && renderColumnStatus(row.status)}
            </TableCell>
            <TableCell align="right">
              <IconButton
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  setClinicSelected(row);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
      ></TableContainer>
    </>
  );
};

export default ClinicListPage;
