import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, filterOption = {}) => async ({ Api, getState }) => {
    let pagingData = paging || getState().trainerManagement.paging;
    let { result, status } = await Api.post(`Users/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      roleType: 'InternalTrainer',
      ...filterOption
    });
    status === 200 && usermanagementDispatcher.getDataSuccess(result);
  },
  editData: (data, searchKey, callback, onFinish, isEdit) => async ({
    Api,
    customToast,
    getState
  }) => {
    let response;
    if (isEdit) {
      response = await Api.put(`Users/${data.id}`, data);
    } else {
      response = await Api.post('Trainers/internal', data);
    }

    if (response.status === 200) {
      let { paging } = getState().userManagement;
      usermanagementDispatcher.getData(searchKey, paging);
      customToast('success', 'Success');
      callback();
    }
    onFinish();
  },
  updateStatus: (data, searchKey) => async ({ Api, customToast, getState }) => {
    const response = await Api.put('Trainers/approve', data);
    if (response.status === 200) {
      let { paging } = getState().trainerManagement;
      usermanagementDispatcher.getData(searchKey, paging);
      customToast('success', 'Success');
    } else {
      customToast('error', 'Failed');
    }
  },
  deleteData: (id, searchKey, filterOption = {}) => async ({
    Api,
    customToast,
    getState
  }) => {
    const response = await Api.delete(`Users/${id}`);
    if (response.status === 200) {
      let { paging } = getState().trainerManagement;
      usermanagementDispatcher.getData(searchKey, paging, filterOption);
      customToast('success', 'Success');
    }
  }
};

const usermanagementDispatcher = synthesize('userManagement', mapDispatchToAC);
export default usermanagementDispatcher;
