import React, { useState, useMemo, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { withStyles } from '@material-ui/core/styles';

import isEmpty from 'lodash/isEmpty';

import { CloseCircle, CloseIcon, SearchIcon } from '@/assets/svg';

import { lowerCase } from 'lodash';
import { ArrowBackIos } from '@material-ui/icons';
import CheckboxTable from './CheckboxTable';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  text: {
    fontFamily: 'Lato',
    fontWeight: 700
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  }
});

const CustomDialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    minHeight: 500
  },
  paperWidthMd: {
    maxWidth: 800,
    overflowX: 'hidden'
  }
})(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset'
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const CustomDialogContent = withStyles(theme => ({
  root: {
    padding: 0
  }
}))(DialogContent);

const CustomDialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    justifyContent: 'space-between'
  }
}))(DialogActions);

const CustomSearchInput = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      borderRadius: 8
    },

    '& input': {
      minHeight: 40,
      padding: 0
    }
  }
}))(TextField);

const CustomViewButton = withStyles(theme => ({
  root: {
    textTransform: 'unset',
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: 18
  }
}))(Button);

const CustomViewTitle = withStyles(theme => ({
  root: {
    textTransform: 'unset',
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: 18
  }
}))(Typography);

const CustomAutocompleteModal = ({
  data,
  open,
  onClose,
  submitLabel,
  onSubmit,
  title,
  searchPlaceholder = 'Search by...',
  columns,
  selectedItem,
  renderRow,
  isViewModal,
  setIsViewModal,
  ViewModal
}) => {
  const [searchKey, setSearchKey] = useState('');
  const [localSelected, setLocalSelected] = useState([]);

  useEffect(() => {
    if (!open) {
      setLocalSelected([]);
      setSearchKey('');
    }
  }, [open]);

  const localData = useMemo(() => {
    const newList = data.filter(
      item =>
        !selectedItem.includes(item?.id) &&
        lowerCase(item?.name).includes(lowerCase(searchKey))
    );
    return newList;
  }, [data, selectedItem, searchKey]);

  const handleSubmit = () => {
    onSubmit(localSelected);
  };

  const handleClose = () => {
    onClose();
    setIsViewModal && setIsViewModal(false);
  };

  return (
    <CustomDialog
      maxWidth="md"
      fullWidth
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={handleClose}
    >
      <CustomDialogTitle onClose={handleClose}>
        {isViewModal ? (
          <>
            <CustomViewButton
              onClick={() => setIsViewModal(false)}
              startIcon={<ArrowBackIos />}
            >
              <CustomViewTitle>View {title} details</CustomViewTitle>
            </CustomViewButton>
          </>
        ) : (
          `${submitLabel} ${title}`
        )}
      </CustomDialogTitle>
      <Divider light />
      <CustomDialogContent>
        {isViewModal ? (
          <ViewModal />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <CustomSearchInput
                fullWidth
                variant="outlined"
                placeholder={searchPlaceholder}
                value={searchKey}
                onChange={e => setSearchKey(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            visibility: !isEmpty(searchKey)
                              ? 'visible'
                              : 'hidden'
                          }}
                          onClick={() => setSearchKey('')}
                        >
                          <CloseCircle
                            height={20}
                            width={20}
                            style={{
                              color: '#666666'
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <CheckboxTable
                data={localData}
                columns={columns}
                renderRow={renderRow}
                selected={localSelected}
                onSelectChange={setLocalSelected}
                onUnSelectAll={() => setLocalSelected([])}
              />
            </Grid>
          </Grid>
        )}
      </CustomDialogContent>
      <Divider light />
      {!isViewModal && (
        <CustomDialogActions>
          <Typography>{localSelected.length} selected</Typography>
          <CustomButton
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={isEmpty(localSelected)}
          >
            {submitLabel}
          </CustomButton>
        </CustomDialogActions>
      )}
    </CustomDialog>
  );
};

export default CustomAutocompleteModal;
