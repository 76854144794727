import React from 'react';
import { COLUMN_PROCEDURE } from '@/module/services/constants';
import ServiceManagement from '../../common/ServiceManagement';
import ProcedureModalContainer from './ProcedureModalContainer';

const ProcedureManagement = props => (
  <ServiceManagement
    {...{
      ...props,
      prefixName: 'Procedure',
      category: 'Procedures',
      noDataHelperText: 'No procedure',
      noDataHelperSubText: 'Please create a new procedure',
      columns: COLUMN_PROCEDURE,
      ModalContainer: ProcedureModalContainer,
      requirePE: true
    }}
  />
);

export default ProcedureManagement;
