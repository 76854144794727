import React, { useState, useEffect } from 'react';
import ModalPage, { ModalFooter } from './styled';
import { DialogContent, TextField, Grid } from '@material-ui/core';
import { ClearRounded } from '@material-ui/icons';
import customToast from '@/new-components/CustomNotification';
import { get, isEmpty } from 'lodash';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import CarouselAnt from '../../../components/CarouselAnt';
import UploadListImages from '../../../components/UploadListImages';
import LoadingButton from '../../../components/LoadingButton';
import validateData from '../../../helpers/validationHelpers/validationSchema';
import trainerDispatcher from '../action';
import { uploadImage } from '../../../firebase';
import { getBase64Promise } from '../../../helpers/CommonHelper';
import { formatListImage } from '../../../helpers';

const TrainerModal = ({ onClose, selectedItem, searchKey }) => {
  const [formValue, setFormValue] = useState({
    canIgnore: false
  });
  const [cert, setCert] = useState([]);
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [certShow, setCertShow] = useState([]);

  const FILE_SIZE = 2 * 1024 * 1024; // ~2mb
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png'
  ];

  const onChangeCert = e => {
    let value = [...cert];
    const file = e.file.originFileObj;
    if (!SUPPORTED_FORMATS.includes(get(file, 'type'))) {
      return customToast('error', 'Image is invalid');
    }
    if (get(file, 'size') > FILE_SIZE) {
      return customToast('error', 'Image is too large');
    }
    value.push(file);
    setCert(value);
  };

  useEffect(() => {
    setFormValue({ ...selectedItem });
    !isEmpty(selectedItem.lstCertificates) &&
      setCert(selectedItem.lstCertificates);
  }, [selectedItem]);

  useEffect(() => {
    if (!isEmpty(cert) && !!cert.length) {
      formatListImage(cert, setCertShow);
    }
  }, [cert]);

  const handleSubmit = async () => {
    try {
      await validateData(
        'trainerSchema',
        { ...formValue, lstCertificates: cert },
        validData => {
          const { password2, ...restparam } = validData;
          setShowLoader(true);
          return Promise.all(
            cert.map(async file => {
              if (typeof file === 'object') {
                const urlPath = await uploadImage(file);
                return urlPath;
              } else return file;
            })
          ).then(data => {
            trainerDispatcher.editData(
              { ...restparam, lstCertificates: data },
              searchKey,
              onClose,
              () => setShowLoader(false)
            );
          });
        }
      );
    } catch (errs) {
      setErrors(errs);
    }
  };

  const handleChangeForm = key => e => {
    setFormValue({ ...formValue, [key]: e.target.value });
  };

  const handleDeleteCert = (item, index) => {
    let lstCertificates = [...cert];
    lstCertificates.splice(index, 1);
    setCert(lstCertificates);
  };

  return (
    <ModalPage>
      <DialogContent>
        <div className="test-header">
          {selectedItem.fullName}
          <ClearRounded
            style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
            onClick={onClose}
          />
        </div>
        <div className="test-body">
          <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
            <div>
              <UploadListImages
                listImage={cert}
                onChange={onChangeCert}
                onDelete={handleDeleteCert}
                error={errors.lstCertificates}
                label="Certificate"
              />
              <CarouselAnt data={certShow} disabled />
            </div>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12}>
              <TextField
                error={errors.firstName}
                helperText={errors.firstName}
                label="First name"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.firstName}
                onChange={handleChangeForm('firstName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.lastName}
                helperText={errors.lastName}
                label="Last name"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.lastName}
                onChange={handleChangeForm('lastName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.email}
                helperText={errors.email}
                label="Email"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.email}
                onChange={handleChangeForm('email')}
              />
            </Grid>
          </Grid>
        </div>
        <ModalFooter>
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={onClose}
          >
            Cancel
          </ButtonEnhance>

          <LoadingButton onClick={handleSubmit} showLoader={showLoader}>
            Update
          </LoadingButton>
        </ModalFooter>
      </DialogContent>
    </ModalPage>
  );
};

export default TrainerModal;
