/*
 * @Author: Phu Phan
 * @Date: 2021-01-08 14:47:03
 */
import styled from 'styled-components';

export const TableWrapped = styled.div`
  padding: 0 20px;
  .action-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    div {
      margin: 0;
    }
  }
`;
