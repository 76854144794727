import React, { useState, useEffect } from 'react';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import { TYPE_MODAL } from '../common/constants/typeModal';
import TableContainer from '../../components/Table/TableContainer';
import {
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  Menu,
  IconButton
} from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import MenuAction from '../../components/MenuAction';
import DeleteModal from '../../components/DeleteModal';
import UserModal from './UserModal';
import renderColumn from './renderColumn';
import renderListAction from './renderListAction';
import userDispatcher from './action';
import usermanagementDispatcher from './action';

const TrainerManagement = () => {
  const { paging, list: data } = useSelector(state => state.userManagement);
  const tableColumn = renderColumn();

  const [userSelected, setUserSelected] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const onShowModal = type => {
    setShowModal(true);
    setTypeModal(type);
    setAnchorEl(null);
  };

  const onShowDeleteModal = () => {
    setDeleteModal(true);
    setAnchorEl(null);
  };

  const onCloseModal = () => {
    setShowModal(false);
    setTypeModal(null);
  };

  const handleDeleteUser = () => {
    usermanagementDispatcher.deleteData(userSelected.id, searchKey);
    setDeleteModal(false);
  };

  const onHandleModal = (type, status) => {
    switch (type) {
      case 'delete':
        onShowDeleteModal();
        break;
      case 'edit': {
        onShowModal(TYPE_MODAL.Edit);
        break;
      }
      default:
        break;
    }
  };

  const listActions = renderListAction(onHandleModal);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(({ label, stateValue }) => {
          if (!stateValue) return <TableCell key={label}></TableCell>;
          return (
            <TableCellFilted
              key={label}
              label={label}
              stateValue={stateValue}
              hiddenRows={hiddenRows}
              setHiddenRows={setHiddenRows}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && <MenuAction listActions={listActions} />}
      </Menu>
      <TableContainer
        title="Internal Trainer Management"
        paging={paging}
        data={data}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={userDispatcher.getData}
        onAddNew={() => onShowModal(TYPE_MODAL.Create)}
        renderRow={row => (
          <React.Fragment key={row.id}>
            <TableCell>{!hiddenRows.fullName && row.fullName}</TableCell>
            <TableCell>{!hiddenRows.email && row.email}</TableCell>
            <TableCell align="right">
              <IconButton
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  setUserSelected(row);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </React.Fragment>
        )}
      />
      <Dialog maxWidth="md" open={showModal} onClose={onCloseModal}>
        <UserModal
          onClose={onCloseModal}
          selectedItem={userSelected}
          typeModal={typeModal}
          searchKey={searchKey}
        />
      </Dialog>
      {deleteModal && (
        <DeleteModal
          keyTitle="fullName"
          showModal={deleteModal}
          selectedItem={userSelected}
          onClose={() => setDeleteModal(false)}
          onDelete={handleDeleteUser}
          modalName="User management"
        />
      )}
    </>
  );
};

export default TrainerManagement;
