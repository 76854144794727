import { formatOldData } from '@/helpers';
import { isEmpty } from 'lodash';

export const formatData = data => {
  let { lstTests } = data;

  let lstMeasurements,
    lstProcedures,
    lstRadiologies,
    lstVaccinations,
    lstReviews;

  lstMeasurements = lstProcedures = lstRadiologies = lstVaccinations = lstReviews = [];
  if (isEmpty(lstTests))
    return {
      ...data,
      description: formatOldData(data.description),
      shortDesc: formatOldData(data.shortDesc),
      lstTests,
      lstMeasurements,
      lstProcedures,
      lstRadiologies,
      lstVaccinations,
      lstReviews
    };

  [
    lstMeasurements,
    lstProcedures,
    lstRadiologies,
    lstVaccinations,
    lstReviews,
    lstTests
  ] = [
    'Measurements',
    'Procedures',
    'Radiologies',
    'Vaccinations',
    'Reviews',
    'Tests'
  ].map(category => lstTests.find(i => i.category === category)?.items || []);

  return {
    ...data,
    description: formatOldData(data.description),
    shortDesc: formatOldData(data.shortDesc),
    lstTests,
    lstMeasurements,
    lstProcedures,
    lstRadiologies,
    lstVaccinations,
    lstReviews
  };
};
