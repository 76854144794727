import _unionBy from 'lodash/unionBy';

import waitingRoomDispatcher from '../action/waitingRoom';
import { roomStatus } from '../../../enum/RoomStatusEnum';

const initialState = {
  waitingList: [],
  calling: false,
  localStream: [],
  remoteStreams: [],
  isMuteAudio: false,
  isMuteVideo: false,
  isMuteSpeaker: false,
  isSharing: false,
  showChat: false,
  channelId: null,
  callId: null,
  patientId: null,
  memberAppointment: null,
  consConfirmVisibility: false,
  verifyModalVisibility: false,
  waitingModalVisibility: false,
  verifyingUserData: {},
  rejoinCallData: null,
  startCallTime: '',
  reconnectRejoinCall: false
};

const waitingRoomReducer = waitingRoomDispatcher(initialState, {
  [waitingRoomDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value
  }),
  [waitingRoomDispatcher.updateLocalStream]: (state, payload) => ({
    localStream: [...payload.streams]
  }),
  [waitingRoomDispatcher.updateRemoteStream]: (state, payload) => ({
    remoteStreams: [...payload.streams]
  }),
  [waitingRoomDispatcher.onGetWaitingList]: (state, payload) => {
    return {
      waitingList: _unionBy([...state.waitingList, ...payload.data], 'UserId')
    };
  },
  [waitingRoomDispatcher.onPTJoinRoom]: (
    state,
    { data, isJoin, isCurrentPT }
  ) => {
    if (isJoin)
      return {
        waitingList: [...state.waitingList, ...data]
      };
    else if (
      state.verifyModalVisibility &&
      data[0] === state.verifyingUserData.UserId
    ) {
      return {
        waitingList: [...state.waitingList].filter(
          i => !data.includes(i.UserId)
        ),
        verifyModalVisibility: false,
        // verifyingUserData: {},
        waitingModalVisibility:
          state.waitingModalVisibility && isCurrentPT
            ? false
            : state.waitingModalVisibility
      };
    } else
      return {
        waitingList: [...state.waitingList].filter(
          i => !data.includes(i.UserId)
        ),
        waitingModalVisibility:
          state.waitingModalVisibility && isCurrentPT
            ? false
            : state.waitingModalVisibility
      };
  },
  [waitingRoomDispatcher.onRemovePT]: (
    state,
    { data, shouldRemove, isCurrentPT }
  ) => {
    let condition = i => i.UserId !== data.UserId;
    if (typeof data === 'string') condition = i => i.UserId !== data;
    if (shouldRemove)
      return {
        waitingList: [...state.waitingList].filter(condition),
        waitingModalVisibility:
          state.waitingModalVisibility && isCurrentPT
            ? false
            : state.waitingModalVisibility
      };
    else
      return {
        waitingList: [...state.waitingList].map(i =>
          condition(i)
            ? i
            : {
                ...data,
                PatientRoomStatus: roomStatus.Waiting
              }
        )
      };
  },
  [waitingRoomDispatcher.clearWaitingList]: () => ({ waitingList: [] }),
  [waitingRoomDispatcher.getMemberAppointmentSuccess]: (state, payload) => ({
    memberAppointment: payload.data
  }),
  [waitingRoomDispatcher.resetState]: () => {
    return initialState;
  }
});

export default waitingRoomReducer;
