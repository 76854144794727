import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  Typography
} from '@material-ui/core';
import { cloneDeep, get, isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';
import { Autocomplete } from '@material-ui/lab';
import { CATEGORY_LIST } from '@/constants/editorConfig';
import CustomSelect from '@/components/CustomSelect';
import MutipleSelection from '@/components/CustomSelect/MutipleSelection';
import { ignoredSelectedTags } from '@/helpers';

const StyledNumberFormat = styled(NumberFormat)`
  border: none;
  border-bottom: 1px solid gray;
  outline: none;
  padding-bottom: 8px;
`;

const GeneralInformation = ({
  personalInfo = {},
  setPersonalInfo,
  errors = {},
  disabled = false,
  price
}) => {
  const { tagsAddOns } = useSelector(state => state.globalState);
  const [tagsModal, setTagsModal] = useState(false);
  const [listTags, setListTags] = useState([]);

  const handleChange = key => e => {
    let value = get(e, 'target.value');

    if (key === 'tags') {
      const newList = ignoredSelectedTags(listTags, value);
      setListTags(newList);
    }
    setPersonalInfo({
      ...personalInfo,
      [key]: value
    });
  };

  const handleChangeInputPrice = values => {
    setPersonalInfo &&
      setPersonalInfo({
        ...personalInfo,
        price: values.value
      });
  };

  useEffect(() => {
    if (!isEmpty(tagsAddOns)) {
      const newListTags = cloneDeep(tagsAddOns).map(it => ({
        key: it,
        value: it,
        isDisabled: false
      }));
      const newList = ignoredSelectedTags(newListTags, personalInfo?.tags);

      setListTags(newList);
    }
  }, [tagsAddOns, personalInfo]);

  return (
    <Grid
      container
      spacing={4}
      style={{ margin: 0, width: '100%', minHeight: 250 }}
    >
      <Grid item xs={6}>
        <TextField
          disabled={disabled}
          fullWidth
          label="Profile Name"
          value={personalInfo.name || ''}
          error={errors.name}
          helperText={errors.name}
          onChange={handleChange('name')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled={disabled}
          fullWidth
          label="Profile Code"
          value={personalInfo.code || ''}
          error={errors.code}
          helperText={errors.code}
          onChange={handleChange('code')}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <Autocomplete
          style={{ width: '100%', marginTop: 5 }}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={option => option.name || ''}
          options={CATEGORY_LIST}
          value={{
            id: personalInfo.group,
            name: CATEGORY_LIST.find(it => it.id === personalInfo.group)?.name
          }}
          autoComplete
          disabled={disabled}
          onChange={(e, newValue) => {
            newValue
              ? setPersonalInfo({
                  ...personalInfo,
                  group: newValue.id
                })
              : setPersonalInfo({
                  ...personalInfo,
                  group: 'None'
                });
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Category"
              error={errors.group}
              helperText={errors.group}
            />
          )}
        />
      </Grid>

      <Grid item lg={6} xs={12}>
        <InputLabel id="id-label-type">Tags</InputLabel>
        {disabled ? (
          <TextField disabled fullWidth value={personalInfo.tags || ''} />
        ) : (
          <Box py={1}>
            <CustomSelect
              isFullWidth
              onClick={() => setTagsModal(!tagsModal)}
              renderValue={
                <>{personalInfo?.tags?.join(', ') || 'Select Tags'}</>
              }
              colorBorderBottomInput="#212121"
              colorInput="unset"
              clearable={!isEmpty(personalInfo?.tags)}
              onClear={() => {
                setPersonalInfo({
                  ...personalInfo,
                  tags: []
                });
              }}
            >
              <MutipleSelection
                value={personalInfo?.tags || []}
                data={listTags}
                open={tagsModal}
                onChange={handleChange('tags')}
              />
            </CustomSelect>
            {errors.tags && (
              <Typography color="error">{errors.tags}</Typography>
            )}
          </Box>
        )}
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled={disabled}
          multiline
          rows={4}
          rowsMax={6}
          fullWidth
          error={!!errors.defaultStatement}
          helperText={errors.defaultStatement}
          label="Default Statement"
          value={personalInfo.defaultStatement || ''}
          onChange={handleChange('defaultStatement')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          multiline
          disabled={disabled}
          rows={4}
          rowsMax={6}
          fullWidth
          label="Default Statement (Chinese)"
          value={personalInfo.defaultStatementChinese || ''}
          onChange={handleChange('defaultStatementChinese')}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledNumberFormat
          prefix="$ "
          disabled={disabled}
          customInput={TextField}
          label="Default price"
          error={errors.price}
          helperText={errors.price}
          thousandSeparator=" "
          onValueChange={handleChangeInputPrice}
          allowNegative={false}
          decimalScale={2}
          value={price ? price : personalInfo.price || ''}
        />
      </Grid>
    </Grid>
  );
};

export default GeneralInformation;
