import { ClearComponent } from './ClearComponent';
import { HideIcon, UnHideIcon } from '@/assets/svg';
import CustomCountryCodeInput from '@/new-components/CustomCountryCodeInput';
import CustomLoadingButton from '@/new-components/CustomLoadingButton';
import {
  Box,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';

const CustomLink = withStyles({
  root: {
    fontWeight: 600,
    color: '#AD5E99',
    cursor: 'pointer',
  },
})(Typography);

const CustomTypography = withStyles({
  root: {
    color: '#666666',
  },
})(Typography);

const CustomTitle = withStyles({
  root: {
    fontSize: 32,
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#191919',
  },
})(Typography);

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,
    fontWeight: 600,
  },
})(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
  },
})(TextField);

const AccountInfo = ({
  errors,
  formValue,
  goLogin,
  handleChangeForm,
  handleSubmit,
  loading,
  confirmPassword,
  setConfirmPassword,
  handleClearForm,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const isEmptyParams = useMemo(() => {
    const filterKeys = ['email', 'password', 'phoneNumber'];
    const checkValid =
      filterKeys.some((key) => !formValue[key]) || !confirmPassword;

    return checkValid;
  }, [formValue, confirmPassword]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height={'100%'}
    >
      <Box textAlign="center">
        <CustomTitle>Sign up</CustomTitle>

        <form>
          <Box maxWidth={600} textAlign="left" mt={5}>
            <CustomInputLabel>Email</CustomInputLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="email@address.com"
              autoComplete="email"
              value={formValue.email || ''}
              onChange={handleChangeForm('email')}
              error={!!errors.email}
              helperText={errors.email}
              InputProps={{
                endAdornment: (
                  <ClearComponent
                    value={formValue.email}
                    onClear={() => handleClearForm('email')}
                  />
                ),
              }}
            />
            <CustomInputLabel style={{ marginTop: 24 }}>
              Password
            </CustomInputLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="12345678a"
              autoComplete="password"
              value={formValue.password || ''}
              onChange={handleChangeForm('password')}
              type={showPassword ? '' : 'password'}
              error={!!errors.password}
              helperText={
                errors.password ||
                'Password must be 8-32 characters long and includes at least one number, one letter (uppercase or lowercase), and may contain special characters like !@#$%^&*(),_-+='
              }
              InputProps={{
                endAdornment: (
                  <>
                    <ClearComponent
                      value={formValue.password}
                      onClear={() => handleClearForm('password')}
                    />
                    <InputAdornment position="start">
                      <>
                        <HideIcon
                          style={{ cursor: 'pointer' }}
                          hidden={showPassword}
                          onClick={() => setShowPassword(true)}
                        />
                        <UnHideIcon
                          style={{ cursor: 'pointer' }}
                          hidden={!showPassword}
                          onClick={() => setShowPassword(false)}
                        />
                      </>
                    </InputAdornment>
                  </>
                ),
              }}
            />
            <CustomInputLabel style={{ marginTop: 24 }}>
              Confirm password
            </CustomInputLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="12345678a"
              autoComplete="password"
              value={confirmPassword || ''}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type={showConfirmPassword ? '' : 'password'}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <>
                    <ClearComponent
                      value={confirmPassword}
                      onClear={() => handleClearForm('confirmPassword')}
                    />

                    <InputAdornment position="start">
                      <>
                        <HideIcon
                          style={{ cursor: 'pointer' }}
                          hidden={showConfirmPassword}
                          onClick={() => setShowConfirmPassword(true)}
                        />
                        <UnHideIcon
                          style={{ cursor: 'pointer' }}
                          hidden={!showConfirmPassword}
                          onClick={() => setShowConfirmPassword(false)}
                        />
                      </>
                    </InputAdornment>
                  </>
                ),
              }}
            />
            <CustomInputLabel style={{ marginTop: 24 }}>
              Phone number
            </CustomInputLabel>
            <Box display="flex" gridGap={8}>
              <CustomCountryCodeInput
                country={formValue.countryCode}
                disabled={false}
                onChange={handleChangeForm('countryCode')}
              />
              <NumberFormat
                fullWidth
                customInput={CustomTextField}
                variant="outlined"
                placeholder="1234 5678"
                onValueChange={handleChangeForm('phoneNumber')}
                allowNegative={false}
                value={formValue.phoneNumber || ''}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                InputProps={{
                  endAdornment: (
                    <ClearComponent
                      value={formValue.phoneNumber}
                      onClear={() => handleClearForm('phoneNumber')}
                    />
                  ),
                }}
              />
            </Box>
          </Box>

          <CustomLoadingButton
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading || isEmptyParams}
            loading={loading}
            style={{ marginTop: 24, width: '100%' }}
          >
            Sign up
          </CustomLoadingButton>
        </form>
      </Box>

      <Box display="flex" gridGap={6} alignSelf="center" py={3}>
        <CustomTypography>Already have an account?</CustomTypography>
        <CustomLink onClick={goLogin}>Sign in</CustomLink>
      </Box>
    </Box>
  );
};

export default AccountInfo;
