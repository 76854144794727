import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import customToast from '@/new-components/CustomNotification';
import AccountInfo from './AccountInfo';
import ProfessionInfo from './ProfessionInfo';
import PersonalInfo from './PersonalInfo';
import VerifyOTP from './VerifyOTP';
import Slide from '@material-ui/core/Slide';
import { setUpRecaptchaThenSendOTP, sendOTP } from '../../../../firebase';
import authDispatcher from '../../action/auth';
import { countries } from '../../../../countries';
import { getNumberInfo } from '../../../../helpers';
import { StyledButton } from '../../../common/componentUI/commonStyleComponents';
import { BTN_CREATE_ACCOUNT_ID, REGISTER_PROCESS_STEPS } from '../../constants';
import Progress from 'react-progress-2';

const Register = ({ clinics, history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('SG');
  const [mcr, setMcr] = useState('');
  const [cerPhotoUrl, setCerPhotoUrl] = useState('');
  const [cerExpDate, setCerExpDate] = useState(new Date());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [credentials, setCredentials] = useState('');
  const [clinicId, setClinicId] = useState('');
  const [step, setStep] = useState(REGISTER_PROCESS_STEPS.accountInfo);
  const [didSendOTP, setDidSendOTP] = useState(false);

  useEffect(() => {
    authDispatcher.getAllClinics();
  }, []);

  const toVerifyOTP = () => {
    Progress.show();
    if (!firstName || !lastName || !credentials || !clinicId) {
      customToast('error', 'Please provide necessary information.');
      return;
    }
    setUpRecaptchaThenSendOTP({
      phone: getNumberInfo(phone, countryCode)[1],
      buttonId: BTN_CREATE_ACCOUNT_ID,
      ifSuccess: () => setDidSendOTP(true),
      ifFailed: err => {
        customToast('error', err.message);
        return;
      }
    });
    setStep(step + 1);

    // Twilio otp
    // authDispatcher.sendOTP(
    //   { otpPhoneNumber: getNumberInfo(phone, countryCode)[1] },
    //   result => {
    //     if (result) {
    //       Progress.hide();
    //       setStep(step + 1);
    //       setDidSendOTP(true);
    //     }
    //   }
    // );
  };

  const reSendOTP = () => {
    Progress.show();
    setDidSendOTP(false);
    sendOTP({
      phone: `+${countries[countryCode].callingCode[0]}${phone}`,
      ifSuccess: () => setDidSendOTP(true),
      ifFailed: err => {
        customToast('error', err.message);
        return;
      }
    });

    // Twilio otp
    // authDispatcher.sendOTP(
    //   { otpPhoneNumber: `+${countries[countryCode].callingCode[0]}${phone}` },
    //   result => {
    //     if (result) {
    //       Progress.hide();
    //       setDidSendOTP(true);
    //       customToast('success','Resent');
    //     }
    //   }
    // );
  };

  const doRegister = () => {
    authDispatcher.register(
      email,
      password,
      phone,
      countryCode,
      mcr,
      cerPhotoUrl,
      cerExpDate,
      clinicId,
      firstName,
      lastName,
      credentials,
      history
    );
  };

  return (
    <>
      <StyledButton
        id={BTN_CREATE_ACCOUNT_ID}
        style={{
          display: 'none'
        }}
      >
        Sign Up
      </StyledButton>
      <Slide direction="left" in={step === REGISTER_PROCESS_STEPS.accountInfo}>
        <div
          key={step}
          style={{
            display:
              step === REGISTER_PROCESS_STEPS.accountInfo ? 'static' : 'none'
          }}
        >
          <AccountInfo
            {...{ step, setStep, email, setEmail, password, setPassword }}
          />
        </div>
      </Slide>
      <Slide
        direction="left"
        in={step === REGISTER_PROCESS_STEPS.professionInfo}
      >
        <div
          key={step}
          style={{
            display:
              step === REGISTER_PROCESS_STEPS.professionInfo ? 'static' : 'none'
          }}
        >
          <ProfessionInfo
            {...{
              step,
              setStep,
              phone,
              setPhone,
              countryCode,
              setCountryCode,
              mcr,
              setMcr,
              setCerPhotoUrl,
              cerExpDate,
              setCerExpDate
            }}
          />
        </div>
      </Slide>
      <Slide direction="left" in={step === REGISTER_PROCESS_STEPS.personalInfo}>
        <div
          key={step}
          style={{
            display:
              step === REGISTER_PROCESS_STEPS.personalInfo ? 'static' : 'none'
          }}
        >
          <PersonalInfo
            {...{
              firstName,
              setFirstName,
              lastName,
              setLastName,
              credentials,
              setCredentials,
              clinicId,
              setClinicId,
              clinics: clinics.list || [],
              toVerifyOTP
            }}
          />
        </div>
      </Slide>
      <Slide direction="left" in={step === REGISTER_PROCESS_STEPS.verifyOTP}>
        <div
          key={step}
          style={{
            display:
              step === REGISTER_PROCESS_STEPS.verifyOTP ? 'static' : 'none'
          }}
        >
          <VerifyOTP
            didSendOTP={didSendOTP}
            phone={phone}
            countryCode={countryCode}
            reSendOTP={reSendOTP}
            doRegister={doRegister}
          />
        </div>
      </Slide>
    </>
  );
};

const mapStateToProps = state => {
  const { clinics } = state.auth;
  return { clinics };
};

export default connect(mapStateToProps)(Register);
