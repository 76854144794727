import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';

import { isEmpty, isEqual } from 'lodash';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { RadioActiveIcon, RadioInactiveIcon } from '@/assets/svg';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 20,
    color: theme.palette.grey[500]
  },
  text: {
    fontWeight: 600
  }
});

const useStyles = makeStyles({
  title: {
    fontWeight: 600
  }
});

const CustomDialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles(theme => ({
  paper: {
    borderRadius: 16,
    width: 400
  }
}))(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset'
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const CustomDialogContent = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end'
  }
})(DialogActions);

const CustomFormControlLabel = withStyles({
  root: {
    marginBottom: 8
  },
  label: {
    /* [class="max-line"] */
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    display: '-webkit-inline-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2
  }
})(FormControlLabel);

const ModalStaffType = ({
  open,
  onClose,
  onSubmit,
  lstStaffTypeItems,
  currentStaffType,
  hasChooseStaffType
}) => {
  const classes = useStyles();

  const [localStaffType, setLocalStaffType] = useState(currentStaffType);

  const handleChangeLocalStaffType = selectedIndex => {
    const selectedLocalStaffType = lstStaffTypeItems[selectedIndex];

    if (
      isEqual(selectedLocalStaffType, localStaffType) &&
      !hasChooseStaffType
    ) {
      setLocalStaffType(null);
    } else {
      setLocalStaffType(selectedLocalStaffType);
    }
  };

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
    >
      <CustomDialogTitle onClose={onClose}>Edit staff type</CustomDialogTitle>
      <CustomDialogContent dividers>
        <Typography className={classes.title}>Select staff type</Typography>

        <RadioGroup value={localStaffType}>
          {!isEmpty(lstStaffTypeItems) &&
            lstStaffTypeItems.map((it, index) => (
              <CustomFormControlLabel
                onClick={() => handleChangeLocalStaffType(index)}
                key={index}
                value={index}
                checked={isEqual(it, localStaffType)}
                control={
                  <Radio
                    color="primary"
                    icon={<RadioInactiveIcon />}
                    checkedIcon={<RadioActiveIcon />}
                  />
                }
                label={it.type}
              />
            ))}
        </RadioGroup>
      </CustomDialogContent>

      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => onSubmit(localStaffType)}
        >
          Save
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalStaffType;
