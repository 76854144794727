import { synthesize } from 'redux-dispatcher';
import { uploadToFirebaseAsync } from '../../../firebase';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, filterOption = {}) => async ({ Api, getState }) => {
    let pagingData = paging || getState().trainerManagement.paging;
    let { result, status } = await Api.post(`Users/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      roleType: 'Trainer',
      ...filterOption
    });
    status === 200 && trainerDispatcher.getDataSuccess(result);
  },
  editData: (data, searchKey, callback, onFinish) => async ({
    Api,
    customToast,
    getState
  }) => {
    const response = await Api.put(`Users/${data.id}`, data);
    if (response.status === 200) {
      let { paging } = getState().trainerManagement;
      trainerDispatcher.getData(searchKey, paging);
      customToast('success', 'Success');
      callback();
    }
    onFinish();
  },
  updateStatus: (data, searchKey) => async ({ Api, customToast, getState }) => {
    const response = await Api.put('Trainers/approve', data);
    if (response.status === 200) {
      let { paging } = getState().trainerManagement;
      trainerDispatcher.getData(searchKey, paging);
      customToast('success', 'Success');
    } else {
      customToast('error', 'Failed');
    }
  },
  deleteData: (id, searchKey, filterOption = {}) => async ({
    Api,
    customToast,
    getState
  }) => {
    const response = await Api.delete(`Users/${id}`);
    if (response.status === 200) {
      let { paging } = getState().trainerManagement;
      trainerDispatcher.getData(searchKey, paging, filterOption);
      customToast('success', 'Success');
    }
  }
};

const trainerDispatcher = synthesize('trainerManagement', mapDispatchToAC);
export default trainerDispatcher;
