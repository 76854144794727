import React, { useState } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Divider,
  Paper,
  Box
} from '@material-ui/core';
import { ClipboardIcon } from '@/assets/svg';
import { isEmpty } from 'lodash';
import AddOnsItem from '../../ViewPatientRegistration/AddOnsItem';
import ViewPackageItem from './ViewPackageItem';

const useStyles = makeStyles({
  container: {
    display: 'flex'
  },

  leftContainer: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '36px 20px',
    flex: '1',
    borderRight: '1px solid #F1F1F1',
    height: 'calc(100vh - 283px)'
  },

  rightContainer: {
    overflow: 'scroll',
    padding: '16px 40px',
    display: 'flex',
    height: 'calc(100vh - 283px)'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    fontSize: 20,
    gap: 8
  },
  disabledSwitch: {
    pointerEvents: 'none',
    cursor: 'not-allowed'
  }
});

const FollowSelectServices = ({
  projectId,
  formValue,
  setFormValue,
  packageDetail,
  dataExist,
  restrictedTestIds
}) => {
  const classes = useStyles();

  return (
    <Paper square variant="outlined" className={classes.container}>
      <Box
        flexDirection="column"
        className={classes.leftContainer}
        gridGap={16}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className={classes.title}>
              <ClipboardIcon style={{ color: '#666666' }} />
              Selected packages
            </Typography>
          </Grid>
          {!isEmpty(formValue?.lstPackages) && (
            <Grid item xs={12}>
              <ViewPackageItem
                packageDetail={packageDetail}
                ignoredTests={dataExist?.ignoredTests}
                ignoredProfiles={dataExist?.ignoredProfiles}
                formValue={formValue}
                setFormValue={setFormValue}
                projectId={projectId}
                restrictedTestIds={restrictedTestIds}
              />
            </Grid>
          )}

          {!isEmpty(formValue.lstPackages) && !isEmpty(formValue.lstTests) && (
            <Grid item xs={12}>
              <Divider light variant="middle" />
            </Grid>
          )}

          {!isEmpty(formValue.lstTests) && (
            <Grid item xs={12}>
              <AddOnsItem listData={formValue.lstTests} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default FollowSelectServices;
