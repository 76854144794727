import { makeStyles, Button } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RemoveIcon from '@material-ui/icons/Remove';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const useStyle = makeStyles({
  root: {
    width: '100%',
    minWidth: '130px',
    height: '40px',
    border: '1px solid #e9e9e9',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconActive: {
    color: '#0b8e5e',
  },
  iconInactive: {
    color: '#999999',
  },
  txtActive: {
    color: '#333333',
  },
  txtFull: {
    color: '#AD5E99',
  },
  txt: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 14,
    margin: '0 8px',
  },
  unavailable: {
    justifyContent: 'flex-start',
    border: '1px solid #e1e1e1',
    backgroundColor: '#f6f6f6',
    '&.MuiButton-root.Mui-disabled': {
      color: '#999999',
    },
  },
});

const BookingItem = ({ item, className, onClick }) => {
  const classes = useStyle();

  const { baseSlot, slot, isActive, isUnavailable } = item;

  if (isUnavailable) {
    return (
      <Button
        className={classnames(classes.root, className, classes.unavailable)}
        disabled
      >
        <FiberManualRecordIcon fontSize="small" />
        <RemoveIcon fontSize="small" />
      </Button>
    );
  }
  return (
    <Button
      id={item.id}
      className={classnames(classes.root, className)}
      onClick={() => onClick(item)}
    >
      <FiberManualRecordIcon
        className={classnames(
          isActive
            ? slot === baseSlot
              ? classes.txtFull
              : classes.iconActive
            : classes.iconInactive
        )}
        fontSize="small"
      />
      <span
        className={classnames(
          classes.txt,
          isActive
            ? slot === baseSlot
              ? classes.txtFull
              : classes.iconActive
            : classes.iconInactive
        )}
      >
        {isActive ? (slot === baseSlot ? 'Full' : 'Open') : 'Close'}
      </span>
      <span
        style={{
          flex: 1,
        }}
        className={classnames(
          isActive ? classes.txtActive : classes.iconInactive
        )}
      >{`${slot}/${baseSlot}`}</span>
    </Button>
  );
};

BookingItem.defaultProps = {
  className: '',
};

BookingItem.propTypes = {
  item: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default BookingItem;
