import { urlLabel } from '@/enum/PermissionEnum';
import fetchHelper from '@/helpers/FetchHelper';
import { authDispatcher } from '@/module/auth';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

class PrivatePatientLayout extends React.Component {
  componentDidMount() {
    const { accessToken } = this.props;

    if (accessToken) {
      fetchHelper.addDefaultHeader('Authorization', `Bearer ${accessToken}`);
      authDispatcher.getUserInfo();
    }
  }
  render() {
    const { location } = this.props;

    if (location.pathname !== `/${urlLabel.teleconsultNow}`) {
      return <Redirect to={`/${urlLabel.teleconsultNow}`} />;
    }
    return (
      <Paper square elevation={0}>
        {this.props.children}
      </Paper>
    );
  }
}

PrivatePatientLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    userInfo: state.auth.userInfo,
    privilege: state.userPermission.privilege,
  };
};
export default withRouter(connect(mapStateToProps)(PrivatePatientLayout));
