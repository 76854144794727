import React, { useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import { isBoolean, isEmpty } from 'lodash';
import CustomModalTitle from '@/new-components/CustomModal/CustomModalTitle';
import CustomDate from '@/new-components/CustomDate';
import { ArrowDown, CheckboxActive, CheckboxDefault } from '@/assets/svg';
import moment from 'moment';
import { getFullTimeInDay } from '@/module/setup/utils';
import { CustomAutocomplete } from '@/module/company/component/CreateAndEditCompany/styled';

const CustomDialog = withStyles(theme => ({
  paper: {
    borderRadius: 16,
    width: 480
  }
}))(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset'
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const CustomDialogContent = withStyles(theme => ({
  root: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end'
  }
})(DialogActions);

const CustomTypography = withStyles({
  root: {
    color: '#666666',
    fontWeight: 600,
    marginBottom: 8
  }
})(Typography);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
      paddingLeft: 0
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    },

    '& p': {
      marginTop: 1
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: 0,
      paddingLeft: 4,
      marginLeft: 0
    }
  }
})(TextField);

const ModalSendMessage = ({ open, onClose, onSubmit, loading }) => {
  const [isSchedule, setIsSchedule] = useState(false);
  const [formValue, setFormValue] = useState({
    title: '',
    body: '',
    redirectUrl: ''
  });

  const [publishDate, setPublishDate] = useState({
    date: '',
    time: ''
  });

  const timeListDefault = useMemo(() => {
    if (isEmpty(publishDate.date))
      return getFullTimeInDay().filter(obj => moment(obj.value, 'h:mm A'));
    const listTime = getFullTimeInDay().filter(obj => {
      const dateTime = moment(
        `${publishDate.date} ${obj.value}`,
        'YYYY-MM-DD hh:mm A'
      ).toISOString();

      return moment(dateTime).isSameOrAfter(moment());
    });

    return listTime;
  }, [publishDate]);

  const handleChangeForm = key => e => {
    let value = e.target.value;
    setFormValue({ ...formValue, [key]: value });
  };

  const handleSelectedSchedule = e => {
    setIsSchedule(e.target.checked);
    setPublishDate({
      date: '',
      time: ''
    });
  };

  const handlePublishDateChangeForm = key => (e, value) => {
    let newValue = '';
    const utcValue = moment(e)
      .startOf('date')
      .format();
    if (key === 'time') {
      newValue = value.value;
      setPublishDate({ ...publishDate, [key]: newValue });
    } else {
      newValue = utcValue;
      setPublishDate({ ...publishDate, [key]: newValue, time: '' });
    }
  };

  const handleSubmit = () => {
    const { date, time } = publishDate;
    const formatDate = moment(date).format('YYYY-MM-DD');
    const momentObj = moment(formatDate + time, 'YYYY-MM-DDLT');
    const dateTime = momentObj.utc().toISOString();
    onSubmit(
      {
        ...formValue,
        publishDate: dateTime
      },
      isSchedule
    );
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterFormValueKeys = ['title', 'body'];
    const filterPublishDateKeys = Object.keys(publishDate);

    const checkFormValue = filterFormValueKeys.some(key =>
      isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
    );

    const checkPublishDate = filterPublishDateKeys.some(key =>
      isBoolean(publishDate[key])
        ? !publishDate[key]
        : isEmpty(publishDate[key])
    );

    if (isSchedule) {
      return checkFormValue || checkPublishDate;
    } else return checkFormValue;
  }, [isSchedule, publishDate, formValue]);

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomModalTitle onClose={onClose}>Send message</CustomModalTitle>
      <CustomDialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTypography>Title</CustomTypography>
            <CustomTextField
              placeholder="Enter text"
              value={formValue.title}
              variant="outlined"
              fullWidth
              onChange={handleChangeForm('title')}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTypography>Content</CustomTypography>
            <CustomTextField
              placeholder="Enter text"
              value={formValue.body}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              onChange={handleChangeForm('body')}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTypography>URL</CustomTypography>
            <CustomTextField
              type="url"
              placeholder="Enter link"
              value={formValue.redirectUrl}
              variant="outlined"
              fullWidth
              onChange={handleChangeForm('redirectUrl')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              checked={isSchedule}
              control={
                <Checkbox
                  icon={<CheckboxDefault />}
                  checkedIcon={<CheckboxActive />}
                  color="primary"
                  onChange={handleSelectedSchedule}
                />
              }
              label="Schedule to send"
            />
          </Grid>

          {isSchedule ? (
            <>
              <Grid item xs={6}>
                <CustomTypography>Date</CustomTypography>
                <CustomDate
                  selectedDate={
                    publishDate.date ? moment(publishDate.date) : ''
                  }
                  minDate={moment()}
                  isFullWidth
                  placeholder="Select date"
                  clearable={false}
                  onChange={handlePublishDateChangeForm('date')}
                  style={{ height: 48 }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTypography>Time</CustomTypography>
                <CustomAutocomplete
                  getOptionSelected={(option, value) => option === value}
                  getOptionLabel={option => option.value || ''}
                  options={timeListDefault || []}
                  value={
                    timeListDefault.find(
                      it => it?.value === publishDate?.time
                    ) || ''
                  }
                  onChange={handlePublishDateChangeForm('time')}
                  popupIcon={<ArrowDown />}
                  renderInput={params => (
                    <CustomTextField
                      variant="outlined"
                      placeholder="Select time"
                      {...params}
                    />
                  )}
                  disableClearable
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </CustomDialogContent>

      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={isEmptyFilterParams || loading}
        >
          Send
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalSendMessage;
