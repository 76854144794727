import {
  DAYS_PER_WEEK,
  FORMAT_TIME,
  FORMAT_YYYY_MM_DD,
  DAYS_OF_WEEK,
  TIME_ZONE_SINGAPORE,
} from '../constants';
import { generateData } from '../utils';
import moment from 'moment';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: (data) => ({ data }),
  getDataBooking:
    (params) =>
    async ({ Api }) => {
      const { week, type } = params;
      const startDateDefault =
        moment(week).format(FORMAT_YYYY_MM_DD) + 'T00:00:00Z';
      const endDateDefault =
        moment(week).format(FORMAT_YYYY_MM_DD) + 'T23:59:59Z';

      const response = await Api.post('BookingTimeConfigurations/filter', {
        fromDate: startDateDefault,
        toDate:
          moment(week)
            .add(DAYS_PER_WEEK - 1, 'day')
            .format(FORMAT_YYYY_MM_DD) + 'T23:59:59Z',
        type,
      });

      let magicWeek = moment(startDateDefault).add(TIME_ZONE_SINGAPORE, 'hour');

      if (!response) return;

      let list = [];
      let defaultStartTime;
      let defaultEndTime;
      if (response.status === 200) {
        defaultStartTime = response.result?.startTime;
        list = response.result?.list;
        defaultEndTime = response.result?.endTime;
      }
      const [keys, bookings] = generateData(
        magicWeek,
        moment(startDateDefault)
          .subtract(TIME_ZONE_SINGAPORE, 'hour')
          .format(FORMAT_TIME),
        moment(endDateDefault)
          .subtract(TIME_ZONE_SINGAPORE, 'hour')
          .format(FORMAT_TIME)
      );
      const newBookings = bookings.map((bookingGen) => {
        const bookingMap = list.find((bookingFrmServer) =>
          moment(bookingFrmServer.startDate)
            .add(TIME_ZONE_SINGAPORE, 'hour')
            .isSame(moment(bookingGen.startDate))
        );
        if (bookingMap) {
          return {
            ...bookingMap,
            time: bookingGen.time,
            date: bookingGen.date,
            isUnavailable: false,
          };
        }
        return bookingGen;
      });

      telecounselAppointmentConfigDispatch.getDataSuccess({
        keys,
        bookings: newBookings,
        startTime: defaultStartTime,
        endTime: defaultEndTime,
      });
    },
  updateBookingTime:
    ({ date, option, baseSlot, type }, onSuccess, onFail) =>
    async ({ Api }) => {
      const isActive = option === 'open'; // open: true, close: false
      const { status } = await Api.put('BookingTimeConfigurations', {
        startDate: date,
        isActive,
        baseSlot: Number(baseSlot),
        type,
      });

      if (status === 200) {
        telecounselAppointmentConfigDispatch.updateListBooking(
          date,
          isActive,
          baseSlot
        );
        onSuccess && onSuccess();
      } else {
        onFail && onFail();
      }
    },
  updateListBooking: (startDate, isActive, baseSlot) => ({
    startDate,
    isActive,
    baseSlot,
  }),
  updateBlockBooking:
    (data, resolve, reject) =>
    async ({ Api }) => {
      try {
        const {
          option,
          startDate,
          endDate,
          endTime,
          startTime,
          baseSlot,
          daysOfWeek: valueDaysOfWeek,
          type,
        } = data;
        const daysOfWeek = valueDaysOfWeek.map((d) => DAYS_OF_WEEK[d]);

        const response = await Api.post('BookingTimeConfigurations/block', {
          isActive: option === 'open',
          startDate: moment(startDate).format('YYYY-MM-DD') + 'T00:00:00Z',
          endDate: moment(endDate).format('YYYY-MM-DD') + 'T23:59:59Z',
          startTime: moment(startTime).format(FORMAT_TIME),
          endTime: moment(endTime).format(FORMAT_TIME),
          baseSlot: Number(baseSlot),
          daysOfWeek,
          type,
        });
        if (response && response.status === 200) {
          resolve();
        } else {
          reject();
        }
      } catch (error) {
        reject(error);
      }
    },
};

const telecounselAppointmentConfigDispatch = synthesize(
  'telecounselApptConfig',
  mapDispatchToAC
);
export default telecounselAppointmentConfigDispatch;
