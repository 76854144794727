import CustomSelect from '../CustomSelect';
import { CustomWeekStaticDatePicker } from './styled';
import { NewCalendar } from '@/assets/svg';
import { getDateRange } from '@/helpers';
import CustomQuickRangeGroup from '@/new-components/CustomChip/CustomGroupChips';
import CustomAdapter from '@/new-components/CustomDate/CustomDateAdapter';
import {
  Box,
  Popover,
  TextField,
  makeStyles,
  withStyles
} from '@material-ui/core';
import { LocalizationProvider, PickersDay } from '@material-ui/pickers';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import React, { useState } from 'react';

export const QUICK_RANGE_WEEK = ['This week', 'Last week'];
export const QUICK_RANGE_MONTH = ['This month', 'Last month', 'Next month'];

const moment = extendMoment(Moment);

const QuickRangeContainer = withStyles({
  root: {
    display: 'flex',
    padding: 16,
    maxWidth: 352,
    flexWrap: 'wrap',
    borderBottom: '2px solid #ECEDF0'
  }
})(Box);

const useStyles = makeStyles(theme => ({
  highlight: {
    margin: '0 !important',
    width: '47px !important',
    borderRadius: 'unset !important',
    backgroundColor: theme.palette.primary.light,

    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },

    '&:focus': {
      backgroundColor: theme.palette.primary.light,
      color: '#333333'
    },

    '& .MuiTouchRipple-root': {
      width: 27,
      left: '50%',
      borderRadius: 4,
      transform: 'translate(-50%)'
    },

    '&.MuiPickersDay-root.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: '#333333',

      '&:hover': {
        backgroundColor: theme.palette.primary.light
      }
    },
    '&.MuiPickersDay-today:not(.Mui-selected)': {
      border: 'unset',

      '& .MuiTouchRipple-root': {
        border: '1px solid rgba(0, 0, 0, 0.54)'
      }
    }
  },
  firstHighlight: {
    background: 'linear-gradient(to right,white 50%,#ffe9fb 50%)',

    '& .MuiPickersDay-dayLabel': {
      zIndex: 1,
      color: theme.palette.common.white
    },

    '& .MuiTouchRipple-root': {
      borderRadius: 4,

      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#333333'
    }
  },
  endHighlight: {
    background: 'linear-gradient(to left,white 50%,#ffe9fb 50%)',

    '& .MuiPickersDay-dayLabel': {
      zIndex: 1,
      color: theme.palette.common.white
    },

    '& .MuiTouchRipple-root': {
      borderRadius: 4,

      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#333333'
    }
  }
}));

const CustomWeekPicker = ({
  selectedDate,
  onChange,
  setWeekPicker,
  placeholder = 'DD/MM/YYYY',
  dates,
  setDates,
  filterOptions,
  setFilterOptions,
  views,
  quickRange,
  setQuickRange
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeQuickRange = (event, rangeType) => {
    setQuickRange(rangeType);
    if (rangeType) {
      let [firstDate, lastDate] = getDateRange(rangeType);
      setWeekPicker(moment(firstDate));
      setDates([firstDate, lastDate]);
      setFilterOptions({
        ...filterOptions,
        fromDate: firstDate.toISOString(),
        toDate: lastDate.toISOString()
      });
    } else {
      let startDate = moment().startOf('week');
      let endDate = moment().endOf('week');
      switch (views) {
        case 'month':
          startDate = moment().startOf('month');
          endDate = moment().endOf('month');
          break;
        default:
          break;
      }
      setWeekPicker(startDate);
      setDates([startDate, endDate]);
      setFilterOptions({
        ...filterOptions,
        fromDate: startDate.toISOString(),
        toDate: endDate.toISOString()
      });
    }
  };

  const renderWeekPickerDay = (date, _selectedDates, DayComponentProps) => {
    const dateClone = moment(date);
    const selectedDateClone = moment(selectedDate);

    const start = moment(selectedDateClone).startOf('week');
    const end = moment(selectedDateClone)
      .endOf('week')
      .startOf('day');

    const rangeDate = moment().range(start, end);

    const dayIsBetween = rangeDate.contains(dateClone);
    const isFirstDay = moment(dateClone).isSame(start);
    const isLastDay = moment(dateClone).isSame(end);

    return (
      <PickersDay
        {...DayComponentProps}
        // disableMargin
        className={cn({
          [classes.highlight]: dayIsBetween,
          [classes.firstHighlight]: isFirstDay,
          [classes.endHighlight]: isLastDay
        })}
      />
    );
  };

  const renderDateValue = (array, defaultLabel) => {
    /* 
    1. !startDate && !endDate => defaultLabel
    2. startDate && !endDate => startDate format: DD/MM/YYYY
    3. startDate && endDate
      - if startDate and endDate in the same month, year => format(DD-DD/MM/YYYY)
      - else format(DD/MM/YYYY-DD/MM/YYYY)
    */

    if (isEmpty(array)) return defaultLabel;
    const startDate = moment(array[0]).format('DD/MM/YYYY');
    const endDate = moment(array[1]).format('DD/MM/YYYY');

    if (startDate.trim() === endDate.trim()) {
      return `${startDate} `;
    } else if (moment(array[0]).isSame(moment(array[1]), 'year')) {
      const newStartDate = moment(array[0]).format('DD/MM');
      return `${newStartDate} - ${endDate} `;
    }

    return `${startDate} - ${endDate} `;
  };

  const renderListQuickRange = type => {
    switch (type) {
      case 'week':
        return QUICK_RANGE_WEEK;
      case 'month':
        return QUICK_RANGE_MONTH;
      default:
        return [];
    }
  };

  return (
    <CustomSelect
      isFullWidth
      onClick={handleClick}
      suffixIcon={
        <NewCalendar
          style={{
            marginLeft: 5
          }}
        />
      }
      renderValue={renderDateValue(dates, placeholder)}
      placeholder={placeholder}
      colorInput="#000000de"
      padding="7px 11px"
    >
      <Popover
        id="date-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        style={{
          borderRadius: 12,
          marginTop: 15
        }}
        PaperProps={{
          style: {
            borderRadius: 12,
            border: '1px solid #E1E1E1',
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <LocalizationProvider
          dateAdapter={CustomAdapter}
          dateFormats={{ monthShort: 'MMMM' }}
        >
          <CustomWeekStaticDatePicker
            type={views}
            views={['month', 'year'].includes(views) ? [views] : ['date']}
            displayStaticWrapperAs="desktop"
            value={selectedDate}
            renderDay={views === 'day' ? null : renderWeekPickerDay}
            renderInput={props => <TextField {...props} />}
            onChange={value => {
              setQuickRange(null);
              onChange(views, value);
            }}
            minDate={views === 'year' ? moment([2020, 0, 1]) : null}
            maxDate={views === 'year' ? moment().endOf('year') : null}
          />

          <QuickRangeContainer>
            <CustomQuickRangeGroup
              value={quickRange}
              handleChange={handleChangeQuickRange}
              listButtons={renderListQuickRange(views)}
            />
          </QuickRangeContainer>
        </LocalizationProvider>
      </Popover>
    </CustomSelect>
  );
};

export default CustomWeekPicker;
