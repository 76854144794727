import { synthesize } from 'redux-dispatcher';
import {
  formatRolePermission,
  filterUserPermission,
  groupUserPermission
} from '../helper';
import {
  MENU_ITEMS,
  GROUPED_MENU_ITEMS
} from '../../common/component/MenuItem';
import { get } from 'lodash';

const mapDispatchToAC = {
  getAllPermissionSuccess: data => ({ data }),
  getAllPermission: () => async ({ Api }) => {
    let { result, status } = await Api.get(`RolePermissions`);
    status === 200 &&
      userPermissionDispatcher.getAllPermissionSuccess(
        formatRolePermission(result)
      );
  },
  setState: (state, value) => ({ state, value }),
  editUserPermission: (items, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    userPermissionDispatcher.setState('loading', true);
    const { result, status, message } = await Api.put(`RolePermissions`, {
      items
    });
    userPermissionDispatcher.setState('loading', false);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
      return customToast('success', message || 'Edit success');
    }
  },
  getUserPermission: cb => async ({ Api, getState }) => {
    const { result = [], status } = await Api.get(`RolePermissions/user`);
    if (status === 200) {
      const roleName = get(getState(), 'auth.userInfo.roleName');
      const filteredPermission = filterUserPermission(
        MENU_ITEMS,
        result,
        roleName
      );
      const groupedPermission = groupUserPermission(
        filteredPermission,
        GROUPED_MENU_ITEMS
      );

      if (filteredPermission.find(item => item.privilege === 'Workout')) {
        cb && cb();
      }
      userPermissionDispatcher.getUserPermissionSuccess(
        filteredPermission,
        groupedPermission
      );
    }
  },
  getUserPermissionSuccess: (filteredPermission, groupedPermission) => ({
    filteredPermission,
    groupedPermission
  }),
  clearPermission: payload => ({ payload })
};

const userPermissionDispatcher = synthesize('userPermission', mapDispatchToAC);
export default userPermissionDispatcher;
