import React from 'react';
import { KeyboardArrowDown } from '@material-ui/icons';
import { CloseCircleFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

const CustomSelect = ({
  children,
  onClick,
  clearable,
  onClear,
  renderValue,
  prefixIcon,
  isFullWidth,
  width = 300,
  colorBorderBottomInput,
  colorInput = '#8F9BA9',
  borderBottom = true,
  arrowStyle
}) => {
  return (
    <div
      style={{
        position: 'relative',
        cursor: 'pointer',
        borderBottom: borderBottom
          ? `1px solid ${colorBorderBottomInput}`
          : 'none',
        padding: '4px 11px 4px 11px',
        alignSelf: 'flex-end',
        width: isFullWidth ? '100%' : width,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginRight: 20
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: 'flex',
          color: colorInput,
          alignItems: 'flex-end',
          overflow: 'hidden'
        }}
      >
        {prefixIcon}
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
        >
          {renderValue}
        </div>
        {children}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end'
        }}
      >
        {clearable && (
          <CloseCircleFilled
            className="ant-input-clear-icon"
            style={{ marginBottom: 4 }}
            onClick={e => {
              e.stopPropagation();
              onClear();
            }}
          />
        )}
        <KeyboardArrowDown
          style={
            arrowStyle || {
              fontSize: 20,
              color: '#E9E9E9'
            }
          }
        />
      </div>
    </div>
  );
};

CustomSelect.propTypes = {
  isFullWidth: PropTypes.bool,
  colorBorderBottomInput: PropTypes.string
};

CustomSelect.defaultProps = {
  isFullWidth: false,
  colorBorderBottomInput: '#E9E9E9'
};

export default CustomSelect;
