import { synthesize } from 'redux-dispatcher';
import { find } from 'lodash';
import moment from 'moment';

const mapDispatchToAC = {
  getLivestreamList: (
    search,
    paging,
    sortOption = {},
    filterOption = {}
  ) => async ({ Api, getState }) => {
    const pagingData = paging || getState().livestream.paging;
    const { pageIndex, pageSize } = pagingData;

    const { result, status } = await Api.post(`TrainerWorkouts/filter`, {
      pageIndex,
      pageSize,
      search,
      ...sortOption,
      ...filterOption
    });
    status === 200 && upcomingDispatcher.getLivestreamListSuccess(result);
  },
  getLivestreamListSuccess: payload => payload,
  setState: (state, value) => ({ state, value }),
  updateLocalStream: streams => ({ streams }),
  updateStatusOnEndStream: id => ({ getState }) => {
    const listLivestream = getState().upcoming.list;
    const foundLivestream = find(listLivestream, ['id', id]);

    if (
      foundLivestream &&
      moment().isAfter(moment(foundLivestream.startLivestreamDate))
    ) {
      upcomingDispatcher.updateStatusOnEndStreamSuccess(id, 'Past');
    } else {
      upcomingDispatcher.updateStatusOnEndStreamSuccess(id, 'Upcomming');
    }
  },
  updateStatusOnEndStreamSuccess: (id, status) => ({ id, status }),
  updateRemoteStream: streams => ({ streams }),
  checkStartedLivestream: (workoutId, callback) => async ({ Api }) => {
    const { result, status } = await Api.get(
      `Livestreams/${workoutId}/started`
    );
    status === 200 && callback(result);
  }
};

const upcomingDispatcher = synthesize('upcoming', mapDispatchToAC);
export default upcomingDispatcher;
