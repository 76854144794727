import { StatusComponent } from '../all-screenings/component/StatusComponent';
import { formatTotalPrice } from '../checkin-patient-information/util';
import ecommerceDispatcher from './action';
import ChipConsultationStatus from './component/ChipConsultationStatus';
import ExportModal from './component/ExportModal';
import GroupToolbar from './component/GroupToolbar';
import ProductModal from './component/ProductModal/index';
import {
  COLUMN_ECOMMERCE_MANAGEMENT,
  ECOMMERCE_STATUSES_FILTER,
  ECOMMERCE_STATUS_COLORS,
  ECOMMERCE_STATUS,
  TYPE_MODAL,
} from './constants';
import {
  ActivateIcon,
  CloseCircle,
  CreateNewIcon,
  DeactivateIcon,
  DeleteModalIcon,
  EditIcon,
  EyeHideIcon,
  EyeIcon,
  NoDataEcommerce,
  ExportIcon,
  CloseIcon,
} from '@/assets/svg';
import ImageViewerEnhanced from '@/components/ConsultDetail/ImageViewerEnhanced';
import Image from '@/components/Image';
import { handleSelectAll } from '@/helpers';
import { createDownloadableLink } from '@/helpers';
import CustomMenu from '@/new-components/CustomMenu';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import {
  Button,
  Drawer,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  Box,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { debounce, get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const PreviewContent = withStyles({
  root: {
    width: '100%',
    height: 85.6,

    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(51,51,51,0.4)',
    borderRadius: 8,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
})(Box);

const CustomDialogContent = withStyles({
  root: {
    padding: 40,
    position: 'relative',
    '& .icon': {
      position: 'absolute',
      top: 10,
      right: 10,
    },

    '&:first-child': {
      paddingTop: 40,
    },
  },
})(DialogContent);

const EcommerceManagement = () => {
  const {
    list: data,
    paging,
    categories,
    loading,
  } = useSelector((state) => state.ecommerce);

  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({});
  const [activeStatuses, setActiveStatuses] = useState([]);
  const [categoryId, setCategoryId] = useState(null);

  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [previewSrc, setPreviewSrc] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState(TYPE_MODAL.Create);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [imageSelected, setImageSelected] = useState('');

  const handleMouseEnter = (url) => {
    setImageSelected(url);
    setIsHovered(true);
  };
  const handleMouseLeave = () => setIsHovered(false);

  const handlePreviewOpen = (url) => {
    setImageSelected(url);
    setPreviewOpen(true);
    handleMouseLeave();
  };
  const handlePreviewClose = () => {
    setImageSelected('');
    setPreviewOpen(false);
  };

  const openImageViewer = (src) => {
    setPreviewSrc(src);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setPreviewSrc('');
    setIsViewerOpen(false);
  };

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    filter = filterOption
  ) => {
    ecommerceDispatcher.getProductsData(search, pagingData, filter);
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize,
        },
        filterOption
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(searchKey, paging, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, filterOption]);

  useEffect(() => {
    ecommerceDispatcher.getCategoriesData();
    ecommerceDispatcher.getUnitsData();
  }, []);

  const handleShowModal = (type) => {
    setTypeModal(type);
    setShowModal(true);
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {COLUMN_ECOMMERCE_MANAGEMENT.map((item) => (
          <TableCell
            key={item.stateValue || item.label}
            style={{ minWidth: item.minWidth, width: item.width }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderListActions = (selectedItem) => {
    if (!selectedItem) return [];
    const isActive = selectedItem?.activeStatus === ECOMMERCE_STATUS.Active;
    return [
      {
        key: `mark-as-${isActive ? 'inactive' : 'active'}`,
        icon: isActive ? EyeHideIcon : EyeIcon,
        label: `Mark as ${isActive ? 'inactive' : 'active'}`,
        onClick: () => {
          setAnchorEl(null);
          setSelectedItem(selectedItem);
          setShowActiveModal(true);
        },
      },
      {
        key: 'edit-product',
        icon: EditIcon,
        label: 'Edit product',
        styles: { width: 18, height: 18 },
        onClick: () => {
          setAnchorEl(null);
          handleShowModal(TYPE_MODAL.Edit);
        },
      },
      {
        key: 'delete-product',
        icon: CloseCircle,
        label: 'Delete product',
        styles: { width: 18, height: 18 },
        color: '#DB3D49',
        onClick: () => {
          setAnchorEl(null);
          setSelectedItem(selectedItem);
          setDeleteShowModal(true);
        },
      },
    ];
  };

  const onDeleteProduct = () => {
    ecommerceDispatcher.deleteProduct(selectedItem.id, () => {
      fetchData(searchKey, paging, filterOption);
      setDeleteShowModal(false);
    });
  };

  const onActivateProduct = () => {
    const activeStatus = selectedItem.activeStatus === ECOMMERCE_STATUS.Active;

    ecommerceDispatcher.activateProduct(selectedItem.id, !activeStatus, () => {
      fetchData(searchKey, paging, filterOption);
      setShowActiveModal(false);
    });
  };

  const renderTableBody = (row) => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.name} />
        </TableCell>
        <TableCell>
          {row.coverImageUrl && (
            <Box
              width="100%"
              position="relative"
              onMouseEnter={() => handleMouseEnter(row.coverImageUrl)}
              onMouseLeave={handleMouseLeave}
            >
              <Image
                src={row.coverImageUrl}
                alt="Preview"
                style={{ borderRadius: 8, width: 160, height: 85.6 }}
              />
              {isHovered && imageSelected === row.coverImageUrl && (
                <PreviewContent
                  onClick={() => handlePreviewOpen(row.coverImageUrl)}
                >
                  <EyeIcon color="white" width={24} height={24} />
                </PreviewContent>
              )}
            </Box>
          )}
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.categoryName} />
        </TableCell>
        <TableCell>
          <CustomTooltip
            content={
              <Typography
                dangerouslySetInnerHTML={{ __html: row?.description }}
              />
            }
          />
        </TableCell>
        <TableCell>
          <ChipConsultationStatus isRequired={row.requiredConsultation} />
        </TableCell>
        <TableCell>{formatTotalPrice(row.price)}</TableCell>
        <TableCell>
          <StatusComponent
            status={row.activeStatus}
            filterList={ECOMMERCE_STATUS}
            filterListColor={ECOMMERCE_STATUS_COLORS}
          />
        </TableCell>
        <TableCell>
          <IconButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setSelectedItem(row);
            }}
          >
            <MoreVertRounded />
          </IconButton>
        </TableCell>
      </>
    );
  };

  const handleCreateProduct = (type, data) => {
    ecommerceDispatcher.setState('loading', true);
    const isCreate = type === TYPE_MODAL.Create;
    if (isCreate) {
      ecommerceDispatcher.createProduct(data, () => {
        handleAPISuccess(isCreate, data.name);
      });
    } else {
      ecommerceDispatcher.updateProduct(data, selectedItem, () => {
        handleAPISuccess(isCreate, data.name);
      });
    }
  };

  const handleAPISuccess = (isCreate, name) => {
    ecommerceDispatcher.setState('loading', false);
    setShowModal(false);
    fetchData(searchKey, paging);
    renderToast(isCreate, name);
  };

  const onFilterOptionChange = (key, setState) => (e, newValue) => {
    let value;
    if (key === 'activeStatuses') {
      value = e.target.value;
      value = handleSelectAll(
        value,
        ECOMMERCE_STATUSES_FILTER.map((item) => item.key),
        activeStatuses
      );
    } else {
      value = get(newValue, 'id');
    }

    setFilterOption({
      ...filterOption,
      [key]: value,
    });
    setState && setState(value);
  };

  const onResetFilterOps = () => {
    setActiveStatuses([]);
    setCategoryId(null);
    setFilterOption({});
  };

  const renderToast = (isCreate, name) => {
    return customToast(
      'success',
      <span>
        Product
        <strong style={{ fontWeight: 600 }}> {name}</strong> has been
        successfully {isCreate ? 'added to the list.' : 'updated.'}
      </span>,
      isCreate ? `Product created` : `Product updated`
    );
  };

  const handleExport = (formValue) => {
    ecommerceDispatcher.setState('loading', true);
    ecommerceDispatcher.exportOrderHistory(
      searchKey,
      paging,
      formValue,
      (result) => {
        createDownloadableLink(result, 'Order History Report', 'csv');
        setShowExportModal(false);
        ecommerceDispatcher.setState('loading', false);
      }
    );
  };

  return (
    <>
      <CustomMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <CustomMenuActions listActions={renderListActions(selectedItem)} />
      </CustomMenu>
      <CustomPaperContainer
        header={
          <CustomHeader
            title="E-commerce Management"
            searchPlaceholder="Search by product name..."
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                filterOption={filterOption}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
                setActiveStatuses={setActiveStatuses}
                activeStatuses={activeStatuses}
                setCategoryId={setCategoryId}
                categoryId={categoryId}
                categories={categories}
              />
            )}
            renderButton={() => (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<ExportIcon width={20} height={20} />}
                  onClick={() => {
                    setShowExportModal(true);
                  }}
                >
                  Download order history
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<CreateNewIcon />}
                  onClick={() => handleShowModal(TYPE_MODAL.Create)}
                >
                  Create new
                </Button>
              </>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          Icon={NoDataEcommerce}
          noDataHelperText="No product"
          noDataHelperSubText="Please create a new product."
          totalCols={COLUMN_ECOMMERCE_MANAGEMENT.length}
          fetchData={fetchData}
        />
      </CustomPaperContainer>

      <ImageViewerEnhanced
        visible={isViewerOpen}
        src={previewSrc}
        onClose={closeImageViewer}
      />

      <Drawer
        anchor="right"
        open={showModal}
        onClose={() => setShowModal(false)}
        disableBackdropClick
        PaperProps={{
          style: {
            width: '50%',
            minWidth: 768,
          },
        }}
      >
        <ProductModal
          loading={loading}
          onSubmit={handleCreateProduct}
          selectedItem={selectedItem}
          typeModal={typeModal}
          onClose={() => {
            setShowModal(false);
            fetchData();
          }}
        />
      </Drawer>

      {showDeleteModal && (
        <ModalConfirmation
          open={showDeleteModal}
          onClick={onDeleteProduct}
          onClose={() => setDeleteShowModal(false)}
          mainContent="Delete product"
          subContent="Are you sure you want to delete this product?"
          cancelLabel="Cancel"
          confirmLabel="Delete"
          confirmColor="red"
          Icon={<DeleteModalIcon />}
        />
      )}

      {showActiveModal && (
        <ModalConfirmation
          open={showActiveModal}
          onClick={onActivateProduct}
          onClose={() => setShowActiveModal(false)}
          mainContent={`Mark as ${
            selectedItem.activeStatus === ECOMMERCE_STATUS.Active
              ? 'inactive'
              : 'active'
          }`}
          subContent={`Are you sure you want to mark this product as ${
            selectedItem.activeStatus === ECOMMERCE_STATUS.Active
              ? 'inactive'
              : 'active'
          }?`}
          cancelLabel="Cancel"
          confirmLabel={'Confirm'}
          Icon={
            selectedItem.activeStatus === ECOMMERCE_STATUS.Active ? (
              <DeactivateIcon />
            ) : (
              <ActivateIcon />
            )
          }
        />
      )}

      {showExportModal && (
        <ExportModal
          onSubmit={handleExport}
          onClose={() => setShowExportModal(false)}
          open={showExportModal}
          loading={loading}
        />
      )}

      {previewOpen && (
        <Dialog
          disableBackdropClick
          open={previewOpen}
          onClose={handlePreviewClose}
          maxWidth="lg"
        >
          <CustomDialogContent>
            <IconButton className="icon" onClick={handlePreviewClose}>
              <CloseIcon />
            </IconButton>

            <Image src={imageSelected} alt="Preview" />
          </CustomDialogContent>
        </Dialog>
      )}
    </>
  );
};

export default EcommerceManagement;
