import Enum from './Enum';

export const ConsultType = Enum({
  Teledoc: 'Teledoc',
  Visit: 'Visit',
  Consult: 'See Doctor Online',
  Review: 'Post-Screening Review',
  TeleArt: 'Supervised ART',
  TeleMedicine: 'Chronic Disease Telemedicine Programme'
});

export const ConsultStatus = Enum({
  InProgress: 'InProgress',
  ConsultCompleted: 'ConsultCompleted',
  Cancelled: 'Cancelled',
  // Emergency: 'Emergency',
  // ProcessSummary: 'ProcessSummary',
  // Paid: 'Paid',
  // ConfirmedPaid: 'ConfirmedPaid',
  Done: 'Done',
  ReadyToPay: 'ReadyToPay',
  AwaitingAddressConfirmation: 'AwaitingAddressConfirmation',
  InDelivery: 'InDelivery'
});

export const ConsultStatusDisplay = Enum({
  InProgress: 'In Progress',
  ConsultCompleted: 'Consult Completed',
  Cancelled: 'Cancelled',
  // Emergency: 'Emergency',
  // ProcessSummary: 'Process Summary',
  // Paid: 'Paid',
  Done: 'Done',
  ReadyToPay: 'Ready To Pay',
  AwaitingAddressConfirmation: 'Waiting Confirmation',
  InDelivery: 'In Delivery'
});

export const StatusColor = Enum({
  InProgress: '#5A5EAB',
  ConsultCompleted: '#CE568F',
  Cancelled: '#8F9BA9',
  Emergency: '#EA6B75',
  ProcessSummary: '#FA8F51',
  Paid: '#2FC2D6',
  // ConfirmedPaid: '#62CAA4',
  Done: '#AD5E99',
  AwaitingAddressConfirmation: '#0288D1',
  InDelivery: '#795548'
});

export const DeliveryMethod = Enum({
  Delivery: 'Delivery',
  Pickup: 'Pickup'
});

export const DeliveryStatus = Enum({
  AwaitingPickUp: 'Awaiting PickUp',
  InTransit: 'In Transit',
  Completed: 'Completed'
});

export const PickUpStatus = Enum({
  AwaitingPickUp: 'Awaiting PickUp',
  Completed: 'Completed'
});
