import { synthesize } from 'redux-dispatcher';
import { uploadImage } from '../../../firebase';

const mapDispatchToAC = {
  getDataSuccess: data => ({
    data
  }),
  getData: (search, paging) => async ({ Api, getState }) => {
    const pagingData = paging || getState().category.paging;
    const { pageIndex, pageSize } = pagingData;

    let { result, status } = await Api.post(`Categories/filter`, {
      pageIndex,
      pageSize,
      search
    });
    if (status === 200) {
      categoryDispatcher.getDataSuccess(result);
    }
  },
  setState: (state, value) => ({ state, value }),
  createCategory: (payload, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    let data = { ...payload };
    if (payload.thumbnail?.constructor === File) {
      categoryDispatcher.setState('loading', true);
      let storageUrl = await uploadImage(payload.thumbnail);
      data.thumbnail = storageUrl;
    }

    const { status, message } = await Api.post('Categories', data);
    categoryDispatcher.setState('loading', false);
    if (status === 200) {
      callbackSuccess();
      return customToast('success', message || 'Success');
    }
  },
  editCategory: (payload, callbackSuccess) => async ({ Api, customToast }) => {
    const { data, id } = payload;
    let content = { ...data };
    if (data.thumbnail?.constructor === File) {
      categoryDispatcher.setState('loading', true);
      let storageUrl = await uploadImage(data.thumbnail);
      content.thumbnail = storageUrl;
    }

    const { result, status, message } = await Api.put(
      `Categories/${id}`,
      content
    );
    categoryDispatcher.setState('loading', false);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
      return customToast('success', message || 'Edit success');
    }
  },
  deleteCategory: (id, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.delete(`Categories/${id}`);
    if (status === 200) {
      customToast('success', 'Deleted');
      callbackSuccess && callbackSuccess();
    }
  },
  getCategoryById: (id, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.get(`Categories/${id}`);
    status === 200 && callbackSuccess(result);
  }
};

const categoryDispatcher = synthesize('category', mapDispatchToAC);
export default categoryDispatcher;
