/*
 * @Author: Phu Phan
 * @Date: 2021-01-08 13:58:53
 */
import React, { useState } from 'react';
import {
  IconButton,
  Menu,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { groupBy, isEmpty } from 'lodash';
import allUsersDispatcher from '../../action';
import { TableWrapped } from './styled';
import InlineTable from '@/module/common/component/InlineTable';
import { PT_SURVEY_COL } from '../../constants';
import TableCellFilted from '@/module/common/componentUI/TableCellFilted';
import FiltedBodyCell from '@/module/common/component/FiltedBodyCell';
import { useAbortableEffect } from '@/helpers/useHookHelpers';
import { InfoRounded, MoreVertRounded } from '@material-ui/icons';
import SurveyDetail from './SurveyDetail';
import MenuAction from '@/components/MenuAction';
import moment from 'moment';

const SurveyHistory = ({ personalInfo }) => {
  const [hiddenRows, setHiddenRows] = useState({});
  const [surveyData, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useAbortableEffect(_isMounted => {
    allUsersDispatcher.getSurveyHistory(
      { patientId: personalInfo.id },
      (data = []) => {
        if (isEmpty(data)) return;
        _isMounted.current && setData(data);
      }
    );
  }, []);

  const onViewDetail = () => {
    setShowModal(true);
    setAnchorEl(null);
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const renderRow = () => row => (
    <>
      <FiltedBodyCell hidden={hiddenRows.surveyName}>{row.name}</FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.company}>{row.company}</FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.date}>
        {moment(row.submittedDate).format('DD/MM/YYYY')}
      </FiltedBodyCell>
      <TableCell align="right">
        <IconButton
          onClick={e => {
            setAnchorEl(e.currentTarget);
            // group ques by category
            setSelectedItem({
              ...row,
              groupedQues: groupBy(row.questions, 'category')
            });
          }}
        >
          <MoreVertRounded />
        </IconButton>
      </TableCell>
    </>
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {PT_SURVEY_COL.map(item =>
          item.label ? (
            <TableCellFiltedWrapper
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
            />
          ) : (
            <TableCell key={item.stateValue}></TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );

  let LIST_ACTIONS = [
    {
      key: 'view-detail',
      icon: InfoRounded,
      label: 'View detail',
      onClick: onViewDetail
    }
  ];

  if (showModal)
    return (
      <SurveyDetail
        data={selectedItem}
        onBack={() => {
          setShowModal(false);
          setSelectedItem();
        }}
      />
    );

  return (
    <TableWrapped>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuAction listActions={LIST_ACTIONS} />
      </Menu>
      <InlineTable
        Header={TableHeader}
        data={surveyData}
        renderRow={renderRow()}
      />
    </TableWrapped>
  );
};

export default SurveyHistory;
