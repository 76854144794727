import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TextField,
  InputAdornment,
  IconButton,
  withStyles
} from '@material-ui/core';

import { TableWrapped } from '@/module/checkin-patient-information/styled';
import ServicesTable from '../ServicesTable';
import CustomAutocompleteModal from '@/new-components/CustomAutocompleteModal';

import servicesDispatcher from '@/module/services/action';
import stationDispatcher from '@/module/station-management/action';
import { CloseIcon, RemoveIcon } from '@/assets/svg';
import { mergeArrayByKey } from '@/helpers';
import {
  BILLING_TAB,
  COLUMN_REVIEWS,
  COLUMN_REVIEWS_BILLING,
  TABLE_STATE,
  renderColumns
} from '../../../constants';
import CustomTooltip from '@/new-components/CustomTooltip';
import { cloneDeep, findIndex, isEmpty } from 'lodash';
import { isBillableProject } from '@/module/setup/utils';

const CustomTextFieldPrice = withStyles({
  root: {
    fontSize: 20,

    '& .MuiInputBase-input': {
      marginLeft: 0
    },

    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 6
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 36
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const CustomTableCell = withStyles({
  root: {
    fontSize: 16
  }
})(TableCell);

const Reviews = ({
  formValue,
  setFormValue,
  errors,
  isAssigned = true,
  requirePrice = true,
  requireStation = true,
  removeColumns = []
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentState, setCurrentState] = useState(TABLE_STATE.Outside);

  const [listTests, setListTests] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const [billingTab, setBillingTab] = useState(BILLING_TAB.Default);

  const columnsTable = renderColumns(COLUMN_REVIEWS, removeColumns);
  const onAddNewService = newSelected => {
    const selected = selectedItem.concat(newSelected);
    let newList = [];
    if (!isAssigned) {
      const transformSelected = mergeArrayByKey(newSelected, listTests, 'id');
      newList = cloneDeep(formValue?.lstReviews).concat(transformSelected);
    } else {
      newList = mergeArrayByKey(selected, listTests, 'id');
      newList = mergeArrayByKey(newList, formValue.lstReviews, 'id');
    }

    setFormValue({ ...formValue, lstReviews: newList });
    setCurrentState(TABLE_STATE.Outside);
    setShowModal(false);
    setSelectedItem(selected);
  };

  const onDeleteService = (row, index) => {
    let lstReviews = cloneDeep(formValue.lstReviews).filter(
      it => it.id !== row.id
    );
    let newSelectedItem = cloneDeep(selectedItem).map(item =>
      item.id === row.id ? { ...item, isChecked: false } : item
    );
    const isNotExist = listTests.every(it => it.id !== row.id);

    if (!isAssigned && isNotExist) {
      // Check if deletdItem not exist in list test => add into list test
      // Because list test is unassigned list, deletdItem is assigned
      let newListTest = [row].concat(listTests);
      setListTests(newListTest);
    }
    newSelectedItem.splice(index, 1);
    setSelectedItem(newSelectedItem);
    setFormValue({ ...formValue, lstReviews });
  };

  const handleChangePrice = (rowId, rowIndex, value) => {
    const isOutside = currentState === TABLE_STATE.Outside;
    // Regex test number contain decimal. Maximum length number of decimal is 2
    const regexNumber = /^([\d]{0,9})(\.[\d]{1,2})?$/g;

    if (isOutside) {
      let newListTests = cloneDeep(formValue.lstReviews);

      if (regexNumber.test(value)) {
        const price = parseFloat(value);
        newListTests[rowIndex].price = Number.isNaN(price) ? value : price;
      }

      setFormValue({
        ...formValue,
        lstReviews: newListTests
      });
    } else {
      let newListTests = cloneDeep(listTests);
      const index = findIndex(newListTests, it => it.id === rowId);
      if (regexNumber.test(value)) {
        const price = parseFloat(value);
        newListTests[index].price = Number.isNaN(price) ? value : price;
        newListTests[index].billableAmount = isBillableProject(
          formValue.billingType
        )
          ? newListTests[index].price
          : 0;
      }

      setListTests(newListTests);
    }
  };

  const handleClearPrice = (rowId, rowIndex) => {
    const isOutside = currentState === TABLE_STATE.Outside;

    if (isOutside) {
      let newListTests = cloneDeep(formValue.lstReviews);
      newListTests[rowIndex].price = '';
      setFormValue({
        ...formValue,
        lstReviews: newListTests
      });
    } else {
      let newListTests = cloneDeep(listTests);
      const index = findIndex(newListTests, it => it.id === rowId);
      newListTests[index].price = '';
      newListTests[index].billableAmount = '';
      setListTests(newListTests);
    }
  };

  const handleChangeBillableAmount = (rowId, rowIndex, value) => {
    // Regex test number contain decimal. Maximum length number of decimal is 2
    const regexNumber = /^([\d]{0,9})(\.[\d]{1,2})?$/g;
    const newListTests = cloneDeep(formValue.lstReviews);
    if (regexNumber.test(value)) {
      const billableAmount = parseFloat(value);
      newListTests[rowIndex].billableAmount = Number.isNaN(billableAmount)
        ? value
        : billableAmount;
    }

    setFormValue({
      ...formValue,
      lstReviews: newListTests
    });
  };

  const onUnSelectAll = () => {
    setSelectedItem([]);
    setFormValue({ ...formValue, lstReviews: [] });
  };

  useEffect(() => {
    if (isAssigned) {
      servicesDispatcher.getAllServicesWithNoneStation('Reviews', result => {
        setListTests(
          result.map(i => ({
            ...i,
            price: i.price || 0,
            billableAmount: isBillableProject(formValue.billingType)
              ? i.price || 0
              : 0
          }))
        );
      });
    } else {
      servicesDispatcher.getAllServices('Reviews', isAssigned, result => {
        setListTests(
          result.map(i => ({
            ...i,
            price: i.price || 0
          }))
        );

        if (!isAssigned && formValue.id) {
          stationDispatcher.getStationById(formValue.id, result => {
            const currentStationAssignedListServices =
              result.tests.find(test => test.category === 'Reviews')?.items ||
              [];

            setListTests(listTests => [
              ...currentStationAssignedListServices,
              ...listTests
            ]);
          });
        }
      });
    }
  }, []);
  useEffect(() => {
    if (!isEmpty(formValue?.lstReviews)) {
      const newSelected = [...formValue?.lstReviews].map(i => i.id);
      setSelectedItem(newSelected);
    }
  }, [formValue]);

  const renderRow = (row, rowIndex) => {
    return (
      <>
        <CustomTableCell>
          <CustomTooltip content={row.name} />
        </CustomTableCell>
        {requireStation && (
          <CustomTableCell>
            <CustomTooltip content={row.stationName} />
          </CustomTableCell>
        )}
        {requirePrice && (
          <CustomTableCell>
            <CustomTextFieldPrice
              type="number"
              variant="outlined"
              value={row.price}
              onChange={e =>
                handleChangePrice(row.id, rowIndex, e.target.value)
              }
              error={!!errors?.lstReviews?.[row.id]?.price}
              helperText={errors?.lstReviews?.[row.id]?.price?.trim()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        visibility:
                          row.price && !isEmpty(String(row.price))
                            ? 'visible'
                            : 'hidden'
                      }}
                      onClick={() => handleClearPrice(row.id, rowIndex)}
                    >
                      <CloseIcon
                        height={20}
                        width={20}
                        style={{
                          color: '#666666'
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </CustomTableCell>
        )}

        <TableCell align="right">
          {currentState === TABLE_STATE.Outside && (
            <IconButton
              className="delete-icon"
              onClick={() => onDeleteService(row, rowIndex)}
            >
              <RemoveIcon />
            </IconButton>
          )}
        </TableCell>
      </>
    );
  };

  const renderBillingRow = (row, rowIndex) => {
    return (
      <>
        <CustomTableCell>
          <CustomTooltip content={row.name} />
        </CustomTableCell>
        {requireStation && (
          <CustomTableCell>
            <CustomTooltip content={row.stationName} />
          </CustomTableCell>
        )}
        {requirePrice && (
          <CustomTableCell>
            <CustomTextFieldPrice
              type="number"
              variant="outlined"
              value={row.billableAmount}
              onChange={e =>
                handleChangeBillableAmount(row.id, rowIndex, e.target.value)
              }
              error={!!errors?.lstReviews?.[row.id]?.billableAmount}
              helperText={errors?.lstReviews?.[row.id]?.billableAmount?.trim()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        visibility:
                          row.billableAmount &&
                          !isEmpty(String(row.billableAmount))
                            ? 'visible'
                            : 'hidden'
                      }}
                      onClick={() =>
                        handleChangeBillableAmount(row.id, rowIndex, '')
                      }
                    >
                      <CloseIcon
                        height={20}
                        width={20}
                        style={{
                          color: '#666666'
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </CustomTableCell>
        )}
      </>
    );
  };

  return (
    <TableWrapped>
      <ServicesTable
        title="Available reviews"
        labelButton="Add review"
        columns={columnsTable}
        renderRow={renderRow}
        data={formValue.lstReviews}
        onClick={() => {
          setShowModal(!showModal);
          setCurrentState(TABLE_STATE.Modal);
        }}
        billingColumns={COLUMN_REVIEWS_BILLING}
        renderBillingRow={renderBillingRow}
        billingTab={billingTab}
        setBillingTab={setBillingTab}
        showBillingTab={isBillableProject(formValue.billingType)}
        hasBillingErrors={!!Object.keys(errors?.lstReviews || {}).length}
      />
      <CustomAutocompleteModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setCurrentState(TABLE_STATE.Outside);
        }}
        onSubmit={onAddNewService}
        data={listTests}
        submitLabel="Add"
        title="review"
        columns={columnsTable}
        searchPlaceholder="Search by review name..."
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        onUnSelectAll={onUnSelectAll}
        renderRow={renderRow}
      />
    </TableWrapped>
  );
};

export default Reviews;
