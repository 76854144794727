import { synthesize } from 'redux-dispatcher';
import { cloneDeep } from 'lodash';
import { uploadImage } from '../../../firebase';
const mapDispatchToAC = {
  getUserInfo: () => async ({ Api }) => {
    const { result, status } = await Api.get('Users/userInfo');
    if (status === 200) {
      profileDispatcher.getUserInfoSuccess(result);
    }
  },
  getUserInfoSuccess: payload => payload,
  updateDoctorInfo: payload => async ({ Api, customToast }) => {
    const body = cloneDeep(payload);
    if (body.imageUrl?.constructor === File) {
      const storageUrl = await uploadImage(body.imageUrl);
      body.imageUrl = storageUrl;
    }
    if (body.praticisingCertificate?.constructor === File) {
      const storageUrl = await uploadImage(body.praticisingCertificate);
      body.praticisingCertificate = storageUrl;
    }

    const { status } = await Api.put('Doctors/info', body);
    if (status === 200) {
      profileDispatcher.updateInfoSuccess(payload);
      return customToast('success', 'Your profile is successfully updated');
    }
  },
  updateInfoSuccess: payload => payload,

  updateUserInfo: payload => async ({ Api, customToast }) => {
    const body = cloneDeep(payload);
    if (body.imageUrl?.constructor === File) {
      const storageUrl = await uploadImage(body.imageUrl);
      body.imageUrl = storageUrl;
    }
    const { status } = await Api.put(`Users/${body.id}`, body);
    if (status === 200) {
      profileDispatcher.updateInfoSuccess(payload);
      return customToast('success', 'Your profile is successfully updated');
    }
  }
};

const profileDispatcher = synthesize('profile', mapDispatchToAC);
export default profileDispatcher;
