import CustomModalTitle from '../CustomModal/CustomModalTitle';
import { PreSendImage, PreSendDoc } from './PreSendFiles';
import { Image2Icon, AttachIcon, SendMessage } from '@/assets/svg';
import {
  sendMessage,
  getMessageHistory,
  formatDataMessage,
  receiveMessage,
  uploadToFirebase,
  createChannel,
} from '@/firebase';
import { ENTER_KEY } from '@/module/common';
import customToast from '@/new-components/CustomNotification';
import { Box, Fab, TextField, makeStyles, withStyles } from '@material-ui/core';
import { includes, isEmpty } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { MessageList } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const useStyles = makeStyles({
  btn: {
    minWidth: 48,
    height: 48,
    backgroundColor: 'white',
    border: '1px solid #ad5e99',
    borderRadius: 8,
    boxShadow: 'none',
  },
  btnSelected: {
    backgroundColor: '#ad5e99',
  },
});

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
    },
    '& input': {
      padding: 8,
      paddingLeft: 16,
      marginLeft: 0,
    },
    '& .Mui-focused > svg': {
      color: '#ad5e99',
    },
  },
})(TextField);

const StreamChatPanel = ({
  isOpen,
  channelInfo,
  onClose,
  setHasNewMessage,
  partner,
  setChatChannel,
  updateListChannels,
}) => {
  const classes = useStyles();

  const [messages, setMessages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [chatMessage, setChatMessage] = useState('');

  const messagesRef = useRef();
  messagesRef.current = messages;
  const callId = useSelector((state) => state.teleQueueRoom.callId);
  const startCallTime = useSelector(
    (state) => state.teleQueueRoom.startCallTime
  );
  const [isListenNewMess, setIsListen] = useState(false);

  const onReceiveNewMess = (data) => {
    if (!isOpen && includes(channelInfo.members, data.senderId))
      setHasNewMessage(true);
    let newMess = formatDataMessage(data);
    setMessages([newMess, ...messagesRef.current]);
    scrollToBottom();
  };

  const getMessage = async () => {
    if (!isEmpty(channelInfo)) {
      receiveMessage(channelInfo.id, onReceiveNewMess);
      let rs = await getMessageHistory(
        channelInfo.id,
        formatDataMessage,
        callId,
        startCallTime
      );
      setMessages([...rs]);
      scrollToBottom();
    }
  };

  const getReverseMessageList = () => {
    const newMessages = [...messages];
    return newMessages.reverse();
  };

  const scrollToBottom = () => {
    const child = document.getElementById('container');
    const chatContainer = child ? child.parentElement : null;
    if (!chatContainer) return;
    chatContainer.scrollTop = chatContainer.scrollHeight;
  };

  useEffect(() => {
    if (channelInfo.id && !isListenNewMess) {
      getMessage();
      setIsListen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelInfo]);

  const onClickAddDocumentIcon = () => {
    document.getElementById('add_file').value = null;
    document.getElementById('add_file').click();
  };

  const onUploadDocument = () => {
    const doc = document.getElementById('add_file').files[0];
    if (!doc) return;
    setUploadedFiles([
      ...uploadedFiles,
      {
        file: doc,
        type: 'file',
      },
    ]);
  };

  const onClickAddImageIcon = () => {
    document.getElementById('add_image').value = null;
    document.getElementById('add_image').click();
  };

  const onUploadImage = () => {
    const image = document.getElementById('add_image').files[0];
    if (!image) return;
    if (!['jpeg', 'jpg', 'png'].includes(image.type.split('/')[1]))
      return customToast('error', 'Invalid support file');

    let fileSource;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      fileSource = fileReader.result;
      setUploadedFiles([
        ...uploadedFiles,
        {
          file: image,
          type: 'photo',
          fileSource,
        },
      ]);
    };
    fileReader.readAsDataURL(image);
  };

  const removeAttatchMent = (index) => {
    const newArray = [...uploadedFiles];
    newArray.splice(index, 1);
    setUploadedFiles(newArray);
  };

  const handleSendMessage = () => {
    if (chatMessage.trim() || uploadedFiles.length !== 0) {
      onSend(chatMessage);
    }
    setHasNewMessage(false);
    setChatMessage('');
    setUploadedFiles([]);
  };

  const onSend = async (message) => {
    uploadedFiles.forEach((file) => {
      uploadToFirebase({
        id: channelInfo.id,
        uploadedFile: file,
        callBack: async (downloadUrl) => {
          let info = channelInfo;
          if (isEmpty(info))
            info = await createChannel(
              partner.id,
              partner.name,
              partner.imageUrl
            );
          updateListChannels();
          setChatChannel(info);
          sendMessage(
            info.id,
            file.type === 'photo' ? null : file.file.name,
            info.members,
            {
              fileName: file.file.name,
              type: file.type,
              fileUrl: file.type === 'file' ? downloadUrl : '',
              imageUrl: file.type === 'photo' ? downloadUrl : '',
              mime: file.file.type,
            },
            callId
          );
        },
      });
    });
    if (message.trim() !== '') {
      let info = channelInfo;
      if (isEmpty(info))
        info = await createChannel(partner.id, partner.name, partner.imageUrl);
      updateListChannels();
      setChatChannel(info);
      sendMessage(info.id, message, info.members, undefined, callId);
    }
    scrollToBottom();
  };

  return (
    <ChatContainer id="container">
      <CustomModalTitle
        onClose={onClose}
        style={{ padding: '20px 20px 16px' }}
        styleButton={{ top: 20 }}
      >
        In-call messages
      </CustomModalTitle>
      <MessageList
        className="message-list custom-message-list"
        toBottomHeight={'100%'}
        downButtonBadge={true}
        dataSource={getReverseMessageList()}
        onClick={(item) => {
          if (item.data.uri) window.open(item.data.uri);
        }}
      />
      <Box
        position="sticky"
        bottom={0}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        borderTop={'1px #f1f1f1 solid'}
      >
        <Box
          display="flex"
          flex={1}
          alignItems={'center'}
          backgroundColor="#ffffff"
          overflow="auto"
          px={2.5}
          py={1.5}
          // height={uploadedFiles.length > 0 ? 150 : 65}
          hidden={uploadedFiles.length === 0}
        >
          {uploadedFiles.map((file, index) => {
            switch (file.type) {
              case 'photo':
                return (
                  <PreSendImage
                    key={index}
                    image={file.file}
                    fileSource={file.fileSource}
                    removeAttatchment={() => removeAttatchMent(index)}
                  />
                );
              case 'file':
                return (
                  <PreSendDoc
                    key={index}
                    doc={file.file}
                    removeAttatchment={() => removeAttatchMent(index)}
                  />
                );
              default:
                return null;
            }
          })}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems={'center'}
          backgroundColor="#ffffff"
          borderTop={'1px #f1f1f1 solid'}
          p="17.5px 19.5px"
          gridGap={8}
        >
          <Fab
            className={classes.btn}
            aria-label="enable-video"
            onClick={onClickAddImageIcon}
          >
            <Image2Icon color="#ad5e99" />
          </Fab>
          <input
            id="add_image"
            type="file"
            accept=".jpeg,.jpg,.png"
            hidden
            onChange={onUploadImage}
          />

          <Fab
            className={classes.btn}
            aria-label="enable-video"
            onClick={onClickAddDocumentIcon}
          >
            <AttachIcon color="#ad5e99" />
          </Fab>

          <input
            id="add_file"
            type="file"
            accept=".pdf,.doc,.docx"
            hidden
            onChange={onUploadDocument}
          />

          <CustomTextField
            placeholder="Type something..."
            multiline={false}
            autoFocus={true}
            disableUnderline={true}
            variant="outlined"
            value={chatMessage}
            fullWidth
            onChange={(e) => setChatMessage(e.target.value)}
            onKeyPress={(e) => {
              // Press Shift with Enter
              if (e.shiftKey && e.charCode === ENTER_KEY) {
                return true;
              }
              if (e.charCode === ENTER_KEY) {
                handleSendMessage();
              }
            }}
            InputProps={{
              endAdornment: (
                <SendMessage
                  width={24}
                  height={24}
                  color="#666666"
                  style={{ cursor: 'pointer' }}
                  onClick={handleSendMessage}
                />
              ),
            }}
          />
        </Box>
      </Box>
    </ChatContainer>
  );
};

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;

  .message-list {
    flex: 1;
    overflow: auto;
  }

  .input-message {
    border-radius: 20px;
    padding: 0px 16px !important;
    background-color: #f6f7fa;
  }

  .rce-mlist > div:first-of-type {
    padding-top: 10px;
  }

  .rce-container-mbox {
    background-color: #ffffff;
  }

  .rce-container-input {
    min-width: 0%;
  }

  .rce-input {
    background-color: #f6f7fa;
    border-radius: 20px;
    height: 40px;
    width: 100%;
  }

  .rce-mbox-left-notch {
    display: none;
  }

  .rce-mbox-right-notch {
    display: none;
  }

  .rce-mbox-time {
    text-align: right;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    position: initial;
    display: none;
  }

  .rce-mbox-text {
    font-size: 14px;
    word-break: break-word;
    &:after {
      display: none;
    }
  }

  .rce-mbox-body {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  .rce-mbox {
    max-width: 60%;
    min-width: 0px;
    border-radius: 8px 8px 8px 2px !important;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    padding: 0px !important;
    box-shadow: none;
    background-color: #f1f1f1;
  }

  .rce-mbox-right {
    background-color: #ffe9fb;
    color: #333333;

    &.rce-mbox {
      border-radius: 8px 2px 8px 8px !important;
    }

    .rce-mbox-file > button {
      background-color: #e2f2ff !important;
      border-radius: 8px;
      max-width: -webkit-fill-available;
      max-width: -moz-available;

      svg {
        color: white !important;
      }
    }

    .rce-mbox-file--text {
      padding: 0;
      color: #333333;
    }
    .rce-mbox-time {
      display: none;
      color: #333333;
    }
  }

  .rce-mbox-photo {
    border-radius: 20px;

    &:hover {
      background-color: black !important;
    }
  }

  .rce-mbox-photo--img {
    object-fit: cover;
    height: 150px;
    width: 150px;
    border-radius: 20px !important;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .rce-mbox--clear-padding {
    // background-color: #f7f7f7 !important;
    box-shadow: none !important;
    padding: 6px 9px 8px 9px !important;
  }

  .rce-mbox-photo--img__block {
    display: none;
  }

  .rce-mbox-file {
    padding-bottom: 0px !important;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rce-mbox-file > button {
    background-color: #efdfeb !important;
    border-radius: 18px;
    min-height: 40px;
    width: 100%;

    svg {
      color: #ad5e99 !important;
    }
  }

  .rce-mbox-file--text {
    font-size: 14px;
    color: #ad5e99;
  }

  .rce-mbox .rce-mbox-right .rce-mbox-file {
    background-color: #f7f7f7 !important;
  }
`;

export default StreamChatPanel;
