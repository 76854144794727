import React, { useState, useEffect } from 'react';
import ModalPage, { ModalFooter } from './styled';
import {
  DialogContent,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  Typography
} from '@material-ui/core';
import { ClearRounded, Visibility, VisibilityOff } from '@material-ui/icons';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import LoadingButton from '../../../components/LoadingButton';
import validateData from '../../../helpers/validationHelpers/validationSchema';
import { TYPE_MODAL } from '../../common/constants/typeModal';
import { Autocomplete } from '@material-ui/lab';
import { ROLE_FIELD, arrayField } from '../constants';
import { mergeArrayByKey, formatListImage } from '../../../helpers';
import { get, isEqual, sortBy, isEmpty } from 'lodash';
import customToast from '@/new-components/CustomNotification';

const FILE_SIZE = 2 * 1024 * 1024; // ~2mb
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const AdminModal = ({
  onClose,
  selectedItem,
  typeModal,
  onSubmit,
  loading,
  listAdminRole,
  listClinic,
  isClinicManager
}) => {
  const [formValue, setFormValue] = useState({
    password: '',
    confirmPassword: '',
    countryCode: 'SG',
    lstCertificates: [],
    lstClinicId: []
  });
  const [certShow, setCertShow] = useState([]);
  const [errors, setErrors] = useState({});
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  //list field to render when choose role
  const [renderField, setRenderField] = useState([]);
  const schema =
    typeModal === TYPE_MODAL.Edit ? 'adminEditSchema' : 'adminSchema';

  //re render list field when role change
  useEffect(() => {
    if (formValue.roleType) {
      const mergeArr =
        mergeArrayByKey(
          ROLE_FIELD[get(formValue, 'roleType.roleType', formValue.roleType)],
          arrayField({
            onChange: handleChangeForm,
            formValue,
            errors,
            listClinic,
            certShow,
            onDeleteCert,
            isSelectAllClinic,
            isClinicManager
          }),
          'key'
        ) || [];
      setRenderField(mergeArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue.roleType, certShow, errors, formValue]);
  //onChange format to show carousel list certificates
  useEffect(() => {
    if (
      !isEmpty(formValue.lstCertificates) &&
      !!formValue.lstCertificates.length
    ) {
      formatListImage(formValue.lstCertificates, setCertShow);
    }
  }, [formValue.lstCertificates]);

  useEffect(() => {
    if (typeModal === TYPE_MODAL.Edit) {
      let lstClinicId;
      if (!isEmpty(selectedItem.lstClinic)) {
        lstClinicId = selectedItem.lstClinic.map(clinic => clinic.id);
      }
      const roleType = listAdminRole.find(
        role => role.roleType === selectedItem.roleType
      );
      setFormValue({
        ...formValue,
        ...selectedItem,
        lstClinicId,
        roleName: roleType.label
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeModal, selectedItem]);

  const handleClickShowPassword = type => {
    if (type === 'password') {
      setIsShowPassword(prevState => !prevState);
    } else if (type === 'confirmPassword') {
      setShowConfirmPassword(prevState => !prevState);
    }
  };

  const onDeleteCert = (item, index) => {
    let lstCertificates = [...formValue.lstCertificates];
    lstCertificates.splice(index, 1);
    setFormValue({ ...formValue, lstCertificates });
  };

  const validateOnCreate = errs => {
    const hasNoPassword =
      typeModal === TYPE_MODAL.Create && !formValue.password;
    if (errs) {
      hasNoPassword
        ? setErrors({
            ...errs,
            password: 'Password is required'
          })
        : setErrors(errs);
    } else
      hasNoPassword
        ? setErrors({ password: 'Password is required' })
        : onSubmit(formValue);
  };

  const handleSubmit = async () => {
    try {
      await validateData(
        schema,
        {
          ...formValue,
          typeModal,
          roleType: get(formValue, 'roleType.roleType', formValue.roleType)
        },
        () => {
          validateOnCreate();
        }
      );
    } catch (errs) {
      validateOnCreate(errs);
    }
  };

  const formatListClinic = () => {
    return listClinic.map(clinic => clinic.id);
  };

  const isSelectAllClinic = () => {
    const clinics = formatListClinic();
    return (
      clinics.length === get(formValue, 'lstClinicId.length') &&
      isEqual(sortBy(formValue.lstClinicId), sortBy(clinics))
    );
  };
  const handleChangeForm = key => (e, newValue) => {
    let value;
    switch (key) {
      case 'imageUrl':
      case 'praticisingCertificate':
        value = e;
        break;
      case 'roleType':
        value = newValue;
        break;
      case 'lstClinicId':
        if (get(e, 'target.value', []).includes('selectAll')) {
          value = isSelectAllClinic() ? [] : formatListClinic();
        } else value = get(e, 'target.value');
        break;
      case 'lstCertificates':
        value = isEmpty(formValue.lstCertificates)
          ? []
          : [...formValue.lstCertificates];
        const file = e.file.originFileObj;
        if (!SUPPORTED_FORMATS.includes(get(file, 'type'))) {
          return customToast('error', 'Image is invalid');
        }
        if (get(file, 'size') > FILE_SIZE) {
          return customToast('error', 'Image is too large');
        }
        value.push(file);
        break;
      default:
        value = get(e, 'target.value');
        break;
    }

    setFormValue({
      ...formValue,
      [key]: value,
      countryCode: isEmpty(formValue.countryCode)
        ? 'SG'
        : key === 'countryCode'
        ? value
        : formValue.countryCode
    });
  };

  return (
    <ModalPage>
      <DialogContent>
        <div className="test-header">
          {typeModal === TYPE_MODAL.Create && 'Create New Role'}
          {typeModal === TYPE_MODAL.Edit &&
            (isEmpty(selectedItem.firstName)
              ? selectedItem.fullName
              : selectedItem.firstName + ' ' + selectedItem.lastName)}
          <ClearRounded
            style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
            onClick={onClose}
          />
        </div>
        <div className="test-body">
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12}>
              <TextField
                error={errors.firstName}
                helperText={errors.firstName}
                label="First name"
                value={formValue.firstName}
                onChange={handleChangeForm('firstName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.lastName}
                helperText={errors.lastName}
                label="Last name"
                value={formValue.lastName}
                onChange={handleChangeForm('lastName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.email}
                helperText={errors.email}
                label="Email"
                value={formValue.email}
                onChange={handleChangeForm('email')}
              />
            </Grid>
            {typeModal === TYPE_MODAL.Create && (
              <>
                <Grid item xs={12}>
                  <TextField
                    type={isShowPassword ? 'text' : 'password'}
                    error={errors.password}
                    helperText={errors.password}
                    label="Password"
                    value={formValue.password}
                    onChange={handleChangeForm('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('password')}
                          >
                            {isShowPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={showConfirmPassword ? 'text' : 'password'}
                    error={errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    label="Confirm Password"
                    value={formValue.confirmPassword}
                    onChange={handleChangeForm('confirmPassword')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword('confirmPassword')
                            }
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Autocomplete
                getOptionSelected={(option, value) =>
                  option.roleType === value.roleType
                }
                getOptionLabel={option => option.label}
                options={listAdminRole}
                value={
                  get(formValue, 'roleType.roleType')
                    ? formValue.roleType
                    : {
                        roleType: formValue.roleType,
                        label: formValue.roleName
                      }
                }
                autoComplete
                onChange={handleChangeForm('roleType')}
                renderInput={params => <TextField {...params} label="Role" />}
              />
              {errors.roleType && (
                <Typography color="error">{errors.roleType}</Typography>
              )}
            </Grid>
            {renderField.map(field => (
              <Grid item xs={12} key={field.key}>
                {field.render}
              </Grid>
            ))}
          </Grid>
        </div>
        <ModalFooter>
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={onClose}
          >
            Cancel
          </ButtonEnhance>
          <LoadingButton onClick={handleSubmit} showLoader={loading}>
            {typeModal === TYPE_MODAL.Create ? 'Create' : 'Update'}
          </LoadingButton>
        </ModalFooter>
      </DialogContent>
    </ModalPage>
  );
};

export default AdminModal;
