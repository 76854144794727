import { Accordion, TextField, withStyles } from '@material-ui/core';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

export const InputLabel = styled.div`
  margin: 10px 0 5px 0;
  color: #666;
  font-weight: 600;
  margin-top: ${p => p.mt};
`;
export const CustomSearchInput = withStyles(theme => ({
  root: {
    '&.value': {
      marginBottom: 8,
      marginTop: 8
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 8
    },
    '& .Mui-disabled': {
      // background: '#f6f6f6'
    },
    '& input': {
      minWidth: 335,
      height: 48,
      padding: 0
    }
  }
}))(TextField);

export const StyledTextArea = styled(TextArea)`
  &.ant-input {
    border-radius: 8px;
    min-height: 96px;
    margin-top: 10px;
  }
`;

export const FormContainer = styled.div`
  min-height: 60vh;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    cursor: pointer;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
  margin-bottom: 8px;

  .icon-drag {
    height: 52px;
  }

  svg {
    cursor: pointer;
  }
`;

export const AddContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #e1e1e1;
  .primary {
    margin: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    font-size: 16px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 40px;
    align-items: center;
  }
`;

export const StyledAccordion = styled(Accordion)`
  border-radius: 12px !important;
  min-height: 52px;
  box-shadow: none !important;
  border: 1px solid #e1e1e1;
  flex: 1;

  .MuiIconButton-root {
    padding: 0;
  }
  .MuiAccordionSummary-root {
    padding: 10px 16px;
  }
  &::before {
    display: none;
  }
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }
  &.expanded {
    background-color: white !important;
    &.selected {
      border: 1px solid #ad5e99;
    }
    .MuiAccordionSummary-root {
      border-bottom: 1px solid #e1e1e1;
    }
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 8px 0;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
    border-bottom: 1px solid #e1e1e1;
  }

  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
    margin-bottom: 10px;
  }
  &.MuiAccordion-root.Mui-expanded:last-child {
    margin-bottom: 10px;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiRadio-root {
    padding: 0 6px 0 0;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #ad5e99;
  }
  .MuiPaper-root.MuiAccordion-root.sc-enfXDO.eJXesr.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    border: 1px solid #e1e1e1;
    padding: 10px 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    padding: 0;
    margin: 0;
  }

  .MuiAccordionDetails-root {
    flex-direction: column;
    padding: 0px;
  }
  .MuiIconButton-edgeEnd {
    margin-right: unset;
    margin-left: 8px;
  }
`;

export const CardTitle = styled.div`
  flex: 1;
  color: #333;
  font-weight: 600;

  span[class='max-line'] {
    word-break: break-word;
    white-space: pre-wrap;
    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
export const CardDesc = styled.div`
  color: #666;
  margin-top: 4px;
`;
export const Number = styled.div`
  background-color: #f6f6f6;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 16px;
  font-weight: 600;
`;
export const Type = styled.div`
  width: 100px;
  color: #333333;
`;

export const CheckboxContainer = styled.div`
  width: 100%;
  textarea {
    margin-left: 20px;
    width: calc(100% - 20px);
  }
  .MuiCheckbox-root {
    padding: 0;
    margin: 6px 0;
  }
`;

export const TextAreaType = styled.div`
  padding-bottom: 16px;
  /* border-bottom: 1px solid #e1e1e1; */
  width: 100%;
  padding: 0 16px 16px;
  color: #999;
  font-size: 16px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid #e1e1e1;
  padding: 12px 11px 11px;
  .secondary {
    margin: 5px;
    border-radius: 8px;
    font-size: 16px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    align-items: center;
    background: white;
    border: 1px solid #ad5e99;
    color: #ad5e99;
    min-width: unset;
    svg {
      margin-right: 6px;
    }
  }
  .warning {
    margin: 5px;
    border-radius: 8px;
    font-size: 16px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 107px;
    height: 40px;
    align-items: center;
    background: white;
    border: 1px solid #db3d49;
    color: #db3d49;
    min-width: unset;
    svg {
      margin-right: 6px;
    }
  }
`;
