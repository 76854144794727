export const SITE_CLOSING_REPORT_TYPE = {
  OUTSTANDING_PAYMENT: 'OutstandingPayment',
  INCOMPLETESTATION: 'IncompleteStation',
  NO_SHOW: 'NoShow'
};

export const SITE_CLOSING_REPORT = [
  {
    key: 'outstanding-payment',
    notificationType: SITE_CLOSING_REPORT_TYPE.OUTSTANDING_PAYMENT,
    label: 'Outstanding Payment Report'
  },
  {
    key: 'incomplete-station',
    notificationType: SITE_CLOSING_REPORT_TYPE.INCOMPLETESTATION,
    label: 'Incomplete Station'
  },
  {
    key: 'no-show',
    notificationType: SITE_CLOSING_REPORT_TYPE.NO_SHOW,
    label: 'No Show'
  }
];
