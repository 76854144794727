import React from 'react';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Box, makeStyles } from '@material-ui/core';
import { RadioActiveIcon, RadioInactiveIcon } from '@/assets/svg';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  active: {
    border: '1px solid #ad5e99'
  },
  inactive: {
    backgroundColor: '#f6f6f6'
  },
  btnContainer: {
    padding: '14px 16px',
    width: '100%',
    height: 52,
    borderRadius: 12,
    boxSizing: 'border-box',

    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-start',

    '&:hover': {
      border: '1px solid #ad5e99'
    }
  },
  txt: {
    color: '#333333',
    fontSize: 16,
    lineHeight: '21px',
    textAlign: 'center',
    textTransform: 'initial',

    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    width: '100%'
  },
  radio: {
    width: 24,
    height: 24,
    marginRight: 8,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    boxSizing: 'border-box',
    borderRadius: '50%'
  }
});

const CustomRadioButton = ({ onClick, keyValue, item, children, checked }) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Button
        className={classnames(
          classes.btnContainer,
          checked ? classes.active : classes.inactive
        )}
        onClick={() => onClick(item[keyValue])}
      >
        {checked ? (
          <RadioActiveIcon style={{ marginRight: 8 }} />
        ) : (
          <RadioInactiveIcon style={{ marginRight: 8 }} />
        )}

        <span className={classes.txt}>{children}</span>
      </Button>
    </div>
  );
};

export default CustomRadioButton;
