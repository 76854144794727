/* global grecaptcha */
import { configure } from '.';
import firebaseConfig from './firebaseConfig.json';
import { loadFromStorage } from './helpers/StorageHelper';
import { authDispatcher } from './module/auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-functions';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/storage';

let firestore;
let storage;
let auth;
// const CollectionPrexfix = process.env.REACT_APP_CHANNEL_PREFIX || '';
// console.log('CollectionPrexfix', CollectionPrexfix);
export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  firestore = firebase.firestore;
  storage = firebase.storage;
  auth = firebase.auth;
  firebase.auth().useDeviceLanguage();

  if (firebase.messaging.isSupported()) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        firebase.messaging().useServiceWorker(registration);
        firebase
          .messaging()
          .getToken()
          .then((currentToken) => {
            if (currentToken) {
              authDispatcher.addNotifyToken({
                token: currentToken,
                deviceId: '',
              });
              authDispatcher.setState('notifyToken', currentToken);
            }
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
      });
  }
};

let currentUserId = '';
let userInfo;

export function setCurrentUserId(userId, data) {
  currentUserId = userId.toString();
  userInfo = data;
}

export function onReceiveMessage(callBack) {
  firebase.messaging().onMessage((payload) => {
    callBack(payload);
  });
}

export function sendMessage(channelId, message, members, file, callId) {
  let serverTimeOffset = loadFromStorage('serverTimeOffset');
  let createAt = Date.now() + serverTimeOffset;
  console.log('serverTimeOffset', createAt);
  let messageData = {
    senderId: currentUserId,
    text: message,
    callId,
    createAt: createAt,
    name: userInfo.fullName,
    ...file,
  };
  members.forEach((item) => {
    updateChannel(item, channelId, message, createAt);
  });
  updateChannel(currentUserId, channelId, message, createAt);

  firestore()
    .collection(`channels/${channelId}/messages`)
    .add(messageData)
    .then(function (docRef) {
      console.log('Document written with ID: ', docRef.id);
    });
}

let messageCursor = '';

export function getMessageHistory(channelId, formatDataMessage, callId, time) {
  let listMessage = [];
  const timeFilter = time ? new Date(time).getTime() : new Date().getTime();
  return new Promise((resolve, reject) => {
    firestore()
      .collection(`channels/${channelId}/messages`)
      .where('callId', '==', callId || '')
      .where('createAt', '>=', timeFilter)
      .orderBy('createAt', 'desc')
      .limit(30)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let message = formatDataMessage({ id: doc.id, ...doc.data() });
          listMessage.push(message);
        });
        if (listMessage.length > 0) {
          messageCursor = querySnapshot.docs[querySnapshot.docs.length - 1];
        }
        resolve(listMessage);
      });
  });
}

export function getMoreMessage(channelId, formatDataMessage) {
  if (!messageCursor) {
    return new Promise((resolve) => resolve([]));
  }
  let listMessage = [];
  return new Promise((resolve, reject) => {
    firestore()
      .collection(`channels/${channelId}/messages`)
      .orderBy('createAt', 'desc')
      .limit(30)
      .startAfter(messageCursor)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let message = formatDataMessage({ id: doc.id, ...doc.data() });
          listMessage.push(message);
        });
        if (listMessage.length > 0) {
          messageCursor = querySnapshot.docs[querySnapshot.docs.length - 1];
        }
        resolve(listMessage);
      });
  });
}

export function receiveMessage(channelId, callBack) {
  let serverTimeOffset = loadFromStorage('serverTimeOffset');
  let createAt = Date.now() + serverTimeOffset;
  console.log('serverTimeOffset', createAt);
  return firestore()
    .collection(`channels/${channelId}/messages`)
    .where('createAt', '>', createAt)
    .onSnapshot(function (snapshot) {
      if (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === 'added') {
            callBack({ ...change.doc.data(), id: change.doc.id });
          }
        });
      }
    });
}

export function readMessageListener(userId, channelId, callBack) {
  return firestore()
    .collection(`users/${userId}/channels`)
    .doc(channelId)
    .onSnapshot(function (snapshot) {
      if (snapshot) {
        let data = snapshot.data();
        if (!!data && !data.countMessageUnread) {
          callBack();
        }
      }
    });
}

export function makeReadMessage(userId, channelId) {
  let url = `users/${userId}/channels`;
  let channelRefs = firestore.collection(url).doc(channelId);
  channelRefs.update({
    countMessageUnread: 0,
  });
}

export function updateChannel(userId, channelId, message, createAt) {
  let channelData = {
    latestMessage: message,
    latestUpdate: createAt,
    latestSenderId: currentUserId,
  };
  let url = `users/${userId}/channels`;
  let channelRefs = firestore().collection(url).doc(channelId);
  return firestore().runTransaction(function (transaction) {
    return transaction.get(channelRefs).then(function (doc) {
      if (!doc.exists) {
        channelRefs.set({
          ...channelData,
          countMessageUnread: userId === currentUserId ? 0 : 1,
          channelName: userInfo.fullName,
          channelPicture: userInfo.avatar,
        });
      } else {
        channelData.countMessageUnread =
          userId === currentUserId ? 0 : doc.data().countMessageUnread + 1;
        transaction.update(channelRefs, { ...channelData });
      }
    });
  });
}

export async function createChannel(
  partnerId,
  channelName = '',
  channelPicture = ''
) {
  let serverTimeOffset = loadFromStorage('serverTimeOffset');
  let latestUpdate = Date.now() + serverTimeOffset;
  console.log('serverTimeOffset', latestUpdate);
  let channelData = {
    latestMessage: '',
    latestUpdate: latestUpdate,
    latestSenderId: currentUserId,
    members: [partnerId],
    countMessageUnread: 0,
    isTyping: false,
    channelName: channelName,
    channelPicture: channelPicture,
  };
  const channelRef = await firestore()
    .collection(`users/${currentUserId}/channels`)
    .add(channelData);
  await firestore()
    .collection(`users/${partnerId}/channels`)
    .doc(channelRef.id)
    .set({
      ...channelData,
      members: [currentUserId],
      channelName: userInfo.fullName,
      channelPicture: userInfo.imageUrl,
    });
  return { ...channelData, id: channelRef.id };
}

export function getListChannels(userId) {
  let listChannels = [];
  let url = `users/${userId}/channels`;
  return new Promise((resolve, reject) => {
    firestore()
      .collection(url)
      .orderBy('latestUpdate', 'desc')
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          listChannels.push({ id: doc.id, ...doc.data() });
        });
        resolve(listChannels);
      })
      .catch((e) => {
        resolve([]);
      });
  });
}

let listenChannel;

export function listenChannels(userId, callBack) {
  let url = `users/${userId}/channels`;
  let serverTimeOffset = loadFromStorage('serverTimeOffset');
  let latestUpdate = Date.now() + serverTimeOffset;
  console.log('serverTimeOffset', latestUpdate);
  return firestore()
    .collection(url)
    .where('latestUpdate', '>', latestUpdate)
    .onSnapshot(function (snapshot) {
      if (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          callBack({
            ...change.doc.data(),
            id: change.doc.id,
            type: change.type,
          });
        });
      }
    });
}

export function removeListenChannels() {
  if (listenChannel) {
    listenChannel();
    listenChannel = '';
  }
}

export function formatDataMessage(message) {
  const isSenderMessage = message.senderId === currentUserId;
  let text;
  switch (message.type) {
    case 'photo':
      text = '';
      break;
    case 'file':
      text = message.fileName;
      break;
    default:
      text = message.text;
      break;
  }
  let newMessage = {
    _id: message.id,
    text,
    createdAt: message.createAt.nanoseconds || message.createAt,
    user: {
      _id: message.senderId,
      avatar: userInfo.avatar,
      name: message.name,
    },
    type: message.type || 'text',
    fileName: message.fileName,
    fileSize: message.fileSize,
    position: isSenderMessage ? 'right' : 'left',
    date: message.createAt.nanoseconds || message.createAt,
    avatar: userInfo.avatar,
    data: {
      status: {
        click: true,
      },
      uri: message.fileUrl || message.imageUrl || '',
    },
  };
  return newMessage;
}

export function uploadToFirebase({ id, uploadedFile, callBack }) {
  const storageRef = storage().ref();
  const timeStamp = new Date().getTime();
  const fileRef = storageRef.child(
    `files/${id}/${timeStamp}${uploadedFile.file.name}`
  );
  const metadata = {
    contentType: uploadedFile.file.type,
  };
  const file = new File([uploadedFile.file], uploadedFile.file.name);
  fileRef.put(file, metadata).then((snapshot) => {
    snapshot.ref.getDownloadURL().then((downloadUrl) => callBack(downloadUrl));
  });
}

export function uploadToFirebaseAsync({ id, uploadedFile }) {
  return new Promise((resolve) => {
    const storageRef = storage().ref();
    const timeStamp = new Date().getTime();
    const fileRef = storageRef.child(
      `files/${id}/${timeStamp}${uploadedFile.file.name}`
    );
    const metadata = {
      contentType: uploadedFile.file.type,
    };
    const file = new File([uploadedFile.file], uploadedFile.file.name);
    fileRef.put(file, metadata).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadUrl) => resolve(downloadUrl));
    });
  });
}

export const uploadImage = (uploadedFile, uploadTimeStamp, isNRIC = false) => {
  const storageRef = storage().ref();
  const timeStamp = new Date().getTime();
  const pathFolder = isNRIC ? 'newPatientLevel2' : 'files/avatar';
  const imageRef = storageRef.child(
    `${pathFolder}/${uploadTimeStamp || timeStamp}${uploadedFile.name}`
  );
  const metadata = {
    contentType: uploadedFile.type,
  };
  const file = new File([uploadedFile], uploadedFile.name);
  return imageRef
    .put(file, metadata)
    .then((snapshot) => snapshot.ref.getDownloadURL());
};

export const getFileNameByUrlFirebase = (path) => {
  try {
    const httpsReference = storage().refFromURL(path);
    return httpsReference.name;
  } catch (error) {
    console.log('getFileNameByUrlFirebase error: ', error);
    return '';
  }
};

export const setUpRecaptchaThenSendOTP = ({
  phone,
  buttonId,
  ifSuccess,
  ifFailed,
}) => {
  window.recaptchaVerifier = new auth.RecaptchaVerifier(buttonId, {
    size: 'invisible',
  });
  window.recaptchaVerifier
    .render()
    .then((widgetId) => (window.recaptchaWidgetId = widgetId));

  sendOTP({ phone, ifSuccess, ifFailed });
};

export const sendOTP = ({ phone, ifSuccess, ifFailed }) => {
  const appVerifier = window.recaptchaVerifier;
  auth()
    .signInWithPhoneNumber(phone, appVerifier)
    .then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
      ifSuccess();
    })
    .catch((err) => {
      grecaptcha.reset(window.recaptchaWidgetId);
      ifFailed(err);
    });
};

export const signInWithGoogle = ({ loginSuccess, loginFailed }) => {
  const provider = new auth.GoogleAuthProvider();
  auth()
    .signInWithPopup(provider)
    .then((result) => {
      const user = result.additionalUserInfo.profile;
      const token = result.credential.idToken;
      loginSuccess(user, token);
    })
    .catch((error) => {
      switch (error.code) {
        case 'auth/popup-closed-by-user':
        case 'auth/cancelled-popup-request':
          break;
        default:
          const errorMessage = error.message;
          loginFailed(errorMessage);
          break;
      }
    });
};

export default firebase;
