export const editorConfiguration = {
  toolbar: [
    'heading',
    '|',
    'fontFamily',
    'fontSize',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'alignment',
    '|',
    'numberedList',
    'bulletedList',
    '|',
    'indent',
    'outdent',
    '|',
    'link',
    'blockQuote',
    'insertTable',
    '|',
    'undo',
    'redo',
  ],
};

export const TINY_API_KEY = 'r3fs7f1ijdgsnoiupcj96yex78je9addexmbzn9l8wdlrcge';

export const TINY_INIT_CONFIGURATION = {
  minHeight: 160,
  menubar: false,
  indent: false,
  plugins: 'lists link hr textcolor paste charmap help',
  toolbar1:
    'formatselect bold italic bullist numlist blockquote alignleft aligncenter alignright link',
  toolbar2:
    'strikethrough hr forecolor pastetext removeformat charmap indent outdent undo redo help',
  content_style:
    "@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap');",
  setup: function (editor) {
    editor.on('init', function () {
      editor.getBody().style.fontFamily = 'Open Sans, sans-serif';
    });
  },
};

export const CATEGORY_LIST = [
  {
    id: 'Biometric',
    name: 'Biometric Parameters',
  },
  {
    id: 'UrineTool',
    name: 'Urine & tool',
  },
  {
    id: 'BloodAnalysis',
    name: 'Blood Analysis',
  },
];

export const GROUP_MEASUREMENT_CATEGORY = {
  Biometric: ['BMIWeight', 'BMIHeight'],
  UrineTool: ['UDSProtein', 'UDSGlucose', 'UDSBlood'],
  BloodAnalysis: ['DIASTOLIC', 'DIASTOLIC2', 'SYSTOLIC', 'SYSTOLIC2'],
};

export const SPECIAL_MEASUREMENT = [
  // Urine Dipstick
  'UDSProtein',
  'UDSGlucose',
  'UDSBlood',
  // BMI
  'BMIWeight',
  'BMIHeight',
  // Waist Hip Ratio
  'WaistHipRatioWaist',
  'WaistHipRatioHip',
  // Visual Acuity
  'LeftUnaided',
  'RightUnaided',
  'LeftAided',
  'RightAided',
  // Blood Pressure
  'DIASTOLIC',
  'DIASTOLIC2',
  'SYSTOLIC',
  'SYSTOLIC2',
  // Peripheral (side) Vision Test
  'PVTRight',
  'PVTLeft',
  // Pupil Reflex
  'APRRight',
  'APRLeft',
];
