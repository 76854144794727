import React from 'react';
import { TextField } from '@material-ui/core';
import { get } from 'lodash';
import { getAddressByCode } from '../../../../helpers/Api';
import { PropTypes } from 'prop-types';
import { debounced } from '../../../../helpers/CommonHelper';
import './styles.scss';
const PostalCodeInput = ({
  isSubmitted,
  field,
  errors,
  value,
  onChange,
  addressChange,
  disabled
}) => {
  const onChangeAddress = value => {
    debounced(() => {
      const data = getAddressByCode(value);
      data.then(result => {
        result && addressChange(get(result, 'Postcodes'));
      });
    }, 500);
  };

  return (
    <>
      <TextField
        variant={disabled ? 'filled' : 'standard'}
        disabled={disabled}
        type="number"
        error={isSubmitted && errors[field] ? true : false}
        className="postal-code-input-custom"
        value={value}
        onChange={e => {
          onChange(e);
          onChangeAddress(e.target.value);
        }}
        label="Postal Code"
        helperText={isSubmitted && errors[field]}
      />
    </>
  );
};

PostalCodeInput.propTypes = {
  errors: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  addressChange: PropTypes.func,
  isSubmitted: PropTypes.bool,
  field: PropTypes.string,
  disabled: PropTypes.bool
};

PostalCodeInput.defaultProps = {
  errors: {},
  value: '',
  onChange: () => null,
  addressChange: () => null,
  isSubmitted: false,
  field: '',
  disabled: false
};
export default PostalCodeInput;
