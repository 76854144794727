import { synthesize } from 'redux-dispatcher';
import customToast from '@/new-components/CustomNotification';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  saveDataById: data => ({ data }),
  getDataById: (id, cb) => async ({ Api }) => {
    try {
      const response = await Api.get(`TestStatements/${id}`);
      if (response.status === 200) {
        dispatcher.saveDataById(response.result);
      }
      cb();
    } catch (error) {
      console.log('Error: ', error);
      cb();
    }
  },
  getData: (search, paging, filterOption = {}) => async ({ Api, getState }) => {
    try {
      const pagingData = paging || getState().promotion.paging;
      const { result, status } = await Api.get('TestStatements', {
        pageIndex: pagingData.pageIndex,
        pageSize: pagingData.pageSize,
        search,
        ...filterOption
      });

      if (status === 200 && Array.isArray(result.list)) {
        const { list, ...restParams } = result;
        const baseIndex = pagingData.pageIndex * pagingData.pageSize + 1;
        const dataAddIndex = list.map((item, index) => ({
          ...item,
          index: baseIndex + index
        }));
        dispatcher.getDataSuccess({ ...restParams, list: dataAddIndex });
      } else {
        customToast('error', 'Fetch data fail');
      }
    } catch (error) {
      customToast('error', 'Fetch data fail');
    }
  },
  editData: (data, onSuccess, onError, onFinish) => async ({
    Api,
    customToast
  }) => {
    try {
      let response;
      if (data.id) {
        response = await Api.put(`TestStatements/${data.id}`, data);
      } else {
        response = await Api.post('TestStatements', data);
      }

      if (response.status === 200) {
        onSuccess();
      } else {
        onError();
      }
      onFinish();
    } catch (error) {
      onError();
      onFinish();
    }
  }
};

const dispatcher = synthesize('statementManagement', mapDispatchToAC);
export default dispatcher;
