import {
  calculateTotalCost,
  formatData,
  formatMeasurementInSideListTest,
  returnOptionalPackageTests,
  generateParamsTestIdsPackageIdsData,
} from '../../utils';
import SelectServiceStep from './SelecetServiceStep';
import SelectPlaceAndTimeStep from './SelectPlaceAndTimeStep';
import { urlLabel } from '@/enum/PermissionEnum';
import allScreeningsDispatcher from '@/module/all-screenings/action';
import checkinPatientDispatcher from '@/module/checkin-patient-information/action';
import companyDispatcher from '@/module/company/action';
import { packageDispatcher } from '@/module/package-management';
import locationDispatcher from '@/module/setup/action';
import upcomingHSDispatcher from '@/module/upcoming-health-screening/action';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomStepper from '@/new-components/CustomStepper';
import { StepperButton } from '@/new-components/CustomStepper/styled';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { get, cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const EditAppointment = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [patientInfo, setPatientInfo] = useState({});
  const [projectInfo, setProjectInfo] = useState({});
  const [packageInfo, setPackageDetail] = useState({});
  const [dataInfo, setDataInfo] = useState({});

  const [loading, setLoading] = useState(false);

  const [timeSlot, setTimeSlot] = useState([]);
  const [timeSlotChecked, setTimeSlotChecked] = useState();
  const [dateChecked, setDateChecked] = useState();
  const [loadingSearchTimeSlot, setLoadingSearchTimeSlot] = useState(false);
  const [showErrorInfo, setShowErrorInfo] = useState(false);

  const [currentLocation, setCurrentLocation] = useState({});
  const [isValidHealthScreening, setIsValidHealthScreening] = useState(false);
  const [isRestrictPackages, setIsRestrictPackages] = useState(false);
  const [errors, setErrors] = useState({});

  const [totalCost, setTotalCost] = useState(0);
  const [totalAmountDefault, setTotalAmountDefault] = useState(0);
  const [lstOptionalTests, setLstOptionalTests] = useState([]);
  const [restrictedTestIds, setRestrictedTestIds] = useState([]);
  const [selectedOptionalIds, setSelectedOptionalIds] = useState([]);

  const [formValue, setFormValue] = useState({
    timeSlotId: '',
    date: '',
    healthScreeningId: '',
    isSuppression: false,
  });

  const location = useLocation();
  const history = useHistory();
  const apptData = get(location, 'state', {});
  const isReschedule = get(apptData, 'isReschedule');
  const isFollowUp = get(apptData, 'isFollowUpAppointment', undefined);
  const isAllScreenings =
    location?.pathname?.includes('all-screenings') || false;

  const _tempHistory = useRef();
  const ref = useRef(false);
  const loadingRestrictedLocations = useRef(true);

  useEffect(() => {
    _tempHistory.current = cloneDeep(history);
  }, []);

  const checkIsValidDate = (valueStartDate) => {
    const startDateUnix = moment(valueStartDate).startOf('date').unix();

    const dateCompare = isFollowUp
      ? moment().startOf('date').add(1, 'day').unix()
      : moment().startOf('date').unix();

    return startDateUnix < dateCompare;
  };

  const checkIsValidHealthScreening = (endTime) => {
    const endTimeUnix = moment(endTime).endOf('date').unix();
    const timeCompare = moment().unix();

    return endTimeUnix >= timeCompare;
  };

  const onSubmit = () => {
    if (loading) return;
    if (!timeSlotChecked) {
      return customToast('error', 'Please select time slots');
    }

    setLoading(true);
    upcomingHSDispatcher.editTeleAppointment(
      {
        id: apptData.id,
        timeSlotId: timeSlotChecked,
        date: dateChecked,
        isSuppression: apptData.isPaxscreenSuppression,
        ...formValue,
      },
      ({ isSuccess }) => {
        if (isSuccess) {
          customToast(
            'success',
            `Appointment for patient ${apptData.fullName} has been successfully updated.`,
            'Appointment edited'
          );
          history.push(
            isAllScreenings
              ? `/${urlLabel.allScreenings}`
              : `/${urlLabel.upcomingHealthScreening}`
          );
        }
      }
    );
  };

  useEffect(() => {
    if (isFollowUp)
      locationDispatcher.getLocationFollowUp((result) => {
        const newList = [...result].map((it) => ({
          ...it,
          locationId: it.id,
          locationName: it.name,
          locationAddress: it.address,
        }));
        const currLocation = newList?.find(
          (location) => location?.locationId === dataInfo?.locationId
        );

        setCurrentLocation({
          ...currLocation,
          startDate: moment().add(1, 'day'),
        });
      });
  }, [isFollowUp, dataInfo]);

  useEffect(() => {
    if (isEmpty(apptData)) return;

    const {
      startDate = '',
      appointmentTypes = [],
      nric = '',
      fullName = '',
      shortId = '',
      endDate = '',
      healthScreeningStartDate,
      healthScreeningEndDate,
      isPaxscreenSuppression,
      isProjectSuppression,
      projectId,
      healthScreeningId,
      locationId,
    } = apptData;

    let newFormValue = {
      appointmentTypes,
      nric,
      fullName,
      shortId,
      endDate,
      healthScreeningStartDate,
      healthScreeningEndDate,
      isPaxscreenSuppression,
      isProjectSuppression,
      healthScreeningId,
    };

    let startDateValid = moment(startDate);
    let isStartDateValid = true;
    if (checkIsValidDate(startDateValid)) {
      isStartDateValid = false;
      startDateValid = isFollowUp ? moment().add(1, 'day') : moment();

      // Check if healthScreeningStartDate is after startDateValid
      if (moment(healthScreeningStartDate).isAfter(moment(startDateValid))) {
        startDateValid = moment(healthScreeningStartDate);
      }
    }
    ref.current = isPaxscreenSuppression;
    newFormValue.startDate = startDateValid;

    onGetDataTimeSlot(startDateValid, isStartDateValid, locationId);

    companyDispatcher.getProjectByID(projectId, (result) => {
      const newListActive = result.lstHealthScreenings.filter((item) =>
        checkIsValidHealthScreening(item.endDate)
      );

      let currentLocation = newListActive.find(
        (item) => item.id === healthScreeningId
      );

      // set healthScreeningEndDate is an endDate of the project if the appointment is a follow-up
      if (isFollowUp) newFormValue.healthScreeningEndDate = result.endDate;

      if (!isFollowUp && isEmpty(newListActive)) {
        setErrors({
          location:
            'All health screenings are expired. Please update in your project!',
        });
      } else {
        setErrors({});
        if (!currentLocation && !isFollowUp) {
          currentLocation = newListActive[0];
          // Update new form value
          newFormValue.healthScreeningStartDate = currentLocation.startDate;
          newFormValue.healthScreeningEndDate = currentLocation.endDate;
          newFormValue.healthScreeningId = currentLocation.id;
          // Get data time slot with new locationId
          onGetDataTimeSlot(
            startDateValid,
            isStartDateValid,
            currentLocation.locationId
          );
        }
      }
      // Check healthScreeningEndDate is expired only with parent appointment
      setIsValidHealthScreening(!isFollowUp && isEmpty(newListActive));
      setCurrentLocation(currentLocation);
      setProjectInfo({
        ...result,
        code: result.code,
        pmCode: currentLocation?.pmCode,
        companyName: result.companyName,
        staffTypeLabel: result.staffTypeLabel,
        startDate: result.startDate,
        endDate: result.endDate,
        lstHealthScreenings: newListActive,
        currLocationId: apptData.locationId,
      });
    });

    checkinPatientDispatcher.getData(apptData.id, (paxData) => {
      const {
        paxInfo,
        lstTests,
        lstProfiles,
        lstPackages,
        ignoredTests,
        ignoredProfiles,
        doneFollowUpTests,
        staffType,
      } = paxData;

      // Have package
      if (!isEmpty(lstPackages)) {
        const { id, name, price } = lstPackages[0];
        let lstOptionalTests = [];
        packageDispatcher.getPackageById(id, (result) => {
          const {
            lstProfiles,
            lstTests: lstPackageTests,
            totalMandatoryProcedure,
            totalMandatoryRadiology,
          } = result;
          let lstProcedures = [];
          let lstRadiologies = [];
          let newListTest = [];
          let newTotalMandatoryProcedure = [];
          let newTotalMandatoryRadiology = [];
          let newLstTestProcedures = [];
          let newLstTestRadiologies = [];
          let holdDoneFollowUpTestsProcedures = [];
          let holdDoneFollowUpTestsRadiologies = [];
          let selectedOptionalProceduresIds = [];
          let selectedOptionalRadiologiesIds = [];
          let selectedOptionalIds = [];

          if (isFollowUp && !isEmpty(doneFollowUpTests)) {
            newListTest = lstPackageTests.map((item) => {
              const holdDoneFollowUpTests = doneFollowUpTests.filter(
                (it) => it.category === item.category && !it.isAddon
              );

              return {
                ...item,
                items: item.items.filter(
                  (test) =>
                    holdDoneFollowUpTests.findIndex(
                      (item) => item.testId === test.id
                    ) === -1
                ),
              };
            });
          } else {
            newListTest = lstPackageTests;
          }

          lstPackageTests.forEach((item) => {
            if (item.category === 'Procedures') lstProcedures = item.items;
            if (item.category === 'Radiologies') lstRadiologies = item.items;
          });
          const unMandatoryProcedures = [...lstProcedures]?.filter(
            (it) => !it.isRequired
          );
          const unMandatoryRadiologies = [...lstRadiologies]?.filter(
            (it) => !it.isRequired
          );
          //  Hold selectd  optional in package
          selectedOptionalProceduresIds = unMandatoryProcedures?.filter(
            (value) => {
              return ignoredTests?.every((test) => {
                return value.id !== test.testId;
              });
            }
          );

          selectedOptionalRadiologiesIds = unMandatoryRadiologies?.filter(
            (value) => {
              return ignoredTests?.every((test) => {
                return value.id !== test.testId;
              });
            }
          );

          selectedOptionalIds = selectedOptionalProceduresIds
            .concat(selectedOptionalRadiologiesIds)
            .map((it) => it.id);
          setSelectedOptionalIds(selectedOptionalIds);

          if (isFollowUp) {
            if (!isEmpty(doneFollowUpTests)) {
              // Procedure
              holdDoneFollowUpTestsProcedures = unMandatoryProcedures.filter(
                (it) => doneFollowUpTests.find((item) => item.testId === it.id)
              );

              newLstTestProcedures = unMandatoryProcedures.map((item) => {
                const itemDone = doneFollowUpTests?.find(
                  (it) => it.testId === item.id && !it.isAddon
                );

                let selectedProcedures = {};
                if (isEmpty(ignoredTests)) {
                  selectedProcedures = ignoredTests?.some((test) => {
                    return item.id === test.testId;
                  });
                } else {
                  selectedProcedures = ignoredTests?.every((test) => {
                    return item.id !== test.testId;
                  });
                }

                if (itemDone) {
                  return { ...item, isDone: true, isChecked: false };
                } else if (selectedProcedures)
                  return { ...item, isDone: false, isChecked: true };

                return { ...item, isDone: false, isChecked: false };
              });
              newLstTestProcedures = newLstTestProcedures.filter(
                (test) =>
                  holdDoneFollowUpTestsProcedures.findIndex(
                    (item) => item.id === test.id
                  ) === -1
              );

              // Radiologies
              holdDoneFollowUpTestsRadiologies = unMandatoryRadiologies.filter(
                (it) => doneFollowUpTests.find((item) => item.testId === it.id)
              );

              newLstTestRadiologies = unMandatoryRadiologies.map((item) => {
                const itemDone = doneFollowUpTests?.find(
                  (it) => it.testId === item.id && !it.isAddon
                );

                let selectedRadiologies = {};
                if (isEmpty(ignoredTests)) {
                  selectedRadiologies = ignoredTests?.some((test) => {
                    return item.id === test.testId;
                  });
                } else {
                  selectedRadiologies = ignoredTests?.every((test) => {
                    return item.id !== test.testId;
                  });
                }

                if (itemDone) {
                  return { ...item, isDone: true, isChecked: false };
                } else if (selectedRadiologies)
                  return { ...item, isDone: false, isChecked: true };

                return { ...item, isDone: false, isChecked: false };
              });

              newLstTestRadiologies = newLstTestRadiologies.filter(
                (test) =>
                  holdDoneFollowUpTestsRadiologies.findIndex(
                    (item) => item.id === test.id
                  ) === -1
              );
            } else {
              newLstTestProcedures = unMandatoryProcedures.map((item) => {
                const itemChecked = lstProcedures.find(
                  (it) => it.id === item.id
                );

                if (itemChecked) {
                  return { ...item, isDone: false, isChecked: true };
                }
                return { ...item, isDone: false, isChecked: false };
              });

              newLstTestRadiologies = unMandatoryRadiologies.map((item) => {
                const itemChecked = lstRadiologies.find(
                  (it) => it.id === item.id
                );

                if (itemChecked) {
                  return { ...item, isDone: false, isChecked: true };
                }
                return { ...item, isDone: false, isChecked: false };
              });
            }

            newTotalMandatoryProcedure =
              totalMandatoryProcedure - holdDoneFollowUpTestsProcedures.length;

            newTotalMandatoryRadiology =
              totalMandatoryRadiology - holdDoneFollowUpTestsRadiologies.length;
          } else {
            newTotalMandatoryProcedure = totalMandatoryProcedure;
            newTotalMandatoryRadiology = totalMandatoryRadiology;
          }

          // Get list of Profile, Procedure, Radiology
          // Filter unmandatory test
          // Filter Optional test based on ingnored Test

          lstOptionalTests = returnOptionalPackageTests({
            lstProfiles,
            lstProcedures,
            lstRadiologies,
            ignoredProfiles,
            ignoredTests,
          });
          setLstOptionalTests(lstOptionalTests);
          setPackageDetail({
            ...result,
            lstTests: newListTest,
            lstProcedures: newLstTestProcedures,
            lstRadiologies: newLstTestRadiologies,
            totalMandatoryProcedure: newTotalMandatoryProcedure,
            totalMandatoryRadiology: newTotalMandatoryRadiology,
            name,
            price,
            holdDoneFollowUpTestsRadiologies,
            holdDoneFollowUpTestsProcedures,
          });
        });
      }
      // Calculate total cost and total amount
      let newLstTests = formatData({ lstTests });
      const totalCost = calculateTotalCost({
        ...newLstTests,
        lstPackages,
        lstProfiles,
      });

      newLstTests = formatMeasurementInSideListTest(lstTests);

      setDataInfo({ ...paxData, lstTests: newLstTests });
      setTotalCost(totalCost);
      setTotalAmountDefault(apptData?.totalCost);

      setPatientInfo({
        fullName: paxInfo.fullName,
        identityNumber: paxInfo.identityNumber,
        dateOfBirth: paxInfo.dateOfBirth,
        nationality: paxInfo.nationality,
        ethnicGroup: paxInfo.ethnicGroup,
        mobile: paxInfo.mobile,
        email: paxInfo.email,
        countryCode: paxInfo.countryCode,
        staffType: staffType?.type,
      });
    });

    if (locationId) getDetailLocation(locationId);

    setFormValue(newFormValue);
  }, [apptData, isReschedule]);

  useEffect(() => {
    if (isFollowUp) return;

    if (isEmpty(dataInfo)) return;

    if (isEmpty(projectInfo)) return;

    if (isEmpty(selectedOptionalIds)) return;

    if (!loadingRestrictedLocations.current) return;

    loadingRestrictedLocations.current = false;

    const formatTests = (lstTests) => {
      lstTests.forEach((testCategory) => {
        if (testCategory.category === 'Measurements') {
          testCategory.items = testCategory.items.reduce((prev, curr) => {
            return prev.concat(isEmpty(curr.total) ? curr : curr.total);
          }, []);
        }
      });

      return lstTests;
    };

    const lstTests = formatTests(cloneDeep(dataInfo).lstTests);
    const lstPackages = cloneDeep(dataInfo.lstPackages);

    const params = generateParamsTestIdsPackageIdsData({
      lstPackages,
      lstTests,
    });
    // Handle optional that already restricted (Procedure & Radiology)
    const newParams = {
      ...params,
      testIds: params.testIds.concat(selectedOptionalIds),
    };

    allScreeningsDispatcher.getRestrictLocationByIds(
      newParams,
      (restrictedLocationIds) => {
        setProjectInfo((projectInfo) => {
          const newLstHealthScreenings = (
            projectInfo.lstHealthScreenings || []
          ).filter((item) => !restrictedLocationIds.includes(item.locationId));

          return {
            ...projectInfo,
            lstHealthScreenings: newLstHealthScreenings,
          };
        });
      }
    );
  }, [isFollowUp, dataInfo, projectInfo, selectedOptionalIds]);

  const onGetDataTimeSlot = (value, isStartDateValid, locationId) => {
    if (!value) return;

    if (checkIsValidDate(value)) {
      setShowErrorInfo(true);
    } else {
      setShowErrorInfo(false);
    }

    if (locationId) {
      setLoadingSearchTimeSlot(true);
      locationDispatcher.getDetailLocationTimeSlot(
        {
          id: locationId,
          viewAll: true,
          date: moment(value).format('YYYY-MM-DD'),
        },
        (result) => {
          const { timeSlotId } = apptData;
          const startTimeSelected = result.find((it) => it.id === timeSlotId);

          const dayOfWeek = moment(value).day();
          const timeSlots = result.filter(
            (it) => it.dayOfWeek === dayOfWeek && !checkInvalidTimeslot(it)
          );

          const timeslotInvalid = result.filter(
            (it) => it.dayOfWeek === dayOfWeek && checkInvalidTimeslot(it)
          );
          const checkTimeslotId = timeslotInvalid?.find(
            (it) => it.id === timeSlotId
          );
          if (
            isEmpty(checkTimeslotId) &&
            startTimeSelected &&
            (!dateChecked ||
              dateChecked === moment(value).startOf('date').format())
          ) {
            isStartDateValid && setTimeSlotChecked(startTimeSelected.id);

            setDateChecked(moment(value).startOf('date').format());
          }
          setTimeSlot(timeSlots);
          setLoadingSearchTimeSlot(false);
        }
      );
      // locationDispatcher.getDetailLocation(apptData.locationId, result => {
      //   setClosureDate(result?.dayOffs);
      // });
    }
  };

  const checkInvalidTimeslot = (row) => {
    const { startDate, endDate, isActive } = row;
    // Disable Time Slot:
    // 1 Start time valid is after current time
    // 2 Start date valid is after current date 2 days
    // 3 Timeslot not active
    // 3 Timeslot not active
    // 4 End time valid is before current 5 minutes

    if (checkIsValidDate(startDate)) return true;
    if (checkIsValidTime(endDate)) return true;
    if (checkIsValidTime5Mins(endDate)) return true;

    if (!isActive) return true;

    return;
  };

  const checkIsValidTime = (startTime) => {
    const startTimeUnix = moment(startTime).unix();
    const timeCompare = moment().unix();

    return startTimeUnix <= timeCompare;
  };

  const checkIsValidTime5Mins = (endTime) => {
    const endTime5Mins = moment(endTime).subtract(5, 'minutes');
    const timeCompare = moment();
    const diffTime = endTime5Mins.diff(timeCompare, 'minutes');

    return diffTime < 0;
  };

  const getDetailLocation = (locationId) => {
    locationDispatcher.getDetailLocation(locationId, (result) => {
      const { restrictedPackageIds, restrictedTestIds } = result;
      if (restrictedPackageIds) {
        checkinPatientDispatcher.getData(apptData?.id, (resultPax) => {
          const packageIds = resultPax.lstPackages.map((test) => test.id);
          const restrictedPackageIds = result.restrictedPackageIds;
          const hasRestrictedIds = restrictedPackageIds.filter((id) =>
            packageIds.includes(id)
          );
          setIsRestrictPackages(!isEmpty(hasRestrictedIds));
        });
      }
      setRestrictedTestIds(restrictedTestIds);
    });
  };

  const STEPS = [
    {
      name: 'Select place and time',
      component: (
        <SelectPlaceAndTimeStep
          errors={errors}
          setErrors={setErrors}
          dataInfo={apptData}
          patientInfo={patientInfo}
          projectInfo={projectInfo}
          setProjectInfo={setProjectInfo}
          formValue={formValue}
          setFormValue={setFormValue}
          setCurrentLocation={setCurrentLocation}
          currentLocation={currentLocation}
          isFollowUp={isFollowUp}
          onGetDataTimeSlot={onGetDataTimeSlot}
          getDetailLocation={getDetailLocation}
          timeSlot={timeSlot}
          setTimeSlot={setTimeSlot}
          timeSlotChecked={timeSlotChecked}
          setTimeSlotChecked={setTimeSlotChecked}
          dateChecked={dateChecked}
          setDateChecked={setDateChecked}
          showErrorInfo={showErrorInfo}
        />
      ),
    },
    {
      name: 'Select service',
      component: (
        <SelectServiceStep
          totalCost={totalCost}
          totalAmountDefault={totalAmountDefault}
          packageInfo={packageInfo}
          apptData={apptData}
          dataInfo={dataInfo}
          projectInfo={projectInfo}
          paymentStatus={apptData.paymentStatus}
          lstOptionalTests={lstOptionalTests}
          restrictedTestIds={restrictedTestIds}
        />
      ),
    },
  ];

  if (!location.state) history.push(`/${urlLabel.allScreenings}`);

  const onCancel = () => history.push(`/${urlLabel.allScreenings}`);

  const StepperButtons = () => (
    <Box display="flex" justifyContent="flex-end" p={1.25}>
      <StepperButton
        className="secondary"
        disabled={currentStep === 0}
        onClick={() => setCurrentStep(currentStep - 1)}
      >
        Back
      </StepperButton>
      <StepperButton
        disabled={isValidHealthScreening || isEmpty(timeSlotChecked)}
        onClick={() => {
          if (currentStep === STEPS.length - 1) {
            onSubmit();
          } else setCurrentStep(currentStep + 1);
        }}
      >
        {currentStep === STEPS.length - 1 ? 'Save changes' : 'Next'}
      </StepperButton>
    </Box>
  );

  return (
    <>
      <CustomPaperContainer
        header={
          <CustomHeader
            title="Edit appointment"
            isSearch={false}
            renderButton={() => (
              <>
                <Button color="primary" variant="outlined" onClick={onCancel}>
                  Cancel
                </Button>
              </>
            )}
          />
        }
        children={() => (
          <Paper square>
            <CustomStepper
              steps={STEPS}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              disableNext
              renderButtons={StepperButtons}
            >
              <Box height="calc(100vh - 280px)" overflow="scroll">
                {STEPS && STEPS[currentStep]?.component}
              </Box>
            </CustomStepper>
          </Paper>
        )}
      />
    </>
  );
};

export default EditAppointment;
