import { Visibility } from '@material-ui/icons';

export const renderColumn = () => {
  let tableComlumn = [
    {
      label: 'Name',
      stateValue: 'fullname'
    },
    {
      label: 'Email',
      stateValue: 'emailAddress'
    },
    {
      label: 'Mobile',
      stateValue: 'phoneNumber'
    },
    {
      label: '',
      stateValue: null
    }
  ];

  return tableComlumn;
};
export const renderConsultListColumn = () => {
  let tableComlumn = [
    {
      label: 'Date',
      stateValue: 'consultantDate'
    },
    {
      label: 'Consult',
      stateValue: 'consult'
    },
    {
      label: 'Doctor',
      stateValue: 'doctorFullName'
    },
    {
      label: 'Patient',
      stateValue: 'patient'
    },
    {
      label: 'Diagnosis',
      stateValue: 'diagnosis'
    },
    {
      label: 'Duration',
      stateValue: 'duration'
    },
    {
      label: 'Total Cost',
      stateValue: 'totalCost'
    },
    {
      label: 'Status',
      stateValue: 'consultStatus'
    }
  ];

  return tableComlumn;
};

export const renderListAction = event => {
  const LIST_ACTIONS = [
    {
      key: 'view',
      icon: Visibility,
      label: 'View Detail',
      onClick: () => event('view')
    }
  ];

  return LIST_ACTIONS;
};
