import caConsultDispatcher from '../action/caConsult';
import CustomLoadingButton from '@/new-components/CustomLoadingButton';
import CustomModalTitle from '@/new-components/CustomModal/CustomModalTitle';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';

export const TYPE_MODAL = {
  Create: 'Create',
  Edit: 'Edit',
};

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 400,
  },
})(Dialog);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end',
  },
})(DialogActions);

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 600,
    color: '#333333',
  },
})(Typography);

const CustomTitleSubLabel = withStyles({
  root: {
    fontWeight: 600,
    color: '#666666',
    marginBottom: 8,
  },
})(Typography);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 40,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
  },
})(TextField);

const ModalAddCoPayment = ({
  selectedItem,
  open,
  onClose,
  typeModal,
  onSubmit,
  loading,
}) => {
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [formValue, setFormValue] = useState({
    coPaymentCost: null,
    consultFee: null,
    coPaymentInvoices: [],
  });
  const handleChangeForm = (key) => (e) => {
    if (key === 'coPaymentCost' && typeModal === TYPE_MODAL.Create) {
      let coPaymentInvoices = [];
      if (!isEmpty(formValue.coPaymentInvoices)) {
        coPaymentInvoices = [...formValue.coPaymentInvoices].map((d) => ({
          ...d,
          subTotal: 0,
        }));
        setFormValue({
          coPaymentCost: e?.floatValue,
          consultFee: 0,
          coPaymentInvoices,
        });
      } else
        setFormValue({
          coPaymentCost: e?.floatValue,
          consultFee: 0,
        });
    } else setFormValue({ ...formValue, [key]: e?.floatValue });
  };

  const handleChangePrescriptionForm = (inx) => (e) => {
    let newCoPaymentInvoices = [...formValue.coPaymentInvoices];
    newCoPaymentInvoices[inx].subTotal = e?.floatValue;

    setFormValue({ ...formValue, coPaymentInvoices: newCoPaymentInvoices });
  };

  const getConsultDetail = async () => {
    setIsLoadingData(true);
    caConsultDispatcher.getConsultDetail(selectedItem.id, (result) => {
      setIsLoadingData(false);

      const { coPaymentCost, consultFees, invoices } = result;
      const consultFee = consultFees?.find(
        (d) => d.name === 'Consultation'
      )?.fee;
      setFormValue({
        coPaymentCost,
        consultFee,
        coPaymentInvoices: invoices || [],
      });
    });
  };

  const isEmptyParams = useMemo(() => {
    const filterKeys = ['coPaymentCost', 'consultFee'];
    const checkCoPaymentInvoices =
      !isEmpty(formValue.coPaymentInvoices) &&
      formValue.coPaymentInvoices.some(
        (d) => typeof d.subTotal !== 'number' && !d.subTotal
      );
    return (
      filterKeys.some((key) => typeof formValue[key] !== 'number') ||
      checkCoPaymentInvoices
    );
  }, [formValue]);

  useEffect(() => {
    getConsultDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
      maxWidth="lg"
    >
      <CustomModalTitle onClose={onClose}>
        {typeModal === TYPE_MODAL.Create ? 'Add' : typeModal} co-payment
      </CustomModalTitle>
      <CustomDialogContent dividers>
        {isLoadingData ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTitleSubLabel>Co-payment</CustomTitleSubLabel>
              <NumberFormat
                fullWidth
                customInput={CustomTextField}
                variant="outlined"
                placeholder="0.00"
                onValueChange={handleChangeForm('coPaymentCost')}
                allowNegative={false}
                value={formValue.coPaymentCost}
                decimalScale={2}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <CustomTitleSubLabel>Consultation</CustomTitleSubLabel>
              <NumberFormat
                fullWidth
                customInput={CustomTextField}
                variant="outlined"
                placeholder="0.00"
                onValueChange={handleChangeForm('consultFee')}
                allowNegative={false}
                value={formValue.consultFee}
                decimalScale={2}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            {!isEmpty(formValue?.coPaymentInvoices) ? (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <CustomTitleLabel>Prescription</CustomTitleLabel>
                </Grid>
                {formValue?.coPaymentInvoices.map((d, inx) => {
                  return (
                    <Grid item xs={12} key={`${d.medicineName}-${inx}`}>
                      <CustomTitleSubLabel>
                        {d.medicineName}
                      </CustomTitleSubLabel>
                      <NumberFormat
                        fullWidth
                        customInput={CustomTextField}
                        variant="outlined"
                        placeholder="0.00"
                        onValueChange={handleChangePrescriptionForm(inx)}
                        allowNegative={false}
                        value={d.subTotal}
                        decimalScale={2}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  );
                })}
              </>
            ) : null}
          </Grid>
        )}
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomLoadingButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => onSubmit(typeModal, formValue)}
          disabled={isEmptyParams || loading}
          style={{ height: 40 }}
        >
          {typeModal === TYPE_MODAL.Create ? 'Add' : 'Save'}
        </CustomLoadingButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalAddCoPayment;
