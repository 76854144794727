import { AddIcon, EditIcon, RemoveIcon } from '@/assets/svg';
import { DeleteRounded, Edit } from '@material-ui/icons';

export const renderColumn = () => {
  const tableColumn = [
    {
      label: 'Start time',
      stateValue: 'startTime'
    },
    {
      label: 'End time',
      stateValue: 'name'
    },
    {
      label: 'Time Interval',
      stateValue: 'intervals'
    },
    {
      label: 'Quota',
      stateValue: 'quota'
    },
    {
      label: 'Status',
      stateValue: 'status'
    },
    {
      label: '',
      stateValue: 'action'
    }
  ];
  return tableColumn;
};

export const renderActions = ({ onEdit, onDelete }) => {
  const LIST_ACTIONS = [
    {
      key: 'edit',
      icon: AddIcon,
      label: 'Edit',
      onClick: onEdit
    },
    {
      key: 'delete',
      icon: RemoveIcon,
      label: 'Delete',
      onClick: onDelete,
      color: '#DB3D49'
    }
  ];
  return LIST_ACTIONS;
};

export const renderActionsStaffType = ({ onEdit, onDelete }) => {
  const LIST_ACTIONS = [
    {
      key: 'edit',
      icon: EditIcon,
      label: 'Edit staff type',
      onClick: onEdit
    },
    {
      key: 'delete',
      icon: RemoveIcon,
      label: 'Remove',
      onClick: onDelete,
      color: '#DB3D49'
    }
  ];
  return LIST_ACTIONS;
};
