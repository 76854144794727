import React, { useEffect } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import CustomDate from '@/new-components/CustomDate';
import CustomRadioButton from '@/new-components/CustomRadioButton';
import CustomTooltip from '@/new-components/CustomTooltip';

import { ArrowDown, MincAdmin, ProjectInfoIcon } from '@/assets/svg';
import { checkIsSpecialLocations, getNationalNumber } from '@/helpers';
import isEmpty from 'lodash/isEmpty';
import { validateDate } from '@/utils/validation';
import { formatTimeShowUILocal } from '@/module/setup/utils';
import { Typography } from '@material-ui/core';
import { parsePhoneNumber } from 'libphonenumber-js';

const rowStyles = makeStyles({
  title: {
    paddingBottom: 10,
    minWidth: 116,
    verticalAlign: 'top',
    fontWeight: 400,
    color: '#666666'
  },
  text: {
    paddingBottom: 10,
    fontSize: 16,
    verticalAlign: 'top'
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 8,
    color: '#666666',
    width: 24,
    height: 24
  }
});

const useStyles = makeStyles({
  border: {
    height: 1,
    width: '100%',
    margin: '8px 0',
    backgroundImage: 'linear-gradient(to right, #C1C1C1 50%, transparent 50%)',
    backgroundPosition: 'top',
    backgroundSize: '10px 1px',
    backgroundRepeat: 'repeat-x'
  },

  container: {
    display: 'flex'
  },

  rightContainer: {
    overflow: 'scroll',
    padding: '20px 40px',
    height: 'calc(100vh - 282px)'
  },

  leftContainer: {
    padding: '20px 40px',
    flex: '1',

    overflow: 'auto',
    borderRight: '1px solid #F1F1F1',
    height: 'calc(100vh - 282px)'
  },
  txtQuota: {
    fontSize: 16,
    color: '#666666'
  }
});

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 6,
    fontWeight: 600
  }
})(InputLabel);

const CustomCard = withStyles({
  root: {
    padding: 20,
    marginTop: 12,
    boxShadow: 'none',
    borderRadius: 16,
    backgroundColor: props => (props.color ? props.color : '#F6F6F6'),
    width: props => (props.width ? props.width : 'unset')
  }
})(Card);

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      height: 48,
      padding: 4
    }
  }
})(TextField);

const RowHeaderItem = ({ title, icon }) => {
  const Icon = icon;
  const classes = rowStyles();
  return (
    <th
      colSpan={2}
      className={classes.title}
      style={{ fontSize: 20, fontWeight: 500 }}
    >
      {icon && <Icon className={classes.icon} />}
      <span style={{ verticalAlign: 'middle', color: '#333333' }}>{title}</span>
    </th>
  );
};

const RowItem = ({ children, title }) => {
  const classes = rowStyles();
  return (
    <tr>
      <td className={classes.title}>{title}</td>
      {children === 'None' ? (
        <td className={classes.text} style={{ color: '#999999' }}>
          {children}
        </td>
      ) : (
        <td className={classes.text}>
          <CustomTooltip content={children} />
        </td>
      )}
    </tr>
  );
};

const SelectPlaceAndTimeStep = ({
  dataInfo,
  patientInfo,
  projectInfo,
  formValue,
  setFormValue,
  errors,
  setErrors,
  currentLocation,
  setCurrentLocation,
  isFollowUp,
  onGetDataTimeSlot,
  getDetailLocation,
  timeSlot,
  setTimeSlot,
  timeSlotChecked,
  setTimeSlotChecked,
  dateChecked,
  setDateChecked,
  showErrorInfo,
  setProjectInfo
}) => {
  const classes = useStyles();

  const checkIsValidDate = valueStartDate => {
    const startDateUnix = moment(valueStartDate)
      .startOf('date')
      .unix();

    const dateCompare = moment()
      .startOf('date')
      .unix();

    return startDateUnix < dateCompare;
  };
  const resetCheckedValue = () => null;

  const handleChangeForm = key => value => {
    const utcValue = moment(value)
      .startOf('date')
      .utc()
      .format();

    const { valid, message } = validateDate(utcValue);

    if (!valid) {
      setErrors({ ...errors, startDate: message });
    }
    if (valid && key === 'startDate') {
      resetCheckedValue();
      const locationId = !isFollowUp
        ? currentLocation?.locationId
        : dataInfo?.locationId;
      onGetDataTimeSlot(utcValue, false, locationId);
      delete errors.startDate;
      setErrors(errors);
    }

    setTimeSlotChecked();
    setFormValue({ ...formValue, [key]: utcValue });
  };

  const handleChangeHealthScreening = (key, value) => {
    let formValueStartDate = undefined;
    const utcValue = moment(formValueStartDate)
      .startOf('date')
      .utc()
      .format();

    const { valid, message } = validateDate(utcValue);

    if (!valid) {
      setErrors({ ...errors, startDate: message });
    } else {
      resetCheckedValue();
      getDetailLocation(value?.locationId);

      // Check if healthScreeningStartDate is after currentDate, then set startDate to healthScreeningStartDate
      if (moment(value?.startDate).isAfter(moment(utcValue))) {
        formValueStartDate = moment(value?.startDate);
        onGetDataTimeSlot(value?.startDate, false, value?.locationId);
      } else {
        onGetDataTimeSlot(utcValue, false, value?.locationId);
      }

      delete errors.startDate;
      setErrors(errors);
    }

    setTimeSlotChecked();
    setCurrentLocation(value);
    setProjectInfo({ ...projectInfo, pmCode: value?.pmCode });
    setFormValue({
      ...formValue,
      [key]: value.id,
      startDate: formValueStartDate,
      healthScreeningStartDate: value.startDate,
      healthScreeningEndDate: value.endDate
    });
  };

  const getMinDatePicker = valueDate => {
    if (checkIsValidDate(valueDate)) {
      return isFollowUp ? moment().add(1, 'day') : moment();
    }
    return valueDate;
  };

  const getMaxDatePicker = () =>
    isFollowUp
      ? undefined
      : currentLocation?.endDate
      ? moment(currentLocation?.endDate)
      : undefined;

  const onChangeChecked = id => {
    setTimeSlotChecked(id);
    setDateChecked(
      moment(formValue.startDate)
        .startOf('date')
        .format()
    );
    setFormValue({
      ...formValue,
      timeSlotId: id
    });
  };

  const checkIsValidHealthScreening = endTime => {
    const endTimeUnix = moment(endTime)
      .endOf('date')
      .unix();
    const timeCompare = moment().unix();

    return endTimeUnix >= timeCompare;
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.leftContainer}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <CustomInputLabel>Location</CustomInputLabel>

            <Autocomplete
              getOptionSelected={(option, value) =>
                option?.locationId === value?.locationId
              }
              getOptionLabel={option => option.locationName || ''}
              options={
                projectInfo?.lstHealthScreenings?.filter(item =>
                  checkIsValidHealthScreening(item.endDate)
                ) || []
              }
              autoComplete
              onChange={(e, newValue) =>
                handleChangeHealthScreening('healthScreeningId', newValue)
              }
              disabled={isFollowUp}
              value={currentLocation}
              renderInput={params => (
                <CustomTextField
                  variant="outlined"
                  {...params}
                  error={!!errors?.location}
                  helperText={errors?.location}
                />
              )}
              renderOption={option => (
                <span className="max-line">{option.locationName || ''}</span>
              )}
              disableClearable
              popupIcon={<ArrowDown />}
            />
            <CustomCard>
              <table>
                <RowItem title="Location name">
                  {currentLocation?.locationName || 'None'}
                </RowItem>
                <RowItem title="Address">
                  {currentLocation?.locationAddress || 'None'}
                </RowItem>
                {!isFollowUp ? (
                  <>
                    <RowItem title="Start date">
                      {currentLocation?.startDate
                        ? moment(currentLocation?.startDate).format(
                            'DD/MM/YYYY'
                          )
                        : 'None'}
                    </RowItem>
                    <RowItem title="End date">
                      {currentLocation?.endDate
                        ? moment(currentLocation?.endDate).format('DD/MM/YYYY')
                        : 'None'}
                    </RowItem>
                  </>
                ) : null}
              </table>
            </CustomCard>
          </Grid>

          <Grid item md={12}>
            <CustomInputLabel>Date</CustomInputLabel>
            <CustomDate
              selectedDate={
                formValue.startDate ? moment(formValue.startDate) : moment()
              }
              maxDate={getMaxDatePicker()}
              minDate={
                checkIsSpecialLocations(currentLocation?.locationName)
                  ? moment().add(3, 'day')
                  : currentLocation?.startDate
                  ? getMinDatePicker(currentLocation?.startDate)
                  : undefined
              }
              isFullWidth
              placeholder="DD/MM/YYYY"
              clearable={false}
              onChange={handleChangeForm('startDate')}
              style={{ height: 48 }}
            />

            {showErrorInfo && (
              <Typography style={{ fontSize: 14, padding: 4 }} color="error">
                <span>Please choose start date is same or later than </span>
                <span style={{ fontWeight: 600 }}>
                  {isFollowUp
                    ? moment()
                        .add(1, 'day')
                        .format('DD/MM/YYYY')
                    : moment().format('DD/MM/YYYY')}
                </span>
              </Typography>
            )}
            <Grid container spacing={2} style={{ marginTop: 16 }}>
              {timeSlot.map(item => {
                return (
                  <Grid key={`${item.id}`} item lg={4} md={6} xs={12}>
                    <CustomRadioButton
                      keyValue="timeslotId"
                      item={item}
                      onClick={() => onChangeChecked(item.id)}
                      checked={
                        item.id === timeSlotChecked &&
                        dateChecked ===
                          moment(formValue.startDate)
                            .startOf('date')
                            .format()
                      }
                    >
                      {item.startDate && formatTimeShowUILocal(item.startDate)}

                      <span className={classes.txtQuota}>
                        {item.quotaUsed}/{item.quotaBase}
                      </span>
                    </CustomRadioButton>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.rightContainer}>
        <CustomCard color="#e2f2ffb3" width={360}>
          <table>
            <RowHeaderItem title="Patient info" icon={MincAdmin} />
            <RowItem title="Name">{patientInfo?.fullName || 'None'}</RowItem>
            <RowItem title="ID number">
              {patientInfo?.identityNumber || 'None'}
            </RowItem>
            <RowItem title="Date of birth">
              {patientInfo?.dateOfBirth
                ? moment(patientInfo?.dateOfBirth).format('DD/MM/YYYY')
                : 'None'}
            </RowItem>
            <RowItem title="Ethnic group">
              {patientInfo?.ethnicGroup || 'None'}
            </RowItem>
            <RowItem title="Phone number">
              {patientInfo?.mobile
                ? parsePhoneNumber(
                    getNationalNumber(
                      patientInfo?.mobile,
                      patientInfo?.countryCode,
                      true
                    )
                  ).formatInternational()
                : 'None'}
            </RowItem>
            <RowItem title="Email">{patientInfo?.email || 'None'}</RowItem>
          </table>
          <div className={classes.border}></div>
          <table>
            <RowHeaderItem title="Project" icon={ProjectInfoIcon} />
            <RowItem title="Project code">
              {projectInfo?.code || 'None'}
            </RowItem>
            {isFollowUp ? null : (
              <RowItem title="PM code">{projectInfo.pmCode || 'None'}</RowItem>
            )}
            <RowItem title="Company">
              {projectInfo.companyName || 'None'}
            </RowItem>
            <RowItem title="Staff type">
              {patientInfo?.staffType || '—'}
            </RowItem>
            <RowItem title="Start date">
              {projectInfo?.startDate
                ? moment(projectInfo?.startDate).format('DD/MM/YYYY')
                : 'None'}
            </RowItem>
            <RowItem title="End date">
              {projectInfo?.endDate
                ? moment(projectInfo?.endDate).format('DD/MM/YYYY')
                : 'None'}
            </RowItem>
          </table>
        </CustomCard>
      </Box>
    </Box>
  );
};

export default SelectPlaceAndTimeStep;
