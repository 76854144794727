/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ModalPage from './styled';
import { DialogContent } from '@material-ui/core';
import { ClearRounded } from '@material-ui/icons';
import locationDispatcher from '@/module/setup/action';

import TabsUI from '@/module/common/componentUI/TabsUI';
import TableTimeSlot from './TableTimeSlot';
import OperationDates from '@/module/setup/components/OperationDate';
import { locationTabs } from './renderColumn';

const ScreeningModal = ({ onClose, currentValue }) => {
  const [locationDetail, setLocationDetail] = useState({ dayOfWeeks: [] });

  const [loadingTable, setLoadingTable] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (currentValue) {
      setLoadingTable(true);

      locationDispatcher.getDetailLocation(currentValue.locationId, data => {
        setLocationDetail(data);
        setLoadingTable(false);
      });
    }
  }, [currentValue]);

  const renderTab = [
    {
      body: (
        <TableTimeSlot
          data={locationDetail.dayOfWeeks || []}
          loading={loadingTable}
        />
      )
    },
    {
      body: <OperationDates selectedDates={locationDetail.dayOffs} disabled />
    }
  ];

  return (
    <ModalPage>
      <DialogContent>
        <div className="test-header">
          Location Time Slots
          <ClearRounded
            style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
            onClick={onClose}
          />
        </div>
        <div className="test-body">
          <TabsUI
            listTab={locationTabs}
            value={currentTab}
            onChange={(e, val) => setCurrentTab(val)}
            renderLabel="label"
            renderKey="key"
          />
          {renderTab[currentTab]?.body}
        </div>
      </DialogContent>
    </ModalPage>
  );
};

export default ScreeningModal;
