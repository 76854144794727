import { appointmentManagementDispatcher } from '..';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  },
  listAvailableDoctors: []
};

const appointmentManagementReducer = appointmentManagementDispatcher(
  initialState,
  {
    [appointmentManagementDispatcher.getDataSuccess]: (state, payload) => ({
      list: payload.data.list,
      paging: {
        ...payload.data,
        list: undefined
      }
    }),
    [appointmentManagementDispatcher.getAvailableDoctorSuccess]: (
      state,
      payload
    ) => ({
      listAvailableDoctors: payload.data.list
    }),
    [appointmentManagementDispatcher.getAvailableUserSuccess]: (
      state,
      payload
    ) => ({
      listAvailableUsers: payload.data.list
    })
  }
);

export default appointmentManagementReducer;
