import React, { useEffect, useState } from 'react';
import { TableCell, IconButton, withStyles } from '@material-ui/core';

import { TableWrapped } from '@/module/checkin-patient-information/styled';
import CustomAutocompleteModal from '@/new-components/CustomAutocompleteModal';
import CustomTooltip from '@/new-components/CustomTooltip';
import { RemoveIcon } from '@/assets/svg';
import { mergeArrayByKey } from '@/helpers';
import { cloneDeep, isEmpty, lowerCase, uniq, uniqBy } from 'lodash';
import {
  COLUMN_RESTRICTED_LOCATION,
  renderColumns,
  TABLE_STATE
} from '@/module/setup/constants';
import ServicesTable from '@/module/setup/components/ServiceItems/ServicesTable';
import { useSelector } from 'react-redux';
import globalDispatcher from '@/redux/global/actions';

const CustomTableCell = withStyles({
  root: {
    fontSize: 16
  }
})(TableCell);

const RestrictLocations = ({
  formValue,
  setFormValue,
  removeColumns = [],
  prefixName
}) => {
  const { locations: listLocations } = useSelector(state => state.globalState);
  const [showModal, setShowModal] = useState(false);
  const [currentState, setCurrentState] = useState(TABLE_STATE.Outside);

  const [selectedItem, setSelectedItem] = useState([]);
  const [indirectSelectedItem, setIndirectSelectedItem] = useState([]);
  const [locations, setLocations] = useState([]);
  const [indirectLocations, setIndirectLocations] = useState([]);

  const columnsTable = renderColumns(COLUMN_RESTRICTED_LOCATION, removeColumns);

  const onAddNewService = newSelected => {
    const selected = selectedItem.concat(newSelected);
    const newList = mergeArrayByKey(selected, listLocations, 'id');
    setLocations(newList);
    setFormValue({ ...formValue, restrictedLocationIds: selected });
    setCurrentState(TABLE_STATE.Outside);
    setShowModal(false);
    setSelectedItem(selected);
  };

  const onDeleteService = (row, index) => {
    let restrictedLocationIds = cloneDeep(
      formValue.restrictedLocationIds
    ).filter(it => it !== row.id);
    let newSelectedItem = cloneDeep(selectedItem).map(item =>
      item.id === row.id ? { ...item, isChecked: false } : item
    );
    newSelectedItem.splice(index, 1);
    setSelectedItem(newSelectedItem);
    setFormValue({ ...formValue, restrictedLocationIds });
  };

  useEffect(() => {
    globalDispatcher.getListLocations();
  }, []);

  useEffect(() => {
    if (!isEmpty(formValue)) {
      const newSelectedList = listLocations.filter(location =>
        formValue.restrictedLocationIds.includes(location.id)
      );
      const newIndirectSelectedList = listLocations.filter(location =>
        formValue.restrictedTestLocationIds?.includes(location.id)
      );
      setLocations(newSelectedList);
      setIndirectLocations(newIndirectSelectedList);
      setSelectedItem(newSelectedList.map(it => it.id));
      setIndirectSelectedItem(newIndirectSelectedList.map(it => it.id));
    }
  }, [formValue, listLocations]);

  const renderRow = (row, i) => {
    return (
      <>
        <CustomTableCell>
          <CustomTooltip content={row.name} />
        </CustomTableCell>

        <CustomTableCell>
          <CustomTooltip content={row.address} />
        </CustomTableCell>
        <TableCell align="right">
          {currentState === TABLE_STATE.Outside &&
            !indirectSelectedItem.includes(row.id) && (
              <IconButton
                className="delete-icon"
                onClick={e => {
                  e && e.preventDefault();
                  onDeleteService(row, i);
                }}
              >
                <RemoveIcon />
              </IconButton>
            )}
        </TableCell>
      </>
    );
  };

  return (
    <TableWrapped>
      <ServicesTable
        title="Restricted locations"
        subTitle={`This ${lowerCase(
          prefixName
        )} will not be offered at the following locations.`}
        labelButton="Add location"
        columns={columnsTable}
        renderRow={renderRow}
        data={uniqBy([...locations, ...indirectLocations], 'id')}
        onClick={e => {
          e && e.preventDefault();
          setShowModal(!showModal);
          setCurrentState(TABLE_STATE.Modal);
        }}
      />

      <CustomAutocompleteModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setCurrentState(TABLE_STATE.Outside);
        }}
        onSubmit={onAddNewService}
        data={listLocations}
        submitLabel="Restrict"
        title="location"
        columns={columnsTable}
        searchPlaceholder="Search by location name..."
        selectedItem={uniq([...selectedItem, ...indirectSelectedItem])}
        setSelectedItem={setSelectedItem}
        renderRow={renderRow}
      />
    </TableWrapped>
  );
};

export default RestrictLocations;
