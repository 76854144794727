import CustomSelect from '../CustomSelect';
import CustomAdapter from './CustomDateAdapter';
import { CustomStaticDatePicker } from './styled';
import { NewCalendar } from '@/assets/svg';
import { DATE_FORMAT } from '@/module/common';
import { Popover, TextField, withStyles } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useState } from 'react';

export const QUICK_RANGE = [
  'Today',
  'Tomorrow',
  'This week',
  'This month',
  'Next week',
  'Next month',
];

const CustomDate = ({
  selectedDate,
  onChange,
  placeholder,
  customRange,
  minDate,
  maxDate,
  clearable = selectedDate,
  onClear,
  disablePast,
  views,
  format = DATE_FORMAT,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <CustomSelect
      isFullWidth
      onClick={handleClick}
      clearable={clearable}
      onClear={onClear}
      suffixIcon={
        <NewCalendar
          style={{
            marginLeft: 5,
          }}
        />
      }
      renderValue={
        selectedDate
          ? typeof selectedDate === 'string'
            ? moment(selectedDate).format(format)
            : selectedDate?.format(format)
          : placeholder
      }
      padding="7px 11px"
      colorInput="#000000de"
      placeholder={placeholder}
      {...props}
    >
      <Popover
        id="date-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ borderRadius: 12, marginTop: 15 }}
      >
        <LocalizationProvider dateAdapter={CustomAdapter}>
          <CustomStaticDatePicker
            displayStaticWrapperAs="desktop"
            value={selectedDate}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            disablePast={disablePast}
            renderInput={(props) => <TextField {...props} />}
            views={views}
          />
        </LocalizationProvider>
        {customRange}
      </Popover>
    </CustomSelect>
  );
};

export default CustomDate;
