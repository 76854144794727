import React, { useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  IconButton
} from '@material-ui/core';
import { DeleteRounded, AddCircle } from '@material-ui/icons';
import InlineTable from '@/module/common/component/InlineTable';
import TableCellFilted from '@/module/common/componentUI/TableCellFilted';
import AutocompleteModal from '@/module/common/component/AutocompleteModal';
import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import { measurementColumn } from '../constants';
import { TableWrapped } from '@/module/company/component/CompanyPaper/styled';
import globalDispatcher from '@/redux/global/actions';
import styled from 'styled-components';

const MeasurementsInformation = ({ formValue, setFormValue, disabled }) => {
  const [hiddenRows, setHiddenRows] = useState({});
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onAddNewTest = data => {
    const lstMeasurements = formValue.lstMeasurements.concat(data);
    setFormValue({ ...formValue, lstMeasurements });
    setShowModal(false);
  };

  const onDeleteTest = index => {
    let lstMeasurements = [...formValue.lstMeasurements];
    lstMeasurements.splice(index, 1);
    setFormValue({ ...formValue, lstMeasurements });
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {measurementColumn.map(item =>
          item.label ? (
            <TableCellFiltedWrapper
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
            />
          ) : (
            <TableCell key={item.stateValue}></TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );

  const TableFooter = () =>
    disabled ? null : (
      <StyledButtonEnhance
        background="#EFDFEB"
        color="#AD5E99"
        backgroundHover="#EFDFEB"
        onClick={() => setShowModal(true)}
      >
        <div className="add-group">
          <span>Add new measurements</span>
          <AddCircle />
        </div>
      </StyledButtonEnhance>
    );

  const renderRow = onDeleteTest => (row, i, onDeleteValue) => (
    <>
      <TableCell>{!hiddenRows.name && row.name}</TableCell>
      <TableCell>{!hiddenRows.stationName && row.stationName}</TableCell>
      <TableCell>{!hiddenRows.code && row.labHeader}</TableCell>
      <TableCell align="right">
        {!disabled && (
          <IconButton
            className="delete-icon"
            onClick={() => (onDeleteTest ? onDeleteTest(i) : onDeleteValue(i))}
          >
            <DeleteRounded />
          </IconButton>
        )}
      </TableCell>
    </>
  );

  return (
    <TableWrapped>
      <InlineTable
        Header={TableHeader}
        data={formValue.lstMeasurements}
        Footer={TableFooter}
        noDataHelperText={
          disabled
            ? 'No data'
            : 'Click button "Add new measurements" to create new one'
        }
        renderRow={renderRow(onDeleteTest)}
      />
      <Dialog maxWidth="lg" open={showModal} onClose={onCloseModal}>
        <AutocompleteModal
          onClose={onCloseModal}
          title="Measurements"
          optionLabel="name"
          textFieldPlaceholder="Select measurement"
          onSubmit={onAddNewTest}
          Header={TableHeader}
          renderRow={renderRow()}
          getListOptions={(...props) =>
            globalDispatcher.getListServiceByCategory('Measurements', ...props)
          }
          existedOption={formValue.lstMeasurements}
        />
      </Dialog>
    </TableWrapped>
  );
};

export const StyledButtonEnhance = styled(ButtonEnhance)`
  display: flex;
  justify-content: center;
  max-width: 220px;
  float: right;
  margin-top: 15px;
  height: 35px;
  padding: 8px 16px;
  .add-group {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: -2px;
    span {
      margin-right: 8px;
    }
  }
`;

export default MeasurementsInformation;
