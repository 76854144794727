import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import {
  PdfIcon,
  Mp3Icon,
  Mp4Icon,
  DocIcon,
  JpgIcon,
  PngIcon
} from '../../../../assets/svg';
import randomColor from 'randomcolor';

const getExtensionFileName = fileName => {
  return fileName.replace(/^.*\./, '');
};

const ContentMessage = ({ data, selectChat }) => {
  const handleDownloadFile = url => {
    window.open(url);
  };

  const checkShowIconTypeFile = fileName => {
    switch (getExtensionFileName(fileName)) {
      case 'pdf':
        return <PdfIcon />;
      case 'doc':
      case 'docx':
        return <DocIcon />;
      case 'mp3':
        return <Mp3Icon />;
      case 'mp4':
        return <Mp4Icon />;
      case 'jpeg':
      case 'jpg':
        return <JpgIcon />;
      case 'png':
        return <PngIcon />;
      default:
        return;
    }
  };

  const handleSelectChat = () => {
    data.position !== 'right' && selectChat(data);
  };
  return (
    <WrapperMessage isMine={data.position === 'right'}>
      {data.type === 'TEXT' && (
        <MessageText
          isMine={data.position === 'right'}
          nameColor={randomColor({
            luminosity: 'dark',
            seed: get(data, 'user.name')
          })}
        >
          {data.position !== 'right' && (
            <div onClick={handleSelectChat} className="username">
              {get(data, 'user.name')}
            </div>
          )}
          <div className="text">{data.text}</div>
        </MessageText>
      )}
      {data.type === 'IMAGE' && (
        <MessageFile>
          {data.position !== 'right' && (
            <div onClick={handleSelectChat} className="username">
              {get(data, 'user.name')}
            </div>
          )}
          <div className="border-photo">
            <img
              onClick={() => handleDownloadFile(data.text)}
              width="150"
              src={data.text}
              alt={data.fileName}
            />
          </div>
        </MessageFile>
      )}
      {data.type === 'FILE' && (
        <>
          <MessageFile>
            {data.position !== 'right' && (
              <div onClick={handleSelectChat} className="username">
                {get(data, 'user.name')}
              </div>
            )}
            <div className="message-file">
              <div
                className="file-container"
                onClick={() => handleDownloadFile(data.text)}
              >
                <span className="icon">
                  {checkShowIconTypeFile(data.fileName)}
                </span>
                <span className="filename">{data.fileName}</span>
              </div>
            </div>
          </MessageFile>
        </>
      )}
    </WrapperMessage>
  );
};

const MessageFile = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  min-width: 15%;
  .username {
    text-transform: uppercase;
    color: black;
    font-weight: 600;
    cursor: pointer;
  }
`;

const MessageText = styled.div`
  max-width: 50%;
  word-break: break-all;
  .text {
    background: ${props => (props.isMine ? '#AD5E99' : '#FFFFFF')};
    color: ${props => (props.isMine ? 'white' : 'black')};
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 14px;
  }
  .username {
    color: ${({ nameColor }) => nameColor || 'black'};
    font-weight: 600;
    cursor: pointer;
  }
`;

const WrapperMessage = styled.div`
  display: flex;
  justify-content: ${props => (props.isMine ? 'flex-end' : 'unset')};
  margin-bottom: 5px;
  .border-photo {
    border: 2.5px solid #ad5e99;
    border-radius: 15px;
    width: fit-content;
    img {
      border-radius: inherit;
      cursor: pointer;
    }
  }
  .message-file {
    background: #ad5e99;
    color: white;
    border-radius: 15px;
    padding: 2px 2px 20px 2px;
    .file-container {
      background: #bd7ead;
      border-radius: inherit;
      padding: 10px;
      display: flex;
      cursor: pointer;
      align-items: center;
      .icon {
        margin-right: 5px;
      }
      .filename {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export default React.memo(ContentMessage);
