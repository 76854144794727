import CustomButton from '../../CustomButton';
import CustomTooltip from '../../CustomTooltip';
import CustomModalTitle from '../CustomModalTitle';
import CorporatePlanImages from './CorporatePlanImages';
import Invoice from './Invoice';
import { FolderIcon } from '@/assets/svg';
import { durationFormatted, momentFormatted } from '@/helpers';
import { StatusComponent } from '@/module/all-screenings/component/StatusComponent';
import {
  COUNSEL_DISPLAY_STATUS,
  COUNSEL_STATUS_COLORS,
} from '@/module/telecounsel-all-counsels/constants';
import teleQueueRoomDispatcher from '@/module/telecounsel-queue/action';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
  title: {
    paddingBottom: 10,
    width: 140,
    verticalAlign: 'top',
    fontWeight: 400,
    color: '#666666',
  },
  text: {
    paddingBottom: 10,
    fontSize: 16,
    verticalAlign: 'top',
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 8,
    color: '#666666',
    width: 24,
    height: 24,
  },
  tableWrapper: {
    width: '100%',
  },
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 800,
  },
})(Dialog);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    maxHeight: '70vh',
  },
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end',
  },
})(DialogActions);

const RowHeaderItem = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,

    fontSize: 20,
    paddingBottom: 10,
    verticalAlign: 'middle',
    color: '#333333',
    fontWeight: 400,
  },
})(Box);

const RowItem = ({ children, title }) => {
  const classes = useStyles();

  if (typeof children === 'object') {
    return (
      <tr>
        <td className={classes.title}>{title}</td>
        <td className={classes.text}>{children}</td>
      </tr>
    );
  }

  return (
    <tr>
      <td className={classes.title}>{title}</td>
      {children === 'None' ? (
        <td className={classes.text} style={{ color: '#999999' }}>
          {children}
        </td>
      ) : (
        <td className={classes.text}>
          <CustomTooltip content={children} />
        </td>
      )}
    </tr>
  );
};

const ModalCounselDetail = ({
  open,
  onClose,
  onSubmit,
  selectedItem,
  modalTitle = 'Counsel details',
}) => {
  const [consultDetail, setConsultDetail] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const classes = useStyles();

  const getConsultDetail = async () => {
    setIsLoadingData(true);
    teleQueueRoomDispatcher.getTeleCounsellingById(
      selectedItem.consultId,
      (result) => {
        setIsLoadingData(false);
        setConsultDetail({
          ...result,
          diagnosis: result.diagnosis || [],
          invoices: result.invoices || [],
          ...selectedItem,
          totalCost: result.totalCost,
        });
      }
    );
  };

  useEffect(() => {
    getConsultDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomDialog
      maxWidth="md"
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomModalTitle onClose={onClose}>{modalTitle}</CustomModalTitle>
      <CustomDialogContent dividers>
        {isLoadingData ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <RowHeaderItem>
                <FolderIcon />
                Client information
              </RowHeaderItem>

              <table className={classes.tableWrapper}>
                <RowItem title="Client">
                  {consultDetail?.patientFullName || '—'}
                </RowItem>
                <RowItem title="Client ID">
                  {consultDetail?.patientShortId || '—'}
                </RowItem>
                <RowItem title="Corporate plan">
                  {consultDetail?.corperatePlan || '—'}
                </RowItem>
              </table>

              {consultDetail?.corperatePlan && (
                <Box mb={2}>
                  <CorporatePlanImages data={consultDetail} />
                </Box>
              )}

              <Divider light style={{ marginBottom: 24 }} />
              <RowHeaderItem>
                <FolderIcon />
                Appointment
              </RowHeaderItem>

              <table className={classes.tableWrapper}>
                <RowItem title="Counsellor">
                  {consultDetail?.doctorFullName || '—'}
                </RowItem>
                <RowItem title="Counselling ID">
                  {consultDetail?.consultShortId || '—'}
                </RowItem>
                <RowItem title="Type">
                  {selectedItem?.counsellingType || '—'}
                </RowItem>
                <RowItem title="Date">
                  {momentFormatted(
                    consultDetail?.consultantDate,
                    'DD/MM/YYYY, h:mm A'
                  ) || '—'}
                </RowItem>
                <RowItem title="Status">
                  {(
                    <StatusComponent
                      status={consultDetail?.consultStatus}
                      filterList={COUNSEL_DISPLAY_STATUS}
                      filterListColor={COUNSEL_STATUS_COLORS}
                    />
                  ) || '—'}
                </RowItem>
                <RowItem title="Duration">
                  {durationFormatted(
                    consultDetail?.startCallTime,
                    consultDetail?.endCallTime
                  ) || '—'}
                </RowItem>
                <RowItem title="Payment method">
                  {[
                    consultDetail?.payWith,
                    consultDetail?.cardNumber
                      ? `(${consultDetail?.cardNumber})`
                      : null,
                  ]
                    .filter((i) => i)
                    .join(' ') || '—'}
                </RowItem>
                {/* <RowItem title="Remarks">
                  {consultDetail?.deliveryStatuses?.some((it) => !isEmpty(it.remarks))
                    ? consultDetail?.deliveryStatuses
                        .map((item) => item.remarks)
                        .join(', ')
                    : '—'}
                </RowItem> */}
              </table>
            </Grid>
            <Grid item xs={6}>
              <Invoice consultDetail={consultDetail} />
            </Grid>
          </Grid>
        )}
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          Close
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalCounselDetail;
