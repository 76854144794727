import {
  DeleteRounded,
  Edit,
  CheckCircleOutline,
  HighlightOff
} from '@material-ui/icons';

const renderListAction = (event, status) => {
  const LIST_ACTIONS = [
    {
      key: 'approve',
      icon: CheckCircleOutline,
      label: 'Approve',
      onClick: () => event('approve', true)
    },
    {
      key: 'edit',
      icon: Edit,
      label: 'Edit',
      onClick: () => event('edit')
    },
    {
      key: 'delete',
      icon: DeleteRounded,
      label: 'Delete',
      onClick: () => event('delete')
    }
  ];

  if (status === 'Approved') {
    LIST_ACTIONS.shift();
    LIST_ACTIONS.unshift({
      key: 'deactive',
      icon: HighlightOff,
      label: 'Reject',
      onClick: () => event('deactive', false)
    });
  }

  return LIST_ACTIONS;
};

export default renderListAction;
