import React from 'react';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormTimePicker from '@/module/common/component/FormTimePicker';

import { TIME_SELECT } from '@/module/setup/constants';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import moment from 'moment';
import { getTimeSlots } from '@/module/setup/utils';
import { isEmpty } from 'lodash';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 4,
    color: '#666666'
  }
})(Typography);

const TimeSlotForm = ({
  errors,
  minTime,
  maxTime,

  setFormValue,
  formValue
}) => {
  const hourStart = moment(minTime).get('h');
  const hourEnd = moment(maxTime).get('h');

  const timeSlotsStart = getTimeSlots(hourStart, hourEnd).filter(obj =>
    moment(obj.value, 'h:mm A').isSameOrBefore(formValue.endTime)
  );
  const timeSlotsEnd = getTimeSlots(hourStart, hourEnd).filter(obj =>
    moment(obj.value, 'h:mm A').isSameOrAfter(formValue.startTime)
  );

  const onChange = key => e => {
    if (!setFormValue) return;

    let value;
    switch (key) {
      case 'startTime':
      case 'endTime':
        value = moment(e.target.value, 'h:mm A');
        break;
      case 'quotaBase':
        if (e.target.value && !e.target.value.match(/\D/)) {
          value = e.target.value;
        } else value = '';
        break;
      default:
        value = e.target.value;
        break;
    }

    setFormValue({
      ...formValue,
      [key]: value
    });
  };

  return (
    <Box px={2.25} py={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomTitleLabel>Template name</CustomTitleLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter name"
            error={!!errors.name}
            helperText={errors.name}
            value={formValue.name}
            onChange={onChange('name')}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTitleLabel>Time interval</CustomTitleLabel>
          <CustomSelectContainer
            isFullWidth
            filterValue={formValue.interval || ''}
            data={TIME_SELECT}
            renderValue={<>{formValue.interval || 'Select time interval'}</>}
            height="48px"
            isMutiple={false}
            onChange={onChange('interval')}
            clearable={false}
          />
          {errors.interval && (
            <Typography color="error">{errors.interval}</Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <CustomTitleLabel>Start time</CustomTitleLabel>
          <CustomSelectContainer
            height="48px"
            data={timeSlotsStart}
            disabled={isEmpty(timeSlotsStart)}
            isMutiple={false}
            renderValue={
              <>
                {formValue?.startTime
                  ? moment(formValue?.startTime)?.format('h:mm A')
                  : 'Select time'}
              </>
            }
            filterValue={moment(formValue?.startTime).format('h:mm A')}
            onChange={onChange('startTime')}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTitleLabel>End time</CustomTitleLabel>
          <CustomSelectContainer
            height="48px"
            data={timeSlotsEnd}
            disabled={isEmpty(timeSlotsEnd)}
            isMutiple={false}
            renderValue={
              <>
                {formValue?.endTime
                  ? moment(formValue?.endTime)?.format('h:mm A')
                  : 'Select time'}
              </>
            }
            filterValue={moment(formValue?.endTime).format('h:mm A')}
            onChange={onChange('endTime')}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTitleLabel>Quota per slot</CustomTitleLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter number"
            error={!!errors.quotaBase}
            helperText={errors.quotaBase}
            value={formValue.quotaBase}
            onChange={onChange('quotaBase')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimeSlotForm;
