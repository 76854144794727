import onDemandDispatcher from '../actions/index';
import { get } from 'lodash';

const initialState = {
  loading: false,
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  },

  listCategoryOnDemand: [],
  listDuration: [],
  listIntensity: []
};

const onDemandReducer = onDemandDispatcher(initialState, {
  [onDemandDispatcher.showHideLoadingAction]: (state, payload) => ({
    loading: payload.loading
  }),
  [onDemandDispatcher.getDurationIntensitySuccess]: (state, payload) => ({
    listDuration: get(payload, 'duration'),
    listIntensity: get(payload, 'intensity')
  }),
  [onDemandDispatcher.getOnDemandListSuccess]: (state, payload) => ({
    list: payload.list,
    paging: {
      ...payload,
      list: undefined
    }
  }),
  [onDemandDispatcher.getCategoryOnDemandSuccess]: (state, payload) => ({
    listCategoryOnDemand: get(payload, 'list')
  })
});

export default onDemandReducer;
