import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CloseCircle, SearchIcon } from '@/assets/svg';
import Divider from '@material-ui/core/Divider';
import { isEmpty } from 'lodash';
import { IconButton } from '@material-ui/core';

const useStyle = makeStyles({
  title: {
    fontWeight: 600,
    fontFamily: 'Lato'
  }
});

const CustomSearchInput = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8
    },

    '& input': {
      minWidth: 335,
      minHeight: 40,
      padding: 0
    }
  }
}))(TextField);

const CustomHeader = ({
  searchKey,
  setSearchKey,
  title,
  isSearch = true,
  searchPlaceholder = 'Search',
  renderButton,
  renderToolbar
}) => {
  const classes = useStyle();

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2.5}
        py={2.25}
        flexWrap="wrap"
        gridGap={16}
        minHeight={76}
      >
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gridGap={16}
        >
          {isSearch && (
            <CustomSearchInput
              variant="outlined"
              placeholder={searchPlaceholder}
              value={searchKey}
              onChange={e => setSearchKey(e.target.value)}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        style={{
                          visibility: !isEmpty(searchKey) ? 'visible' : 'hidden'
                        }}
                        onClick={() => setSearchKey('')}
                      >
                        <CloseCircle
                          height={20}
                          width={20}
                          style={{
                            color: '#666666'
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  </>
                )
              }}
            />
          )}
          {renderButton && renderButton()}
        </Box>
      </Box>

      {renderToolbar && (
        <>
          <Divider light />
          {renderToolbar()}
        </>
      )}
    </Box>
  );
};

export default CustomHeader;
