import React, { useState, useEffect, useMemo } from 'react';

import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/styles';

import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';

import { useHistory, useLocation } from 'react-router-dom';
import { urlLabel } from '@/enum/PermissionEnum';
import { cloneDeep, get, isBoolean, isEmpty } from 'lodash';
import Invoice from '@/module/all-screenings/component/Invoice';

import SelectServices from './SelectServices';
import checkinPatientDispatcher from '@/module/checkin-patient-information/action';
import { packageDispatcher } from '@/module/package-management';
import {
  formatCustomCategories,
  formatData,
  formatExpressTotalTestServices,
  formatMeasurementInSideListTest,
  generateLstTests,
  generateScreeningFieldsListData,
  returnOptionalPackageTests
} from '../../utils';
import upcomingHSDispatcher from '@/module/upcoming-health-screening/action';
import companyDispatcher from '@/module/company/action';
import locationDispatcher from '@/module/setup/action';
import { FORMAT_LIST_TESTS, FIELD_ARRAY_VALUE } from '../../constants';
import { refactorListData as refactorListTestData } from '@/module/setup/constants';
import FollowSelectServices from './FollowSelectServices';

const useStyles = makeStyles({
  container: {
    display: 'flex'
  },

  rightContainer: {
    display: 'flex',
    padding: '16px 20px',
    overflowX: 'auto',
    height: 'calc(100vh - 222px)'
  },

  leftContainer: {
    flex: '1',
    borderRight: '1px solid #F1F1F1',
    height: 'calc(100vh - 222px)'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20
  }
});

const EditPackage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const apptData = get(location, 'state', {});
  const label = get(location, 'label', '');
  const isRegisterPatient = get(apptData, 'isRegisterPatient', false);
  const isAfterRegistration = get(apptData, 'isAfterRegistration', false);
  const isFollowUp = get(apptData, 'isFollowUpAppointment', false);
  const locationId = get(apptData, 'locationId', '');

  const [formValue, setFormValue] = useState({});
  const [packageDetail, setPackageDetail] = useState({});
  const [restrictedTestIds, setRestrictedTestIds] = useState([]);

  const [projectCustomFields, setProjectCustomFields] = useState([]);
  const [lstOptionalTests, setLstOptionalTests] = useState([]);
  const [currentPackage, setCurrentPackage] = useState({});
  const [dataPackageExisted, setDataPackageExisted] = useState({});
  const [projectInfo, setProjectInfo] = useState({});
  const [hasEditPackage, setHasEditPackage] = useState(false);

  const [loading, setLoading] = useState(false);
  const onCancel = () => {
    history.push({
      pathname: `/${urlLabel.aSRegisterPatient}`,
      state: { ...apptData, isViewRegistration: false, hasEditPackage: false },
      label
    });
  };

  const onSubmit = () => {
    if (!isAfterRegistration) {
      upcomingHSDispatcher.setState('editPackageData', formValue);

      history.push({
        pathname: `/${urlLabel.aSRegisterPatient}`,
        state: {
          ...apptData,
          isViewRegistration: false,
          isEditPackage: true,
          hasEditPackage: true
        },
        label
      });
    } else {
      const { id: paxScreeningId, lstConsentIds } = apptData;
      const {
        staffType,
        lstProfiles,
        lstPackages,
        ignoredTests,
        ignoredProfiles
      } = formValue;

      let newListTest = refactorListTestData(formValue);
      newListTest = formatExpressTotalTestServices(newListTest);

      const newScreeningFieldsList = generateScreeningFieldsListData(
        formValue.lstScreeningFields
      );
      const params = {
        lstConsentIds,
        staffType,
        lstTests: newListTest,
        lstProfiles,
        lstPackages,
        ignoredTests,
        ignoredProfiles,
        lstScreeningFields: newScreeningFieldsList
      };
      checkinPatientDispatcher.updateAfterRegistration(
        paxScreeningId,
        params,
        isSuccess => {
          setLoading(false);
          if (isSuccess) {
            upcomingHSDispatcher.setState('editPackageData', formValue);
            history.push({
              pathname: `/${urlLabel.aSRegisterPatient}`,
              state: {
                ...apptData,
                isViewRegistration: false,
                isEditPackage: true,
                hasEditPackage: true
              },
              label
            });
          }
        }
      );
    }
  };

  const handleChangeData = (data, filterKey) => {
    setHasEditPackage(true);

    let allTest = [];
    data.forEach(prj => {
      allTest = allTest.concat(prj.items);
    });
    const selectedData = allTest.filter(test => test.isChecked);

    if (filterKey === 'lstPackages' && isEmpty(selectedData)) {
      setFormValue({
        ...formValue,
        [filterKey]: selectedData,
        ignoredProfiles: [],
        ignoredTests: []
      });
    } else setFormValue({ ...formValue, [filterKey]: selectedData });
  };

  const refactorListData = data => {
    let newLstTests = [];
    for (const [key, value] of Object.entries(data)) {
      if (FORMAT_LIST_TESTS[key] && !isEmpty(value))
        newLstTests.push({
          category: FORMAT_LIST_TESTS[key],
          items: value
        });
    }

    return {
      staffType: formValue.staffType,
      lstPackages: formValue.lstPackages,
      lstProfiles: formValue.lstProfiles,
      lstTests: newLstTests
    };
  };

  useEffect(() => {
    if (apptData.id && apptData.projectId) {
      setLoading(true);
      checkinPatientDispatcher.getData(apptData.id, paxData => {
        const {
          lstPackages,
          lstTests,
          lstProfiles,
          ignoredTests,
          ignoredProfiles,
          lstScreeningFields,
          doneFollowUpTests,
          staffType
        } = paxData;
        const filterScreeningField = formatCustomCategories(
          lstScreeningFields,
          FIELD_ARRAY_VALUE
        );

        let newLstPackages = !isAfterRegistration
          ? apptData?.lstPackages
          : lstPackages;

        let newIgnoredTests = !isAfterRegistration
          ? apptData?.ignoredTests
          : ignoredTests;

        let newIgnoredProfiles = !isAfterRegistration
          ? apptData?.ignoredProfiles
          : ignoredProfiles;

        // Have package
        if (!isEmpty(newLstPackages)) {
          const { id, name, price } = newLstPackages[0];
          let lstOptionalTests = [];
          packageDispatcher.getPackageById(id, result => {
            const {
              lstProfiles,
              lstTests: lstPackageTests,
              totalMandatoryProcedure,
              totalMandatoryRadiology
            } = result;

            // Get list of Profile, Procedure, Radiology
            // Filter unmandatory test
            // Filter Optional test based on ingnored Test
            let lstProcedures = [];
            let lstRadiologies = [];
            let newListTest = [];
            let newTotalMandatoryProcedure = [];
            let newTotalMandatoryRadiology = [];

            if (isFollowUp && !isEmpty(doneFollowUpTests)) {
              newListTest = lstPackageTests.map(item => {
                const holdDoneFollowUpTests = doneFollowUpTests.filter(
                  it => it.category === item.category && !it.isAddon
                );

                return {
                  ...item,
                  items: item.items.filter(
                    test =>
                      holdDoneFollowUpTests.findIndex(
                        item => item.testId === test.id
                      ) === -1
                  )
                };
              });
            } else {
              newListTest = lstPackageTests;
            }
            let newLstTestProcedures = [];
            let newLstTestRadiologies = [];
            let holdDoneFollowUpTestsProcedures = [];
            let holdDoneFollowUpTestsRadiologies = [];

            lstPackageTests.forEach(item => {
              if (item.category === 'Procedures') lstProcedures = item.items;
              if (item.category === 'Radiologies') lstRadiologies = item.items;
            });

            if (isFollowUp) {
              const unMandatoryProcedures = [...lstProcedures]?.filter(
                it => !it.isRequired
              );
              const unMandatoryRadiologies = [...lstRadiologies]?.filter(
                it => !it.isRequired
              );

              if (!isEmpty(doneFollowUpTests)) {
                // Procedure
                holdDoneFollowUpTestsProcedures = unMandatoryProcedures.filter(
                  it => doneFollowUpTests.find(item => item.testId === it.id)
                );

                newLstTestProcedures = unMandatoryProcedures.map(item => {
                  const itemDone = doneFollowUpTests?.find(
                    it => it.testId === item.id && !it.isAddon
                  );

                  let selectedProcedures = {};
                  if (isEmpty(ignoredTests)) {
                    selectedProcedures = ignoredTests?.some(test => {
                      return item.id === test.testId;
                    });
                  } else {
                    selectedProcedures = ignoredTests?.every(test => {
                      return item.id !== test.testId;
                    });
                  }

                  if (itemDone) {
                    return { ...item, isDone: true, isChecked: false };
                  } else if (selectedProcedures)
                    return { ...item, isDone: false, isChecked: true };

                  return { ...item, isDone: false, isChecked: false };
                });
                newLstTestProcedures = newLstTestProcedures.filter(
                  test =>
                    holdDoneFollowUpTestsProcedures.findIndex(
                      item => item.id === test.id
                    ) === -1
                );

                // Radiologies
                holdDoneFollowUpTestsRadiologies = unMandatoryRadiologies.filter(
                  it => doneFollowUpTests.find(item => item.testId === it.id)
                );

                newLstTestRadiologies = unMandatoryRadiologies.map(item => {
                  const itemDone = doneFollowUpTests?.find(
                    it => it.testId === item.id && !it.isAddon
                  );

                  let selectedRadiologies = {};
                  if (isEmpty(ignoredTests)) {
                    selectedRadiologies = ignoredTests?.some(test => {
                      return item.id === test.testId;
                    });
                  } else {
                    selectedRadiologies = ignoredTests?.every(test => {
                      return item.id !== test.testId;
                    });
                  }

                  if (itemDone) {
                    return { ...item, isDone: true, isChecked: false };
                  } else if (selectedRadiologies)
                    return { ...item, isDone: false, isChecked: true };

                  return { ...item, isDone: false, isChecked: false };
                });

                newLstTestRadiologies = newLstTestRadiologies.filter(
                  test =>
                    holdDoneFollowUpTestsRadiologies.findIndex(
                      item => item.id === test.id
                    ) === -1
                );
              } else {
                newLstTestProcedures = unMandatoryProcedures.map(item => {
                  const itemChecked = lstProcedures.find(
                    it => it.id === item.id
                  );

                  if (itemChecked) {
                    return { ...item, isDone: false, isChecked: true };
                  }
                  return { ...item, isDone: false, isChecked: false };
                });

                newLstTestRadiologies = unMandatoryRadiologies.map(item => {
                  const itemChecked = lstRadiologies.find(
                    it => it.id === item.id
                  );

                  if (itemChecked) {
                    return { ...item, isDone: false, isChecked: true };
                  }
                  return { ...item, isDone: false, isChecked: false };
                });
              }

              newTotalMandatoryProcedure =
                totalMandatoryProcedure -
                holdDoneFollowUpTestsProcedures.length;

              newTotalMandatoryRadiology =
                totalMandatoryRadiology -
                holdDoneFollowUpTestsRadiologies.length;
            } else {
              newTotalMandatoryProcedure = totalMandatoryProcedure;
              newTotalMandatoryRadiology = totalMandatoryRadiology;
            }

            lstOptionalTests = returnOptionalPackageTests({
              lstProcedures: newLstTestProcedures,
              lstRadiologies: newLstTestRadiologies,
              lstProfiles,
              ignoredTests: newIgnoredTests,
              ignoredProfiles: newIgnoredProfiles
            });

            setLstOptionalTests(lstOptionalTests);
            if (isFollowUp) {
              setPackageDetail({
                ...result,
                lstTests: newListTest,
                lstProcedures: newLstTestProcedures,
                lstRadiologies: newLstTestRadiologies,
                totalMandatoryProcedure: newTotalMandatoryProcedure,
                totalMandatoryRadiology: newTotalMandatoryRadiology,
                name,
                price,
                holdDoneFollowUpTestsRadiologies,
                holdDoneFollowUpTestsProcedures
              });
            } else {
              setPackageDetail({
                ...result,

                lstProcedures,
                lstRadiologies,
                name,
                price
              });
            }

            setDataPackageExisted({
              ...result,
              ...newLstPackages[0],
              lstTests: newListTest,
              ignoredTests: newIgnoredTests,
              ignoredProfiles: newIgnoredProfiles,
              lstProfiles,
              lstProcedures,
              lstRadiologies,
              totalMandatoryProcedure: newTotalMandatoryProcedure,
              totalMandatoryRadiology: newTotalMandatoryRadiology,
              price
            });
            setCurrentPackage({
              ...result,
              ...newLstPackages[0],
              lstTests: newListTest,
              ignoredTests: newIgnoredTests,
              ignoredProfiles: newIgnoredProfiles,
              lstProfiles,
              lstProcedures,
              lstRadiologies,
              totalMandatoryProcedure: newTotalMandatoryProcedure,
              totalMandatoryRadiology: newTotalMandatoryRadiology,
              price
            });
          });
        }
        if (!isAfterRegistration) {
          const newLstTests = formatData({ lstTests: apptData.lstTests });

          setFormValue({
            ...newLstTests,
            lstScreeningFields: filterScreeningField,
            projectId: apptData.projectId,
            lstProfiles: apptData.lstProfiles,
            lstPackages: apptData.lstPackages,
            ignoredTests: apptData.ignoredTests,
            ignoredProfiles: apptData.ignoredProfiles,
            staffType
          });
        } else {
          let newLstTests = formatMeasurementInSideListTest(lstTests);
          newLstTests = formatData({ lstTests: newLstTests });

          setFormValue({
            ...newLstTests,
            lstScreeningFields: filterScreeningField,
            projectId: apptData.projectId,
            lstProfiles,
            lstPackages,
            ignoredTests,
            ignoredProfiles,
            staffType
          });
        }
        setLoading(false);
      });

      companyDispatcher.getProjectByID(apptData.projectId, result => {
        setProjectInfo(result);
        setProjectCustomFields({
          id: result.id,
          name: result.name,
          items: result.lstProjectCustomFields
        });
      });
      // Hadndle restricted test ids
      locationDispatcher.getDetailLocation(apptData.locationId, result => {
        const { restrictedTestIds } = result;
        setRestrictedTestIds(restrictedTestIds);
      });
    }
  }, [apptData]);
  useEffect(() => {
    if (isEmpty(packageDetail)) return;
    if (isFollowUp) {
      const { lstRadiologies, lstProcedures } = packageDetail;
      const { ignoredTests } = formValue;
      // let newLstRadiologies = cloneDeep(lstRadiologies);
      // let newLstProcedures = cloneDeep(lstProcedures);

      // Filter test restricted by location
      // if (!isEmpty(restrictedTestIds)) {
      //   newLstProcedures = newLstProcedures?.filter(
      //     it => !restrictedTestIds?.includes(it.id)
      //   );
      //   newLstRadiologies = newLstRadiologies?.filter(
      //     it => !restrictedTestIds?.includes(it.id)
      //   );
      // }
      // Get list of Profile, Procedure, Radiology
      // Filter unmandatory test
      // Filter Optional test based on ingnored Test
      let lstOptionalTests = [];
      lstOptionalTests = returnOptionalPackageTests({
        lstProcedures,
        lstRadiologies,
        ignoredTests
      });

      setLstOptionalTests(lstOptionalTests);
    } else {
      if (isEmpty(currentPackage)) {
        const { lstRadiologies, lstProcedures, lstProfiles } = packageDetail;
        const { ignoredProfiles, ignoredTests } = formValue;
        // let newLstRadiologies = [];
        // let newLstProcedures = [];

        // Filter test restricted by location
        // if (!isEmpty(restrictedTestIds)) {
        //   newLstProcedures = lstProcedures?.filter(
        //     it => !restrictedTestIds?.includes(it.id)
        //   );
        //   newLstRadiologies = lstRadiologies?.filter(
        //     it => !restrictedTestIds?.includes(it.id)
        //   );
        // }

        // Get list of Profile, Procedure, Radiology
        // Filter unmandatory test
        // Filter Optional test based on ingnored Test
        let lstOptionalTests = [];
        lstOptionalTests = returnOptionalPackageTests({
          lstRadiologies,
          lstProcedures,
          lstProfiles,
          ignoredTests,
          ignoredProfiles
        });
        setLstOptionalTests(lstOptionalTests);
      } else {
        let {
          lstRadiologies,
          lstProcedures,
          lstProfiles,
          ignoredProfiles,
          ignoredTests
        } = currentPackage;

        // Filter test restricted by location
        // if (!isEmpty(restrictedTestIds)) {
        //   newLstProcedures = newLstProcedures?.filter(
        //     it => !restrictedTestIds?.includes(it.id)
        //   );
        //   newLstRadiologies = newLstRadiologies?.filter(
        //     it => !restrictedTestIds?.includes(it.id)
        //   );
        // }

        // Get list of Profile, Procedure, Radiology
        // Filter unmandatory test
        // Filter Optional test based on ingnored Test
        let lstOptionalTests = [];
        lstOptionalTests = returnOptionalPackageTests({
          lstRadiologies,
          lstProcedures,
          lstProfiles,
          ignoredTests,
          ignoredProfiles
        });
        setLstOptionalTests(lstOptionalTests);
      }
    }
  }, [isFollowUp, formValue, packageDetail, currentPackage, restrictedTestIds]);

  if (!apptData || isEmpty(apptData)) {
    history.push(`/${urlLabel.allScreenings}`);
  }

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = [
      'lstPackages',
      'lstProfiles',
      'lstMeasurements',
      'lstProcedures',
      'lstRadiologies',
      'lstVaccinations',
      'lstReviews',
      'lstTests'
    ];

    // Check package required optional but don't select any test
    // Check don't select any test

    if (!isEmpty(formValue.lstPackages) && !isEmpty(packageDetail)) {
      const {
        lstProcedures,
        lstRadiologies,
        lstProfiles,
        totalMandatoryProcedure,
        totalMandatoryProfile,
        totalMandatoryRadiology,
        holdDoneFollowUpTestsRadiologies,
        holdDoneFollowUpTestsProcedures
      } = packageDetail;
      const { ignoredTests, ignoredProfiles } = formValue;
      let newProcedures = lstProcedures?.filter(
        it => !restrictedTestIds?.includes(it.id)
      );
      let newRadiologies = lstRadiologies?.filter(
        it => !restrictedTestIds?.includes(it.id)
      );
      // Profiles
      const unMandatoryProfiles = cloneDeep(lstProfiles)?.filter(
        it => !it.isRequired
      );
      const selectedProfiles = unMandatoryProfiles?.filter(value => {
        return ignoredProfiles?.some(test => {
          return value.id === test.profileId;
        });
      });
      // Procedures
      const unMandatoryProcedures = cloneDeep(newProcedures)?.filter(
        it => !it.isRequired
      );
      const selectedProcedures = unMandatoryProcedures?.filter(value => {
        return ignoredTests?.some(test => {
          return value.id === test.testId;
        });
      });
      const selectedRequiredProcedures = unMandatoryProcedures?.filter(
        value => {
          return ignoredTests?.every(test => {
            return value.id !== test.testId;
          });
        }
      );

      // Radiology
      const unMandatoryRadiology = cloneDeep(newRadiologies)?.filter(
        it => !it.isRequired
      );
      const selectedRadiology = unMandatoryRadiology?.filter(value => {
        return ignoredTests?.some(test => {
          return value.id === test.testId;
        });
      });

      const selectedRequiredRadiology = unMandatoryRadiology?.filter(value => {
        return ignoredTests?.every(test => {
          return value.id !== test.testId;
        });
      });

      if (isFollowUp) {
        /*
          totalMandatoryProcedure : Total Procedure must be selected
          holdDoneFollowUpTestsProcedures : Procedure (optional) had already done
          selectedRequiredProcedures: Other Procedure (optional) that can be selected
        */

        const checkFollowProcedures = !(totalMandatoryProcedure -
          holdDoneFollowUpTestsProcedures.length ===
        0
          ? totalMandatoryProcedure === selectedRequiredProcedures.length
          : totalMandatoryProcedure - holdDoneFollowUpTestsProcedures.length ===
            selectedRequiredProcedures.length);

        const checkFollowRadiology = !(totalMandatoryRadiology -
          holdDoneFollowUpTestsRadiologies.length ===
        0
          ? totalMandatoryRadiology === selectedRequiredRadiology.length
          : totalMandatoryRadiology -
              holdDoneFollowUpTestsRadiologies.length ===
            selectedRequiredRadiology.length);

        return checkFollowProcedures || checkFollowRadiology;
      } else
        return (
          unMandatoryProfiles?.length - selectedProfiles?.length !==
            totalMandatoryProfile ||
          unMandatoryProcedures?.length - selectedProcedures?.length !==
            totalMandatoryProcedure ||
          unMandatoryRadiology?.length - selectedRadiology?.length !==
            totalMandatoryRadiology
        );
    }

    return !filterKeys.some(key =>
      isBoolean(formValue[key]) ? formValue[key] : !isEmpty(formValue[key])
    );
  }, [formValue, packageDetail]);

  return (
    <>
      <CustomPaperContainer
        header={
          <CustomHeader
            title="Edit package"
            isSearch={false}
            renderButton={() => (
              <Button color="primary" variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            )}
          />
        }
        children={
          <>
            <Paper square variant="outlined" className={classes.container}>
              <Box flexDirection="column" className={classes.leftContainer}>
                {!loading &&
                  (isFollowUp ? (
                    <FollowSelectServices
                      formValue={formValue}
                      setFormValue={setFormValue}
                      projectId={apptData.projectId}
                      packageDetail={packageDetail}
                      dataExist={apptData}
                      restrictedTestIds={restrictedTestIds}
                    />
                  ) : (
                    <SelectServices
                      formValue={formValue}
                      setFormValue={setFormValue}
                      projectId={apptData.projectId}
                      locationId={locationId}
                      handleChangeData={handleChangeData}
                      packageDetail={packageDetail}
                      setPackageDetail={setPackageDetail}
                      projectCustomFields={projectCustomFields}
                      setCurrentPackage={setCurrentPackage}
                      currentPackage={currentPackage}
                      dataPackageExisted={dataPackageExisted}
                      restrictedTestIds={restrictedTestIds}
                    />
                  ))}
              </Box>
              <Box flexDirection="column" className={classes.rightContainer}>
                <Invoice
                  apptData={apptData}
                  lstTests={generateLstTests(formValue)}
                  lstProfiles={formValue?.lstProfiles}
                  lstPackages={formValue?.lstPackages}
                  ignoredProfiles={formValue?.ignoredProfiles}
                  ignoredTests={formValue?.ignoredTests}
                  lstOptionalTests={lstOptionalTests}
                  projectInfo={projectInfo}
                  staffType={apptData?.staffType}
                  subsidy={apptData?.subsidy}
                  billableAmount={
                    hasEditPackage ? null : apptData?.billableAmount
                  }
                />
              </Box>
            </Paper>
            <Toolbar>
              <Box
                width="100%"
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                py={2}
              >
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                  disabled={isEmptyFilterParams}
                >
                  Save changes
                </Button>
              </Box>
            </Toolbar>
          </>
        }
      />
    </>
  );
};

export default EditPackage;
