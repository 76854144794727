import {
  FORMAT_TIME,
  FORMAT_TIME_STAMP,
  MAX_TIME_PICK,
  MIN_TIME_PICK,
  FORMAT_DATEOFF,
  BILLING_TYPE,
} from './constants';
import { map, uniq } from 'lodash';
import moment from 'moment';

const FORMAT_TIME_LT = 'LT'; // 04:00 PM
const FORMAT_DATE = 'YYYY-MM-DD';
const TIME_ZONE_SERVER = 8;
const CURRENT_TIME_ZONE = moment().utcOffset() / 60;

export const formatDate = (value) => {
  if (!value) return;
  return moment(value).format(FORMAT_DATE);
};

export const formatToUTC = (value) => {
  if (!value) return;
  return moment(value).utc().format();
};

// HH:mm
export const formatTimeShowUI = (value) => {
  if (!value) return;
  return moment(value, FORMAT_TIME_STAMP).format(FORMAT_TIME_LT);
};

export const formatTimeShowUILocal = (value) => {
  if (!value) return;
  return moment(value).format(FORMAT_TIME_LT);
};

export const formatTimeToSendServer = (value) => {
  if (!value) return;
  return moment(value, FORMAT_TIME).format(FORMAT_TIME_STAMP);
};

export const parseDataTimeMoment = (value) => {
  if (!value) return '';
  return moment(value, FORMAT_TIME_STAMP);
  // .subtract(TIME_ZONE_SERVER, 'hour')
  // .add(CURRENT_TIME_ZONE, 'hour');
};

export const formatToTimeStamp = (value) => {
  return moment(value).format(FORMAT_TIME_STAMP);
};

export const convertTimeToUTC = (value) => {
  if (!value) return;
  return moment(value, FORMAT_TIME_STAMP).format(FORMAT_TIME);
};

export const transformData = (result) => {
  const newResult = { ...result };
  newResult.dayOfWeeks = map(result.dayOfWeeks, (item) => {
    const { startTime, endTime } = item;
    const startTimeMoment = parseDataTimeMoment(startTime);
    const endTimeMoment = parseDataTimeMoment(endTime);
    item.timeSlots = map(item.timeSlots, (item) => {
      const { startTime, endTime } = item;
      const startTimeMoment = parseDataTimeMoment(startTime);
      const endTimeMoment = parseDataTimeMoment(endTime);
      const interval = endTimeMoment.diff(startTimeMoment, 'minutes');
      return {
        ...item,
        startTime: startTimeMoment,
        endTime: endTimeMoment,
        interval,
      };
    });
    return {
      ...item,
      startTime: startTimeMoment,
      endTime: endTimeMoment,
    };
  });
  let fullDayOfWeeks = dayOfWeeks.map((i) => {
    let existData = newResult?.dayOfWeeks.find(
      (j) => j.dayOfWeek === i.dayOfWeek
    );
    return (
      existData || {
        ...i,
        startTime: MIN_TIME_PICK,
        endTime: MAX_TIME_PICK,
        timeSlots: [],
      }
    );
  });
  newResult.dayOffs = map(result.dayOffs, (item) =>
    moment(item).format(FORMAT_DATEOFF)
  );
  return {
    ...newResult,
    dayOfWeeks: fullDayOfWeeks,
  };
};

export const transformDataTimeSlot = (data) => {
  const newData = map(data, (item) => {
    const { startTime, endTime } = item;
    const startTimeMoment = parseDataTimeMoment(startTime);
    const endTimeMoment = parseDataTimeMoment(endTime);
    const interval = endTimeMoment.diff(startTimeMoment, 'minutes');
    return {
      ...item,
      startTime: startTimeMoment,
      endTime: endTimeMoment,
      interval,
    };
  });

  return newData;
};

export const dayOfWeeks = [
  { dayOfWeek: 1, label: 'monday' },
  { dayOfWeek: 2, label: 'tuesday' },
  { dayOfWeek: 3, label: 'wednesday' },
  { dayOfWeek: 4, label: 'thursday' },
  { dayOfWeek: 5, label: 'friday' },
  { dayOfWeek: 6, label: 'saturday' },
  { dayOfWeek: 0, label: 'sunday' },
];

export const findMaxtimeImportArray = (data, selectedDay) => {
  let selectedData = data.filter((i) => selectedDay.includes(i.dayOfWeek));
  let result = selectedData.map((day) => {
    if (day.timeSlots.length === 0) return day.startTime.unix();
    else return day.timeSlots[day.timeSlots.length - 1].endTime.unix();
  });
  return result;
};

export const errsPosition = (errors) => {
  const errKeys = Object.getOwnPropertyNames(errors);

  const pos = map(errKeys, (key) => (key === 'dayOfWeeks' ? 1 : 0));

  return uniq(pos);
};

export const getTimeSlots = (startHour, endHour, interval = 15) => {
  const timeSlots = [];
  const startTime = moment().startOf('day').hour(startHour);
  const endTime = moment()
    .startOf('day')
    .hour(endHour)
    .add(interval, 'minutes');

  while (startTime.isBefore(endTime)) {
    timeSlots.push({
      key: startTime.format('h:mm A'),
      value: startTime.format('h:mm A'),
    });
    startTime.add(interval, 'minutes');
  }

  return timeSlots;
};
export const getFullTimeInDay = (startHour = 0, endHour = 24, interval = 5) => {
  const timeList = [];
  const startTime = moment().startOf('day').hour(startHour);
  const endTime = moment().startOf('day').hour(endHour);

  while (startTime.isBefore(endTime)) {
    timeList.push({
      key: startTime.format('h:mm A'),
      value: startTime.format('h:mm A'),
    });
    startTime.add(interval, 'minutes');
  }

  return timeList;
};

export const isBillableProject = (billingType) =>
  [BILLING_TYPE.Billable, BILLING_TYPE.Eligible].includes(billingType);
