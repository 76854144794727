import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import CustomSelect from '../../../components/CustomSelect';
import MutipleSelection from '../../../components/CustomSelect/MutipleSelection';
import Enum from '../../../enum/Enum';
import { MenuIcon } from '../../../assets/svg';
import styled from 'styled-components';

const SearchOnDemand = props => {
  const [workoutTypeOpen, setWorkoutTypeOpen] = useState(false);
  const [workoutTypeFilter, setWorkoutTypeFilter] = useState([]);

  const { listCategoryOnDemand } = useSelector(state => state.onDemand);

  let enumWorkoutType = {};
  (listCategoryOnDemand || []).forEach(category => {
    const title = category.title;
    enumWorkoutType = { ...enumWorkoutType, [title]: category.id };
  });

  return (
    <>
      <CustomSelect
        onClick={() => setWorkoutTypeOpen(!workoutTypeOpen)}
        clearable={!isEmpty(workoutTypeFilter)}
        onClear={() => {
          setWorkoutTypeFilter([]);
          props.onSearch({ categoriesId: [] });
        }}
        prefixIcon={
          <WrapperIconSearch>
            <MenuIcon width="13px" height="13px" color="#ccc" />
          </WrapperIconSearch>
        }
        renderValue={
          <>
            {workoutTypeFilter && workoutTypeFilter.length
              ? `Workout Type Selected (${workoutTypeFilter.length})`
              : 'Workout Type'}
          </>
        }
      >
        <MutipleSelection
          value={workoutTypeFilter}
          data={Enum(enumWorkoutType)}
          open={workoutTypeOpen}
          onChange={e => {
            props.onSearch({ categoriesId: e.target.value });
            setWorkoutTypeFilter(e.target.value);
          }}
        />
      </CustomSelect>
    </>
  );
};

const WrapperIconSearch = styled.div`
  margin-right: 5px;
`;

export default SearchOnDemand;
