import MomentUtils from '@material-ui/pickers/adapter/moment';

export default function CustomAdapter(options) {
  const adapter = new MomentUtils(options);

  const constructDayObject = day => ({
    charAt: () => day.charAt(0).toUpperCase() + day.slice(1)
  });

  return {
    ...adapter,
    getWeekdays() {
      const customWeekdays = adapter.getWeekdays();
      return customWeekdays.map(day => constructDayObject(day));
    }
  };
}
