import CustomTooltip from '../CustomTooltip';
import { DocumentIcon } from '@/assets/svg';
import { CloseCircleFilled } from '@ant-design/icons';
import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  height: 80px;
  width: 80px;
  position: relative;
  margin-right: 10px;
  transition: all 1s;

  &:hover .remove-attatchment-button {
    display: initial;
  }

  .remove-attatchment-button {
    color: #666666;
    display: none;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 3px;
    right: 0;
    cursor: pointer;
  }
`;

const DocumentContainer = styled.div`
  position: relative;
  margin-right: 10px;
  transition: all 1s;

  &:hover .remove-attatchment-button {
    display: initial;
  }

  .remove-attatchment-button {
    color: #666666;
    display: none;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 3px;
    right: 0;
    cursor: pointer;
  }
`;

export const PreSendImage = ({ image, fileSource, removeAttatchment }) => {
  return (
    <ImageContainer>
      <CloseCircleFilled
        className="remove-attatchment-button"
        onClick={removeAttatchment}
      />
      <img
        src={fileSource}
        height={80}
        width={80}
        style={{ objectFit: 'cover', borderRadius: 8 }}
        alt={image.name}
      />
    </ImageContainer>
  );
};

export const PreSendDoc = ({ doc, removeAttatchment }) => {
  return (
    <DocumentContainer>
      <div>
        <CloseCircleFilled
          className="remove-attatchment-button"
          onClick={removeAttatchment}
        />

        <Box
          width={80}
          height={80}
          bgcolor="#f1f1f1"
          borderRadius={8}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          color="#666666"
          fontWeight="600"
          p={1}
        >
          <DocumentIcon color="#999999" />
          <CustomTooltip content={doc.name} style={{ textAlign: 'center' }} />
        </Box>
      </div>
    </DocumentContainer>
  );
};
