import React from 'react';
import {
  TextField,
  IconButton,
  withStyles,
  Box,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';

import { TYPE_MODAL } from '@/module/common/constants/typeModal';
import { CheckboxActive, CheckboxDefault, CloseIcon } from '@/assets/svg';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
      paddingLeft: 0
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    },

    '& p': {
      marginTop: 1
    },

    '&.other-value': {
      pointerEvents: 'none',
      color: '#999999'
    }
  }
})(TextField);

const AnswerItem = ({
  onChangeAnswer,
  value,
  answerPos,
  typeModal,
  hasOther,
  additionalNote,
  hasAdditional,
  errors
}) => {
  return (
    <React.Fragment>
      {hasOther && value === 'Others' ? (
        <>
          <Box display="flex" alignItems="center" gridGap={8}>
            <DragIndicator />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gridGap={8}
              width="100%"
            >
              <CustomTextField
                placeholder={'Option ' + (answerPos + 1)}
                variant="outlined"
                value={value}
                fullWidth
                className="other-value"
                // style={{ pointerEvents: 'none', color: '#333333' }}
              />
            </Box>
            <IconButton
              onClick={e => onChangeAnswer(TYPE_MODAL.Delete, answerPos, e)}
            >
              <CloseIcon style={{ color: '#8F9BA9' }} />
            </IconButton>
          </Box>
          <Box my={1} pl={4} pr={5}>
            <FormControlLabel
              className="custom-checkbox"
              style={{ marginBottom: 8 }}
              control={
                <Checkbox
                  checked={hasAdditional}
                  color="primary"
                  icon={<CheckboxDefault />}
                  checkedIcon={<CheckboxActive />}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={e =>
                    onChangeAnswer(
                      TYPE_MODAL.EditCheck,
                      answerPos,
                      e,
                      'hasAdditional'
                    )
                  }
                />
              }
              label="Additional answer"
            />
            <CustomTextField
              fullWidth
              placeholder="Enter your hint text"
              multiline
              rows={2}
              value={additionalNote}
              variant="outlined"
              disabled={!hasAdditional}
              onChange={e =>
                onChangeAnswer(
                  TYPE_MODAL.EditAddition,
                  answerPos,
                  e,
                  'additionalNote'
                )
              }
            />
          </Box>
        </>
      ) : (
        <Box display="flex" alignItems="center" gridGap={8}>
          <DragIndicator />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gridGap={8}
            width="100%"
          >
            <CustomTextField
              placeholder={'Option ' + (answerPos + 1)}
              variant="outlined"
              value={value}
              fullWidth
              onChange={e =>
                onChangeAnswer(TYPE_MODAL.Edit, answerPos, e, 'answer')
              }
            />
          </Box>

          <IconButton
            onClick={e => onChangeAnswer(TYPE_MODAL.Delete, answerPos, e)}
          >
            <CloseIcon style={{ color: '#8F9BA9' }} />
          </IconButton>
        </Box>
      )}
    </React.Fragment>
  );
};

export default AnswerItem;
