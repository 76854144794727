import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  withStyles,
  Button,
  IconButton
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { GetApp } from '@material-ui/icons';
import customToast from '@/new-components/CustomNotification';

// import TableContainer from '../../components/Table/TableContainer';
import TableContainer from '@/components/Table/TableContainer';
import TableCellFilter from '@/module/common/componentUI/TableCellFilted';
import livestreamAction from '../../actions';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14
  }
}))(Tooltip);

const tableColumn = [
  {
    label: 'Name',
    stateValue: 'fullName',
    sortValue: 'FullName'
  },
  {
    label: 'Mobile',
    stateValue: 'mobile',
    sortValue: 'Mobile'
  },
  {
    label: 'Email',
    stateValue: 'email',
    sortValue: 'Email'
  },
  {
    label: 'Duration',
    stateValue: 'duration'
    // sortValue: 'Duration'
  },
  {
    label: 'Time join',
    stateValue: 'joinTime',
    sortValue: 'JoinTime'
  },
  {
    label: 'Time left',
    stateValue: 'leftTime',
    sortValue: 'LeftTime'
  }
];

const SessionDetail = props => {
  const {
    location: { state }
  } = props;

  const { sessionDetail } = useSelector(state => state.livestream);

  const { title, sessionId, categoryTitle, trainerName, date, list = [] } =
    sessionDetail || {};

  const [txtSearch, setSearch] = useState('');
  const [paging, setPaging] = useState({
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  });
  const [sortOption, setSortOption] = useState({
    by: 'JoinTime',
    dir: 'desc'
  });

  const [hiddenRows, setHiddenRows] = useState({});

  useEffect(() => {
    return () => {
      livestreamAction.resetListSessionDetail();
    };
  }, []);

  const renderKeyList = useMemo(
    () => list.map((item, i) => ({ ...item, id: `${item.id}_${i}` })),
    [list]
  );

  const TableCellFilterWrapper = ({ ...props }) => {
    return (
      <TableCellFilter
        {...{
          hiddenRows,
          setHiddenRows
        }}
        {...props}
      />
    );
  };

  useEffect(() => {
    if (!isEmpty(sessionDetail)) {
      setPaging({
        ...paging,
        totalItem: sessionDetail.totalItem,
        totalPage: sessionDetail.totalPage
      });
    }
  }, [sessionDetail]);

  if (!state) return <Redirect to="/livestream-management" />;

  const { id } = state;

  const fetchData = (search, paging, sort) => {
    livestreamAction.getListDetailSession({
      id,
      paging,
      search,
      ...sort
    });
  };

  const queryData = (currentSearch, currentPaging, sortOption) => {
    setPaging(currentPaging);
    setSearch(currentSearch);
    setSortOption(sortOption);
    fetchData(currentSearch, currentPaging, sortOption);
  };

  const exportExcel = () => {
    if (list.length === 0) {
      return customToast(
        'info',
        'There is no data available to export the csv file'
      );
    }
    const { pageIndex, pageSize } = paging;
    livestreamAction.getExportDetailByID(id, {
      search: txtSearch,
      pageIndex,
      pageSize,
      ...sortOption
    });
  };

  const titleTable = (
    <div style={{ display: 'flex' }}>
      {sessionId && (
        <LightTooltip title="Session Id" placement="top">
          <Button color="primary">{sessionId}</Button>
        </LightTooltip>
      )}

      {title && (
        <LightTooltip title="Session Name" placement="top">
          <Button color="primary">{title}</Button>
        </LightTooltip>
      )}
      {categoryTitle && (
        <LightTooltip title="Workout Type" placement="top">
          <Button color="primary">{categoryTitle}</Button>
        </LightTooltip>
      )}
      {trainerName && (
        <LightTooltip title="Trainer Name" placement="top">
          <Button color="primary">{trainerName}</Button>
        </LightTooltip>
      )}
      {date && (
        <LightTooltip title="Date" placement="top">
          <Button color="primary">{moment(date).format('DD-MM-YYYY')}</Button>
        </LightTooltip>
      )}
    </div>
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(({ label, stateValue, sortValue }) => {
          return (
            <TableCellFilterWrapper
              label={label}
              stateValue={stateValue}
              isSort={stateValue !== 'duration'}
              key={label}
              sortOption={sortOption}
              sortBy={sortValue}
              onSortChange={() => onSortChange(sortValue)}
            >
              {label}
            </TableCellFilterWrapper>
          );
        })}
        <TableCell align="right">
          <IconButton
            style={{ marginBottom: -12 }}
            aria-label="export"
            onClick={exportExcel}
          >
            <GetApp />
          </IconButton>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const onSortChange = name => {
    let newSortOption = {
      by: name,
      dir: sortOption.dir !== 'asc' ? 'asc' : 'desc'
    };
    setSortOption(newSortOption);
  };

  return (
    <TableContainer
      title={titleTable}
      data={renderKeyList}
      header={TableHeader}
      searchKey={txtSearch}
      setSearchKey={setSearch}
      query={queryData}
      sortOption={sortOption}
      paging={paging}
      renderRow={(row, i) => (
        <React.Fragment key={`${row.id}_${i}`}>
          <TableCell>{!hiddenRows.fullName && row.fullName}</TableCell>
          <TableCell>{!hiddenRows.mobile && row.mobile}</TableCell>
          <TableCell>{!hiddenRows.email && row.email}</TableCell>
          <TableCell>{!hiddenRows.duration && row.duration}</TableCell>
          <TableCell>
            {!hiddenRows.joinTime &&
              row.joinTime &&
              moment(row.joinTime).format('lll')}
          </TableCell>
          <TableCell>
            {!hiddenRows.leftTime &&
              row.leftTime &&
              moment(row.leftTime).format('lll')}
          </TableCell>
          <TableCell />
        </React.Fragment>
      )}
    />
  );
};

SessionDetail.propTypes = {
  match: PropTypes.object.isRequired
};

export default SessionDetail;
