export const COLUMN_ECOMMERCE_MANAGEMENT = [
  {
    label: 'Product name',
    stateValue: 'name',
    width: 200,
  },
  {
    label: 'Product image',
    stateValue: 'image',
    width: 140,
  },
  {
    label: 'Category',
    stateValue: 'category',
    width: 200,
  },
  {
    label: 'Description',
    stateValue: 'description',
  },
  {
    label: 'Consultation',
    stateValue: 'consultation',
    width: 100,
  },
  {
    label: 'Price',
    stateValue: 'price',
    width: 100,
  },
  {
    label: 'Status',
    stateValue: 'status',
    width: 100,
  },
  {
    label: 'Action',
    width: 100,
  },
];

export const TYPE_MODAL = {
  Edit: 'Edit',
  Create: 'Create',
};

export const ECOMMERCE_STATUSES_FILTER = [
  {
    value: 'Active',
    key: 'Active',
  },
  {
    value: 'Inactive',
    key: 'InActive',
  },
];

export const ECOMMERCE_STATUS = {
  Active: 'Active',
  InActive: 'Inactive',
};

export const ECOMMERCE_STATUS_COLORS = {
  [ECOMMERCE_STATUS.InActive]: {
    txt: '#333333',
    background: '#f1f1f1',
  },
  [ECOMMERCE_STATUS.Active]: {
    txt: '#0B8E5E',
    background: '#15a47029',
  },
};

export const STEPS_CONTROL = {
  back: 'Back',
  next: 'Next',
  finish: 'Create',
};

export const COLUMN_BUNDLE_PRICING = [
  {
    label: 'Bundle quantity',
    stateValue: 'degree',
    width: '40%',
  },
  {
    label: 'Price',
    stateValue: 'institution',
  },
  {
    label: 'Action',
    width: 100,
  },
];
