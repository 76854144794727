const handleUpdateStatusClinicSuccess = (list, payload) => {
  const { id, status } = payload;
  return list.map(item => {
    if (item.id === id) {
      return {
        ...item,
        status
      };
    }
    return item;
  });
};

const updateOperatingSuccess = (list, payload) => {
  const { clinicId, operatingHours } = payload;
  return list.map(item => {
    if (item.id === clinicId) {
      return { ...item, operationHours: operatingHours };
    }
    return item;
  });
};

export { handleUpdateStatusClinicSuccess, updateOperatingSuccess };
