import React from 'react';
import { DeleteRounded, CreateRounded } from '@material-ui/icons';
import { MenuItem, ListItemIcon } from '@material-ui/core';
import styled from 'styled-components';
import { TYPE_MODAL } from '../constants';

const MenuAction = ({ onShowModal, onShowConfirmDelete }) => {
  let listActions = [
    {
      key: 'edit',
      icon: CreateRounded,
      label: 'Edit',
      onClick: () => onShowModal(TYPE_MODAL.Edit)
    },
    {
      key: 'delete',
      icon: DeleteRounded,
      label: 'Delete',
      onClick: () => onShowConfirmDelete()
    }
  ];

  return listActions.map(action => {
    const Icon = action.icon;
    return (
      <MenuItemAction
        key={action.key}
        onClick={() => {
          action.onClick();
        }}
      >
        <ListItemMenuIcon style={{ minWidth: 34 }}>
          <Icon className="icon" />
        </ListItemMenuIcon>
        {action.label}
      </MenuItemAction>
    );
  });
};

const MenuItemAction = styled(MenuItem)`
  display: flex;
  align-items: center;
  color: #192637;
`;

const ListItemMenuIcon = styled(ListItemIcon)`
  .icon {
    color: #cacfd3;
  }
  svg {
    color: #cacfd3;
  }
`;

export default MenuAction;
