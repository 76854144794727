import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { ArrowDownCircle, MailIcon } from '@/assets/svg';

const CustomButton = withStyles({
  root: {
    minWidth: 60
  },
  label: {
    fontWeight: 600
  }
})(Button);

const GroupToolbarSelectedCheckbox = ({ selected, setSelected, onSubmit }) => {
  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" fontSize={16}>
          {selected?.length} {selected?.length > 1 ? 'projects' : 'project'}{' '}
          selected
          <CustomButton color="primary" onClick={() => setSelected([])}>
            Clear
          </CustomButton>
        </Box>
        <Box display="flex" gridGap={12}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<MailIcon width={20} height={20} />}
            onClick={() => onSubmit('email-all')}
          >
            Email all
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowDownCircle width={20} height={20} />}
            onClick={() => onSubmit('export-all')}
          >
            Download all
          </Button>
        </Box>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbarSelectedCheckbox;
