import { synthesize } from 'redux-dispatcher';
import { uploadToFirebaseAsync } from '../../../firebase';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, filterOption = {}) => async ({ Api, getState }) => {
    let pagingData = paging || getState().studio.paging;
    let { result, status } = await Api.post(`Studios/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption
    });
    status === 200 && studioDispatcher.getDataSuccess(result);
  },
  addStudio: (
    rawData,
    searchKey,
    filterOption = {},
    callback,
    onFinish
  ) => async ({ Api, customToast, getState }) => {
    let data = {
      ...rawData
    };
    let storageUrl;
    if (data.imageUrls[0]?.constructor === File) {
      storageUrl = await uploadToFirebaseAsync({
        id: 'studio',
        uploadedFile: {
          file: data.imageUrls[0]
        }
      });
      data.imageUrls[0] = storageUrl;
    }
    let { status } = await Api.post(`Studios`, {
      ...data
    });
    if (status === 200) {
      let { paging } = getState().studio;
      studioDispatcher.getData(searchKey, paging, filterOption);
      customToast('success', 'Success');
      callback();
    }
    onFinish();
  },
  editStudio: (
    rawData,
    searchKey,
    filterOption = {},
    callback,
    onFinish
  ) => async ({ Api, customToast, getState }) => {
    let data = {
      ...rawData
    };
    let storageUrl;
    if (data.imageUrls[0]?.constructor === File) {
      storageUrl = await uploadToFirebaseAsync({
        id: 'studio',
        uploadedFile: {
          file: data.imageUrls[0]
        }
      });
      data.imageUrls[0] = storageUrl;
    }
    let { status } = await Api.put(`Studios/${data.id}`, {
      ...data
    });
    if (status === 200) {
      let { paging } = getState().studio;
      studioDispatcher.getData(searchKey, paging, filterOption);
      customToast('success', 'Success');
      callback();
    }
    onFinish();
  },
  activeStudio: (data, searchKey, filterOption = {}) => async ({
    Api,
    customToast,
    getState
  }) => {
    let { status } = await Api.put(`Studios/${data.id}`, {
      ...data
    });
    if (status === 200) {
      let { paging } = getState().studio;
      studioDispatcher.getData(searchKey, paging, filterOption);
      customToast('success', 'Success');
    }
  },
  deleteStudio: (data, searchKey, filterOption = {}) => async ({
    Api,
    customToast,
    getState
  }) => {
    let { status } = await Api.delete(`Studios/${data.id}`);
    if (status === 200) {
      let { paging } = getState().studio;
      studioDispatcher.getData(searchKey, paging, filterOption);
      customToast('success', 'Success');
    }
  }
};

const studioDispatcher = synthesize('studio', mapDispatchToAC);
export default studioDispatcher;
