import React from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Card,
  Chip,
  CircularProgress,
  Typography,
  withStyles
} from '@material-ui/core';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { formatTotalPrice } from '@/module/checkin-patient-information/util';
import { ORDER_INVOCE_TESTS } from '@/module/all-screenings/constants';
import CustomTooltip from '@/new-components/CustomTooltip';
import { isBillableProject } from '@/module/setup/utils';
import { useCalculateCost } from '@/module/all-screenings/component/useCalculateCost';

const CustomCard = withStyles({
  root: {
    boxShadow: 'none',
    borderRadius: 10,
    background: 'linear-gradient(to bottom, #FEFFFF 0%, #EDF7FF 100%)',
    position: 'relative',
    width: props => (props.width ? props.width : 'unset')
  }
})(Card);

const CustomBox = withStyles({
  root: {
    '& .divider': {
      height: 1,
      width: '100%',
      marginTop: 20,
      backgroundImage:
        'linear-gradient(to right, #3776ab 50%, transparent 50%)',
      backgroundPosition: 'top',
      backgroundSize: '10px 1px',
      backgroundRepeat: 'repeat-x'
    },

    '&:last-child .divider': {
      display: 'none'
    }
  }
})(Box);

const CustomHeaderBox = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.light,

    '& .title': {
      fontWeight: 800,
      fontFamily: 'Lato',
      fontSize: 20,

      color: theme.palette.secondary.main
    }
  }
}))(Box);

const Invoice = ({
  apptData,
  projectInfo,
  paymentStatus,
  lstTests,
  lstProfiles,
  lstPackages,
  ignoredProfiles,
  ignoredTests,
  lstOptionalTests,
  staffType,
  subsidy,
  billableAmount
}) => {
  const { paxCost, isPaxCostCalculating } = useCalculateCost({
    apptData,
    projectInfo,
    lstTests,
    lstProfiles,
    lstPackages,
    ignoredProfiles,
    ignoredTests,
    staffType,
    subsidy,
    billableAmount
  });

  const renderStaffTypeLabel = data => {
    if (data?.staffValueType === 'Percent') return ` (${data?.subsidy}%) `;
    return '';
  };

  return (
    <Box>
      <CustomCard color="#e2f2ffb3" width={360}>
        <CustomHeaderBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={1.625}
          px={2.5}
        >
          <Typography className="title" style={{ color: '#333333' }}>
            Invoice
          </Typography>
          {paymentStatus && (
            <Chip color="primary" size="small" label={paymentStatus} />
          )}
        </CustomHeaderBox>

        <Box maxHeight={400} overflow="auto">
          {isEmpty(lstPackages) && isEmpty(lstTests) && isEmpty(lstProfiles) && (
            <Box pt={3} px={2.5}>
              <BigText>No services selected.</BigText>
            </Box>
          )}
          {!isEmpty(lstPackages) && (
            <CustomBox pt={2.5} px={2.5}>
              <SmallText>Package</SmallText>
              <Box pt={2.5} display="flex" justifyContent="space-between">
                <BigText>{lstPackages[0]?.name}</BigText>
                {lstPackages[0]?.price ? (
                  <LightText style={{ color: '#333333' }}>
                    {formatTotalPrice(lstPackages[0]?.price, '$')}
                  </LightText>
                ) : (
                  <LightText style={{ fontWeight: 500 }}>Free</LightText>
                )}
              </Box>
              {!isEmpty(lstOptionalTests) && (
                <>
                  <BoldText m="20px 0 10px">Optional tests</BoldText>
                  {lstOptionalTests.map(test => (
                    <BorderRow key={test.id}>
                      <BigText>{test?.name}</BigText>
                      {test?.price ? (
                        <LightText>
                          {formatTotalPrice(test?.price, '$')}
                        </LightText>
                      ) : (
                        <LightText style={{ fontWeight: 500 }}>Free</LightText>
                      )}
                    </BorderRow>
                  ))}
                </>
              )}

              <div className="divider"></div>
            </CustomBox>
          )}

          {(!isEmpty(lstTests) || !isEmpty(lstProfiles)) && (
            <CustomBox pt={2.5} px={2.5}>
              <SmallText>Add-Ons</SmallText>
              {!isEmpty(lstProfiles) && (
                <>
                  <BoldText m="20px 0 10px">Profiles</BoldText>
                  {lstProfiles.map(test => (
                    <BorderRow key={test.id}>
                      <BigText>
                        <CustomTooltip content={test?.name} />
                      </BigText>

                      {test?.price ? (
                        <LightText>
                          {formatTotalPrice(test?.price, '$')}
                        </LightText>
                      ) : (
                        <LightText style={{ fontWeight: 500 }}>Free</LightText>
                      )}
                    </BorderRow>
                  ))}
                  <div className="divider"></div>
                </>
              )}
              {!isEmpty(lstTests) &&
                lstTests
                  .sort(
                    (a, b) =>
                      ORDER_INVOCE_TESTS[a.category] -
                      ORDER_INVOCE_TESTS[b.category]
                  )
                  .map(test => (
                    <>
                      <BoldText key={test.id} m="20px 0 10px">
                        {test.category}
                      </BoldText>
                      {test?.items?.map(item => (
                        <BorderRow key={item.id}>
                          <BigText>
                            <CustomTooltip content={item?.name} />
                          </BigText>

                          {item?.price ? (
                            <LightText>
                              {formatTotalPrice(item?.price, '$')}
                            </LightText>
                          ) : (
                            <LightText style={{ fontWeight: 500 }}>
                              Free
                            </LightText>
                          )}
                        </BorderRow>
                      ))}
                    </>
                  ))}
              <div className="divider"></div>
            </CustomBox>
          )}
        </Box>

        <Box p={2.5}>
          <DividerBox />
          <Row m="12px 0">
            <BigText>Total</BigText>
            <StrongText>{formatTotalPrice(paxCost?.subTotal, '$')}</StrongText>
          </Row>
          <Row m="12px 0" hidden={isBillableProject(projectInfo?.billingType)}>
            <BigText>Staff discount {renderStaffTypeLabel(staffType)}</BigText>
            <StrongText>-{formatTotalPrice(paxCost?.discount, '$')}</StrongText>
          </Row>
          <Row m="12px 0" hidden={!isBillableProject(projectInfo?.billingType)}>
            <BigText>Deduction</BigText>
            <StrongText>
              -{formatTotalPrice(paxCost?.billableAmount, '$')}
            </StrongText>
          </Row>
          <DividerBox />
          <Row m="20px 0">
            <PurpleText color={'#ad5e99'}>TOTAL AMOUNT</PurpleText>
            <PurpleText color={'#ad5e99'}>
              {formatTotalPrice(paxCost?.totalCost, '$')}
            </PurpleText>
          </Row>
        </Box>

        <Backdrop
          open={isPaxCostCalculating}
          style={{
            zIndex: 1,
            position: 'absolute',
            backgroundColor: 'rgba(255, 255, 255, 0.3)'
          }}
        >
          <CircularProgress color="primary" size={54} />
        </Backdrop>
      </CustomCard>
    </Box>
  );
};

Invoice.propTypes = {
  apptData: PropTypes.object,
  projectInfo: PropTypes.object,
  lstTests: PropTypes.array,
  lstProfiles: PropTypes.array,
  lstPackages: PropTypes.array,
  ignoredProfiles: PropTypes.array,
  ignoredTests: PropTypes.array,
  lstOptionalTests: PropTypes.array,
  staffType: PropTypes.object,
  paymentStatus: PropTypes.string
};

Invoice.defaultProps = {
  apptData: {},
  projectInfo: {},
  lstTests: [],
  lstProfiles: [],
  lstPackages: [],
  ignoredProfiles: [],
  ignoredTests: [],
  lstOptionalTests: []
};

export default Invoice;
const StrongText = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: 700;
  margin: ${p => p.m};
`;

const SmallText = styled.div`
  margin: ${p => p.m};
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  // text-transform: uppercase;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: ${p => p.m};
`;

const BorderRow = styled(Row)`
  padding: 8px 0 8px 12px;
  border-left: 2px solid #3776ab;
`;

const PurpleText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${p => p.color};
`;

const DividerBox = styled.div`
  height: 1px;
  width: 100%;
  margin: ${p => p.m};
  background: #3776ab;
`;

const LightText = styled.div`
  color: #999;
  font-size: 16px;
  font-weight: 700;
  margin: ${p => p.m};
`;
const BigText = styled.div`
  font-size: 16px;
  margin: ${p => p.m};
  max-width: 250px;
  word-break: break-all;
`;

const BoldText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin: ${p => p.m};
`;
