import React from 'react';
import { Typography, MenuItem } from '@material-ui/core';
import MutipleSelection from '../../../components/CustomSelect/MutipleSelection';
import { DoneRounded } from '@material-ui/icons';

const AssignMenu = ({
  title = '',
  open,
  setOpen,
  channelData,
  onAssigneeChanged
}) => {
  let admin = channelData.Admin;
  let coHost = channelData.CoHosts || [];
  let currentHost = channelData.Host;
  return (
    <MutipleSelection
      open={open}
      value={[]}
      asolutePosition={false}
      onClose={() => {
        setOpen(false);
      }}
      onChange={() => null}
      style={{
        minWidth: 200,
        width: 200
      }}
    >
      <Typography style={{ margin: '8px 16px 8px 16px', color: '#8F9BA9' }}>
        {title}
      </Typography>
      <MenuItem
        key={admin.Id}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Source Sans Pro',
          color: '#192637',
          justifyContent: 'space-between'
        }}
        onClick={() => {
          if (currentHost.Id === admin.Id) return;
          onAssigneeChanged(admin.Id);
        }}
      >
        {'Me (Admin)'}
        {currentHost.Id === admin.Id && (
          <DoneRounded style={{ color: '#62CAA4', fontSize: 14 }} />
        )}
      </MenuItem>
      {coHost.map(d => (
        <MenuItem
          key={d.Id}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Source Sans Pro',
            color: '#192637',
            justifyContent: 'space-between'
          }}
          onClick={() => {
            if (currentHost.Id === d.Id) return;
            onAssigneeChanged(d.Id);
          }}
        >
          {generateName(d) + ' (co-host)'}
          {currentHost.Id === d.Id && (
            <DoneRounded style={{ color: '#62CAA4', fontSize: 14 }} />
          )}
        </MenuItem>
      ))}
    </MutipleSelection>
  );
};

const generateName = data =>
  [data.FirstName, data.LastName].filter(d => d).join(', ');

export default AssignMenu;
