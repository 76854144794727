import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  ClearRounded,
  CloudUpload,
  FileCopy,
  DeleteOutline
} from '@material-ui/icons';
import customToast from '@/new-components/CustomNotification';
import { Upload } from 'antd';
import {
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  FormHelperText,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip
} from '@material-ui/core';
import {
  TYPE_MODAL,
  FORM_VALUE_LIVESTREAM,
  LIVE_STREAM_OPTION_VALUE,
  LIST_REQUIRED_LIVESTREAM_UPLOAD,
  LIST_REQUIRED_LIVESTREAM_VIDEO,
  MENU_PROPS
} from '../../constants';
import { ButtonEnhance } from '../../../common/componentUI/commonStyleComponents';
import { SelectInlineEnhance } from '../../../common/component';
import { STREAM_OPTIONS } from '../../constants';
import { isEmpty, get, map } from 'lodash';
import { useSelector } from 'react-redux';
import { LinkIcon } from '../../../../assets/svg';
import LoadingButton from '../../../../components/LoadingButton';
import MultipleAutocomplete from '../../../common/component/MultipleAutocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { uploadToFirebaseAsync } from '../../../../firebase';

const useStyles = makeStyles({
  root: {
    marginBottom: 0
  }
});

const FileMp3Item = ({ files, onRemoveFile, isView, loadingUpload }) => {
  const maximumFileName = 30;

  const renderFileName = (fileName = '') => {
    if (fileName.length > maximumFileName) {
      return (
        <Tooltip
          title={<span style={{ fontSize: 14 }}>{fileName}</span>}
          placement="top"
        >
          <ListItemText
            primary={fileName.substring(0, maximumFileName) + '...'}
          />
        </Tooltip>
      );
    }
    return <ListItemText primary={fileName} />;
  };

  return (
    <List style={{ height: 420, overflow: 'auto' }}>
      {files.map(file => (
        <ListItem key={file.name}>
          <ListItemIcon>
            <FileCopy />
          </ListItemIcon>
          {renderFileName(file.name)}
          {!isView && (
            <ListItemSecondaryAction>
              <IconButton
                disabled={loadingUpload}
                edge="end"
                aria-label="remove"
                onClick={() => onRemoveFile(file.name)}
              >
                <DeleteOutline color="error" />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  );
};

const LivestreamFormModalPage = ({
  onCloseModal,
  typeModal,
  livestreamDetail,
  onSaveData,
  backToBasicForm,
  notAdminView
}) => {
  const classes = useStyles();
  const [formValue, setFormValue] = useState(FORM_VALUE_LIVESTREAM);
  const { listTrainer, loading, listAdmin } = useSelector(
    state => state.livestream
  );

  const isView = typeModal === TYPE_MODAL.View;

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [filesMp3, setFilesMp3] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const maxFileUpload = 20;
  const maxSizeUpload = 5 * 1024 * 1024; // 5mb
  const typeAudioMp3 = ['audio/mpeg', 'audio/x-m4a'];

  const isUploadRecorded =
    formValue.liveStreamOption === LIVE_STREAM_OPTION_VALUE.UploadPrerecord;

  const onRemoveFile = name => {
    const newFileMp3 = filesMp3.filter(file => file.name !== name);
    setFilesMp3(newFileMp3);
  };

  const props = {
    accept: typeAudioMp3.join(' ,'),
    listType: 'file',
    multiple: true,
    showUploadList: false,
    fileList: filesMp3,
    transformFile: file => {
      if (!typeAudioMp3.includes(file.type)) {
        customToast('error', 'Supported file mp3 and m4a');
        return false;
      }
      if (file.size > maxSizeUpload) {
        customToast('error', 'File is smaller than 5 mb');
        return false;
      }
    },
    customRequest: event => {
      const { onSuccess } = event;
      return onSuccess();
    },
    onChange: ({ fileList, file }) => {
      if (!typeAudioMp3.includes(file.type) || file.size > maxSizeUpload) {
        return;
      }
      if (fileList.length > maxFileUpload) {
        customToast(
          'error',
          'Files audio upload must be smaller than ' + maxFileUpload
        );
        return;
      }
      const newFile = { ...file };
      const fileNameArr = newFile.name.split('.');
      const newFileName =
        fileNameArr[0] + '_' + new Date().getTime() + '.' + fileNameArr[1];
      newFile.name = newFileName;

      setFilesMp3(prevState => prevState.concat(newFile));
    }
  };

  useEffect(() => {
    if (!isEmpty(livestreamDetail)) {
      const trainerId = map(
        livestreamDetail.trainerId || livestreamDetail.trainers,
        item => item.id
      );
      setFormValue({
        trainerId: trainerId || [],
        managerId: livestreamDetail.managerId || '',
        vimeoClipId: livestreamDetail.vimeoClipId || '',
        liveStreamOption:
          livestreamDetail.liveStreamOption ||
          LIVE_STREAM_OPTION_VALUE.UploadPrerecord
      });
      setFilesMp3(livestreamDetail.mp3Urls || []);
    }
  }, [livestreamDetail]);

  const handleChangeData = (data, field) => {
    setFormValue({
      ...formValue,
      [field]: data
    });
  };

  const handleChangeTrainer = data => {
    setFormValue({ ...formValue, trainerId: [data] });
  };

  const onCheckValidForm = () => {
    setIsSubmitted(true);
    let valid = true;
    let errors = {};
    let listValidation = [];
    if (
      formValue.liveStreamOption === LIVE_STREAM_OPTION_VALUE.UploadPrerecord
    ) {
      listValidation = LIST_REQUIRED_LIVESTREAM_UPLOAD;
    }
    if (
      formValue.liveStreamOption === LIVE_STREAM_OPTION_VALUE.LiveStreamVideo
    ) {
      listValidation = LIST_REQUIRED_LIVESTREAM_VIDEO;
    }
    listValidation.forEach(field => {
      if (
        !formValue[field] ||
        (field === 'trainerId' && formValue[field] && !formValue[field].length)
      ) {
        errors = { ...errors, [field]: 'Required' };
        valid = false;
      }
    });
    setErrors(errors);
    return valid;
  };

  const handleSubmit = () => {
    if (typeModal === TYPE_MODAL.View) {
      onCloseModal();
      return;
    }
    if (!onCheckValidForm()) {
      return;
    }
    const body = { ...formValue };
    setLoadingUpload(true);
    //format trainerId before call api
    // let listTrainer = formValue.trainerId.map(trainer => trainer.id);

    if (!isUploadRecorded && filesMp3.length > 0) {
      return Promise.all(
        filesMp3.map(async file => {
          if (file.url) {
            return file;
          }
          const urlPath = await uploadToFirebaseAsync({
            id: file.uid,
            uploadedFile: { file: file.originFileObj }
          });

          return {
            name: file.name,
            size: file.size,
            url: urlPath
          };
        })
      ).then(data => {
        onSaveData({ ...body, mp3Urls: data });
      });
    } else {
      onSaveData(body);
    }
  };

  const customArrayData = (list, fullName, id) => {
    if (!notAdminView) return list;
    return list && !!list.length ? list : [{ fullName, id }];
  };

  return (
    <div style={{ maxHeight: 700 }}>
      <HeaderModal>
        <div className="title">{livestreamDetail?.title}</div>
        <ClearRounded onClick={() => onCloseModal()} className="icon-close" />
      </HeaderModal>
      <ContentModal>
        <Grid classes={classes} container spacing={5}>
          <Grid item xs={isUploadRecorded ? 12 : 6}>
            <Grid container>
              <GridResetPaddingBottom item xs={12}>
                <SelectInlineEnhance
                  disabled={typeModal === TYPE_MODAL.View}
                  value={formValue.liveStreamOption}
                  onSelect={data => handleChangeData(data, 'liveStreamOption')}
                  options={STREAM_OPTIONS}
                />
              </GridResetPaddingBottom>
              {formValue.liveStreamOption ===
                LIVE_STREAM_OPTION_VALUE.UploadPrerecord && (
                <Grid item xs={12} md={6}>
                  <WrapperInput
                    error={isSubmitted && errors.vimeoClipId ? true : false}
                    variant={
                      typeModal === TYPE_MODAL.View ? 'filled' : 'standard'
                    }
                    value={formValue.vimeoClipId}
                    disabled={typeModal === TYPE_MODAL.View}
                    onChange={e =>
                      handleChangeData(e.target.value, 'vimeoClipId')
                    }
                    label="Vimeo ID"
                    placeholder="Enter Vimeo ID"
                    helperText={isSubmitted && errors.vimeoClipId}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkIcon width="16px" height="8px" />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {/* <MultipleAutocomplete
                  label="Co-host"
                  options={listTrainer}
                  onChange={handleChangeTrainer}
                  optionLabel="fullName"
                  value={formValue.trainerId}
                  disabled={typeModal === TYPE_MODAL.View ? true : false}
                  error={isSubmitted && errors.trainerId}
                  helperText="Co-host is required"
                /> */}
                <WrapperFormControl
                  disabled={typeModal === TYPE_MODAL.View}
                  variant={
                    typeModal === TYPE_MODAL.View ? 'filled' : 'standard'
                  }
                  error={isSubmitted && errors.trainerId ? true : false}
                >
                  <InputLabel id="trainer-select-label">Co-host</InputLabel>
                  <WrapperSelect
                    className="select-custom"
                    labelId="trainer-select-label"
                    value={formValue.trainerId}
                    disabled={typeModal === TYPE_MODAL.View}
                    onChange={e => handleChangeTrainer(e.target.value)}
                  >
                    {listTrainer.map((item, index) => (
                      <MenuItem key={item.id + index} value={item.id}>
                        {item.fullName}
                      </MenuItem>
                    ))}
                  </WrapperSelect>
                  {isSubmitted && (
                    <FormHelperText>{errors.trainerId}</FormHelperText>
                  )}
                </WrapperFormControl>
              </Grid>

              <Grid item xs={12}>
                <WrapperFormControl
                  disabled={typeModal === TYPE_MODAL.View}
                  variant={
                    typeModal === TYPE_MODAL.View ? 'filled' : 'standard'
                  }
                  error={isSubmitted && errors.managerId ? true : false}
                >
                  <InputLabel id="admin-select-label">Admin</InputLabel>
                  <WrapperSelect
                    className="select-custom"
                    labelId="admin-select-label"
                    value={formValue.managerId}
                    disabled={livestreamDetail?.liveStreamStatus === 'Live'}
                    onChange={e =>
                      handleChangeData(e.target.value, 'managerId')
                    }
                  >
                    {customArrayData(
                      listAdmin,
                      get(livestreamDetail, 'managerName'),
                      get(livestreamDetail, 'managerId')
                    ).map((item, index) => (
                      <MenuItem key={item.id + index} value={item.id}>
                        {item.fullName}
                      </MenuItem>
                    ))}
                  </WrapperSelect>
                  {isSubmitted && (
                    <FormHelperText>{errors.managerId}</FormHelperText>
                  )}
                </WrapperFormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={isUploadRecorded ? 12 : 6}
            style={{ display: isUploadRecorded ? 'none' : 'block' }}
          >
            <InputLabel style={{ marginBottom: 7 }} id="admin-select-label">
              File Video
            </InputLabel>
            <WrapperFormControl
              disabled={typeModal === TYPE_MODAL.View}
              variant={typeModal === TYPE_MODAL.View ? 'filled' : 'standard'}
            >
              <Upload
                {...props}
                style={{ width: '100%', display: 'block' }}
                disabled={isView || loadingUpload}
              >
                <Button
                  style={{ height: 45, marginBottom: 8 }}
                  variant="contained"
                  color="primary"
                  disabled={isView || loadingUpload}
                  className={classes.button}
                  fullWidth
                  startIcon={<CloudUpload />}
                >
                  Upload
                </Button>
              </Upload>
              <FileMp3Item
                files={filesMp3}
                onRemoveFile={onRemoveFile}
                isView={isView}
                loadingUpload={loadingUpload}
              />
            </WrapperFormControl>
          </Grid>
        </Grid>
      </ContentModal>

      <ModalFooter>
        <ButtonEnhance
          background="#E9E9E9"
          color="black"
          backgroundHover="#ccc"
          onClick={backToBasicForm}
        >
          Back
        </ButtonEnhance>

        <LoadingButton
          showLoader={loading || loadingUpload}
          onClick={handleSubmit}
        >
          {typeModal === TYPE_MODAL.Create && 'Create'}
          {typeModal === TYPE_MODAL.Edit && 'Update'}
          {typeModal === TYPE_MODAL.View && 'Cancel'}
        </LoadingButton>
      </ModalFooter>
    </div>
  );
};

const GridResetPaddingBottom = styled(Grid)`
  padding-bottom: 0px !important;
`;

const WrapperInput = styled(TextField)`
  width: 100%;
  label {
    color: #8f9ba9;
  }
`;
const HeaderModal = styled.div`
  border-bottom: 1px solid rgb(202, 207, 211);
  font-weight: 600;
  color: rgb(25, 38, 55);
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  .icon-close {
    cursor: pointer;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
  }
`;
const ContentModal = styled(DialogContent)`
  width: 850px;
`;
const WrapperSelect = styled(Select)`
  width: 100%;
`;
const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 20px 0px;
`;

const WrapperFormControl = styled(FormControl)`
  width: 100%;
`;

export default LivestreamFormModalPage;
