import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging) => async ({ Api, getState }) => {
    let pagingData = paging || getState().healthProfile.paging;
    let { result, status } = await Api.get(`Profiles`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search
    });
    status === 200 && healthProfileDispatcher.getDataSuccess(result);
  },
  getProfilesData: () => async ({ Api }) => {
    let profilesApi = Api.get(`Profiles`);
    let [profilesRes] = await Promise.all([profilesApi]);
    if (profilesRes.status === 200)
      healthProfileDispatcher.getProfilesDataSuccess(
        profilesRes.result.list || []
      );
  },
  getAllProfiles: callback => async ({ Api }) => {
    let { result, status } = await Api.get(`Profiles`);
    status === 200 && callback && callback(result.list);
  },
  getProfilesDataSuccess: data => ({ data }),
  getTestsData: () => async ({ Api }) => {
    let testsApi = Api.get(`Tests`);
    let [testRes] = await Promise.all([testsApi]);
    if (testRes.status === 200)
      healthProfileDispatcher.getTestDataSuccess(testRes.result.list || []);
  },
  getTestDataSuccess: data => ({ data }),
  addProfile: (payload, callbackSuccess) => async ({ Api, customToast }) => {
    const { result, status } = await Api.post(`Profiles`, payload);
    if (status === 200) {
      callbackSuccess(result);
    }
  },
  getProfileById: (id, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.get(`Profiles/${id}`);
    if (status === 200) {
      callbackSuccess(result);
    }
  },
  getProfileCondition: (id, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.get(`Profiles/${id}/condition`);
    if (status === 200) {
      callbackSuccess(result);
    }
  },
  updateProfileCondition: ({ data, id }, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    const { result, status } = await Api.put(`Profiles/${id}/condition`, data);
    if (status === 200) {
      callbackSuccess();
    }
  },
  updateProfile: (id, payload, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.put(`Profiles/${id}`, payload);
    if (status === 200) {
      callbackSuccess(result);
    }
  },
  deleteProfile: (id, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.delete(`Profiles/${id}`);
    if (status === 200) {
      customToast('success', 'Profile was deleted');
      callbackSuccess && callbackSuccess();
    }
  }
};

const healthProfileDispatcher = synthesize('healthProfile', mapDispatchToAC);
export default healthProfileDispatcher;
