import patientDispatcher from '../actions/index';
import {
  handleApprovePatientSuccess,
  handleInActiveOrActiveSuccess
} from './utils';
const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  }
};

const patientReducer = patientDispatcher(initialState, {
  [patientDispatcher.getPatientListSuccess]: (state, payload) => ({
    list: payload.list,
    paging: {
      ...payload,
      list: undefined
    }
  }),
  [patientDispatcher.approvePatientSuccess]: (state, payload) => ({
    list: handleApprovePatientSuccess(state.list, payload)
  }),
  [patientDispatcher.inActiveOrActivePatientSuccess]: (state, payload) => {
    return {
      list: handleInActiveOrActiveSuccess(state.list, payload)
    };
  }
});

export default patientReducer;
