import styled from 'styled-components';
import {
  DialogActions,
  FormControl,
  Select,
  TextField
} from '@material-ui/core';

const ModalPage = styled.div`
  max-width: 780px;
  hr {
    background: #cacfd3;
    margin: 0;
  }
  .MuiDialogContent-root {
    padding: 0;
    &:first-child {
      padding-top: 0;
    }
  }
  .test-header {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #192637;
    border-bottom: 1px solid rgb(202, 207, 211);
  }
  .test-body {
    padding: 0 20px 20px 20px;
  }
`;

export const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 10px 0px;
`;

export const WrapperFormControl = styled(FormControl)`
  width: 100%;
`;

export const WrapperSelect = styled(Select)`
  width: 100%;
`;

export const WrapperInput = styled(TextField)`
  width: 100%;
  label {
    color: #8f9ba9;
  }
`;

export const WrapperButtonUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    color: #ccc;
  }
`;

export default ModalPage;
