import React, { useState } from 'react';

import Button from '@material-ui/core/Button';

import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';

import { CreateNewIcon } from '@/assets/svg';
import GroupToolbar from './GroupToolbar';
import TestManagement from '@/module/services/component/sub-module/test/TestManagement';
import MeasurementManagement from '@/module/services/component/sub-module/measurement/MeasurementManagement';
import ProcedureManagement from '@/module/services/component/sub-module/procedure/ProcedureManagement';
import RadiologyManagement from '@/module/services/component/sub-module/radiologies/RadiologyManagement';
import VaccinationManagement from '@/module/services/component/sub-module/vaccination/VaccinationManagement';
import ReviewManagement from '@/module/services/component/sub-module/review/ReviewManagement';

const OtherServices = () => {
  const [searchKey, setSearchKey] = useState('');
  const [currentTab, setCurrentTab] = useState(0);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isCreate, setIsCreate] = useState(true);

  const OTHER_SERVICES_TABS = [
    {
      key: 'tests',
      category: 'Tests',
      searchPlaceholder: 'Search by test name',
      component: (
        <TestManagement
          isCreate={isCreate}
          setIsCreate={setIsCreate}
          searchKey={searchKey}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )
    },
    {
      key: 'measurements',
      category: 'Measurements',
      searchPlaceholder: 'Search by measurement name',
      component: (
        <MeasurementManagement
          isCreate={isCreate}
          setIsCreate={setIsCreate}
          searchKey={searchKey}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )
    },
    {
      key: 'procedures',
      category: 'Procedures',
      searchPlaceholder: 'Search by procedure name',
      component: (
        <ProcedureManagement
          isCreate={isCreate}
          setIsCreate={setIsCreate}
          searchKey={searchKey}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )
    },
    {
      key: 'radiologies',
      category: 'Radiologies',
      searchPlaceholder: 'Search by radiology name',
      component: (
        <RadiologyManagement
          isCreate={isCreate}
          setIsCreate={setIsCreate}
          searchKey={searchKey}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )
    },
    {
      key: 'vaccinations',
      category: 'Vaccinations',
      searchPlaceholder: 'Search by vaccination name',
      component: (
        <VaccinationManagement
          isCreate={isCreate}
          setIsCreate={setIsCreate}
          searchKey={searchKey}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )
    },
    {
      key: 'reviews',
      category: 'Reviews',
      searchPlaceholder: 'Search by review name',
      component: (
        <ReviewManagement
          isCreate={isCreate}
          setIsCreate={setIsCreate}
          searchKey={searchKey}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )
    }
  ];

  return (
    <>
      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder={
              OTHER_SERVICES_TABS[currentTab].searchPlaceholder
            }
            title="Other services"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            renderToolbar={() => (
              <GroupToolbar
                tabs={OTHER_SERVICES_TABS}
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
              />
            )}
            renderButton={() => (
              <Button
                color="primary"
                variant="contained"
                startIcon={<CreateNewIcon />}
                onClick={() => {
                  setOpenDrawer(true);
                  setIsCreate(true);
                }}
              >
                Create new
              </Button>
            )}
          />
        }
      >
        {OTHER_SERVICES_TABS[currentTab].component}
      </CustomPaperContainer>
    </>
  );
};

export default OtherServices;
