import React, { useState } from 'react';
import { LocalizationProvider, StaticDatePicker } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import CustomSelect from '../CustomSelect';
import { Popover, TextField } from '@material-ui/core';
import { DATE_FORMAT_2 } from '../../module/common';
import { Calendar } from '@/assets/svg';

export const QUICK_RANGE = [
  'Today',
  'Yesterday',
  'This week',
  'This month',
  'Last week',
  'Last month',
  'Next week',
  'Next month'
];

const CustomDate = ({
  selectedDate,
  onChange,
  placeholder,
  customRange,
  minDate,
  maxDate,
  clearable = true,
  onClear
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <CustomSelect
      isFullWidth
      onClick={handleClick}
      clearable={clearable}
      onClear={onClear}
      prefixIcon={
        <Calendar
          style={{
            marginRight: 8
          }}
        />
      }
      renderValue={
        selectedDate
          ? typeof selectedDate === 'string'
            ? selectedDate
            : selectedDate?.format(DATE_FORMAT_2)
          : placeholder
      }
    >
      <Popover
        id="date-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {customRange}
        <LocalizationProvider dateAdapter={MomentUtils}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={selectedDate}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            renderInput={props => <TextField {...props} />}
          />
        </LocalizationProvider>
      </Popover>
    </CustomSelect>
  );
};

export default CustomDate;
