import { packageDispatcher } from '..';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  },
  allProfiles: []
};

const packageReducer = packageDispatcher(initialState, {
  [packageDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [packageDispatcher.getProfilesDataSuccess]: (state, payload) => ({
    allProfiles: payload.data
  })
});

export default packageReducer;
