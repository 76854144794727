import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, filterOption = {}) => async ({ Api, getState }) => {
    let pagingData = paging || getState().mhs.paging;
    let { result, status } = await Api.post(`Users/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      roleType: 'MHS',
      ...filterOption
    });
    status === 200 && mhsDispatcher.getDataSuccess(result);
  },
  setState: (state, value) => ({ state, value }),
  createMHS: (data, callback) => async ({ Api, customToast }) => {
    const { status } = await Api.post('MHSs', data);
    mhsDispatcher.setState('loading', false);

    if (status === 200) {
      customToast('success', 'Success');
      callback();
    }
  },
  updateMHS: (payload, callback) => async ({ Api, customToast }) => {
    const { id, data } = payload;
    const { status } = await Api.put(`Users/${id}`, data);
    mhsDispatcher.setState('loading', false);

    if (status === 200) {
      customToast('success', 'Success');
      callback();
    }
  },
  deleteData: (id, searchKey, filterOption = {}) => async ({
    Api,
    customToast,
    getState
  }) => {
    const { status } = await Api.delete(`Users/${id}`);
    if (status === 200) {
      let { paging } = getState().mhs;
      mhsDispatcher.getData(searchKey, paging, filterOption);
      customToast('success', 'Deleted');
    }
  }
};

const mhsDispatcher = synthesize('mhs', mapDispatchToAC);
export default mhsDispatcher;
