import React, { useEffect, useCallback } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  Table,
  Paper,
  TableContainer as MUTableContainer,
  TableBody,
  TablePagination,
  Typography,
  TableRow,
  IconButton,
  Grid
} from '@material-ui/core';
import { debounce, isEmpty } from 'lodash';
import { ButtonEnhance } from '../../module/common/componentUI/commonStyleComponents';
import { renderIf } from '../../helpers';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  LastPage,
  FirstPage,
  KeyboardArrowRight,
  KeyboardArrowLeft
} from '@material-ui/icons';
import styled from 'styled-components';

const useStyles = makeStyles({
  contained: {
    boxShadow: 'none'
  },
  head: {
    fontWeight: 600
  },
  container: {
    // height: 'calc(100% - 400px)',
    height: '100%',
    width: '100%'
  },
  tableContainer: {
    width: '100%',
    maxHeight: 'calc(100vh - 350px)',
    margin: 0,
    padding: 0,
    overflow: 'auto'
  },
  divTxtTable: {
    padding: '16px 0 16px',
    borderBottom: '1px solid #e9e9e9'
  },
  descTxtTable: {
    fontSize: 14,
    textAlign: 'center',
    color: '#8F9BA9',
    margin: 0
  },
  nonePaging: {
    minHeight: 52,
    paddingRight: 2
  }
});

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

const TableContainer = ({
  header: Header,
  renderRow,
  query = () => {},
  paging = {},
  data = [],
  setSearchKey,
  searchKey,
  onAddNew,
  sortOption,
  filterOption,
  title = '',
  toolbar = true,
  search = true,
  ToolbarComponent,
  className,
  customButtons = [],
  txtBtnAddNew = 'Create New',
  searchPlaceholder = 'Search',
  defaultSearchInput = true,
  fullWidthSearch = false,
  useHelperText,
  noDataHelperText,
  isSearch = true
}) => {
  const classes = useStyles();
  const onChangeSearch = e => {
    setSearchKey(e.target.value);
  };
  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    sortOptionData = sortOption,
    filterOptionData = filterOption
  ) => {
    query(
      search,
      pagingData,
      ...[sortOptionData, filterOptionData].filter(i => i)
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(searchKey, paging, sortOption, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, sortOption, filterOption]);

  const TablePaginationActions = props => {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage } = props;

    const handleFirstPageButtonClick = () => {
      fetchData(searchKey, {
        ...paging,
        pageIndex: 0
      });
    };

    const handleBackButtonClick = () => {
      fetchData(searchKey, {
        ...paging,
        pageIndex: page - 1
      });
    };

    const handleNextButtonClick = () => {
      fetchData(searchKey, {
        ...paging,
        pageIndex: page + 1
      });
    };

    const handleLastPageButtonClick = () => {
      fetchData(searchKey, {
        ...paging,
        pageIndex: paging.totalPage - 1
      });
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
        </IconButton>
      </div>
    );
  };

  return (
    <Paper className={classes.root}>
      <MUTableContainer className={classes.container}>
        {/* Table toolbar */}
        {renderIf(toolbar)(
          <div className="table-container-header">
            <Typography
              style={{
                fontSize: 18,
                fontWeight: 600
              }}
            >
              {title}
            </Typography>

            <div style={{ display: 'flex' }}>
              {onAddNew && (
                <ButtonEnhance
                  style={{
                    margin: 5,
                    paddingLeft: 40,
                    paddingRight: 40
                  }}
                  onClick={onAddNew}
                >
                  {txtBtnAddNew}
                </ButtonEnhance>
              )}
              {customButtons}
            </div>
          </div>
        )}
        <ToolsBar>
          <div
            style={{ display: 'flex', marginBottom: 10 }}
            className={className}
          >
            {isSearch ? (
              fullWidthSearch ? (
                <Grid container style={{ padding: '20px 20px 0' }}>
                  <Grid item xs={12} className="search-container">
                    <Input
                      allowClear
                      value={searchKey}
                      onChange={onChangeSearch}
                      placeholder={searchPlaceholder}
                      prefix={
                        <SearchOutlined
                          style={{ color: 'rgba(0,0,0,.25)', fontSize: 16 }}
                          type="search"
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 16 }}>
                    {ToolbarComponent}
                  </Grid>
                </Grid>
              ) : (
                <>
                  <div className="search-container">
                    <Input
                      allowClear
                      value={searchKey}
                      onChange={onChangeSearch}
                      placeholder={searchPlaceholder}
                      prefix={
                        <SearchOutlined
                          style={{ color: 'rgba(0,0,0,.25)', fontSize: 16 }}
                          type="search"
                        />
                      }
                    />
                  </div>
                  {ToolbarComponent}
                </>
              )
            ) : (
              <Grid container style={{ padding: '20px 20px 0' }}>
                <Grid item xs={12} style={{ marginTop: 16 }}>
                  {ToolbarComponent}
                </Grid>
              </Grid>
            )}
          </div>
        </ToolsBar>

        <div className={classes.tableContainer}>
          <Table stickyHeader>
            {Header && <Header />}
            <TableBody>
              {(data || []).map((d, i) => (
                <TableRow
                  key={d.id}
                  hover
                  classes={{
                    head: classes.head
                  }}
                >
                  {renderRow(d, i)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </MUTableContainer>
      {noDataHelperText && data.length === 0 && (
        <div className={classes.divTxtTable}>
          <p className={classes.descTxtTable}>{noDataHelperText}</p>
        </div>
      )}
      {(!useHelperText || data.length !== 0) && !isEmpty(paging) ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 50, 100, 200]}
          component="div"
          count={paging.totalItem || 0}
          rowsPerPage={paging.pageSize}
          page={paging.pageIndex}
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onChangePage={(e, page) => {
            e &&
              fetchData(searchKey, {
                ...paging,
                pageIndex: page
              });
          }}
          onChangeRowsPerPage={e => {
            fetchData(searchKey, {
              ...paging,
              pageSize: e.target.value
            });
          }}
          ActionsComponent={TablePaginationActions}
        />
      ) : (
        <div className={classes.nonePaging}></div>
      )}
    </Paper>
  );
};

export default TableContainer;

const ToolsBar = styled.div`
  .justify-between {
    justify-content: space-between;
  }
`;
