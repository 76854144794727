import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  IconButton,
  Typography,
  TextField,
  Checkbox,
  Grid
} from '@material-ui/core';
import { DeleteRounded, AddCircle } from '@material-ui/icons';
import InlineTable from '@/module/common/component/InlineTable';
import TableCellFilted from '@/module/common/componentUI/TableCellFilted';
import AutocompleteModal from '@/module/common/component/AutocompleteModal';
import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import { radiologyColumn } from '../constants';
import { TableWrapped } from '@/module/company/component/CompanyPaper/styled';
import globalDispatcher from '@/redux/global/actions';
import styled from 'styled-components';
import { isEmpty, findIndex } from 'lodash';

const RadiologiesInformation = ({
  errors,
  setErrors,
  formValue,
  setFormValue,
  disabled
}) => {
  const [hiddenRows, setHiddenRows] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [numberSelected, setNumberSelected] = useState(0);

  const [localData, setLocalData] = useState([]);
  const [mandatoryData, setMandatoryData] = useState([]);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onAddNewTest = data => {
    const mandatory = data.filter(item => item.isRequired);

    const newMandatoryData = mandatory.map(item => ({
      ...item,
      isRequired: true
    }));

    const unMandatoryData = data.filter(item => !item.isRequired);
    const newUnMandatoryData = unMandatoryData.map(item => ({
      ...item,
      isRequired: false
    }));

    const lstMandatory = mandatoryData.concat(newMandatoryData);
    const lsUntMandatory = localData.concat(newUnMandatoryData);

    let lstRadiologies = [...lsUntMandatory, ...lstMandatory];
    setMandatoryData(lstMandatory);
    setLocalData(lsUntMandatory);
    setFormValue({ ...formValue, lstRadiologies });
    setShowModal(false);
  };

  const onDeleteTest = (index, isRequired) => {
    let newMandatoryData = [...mandatoryData];
    let newLocalData = [...localData];
    if (isRequired) {
      newMandatoryData.splice(index, 1);
      setMandatoryData(newMandatoryData);
    } else {
      newLocalData.splice(index, 1);
      setLocalData(newLocalData);
    }

    let lstRadiologies = [...newMandatoryData, ...newLocalData];
    if (isEmpty(newLocalData)) {
      setFormValue({
        ...formValue,
        lstRadiologies,
        totalMandatoryRadiology: 0
      });
      setNumberSelected(0);
    } else setFormValue({ ...formValue, lstRadiologies });
  };

  const updateCheckedData = (dataUpdated = [], testExists = []) => {
    const newDataLocal = [...dataUpdated];

    testExists.forEach(test => {
      const indexProject = findIndex(dataUpdated, it => it.id === test.id);

      if (indexProject < 0) return;
      const updatedProject = newDataLocal[indexProject];

      const indexTestChange = findIndex(
        newDataLocal,
        it => it.id === updatedProject.id
      );
      if (indexTestChange < 0) return;
      newDataLocal[indexTestChange] = { ...test };
    });

    return newDataLocal;
  };

  const onChangeValueCheckbox = (value, row) => {
    const newData = { ...row, isRequired: value };
    const dataLocalUpdated = updateCheckedData(formValue.lstRadiologies, [
      newData
    ]);
    const newListMandatory = [...dataLocalUpdated].filter(
      item => item.isRequired
    );
    const newListUnMandatory = [...dataLocalUpdated].filter(
      item => !item.isRequired
    );
    if (isEmpty(newListUnMandatory)) {
      setFormValue({
        ...formValue,
        lstRadiologies: dataLocalUpdated,
        totalMandatoryRadiology: 0
      });
      setNumberSelected(0);
    } else setFormValue({ ...formValue, lstRadiologies: dataLocalUpdated });

    setLocalData(newListUnMandatory);
    setMandatoryData(newListMandatory);
  };

  const onChangeValueRequired = e => {
    const value = e.target.value;

    setNumberSelected(value);
    setFormValue({ ...formValue, totalMandatoryRadiology: Number(value) });
  };

  useEffect(() => {
    if (isEmpty(formValue.lstRadiologies)) return;
    const mandatory = formValue.lstRadiologies.filter(item => item.isRequired);

    const unMandatoryData = formValue.lstRadiologies.filter(
      item => !item.isRequired
    );

    setMandatoryData(mandatory);
    setLocalData(unMandatoryData);
    setNumberSelected(formValue.totalMandatoryRadiology);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (setErrors)
      if (numberSelected > localData.length)
        setErrors({
          ...errors,
          numberSelected: `Please choose number no more than total radiologies (${localData.length})`
        });
      else setErrors({});

    // eslint-disable-next-line
  }, [localData, numberSelected]);

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const TableHeader = () => (
    <TableHead>
      {!disabled && !showModal && !isEmpty(localData) && (
        <TableRow>
          <TableCell colspan={radiologyColumn.length}>
            <Typography
              variant="h6"
              component="span"
              style={{ fontWeight: 'bold' }}
            >
              Mandatory
            </Typography>
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        {radiologyColumn.map(item =>
          item.label ? (
            <TableCellFiltedWrapper
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              style={{ width: item?.width }}
            />
          ) : (
            <TableCell
              key={item.stateValue}
              style={{ width: item?.width }}
            ></TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );

  const TableHeaderForIsRequired = () => (
    <TableHead>
      <TableRow>
        <TableCell colspan={radiologyColumn.length}>
          <Grid container direction="row" alignItems="center">
            <Typography
              variant="h6"
              component="span"
              style={{ fontWeight: 'bold' }}
            >
              Optional, choose
            </Typography>
            <TextField
              autoFocus
              type="number"
              value={numberSelected}
              onChange={onChangeValueRequired}
              error={errors.numberSelected}
              helperText={errors.numberSelected}
            />
            <Typography
              variant="h6"
              component="span"
              style={{ fontWeight: 'bold' }}
            >
              from:
            </Typography>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        {radiologyColumn.map(item =>
          item.label ? (
            <TableCellFiltedWrapper
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              style={{ width: item?.width }}
            />
          ) : (
            <TableCell
              key={item.stateValue}
              style={{ width: item?.width }}
            ></TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );

  const TableFooter = () =>
    disabled ? null : (
      <StyledButtonEnhance
        background="#EFDFEB"
        color="#AD5E99"
        backgroundHover="#EFDFEB"
        onClick={() => setShowModal(true)}
      >
        <div className="add-group">
          <span>Add new radiology</span>
          <AddCircle />
        </div>
      </StyledButtonEnhance>
    );

  const renderRow = (onDeleteTest, isRequired) => (row, i, onDeleteValue) => (
    <>
      <TableCell>{!hiddenRows.name && row.name}</TableCell>
      <TableCell>{!hiddenRows.stationName && row.stationName}</TableCell>
      {!disabled && !showModal ? (
        <TableCell>
          <Checkbox
            checked={row.isRequired}
            color="primary"
            onChange={e => onChangeValueCheckbox(e.target.checked, row)}
          />
        </TableCell>
      ) : (
        <TableCell></TableCell>
      )}
      <TableCell align="right">
        {!disabled && (
          <IconButton
            className="delete-icon"
            onClick={() =>
              onDeleteTest ? onDeleteTest(i, isRequired) : onDeleteValue(i)
            }
          >
            <DeleteRounded />
          </IconButton>
        )}
      </TableCell>
    </>
  );

  return (
    <TableWrapped>
      {disabled ? (
        <InlineTable
          Header={TableHeader}
          data={formValue.lstRadiologies}
          Footer={TableFooter}
          noDataHelperText={
            disabled
              ? 'No data'
              : 'Click button "Add new radiology" to create new one'
          }
          renderRow={renderRow(onDeleteTest)}
        />
      ) : (
        <>
          <InlineTable
            Header={TableHeader}
            data={mandatoryData}
            Footer={TableFooter}
            noDataHelperText="Select checkbox list below to create new one"
            renderRow={renderRow(onDeleteTest, true)}
          />
          {!isEmpty(localData) && (
            <InlineTable
              Header={TableHeaderForIsRequired}
              data={localData}
              renderRow={renderRow(onDeleteTest)}
            />
          )}
        </>
      )}

      <Dialog maxWidth="lg" open={showModal} onClose={onCloseModal}>
        <AutocompleteModal
          onClose={onCloseModal}
          title="Radiologies"
          optionLabel="name"
          textFieldPlaceholder="Select radiology"
          onSubmit={onAddNewTest}
          Header={TableHeader}
          renderRow={renderRow()}
          getListOptions={(...props) =>
            globalDispatcher.getListServiceByCategory('Radiologies', ...props)
          }
          existedOption={[...localData, ...mandatoryData]}
        />
      </Dialog>
    </TableWrapped>
  );
};

export const StyledButtonEnhance = styled(ButtonEnhance)`
  display: flex;
  justify-content: center;
  max-width: 200px;
  float: right;
  margin-top: 15px;
  height: 35px;
  padding: 8px 16px;
  .add-group {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: -2px;
    span {
      margin-right: 8px;
    }
  }
`;

export default RadiologiesInformation;
