import React from 'react';
import styled from 'styled-components';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

const ImageMessage = ({ file, fileSource, onRemove }) => {
  return (
    <ImageContainer>
      <CancelOutlinedIcon className="icon-remove" onClick={onRemove} />
      <img src={fileSource} height={60} width={60} alt={file.name} />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  height: 60px;
  position: relative;
  margin-right: 10px;
  transition: all 1s;
  img {
    object-fit: cover;
    border-radius: 5px;
  }
  .icon-remove {
    display: none;
    position: absolute;
    top: -2px;
    right: -10px;
    cursor: pointer;
  }

  &:hover .icon-remove {
    display: initial;
  }
`;

export default ImageMessage;
