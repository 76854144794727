import Enum from './Enum';

export const QueueStatus = Enum({
  Accepted: 'Accepted',
  Rejected: 'Rejected',
  Cancelled: 'Cancelled'
});

export const QueueColor = Enum({
  Accepted: '#62CAA4',
  Rejected: '#EA6B75',
  Cancelled: '#8F9BA9'
});
