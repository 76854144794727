import React from 'react';
import { Modal, Typography } from '@material-ui/core';
import ModalPresenter from '../../../components/ModalPresenter';
import { ErrorOutlineRounded } from '@material-ui/icons';
const ReassignModal = ({ showModal, onClose, onAccept }) => {
  return (
    <Modal open={showModal} onClose={onClose}>
      <div>
        <ModalPresenter
          onClose={onClose}
          Icon={
            <ErrorOutlineRounded style={{ fontSize: 80, color: '#F3D981' }} />
          }
          title="Reassigning Camera?"
          onClick={onAccept}
        >
          <Typography>Are you sure you want to change camera right?</Typography>
          <Typography>
            Please click “Continue” to change camera rights.
          </Typography>
        </ModalPresenter>
      </div>
    </Modal>
  );
};

export default ReassignModal;
