import { Tooltip, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,

    '& .MuiTypography-body1': {
      '& > p span, h1,h2,h3,h4,h5,h6': {
        color: '#ffffff !important',
        fontWeight: 'revert !important',
      },
    },
  },
}))(Tooltip);

export const ParagraphWrapped = styled.span`
  /* max-line */
  word-break: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: revert;
  }
  span {
    color: #333333 !important;
  }
`;

const CustomTooltip = ({ content, placement, className, style }) => {
  if (!content) return null;

  return (
    <LightTooltip
      title={content}
      placement={placement}
      style={{ cursor: 'pointer', ...style }}
      className={className}
    >
      <ParagraphWrapped>{content}</ParagraphWrapped>
    </LightTooltip>
  );
};

CustomTooltip.defaultProps = {
  content: '',
  placement: 'top',
};

CustomTooltip.propTypes = {
  content: PropTypes.node,
  placement: PropTypes.string,
};

export default CustomTooltip;
