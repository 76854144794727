/* eslint-disable no-unused-expressions */
import AgoraRTC from 'agora-rtc-sdk-ng';
import { AGORA_APP_ID } from './config';
import { isFirefox } from '../helpers';

const DOM_MAIN_VIDEO_AREA = 'main_stream_appointment_call';
const DOM_SUB_VIDEO_AREA = 'sub_stream_appointment_call';

class Agora4xInstance {
  constructor(appId, dispatcher, onShareStream, onEndCall, patientId) {
    this.rtc = {
      client: {
        instance: null,
        audioTrack: null,
        videoTrack: null
      },
      remoteClient: {
        audioTrack: null,
        videoTrack: null
      },
      shareScreenClient: {
        instance: null,
        screenTrack: null,
        uid: null
      },
      localUserIds: []
    };
    this.option = {
      appID: appId,
      channel: '',
      uid: null,
      token: ''
    };
    this.dispatcher = dispatcher;
    this.cbShareStream = onShareStream;
    this.cbEndCall = onEndCall;
    this.patientId = patientId;
    this.configInit = { mode: 'rtc', codec: 'h264' };
    this.configVideoTrack = { fit: 'contain' };
    this.initClient(appId);
  }

  initClient(appId = AGORA_APP_ID) {
    const { option, rtc, configInit, subscribeAudioAndVideoTracks } = this;
    option.appID = appId;

    rtc.client.instance = AgoraRTC.createClient(configInit);
    rtc.shareScreenClient.instance = AgoraRTC.createClient(configInit);
    subscribeAudioAndVideoTracks();
  }

  setOption = (option = {}) => {
    this.option = {
      ...this.option,
      ...option
    };
  };

  getListRemoteStreams = () => {
    const { rtc } = this;
    return [rtc.remoteClient.audioTrack, rtc.remoteClient.videoTrack].filter(
      i => i
    );
  };

  joinChannel = async (channel = this.option.channel, shareScreen = false) => {
    try {
      const {
        option,
        rtc,
        createAndPublishLocalTracks,
        setOption,
        dispatcher
      } = this;
      setOption({
        channel
      });

      let clientInstance = !shareScreen
        ? rtc.client.instance
        : rtc.shareScreenClient.instance;

      const uid = await clientInstance.join(
        option.appID,
        channel,
        null,
        option.uid
      );

      console.log('join channel: ' + option.channel + ' success, uid: ' + uid);
      dispatcher.setState('isInitSuccess', true);
      dispatcher.setState('isInitLocalStream', true);

      rtc.localUserIds.push(uid);
      if (shareScreen) rtc.shareScreenClient.uid = uid;
      createAndPublishLocalTracks(shareScreen, uid);
    } catch (err) {
      console.error('client join failed', err);
    }
  };

  leaveChannel = async ({ leaveOnlyShareScreen }) => {
    try {
      const { rtc, dispatcher, configVideoTrack } = this;
      if (rtc.client.instance && !leaveOnlyShareScreen) {
        rtc.client.videoTrack?.stop();
        rtc.client.videoTrack?.close();
        rtc.client.audioTrack?.stop();
        rtc.client.audioTrack?.close();
        rtc.remoteClient.videoTrack?.stop();
        rtc.remoteClient.videoTrack?.close();
        rtc.remoteClient.audioTrack?.stop();
        rtc.remoteClient.audioTrack?.close();

        await rtc.client.instance.leave();
        dispatcher.resetState();
        console.log('client leaves channel success');
      }
      if (rtc.shareScreenClient.instance && rtc.shareScreenClient.screenTrack) {
        rtc.shareScreenClient.screenTrack?.stop();
        rtc.shareScreenClient.screenTrack?.close();
        rtc.shareScreenClient.uid = null;
        // play remote client on main screen, play client on sub screen after turn off share screen
        rtc.remoteClient.audioTrack?.play();
        rtc.remoteClient.videoTrack?.play(
          DOM_MAIN_VIDEO_AREA,
          configVideoTrack
        );
        rtc.client.videoTrack?.play(DOM_SUB_VIDEO_AREA, configVideoTrack);

        dispatcher.setState('isSharing', false);
        await rtc.shareScreenClient.instance.leave();
      }
    } catch (err) {
      console.error('leaveChannel failed', err);
    }
  };

  createAndPublishLocalTracks = async (shareScreen, shareScreenUid) => {
    try {
      const {
        rtc,
        dispatcher,
        cbShareStream,
        patientId,
        createTracks,
        playLocalTracks,
        subscribeShareScreenTrackEndedEvent
      } = this;
      let tracks = await createTracks(shareScreen);

      let client = !shareScreen ? rtc.client : rtc.shareScreenClient;

      await client.instance.publish(tracks);

      //save track after publish success
      if (shareScreen) {
        client.screenTrack = tracks;
        playLocalTracks(shareScreen, client.screenTrack);
        dispatcher.setState('isSharing', true);
        cbShareStream(shareScreenUid, patientId);
        // subscribe for track-ended event
        subscribeShareScreenTrackEndedEvent(tracks);
      } else {
        client.audioTrack = tracks[0];
        client.videoTrack = tracks[1];
        playLocalTracks(shareScreen, client.videoTrack);
      }

      console.log('publish success!');
    } catch (err) {
      const { rtc } = this;
      let clientInstance = !shareScreen
        ? rtc.client.instance
        : rtc.shareScreenClient.instance;
      await clientInstance.leave();
      console.log('publish failed');
      console.error(err);
    }
  };

  createTracks = async shareScreen => {
    if (!shareScreen) {
      // Capture and audio and video at one time
      const [
        audioTrack,
        videoTrack
      ] = await AgoraRTC.createMicrophoneAndCameraTracks();
      return [audioTrack, videoTrack];
    } else {
      let trackParams = {
        // Set the encoder configurations. For details, see the API description.
        encoderConfig: '1080p_1',
        // Set the video transmission optimization mode as prioritizing video quality.
        optimizationMode: 'detail'
      };
      if (isFirefox()) {
        trackParams.screenSourceType = 'window';
      }
      const screenTrack = await AgoraRTC.createScreenVideoTrack(
        trackParams,
        'auto'
      );
      return screenTrack;
    }
  };

  playLocalTracks = (shareScreen, videoTrack) => {
    const { rtc, configVideoTrack } = this;
    if (shareScreen) {
      //stop local and remote tracks
      rtc.client.audioTrack?.stop();
      rtc.client.videoTrack?.stop();
      rtc.remoteClient.audioTrack?.stop();
      rtc.remoteClient.videoTrack?.stop();

      // play remote client on sub screen, play share screen on main screen
      rtc.remoteClient.audioTrack?.play();
      rtc.remoteClient.videoTrack?.play(DOM_SUB_VIDEO_AREA, {
        fit: 'contain'
      });
      videoTrack.play(DOM_MAIN_VIDEO_AREA, configVideoTrack);
    } else videoTrack.play(DOM_SUB_VIDEO_AREA, configVideoTrack);
  };

  subscribeShareScreenTrackEndedEvent = track => {
    const { rtc } = this;
    const self = this;
    track.on('track-ended', async () => {
      self.dispatcher.setState('isSharing', false);
      await rtc.shareScreenClient.screenTrack?.stop();
      await rtc.shareScreenClient.screenTrack?.close();
      await rtc.shareScreenClient.instance.leave();
    });
  };

  subscribeAudioAndVideoTracks = () => {
    const { rtc, configVideoTrack } = this;
    let client = rtc.client.instance;
    // Will be triggered when a remote user publishes an audio or video track
    client.on('user-published', async (user, mediaType) => {
      // Initiate the subscription
      // Prevent subscribing to your own streams (normal and share screen)
      if (!rtc.localUserIds.includes(user.uid)) {
        await client.subscribe(user, mediaType);
      } else return;

      // If the remote user publishes a video track.
      if (mediaType === 'video') {
        const videoTrack = user.videoTrack;
        rtc.remoteClient.videoTrack = videoTrack;

        if (rtc.shareScreenClient.uid) {
          // play remote client on sub screen when sharing
          rtc.remoteClient.videoTrack?.play(
            DOM_SUB_VIDEO_AREA,
            configVideoTrack
          );
        } else {
          rtc.remoteClient.videoTrack?.play(
            DOM_MAIN_VIDEO_AREA,
            configVideoTrack
          );
          rtc.client.videoTrack?.play(DOM_SUB_VIDEO_AREA, configVideoTrack);
        }
      }

      // If the subscribed track is an audio track
      if (mediaType === 'audio') {
        const audioTrack = user.audioTrack;
        audioTrack.play();
        rtc.remoteClient.audioTrack = audioTrack;
      }
    });

    // Will be triggered when a remote user publishes an audio or video track
    client.on('user-unpublished', async (user, mediaType) => {
      console.log('user-unpublished', user);
    });

    // Will be triggered when a remote user publishes an audio or video track
    client.on('user-left', async user => {
      const id = user.uid;
      // check if remote user
      if (rtc.remoteClient.videoTrack?.getUserId() === id)
        rtc.remoteClient.videoTrack = null;
      if (rtc.remoteClient.audioTrack?.getUserId() === id)
        rtc.remoteClient.audioTrack = null;

      // check if share screen user
      if (rtc.shareScreenClient.uid === id) {
        //stop local and remote tracks
        rtc.client.audioTrack?.stop();
        rtc.client.videoTrack?.stop();
        rtc.remoteClient.audioTrack?.stop();
        rtc.remoteClient.videoTrack?.stop();
        rtc.shareScreenClient.uid = null;

        // play remote client on main screen, play client on sub screen
        rtc.remoteClient.audioTrack?.play();
        rtc.remoteClient.videoTrack?.play(
          DOM_MAIN_VIDEO_AREA,
          configVideoTrack
        );
        rtc.client.videoTrack?.play(DOM_SUB_VIDEO_AREA, configVideoTrack);
      }
    });
  };

  handleSpeaker = isMuteSpeaker => {
    try {
      const { rtc } = this;
      this.dispatcher.setState('isMuteSpeaker', isMuteSpeaker);
      isMuteSpeaker
        ? rtc.remoteClient.audioTrack?.stop()
        : rtc.remoteClient.audioTrack?.play();
    } catch (err) {
      console.log('muteSpeaker failed');
      console.error(err);
    }
  };

  handleAuditAudio = isMuteAudio => {
    try {
      const { rtc } = this;
      this.dispatcher.setState('isMuteAudio', isMuteAudio);
      isMuteAudio
        ? rtc.client.audioTrack.setMuted(true)
        : rtc.client.audioTrack.setMuted(false);
    } catch (err) {
      console.log('muteAudio failed');
      console.error(err);
    }
  };

  handleAuditVideo = isMuteVideo => {
    try {
      const { rtc } = this;
      this.dispatcher.setState('isMuteVideo', isMuteVideo);
      isMuteVideo
        ? rtc.client.videoTrack.setMuted(true)
        : rtc.client.videoTrack.setMuted(false);
    } catch (err) {
      console.log('muteAudio failed');
      console.error(err);
    }
  };

  handleSharing = isSharing => {
    if (!isSharing) {
      this.joinChannel(this.option.channel, true);
    } else {
      this.leaveChannel({
        leaveOnlyShareScreen: true
      });
    }
  };
}

export default class NewAgoraCallAppointmentInstance extends Agora4xInstance {
  static instance = Agora4xInstance.instance || new Agora4xInstance();
}
