import React, { useState } from 'react';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import { TYPE_MODAL } from '../../common/constants/typeModal';
import TableContainer from '../../../components/Table/TableContainer';
import {
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  Menu,
  IconButton
} from '@material-ui/core';
import {
  DeleteRounded,
  CreateRounded,
  MoreVertRounded,
  CancelRounded,
  CheckCircleRounded,
  ArtTrackOutlined
} from '@material-ui/icons';

import { useSelector } from 'react-redux';
import MenuAction from '../../../components/MenuAction';
// import DeleteModal from '../../../components/DeleteModal';
// import CompanyModal from './CompanyModal';
import { studioDispatcher } from '..';
import StudioForm from './StudioForm';
import { userStatus } from '../../../enum/RequestEnum';
import { ChipStatus } from '../../common/componentUI/commonStyleComponents';
import { startCase, isEmpty } from 'lodash';
import DeleteModal from '../../../components/DeleteModal';
import CustomSelect from '../../../components/CustomSelect';
import MutipleSelection from '../../../components/CustomSelect/MutipleSelection';

const StudioManagement = () => {
  const { list, paging } = useSelector(state => state.studio);

  const [itemSelected, setItemSelected] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterOption, setFilterOption] = useState({});
  const [statusOpen, setStatusOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);

  const onShowModal = type => {
    setShowModal(true);
    setTypeModal(type);
    setAnchorEl(null);
  };

  const onCloseModal = () => {
    setShowModal(false);
    setTypeModal(null);
  };

  const onShowDeleteModal = () => {
    setDeleteModal(true);
    setAnchorEl(null);
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper label="Name" stateValue="name" />
        <TableCellFiltedWrapper label="Address" stateValue="address" />
        <TableCellFiltedWrapper label="Unit" stateValue="unit" />
        <TableCellFiltedWrapper label="Status" stateValue="status" />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
  const getListActions = () => {
    let actions = [
      {
        key: 'edit',
        icon: CreateRounded,
        label: 'Edit',
        onClick: () => onShowModal(TYPE_MODAL.Edit)
      },
      {
        key: 'delete',
        icon: DeleteRounded,
        label: 'Delete',
        onClick: onShowDeleteModal
      }
    ];
    if (itemSelected?.studioStatus === userStatus.Active)
      actions.push({
        key: 'deactive',
        icon: CancelRounded,
        label: 'Deactivate',
        onClick: () => onActive(userStatus.InActive)
      });
    else
      actions.push({
        key: 'active',
        icon: CheckCircleRounded,
        label: 'Activate',
        onClick: () => onActive(userStatus.Active)
      });

    return actions;
  };
  const renderMenuItem = () => {
    let listActions = getListActions();
    return <MenuAction listActions={listActions} />;
  };
  const onActive = studioStatus => {
    setAnchorEl(null);
    studioDispatcher.activeStudio(
      {
        ...itemSelected,
        studioStatus
      },
      searchKey,
      filterOption
    );
  };

  const handleDeleteStudio = () => {
    setDeleteModal(false);
    studioDispatcher.deleteStudio(itemSelected, searchKey, filterOption);
  };

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {renderMenuItem()}
      </Menu>
      <TableContainer
        title="Studio Management"
        paging={paging}
        data={list}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        query={studioDispatcher.getData}
        onAddNew={() => onShowModal(TYPE_MODAL.Create)}
        ToolbarComponent={
          <>
            <CustomSelect
              onClick={() => {
                setStatusOpen(!statusOpen);
              }}
              clearable={!isEmpty(statusFilter)}
              onClear={() => {
                setStatusFilter([]);
                setFilterOption({
                  ...filterOption,
                  studioStatuses: []
                });
              }}
              prefixIcon={
                <ArtTrackOutlined
                  style={{
                    fontSize: 20,
                    color: '#E9E9E9',
                    marginRight: 8
                  }}
                />
              }
              renderValue={<>{statusFilter.join(', ') || 'Select Status'}</>}
            >
              <MutipleSelection
                value={statusFilter}
                data={userStatus}
                open={statusOpen}
                onChange={e => {
                  setFilterOption({
                    ...filterOption,
                    studioStatuses: e.target.value
                  });
                  setStatusFilter(e.target.value);
                }}
              />
            </CustomSelect>
          </>
        }
        renderRow={row => (
          <>
            <TableCell>{!hiddenRows.name && row.name}</TableCell>
            <TableCell>{!hiddenRows.address && row.address}</TableCell>
            <TableCell>{!hiddenRows.unit && row.unit}</TableCell>
            <TableCell>
              {!hiddenRows.status && (
                <ChipStatus
                  color={
                    row.studioStatus === userStatus.Active
                      ? '#62CAA4'
                      : '#EA6B75'
                  }
                >
                  {startCase(row.studioStatus)}
                </ChipStatus>
              )}
            </TableCell>
            <TableCell align="right">
              <IconButton
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  setItemSelected(row);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
      />
      <Dialog maxWidth="md" open={showModal} onClose={onCloseModal}>
        <StudioForm
          onClose={onCloseModal}
          selectedItem={itemSelected}
          typeModal={typeModal}
          searchKey={searchKey}
        />
      </Dialog>
      {deleteModal && (
        <DeleteModal
          showModal={deleteModal}
          selectedItem={itemSelected}
          onClose={() => setDeleteModal(false)}
          onDelete={handleDeleteStudio}
          modalName="Studio"
        />
      )}
    </>
  );
};

export default StudioManagement;
