import livestreamDispatcher from '../actions/index';
import { get, unionBy } from 'lodash';
const initialState = {
  loading: false,
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  },
  listCategoryLivestream: [],
  listAdmin: [],
  listTrainer: [],
  listMessage: {},
  listMember: [],
  sessionDetail: {}
};

const livestreamReducer = livestreamDispatcher(initialState, {
  [livestreamDispatcher.sendMessageSuccess]: (state, payload) => {
    const { channelName, contentMessage } = payload;
    if (get(state.listMessage, channelName)) {
      const listMessage = [...state.listMessage[channelName], contentMessage];
      return {
        listMessage: {
          ...state.listMessage,
          [channelName]: listMessage
        }
      };
    }
    return {
      listMessage: {
        ...state.listMessage,
        [channelName]: [contentMessage]
      }
    };
  },
  [livestreamDispatcher.getListTrainerSuccess]: (state, payload) => ({
    listTrainer: get(payload, 'list')
  }),
  [livestreamDispatcher.getListManagerSuccess]: (state, payload) => ({
    listAdmin: get(payload, 'list')
  }),
  [livestreamDispatcher.getCategoryLivestreamSuccess]: (state, payload) => ({
    listCategoryLivestream: get(payload, 'list')
  }),
  [livestreamDispatcher.showHideLoadingAction]: (state, payload) => ({
    loading: payload.loading
  }),
  [livestreamDispatcher.getLivestreamListSuccess]: (state, payload) => ({
    list: payload.list,
    paging: {
      ...payload,
      list: undefined
    }
  }),
  [livestreamDispatcher.memberJoinedSuccess]: (state, payload) => ({
    listMember: unionBy(state.listMember, get(payload, 'member'), 'Id')
  }),
  [livestreamDispatcher.memberLeft]: (state, payload) => ({
    listMember: state.listMember.filter(
      member => member.Id !== get(payload, 'member.Id')
    )
  }),
  [livestreamDispatcher.clearMember]: () => ({ listMember: [] }),
  [livestreamDispatcher.getListSessionDetailSuccess]: (state, payload) => {
    return {
      ...state,
      sessionDetail: payload
    };
  }
});

export default livestreamReducer;
