import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DialogContent, DialogActions } from '@material-ui/core';
import { ClearRounded } from '@material-ui/icons';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import OperatingHoursItem from './OperatingHoursItem';
import { findIndex } from 'lodash';
import moment from 'moment';
import clinicDispatcher from '../actions';
import { mergeArrayByKey } from '../../../helpers';
import { get } from 'lodash';
import isValidNewDate from './validatetionTimePicker';

const OperatingHours = ({ clinicSelected, onClose }) => {
  const [operatingHours, setOperatingHours] = useState([
    { dayOfWeek: 1, name: 'monday', items: [] },
    { dayOfWeek: 2, name: 'tuesday', items: [] },
    { dayOfWeek: 3, name: 'wednesday', items: [] },
    { dayOfWeek: 4, name: 'thursday', items: [] },
    { dayOfWeek: 5, name: 'friday', items: [] },
    { dayOfWeek: 6, name: 'saturday', items: [] },
    { dayOfWeek: 0, name: 'sunday', items: [] }
  ]);

  useEffect(() => {
    clinicDispatcher.getOperatingHours(clinicSelected.id, data => {
      const mergedArr = mergeArrayByKey(operatingHours, data, 'dayOfWeek');
      setOperatingHours(mergedArr);
    });
  }, []);

  const foundChildIndex = (target, child) => {
    return findIndex(target, ['dayOfWeek', child.dayOfWeek]);
  };

  const onUpdateTimeFrame = (type, selectedItem, index) => e => {
    //Update to temp arr
    let arr = [...operatingHours];
    const foundItem = foundChildIndex(arr, selectedItem);
    const value = `${moment(e).format('HH:mm')}`;
    arr[foundItem].items[index][type] = value;
    const chosenItem = arr[foundItem].items[index];

    if (!isValidNewDate(operatingHours[foundItem].items, chosenItem)) {
      arr[foundItem].items[index].error = true;
      return setOperatingHours(arr);
    } else {
      arr[foundItem].items[index].error = false;
    }

    if (!chosenItem.id) {
      addTimeFrame(selectedItem, {
        startTime: chosenItem.startTime,
        endTime: chosenItem.endTime,
        index: index,
        value: value,
        type: type
      });
      return;
    }

    clinicDispatcher.updateOperatingHours(
      { data: chosenItem, id: chosenItem.id },
      () => {
        clinicDispatcher.onUpdateOperatingSuccess({
          clinicId: clinicSelected.id,
          operatingHours: arr
        });
        setOperatingHours(arr);
      }
    );
  };

  const addTimeFrame = (selectedItem, data) => {
    let addedData = {
      clinicId: clinicSelected.id,
      dayOfWeek: selectedItem.dayOfWeek,
      startTime: '09:00',
      endTime: '11:00'
    };
    let arr = [...operatingHours];
    const foundItem = foundChildIndex(arr, selectedItem); //find item position
    //create case: have data (call by onUpdateTimeFrame) or dayOfWeek have no item before
    if (arr[foundItem].items.length === 0 || data) {
      if (data) {
        addedData.startTime = data.startTime;
        addedData.endTime = data.endTime;
      }
      clinicDispatcher.createOperatingHours(addedData, res => {
        if (data) {
          arr[foundItem].items[data.index][data.type] = data.value;
          arr[foundItem].items[data.index].id = res.id;
        } else {
          arr[foundItem].items.push({
            id: res.id,
            startTime: res.startTime,
            endTime: res.endTime
          });
        }
        clinicDispatcher.onUpdateOperatingSuccess({
          clinicId: clinicSelected.id,
          operatingHours: arr
        });
        setOperatingHours(arr);
      });
    } else {
      arr[foundItem].items.push({
        newValue: true,
        startTime: '09:00',
        endTime: '11:00',
        error: true
      });
      setOperatingHours(arr);
    }
  };

  const deleteTimeFrame = (selectedItem, index) => {
    let arr = [...operatingHours];
    const id = get(
      arr[foundChildIndex(arr, selectedItem)],
      `items[${index}].id`
    );
    //if item is temp in arr
    if (!id) {
      arr[foundChildIndex(arr, selectedItem)].items.splice(index, 1);
      return setOperatingHours(arr);
    }

    clinicDispatcher.deleteOperatingHours(id, () => {
      arr[foundChildIndex(arr, selectedItem)].items.splice(index, 1);
      clinicDispatcher.onUpdateOperatingSuccess({
        clinicId: clinicSelected.id,
        operatingHours: arr
      });
      setOperatingHours(arr);
    });
  };

  return (
    <ModalPage>
      <DialogContent>
        <div className="header-container">
          {clinicSelected.name}
          <ClearRounded
            style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
            onClick={onClose}
          />
        </div>
        <div className="body-container">
          {operatingHours.map(operate => (
            <OperatingHoursItem
              data={operate}
              onUpdateTimeFrame={onUpdateTimeFrame}
              key={operate.dayOfWeek}
              addTimeFrame={() => addTimeFrame(operate)}
              deleteTimeFrame={deleteTimeFrame}
            />
          ))}
        </div>
        <ModalFooter>
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={onClose}
          >
            Cancel
          </ButtonEnhance>
        </ModalFooter>
      </DialogContent>
    </ModalPage>
  );
};

export default OperatingHours;

export const ModalPage = styled.div`
  max-width: 780px;
  width: 100vw;
  .MuiDialogContent-root {
    padding: 20px 0 0 0;
  }
  .header-container {
    padding: 0 20px 20px 20px;
    border-bottom: 1px solid #cccccc;
    font-weight: 600;
    font-size: 18px;
  }
  .body-container {
    max-height: 70vh;
    overflow-y: scroll;
    padding: 20px;
  }
  .section {
    padding-top: 20px;
    padding-bottom: 20px;
    .section-title {
      font-size: 16px;
      font-weight: 600;
      span {
        text-transform: uppercase;
      }
    }
    .section-container {
      background-color: #f7f7f7;
      border-radius: 10px;
      padding: 20px;
      .add-btn {
        max-width: 160px;
        .add-group {
          display: flex;
          align-items: center;
          margin-top: -2px;
          span {
            margin-left: 8px;
          }
        }
      }
      .operate-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
      .MuiFormControl-root {
        background: white;
        min-width: 220px;
        border-radius: 4px;
        .MuiSvgIcon-root {
          font-size: 14px;
          color: #cacfd3;
        }
        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      }
      .MuiOutlinedInput-inputAdornedEnd {
        padding: 10px 0;
      }
      .text-color {
        color: #8f9ba9;
        margin-left: 18px;
      }
      .delete-icon {
        cursor: pointer;
        font-size: 20px;
        color: #cacfd3;
        margin-top: 5px;
        margin-left: 18px;
      }
    }
  }
`;

export const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 10px 0px;
`;
