import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce, lowerCase } from 'lodash';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';

import {
  AddIcon,
  CheckmarkCircleIcon,
  DeleteModalIcon,
  RemoveIcon
} from '@/assets/svg';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import CustomTable from '@/new-components/CustomTable';

import serviceDispatcher from '@/module/services/action';
import CustomTooltip from '@/new-components/CustomTooltip';

const ServiceManagement = ({
  prefixName,
  category,
  noDataHelperText,
  noDataHelperSubText,
  columns,
  ModalContainer,
  requirePE,
  requireLabCode,

  searchKey,
  openDrawer,
  setOpenDrawer,
  isCreate,
  setIsCreate
}) => {
  const { list, paging } = useSelector(state => state.service[category]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchData = async (search = searchKey, pagingData = paging) => {
    serviceDispatcher.getTests(search, pagingData, category);
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize
        },
        category
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(searchKey, paging, category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const onDeleteTest = () => {
    serviceDispatcher.deleteTest(selectedItem.id, () => {
      serviceDispatcher.getTests(searchKey, undefined, category);
      setDeleteModal(false);
    });
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {columns.map(item => (
          <TableCell
            key={item.stateValue || item.label}
            style={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              width: item.width
            }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = row => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.name} />
        </TableCell>
        {requireLabCode && (
          <TableCell>
            <CustomTooltip content={row.labHeader} />
          </TableCell>
        )}
        <TableCell>
          <CustomTooltip content={row.stationName} />
        </TableCell>
        <TableCell>
          {row.isMandatory && (
            <CheckmarkCircleIcon
              style={{ color: '#0B8E5E', width: 24, height: 24 }}
            />
          )}
        </TableCell>
        {requirePE && (
          <TableCell>
            {row.hasPhysicalExamination && (
              <CheckmarkCircleIcon
                style={{ color: '#0B8E5E', width: 24, height: 24 }}
              />
            )}
          </TableCell>
        )}

        <TableCell>
          <IconButton
            onClick={() => {
              setIsCreate(false);
              setOpenDrawer(true);
              setSelectedItem(row);
            }}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeleteModal(true);
              setSelectedItem(row);
            }}
          >
            <RemoveIcon />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        disableBackdropClick
        PaperProps={{
          style: {
            width: '50%',
            minWidth: 768
          }
        }}
      >
        <ModalContainer
          selectedItem={selectedItem}
          isCreate={isCreate}
          onClose={() => {
            setOpenDrawer(false);
            fetchData();
          }}
        />
      </Drawer>

      <CustomTable
        data={list}
        paging={paging}
        header={TableHeader}
        renderRow={(row, i) => renderTableBody(row)}
        noDataHelperText={noDataHelperText}
        noDataHelperSubText={noDataHelperSubText}
        totalCols={columns?.length}
        fetchData={fetchData}
      />

      {deleteModal && (
        <ModalConfirmation
          confirmLabel="Delete"
          mainContent={`Delete ${lowerCase(prefixName)}`}
          subContent={`Are you sure you want to delete this ${lowerCase(
            prefixName
          )} ?`}
          open={deleteModal}
          confirmColor="red"
          Icon={<DeleteModalIcon />}
          onClose={() => setDeleteModal(false)}
          onClick={onDeleteTest}
        />
      )}
    </>
  );
};

export default ServiceManagement;
