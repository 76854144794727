import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Fab, Typography } from '@material-ui/core';
import { EditRounded } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { isString, get } from 'lodash';
import PropTypes from 'prop-types';
import { getFileNameByUrlFirebase } from '../../../../firebase';
import customToast from '@/new-components/CustomNotification';
import './styles.scss';
import { getBase64 } from '../../../../helpers';
import { dummyRequest } from '@/module/lab-result/utils';

export const TYPE_UPLOAD = {
  avatar: 'avatar',
  inline: 'inline',
  normal: 'normal'
};

const UploadSingle = ({
  index,
  disabled,
  onChange,
  onRemove,
  typeUpload,
  imageUrlSelected,
  avatarCircle,
  uploadButton,
  accept,
  name,
  error,
  setIsShowCropImageModal
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [fileName, setFileName] = useState(null);
  useEffect(() => {
    if (imageUrlSelected && isString(imageUrlSelected)) {
      setImageUrl(imageUrlSelected);
      setFileName(getFileNameByUrlFirebase(imageUrlSelected));
      return;
    }
    if (imageUrlSelected && imageUrlSelected?.constructor === File) {
      getBase64(imageUrlSelected, img => img && setImageUrl(img));
      setFileName(imageUrlSelected.name);
    }
  }, [imageUrlSelected]);

  const handleChange = info => {
    if (accept && !accept.includes(get(info, 'file.type', '').split('/')[1]))
      return customToast('error', 'Invalid supported file type');
    onChange(get(info, 'file.originFileObj'));
    getBase64(info.file.originFileObj, imageUrl => {
      imageUrl && setImageUrl(imageUrl);
    });
    setIsShowCropImageModal(true);
  };

  const triggerUpdate = e => {
    e.stopPropagation();
    const el = document.querySelector('.image-upload');
    el && el.click();
  };

  // Type Inline
  const handleChangeInline = info => {
    setFileName(info.file.name);
    onChange(get(info, 'file.originFileObj'));

    getBase64(info.file.originFileObj, imageUrl => {
      imageUrl && setImageUrl(imageUrl);
    });
  };

  const uploadButtonInline = (
    <div className="button-upload-inline">
      <PlusOutlined />
    </div>
  );

  const handleRemoveImage = e => {
    e.stopPropagation();
    setFileName(null);
    setImageUrl(null);
    onRemove(index);
  };

  const checkRenderIconEdit = () => {
    if (!disabled && typeUpload === TYPE_UPLOAD.avatar) {
      return (
        <Fab className="wrapper-icon-edit" onClick={triggerUpdate}>
          <EditRounded style={{ fontSize: 16 }} />
        </Fab>
      );
    }
  };

  const renderUploadAvatar = () => (
    <>
      <Upload
        disabled={disabled}
        name={name || 'avatar'}
        listType="picture-card"
        className={
          typeUpload === TYPE_UPLOAD.avatar
            ? 'upload-single-custom'
            : 'upload-normal-custom'
        }
        showUploadList={false}
        onChange={handleChange}
        accept={accept}
        customRequest={dummyRequest}
      >
        {imageUrl ? (
          <div className="wrapper-image">
            <img
              className={`image-upload ${avatarCircle ? 'avatar-circle' : ''}`}
              src={imageUrl}
              alt="avatar"
            />
            {checkRenderIconEdit()}
          </div>
        ) : (
          uploadButton
        )}
      </Upload>
    </>
  );

  const renderUploadInline = () => (
    <Upload
      disabled={disabled}
      name={name || 'inline'}
      listType="picture-card"
      className="upload-single-inline-custom"
      showUploadList={false}
      onChange={handleChangeInline}
      customRequest={dummyRequest}
    >
      {fileName ? (
        <div className="wrapper-selected">
          <div>{fileName}</div>
          {!disabled && (
            <div className="action-delete" onClick={handleRemoveImage}>
              <DeleteIcon className="icon-delete-upload" />
            </div>
          )}
        </div>
      ) : (
        uploadButtonInline
      )}
    </Upload>
  );

  return (
    <div className="upload-container">
      {(typeUpload === TYPE_UPLOAD.avatar ||
        typeUpload === TYPE_UPLOAD.normal) &&
        renderUploadAvatar()}
      {typeUpload === TYPE_UPLOAD.inline && renderUploadInline()}
      {error && <Typography color="error">{error}</Typography>}
    </div>
  );
};

UploadSingle.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  typeUpload: PropTypes.string,
  imageUrlSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  avatarCircle: PropTypes.bool,
  uploadButton: PropTypes.element,
  setIsShowCropImageModal: PropTypes.func
};

UploadSingle.defaultProps = {
  index: 0,
  onChange: () => null,
  onRemove: () => null,
  typeUpload: TYPE_UPLOAD.avatar,
  imageUrlSelected: '',
  disabled: false,
  avatarCircle: false,
  uploadButton: (
    <div className="button-upload">
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  ),
  setIsShowCropImageModal: () => {}
};

export default React.memo(UploadSingle);
