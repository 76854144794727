import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  font-size: 22px;
  padding: 15px;
  justify-content: space-between;
  border-right: 1px solid #cacfd3;
  border-bottom: 1px solid #cacfd3;
  .header {
    font-weight: 600;
    letter-spacing: normal;
  }
`;

export { Header };
