import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import CustomTabs from '@/new-components/CustomTabs';
import Measurements from '@/module/setup/components/ServiceItems/Measurements';
import Reviews from '@/module/setup/components/ServiceItems/Reviews';
import Tests from '@/module/setup/components/ServiceItems/Tests';
import Vaccinations from '@/module/setup/components/ServiceItems/Vaccinations';
import OptionalServiceForm from './OptionalServiceForm';
import {
  COLUMN_PROCEDURES,
  COLUMN_PROCEDURES_OUTSITE,
  COLUMN_PROFILE,
  COLUMN_PROFILE_OUTSITE,
  COLUMN_RADIOLOGIES,
  COLUMN_RADIOLOGIES_OUTSITE
} from '@/module/setup/constants';
import { packageDispatcher } from '@/module/package-management';
import globalDispatcher from '@/redux/global/actions';

const useStyles = makeStyles({
  leftContainer: {
    height: 'calc(100vh - 200px)'
  },
  rightContainer: {
    width: '100%',
    height: 'calc(100vh - 200px)',
    overflow: 'auto'
  }
});

const AvailableServicesStep = ({
  errors,
  setErrors,
  formValue,
  setFormValue
}) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const TABS = [
    {
      label: 'Tests',
      chipLabel: formValue?.lstTests?.length,
      body: (
        <Tests
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          requirePrice={false}
          removeColumns={['price']}
        />
      )
    },
    {
      label: 'Profiles',
      chipLabel: formValue?.lstProfiles?.length,
      body: (
        <OptionalServiceForm
          column={COLUMN_PROFILE}
          outSiteColumn={COLUMN_PROFILE_OUTSITE}
          titleTable="profiles"
          labelButton="profile"
          placeholder="Search by profile name..."
          listKey="lstProfiles"
          totalMandatoryKey="totalMandatoryProfile"
          formValue={formValue}
          setFormValue={setFormValue}
          errors={errors}
          getListOptions={(...props) =>
            packageDispatcher.getAllProfiles(...props)
          }
        />
      )
    },
    {
      label: 'Measurements',
      chipLabel: formValue?.lstMeasurements?.length,
      body: (
        <Measurements
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          requirePrice={false}
          removeColumns={['price']}
        />
      )
    },
    {
      label: 'Procedures',
      chipLabel: formValue?.lstProcedures?.length,
      body: (
        <OptionalServiceForm
          column={COLUMN_PROCEDURES}
          outSiteColumn={COLUMN_PROCEDURES_OUTSITE}
          titleTable="procedures"
          labelButton="procedure"
          placeholder="Search by procedure name..."
          listKey="lstProcedures"
          totalMandatoryKey="totalMandatoryProcedure"
          formValue={formValue}
          setFormValue={setFormValue}
          errors={errors}
          getListOptions={(...props) =>
            globalDispatcher.getListServiceByCategory('Procedures', ...props)
          }
        />
      )
    },
    {
      label: 'Radiologies',
      chipLabel: formValue?.lstRadiologies?.length,
      body: (
        <OptionalServiceForm
          column={COLUMN_RADIOLOGIES}
          outSiteColumn={COLUMN_RADIOLOGIES_OUTSITE}
          titleTable="radiologies"
          labelButton="radiology"
          placeholder="Search by radiology name..."
          listKey="lstRadiologies"
          totalMandatoryKey="totalMandatoryRadiology"
          formValue={formValue}
          setFormValue={setFormValue}
          errors={errors}
          getListOptions={(...props) =>
            globalDispatcher.getListServiceByCategory('Radiologies', ...props)
          }
        />
      )
    },
    {
      label: 'Vaccinations',
      chipLabel: formValue?.lstVaccinations?.length,
      body: (
        <Vaccinations
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          requirePrice={false}
          removeColumns={['price']}
        />
      )
    },
    {
      label: 'Reviews',
      chipLabel: formValue?.lstReviews?.length,
      body: (
        <Reviews
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          requirePrice={false}
          removeColumns={['price']}
        />
      )
    }
  ];

  return (
    <>
      <Box display="flex">
        <CustomTabs
          listTab={TABS}
          value={currentTab}
          onChange={(e, val) => {
            setCurrentTab(val);
          }}
          renderLabel="label"
          renderKey="label"
          orientation="vertical"
          height="calc(100vh - 200px)"
        />

        <main className={classes.rightContainer}>{TABS[currentTab].body}</main>
      </Box>
    </>
  );
};

export default AvailableServicesStep;
