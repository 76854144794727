import React, { useState } from 'react';
import {
  TextField,
  Grid,
  ClickAwayListener,
  makeStyles
} from '@material-ui/core';
import { DatePicker, PickersDay } from '@material-ui/pickers';
import { FORMAT_DATEOFF } from '@/module/setup/constants';

const useStyles = makeStyles(theme => ({
  highlight: {
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    },
    pointerEvents: 'none'
  }
}));

const ControlDatepicker = ({
  label,
  onChange,
  value,
  error,
  grid,
  variant,
  disableFuture,
  disablePast,
  inputFormat,
  disabled,
  gridWrapper = true,
  clickAwayListener = true,
  minDate,
  maxDate,
  selectedDates
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const renderPickerDay = selectedDates => (
    date,
    _selectedDates,
    DayComponentProps
  ) => {
    const formattedValue = date.format(FORMAT_DATEOFF);
    const isSelected = selectedDates.includes(formattedValue);

    return (
      <PickersDay
        {...DayComponentProps}
        className={isSelected && classes.highlight}
      />
    );
  };

  const renderChildren = () => {
    const children = (
      <DatePicker
        disabled={disabled}
        label={label}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disablePast={disablePast}
        disableFuture={disableFuture}
        inputFormat={inputFormat || 'DD/MM/YYYY'}
        minDate={minDate}
        maxDate={maxDate}
        renderDay={selectedDates && renderPickerDay(selectedDates)}
        renderInput={props => (
          <TextField
            className="date-picker-custom"
            variant={variant || 'standard'}
            {...props}
            helperText={error}
            error={!!error}
          />
        )}
        value={value}
        onChange={onChange}
        autoOk
      />
    );
    if (gridWrapper)
      return (
        <Grid item xs={grid || 6}>
          {children}
        </Grid>
      );
    return children;
  };

  if (!clickAwayListener) return renderChildren();

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      {renderChildren()}
    </ClickAwayListener>
  );
};

export default ControlDatepicker;
