import customToast from '@/new-components/CustomNotification';
import callDispatcher from '../redux/booking-appointment/actions';
import { transform, camelCase } from 'lodash';
import { PATIENT_REJOIN_TIMEOUT } from '../mink.config.json';
import { configure } from '..';

const signalR = require('@aspnet/signalr');

let hubConnection;
let timeOut;

const STATUS_HUB = {
  BEGIN_CALL: 'BeginCall',
  END_CALL: 'EndCall',
  REJOIN_CALL: 'RejoinCall',
  CANCEL_REJOIN: 'CancelRejoin',
  WAITING_REJOIN: 'WaitingRejoin',
  CALL_PROBLEM: 'CallProblem',
  REQUEST_CALL: 'RequestCall',
  CANCEL_REQUEST_CALL: 'CancelCallRequest',
  UNAVAILABLE: 'Unavailable',
  SUCCESS_REJOIN: 'SuccessRejoin',
  PLATO_ERROR: 'PlatoError'
};

export const initHubConnection = (token = '', userId) => {
  try {
    stopConnection();

    hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_BOOKING_APPOINTMENT_HUB_URL, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => token
      })
      .build();
    registerEvents();
    hubConnection.start().then(() => {
      joinGroup(userId);
    });

    return hubConnection;
  } catch (err) {
    console.log(err);
  }
};

async function tryReconnect(connection) {
  const currentUser = configure.store.getState().auth.userInfo;
  try {
    // Add triggle to call rejoin connection when reconnecting internet
    callDispatcher.setState('reconnectRejoinCall', true);
    let rs = await connection.start().then(() => joinGroup(currentUser.id));
    console.log('Reconnected successful!');
    return rs;
  } catch (e) {
    await new Promise(resolve => setTimeout(resolve, 5000));
    return await tryReconnect(connection);
  }
}

export const stopConnection = () => {
  try {
    if (hubConnection) hubConnection.stop();
  } catch (err) {
    console.log(err);
  }
};

export const joinGroup = userId => {
  try {
    hubConnection.invoke(
      'JoinGroup',
      userId,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  } catch (error) {
    console.log(error);
  }
};

export const funcClearTimeout = () => {
  if (timeOut) {
    clearTimeout(timeOut);
  }
};

export const registerEvents = () => {
  hubConnection.onclose(function(errors) {
    if (!errors) return;
    const isCalling = configure.store.getState().callBookingAppointment.calling;
    if (isCalling) tryReconnect(hubConnection);
    customToast('error', 'Your connection has been disconnected.');
  });
  const currentUser = configure.store.getState().auth.userInfo;
  const accessToken = configure.store.getState().auth.accessToken;

  hubConnection.on('NotifyToDoctor', patientInfo => {
    if (!patientInfo) return;
    funcClearTimeout();
    if (patientInfo.PatientAction === STATUS_HUB.SUCCESS_REJOIN) {
      return;
    }
    callDispatcher.updateDataAppointment(patientInfo);
    switch (patientInfo.PatientAction) {
      case STATUS_HUB.REQUEST_CALL: {
        callDispatcher.getMemberAppointment(patientInfo?.PatientId);
        callDispatcher.setState('openModalAcceptCall', true);
        break;
      }
      case STATUS_HUB.WAITING_REJOIN: {
        callDispatcher.setState('openModalAcceptCall', true);
        callDispatcher.setState('isRejoinCall', true);
        // save memberAppointments
        let memberAppointments = patientInfo?.MemberAppointments?.map(
          upperCaseData => {
            return transform(upperCaseData, function(result, val, key) {
              result[camelCase(key)] = val;
            });
          }
        );
        callDispatcher.setState('memberAppointment', memberAppointments);
        break;
      }
      case STATUS_HUB.REJOIN_CALL: {
        customToast('info', 'Patient rejoin call');
        funcClearTimeout();
        callDispatcher.setState('calling', true);
        break;
      }
      case STATUS_HUB.CALL_PROBLEM:
        timeOut = setTimeout(() => {
          customToast(
            'info',
            `The consult was cancelled due to connection problem`
          );
          callDispatcher.setState('calling', false);
        }, PATIENT_REJOIN_TIMEOUT * 1000);
        customToast('info', `CallProblem happen`);
        break;
      case STATUS_HUB.CANCEL_REQUEST_CALL: {
        customToast('info', `Patient cancel call`);
        callDispatcher.setState('openModalAcceptCall', false);
        break;
      }
      case STATUS_HUB.UNAVAILABLE:
      case STATUS_HUB.END_CALL:
      case STATUS_HUB.CANCEL_REJOIN: {
        customToast('info', 'Patient end call');
        // callDispatcher.endCall(patientInfo.AppointmentId);
        callDispatcher.endCall();
        funcClearTimeout();
        callDispatcher.setState('calling', false);
        callDispatcher.setState('openModalAcceptCall', false);
        callDispatcher.setState('openConsultModal', true);
        break;
      }
      case STATUS_HUB.PLATO_ERROR:
        customToast('error', `The Plato API not responding!`);
        // reset state
        callDispatcher.resetState();
        // reconnect
        initHubConnection(accessToken, currentUser.id);
        break;

      default:
        break;
    }
  });
};

export const rejoinCall = async (isJoin = true) => {
  try {
    const reconnectRejoinCall = configure.store.getState()
      .callBookingAppointment.reconnectRejoinCall;

    if (reconnectRejoinCall)
      callDispatcher.setState('reconnectRejoinCall', false);
    const rs = await hubConnection.invoke('RejoinCall', isJoin);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const shareScreenToPatient = async (streamId = '', patientId) => {
  try {
    let rs = await hubConnection.invoke('ShareScreen', patientId, streamId);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const rejectCall = async patientId => {
  try {
    let rs = await hubConnection.invoke('RejectPatient', patientId);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const approvePatient = async memberId => {
  try {
    let rs = await hubConnection.invoke('ApprovePatient', memberId);
    customToast('success', 'Approve Successful');
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const answerPatientRequest = async (patientId, isAccept) => {
  try {
    let rs = await hubConnection.invoke(
      'AnswerPatientRequest',
      patientId,
      isAccept
    );
    // bulk selectMemberAppointment
    const memberAppointment = configure.store.getState().callBookingAppointment
      .memberAppointment;
    bulkSelectMemberAppointment(memberAppointment);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const endCall = async patientId => {
  try {
    let rs = await hubConnection.invoke('EndCall', patientId);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const cancelCallRequestPatient = async patientId => {
  try {
    let rs = await hubConnection.invoke('CancelPatientRequest', patientId);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const selectMemberAppointment = async (apptId, isSelected) => {
  try {
    let rs = await hubConnection.invoke('SelectMemberAppt', apptId, isSelected);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const bulkSelectMemberAppointment = async memberAppointment => {
  try {
    let rs = await Promise.all(
      memberAppointment
        ?.filter(data => data.isSelected)
        ?.map(data =>
          selectMemberAppointment(data?.appointmentId, data?.isSelected)
        )
    );
  } catch (error) {
    console.log(error);
  }
};

export const unSelectAlltMemberAppointment = async () => {
  try {
    let rs = await hubConnection.invoke('SelectAllMemberAppts', false);
    return rs;
  } catch (error) {
    console.log(error);
  }
};
