import Enum from '@/enum/Enum';

export const HS_COLUMN_UPCOMING = [
  {
    label: 'Name',
    stateValue: 'name'
  },
  {
    label: 'ID number',
    stateValue: 'idNumber',
    minWidth: 112
  },
  {
    label: 'Service',
    stateValue: 'service'
  },
  {
    label: 'Date & Time',
    stateValue: 'date',
    sortBy: 'Date',
    minWidth: 112
  },
  {
    label: 'Package',
    stateValue: 'package'
  },
  {
    label: 'Location',
    stateValue: 'location'
  },
  {
    label: 'Total cost',
    stateValue: 'totalCost',
    minWidth: 112
  },
  {
    label: 'Status',
    stateValue: 'status'
  },
  {
    label: 'Action'
  }
];

export const HS_SERVICE_TYPES = Enum({
  MMS: 'MMS',
  HBS: 'HBS',
  EHS: 'EHS',
  CHS: 'CHS',
  Others: 'Others'
});

export const HS_PAXSCREENING_STATUS = {
  Upcoming: 'Upcoming',
  CheckIn: 'Registered',
  InProgress: 'In Progress',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Missed: 'Missed',
  Waiting: 'Waiting',
  CheckInLocation: 'Check In'
};

export const HS_PAXSCREENING_STATUS_COLORS = {
  [HS_PAXSCREENING_STATUS.Upcoming]: {
    txt: '#AD5E99',
    background: '#FFE9FB'
  },
  [HS_PAXSCREENING_STATUS.CheckIn]: {
    txt: '#3C7D0E',
    background: '#83cc2629'
  },
  [HS_PAXSCREENING_STATUS.InProgress]: {
    txt: '#9365E8',
    background: '#9365e829'
  },
  [HS_PAXSCREENING_STATUS.Completed]: {
    txt: '#0B8E5E',
    background: '#15a47029'
  },
  [HS_PAXSCREENING_STATUS.Cancelled]: {
    txt: '#333333',
    background: '#F1F1F1'
  },
  [HS_PAXSCREENING_STATUS.Missed]: {
    txt: '#DB3D49',
    background: '#db3d4929'
  },
  [HS_PAXSCREENING_STATUS.Waiting]: {
    txt: '#23A6CC',
    background: '#23a6cc29'
  },
  [HS_PAXSCREENING_STATUS.CheckInLocation]: {
    txt: '#E37F2A',
    background: '#e37f2a29'
  }
};

export const HS_UPCOMING_PAXSCREENING_STATUS = [
  {
    value: 'Upcoming',
    key: 'Upcoming'
  },
  {
    value: 'Cancelled',
    key: 'Cancelled'
  }
  // {
  //   value: 'Missed',
  //   key: 'Missed'
  // }
];

export const GENDER = Enum({
  Male: 'Male',
  Female: 'Female'
});

export const QUICK_PRICE_VALUE_RANGE = [
  'Under $200',
  '$200 - $500',
  '$1,000 - $2,000',
  '$2,000 up'
];

export const STEPS = [
  {
    name: 'Select patient'
  },
  {
    name: 'Select place and time'
  },
  {
    name: 'Select service'
  }
];

export const STEPS_CONTROL = {
  back: 'Back',
  next: 'Next',
  finish: 'Create'
};
