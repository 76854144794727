import React, { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import CustomTabs from '@/new-components/CustomTabs';
import RestrictServicesForm from './RestrictServicesForm';

const useStyles = makeStyles({
  leftContainer: {
    height: 'calc(100vh - 282px)'
  },
  rightContainer: {
    width: '100%',
    height: 'calc(100vh - 282px)',
    overflow: 'auto'
  }
});
const RestrictServices = ({
  listProcedures,
  listRadiologies,
  listPackages,
  restrictedPackageIds,
  setRestrictedPackageIds,
  restrictedTestIds,
  setRestrictedTestIds
}) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    setStatus('');
    setName('');
  }, [currentTab]);

  const TABS = [
    {
      label: 'Package',
      body: (
        <RestrictServicesForm
          isPackage
          setName={setName}
          name={name}
          setStatus={setStatus}
          status={status}
          prefix="package"
          prefixPlural="packages"
          placeholder="Search by package name..."
          listData={listPackages}
          selectedList={restrictedPackageIds}
          setSelectedList={setRestrictedPackageIds}
          restrictedTestIds={restrictedTestIds}
        />
      )
    },
    {
      label: 'Procedure',
      body: (
        <RestrictServicesForm
          setName={setName}
          name={name}
          setStatus={setStatus}
          status={status}
          prefix="procedure"
          prefixPlural="procedures"
          placeholder="Search by procedure name..."
          listData={listProcedures}
          selectedList={restrictedTestIds}
          setSelectedList={setRestrictedTestIds}
        />
      )
    },
    {
      label: 'Radiology',
      body: (
        <RestrictServicesForm
          setName={setName}
          name={name}
          setStatus={setStatus}
          status={status}
          prefix="radiology"
          prefixPlural="radiologies"
          placeholder="Search by radiology name..."
          listData={listRadiologies}
          selectedList={restrictedTestIds}
          setSelectedList={setRestrictedTestIds}
        />
      )
    }
  ];

  return (
    <>
      <Box display="flex">
        <CustomTabs
          listTab={TABS}
          value={currentTab}
          onChange={(e, val) => {
            setCurrentTab(val);
          }}
          renderLabel="label"
          renderKey="label"
          orientation="vertical"
          height="calc(100vh - 282px)"
        />

        <main className={classes.rightContainer}>{TABS[currentTab].body}</main>
      </Box>
    </>
  );
};

export default RestrictServices;
