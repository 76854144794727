import Enum from '@/enum/Enum';
import { CloudUpload, GetApp } from '@material-ui/icons';

const BACKROOM_MODULE_STATUS = Enum({
  Generated: 'Generated',
  Pending: 'Pending',
  Late: 'Late',
  Incomplete: 'Incomplete'
});

const BACKROOM_MODULE_STATUS_COLOR = Enum({
  Generated: '#34AA7F',
  Pending: '#2FC2D6',
  Late: '#E5C042',
  Incomplete: '#CC454F'
});

const renderColumn = () => {
  let tableComlumn = [
    {
      label: 'Name',
      stateValue: 'fullname'
    },
    {
      label: 'NRIC',
      stateValue: 'identityNumber'
    },
    {
      label: 'Date & Time',
      stateValue: 'datetime'
    },
    {
      label: 'IR Status',
      stateValue: 'backroomStatus'
    },
    {
      label: '',
      stateValue: null
    }
  ];

  return tableComlumn;
};

const renderListAction = (event, isCheck, isDisable = false) => {
  const LIST_ACTIONS = [
    {
      key: 'download',
      icon: GetApp,
      label: 'Download Result',
      onClick: () => event('download'),
      disabled: !isDisable
    }
  ];

  if (isCheck) {
    LIST_ACTIONS.unshift({
      key: 'upload',
      icon: CloudUpload,
      label: 'Upload Result',
      onClick: () => event('upload'),
      disabled: false
    });
  }
  return LIST_ACTIONS;
};

export {
  BACKROOM_MODULE_STATUS,
  BACKROOM_MODULE_STATUS_COLOR,
  renderColumn,
  renderListAction
};
