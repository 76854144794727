import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  makeStyles,
  TableBody,
  TextField,
  Paper,
  Typography,
  IconButton,
  withStyles
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import renderColumns from './renderColumn';
import { formatTimeShowUI } from '../../../setup/utils';
import { isEmpty } from 'lodash';
import { IOSSwitch } from '@/module/setup/components/mainScreen.styled';
import { RemoveIcon } from '@/assets/svg';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 36
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const useStyles = makeStyles(theme => ({
  table: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 350px)',
    borderRadius: 11,

    '& .MuiTableHead-root > .MuiTableRow-root': {
      height: 40,
      backgroundColor: '#f1f1f1',

      '& .MuiTableCell-root': {
        padding: '8px 16px',
        backgroundColor: 'transparent',

        fontSize: 12,
        fontWeight: 500
      }
    }
  },
  tableBody: {
    '& .MuiTableRow-root': {
      height: 44,

      '& .MuiTableCell-root': {
        padding: '12px 16px',
        fontSize: 16,
        verticalAlign: 'middle'
      }
    }
  },
  btnAddTimeSlot: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  infoSecondary: {
    fontSize: '14px',
    fontWeight: 600
  },
  button: {
    borderRadius: 50,
    backgroundColor: '#F6F7FA',
    color: '#AD5E99'
  },
  tableTimeContainer: {
    minHeight: 350,
    maxHeight: 'calc(100vh - 580px)',
    overflowY: 'auto'
  },
  loading: {
    width: '100%',
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noDataText: {
    padding: 16,
    color: theme.palette.grey.disableText
  }
}));

const TableTimeSlot = ({
  data,
  handleChangeDataTimeSlot,
  loading = false,
  onDeleteTemplate
}) => {
  const classes = useStyles();
  const tableColumn = renderColumns();
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(({ label, width }) => (
          <TableCell key={label} style={{ width }}>
            {label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
  return (
    <>
      <div>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          <TableContainer component={Paper} variant="outlined">
            <Table className={classes.table} stickyHeader>
              <TableHeader />
              <TableBody className={classes.tableBody}>
                {!isEmpty(data) ? (
                  data.map((row, index) => (
                    <TableRow key={`${row.startTime}-${index}`}>
                      <TableCell component="th" scope="row" align="left">
                        {row.startTime && formatTimeShowUI(row.startTime)}
                      </TableCell>
                      <TableCell align="left">
                        {row.endTime && formatTimeShowUI(row.endTime)}
                      </TableCell>
                      <TableCell align="left">{row.interval} min</TableCell>
                      <TableCell align="left">
                        <CustomTextField
                          variant="outlined"
                          value={row.quotaBase}
                          size="small"
                          onChange={e =>
                            handleChangeDataTimeSlot(row, {
                              key: 'quotaBase',
                              value: e.target.value
                            })
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <IOSSwitch
                          checked={row.isActive}
                          color="primary"
                          name="isActive"
                          size="medium"
                          onChange={e =>
                            handleChangeDataTimeSlot(row, {
                              key: 'isActive',
                              value: e.target.checked
                            })
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => onDeleteTemplate(row, index)}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <Typography className={classes.noDataText}>
                      No time slot
                    </Typography>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
};

export default TableTimeSlot;
