import { TYPE_MODAL } from '../constants';
import CustomModalTitle from '@/new-components/CustomModal/CustomModalTitle';
import { FormHelperText, InputAdornment } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { cloneDeep, isEmpty } from 'lodash';
import { useEffect } from 'react';
import React, { useState, useRef, useMemo } from 'react';
import NumberFormat from 'react-number-format';

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,
    fontWeight: 600,
  },
})(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
  },
})(TextField);

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 480,
  },
})(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    '&.red': {
      background: '#DB3D49',
    },
    '&.Mui-disabled': {
      opacity: 0.3,
      color: '#ffffff',
      backgroundColor: '#AD5E99',
    },
  },
  containedPrimary: {
    boxShadow: 'none',
  },
})(Button);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}))(DialogContent);

const CustomDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
}))(DialogActions);

const CustomFormHelperText = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'OpenSans',
    fontSize: 12,
    gap: 6,
    marginLeft: 4,
    marginTop: 0,

    '& .MuiSvgIcon-root': {
      fontSize: 'unset',
    },
  },
})(FormHelperText);

const ModalAddOrEditBundlePricing = ({
  open,
  title,
  onSubmit,
  onClose,
  selectedItem,
  typeModal,
  productPrices,
}) => {
  const [formValue, setFormValue] = useState({
    quantity: 0,
    price: 0,
    note: '',
  });
  const [errors, setErrors] = useState({});
  const _tempForm = useRef(null);

  useEffect(() => {
    /** Block code to cache initial form data on edit mode */
    const handleExistItem = () => {
      setFormValue(selectedItem.item);
      _tempForm.current = cloneDeep(selectedItem.item);
    };
    !isEmpty(selectedItem.item)
      ? handleExistItem()
      : setFormValue({
          quantity: 0,
          price: 0,
        });
  }, [typeModal, selectedItem]);

  const handleChangeForm = (key) => (e, value) => {
    let newValue = '';

    switch (key) {
      case 'price':
      case 'quantity':
        newValue = e.floatValue;
        break;

      default:
        newValue = e.target.value;
        break;
    }

    setFormValue({ ...formValue, [key]: newValue });
  };

  const handleSubmit = () => {
    const isQuantityExisted = (productPrices || []).some((d) => {
      const quantityMatches = Number(d.quantity) === Number(formValue.quantity);

      if (formValue.id) {
        // Check if any item in productPrices has the same quantity but a different id
        return formValue.id !== d.id && quantityMatches;
      } else {
        // Check if any item in productPrices has the same properties as formValue
        const objectExists = productPrices.some(
          (item) => Number(item.quantity) === Number(formValue.quantity)
        );
        return (
          objectExists &&
          quantityMatches &&
          Number(selectedItem?.item?.quantity) !== Number(formValue.quantity)
        );
      }
    });

    if (isQuantityExisted) {
      setErrors({
        quantity:
          'The quantity has already existed. Please enter another quantity!',
      });
    } else {
      setErrors({});
      onSubmit(typeModal, formValue, selectedItem.position);
    }
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = ['price', 'quantity'];
    const checkValid = filterKeys.some((key) => !formValue[key]);
    return checkValid;
  }, [formValue]);

  return (
    <CustomDialog
      maxWidth="md"
      fullWidth
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomModalTitle onClose={onClose}>{title}</CustomModalTitle>
      <Divider light />
      <CustomDialogContent>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <CustomInputLabel>Bundle quantity</CustomInputLabel>
            <NumberFormat
              fullWidth
              customInput={CustomTextField}
              variant="outlined"
              placeholder="Enter number"
              onValueChange={handleChangeForm('quantity')}
              allowNegative={false}
              value={formValue.quantity || ''}
              decimalSeparator={false}
            />

            {errors.quantity && (
              <CustomFormHelperText error>
                {errors.quantity}
              </CustomFormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <CustomInputLabel>Price</CustomInputLabel>
            <NumberFormat
              fullWidth
              customInput={CustomTextField}
              variant="outlined"
              placeholder="0.00"
              onValueChange={handleChangeForm('price')}
              allowNegative={false}
              value={formValue.price || ''}
              decimalScale={2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </CustomDialogContent>
      <Divider light />
      <CustomDialogActions>
        <CustomButton
          disabled={isEmptyFilterParams}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          autoFocus
          size="small"
        >
          {typeModal === TYPE_MODAL.Create ? 'Add' : 'Save'}
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalAddOrEditBundlePricing;
