import { healthProfileDispatcher } from '..';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  },
  allProfiles: [],
  allTests: []
};

const healthProfileReducer = healthProfileDispatcher(initialState, {
  [healthProfileDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [healthProfileDispatcher.getProfilesDataSuccess]: (state, payload) => ({
    allProfiles: payload.data
  }),
  [healthProfileDispatcher.getTestDataSuccess]: (state, payload) => ({
    allTests: payload.data
  })
});

export default healthProfileReducer;
