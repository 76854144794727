/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Viewer from 'react-viewer';
const ImageViewerEnhanced = ({ disabled, visible, onClose, src }) => {
  if (disabled) return null;
  return (
    <Viewer
      zIndex={9999}
      visible={visible}
      scalable={false}
      drag={false}
      onClose={onClose}
      images={[
        {
          src: src,
          alt: 'img-preview'
        }
      ]}
    />
  );
};

export default ImageViewerEnhanced;
