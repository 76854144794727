import { map, isNumber } from 'lodash';

export const editTypeData = ({
  position,
  values,
  setValues,
  key,
  callback
}) => {
  let newVal = [];
  newVal = map(values[key] || values, (item, index) => ({
    ...item,
    isEdit: index === position
  }));
  callback && callback(newVal);
  setValues &&
    (key ? setValues({ ...values, [key]: newVal }) : setValues(newVal));
  return newVal;
};

export const formatPrice = (data = []) => {
  const result =
    map(data, item => ({
      ...item,
      price: isNumber(item.price) ? item.price : 0,
      billableAmount: isNumber(item.billableAmount) ? item.billableAmount : 0
    })) || [];

  return result;
};
