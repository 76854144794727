import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';

const TableUI = ({ headCells, bodyCells }) => {
  return (
    <Table>
      <TableHeadStyle>
        <TableRow>{headCells}</TableRow>
      </TableHeadStyle>

      <TableBody>{bodyCells}</TableBody>
    </Table>
  );
};

const TableHeadStyle = styled(TableHead)`
  th {
    font-weight: bold;
  }
`;

export default TableUI;
