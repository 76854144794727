import caQueueDispatcher from '../action/caQueue';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  }
};

const caQueueReducer = caQueueDispatcher(initialState, {
  [caQueueDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data
    // paging: {
    //   ...payload.data,
    //   list: undefined
    // }
  })
});

export default caQueueReducer;
