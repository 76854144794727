import React from 'react';
import styled from 'styled-components';
import { TextField, Typography } from '@material-ui/core';
import { StyledButton } from '../../../common/componentUI/commonStyleComponents';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const UpdateInfo2 = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  credentials,
  setCredentials,
  clinicId,
  setClinicId,
  clinics,
  toVerifyOTP
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 546,
        width: 380,
        borderRadius: 10,
        backgroundColor: 'white',
        padding: '40px 20px',
        transition: 'all 0.1s'
      }}
    >
      <Typography
        style={{
          fontSize: 24,
          letterSpacing: 0,
          fontWeight: 600
        }}
      >
        Update your info
      </Typography>
      <TextFieldStyle
        label="First Name"
        helperText="This will be reflected in all documents (eg receipt, MC etc)"
        margin="normal"
        value={firstName}
        FormHelperTextProps={{
          style: { fontSize: 10 }
        }}
        InputProps={{
          style: { fontSize: 14, color: '#192637', letterSpacing: 0 }
        }}
        onChange={e => setFirstName(e.target.value)}
      />
      <TextFieldStyle
        label="Last Name"
        helperText="This will be reflected in all documents (eg receipt, MC etc)"
        margin="normal"
        value={lastName}
        FormHelperTextProps={{
          style: { fontSize: 10 }
        }}
        InputProps={{
          style: { fontSize: 14, color: '#192637', letterSpacing: 0 }
        }}
        onChange={e => setLastName(e.target.value)}
      />
      <TextFieldStyle
        label="Credentials"
        margin="normal"
        value={credentials}
        InputProps={{
          style: {
            fontSize: 14,
            color: '#192637',
            letterSpacing: 0
          }
        }}
        onChange={e => setCredentials(e.target.value)}
      />
      <FormControl style={{ width: 340, marginTop: 10, marginBottom: 25 }}>
        <InputLabel>Clinic</InputLabel>
        <Select
          value={clinicId}
          onChange={event => setClinicId(event.target.value)}
        >
          {clinics.map(c => (
            <MenuItem
              key={c.id}
              value={c.id}
            >{`${c.name} - ${c.address}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <StyledButton style={{ marginTop: 30 }} onClick={toVerifyOTP}>
        Update
      </StyledButton>
    </div>
  );
};

const TextFieldStyle = styled(TextField)`
  height: 40px;
  width: 340px;
  height: 80px;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
`;

export default UpdateInfo2;
