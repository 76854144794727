import React from 'react';
import { PlusIcon } from '../../../../assets/svg';
import { Header } from './styles/common';
import { TextField, Badge } from '@material-ui/core';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { formatUsername } from '../../helpers';
import { ROLE_NAME, ROLE_COLOR } from './constants';
import { get } from 'lodash';
import { isUserOnline, isPeerChatting } from './helper';

const ChatLeftPage = ({
  switchChat,
  currentRoom,
  selectedLivestream,
  roleName,
  listRoom,
  onSelectSearchChat
}) => {
  const { listMember } = useSelector(state => state.livestream);
  const ChannelId = useSelector(state =>
    get(state, 'upcoming.channelData.ChannelId')
  );
  const formatListMember = () => {
    return !!listMember.length
      ? listMember.map(item => {
          const name =
            item.UserType === 'PublicUser'
              ? item.FullName
              : item.FirstName + ' ' + item.LastName;
          return {
            id: formatUsername(
              name,
              item.UserType === 'PublicUser' ? ChannelId : item.Id
            ),
            name,
            roleType: item.UserType
          };
        })
      : [];
  };

  return (
    <>
      <Header>
        <span className="header">{selectedLivestream.title}</span>
        <span>
          <PlusIcon />
        </span>
      </Header>
      <Content>
        <Autocomplete
          getOptionLabel={option => option.name || ''}
          options={formatListMember()}
          value={{
            id: currentRoom,
            name: listRoom.find(room => room.id === currentRoom)?.name,
            roleType: listRoom.find(room => room.id === currentRoom)?.roleType
          }}
          autoComplete
          onChange={(e, newValue) => {
            newValue && onSelectSearchChat(newValue);
          }}
          renderInput={params => <WrapperInput {...params} label="Search" />}
        />
        <div className="group-chat">
          {listRoom.map(chat => {
            if (roleName === 'Patient' && chat.id.includes('cohostRoom'))
              return null;
            return (
              <GroupChatItem
                key={chat.id}
                className={currentRoom.includes(chat.id) && 'selected'}
                onClick={() => switchChat(chat.id)}
              >
                <div className="header">
                  {isPeerChatting(chat.id) && (
                    <StatusStyled
                      isOnline={() =>
                        isUserOnline(listMember, chat.id, ChannelId)
                      }
                    >
                      <Badge badgeContent="" color="primary" />
                    </StatusStyled>
                  )}
                  <span className="title">{chat.title}</span>
                  {isPeerChatting(chat.id) && (
                    <RoleNameStyled roleName={chat.roleType}>
                      {`(${ROLE_NAME[chat.roleType] || 'User'})`}
                    </RoleNameStyled>
                  )}
                </div>

                <div className="message">
                  <div className="text">{chat.message}</div>
                  {chat.hasNewChat && (
                    <div className="number-message">
                      <Badge badgeContent="" color="primary" />
                    </div>
                  )}
                </div>
              </GroupChatItem>
            );
          })}
        </div>
      </Content>
    </>
  );
};
const WrapperInput = styled(TextField)`
  width: 100%;
`;

const GroupChatItem = styled.div`
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
  &.selected {
    background: #f7f7f7;
    border-radius: 10px;
  }
  .header {
    margin-bottom: 6px;
    .title {
      font-size: 16px;
      font-weight: 600;
      max-width: 90%;
      word-break: break-word;
    }
    .time {
      color: #b0b8c2;
    }
  }
  .message {
    display: flex;
    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 90%;
      text-transform: none;
    }
    .number-message {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      .MuiBadge-badge {
        transform: unset;
      }
    }
  }
`;

const RoleNameStyled = styled.span`
  color: ${({ roleName }) => ROLE_COLOR[roleName] || '#8F9BA9'};
  margin-left: 5px;
`;

const StatusStyled = styled.span`
  margin-right: 10px;
  margin-left: 5px;
  .MuiBadge-badge {
    background: ${({ isOnline }) => (isOnline() ? '#31a24c' : '#8F9BA9')};
    min-width: 8px;
    height: 8px;
    padding: 0 4px;
  }
`;

const Content = styled.div`
  padding: 15px;
  overflow: auto;
  text-transform: capitalize;
  .group-chat {
    margin-top: 20px;
  }
  // Custom scroll bar
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #e9e9e9;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cacfd3;
  }
`;
export default ChatLeftPage;
