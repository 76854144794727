import styled from 'styled-components';
import { FormControl, Select } from '@material-ui/core';

export const WrapperFormControl = styled(FormControl)`
  width: 100%;
`;

export const WrapperSelect = styled(Select)`
  width: 100%;
`;
