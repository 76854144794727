import React from 'react';
import ServiceForm from '../../common/ServiceForm';

const MeasurementForm = props => (
  <ServiceForm
    {...{
      ...props,
      category: 'Measurements',
      prefixName: 'Measurement',
      requireLabCode: true
    }}
  />
);

export default MeasurementForm;
