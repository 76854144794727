import { RemoveIcon } from '@/assets/svg';
import CustomAdapter from '@/new-components/CustomDate/CustomDateAdapter';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { Box, Grid, TextField, makeStyles } from '@material-ui/core';
import { LocalizationProvider, PickersDay } from '@material-ui/pickers';
import classnames from 'classnames';
import moment from 'moment';
import React, { useState, useRef } from 'react';
import { FORMAT_DATEOFF } from '../../constants';
import {
  ClosingDateContainer,
  ClosingHeader,
  ClosingRow,
  DateText,
  NoDateText,
  OperationTitle,
  OperationTitleRow,
  StyledDatepicker
} from '../../createAndEditLocation.styled';

const useStyles = makeStyles(theme => ({
  highlight: {
    borderRadius: '50%',
    backgroundColor: '#ad5e99',
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  container: {
    display: 'flex',
    width: 850,
    margin: '0 auto',
    gap: '20px'
  },

  rightContainer: {
    padding: '20px 0',
    height: 'calc(100vh - 282px)',
    flex: '1'
  },

  leftContainer: {
    padding: '20px 0',
    flex: '1',

    height: 'calc(100vh - 282px)',
    maxWidth: 352,
    overflow: 'auto'
  }
}));

const OperationDate = ({ selectedDates, onSelectDate, disabled = false }) => {
  const classes = useStyles();
  const _tempDate = useRef(moment());
  const renderPickerDay = (date, _selectedDates, DayComponentProps) => {
    const formattedValue = date.format(FORMAT_DATEOFF);
    const isSelected = selectedDates.includes(formattedValue);

    return (
      <div
        onClick={() => (disabled ? {} : onSelectDate(date))}
        key={formattedValue}
      >
        <PickersDay
          {...DayComponentProps}
          disabled={disabled}
          selected={false}
          className={classnames(
            isSelected && {
              [classes.highlight]: true
            }
          )}
        />
      </div>
    );
  };
  const [year, setYear] = useState([2023]);
  const [date, setDate] = useState(moment());
  const currentYear = new Date().getFullYear();
  const YEARS = Array.from({ length: 5 }, (_, index) => ({
    key: currentYear + index,
    value: currentYear + index
  }))
    .reverse()
    .concat(
      Array.from({ length: 12 }, (_, index) => ({
        key: currentYear - index - 1,
        value: currentYear - index - 1
      }))
    );

  const handleDatePickerChange = value => {
    _tempDate.current = value;
    onSelectDate && value && onSelectDate(value);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.leftContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OperationTitleRow>
              <OperationTitle>Closing date</OperationTitle>
              <CustomSelectContainer
                isFullWidth
                filterValue={year}
                data={YEARS}
                renderValue={<>{year.length ? year[0] : 'Select a year'}</>}
                isMutiple={false}
                onChange={e => {
                  setYear([e.target.value]);
                  // onSelectDate(moment(e.target.value, 'YYYY').endOf('year'));
                  setDate(moment(_tempDate.current).year(e.target.value));
                }}
              />
            </OperationTitleRow>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={CustomAdapter}>
              <StyledDatepicker
                value={date}
                displayStaticWrapperAs="desktop"
                onChange={handleDatePickerChange}
                renderDay={renderPickerDay}
                renderInput={props => <TextField {...props} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.rightContainer}>
        <Grid item xs={12}>
          <Box height={63}></Box>
        </Grid>
        <Grid item xs={12}>
          <ClosingDateContainer>
            <ClosingHeader container>
              <Grid item xs={10}>
                Closing dates
              </Grid>
              <Grid item xs={2}>
                Action
              </Grid>
            </ClosingHeader>
            {selectedDates.length ? (
              selectedDates.map(date => (
                <ClosingRow container key={date}>
                  <Grid item xs={10}>
                    <DateText>{date}</DateText>
                  </Grid>

                  <Grid item xs={2}>
                    <Box
                      style={{ cursor: 'pointer' }}
                      onClick={() => onSelectDate(moment(date))}
                    >
                      <RemoveIcon />
                    </Box>
                  </Grid>
                </ClosingRow>
              ))
            ) : (
              <ClosingRow container>
                <Grid item xs={8}>
                  <NoDateText>No date selected</NoDateText>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}></Grid>
              </ClosingRow>
            )}
          </ClosingDateContainer>
        </Grid>
      </Box>
    </Box>
  );
};

export default OperationDate;
