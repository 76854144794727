import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import CustomTabs from '@/new-components/CustomTabs';
import Measurements from '@/module/setup/components/ServiceItems/Measurements';
import Procedures from '@/module/setup/components/ServiceItems/Procedures';
import Radiologies from '@/module/setup/components/ServiceItems/Radiologies';
import Reviews from '@/module/setup/components/ServiceItems/Reviews';
import Tests from '@/module/setup/components/ServiceItems/Tests';
import Vaccinations from '@/module/setup/components/ServiceItems/Vaccinations';

const useStyles = makeStyles({
  leftContainer: {
    height: 'calc(100vh - 200px)'
  },
  rightContainer: {
    width: '100%',
    height: 'calc(100vh - 200px)',
    overflow: 'auto'
  }
});

const TabsServices = ({ errors, formValue, setFormValue }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const TABS = [
    {
      label: 'Tests',
      chipLabel: formValue?.lstTests?.length,
      body: (
        <Tests
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          isAssigned={false}
          requireStation={false}
          requirePrice={false}
          removeColumns={['price', 'station']}
        />
      )
    },
    {
      label: 'Measurements',
      chipLabel: formValue?.lstMeasurements?.length,
      body: (
        <Measurements
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          isAssigned={false}
          requireStation={false}
          requirePrice={false}
          requireCode={false}
          removeColumns={['price', 'station', 'code']}
        />
      )
    },
    {
      label: 'Procedures',
      chipLabel: formValue?.lstProcedures?.length,
      body: (
        <Procedures
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          isAssigned={false}
          requireStation={false}
          requirePrice={false}
          removeColumns={['price', 'station', 'code']}
        />
      )
    },
    {
      label: 'Radiologies',
      chipLabel: formValue?.lstRadiologies?.length,
      body: (
        <Radiologies
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          isAssigned={false}
          requireStation={false}
          requirePrice={false}
          removeColumns={['price', 'station']}
        />
      )
    },
    {
      label: 'Vaccinations',
      chipLabel: formValue?.lstVaccinations?.length,
      body: (
        <Vaccinations
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          isAssigned={false}
          requireStation={false}
          requirePrice={false}
          removeColumns={['price', 'station']}
        />
      )
    },
    {
      label: 'Reviews',
      chipLabel: formValue?.lstReviews?.length,
      body: (
        <Reviews
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          isAssigned={false}
          requireStation={false}
          requirePrice={false}
          removeColumns={['price', 'station']}
        />
      )
    }
  ];

  return (
    <>
      <Box display="flex">
        <CustomTabs
          listTab={TABS}
          value={currentTab}
          onChange={(e, val) => {
            setCurrentTab(val);
          }}
          renderLabel="label"
          renderKey="label"
          orientation="vertical"
          height="calc(100vh - 200px)"
        />

        <main className={classes.rightContainer}>{TABS[currentTab].body}</main>
      </Box>
    </>
  );
};

export default TabsServices;
