import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import Enum from '@/enum/Enum';
import { MenuIcon } from '@/assets/svg';

import CustomDateRange from '../../../../components/CustomDateRange';
import CustomSelect from '../../../../components/CustomSelect';
import MutipleSelection from '../../../../components/CustomSelect/MutipleSelection';

const SearchLivestream = ({ filterOption, setFilterOption, onSearch }) => {
  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [workoutTypeOpen, setWorkoutTypeOpen] = useState(false);
  const [workoutTypeFilter, setWorkoutTypeFilter] = useState([]);
  const { categories } = useSelector(state => state.globalState);
  let enumWorkoutType = {};

  categories.forEach(category => {
    const title = category.title;
    enumWorkoutType = { ...enumWorkoutType, [title]: category.id };
  });
  return (
    <>
      <CustomDateRange
        selectedDate={selectedDate}
        onChange={(fromDate, toDate) => {
          setSelectedDate([fromDate, toDate]);

          setFilterOption({
            ...filterOption,
            fromDate,
            toDate
          });
        }}
      />
      <CustomSelect
        onClick={() => setWorkoutTypeOpen(!workoutTypeOpen)}
        clearable={!isEmpty(workoutTypeFilter)}
        onClear={() => {
          setWorkoutTypeFilter([]);
          setFilterOption({
            ...filterOption,
            categoriesId: []
          });
        }}
        prefixIcon={
          <div style={{ marginRight: 5 }}>
            <MenuIcon width="13px" height="13px" color="#ccc" />
          </div>
        }
        renderValue={
          <>
            {workoutTypeFilter && workoutTypeFilter.length
              ? `Workout Type Selected (${workoutTypeFilter.length})`
              : 'Workout Type'}
          </>
        }
      >
        <MutipleSelection
          value={workoutTypeFilter}
          data={Enum(enumWorkoutType)}
          open={workoutTypeOpen}
          onChange={e => {
            setFilterOption({
              ...filterOption,
              categoriesId: e.target.value
            });
            setWorkoutTypeFilter(e.target.value);
          }}
        />
      </CustomSelect>
    </>
  );
};

export default SearchLivestream;
