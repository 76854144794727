import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { parsePhoneNumber } from 'libphonenumber-js';

import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Tooltip,
  Modal,
  withStyles
} from '@material-ui/core';
import TableContainer from '@/components/Table/TableContainer';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import FiltedBodyCell from '../common/component/FiltedBodyCell';
import renderColumn from './components/renderColumn';
import { DeleteRounded } from '@material-ui/icons';
import ModalPresenter from '@/components/ModalPresenter';
import deletionRequestDispatcher from './action';
import { DeleteAct, Pause } from '@/assets/svg';
import moment from 'moment';
import { FORMAT_DD_MM_YYYY } from '../booking-management/constants';

const TableCellSticky = withStyles({
  root: {
    position: 'sticky',
    background: 'white',
    right: 0,
    width: 50
  }
})(TableCell);

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 230,
    padding: '8px 16px',
    fontSize: 14,
    boxShadow: '1px 1px 4px -1px #000000'
  }
})(Tooltip);

const AccountDeletion = () => {
  const { list: data, paging } = useSelector(state => state.deletionRequest);

  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const tableColumn = renderColumn();

  const renderPhoneNumber = data => {
    const { mobile, countryCode } = data;
    if (!mobile) return '';
    const phoneNumberObj = parsePhoneNumber(mobile.toString(), countryCode);
    if (!phoneNumberObj) return '';
    return phoneNumberObj.format('E.164');
  };

  const onDeleteAccountRequest = () => {
    deletionRequestDispatcher.deleteAccountRequest(selectedItem.userId, () => {
      deletionRequestDispatcher.getData(searchKey);
      setDeleteModal(false);
    });
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(({ label, stateValue }) =>
          stateValue ? (
            <TableCellFiltedWrapper
              key={stateValue}
              label={label}
              stateValue={stateValue}
              style={{ width: 160 }}
            >
              {label}
            </TableCellFiltedWrapper>
          ) : (
            <TableCellSticky />
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = row => (
    <>
      <FiltedBodyCell hidden={hiddenRows.name}>{row.name}</FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.identityNumber}>
        {row.identityNumber || ''}
      </FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.mobile}>
        {renderPhoneNumber(row)}
      </FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.email}>{row.email}</FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.requestDateTime}>
        {row.requestDateTime &&
          moment(row.requestDateTime).format(FORMAT_DD_MM_YYYY)}
      </FiltedBodyCell>

      <TableCellSticky align="right" style={{ padding: 2 }}>
        <CustomTooltip
          title={
            row.isMEU
              ? 'This patient had prior medical engagements with Minmed.'
              : "This patient doesn't have medical engagement."
          }
        >
          <IconButton
            onClick={() => {
              setSelectedItem(row);
              setDeleteModal(true);
            }}
          >
            {row.isMEU ? <Pause /> : <DeleteAct />}
          </IconButton>
        </CustomTooltip>
      </TableCellSticky>
    </>
  );
  return (
    <>
      <TableContainer
        fullWidthSearch
        title="Deletion Worklist"
        header={TableHeader}
        data={data}
        paging={paging}
        query={(...params) => {
          deletionRequestDispatcher.getData(...params);
        }}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        renderRow={(row, i) => renderTableBody(row)}
      />

      <Modal open={!!deleteModal} onClose={() => setDeleteModal(false)}>
        <div>
          <ModalPresenter
            onClose={() => setDeleteModal(false)}
            Icon={<DeleteRounded style={{ fontSize: 80, color: '#EA6B75' }} />}
            title="Delete Profile?"
            onClick={onDeleteAccountRequest}
          >
            <Typography>Click “Continue” to delete</Typography>
            <Typography>
              <Typography style={{ fontWeight: 600 }} display={'inline'}>
                {selectedItem.name}
              </Typography>
              ’s profile.
            </Typography>
          </ModalPresenter>
        </div>
      </Modal>
    </>
  );
};

export default AccountDeletion;
