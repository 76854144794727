const renderColumn = type => {
  let tableComlumn = [
    {
      label: 'Patient Name',
      stateValue: 'name'
    },
    {
      label: 'Contact',
      stateValue: 'contact'
    },
    {
      label: 'Email',
      stateValue: 'email'
    }
  ];
  switch (type) {
    case 'OutstandingPayment':
      tableComlumn = tableComlumn.concat([
        {
          label: 'Amount outstanding',
          stateValue: 'amount'
        },
        {
          label: '',
          stateValue: null
        }
      ]);
      break;

    case 'IncompleteStation':
      tableComlumn = tableComlumn.concat([
        {
          label: 'Stations not completed',
          stateValue: 'stations'
        },
        {
          label: '',
          stateValue: null
        }
      ]);
      break;

    default:
      tableComlumn = tableComlumn.concat({
        ...tableComlumn,
        label: '',
        stateValue: null
      });
      break;
  }

  return tableComlumn;
};

export default renderColumn;
