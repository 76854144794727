import PrivateLayout from './component/PrivateLayout';
import PublicLayout from './component/PublicLayout';
import AuthLayout from './component/AuthLayout';
import ToolbarUI from './componentUI/ToolbarUI';
import TableUI from './componentUI/TableUI';
import InputModal from './componentUI/InputModal';
import { TopProgressBar } from './component/TopProgressBar';
import ModalUI from './componentUI/ModalUI';

const API_ROOT = process.env.REACT_APP_API_ROOT;
const DEFAULT_PAGESIZE = 5;
const DATE_FORMAT = 'DD/MM/YYYY';
const TIME_FORMAT = 'h:mm A';

const MATERIAL_UI_PICKER_DATE_FORMAT = 'dd/MM/yyyy';
const DATE_FORMAT_2 = 'DD MMM YYYY';
const ENTER_KEY = 13;
const BACK_KEY = 8;
const DEFAULT_INPUT_OTP = '123456';
const DATE_TIME_FORMAT = 'DD/MM/YYYY, HH:mm A';

export {
  DEFAULT_PAGESIZE,
  DATE_FORMAT,
  DATE_FORMAT_2,
  MATERIAL_UI_PICKER_DATE_FORMAT,
  API_ROOT,
  ENTER_KEY,
  PrivateLayout,
  PublicLayout,
  AuthLayout,
  ToolbarUI,
  TableUI,
  InputModal,
  TopProgressBar,
  ModalUI,
  BACK_KEY,
  DEFAULT_INPUT_OTP,
  DATE_TIME_FORMAT,
  TIME_FORMAT
};
