import React, { useEffect } from 'react';
import { ProgressWrapped } from './styled';
import { CircularProgress, Typography, Box } from '@material-ui/core';
import labResultDispatcher from '../action';
import { convertToFormData, calcProgressItem } from '../utils';
import { useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash';

const UploadProgress = ({ showProgress, files = [], onClose }) => {
  const { progress } = useSelector(state => state.labResult);

  useEffect(() => {
    if (showProgress) {
      const filesList = files.map(item => item.originFileObj);

      const progressItem = calcProgressItem(filesList.length);
      labResultDispatcher.setState('progressItem', progressItem);

      uploadFile(filesList);
    }
  }, [showProgress]);

  const uploadFile = async fileList => {
    if (isEmpty(fileList)) {
      const fileNames = files.map(file => get(file, 'originFileObj.name', ''));
      labResultDispatcher.submitTestsResult({ fileNames }, onClose);
      return;
    }
    return new Promise((resolve, reject) => {
      return labResultDispatcher.uploadTestResult(
        convertToFormData(fileList.pop(), 'tempFile'),
        {
          resolve,
          reject
        }
      );
    })
      .then(() => {
        uploadFile(fileList);
      })
      .catch(() => {
        onClose();
      });
  };

  return (
    <ProgressWrapped>
      <Typography className="label">Files Uploading... </Typography>
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" value={progress} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      </Box>
    </ProgressWrapped>
  );
};

export default UploadProgress;
