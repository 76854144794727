import React from 'react';
import { Assignment, Autorenew, KeyboardArrowDown } from '@material-ui/icons';
import { Grid, Button, withStyles, TextField } from '@material-ui/core';

import CustomDate from '@/components/CustomDate';
import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import styled from 'styled-components';

import { Autocomplete } from '@material-ui/lab';
import { CloseCircleFilled } from '@ant-design/icons';
import { sortAlphabetically } from '@/utils/sort';

const CustomButton = withStyles({
  root: {
    margin: 5,
    paddingLeft: 40,
    paddingRight: 40,

    height: 40,
    minWidth: 160,

    textTransform: 'unset',
    fontWeight: 600,
    borderRadius: 50
  }
})(Button);

const GroupSearch = ({
  filterOption,
  setFilterOption,
  date,
  setDate,
  onResetFilterOps,
  listLocations = [],
  setListProjects,
  setLocationFilter,
  defaultDate = '',
  listProjects,
  projectFilter,
  setProjectFilter,
  locationFilter
}) => {
  const onChangeDate = value => {
    setFilterOption({ ...filterOption, date: value });
    setDate(value || defaultDate);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginBottom: 16
      }}
    >
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item md={3} sm={6} xs={12}>
          <CustomDate
            {...{
              clearable: false,
              selectedDate: date,
              onChange: onChangeDate,
              placeholder: 'Date'
            }}
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <Autocomplete
            value={listLocations.find(it => it?.id === locationFilter) || ''}
            autoComplete
            options={sortAlphabetically(listLocations, 'name')}
            getOptionLabel={option => option.name || ''}
            onChange={(event, value) => {
              value && setLocationFilter(value.id);
            }}
            popupIcon={
              <KeyboardArrowDown style={{ fontSize: 20, color: '#E9E9E9' }} />
            }
            closeIcon={
              <CloseCircleFilled
                style={{ margin: 0, padding: 0 }}
                className="ant-input-clear-icon"
                onClick={e => {
                  setProjectFilter('');
                  setLocationFilter('');
                  setListProjects([]);
                }}
              />
            }
            renderInput={params => (
              <InputWrapper>
                <Assignment
                  style={{
                    fontSize: 20,
                    color: '#E9E9E9',
                    marginRight: 8
                  }}
                />

                <TextFieldWrapper
                  placeholder="Locations"
                  style={{ color: '#8F9BA9' }}
                  {...params}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                />
              </InputWrapper>
            )}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <p
            style={{ color: '#8f9ba9', padding: '0px 11px' }}
          >{`(Optional)`}</p>
          <Autocomplete
            value={listProjects.find(it => it?.id === projectFilter) || ''}
            autoComplete
            options={sortAlphabetically(listProjects, 'name')}
            getOptionLabel={option => option.name}
            onChange={(event, value) => {
              value && setProjectFilter(value?.id);
            }}
            popupIcon={
              <KeyboardArrowDown style={{ fontSize: 20, color: '#E9E9E9' }} />
            }
            closeIcon={
              <CloseCircleFilled
                style={{ margin: 0, padding: 0 }}
                className="ant-input-clear-icon"
                onClick={e => {
                  setProjectFilter('');
                }}
              />
            }
            renderInput={params => (
              <InputWrapper>
                <Assignment
                  style={{
                    fontSize: 20,
                    color: '#E9E9E9',
                    marginRight: 8
                  }}
                />

                <TextFieldWrapper
                  placeholder="Project"
                  style={{ color: '#8F9BA9' }}
                  {...params}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                />
              </InputWrapper>
            )}
          />
        </Grid>

        <Grid container justify="flex-end" style={{ marginRight: 16 }}>
          <CustomButton
            onClick={() => {
              onResetFilterOps();
            }}
            variant="contained"
            color="primary"
            endIcon={<Autorenew />}
            style={{ borderRadius: 50, margin: 5 }}
          >
            Reset Filter
          </CustomButton>
        </Grid>
      </Grid>
    </div>
  );
};

export const StyledButtonEnhance = styled(ButtonEnhance)`
  max-width: 160px;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  margin: 12px;
  .add-group {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: -2px;
    span {
      margin-right: 8px;
      color: #cacfd3;
    }
  }
`;

const TextFieldWrapper = styled(TextField)`
  .MuiInputBase-input {
    padding: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 0;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root']
    .MuiAutocomplete-input:first-child {
    padding: 0;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  cursor: poiter;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  align-items: flex-end;
  padding: 4px 11px 4px 11px;
`;

export default GroupSearch;
