import React, { useState } from 'react';
import {
  Button,
  Card,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  withStyles
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { PaperWrapped, HeaderWrapped } from '../lab-result/component/styled';
import { Clear } from '@material-ui/icons';
import minmedSupportsDispatcher from './action';
import { isEmpty } from 'lodash';
import {
  validateEmail,
  validateIdentity,
  validateNRIC
} from '@/utils/validation';
import { LOGIN_TYPE } from './constants';

const CustomTextField = withStyles({
  root: {
    '& input::placeholder': {
      fontSize: 16
    },
    '& .MuiOutlinedInput-input': {
      padding: '16px 0'
    }
  }
})(TextField);

const CustomButton = withStyles({
  root: {
    height: 48,
    width: 200
  }
})(Button);

const CustomTitle = withStyles({
  root: {
    fontSize: 18,
    fontWeight: 600
  }
})(Typography);

const CustomCard = withStyles({
  root: {
    marginTop: 16,
    padding: 16,
    height: 300,
    overflow: 'auto'
  }
})(Card);

const CustomAccordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(Accordion);

const CustomAccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(AccordionSummary);

const CustomAccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(AccordionDetails);

const APISupportScreen = () => {
  const [errors, setErrors] = useState({});

  const [formValue, setFormValue] = useState({});
  const [responseData, setResponseData] = useState({});
  const [loginType, setLoginType] = useState('Manual');

  const API_SUPPORT_LIST = [
    {
      key: 'get-receipt',
      title: 'Get Receipt',
      placeholder: 'Enter paxScreeningId here...'
    },
    {
      key: 'remove-GG-authen',
      title: 'Remove GG Authen',
      placeholder: 'Enter userId here...'
    },
    {
      key: 'change-HS-status',
      title: `Change HS's Status`,
      placeholder: 'Enter paxScreeningId here...'
    },
    {
      key: 'change-past-report',
      title: 'Check Past Report',
      placeholder: 'Enter userId here...',
      response: true
    },
    {
      key: 'reset-past-report',
      title: 'Reset Past Report',
      placeholder: 'Enter userId here...'
    },
    {
      key: 're-active-account',
      title: 'Re-active Account',
      placeholder: 'Enter userId here...'
    },
    {
      key: 'get-pax-data',
      title: `Get pax's Data`,
      placeholder: 'Enter email here...',
      response: true
    },
    {
      key: 'get-email',
      title: `Get Email`,
      placeholder: 'Enter nric here...',
      response: true
    },
    {
      key: 'change-sign-in-method',
      title: `Change Sign-In Method`,
      placeholder: 'Enter userId here...',
      isLoginType: true
    }
  ];

  const onChange = key => e => {
    setFormValue({ ...formValue, [key]: e.target.value });
  };

  const onSubmit = key => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    if (isEmpty(formValue[key])) {
      setErrors({
        ...errors,
        [key]: 'This value is required'
      });
    } else {
      let value = formValue[key];
      switch (key) {
        case 'get-receipt':
          if (regexExp.test(value)) {
            setErrors({ ...errors, [key]: '' });
            minmedSupportsDispatcher.getReceipt(value);
          } else setErrors({ ...errors, [key]: 'This value is invalid' });
          break;
        case 'remove-GG-authen':
          if (regexExp.test(value)) {
            setErrors({ ...errors, [key]: '' });
            minmedSupportsDispatcher.removeGGAuthen(value);
          } else setErrors({ ...errors, [key]: 'This value is invalid' });
          break;
        case 'change-HS-status':
          if (regexExp.test(value)) {
            setErrors({ ...errors, [key]: '' });
            minmedSupportsDispatcher.changeHSStatus(value);
          } else setErrors({ ...errors, [key]: 'This value is invalid' });
          break;
        case 'change-past-report':
          if (regexExp.test(value)) {
            setErrors({ ...errors, [key]: '' });
            minmedSupportsDispatcher.checkPastReport(value, result => {
              setResponseData({
                ...responseData,
                [key]: JSON.stringify(result, null, 2)
              });
              setErrors({ ...errors, [key]: '' });
            });
          } else setErrors({ ...errors, [key]: 'This value is invalid' });

          break;
        case 'reset-past-report':
          if (regexExp.test(value)) {
            setErrors({ ...errors, [key]: '' });
            minmedSupportsDispatcher.resetPastReport(value);
          } else setErrors({ ...errors, [key]: 'This value is invalid' });
          break;
        case 're-active-account':
          if (regexExp.test(value)) {
            setErrors({ ...errors, [key]: '' });
            minmedSupportsDispatcher.reActiveAccount(value);
          } else setErrors({ ...errors, [key]: 'This value is invalid' });
          break;
        case 'get-pax-data':
          if (validateEmail(value)) {
            setErrors({ ...errors, [key]: '' });
            minmedSupportsDispatcher.getPaxData(
              encodeURIComponent(value.trim()),
              result => {
                setResponseData({
                  ...responseData,
                  [key]: JSON.stringify(result, null, 2)
                });
              }
            );
          } else setErrors({ ...errors, [key]: 'This value is invalid' });
          break;
        case 'get-email':
          minmedSupportsDispatcher.getPaxData(value, result => {
            const emails = result.userInfos.map(item => item.email);
            setResponseData({
              ...responseData,
              [key]: JSON.stringify(emails.join(', '), null, 2)
            });
            setErrors({ ...errors, [key]: '' });
          });
          break;
        case 'change-sign-in-method':
          if (regexExp.test(value)) {
            setErrors({ ...errors, [key]: '' });
            minmedSupportsDispatcher.changeSignInMethod(value, loginType);
          } else {
            setErrors({
              ...errors,
              [key]: 'This value is invalid'
            });
          }
          break;
        default:
          break;
      }
    }
  };

  const onClear = key => {
    setFormValue({ ...formValue, [key]: '' });
    setResponseData('');
  };

  return (
    <PaperWrapped>
      <HeaderWrapped className="no-tabs-header">
        <CustomTitle>API Support</CustomTitle>
      </HeaderWrapped>
      <Box p={2}>
        {API_SUPPORT_LIST.map(item => (
          <CustomAccordion key={item.key}>
            <CustomAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <CustomTitle color="primary">{item.title}</CustomTitle>
            </CustomAccordionSummary>
            <CustomAccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <Box display="flex" gridGap={16}>
                    <CustomTextField
                      fullWidth
                      variant="outlined"
                      placeholder={item.placeholder}
                      value={formValue[item.key] || ''}
                      onChange={onChange(item.key)}
                      error={errors[item.key]}
                      helperText={errors[item.key]}
                      InputProps={{
                        endAdornment: formValue[item.key] && (
                          <IconButton
                            edge="end"
                            onClick={() => onClear(item.key)}
                            size="small"
                          >
                            <Clear />
                          </IconButton>
                        )
                      }}
                    />

                    {item.isLoginType && (
                      <CustomTextField
                        fullWidth
                        select
                        required
                        variant="outlined"
                        value={loginType}
                        onChange={e => setLoginType(e.target.value)}
                        error={errors.loginType}
                        helperText={errors.loginType}
                        placeholder="Select login type"
                      >
                        {LOGIN_TYPE.map(item => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </CustomTextField>
                    )}

                    <CustomButton
                      variant="contained"
                      color="primary"
                      onClick={() => onSubmit(item.key)}
                    >
                      Submit
                    </CustomButton>
                  </Box>
                </Grid>
                {item.response && (
                  <Grid item xs={12}>
                    <CustomCard variant="outlined">
                      <pre>
                        {!isEmpty(responseData[item.key])
                          ? responseData[item.key]
                          : 'No data to show.'}
                      </pre>
                    </CustomCard>
                  </Grid>
                )}
              </Grid>
            </CustomAccordionDetails>
          </CustomAccordion>
        ))}
      </Box>
    </PaperWrapped>
  );
};

export default APISupportScreen;
