import React, { useState, useEffect, useMemo } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  Dialog,
  Modal,
  Typography,
  Fab,
  Tooltip
} from '@material-ui/core';
import { get } from 'lodash';
import {
  MoreVertRounded,
  DeleteRounded,
  GetAppRounded
} from '@material-ui/icons';
import TableContainer from '../../../components/Table/TableContainer';
import { useSelector } from 'react-redux';
import { SEARCH_PARAM, TYPE_MODAL } from '../constants';
import { MenuAction, SearchOnDemand } from '../components';
import OnDemandFormPage from './OnDemandFormPage';
import onDemandDispatcher from '../actions/index';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import ModalPresenter from '../../../components/ModalPresenter';
import './styles.scss';

const OnDemandListPage = () => {
  const { list, paging } = useSelector(state => state.onDemand);

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState(SEARCH_PARAM);

  const [onDemandSelected, setOnDemandSelected] = useState(null);

  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalForm, setShowModalForm] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});

  useEffect(() => {
    onDemandDispatcher.getCategoryOnDemand();
    onDemandDispatcher.getDurationIntensity();
  }, []);

  const reGetOnDemandList = () => {
    setFilterOption({ ...filterOption });
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper label="Workout Name" stateValue="title" />
        <TableCellFiltedWrapper
          label="Workout Type"
          stateValue="categoryTitle"
        />
        {/* <TableCellFiltedWrapper label="Trainer" stateValue="trainer" /> */}
        <TableCellFiltedWrapper label="Intensity" stateValue="intensity" />
        <TableCellFiltedWrapper label="Duration" stateValue="duration" />
        <TableCellFiltedWrapper label="Calories" stateValue="calories" />
        <TableCellFiltedWrapper label="Description" stateValue="description" />
        <TableCellFiltedWrapper label="Equipment" stateValue="equipment" />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const onShowModalForm = type => {
    setTypeModal(type);
    setShowModalForm(true);
    setAnchorEl(null);
  };

  const onCloseModalForm = () => {
    setTypeModal(null);
    setShowModalForm(false);
    setOnDemandSelected(null);
  };

  const showModalConfirmDelete = () => {
    setShowModalConfirm(true);
    setAnchorEl(null);
  };

  const handleDeleteWorkout = () => {
    onDemandDispatcher.deleteOnDemand(onDemandSelected.id, () => {
      setFilterOption({ ...filterOption });
    });
    setShowModalConfirm(false);
  };

  const listTableButtons = useMemo(
    () => [
      <Fab
        style={{
          backgroundColor: '#ad5e99',
          color: 'white',
          width: 40,
          height: 40,
          margin: 5
        }}
        aria-label="export"
        onClick={onDemandDispatcher.getExportExcel}
      >
        <Tooltip title="Export excel">
          <GetAppRounded style={{ fontSize: 18 }} />
        </Tooltip>
      </Fab>
    ],
    []
  );

  return (
    <>
      {showModalConfirm && (
        <Modal
          open={showModalConfirm}
          onClose={() => setShowModalConfirm(false)}
        >
          <div>
            <ModalPresenter
              onClose={() => setShowModalConfirm(false)}
              Icon={
                <DeleteRounded style={{ fontSize: 80, color: '#EA6B75' }} />
              }
              title={`Delete '${get(onDemandSelected, 'title')}' Workout?`}
              onClick={handleDeleteWorkout}
            >
              <Typography>Click “Continue” to delete</Typography>
              <Typography>
                <Typography style={{ fontWeight: 600 }} display={'inline'}>
                  {onDemandSelected.fullName}
                </Typography>
              </Typography>
            </ModalPresenter>
          </div>
        </Modal>
      )}
      {showModalForm && (
        <Dialog
          open={showModalForm}
          onClose={() => setShowModalForm(false)}
          maxWidth="md"
          scroll="body"
        >
          <OnDemandFormPage
            reGetOnDemandList={reGetOnDemandList}
            typeModal={typeModal}
            onCloseModal={onCloseModalForm}
            onDemandDetail={onDemandSelected}
          />
        </Dialog>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && (
          <MenuAction
            onShowModal={onShowModalForm}
            onShowConfirmDelete={showModalConfirmDelete}
          />
        )}
      </Menu>

      <TableContainer
        title="On Demand Workouts"
        paging={paging}
        data={list}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        query={onDemandDispatcher.getOnDemandList}
        onAddNew={() => {
          setOnDemandSelected(null);
          onShowModalForm(TYPE_MODAL.Create);
        }}
        ToolbarComponent={
          <SearchOnDemand
            onSearch={data => {
              setFilterOption({ ...filterOption, ...data });
            }}
          />
        }
        renderRow={row => (
          <>
            <TableCell>{!hiddenRows.title && row.title}</TableCell>
            <TableCell>
              {!hiddenRows.categoryTitle && row.categoryTitle}
            </TableCell>
            {/* <TableCell>{!hiddenRows.trainer && row.trainer}</TableCell> */}
            <TableCell>{!hiddenRows.intensity && row.intensity}</TableCell>
            <TableCell>
              {!hiddenRows.duration && <span>{row.duration} minutes</span>}
            </TableCell>
            <TableCell>
              {!hiddenRows.calories && <span>{row.calories} kcal</span>}
            </TableCell>
            <TableCell>{!hiddenRows.description && row.description}</TableCell>
            <TableCell>{!hiddenRows.equipment && row.equipment}</TableCell>
            <TableCell align="right">
              <IconButton
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  setOnDemandSelected(row);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
        customButtons={listTableButtons}
      ></TableContainer>
    </>
  );
};

export default OnDemandListPage;
