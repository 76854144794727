import styled from 'styled-components';

export const LocalStreamContainer = styled.div`
  width: 325px;
  height: 200px;
  background-color: #333333;
  border-radius: 16px;
  position: relative;
  bottom: ${(props) => (props.isResponsive ? '88px' : '0px')};

  .mute-video-layer {
    width: 100%;
    height: 100%;
    background-color: #333333;
    border-radius: 16px;
    position: absolute;
    z-index: ${(props) => (props.isMuteVideo ? 1 : 0)};

    .text {
      color: white;
      position: absolute;
      bottom: 20px;
      left: 20px;

      font-size: 24px;
      font-family: 'Lato';
      font-weight: 600;
    }
  }

  video {
    z-index: ${(props) => (props.isMuteVideo ? 0 : 1)};
    transform: ${(props) => (props.transform ? props.transform : 'unset')};
    background-color: #333333 !important;
    object-fit: cover !important;
  }
  div {
    border-radius: 16px;
    border: ${(props) =>
      props.isHighlight ? '6px solid #50a5ed ' : 'inhiret'};
  }
`;

export const LocalSubUserStreamContainer = styled(LocalStreamContainer)`
  video {
    height: 180% !important;
    top: -90px !important;
    object-fit: contain !important;
    transform: ${(props) =>
      props.deviceType === 'Android' ? 'rotate(-90deg)' : ''};
  }
`;
