import React from 'react';
import {
  Grid,
  MenuItem,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import DividerHeader from '@/module/common/componentUI/DividerHeader';

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
    marginRight: 8
  },
  wrapperItem: {
    height: '100%',
    backgroundColor: '#F6F7FA',
    padding: ' 10px 20px 0 20px'
  },
  rowItem: {
    margin: 0,
    overflowY: 'auto',
    paddingBottom: 10
  },
  dateInput: { width: 335 },
  tabInput: { width: '25%', alignItems: 'center' },
  wrapperHeader: { borderBottom: ' 2px solid #F6F7FA', padding: '8px 0px' },
  textWrap: {
    overflowWrap: 'anywhere'
  }
});

const PhysicalDoctorForm = ({
  dataPEDoctor,
  setDataPEDoctor,
  isEdit,
  errors
}) => {
  const classes = useStyles();
  const handleChange = (optInx, sectionInx, inxItem) => e => {
    const { value, name } = e.target;
    let newListPhysicalExam = [...dataPEDoctor.doctorJsonData];

    if (inxItem !== undefined) {
      newListPhysicalExam[sectionInx].options[optInx].list[inxItem][
        name
      ] = value;
    } else {
      newListPhysicalExam[sectionInx].options[optInx][name] = value;
    }
    setDataPEDoctor({ ...dataPEDoctor, doctorJsonData: newListPhysicalExam });
  };

  if (isEmpty(dataPEDoctor)) return;

  const renderSectionFields = options => {
    if (isEmpty(options)) return null;
    return options?.map(option => (
      <Grid item xs={6}>
        <PhysicalItem data={option} key={option.label} />
      </Grid>
    ));
  };

  const PhysicalItem = ({ data: { label, value, list, valueSelected } }) => {
    return (
      <div className={classes.wrapperItem}>
        <div className={classes.rowItem}>
          <span className={classes.title}>{label}:</span>
          <span>{value || valueSelected || 'Nil'}</span>
        </div>
        {!isEmpty(list) &&
          list.map(
            item =>
              valueSelected === item.value &&
              item.hasOwnProperty('detail') && (
                <div
                  className={classes.rowItem}
                  style={{ display: 'inline-flex' }}
                >
                  <span className={classes.title}>Details:</span>
                  <span className={classes.textWrap}>{item.detail}</span>
                </div>
              )
          )}
      </div>
    );
  };

  return (
    !isEmpty(dataPEDoctor.doctorJsonData) &&
    dataPEDoctor.doctorJsonData?.map((section, sectionInx) => (
      <Grid container spacing={2} key={section.title}>
        <Grid item xs={12}>
          <DividerHeader title={section.title?.toUpperCase()} />
        </Grid>

        {isEdit
          ? section.options.map((option, optInx) => {
              const { label, valueSelected, list } = option;
              return (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    label={label}
                    id={`${label}-${optInx}`}
                    name="valueSelected"
                    value={valueSelected}
                    disabled={!isEdit}
                    onChange={handleChange(optInx, sectionInx)}
                  >
                    {list.map((item, inxItem) => (
                      <MenuItem key={inxItem} value={item.value}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </TextField>
                  {list.map(
                    (item, inxItem) =>
                      valueSelected === item.value &&
                      item.hasOwnProperty('detail') && (
                        <>
                          <TextField
                            fullWidth
                            multiline
                            rowsMax={5}
                            name="detail"
                            placeholder="Details"
                            onChange={handleChange(optInx, sectionInx, inxItem)}
                            value={item.detail}
                          />
                          {errors.doctorNote && isEmpty(item.detail) && (
                            <Typography color="error">
                              {errors.doctorNote}
                            </Typography>
                          )}
                        </>
                      )
                  )}
                </Grid>
              );
            })
          : renderSectionFields(section.options)}
      </Grid>
    ))
  );
};

export default PhysicalDoctorForm;
