import Enum from './Enum';

export const LiveStreamStatus = Enum({
  Upcomming: 'Upcomming',
  Live: 'Live',
  Past: 'Past'
});

export const LiveStreamStatusColor = Enum({
  Upcomming: '#2FC2D6',
  Live: '#EA6B75',
  Past: '#CACFD3'
});

export const StatusDisplay = Enum({
  Upcomming: 'Upcoming',
  Live: 'Live',
  Past: 'Past'
});
