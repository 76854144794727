import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TextField,
  InputAdornment,
  IconButton,
  withStyles
} from '@material-ui/core';

import { TableWrapped } from '@/module/checkin-patient-information/styled';
import ServicesTable from '@/module/setup/components/ServiceItems/ServicesTable';
import CustomAutocompleteModal from '@/new-components/CustomAutocompleteModal';
import CustomTooltip from '@/new-components/CustomTooltip';

import { RemoveIcon } from '@/assets/svg';
import { mergeArrayByKey } from '@/helpers';

import { cloneDeep, isEmpty } from 'lodash';
import {
  COLUMN_PROFILE,
  renderColumns,
  TABLE_STATE
} from '@/module/setup/constants';
import { packageDispatcher } from '@/module/package-management';

const CustomTextFieldPrice = withStyles({
  root: {
    fontSize: 20,
    maxWidth: 100,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const CustomTableCell = withStyles({
  root: {
    fontSize: 16
  }
})(TableCell);

const ProfilesForm = ({
  formValue,
  setFormValue,
  errors,
  requirePrice = true
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentState, setCurrentState] = useState(TABLE_STATE.Outside);

  const [listProfiles, setListProfiles] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const columnsTable = renderColumns(COLUMN_PROFILE, ['price']);

  const onAddNewService = newSelected => {
    const selected = selectedItem.concat(newSelected);
    const newList = mergeArrayByKey(selected, listProfiles, 'id');
    setFormValue({ ...formValue, lstOldProfiles: newList });
    setCurrentState(TABLE_STATE.Outside);
    setShowModal(false);
    setSelectedItem(selected);
  };

  const onDeletePackage = (row, index) => {
    let lstTests = cloneDeep(formValue.lstOldProfiles).filter(
      it => it.id !== row.id
    );
    let newSelectedItem = cloneDeep(selectedItem).map(item =>
      item.id === row.id ? { ...item, isChecked: false } : item
    );
    newSelectedItem.splice(index, 1);
    setSelectedItem(newSelectedItem);
    setFormValue({ ...formValue, lstOldProfiles: lstTests });
  };

  const handleChangePrice = (value = '', row) => {
    // Regex test number contain decimal. Maximum length number of decimal is 2
    const regexNumber = /^([\d]{0,9})(\.[\d]{1,2})?$/g;

    if (regexNumber.test(value)) {
      const newList = mergeArrayByKey(listProfiles, selectedItem, 'id');
      const newTests = cloneDeep(newList).map(item =>
        item.id === row.id ? { ...item, price: value } : item
      );
      let newSelectedItem = cloneDeep(selectedItem).map(item =>
        item.id === row.id ? { ...item, price: value } : item
      );
      setFormValue({ ...formValue, Tests: newSelectedItem });
      setListProfiles(newTests);
    }
  };

  const onUnSelectAll = () => {
    setSelectedItem([]);
    setFormValue({ ...formValue, lstProfiles: [] });
  };

  useEffect(() => {
    packageDispatcher.getAllProfiles(result => setListProfiles(result));
  }, []);

  useEffect(() => {
    if (!isEmpty(formValue)) {
      const newSelected = formValue.lstOldProfiles.map(i => i.id);
      setSelectedItem(newSelected);
    }
  }, [formValue]);

  const renderRow = (row, i) => {
    return (
      <>
        <CustomTableCell>
          <CustomTooltip content={row.name} />
        </CustomTableCell>

        <CustomTableCell>{row.totalTests}</CustomTableCell>
        <CustomTableCell>{row.totalProfiles}</CustomTableCell>

        {requirePrice && (
          <CustomTableCell>
            <CustomTextFieldPrice
              type="number"
              variant="outlined"
              value={row.price}
              onChange={e => handleChangePrice(e.target.value, row)}
              error={errors.price}
              helperText={errors.price}
              style={{ pointerEvents: row.isChecked ? 'auto' : 'none' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </CustomTableCell>
        )}

        <TableCell align="right">
          {currentState === TABLE_STATE.Outside && (
            <IconButton
              className="delete-icon"
              onClick={() => onDeletePackage(row, i)}
            >
              <RemoveIcon />
            </IconButton>
          )}
        </TableCell>
      </>
    );
  };

  return (
    <TableWrapped>
      <ServicesTable
        title="Available profiles"
        labelButton="Add profile"
        columns={columnsTable}
        renderRow={renderRow}
        data={formValue.lstOldProfiles}
        onClick={e => {
          e && e.preventDefault();
          setShowModal(!showModal);
          setCurrentState(TABLE_STATE.Modal);
        }}
      />

      <CustomAutocompleteModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setCurrentState(TABLE_STATE.Outside);
        }}
        onSubmit={onAddNewService}
        data={listProfiles}
        submitLabel="Add"
        title="profile"
        columns={columnsTable}
        searchPlaceholder="Search by profile name..."
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        onUnSelectAll={onUnSelectAll}
        renderRow={renderRow}
      />
    </TableWrapped>
  );
};

export default ProfilesForm;
