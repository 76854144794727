export const COMPANY_TYPE = ['Internal', 'External'];

export const SUB_SERVICES = [
  'Tests',
  'Measurements',
  'Procedures',
  'Radiologies',
  'Vaccinations',
  'Reviews'
];

export const LIST_MEASUREMENT = [
  { label: 'BMI - Height (m) & Weight (kg)', value: false },
  { label: 'Blood Pressure', value: false },
  { label: 'Waist Hip Ratio (cm)', value: false },
  { label: 'Fat Analysis (%)', value: false },
  { label: 'Urine Dipstick', value: false },
  { label: 'Visual Acuity', value: false },
  { label: 'Ishihara', value: false },
  { label: 'Waist Circumference (cm)', value: false }
];

export const LIST_FIELD = [
  { label: 'Text', value: 'Text' },
  { label: 'Single Choice', value: 'DropdownList' },
  { label: 'Multiple Choice', value: 'MultiSelect' },
  { label: 'Rating', value: 'Rating' }
];

export const FIELD_ARRAY_VALUE = ['DropdownList', 'MultiSelect', 'Rating'];

export const FORMAT_DD_MM_YYYY = 'DD/MM/yyyy';
export const FORMAT_TIME = 'LT';
export const FORMAT_TIME_TAMP = 'HH:mm:ss';

export const testColumn = [
  {
    label: 'Test',
    stateValue: 'test'
  },
  {
    label: 'Test Code',
    stateValue: 'labCode'
  },
  // {
  //   label: 'Minc Code',
  //   stateValue: 'mincCode'
  // },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const projectColumn = [
  {
    label: 'Project',
    stateValue: 'project'
  },
  {
    label: 'Code',
    stateValue: 'code'
  },
  {
    label: 'Start date',
    stateValue: 'startTime'
  },
  {
    label: 'End date',
    stateValue: 'endTime'
  },
  {
    label: 'Personal screening',
    stateValue: 'personalScreening'
  },
  {
    label: 'Free payment',
    stateValue: 'paymentSuppression'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const profileColumn = [
  {
    label: 'Profile',
    stateValue: 'profile'
  },
  {
    label: 'Tests',
    stateValue: 'tests'
  },
  {
    label: 'Profiles',
    stateValue: 'profiles'
  },
  {
    label: 'Price',
    stateValue: 'price'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const measurementColumn = [
  {
    label: 'Measurement',
    stateValue: 'measurement'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Measurement Code',
    stateValue: 'code'
  },
  {
    label: 'Price',
    stateValue: 'price'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const procedureColumn = [
  {
    label: 'Procedure',
    stateValue: 'procedure'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const radiologyColumn = [
  {
    label: 'Radiology',
    stateValue: 'radiology'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  },
  {
    label: '',
    stateValue: 'action'
  }
];
export const vaccinationColumn = [
  {
    label: 'Vaccination',
    stateValue: 'vaccination'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  },
  {
    label: '',
    stateValue: 'action'
  }
];
export const reviewColumn = [
  {
    label: 'Review',
    stateValue: 'review'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  },
  {
    label: '',
    stateValue: 'action'
  }
];
export const staffTypeColumn = [
  {
    label: 'Value',
    stateValue: 'value'
  },
  {
    label: 'Sub-value',
    stateValue: 'subValue'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const categoryColumn = [
  {
    label: 'Custom',
    stateValue: 'category'
  },
  {
    label: 'Type of answer',
    stateValue: 'type'
  },
  {
    label: 'Options',
    stateValue: 'options'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const packageColumn = [
  {
    label: 'Package',
    stateValue: 'package'
  },
  {
    label: 'Type',
    stateValue: 'type'
  },
  {
    label: 'Price',
    stateValue: 'price'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const screeningDateColumn = [
  { label: 'Location Name', stateValue: 'locationName', width: 120 },
  { label: 'Location', stateValue: 'address' },
  {
    label: 'Start Date',
    stateValue: 'startDate'
  },
  {
    label: 'End Date',
    stateValue: 'endDate'
  },
  // {
  //   label: 'Start Time',
  //   stateValue: 'startTime'
  // },
  // {
  //   label: 'End Time',
  //   stateValue: 'endTime'
  // },
  {
    label: '',
    stateValue: 'action'
  }
];

export const REQUIRED_FIELD = [
  {
    label: 'General Information',
    field: ['name', 'startDate', 'endDate']
  },
  {
    label: 'Screening Dates',
    field: ['lstHealthScreenings']
  },
  {
    label: 'Tests',
    field: []
  },
  {
    label: 'Profiles',
    field: []
  },
  {
    label: 'Measurements',
    field: []
  },
  {
    label: 'Procedures',
    field: []
  },
  {
    label: 'Radiologies',
    field: []
  },
  {
    label: 'Vaccinations',
    field: []
  },
  {
    label: 'Reviews',
    field: []
  },
  {
    label: 'Packages',
    field: []
  },
  {
    label: 'Staff Types',
    field: ['lstStaffTypeItems', 'staffTypeLabel']
  },
  {
    label: 'Categories',
    field: []
  }
];

export const TABLE_STATE = {
  Outside: 'Outside',
  Modal: 'Modal'
};

export const STAFF_SUBSIDY_TYPE = [
  {
    label: '%',
    value: 'Percent'
  },
  {
    label: '$',
    value: 'Value'
  }
];

export const SPECIAL_LOCATION = 'homebased health screening';
export const DEFAULT_SEQUENCE_STATIONS = [
  'Registration',
  'BP Measurement',
  'BMI Measurement',
  'Waist/Hip Measurement',
  'Fat Analysis Measurement',
  'Eye Check',
  'Blood Taking'
];

export const sequenceStationsColumn = [
  {
    label: 'Station Name',
    stateValue: 'stationName'
  }
];

export const COLUMN_SCREENING_DATES = [
  {
    label: 'Location',
    stateValue: 'locationName',
    width: '30%'
  },
  {
    label: 'Address',
    stateValue: 'address',
    width: '30%'
  },
  {
    label: 'Start Date',
    stateValue: 'startDate'
  },
  {
    label: 'End Date',
    stateValue: 'endDate'
  },
  {
    label: 'Action',
    stateValue: 'action'
  }
];
