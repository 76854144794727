import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';

import CustomDate from '@/new-components/CustomDate';
import CustomRadioButton from '@/new-components/CustomRadioButton';

import { checkIsSpecialLocations } from '@/helpers';
import locationDispatcher from '@/module/setup/action';
import { formatTimeShowUILocal } from '@/module/setup/utils';
import { validateDate } from '@/utils/validation';
import isEmpty from 'lodash/isEmpty';
import { ExpandMore } from '@material-ui/icons';
import moment from 'moment';
import PatientInfoCard from '@/new-components/CustomCard/PatientInfoCard';
import CustomTooltip from '@/new-components/CustomTooltip';

const rowStyles = makeStyles({
  title: {
    paddingBottom: 10,
    minWidth: 116,
    verticalAlign: 'top',
    fontWeight: 400,
    color: '#666666'
  },
  text: {
    paddingBottom: 10,
    fontSize: 16,
    verticalAlign: 'top'
  }
});

const useStyles = makeStyles({
  border: {
    height: 1,
    width: '100%',
    margin: '8px 0',
    // border: '1px dashed #C1C1C1',
    backgroundImage: 'linear-gradient(to right, #C1C1C1 50%, transparent 50%)',
    backgroundPosition: 'top',
    backgroundSize: '10px 1px',
    backgroundRepeat: 'repeat-x'
  },

  container: {
    display: 'flex'
  },

  rightContainer: {
    overflow: 'scroll',
    padding: '20px 40px',
    height: 'calc(100vh - 282px)'
  },

  leftContainer: {
    padding: '20px 40px',
    flex: '1',

    overflow: 'auto',
    borderRight: '1px solid #F1F1F1',
    height: 'calc(100vh - 282px)'
  },
  txtQuota: {
    fontSize: 16,
    color: '#666666'
  }
});

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 8,
    fontWeight: 600,
    color: '#666666'
  }
})(InputLabel);

const CustomCard = withStyles({
  root: {
    padding: 20,
    boxShadow: 'none',
    borderRadius: 16,
    backgroundColor: props => (props.color ? props.color : '#F6F6F6'),
    width: props => (props.width ? props.width : 'unset')
  }
})(Card);

const CustomTextField = withStyles({
  root: {
    marginBottom: 12,
    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8
    },

    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    }
  }
})(TextField);

const RowItem = ({ children, title }) => {
  const classes = rowStyles();
  return (
    <tr>
      <td className={classes.title}>{title}</td>
      {children === 'None' ? (
        <td className={classes.text} style={{ color: '#999999' }}>
          {children}
        </td>
      ) : (
        <td className={classes.text}>
          <CustomTooltip content={children} />
        </td>
      )}
    </tr>
  );
};

const SelectPlaceAndTimeStep = ({
  isFollowUp = false,
  dataInfo,
  patientInfo,
  projectInfo,
  formValue,
  setFormValue,
  timeSlot,
  setTimeSlot,
  timeSlotChecked,
  setTimeSlotChecked,
  dateChecked,
  setDateChecked,
  currentLocation,
  setCurrentLocation,
  setRestrictedPackageIds,
  setRestrictedTestIds,
  locationFollowUp,
  setProjectInfo
}) => {
  const classes = useStyles();
  const [closureDate, setClosureDate] = useState([]);
  const [loadingSearchTimeSlot, setLoadingSearchTimeSlot] = useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!isFollowUp && !isEmpty(projectInfo)) {
      const currLocation = projectInfo?.lstHealthScreenings?.find(
        location => location?.locationId === projectInfo?.currLocationId
      );
      currLocation && setCurrentLocation(currLocation);
    }
  }, [isFollowUp, projectInfo]);

  useEffect(() => {
    if (!isEmpty(dataInfo) && isEmpty(formValue?.date)) {
      let startDateValid = moment(dataInfo.startDate);
      let isStartDateValid = true;
      if (checkIsValidDate(startDateValid)) {
        isStartDateValid = false;
        startDateValid = isFollowUp ? moment().add(1, 'day') : moment();

        // Check if healthScreeningStartDate is after startDateValid
        if (
          moment(currentLocation?.startDate).isAfter(moment(startDateValid))
        ) {
          startDateValid = moment(currentLocation?.startDate);
        }
      }

      onGetDataTimeSlot(startDateValid, isStartDateValid, currentLocation?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInfo, currentLocation]);

  const checkIsValidDate = valueStartDate => {
    const startDateUnix = moment(valueStartDate)
      .startOf('date')
      .unix();

    const dateCompare = isFollowUp
      ? moment()
          .startOf('date')
          .add(1, 'day')
          .unix()
      : moment()
          .startOf('date')
          .unix();
    return startDateUnix < dateCompare;
  };

  const resetCheckedValue = () => null;

  const handleChangeDate = key => value => {
    const utcValue = moment(value)
      .startOf('date')
      .utc()
      .format();

    const { valid, message } = validateDate(utcValue);
    if (!valid) {
      setErrors({ startDate: message });
    }
    if (valid && key === 'date') {
      resetCheckedValue();
      onGetDataTimeSlot(utcValue, false, currentLocation?.locationId);

      setErrors({});
    }
    setTimeSlotChecked();
    setFormValue({ ...formValue, [key]: utcValue, timeSlotId: '' });
  };

  const checkIsValidTime = endTime => {
    const endTimeUnix = moment(endTime).unix();
    const timeCompare = moment().unix();

    return endTimeUnix < timeCompare;
  };

  const checkIsValidTime5Mins = endTime => {
    const endTime5Mins = moment(endTime).subtract(5, 'minutes');
    const timeCompare = moment();
    const diffTime = endTime5Mins.diff(timeCompare, 'minutes');

    return diffTime < 0;
  };

  const checkInvalidTimeslot = row => {
    const { startDate, endDate, isActive } = row;
    // Disable Time Slot:
    // 1 Start time valid is after current time
    // 2 Start date valid is after current date 2 days
    // 3 Timeslot not active
    // 3 Timeslot not active
    // 4 End time valid is before current 5 minutes

    if (checkIsValidDate(startDate)) return true;
    if (checkIsValidTime(endDate)) return true;
    if (checkIsValidTime5Mins(endDate)) return true;

    if (!isActive) return true;

    return;
  };

  const onGetDataTimeSlot = (value, isStartDateValid, locationId) => {
    if (!value) return;

    if (locationId) {
      setLoadingSearchTimeSlot(true);
      locationDispatcher.getDetailLocationTimeSlot(
        {
          id: locationId,
          viewAll: true,
          date: moment(value).format('YYYY-MM-DD')
        },
        result => {
          const { timeSlotId } = dataInfo;
          const startTimeSelected = result.find(it => it.id === timeSlotId);

          const dayOfWeek = moment(value).day();
          const timeSlots = result.filter(
            it => it.dayOfWeek === dayOfWeek && !checkInvalidTimeslot(it)
          );

          const timeslotInvalid = result.filter(
            it => it.dayOfWeek === dayOfWeek && checkInvalidTimeslot(it)
          );
          const checkTimeslotId = timeslotInvalid?.find(
            it => it.id === timeSlotId
          );
          if (
            isEmpty(checkTimeslotId) &&
            startTimeSelected &&
            (!dateChecked ||
              dateChecked ===
                moment(value)
                  .startOf('date')
                  .format())
          ) {
            isStartDateValid && setTimeSlotChecked(startTimeSelected.id);

            setDateChecked(
              moment(value)
                .startOf('date')
                .format()
            );
          }

          setTimeSlot(timeSlots);
          setLoadingSearchTimeSlot(false);
        }
      );
      locationDispatcher.getDetailLocation(locationId, result => {
        setClosureDate(result?.dayOffs);
        setRestrictedPackageIds(result?.restrictedPackageIds);
        setRestrictedTestIds(result?.restrictedTestIds);
      });
    }
  };

  const getMinDatePicker = valueDate => {
    if (checkIsValidDate(valueDate)) {
      return isFollowUp ? moment().add(1, 'day') : moment();
    }

    return valueDate;
  };

  const getMaxDatePicker = () =>
    isFollowUp
      ? undefined
      : currentLocation?.endDate
      ? moment(currentLocation?.endDate)
      : undefined;

  const onChangeChecked = id => {
    setTimeSlotChecked(id);
    setDateChecked(
      moment(formValue.date)
        .startOf('date')
        .format()
    );
    setFormValue({
      ...formValue,
      timeSlotId: id
    });
  };

  const handleChangeHealthScreening = (key, value) => {
    let formValueStartDate = undefined;
    const utcValue = isFollowUp
      ? moment(formValueStartDate)
          .add(1, 'day')
          .startOf('date')
          .utc()
          .format()
      : moment(formValueStartDate)
          .startOf('date')
          .utc()
          .format();

    const { valid, message } = validateDate(utcValue);

    if (!valid) {
      setErrors({ ...errors, startDate: message });
    } else {
      resetCheckedValue();

      // Check if healthScreeningStartDate is after currentDate, then set startDate to healthScreeningStartDate
      if (moment(value?.startDate).isAfter(moment(utcValue))) {
        formValueStartDate = moment(value?.startDate);
        onGetDataTimeSlot(value?.startDate, false, value?.locationId);
      } else {
        formValueStartDate = utcValue;
        onGetDataTimeSlot(utcValue, false, value?.locationId);
      }

      delete errors.startDate;
      setErrors(errors);
    }

    setFormValue({
      ...formValue,
      [key]: value.id,
      timeSlotId: '',
      date: formValueStartDate,
      healthScreeningStartDate: value.startDate,
      healthScreeningEndDate: value.endDate
    });
    setTimeSlotChecked();
    if (isFollowUp)
      setCurrentLocation({
        ...value,
        startDate: projectInfo.startDate
      });
    else {
      setProjectInfo({ ...projectInfo, pmCode: value?.pmCode });
      setCurrentLocation(value);
    }
  };

  const checkIsValidHealthScreening = endTime => {
    const endTimeUnix = moment(endTime)
      .endOf('date')
      .unix();
    const timeCompare = moment().unix();

    return endTimeUnix >= timeCompare;
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.leftContainer}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <CustomInputLabel>Location</CustomInputLabel>

            <Autocomplete
              getOptionSelected={(option, value) =>
                option?.locationId === value?.locationId
              }
              getOptionLabel={option => option?.locationName || ''}
              options={
                isFollowUp
                  ? locationFollowUp || []
                  : projectInfo?.lstHealthScreenings.filter(item =>
                      checkIsValidHealthScreening(item.endDate)
                    ) || []
              }
              autoComplete
              onChange={(e, newValue) =>
                handleChangeHealthScreening('screeningId', newValue)
              }
              value={currentLocation}
              renderInput={params => (
                <CustomTextField
                  variant="outlined"
                  {...params}
                  placeholder="Select location or location name"
                />
              )}
              renderOption={option => (
                <span className="max-line">{option.locationName || ''}</span>
              )}
              disableClearable
              popupIcon={<ExpandMore />}
            />
            <CustomCard>
              <table>
                <RowItem title="Location name">
                  {currentLocation?.locationName ||
                    currentLocation?.name ||
                    'None'}
                </RowItem>
                <RowItem title="Address">
                  {currentLocation?.locationAddress ||
                    currentLocation?.address ||
                    'None'}
                </RowItem>
                {!isFollowUp ? (
                  <>
                    <RowItem title="Start date">
                      {currentLocation?.startDate
                        ? moment(currentLocation?.startDate).format(
                            'DD/MM/YYYY'
                          )
                        : 'None'}
                    </RowItem>
                    <RowItem title="End date">
                      {currentLocation?.endDate
                        ? moment(currentLocation?.endDate).format('DD/MM/YYYY')
                        : 'None'}
                    </RowItem>
                  </>
                ) : null}
              </table>
            </CustomCard>
          </Grid>

          <Grid item md={12}>
            <CustomInputLabel>Date</CustomInputLabel>
            <CustomDate
              selectedDate={formValue.date ? moment(formValue.date) : undefined}
              maxDate={getMaxDatePicker()}
              minDate={
                checkIsSpecialLocations(currentLocation?.locationName)
                  ? moment().add(3, 'day')
                  : currentLocation?.startDate
                  ? getMinDatePicker(currentLocation?.startDate)
                  : undefined
              }
              isFullWidth
              placeholder="DD/MM/YYYY"
              clearable={false}
              onChange={handleChangeDate('date')}
              style={{ height: 48 }}
            />

            <Grid container spacing={2} style={{ marginTop: 16 }}>
              {timeSlot?.map(item => {
                return (
                  <Grid key={`${item.id}`} item lg={4} md={6} xs={12}>
                    <CustomRadioButton
                      keyValue="timeslotId"
                      item={item}
                      onClick={() => onChangeChecked(item.id)}
                      checked={
                        item.id === timeSlotChecked &&
                        dateChecked ===
                          moment(formValue.date)
                            .startOf('date')
                            .format()
                      }
                    >
                      {item?.startDate &&
                        formatTimeShowUILocal(item?.startDate)}

                      <span className={classes.txtQuota}>
                        {item.quotaUsed}/{item.quotaBase}
                      </span>
                    </CustomRadioButton>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.rightContainer}>
        <PatientInfoCard
          patientInfo={patientInfo}
          projectInfo={projectInfo}
          isFollowUp={isFollowUp}
        />
      </Box>
    </Box>
  );
};

export default SelectPlaceAndTimeStep;
