import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';

const useStyles = makeStyles({
  contained: {
    boxShadow: 'none'
  },
  head: {
    fontWeight: 600
  },
  button: {
    margin: 5,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 20,
    fontWeight: 600,
    width: 'auto',
    minWidth: 160,
    boxShadow: 'none'
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    marginTop: 20,
    marginBottom: 10
  }
});

const ModalPresenter = ({
  Icon,
  title,
  children,
  onClose,
  onClick,
  cancelLabel = 'Cancel',
  confirmLabel = 'Continue',
  showFooter = true,
  style = {},
  anotherAction = false,
  anotherActionContent
}) => {
  const classes = useStyles();

  return (
    <div style={{ ...containerStyle, ...style }}>
      <div style={bodyContainerStyle}>
        {Icon}
        <Typography className={classes.title}>{title}</Typography>
        {children}
      </div>
      {showFooter &&
        (anotherAction ? (
          anotherActionContent()
        ) : (
          <div style={footerContainerStyle}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={onClose}
            >
              {cancelLabel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={onClick}
            >
              {confirmLabel}
            </Button>
          </div>
        ))}
    </div>
  );
};

const containerStyle = {
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
  letterSpacing: 0,
  position: 'fixed',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

const bodyContainerStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 20px 30px 20px',
  fontSize: 14,
  justifyContent: 'center',
  alignItems: 'center'
};

const footerContainerStyle = {
  display: 'flex',
  padding: '10px 20px 10px 20px',
  flexDirection: 'row',
  borderTop: '1px #CACFD3 solid',
  justifyContent: 'center',
  alignItems: 'center'
};
export default ModalPresenter;
