import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useState } from 'react';
import AsyncAutoComplete from '@/components/AsyncAutoComplete';
import companyDispatcher from '@/module/company/action';
import { Button, IconButton, InputAdornment, Radio } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import PatientInfoCard from '@/new-components/CustomCard/PatientInfoCard';
import { CloseIcon, RadioActiveIcon, RadioInactiveIcon } from '@/assets/svg';
import { IOSSwitch } from '@/module/setup/components/mainScreen.styled';
import { formatCustomCategories } from '@/module/checkin-patient-information/util';
import { isEqual } from 'lodash';
import CustomTooltip from '@/new-components/CustomTooltip';
import { isBillableProject } from '@/module/setup/utils';

export const FIELD_ARRAY_VALUE = ['MultiSelect'];

const useStyles = makeStyles({
  border: {
    height: 1,
    width: '100%',
    margin: '8px 0',
    backgroundImage: 'linear-gradient(to right, #C1C1C1 50%, transparent 50%)',
    backgroundPosition: 'top',
    backgroundSize: '10px 1px',
    backgroundRepeat: 'repeat-x'
  },

  container: {
    display: 'flex'
  },

  rightContainer: {
    overflow: 'scroll',
    padding: '20px 40px',
    height: 'calc(100vh - 282px)'
  },

  leftContainer: {
    padding: '20px 40px',
    flex: '1',

    overflow: 'auto',
    borderRight: '1px solid #F1F1F1',
    height: 'calc(100vh - 282px)'
  },
  radioBtn: {
    display: 'flex',
    marginBottom: 8,

    '&:hover': {
      cursor: 'pointer'
    },

    '& > span': {
      margin: 'auto 0'
    }
  }
});

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 8,
    fontWeight: 600,
    color: '#666666'
  }
})(InputLabel);

const CustomSubmitButton = withStyles({
  root: {
    minWidth: 'unset',
    maxWidth: 86
  }
})(Button);

const CustomTextField = withStyles({
  root: {
    marginBottom: 28,
    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8
    },

    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    }
  }
})(TextField);

const SelectPatientStep = ({
  patientInfo,
  setPatientInfo,
  projectInfo,
  setProjectInfo,
  formValue,
  setFormValue,
  setIsEditCode,
  errors,
  setErrors,
  setProjectCustomFields,
  setCurrentLocation
}) => {
  const classes = useStyles();
  // Disable updating status payment of Paxscreening when Project is free payment
  const [isProjectSuppression, setIsProjectSuppression] = useState(false);

  const isValidDateTime = data => {
    const { endDate } = data;

    const endDateCompare = moment(endDate)
      .startOf('date')
      .unix();
    const dateCompare = moment()
      .startOf('date')
      .unix();

    if (endDateCompare < dateCompare) return true;

    return false;
  };

  const handleChangeForm = key => e => {
    let value = '';
    let newProject = {};
    // Allow alphanumeric characters
    const regex = /^[a-zA-Z0-9]*$/;

    switch (key) {
      case 'code':
        if (regex.test(e.target.value)) {
          value = e.target.value;
        }
        setIsEditCode(true);
        break;
      // case 'staffType':
      //   value = projectInfo?.lstStaffTypeItems[e.target.value];
      //   newProject = { ...projectInfo, [key]: value };
      //   break;
      case 'isPaxscreenSuppression':
        value = e.target.checked;
        newProject = { ...projectInfo };
        break;
      default:
        value = e.target.value;
        break;
    }
    setProjectInfo(newProject);
    setFormValue({ ...formValue, [key]: value });
  };

  const handlePatientData = (key, newValue) => {
    const projectId = newValue?.projectId;

    if (projectId) {
      companyDispatcher.getProjectByID(projectId, result => {
        const filterScreeningField = formatCustomCategories(
          [],
          FIELD_ARRAY_VALUE
        );

        setFormValue({
          ...formValue,
          code: result.code,
          lstScreeningFields: filterScreeningField,
          isPaxscreenSuppression: result.isSuppression
        });
        setProjectCustomFields({
          id: result.id,
          name: result.name,
          items: result.lstProjectCustomFields
        });
        setProjectInfo(result);
        setIsProjectSuppression(result.isSuppression);
        setCurrentLocation({});
        if (isValidDateTime(result)) {
          setErrors({
            code:
              'The company code you entered is out of date. Please enter again.'
          });
        } else setErrors({});
      });
    } else {
      setFormValue({ [key]: newValue });
      setProjectInfo({});
    }

    setPatientInfo(newValue);
  };

  const onSaveCode = code => {
    if (formValue?.location) delete formValue?.lgocation;
    if (formValue?.staffType) delete formValue?.staffType;

    setProjectInfo({});
    setCurrentLocation({});
    if (code)
      companyDispatcher.getProjectByCode(code, result => {
        if (result) {
          const filterScreeningField = formatCustomCategories(
            [],
            FIELD_ARRAY_VALUE
          );

          setFormValue({
            ...formValue,
            lstScreeningFields: filterScreeningField,
            isPaxscreenSuppression: result.isSuppression
          });
          setProjectInfo(result);
          setIsProjectSuppression(result.isSuppression);

          setProjectCustomFields({
            id: result.id,
            name: result.name,
            items: result.lstProjectCustomFields
          });

          if (isValidDateTime(result)) {
            setErrors({
              code:
                'Please enter a new company code as the one entered has already expired.'
            });
          } else setErrors({});
        } else
          setErrors({
            code: 'Please enter a correct company code.'
          });
      });
    setIsEditCode(false);
  };

  const handleToggle = value => {
    const newStaffType = formValue.staffType === value ? '' : value;

    // If the selected value is already the current value, unselect it
    setFormValue({
      ...formValue,
      staffType: newStaffType
    });
    setProjectInfo({ ...projectInfo, staffType: newStaffType });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.leftContainer}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <CustomInputLabel>Patient name</CustomInputLabel>
            <AsyncAutoComplete
              label="Patient"
              endpoint="Patients/filter"
              method="post"
              defaultFilter={{
                isFamilyMember: false,
                filterPatient: 'HealthScreening',
                approveStatuses: ['Approved', 'Pending']
              }}
              value={patientInfo}
              setValue={newValue => handlePatientData('patientInfo', newValue)}
              getOptionLabel={option =>
                option.fullName &&
                option.identityNumber &&
                `${option.fullName} - ${option.identityNumber}`
              }
              renderInput={params => (
                <CustomTextField
                  variant="outlined"
                  {...params}
                  placeholder="Enter patient name or ID number"
                />
              )}
              renderOption={option =>
                option.fullName &&
                option.identityNumber &&
                `${option?.fullName} - ${option?.identityNumber}`
              }
            />
          </Grid>
          <Grid item md={12}>
            <CustomInputLabel>Project code</CustomInputLabel>
            <Box display="flex" justifyItems="space-between" gridGap={16}>
              <CustomTextField
                fullWidth
                variant="outlined"
                value={formValue?.code || ''}
                onChange={handleChangeForm('code')}
                placeholder="Enter code"
                error={errors?.code}
                helperText={errors?.code}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{
                          visibility:
                            formValue?.code && !isEmpty(String(formValue?.code))
                              ? 'visible'
                              : 'hidden'
                        }}
                        onClick={() => {
                          setFormValue({
                            ...formValue,
                            staffType: null,
                            code: ''
                          });
                          setProjectInfo({});
                        }}
                      >
                        <CloseIcon
                          height={20}
                          width={20}
                          style={{
                            color: '#666666'
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <CustomSubmitButton
                size="large"
                variant="contained"
                color="primary"
                disabled={!formValue?.code}
                onClick={() => onSaveCode(formValue?.code)}
              >
                Submit
              </CustomSubmitButton>
            </Box>
          </Grid>

          {!isEmpty(projectInfo.lstStaffTypeItems) &&
            !isBillableProject(projectInfo.billingType) && (
              <Grid item md={12}>
                <CustomInputLabel>Staff type</CustomInputLabel>
                {projectInfo.lstStaffTypeItems.map((it, index) => (
                  <Box
                    key={index}
                    ml="-10px"
                    className={classes.radioBtn}
                    onClick={() => handleToggle(it)}
                  >
                    <Radio
                      checked={isEqual(it, formValue.staffType)}
                      color="primary"
                      icon={<RadioInactiveIcon />}
                      checkedIcon={<RadioActiveIcon />}
                    />
                    <CustomTooltip content={it.type} />
                  </Box>
                ))}
              </Grid>
            )}

          {!isEmpty(projectInfo) &&
            !isBillableProject(projectInfo.billingType) && (
              <Grid item md={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gridGap={16}
                >
                  <Box>
                    <CustomInputLabel>Free payment</CustomInputLabel>
                    The appointment no longer requires further payment from the
                    patient
                  </Box>
                  <IOSSwitch
                    disabled={projectInfo.isSuppression}
                    checked={formValue.isPaxscreenSuppression}
                    onChange={handleChangeForm('isPaxscreenSuppression')}
                  />
                </Box>
              </Grid>
            )}
        </Grid>
      </Box>
      <Box className={classes.rightContainer}>
        <PatientInfoCard patientInfo={patientInfo} projectInfo={projectInfo} />
      </Box>
    </Box>
  );
};

export default SelectPatientStep;
