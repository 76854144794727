import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getBase64 } from '@/helpers';
import { ButtonEnhance } from '../../componentUI/commonStyleComponents';
import LoadingButton from '@/components/LoadingButton';
import { UploadSingle } from '..';
import { TYPE_MODAL } from '@/module/livestream/constants';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledModalContent = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  max-height: 90%;
  .ReactCrop {
    img {
      max-height: 600px;
      min-height: 100px;
    }
  }
`;

const StyledWrapButton = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
`;

export function getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  // New lines to be added
  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        blob.name = fileName;
        resolve(blob);
      },
      'image/jpeg',
      1
    );
  });
}

const CropImage = props => {
  const { image, onChange, previousImage, typeModal } = props;
  const [imageUrl, setImageUrl] = useState(null);
  const [crop, setCrop] = useState({
    unit: 'px',
    width: 100,
    x: 0,
    y: 0,
    height: 100
  });

  const [completedCrop, setCompletedCrop] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [isShowCropImageModal, setIsShowCropImageModal] = useState(false);

  const handleApplyCropImage = async () => {
    if (!image) {
      return;
    }
    const cropImage = await getCroppedImg(
      currentImage,
      completedCrop,
      image.name
    );
    const fileCropImage = new File([cropImage], image.name, {
      type: image.type
    });
    fileCropImage.uid = image.uid;
    onChange(fileCropImage);
    setIsShowCropImageModal(false);
  };

  const handleCancelCropImage = () => {
    onChange(previousImage);
    setIsShowCropImageModal(false);
  };

  useEffect(() => {
    if (image && image?.constructor === File) {
      getBase64(image, img => img && setImageUrl(img));
    }
  }, [image]);

  const onLoad = image => {
    setCurrentImage(image);
    const width = image.width > 100 ? image.width - 50 : image.width - 4;
    const height = image.height > 100 ? image.height - 50 : image.height - 4;
    setCrop({
      unit: 'px',
      width,
      x: Math.floor(image.width / 2) - Math.floor(width / 2),
      y: Math.floor(image.height / 2) - Math.floor(height / 2),
      height
    });
    return false;
  };

  return (
    <div>
      <UploadSingle
        disabled={typeModal === TYPE_MODAL.View}
        imageUrlSelected={image}
        onChange={onChange}
        setIsShowCropImageModal={setIsShowCropImageModal}
        accept={['jpg', 'jpeg', 'png', 'gif', 'svg', 'svg+xml']}
      />
      <Modal
        open={isShowCropImageModal}
        onClose={() => setIsShowCropImageModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledWrapper>
          <StyledModalContent>
            <ReactCrop
              src={imageUrl}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={c => setCrop(c)}
              onComplete={c => setCompletedCrop(c)}
            />
            <StyledWrapButton>
              <ButtonEnhance
                background="#E9E9E9"
                color="black"
                backgroundHover="#ccc"
                onClick={() => handleCancelCropImage()}
              >
                Cancel
              </ButtonEnhance>
              <LoadingButton onClick={handleApplyCropImage}>Save</LoadingButton>
            </StyledWrapButton>
          </StyledModalContent>
        </StyledWrapper>
      </Modal>
    </div>
  );
};

CropImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  previousImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  typeModal: PropTypes.string
};

CropImage.defaultProps = {
  image: null,
  onChange: () => {},
  previousImage: null,
  typeModal: TYPE_MODAL.View
};

export default CropImage;
