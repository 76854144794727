import Image from '../../components/Image';
import { doctorStatus } from '../../enum/RequestEnum';
import { renderIf } from '../../helpers';
import {
  approvePatient,
  rejectPatient,
} from '../../helpers/waitingRoomSignalR';
import { ButtonEnhance } from '../common/componentUI/commonStyleComponents';
import SelectChildrenParticipants from './SelectChildrenParticipants';
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React from 'react';

const useStyles = makeStyles({
  root: {
    padding: '0px !important',
    minWidth: 780,
    // width: 780
  },
  image: {
    width: 540,
    height: 419,
    borderRadius: 4,
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 20px 30px 20px',
    fontSize: 14,
    backgroundColor: '#f7f7f7',
    borderRadius: 10,
  },
  title: {
    color: '#192637',
    fontSize: 18,
    lineHeight: '27px',
  },
});

const VerifyIDPanel = ({
  verifyingUserData,
  doCalling,
  closeModal,
  setRejectModal,
}) => {
  const classes = useStyles();
  const doVerify = async () => {
    approvePatient(verifyingUserData.UserId);
    doCalling({ data: verifyingUserData });
    closeModal();
  };

  const doReject = () => {
    setRejectModal(true);
    closeModal(true);
  };

  return (
    <>
      <DialogTitle>
        <div className={classes.header}>
          <div className={classes.title}>Verify Identification </div>

          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => closeModal(true)}
          >
            <HighlightOffIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.body}>
          {verifyingUserData.IdentityStatus !== doctorStatus.Approved && (
            <>
              <span style={{ color: '#192637' }}>
                Please verify if the photos uploaded by the patient match our
                record.
              </span>
              <span style={{ marginTop: 20, color: '#8F9BA9' }}>
                NRIC (Patient Upload):
                <span
                  style={{ marginLeft: 10, color: '#192637', fontWeight: 600 }}
                >
                  {verifyingUserData.IdentityNumber}
                </span>
              </span>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={6}>
                  <Image
                    src={verifyingUserData.FrontIdentityUrl}
                    alt="Client uploaded ID's frontside"
                    className={classes.image}
                  />
                  <span style={{ color: '#192637' }}>ID Front</span>
                </Grid>
                <Grid item xs={6}>
                  <Image
                    src={verifyingUserData.BackIdentityUrl}
                    alt="Client uploaded ID's backside"
                    className={classes.image}
                  />
                  <span style={{ color: '#192637' }}>ID Back</span>
                </Grid>
              </Grid>
            </>
          )}

          {renderIf(verifyingUserData.CorperatePlan)(
            <span style={{ marginTop: 20, color: '#8F9BA9' }}>
              Corporate Name:
              <span
                style={{ marginLeft: 10, color: '#192637', fontWeight: 600 }}
              >
                {verifyingUserData.CorperatePlan}
              </span>
            </span>
          )}
          <Grid container spacing={2}>
            {renderIf(verifyingUserData.FrontCorperatePlan)(
              <Grid item xs={6}>
                <Image
                  src={verifyingUserData.FrontCorperatePlan}
                  alt="FrontCorperatePlan"
                  style={{ borderRadius: 5, width: 360, height: 219 }}
                />
                <span style={{ color: '#192637' }}>
                  Front of Corporate Plan
                </span>
              </Grid>
            )}
            {renderIf(verifyingUserData.BackCorperatePlan)(
              <Grid item xs={6}>
                <Image
                  src={verifyingUserData.BackCorperatePlan}
                  alt="BackCorperatePlane"
                  style={{ borderRadius: 5, width: 360, height: 219 }}
                />
                <span style={{ color: '#192637' }}>Back of Corporate Plan</span>
              </Grid>
            )}
          </Grid>
        </div>
        <Box marginTop={2}>
          <SelectChildrenParticipants />
        </Box>
      </DialogContent>

      <DialogActions className={classes.footer}>
        {verifyingUserData.IdentityStatus !== doctorStatus.Approved ? (
          <>
            <ButtonEnhance
              background="#EA6B75"
              backgroundHover="#994950"
              onClick={doReject}
            >
              Reject
            </ButtonEnhance>
            <ButtonEnhance onClick={doVerify}>View and Approve</ButtonEnhance>
          </>
        ) : (
          <ButtonEnhance
            onClick={() => {
              doCalling({ data: verifyingUserData });
            }}
          >
            Continue
          </ButtonEnhance>
        )}
      </DialogActions>
    </>
  );
};

export default VerifyIDPanel;
