const SEARCH_PARAM = {
  workoutTypes: ['StudioClass'],
  categoriesId: []
};

const TYPE_MODAL = {
  Create: 'Create',
  View: 'View',
  Edit: 'Edit'
};

const LIST_REQUIRED = [
  'title',
  'categoryId',
  'trainerStudioClassId',
  'date',
  'studioId',
  'price',
  'slot',
  'duration'
];

const FORM_VALUE = {
  title: '',
  thumbnail: '',
  price: 0,
  slot: '',
  categoryId: '',
  trainerStudioClassId: '',
  studioId: ''
};

const FORMAT_DATE_TIME = 'HH:mm, DD MMM YYYY';

export {
  SEARCH_PARAM,
  TYPE_MODAL,
  LIST_REQUIRED,
  FORM_VALUE,
  FORMAT_DATE_TIME
};
