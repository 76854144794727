/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import {
  Paper,
  Box,
  TextField,
  makeStyles,
  Grid,
  MenuItem,
  ButtonGroup,
  Button,
  FormControl,
  FormLabel,
  withStyles,
  Typography,
  IconButton
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { CONDITIONS, GENDER, TYPES } from './../contansts/index';
import { cloneDeep, isEmpty } from 'lodash';
import clsx from 'clsx';
import { AddContainer } from '@/module/setup/components/ServiceItems/CustomSurvey/styled';
import { Add, ArrowDown, TrashIcon } from '@/assets/svg';

const CustomTitleLabel = withStyles(theme => ({
  root: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 4,
    color: theme.palette.grey.mainText
  }
}))(Typography);

const CustomButtonGroup = withStyles(theme => ({
  root: {
    gap: 8,
    display: 'flex',
    flexDirection: 'row',
    padding: 4,
    borderRadius: 8,
    backgroundColor: theme.palette.grey.disableBackground,

    '& .MuiButtonGroup-groupedHorizontal:not(:last-child)': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8
    },
    '& .MuiButtonGroup-groupedHorizontal:not(:first-child)': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8
    }
  }
}))(ButtonGroup);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    border: '1px solid #E1E1E1',
    boxShadow: 'none',
    borderRadius: 12
  },
  errorContainer: {
    border: '2px solid #f44336'
  },
  statementContainer: {
    marginTop: theme.spacing(4)
  },
  buttonGroup: {
    padding: 4,
    borderRadius: 8,
    backgroundColor: '#F1F1F1',
    boxShadow: 'none !important'
  },
  button: {
    textTransform: 'none !important',
    maxWidth: 116,
    width: 116,
    height: 32
  }
}));
const Expression = ({
  data,
  onDelete,
  onAddRule: onAddRuleProp,
  onAddGroup: onAddGroupProp,
  error
}) => {
  const [refreshFlag, setRefresh] = useState(false);
  const isEntry = Array.isArray(data);
  const isGroup = data?.type === 'Group';

  const {
    code = '',
    conjunction = '',
    condition = '',
    value = '',
    type = '',
    gender = ''
  } = data || {};
  const classes = useStyles();

  const { tests } = useSelector(state => state.globalState);

  useEffect(() => {
    refresh();
  }, []);

  const isValid = useMemo(() => {
    let isValid = true;
    if (type === 'Group' && data?.children?.length === 0) {
      isValid = false;
    } else if (!isEntry) {
      if (type === '') isValid = false;
      else if (type === 'Gender' && !gender) isValid = false;
      else if (type === 'Age' && (!condition || !value)) isValid = false;
      else if (type === 'TestCode' && (!condition || !value || !code))
        isValid = false;
    }

    data.isValid = isValid;
    return isValid;
  }, [refreshFlag]);

  const refresh = () => setRefresh(!refreshFlag);

  const onAddRule = () => {
    const newRule = {
      type: '',
      conjunction: 'And',
      code: '',
      condition: '',
      value: ''
    };
    if (onAddRuleProp) {
      onAddRuleProp();
    } else if (data?.children) {
      data.children = [...data.children, newRule];
    } else {
      data = [...data, newRule];
    }
    refresh();
  };

  const onAddGroup = () => {
    const newGroup = {
      type: 'Group',
      conjunction: 'And',
      children: []
    };
    if (onAddGroupProp) {
      onAddGroupProp();
    } else if (data?.children) {
      data.children = [...data.children, newGroup];
    } else {
      data = [...data, newGroup];
    }
    refresh();
  };

  const handleChangeTest = (_e, value) => {
    data.code = value?.key;
    refresh();
  };

  const handleChangeValue = (key = 'value') => e => {
    data[key] = e.target.value;

    // if key === type, reset data
    if (key === 'type') {
      data.conjunction = 'And';
      data.code = '';
      data.condition = '';
      data.value = '';
    }

    refresh();
  };

  const renderItems = () => {
    if (isEntry)
      return data.map((item, index) => (
        <Box py={1}>
          <CustomTitleLabel>
            {item.type === 'Group' ? 'Group' : 'Rule'} {index + 1}
          </CustomTitleLabel>
          <Expression
            key={index}
            data={item}
            onDelete={() => {
              data.splice(index, 1);
              refresh();
            }}
          />
        </Box>
      ));
    else {
      return data.children.map((item, index) => (
        <Box py={1}>
          <CustomTitleLabel>Rule {index + 1}</CustomTitleLabel>
          <Expression
            data={item}
            onDelete={() => {
              data.children.splice(index, 1);
              refresh();
            }}
          />
        </Box>
      ));
    }
  };

  const renderRelationButtons = () => {
    if (isEntry) return null;
    return (
      <Box display="flex" alignItems="center">
        <CustomButtonGroup>
          <Button
            className={classes.button}
            variant="contained"
            style={{
              backgroundColor: conjunction === 'And' ? '#AD5E99' : '#F1F1F1'
            }}
            color={conjunction === 'And' ? 'primary' : 'default'}
            onClick={() => {
              data.conjunction = 'And';
              refresh();
            }}
          >
            And
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            style={{
              backgroundColor: conjunction === 'Or' ? '#AD5E99' : '#F1F1F1'
            }}
            color={conjunction === 'Or' ? 'primary' : 'default'}
            onClick={() => {
              data.conjunction = 'Or';
              refresh();
            }}
          >
            Or
          </Button>
        </CustomButtonGroup>
      </Box>
    );
  };

  const renderExpressionsHeader = () => (
    <Box display="flex">
      {isGroup || isEntry ? (
        <AddContainer>
          <Button color="primary" startIcon={<Add />} onClick={onAddRule}>
            Add rule
          </Button>
          or
          <Button color="primary" startIcon={<Add />} onClick={onAddGroup}>
            Add group
          </Button>
        </AddContainer>
      ) : null}
      {!isEntry ? (
        <IconButton onClick={onDelete}>
          <TrashIcon />
        </IconButton>
      ) : null}
    </Box>
  );
  if (data === null) return null;

  // case of type is not Group (TestCode || Age || Gender)
  if (!isEntry && !isGroup)
    return (
      <Paper
        className={clsx(classes.container, {
          [classes.statementContainer]: isEntry,
          [classes.errorContainer]: !isValid && error
        })}
      >
        <Box display="flex" justifyContent="space-between">
          {renderRelationButtons()}
          {renderExpressionsHeader()}
        </Box>
        <Box display="flex">
          <Grid container spacing={4}>
            <Grid item xs={6} md={3}>
              <CustomTitleLabel>Type</CustomTitleLabel>
              <TextField
                fullWidth
                select
                size="small"
                variant="outlined"
                onChange={handleChangeValue('type')}
                value={type || ''}
                SelectProps={{
                  IconComponent: ArrowDown,
                  displayEmpty: true,
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right'
                    },
                    getContentAnchorEl: null
                  }
                }}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {TYPES.map(t => (
                  <MenuItem key={'type_' + t} value={t}>
                    {t}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {type === 'TestCode' && !isEmpty(type) ? (
              <Grid item xs={6} md={3}>
                <CustomTitleLabel>Code</CustomTitleLabel>

                <Autocomplete
                  size="small"
                  getOptionLabel={option => option['key'] || ''}
                  getOptionSelected={option => option['key'] === code}
                  options={tests}
                  value={tests.find(test => test.key === code) || ''}
                  autoComplete
                  onChange={handleChangeTest}
                  renderInput={params => (
                    <TextField required {...params} variant="outlined" />
                  )}
                  popupIcon={<ArrowDown />}
                />
              </Grid>
            ) : null}
            {!isEmpty(type) && type !== 'Gender' ? (
              <Grid item xs={6} md={3}>
                <CustomTitleLabel>Condition</CustomTitleLabel>

                <TextField
                  fullWidth
                  select
                  size="small"
                  variant="outlined"
                  onChange={handleChangeValue('condition')}
                  value={condition || ''}
                  SelectProps={{
                    IconComponent: ArrowDown,
                    displayEmpty: true,
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      },
                      getContentAnchorEl: null
                    }
                  }}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {CONDITIONS.map(c => (
                    <MenuItem key={'condidion_' + c} value={c}>
                      {c}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}
            {!isEmpty(type) ? (
              type === 'Gender' ? (
                <Grid item xs={6} md={3}>
                  <CustomTitleLabel>Gender</CustomTitleLabel>

                  <TextField
                    fullWidth
                    select
                    size="small"
                    variant="outlined"
                    onChange={handleChangeValue('gender')}
                    value={gender || ''}
                    SelectProps={{
                      IconComponent: ArrowDown,
                      displayEmpty: true,
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'right'
                        },
                        getContentAnchorEl: null
                      }
                    }}
                  >
                    {GENDER.map(g => (
                      <MenuItem key={'gender_' + g.value} value={g.value}>
                        {g.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : type === 'Age' ? (
                <Grid item xs={6} md={3}>
                  <CustomTitleLabel>Age</CustomTitleLabel>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    value={value}
                    onChange={handleChangeValue()}
                    size="small"
                    placeholder="Enter number"
                  />
                </Grid>
              ) : (
                <Grid item xs={6} md={3}>
                  <CustomTitleLabel>Value</CustomTitleLabel>

                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    value={value}
                    onChange={handleChangeValue()}
                    size="small"
                    placeholder="Enter value"
                  />
                </Grid>
              )
            ) : null}
          </Grid>
        </Box>
      </Paper>
    );

  return (
    <Paper
      elevation={0}
      className={clsx({
        [classes.statementContainer]: !isEntry,
        [classes.container]: !isEntry,
        [classes.errorContainer]: !isValid && error
      })}
    >
      <Box display="flex" justifyContent="space-between">
        {!isEntry ? renderRelationButtons() : null}
        {renderExpressionsHeader()}
      </Box>
      <Box display="flex" flexDirection="column">
        {renderItems()}
      </Box>
    </Paper>
  );
};

const ExpressionContainer = ({ data, setFormValue, formValue, error }) => {
  const [refreshFlag, setRefresh] = useState(false);
  const refresh = () => setRefresh(!refreshFlag);

  return (
    <>
      <Expression
        data={formValue.expressions}
        error={error}
        onAddRule={() => {
          if (!formValue) return;
          let newExpression = cloneDeep(formValue.expressions);
          newExpression.push({
            type: '',
            conjunction: 'And',
            code: '',
            condition: '',
            value: ''
          });
          setFormValue({ ...formValue, expressions: newExpression });
          refresh();
        }}
        onAddGroup={() => {
          if (!formValue) return;
          let newExpression = cloneDeep(formValue.expressions);
          newExpression.push({
            type: 'Group',
            conjunction: 'And',
            children: []
          });
          setFormValue({ ...formValue, expressions: newExpression });
          refresh();
        }}
      />
      {error ? (
        <FormControl error>
          <FormLabel component="legend" style={{ marginTop: 16 }}>
            {error}
          </FormLabel>
        </FormControl>
      ) : null}
    </>
  );
};

export default ExpressionContainer;
