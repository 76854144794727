/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  DialogActions
} from '@material-ui/core';
import { ClearRounded } from '@material-ui/icons';
import { capitalize } from 'lodash';
import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import LoadingButton from '@/components/LoadingButton';
import FormUpload from './FormUpload';
import FormDownload from './FormDownload';

const useStyle = makeStyles({
  titleDialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 600
  },
  closeBtn: {
    cursor: 'pointer',
    float: 'right',
    color: '#CACFD3'
  }
});

const ModalShowResult = ({
  typeModal,
  onClose,
  onSubmit,
  listModule,
  selectedItem
}) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const [listFiles, setListFiles] = useState([]);
  const [fileData, setFileData] = useState({});

  const classes = useStyle();

  const onSubmitForm = async () => {
    try {
      setBtnLoading(true);
      onSubmit(listFiles, typeModal, isSuccess => {
        if (isSuccess) onClose();
        setBtnLoading(false);
      });
    } catch (errs) {
      setBtnLoading(false);
    }
    setBtnLoading(false);
  };

  const handleUpload = data => {
    const newListFiles = [...listFiles];
    newListFiles.push({ ...listFiles, ...data });

    setFileData(data);
    setListFiles(newListFiles);
  };

  return (
    <>
      <DialogTitle>
        <div className={classes.titleDialog}>
          {`${capitalize(typeModal)} Result`}
          <ClearRounded className={classes.closeBtn} onClick={onClose} />
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ padding: '16px 0' }}>
        {typeModal === 'download' ? (
          <FormDownload
            dataModule={listModule}
            hasIRReport={selectedItem.hasIRReport}
          />
        ) : (
          <FormUpload
            dataModule={listModule}
            label="Update Result PDF"
            fileData={fileData.url}
            onChange={handleUpload}
            listFiles={listFiles}
          />
        )}
      </DialogContent>
      <DialogActions>
        <ButtonEnhance
          background="#E9E9E9"
          color="black"
          backgroundHover="#ccc"
          onClick={onClose}
        >
          Cancel
        </ButtonEnhance>

        <LoadingButton showLoader={btnLoading} onClick={onSubmitForm}>
          {capitalize(typeModal)}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ModalShowResult;
