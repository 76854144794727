export const INTERNAL_ROLE = ['Trainer', 'InternalTrainer'];

export const BTN_LOGIN_ID = 'btn-login';
export const BTN_UPDATE_PROFILE_ID = 'btn-update-profile';
export const BTN_CREATE_ACCOUNT_ID = 'btn-create-account';

export const LOGIN_PROCESS_STEPS = {
  loginInfo: 1,
  verifyOTP: 2,
  updateInfo1: 3,
  updateInfo2: 4,
  updateOTP: 5,
  verify2FA: 6
};

export const REGISTER_PROCESS_STEPS = {
  accountInfo: 1,
  professionInfo: 2,
  personalInfo: 3,
  verifyOTP: 4
};
