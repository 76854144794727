import styled from 'styled-components';
import { DialogActions } from '@material-ui/core';

export const PaperWrapped = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
  min-height: calc(100vh - 200px);
  .no-tabs-header {
    border-bottom: 0.5px solid #ecedf0;
  }
  .add-btn {
    float: right;
    margin-top: 15px;
    height: 35px;
    padding: 8px 16px;
    margin-bottom: 20px;
    .add-group {
      display: flex;
      align-items: center;
      margin-top: -2px;
      justify-content: center;
      span {
        margin-right: 4px;
      }
    }
  }
  .delete-icon {
    padding: 0;
  }
`;

export const HeaderWrapped = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  .title {
    color: #192637;
    font-size: 24px;
    font-weight: 600;
  }
  .personal-btn {
    display: flex;
  }
`;

export const DetailWrapped = styled.div`
  padding: 20px;
  .icon {
    color: #cacfd3;
    font-size: 16px;
    margin: 0 15px;
  }
`;

export const TableWrapped = styled.div`
  padding: 0 20px;
  .action-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    div {
      margin: 0;
    }
  }
`;

export const ModalPage = styled.div`
  width: 400px;
  hr {
    background: #cacfd3;
    margin: 0;
  }
  .MuiInput-root {
    width: 100%;
  }
  .MuiFormControl-root {
    width: 100%;
    margin-top: 10px;
  }
  .MuiDialogContent-root {
    padding: 0;
    &:first-child {
      padding-top: 0;
    }
  }
  .test-header {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #192637;
    border-bottom: 1px solid rgb(202, 207, 211);
  }
  .test-body {
    padding: 0 20px 50px 20px;
    .options-label {
      font-size: 16px;
      font-weight: 600;
      color: #192637;
      margin-top: 25px;
    }
    .custom-divider {
      margin-left: 5px;
    }
    .select-adornment {
      display: flex;
      .MuiInput-underline:before {
        border: none;
      }
    }
    .drag-list {
      input {
        cursor: pointer;
      }
    }
  }
  .MuiTypography-colorError {
    font-size: 12px;
    font-weight: 500;
  }
`;

export const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 10px 0px;
`;

export const ValueItem = styled.div`
  cursor: pointer;
  padding: 5px 8px;
`;
export const StepWrapped = styled.div`
  width: 250px;
  .item {
    border-radius: 4px;
    padding: 15px;
    background: #f6f7fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .step-number {
      padding: 5px 13px
      border-radius: 50%;
      background: #cacfd3;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
    .detail-info {
      color: #8f9ba9;
      .title {
        font-size: 18px;
        font-weight: 600;
      }
      .description {
        font-size: 14px;
      }
    }
  }
  .active-step {
    background: #ad5e99;
    .step-number {
      background: #fff;
      color: #ad5e99;
    }
    .detail-info {
      color: #fff;
    }
  }
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;
`;
