import roleManagementDispatcher from '../action';

const initialState = {
  list: [],
  privilege: [],
  groupedPrivilege: []
};

const roleManagementReducer = roleManagementDispatcher(initialState, {
  [roleManagementDispatcher.getState]: (state, payload) => ({
    ...state,
    ...payload
  }),
  [roleManagementDispatcher.getUserPermissionSuccess]: (state, payload) => ({
    groupedPrivilege: payload.groupedPermission
  })
});

export default roleManagementReducer;
