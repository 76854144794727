import { createDownloadableLink } from '@/helpers';
import { synthesize } from 'redux-dispatcher';
import { transformData, transformDataTimeSlot } from '../utils';
import { cloneDeep, get, isEmpty } from 'lodash';
import { uploadImage } from '@/firebase';
const mapDispatchToAC = {
  getLocationSuccess: data => ({ data }),
  getLocation: (
    search,
    paging,
    sortOption = {},
    filterOption = {},
    cb
  ) => async ({ Api, getState }) => {
    let pagingData = paging || getState().location.paging;
    let { result, status } = await Api.post(`Locations/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...sortOption,
      ...filterOption
    });
    if (status === 200) {
      const { list, ...restParams } = result;
      const baseIndex = pagingData.pageIndex * pagingData.pageSize + 1;
      const dataAddIndex = list.map((item, index) => ({
        ...item,
        index: baseIndex + index
      }));

      locationDispatcher.getLocationSuccess({
        ...restParams,
        list: dataAddIndex
      });
      cb && cb(list);
    }
  },
  getAllLocation: callback => async ({ Api }) => {
    let { result, status } = await Api.post(`Locations/filter`, {});
    if (status === 200) {
      callback && callback(result.list);
    }
  },
  setState: (state, value) => ({ state, value }),
  createLocation: (data, callbackSuccess, callBackError) => async ({ Api }) => {
    let params = { ...data };
    if (!isEmpty(data?.imageUrl)) {
      const timeStamp = new Date().getTime();
      data.fileName = timeStamp + get(data, 'imageUrl.name');

      let storageUrl = await uploadImage(data.imageUrl, timeStamp);
      params.imageUrl = storageUrl;
    }

    const { status } = await Api.post('Locations', params);
    locationDispatcher.setState('loading', false);
    if (status === 200) {
      callbackSuccess && callbackSuccess();
    }
    callBackError && callBackError();
  },
  editLocation: (id, payload, callbackSuccess, callBackError) => async ({
    Api
  }) => {
    const data = cloneDeep(payload);
    if (data.imageUrl?.constructor === File) {
      const timeStamp = new Date().getTime();
      data.fileName = timeStamp + get(data, 'imageUrl.name');

      const storageUrl = await uploadImage(data.imageUrl);
      data.imageUrl = storageUrl;
    }

    const { result, status } = await Api.put(`Locations/${id}`, data);
    locationDispatcher.setState('loading', false);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
    callBackError && callBackError();
  },
  deleteLocation: (id, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.delete(`Locations/${id}`);
    if (status === 200) {
      customToast('success', 'Location deleted');
      callbackSuccess && callbackSuccess();
    }
  },
  updateStatus: ({ id, value, cb }) => async ({ Api, customToast }) => {
    const { status } = await Api.put(`Locations/status/${id}`, {
      status: value
    });
    if (status === 200) {
      customToast('success', 'Update status location success');
      cb && cb();
    }
  },
  getDetailLocation: (id, callbackSuccess) => async ({ Api }) => {
    const { status, result } = await Api.get(`Locations/${id}`);
    if (status === 200) {
      const newResult = transformData(result);
      callbackSuccess && callbackSuccess(newResult);
    }
  },
  getExportExcel: params => async ({ Api }) => {
    let { result, status } = await Api.post(`Locations/csv-report`, {
      ...params
    });
    status === 200 && createDownloadableLink(result, 'locations-report');
  },
  getDetailLocationTimeSlot: (data, callbackSuccess) => async ({ Api }) => {
    const { id, startDate } = data;

    const { status, result } = await Api.post(
      `LocationTimeSlots/filterByLocation`,
      {
        ...data,
        locationId: id,
        viewAll: true
      }
    );
    if (status === 200) {
      const newResult = transformDataTimeSlot(result);
      callbackSuccess && callbackSuccess(newResult);
    }
  },
  getLocationByProject: (projectId, callbackSuccess) => async ({ Api }) => {
    const { status, result } = await Api.get(`Locations/filter-by-project`, {
      projectId
    });
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  getLocationFollowUp: callback => async ({ Api }) => {
    const { status, result } = await Api.get(`Locations/follow-up`);
    if (status === 200) callback && callback(result);
  }
};

const locationDispatcher = synthesize('location', mapDispatchToAC);
export default locationDispatcher;
