import React, { useState, useEffect } from 'react';
import customToast from '@/new-components/CustomNotification';
import { Slide } from '@material-ui/core';
import authDispatcher from '../../action/auth';
import { urlLabel } from '../../../../enum/PermissionEnum';
import LoginInfo from './LoginInfo';
import { get } from 'lodash';
import { StyledButton } from '../../../common/componentUI/commonStyleComponents';
import { useSelector } from 'react-redux';
import validateData from '../../../../helpers/validationHelpers/validationSchema';
import { INTERNAL_ROLE } from '../../constants';
import { getStorage, setStorage, removeStorage } from '../../../../helpers';

const loginBtnId = 'btn-login';
const updateProfileBtnId = 'btn-update-profile';
const processSteps = {
  loginInfo: 1
  // verifyOTP: 2
};

const InternalLogin = ({ history }) => {
  const { loading } = useSelector(state => state.auth);
  const [step] = useState(processSteps.accountInfo);
  const [loginInfo, setLoginInfo] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const isChecked = getStorage('isChecked');
    if (isChecked) {
      setLoginInfo({
        email: getStorage('email') || '',
        password: getStorage('password') || ''
      });
    }
  }, []);

  const handleChangeInfo = key => e => {
    setLoginInfo({ ...loginInfo, [key]: get(e, 'target.value') });
  };
  const handleLogin = async remember => {
    try {
      const { email, password } = loginInfo;
      if (remember) {
        setStorage('email', email);
        setStorage('password', password);
        setStorage('isChecked', true);
      } else {
        removeStorage(['email', 'password', 'isChecked']);
      }
      await validateData('loginSchema', loginInfo, () => {
        authDispatcher.setState('loading', true);
        authDispatcher.login(email, password, (result, email) => {
          authDispatcher.setState('loading', false);
          if (!email) {
            setErrors({ ...errors, api: result });
            return;
          }
          if (!INTERNAL_ROLE.includes(result.roleType))
            return customToast('error', 'Permission denied');
          setErrors({});
          authDispatcher.loginSuccess(result, result.email);
          // setLoginResult(result);
          // toVerifyOTP({
          //   ...result,
          //   email
          // });
        });
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const toRegister = () => {
    history.push(`/${urlLabel.internalRegister}`);
  };

  const toForgotPassword = () => {
    history.push(`/${urlLabel.forgotPassword}`);
  };

  return (
    <>
      <StyledButton
        id={loginBtnId}
        style={{
          display: 'none'
        }}
      >
        Login
      </StyledButton>
      <StyledButton
        id={updateProfileBtnId}
        style={{
          display: 'none'
        }}
      >
        update
      </StyledButton>
      <Slide direction="left" in={step === processSteps.accountInfo}>
        <div
          key={step}
          style={{
            display: step === processSteps.accountInfo ? 'static' : 'none'
          }}
        >
          <LoginInfo
            values={loginInfo}
            errors={errors}
            onChange={handleChangeInfo}
            toForgotPassword={toForgotPassword}
            toRegister={toRegister}
            loading={loading}
            handleLogin={handleLogin}
          />
        </div>
      </Slide>
    </>
  );
};

export default InternalLogin;
