import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/core/Menu';
import { MoreVertOutlined } from '@material-ui/icons';

import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import { CreateNewIcon, DeleteModalIcon } from '@/assets/svg';
import GroupToolbar from './GroupToolbar';
import { useSelector } from 'react-redux';
import {
  COLUMN_PACKAGES,
  refactorDuplicateListData,
  renderPackageActions
} from '../../constants';
import { packageDispatcher } from '@/module/package-management';
import { debounce, isEmpty } from 'lodash';
import CreateAndEditPackage from './CreateAndEditPackage';
import { HS_SERVICE_TYPES } from '@/module/all-screenings/constants';
import { getCostRange, handleSelectAll } from '@/helpers';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import { formatTotalPrice } from '@/module/checkin-patient-information/util';
import globalDispatcher from '@/redux/global/actions';
import CustomTooltip from '@/new-components/CustomTooltip';

const Packages = () => {
  const { list, paging } = useSelector(state => state.package);

  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({});
  const [packageTypes, setPackageTypes] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [quickRange, setQuickRange] = useState(null);
  const [isCreate, setIsCreate] = useState(true);
  const [anchorEl, setAnchorEl] = useState();

  const [duplicateData, setDuplicateData] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [maxPriceDefault, setMaxPriceDefault] = useState(0);

  const data = useMemo(() => {
    let formattedList = list.map(p => {
      let totalTests,
        totalMeasurements,
        totalProcedures,
        totalRadiologies,
        totalVaccinations,
        totalReviews;

      totalTests = totalMeasurements = totalProcedures = totalRadiologies = totalVaccinations = totalReviews = 0;

      if (isEmpty(p.totalServices))
        return {
          ...p,
          totalTests,
          totalMeasurements,
          totalProcedures,
          totalRadiologies,
          totalVaccinations,
          totalReviews
        };

      [
        totalTests,
        totalMeasurements,
        totalProcedures,
        totalRadiologies,
        totalVaccinations,
        totalReviews
      ] = [
        'Tests',
        'Measurements',
        'Procedures',
        'Radiologies',
        'Vaccinations',
        'Reviews'
      ].map(
        category =>
          p.totalServices.find(i => i.category === category)?.total || 0
      );

      return {
        ...p,
        totalTests,
        totalMeasurements,
        totalProcedures,
        totalRadiologies,
        totalVaccinations,
        totalReviews
      };
    });
    return formattedList;
  }, [list]);

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    filterOptionData = filterOption
  ) => {
    packageDispatcher.getData(
      search,
      pagingData,
      ...[filterOptionData].filter(i => i)
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  const onResetFilterOps = () => {
    setSearchKey('');
    setPackageTypes([]);
    setFilterOption({});
    setQuickRange(null);
  };

  const onFilterOptionClear = (key, defaultValue, setState) => () => {
    let clearValue = defaultValue;
    switch (key) {
      case 'genders':
        clearValue = '';
        break;
      case 'paxScreenStatuses':
        clearValue = [];
        break;

      default:
        break;
    }

    setFilterOption({
      ...filterOption,
      [key]: defaultValue
    });

    setState && setState(clearValue);
  };

  const onFilterOptionChange = (key, setState) => (e, newValue) => {
    let value = e?.target?.value || '';
    switch (key) {
      case 'minCost':
      case 'maxCost':
        value = e.value;
        break;
      case 'packageTypes':
        value = handleSelectAll(
          value,
          HS_SERVICE_TYPES.map(item => item.value),
          packageTypes
        );
        break;

      default:
        break;
    }

    setFilterOption({
      ...filterOption,
      [key]: value
    });
    setState && setState(value);
  };

  const onClonePackage = () => {
    let params = {
      ...duplicateData,
      name: `${duplicateData.name} (copied)`,
      code: `${duplicateData.code} (copied)`,
      ...refactorDuplicateListData(duplicateData),
      profiles: duplicateData?.lstProfiles.map(item => ({
        ...item,
        profileId: item.id
      }))
    };

    packageDispatcher.addPackage(params, () => {
      setAnchorEl(null);
      setSelectedItem(null);
      setDuplicateData(null);
      packageDispatcher.getData(searchKey, paging);
    });
  };

  const onDeletePackage = () => {
    packageDispatcher.deletePackage(selectedItem.id, () => {
      packageDispatcher.getData(searchKey);
      setDeleteModal(false);
    });
  };

  const listActions = renderPackageActions({
    onEdit: () => {
      setAnchorEl(null);
      setIsCreate(false);
      setOpenDrawer(true);
    },
    onClone: () => {
      setAnchorEl(null);
      onClonePackage();
    },
    onDelete: () => {
      setAnchorEl(null);
      setDeleteModal(true);
    }
  });

  useEffect(() => {
    debounceLoadData(searchKey, paging, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, filterOption]);

  useEffect(() => {
    if (!isEmpty(selectedItem)) {
      packageDispatcher.getPackageById(selectedItem.id, data => {
        setDuplicateData(data);
      });
    }
  }, [selectedItem]);

  useEffect(() => {
    globalDispatcher.getMaxPriceDefault('Package', result => {
      setMaxPriceDefault(result);
      setFilterOption({
        ...filterOption,
        maxCost: result,
        minCost: 0
      });
    });
  }, []);

  useEffect(() => {
    const [minCost, maxCost] = getCostRange(quickRange, maxPriceDefault);
    if (
      Number(filterOption.minCost) !== Number(minCost) ||
      Number(filterOption.maxCost) !== Number(maxCost)
    )
      setQuickRange(null);
  }, [filterOption, maxPriceDefault, quickRange]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {COLUMN_PACKAGES.map(item => (
          <TableCell
            key={item.stateValue || item.label}
            style={{ minWidth: item.minWidth || '' }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = row => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.name} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.code} />
        </TableCell>
        <TableCell>{row.packageType}</TableCell>
        <TableCell>{formatTotalPrice(row.price)}</TableCell>
        <TableCell>{row.totalTests}</TableCell>
        <TableCell>{row.totalProfiles}</TableCell>
        <TableCell>{row.totalMeasurements}</TableCell>
        <TableCell>{row.totalProcedures}</TableCell>
        <TableCell>{row.totalRadiologies}</TableCell>
        <TableCell>{row.totalVaccinations}</TableCell>
        <TableCell>{row.totalReviews}</TableCell>
        <TableCell>
          <IconButton
            onClick={e => {
              setAnchorEl(e.currentTarget);
              setSelectedItem(row);
            }}
          >
            <MoreVertOutlined />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        disableBackdropClick
        PaperProps={{
          style: {
            width: '50%',
            minWidth: 768
          }
        }}
      >
        <CreateAndEditPackage
          selectedItem={selectedItem}
          isCreate={isCreate}
          onClose={() => {
            setOpenDrawer(false);
            fetchData();
          }}
        />
      </Drawer>
      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder="Search by package name..."
            title="Packages"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                filterOption={filterOption}
                setFilterOption={setFilterOption}
                packageTypes={packageTypes}
                setPackageTypes={setPackageTypes}
                onFilterOptionClear={onFilterOptionClear}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
                quickRange={quickRange}
                setQuickRange={setQuickRange}
                maxPriceDefault={maxPriceDefault}
              />
            )}
            renderButton={() => (
              <Button
                color="primary"
                variant="contained"
                startIcon={<CreateNewIcon />}
                onClick={() => {
                  setOpenDrawer(true);
                  setIsCreate(true);
                }}
              >
                Create new
              </Button>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          noDataHelperText="No package"
          noDataHelperSubText="Please create a new package"
          totalCols={COLUMN_PACKAGES.length}
          fetchData={fetchData}
        />
      </CustomPaperContainer>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <CustomMenuActions listActions={listActions} />
      </Menu>

      {deleteModal && (
        <ModalConfirmation
          confirmLabel="Delete"
          mainContent="Delete package"
          subContent="Are you sure you want to delete this package ?"
          open={deleteModal}
          confirmColor="red"
          Icon={<DeleteModalIcon />}
          onClose={() => setDeleteModal(false)}
          onClick={onDeletePackage}
        />
      )}
    </>
  );
};

export default Packages;
