import { synthesize } from 'redux-dispatcher';
import { createDownloadableLink } from '@/helpers';
import { isEmpty } from 'lodash';
import globalDispatcher from '@/redux/global/actions';

const mapDispatchToAC = {
  getAllUsersSuccess: data => ({ data }),
  getAllUsers: (search, paging, sortOption = {}, filterOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().allUsers.paging;
    let { result, status } = await Api.post(`Homescreens/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...sortOption,
      ...filterOption
    });
    status === 200 && allUsersDispatcher.getAllUsersSuccess(result);
  },
  getUserDetail: (id, callback) => async ({ Api }) => {
    const { status, result } = await Api.get(`Homescreens/${id}`);
    if (status === 200) {
      callback && callback(result);
    }
  },
  editPersonalInfo: (id, data) => async ({ Api, customToast }) => {
    //convert lstIdentityUrl
    allUsersDispatcher.setState('loading', true);

    const { status } = await Api.put(`Homescreens/user/${id}`, {
      ...data
    });
    allUsersDispatcher.setState('loading', false);
    status === 200 && customToast('success', 'Edit success');
  },
  getPhysicalHistories: (patientId, callback, paging) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().allUsers.paging;
    let { result, status } = await Api.post(`PhysicalHistories/filter`, {
      patientId,
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search: ''
    });
    status === 200 && callback(result);
  },
  setState: (state, value) => ({ state, value }),
  getAllMedicalHistory: (id, cb) => async ({ Api, customToast }) => {
    const response = await Api.get(`MedicalHistories`, {
      patientId: id
    });
    response.status === 200 && cb && cb(response.result);
  },
  getExportExcel: search => async ({ Api }) => {
    let { result, status } = await Api.post(`Homescreens/csv-report`, {
      search
    });
    status === 200 && createDownloadableLink(result, 'user-homescreen');
  },
  getSurveyHistory: (params, callback = () => null) => async ({ Api }) => {
    const { patientId, paxScreeningId } = params || {};

    let { result, status } = await Api.get(`SurveyHistories`, {
      patientId,
      paxScreeningId
    });
    status === 200 && callback(result);
  },
  updateDoctorPE: (prarams, callback) => async ({ Api, customToast }) => {
    const { status } = await Api.put(
      `PhysicalHistories/doctor-submit`,
      prarams
    );
    if (status === 200) {
      callback && callback();
      customToast('success', 'Edit success');
    }
  }
};

const allUsersDispatcher = synthesize('allUsers', mapDispatchToAC);
export default allUsersDispatcher;
