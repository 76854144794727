import React from 'react';
import ServiceForm from '../../common/ServiceForm';

const ProcedureForm = props => (
  <ServiceForm
    {...{
      ...props,
      category: 'Procedures',
      prefixName: 'Procedure',
      requirePE: true
    }}
  />
);

export default ProcedureForm;
