import styled from 'styled-components';
import { DialogActions, FormControl, Select } from '@material-ui/core';

const ModalPage = styled.div`
  width: 1000px;
  hr {
    background: #cacfd3;
    margin: 0;
  }
  .MuiDialogContent-root {
    padding: 0;
    &:first-child {
      padding-top: 0;
    }
  }
  .test-header {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #192637;
    border-bottom: 1px solid rgb(202, 207, 211);
  }
  .test-body {
    padding: 0 20px 20px 20px;
  }
`;

export const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 10px 0px;
`;

export default ModalPage;
