import doctorDispatcher from '../action/doctor';
import authDispatcher from '../../auth/action/auth';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  },
  clinics: [],
  teleConsultStatus: true
};

const doctorReducer = doctorDispatcher(initialState, {
  [doctorDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [doctorDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [authDispatcher.logoutSuccess]: state => ({ ...initialState }),
  [doctorDispatcher.getClinicSuccess]: (state, { data }) => ({
    clinics: data
  }),
  [doctorDispatcher.getTeleConsultStatusSuccess]: (state, { data }) => ({
    teleConsultStatus: data
  })
});

export default doctorReducer;
