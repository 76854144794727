import React, { useState, useEffect, useMemo } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  Dialog,
  Typography,
  Fab,
  Tooltip
} from '@material-ui/core';
import { startCase } from 'lodash';
import {
  MoreVertRounded,
  EventAvailableRounded,
  CreateRounded,
  CancelRounded,
  VisibilityRounded,
  CheckCircleOutlineRounded,
  GetAppRounded
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TableContainer from '../../../components/Table/TableContainer';
import {
  TYPE_MODAL,
  AppointmentColor,
  AppointmentType,
  AppointmentStatus,
  FORMAT_DATE_TIME,
  renderAppointmentType,
  TYPE_BOOKING_TIME
} from '../constants';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import { appointmentManagementDispatcher } from '..';
import { ChipStatus } from '../../common/componentUI/commonStyleComponents';
import CustomDateRange from '../../../components/CustomDateRange';
import MenuAction from '../../../components/MenuAction';
import { DoctorIcon, ErrorCircleFilled } from '../../../assets/svg';
import DeleteModal from '../../../components/DeleteModal';
import AppointmentForm from './AppointmentForm';
import CustomSelectContainer from '@/components/CustomSelect/CustomSelectContainer';
import { ROLES_NAMES_SPECIAL } from '@/constants/roles';

const AppointmentManagement = () => {
  const { list, paging, listAvailableUsers } = useSelector(
    state => state.appointmentManagement
  );
  const userInfo = useSelector(state => state.auth.userInfo);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const isHiddenAppointmentTypes = [
    'Clinic Assistant',
    'MHS',
    'LabAdmin'
  ].includes(userInfo.roleName);

  const [filterOption, setFilterOption] = useState({
    appointmentTypes:
      userInfo.roleName === 'Swabber'
        ? ['TeleArt']
        : isHiddenAppointmentTypes
        ? ['TeleConsult', 'TeleReview', 'TeleMedicine']
        : []
  });
  const [sortOption, setSortOption] = useState({ by: 'Date', dir: 'desc' });

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDate, handleDateChange] = useState([null, null]);

  const [typeModal, setTypeModal] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentStatuses, setAppointmentStatuses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const appointmentTypeList = renderAppointmentType(
    userInfo.roleName === 'Swabber',
    isHiddenAppointmentTypes,
    true
  );

  useEffect(() => {
    const roleType = 'Doctor';
    userInfo.roleName !== 'Doctor' &&
      appointmentManagementDispatcher.getAvailableUser(roleType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.roleName]);

  const onShowModal = type => {
    setShowModal(true);
    setTypeModal(type);
    setAnchorEl(null);
  };

  const onCloseModal = () => {
    setShowModal(false);
    setTypeModal(null);
  };

  const onShowCancelModal = () => {
    setCancelModal(true);
    setAnchorEl(null);
  };

  const handleAcceptAppointment = id => () => {
    setAnchorEl(null);
    appointmentManagementDispatcher.doctorAcceptAppointment(
      id,
      onActionSuccess
    );
  };

  const listTableButtons = useMemo(
    () => [
      <Fab
        style={{
          backgroundColor: '#ad5e99',
          color: 'white',
          width: 40,
          height: 40,
          margin: 5
        }}
        aria-label="export"
        onClick={() =>
          appointmentManagementDispatcher.getExportExcel(
            searchKey,
            sortOption,
            filterOption
          )
        }
      >
        <Tooltip title="Export excel">
          <GetAppRounded style={{ fontSize: 18 }} />
        </Tooltip>
      </Fab>
    ],
    [filterOption, searchKey, sortOption]
  );

  const listActions = useMemo(() => {
    if (!selectedItem) return [];
    let listActions = [
      {
        key: 'view',
        icon: VisibilityRounded,
        label: 'View Details',
        onClick: () => onShowModal(TYPE_MODAL.View)
      }
    ];
    if (
      ![
        AppointmentStatus.Cancelled,
        AppointmentStatus.Rejected,
        AppointmentStatus.Missed,
        AppointmentStatus.Completed
      ].includes(selectedItem.appointmentStatus) &&
      !ROLES_NAMES_SPECIAL.includes(userInfo.roleName)
    ) {
      listActions = listActions.concat([
        // {
        //   key: 'assign',
        //   icon: DoctorIcon,
        //   label: 'Assign Doctor',
        //   onClick: () => onShowModal(TYPE_MODAL.AssignDoctor)
        // },
        {
          key: 'edit',
          icon: CreateRounded,
          label: 'Edit Appointment',
          onClick: () => onShowModal(TYPE_MODAL.Edit)
        },
        {
          key: 'cancel',
          icon: ErrorCircleFilled,
          label: 'Cancel Appointment',
          onClick: onShowCancelModal
        }
      ]);
    }

    if (
      [AppointmentStatus.Upcoming].includes(selectedItem.appointmentStatus) &&
      ROLES_NAMES_SPECIAL.includes(userInfo.roleName) &&
      !selectedItem.doctorId
    )
      listActions.push({
        key: 'accept',
        icon: CheckCircleOutlineRounded,
        label: 'Accept Appointment',
        onClick: handleAcceptAppointment(selectedItem.id)
      });
    return listActions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, userInfo.roleName]);

  const onFilterOptionChange = (state, setState) => e => {
    setFilterOption({
      ...filterOption,
      [state]: e.target.value
    });
    setState(e.target.value);
  };

  const onFilterOptionClear = (state, setState, defaultValue) => () => {
    setFilterOption({
      ...filterOption,
      [state]: defaultValue
    });
    setState(defaultValue);
  };

  const reloadAppointmentManagement = () => {
    setFilterOption({ ...filterOption });
  };

  const onActionSuccess = () => {
    reloadAppointmentManagement();
    onCloseModal();
  };

  const handleUpdateAppointment = (type, data) => {
    switch (type) {
      case TYPE_MODAL.AssignDoctor:
        appointmentManagementDispatcher.assignDoctorToAppointment(
          data.id,
          data.doctorId,
          onActionSuccess
        );
        break;
      case TYPE_MODAL.Edit:
        appointmentManagementDispatcher.editDoctorToAppointment(
          data.id,
          {
            ...data,
            appointmentType: data.appointmentType,
            appointmentDate: data.appointmentDate
          },
          onActionSuccess
        );
        break;
      case TYPE_MODAL.Create:
        appointmentManagementDispatcher.createDoctorToAppointment(
          {
            appointmentType: data.appointmentType,
            appointmentDate: data.appointmentDate,
            doctorId: data.doctorId,
            patientId: data.patient?.id,
            bookingTimeType:
              data.appointmentType === 'TeleArt'
                ? TYPE_BOOKING_TIME.SupervisedArt
                : TYPE_BOOKING_TIME.Other
          },
          onActionSuccess
        );
        break;
      default:
        break;
    }
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper label="Patient" stateValue="patient" />
        <TableCellFiltedWrapper label="Patient NRIC" stateValue="patientNRIC" />
        <TableCellFiltedWrapper
          label="Appointment Type"
          stateValue="appointmentType"
        />
        <TableCellFiltedWrapper
          label="Time"
          stateValue="date"
          sortBy="Date"
          sortOption={sortOption}
          onSortChange={() => {
            let newSortOption = {
              by: 'Date',
              dir: sortOption.dir !== 'asc' ? 'asc' : 'desc'
            };
            setSortOption(newSortOption);
          }}
        />
        {/* <TableCellFiltedWrapper label="Time" stateValue="time" /> */}
        {/* <TableCellFiltedWrapper label="Doctor" stateValue="doctorFullName" /> */}
        <TableCellFiltedWrapper label="Status" stateValue="appointmentStatus" />
        <TableCell />
      </TableRow>
    </TableHead>
  );

  const handleCancelAppointment = () => {
    appointmentManagementDispatcher.cancelAppointment(
      selectedItem.id,
      reloadAppointmentManagement
    );
    setCancelModal(false);
  };

  const handleShowActionMenu = item => event => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {cancelModal && (
        <DeleteModal
          showModal={cancelModal}
          selectedItem={selectedItem}
          onClose={() => setCancelModal(false)}
          onDelete={handleCancelAppointment}
          Icon={CancelRounded}
          title="Cancel Appointment"
          content={
            <>
              <Typography>
                Are you sure you want to cancel your appointment with
              </Typography>
              <Typography style={{ fontWeight: 600 }} display={'inline'}>
                {selectedItem.patientFullName}
              </Typography>
              ? Click ‘Yes’ to Continue.
            </>
          }
        />
      )}
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="md"
        fullWidth
        scroll="body"
        disableEnforceFocus
      >
        <AppointmentForm
          onClose={onCloseModal}
          selectedItem={selectedItem}
          typeModal={typeModal}
          listAvailableUsers={listAvailableUsers}
          onSubmit={handleUpdateAppointment}
          isDoctor={userInfo.roleName === 'Doctor'}
          isSwabber={userInfo.roleName === 'Swabber'}
          isHiddenAppointmentTypes={isHiddenAppointmentTypes}
        />
      </Dialog>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuAction listActions={listActions} />
      </Menu>

      <TableContainer
        title="Appointments"
        paging={paging}
        data={list}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        sortOption={sortOption}
        query={appointmentManagementDispatcher.getData}
        onAddNew={
          !ROLES_NAMES_SPECIAL.includes(userInfo.roleName) &&
          (() => {
            setSelectedItem(null);
            onShowModal(TYPE_MODAL.Create);
          })
        }
        ToolbarComponent={
          <>
            {userInfo.roleName !== 'Swabber' && (
              <CustomSelectContainer
                StartAdornment={EventAvailableRounded}
                filterValue={appointmentTypes}
                data={appointmentTypeList}
                renderValue={
                  <>
                    {appointmentTypes
                      .map(status => appointmentTypeList[status])
                      .join(', ') || 'Appointment Type'}
                  </>
                }
                onClear={onFilterOptionClear(
                  'appointmentTypes',
                  setAppointmentTypes,
                  []
                )}
                onChange={onFilterOptionChange(
                  'appointmentTypes',
                  setAppointmentTypes
                )}
              />
            )}

            <CustomDateRange
              selectedDate={selectedDate}
              onChange={(startDate, endDate) => {
                if (startDate && endDate) {
                  handleDateChange([startDate, endDate]);
                  setFilterOption({
                    ...filterOption,
                    fromDate: startDate.toISOString(),
                    toDate: endDate.toISOString()
                  });
                } else {
                  handleDateChange([null, null]);
                  setFilterOption({
                    ...filterOption,
                    fromDate: null,
                    toDate: null
                  });
                }
              }}
            />
            <CustomSelectContainer
              filterValue={appointmentStatuses}
              data={AppointmentStatus}
              renderValue={<>{appointmentStatuses.join(', ') || 'Status'}</>}
              onClear={onFilterOptionClear(
                'appointmentStatuses',
                setAppointmentStatuses,
                []
              )}
              onChange={onFilterOptionChange(
                'appointmentStatuses',
                setAppointmentStatuses
              )}
            />
          </>
        }
        renderRow={row => (
          <>
            <TableCell>
              {!hiddenRows.patient && (
                <>
                  <Typography>{row.patientFullName}</Typography>
                  <Typography style={{ color: '#8F9BA9' }}>
                    {row.patientShortId}
                  </Typography>
                </>
              )}
            </TableCell>
            <TableCell>{!hiddenRows.patientNRIC && row.patientNRIC}</TableCell>
            <TableCell>
              {!hiddenRows.appointmentType &&
                AppointmentType[row.appointmentType]}
            </TableCell>
            <TableCell>
              {!hiddenRows.date &&
                moment(row.appointmentDate).format(FORMAT_DATE_TIME)}
            </TableCell>
            {/* <TableCell>
              {!hiddenRows.doctorFullName &&
                (row.doctorFullName ? (
                  <>
                    <Typography>{row.doctorFullName}</Typography>
                    <Typography style={{ color: '#8F9BA9' }}>
                      {row.doctorAddress}
                    </Typography>
                  </>
                ) : (
                  '- -'
                ))}
            </TableCell> */}
            <TableCell>
              {!hiddenRows.appointmentStatus && (
                <ChipStatus color={AppointmentColor[row.appointmentStatus]}>
                  {startCase(row.appointmentStatus)}
                </ChipStatus>
              )}
            </TableCell>
            <TableCell align="right">
              <IconButton onClick={handleShowActionMenu(row)}>
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
        customButtons={listTableButtons}
      />
    </>
  );
};

export default AppointmentManagement;
