import swabberDispatcher from '../action/swabber';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  }
};

const swabberReducer = swabberDispatcher(initialState, {
  [swabberDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list.map(i => ({
      ...i,
      lstClinicId: i.lstClinic.map(c => c.id)
    })),
    paging: {
      ...payload.data,
      list: undefined
    }
  })
});

export default swabberReducer;
