import React from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import classnames from 'classnames';
import { makeStyles, TextField } from '@material-ui/core';
import { StaticDatePicker, PickersDay } from '@material-ui/pickers';
import PropTypes from 'prop-types';

const moment = extendMoment(Moment);

const useStyles = makeStyles(theme => ({
  highlight: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  firstHighlight: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  },
  endHighlight: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  }
}));

const WeekPicker = ({ changeWeek, value }) => {
  const classes = useStyles();

  const renderWeekPickerDay = (date, _selectedDates, DayComponentProps) => {
    const dateClone = moment(date);
    const selectedDateClone = moment(value);

    const start = moment(selectedDateClone).startOf('week');
    const end = moment(selectedDateClone)
      .endOf('week')
      .startOf('day');

    const rangeDate = moment().range(start, end);

    const dayIsBetween = rangeDate.contains(dateClone);
    const isFirstDay = moment(dateClone).isSame(start);
    const isLastDay = moment(dateClone).isSame(end);

    return (
      <PickersDay
        {...DayComponentProps}
        disableMargin
        className={classnames({
          [classes.highlight]: dayIsBetween,
          [classes.firstHighlight]: isFirstDay,
          [classes.endHighlight]: isLastDay
        })}
      />
    );
  };

  return (
    <StaticDatePicker
      showToolbar={false}
      disableMaskedInput
      showDaysOutsideCurrentMonth
      value={value}
      onChange={value =>
        changeWeek(
          moment(value)
            .startOf('week')
            .add('day', 1)
        )
      }
      renderDay={renderWeekPickerDay}
      renderInput={props => <TextField {...props} />}
    />
  );
};

WeekPicker.propTypes = {
  value: PropTypes.object.isRequired,
  changeWeek: PropTypes.func.isRequired
};

export default React.memo(WeekPicker);
