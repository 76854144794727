import React from 'react';
import ServiceForm from '../../common/ServiceForm';

const TestForm = props => (
  <ServiceForm
    {...{
      ...props,
      category: 'Tests',
      prefixName: 'Test',
      requireLabCode: true,
      requireLoinc: true
    }}
  />
);

export default TestForm;
