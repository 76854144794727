import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Modal, Typography, Button } from '../constants/OtherItemUI';
import { Paper } from '../constants/Table';

const styles = theme => ({
  paper: {
    position: 'absolute',
    // width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    top: '35%',
    left: '35%'
  },
  center: {},
  margin: {
    margin: theme.spacing(1)
  }
});

const InputModal = props => {
  const {
    open,
    title,
    onClickLeft,
    onClickRight,
    children,
    classes,
    onClose,
    buttonTextLeft,
    buttonTextRight,
    stylesBox
  } = props;
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open || false}
      onClose={onClose || null}
      className={classes.center}
    >
      <Paper className={classes.paper} style={{ ...stylesBox }}>
        <Typography
          style={{ fontWeight: 'bold', textAlign: 'center' }}
          variant="h2"
          id="modal-title"
        >
          {(title && title) || 'Warning'}
        </Typography>
        <div>{children}</div>

        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button
            onClick={onClickLeft || null}
            style={{ float: 'left', width: 150, marginLeft: 10 }}
            variant="outlined"
            color="secondary"
            size="medium"
            className={classes.margin}
          >
            {(buttonTextLeft && buttonTextLeft) || 'CANCEL'}
          </Button>
          <Button
            onClick={onClickRight || null}
            style={{ float: 'right', width: 150, marginRight: 10 }}
            variant="outlined"
            color="primary"
            size="medium"
            className={classes.margin}
          >
            {(buttonTextRight && buttonTextRight) || 'OK'}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

InputModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  buttonTextLeft: PropTypes.string,
  buttonTextRight: PropTypes.string,
  onClickLeft: PropTypes.func.isRequired,
  onClickRight: PropTypes.func.isRequired,
  stylesBox: PropTypes.object
};

export default withStyles(styles)(InputModal);
