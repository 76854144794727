import React, { useState, useRef, useMemo } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Divider from '@material-ui/core/Divider';
import { STAFF_SUBSIDY_TYPE } from '../../constants';
import { cloneDeep, isEmpty, isNumber } from 'lodash';
import InputLabel from '@material-ui/core/InputLabel';
import { Box } from '@material-ui/core';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { useEffect } from 'react';
import validateData from '@/helpers/validationHelpers/validationSchema';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  text: {
    fontFamily: 'Lato',
    fontWeight: 700
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  }
});

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,
    fontWeight: 600
  }
})(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 16,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const CustomDialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16
  },
  paperWidthXs: {
    maxWidth: 400
  }
})(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    '&.red': {
      background: '#DB3D49'
    },
    '&.Mui-disabled': {
      opacity: 0.3,
      color: '#ffffff',
      backgroundColor: '#AD5E99'
    }
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const CustomDialogContent = withStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  }
}))(DialogContent);

const CustomDialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    justifyContent: 'flex-end'
  }
}))(DialogActions);

const ModalImportStaffType = ({
  maxWidth = 'sm',
  open,
  title,
  submitLabel = 'Submit',
  otherActionsContent,
  onSubmit,
  onClose,
  selectedItem,
  typeModal
}) => {
  const [formValue, setFormValue] = useState({
    type: '',
    subsidy: '',
    staffValueType: 'Percent'
  });
  const [errors, setErrors] = useState({});
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const _tempForm = useRef(null);

  useEffect(() => {
    /** Block code to cache initial form data on edit mode */
    const handleExistItem = () => {
      setFormValue(selectedItem.item);
      _tempForm.current = cloneDeep(selectedItem.item);
    };
    !isEmpty(selectedItem.item)
      ? handleExistItem()
      : setFormValue({
          type: '',
          subsidy: '',
          staffValueType: 'Percent'
        });
  }, [typeModal, selectedItem]);

  const handleChangeForm = key => e => {
    /** Block code to cache initial form values */
    if (_tempForm.current === null) {
      _tempForm.current = cloneDeep(formValue);
    }

    let value;
    switch (key) {
      case 'subsidy':
        // Regex test number contain decimal. Maximum length number of decimal is 2
        const regexValue = /^([\d]{0,9})(\.[\d]{1,2})?$/g;
        const regexPercent = /^(?:100|(?:(?:\d{1,2})(?:\.\d{1,2})?))$/g;
        if (
          (formValue.staffValueType === 'Value' &&
            regexValue.test(Number(e.target.value))) ||
          (formValue.staffValueType === 'Percent' &&
            regexPercent.test(Number(e.target.value)))
        ) {
          value = e.target.value;
        }

        break;
      case 'staffValueType':
        value = e.target.value.value;
        break;
      default:
        value = e.target.value;
        break;
    }
    /** Update temporary form values on changes */
    setFormValue({ ...formValue, [key]: value });
  };

  const isObjNullish = obj => {
    return Object.values(obj).some(
      value => value === '' || value === null || value === undefined
    );
  };

  /** Check is disable Submit button on open edit modal */
  useEffect(() => {
    const isObjectEmpty =
      Object.keys(selectedItem).length === 0 &&
      selectedItem.constructor === Object;
    if (!isObjectEmpty) {
      const isDisable =
        selectedItem &&
        JSON.stringify(_tempForm.current) === JSON.stringify(formValue);
      setIsDisabledSubmit(isDisable);
    }
  }, [selectedItem]);

  /** Check form is valid to hit submit */
  useEffect(() => {
    const isFormInvalid = () => {
      return !_tempForm.current || isObjNullish(formValue) ? true : false;
    };
    setIsDisabledSubmit(isFormInvalid());
  }, [formValue]);

  const handleSubmit = async () => {
    try {
      await validateData('staffTypeSchema', formValue, () => {
        onSubmit(typeModal, formValue, selectedItem.position);
      });
    } catch (err) {
      setErrors(err);
    }
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(formValue);
    return !filterKeys.every(key => formValue[key] || !isEmpty(formValue[key]));
  }, [formValue]);

  return (
    <CustomDialog
      maxWidth={maxWidth}
      fullWidth
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomDialogTitle onClose={onClose}>{title}</CustomDialogTitle>
      <Divider light />
      <CustomDialogContent>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <CustomInputLabel>Staff type</CustomInputLabel>
            <CustomTextField
              fullWidth
              error={errors.type}
              helperText={errors.type}
              value={formValue.type || ''}
              onChange={handleChangeForm('type')}
              variant="outlined"
              placeholder="Enter details"
            />
          </Grid>

          <Grid item xs={12}>
            <CustomInputLabel>Value</CustomInputLabel>
            <Box display="flex" gridGap={8}>
              <CustomSelectContainer
                open={true}
                height="48px"
                label="label"
                data={STAFF_SUBSIDY_TYPE}
                isMutiple={false}
                renderValue={
                  STAFF_SUBSIDY_TYPE.find(
                    item => formValue?.staffValueType === item.value
                  )?.label || ''
                }
                filterValue={
                  STAFF_SUBSIDY_TYPE.find(
                    item => formValue?.staffValueType === item.value
                  ) || ''
                }
                onChange={handleChangeForm('staffValueType')}
                width={72}
              />
              <CustomTextField
                fullWidth
                error={errors.subsidy}
                helperText={errors.subsidy}
                value={formValue?.subsidy || ''}
                onChange={handleChangeForm('subsidy')}
                variant="outlined"
                placeholder="Enter value"
              />
            </Box>
          </Grid>
        </Grid>
      </CustomDialogContent>
      <Divider light />
      <CustomDialogActions>
        {otherActionsContent ? (
          otherActionsContent()
        ) : (
          <>
            <CustomButton
              disabled={isEmptyFilterParams}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              autoFocus
              size="small"
            >
              {submitLabel}
            </CustomButton>
          </>
        )}
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalImportStaffType;
