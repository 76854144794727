import {
  AddIcon,
  ImportIcon,
  EditStationsSequenceIcon,
  DeleteCircleRedIcon,
  EditGreyIcon
} from '@/assets/svg';
import { DeleteRounded, Edit, InfoRounded, GetApp } from '@material-ui/icons';

export const renderActions = ({ onEdit, onDelete, isEditPrice = true }) => {
  const LIST_ACTIONS = [
    {
      key: 'edit',
      icon: Edit,
      label: isEditPrice ? 'Edit price' : 'Edit',
      onClick: onEdit
    },
    {
      key: 'delete',
      icon: DeleteRounded,
      label: 'Delete',
      onClick: onDelete
    }
  ];
  return LIST_ACTIONS;
};

export const renderProfilesAction = ({ onDelete, onViewDetail }) => {
  const LIST_ACTIONS = [
    {
      key: 'view-detail',
      icon: InfoRounded,
      label: 'View detail',
      onClick: onViewDetail
    },
    {
      key: 'delete',
      icon: DeleteRounded,
      label: 'Delete',
      onClick: onDelete
    }
  ];
  return LIST_ACTIONS;
};

export const renderPackageAction = ({
  onEdit,
  onDelete,
  onViewDetail,
  isEdit
}) => {
  let LIST_ACTIONS = [
    {
      key: 'view-detail',
      icon: InfoRounded,
      label: 'View detail',
      onClick: onViewDetail
    },
    {
      key: 'edit',
      icon: Edit,
      label: 'Edit price',
      onClick: onEdit
    },
    {
      key: 'delete',
      icon: DeleteRounded,
      label: 'Delete',
      onClick: onDelete
    }
  ];
  if (!isEdit) {
    LIST_ACTIONS = LIST_ACTIONS.filter(action => action.key !== 'edit');
  }

  return LIST_ACTIONS;
};

export const renderProjectActions = (
  event,
  hasSubsidyClaimForm,
  isDisabledEditSequence
) => {
  let LIST_ACTIONS = [
    {
      key: 'edit',
      icon: EditGreyIcon,
      label: 'Edit',
      onClick: () => event('edit')
    },
    {
      key: 'editSequenceStations',
      icon: EditStationsSequenceIcon,
      label: 'Edit station sequence',
      disabled: isDisabledEditSequence,
      onClick: () => event('editSequenceStations')
    },
    {
      key: 'export',
      icon: ImportIcon,
      label: 'Export CR Report',
      onClick: () => event('export')
    }
  ];

  if (hasSubsidyClaimForm) {
    LIST_ACTIONS = LIST_ACTIONS.concat(
      {
        key: 'export-subsidy',
        icon: GetApp,
        label: 'Export Subsidy Claim Forms',
        color: '#333333',
        onClick: () => event('export-subsidy')
      },
      {
        key: 'delete',
        icon: DeleteCircleRedIcon,

        fill: '#DB3D49',
        label: 'Delete',
        color: '#DB3D49',
        onClick: () => event('delete')
      }
    );
  } else {
    LIST_ACTIONS = LIST_ACTIONS.concat({
      key: 'delete',
      icon: DeleteCircleRedIcon,
      fill: '#DB3D49',
      label: 'Delete',
      color: '#DB3D49',
      onClick: () => event('delete')
    });
  }

  return LIST_ACTIONS;
};
