import FiltedBodyCell from '../common/component/FiltedBodyCell';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import { ChipStatus } from '../common/componentUI/commonStyleComponents';
import { teleAppoinmentDispatcher } from '../tele-appointment';
import backroomModuleDispatcher from './action';
import GroupSearch from './component/GroupSearch';
import ModalShowResult from './component/ModalShowResult';
import TableContainer from './component/Table/TableContainer';
import {
  BACKROOM_MODULE_STATUS_COLOR,
  renderColumn,
  renderListAction,
} from './constants';
import { blobUrl, createDownloadableMultiLinks } from '@/helpers';
import customToast from '@/new-components/CustomNotification';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  withStyles,
  Dialog,
} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const TableCellSticky = withStyles({
  root: {
    position: 'sticky',
    background: 'white',
    right: 0,
    width: 100,
  },
})(TableCell);

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 230,
    padding: '8px 16px',
    fontSize: 14,
    boxShadow: '1px 1px 4px -1px #000000',
  },
})(Tooltip);

const BackroomModule = () => {
  const { list: data, paging } = useSelector((state) => state.backroomModule);

  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [sortOption, setSortOption] = useState({});
  const [selectedItem, setSelectedItem] = useState([]);
  const [listModule, setListModule] = useState([]);

  const [startTime, setStartTime] = useState(moment().startOf('month'));
  const [endTime, setEndTime] = useState(moment().endOf('date'));
  const [backroomStatuses, setBackroomStatuses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState('upload');

  const [filterOption, setFilterOption] = useState({
    statuses: backroomStatuses,
    startTime,
    endTime,
  });
  const tableColumn = renderColumn();

  const onResetFilterOps = () => {
    setBackroomStatuses([]);
    setStartTime('');
    setEndTime('');
    setFilterOption({
      statuses: [],
      startTime: '',
      endTime: '',
    });

    backroomModuleDispatcher.getData(
      searchKey,
      {
        pageIndex: 0,
        pageSize: paging.pageSize,
      },
      sortOption,
      { statuses: [], startTime: '', endTime: '' }
    );
  };

  const onApplyFilterOps = () => {
    backroomModuleDispatcher.getData(
      searchKey,
      {
        pageIndex: 0,
        pageSize: paging.pageSize,
      },
      sortOption,
      filterOption
    );
  };
  const onFilterOptionClear = (state, setState, defaultValue) => () => {
    setFilterOption({
      ...filterOption,
      [state]: defaultValue,
    });
    setState(defaultValue);
  };

  const onFilterOptionChange = (state, setState) => (e) => {
    setFilterOption({
      ...filterOption,
      [state]: e.target.value,
    });
    setState(e.target.value);
  };

  const onHandleModal = (type) => {
    setShowModal(true);
    setTypeModal(type);
  };

  const onSubmit = (data, key) => {
    if (!selectedItem) return null;
    const { paxScreeningId, hasIRReport } = selectedItem;

    let formData = new FormData();
    data.forEach((item) => {
      formData.append(
        'fileResults',
        item.resultFilePath,
        `${item.moduleId}.pdf`
      );
    });

    if (key === 'upload') {
      backroomModuleDispatcher.uploadBackroom(paxScreeningId, formData, () =>
        setShowModal(false)
      );
    } else {
      let holdLinksDownload = [];
      let totalModulesDownload = listModule.filter(
        (it) => it.resultFilePath
      ).length;
      if (hasIRReport)
        teleAppoinmentDispatcher.downloadIRReport(paxScreeningId);
      else if (!hasIRReport && totalModulesDownload !== 0) {
        listModule.forEach((item) => {
          if (item.resultFilePath) {
            backroomModuleDispatcher.getLinkDownloadBackroom(
              paxScreeningId,
              item.moduleId,
              (link) => {
                holdLinksDownload.push(blobUrl(link));
                if (holdLinksDownload.length === totalModulesDownload) {
                  createDownloadableMultiLinks(
                    holdLinksDownload,
                    'Modules Result',
                    'pdf'
                  );
                  customToast('success', 'Download modules successs');
                }
              }
            );
          }
        });
      }

      setShowModal(false);
    }
  };

  const renderDateTime = (row) => {
    let dateFormat, timeFormat;
    const { datetime } = row;

    if (!datetime) return null;

    dateFormat = moment(datetime).format('DD-MM-YYYY');
    timeFormat = moment(datetime).format('LT');
    return `${dateFormat}, ${timeFormat}`;
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows,
      }}
      {...props}
    />
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(({ label, stateValue }) =>
          stateValue ? (
            <TableCellFiltedWrapper
              key={stateValue}
              label={label}
              stateValue={stateValue}
              style={{ width: 160 }}
            >
              {label}
            </TableCellFiltedWrapper>
          ) : (
            <TableCellSticky />
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (row) => {
    const disableDownload = row.hasIRReport || row.hasResultFilePath;
    return (
      <>
        <FiltedBodyCell hidden={hiddenRows.fullname}>
          {row.fullname}
        </FiltedBodyCell>
        <FiltedBodyCell hidden={hiddenRows.identityNumber}>
          {row.identityNumber || ''}
        </FiltedBodyCell>
        <FiltedBodyCell hidden={hiddenRows.datetime}>
          {renderDateTime(row)}
        </FiltedBodyCell>
        <FiltedBodyCell hidden={hiddenRows.backroomStatus}>
          <ChipStatus color={BACKROOM_MODULE_STATUS_COLOR[row.backroomStatus]}>
            {row.backroomStatus}
          </ChipStatus>
        </FiltedBodyCell>

        <TableCellSticky align="right" style={{ padding: 2 }}>
          {renderListAction(
            onHandleModal,
            row.hasRadiologiesProcedure,
            disableDownload
          ).map((action) => {
            const Icon = action.icon;
            return (
              <CustomTooltip title={action.label}>
                <IconButton
                  key={action.key}
                  disabled={action.disabled}
                  onClick={() => {
                    action.onClick();
                    setSelectedItem(row);
                    backroomModuleDispatcher.getBackroomDetails(
                      row.paxScreeningId,
                      (data) => setListModule(data)
                    );
                  }}
                >
                  <Icon
                    style={{ color: action.disabled ? '#c7cdd4' : '#8f9ba9' }}
                  />
                </IconButton>
              </CustomTooltip>
            );
          })}
        </TableCellSticky>
      </>
    );
  };

  return (
    <>
      <TableContainer
        fullWidthSearch
        title="Backroom Module"
        searchPlaceholder="User Name, NRIC"
        header={TableHeader}
        data={data}
        paging={paging}
        ignoreLoadData={true}
        query={(...params) => {
          backroomModuleDispatcher.getData(...params);
        }}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        sortOption={sortOption}
        renderRow={(row, i) => renderTableBody(row)}
        ToolbarComponent={
          <GroupSearch
            {...{
              onFilterOptionClear,
              onFilterOptionChange,
              filterOption,
              setFilterOption,
              onResetFilterOps,
              onApplyFilterOps,
              backroomStatuses,
              setBackroomStatuses,
              startTime,
              setStartTime,
              endTime,
              setEndTime,
            }}
          />
        }
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <ModalShowResult
          selectedItem={selectedItem}
          onSubmit={onSubmit}
          listModule={listModule}
          typeModal={typeModal}
          onClose={() => setShowModal(false)}
        />
      </Dialog>
    </>
  );
};

export default BackroomModule;
