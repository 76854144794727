import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  makeStyles,
  TableBody,
  Switch
} from '@material-ui/core';
import { map, reduce } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import TabsUI from '@/module/common/componentUI/TabsUI';

import { timeSlotColumns } from './renderColumn';
import { formatTimeShowUILocal } from '@/module/setup/utils';
import { dayOfWeeks } from '@/module/setup/utils';

const useStyles = makeStyles({
  tableTimeContainer: {
    minHeight: 500,
    maxHeight: 'calc(100vh - 400px)',
    overflowY: 'auto'
  },
  loading: {
    width: '100%',
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  infoSecondary: {
    fontSize: '14px',
    fontWeight: 600,
    margin: '16px 0'
  }
});

const TableTimeEditSlot = ({ data = [], loading = false }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const classes = useStyles();
  const currentData = data[currentTab] || {};

  const countDataBase = reduce(
    currentData.timeSlots,
    (sum, current) => sum + Number(current.quotaBase),
    0
  );

  const countDataUsed = reduce(
    currentData.timeSlots,
    (sum, current) => sum + Number(current.quotaUsed),
    0
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {timeSlotColumns.map(({ label }) => (
          <TableCell key={label}>{label}</TableCell>
        ))}
        {/* <TableCell key="quotaBase">Quota used ({countDataUsed})</TableCell> */}
        <TableCell key="quotaUse">Quota base ({countDataBase})</TableCell>
        <TableCell key="status">Status</TableCell>
      </TableRow>
    </TableHead>
  );
  return (
    <div style={{ marginTop: 16 }}>
      <TabsUI
        listTab={dayOfWeeks}
        value={currentTab}
        onChange={(e, val) => setCurrentTab(val)}
        renderLabel="label"
        renderKey="label"
        isCapitalize
      />
      <div className={classes.tableTimeContainer}>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <p className={classes.infoSecondary}>
              List of Time Slot ({currentData.timeSlots?.length || 0})
            </p>
            <TableContainer>
              <Table>
                <TableHeader />
                <TableBody>
                  {map(currentData.timeSlots, row => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.startTime && formatTimeShowUILocal(row.startTime)}
                      </TableCell>
                      <TableCell>
                        {row.endTime && formatTimeShowUILocal(row.endTime)}
                      </TableCell>
                      {/* <TableCell align="left">{row.interval}</TableCell> */}
                      {/* <TableCell>{row.quotaUsed}</TableCell> */}
                      <TableCell>{row.quotaBase}</TableCell>
                      <TableCell>
                        <Switch
                          checked={!!row.isActive}
                          color="primary"
                          size="medium"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </div>
  );
};

TableTimeEditSlot.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

export default TableTimeEditSlot;
