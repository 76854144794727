import React, { useState, useEffect, useMemo } from 'react';
import { Checkbox, Grid, FormControlLabel, Card } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import { cloneDeep, findIndex, isEmpty } from 'lodash';
import { StyledAccordion } from '@/new-components/CustomCollapseCard/styled';
import { formatTotalPrice } from '@/module/checkin-patient-information/util';
import CustomTooltip from '@/components/CustomTooltip';
import { UncheckCircleIcon } from '@/assets/svg';
import Box from '@material-ui/core/Box';

import {
  CheckboxActive,
  CheckboxDefault,
  CheckboxDisabledActive
} from '@/assets/svg';

const CustomCard = withStyles(theme => ({
  root: {
    marginTop: 8,
    marginBottom: 8,
    padding: '0 16px',
    boxShadow: 'none',
    borderRadius: 12,
    backgroundColor: props => (props.color ? props.color : '#F6F6F6'),
    width: props => (props.width ? props.width : 'unset'),
    border: `1px solid #e1e1e1`,

    height: 48,
    pointerEvents: 'none',

    '&.checked': {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    },
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))(Card);

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 16,
    color: '#333333',
    fontWeight: 600
  }
})(InputLabel);

const CustomGrid = withStyles({
  root: {
    borderBottom: '1px solid #e1e1e1',
    paddingBottom: 16,
    marginBottom: 16,

    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
      marginBottom: 0
    }
  }
})(Grid);

const useStyle = makeStyles({
  textPrice: {
    fontWeight: 600
  }
});

const itemStyle = makeStyles({
  iconUncheck: {
    margin: 1,
    width: 16,
    height: 16,
    color: '#F1F1F1',
    borderRadius: '50%'
  },
  iconChecked: {
    width: 20,
    height: 20,
    color: '#0B8E5E'
  },
  formControl: {
    pointerEvents: 'none'
  }
});

const ViewPackageItem = ({
  ignoredTests = [],
  ignoredProfiles,
  packageDetail,
  formValue,

  setFormValue,
  projectId,
  restrictedTestIds
}) => {
  const classes = useStyle();
  const [expanded, setExpanded] = useState('panel');

  const [radiologies, setRadiologies] = useState([]);
  const [procedures, setProcedures] = useState([]);

  const [unMandatoryProcedures, setUnMandatoryProcedures] = useState([]);
  const [unMandatoryRadiologies, setUnMandatoryRadiologies] = useState([]);

  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [selectedRadiologies, setSelectedRadiologies] = useState([]);

  const availableProcedures = useMemo(
    () => procedures.filter(item => !restrictedTestIds.includes(item.id)),
    [procedures, restrictedTestIds]
  );
  const availableRadiologies = useMemo(
    () => radiologies.filter(item => !restrictedTestIds.includes(item.id)),
    [radiologies, restrictedTestIds]
  );

  useEffect(() => {
    if (isEmpty(packageDetail)) return;
    const {
      lstTests,
      totalMandatoryProcedure,
      totalMandatoryRadiology
    } = packageDetail;
    let selectedProcedures = [];
    let selectedRadiologies = [];
    let procedures = [];
    let radiologies = [];

    lstTests.forEach(item => {
      if (item.category === 'Procedures')
        procedures = item.items?.sort((a, b) => b.isRequired - a.isRequired);
      if (item.category === 'Radiologies')
        radiologies = item.items?.sort((a, b) => b.isRequired - a.isRequired);
    });

    // Filter test restricted by location
    // if (!isEmpty(restrictedTestIds)) {
    //   procedures = procedures?.filter(
    //     it => !restrictedTestIds?.includes(it.id)
    //   );
    //   radiologies = radiologies?.filter(
    //     it => !restrictedTestIds?.includes(it.id)
    //   );
    // }

    const unMandatoryProcedures = [...procedures].filter(it => !it.isRequired);
    const unMandatoryRadiologies = [...radiologies].filter(
      it => !it.isRequired
    );

    // Procedures
    if (unMandatoryProcedures.length === totalMandatoryProcedure) {
      selectedProcedures = unMandatoryProcedures;
    } else {
      if (isEmpty(ignoredTests)) {
        selectedProcedures = unMandatoryProcedures?.filter(value => {
          return ignoredTests?.some(test => {
            return value.id === test.testId;
          });
        });
      } else {
        selectedProcedures = unMandatoryProcedures?.filter(value => {
          return ignoredTests?.every(test => {
            return value.id !== test.testId;
          });
        });
      }
    }

    const newUnMandatoryProcedures = cloneDeep(procedures)
      ?.map(it => {
        if (findIndex(selectedProcedures, test => test.id === it.id) !== -1) {
          return { ...it, isChecked: true };
        }

        return it;
      })
      ?.sort((a, b) => b.isRequired - a.isRequired);

    // Radiologies
    if (unMandatoryRadiologies.length === totalMandatoryRadiology) {
      selectedRadiologies = unMandatoryRadiologies;
    } else {
      if (isEmpty(ignoredTests)) {
        selectedRadiologies = unMandatoryRadiologies?.filter(value => {
          return ignoredTests?.some(test => {
            return value.id === test.testId;
          });
        });
      } else {
        selectedRadiologies = unMandatoryRadiologies?.filter(value => {
          return ignoredTests?.every(test => {
            return value.id !== test.testId;
          });
        });
      }
    }

    const newUnMandatoryRadiologies = cloneDeep(radiologies)
      ?.map(it => {
        if (findIndex(selectedRadiologies, test => test.id === it.id) !== -1)
          return { ...it, isChecked: true };
        return it;
      })
      ?.sort((a, b) => b.isRequired - a.isRequired);

    setUnMandatoryProcedures(unMandatoryProcedures);
    setUnMandatoryRadiologies(unMandatoryRadiologies);

    setProcedures(newUnMandatoryProcedures);
    setRadiologies(newUnMandatoryRadiologies);

    setSelectedProcedures(selectedProcedures);
    setSelectedRadiologies(selectedRadiologies);
  }, [packageDetail, ignoredProfiles, ignoredTests]);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const updateCheckedData = (dataUpdated = [], testExists = []) => {
    const newDataLocal = [...dataUpdated];

    testExists.forEach(test => {
      const indexProject = findIndex(dataUpdated, it => it.id === test.id);

      if (indexProject < 0) return;
      const updatedProject = newDataLocal[indexProject];

      const indexTestChange = findIndex(
        newDataLocal,
        it => it.id === updatedProject.id
      );
      if (indexTestChange < 0) return;
      newDataLocal[indexTestChange] = { ...test };
    });

    return newDataLocal;
  };

  const onChangeValueCheckbox = (value, row, data, setData, setSelected) => {
    const newData = { ...row, isChecked: value };
    const dataLocalUpdated = updateCheckedData(data, [newData]);
    const newListSelected = [...dataLocalUpdated].filter(
      item => item.isChecked
    );

    setData(dataLocalUpdated);
    setSelected(newListSelected);
  };

  useEffect(() => {
    const ignoredProcedures = procedures.filter(
      it => !it.isChecked && !it.isRequired
    );
    const ignoredRadiologies = radiologies.filter(
      it => !it.isChecked && !it.isRequired
    );
    const newIgnoredProcedures = formatIgnoredTests(ignoredProcedures);
    const newIgnoredRadiologies = formatIgnoredTests(ignoredRadiologies);

    const newIgnoredTests = mergeArray(
      newIgnoredRadiologies,
      newIgnoredProcedures,
      'testId'
    );

    setFormValue &&
      setFormValue({ ...formValue, ignoredTests: newIgnoredTests });
  }, [procedures, radiologies]);

  const mergeArray = (a, b, key) => {
    const reduced = a.filter(
      aitem => !b.find(bitem => aitem[key] === bitem[key])
    );
    return reduced.concat(b);
  };
  const formatIgnoredTests = data => {
    const newData = data.map(item => ({
      testId: item.id,
      packageId: packageDetail.id,
      projectId
    }));
    return newData;
  };

  return (
    <Box px={2}>
      <CustomInputLabel>Package</CustomInputLabel>

      <StyledAccordion
        expanded={expanded === 'panel'}
        onChange={handleChange('panel')}
        className={clsx(expanded === 'panel' ? 'expanded' : '')}
        style={{ border: '1px solid #e1e1e1' }}
      >
        <AccordionSummary
          aria-controls="panel-content"
          id="panel-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Box display="flex" alignItems="center" gridGap={8}>
            <Typography>{packageDetail?.name}</Typography>
          </Box>

          <Typography className={classes.textPrice}>
            {formatTotalPrice(packageDetail?.price, '$')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {!isEmpty(procedures) && !isEmpty(unMandatoryProcedures) && (
              <CustomGrid container>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {`Procedures (Choose only ${packageDetail.totalMandatoryProcedure} tests)`}
                  </Typography>
                </Grid>
                {availableProcedures.map(item => (
                  <Grid item xs={6} key={item.id}>
                    {item.isRequired ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={
                              item.isRequired ? classes.iconDisabled : null
                            }
                            icon={<CheckboxDefault />}
                            checkedIcon={<CheckboxDisabledActive />}
                            checked={item.isRequired}
                            disabled={item.isRequired}
                            color="primary"
                            name={item.name}
                          />
                        }
                        label={
                          <CustomTooltip content={item.name} maxLength={30} />
                        }
                      />
                    ) : (
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<CheckboxDefault />}
                            checkedIcon={<CheckboxActive />}
                            checked={item.isChecked}
                            disabled={
                              !item.isChecked &&
                              selectedProcedures.length ===
                                packageDetail.totalMandatoryProcedure
                            }
                            color="primary"
                            name={item.name}
                            onChange={e =>
                              onChangeValueCheckbox(
                                e.target.checked,
                                item,
                                procedures,
                                setProcedures,
                                setSelectedProcedures
                              )
                            }
                          />
                        }
                        label={
                          <CustomTooltip content={item.name} maxLength={30} />
                        }
                      />
                    )}
                  </Grid>
                ))}
              </CustomGrid>
            )}

            {!isEmpty(radiologies) && !isEmpty(unMandatoryRadiologies) && (
              <CustomGrid container>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {`Radiologies (Choose only ${packageDetail.totalMandatoryRadiology} tests)`}
                  </Typography>
                </Grid>
                {availableRadiologies.map(item => (
                  <Grid item xs={6} key={item.id}>
                    {item.isRequired ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={
                              item.isRequired ? classes.iconDisabled : null
                            }
                            icon={<CheckboxDefault />}
                            checkedIcon={<CheckboxDisabledActive />}
                            checked={item.isRequired}
                            disabled={item.isRequired}
                            color="primary"
                            name={item.name}
                          />
                        }
                        label={
                          <CustomTooltip content={item.name} maxLength={30} />
                        }
                      />
                    ) : (
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<CheckboxDefault />}
                            checkedIcon={<CheckboxActive />}
                            checked={item.isChecked}
                            disabled={
                              !item.isChecked &&
                              selectedRadiologies.length ===
                                packageDetail.totalMandatoryRadiology
                            }
                            color="primary"
                            name={item.name}
                            onChange={e =>
                              onChangeValueCheckbox(
                                e.target.checked,
                                item,
                                radiologies,
                                setRadiologies,
                                setSelectedRadiologies
                              )
                            }
                          />
                        }
                        label={
                          <CustomTooltip content={item.name} maxLength={30} />
                        }
                      />
                    )}
                  </Grid>
                ))}
              </CustomGrid>
            )}
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default ViewPackageItem;
