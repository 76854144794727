import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { MenuItem, Popover } from '@material-ui/core';
import { QuestionAnswer, People } from '@material-ui/icons';

const SelectCohost = ({ listMember, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const getCohostList = useMemo(() => {
    return !!listMember.length
      ? listMember.filter(member => member.UserType !== 'PublicUser')
      : [];
  }, [listMember]);

  const onSelectMember = member => {
    onSelect(member);
    setAnchorEl(null);
  };

  return (
    <SelectStyled>
      <div className="show-menu" onClick={e => setAnchorEl(e.currentTarget)}>
        <People />
        <p>Cohosts</p>
      </div>
      <WrapperPopover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {getCohostList.map(member => (
          <MenuItem key={member.Id} onClick={() => onSelectMember(member)}>
            <div className="group-title">
              <span className="title">
                {member.FirstName + ' ' + member.LastName}
              </span>
              <QuestionAnswer />
            </div>
          </MenuItem>
        ))}
      </WrapperPopover>
    </SelectStyled>
  );
};

export default SelectCohost;

const SelectStyled = styled.div`
  color: #192637;
  .show-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      margin-left: 12px;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }
    .MuiSvgIcon-root {
      fill: #ad5e99;
    }
  }
`;
const WrapperPopover = styled(Popover)`
  .MuiPopover-paper {
    margin-top: 10px;
  }
  .group-title {
    min-width: 128px;
    padding: 6px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 14px;
    }
    .MuiSvgIcon-root {
      fill: #cacfd3;
      width: 13px;
      height: 13px;
    }
  }
`;
