/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Box, Grid } from '@material-ui/core';

import CustomSurveyItem from './CustomSurveyItem';
import { FIELD_TYPES_ENUM } from '@/module/checkin-patient-information/components/TabContainers/ModalSelectCategory/constants';

const CustomSurvey = ({ formValue, setFormValue, dataDefault, dataExist }) => {
  const [data, setData] = useState([]);
  const [localData, setLocalData] = useState([]);
  const [dataShowTable, setDataShowTable] = useState({});
  const [tempData, setTempData] = useState(dataExist);

  const [newText, setNewText] = useState({});
  const [newDropdown, setNewDropdown] = useState({});
  const [newMutilSelect, setNewMutilSelect] = useState({});
  const [newRating, setNewRating] = useState({});

  // Checked Table data if data exist
  const updateCheckedData = (dataUpdated = [], testExists = []) => {
    const newDataLocal = [...dataUpdated];

    newDataLocal.forEach(test => {
      testExists.forEach(item => {
        const existItemIdx = test?.items?.findIndex(
          i => i.fieldId === item.fieldId
        );

        if (existItemIdx >= 0) {
          test.items[existItemIdx] = item;
        } else {
          test.items.push(item);
        }
      });
    });

    return newDataLocal;
  };

  const onChangeDataSelected = (dataItem, key) => {
    const newData = { ...dataItem };
    switch (key) {
      case FIELD_TYPES_ENUM.TEXT:
        setNewText(newData);
        break;

      case FIELD_TYPES_ENUM.DROPDOWNLIST:
        setNewDropdown(newData);
        break;

      case FIELD_TYPES_ENUM.MULTISELECT:
        setNewMutilSelect(newData);
        break;

      case FIELD_TYPES_ENUM.RATING:
        setNewRating(newData);
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    if (data.length !== 0) {
      let newDataItem = [
        newText,
        newDropdown,
        newMutilSelect,
        newRating
      ].filter(item => !isEmpty(item));

      newDataItem = newDataItem?.map(item =>
        !isEmpty(item)
          ? {
              customFieldType: item?.customFieldType,
              isOther: item?.isOther ? true : false,
              otherAnswer: item?.otherAnswer || '',
              fieldId: item?.id,
              value: item?.value
            }
          : null
      );

      const dataUpdated = updateCheckedData(data, newDataItem);
      setData(dataUpdated);
      setTempData(newDataItem);
    }
  }, [newText, newDropdown, newMutilSelect, newRating]);

  const mergeArrayData = array => {
    let seen = new Map();
    array = array.filter(function(entry) {
      var previous;

      if (seen.hasOwnProperty(entry.id)) {
        previous = seen[entry.id];
        previous.data.push(entry.data);

        return false;
      }

      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }

      seen[entry.id] = entry;

      return true;
    });
    return array;
  };

  useEffect(() => {
    const newData = dataDefault?.items?.map(it =>
      it.customFieldType !== FIELD_TYPES_ENUM.TEXT
        ? { ...it, value: JSON.parse(it.value) }
        : it
    );

    setData([{ ...dataDefault, items: [] }]);
    setLocalData([{ ...dataDefault, items: newData }]);
  }, [dataDefault]);

  useEffect(() => {
    if (!isEmpty(dataExist)) {
      setData([{ ...dataDefault, items: dataExist }]);
    }
  }, [dataExist]);

  useEffect(() => {
    let allTest = [];
    data.forEach(prj => {
      if (isEmpty(prj.items)) {
        allTest = dataExist;
      } else {
        allTest = prj.items;
      }
    });
    setFormValue({ ...formValue, lstScreeningFields: allTest });
  }, [data]);

  return (
    <Box width="100%" height="calc(100vh - 282px)" p={2.5} overflow="auto">
      <Grid container spacing={2}>
        {localData &&
          localData?.map(testItem => (
            <CustomSurveyItem
              id={testItem.id}
              showTable={dataShowTable[testItem.id]}
              key={testItem.id}
              title={testItem.name}
              data={testItem?.items}
              onChangeDataSelected={onChangeDataSelected}
              dataExist={dataExist}
            />
          ))}
      </Grid>
    </Box>
  );
};

export default CustomSurvey;
