import React, { useState } from 'react';
import CustomSelect from '../../../components/CustomSelect';
import { ArtTrackOutlined } from '@material-ui/icons';
import MutipleSelection from '../../../components/CustomSelect/MutipleSelection';
import { isEmpty } from 'lodash';
import { STATUS_CLINIC } from '../constants';

const SearchClinic = props => {
  const [statusOpen, setStatusOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);

  return (
    <CustomSelect
      onClick={() => setStatusOpen(!statusOpen)}
      clearable={!isEmpty(setStatusFilter)}
      onClear={() => {
        setStatusFilter([]);
        props.onSearch({ clinicStatus: [] });
      }}
      prefixIcon={
        <ArtTrackOutlined
          style={{
            fontSize: 20,
            color: '#E9E9E9',
            marginRight: 8
          }}
        />
      }
      renderValue={<>{statusFilter.join(', ') || 'Select Status'}</>}
    >
      <MutipleSelection
        value={statusFilter}
        data={STATUS_CLINIC}
        open={statusOpen}
        onChange={e => {
          props.onSearch({ clinicStatus: e.target.value });
          setStatusFilter(e.target.value);
        }}
      />
    </CustomSelect>
  );
};

export default SearchClinic;
