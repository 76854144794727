import React, { useEffect, useState } from 'react';
import { Edit, CheckCircle } from '@material-ui/icons';
import { Camera } from '@/assets/svg';
import { Box, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import ModuleImage from '@/assets/image/module-default.png';

const useStyle = () =>
  makeStyles({
    wrapperUpload: {
      backgroundColor: '#F6F7FA'
    },

    editIcon: {
      position: 'absolute',
      bottom: 0,
      right: -10,
      textAlign: 'center',

      width: 24,
      height: 24,
      color: '#fff',
      backgroundColor: '#AD5E99',
      borderRadius: '50%'
    }
  });

const FormUpload = ({ fileData, onChange, label, dataModule, listFiles }) => {
  const classes = useStyle(fileData)();
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    const newLocalData = dataModule.map(it1 => ({
      ...it1,
      ...listFiles.find(it2 => it2.moduleId === it1.moduleId)
    }));

    setLocalData(newLocalData);
  }, [dataModule, listFiles]);

  const renderForm = data => {
    if (isEmpty(data)) return null;
    const { moduleId, moduleName, resultFilePath } = data;
    let justifyContent = 'flex-start';

    let component = (
      <>
        <Camera color="primary" style={{ marginRight: 5 }} />
        {label}
      </>
    );

    if (resultFilePath) {
      justifyContent = 'space-between';
      component = (
        <>
          <Box
            position="relative"
            display="flex"
            justifyContent="space-between"
          >
            <div className={classes.editIcon}>
              <Edit style={{ fontSize: 16, verticalAlign: 'middle' }} />
            </div>
            <img
              id={moduleId}
              src={ModuleImage}
              width="80"
              height="80"
              alt={moduleName}
            />
          </Box>
          <CheckCircle style={{ color: '#62CAA4' }} />
        </>
      );
    }

    return (
      <Box
        display="flex"
        color="primary.main"
        alignItems="center"
        height={93}
        fontWeight="600"
        justifyContent={justifyContent}
      >
        {component}
      </Box>
    );
  };

  const onInputChange = (event, module) => {
    const file = event.target.files[0];
    file &&
      onChange({
        ...module,
        resultFilePath: file
      });
    event.target.value = '';
  };

  return (
    !isEmpty(localData) &&
    localData.map(item => (
      <Box my={3} key={item.moduleId}>
        <Box pb={1} px={3} fontSize={12}>
          {item.moduleName}
        </Box>
        <label htmlFor={item.moduleId}>
          <Box
            px={3}
            className={classes.wrapperUpload}
            style={{ cursor: 'pointer' }}
          >
            <input
              id={item.moduleId}
              accept=".pdf"
              type="file"
              hidden
              onChange={e => onInputChange(e, item)}
            />

            {renderForm(item)}
          </Box>
        </label>
      </Box>
    ))
  );
};

export default FormUpload;
