import RtmInstance from '../../../agora/rtm-instance';
import { findIndex, set, map, indexOf } from 'lodash';
import moment from 'moment';
import { FORMAT_DATE } from '../constants';

export const joinChannel = async (username, channelName) => {
  const rtm = RtmInstance.instance;
  try {
    if (rtm._logined) {
      if (
        rtm.channels[channelName] ||
        (rtm.channels[channelName] && rtm.channels[channelName].joined)
      )
        return;
      await rtm.joinChannel(channelName);
      rtm.channels[channelName].joined = true;
      return;
    }
    await rtm.login(username);
    rtm._logined = true;
    if (
      rtm.channels[channelName] ||
      (rtm.channels[channelName] && rtm.channels[channelName].joined)
    )
      return;
    await rtm.joinChannel(channelName);
    rtm.channels[channelName].joined = true;
  } catch (err) {
    console.log(err);
  }
};

export const leaveChannel = async channelName => {
  const rtm = RtmInstance.instance;
  if (
    !rtm.channels[channelName] ||
    (rtm.channels[channelName] && !rtm.channels[channelName].joined)
  ) {
    return;
  }
  try {
    await rtm.leaveChannel(channelName);
    if (rtm.channels[channelName]) {
      rtm.channels[channelName].joined = false;
      rtm.channels[channelName] = null;
    }
  } catch (err) {
    console.log(err);
  }
};

export const leaveAllChannel = async () => {
  const rtm = RtmInstance.instance;
  const channels = Object.getOwnPropertyNames(rtm.channels);
  channels.reduce(
    (p, channel) => p.then(_ => leaveChannel(channel)),
    Promise.resolve()
  );
  if (rtm._logined) {
    await rtm.logout();
    rtm._logined = false;
  }
};
export const joinMultiChannel = (username, channels) => {
  channels.reduce(
    (p, channel) => p.then(_ => joinChannel(username, channel)),
    Promise.resolve()
  );
};

export const formatUsername = (name, id) => {
  let username = `${name},${id}`;
  if (username.length > 63) username = username.slice(0, 63);
  return username;
};

export const getNameBasedOnChannel = channelName => {
  if (channelName.includes('publicRoom')) return 'Public Chat';
  else {
    if (channelName.includes('cohostRoom')) return 'Cohost Chat';
    else return channelName.split(',')[0];
  }
};

export const setAttributeBasedOnID = (array, id, listAction) => {
  let newArr = [...array];
  const index = findIndex(array, item => item.id === id);
  listAction.forEach(element => {
    set(newArr[index], element.attr, element.value);
  });
  return newArr;
};

export const isChannel = id => {
  return id.includes('publicRoom') || id.includes('cohostRoom');
};

export const formatChannels = (channelId, roleName) => {
  let channels = [`${channelId},publicRoom`];
  if (roleName !== 'Patient') channels.push(`${channelId},cohostRoom`);
  return channels;
};

export const foundIndexOfDate = (listDates, date) => {
  const formattedList = map(listDates, date =>
    moment(date).format(FORMAT_DATE)
  );
  return indexOf(formattedList, moment(date).format(FORMAT_DATE));
};

export const mergeListDateTime = (listDates, time) => {
  const [hour, minute] = moment(time)
    .format('HH:mm')
    .split(':');
  const result = map(listDates, date => {
    const utcDate = moment(date)
      .utc()
      .format();
    return moment(utcDate).set({ hour, minute });
  });

  return result;
};
