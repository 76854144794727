import React, { useEffect } from 'react';
import { Divider, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { MOTHER_STATEMENT, MOTHER_STATUS } from '../contansts';
import Expression from './Expression';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { LAB_PROVIDERS } from '@/new-components/CustomModal/ModalAssignLabNumber';

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    }
  }
})(TextField);

const CustomMultilineTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      minHeight: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0
      }
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const CustomTitleLabel = withStyles(theme => ({
  root: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 4,
    color: '#666666'
  }
}))(Typography);

const MotherForm = ({ errors, setFormValue, formValue }) => {
  const onChange = key => e => {
    if (!setFormValue) return;
    setFormValue({
      ...formValue,
      [key]: e.target.value
    });
  };

  return (
    <Box px={2.25} py={2}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <CustomTitleLabel>Statement code</CustomTitleLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter code"
            error={!!errors.status}
            helperText={errors.status}
            value={formValue.status}
            onChange={onChange('status')}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTitleLabel>Category</CustomTitleLabel>

          <CustomSelectContainer
            label="label"
            isFullWidth
            filterValue={formValue.type}
            data={MOTHER_STATUS}
            renderValue={
              <>
                {MOTHER_STATUS.find(it => it.key === formValue.type)?.label ||
                  'Select category'}
              </>
            }
            height="48px"
            isMutiple={false}
            clearable={false}
            onChange={onChange('type')}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomTitleLabel>Lab provider</CustomTitleLabel>

          <CustomSelectContainer
            data={Object.values(LAB_PROVIDERS)}
            inputLabel="Provider"
            height="48px"
            isFullWidth
            isMutiple={false}
            clearable={false}
            value={formValue.labProvider || ''}
            renderValue={formValue.labProvider || 'Select provider'}
            filterValue={formValue.labProvider}
            onChange={onChange('labProvider')}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        {MOTHER_STATEMENT.map(({ key, value }) => (
          <Grid item xs={12} md={6} key={value}>
            <CustomTitleLabel>{key}</CustomTitleLabel>
            <CustomMultilineTextField
              rows={4}
              multiline
              fullWidth
              variant="outlined"
              placeholder="Enter details"
              error={!!errors[value]}
              helperText={errors[value]}
              value={formValue[value]}
              onChange={onChange(value)}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12}>
          <CustomTitleLabel>Rule</CustomTitleLabel>
          <Expression
            formValue={formValue}
            setFormValue={setFormValue}
            data={formValue.expressions}
            error={errors.expressions}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MotherForm;
