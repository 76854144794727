import { COLUMN_BUNDLE_PRICING, TYPE_MODAL } from '../../constants';
import ModalAddOrEditBundlePricing from '../ModalAddOrEditBundlePricing';
import { CloseCircle, EditIcon } from '@/assets/svg';
import { formatTotalPrice } from '@/helpers';
import CustomMenu from '@/new-components/CustomMenu';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import TableWithFooter from '@/new-components/CustomTable/TableWithFooter';
import CustomTooltip from '@/new-components/CustomTooltip';
import {
  Box,
  IconButton,
  TableCell,
  Typography,
  withStyles,
} from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { cloneDeep, lowerCase } from 'lodash';
import pluralize from 'pluralize';
import React, { useState } from 'react';

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 24,
    color: '#333333',
  },
})(Typography);

const BundlePricingStep = ({ formValue, setFormValue }) => {
  const [showBundleModal, setShowBundleModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [typeModal, setTypeModal] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const changeTableValue = (action, value, position) => {
    let newValue = cloneDeep(value);
    let productPrices = [...formValue.productPrices];

    newValue = {
      ...value,
      note: pluralize(lowerCase(formValue?.unit), value.quantity, true),
    };

    switch (action) {
      case TYPE_MODAL.Create:
        productPrices.push(newValue);
        break;
      case TYPE_MODAL.Edit:
        productPrices[position] = newValue;
        break;
      case TYPE_MODAL.Delete:
        productPrices.splice(position, 1);
        break;
      default:
        break;
    }
    setFormValue({ ...formValue, productPrices });
    setShowBundleModal(false);
  };

  const renderRow = (row, i) => (
    <>
      <TableCell>
        <CustomTooltip
          content={pluralize(lowerCase(formValue?.unit), row.quantity, true)}
        />
      </TableCell>
      <TableCell>{formatTotalPrice(row.price)}</TableCell>

      <TableCell>
        <IconButton
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setSelectedItem({ item: row, position: i });
          }}
        >
          <MoreVertRounded />
        </IconButton>
      </TableCell>
    </>
  );

  const renderListActions = (selectedItem) => {
    if (!selectedItem) return [];
    return [
      {
        key: 'edit-bundle',
        icon: EditIcon,
        label: 'Edit bundle',
        onClick: () => {
          setAnchorEl(null);
          setShowBundleModal(true);
          setTypeModal(TYPE_MODAL.Edit);
        },
      },
      {
        key: 'delete-bundle',
        icon: CloseCircle,
        styles: { width: 18, height: 18 },
        color: '#DB3D49',
        label: 'Remove',
        onClick: () => {
          setAnchorEl(null);
          changeTableValue(
            TYPE_MODAL.Delete,
            selectedItem.item,
            selectedItem.position
          );
        },
      },
    ];
  };

  return (
    <>
      <CustomMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <CustomMenuActions listActions={renderListActions(selectedItem)} />
      </CustomMenu>

      <Box px={2.25} py={2.5}>
        <CustomTitleLabel>Pricing</CustomTitleLabel>
        <TableWithFooter
          data={formValue.productPrices || []}
          labelButton="Add bundle"
          columns={COLUMN_BUNDLE_PRICING}
          onClick={(e) => {
            e && e.stopPropagation();
            setSelectedItem({});
            setShowBundleModal(true);
            setTypeModal(TYPE_MODAL.Create);
          }}
          renderRow={renderRow}
        />
      </Box>

      {showBundleModal && (
        <ModalAddOrEditBundlePricing
          productPrices={formValue?.productPrices}
          typeModal={typeModal}
          title={`${typeModal === TYPE_MODAL.Create ? 'Add' : 'Edit'} bundle`}
          open={showBundleModal}
          onClose={(e) => {
            e.stopPropagation();
            setShowBundleModal(false);
            setSelectedItem({});
            setTypeModal(null);
          }}
          onSubmit={changeTableValue}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
};

export default BundlePricingStep;
