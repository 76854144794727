import React from 'react';
import { TableCell, Checkbox } from '@material-ui/core';
import { get } from 'lodash';
import { ROLE_MERGE_TRAINER } from '../constants';

const RoleCheckRow = ({ row, hiddenRows, onChange, isParent }) => {
  const isParentChecked = rolePosition => {
    for (const index in row.child) {
      const rowChild = get(row, `child[${index}].items[${rolePosition}]`);
      if (rowChild && !rowChild.isActive) return { isActive: false };
    }
    return { isActive: true };
  };
  return (
    <React.Fragment>
      {ROLE_MERGE_TRAINER.map((role, index) => {
        let item;
        if (isParent) {
          item = isParentChecked(index);
        } else item = row.items[index];
        return (
          <TableCell key={`${role.roleType}_${row.privilege}`}>
            {!hiddenRows[role.roleType] && (
              <Checkbox
                color="primary"
                checked={get(item, 'isActive')}
                onChange={e => onChange(e, item, index)}
              />
            )}
          </TableCell>
        );
      })}
    </React.Fragment>
  );
};

export default RoleCheckRow;
