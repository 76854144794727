import React, { useEffect, useState } from 'react';
import { urlLabel } from '@/enum/PermissionEnum';
import { useAbortableEffect } from '@/helpers/useHookHelpers';
import validateData from '@/helpers/validationHelpers/validationSchema';
import globalDispatcher from '@/redux/global/actions';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  TextField,
  withStyles
} from '@material-ui/core';
import { capitalize, get, isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import companyDispatcher from '../../action';
import {
  AddProject,
  CustomAutocomplete,
  CustomGridItem,
  CustomSearchInput,
  DetailWrapped,
  InputLabel,
  ProjectRow,
  ProjectsContainer,
  ProjectsHeader,
  SectionTitle
} from './styled';

import { Add, AddIcon, ArrowDown, SuccessIcon } from '@/assets/svg';
import { MoreVertRounded } from '@material-ui/icons';
import moment from 'moment';
import Drawer from '@material-ui/core/Drawer';
import CreateAndEditProject from '@/module/setup/CreateAndEditProject';
import { renderProjectActions } from '../../renderAction';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import EditSequenceStationsModal from './EditSequenceStationsModal';
import customToast from '@/new-components/CustomNotification';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import CustomTooltip from '@/new-components/CustomTooltip';

const CustomTextField = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: 0,
      paddingLeft: 4,
      marginLeft: 0
    },
    '& .Mui-disabled': {
      color: theme.palette.grey.mainText,
      pointerEvents: 'none'
    }
  }
}))(TextField);

const CustomButton = withStyles({
  sizeLarge: {
    fontSize: 16,
    minWidth: 120
  }
})(Button);

const CreateAndEditCompany = () => {
  const [formValue, setFormValue] = useState({});
  const [errors, setErrors] = useState({});
  const [paperStatus, setPaperStatus] = useState({
    isCreate: true
  });
  const [lstSurvey, setLstSurvey] = useState([]);
  const [listProjects, setListProjects] = useState([]);

  const location = useLocation();
  const history = useHistory();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isCreateProject, setIsCreateProject] = useState(true);
  const [isEditCompany, setIsEditCompany] = useState(false);
  const [isDisabledEditSequence, setIsEditSequence] = useState(false);

  const [isOpenEditSequence, setOpenEditSequence] = useState(false);

  const companyID =
    get(location, 'company.id') || get(location, 'search').replace('?id=', '');

  useAbortableEffect(_isMounted => {
    globalDispatcher.getListSurveys((result = []) => {
      _isMounted.current && setLstSurvey(result);
    });
  }, []);

  const onCancel = () => history.push(`/${urlLabel.companyManagement}`);

  /**
   * Handle cache edit component data on reload action
   * Note: this is just temp solution, need to update/refactor redirect solution
   */
  useEffect(() => {
    if (localStorage.getItem('temp_editCompany')) {
      setFormValue(JSON.parse(localStorage.getItem('temp_editCompany')));
    } else {
      onCancel();
    }
  }, [
    Object.keys(!formValue).length === 0 && formValue?.constructor === Object
  ]);

  useEffect(() => {
    // const companyID = get(location, 'search').replace('?id=', '');
    if (isEmpty(companyID)) setPaperStatus({ isCreate: true });
    else {
      setPaperStatus({
        isCreate: false,
        name: get(location, 'company.name')
      });
      setFormValue(location.company);
      companyDispatcher.getProjectByCompanyID(companyID, result => {
        setListProjects(result);
      });
    }

    /** Handle remove `temp_editCompany` data on component release */
    return () => {
      localStorage.removeItem('temp_editCompany');
    };
  }, []);

  const handleChange = key => (e, newValue) => {
    let value = get(e, 'target.value');
    if (key === 'surveyId') value = get(newValue, 'id');
    setFormValue({ ...formValue, [key]: value });
  };

  const renderToast = (isCreate, data, mainType) => {
    return customToast(
      'success',
      <span>
        Company <strong style={{ fontWeight: 600 }}>{data}</strong> has been
        successfully {isCreate ? 'created' : 'updated'}.
      </span>,
      isCreate ? `New ${mainType} created` : `${capitalize(mainType)} updated`
    );
  };

  const onSaveEditCompany = async e => {
    e && e.preventDefault();

    try {
      setIsEditCompany(false);
      await validateData('companySchema', formValue, data => {
        companyDispatcher.editCompany(
          {
            id: data.id,
            data
          },
          () => {
            renderToast(false, data?.name, 'company');
          }
        );
      });
    } catch (err) {
      setErrors({ ...errors, ...err });
    }
  };

  const onExportCRReport = () => {
    companyDispatcher.getExportCRReport(selectedItem.id);
  };

  const onDownloadSubsidyForm = () => {
    companyDispatcher.getDownloadSubsidyForm(
      selectedItem.id,
      selectedItem.name
    );
  };

  const getProjectByCompany = () => {
    if (!companyID)
      return history.push({ pathname: `/${urlLabel.companyManagement}` });
    companyDispatcher.getProjectByCompanyID(companyID, result => {
      setListProjects(result);
    });
  };

  const onDeleteProject = () => {
    companyDispatcher.deleteProject(selectedItem.id, () => {
      getProjectByCompany();
    });
    onClose();
  };

  const onClose = () => {
    setDeleteModal(false);
    setSelectedItem(null);
  };

  const toggleEditSequenceStations = isOpen => {
    setOpenEditSequence(isOpen);
    setAnchorEl(null);
  };
  const handleEitCompany = e => {
    e && e.preventDefault();
    setIsEditCompany(true);
  };

  const onHandleModal = type => {
    switch (type) {
      case 'delete':
        setDeleteModal(true);
        setAnchorEl(null);
        break;
      case 'edit':
        setOpenDrawer(true);
        setIsCreateProject(false);
        setAnchorEl(null);
        break;
      case 'export':
        onExportCRReport();
        setAnchorEl(null);
        break;
      case 'export-subsidy':
        onDownloadSubsidyForm();
        setAnchorEl(null);
        break;
      case 'editSequenceStations':
        toggleEditSequenceStations(true);
        break;
      default:
        break;
    }
  };

  const listActions = renderProjectActions(
    onHandleModal,
    selectedItem?.hasSubsidyClaimForm,
    !isDisabledEditSequence
  );

  return (
    <CustomPaperContainer
      isDialog
      onClick={onCancel}
      btnLabel="Close"
      header={
        <CustomHeader
          isSearch={false}
          title={
            paperStatus.isCreate ? 'Create new company' : 'Company details'
          }
        />
      }
    >
      <Divider light />
      <Box
        height="calc(100vh - 135px)"
        overflowY="auto"
        py={3}
        width="768px"
        margin="0 auto"
      >
        <DetailWrapped>
          <Box display="flex" justifyContent="space-between">
            <SectionTitle>General information</SectionTitle>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleEitCompany}
              disabled={isEditCompany}
            >
              Edit
            </Button>
          </Box>

          <Grid container spacing={2}>
            <Grid item md={8} sm={12}>
              <InputLabel>Company name</InputLabel>
              <CustomSearchInput
                error={errors.name}
                helperText={errors.name}
                placeholder="Enter company name"
                value={formValue?.name || ''}
                fullWidth
                onChange={handleChange('name')}
                variant="outlined"
                allowClear
                disabled={!isEditCompany}
              />
            </Grid>
            <Grid item md={4} sm={12}>
              <InputLabel>Company size</InputLabel>
              <CustomSearchInput
                error={errors.staffQuantity}
                helperText={errors.staffQuantity}
                placeholder="Enter number"
                value={formValue?.staffQuantity || ''}
                fullWidth
                onChange={handleChange('staffQuantity')}
                type="number"
                variant="outlined"
                allowClear
                disabled={!isEditCompany}
              />
            </Grid>

            <Grid item md={8} sm={12}>
              <InputLabel>Lifestyle survey</InputLabel>

              <CustomAutocomplete
                disabled={!isEditCompany}
                getOptionSelected={(option, value) => option.id === value}
                getOptionLabel={option => option.name || ''}
                options={lstSurvey || []}
                value={
                  lstSurvey.find(survey => survey.id === formValue?.surveyId) ||
                  ''
                }
                onChange={handleChange('surveyId')}
                popupIcon={<ArrowDown />}
                renderInput={params => (
                  <CustomTextField
                    variant="outlined"
                    placeholder="Select survey"
                    {...params}
                    error={errors.surveyId}
                    helperText={errors.surveyId}
                  />
                )}
              />
            </Grid>
            {isEditCompany && (
              <Grid item md={8} sm={12}>
                <CustomButton
                  color="primary"
                  variant="contained"
                  onClick={onSaveEditCompany}
                >
                  Save
                </CustomButton>
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider light />
            </Grid>

            <Grid item xs={12}>
              <SectionTitle>Projects</SectionTitle>
              <ProjectsContainer>
                <ProjectsHeader container>
                  <CustomGridItem className="grid-3">Project </CustomGridItem>
                  <CustomGridItem className="grid-2">Code</CustomGridItem>
                  <CustomGridItem className="grid-2">Type</CustomGridItem>
                  <CustomGridItem className="grid-2">
                    Free payment
                  </CustomGridItem>
                  <CustomGridItem className="grid-2">Start Date</CustomGridItem>
                  <CustomGridItem className="grid-2">End Date</CustomGridItem>
                  <CustomGridItem className="grid-1">Action</CustomGridItem>
                </ProjectsHeader>
                {listProjects.map(project => (
                  <ProjectRow container key={project.id}>
                    <CustomGridItem className="grid-3">
                      <CustomTooltip content={project.name} />
                    </CustomGridItem>
                    <CustomGridItem className="grid-2">
                      {project.code}
                    </CustomGridItem>
                    <CustomGridItem className="grid-2">
                      {project.isPersonal ? 'Personal' : 'Corporate'}
                    </CustomGridItem>
                    <CustomGridItem className="grid-2">
                      {project.isSuppression && (
                        <SuccessIcon className="success" />
                      )}
                    </CustomGridItem>
                    <CustomGridItem className="grid-2">
                      {moment(project.startDate).format('DD/MM/YYYY')}
                    </CustomGridItem>
                    <CustomGridItem className="grid-2">
                      {moment(project.endDate).format('DD/MM/YYYY')}
                    </CustomGridItem>
                    <CustomGridItem className="grid-1">
                      <IconButton
                        onClick={e => {
                          setAnchorEl(e.currentTarget);
                          setSelectedItem(project);
                          companyDispatcher.getStationsByProject(
                            project.id,
                            result => setIsEditSequence(result.length > 1)
                          );
                        }}
                      >
                        <MoreVertRounded />
                      </IconButton>
                    </CustomGridItem>
                  </ProjectRow>
                ))}

                <AddProject
                  onClick={() => {
                    setOpenDrawer(true);
                    setIsCreateProject(true);
                  }}
                >
                  <Add />
                  Create project
                </AddProject>
              </ProjectsContainer>
            </Grid>
          </Grid>
        </DetailWrapped>
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          disableBackdropClick
          PaperProps={{
            style: {
              width: '50%',
              minWidth: 768
            }
          }}
        >
          <CreateAndEditProject
            isCreate={isCreateProject}
            companyID={companyID}
            selectedItem={selectedItem}
            onClose={() => {
              setOpenDrawer(false);
              getProjectByCompany();
            }}
          />
        </Drawer>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {<CustomMenuActions listActions={listActions} />}
        </Menu>
        <ModalConfirmation
          open={deleteModal}
          onClose={onClose}
          onClick={onDeleteProject}
          mainContent={'Delete project'}
          subContent={'Are you sure you want to delete this project?'}
          cancelLabel="Cancel"
          confirmLabel="Confirm"
        />

        {/** Edit Sequence Stations Modal */}
        {selectedItem && isOpenEditSequence ? (
          <EditSequenceStationsModal
            isOpen={isOpenEditSequence}
            projectId={selectedItem.id}
            projectName={selectedItem.name}
            onCloseModal={() => setOpenEditSequence(false)}
          />
        ) : (
          ''
        )}
      </Box>
    </CustomPaperContainer>
  );
};

export default CreateAndEditCompany;
