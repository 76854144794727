import adminReducer from '../module/admin-management/reducer';
import allUsersReducer from '../module/all-users/reducer';
import { appointmentManagementReducer } from '../module/appointment-management';
import { authReducer } from '../module/auth';
import { bannerReducer } from '../module/banner';
import bookingReducer from '../module/booking-management/reducer';
import caConsultReducer from '../module/ca-consult/reducer/caConsult';
import { caQueueReducer } from '../module/ca-queue';
import caReducer from '../module/ca/reducer/ca';
import { categoryReducer } from '../module/category';
import clinicReducer from '../module/clinic/reducer/index';
import companyReducer from '../module/company/reducer';
import doctorReducer from '../module/doctor/reducer/doctor';
import { healthProfileReducer } from '../module/health-profile';
import livestreamReducer from '../module/livestream/reducer/index';
import meetingHistoryReducer from '../module/meeting-history/reducer/meetingHistory';
import mhsReducer from '../module/mhs-management/reducer';
import { notificationReducer } from '../module/notification';
import onDemandReducer from '../module/on-demand/reducer/index';
import patientReducer from '../module/patient/reducer/index';
import profileReducer from '../module/profile/reducer/index';
import promotionReducer from '../module/promotion/reducer';
import testsReducer from '../module/services/reducer';
import locationReducer from '../module/setup/reducer';
import { studioReducer } from '../module/studio';
import studioClassReducer from '../module/studio-classes/reducer';
import trainerManagement from '../module/trainer-management/reducer';
import { upcomingReducer } from '../module/upcoming-workout';
import userManagement from '../module/user-management/reducer';
import userPermissionReducer from '../module/user-permission/reducer';
import waitingRoomReducer from '../module/waiting-room/reducer/waitingRoom';
import bookingAppointmentReducer from './booking-appointment/reducer';
import globalReducer from './global/reducer';
import allScreeningsReducer from '@/module/all-screenings/reducer';
import backroomModuleReducer from '@/module/backroom-module/reducer';
import checkInPatientInfoReducer from '@/module/checkin-patient-information/reducer';
import consentReducer from '@/module/consents/reducer';
import counsellorsEXReducer from '@/module/counsellor-executives-management/reducer';
import counsellorsReducer from '@/module/counsellors-management/reducer';
import deletionRequestReducer from '@/module/deletion-request/reducer';
import ecommerceReducer from '@/module/e-commerce-management/reducer';
import labResultReducer from '@/module/lab-result/reducer';
import marketingBroadcastReducer from '@/module/marketing-messages/reducer';
import motherStatementReducer from '@/module/mother-statement-management/reducer';
import eOrderListReducer from '@/module/order-list-export/reducer';
import { packageReducer } from '@/module/package-management';
import roleManagementReducer from '@/module/role-management/reducer';
import { questionnairesReducer } from '@/module/screening-questionnaires';
import siteClosingReportReducer from '@/module/site-closing-report/reducer';
import statementReducer from '@/module/statement-management/reducer';
import stationReducer from '@/module/station-management/reducer';
import stuckAccountsReducer from '@/module/stuck-accounts/reducer';
import surveyReducer from '@/module/surveys/reducer';
import swabberReducer from '@/module/swabber/reducer/swabber';
import { teleAppoinmentReducer } from '@/module/tele-appointment';
import teleConsultNowReducer from '@/module/teleconsult-now/reducer';
import telecounselAllCounselsReducer from '@/module/telecounsel-all-counsels/reducer';
import telecounselAppointmentConfigReducer from '@/module/telecounsel-appointment-configuration/reducer';
import telecounselAppointmentReducer from '@/module/telecounsel-appointments/reducer';
import teleQueueRoomRoomReducer from '@/module/telecounsel-queue/reducer';
import timeSlotTemplateManagementReducer from '@/module/time-slot-template/reducer';
import upcomingHSReducer from '@/module/upcoming-health-screening/reducer';
import { combineReducers } from 'redux';

export default combineReducers({
  ...authReducer,
  ...doctorReducer,
  ...waitingRoomReducer,
  ...caReducer,
  ...caConsultReducer,
  ...notificationReducer,
  ...meetingHistoryReducer,
  ...patientReducer,
  ...clinicReducer,
  ...profileReducer,
  ...categoryReducer,
  ...onDemandReducer,
  ...livestreamReducer,
  ...caQueueReducer,
  ...upcomingReducer,
  ...studioClassReducer,
  ...testsReducer,
  ...healthProfileReducer,
  ...companyReducer,
  ...locationReducer,
  ...studioReducer,
  ...trainerManagement,
  ...userManagement,
  ...allUsersReducer,
  ...promotionReducer,
  ...mhsReducer,
  ...adminReducer,
  ...appointmentManagementReducer,
  ...bookingReducer,
  ...bookingAppointmentReducer,
  ...bannerReducer,
  ...userPermissionReducer,
  ...packageReducer,
  ...statementReducer,
  ...globalReducer,
  ...teleAppoinmentReducer,
  ...stationReducer,
  ...timeSlotTemplateManagementReducer,
  ...consentReducer,
  ...checkInPatientInfoReducer,
  ...surveyReducer,
  ...labResultReducer,
  ...motherStatementReducer,
  ...questionnairesReducer,
  ...swabberReducer,
  ...backroomModuleReducer,
  ...siteClosingReportReducer,
  ...deletionRequestReducer,
  ...roleManagementReducer,
  ...stuckAccountsReducer,
  ...upcomingHSReducer,
  ...allScreeningsReducer,
  ...marketingBroadcastReducer,
  ...eOrderListReducer,
  ...teleQueueRoomRoomReducer,
  ...telecounselAppointmentReducer,
  ...telecounselAppointmentConfigReducer,
  ...telecounselAllCounselsReducer,
  ...counsellorsReducer,
  ...counsellorsEXReducer,
  ...ecommerceReducer,
  ...teleConsultNowReducer,
});
