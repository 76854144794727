export const togglePermission = (originArr = [], item) => {
  const foundItem = originArr.findIndex(
    it => it.roleType === item.roleType && it.privilege === item.privilege
  );
  if (foundItem < 0) {
    originArr.push({ ...item });
  } else {
    if (originArr[foundItem].isActive !== item.isActive) {
      originArr.splice(foundItem, 1);
    }
  }
  return originArr;
};
