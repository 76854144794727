/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TableWithFooter from '@/new-components/CustomTable/TableWithFooter';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { BILLING_TAB } from '../../constants';

const CustomTitleContainer = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 16
  }
})(Box);
const CustomTitleLabel = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600
  }
})(Typography);
const CustomSubtitleLabel = withStyles({
  root: {}
})(Typography);

const CustomToggleButtonGroup = withStyles({
  root: {
    background: '#f1f1f1',
    borderRadius: 8,
    gap: 4,
    padding: 4
  }
})(ToggleButtonGroup);

const CustomToggleButton = withStyles(theme => ({
  root: {
    border: 'none',
    borderRadius: '6px !important',
    color: '#666666',
    textTransform: 'unset',
    fontSize: 14,
    height: 32,

    '&.Mui-selected': {
      color: theme.palette.general.white,
      backgroundColor: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    }
  }
}))(ToggleButton);

const ServicesTable = ({
  title = '',
  subTitle = '',
  labelButton,
  columns,
  renderRow,
  data,
  onClick,
  billingColumns,
  renderBillingRow,
  billingTab,
  setBillingTab,
  showBillingTab,
  hasBillingErrors
}) => {
  return (
    <Box py={2.5}>
      <CustomTitleContainer>
        {title && (
          <CustomTitleLabel>
            {title}
            {subTitle && <CustomSubtitleLabel>{subTitle}</CustomSubtitleLabel>}
          </CustomTitleLabel>
        )}

        <CustomToggleButtonGroup
          style={{ marginLeft: 'auto' }}
          hidden={!showBillingTab}
          exclusive
          value={billingTab}
          onChange={(_event, value) => value && setBillingTab(value)}
        >
          <CustomToggleButton value={BILLING_TAB.Default}>
            {BILLING_TAB.Default}
          </CustomToggleButton>
          <CustomToggleButton value={BILLING_TAB.Billable}>
            {BILLING_TAB.Billable}
          </CustomToggleButton>
        </CustomToggleButtonGroup>
      </CustomTitleContainer>

      {billingTab === BILLING_TAB.Default && (
        <TableWithFooter
          data={data || []}
          labelButton={labelButton}
          columns={columns}
          onClick={onClick}
          renderRow={renderRow}
        />
      )}

      {billingTab === BILLING_TAB.Billable && hasBillingErrors && (
        <Alert
          style={{ marginBottom: '16px', color: '#DB3D49' }}
          severity="error"
        >
          All values must be less than or equal to the default prices.
        </Alert>
      )}

      {billingTab === BILLING_TAB.Billable && (
        <TableWithFooter
          data={data || []}
          labelButton={labelButton}
          columns={billingColumns}
          isFooter={false}
          renderRow={renderBillingRow}
        />
      )}
    </Box>
  );
};

ServicesTable.propTypes = {
  billingColumns: PropTypes.array,
  renderBillingRow: PropTypes.func,
  billingTab: PropTypes.string,
  setBillingTab: PropTypes.func,
  showBillingTab: PropTypes.bool,
  hasBillingErrors: PropTypes.bool
};

ServicesTable.defaultProps = {
  billingColumns: [],
  renderBillingRow: () => console.warn('Not implemented yet'),
  billingTab: BILLING_TAB.Default,
  setBillingTab: () => console.warn('Not implemented yet'),
  showBillingTab: false,
  hasBillingErrors: false
};

export default ServicesTable;
