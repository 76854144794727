import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button } from '@material-ui/core';
import classnames from 'classnames';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyle = makeStyles({
  root: {
    width: '130px',
    height: '24px',
    border: '1px solid #e9e9e9',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  iconActive: {
    color: '#62CAA4'
  },
  iconInactive: {
    color: '#CACFD3'
  },
  txt: {
    color: '#192637',
    fontSize: 14,
    margin: '0 8px'
  },
  unavailable: {
    backgroundColor: '#CACFD3'
  }
});

const BookingItem = ({ item, className, onClick }) => {
  const classes = useStyle();

  const { baseSlot, slot, isActive, isUnavailable } = item;

  if (isUnavailable) {
    return (
      <Button
        className={classnames(classes.root, className, classes.unavailable)}
        disabled
      >
        <span className={classes.txt}>Unavailable</span>
      </Button>
    );
  }
  return (
    <Button
      id={item.id}
      className={classnames(classes.root, className)}
      onClick={() => onClick(item)}
    >
      <FiberManualRecordIcon
        className={classnames(
          isActive ? classes.iconActive : classes.iconInactive
        )}
        fontSize="small"
      />
      <span className={classes.txt}>{isActive ? 'Open' : 'Close'}</span>
      <span
        style={{
          flex: 1,
          color: baseSlot === slot ? '#CACFD3' : '#62CAA4'
        }}
      >{`${slot}/${baseSlot}`}</span>
    </Button>
  );
};

BookingItem.defaultProps = {
  className: ''
};

BookingItem.propTypes = {
  item: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default BookingItem;
