import dispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  },
  currentStatement: null
};

const reducer = dispatcher(initialState, {
  [dispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [dispatcher.saveDataById]: (state, payload) => {
    return { ...state, currentStatement: payload.data };
  }
});

export default reducer;
