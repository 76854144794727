import { createDownloadableLink } from '@/helpers';
import { synthesize } from 'redux-dispatcher';
import customToast from '@/new-components/CustomNotification';

const mapDispatchToAC = {
  uploadHSAppointments: (data, callback) => async ({ Api }) => {
    const { result, status } = await Api.post(
      `Paxscreenings/import`,
      data,
      '',
      undefined,
      true,
      true
    );
    if (status === 200) {
      customToast('success', 'Upload success');
      callback && callback();
      createDownloadableLink(result, `HS-Appointment`);
    }
  }
};

const importHSAppointmentDispatcher = synthesize(
  'importHSAppointment',
  mapDispatchToAC
);

export default importHSAppointmentDispatcher;
