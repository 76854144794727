import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Modal } from '../constants/OtherItemUI';
import { Paper } from '../constants/Table';

const styles = theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: '#192637',
    boxShadow: theme.shadows[5],
    outline: 'none',
    top: '10px',
    left: '10px',
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)'
  },
  margin: {
    margin: theme.spacing(1)
  }
});

const ModalUI = props => {
  const { open, children, classes } = props;
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open || false}
    >
      <Paper className={classes.paper}>{children}</Paper>
    </Modal>
  );
};

ModalUI.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  stylesBox: PropTypes.object
};

export default withStyles(styles)(ModalUI);
