const SEARCH_PARAM = {
  workoutTypes: ['OnDemand'],
  categoriesId: []
};

const TYPE_MODAL = {
  Create: 'Create',
  View: 'View',
  Edit: 'Edit'
};

const LIST_REQUIRED = [
  'title',
  'categoryId',
  'intensity',
  'duration',
  'calories',
  'vimeoClipId'
];

const FORM_VALUE = {
  title: '',
  video: null,
  thumbnail: '',
  price: 0,
  duration: '',
  calories: '',
  intensity: '',
  description: '',
  equipment: '',
  categoryId: '',
  vimeoClipId: ''
};

export { SEARCH_PARAM, TYPE_MODAL, LIST_REQUIRED, FORM_VALUE };
