import React, { useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/ClearRounded';
import { makeStyles } from '@material-ui/core/styles';
import { StyledButton } from '../../common/componentUI/commonStyleComponents';
import { useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { validateEmail, validateMobile } from '../../../utils/validation';
import caDispatcher from '../action/ca';
import PhoneInput from '../../../components/PhoneInput';
import { useSelector } from 'react-redux';
import { get, isEqual, sortBy, isEmpty } from 'lodash';
import MultipleSelection from '../../../components/CustomSelect/MutipleSelection';

const titleContainerStyle = {
  height: 60,
  padding: '16px 20px 17px 20px',
  fontSize: 18,
  fontWeight: 600,
  color: '#192637',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px #CACFD3 solid'
};

const bodyContainerStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px 30px 20px',
  fontSize: 14,
  width: 400
};

const footerContainerStyle = {
  display: 'flex',
  padding: '10px 20px 10px 20px',
  flexDirection: 'row-reverse',
  borderTop: '1px #CACFD3 solid',
  marginTop: 20
};

const useStyles = makeStyles({
  contained: {
    boxShadow: 'none'
  },
  head: {
    fontWeight: 600
  },
  root: {
    padding: '0px !important'
  }
});

const DetailPresenter = ({ onClose, selectedItem, detailModal, searchKey }) => {
  const classes = useStyles();
  const listClinic = useSelector(state => state.doctor.clinics.list || []);
  const [editableItem, setEditableItem] = useState({ countryCode: 'SG' });
  const [error, setError] = useState({
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    mobileError: '',
    countryCode: '',
    passwordError: '',
    confirmPasswordError: ''
  });

  useEffect(() => {
    if (!detailModal.isAddNew) {
      setEditableItem({
        ...selectedItem
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClinicChange = e => {
    if (get(e, 'target.value', []).includes('selectAll')) {
      isSelectAllClinic()
        ? setEditableItem({
            ...editableItem,
            lstClinicId: []
          })
        : setEditableItem({
            ...editableItem,
            lstClinicId: formatListClinic()
          });
    } else {
      setEditableItem({
        ...editableItem,
        lstClinicId: e.target.value
      });
    }
  };

  const formatListClinic = () => {
    return listClinic.map(clinic => clinic.id);
  };

  const isSelectAllClinic = () => {
    const clinics = formatListClinic();
    return (
      clinics.length === get(editableItem, 'lstClinicId.length') &&
      isEqual(sortBy(editableItem.lstClinicId), sortBy(clinics))
    );
  };

  const validate = () => {
    let isValid = false;
    if (!editableItem.firstName) {
      setError({
        firstNameError: `First Name is required`
      });
    } else if (!editableItem.lastName) {
      setError({
        lastNameError: `LastName is required`
      });
    } else if (!editableItem.mobile) {
      setError({
        mobileError: `Mobile phone is required`
      });
    } else if (!validateMobile(editableItem.mobile, editableItem.countryCode)) {
      setError({
        mobileError: `Invalid mobile phone`
      });
    } else if (!editableItem.email) {
      setError({
        emailError: `Email is required`
      });
    } else if (!validateEmail(editableItem.email)) {
      setError({
        emailError: `Invalid email address`
      });
    } else if (!editableItem.password && detailModal.isAddNew) {
      setError({
        passwordError: `Password is required`
      });
    } else if (editableItem.password !== editableItem.confirmPassword) {
      setError({
        confirmPasswordError: `Confirm password does not match`
      });
    } else {
      isValid = true;
    }
    return isValid;
  };

  const renderFooter = () => {
    return (
      <>
        <StyledButton
          variant="contained"
          style={{
            width: 'auto',
            minWidth: 160,
            color: 'white',
            background: '#ad5e99',
            textTransform: 'capitalize',
            margin: 5,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 20,
            fontWeight: 600
          }}
          classes={{
            contained: classes.contained
          }}
          onClick={() => {
            const data = {
              id: selectedItem.id,
              mobile: `+${editableItem.mobile}`,
              ...editableItem
            };
            validate() &&
              (detailModal.isAddNew
                ? caDispatcher.createCA(data, searchKey, onClose)
                : caDispatcher.updateCA(data, searchKey, onClose));
          }}
        >
          {detailModal.isAddNew ? 'Create' : 'Update'}
        </StyledButton>
        <StyledButton
          variant="contained"
          style={{
            width: 'auto',
            minWidth: 160,
            color: '#192637',
            background: '#E9E9E9',
            textTransform: 'capitalize',
            margin: 5,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 20,
            fontWeight: 600
          }}
          classes={{
            contained: classes.contained
          }}
          onClick={onClose}
        >
          Cancel
        </StyledButton>
      </>
    );
  };
  return (
    <DialogContent className={classes.root}>
      <div style={titleContainerStyle}>
        {detailModal.isAddNew
          ? 'Create New'
          : !isEmpty(selectedItem.firstName)
          ? selectedItem.firstName + ' ' + selectedItem.lastName
          : selectedItem.fullName}
        <ClearIcon style={{ cursor: 'pointer' }} onClick={onClose} />
      </div>
      <div style={bodyContainerStyle}>
        <TextField
          error={error.firstNameError}
          label="First Name"
          style={{ width: '100%', marginTop: 10 }}
          value={editableItem.firstName || ''}
          onChange={e =>
            setEditableItem({
              ...editableItem,
              firstName: e.target.value
            })
          }
          helperText={
            error.firstNameError ||
            (detailModal.isAddNew
              ? 'This will be reflected in all documents (eg receipt, MC etc)'
              : null)
          }
        />
        <TextField
          error={error.lastNameError}
          label="Last Name"
          style={{ width: '100%', marginTop: 10 }}
          value={editableItem.lastName || ''}
          onChange={e =>
            setEditableItem({
              ...editableItem,
              lastName: e.target.value
            })
          }
          helperText={
            error.lastNameError ||
            (detailModal.isAddNew
              ? 'This will be reflected in all documents (eg receipt, MC etc)'
              : null)
          }
        />
        <TextField
          error={error.mobileError}
          helperText={error.mobileError}
          id="phone-input"
          label="Phone"
          style={{ width: '100%', marginTop: 10 }}
          value={editableItem.mobile || ''}
          onChange={e =>
            setEditableItem({
              ...editableItem,
              mobile: e.target.value
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneInput
                  country={editableItem.countryCode}
                  disabled={false}
                  onChange={e =>
                    setEditableItem({
                      ...editableItem,
                      countryCode: e.target.value
                    })
                  }
                />
              </InputAdornment>
            )
          }}
        />
        <TextField
          error={error.emailError}
          label="Email"
          style={{ width: '100%', marginTop: 10 }}
          value={editableItem.email || ''}
          onChange={e =>
            setEditableItem({
              ...editableItem,
              email: e.target.value
            })
          }
          helperText={error.emailError}
        />
        <TextField
          error={error.passwordError}
          label="Password"
          type="password"
          style={{
            width: '100%',
            marginTop: 10,
            display: detailModal.isAddNew ? 'static' : 'none'
          }}
          value={editableItem.password || ''}
          onChange={e =>
            setEditableItem({
              ...editableItem,
              password: e.target.value
            })
          }
          helperText={error.passwordError}
        />
        <TextField
          error={error.confirmPasswordError}
          label="Confirm Password"
          type="password"
          style={{
            width: '100%',
            marginTop: 10,
            display: detailModal.isAddNew ? 'static' : 'none'
          }}
          value={editableItem.confirmPassword || ''}
          onChange={e =>
            setEditableItem({
              ...editableItem,
              confirmPassword: e.target.value
            })
          }
          helperText={error.confirmPasswordError}
        />
        <MultipleSelection
          onChange={onClinicChange}
          value={editableItem.lstClinicId || []}
          isMutiple
          inputLabel="clinic"
          labelId="clinic-select-label"
          formClasses={{
            width: '100%',
            maxWidth: '100%',
            margin: '10px 0 0 0'
          }}
          data={listClinic}
          renderValue={value => {
            let contentList = value.map(i =>
              get(
                listClinic.find(c => c.id === i),
                'name'
              )
            );
            return contentList.join(', ');
          }}
          useSelectAll
          isClinic
          checkedAll={isSelectAllClinic()}
        />
      </div>
      <div style={footerContainerStyle}>{renderFooter()}</div>
    </DialogContent>
  );
};

export default DetailPresenter;
