import { synthesize } from 'redux-dispatcher';
import { uploadImage } from '../../../firebase';
import { createDownloadableLink } from '../../../helpers';

const mapDispatchToAC = {
  showHideLoadingAction: payload => payload,
  getOnDemandList: (
    search,
    paging,
    sortOption = {},
    filterOption = {}
  ) => async ({ Api, getState }) => {
    const pagingData = paging || getState().onDemand.paging;
    const { pageIndex, pageSize } = pagingData;
    const { result, status } = await Api.post(`Workouts/filter`, {
      pageIndex,
      pageSize,
      search,
      ...sortOption,
      ...filterOption
    });
    status === 200 && onDemandDispatcher.getOnDemandListSuccess(result);
  },
  getOnDemandListSuccess: payload => payload,

  getDurationIntensity: () => async ({ Api }) => {
    const { result, status } = await Api.get(`Workouts/duration_intensity`);
    status === 200 && onDemandDispatcher.getDurationIntensitySuccess(result);
  },
  getDurationIntensitySuccess: payload => payload,

  getCategoryOnDemand: () => async ({ Api }) => {
    const { result, status } = await Api.post(`Categories/filter`, {});
    status === 200 && onDemandDispatcher.getCategoryOnDemandSuccess(result);
  },
  getCategoryOnDemandSuccess: payload => payload,
  getOnDemandById: (id, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.get(`Workouts/${id}`);
    status === 200 && callbackSuccess(result);
  },

  addOnDemand: (payload, callbackSuccess) => async ({ Api, customToast }) => {
    const { result, status, message } = await Api.post(
      `Workouts`,
      payload,
      '',
      false,
      true,
      true
    );
    onDemandDispatcher.showHideLoadingAction({ loading: false });
    if (status === 200) {
      callbackSuccess(result);
      return customToast('success', message || 'Success');
    }
  },

  editOnDemand: (payload, callbackSuccess) => async ({ Api, customToast }) => {
    const { data, id } = payload;

    const { result, status } = await Api.put(
      `Workouts/${id}`,
      data,
      '',
      false,
      true,
      true
    );
    onDemandDispatcher.showHideLoadingAction({ loading: false });
    if (status === 200) {
      callbackSuccess(result);
      return customToast('success', 'Success');
    }
  },
  editOnDemandSuccess: payload => payload,

  deleteOnDemand: (workoutId, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    const { status } = await Api.delete(`Workouts/${workoutId}`);
    if (status === 200) {
      customToast('success', 'Deleted successfully');
      callbackSuccess();
    }
  },
  getExportExcel: () => async ({ Api }) => {
    let { result, status } = await Api.get(`WorkoutReports/ondemand-csv`);
    status === 200 && createDownloadableLink(result);
  }
};

const onDemandDispatcher = synthesize('onDemand', mapDispatchToAC);
export default onDemandDispatcher;
