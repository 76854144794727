import React, { useCallback, useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';

import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';

import {
  AddIcon,
  CreateNewIcon,
  DeleteModalIcon,
  RemoveIcon
} from '@/assets/svg';

import GroupToolbar from './components/GroupToolbar';
import timeslotTemplateDispatcher from './action';
import { formatTimeShowUI } from '../setup/utils';
import ModalContainerForm from './components/ModalContainerForm';
import { handleSelectAll, momentFormatted } from '@/helpers';

import { TIME_SELECT } from '../setup/constants';
import { COLUMN_TIMESLOT_TEMPLATE } from './constants';
import { DATE_TIME_FORMAT } from '../common';

const TimeSlotTemplate = () => {
  const { list: data, paging } = useSelector(state => state.timeSlotManagement);

  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({
    startTime: moment()
      .startOf('day')
      .set('hour', 7),
    endTime: moment()
      .startOf('day')
      .set('hour', 21),
    interval: []
  });

  const [openDrawer, setOpenDrawer] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    filterOptionData = filterOption
  ) => {
    timeslotTemplateDispatcher.getData(
      search,
      pagingData,
      ...[filterOptionData].filter(i => i)
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  const onResetFilterOps = () => {
    setSearchKey('');
    setFilterOption({});
  };

  const onDeleteTimeslot = () => {
    timeslotTemplateDispatcher.deleteTemplate(selectedItem.id, () => {
      timeslotTemplateDispatcher.getData(searchKey, {
        pageIndex: 0,
        pageSize: paging.pageSize
      });
      setDeleteModal(false);
    });
  };

  const onFilterOptionClear = (key, defaultValue) => () => {
    setFilterOption({
      ...filterOption,
      [key]: defaultValue
    });
  };

  const onFilterOptionChange = key => e => {
    let value = '';

    switch (key) {
      case 'startTime':
      case 'endTime':
        value = moment(e.target.value, 'h:mm A');
        break;
      case 'interval':
        value = handleSelectAll(
          e.target.value,
          TIME_SELECT,
          filterOption.interval
        );
        break;

      default:
        value = e.target.value;
        break;
    }

    setFilterOption({
      ...filterOption,
      [key]: value
    });
  };

  useEffect(() => {
    debounceLoadData(searchKey, paging, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, filterOption]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {COLUMN_TIMESLOT_TEMPLATE.map(item => (
          <TableCell
            key={item.stateValue || item.label}
            style={{ minWidth: item.minWidth, width: item.width }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = row => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.name} />
        </TableCell>
        <TableCell>{formatTimeShowUI(row.startTime)}</TableCell>
        <TableCell>{formatTimeShowUI(row.endTime)}</TableCell>
        <TableCell>{row.interval} min</TableCell>
        <TableCell>{row.quotaBase}</TableCell>
        <TableCell>
          {momentFormatted(row.updatedAt, DATE_TIME_FORMAT)}
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              setIsCreate(false);
              setOpenDrawer(true);
              setSelectedItem(row);
            }}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeleteModal(true);
              setSelectedItem(row);
            }}
          >
            <RemoveIcon />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        disableBackdropClick
        PaperProps={{
          style: {
            width: '50%',
            minWidth: 768
          }
        }}
      >
        <ModalContainerForm
          selectedItem={selectedItem}
          isCreate={isCreate}
          onClose={() => {
            setOpenDrawer(false);
            fetchData();
          }}
        />
      </Drawer>
      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder="Search by timeslot template name"
            title="Timeslot template"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                filterOption={filterOption}
                onFilterOptionClear={onFilterOptionClear}
                onFilterOptionChange={onFilterOptionChange}
                searchKey={searchKey}
                onResetFilterOps={onResetFilterOps}
              />
            )}
            renderButton={() => (
              <Button
                color="primary"
                variant="contained"
                startIcon={<CreateNewIcon />}
                onClick={() => {
                  setOpenDrawer(true);
                  setIsCreate(true);
                }}
              >
                Create new
              </Button>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          noDataHelperText="No template"
          noDataHelperSubText="Please create a new  timeslot template."
          totalCols={COLUMN_TIMESLOT_TEMPLATE.length}
          fetchData={fetchData}
        />
      </CustomPaperContainer>

      {deleteModal && (
        <ModalConfirmation
          confirmLabel="Delete"
          mainContent="Delete template"
          subContent="Are you sure you want to delete this timeslot template ?"
          open={deleteModal}
          confirmColor="red"
          Icon={<DeleteModalIcon />}
          onClose={() => setDeleteModal(false)}
          onClick={onDeleteTimeslot}
        />
      )}
    </>
  );
};

export default TimeSlotTemplate;
