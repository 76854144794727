import React, { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import ModuleImage from '@/assets/image/module-default.png';

const useStyle = makeStyles({
  wrapperUpload: {
    backgroundColor: '#F6F7FA'
  },

  editIcon: {
    position: 'absolute',
    bottom: 0,
    right: -10,
    textAlign: 'center',

    width: 24,
    height: 24,
    color: '#fff',
    backgroundColor: '#AD5E99',
    borderRadius: '50%'
  }
});

const FormDownload = ({ dataModule, hasIRReport }) => {
  const classes = useStyle();
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    setLocalData(dataModule);
  }, [dataModule]);

  return (
    <>
      <Box fontSize={12}>
        {hasIRReport && (
          <>
            <p style={{ padding: '0 24px' }}>IR Report</p>

            <Box
              display="flex"
              alignItems="center"
              height={48}
              justifyContent={'flex-start'}
              style={{ backgroundColor: '#F6F7FA' }}
              mb={0}
              fontSize={14}
              px={3}
              py={3}
            >
              Health Screening Report.pdf
            </Box>
          </>
        )}
      </Box>
      {!isEmpty(localData) &&
        localData.map(item =>
          item.resultFilePath ? (
            <Box my={3} key={item.moduleId}>
              <label htmlFor={item.moduleId}>
                <Box pb={1} px={3} fontSize={12}>
                  {item.moduleName}
                </Box>

                <Box
                  display="flex"
                  color="primary.main"
                  alignItems="center"
                  height={93}
                  fontWeight="600"
                  justifyContent="space-between"
                  className={classes.wrapperUpload}
                  px={3}
                >
                  <img
                    id={item.moduleId}
                    src={ModuleImage}
                    width="80"
                    height="80"
                    alt={item.moduleName}
                  />
                </Box>
              </label>
            </Box>
          ) : null
        )}
    </>
  );
};

export default FormDownload;
