import Enum from './Enum';

export const roleType = Enum({
  SuperAdmin: 1,
  Doctor: 2,
  Patient: 3,
  CA: 4,
  Swabber: 5
});

export const doctorStatus = Enum({
  Pending: 'Pending',
  Reject: 'Reject',
  Approved: 'Approved'
});

export const userStatus = Enum({
  InActive: 'InActive',
  Active: 'Active'
});
