import React, { useEffect, useState } from 'react';
import { API_ROOT } from '../common';
import Box from '@material-ui/core/Box';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import customToast from '@/new-components/CustomNotification';

const VerifyPatient = props => {
  const [errorMessage, setErrorMessage] = useState({
    valid: false,
    message: ''
  });
  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const token = query.get('token');
    const userId = query.get('userId');

    if (!token || !userId) {
      return customToast('error', 'There is a problem when verify your email');
    }
    fetch(
      `${API_ROOT}UserTokens/verify-email?userId=${userId}&token=${token}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(res => {
        return res.json();
      })
      .then(res => {
        if (get(res, 'statusCode') === 200) {
          setErrorMessage({ valid: true, message: 'Verify successfully' });
          return customToast('success', 'Verify successfully');
        }
        setErrorMessage({
          valid: false,
          message: get(res, 'message') || 'Verify error!'
        });
        return customToast('error', get(res, 'message') || 'Verify error!');
      })
      .catch(error => {
        return customToast('error', 'Verify error!');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history]);

  return (
    <>
      {errorMessage.message && (
        <h1>
          {errorMessage.valid && (
            <Box color="success.main">{errorMessage.message}</Box>
          )}
          {!errorMessage.valid && (
            <Box color="error.main">{errorMessage.message}</Box>
          )}
        </h1>
      )}
    </>
  );
};

export default VerifyPatient;
