import { urlLabel } from '@/enum/PermissionEnum';
import { AuthLayout } from '@/module/common';
import { useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const AuthRoute = ({ component: Component, publicComponent, ...rest }) => {
  const theme = useTheme();
  const isMobileSize = !useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Route
      render={(matchProps) => {
        if (publicComponent || !rest.auth.accessToken)
          return isMobileSize &&
            [
              `/${urlLabel.patientLogin}`,
              `/${urlLabel.patientRegister}`,
              `/${urlLabel.patientForgotPassword}`,
            ].includes(rest.path) ? (
            <Component {...matchProps} {...rest} />
          ) : (
            <AuthLayout>
              <Component {...matchProps} {...rest} />
            </AuthLayout>
          );
        return <Redirect to="/" />;
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    privilege: state.userPermission.privilege,
  };
};

export const WrapperAuthRoute = connect(mapStateToProps)(AuthRoute);
