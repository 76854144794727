import timeslotTemplateDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  }
};

const timeSlotTemplateManagementReducer = timeslotTemplateDispatcher(
  initialState,
  {
    [timeslotTemplateDispatcher.getTimeSlotsSuccess]: (state, payload) => ({
      list: payload.data.list,
      paging: {
        ...payload.data,
        list: undefined
      }
    }),
    [timeslotTemplateDispatcher.setState]: (state, payload) => ({
      [payload.state]: payload.value
    })
  }
);

export default timeSlotTemplateManagementReducer;
