/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
  DialogContent,
  TextField,
  Grid,
  FormLabel,
  Typography
} from '@material-ui/core';
import {
  TimePicker,
  LocalizationProvider,
  DatePicker
} from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { ClearRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import moment from 'moment';

import LoadingButton from '@/components/LoadingButton';
import validateData from '@/helpers/validationHelpers/validationSchema';

import CustomRadioBtn from '../CustomRadioBtn';
import { FORMAT_DD_MM_YYYY, TIME_ZONE_SINGAPORE } from '../../constants';
import ModalPage, { ModalFooter } from './styled';
import { ButtonEnhance } from '../../../common/componentUI/commonStyleComponents';

const regexNumber = /\D/;

const ModalEditBookingItem = ({ onClose, submitForm, value }) => {
  const [formValue, setFormValue] = useState({
    date: moment(value.startDate),
    option: value.isActive ? 'open' : 'close',
    time: moment(value.startDate),
    baseSlot: value.baseSlot
  });
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  const handleSubmit = async () => {
    try {
      await validateData('validationEditBookingItem', formValue, validData => {
        if (Number(validData.baseSlot) < value.slot) {
          setErrors({
            ...errors,
            baseSlot: `Base slots must be greater than slots (${value.slot}) is booked`
          });
          return;
        }
        setErrors({ ...errors, baseSlot: '' });
        setShowLoader(true);
        submitForm(
          {
            ...validData,
            date: moment.utc(validData.date).format()
          },
          () => setShowLoader(false)
        );
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const onChangeForm = key => value => {
    if (key === 'baseSlot') {
      if (!value) setFormValue({ ...formValue, [key]: '' });

      if (value && !value.match(regexNumber)) {
        setFormValue({ ...formValue, [key]: value });
      }

      return;
    }
    setFormValue({ ...formValue, [key]: value });
  };

  const renderContentPage = () => {
    return (
      <ModalPage>
        <DialogContent>
          <div className="header">
            Edit Booking Time
            <ClearRounded
              style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
              onClick={onClose}
            />
          </div>
          <div className="body">
            <LocalizationProvider dateAdapter={MomentAdapter}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <DatePicker
                    disabled
                    value={formValue.date}
                    name="date"
                    inputFormat={FORMAT_DD_MM_YYYY}
                    renderInput={props => (
                      <TextField
                        {...props}
                        label="Date"
                        style={{ width: '100%', marginTop: 10 }}
                        error={errors.date}
                        helperText={errors.date}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TimePicker
                    disabled
                    clearable
                    label="Time"
                    value={formValue.time}
                    views={['hours', 'minutes']}
                    name="time"
                    renderInput={props => (
                      <TextField
                        {...props}
                        style={{ width: '100%', marginTop: 10 }}
                        error={errors.time}
                        helperText={errors.time}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel error={errors.option}>Select Option</FormLabel>
                  <CustomRadioBtn
                    value={formValue.option}
                    onChange={onChangeForm}
                  />
                  <Typography color="error" variant="caption">
                    {errors.option}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel error={errors.baseSlot}>Number of slots</FormLabel>
                  <TextField
                    error={errors.baseSlot}
                    helperText={errors.baseSlot}
                    style={{ width: '100%', height: 40, paddingTop: 4 }}
                    value={formValue.baseSlot}
                    onChange={e => onChangeForm('baseSlot')(e.target.value)}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </div>
          <ModalFooter>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <ButtonEnhance
                background="#E9E9E9"
                color="black"
                backgroundHover="#ccc"
                onClick={onClose}
              >
                Close
              </ButtonEnhance>
              <LoadingButton onClick={handleSubmit} showLoader={showLoader}>
                Update
              </LoadingButton>
            </div>
          </ModalFooter>
        </DialogContent>
      </ModalPage>
    );
  };

  return <>{renderContentPage()} </>;
};

ModalEditBookingItem.propTypes = {
  onClose: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired
};

export default ModalEditBookingItem;
