import Enum from '@/enum/Enum';

export const CONDITIONS = ['>=', '>', '<=', '<', '==', '!=', 'Like'];
export const TYPES = ['TestCode', 'Gender', 'Age'];
export const GENDER = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Non-specified', value: 'Other' }
];

export const COLUMN_MOTHER_STATEMENT = [
  {
    label: 'Statement code',
    stateValue: 'code'
  },
  {
    label: 'S1 Eng',
    stateValue: 's1English'
  },
  {
    label: 'S2 Eng',
    stateValue: 's2English'
  },
  {
    label: 'R1 Eng',
    stateValue: 'r1English'
  },
  {
    label: 'R2 Eng',
    stateValue: 'r2English'
  },
  {
    label: 'S1 Chi',
    stateValue: 's1Chinese'
  },
  {
    label: 'S2 Chi',
    stateValue: 's2Chinese'
  },
  {
    label: 'R1 Chi',
    stateValue: 'r1Chinese'
  },
  {
    label: 'R2 Chi',
    stateValue: 'r2Chinese'
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 100
  }
];

export const MOTHER_STATEMENT = Enum({
  s1English: 'S1 English',
  s2English: 'S2 English',
  r1English: 'R1 English',
  r2English: 'R2 English',
  s1Chinese: 'S1 Chinese',
  s2Chinese: 'S2 Chinese',
  r1Chinese: 'R1 Chinese',
  r2Chinese: 'R2 Chinese'
});

export const LANGUAGES_MOTHER_STATEMENT = ['English', 'Chinese'];
export const MOTHER_STATUS = [
  { label: 'Normal', key: 'Normal' },
  { label: 'Abnormal', key: 'Abnormal' },
  { label: 'Urgent', key: 'Urgent' }
];
