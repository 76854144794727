import CustomButton from '../CustomButton';
import CustomSelectContainer from '../CustomSelectOld/CustomSelectContainer';
import CustomTooltip from '../CustomTooltip';
import CustomModalTitle from './CustomModalTitle';
import { GeneralInforIcon } from '@/assets/svg';
import { durationFormatted, renderListDuration } from '@/helpers';
import validateData from '@/helpers/validationHelpers/validationSchema';
import teleQueueRoomDispatcher from '@/module/telecounsel-queue/action';
import { COUNSELLING_TYPES } from '@/module/telecounsel-queue/constants';
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  makeStyles,
  withStyles
} from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

const rowStyles = makeStyles({
  gridItems: { display: 'flex' },
  title: {
    margin: 0,
    paddingBottom: 10,
    width: 130,
    verticalAlign: 'top',
    fontSize: 14,
    color: '#666666'
  },
  text: {
    margin: 0,
    paddingBottom: 10,
    paddingRight: 8,
    fontSize: 16,
    verticalAlign: 'top',
    width: 'calc(100% - 130px)', // title length
    color: '#333333'
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 8,
    color: '#666666'
  }
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 480
  }
})(Dialog);

const CustomDialogContent = withStyles(theme => ({
  root: {
    maxHeight: '70vh',
    padding: theme.spacing(3)
  }
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end'
  }
})(DialogActions);

const CustomInputLabel = withStyles(theme => ({
  root: {
    color: '#666666',
    fontWeight: 600,
    marginBottom: theme.spacing(1)
  }
}))(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
      borderColor: '#e1e1e1'
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16
    },
    '& .Mui-disabled': {
      '& > div > div': {
        color: '#999999'
      },
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset'
      }
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#999999'
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0
      },
      '& input': {
        marginLeft: 0
      }
    }
  }
})(TextField);

const CustomCard = withStyles({
  root: {
    padding: 20,
    boxShadow: 'none',
    borderRadius: 16,
    overflow: 'auto',
    backgroundColor: props => (props.color ? props.color : '#F6F6F6'),
    width: props => (props.width ? props.width : 'unset')
  }
})(Card);

const RowHeaderItem = ({ title, icon }) => {
  const Icon = icon;
  const classes = rowStyles();
  return (
    <Grid
      item
      xs={12}
      className={classes.title}
      style={{ fontSize: 20, color: '#333333' }}
    >
      {icon && <Icon className={classes.icon} />}
      <span style={{ verticalAlign: 'middle' }}>{title}</span>
    </Grid>
  );
};

const RowItem = ({ children, title }) => {
  const classes = rowStyles();
  return (
    <Grid className={classes.gridItems} item xs={12}>
      <p className={classes.title}>{title}</p>
      <span className={classes.text}>{children}</span>
    </Grid>
  );
};

const ModalGenerateInvoice = ({ open, onClose, selectedItem, onSubmit }) => {
  const [formValue, setFormValue] = useState({
    counsellingFee: '',
    deduction: '',
    addition: '',
    duration: 0,
    remarks: '',
    counsellingType: 'Individual',
    depositDeduction: 50
  });
  const [errors, setErrors] = useState({});
  const [pricePerHour, setPricePerHour] = useState(0);

  const handleChangeForm = key => (e, selectVal) => {
    let value = e?.value;
    let counsellingFee = 0;

    switch (key) {
      case 'duration':
        counsellingFee =
          (Number(get(e, 'target.value')) / 60) * pricePerHour +
          formValue.addition -
          formValue.deduction;

        setFormValue({
          ...formValue,
          counsellingFee,
          [key]: get(e, 'target.value')
        });
        return;
      case 'counsellingType':
        value = get(e, 'target.value');
        break;

      default:
        value = Number(value);
        break;
    }

    setFormValue({ ...formValue, [key]: value });
  };

  const handleSubmit = async () => {
    try {
      await validateData('counsellingPaymentSchema', formValue, () => {
        onSubmit(selectedItem.consultId, formValue);
        setErrors({});
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  useEffect(() => {
    if (selectedItem?.consultId) {
      teleQueueRoomDispatcher.getTeleCounsellingById(
        selectedItem.consultId,
        data => {
          setFormValue({
            ...formValue,
            duration: data.duration,
            remark: data.remark,

            counsellingFee: data.counsellingFee,
            deduction: data.discountAmount,
            addition: data.additionalCost,
            depositDeduction: data.duration === 0 ? 50 : data.depositDeduction
          });
          setPricePerHour(data.pricePerHour);
        }
      );
    }
  }, []);

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomModalTitle onClose={onClose}>Update details</CustomModalTitle>
      <CustomDialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomCard>
              <Grid container>
                <RowHeaderItem
                  title="Counselling details"
                  icon={GeneralInforIcon}
                />
                <RowItem title="Client">
                  <CustomTooltip
                    content={selectedItem?.patientFullName || '—'}
                  />
                </RowItem>
                <RowItem title="ID number">
                  <CustomTooltip
                    content={selectedItem?.patientIdentityNumber || '—'}
                  />
                </RowItem>
                <RowItem title="Counsellor">
                  <CustomTooltip
                    content={selectedItem?.doctorFullName || '—'}
                  />
                </RowItem>
                <RowItem title="Counselling ID">
                  <CustomTooltip
                    content={selectedItem?.consultShortId || '—'}
                  />
                </RowItem>
                <RowItem title="Date">
                  {selectedItem.consultantDate
                    ? moment(selectedItem.consultantDate).format(
                        'DD/MM/YYYY, HH:mm A'
                      )
                    : '—'}
                </RowItem>
                <RowItem title="Duration">
                  {selectedItem.startCallTime && selectedItem.endCallTime
                    ? durationFormatted(
                        selectedItem.startCallTime,
                        selectedItem.endCallTime
                      )
                    : '—'}
                </RowItem>
              </Grid>
            </CustomCard>
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Type of counselling</CustomInputLabel>
            <CustomSelectContainer
              data={COUNSELLING_TYPES || []}
              height="48px"
              fontSize="16px"
              isMutiple={false}
              hideClear
              colorInput="#000000de"
              placeholder="Select"
              renderValue={
                formValue.counsellingType
                  ? COUNSELLING_TYPES.find(
                      min => min.key === formValue.counsellingType
                    )?.value
                  : 'Select'
              }
              disabled
              filterValue={formValue.counsellingType || ''}
              onChange={handleChangeForm('counsellingType')}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Duration</CustomInputLabel>

            <CustomSelectContainer
              data={renderListDuration() || []}
              height="48px"
              fontSize="16px"
              isMutiple={false}
              hideClear
              colorInput="#000000de"
              placeholder="Select"
              renderValue={
                formValue.duration
                  ? renderListDuration().find(
                      min => String(min.key) === String(formValue.duration)
                    )?.value
                  : 'Select'
              }
              filterValue={formValue.duration || ''}
              onChange={handleChangeForm('duration')}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Counselling fee</CustomInputLabel>
            <NumberFormat
              fullWidth
              customInput={CustomTextField}
              variant="outlined"
              placeholder="0"
              onValueChange={handleChangeForm('counsellingFee')}
              allowNegative={false}
              value={formValue.counsellingFee || ''}
              decimalScale={2}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomInputLabel>Deduction</CustomInputLabel>
            <NumberFormat
              fullWidth
              customInput={CustomTextField}
              variant="outlined"
              placeholder="0"
              onValueChange={handleChangeForm('deduction')}
              allowNegative={false}
              value={formValue.deduction || ''}
              decimalScale={2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomInputLabel>Addition</CustomInputLabel>
            <NumberFormat
              fullWidth
              customInput={CustomTextField}
              variant="outlined"
              placeholder="0"
              onValueChange={handleChangeForm('addition')}
              allowNegative={false}
              value={formValue.addition || ''}
              decimalScale={2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomInputLabel>Deposit amount</CustomInputLabel>
            <NumberFormat
              fullWidth
              customInput={CustomTextField}
              variant="outlined"
              placeholder="0"
              onValueChange={handleChangeForm('depositDeduction')}
              allowNegative={false}
              value={formValue.depositDeduction || ''}
              decimalScale={2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            Number(formValue.duration) === 0 ||
            isEmpty(formValue.counsellingType)
          }
        >
          Complete
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalGenerateInvoice;
