import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import {
  Add,
  CheckboxDefault,
  DeleteIcon,
  EditIcon,
  RadioInactiveIcon
} from '@/assets/svg';
import { DragIndicator } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { cloneDeep, get, isEmpty } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidV4 } from 'uuid';
import {
  ButtonGroup,
  CardTitle,
  CheckboxContainer,
  Number,
  QuestionContainer,
  StyledAccordion,
  TextAreaType,
  Type
} from '@/module/surveys/component/styled';

import SurveyModal from './SurveyModal';
import { TYPE_MODAL } from '@/module/common/constants/typeModal';
import { ANSWER_TYPE, FIELD_ARRAY_VALUE } from '@/module/setup/constants';

const CustomButton = withStyles({
  root: {
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'unset',
    marginLeft: 10
  }
})(Button);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiInputBase-multiline': {
      borderRadius: 8,
      height: 'auto',
      paddingLeft: 0,
      fontSize: 16
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const CustomSurvey = ({ formValue, setFormValue, errors, setErrors }) => {
  const [openModal, setOpenModal] = useState(false);
  const [itemSelected, setItemSelected] = useState({});

  const CustomTitleLabel = withStyles({
    root: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 10,
      marginTop: 16,
      marginLeft: 16
    }
  })(Typography);

  const [surveyValue, setSurveyValue] = useState({ customFieldType: '' });
  const [isEdit, setIsEdit] = useState(false);
  const [options, setOptions] = useState([
    {
      value: null,
      id: uuidV4()
    }
  ]);

  const handleDelete = (item, index) => {
    let newFormValue = cloneDeep(formValue.lstProjectCustomFields);
    newFormValue.splice(index, 1);
    setFormValue({ ...formValue, lstProjectCustomFields: newFormValue });
  };

  const handleEdit = () => {
    setOpenModal(true);
    setIsEdit(true);
  };

  const handleDragEnd = result => {
    if (!result.destination) return;

    const { source, destination } = result;

    let updatedQuestions = Array.from(formValue?.lstProjectCustomFields);
    const [removed] = updatedQuestions.splice(source.index, 1);
    updatedQuestions.splice(destination.index, 0, removed);
    updatedQuestions = updatedQuestions.map((item, index) => ({
      ...item,
      order: index
    }));
    setFormValue({ ...formValue, lstProjectCustomFields: updatedQuestions });
  };

  useEffect(() => {
    updateContainerHeight();
  }, [options]);

  const updateContainerHeight = () => {
    const container = document.getElementById('options-container');
    if (container) {
      const containerHeight = container.getBoundingClientRect().height;
      container.style.minHeight = `${containerHeight}px`;
    }
  };

  const changeTableValue = (action, value, position) => {
    let lstProjectCustomFields = [...formValue.lstProjectCustomFields];
    switch (action) {
      case TYPE_MODAL.Create:
        lstProjectCustomFields.push(value);
        break;
      case TYPE_MODAL.Edit:
        lstProjectCustomFields[position] = value;
        break;
      case TYPE_MODAL.Delete:
        lstProjectCustomFields.splice(position, 1);
        break;
      default:
        break;
    }
    setFormValue({ ...formValue, lstProjectCustomFields });
    setOpenModal(false);
  };

  return (
    <>
      <CustomTitleLabel>Survey questions</CustomTitleLabel>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="questions">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {!isEmpty(formValue?.lstProjectCustomFields) &&
                formValue?.lstProjectCustomFields.map((item, index) => (
                  <Draggable
                    key={`question-${index}`}
                    draggableId={`question-${item?.id}-${index}`}
                    index={index}
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Box px={2} py={0.5}>
                          <QuestionContainer>
                            <DragIndicator className="icon-drag" />
                            <StyledAccordion
                              className={
                                item.customFieldType === 'Text' && 'isTextarea'
                              }
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Number>{index + 1}</Number>

                                <CardTitle>
                                  <span className="max-line">{item.label}</span>
                                  {item.customFieldType === 'MultiSelect' ? (
                                    <Typography>
                                      Please concern all option below
                                    </Typography>
                                  ) : ['Rating', 'DropdownList'].includes(
                                      item.customFieldType
                                    ) ? (
                                    <Typography>Select one only</Typography>
                                  ) : null}
                                </CardTitle>
                                <Type>{ANSWER_TYPE[item.customFieldType]}</Type>
                                <Divider
                                  light
                                  orientation="vertical"
                                  flexItem
                                />
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box p={1.5}>
                                  {FIELD_ARRAY_VALUE.includes(
                                    item.customFieldType
                                  ) &&
                                    (item?.customFieldType === 'Rating' ? (
                                      <Box
                                        display="flex"
                                        gridGap={24}
                                        flexWrap="wrap"
                                      >
                                        {item?.value.map(answer => (
                                          <Box width={60}>
                                            <Checkbox
                                              disabled
                                              icon={<RadioInactiveIcon />}
                                              style={{
                                                marginRight: 8
                                              }}
                                            />
                                            {answer}
                                          </Box>
                                        ))}
                                      </Box>
                                    ) : (
                                      <CheckboxContainer>
                                        {item?.value.map(answer => (
                                          <div
                                            key={answer}
                                            style={{
                                              width: '100%',

                                              /* [class="max-line"] */
                                              whiteSpace: 'pre-wrap',
                                              overflow: 'hidden',
                                              display: '-webkit-inline-box',
                                              WebkitBoxOrient: 'vertical',
                                              WebkitLineClamp: 2
                                            }}
                                          >
                                            <>
                                              <Checkbox
                                                disabled
                                                icon={<CheckboxDefault />}
                                                style={{
                                                  marginRight: 8
                                                }}
                                              />
                                              {answer}
                                              {answer === 'Others' &&
                                                item.hasAdditional && (
                                                  <Box pl={4}>
                                                    <CustomTextField
                                                      fullWidth
                                                      placeholder="Enter your answer"
                                                      multiline
                                                      disabled
                                                      variant="outlined"
                                                      maxRows={2}
                                                      value={
                                                        item.additionalNote
                                                      }
                                                    />
                                                  </Box>
                                                )}
                                            </>
                                          </div>
                                        ))}
                                      </CheckboxContainer>
                                    ))}

                                  {item.customFieldType === 'Text' && (
                                    <TextAreaType>
                                      Enter your answer
                                    </TextAreaType>
                                  )}
                                </Box>
                                <ButtonGroup>
                                  <Button
                                    className="warning"
                                    onClick={() => handleDelete(item, index)}
                                  >
                                    <DeleteIcon />
                                    Delete
                                  </Button>
                                  <Button
                                    className="secondary"
                                    onClick={e => {
                                      e && e.preventDefault();
                                      handleEdit(item);
                                      setItemSelected({
                                        item,
                                        position: index
                                      });
                                    }}
                                  >
                                    <EditIcon />
                                    Edit
                                  </Button>
                                </ButtonGroup>
                              </AccordionDetails>
                            </StyledAccordion>
                          </QuestionContainer>
                        </Box>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <CustomButton
        color="primary"
        startIcon={<Add />}
        onClick={() => {
          setOpenModal(true);
          setIsEdit(false);
          setItemSelected({});
        }}
      >
        Add question
      </CustomButton>
      {openModal && (
        <SurveyModal
          open={openModal}
          onClose={() => {
            setItemSelected({});
            setOpenModal(false);
          }}
          selectedItem={itemSelected}
          typeModal={!isEdit ? 'Create' : 'Edit'}
          onSubmit={changeTableValue}
          setFormValue={setSurveyValue}
          formValue={surveyValue}
          isCreateQues={!isEdit}
        />
      )}
    </>
  );
};

export default CustomSurvey;
