import React, { useState } from 'react';
import {
  withStyles,
  makeStyles,
  Card,
  Grid,
  Divider,
  Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import TabsUI from '@/module/common/componentUI/TabsUI';
import { formatTotalPrice } from '@/module/checkin-patient-information/util';
import { GeneralInforIcon, NoDataDescription } from '@/assets/svg';
import CustomTabs from '@/new-components/CustomTabs';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';

const useStyles = makeStyles({
  rightContainer: {
    marginTop: '20px',
    marginRight: '20px',
    marginBottom: '20px',
    width: '100%',
    overflow: 'auto',

    '& table': {
      minWidth: 'unset'
    }
  },
  noDataTitle: {
    color: '#333333',
    fontFamily: 'Lato',
    fontSize: 18,
    fontWeight: 700,
    marginTop: 16
  },
  noDataText: {
    color: '#666666'
  }
});

const rowStyles = makeStyles({
  gridItems: { display: 'flex' },
  title: {
    margin: 0,
    paddingBottom: 10,
    width: 130,
    verticalAlign: 'top',
    fontSize: 14,
    color: '#666666'
  },
  text: {
    margin: 0,
    paddingBottom: 10,
    paddingRight: 8,
    fontSize: 16,
    verticalAlign: 'top',
    width: 'calc(100% - 130px)' // title length
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 8,
    color: '#666666'
  }
});

const CustomCard = withStyles({
  root: {
    padding: 20,
    boxShadow: 'none',
    borderRadius: 16,
    backgroundColor: props => (props.color ? props.color : '#F6F6F6'),
    width: props => (props.width ? props.width : 'unset')
  }
})(Card);

const RowHeaderItem = ({ title, icon }) => {
  const Icon = icon;
  const classes = rowStyles();
  return (
    <Grid item xs={12} className={classes.title} style={{ fontSize: 20 }}>
      {icon && <Icon className={classes.icon} />}
      <span style={{ verticalAlign: 'middle' }}>{title}</span>
    </Grid>
  );
};

const RowItem = ({ children, title }) => {
  const classes = rowStyles();
  return (
    <Grid className={classes.gridItems} item xs={6}>
      <p className={classes.title}>{title}</p>
      <span className={classes.text}>{children}</span>
    </Grid>
  );
};

const CustomTypography = withStyles({
  root: {
    fontFamily: 'OpenSans',
    fontWeight: 600,
    fontSize: 16
  }
})(Typography);

const GeneralInformation = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      style={{ margin: 0, width: '100%', padding: '8px 8px 0' }}
    >
      <Grid item xs={12}>
        <CustomCard>
          <Grid container>
            <RowHeaderItem
              title="General information"
              icon={GeneralInforIcon}
            />
            <RowItem title="Package name">
              <CustomTooltip content={data?.name || 'None'} />
            </RowItem>
            <RowItem title="Test code">
              <CustomTooltip content={data?.code || 'None'} />
            </RowItem>
            <RowItem title="Service type">
              <CustomTooltip content={data?.packageType || 'None'} />
            </RowItem>
            <RowItem title="Default price">
              {formatTotalPrice(data?.price, '$')}
            </RowItem>
          </Grid>
        </CustomCard>
      </Grid>
      <Grid item xs={12}>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        {data?.description ? (
          <Grid container>
            <Grid item xs={12}>
              <CustomTypography>Details</CustomTypography>
            </Grid>
            <Grid item xs={12}>
              <CustomTypography
                dangerouslySetInnerHTML={{ __html: data?.description }}
              />
            </Grid>
          </Grid>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={350}
            margin="0 auto"
          >
            <NoDataDescription />

            <Typography className={classes.noDataTitle}>
              No description
            </Typography>
            <Typography className={classes.noDataText}>
              You can add a description in Configuration
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const IncludedServices = ({ data }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const renderColumns = key => {
    let columns = [];
    switch (key) {
      case 'Profile':
        columns = [
          {
            label: `${key}`,
            stateValue: 'name'
          },
          {
            label: `${key} code`,
            stateValue: 'testCode'
          },
          {
            label: 'Profiles',
            stateValue: 'profiles'
          },
          {
            label: 'Tests',
            stateValue: 'tests'
          }
        ];
        break;
      case 'Procedure':
      case 'Radiology':
      case 'Vaccinations':
      case 'Review':
        columns = [
          {
            label: `${key}`,
            stateValue: 'name'
          },
          {
            label: 'Station',
            stateValue: 'station'
          }
        ];
        break;
      default:
        columns = [
          {
            label: `${key}`,
            stateValue: 'name',
            width: '33%'
          },
          {
            label: `${key} code`,
            stateValue: 'testCode',
            width: '33%'
          },
          {
            label: 'Station',
            stateValue: 'station',
            width: '33%'
          }
        ];
        break;
    }

    return columns;
  };

  const servicesTabs = [
    { label: 'Tests', key: 'Test' },
    { label: 'Profiles', key: 'Profile' },
    { label: 'Measurements', key: 'Measurement' },
    { label: 'Procedures', key: 'Procedure' },
    { label: 'Radiologies', key: 'Radiology' },
    { label: 'Vaccinations', key: 'Vaccinations' },
    { label: 'Reviews', key: 'Review' }
  ];

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {renderColumns(servicesTabs[currentTab].key).map(item => (
          <TableCell
            key={item.stateValue}
            style={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              width: item.width
            }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
  const renderRow = row => {
    let component;
    switch (servicesTabs[currentTab].key) {
      case 'Profile':
        component = (
          <>
            <TableCell>
              <CustomTooltip content={row.name} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.code} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.totalProfiles} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.totalTests} />
            </TableCell>
          </>
        );
        break;
      case 'Procedure':
      case 'Radiology':
      case 'Vaccinations':
      case 'Review':
        component = (
          <>
            <TableCell>
              <CustomTooltip content={row.name} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.stationName} />
            </TableCell>
          </>
        );
        break;
      default:
        component = (
          <>
            <TableCell>
              <CustomTooltip content={row.name} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.labHeader} />
            </TableCell>
            <TableCell>
              <CustomTooltip content={row.stationName} />
            </TableCell>
          </>
        );
        break;
    }

    return component;
  };

  return (
    <Box display="flex" style={{ height: '100%' }}>
      <CustomTabs
        listTab={servicesTabs}
        value={currentTab}
        onChange={(e, val) => {
          setCurrentTab(val);
        }}
        renderLabel="label"
        renderKey="label"
        orientation="vertical"
      />
      <main className={classes.rightContainer}>
        <CustomTable
          data={
            servicesTabs[currentTab].key === 'Profile'
              ? data?.lstProfiles
              : data?.lstTests?.find(
                  item => item.category === servicesTabs[currentTab]?.label
                )?.items || []
          }
          header={TableHeader}
          totalCols={renderColumns(servicesTabs[currentTab].key)?.length}
          renderRow={(row, i) => renderRow(row)}
          useHelperText
          style={{ height: 'unset' }}
        />
      </main>
    </Box>
  );
};

const PackageDetail = ({ data }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const detailTabs = [
    {
      key: 'general-information',
      label: 'General information',
      body: <GeneralInformation data={data} />
    },
    {
      key: 'included-servicesn',
      label: 'Included services',
      body: <IncludedServices data={data} />
    }
  ];

  return (
    <>
      <TabsUI
        listTab={detailTabs}
        value={currentTab}
        onChange={(e, val) => {
          setCurrentTab(val);
        }}
        renderLabel="label"
        renderKey="label"
        variant="fullWidth"
      />

      <main style={{ height: 600 }}>{detailTabs[currentTab]?.body}</main>
    </>
  );
};

export default PackageDetail;
