export const isUserOnline = (listMember, id, ChannelId) => {
  for (const index in listMember) {
    const member = listMember[index];
    if (member.FirstName) {
      if (id.includes(member.FirstName + ' ' + member.LastName)) return true;
    } else {
      if (id.includes(`${member.FullName},${ChannelId}`)) return true;
    }
  }
  return false;
};

export const isPeerChatting = id =>
  !id.includes('publicRoom') && !id.includes('cohostRoom');
