import studioClassDispatcher from '../actions/index';
import { get } from 'lodash';

const initialState = {
  loading: false,
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  },

  listCategoryStudioClass: [],
  listDuration: [],
  listIntensity: [],
  listAvailableTrainer: [],
  listStudio: []
};

const studioClassReducer = studioClassDispatcher(initialState, {
  [studioClassDispatcher.showHideLoadingAction]: (state, payload) => ({
    loading: payload.loading
  }),
  [studioClassDispatcher.getDurationIntensitySuccess]: (state, payload) => ({
    listDuration: get(payload, 'duration'),
    listIntensity: get(payload, 'intensity')
  }),
  [studioClassDispatcher.getStudioClassListSuccess]: (state, payload) => ({
    list: payload.list,
    paging: {
      ...payload,
      list: undefined
    }
  }),
  [studioClassDispatcher.getCategoryStudioClassSuccess]: (state, payload) => ({
    listCategoryStudioClass: get(payload, 'list')
  }),
  [studioClassDispatcher.getAvailableTrainerSuccess]: (state, payload) => ({
    listAvailableTrainer: get(payload, 'list')
  }),
  [studioClassDispatcher.getStudioSuccess]: (state, payload) => ({
    listStudio: get(payload, 'list')
  })
});

export default studioClassReducer;
