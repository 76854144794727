export const timeSlotColumns = [
  {
    label: 'Start time',
    stateValue: 'startTime'
  },
  {
    label: 'End time',
    stateValue: 'endTime'
  }
];

export const locationTabs = [
  { label: 'Time Slots', key: 'timeSlots' },
  { label: 'Closure Date', key: 'operationDates' }
];
