import React from 'react';
import { startCase } from 'lodash';
import {
  HS_PAXSCREENING_STATUS,
  HS_PAXSCREENING_STATUS_COLORS
} from '../constants';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  chip: {
    minWidth: 82,
    color: props => props.color,
    backgroundColor: props => props.backgroundColor
  }
});

export const StatusComponent = ({ status }) => {
  let displayStatus = HS_PAXSCREENING_STATUS[status];
  let color = HS_PAXSCREENING_STATUS_COLORS[displayStatus]?.txt || '#000';
  let backgroundColor =
    HS_PAXSCREENING_STATUS_COLORS[displayStatus]?.background || '#fff';
  const classes = useStyles({ color, backgroundColor });

  return (
    <Chip
      size="small"
      className={classes.chip}
      label={startCase(displayStatus)}
    />
  );
};
