import Enum from '../../../enum/Enum';

export const GENDER = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Non-specified', value: 'Other' }
];

export const PACKAGE_TYPE = Enum({
  General: 'General',
  Onsite: 'Onsite',
  Offsite: 'Offsite'
});

export const NEW_PACKAGE_TYPE = Enum({
  MMS: 'MMS',
  HBS: 'HBS',
  EHS: 'EHS',
  CHS: 'CHS'
});

export const PACKAGE_STATUS = Enum({
  Active: 'Active',
  InActive: 'InActive'
});

export const testColumn = [
  {
    label: 'Test',
    stateValue: 'test'
  },
  {
    label: 'Test Code',
    stateValue: 'labCode'
  },
  // {
  //   label: 'Minc Code',
  //   stateValue: 'mincCode'
  // },
  {
    label: 'Station',
    stateValue: 'stationName'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const profileColumn = [
  {
    label: 'Profile',
    stateValue: 'name'
  },
  {
    label: 'Profile Code',
    stateValue: 'code'
  },
  {
    label: 'Tests',
    stateValue: 'totalTests'
  },
  {
    label: 'Profiles',
    stateValue: 'totalProfiles'
  },
  {
    label: '',
    stateValue: 'isRequired'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const measurementColumn = [
  {
    label: 'Measurement',
    stateValue: 'name'
  },
  {
    label: 'Station',
    stateValue: 'stationName'
  },
  {
    label: 'Measurement Code',
    stateValue: 'code'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const procedureColumn = [
  {
    label: 'Procedure',
    stateValue: 'name',
    width: 400
  },
  {
    label: 'Station',
    stateValue: 'stationName',
    width: 400
  },
  {
    label: '',
    stateValue: 'isRequired'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const radiologyColumn = [
  {
    label: 'Radiology',
    stateValue: 'name',
    width: 400
  },
  {
    label: 'Station',
    stateValue: 'stationName',
    width: 400
  },
  {
    label: '',
    stateValue: 'isRequired'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const vaccinationColumn = [
  {
    label: 'Vaccination',
    stateValue: 'name'
  },
  {
    label: 'Station',
    stateValue: 'stationName'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const reviewColumn = [
  {
    label: 'Review',
    stateValue: 'name'
  },
  {
    label: 'Station',
    stateValue: 'stationName'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const renderProcedureColumn = [
  {
    label: '',
    stateValue: '',
    align: 'left',
    width: 50
  },
  {
    label: 'Procedure',
    stateValue: 'name',
    align: 'left',
    width: 300
  },
  {
    label: 'Station',
    stateValue: 'stationName',
    align: 'left',
    width: 300
  },
  {
    label: 'Price',
    stateValue: 'price',
    align: 'left'
  }
];

export const renderProcedureColumnForEdit = [
  {
    label: 'Procedure',
    stateValue: 'name',
    width: 400
  },
  {
    label: 'Station',
    stateValue: 'stationName',
    width: 400
  },
  {
    label: '',
    stateValue: 'isRequired'
  }
];

export const renderRadiologyColumnForEdit = [
  {
    label: 'Radiology',
    stateValue: 'name',
    width: 400
  },
  {
    label: 'Station',
    stateValue: 'stationName',
    width: 400
  },
  {
    label: '',
    stateValue: 'isRequired'
  }
];

export const renderProfileColumnForEdit = [
  {
    label: 'Profile',
    stateValue: 'name',
    width: 400
  },
  {
    label: 'Tests',
    stateValue: 'totalTests'
  },
  {
    label: 'Profiles',
    stateValue: 'totalProfiles'
  },
  {
    label: '',
    stateValue: 'isRequired'
  }
];

export const locationColumn = [
  {
    label: 'Location Name',
    stateValue: 'name'
  },
  {
    label: 'Address',
    stateValue: 'address'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const SPECIAL_TAGS_PACKAGE = ['General', 'Male', 'Female'];
