import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import CustomTabs from '@/new-components/CustomTabs';
import ServicesItem from './ServicesItem';
import CustomSurvey from '../../CreateAppointment/ServiceItems/CustomSurvey';

const useStyles = makeStyles({
  leftContainer: {
    height: 'calc(100vh - 210px)'
  },
  rightContainer: {
    width: '100%',
    height: 'calc(100vh - 210px)'
  }
});

const SelectServices = ({
  formValue,
  setFormValue,
  projectId,
  locationId,
  handleChangeData,
  packageDetail,
  setPackageDetail,
  restrictedPackageIds,
  projectCustomFields,
  currentPackage,
  setCurrentPackage,
  dataPackageExisted,
  restrictedTestIds
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const classes = useStyles();
  const TABS = [
    {
      label: 'Package',
      chipLabel: formValue?.lstPackages?.length,
      body: (
        <ServicesItem
          isPackage
          projectId={projectId}
          locationId={locationId}
          dataExist={formValue?.lstPackages}
          handleChangeData={handleChangeData}
          filterKey="lstPackages"
          formValue={formValue}
          setFormValue={setFormValue}
          packageDetail={packageDetail}
          setPackageDetail={setPackageDetail}
          restrictedPackageIds={restrictedPackageIds}
          currentPackage={currentPackage}
          setCurrentPackage={setCurrentPackage}
          dataPackageExisted={dataPackageExisted}
          restrictedTestIds={restrictedTestIds}
        />
      )
    },
    {
      label: 'Profile',
      chipLabel: formValue?.lstProfiles?.length,
      body: (
        <ServicesItem
          isProfile
          projectId={projectId}
          dataExist={formValue.lstProfiles}
          handleChangeData={handleChangeData}
          filterKey="lstProfiles"
        />
      )
    },
    {
      label: 'Measurement',
      chipLabel: formValue?.lstMeasurements?.length,
      body: (
        <ServicesItem
          category="Measurements"
          projectId={projectId}
          dataExist={formValue.lstMeasurements}
          handleChangeData={handleChangeData}
          filterKey="lstMeasurements"
        />
      )
    },
    {
      label: 'Procedure',
      chipLabel: formValue?.lstProcedures?.length,
      body: (
        <ServicesItem
          category="Procedures"
          projectId={projectId}
          locationId={locationId}
          dataExist={formValue.lstProcedures}
          handleChangeData={handleChangeData}
          filterKey="lstProcedures"
        />
      )
    },
    {
      label: 'Radiology',
      chipLabel: formValue?.lstRadiologies?.length,
      body: (
        <ServicesItem
          category="Radiologies"
          projectId={projectId}
          locationId={locationId}
          dataExist={formValue.lstRadiologies}
          handleChangeData={handleChangeData}
          filterKey="lstRadiologies"
        />
      )
    },
    {
      label: 'Vaccination',
      chipLabel: formValue?.lstVaccinations?.length,
      body: (
        <ServicesItem
          category="Vaccinations"
          projectId={projectId}
          dataExist={formValue.lstVaccinations}
          handleChangeData={handleChangeData}
          filterKey="lstVaccinations"
        />
      )
    },
    {
      label: 'Review',
      chipLabel: formValue?.lstReviews?.length,
      body: (
        <ServicesItem
          category="Reviews"
          projectId={projectId}
          dataExist={formValue.lstReviews}
          handleChangeData={handleChangeData}
          filterKey="lstReviews"
        />
      )
    }
    // {
    //   label: 'Custom survey',
    //   chipCustomSurvey: formValue?.lstScreeningFields?.length,
    //   body: (
    //     <CustomSurvey
    //       errors={errors}
    //       setErrors={setErrors}
    //       formValue={formValue}
    //       setFormValue={setFormValue}
    //       dataDefault={projectCustomFields}
    //       dataExist={formValue?.lstScreeningFields}
    //     />
    //   )
    // }
  ];

  return (
    <Box display="flex">
      <CustomTabs
        listTab={TABS}
        value={currentTab}
        onChange={(e, val) => {
          setCurrentTab(val);
        }}
        renderLabel="label"
        renderKey="label"
        orientation="vertical"
        height="calc(100vh - 210px)"
      />

      <main className={classes.rightContainer}>{TABS[currentTab].body}</main>
    </Box>
  );
};

export default SelectServices;
