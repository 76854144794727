import {
  DAYS_OF_WEEK,
  DAYS_OF_WEEK_LIST,
  DAYS_OF_WEEK_FORMAT,
  FORMAT_DATE_TIME,
  MIN_TIME_PICK,
  MAX_TIME_PICK
} from '../constants';

import { OtherDay } from '@/module/setup/createAndEditLocation.styled';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import { cloneDeep, isEmpty, lowerCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DayItem from './DayItem';

const CustomTitleSubLabel = withStyles({
  root: {
    fontWeight: 600,
    fontSize: 16,
    color: '#333333'
  }
})(Typography);

const AvailabilityStep = ({ formValue, setFormValue }) => {
  const [listDaysOfWeek, setListDaysOfWeek] = useState(DAYS_OF_WEEK_LIST);

  const renderDayOfWeek = () => {
    const results = [];
    for (let key in DAYS_OF_WEEK) {
      results.push(key);
    }
    return results;
  };

  const DAY_OF_WEEK = renderDayOfWeek();
  const checkIsSelectAll = () => {
    return (
      DAY_OF_WEEK.length === listDaysOfWeek.filter(d => d?.isActivated).length
    );
  };

  const onSelectAllDayOfWeeks = () => {
    let newList = cloneDeep(listDaysOfWeek);

    if (checkIsSelectAll()) {
      newList = newList.map(item => ({ ...item, isActivated: false }));
    } else {
      newList = newList.map(item => ({ ...item, isActivated: true }));
    }
    setFormValue({ ...formValue, availabilities: newList });
    setListDaysOfWeek(newList);
  };

  // const sortDaysOfWeek = array => {
  //   const sortingArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  //   return array.sort(
  //     (a, b) => sortingArr.indexOf(a.key) - sortingArr.indexOf(b.key)
  //   );
  // };

  const onChangeDayOfWeeks = value => {
    let newList = cloneDeep(listDaysOfWeek);
    newList = newList.map(item =>
      value.key === item.key
        ? { ...item, isActivated: !item.isActivated }
        : item
    );

    setFormValue({ ...formValue, availabilities: newList });
    setListDaysOfWeek(newList);
  };

  const handleChangeTime = (key, dataInx) => (e, value) => {
    let newListDaysOfWeek = cloneDeep(listDaysOfWeek);

    let newValue = '';
    switch (key) {
      case 'startTime':
        newValue = moment(value.value, 'h:mm A');
        break;
      case 'endTime':
        if (
          moment(value.value, 'h:mm A').format(FORMAT_DATE_TIME) ===
          moment(MIN_TIME_PICK).format(FORMAT_DATE_TIME)
        )
          newValue = MAX_TIME_PICK;
        newValue = moment(value.value, 'h:mm A');
        break;

      default:
        newValue = e.target.value;
        break;
    }
    newListDaysOfWeek[dataInx][key] = newValue;
    setFormValue({ ...formValue, availabilities: newListDaysOfWeek });
    setListDaysOfWeek(newListDaysOfWeek);
  };

  useEffect(() => {
    const availabilities = cloneDeep(formValue.availabilities);
    const listDaysOfWeek = availabilities.map(d => ({
      ...d,
      key: DAYS_OF_WEEK_LIST[d.dayOfWeek].key,
      value: DAYS_OF_WEEK_LIST[d.dayOfWeek].value
    }));
    setListDaysOfWeek(listDaysOfWeek);
  }, []);

  return (
    <Box p={2.5}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTitleSubLabel>Select days</CustomTitleSubLabel>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" gridGap={8}>
            <OtherDay
              className={checkIsSelectAll() && 'active'}
              onClick={e => {
                e && e.preventDefault();
                onSelectAllDayOfWeeks();
              }}
            >
              All
            </OtherDay>
            {listDaysOfWeek?.map((item, index) => {
              const indexResults = DAYS_OF_WEEK_FORMAT.find(
                it => it.dayOfWeek === index
              );
              return (
                <OtherDay
                  key={item.key}
                  className={item.isActivated && 'active'}
                  onClick={e => {
                    e && e.preventDefault();
                    onChangeDayOfWeeks(item);
                  }}
                  disabled={lowerCase(item.value) === indexResults.label}
                >
                  {item.key}
                </OtherDay>
              );
            })}
          </Box>
        </Grid>
        {!isEmpty(listDaysOfWeek) &&
          listDaysOfWeek.map((item, inx) => {
            return item.isActivated ? (
              <Grid item xs={12} key={item.key}>
                <DayItem
                  dataIndex={inx}
                  dataItem={item}
                  handleChangeTime={handleChangeTime}
                />
              </Grid>
            ) : null;
          })}
      </Grid>
    </Box>
  );
};

export default AvailabilityStep;
