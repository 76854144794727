import React from 'react';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);
const CustomTitleLabel = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 4,
    color: '#666666'
  }
})(Typography);

const SurveyForm = ({ errors, setFormValue, formValue }) => {
  const onChange = key => e => {
    if (!setFormValue) return;
    setFormValue({
      ...formValue,
      [key]: e.target.value
    });
  };

  return (
    <Box px={2.25} py={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTitleLabel>Survey name</CustomTitleLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter name"
            error={!!errors.name}
            helperText={errors.name}
            value={formValue.name}
            onChange={onChange('name')}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTitleLabel>Description</CustomTitleLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter details"
            error={!!errors.description}
            helperText={errors.description}
            value={formValue.description}
            onChange={onChange('description')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SurveyForm;
