import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Box, withStyles } from '@material-ui/core';
import { Add, CloseIcon } from '@/assets/svg';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { cloneDeep, get, isEmpty } from 'lodash';
import { TYPE_MODAL } from '@/module/common/constants/typeModal';
import AnswerItem from './AnswerItem';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LIST_FIELD, FIELD_ARRAY_VALUE } from '@/module/setup/constants';
import validateData from '@/helpers/validationHelpers/validationSchema';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  text: {
    fontFamily: 'Lato',
    fontWeight: 700
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  }
});

const CustomDialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16
  },
  paperWidthMd: {
    maxWidth: 800
  }
})(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset'
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const CustomDialogContent = withStyles(theme => ({
  root: {
    padding: 24,
    minHeight: 600
  }
}))(DialogContent);

const CustomDialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    justifyContent: 'flex-end'
  }
}))(DialogActions);
const CustomTitleLabel = withStyles(theme => ({
  root: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 4,
    color: '#666666'
  }
}))(Typography);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
      paddingLeft: 0
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    },

    '& p': {
      marginTop: 1
    }
  }
})(TextField);

const CustomBox = withStyles(theme => ({
  root: {
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0
    }
  }
}))(Box);

const ErrorText = withStyles(theme => ({
  root: {
    marginLeft: 14
  }
}))(Typography);

const SurveyModal = ({
  open,
  onClose,
  onSubmit,
  selectedItem,
  typeModal,
  isCreateQues
}) => {
  const [formValue, setFormValue] = useState({ customFieldType: '' });
  const [optionValue, setOptionValue] = useState('');
  const [order, setOrder] = useState([]);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    !isEmpty(selectedItem.item) && setFormValue(cloneDeep(selectedItem.item));
  }, []);

  const switchTypeValue = (oldType, newType) => {
    let result = [];
    if (
      FIELD_ARRAY_VALUE.includes(oldType) &&
      !FIELD_ARRAY_VALUE.includes(newType)
    )
      result = '';
    else if (
      !FIELD_ARRAY_VALUE.includes(oldType) &&
      FIELD_ARRAY_VALUE.includes(newType)
    )
      result = [];

    return result;
  };

  const handleChangeForm = key => e => {
    let value = e.target.value;
    let newFormVal = cloneDeep(formValue);
    //if type is change
    if (key === 'customFieldType') {
      value = LIST_FIELD.find(it => it.key === e.target.value)?.value;
      const result = switchTypeValue(formValue.customFieldType, value);
      newFormVal[key] = value;
      newFormVal.value = result;
      newFormVal.hasOther = false;
      newFormVal.hasAdditional = false;
      newFormVal.additionalNote = '';
    } else {
      newFormVal[key] = value;
    }
    setFormValue(newFormVal);
  };

  const onChangeValue = (action, index) => {
    let newFormValue = cloneDeep(formValue);
    let newVal = reOrderValue(order, newFormValue.value);

    switch (action) {
      case TYPE_MODAL.Create:
        newVal.push(optionValue);
        setOptionValue('');
        setErrors({});
        break;
      case TYPE_MODAL.Delete:
        const i = !isEmpty(order)
          ? order.findIndex(item => item === index)
          : index;
        newVal.splice(i, 1);

        break;

      case TYPE_MODAL.Other:
        newFormValue.hasOther = true;
        newVal.push('Others');
        break;
      default:
        break;
    }
    setFormValue({ ...newFormValue, value: newVal });
    setOrder([]);
  };

  const reOrderValue = (order, originArr) => {
    if (isEmpty(order)) return originArr;
    let newVal = [];
    for (const index in order) {
      newVal[index] = originArr[order[index]];
    }
    return newVal;
  };

  const handleSubmit = async () => {
    try {
      await validateData('projectCategorySchema', formValue, () => {
        let value;
        if (FIELD_ARRAY_VALUE.includes(formValue.customFieldType)) {
          value = formValue?.value.filter(it => !isEmpty(it));
        } else value = [];
        onSubmit(typeModal, { ...formValue, value }, selectedItem.position);
      });
    } catch (err) {
      setErrors(err);
    }
  };

  const changeAnswerOption = (action, index, event, key) => {
    let newFormValue = cloneDeep(formValue);
    let listValue = [...newFormValue.value];
    let value = event.target.value || '';

    switch (action) {
      case TYPE_MODAL.EditAddition:
        newFormValue[key] = event.target.value;
        break;
      case TYPE_MODAL.EditCheck:
        newFormValue[key] = event.target.checked;
        break;
      case TYPE_MODAL.Delete:
        // Check if this item is Other
        if (listValue[index] === 'Others' && newFormValue.hasOther) {
          newFormValue.hasOther = false;
          newFormValue.hasAdditional = false;
          newFormValue.additionalNote = '';
        }
        listValue.splice(index, 1);
        break;
      default:
        const regexNumber = /^[0-9\b]+$/;
        if (newFormValue.customFieldType === 'Rating') {
          value = regexNumber.test(event.target.value)
            ? event.target.value
            : '';
        }
        listValue[index] = value;
        break;
    }

    setFormValue({ ...newFormValue, value: listValue });
  };

  const handleDragEnd = result => {
    if (!result.destination) return;
    const { source, destination } = result;

    const updatedQuestions = Array.from(formValue.value);
    const [removed] = updatedQuestions.splice(source.index, 1);
    updatedQuestions.splice(destination.index, 0, removed);

    setFormValue({ ...formValue, value: updatedQuestions });
  };

  return (
    <CustomDialog
      maxWidth="md"
      fullWidth
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
    >
      <CustomDialogTitle onClose={onClose}>
        {isCreateQues ? 'Add' : 'Edit'} question
      </CustomDialogTitle>
      <Divider light />
      <CustomDialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTitleLabel>Question title</CustomTitleLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Enter here"
              error={!!errors.label}
              helperText={errors.label}
              value={formValue.label || ''}
              onChange={handleChangeForm('label')}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTitleLabel>Description</CustomTitleLabel>
            <CustomTextField
              fullWidth
              variant="outlined"
              placeholder="Enter here"
              error={!!errors.description}
              helperText={errors.description}
              value={formValue.description || ''}
              onChange={handleChangeForm('description')}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTitleLabel style={{ marginBottom: 8 }}>
              Type
            </CustomTitleLabel>
            <CustomSelectContainer
              label="key"
              isFullWidth
              filterValue={
                LIST_FIELD.find(it => it.value === formValue.customFieldType)
                  ?.key || ''
              }
              data={LIST_FIELD}
              renderValue={
                <>
                  {LIST_FIELD.find(it => it.value === formValue.customFieldType)
                    ?.key || 'Select'}
                </>
              }
              height="48px"
              isMutiple={false}
              clearable={false}
              onChange={handleChangeForm('customFieldType')}
            />
            {errors?.customFieldType && (
              <ErrorText variant="caption" color="error">
                {errors?.customFieldType}
              </ErrorText>
            )}
            {errors?.value && (
              <ErrorText variant="caption" color="error">
                {errors?.value}
              </ErrorText>
            )}
          </Grid>
          {FIELD_ARRAY_VALUE.includes(formValue.customFieldType) ? (
            <>
              <Grid item xs={12}>
                <CustomTitleLabel>Answer</CustomTitleLabel>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="answers">
                        {provided => (
                          <div
                            {...provided?.droppableProps}
                            ref={provided.innerRef}
                          >
                            {!isEmpty(formValue.value) &&
                              formValue.value.map((answer, i) => (
                                <Draggable
                                  index={i}
                                  key={`answer-${i}`}
                                  draggableId={`answer-${i}`}
                                >
                                  {provided => (
                                    <CustomBox
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <AnswerItem
                                        onChangeAnswer={changeAnswerOption}
                                        value={answer}
                                        answerPos={i}
                                        typeModal={typeModal}
                                        errors={errors}
                                        hasOther={formValue?.hasOther}
                                        additionalNote={
                                          formValue?.additionalNote
                                        }
                                        hasAdditional={formValue?.hasAdditional}
                                      />
                                    </CustomBox>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      startIcon={<Add />}
                      onClick={() => onChangeValue(TYPE_MODAL.Create)}
                    >
                      Add option
                    </Button>
                    {!formValue.hasOther &&
                      formValue.customFieldType !== 'Rating' && (
                        <>
                          {`  or  `}
                          <Button
                            color="primary"
                            startIcon={<Add />}
                            onClick={() => onChangeValue(TYPE_MODAL.Other)}
                          >
                            Add "Others"
                          </Button>
                        </>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : formValue.customFieldType === 'Text' ? (
            <Grid item xs={12}>
              <CustomTitleLabel style={{ marginBottom: 8 }}>
                Answer
              </CustomTitleLabel>
              <CustomTextField
                fullWidth
                variant="outlined"
                placeholder="Type your answer"
                disabled
                multiline
                rows={3}
              />
            </Grid>
          ) : null}
        </Grid>
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Add
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default SurveyModal;
