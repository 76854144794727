import React, { useState, useEffect } from 'react';
import {
  Paper,
  TableContainer as MUTableContainer,
  Typography,
  makeStyles,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';

import TabsUI from '../common/componentUI/TabsUI';
import GroupSearch from './component/GroupSearch';
import FiltedBodyCell from '../common/component/FiltedBodyCell';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import TableContainer from './component/TableContainer';
import renderColumn from './component/renderColumn';

import { useSelector } from 'react-redux';
import siteClosingReportDispatcher from './action';
import companyDispatcher from '../company/action';
import { SITE_CLOSING_REPORT, SITE_CLOSING_REPORT_TYPE } from './constants';

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%'
  },
  tableContainer: {
    width: '100%',
    maxHeight: 'calc(100vh - 350px)',
    margin: 0,
    padding: 0,
    overflow: 'auto'
  },
  divTxtTable: {
    padding: '16px 0 16px',
    borderBottom: '1px solid #e9e9e9'
  },
  descTxtTable: {
    fontSize: 14,
    textAlign: 'center',
    color: '#8F9BA9',
    margin: 0
  }
});

const SiteClosingReport = () => {
  const { list: data, paging } = useSelector(state => state.siteClosingReport);

  const [hiddenRows, setHiddenRows] = useState({});

  const [currentTab, setCurrentTab] = useState(0);

  const [listLocations, setListLocations] = useState([]);
  const [locationFilter, setLocationFilter] = useState('');
  const [listProjects, setListProjects] = useState([]);
  const [projectFilter, setProjectFilter] = useState('');
  const [date, setDate] = useState(moment());
  const [filterOption, setFilterOption] = useState({});

  const classes = useStyles();
  const tableColumn = renderColumn(
    SITE_CLOSING_REPORT[currentTab].notificationType
  );

  useEffect(() => {
    companyDispatcher.getLocationKeyValue(result => setListLocations(result));
    return () => {
      siteClosingReportDispatcher.resetData();
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(locationFilter)) {
      companyDispatcher.getProjectByLocation([locationFilter], result =>
        setListProjects(result)
      );
    }
  }, [locationFilter]);

  useEffect(() => {
    if (!isEmpty(projectFilter) && !isEmpty(locationFilter))
      setFilterOption({
        projectId: projectFilter,
        locationId: locationFilter,
        date
      });
    else if (!isEmpty(locationFilter)) {
      setFilterOption({
        locationId: locationFilter,
        date
      });
    } else {
      setFilterOption({ date });
    }
  }, [projectFilter, locationFilter, date]);
  useEffect(() => {
    setFilterOption({ date });
  }, []);

  const onResetFilterOps = () => {
    setListProjects([]);
    setLocationFilter('');
    setProjectFilter('');
    setDate(moment());
    setFilterOption([]);
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(({ label, stateValue }) =>
          stateValue ? (
            <TableCellFiltedWrapper
              key={stateValue}
              label={label}
              stateValue={stateValue}
            >
              {label}
            </TableCellFiltedWrapper>
          ) : (
            <TableCell style={{ width: 160 }} />
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderStations = lstStations => {
    if (isEmpty(lstStations)) return;
    const lstStationsName = lstStations.map(it => it.name);
    const strStations = lstStationsName.join(', ');
    const maxLength = 50;
    let shortStrStations = '';
    if (strStations && strStations.length >= maxLength) {
      shortStrStations = lstStationsName[0] + ' ,...';
    }
    return (
      <>
        {shortStrStations ? (
          <Tooltip title={strStations}>
            <p className={classes.content}> {shortStrStations}</p>
          </Tooltip>
        ) : (
          <p className={classes.content}> {strStations}</p>
        )}
      </>
    );
  };

  const renderPhoneNumber = data => {
    const { contact, countryCode } = data;
    if (!contact) return '';
    const phoneNumberObj = parsePhoneNumber(contact.toString(), countryCode);
    if (!phoneNumberObj) return '';
    return phoneNumberObj.format('E.164');
  };

  const renderTableBody = row => (
    <>
      <FiltedBodyCell hidden={hiddenRows.name}>{row.name}</FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.contact}>
        {renderPhoneNumber(row)}
      </FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.email}>{row.email}</FiltedBodyCell>

      {SITE_CLOSING_REPORT[currentTab].notificationType ===
        SITE_CLOSING_REPORT_TYPE.OUTSTANDING_PAYMENT && (
        <FiltedBodyCell hidden={hiddenRows.amount}>
          ${row.amount || 0}
        </FiltedBodyCell>
      )}
      {SITE_CLOSING_REPORT[currentTab].notificationType ===
        SITE_CLOSING_REPORT_TYPE.INCOMPLETESTATION && (
        <FiltedBodyCell hidden={hiddenRows.stations}>
          {renderStations(row.stations)}
        </FiltedBodyCell>
      )}
      <TableCell align="right" style={{ padding: 2 }}>
        <Tooltip title="">
          <IconButton
            onClick={() =>
              siteClosingReportDispatcher.notifyCosingReport(
                row.id,
                SITE_CLOSING_REPORT[currentTab].notificationType
              )
            }
          >
            <Notifications color="primary" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </>
  );

  const renderListTabs = key => (
    <TableContainer
      keyFilter={key}
      header={TableHeader}
      data={data}
      toolbar={false}
      search={false}
      searchKey={''}
      filterOption={filterOption}
      paging={paging}
      query={(...params) => {
        siteClosingReportDispatcher.getData(...params);
      }}
      renderRow={(row, i) => renderTableBody(row)}
    />
  );

  return (
    <Paper>
      <MUTableContainer className={classes.container}>
        <div className="table-container-header">
          <Typography
            style={{
              fontSize: 18,
              fontWeight: 600
            }}
          >
            Closing Report
          </Typography>
        </div>
        <Grid container style={{ padding: '0 20px' }}>
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <GroupSearch
              {...{
                filterOption,
                setFilterOption,
                date,
                setDate,
                listLocations,
                setListProjects,
                locationFilter,
                setLocationFilter,
                listProjects,
                projectFilter,
                setProjectFilter,
                onResetFilterOps
              }}
            />
          </Grid>
        </Grid>
        <div className={classes.tableContainer}>
          <TabsUI
            listTab={SITE_CLOSING_REPORT}
            value={currentTab}
            onChange={(e, val) => {
              setCurrentTab(val);
              setHiddenRows({});
              setFilterOption({ ...filterOption });
              siteClosingReportDispatcher.resetData();
            }}
            renderLabel="label"
            renderKey="label"
          />
          <main className={classes.main}>
            {renderListTabs(SITE_CLOSING_REPORT[currentTab].key)}
          </main>
        </div>
      </MUTableContainer>
    </Paper>
  );
};
export default SiteClosingReport;
