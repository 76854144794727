import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles, withStyles } from '@material-ui/core';
import { parsePhoneNumber } from 'libphonenumber-js';
import { getNationalNumber } from '@/helpers';
import { MincAdmin, ProjectInfoIcon } from '@/assets/svg';

import CustomTooltip, {
  ParagraphWrapped
} from '@/new-components/CustomTooltip';
import moment from 'moment';

const rowStyles = makeStyles({
  title: {
    paddingBottom: 10,
    minWidth: 116,
    verticalAlign: 'top',
    fontWeight: 400,
    color: '#666666'
  },
  text: {
    paddingBottom: 10,
    fontSize: 16,
    verticalAlign: 'top'
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 8,
    color: '#666666',
    width: 24,
    height: 24
  }
});

const useStyles = makeStyles({
  border: {
    height: 1,
    width: '100%',
    margin: '8px 0',
    backgroundImage: 'linear-gradient(to right, #C1C1C1 50%, transparent 50%)',
    backgroundPosition: 'top',
    backgroundSize: '10px 1px',
    backgroundRepeat: 'repeat-x'
  }
});

const CustomCard = withStyles({
  root: {
    padding: 20,
    marginTop: 12,
    boxShadow: 'none',
    borderRadius: 16,
    backgroundColor: props => (props.color ? props.color : '#F6F6F6'),
    width: props => (props.width ? props.width : 'unset')
  }
})(Card);

const RowHeaderItem = ({ title, icon }) => {
  const Icon = icon;
  const classes = rowStyles();
  return (
    <th
      colSpan={2}
      className={classes.title}
      style={{ fontSize: 20, fontWeight: 500 }}
    >
      {icon && <Icon className={classes.icon} />}
      <span style={{ verticalAlign: 'middle', color: '#333333' }}>{title}</span>
    </th>
  );
};

const RowItem = ({ children, title }) => {
  const classes = rowStyles();
  return (
    <tr>
      <td className={classes.title}>{title}</td>
      {children === 'None' ? (
        <td className={classes.text} style={{ color: '#999999' }}>
          <ParagraphWrapped>{children}</ParagraphWrapped>
        </td>
      ) : (
        <td className={classes.text}>
          <CustomTooltip content={children} />
        </td>
      )}
    </tr>
  );
};

const PatientInfoCard = ({ patientInfo, projectInfo, isFollowUp }) => {
  const classes = useStyles();
  return (
    <CustomCard color="#e2f2ffb3" width={360}>
      <table>
        <RowHeaderItem title="Patient info" icon={MincAdmin} />
        <RowItem title="Name">{patientInfo?.fullName || 'None'}</RowItem>
        <RowItem title="ID number">
          {patientInfo?.identityNumber || 'None'}
        </RowItem>
        <RowItem title="Date of birth">
          {patientInfo?.dateOfBirth
            ? moment(patientInfo?.dateOfBirth).format('DD/MM/YYYY')
            : 'None'}
        </RowItem>
        <RowItem title="Ethnic group">
          {patientInfo?.ethnicGroup || 'None'}
        </RowItem>
        <RowItem title="Phone number">
          {patientInfo?.mobile
            ? parsePhoneNumber(
                getNationalNumber(
                  patientInfo?.mobile,
                  patientInfo?.countryCode,
                  true
                )
              ).formatInternational()
            : 'None'}
        </RowItem>
        <RowItem title="Email">{patientInfo?.email || 'None'}</RowItem>
      </table>
      <div className={classes.border}></div>
      <table>
        <RowHeaderItem title="Project" icon={ProjectInfoIcon} />
        <RowItem title="Project code">{projectInfo?.code || 'None'}</RowItem>
        {isFollowUp ? null : (
          <RowItem title="PM code">{projectInfo?.pmCode || 'None'}</RowItem>
        )}
        <RowItem title="Company">{projectInfo?.companyName || 'None'}</RowItem>
        <RowItem title="Staff type">
          {projectInfo?.staffType?.type || 'None'}
        </RowItem>
        <RowItem title="Start date">
          {projectInfo?.startDate
            ? moment(projectInfo?.startDate).format('DD/MM/YYYY')
            : 'None'}
        </RowItem>
        <RowItem title="End date">
          {projectInfo?.endDate
            ? moment(projectInfo?.endDate).format('DD/MM/YYYY')
            : 'None'}
        </RowItem>
      </table>
    </CustomCard>
  );
};

export default PatientInfoCard;
