export const COLUMN_COUNSELLOR_EXECUTIVES = [
  {
    label: 'Name',
    stateValue: 'name',
  },
  {
    label: 'Phone number',
    stateValue: 'phoneNumber',
  },
  {
    label: 'Email',
    stateValue: 'email',
  },
  {
    label: 'Status',
    stateValue: 'status',
  },
  {
    label: 'Action',
    minWidth: 60,
  },
];

export const COLUMN_QUALIFICATION = [
  {
    label: 'Degree',
    stateValue: 'degree',
    width: '30%',
  },
  {
    label: 'School',
    stateValue: 'school',
    width: '30%',
  },
  {
    label: 'Date',
    stateValue: 'date',
  },
  {
    label: '',
    minWidth: 60,
  },
];

export const TYPE_MODAL = {
  Create: 'Create',
  Edit: 'Edit',
};

export const ACTIVE_STATUSES = {
  IN_ACTIVE: 'InActive',
  ACTIVE: 'Active',
  BANNED: 'Banned',
};

export const APPROVE_STATUSES = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECT: 'Reject',
};

export const COUNSELLOR_STATUS = {
  Active: 'Active',
  InActive: 'Inactive',
};

export const COUNSELLOR_STATUS_COLORS = {
  [COUNSELLOR_STATUS.InActive]: {
    txt: '#333333',
    background: '#f1f1f1',
  },
  [COUNSELLOR_STATUS.Active]: {
    txt: '#0B8E5E',
    background: '#15a47029',
  },
};

export const ALL_COUNSELLOR_STATUSES = [
  {
    value: 'Active',
    key: 'Active',
  },
  {
    value: 'Inactive',
    key: 'InActive',
  },
];
