import {
  CreateNewIcon,
  EditIcon,
  ErrorCircleFilled,
} from '../../../assets/svg';
import ConsultDetail from '../../../components/ConsultDetail';
import CustomDateRange from '../../../components/CustomDateRange';
import CustomSelect from '../../../components/CustomSelect';
import MutipleSelection from '../../../components/CustomSelect/MutipleSelection';
import TableContainer from '../../../components/Table/TableContainer';
import {
  ConsultType,
  ConsultStatus,
  ConsultStatusDisplay,
  StatusColor,
} from '../../../enum/ConsultEnum';
import { durationFormatted, momentFormatted } from '../../../helpers';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import { ChipStatus } from '../../common/componentUI/commonStyleComponents';
import caConsultDispatcher from '../action/caConsult';
import ModalAddCoPayment from './ModalAddCoPayment';
import ModalPayment from './ModalPayment';
import { ROLES_CANNOT_REVERT_CONSULT, ROLE_TYPES } from '@/constants/roles';
import customToast from '@/new-components/CustomNotification';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Dialog,
  Typography,
  Checkbox,
  Fab,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import {
  MoreVertRounded,
  ArtTrackOutlined,
  RepeatRounded,
  VisibilityRounded,
  AccountBalanceWalletRounded,
  GetAppRounded,
  Autorenew,
  ErrorOutline,
} from '@material-ui/icons';
import { isEmpty, cloneDeep } from 'lodash';
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const TYPE_MODAL = {
  Create: 'Create',
  Edit: 'Edit',
};

const useStyle = makeStyles({
  txtPayment: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
  },
});

const CAConsult = () => {
  const data = useSelector((state) => state.caConsult.list);
  const paging = useSelector((state) => state.caConsult.paging);
  const loading = useSelector((state) => state.caConsult.loading);

  const userInfo = useSelector((state) => state.auth.userInfo);
  const roleType = useSelector((state) => state.auth.roleType);
  const [selectedItem, setSelectedItem] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, handleDateChange] = useState([null, null]);
  const [statusOpen, setStatusOpen] = useState(false);
  const [typeOpen, setTypeOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({});
  const [detailModal, setDetailModal] = useState(false);
  const [hiddenRows, setHiddenRows] = useState({});
  const [openModalPay, setOpenModalPay] = useState(false);

  const [showModalAddCoPayment, setShowModalAddCoPayment] = useState(false);
  const [typeModal, setTypeModal] = useState(null);

  const classes = useStyle();
  const ConsultTypesByRole = useMemo(() => {
    if (roleType === ROLE_TYPES.CA)
      return ConsultType.filter((i) => i.key !== ConsultType.TeleArt);
    else return ConsultType;
  }, [roleType]);

  const listTableButtons = useMemo(
    () => [
      <Fab
        style={{
          backgroundColor: '#ad5e99',
          color: 'white',
          width: 40,
          height: 40,
          margin: 5,
        }}
        aria-label="export"
        onClick={() => {
          if (data.length === 0) {
            customToast('info', 'No data to export');
          } else {
            caConsultDispatcher.getExportExcel(searchKey, filterOption);
          }
        }}
      >
        <Tooltip title="Export excel">
          <GetAppRounded style={{ fontSize: 18 }} />
        </Tooltip>
      </Fab>,
    ],
    [filterOption, searchKey, data]
  );

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows,
      }}
      {...props}
    />
  );

  const Header = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper
          style={{ paddingLeft: 20 }}
          label="Patient"
          stateValue="patient"
        />
        <TableCellFiltedWrapper
          label="NRIC/FIN/Passport"
          stateValue="patientIdentityNumber"
        />
        <TableCellFiltedWrapper
          label="Doctor Name"
          stateValue="doctorFullName"
        />
        <TableCellFiltedWrapper label="Date" stateValue="consultantDate" />
        <TableCellFiltedWrapper label="Duration" stateValue="duration" />
        <TableCellFiltedWrapper label="Total Cost" stateValue="totalCost" />
        <TableCellFiltedWrapper label="Consult Type" stateValue="consultType" />
        <TableCellFiltedWrapper label="Status" stateValue="status" />
        <TableCellFiltedWrapper
          label="Payment Note"
          stateValue="paymentNote"
        ></TableCellFiltedWrapper>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const handleClick = (item) => (event) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onShowCoPaymentModal = (type) => {
    setShowModalAddCoPayment(true);
    setTypeModal(type);
  };

  const handleUpdateCoPayment = (typeModal, data) => {
    caConsultDispatcher.setState('loading', true);

    let newData = cloneDeep(data);

    newData = {
      ...data,
      coPaymentInvoices: !isEmpty(newData?.coPaymentInvoices)
        ? newData?.coPaymentInvoices
        : [],
    };

    caConsultDispatcher.updateCoPayment({
      id: selectedItem.consultId,
      search: searchKey,
      filterOption,
      data: newData,

      onSuccess: () => {
        customToast(
          'success',
          `${
            typeModal === TYPE_MODAL.Create ? 'Added' : 'Updated'
          } co-payment successfull`
        );
        setShowModalAddCoPayment(false);
      },
    });
  };

  const updateStatusPayment = (data, onDone) => {
    caConsultDispatcher.updateStatusPayment({
      id: selectedItem.consultId,
      search: searchKey,
      filterOption,
      data,
      onDone,
      onSuccess: () => {
        customToast('success', 'Update payment status success');
        setOpenModalPay(false);
      },
    });
  };

  const renderMenuItem = () => {
    if (!selectedItem) return [];
    let options = [
      {
        key: 'view',
        icon: VisibilityRounded,
        label: 'View Details',
        onClick: () => {
          setDetailModal(true);
          handleClose();
        },
      },
    ];

    if (selectedItem.consultStatus === ConsultStatus.ConsultCompleted) {
      const isCreate = selectedItem?.coPaymentCost === null;
      options.push({
        key: 'co-payment',
        icon: isCreate ? CreateNewIcon : EditIcon,
        label: `${isCreate ? 'Add' : 'Edit'} co-payment`,
        style: { width: 24, height: 24 },
        onClick: () => {
          onShowCoPaymentModal(isCreate ? TYPE_MODAL.Create : TYPE_MODAL.Edit);
          handleClose();
        },
      });

      if (isCreate) {
        options.push({
          key: 'processSummary',
          icon: RepeatRounded,
          label: 'Process Summary',
          onClick: () => {
            handleClose();

            caConsultDispatcher.processSummary(
              selectedItem.consultId,
              searchKey,
              filterOption
            );
          },
        });
      } else {
        options.push(
          {
            key: 'revert-co-payment',
            icon: Autorenew,
            label: 'Revert co-payment',
            onClick: () => {
              handleClose();
              caConsultDispatcher.revertCoPayment({
                id: selectedItem.consultId,
                onSuccess: () => {
                  customToast('success', 'Revert co-payment successfully');
                },
                searchKey,
                filterOption,
              });
            },
          },
          {
            key: 'processSummary',
            icon: RepeatRounded,
            label: 'Process Summary',
            onClick: () => {
              handleClose();

              caConsultDispatcher.processSummary(
                selectedItem.consultId,
                searchKey,
                filterOption
              );
            },
          }
        );
      }
    } else if (selectedItem.consultStatus === ConsultStatus.ProcessSummary) {
      options.push({
        key: 'readyToPay',
        icon: AccountBalanceWalletRounded,
        label: 'Ready To Pay',
        onClick: () => {
          handleClose();
          caConsultDispatcher.confirmReadyToPay(
            selectedItem.consultId,
            searchKey,
            filterOption
          );
        },
      });
    }
    // Add payment for CA and system
    // if (
    //   ROLES_CONSULT_TYPE_PAYMENT.includes(roleType) &&
    //   selectedItem.consultStatus === ConsultStatus.ReadyToPay
    // ) {
    //   options.push({
    //     key: 'makePay',
    //     icon: Payment,
    //     label: 'Paid',
    //     onClick: () => {
    //       handleClose();
    //       setOpenModalPay(true);
    //     }
    //   });
    // }

    // MT-514: Add force cancel btn for SuperAdmin
    if (userInfo.roleName === ROLE_TYPES.SUPER_ADMIN) {
      if (
        [
          ConsultStatus.InProgress,
          ConsultStatus.ProcessSummary,
          ConsultStatus.ConsultCompleted,
          ConsultStatus.ReadyToPay,
        ].includes(selectedItem.consultStatus)
      )
        options.push({
          key: 'cancel',
          icon: ErrorCircleFilled,
          label: 'Cancel',
          onClick: () => {
            handleClose();
            caConsultDispatcher.forceCancelConsult(
              selectedItem.consultId,
              searchKey,
              filterOption
            );
          },
        });
    }
    // [clickup-27k1e82] Hide "Revert" button of CA/Swabber.
    if (!ROLES_CANNOT_REVERT_CONSULT.includes(roleType)) {
      if (
        selectedItem.consultStatus === ConsultStatus.ReadyToPay ||
        selectedItem.consultStatus === ConsultStatus.Done ||
        selectedItem.consultStatus === ConsultStatus.ProcessSummary
      ) {
        options.push({
          key: 'revert',
          icon: Autorenew,
          label: 'Revert',
          onClick: () => {
            handleClose();
            caConsultDispatcher.revertStatusToResultCompleted({
              id: selectedItem.consultId,
              onSuccess: () => {
                customToast('success', 'Revert status successfully');
              },
              searchKey,
              filterOption,
            });
          },
        });
      }
    }

    return options.map((d) => {
      let Icon = d.icon;
      return (
        <MenuItem
          key={d.key}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Source Sans Pro',
            color: '#192637',
          }}
          onClick={d.onClick}
        >
          <ListItemIcon style={{ minWidth: 34 }}>
            <Icon style={{ color: '#CACFD3', ...d.style }} />
          </ListItemIcon>
          {d.label}
        </MenuItem>
      );
    });
  };
  return (
    <>
      <Dialog
        open={!!detailModal}
        onClose={() => setDetailModal(false)}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        disableEnforceFocus
      >
        <ConsultDetail
          selectedItem={selectedItem}
          detailModal={detailModal}
          onClose={() => setDetailModal(false)}
          onRefresh={() =>
            caConsultDispatcher.getData(searchKey, paging, filterOption)
          }
          // isDoctor={userInfo.roleName === 'SuperAdmin'} //Reuse CA consult module for SuperAdmin, set isDoctor = true if SuperAdmin to disable all CA funcs in consult detail popup
        />
      </Dialog>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderMenuItem()}
      </Menu>
      <TableContainer
        title="Visit Records"
        query={caConsultDispatcher.getData}
        header={Header}
        paging={paging}
        data={data}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        ToolbarComponent={
          <>
            <CustomDateRange
              selectedDate={selectedDate}
              onChange={(startDate, endDate) => {
                if (startDate && endDate) {
                  handleDateChange([startDate, endDate]);
                  setFilterOption({
                    ...filterOption,
                    fromDate: startDate.toISOString(),
                    toDate: endDate.toISOString(),
                  });
                } else {
                  handleDateChange([null, null]);
                  setFilterOption({
                    ...filterOption,
                    fromDate: null,
                    toDate: null,
                  });
                }
              }}
            />
            <CustomSelect
              onClick={() => {
                setStatusOpen(!statusOpen);
              }}
              clearable={!isEmpty(statusFilter)}
              onClear={() => {
                setStatusFilter([]);
                setFilterOption({
                  ...filterOption,
                  consultStatuses: [],
                });
              }}
              prefixIcon={
                <ArtTrackOutlined
                  style={{
                    fontSize: 20,
                    color: '#E9E9E9',
                    marginRight: 8,
                  }}
                />
              }
              renderValue={
                <>
                  {statusFilter
                    .map((item) => ConsultStatusDisplay[item])
                    .join(', ') || 'Select Status'}
                </>
              }
            >
              <MutipleSelection
                value={statusFilter}
                data={ConsultStatus}
                open={statusOpen}
                onChange={(e) => {
                  setFilterOption({
                    ...filterOption,
                    consultStatuses: e.target.value,
                  });
                  setStatusFilter(e.target.value);
                }}
              >
                {ConsultStatusDisplay.map((item) => (
                  <MenuItem
                    key={item.key || item.id}
                    value={item.value}
                    style={{ fontSize: 14 }}
                  >
                    <Checkbox
                      color="primary"
                      checked={statusFilter.includes(item.value)}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                      }}
                    />
                    {item.key}
                  </MenuItem>
                ))}
              </MutipleSelection>
            </CustomSelect>
            {userInfo.roleName !== 'Swabber' && (
              <CustomSelect
                onClick={() => {
                  setTypeOpen(!typeOpen);
                }}
                clearable={!isEmpty(typeFilter)}
                onClear={() => {
                  setTypeFilter([]);
                  setFilterOption({
                    ...filterOption,
                    consultTypes: [],
                  });
                }}
                prefixIcon={
                  <ArtTrackOutlined
                    style={{
                      fontSize: 20,
                      color: '#E9E9E9',
                      marginRight: 8,
                    }}
                  />
                }
                renderValue={
                  <>
                    {typeFilter.map((type) => ConsultType[type]).join(', ') ||
                      'Select Consult Type'}
                  </>
                }
              >
                <MutipleSelection
                  value={typeFilter}
                  data={ConsultTypesByRole}
                  open={typeOpen}
                  onChange={(e) => {
                    setFilterOption({
                      ...filterOption,
                      consultTypes: e.target.value,
                    });
                    setTypeFilter(e.target.value);
                  }}
                >
                  {ConsultTypesByRole.map((item) => (
                    <MenuItem
                      key={item.key || item.id}
                      value={item.value}
                      style={{ fontSize: 14 }}
                    >
                      <Checkbox
                        color="primary"
                        checked={typeFilter.includes(item.value)}
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                        }}
                      />
                      {item.key}
                    </MenuItem>
                  ))}
                </MutipleSelection>
              </CustomSelect>
            )}
          </>
        }
        renderRow={(d) => (
          <>
            <TableCell
              style={{
                paddingLeft: 20,
              }}
            >
              {!hiddenRows.patient && (
                <>
                  <Typography>{d.patientFullName}</Typography>
                  <Typography style={{ color: '#8F9BA9' }}>
                    {d.patientShortId}
                  </Typography>
                </>
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.patientIdentityNumber && d.patientIdentityNumber}
            </TableCell>
            <TableCell>
              {!hiddenRows.doctorFullName && d.doctorFullName}
            </TableCell>
            <TableCell>
              {!hiddenRows.consultantDate &&
                momentFormatted(d.consultantDate, 'DD MMM, YYYY hh:mmA')}
            </TableCell>
            <TableCell>
              {!hiddenRows.duration &&
                durationFormatted(d.startCallTime, d.endCallTime)}
            </TableCell>
            <TableCell>{!hiddenRows.totalCost && `$${d.totalCost}`}</TableCell>
            <TableCell>
              {!hiddenRows.consultType && (
                <>
                  <Typography>{ConsultType[d.consultType]}</Typography>
                  <Typography style={{ color: '#8F9BA9' }}>
                    {d.consultShortId}
                  </Typography>
                </>
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.status &&
              d.consultStatus === ConsultStatusDisplay.Cancelled ? (
                <ChipStatus color={d?.cancelledReason ? '#db3d49' : '#8F9BA9'}>
                  {ConsultStatusDisplay[d.consultStatus] || 'No data'}
                </ChipStatus>
              ) : (
                <ChipStatus color={StatusColor[d.consultStatus]}>
                  {ConsultStatusDisplay[d.consultStatus] || 'No data'}
                </ChipStatus>
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.paymentNote && (
                <Typography color="error" className={classes.txtPayment}>
                  <ErrorOutline
                    style={
                      isEmpty(d.paymentNote)
                        ? { visibility: 'hidden' }
                        : { visibility: 'visible' }
                    }
                    fontSize="small"
                  />
                  {d.paymentNote}
                </Typography>
              )}
            </TableCell>

            <TableCell align="right">
              <IconButton onClick={handleClick(d)}>
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
        customButtons={listTableButtons}
      />
      {openModalPay && (
        <ModalPayment
          isShowDelivery={selectedItem.hasMedicines}
          code={selectedItem.consultShortId}
          open={true}
          onClose={() => setOpenModalPay(false)}
          updateStatusPayment={updateStatusPayment}
        />
      )}
      {showModalAddCoPayment && (
        <ModalAddCoPayment
          loading={loading}
          onSubmit={handleUpdateCoPayment}
          selectedItem={selectedItem}
          typeModal={typeModal}
          open={showModalAddCoPayment}
          onClose={() => setShowModalAddCoPayment(false)}
        />
      )}
    </>
  );
};

export default CAConsult;
