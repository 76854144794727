import React, { useCallback, useEffect, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';

import { useSelector } from 'react-redux';

import {
  AddIcon,
  CreateNewIcon,
  DeleteModalIcon,
  ExportIcon,
  RemoveIcon
} from '@/assets/svg';
import { urlLabel } from '@/enum/PermissionEnum';
import locationDispatcher from '@/module/setup/action';
import {
  FILTER_STATUSES,
  FILTER_TAGS,
  LOCATION_COLUMN
} from '@/module/setup/constants';
import { Button } from '@material-ui/core';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';

import './components/mainScreen.scss';
import { IOSChip, IOSSwitch } from './components/mainScreen.styled';
import { handleSelectAll } from '@/helpers';
import GroupToolbar from './GroupToolbar';
import CustomTooltip from '@/new-components/CustomTooltip';

const Location = () => {
  const query = locationDispatcher.getLocation;

  const { list: data, paging } = useSelector(state => state.location);

  const [filterOption, setFilterOption] = useState({
    locationTypes: [],
    statuses: []
  });
  const [searchKey, setSearchKey] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [locationSelected, setLocationSelected] = useState(null);
  const history = useHistory();

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    filterOptionData = filterOption
  ) => {
    query(search, pagingData, ...[filterOptionData].filter(i => i));
  };
  const debounceLoadData = useCallback(
    debounce((searchKey, paging, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize
        },
        filterOption
      );
    }, 500),
    []
  );

  const exportExcel = () => {
    if (data.length === 0) {
      return customToast(
        'info',
        'There is no data available to export the excel file'
      );
    }
    locationDispatcher.getExportExcel({ search: searchKey, ...filterOption });
  };

  const onResetFilterOps = () => {
    setSearchKey('');
    setFilterOption({
      statuses: [],
      locationTypes: []
    });
  };

  const onFilterOptionChange = key => (e, newValue) => {
    let value = e.target.value;

    switch (key) {
      case 'statuses':
        value = handleSelectAll(
          value,
          FILTER_STATUSES.map(item => item.key),
          filterOption.statuses
        );
        break;
      case 'locationTypes':
        value = handleSelectAll(
          value,
          FILTER_TAGS.map(item => item.value),
          filterOption.locationTypes
        );
        break;
      default:
        break;
    }
    setFilterOption({
      ...filterOption,
      [key]: value
    });
  };

  const onFilterOptionClear = (key, defaultValue) => () => {
    let clearValue;
    switch (key) {
      case 'statuses':
      case 'locationTypes':
        clearValue = [];
        break;

      default:
        clearValue = defaultValue;
        break;
    }

    setFilterOption({
      ...filterOption,
      [key]: clearValue
    });
  };

  useEffect(() => {
    debounceLoadData(searchKey, paging, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, filterOption]);

  const columns = LOCATION_COLUMN;

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {columns.map(item => (
          <TableCell
            key={item.stateValue || item.label}
            style={{ minWidth: item.minWidth, width: item.width }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (row, setLocationSelected, setDeleteModal) => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.name}></CustomTooltip>
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.address}></CustomTooltip>
        </TableCell>
        <TableCell>
          <div className="mw-120"> {row.postalCode}</div>
        </TableCell>
        <TableCell>
          <IOSChip label={row.locationType} className={row.locationType} />
        </TableCell>
        <TableCell>
          <IOSSwitch
            checked={row.locationStatus === 'Active'}
            style={{ pointerEvents: 'none' }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              history.push({
                pathname: `/${urlLabel.locationManagementEdit}`,
                state: { id: row?.id }
              });
            }}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setLocationSelected(row.id);
              setDeleteModal(true);
            }}
          >
            <RemoveIcon />
          </IconButton>
        </TableCell>
      </>
    );
  };

  const handleDeleteLocation = () => {
    locationDispatcher.deleteLocation(locationSelected, () => {
      locationDispatcher.getLocation();
      setDeleteModal(false);
    });
  };

  return (
    <>
      <ModalConfirmation
        confirmLabel="Delete"
        mainContent="Delete location"
        subContent="Are you sure you want to delete this location ?"
        open={deleteModal}
        confirmColor="red"
        Icon={<DeleteModalIcon />}
        onClose={() => setDeleteModal(false)}
        onClick={handleDeleteLocation}
      />
      <CustomPaperContainer
        header={
          <CustomHeader
            title="Location"
            searchPlaceholder="Search by location name"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                isLocation
                searchKey={searchKey}
                filterOption={filterOption}
                onFilterOptionClear={onFilterOptionClear}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
              />
            )}
            renderButton={() => (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<ExportIcon width={20} height={20} />}
                  onClick={exportExcel}
                >
                  Export
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<CreateNewIcon />}
                  onClick={() =>
                    history.push(`/${urlLabel.locationManagementCreate}`)
                  }
                >
                  Create new
                </Button>
              </>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          header={TableHeader}
          renderRow={(row, i) =>
            renderTableBody(row, setLocationSelected, setDeleteModal)
          }
          paging={paging}
          noDataHelperText="No location"
          noDataHelperSubText="Please create a new location."
          totalCols={columns.length}
          fetchData={fetchData}
        />
      </CustomPaperContainer>
    </>
  );
};

export default Location;
