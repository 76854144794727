import React from 'react';
import 'react-progress-2/main.css';
import Progress from 'react-progress-2';

/**
 * A wrapper of react-progress-2, which is a YouTube style progress bar for ReactJS.
 * See more: https://github.com/milworm/react-progress-2
 */
class TopProgressBar extends React.Component {
  static show() {
    Progress.show();
  }
  static hide() {
    Progress.hide();
  }
  render() {
    return <Progress.Component />;
  }
}
export { TopProgressBar };
