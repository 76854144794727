import { CONTENT_DEFAULT, CONTENT_NO_CORPORATE } from '../constants';
import { Box, Card, Typography, withStyles } from '@material-ui/core';
import React from 'react';

const CustomCard = withStyles({
  root: {
    boxShadow: 'none',
    borderRadius: 10,
    background: 'linear-gradient(to bottom, #FEFFFF 0%, #EDF7FF 100%)',
    width: (props) => (props.width ? props.width : 'unset'),
    zIndex: -1,
    marginTop: 24,
    border: '1px solid #3776AB',
  },
})(Card);
const CustomHeaderBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,

    '& .title': {
      fontWeight: 800,
      fontFamily: 'Lato',
      fontSize: 20,

      color: '#3776AB',
    },
  },
}))(Box);

const CustomBox = withStyles({
  root: {
    '& .divider': {
      height: 1,
      width: '100%',
      marginTop: 20,
      backgroundImage:
        'linear-gradient(to right, #3776ab 50%, transparent 50%)',
      backgroundPosition: 'top',
      backgroundSize: '10px 1px',
      backgroundRepeat: 'repeat-x',
    },

    '&:last-child .divider': {
      display: 'none',
    },
  },
})(Box);

const LabelText = withStyles({
  root: {
    fontSize: 16,
    color: '#333333',
    fontFamily: 'OpenSans',
  },
})(Box);

const ValueText = withStyles({
  root: {
    color: '#333333',
    fontWeight: 600,
    fontFamily: 'OpenSans',
    paddingBottom: 0,
  },
})(Box);

const DividerDashBox = withStyles({
  root: {
    height: 1,
    width: '100%',
    margin: '32px 0 16px',
    backgroundImage:
      'linear-gradient(to right, #3776ab4D 50%, transparent 50%)',
    backgroundPosition: 'top',
    backgroundSize: '10px 1px',
    backgroundRepeat: 'repeat-x',
  },
})(Box);
const RowItem = ({ children, title, pb = 1 }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pb={pb}
    >
      <LabelText>{title}</LabelText>
      {children}
    </Box>
  );
};

const ConsultationFeeCard = ({ valueOption }) => {
  return (
    <CustomCard>
      <CustomHeaderBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={1.625}
        px={2.5}
      >
        <Typography className="title">Consultation fee</Typography>
      </CustomHeaderBox>
      <Box overflow="auto">
        <CustomBox pt={4} p={2.5}>
          {valueOption === 'yes' ? (
            <LabelText>
              Charges will vary according to your corporate plan.
            </LabelText>
          ) : (
            CONTENT_NO_CORPORATE.map((it) => (
              <RowItem title={it.label} pb={0}>
                <ValueText fontSize={20}>{it.value}</ValueText>
              </RowItem>
            ))
          )}

          <DividerDashBox />
          {CONTENT_DEFAULT.map((it) => (
            <RowItem title={it.label}>
              <ValueText fontSize={16}>{it.value}</ValueText>
            </RowItem>
          ))}
        </CustomBox>
      </Box>
    </CustomCard>
  );
};

export default ConsultationFeeCard;
