import React from 'react';
import {
  Checkbox,
  Grid,
  FormControlLabel,
  Card,
  IconButton
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { isEmpty } from 'lodash';
import { formatTotalPrice } from '@/module/checkin-patient-information/util';
import CustomTooltip from '@/new-components/CustomTooltip';
import { EditIcon, UncheckCircleIcon } from '@/assets/svg';
import Box from '@material-ui/core/Box';

const CustomPrice = withStyles({
  root: {
    color: '#333333',
    fontWeight: 600,
    fontSize: 16,
    marginLeft: 'auto'
  }
})(Typography);
const CustomCard = withStyles(theme => ({
  root: {
    marginTop: 8,
    marginBottom: 8,
    padding: '0 16px',
    height: 48,
    width: props => (props.width ? props.width : 'unset'),

    boxShadow: 'none',
    borderRadius: 12,
    backgroundColor: theme.palette.grey.background
  }
}))(Card);

const itemStyle = makeStyles({
  iconUncheck: {
    margin: 1,
    width: 16,
    height: 16,
    color: '#F1F1F1',
    borderRadius: '50%'
  },
  iconChecked: {
    width: 20,
    height: 20,
    color: '#0B8E5E'
  },
  formControl: {
    pointerEvents: 'none'
  }
});

const Subsidy = ({
  subsidy,
  subsidyNote,
  onOpenEditSubsidy,
  isAfterRegistration
}) => {
  return (
    <Box px={2}>
      <CustomCard>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={1.5}
        >
          <Box display="flex" alignItems="center" gridGap={8}>
            <Typography style={{ fontSize: 16, color: '#333333' }}>
              Subsidy <span hidden={!subsidyNote}>({subsidyNote})</span>
            </Typography>
          </Box>

          <CustomPrice>-{formatTotalPrice(subsidy, '$')}</CustomPrice>

          <IconButton
            style={{ padding: 0, marginLeft: '8px' }}
            onClick={onOpenEditSubsidy}
            hidden={isAfterRegistration}
          >
            <EditIcon />
          </IconButton>
        </Box>
      </CustomCard>
    </Box>
  );
};

export default Subsidy;
