import React, { useState } from 'react';
import customToast from '@/new-components/CustomNotification';
import { TextField, Typography } from '@material-ui/core';
import { ENTER_KEY } from '../../../common';
import styled from 'styled-components';
import { StyledButton } from '../../../common/componentUI/commonStyleComponents';
import { EmailIcon, LockIcon, EyeHide, EyeShow } from '../../../../assets/svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import { validateEmail } from '../../../../utils/validation';
import authDispatcher from '../../action/auth';
import { PASSWORD_REGEX } from '@/helpers/validationHelpers/validationSchema';

const AccountInfo = ({
  step,
  setStep,
  email,
  setEmail,
  password,
  setPassword
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toNextStep = () => {
    if (!validateEmail(email)) {
      customToast('error', 'Incorrect email format!');
      return;
    }
    if (confirmPassword !== password) {
      customToast('error', 'Confirm password does not match!');
      return;
    }
    if (!email || !password) {
      customToast('error', 'All fields are required!');
      return;
    }
    if (!PASSWORD_REGEX.test(password)) {
      customToast(
        'error',
        'Password must be 8-32 characters long and includes at least one number, one letter (uppercase or lowercase), and may contain special characters like !@#$%^&*(),_-+='
      );
      return;
    }
    authDispatcher.checkEmailExist(email, 'Doctor', () => setStep(step + 1));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 446,
        width: 380,
        borderRadius: 10,
        backgroundColor: 'white',
        padding: '40px 20px'
      }}
    >
      <Typography
        style={{
          fontSize: 24,
          letterSpacing: 0,
          fontWeight: 600
        }}
      >
        Create A New Account
      </Typography>
      <div
        style={{
          display: 'flex',
          marginTop: 40,
          alignItems: 'center',
          height: 40
        }}
      >
        <EmailIcon style={{ marginRight: 13, marginTop: 30 }} />
        <TextFieldStyle
          name="Email"
          margin="normal"
          label="Email"
          autoComplete="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: 40,
          alignItems: 'center',
          height: 40
        }}
      >
        <LockIcon style={{ marginRight: 13, marginTop: 30 }} />
        <TextFieldStyle
          type={showPassword ? '' : 'password'}
          name="Password"
          margin="normal"
          label="Password"
          autoComplete="password"
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <>
                  <EyeHide
                    style={{ cursor: 'pointer' }}
                    hidden={showPassword}
                    onClick={() => setShowPassword(true)}
                  />
                  <EyeShow
                    style={{ cursor: 'pointer' }}
                    hidden={!showPassword}
                    onClick={() => setShowPassword(false)}
                  />
                </>
              </InputAdornment>
            )
          }}
          onChange={e => setPassword(e.target.value)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: 40,
          marginBottom: 60,
          alignItems: 'center',
          height: 40
        }}
      >
        <LockIcon style={{ marginRight: 13, marginTop: 30 }} />
        <TextFieldStyle
          type={showConfirmPassword ? '' : 'password'}
          name="Confirm Password"
          margin="normal"
          label="Confirm Password"
          autoComplete="password"
          value={confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <>
                  <EyeHide
                    style={{ cursor: 'pointer' }}
                    hidden={showConfirmPassword}
                    onClick={() => setShowConfirmPassword(true)}
                  />
                  <EyeShow
                    style={{ cursor: 'pointer' }}
                    hidden={!showConfirmPassword}
                    onClick={() => setShowConfirmPassword(false)}
                  />
                </>
              </InputAdornment>
            )
          }}
          onChange={e => setConfirmPassword(e.target.value)}
          onKeyDown={e => e.keyCode === ENTER_KEY && toNextStep()}
        />
      </div>
      <StyledButton onClick={toNextStep}>Next</StyledButton>
    </div>
  );
};

const TextFieldStyle = styled(TextField)`
  height: 40px;
  width: 308px;
`;

export default AccountInfo;
