import timeslotTemplateDispatcher from '../action';
import TableTimeSlot from './TableTimeSlot';
import TimeSlotForm from './TimeSlotForm';
import { CloseIcon } from '@/assets/svg';
import validateData from '@/helpers/validationHelpers/validationSchema';
import { MAX_TIME_PICK, MIN_TIME_PICK } from '@/module/setup/constants';
import {
  formatTimeToSendServer,
  parseDataTimeMoment,
} from '@/module/setup/utils';
import customToast from '@/new-components/CustomNotification';
import {
  StepperButton,
  StepperControl,
} from '@/new-components/CustomStepper/styled';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { cloneDeep, findIndex, isBoolean, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    border: '1px solid #f1f1f1',
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#333',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    height: 'calc(100vh - 140px)',
    overflow: 'auto',
  },
  stepsControlWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
}));

const CustomDrawerTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

const ModalContainerForm = ({ onClose, isCreate, selectedItem }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [timeSlots, setTimeSlots] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formValue, setFormValue] = useState({
    startTime: MIN_TIME_PICK,
    endTime: MAX_TIME_PICK,
    quotaBase: '',
    interval: '',
  });

  useEffect(() => {
    if (!isCreate) {
      timeslotTemplateDispatcher.getDetailTemplate(selectedItem.id, (data) => {
        const { timeSlots, startTime, endTime, interval, quotaBase, id, name } =
          data;
        setFormValue({
          name,
          id,
          interval: interval.toString(),
          quotaBase: quotaBase.toString(),
          startTime: parseDataTimeMoment(startTime),
          endTime: parseDataTimeMoment(endTime),
        });
        setTimeSlots(timeSlots);
      });
    }
  }, []);

  useEffect(() => {
    handleUpdateTableTimeSlotData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formValue.startTime,
    formValue.endTime,
    formValue.interval,
    formValue.quotaBase,
  ]);

  const handleUpdateTableTimeSlotData = () => {
    const newTimeSlot = createDataTimeSlot();
    if (!newTimeSlot) {
      return;
    }
    setLoadingTable(true);
    setTimeSlots(newTimeSlot);
    setTimeout(() => {
      setLoadingTable(false);
    }, 250);
  };

  const createDataTimeSlot = () => {
    const { startTime, endTime, interval, quotaBase } = formValue;
    if (!startTime || !endTime || !interval || !quotaBase) return;
    let newTimeSlots = [];
    let newStartTime = { ...startTime };

    while (moment(newStartTime).add(+interval, 'minute').diff(endTime) <= 0) {
      const endTimeAccept = moment(newStartTime).add(+interval, 'minute');
      newTimeSlots.push({
        startTime: newStartTime,
        endTime: endTimeAccept,
        interval,
        quotaBase,
        isActive: true,
      });
      newStartTime = moment(endTimeAccept);
    }

    return newTimeSlots;
  };

  const saveDataTimeSlots = (currentValue, { key, value }) => {
    const newDataTimeSlot = [...timeSlots];
    const index = findIndex(
      timeSlots,
      (slot) =>
        moment(slot.startTime).diff(
          moment(currentValue.startTime),
          'minute'
        ) === 0
    );
    newDataTimeSlot[index] = { ...currentValue, [key]: value };
    setTimeSlots(newDataTimeSlot);
  };

  const onDeleteTemplate = (row, index) => {
    let newDataTimeSlot = cloneDeep(timeSlots).filter((it) => it !== row);
    newDataTimeSlot.splice(index, 1);
    setTimeSlots(newDataTimeSlot);
  };

  const handleSubmit = async () => {
    try {
      await validateData('timeSlotTemplate', formValue, (data) => {
        setLoading(true);
        let params = {
          ...data,
          startTime: formatTimeToSendServer(data.startTime),
          endTime: formatTimeToSendServer(data.endTime),
          timeSlots: timeSlots.map(({ startTime, endTime, ...restParams }) => ({
            startTime: formatTimeToSendServer(startTime),
            endTime: formatTimeToSendServer(endTime),
            ...restParams,
          })),
        };
        onSubmit(params);
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const onSubmit = (data) =>
    isCreate
      ? timeslotTemplateDispatcher.addTemplate(data, () => {
          setLoading(false);
          onClose();
          customToast(
            'success',
            <span>
              <strong style={{ fontWeight: 600 }}>{data.name}</strong> has been
              successfully created.
            </span>,
            'New timeslot template created'
          );
        })
      : timeslotTemplateDispatcher.updateTemplate(selectedItem.id, data, () => {
          setLoading(false);
          onClose();
          customToast(
            'success',
            <span>
              <strong style={{ fontWeight: 600 }}>{selectedItem.name}</strong>{' '}
              has been successfully updated.
            </span>,
            'Timeslot template updated'
          );
        });

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = ['name', 'interval', 'quotaBase'];
    const checkString = filterKeys.filter((key) =>
      Boolean(formValue[key]?.trim())
    );
    return checkString.length !== filterKeys.length;
  }, [formValue]);

  return (
    <LocalizationProvider dateAdapter={MomentAdapter}>
      <Paper square elevation={0}>
        <CustomDrawerTitle onClose={onClose}>
          {isCreate ? 'Create new' : 'Edit'} timeslot template
        </CustomDrawerTitle>
        <main className={classes.mainWrapper}>
          <TimeSlotForm
            minTime={MIN_TIME_PICK}
            maxTime={MAX_TIME_PICK}
            errors={errors}
            formValue={formValue}
            setFormValue={setFormValue}
          />
          <Box px={2} pb={2}>
            <TableTimeSlot
              data={timeSlots}
              loading={loadingTable}
              handleChangeDataTimeSlot={saveDataTimeSlots}
              onDeleteTemplate={onDeleteTemplate}
            />
          </Box>
        </main>
        <StepperControl className={classes.stepsControlWrapper}>
          <StepperButton
            disabled={isEmptyFilterParams || loading}
            onClick={() => handleSubmit(formValue)}
          >
            {isCreate ? 'Create' : 'Save changes'}
          </StepperButton>
        </StepperControl>
      </Paper>
    </LocalizationProvider>
  );
};

export default ModalContainerForm;
