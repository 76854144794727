import { TYPE_MODAL } from '../../constants';
import MultipleImageUpload from '../MultipleImageUpload';
import { CheckboxContainer } from '../styled';
import { ArrowDown } from '@/assets/svg';
import {
  TINY_API_KEY,
  TINY_INIT_CONFIGURATION,
} from '@/constants/editorConfig';
import { CustomAutocomplete } from '@/module/company/component/CreateAndEditCompany/styled';
import CustomCheckbox from '@/new-components/CustomCheckbox';
import {
  Box,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { cloneDeep } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 24,
    color: '#333333',
  },
})(Typography);

const CustomInputLabel = withStyles((theme) => ({
  root: {
    color: '#666666',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}))(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
})(TextField);

const CustomPaper = withStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))(Paper);

const GeneralInformationStep = ({ formValue, typeModal, handleChangeForm }) => {
  const { categories, units } = useSelector((state) => state.ecommerce);

  const customTinyConfiguration = () => {
    let config = cloneDeep(TINY_INIT_CONFIGURATION);
    config.plugins = 'lists link hr paste charmap help';
    return config;
  };

  return (
    <Box px={2.25} py={2.5}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTitleLabel>Product information</CustomTitleLabel>
          <CustomInputLabel>Product image</CustomInputLabel>

          <MultipleImageUpload
            isEdit={typeModal === TYPE_MODAL.Edit}
            formValue={formValue}
            handleChangeForm={handleChangeForm('listDetailImageUrls')}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputLabel>Product name</CustomInputLabel>
          <CustomTextField
            fullWidth={true}
            variant="outlined"
            placeholder="Enter text"
            value={formValue.name || ''}
            onChange={handleChangeForm('name')}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputLabel>Category</CustomInputLabel>
          {categories && categories.length ? (
            <CustomAutocomplete
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option.name || ''}
              options={categories || []}
              value={
                categories.find((test) => test.id === formValue.categoryId) ||
                null
              }
              onChange={handleChangeForm('categoryId')}
              popupIcon={<ArrowDown />}
              renderInput={(params) => (
                <CustomTextField
                  variant="outlined"
                  placeholder="Select"
                  {...params}
                />
              )}
              disableClearable
              PaperComponent={({ children, ...other }) => (
                <CustomPaper {...other}>{children}</CustomPaper>
              )}
            />
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <CustomInputLabel>Unit</CustomInputLabel>
          {units && units.length ? (
            <CustomAutocomplete
              getOptionSelected={(option, value) => option === value}
              options={units || []}
              value={formValue.unit || null}
              onChange={handleChangeForm('unit')}
              popupIcon={<ArrowDown />}
              renderInput={(params) => (
                <CustomTextField
                  variant="outlined"
                  placeholder="Select"
                  {...params}
                />
              )}
              disableClearable
              PaperComponent={({ children, ...other }) => (
                <CustomPaper {...other}>{children}</CustomPaper>
              )}
            />
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <CustomInputLabel>Description</CustomInputLabel>
          <Editor
            apiKey={TINY_API_KEY}
            init={customTinyConfiguration()}
            value={formValue?.description || ''}
            onEditorChange={handleChangeForm('description')}
            cloudChannel="stable"
            placeholder="Enter text"
          />
          <CheckboxContainer style={{ marginTop: '20px' }}>
            <CustomCheckbox
              checked={formValue.requiredConsultation}
              onChange={handleChangeForm('requiredConsultation')}
            />
            Require doctor consultation
          </CheckboxContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralInformationStep;
