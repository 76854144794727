import { synthesize } from 'redux-dispatcher';
import moment from 'moment';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  saveDataById: data => ({ data }),
  getDataById: id => async ({ Api }) => {
    try {
      const response = await Api.get(`Promotions/${id}`);
      if (response.status === 200) {
        dispatcher.saveDataById(response.result);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  },
  getData: (search, paging, filterOption = {}) => async ({ Api, getState }) => {
    let pagingData = paging || getState().promotion.paging;
    let { result, status } = await Api.get('Promotions', {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption
    });
    status === 200 && dispatcher.getDataSuccess(result);
  },
  editData: (data, searchKey, callback, onFinish, id) => async ({
    Api,
    customToast,
    getState
  }) => {
    const { startDate, endDate, ...restparams } = data;
    const body = {
      ...restparams,
      startDate: moment(startDate)
        .startOf('day')
        .format(),
      endDate: moment(endDate)
        .endOf('day')
        .format(),
      id
    };

    let response;
    if (id) {
      response = await Api.put(`Promotions/${id}`, body);
    } else {
      response = await Api.post('Promotions', body);
    }

    if (response.status === 200) {
      let { paging } = getState().userManagement;
      dispatcher.getData(searchKey, paging);
      customToast('success', 'Success');
      callback();
    }
    onFinish();
  },
  deleteData: (id, searchKey, filterOption = {}) => async ({
    Api,
    customToast,
    getState
  }) => {
    const response = await Api.delete(`Promotions/${id}`);
    if (response.status === 200) {
      let { paging } = getState().trainerManagement;
      dispatcher.getData(searchKey, paging, filterOption);
      customToast('success', 'Success');
    }
  }
};

const dispatcher = synthesize('promotion', mapDispatchToAC);
export default dispatcher;
