/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import customToast from '@/new-components/CustomNotification';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Progress from 'react-progress-2';

import LoadingButton from '../../../../components/LoadingButton';
import { getNumberInfo } from '../../../../helpers';
import { BACK_KEY } from '../../../common';
import authDispatcher from '../../action/auth';

const VerifyOTP = ({
  didSendOTP,
  phone,
  countryCode,
  reSendOTP,
  doRegister,
  loading
}) => {
  const [firstChar, setFirstChar] = useState(null);
  const [secondChar, setSecondChar] = useState(null);
  const [thirdChar, setThirdChar] = useState(null);
  const [fourthChar, setFourthChar] = useState(null);
  const [fifthChar, setFifthChar] = useState(null);
  const [sixthChar, setSixthChar] = useState(null);
  const [showLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isInit()) document.getElementById('first-char').focus();
    else if (firstChar) document.getElementById('second-char').focus();
  }, [firstChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (secondChar) document.getElementById('third-char').focus();
    else document.getElementById('first-char').focus();
  }, [secondChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (thirdChar) document.getElementById('fourth-char').focus();
    else document.getElementById('second-char').focus();
  }, [thirdChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (fourthChar) document.getElementById('fifth-char').focus();
    else document.getElementById('third-char').focus();
  }, [fourthChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (fifthChar) document.getElementById('sixth-char').focus();
    else document.getElementById('fourth-char').focus();
  }, [fifthChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (!sixthChar) document.getElementById('fifth-char').focus();
  }, [sixthChar]);

  const isInit = () =>
    !(
      firstChar ||
      secondChar ||
      thirdChar ||
      fourthChar ||
      fifthChar ||
      sixthChar
    );

  const verifyOTP = () => {
    Progress.show();
    if (!didSendOTP) return;
    setLoading(true);
    const inputOTP = `${firstChar}${secondChar}${thirdChar}${fourthChar}${fifthChar}${sixthChar}`;
    window.confirmationResult
      .confirm(inputOTP)
      .then(() => {
        Progress.hide();
        setLoading(false);
        doRegister();
      })
      .catch(() => {
        Progress.hide();
        setLoading(false);
        customToast('error', 'Wrong OTP');
        resetInput();
      });

    // Twilio otp
    // authDispatcher.verifyOTP(
    //   {
    //     otpPhoneNumber: getNumberInfo(phone, countryCode)[1],
    //     otp: inputOTP
    //   },
    //   result => {
    //     if (result) {
    //       Progress.hide();
    //       setLoading(false);
    //       doRegister();
    //     } else {
    //       Progress.hide();
    //       setLoading(false);
    //       customToast('error','Wrong OTP');
    //       resetInput();
    //     }
    //   }
    // );
  };

  const resetInput = () => {
    setFirstChar('');
    setSecondChar('');
    setThirdChar('');
    setFourthChar('');
    setFifthChar('');
    setSixthChar('');
    document.getElementById('first-char').focus();
  };

  const onChangeValue = setState => event => {
    if (event.target.value.length <= 1) setState(event.target.value);
    else setState(event.target.value.substring(1));
  };

  const onBackKey = setState => e => {
    if (!e.target.value) setState(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 395,
        width: 380,
        borderRadius: 10,
        backgroundColor: 'white',
        padding: '40px 20px'
      }}
    >
      <Typography
        style={{
          fontSize: 24,
          letterSpacing: 0,
          fontWeight: 600
        }}
      >
        OTP Verification
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
        <Typography
          style={{
            fontSize: 14,
            letterSpacing: 0,
            color: '#8F9BA9',
            marginRight: 5
          }}
        >
          Enter the OTP code that was sent to
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            letterSpacing: 0,
            color: '#192637',
            fontWeight: 600
          }}
        >
          {getNumberInfo(phone, countryCode)[1]}
        </Typography>
      </div>
      <div style={{ marginTop: 40, display: 'flex', alignItems: 'center' }}>
        <TextField
          id="first-char"
          value={firstChar || ''}
          onChange={onChangeValue(setFirstChar)}
          inputProps={{
            style: {
              fontSize: 24,
              fontWeight: 600,
              width: 40,
              textAlign: 'center',
              marginLeft: 0
            }
          }}
        />
        <TextField
          id="second-char"
          value={secondChar || ''}
          style={{ marginLeft: 10 }}
          onKeyUp={e => e.keyCode === BACK_KEY && onBackKey(setSecondChar)(e)}
          onChange={onChangeValue(setSecondChar)}
          inputProps={{
            style: {
              fontSize: 24,
              fontWeight: 600,
              width: 40,
              textAlign: 'center',
              marginLeft: 0
            }
          }}
        />
        <TextField
          id="third-char"
          value={thirdChar || ''}
          style={{ marginLeft: 10 }}
          onKeyUp={e => e.keyCode === BACK_KEY && onBackKey(setThirdChar)(e)}
          onChange={onChangeValue(setThirdChar)}
          inputProps={{
            style: {
              fontSize: 24,
              fontWeight: 600,
              width: 40,
              textAlign: 'center',
              marginLeft: 0
            }
          }}
        />
        <TextField
          id="fourth-char"
          value={fourthChar || ''}
          style={{ marginLeft: 10 }}
          onKeyUp={e => e.keyCode === BACK_KEY && onBackKey(setFourthChar)(e)}
          onChange={onChangeValue(setFourthChar)}
          inputProps={{
            style: {
              fontSize: 24,
              fontWeight: 600,
              width: 40,
              textAlign: 'center',
              marginLeft: 0
            }
          }}
        />
        <TextField
          id="fifth-char"
          value={fifthChar || ''}
          style={{ marginLeft: 10 }}
          onKeyUp={e => e.keyCode === BACK_KEY && onBackKey(setFifthChar)(e)}
          onChange={onChangeValue(setFifthChar)}
          inputProps={{
            style: {
              fontSize: 24,
              fontWeight: 600,
              width: 40,
              textAlign: 'center',
              marginLeft: 0
            }
          }}
        />
        <TextField
          id="sixth-char"
          value={sixthChar || ''}
          onKeyUp={e => e.keyCode === BACK_KEY && onBackKey(setSixthChar)(e)}
          style={{ marginLeft: 10 }}
          onChange={onChangeValue(setSixthChar)}
          inputProps={{
            style: {
              fontSize: 24,
              fontWeight: 600,
              width: 40,
              textAlign: 'center',
              marginLeft: 0
            }
          }}
        />
      </div>
      <div style={{ display: 'flex', marginTop: 20 }}>
        <Typography
          style={{
            fontSize: 14,
            letterSpacing: 0,
            color: '#8F9BA9',
            marginRight: 7
          }}
        >
          Didn't receive OTP?
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            letterSpacing: 0,
            color: '#AD5E99',
            fontWeight: 600,
            cursor: 'pointer'
          }}
          onClick={() => {
            reSendOTP();
            resetInput();
          }}
        >
          Resend
        </Typography>
      </div>
      <LoadingButton
        key={didSendOTP}
        style={{ marginTop: 60 }}
        onClick={verifyOTP}
        showLoader={showLoading || loading}
        disabled={
          !(
            firstChar &&
            secondChar &&
            thirdChar &&
            fourthChar &&
            fifthChar &&
            sixthChar
          )
        }
      >
        Verify
      </LoadingButton>
    </div>
  );
};

export default VerifyOTP;
