import {
  IconGroup,
  SelectContainer,
  SelectContent,
  SelectRenderValue,
  StyledArrowDown,
} from './CustomeSelect.styled';
import { CloseCircleFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';

const CustomSelect = ({
  children,
  onClick,
  clearable,
  onClear,
  renderValue,
  prefixIcon,
  suffixIcon,
  isFullWidth,
  width = 300,
  arrowStyle,
  isShowArrow,
  padding,
  height,
  disabled,
  colorInput = '#999999',
  placeholder,
  ...rest
}) => {
  return (
    <SelectContainer
      onClick={onClick}
      {...rest}
      height={height}
      className={disabled && 'disabled'}
    >
      {renderValue === placeholder ? (
        <SelectContent color={'#999999'}>
          {prefixIcon}
          <SelectRenderValue>{placeholder}</SelectRenderValue>
          {children}
        </SelectContent>
      ) : (
        <SelectContent color={clearable ? '#333' : colorInput}>
          {prefixIcon}
          <SelectRenderValue>{renderValue}</SelectRenderValue>
          {children}
        </SelectContent>
      )}

      <IconGroup>
        {clearable && (
          <CloseCircleFilled
            className="ant-input-clear-icon"
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
          />
        )}
        {isShowArrow && <StyledArrowDown style={arrowStyle} />}
        {suffixIcon}
      </IconGroup>
    </SelectContainer>
  );
};

CustomSelect.propTypes = {
  isFullWidth: PropTypes.bool,
  colorBorderBottomInput: PropTypes.string,
};

CustomSelect.defaultProps = {
  isFullWidth: false,
  colorBorderBottomInput: '#E9E9E9',
};

export default CustomSelect;
