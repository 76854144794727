import { synthesize } from 'redux-dispatcher';
import { uploadImage } from '../../../firebase';
import { get, isArray } from 'lodash';
import customToast from '@/new-components/CustomNotification';
import { createDownloadableLink } from '../../../helpers';

const mapDispatchToAC = {
  showHideLoadingAction: payload => payload,

  sendMessageSuccess: payload => payload,
  getCategoryLivestream: () => async ({ Api }) => {
    const { result, status } = await Api.post(`Categories/filter`, {});
    status === 200 && livestreamDispatcher.getCategoryLivestreamSuccess(result);
  },
  getCategoryLivestreamSuccess: payload => payload,
  getListSessionDetailSuccess: payload => payload,
  resetListSessionDetail: () => () => {
    livestreamDispatcher.getListSessionDetailSuccess({});
  },
  getLivestreamList: (
    search,
    paging,
    sortOption = {},
    filterOption = {}
  ) => async ({ Api, getState }) => {
    const pagingData = paging || getState().livestream.paging;
    const { pageIndex, pageSize } = pagingData;

    const { result, status } = await Api.post(`Workouts/filter`, {
      pageIndex,
      pageSize,
      search,
      sort: sortOption,
      ...filterOption
    });
    status === 200 && livestreamDispatcher.getLivestreamListSuccess(result);
  },
  getLivestreamListSuccess: payload => payload,

  addLivestream: (payload, callbackSuccess) => async ({ Api, customToast }) => {
    let body = { ...payload, price: 0, workoutType: 'Livestream' };
    livestreamDispatcher.showHideLoadingAction({ loading: true });
    if (payload.thumbnail?.constructor === File) {
      let storageUrl = await uploadImage(payload.thumbnail);
      body.thumbnail = storageUrl;
    }

    const { result, status } = await Api.post(`Livestreams/live-stream`, body);
    livestreamDispatcher.showHideLoadingAction({ loading: false });
    if (status === 200) {
      callbackSuccess(result);
      return customToast('success', 'Success');
    }
  },

  editLivestream: (payload, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    livestreamDispatcher.showHideLoadingAction({ loading: true });
    const { data, id } = payload;
    let body = { ...data };
    if (data.thumbnail?.constructor === File) {
      let storageUrl = await uploadImage(data.thumbnail);
      body.thumbnail = storageUrl;
    }

    const { result, status } = await Api.put(
      `Workouts/live-stream/${id}`,
      body
    );
    livestreamDispatcher.showHideLoadingAction({ loading: false });
    if (status === 200) {
      callbackSuccess(result);
      return customToast('success', 'Success');
    }
  },
  editLivestreamSuccess: payload => payload,

  deleteLivestream: (workoutId, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    const { status } = await Api.delete(`Workouts/${workoutId}`);
    if (status === 200) {
      customToast('success', 'Deleted successfully');
      callbackSuccess();
    }
  },

  getListTrainer: () => async ({ Api }) => {
    const { result, status } = await Api.get(`Workouts/trainer`);
    status === 200 && livestreamDispatcher.getListTrainerSuccess(result);
  },
  getListTrainerSuccess: payload => payload,

  getLivestreamByID: (id, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.get(`Workouts/${id}`);
    status === 200 && callbackSuccess(result);
  },

  getListManager: () => async ({ Api }) => {
    const { result, status } = await Api.post(`Users/filter`, {
      roleType: 'Manager'
    });
    status === 200 && livestreamDispatcher.getListManagerSuccess(result);
  },
  getListHost: () => async ({ Api }) => {
    const { result, status } = await Api.get(`Workouts/manager`);
    status === 200 && livestreamDispatcher.getListManagerSuccess(result);
  },
  getListManagerSuccess: payload => payload,
  memberJoined: member => ({ getState }) => {
    if (member === null) return;
    const userId = get(getState().auth, 'userInfo.id');
    userId !== get(member, 'Id') &&
      livestreamDispatcher.memberJoinedSuccess(
        isArray(member) ? member.filter(i => i && i.Id !== userId) : [member]
      );
  },
  memberJoinedSuccess: member => ({ member }),

  memberLeft: member => ({ member }),

  clearMember: payload => ({ payload }),

  getExportExcel: params => async ({ Api }) => {
    let { result, status } = await Api.post(
      `WorkoutReports/livestream-csv`,
      params
    );
    status === 200 && createDownloadableLink(result);
  },
  endForceLiveStream: ({ livestreamId, search, paging }) => async ({ Api }) => {
    try {
      let { status } = await Api.put(`Livestreams/${livestreamId}/force-end`);

      if (status === 200) {
        livestreamDispatcher.getLivestreamList(search, paging);
        customToast('success', 'End livestream successful');
      } else {
        customToast('error', 'End livestream failure');
      }
    } catch (error) {
      customToast('error', 'End livestream failure');
    }
  },
  getListDetailSession: ({ id, paging, search, dir, by }) => async ({
    Api
  }) => {
    try {
      const { status, result } = await Api.get(`WorkoutReports/${id}`, {
        pageIndex: paging.pageIndex,
        pageSize: paging.pageSize,
        search,
        dir,
        by
      });
      status === 200 &&
        livestreamDispatcher.getListSessionDetailSuccess(result);
    } catch (error) {
      customToast('error', 'End livestream failure');
    }
  },
  getExportDetailByID: (id, filterOptions) => async ({ Api }) => {
    let { result, status } = await Api.get(
      `WorkoutReports/${id}/livestream-details-csv`,
      filterOptions
    );
    status === 200 && createDownloadableLink(result, `WorkoutReport-${id}`);
  }
};

const livestreamDispatcher = synthesize('livestream', mapDispatchToAC);
export default livestreamDispatcher;
