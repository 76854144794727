import dispatcher from '../action';

const initialState = {};

const reducer = dispatcher(initialState, {
  [dispatcher.setState]: (_, payload) => {
    const { name, value } = payload;
    return {
      [name]: value
    };
  }
});

export default reducer;
