import React, { useState } from 'react';
import { PaperWrapped, HeaderWrapped } from './styled';
import { Typography, Dialog } from '@material-ui/core';
import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import { FILE_ACCEPTED } from '../constants';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { dummyRequest } from '../utils';
import { isEmpty } from 'lodash';
import labResultDispatcher from '../action';
import UploadProgress from './UploadProgress';

const LabResult = () => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  const handleUploadFiles = () => {
    if (isEmpty(files)) return setError('Files must not be empty');

    setShowProgress(true);
  };

  const closeProgress = status => {
    setShowProgress(false);
    labResultDispatcher.updateProgress(0);
    if (status === 200) {
      setFiles([]);
    }
  };

  const handleChangeFiles = e => {
    setFiles(e.fileList);
  };

  const handleClearFiles = () => setFiles([]);

  return (
    <PaperWrapped>
      <HeaderWrapped className="no-tabs-header">
        <Typography className="title">Upload result</Typography>
        <div className="personal-btn">
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={handleClearFiles}
          >
            Clear
          </ButtonEnhance>
          <ButtonEnhance onClick={handleUploadFiles}>Upload</ButtonEnhance>
        </div>
      </HeaderWrapped>
      <Upload.Dragger
        name="excelFiles"
        onChange={handleChangeFiles}
        customRequest={dummyRequest}
        showUploadList={false}
        accept={FILE_ACCEPTED}
        fileList={files}
        multiple
      >
        <div className="upload-btn">
          <div className="group">
            <InboxOutlined />
            <div className="text">
              {files.length === 0
                ? 'Click or drag file to this area to upload'
                : `${files.length} item selected`}
            </div>
          </div>
        </div>
      </Upload.Dragger>
      {error && (
        <Typography style={{ marginLeft: 10 }} color="error">
          {error}
        </Typography>
      )}
      <Dialog maxWidth="md" open={showProgress} disableEnforceFocus>
        <UploadProgress
          onClose={closeProgress}
          showProgress={showProgress}
          files={files}
        />
      </Dialog>
    </PaperWrapped>
  );
};

export default LabResult;
