import counsellorsEXManagementDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0,
  },
};

const counsellorsEXReducer = counsellorsEXManagementDispatcher(initialState, {
  [counsellorsEXManagementDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined,
    },
  }),
  [counsellorsEXManagementDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value,
  }),
  [counsellorsEXManagementDispatcher.resetData]: (state, _) => ({
    ...initialState,
  }),
});

export default counsellorsEXReducer;
