import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Close as CloseIcon } from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import CustomButton from '@/new-components/CustomButton';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey.subText
  },
  text: {
    fontWeight: 600
  }
});

const CustomDialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 400
  }
})(Dialog);

const CustomDialogContent = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end'
  }
})(DialogActions);

const CustomInputLabel = withStyles(theme => ({
  root: {
    color: theme.palette.grey.mainText,
    fontWeight: 600,
    marginBottom: theme.spacing(1)
  }
}))(InputLabel);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiInputBase-input': {
      marginLeft: 0,
      paddingTop: '14px',
      paddingBottom: '14px'
    },

    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 6
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: 8
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const Modes = {
  Add: 'Add',
  Edit: 'Edit'
};

const ModalAddOrEditSubsidy = ({
  subsidy,
  subsidyNote,
  subsidyMode,
  open,
  onClose,
  onSubmit
}) => {
  const [localSubsidy, setLocalSubsidy] = useState(subsidy || 0);
  const [localSubsidyNote, setLocalSubsidyNote] = useState(subsidyNote);

  const handleChangeLocalSubsidy = event => {
    const value = event.target.value;

    // Regex test number contain decimal. Maximum length number of decimal is 2
    const regexNumber = /^([\d]{0,9})(\.[\d]{1,2})?$/g;

    if (regexNumber.test(value)) {
      const newLocalSubsidy = parseFloat(value);
      setLocalSubsidy(Number.isNaN(newLocalSubsidy) ? value : newLocalSubsidy);
    }
  };

  const error = Number.isNaN(localSubsidy);

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
    >
      <CustomDialogTitle onClose={onClose}>
        {subsidyMode === Modes.Add ? 'Add subsidy' : 'Edit subsidy'}
      </CustomDialogTitle>
      <CustomDialogContent dividers>
        <CustomInputLabel>Value</CustomInputLabel>
        <CustomTextField
          type="number"
          variant="outlined"
          value={localSubsidy}
          onChange={handleChangeLocalSubsidy}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />

        <CustomInputLabel style={{ marginTop: '16px' }}>Note</CustomInputLabel>
        <CustomTextField
          type="text"
          variant="outlined"
          value={localSubsidyNote}
          onChange={event => setLocalSubsidyNote(event.target.value)}
          placeholder="Enter details"
        />
      </CustomDialogContent>

      <CustomDialogActions>
        <CustomButton
          type="button"
          variant="contained"
          color="error"
          onClick={() => onSubmit(null, '')}
          hidden={subsidyMode === Modes.Add}
        >
          Delete
        </CustomButton>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={error}
          onClick={() => {
            if (localSubsidy) {
              return onSubmit(parseFloat(localSubsidy), localSubsidyNote);
            } else {
              return onSubmit(0, localSubsidyNote);
            }
          }}
        >
          {subsidyMode === Modes.Add ? 'Add' : 'Save'}
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

ModalAddOrEditSubsidy.propTypes = {
  subsidy: PropTypes.number,
  subsidyMode: PropTypes.string,
  subsidyNote: PropTypes.string,
  subTotal: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

ModalAddOrEditSubsidy.defaultProps = {
  subsidy: null,
  subsidyMode: Modes.Add,
  subTotal: 0,
  open: false,
  onClose: () => console.warn('Not implemented yet'),
  onSubmit: () => console.warn('Not implemented yet')
};

export default ModalAddOrEditSubsidy;
