const { isEmpty } = require('lodash');

export const formatData = data => {
  let { tests } = data;
  let lstTests,
    lstMeasurements,
    lstProcedures,
    lstRadiologies,
    lstVaccinations,
    lstReviews;

  lstTests = lstMeasurements = lstProcedures = lstRadiologies = lstVaccinations = lstReviews = [];
  if (isEmpty(tests))
    return {
      ...data,
      lstTests,
      lstMeasurements,
      lstProcedures,
      lstRadiologies,
      lstVaccinations,
      lstReviews
    };

  lstMeasurements = tests.find(i => i.category === 'Measurements')?.items || [];
  lstProcedures = tests.find(i => i.category === 'Procedures')?.items || [];
  lstRadiologies = tests.find(i => i.category === 'Radiologies')?.items || [];
  lstVaccinations = tests.find(i => i.category === 'Vaccinations')?.items || [];
  lstReviews = tests.find(i => i.category === 'Reviews')?.items || [];
  lstTests = tests.find(i => i.category === 'Tests')?.items || [];

  return {
    ...data,
    lstTests,
    lstMeasurements,
    lstProcedures,
    lstRadiologies,
    lstVaccinations,
    lstReviews
  };
};

export const refactorListData = data => {
  const {
    lstTests,
    lstMeasurements,
    lstProcedures,
    lstRadiologies,
    lstVaccinations,
    lstReviews
  } = data;
  let listTotalTests = [
    ...lstTests,
    ...lstMeasurements,
    ...lstProcedures,
    ...lstRadiologies,
    ...lstVaccinations,
    ...lstReviews
  ];
  let testIds = listTotalTests.map(i => i.id);
  return {
    testIds
  };
};
