import React from 'react';
import classNames from 'classnames';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar, Typography } from '@material-ui/core';

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex'
  },
  title: {
    flex: '0 0 auto'
  }
});

class ToolbarUI extends React.Component {
  render() {
    const { title, numSelected, selectedTool, classes } = this.props;

    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} Item Selected
            </Typography>
          ) : (
            <Typography
              variant="h4"
              id="tableTitle"
              style={{ fontWeight: 'bold' }}
            >
              {title}
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {numSelected > 0 ? selectedTool : this.props.children}
        </div>
      </Toolbar>
    );
  }
}

export default withStyles(toolbarStyles)(ToolbarUI);
