import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { NoDataHelperWhiteIcon } from '@/assets/svg';
import moment from 'moment';
import MinmedLogo from '@/assets/image/minmed-logo.png';
import { HS_PAXSCREENING_STATUS } from '../../constants';

const useStyles = makeStyles(theme => ({
  noDataWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    verticalAlign: 'middle',
    height: 'calc(100vh - 144px)',
    border: 'none',
    '& .title-text': {
      marginTop: 12,
      fontSize: 18,
      fontWeight: 600,
      fontFamily: 'Lato'
    }
  },
  nameSurvey: {
    fontSize: 16,
    fontWeight: 600
  },
  titleConsent: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 32
  },
  subTitleConsent: {
    fontWeight: 600,
    marginBottom: 16,
    marginTop: 32
  },
  headerConsentForm: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  bodyConsentForm: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  }
}));

const rowStyles = makeStyles({
  title: {
    verticalAlign: 'top',
    fontWeight: 400,
    color: '#666666'
  },
  rightWrapper: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8
  },
  text: {
    verticalAlign: 'top',
    fontWeight: 600,
    color: '#333333'
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 8,
    color: '#666666',
    width: 24,
    height: 24
  }
});

const RowItem = ({ title, children }) => {
  const classes = rowStyles();
  return (
    <Box display="flex" borderRadius={8} border={'1px solid #e1e1e1'}>
      <Box
        p={1.5}
        width="60%"
        bgcolor="#f1f1f1"
        className={classes.rightWrapper}
      >
        <Typography className={classes.title}>{title}</Typography>
      </Box>
      <Box p={1.5} width="40%">
        {children === 'None' ? (
          <Typography className={classes.text} style={{ color: '#999999' }}>
            {children}
          </Typography>
        ) : (
          <Typography className={classes.text}>{children}</Typography>
        )}
      </Box>
    </Box>
  );
};

const CustomSurveyContent = ({
  data = [],
  dataInfor,
  Icon = NoDataHelperWhiteIcon,
  noDataHelperText = 'No lifestyle survey',
  noDataHelperSubText = 'This patient hasn’t completed the survey.'
}) => {
  const classes = useStyles();

  const renderCustomValue = row => {
    let value = '';
    switch (row.customFieldType) {
      case 'MultiSelect':
        if (row.isOther) {
          value = row?.value?.map((it, index) => (
            <div key={index}>
              <p style={{ marginBottom: 0 }}>
                {row?.otherAnswer ? `${it}: ` : it}
                <span style={{ fontWeight: 400 }}>{row?.otherAnswer}</span>
              </p>
            </div>
          ));
        } else
          value = isEmpty(row?.value) ? (
            <div>
              <p style={{ marginBottom: 0, color: '#999999' }}>None</p>
            </div>
          ) : (
            row?.value?.map((it, index) => (
              <div key={index}>
                <p style={{ marginBottom: 0 }}>{it || 'None'}</p>
              </div>
            ))
          );

        break;
      case 'DropdownList':
      case 'Rating':
        if (row.isOther) {
          value = (
            <p style={{ marginBottom: 0 }}>
              {row?.otherAnswer ? `${row?.value}: ` : row?.value}
              <span style={{ fontWeight: 400 }}>{row?.otherAnswer}</span>
            </p>
          );
        } else {
          value = row?.value;
        }
        break;
      default:
        value = row?.value;
        break;
    }
    return value;
  };
  return (
    <Box bgcolor="#f1f1f1" p={2}>
      {isEmpty(data) ||
      dataInfor?.paxScreenStatus === HS_PAXSCREENING_STATUS.CheckInLocation ? (
        <Box className={classes.noDataWrapper}>
          <Icon />
          <Typography className="title-text">{noDataHelperText}</Typography>
          <Typography>{noDataHelperSubText}</Typography>
        </Box>
      ) : (
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          gridGap={12}
          borderRadius={8}
          bgcolor={'#ffffff'}
        >
          {data?.map((row, rowIndex) => (
            <RowItem key={row.fieldId} title={`${rowIndex + 1}. ${row.label}`}>
              {renderCustomValue(row)}
            </RowItem>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CustomSurveyContent;
