import React from 'react';
import serviceDispatcher from '@/module/services/action';
import ServiceModalContainer from '../../common/ServiceModalContainer';
import ReviewForm from './ReviewForm';

const ReviewModalContainer = props => (
  <ServiceModalContainer
    {...{
      ...props,
      prefixName: 'Review',
      category: 'Reviews',
      createDispatcher: serviceDispatcher.createTest,
      editDispatcher: serviceDispatcher.editTest,
      getServiceByID: serviceDispatcher.getServiceByID,
      schema: 'serviceSchema',
      requireLabCode: false,
      requireLoinc: false,
      ServicesForm: ReviewForm,
      listValidKeys: ['name']
    }}
  />
);

export default ReviewModalContainer;
