import styled from 'styled-components';

export const PaperWrapped = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
  min-height: calc(100vh - 200px);
  .no-tabs-header {
    border-bottom: 0.5px solid #ecedf0;
  }
  .group {
    font-weight: 500;
    font-size: 16px;
  }
  .anticon {
    font-size: 40px;
  }
  .ant-upload.ant-upload-drag {
    width: 600px;
    height: 200px;
    border: 4px dashed #d9d9d9;
    border-radius: 30px;
    margin: 20px auto;
    background: #fff;
  }
`;

export const HeaderWrapped = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  .title {
    color: #192637;
    font-size: 24px;
    font-weight: 600;
  }
  .personal-btn {
    display: flex;
  }
`;

export const ProgressWrapped = styled.div`
  width: 250px;
  text-align: center;
  padding: 50px;
  .label {
    margin-bottom: 10px;
  }
`;
