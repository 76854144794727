import GroupToolbar from '../../GroupToolbar';
import ModalCreateCompany from '../ModalCreateCompany';
import '../mainScreen.scss';
import {
  AddIcon,
  CreateNewIcon,
  DeleteModalIcon,
  ExportIcon,
  RemoveIcon,
  NoDataCompany,
} from '@/assets/svg';
import { urlLabel } from '@/enum/PermissionEnum';
import { handleSelectAll } from '@/helpers';
import { useAbortableEffect } from '@/helpers/useHookHelpers';
import validateData from '@/helpers/validationHelpers/validationSchema';
import companyDispatcher from '@/module/company/action';
import { COMPANY_COLUMN, FILTER_STATUSES } from '@/module/setup/constants';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable/index';
import CustomTooltip from '@/new-components/CustomTooltip';
import globalDispatcher from '@/redux/global/actions';
import { Button, Chip, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { debounce, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  activeStatus: {
    color: '#0B8E5E',
    backgroundColor: '#0B8E5E29',
  },
  inActiveStatus: { color: '#333333', backgroundColor: '#e1e1e1' },
});

const Company = () => {
  const history = useHistory();
  const classes = useStyles();

  const { list: data, paging } = useSelector((state) => state.company);

  const [filterOption, setFilterOption] = useState({});
  const [searchKey, setSearchKey] = useState('');

  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [companySelected, setCompanySelected] = useState(null);
  const [formValue, setFormValue] = useState({});
  const [errors, setErrors] = useState({});
  const [lstSurvey, setLstSurvey] = useState([]);
  const [loading, setLoading] = useState(false);

  const companyStatus = (filters) => {
    return !isEmpty(filters?.statuses) &&
      filters?.statuses?.length !== FILTER_STATUSES.length
      ? {
          isActive: filters?.statuses[0] === 'Active',
        }
      : {};
  };

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    filterOptionData = filterOption
  ) => {
    companyDispatcher.getCompany(
      search,
      pagingData,
      companyStatus(filterOptionData)
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize,
        },
        filterOption
      );
    }, 500),
    []
  );

  const exportExcel = () => {
    if (data.length === 0) {
      return customToast(
        'info',
        'There is no data available to export the excel file'
      );
    }
    companyDispatcher.getExportExcel(
      searchKey,
      paging,
      companyStatus(filterOption)
    );
  };

  useAbortableEffect((_isMounted) => {
    globalDispatcher.getListSurveys((result = []) => {
      _isMounted.current && setLstSurvey(result);
    });
  }, []);

  useEffect(() => {
    debounceLoadData(searchKey, paging, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, filterOption]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {COMPANY_COLUMN.map((item) => (
          <TableCell
            key={item.stateValue || item.label}
            style={{ minWidth: item.minWidth, width: item.width }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = (row, setCompanySelected) => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.name} />
        </TableCell>
        <TableCell>{row.staffQuantity}</TableCell>
        <TableCell>{row.totalProjects}</TableCell>

        <TableCell>
          <Chip
            className={
              row?.isActive ? classes.activeStatus : classes.inActiveStatus
            }
            label={row?.isActive ? 'Active' : 'Inactive'}
          />
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              /**
               * Set `localStorage` to cache edit information with name `temp_editCompany`
               * Note: This is temp solution, need to update/refactor redirect solution
               */

              localStorage.setItem('temp_editCompany', JSON.stringify(row));
              history.push({
                pathname: `/${urlLabel.editCompany}`,
                search: `id=${row.id}`,
                company: row,
              });
            }}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setCompanySelected(row.id);
              setDeleteModal(true);
            }}
          >
            <RemoveIcon />
          </IconButton>
        </TableCell>
      </>
    );
  };

  const handleDeleteCompany = () => {
    companyDispatcher.deleteCompany(companySelected, () => {
      companyDispatcher.getCompany();
      setDeleteModal(false);
    });
  };

  const onSaveGeneralInfo = async () => {
    try {
      await validateData('companySchema', formValue, () => {
        setLoading(true);
        companyDispatcher.createCompany(formValue, () => {
          setLoading(false);
          companyDispatcher.getCompany();
          setCreateModal(false);
        });
      });
    } catch (err) {
      setErrors({ ...errors, ...err });
    }
  };

  const onResetFilterOps = () => {
    setSearchKey('');
    setFilterOption({
      statuses: [],
    });
  };

  const onFilterOptionChange = (key) => (e, newValue) => {
    let value = e.target.value;

    switch (key) {
      case 'statuses':
        value = handleSelectAll(
          value,
          FILTER_STATUSES.map((item) => item.key),
          filterOption.statuses
        );
        break;
      default:
        break;
    }
    setFilterOption({
      ...filterOption,
      [key]: value,
    });
  };

  const onFilterOptionClear = (key, defaultValue) => () => {
    let clearValue;
    switch (key) {
      case 'statuses':
        clearValue = [];
        break;

      default:
        clearValue = defaultValue;
        break;
    }

    setFilterOption({
      ...filterOption,
      [key]: clearValue,
    });
  };

  return (
    <>
      <ModalConfirmation
        confirmLabel="Delete"
        mainContent="Delete company"
        subContent="Are you sure you want to delete this company ?"
        open={deleteModal}
        confirmColor="red"
        Icon={<DeleteModalIcon />}
        onClose={() => setDeleteModal(false)}
        onClick={handleDeleteCompany}
      />
      <CustomPaperContainer
        header={
          <CustomHeader
            title="Company"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            paging={paging}
            filterOption={filterOption}
            fetchData={fetchData}
            searchPlaceholder="Search by company name..."
            renderToolbar={() => (
              <GroupToolbar
                searchKey={searchKey}
                filterOption={filterOption}
                onFilterOptionClear={onFilterOptionClear}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
              />
            )}
            renderButton={() => (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<ExportIcon width={20} height={20} />}
                  onClick={exportExcel}
                >
                  Export
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<CreateNewIcon />}
                  onClick={() => setCreateModal(true)}
                >
                  Create new
                </Button>
              </>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          header={TableHeader}
          renderRow={(row, i) =>
            renderTableBody(row, setCompanySelected, setDeleteModal)
          }
          paging={paging}
          noDataHelperText="No company"
          noDataHelperSubText="Please create a new company."
          totalCols={COMPANY_COLUMN.length}
          fetchData={fetchData}
          Icon={NoDataCompany}
        />
      </CustomPaperContainer>
      {createModal && (
        <ModalCreateCompany
          loading={loading}
          title="Create new company"
          open={createModal}
          onClose={() => setCreateModal(false)}
          submitLabel="Create"
          formValue={formValue}
          setFormValue={setFormValue}
          errors={errors}
          lstSurvey={lstSurvey}
          onSubmit={onSaveGeneralInfo}
        />
      )}
    </>
  );
};

export default Company;
