import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import {
  get,
  startCase,
  upperCase,
  lowerCase,
  isEmpty,
  isString
} from 'lodash';

import DividerHeader from '@/module/common/componentUI/DividerHeader';
import { WrappedItem, RowItem } from './CommonStyles';

const PastMedicalHistory = ({
  data = {},
  title,
  classes,
  keyHaveDetails = [],
  prefix = '',
  isSystemReview = false
}) => {
  const getDataChildren = (dataChildren, name) => {
    let res = [];
    for (let name in dataChildren) {
      if (dataChildren[name].details) {
        continue;
      }

      if (isSystemReview) {
        if (isString(dataChildren)) {
          return startCase(dataChildren);
        } else {
          if (dataChildren['yes']) {
            res = res.concat(get(dataChildren['yes'], 'array') || []);
          } else {
            return 'Nil';
          }
        }
      } else {
        if (get(dataChildren[name], 'checked')) {
          res = res.concat(name);
        }
      }
    }
    return res.map(it => startCase(it)).join('/');
  };

  const getDataChildrenForDetail = dataRender => {
    const lastIndex = keyHaveDetails.length - 1;
    const results = keyHaveDetails.map((keyDetail, index) => (
      <Grid container style={{ marginBottom: lastIndex === index ? 0 : 24 }}>
        <WrappedItem key={keyDetail}>
          <Grid item xs={12}>
            <RowItem>
              <span className={classes.title}>{startCase(keyDetail)}:</span>
              <span>
                {get(dataRender, `${keyDetail}.checked`) ? 'Yes' : 'Nil'}
              </span>
            </RowItem>
            <RowItem>
              <span className={classes.title}>Detail:</span>
              <span>{get(dataRender, `${keyDetail}.details`)}</span>
            </RowItem>
          </Grid>
        </WrappedItem>
      </Grid>
    ));
    return results;
  };

  const renderData = dataRender => {
    const result = [];
    for (let name in dataRender) {
      let titleItem = startCase(name);
      let content = '';

      if (keyHaveDetails.includes(name)) {
        continue;
      }
      if (isSystemReview || lowerCase(name) === prefix) {
        content = getDataChildren(get(dataRender, name), name);
      } else {
        content = get(dataRender[name], 'checked') ? 'Yes' : 'Nil';
      }
      result.push(
        <RowItem key={name}>
          <span className={classes.title}>{titleItem}:</span>
          <span>{content}</span>
        </RowItem>
      );
    }
    return result;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DividerHeader title={upperCase(title)} />
        </Grid>
        {!isEmpty(data) && (
          <>
            <Grid item xs={12} md={6}>
              <WrappedItem>{renderData(data)}</WrappedItem>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} md={6}></Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
              {getDataChildrenForDetail(prefix ? data[prefix] : data)}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default PastMedicalHistory;
