import authDispatcher from '../action/auth';

const initialState = {
  showOtpPopup: false,
  setting: {
    notificationType: {
      email: true,
      message: false,
      other: false,
    },
  },
  accessToken: '',
  expireIn: 0,
  expireOn: '',
  refreshToken: '',
  tokenType: 'Bearer',
  userInfo: {},
  clinics: [],
  loading: false,
};

const authReducer = authDispatcher(initialState, {
  [authDispatcher.loginSuccess]: (state, payload) => ({
    userInfo: { ...state.userInfo, email: payload.email },
    ...payload.result,
  }),
  [authDispatcher.registerSuccess]: (state, payload) => ({
    ...payload.generalInfo,
    userInfo: payload.rest,
  }),
  [authDispatcher.checkOtpSuccess]: (state, payload) => ({ ...payload.data }),
  [authDispatcher.logoutSuccess]: (state) => ({ ...initialState }),
  [authDispatcher.getUserInfoSuccess]: (state, payload) => ({
    ...payload.roleType,
    userInfo: payload.userInfo,
  }),
  [authDispatcher.getUserDetailSuccess]: (state, payload) => ({
    userInfo: payload.userInfo,
  }),
  [authDispatcher.getAllClinicsSuccess]: (state, payload) => ({
    ...state,
    clinics: payload.clinics,
  }),
  [authDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value,
  }),
});

export default authReducer;
