import jsPDF from 'jspdf';
import format from 'date-fns/format';
import './fonts/Assistant-Bold';
import './fonts/Assistant-Light';
import './fonts/Assistant-Regular';
import lbGreen from '@/assets/image/pdfexport/lb-green.png';
import lbRed from '@/assets/image/pdfexport/lb-red.png';
import lbYellow from '@/assets/image/pdfexport/lb-yellow.png';
import ptYellow from '@/assets/image/pdfexport/pt-yellow.png';
import ptRed from '@/assets/image/pdfexport/pt-red.png';
import ptGreen from '@/assets/image/pdfexport/pt-green.png';
import ptBlood from '@/assets/image/pdfexport/pt-blood.png';
import bgCover from '@/assets/image/pdfexport/bg-cover.png';
import bgHealthScreening from '@/assets/image/pdfexport/bg-health-screening.png';
import bgExaminationResults from '@/assets/image/pdfexport/bg-examination-results.png';
import bgBloodPressure from '@/assets/image/pdfexport/bg-blood-pressure.png';
import bgFatAnalysis from '@/assets/image/pdfexport/bg-fat-analysis.png';
import bgCholesterol from '@/assets/image/pdfexport/bg-cholesterol.png';
import bgGlucoseTest from '@/assets/image/pdfexport/bg-glucose-test.png';
import bgAdditionalTest from '@/assets/image/pdfexport/bg-additional-test.png';
import bgHeartRiskAssessment from '@/assets/image/pdfexport/bg-heart-risk.png';
import bgReferralLetter from '@/assets/image/pdfexport/bg-referral-letter.png';
import bgFatAnalysisWaistHipRatio from '@/assets/image/pdfexport/bg-fat-analysis-waist-hip-ratio.png';
import bgWaistHipRatio from '@/assets/image/pdfexport/bg-waist-hip-ratio.png';

import { get, isNumber } from 'lodash';
export const FONTS = {
  bold: 'Assistant-Bold',
  light: 'Assistant-Light',
  regular: 'Assistant-Regular'
};
export const COLOR_TYPES = {
  green: 'green',
  yellow: 'yellow',
  red: 'red'
};
export const LABEL_TYPES = {
  Normal: 'Normal',
  Borderline: 'Borderline',
  BorderlineHigh: 'Borderline High',
  Desirable: 'Desirable'
};
class JsPdfExtended {
  constructor(margins = {}, papersize = 'a4') {
    Object.assign(this, new jsPDF('p', 'pt', papersize, true));
    this.margins = {
      top: 20,
      right: 25,
      bottom: 15,
      left: 25,
      ...margins
    };
    this.bounds = {
      width: this.internal.pageSize.width,
      height: this.internal.pageSize.height
    };
    this.x = this.margins.left;
    this.y = this.margins.top;
    this.page = 0;
  }

  setFontStyle({
    font = FONTS.regular,
    fontStyle = 'normal',
    fontWeight,
    fontSize = 15,
    color = '#515663',
    lineHeight
  } = {}) {
    return this.setFont(font, fontStyle, fontWeight)
      .setFontSize(fontSize)
      .setLineHeightFactor(lineHeight ? lineHeight / fontSize : 1.15)
      .setTextColor(color);
  }
  addBg(img) {
    return this.addImage(img, 'PNG', 0, 0, 595, 842);
  }
  setFontStyleLabel() {
    return this.setFontStyle({ fontSize: 12, color: '#959CAD' });
  }
  setFontStyleValue() {
    return this.setFontStyle({ fontSize: 20, color: '#2C3341' });
  }
  setFontStyleUnit() {
    return this.setFontStyle({ fontSize: 14, color: '#2C3341' });
  }
  addOverview(text, x, y, title = 'OVERVIEW') {
    return this.setFontStyle({ fontSize: 14, color: '#2C3341' })
      .text(title, x, y)
      .setFontStyle({
        font: FONTS.light,
        fontSize: 14,
        lineHeight: 18,
        color: '#515663'
      })
      .text(text || '', x, y + 20, { maxWidth: 450 });
  }
  addTitle(text) {
    return this.setFontStyle({
      font: FONTS.regular,
      fontSize: 40,
      lineHeight: 52,
      color: '#494d56'
    }).text(text, 40, 80);
  }
  addPaging() {
    this.page += 1;
    return this;
  }
  addPagingAll() {
    for (let i = 1; i <= this.page; i++) {
      this.setPage(i + 1);
      this.setFontStyle({
        font: FONTS.regular,
        fontSize: 10,
        color: '#494d56'
      }).text(`Page ${i} of ${this.page}`, 277.5, 823);
    }
    return this;
  }
  addLabel(label, x, y) {
    switch (label) {
      case LABEL_TYPES.Normal:
        return this.addImage(lbGreen, x, y, 40, 15)
          .setFontStyle({ fontSize: 10, color: '#FFFFFF' })
          .text(label, x + 5, y + 11);
      case LABEL_TYPES.Borderline:
        return this.addImage(lbRed, x, y, 60, 15)
          .setFontStyle({ fontSize: 10, color: '#FFFFFF' })
          .text(label, x + 5, y + 11);
      case LABEL_TYPES.BorderlineHigh:
        return this.addImage(lbRed, x, y, 75, 15)
          .setFontStyle({ fontSize: 10, color: '#FFFFFF' })
          .text(label, x + 5, y + 11);
      case LABEL_TYPES.Desirable:
        return this.addImage(lbYellow, x, y, 50, 15)
          .setFontStyle({ fontSize: 10, color: '#FFFFFF' })
          .text(label, x + 5, y + 11);
      default:
        return this;
    }
  }
  addPointer(type, value, x, y) {
    const img = {
      [COLOR_TYPES.green]: ptGreen,
      [COLOR_TYPES.yellow]: ptYellow,
      [COLOR_TYPES.red]: ptRed
    }[type];
    return this.addImage(img, x, y - 8, 30, 37)
      .setFontStyle({ fontSize: 10, color: '#2C3341' })
      .text(`${value}`, x + 6, y + 13);
  }
  addPointerBlood(dia, sys) {
    let x, y;
    if (isNaN(dia + sys)) return this;
    if (dia < 70) {
      x = 80;
    } else if (dia < 80) {
      x = 120;
    } else if (dia < 90) {
      x = 160;
    } else if (dia < 100) {
      x = 200;
    } else if (dia < 110) {
      x = 240;
    } else {
      x = 280;
    }
    if (sys < 110) {
      y = 483;
    } else if (sys < 120) {
      y = 461;
    } else if (sys < 130) {
      y = 440;
    } else if (sys < 140) {
      y = 419;
    } else if (sys < 150) {
      y = 398;
    } else if (sys < 160) {
      y = 376;
    } else if (sys < 170) {
      y = 354;
    } else if (sys < 180) {
      y = 333;
    } else if (sys < 190) {
      y = 311;
    } else if (sys < 200) {
      y = 289;
    } else {
      y = 267;
    }

    return this.addImage(ptBlood, x - 17, y - 43, 35, 43);
  }
  addBackGroundForBloodPointer(dia, sys) {
    let x, y, color;
    if (isNaN(dia + sys)) return this;
    if (dia < 70) {
      x = 61;
    } else if (dia < 80) {
      x = 101;
    } else if (dia < 90) {
      x = 141;
    } else if (dia < 100) {
      x = 181;
    } else if (dia < 110) {
      x = 220;
    } else {
      x = 260;
    }
    if (sys < 110) {
      y = 465;
    } else if (sys < 120) {
      y = 444;
    } else if (sys < 130) {
      y = 422;
    } else if (sys < 140) {
      y = 401;
    } else if (sys < 150) {
      y = 379;
    } else if (sys < 160) {
      y = 357;
    } else if (sys < 170) {
      y = 336;
    } else if (sys < 180) {
      y = 314;
    } else if (sys < 190) {
      y = 293;
    } else if (sys < 200) {
      y = 271;
    } else {
      y = 250;
    }
    if (dia < 80 && sys < 130) {
      color = '#CBF2E4';
    } else if (dia < 90 && sys < 140) {
      color = '#F3D981';
    } else {
      color = '#E96C75';
    }
    return this.setFillColor(color).rect(x, y, 39, 21, 'F');
  }
  addPointerWeight(value, x, y) {
    let img, offset;
    const textValue = `${value}`;
    if (!isNumber(value)) return this;
    if (value <= 55.4) {
      img = ptGreen;
      offset = (value / 55.4) * 89;
    } else if (value <= 75.4) {
      img = ptYellow;
      offset = 89 + ((value - 55.4) / 20) * 89;
    } else {
      img = ptRed;
      offset = 178 + ((value - 75.4) / 124) * 91;
    }
    return this.addImage(img, x + offset - 15, y - 8, 30, 37)
      .setFontStyle({ fontSize: 10, color: '#2C3341' })
      .text(textValue, x + offset - this.getTextWidth(textValue) / 2, y + 13);
  }
  addPointerFat(value, x, y) {
    let img, offset;
    const textValue = `${value}`;
    if (!isNumber(value)) return this;
    if (value <= 20) {
      img = ptYellow;
      offset = (value / 20) * 104;
    } else if (value <= 30) {
      img = ptGreen;
      offset = 104 + ((value - 20) / 10) * 104;
    } else {
      img = ptRed;
      offset = 208 + ((value - 30) / 50) * 107;
    }
    return this.addImage(img, x + offset - 15, y - 8, 30, 37)
      .setFontStyle({ fontSize: 10, color: '#2C3341' })
      .text(`${value}`, x + offset - this.getTextWidth(textValue) / 2, y + 13);
  }
  addPointerWaistHipRatio(value, x, y) {
    let img, offset;
    const textValue = `${value}`;
    if (!isNumber(value)) return this;
    if (value <= 0.85) {
      img = ptGreen;
      offset = (value / 0.85) * 166;
    } else {
      img = ptRed;
      offset = 166 + ((value - 0.85) / 2) * 166;
    }
    return this.addImage(img, x + offset - 15, y - 8, 30, 37)
      .setFontStyle({ fontSize: 10, color: '#2C3341' })
      .text(`${value}`, x + offset - this.getTextWidth(textValue) / 2, y + 13);
  }
  addPointerCholesterol(value, x, y) {
    let img, offset;
    const textValue = `${value}`;
    if (!isNumber(value)) return this;
    if (value <= 200) {
      img = ptGreen;
      offset = (value / 200) * 171;
    } else {
      img = ptRed;
      offset = 171 + ((value - 200) / 500) * 171;
    }
    return this.addImage(img, x + offset - 15, y - 8, 30, 37)
      .setFontStyle({ fontSize: 10, color: '#2C3341' })
      .text(`${value}`, x + offset - this.getTextWidth(textValue) / 2, y + 13);
  }
  addPointerGlucose(value, x, y) {
    let img, offset;
    const textValue = `${value}`;
    if (!isNumber(value)) return this;
    if (value <= 6) {
      img = ptGreen;
      offset = (value / 6) * 171;
    } else {
      img = ptRed;
      offset = 171 + ((value - 6) / 100) * 171;
    }
    return this.addImage(img, x + offset - 15, y - 8, 30, 37)
      .setFontStyle({ fontSize: 10, color: '#2C3341' })
      .text(`${value}`, x + offset - this.getTextWidth(textValue) / 2, y + 13);
  }
  addCover(paxScreening) {
    const fullName = get(paxScreening, 'patientInfo.fullName');
    const reportDate = get(paxScreening, 'patientInfo.reportDate');
    const yearToShow = format(new Date(reportDate), 'yyyy');
    this.setFontStyle({ fontSize: 30 });
    const splitFullName = this.splitTextToSize(fullName, 500);
    const titlePositionY = 286 + 30 * splitFullName.length;
    const fullNameToShow = splitFullName.join('\n');

    return this.addBg(bgCover, 'PNG', 0, 0, 595, 842)
      .setFontStyle({ font: FONTS.light, fontSize: 30, color: '#737373' })
      .text(`${yearToShow}\n${fullNameToShow}`, 46, 221)
      .setFontStyle({ font: FONTS.regular, fontSize: 50, color: '#ad5e98' })
      .text('Health Screening\nReport', 46, titlePositionY);
  }
  addHealthScreening() {
    return this.addPage()
      .addBg(bgHealthScreening)
      .addPaging()
      .addTitle('Health Screening\nAdvisory')
      .setFontStyle({ fontSize: 14, color: '#686c74' })
      .text(
        `We are glad that you are taking a step towards understanding your health better. Health screening seeks to detect silent diseases that may not show signs and symptoms in early stages, leading to early detection and better treatment outcomes.

This provides a guide for brief and useful information as you prepare for your health screen. Do note the following:
    
1. Guidelines for health screening are presented by the Health Promotion Board here: 
https://www.healthhub.sg/programmes/61/Screen_for_Life#check-your-recommended-screenings.
    
2. Health screening seeks to detect diseases unknown to you. Should you have symptoms of, or already have a known condition, please consult your doctor for diagnosis and further management.
    
3. Screening tests may carry limitations in the capacity to identify or rule out the presence or absence of a condition.
    
4. Should you have queries about your screening tests, do contact our telesupport team or visit our clinics prior to your screen.
    
5. Follow up review of your screening is the most essential part of the exercise. Health conditions are not diagnosed conclusively based on screening test results. Your screening is complete when you visit our clinics for review and where necessary, referral for further management in a timely manner.`,
        40,
        225,
        { maxWidth: 535, lineHeightFactor: 1.57 }
      );
  }
  splitTextOverView(text, size, numOfLinesToSplit) {
    if (!text) {
      return {
        firstPageOverview: '',
        secondPageOverview: ''
      };
    }
    this.setFontStyle({ fontSize: 14, lineHeight: 18 });
    const splitTextOverview = this.splitTextToSize(text, size);
    if (splitTextOverview.length <= numOfLinesToSplit) {
      return {
        firstPageOverview: text,
        secondPageOverview: ''
      };
    }
    let firstPageOverview = '';
    let secondPageOverview = '';
    splitTextOverview.forEach((item, index) => {
      if (index < numOfLinesToSplit) {
        firstPageOverview += item + '\n';
      } else {
        secondPageOverview += item + '\n';
      }
    });
    return { firstPageOverview, secondPageOverview };
  }
  addExaminationResults({ paxScreening, chartBMI, chartData }) {
    if (chartBMI) {
      const weightText = `${get(paxScreening, 'bmi.weight') || 0}`;
      const heightText = `${get(paxScreening, 'bmi.height') || 0}`;
      const bmiText = `${get(paxScreening, 'bmi.bmi') || 0}`;

      const fullName = get(paxScreening, 'patientInfo.fullName');
      const nric = get(paxScreening, 'patientInfo.nric');
      const reportDate = get(paxScreening, 'patientInfo.reportDate');
      const screeningDate = get(paxScreening, 'patientInfo.screeningDate');
      const gender = get(paxScreening, 'patientInfo.gender');
      const yearToShow = format(new Date(reportDate), 'yyyy');
      const reportDateToShow = format(new Date(reportDate), 'dd MMM');
      const screeningDateToShow = screeningDate
        ? format(new Date(reportDate), 'dd MMM')
        : '';
      const weightWidth = this.getTextWidth(weightText);
      const heightWidth = this.getTextWidth(heightText);
      const { firstPageOverview, secondPageOverview } = this.splitTextOverView(
        get(paxScreening, 'bmi.overview'),
        450,
        10
      );

      if (paxScreening.bmi) {
        this.addPage()
          .addBg(bgExaminationResults)
          .addPaging()
          .setFontStyle({ fontSize: 26, color: '#494D56' })
          .text('Examination Results', 40, 55)
          .text('Weight & BMI', 40, 234)
          .setFontStyle({ fontSize: 19.2, color: '#737373' })
          .text(`${yearToShow}\n${fullName}`, 40, 111.95)
          .setFontStyle({ fontSize: 12.8, color: '#737373' })
          .text(`NRIC_${nric}`, 40, 161)
          .text(`Screening Date_${screeningDateToShow}`, 188, 161)
          .text(`Gender_${gender}`, 40, 185)
          .text(`Report Date_${reportDateToShow}`, 188, 185)
          .setFontStyleLabel()
          .text('WEIGHT', 56, 297)
          .text('HEIGHT', 151, 297)
          .text('BMI', 246, 297)
          .setFontStyleValue()
          .text(weightText, 56, 319)
          .text(heightText, 151, 319)
          .text(bmiText, 246, 319);

        this.setFontStyleUnit()
          .text('kg', 66 + weightWidth, 319)
          .text('m', 161 + heightWidth, 319)
          // .addLabel(LABEL_TYPES.Normal, 40, 337)
          .addPointerWeight(get(paxScreening, 'bmi.weight'), 40, 602)
          // .addImage(chartBMI, 'PNG', 40, 360, 300, 220)
          .setFontStyle({ fontSize: 10 })
          .text('0kg', 40, 651)
          .text('55.4kg', 128, 651)
          .text('75.4kg', 217, 651)
          .addOverview(firstPageOverview, 40, 360);

        // const lastData = chartData[chartData.length - 1];
        // const y1 = 552 - Math.ceil((lastData[1] * 164) / 250);
        // const y2 = 552 - Math.ceil((lastData[2] * 164) / 250);

        // this.setFillColor('white').circle(326, y1, 5, 'F');
        // this.setFillColor('#E7728D').circle(326, y1, 3, 'F');
        // this.setFillColor('white').circle(326, y2, 5, 'F');
        // this.setFillColor('#FFCDD9').circle(326, y2, 3, 'F');
      } else {
        this.addPage()
          .addBg(bgAdditionalTest)
          .addPaging()
          .setFontStyle({ fontSize: 26, color: '#494D56' })
          .text('Examination Results', 40, 70)
          .setFontStyle({ fontSize: 19.2, color: '#737373' })
          .text(`${yearToShow}\n${fullName}`, 40, 126.95)
          .setFontStyle({ fontSize: 12.8, color: '#737373' })
          .text(`NRIC_${nric}`, 40, 176)
          .text(`Screening Date_${screeningDateToShow}`, 188, 176)
          .text(`Gender_${gender}`, 40, 200)
          .text(`Report Date_${reportDateToShow}`, 188, 200);
      }

      if (!secondPageOverview) {
        return this;
      }

      return this.addPage()
        .addBg(bgAdditionalTest)
        .addPaging()
        .setFontStyle({ fontSize: 26, color: '#494D56' })
        .text('Examination Results', 40, 70)
        .addOverview(secondPageOverview, 40, 135);
    } else {
      return this.addPage()
        .addBg(bgAdditionalTest)
        .addPaging()
        .setFontStyle({ fontSize: 26, color: '#494D56' })
        .text('Examination Results', 40, 70);
    }
  }
  addBloodPressure(paxScreening) {
    if (!paxScreening.bloodPressure) return this;
    const sysText = `${get(paxScreening, 'bloodPressure.sys') || 0}`;
    const diaText = `${get(paxScreening, 'bloodPressure.dia') || 0}`;
    const sysDiaText = `${sysText}/${diaText}`;
    this.addPage()
      .addBg(bgBloodPressure)
      .addPaging()
      .addTitle('Blood Pressure')
      .setFontStyleLabel()
      .text('SYS', 60, 150)
      .text('DIA', 179, 150)
      .text('BD', 298, 150)
      .setFontStyleValue()
      .text(sysText, 60, 177)
      .text(diaText, 179, 177)
      .text(sysDiaText, 298, 177);
    const sysWidth = this.getTextWidth(sysText);
    const diaWidth = this.getTextWidth(diaText);
    const sysDiaWidth = this.getTextWidth(sysDiaText);

    const { firstPageOverview, secondPageOverview } = this.splitTextOverView(
      get(paxScreening, 'bloodPressure.overview'),
      450,
      14
    );
    this.setFontStyleUnit()
      .text('mmHg', 63 + sysWidth, 177)
      .text('mmHg', 182 + diaWidth, 177)
      .text('mmHg', 301 + sysDiaWidth, 177)
      // .addLabel(LABEL_TYPES.Normal, 40, 212)
      .setFontStyle({ fontSize: 12, color: '#2C3341' })
      .text('NORMAL', 380, 258)
      .text('PREHYPERTENSION', 380, 278)
      .text('HIGH', 380, 298)
      .setFontStyle({ fontSize: 9, color: '#959CAD' })
      .text('200', 42, 274)
      .text('190', 42, 295)
      .text('180', 42, 317)
      .text('170', 42, 338)
      .text('160', 42, 360)
      .text('150', 42, 382)
      .text('140', 42, 403)
      .text('130', 42, 425)
      .text('120', 42, 446)
      .text('110', 42, 468)
      .text('100', 42, 489)
      .text('60', 60, 499)
      .text('70', 100, 499)
      .text('80', 139, 499)
      .text('90', 180, 499)
      .text('100', 219, 499)
      .text('110', 259, 499)
      .addBackGroundForBloodPointer(
        get(paxScreening, 'bloodPressure.dia'),
        get(paxScreening, 'bloodPressure.sys')
      )
      .addPointerBlood(
        get(paxScreening, 'bloodPressure.dia'),
        get(paxScreening, 'bloodPressure.sys')
      )
      .addOverview(firstPageOverview, 40, 551);
    if (!secondPageOverview) {
      return this;
    }

    return this.addPage()
      .addBg(bgAdditionalTest)
      .addPaging()
      .setFontStyle({ fontSize: 26, color: '#494D56' })
      .text('Blood Pressure', 40, 70)
      .addOverview(secondPageOverview, 40, 135);
  }

  addFatAnalysis(paxScreening) {
    const fatText = `${get(paxScreening, 'fatAnalysis.fatAnalysis') || 0}`;
    const waistText = `${get(
      paxScreening,
      'waistHipRatio.waistHipRatioWaist'
    ) || 0}`;
    const hipText = `${get(paxScreening, 'waistHipRatio.waistHipRatioHip') ||
      0}`;
    const waistHipText = `${get(paxScreening, 'waistHipRatio.waistHipRatio') ||
      0}`;

    const fatWidth = this.getTextWidth(fatText);
    const waistWidth = this.getTextWidth(waistText);
    const hipWidth = this.getTextWidth(hipText);
    const fatAnalysisOverview = this.splitTextOverView(
      get(paxScreening, 'fatAnalysis.overview'),
      450,
      5
    );

    const waistHipRatioOverview = this.splitTextOverView(
      get(paxScreening, 'waistHipRatio.overview'),
      450,
      5
    );
    const fatAnalysisOverviewToShow = fatAnalysisOverview.secondPageOverview
      ? fatAnalysisOverview.firstPageOverview + '...'
      : fatAnalysisOverview.firstPageOverview;

    if (!paxScreening.fatAnalysis && !paxScreening.waistHipRatio) return this;
    if (paxScreening.fatAnalysis && paxScreening.waistHipRatio) {
      this.addPage()
        .addBg(bgFatAnalysisWaistHipRatio)
        .addPaging()
        .setFontStyle({ fontSize: 26, color: '#494D56' })
        .text('Fat Analysis', 40, 55)
        .text('Waist Hip Ratio', 40, 438)
        .setFontStyleLabel()
        .text('FAT PERCENTAGE', 60, 132)
        .text('WAIST', 60, 531)
        .text('HIP', 179, 531)
        .text('W/H RATIO', 298, 531)
        .setFontStyleValue()
        .text(fatText, 60, 160)
        .text(waistText, 60, 560)
        .text(hipText, 180, 560)
        .text(waistHipText, 298, 560);

      this.setFontStyleUnit()
        .text('%', 70 + fatWidth, 160)
        .text('cm', 70 + waistWidth, 560)
        .text('cm', 190 + hipWidth, 560)
        // .addLabel(LABEL_TYPES.Borderline, 40, 183)
        .addPointerFat(get(paxScreening, 'fatAnalysis.fatAnalysis'), 40, 211)
        .setFontStyle({ fontSize: 10 })
        .text('0', 40, 261)
        .text('20', 143, 261)
        .text('30', 248, 261)
        .text('0', 40, 674)
        .text('0.85', 206, 674)
        // .addLabel(LABEL_TYPES.Normal, 40, 597)
        .addPointerWaistHipRatio(
          get(paxScreening, 'waistHipRatio.waistHipRatio'),
          40,
          625
        )
        // .addOverview(get(paxScreening, 'fatAnalysis.overview'), 40, 298)
        .addOverview(fatAnalysisOverviewToShow, 40, 298)
        .addOverview(waistHipRatioOverview.firstPageOverview, 40, 705);
    } else if (paxScreening.fatAnalysis) {
      this.addPage()
        .addBg(bgFatAnalysis)
        .addPaging()
        .setFontStyle({ fontSize: 26, color: '#494D56' })
        .text('Fat Analysis', 40, 55)
        .setFontStyleLabel()
        .text('FAT PERCENTAGE', 60, 132)
        .setFontStyleValue()
        .text(fatText, 60, 160);

      this.setFontStyleUnit()
        .text('%', 70 + fatWidth, 160)
        .addPointerFat(get(paxScreening, 'fatAnalysis.fatAnalysis'), 40, 211)
        .setFontStyle({ fontSize: 10 })
        .text('0', 40, 261)
        .text('20', 143, 261)
        .text('30', 248, 261)
        .addOverview(fatAnalysisOverviewToShow, 40, 298);
    } else {
      this.addPage()
        .addBg(bgWaistHipRatio)
        .addPaging()
        .setFontStyle({ fontSize: 26, color: '#494D56' })
        .text('Waist Hip Ratio', 40, 55)
        .setFontStyleLabel()
        .text('WAIST', 60, 147)
        .text('HIP', 179, 147)
        .text('W/H RATIO', 298, 147)
        .setFontStyleValue()
        .text(waistText, 60, 175)
        .text(hipText, 180, 175)
        .text(waistHipText, 298, 175);

      this.setFontStyleUnit()
        .text('cm', 70 + waistWidth, 175)
        .text('cm', 190 + hipWidth, 175)
        .addPointerWaistHipRatio(
          get(paxScreening, 'waistHipRatio.waistHipRatio'),
          40,
          242
        )
        .setFontStyle({ fontSize: 10 })
        .text('0', 40, 291)
        .text('0.85', 206, 291)
        .addOverview(waistHipRatioOverview.firstPageOverview, 40, 320);
    }

    if (!waistHipRatioOverview.secondPageOverview) {
      return this;
    }
    return this.addPage()
      .addBg(bgAdditionalTest)
      .addPaging()
      .setFontStyle({ fontSize: 26, color: '#494D56' })
      .text('Waist Hip Ratio', 40, 70)
      .addOverview(waistHipRatioOverview.secondPageOverview, 40, 135);
  }

  addCholesterol(paxScreening) {
    const isDataNull = Object.values(paxScreening.totalCholessterol).some(
      item => item !== 0 && item !== null && item !== ''
    );
    if (!isDataNull) return this;

    const tcText = `${get(paxScreening, 'totalCholessterol.tc') || 0}`;
    const hdlText = `${get(paxScreening, 'totalCholessterol.hdl') || 0}`;
    const ldlText = `${get(paxScreening, 'totalCholessterol.ldl') || 0}`;
    const trigText = `${get(paxScreening, 'totalCholessterol.trig') || 0}`;
    const ratioText = `${get(paxScreening, 'totalCholessterol.ratio') || 0}`;
    this.addPage()
      .addBg(bgCholesterol)
      .addPaging()
      .addTitle('Cholesterol\nProfile')
      .setFontStyleLabel()
      .text('TOTAL CHOLESTEROL', 60, 205)
      .text('HDL CHOLESTEROL', 220, 205)
      .text('LDL CHOLESTEROL', 378, 205)
      .text('TRIGLYCERIDE', 60, 298)
      .text('TOTAL/ HDL RATIO', 220, 298)
      .setFontStyleValue()
      .text(tcText, 60, 230)
      .text(hdlText, 220, 230)
      .text(ldlText, 378, 230)
      .text(trigText, 60, 323)
      .text(ratioText, 220, 323);
    const tcWidth = this.getTextWidth(tcText);
    const hdlWidth = this.getTextWidth(hdlText);
    const ldlWidth = this.getTextWidth(ldlText);
    const trigWidth = this.getTextWidth(trigText);
    const { firstPageOverview, secondPageOverview } = this.splitTextOverView(
      get(paxScreening, 'totalCholessterol.overview'),
      450,
      17
    );
    this.setFontStyleUnit()
      .text('mg/dL', 63 + tcWidth, 230)
      .text('mg/dL', 223 + hdlWidth, 230)
      .text('mg/dL', 381 + ldlWidth, 230)
      .text('mg/dL', 63 + trigWidth, 323)
      // .addLabel(LABEL_TYPES.Normal, 60, 252)
      // .addLabel(LABEL_TYPES.Normal, 220, 252)
      // .addLabel(LABEL_TYPES.Normal, 378, 252)
      // .addLabel(LABEL_TYPES.BorderlineHigh, 60, 345)
      // .addLabel(LABEL_TYPES.Desirable, 220, 345)
      .setFontStyle({ fontSize: 10 })
      .text('0', 40, 450)
      .text('200', 211, 450)
      .addPointerCholesterol(get(paxScreening, 'totalCholessterol.tc'), 40, 397)
      .addOverview(firstPageOverview, 40, 481);
    if (!secondPageOverview) {
      return this;
    }
    return this.addPage()
      .addBg(bgAdditionalTest)
      .addPaging()
      .setFontStyle({ fontSize: 30, color: '#494D56' })
      .text('Cholesterol\nProfile', 40, 50)
      .addOverview(secondPageOverview, 40, 135);
  }
  addGlucoseTest(paxScreening) {
    if (!paxScreening.glucose) return this;
    const glucoseText = `${get(paxScreening, 'glucose.glucose') || 0}`;
    this.addPage()
      .addBg(bgGlucoseTest)
      .addPaging()
      .addTitle('Glucose Test')
      .setFontStyleLabel()
      .text('GLUCOSE', 60, 150)
      .setFontStyleValue()
      .text(glucoseText, 60, 177);

    const glucoseWidth = this.getTextWidth(glucoseText);
    const { firstPageOverview, secondPageOverview } = this.splitTextOverView(
      get(paxScreening, 'glucose.overview'),
      450,
      26
    );
    this.setFontStyleUnit()
      .text('mmol/dL', 63 + glucoseWidth, 177)
      .setFontStyle({ fontSize: 10 })
      .text('0', 40, 286)
      .text('6', 211, 286)
      .addPointerGlucose(get(paxScreening, 'glucose.glucose'), 40, 236)
      .addOverview(firstPageOverview, 40, 330);
    if (!secondPageOverview) {
      return this;
    }
    return this.addPage()
      .addBg(bgAdditionalTest)
      .addPaging()
      .setFontStyle({ fontSize: 30, color: '#494D56' })
      .text('Glucose Test', 40, 70)
      .addOverview(secondPageOverview, 40, 135);
  }
  addAdditionalTest(paxScreening) {
    if (!paxScreening.additionalTest.length) return this;

    let initialY = 148;
    let lineSpacing = 20;
    let x = 40,
      y = initialY;

    this.addPage()
      .addBg(bgAdditionalTest)
      .addPaging()
      .addTitle('Additional Test')
      .setFontStyle({ fontSize: 14, color: '#2C3341' });

    paxScreening.additionalTest.forEach(test => {
      const lines = this.splitTextToSize(test.statement, 450);

      this.setFontStyle({ fontSize: 14, color: '#2C3341' }).text(
        test.testName,
        x,
        y
      );
      lines.forEach(lineText => {
        if (y > 700) {
          y = lineSpacing;
          this.addPage()
            .addBg(bgAdditionalTest)
            .addPaging()
            .setFontStyle({ fontSize: 30, color: '#494D56' })
            .text('Additional Test', 40, 70)
            .setFontStyle({ fontSize: 14, color: '#2C3341' })
            .text(test.testName, x, initialY);
          y = initialY;
        }
        this.setFontStyle({
          font: FONTS.light,
          fontSize: 14,
          lineHeight: 18,
          color: '#515663'
        }).text(lineText || '', x, y + 20, { maxWidth: 450 });
        y += lineSpacing;
      });
      y += 40;
    });

    return this;
  }

  addHeartRiskAssessment() {
    return this.addPage()
      .addBg(bgHeartRiskAssessment)
      .addPaging()
      .addTitle('Heart Risk\nAssessment')
      .addOverview(
        `We have assessed your cardiovascular (CV) risk using the Framingham Risk Score adapted for local use. Based on your health screening findings, your ten-year risk of vascular event is Low (<1%).

To learn how to calculate the ten-year Coronary Heart Disease(CHD) risk, click: https://goo.gl/7kfYro`,
        40,
        210
      );
  }
  addReferralLetter(paxScreening) {
    const fullName = get(paxScreening, 'patientInfo.fullName');
    const nric = get(paxScreening, 'patientInfo.nric');
    return this.addPage()
      .addBg(bgReferralLetter)
      .addPaging()
      .addTitle('Referral Letter')
      .setFontStyle()
      .text(
        `${fullName}
${nric}



Our client, ${fullName}, ${nric}, has just completed a health screening with us.

This letter is issued to facilitate our client in follow-up arrangements required upon release of health screening results.

Our client has opted to consult you on the screening results.We would appreciate your kind attendance for follow up review with our client for the health screening results.

Thank you.

Please call us on 6339 9339 if you need further clarification.

Warmest regards,
Minmed Health Screeners

(This letter is computer-generated. A signature is not required)`,
        40,
        126,
        { maxWidth: 450 }
      )
      .setFontStyle({ fontSize: 20 })
      .text('Contact', 259, 650)
      .setFontStyle()
      .text('Minmed Health Screeners\nask@minmed.sg', 362, 650)
      .setTextColor('#af5d98')
      .text('@minmedgroup', 454, 700);
  }
}

export default JsPdfExtended;
