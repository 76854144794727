import {
  FORMAT_TIME,
  FORMAT_DATE,
  FORMAT_TIME_LT,
  DAYS_PER_WEEK,
  FORMAT_YYYY_MM_DD,
} from './constants';
import { TableCell } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

const regexTime = /(00|15|30|45)$/;

/**
 * Group Data to time
 * @param {Array} data
 * @return {Object} data with unique time
 */
const groupByTime = (data) => {
  const dataGroupTime = {};
  data.forEach((item) => {
    if (dataGroupTime[item.time]) {
      dataGroupTime[item.time].push(item);
    } else {
      dataGroupTime[item.time] = [item];
    }
  });
  return dataGroupTime;
};

const changeObjectToArrayWithKey = (data = {}) => {
  const dataGroupArr = [];
  for (let key in data) {
    dataGroupArr.push({ key, data: data[key] });
  }
  return dataGroupArr;
};

const groupByDay = (data) => {
  const dataGroupByDay = data.map((item) => {
    const temp = {};
    const { data } = item;
    data.forEach((item) => {
      if (temp[item.date]) {
        temp[item.date].push(item);
      } else {
        temp[item.date] = [item];
      }
    });
    return { key: item.key, ...temp };
  });

  return dataGroupByDay;
};

export const groupDataByTime = (data = []) => {
  if (data.length === 0) return data;
  const dataGroup = groupByTime(data);

  const dataGroupArr = changeObjectToArrayWithKey(dataGroup);

  const dataGroupByDay = groupByDay(dataGroupArr);

  return dataGroupByDay;
};

export const renderHeadTableBooking = (
  keys,
  classes,
  formatNumberDay = 'DD',
  formatDate = 'dddd'
) => {
  return keys.map((key) => (
    <TableCell align="center" key={key}>
      <div className={classes.txtDay}>
        {moment(key, FORMAT_DATE).format(formatNumberDay)}
      </div>
      <div className={classes.txtCell}>
        {moment(key, FORMAT_DATE).format(formatDate)}
      </div>
    </TableCell>
  ));
};

export const generateData = (week, defaultStartTime, defaultEndTime) => {
  const [startHour, startMinute] = defaultStartTime.split(':');

  let startDate = moment(week).set({ hour: startHour, minute: startMinute });
  const endDate = moment(startDate).add('day', DAYS_PER_WEEK);

  const data = [];
  let headingKeys = [];
  let key;

  while (endDate.diff(startDate, 'd') > 0) {
    headingKeys.push(moment(startDate));
    let startTime = moment(defaultStartTime, FORMAT_TIME);
    const endTime = moment(defaultEndTime, FORMAT_TIME);
    while (!startTime.isAfter(endTime)) {
      const date = moment(startDate).format(FORMAT_DATE);
      const time = moment(startTime).format(FORMAT_TIME);

      const [hours, minutes] = time.split(':');

      if (regexTime.test(time)) {
        key = moment(startTime).format(FORMAT_TIME_LT);
      }

      if (!key) {
        key = moment(startTime).subtract('minute', 30).format(FORMAT_TIME_LT);
      }
      const dateString = `${moment(startDate).format(
        FORMAT_YYYY_MM_DD
      )}T${hours}:${minutes}:00Z`;

      data.push({
        startDate: dateString,
        isActive: true,
        isUnavailable: true,
        date,
        baseSlot: 3,
        slot: 0,
        time: key,
      });

      startTime.add('minute', 15);
    }
    startDate.add(1, 'day');
  }
  headingKeys = headingKeys.map((key) => moment(key).format(FORMAT_DATE));
  return [headingKeys, data];
};
