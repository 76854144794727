/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { findIndex, isEmpty } from 'lodash';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';

import { StyledAccordion } from '@/new-components/CustomCollapseCard/styled';

import {
  formatTotalPrice,
  generateKeyCompare
} from '@/module/checkin-patient-information/util';
import checkinPatientDispatcher from '@/module/checkin-patient-information/action';
import useDebounce from '@/helpers/useDebounce';
import {
  CheckboxActive,
  CheckboxDefault,
  CloseCircle,
  RadioActiveIcon,
  RadioInactiveIcon,
  SearchIcon
} from '@/assets/svg';
import PackageItem from './PackageItem';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';
import { packageDispatcher } from '@/module/package-management';
import {
  formatData,
  formatDataMeasurements
} from '@/module/all-screenings/utils';
import CustomTooltip from '@/new-components/CustomTooltip';

const CustomCard = withStyles(theme => ({
  root: {
    marginTop: 8,
    marginBottom: 8,
    padding: '8px 16px',
    boxShadow: 'none',
    borderRadius: 12,
    backgroundColor: props => (props.color ? props.color : '#F6F6F6'),
    width: props => (props.width ? props.width : 'unset'),
    border: `1px solid #F6F6F6`,

    '&.checked': {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    },
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))(Card);

const CustomBox = withStyles({
  root: {
    borderBottom: '1px solid #F1F1F1',
    '&:last-child': {
      borderBottom: 'none'
    }
  }
})(Box);

const CustomSearchInput = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8
    },

    '& input': {
      minWidth: 335,
      minHeight: 48,
      padding: 0
    }
  }
}))(TextField);

const useStyle = makeStyles({
  loadingWrapper: {
    display: 'flex',
    width: '100%',
    height: 100,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const rowItemStyle = makeStyles({
  title: {
    marginBottom: 16,
    fontWeight: 600
  },
  price: {
    color: '#333333',
    fontWeight: 600
  },
  loadingWrapper: {
    display: 'flex',
    width: '100%',
    height: 400,
    justifyContent: 'center',
    alignItems: 'center'
  },
  smallCircle: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    background: '#c1c1c1',
    margin: '0 5px',
    display: 'inline-block',
    color: '#c1c1c1'
  }
});

const CustomCheckboxRowItem = ({
  id,
  data,
  title,
  onChangeDataSelected,
  isPackage,
  isProfile,
  projectId,
  setFormValue,
  formValue,
  packageDetail,
  setPackageDetail,
  currentPackage,
  setCurrentPackage,
  dataPackageExisted,
  restrictedTestIds
}) => {
  const classes = rowItemStyle();
  const [expanded, setExpanded] = useState('');
  const [currentValue, setCurrentValue] = useState({});

  const [profiles, setProfiles] = useState([]);
  const [radiologies, setRadiologies] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [ignoredTestsLocal, setIgnoredTestsLocal] = useState([]);
  const [ignoredProfilesLocal, setIgnoredProfilesLocal] = useState([]);

  const debounceGetDetail = useDebounce(currentValue);
  const [loading, setLoading] = useState(false);

  const handleChangePackage = value => (event, isExpanded) => {
    resetData();
    const newData = { ...value, isChecked: isExpanded };
    setExpanded(isExpanded ? value.id : false);
    if (isExpanded) setCurrentValue(newData);
    onChangeDataSelected(newData);

    if ((currentPackage && value?.id !== currentPackage?.id) || !isExpanded) {
      setCurrentPackage && setCurrentPackage({});
    }

    if (
      (dataPackageExisted && value?.id === dataPackageExisted?.id) ||
      !isExpanded
    ) {
      setCurrentPackage && setCurrentPackage(dataPackageExisted);
    }
  };

  const handleChangeNonOptionalPackage = value => event => {
    const newData = {
      ...value,
      isChecked: value.isChecked ? !value.isChecked : true
    };
    if (!value.isChecked) setCurrentValue(newData);
    onChangeDataSelected(newData);
    if (
      (currentPackage && value?.id !== currentPackage?.id) ||
      value.isChecked
    ) {
      setCurrentPackage && setCurrentPackage({});
    }
  };

  const resetData = () => {
    setPackageDetail({
      name: '',
      packageType: '',
      lstTests: [],
      lstProfiles: [],
      lstMeasurements: [],
      lstProcedures: [],
      lstRadiologies: [],
      lstVaccinations: [],
      lstReviews: []
    });
    setFormValue({
      ...formValue,
      lstPackages: [],
      ignoredTests: [],
      ignoredProfiles: []
    });

    setProcedures([]);
    setRadiologies([]);
    setProfiles([]);
    setCurrentValue({});
  };

  useEffect(() => {
    if (isEmpty(debounceGetDetail)) return;
    setLoading(true);
    let newData = [];
    let listProcedures = [];
    let listRadiologies = [];
    let newIgnoredTests = [];
    let newIgnProfiles = [];

    if (isPackage)
      packageDispatcher.getPackageById(debounceGetDetail.id, result => {
        newData.push(result);

        result.lstTests.forEach(item => {
          if (item.category === 'Procedures')
            listProcedures = item.items?.sort(
              (a, b) => b.isRequired - a.isRequired
            );
          if (item.category === 'Radiologies')
            listRadiologies = item.items.sort(
              (a, b) => b.isRequired - a.isRequired
            );
        });

        // // Filter test restricted by location
        // if (!isEmpty(restrictedTestIds)) {
        //   listProcedures = listProcedures?.filter(
        //     it => !restrictedTestIds?.includes(it.id)
        //   );
        //   listRadiologies = listRadiologies?.filter(
        //     it => !restrictedTestIds?.includes(it.id)
        //   );
        // }

        const ignoredProcedures = [...listProcedures].filter(
          it => !it.isRequired
        );
        const newIgnoredProcedures = formatIgnoredTests(
          debounceGetDetail.id,
          ignoredProcedures
        );
        const ignoredRadiologies = [...listRadiologies].filter(
          it => !it.isRequired
        );
        const newIgnoredRadiologies = formatIgnoredTests(
          debounceGetDetail.id,
          ignoredRadiologies
        );

        const ignoredProfiles = [...result.lstProfiles].filter(
          it => !it.isRequired
        );
        const newIgnoredProfiles = formatIgnoredProfiles(
          debounceGetDetail.id,
          ignoredProfiles
        );

        const ignoredTests = mergeArray(
          newIgnoredProcedures,
          newIgnoredRadiologies,
          'testId'
        );

        if (isEmpty(currentPackage?.ignoredTests)) {
          newIgnoredTests = ignoredTests;
        } else newIgnoredTests = currentPackage?.ignoredTests;

        if (isEmpty(currentPackage?.ignoredProfiles)) {
          newIgnProfiles = newIgnoredProfiles;
        } else {
          newIgnProfiles = currentPackage?.ignoredProfiles;
        }

        setIgnoredTestsLocal(newIgnoredTests);
        setIgnoredProfilesLocal(newIgnProfiles);
        setFormValue({
          ...formValue,
          lstPackages: [debounceGetDetail],
          ignoredTests: newIgnoredTests,
          ignoredProfiles: newIgnoredProfiles
        });

        setProcedures(listProcedures);
        setRadiologies(listRadiologies);
        setProfiles(
          result.lstProfiles?.sort((a, b) => b.isRequired - a.isRequired)
        );
      });
    setTimeout(() => {
      setPackageDetail({
        ...formatData(newData[0]),
        price: debounceGetDetail?.price
      });

      setLoading(false);
    }, 250);
  }, [debounceGetDetail]);

  const formatIgnoredProfiles = (packageId, data) => {
    const newData = data.map(item => ({
      profileId: item.id,
      packageId,
      projectId
    }));
    return newData;
  };

  const formatIgnoredTests = (packageId, data) => {
    const newData = data.map(item => ({
      testId: item.id,
      packageId,
      projectId
    }));
    return newData;
  };
  const mergeArray = (a, b, key) => {
    const reduced = a.filter(
      aitem => !b.find(bitem => aitem[key] === bitem[key])
    );
    return reduced.concat(b);
  };

  const onClickChangeService = value => e => {
    const newData = {
      ...value,
      isChecked: value.isChecked ? !value.isChecked : true
    };
    onChangeDataSelected(newData);
  };
  return (
    <>
      <CustomBox key={id} pb={2.5} mb={2.5} width="100%" alignItems="center">
        <p className={classes.title}>
          <CustomTooltip content={title} />
        </p>
        {data.map(row =>
          isPackage ? (
            row.hasOptional ? (
              <StyledAccordion
                key={row.id}
                expanded={expanded === row.id && row.isChecked}
                onChange={handleChangePackage(row)}
                className={clsx(
                  expanded === row.id && row.isChecked ? 'expanded' : ''
                )}
              >
                <AccordionSummary
                  aria-controls="panel-content"
                  id="panel-header"
                  expandIcon={<ExpandMore />}
                >
                  <Box display="flex" alignItems="center" gridGap={12}>
                    <Checkbox
                      icon={<RadioInactiveIcon />}
                      checkedIcon={<RadioActiveIcon />}
                      checked={row.isChecked}
                      color="primary"
                      style={{ padding: 0 }}
                    />
                    <CustomTooltip content={row.name} />
                  </Box>

                  <Typography className={classes.price}>
                    {formatTotalPrice(row?.price, '$')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!isEmpty(packageDetail) &&
                    expanded === row.id &&
                    !loading && (
                      <PackageItem
                        projectId={projectId}
                        packageDetail={packageDetail}
                        profiles={profiles}
                        setProfiles={setProfiles}
                        procedures={procedures}
                        setProcedures={setProcedures}
                        radiologies={radiologies}
                        setRadiologies={setRadiologies}
                        formValue={formValue}
                        setFormValue={setFormValue}
                        ignoredProfilesLocal={ignoredProfilesLocal}
                        ignoredTestsLocal={ignoredTestsLocal}
                        currentPackage={currentPackage}
                        setCurrentPackage={setCurrentPackage}
                        dataPackageExisted={dataPackageExisted}
                        restrictedTestIds={restrictedTestIds}
                      />
                    )}
                </AccordionDetails>
              </StyledAccordion>
            ) : (
              <CustomCard
                key={row.id}
                className={clsx(row.isChecked ? 'checked' : '')}
                onClick={handleChangeNonOptionalPackage(row)}
                style={{ padding: '0 16px', height: 48 }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py={1.5}
                >
                  <Box display="flex" alignItems="center" gridGap={12}>
                    <Checkbox
                      icon={<RadioInactiveIcon />}
                      checkedIcon={<RadioActiveIcon />}
                      checked={row.isChecked}
                      color="primary"
                      style={{ padding: 0 }}
                    />
                    <Box>
                      <CustomTooltip content={row.name} />
                    </Box>
                  </Box>

                  <Typography className={classes.price}>
                    {formatTotalPrice(row?.price, '$')}
                  </Typography>
                </Box>
              </CustomCard>
            )
          ) : (
            <CustomCard
              key={row.id}
              className={clsx(row.isChecked ? 'checked' : '')}
              onClick={onClickChangeService(row)}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={1.25}
              >
                <Box display="flex" alignItems="center" gridGap={12}>
                  <Checkbox
                    icon={<CheckboxDefault />}
                    checkedIcon={<CheckboxActive />}
                    checked={row.isChecked}
                    color="primary"
                    style={{ padding: 0 }}
                  />
                  <Box>
                    <CustomTooltip content={row.name} />
                    {isProfile && (
                      <Box display="flex" alignItems="center" color="#666666">
                        Test: {row.totalTests}
                        <div className={classes.smallCircle}></div>
                        Profile:{row.totalProfiles}
                      </Box>
                    )}
                  </Box>
                </Box>

                <Typography className={classes.price}>
                  {formatTotalPrice(row?.price, '$')}
                </Typography>
              </Box>
            </CustomCard>
          )
        )}
      </CustomBox>
    </>
  );
};

const ServicesItem = ({
  projectId,
  locationId,
  dataExist = [],
  category,
  isProfile,
  isPackage,
  restrictedPackageIds = [],
  restrictedTestIds = [],
  handleChangeData,
  filterKey,
  formValue,
  setFormValue,
  packageDetail,
  setPackageDetail,
  setCurrentPackage,
  currentPackage,
  dataPackageExisted
}) => {
  const classes = useStyle();
  const [searchKey, setSearchKey] = useState('');
  const [loadingSearch, setLoadingSearch] = useState('');
  const [data, setData] = useState([]);
  const debounceSearch = useDebounce(searchKey);
  const [localData, setLocalData] = useState([]);

  const generateNewTestExist = testExistData => {
    return testExistData.map(it => ({
      ...it,
      keyCompare: generateKeyCompare(it.projectId, it.id || it.testId),
      isChecked: true
    }));
  };

  const fetchData = cb => {
    setLoadingSearch(true);
    if (isProfile) {
      checkinPatientDispatcher.getTestProfiles(projectId, cb);
    } else if (isPackage) {
      checkinPatientDispatcher.getTestPackagesByLocation(
        { projectId, locationId },
        cb
      );
    } else {
      checkinPatientDispatcher.getAllListTests(
        { Category: category, projectId, locationId },
        cb
      );
    }
  };

  useEffect(() => {
    fetchData(values => {
      let newData = [];
      const dataShowTable = {};
      const testExistWithKeyCompare = generateNewTestExist(dataExist);
      const restrictedServiceIds = [
        ...restrictedPackageIds,
        ...restrictedTestIds
      ];

      values.forEach(project => {
        const { id: projectId, items } = project;

        const testsFormat = formatDataMeasurements(
          items
            .filter(item => !restrictedServiceIds.includes(item.id))
            .map(item => ({
              ...item,
              testName: item.name || '',
              testCode: item.code || '',
              testPrice: item.price || 0
            }))
        );

        const newTests = testsFormat
          .map(it => ({
            ...it,
            projectId,
            keyCompare: generateKeyCompare(projectId, it.id || it.testId),
            isChecked: false
          }))
          .sort((a, b) => a.price - b.price);

        newData.push({ ...project, items: newTests });
        dataShowTable[projectId] = false;
      });
      newData = updateCheckedData(newData, testExistWithKeyCompare);

      setData(newData);
      setLocalData(newData);
      setLoadingSearch(false);
    });
  }, [category, isProfile, isPackage]);

  useEffect(() => {
    if (data.length === 0) return;
    setLoadingSearch(true);
    let newData = [];
    data.forEach(project => {
      const results = [];
      const { items } = project;
      items.forEach(item => {
        const { name = '', code = '' } = item;
        if (
          name?.toLowerCase().includes(debounceSearch.toLowerCase()) ||
          code?.toLowerCase().includes(debounceSearch.toLowerCase())
        ) {
          results.push(item);
        }
      });

      newData.push({ ...project, items: results });
    });
    setTimeout(() => {
      setLocalData(newData);
      setLoadingSearch(false);
    }, 250);
  }, [debounceSearch]);

  // Checked Table data if data exist
  const updateCheckedData = (dataUpdated = [], testExists = []) => {
    const newDataLocal = [...dataUpdated];

    testExists.forEach(test => {
      const indexProject = findIndex(
        dataUpdated,
        it => it.id === test.projectId
      );
      if (indexProject < 0) return;
      const updatedProject = newDataLocal[indexProject];
      const indexTestChange = findIndex(
        updatedProject.items,
        it => it.keyCompare === test.keyCompare
      );

      if (indexTestChange < 0) return;
      updatedProject.items[indexTestChange] = {
        ...test,
        hasOptional: updatedProject?.items[indexTestChange]?.hasOptional
      };
    });

    return newDataLocal;
  };

  const updateCheckedPackageData = (
    dataUpdated = [],
    testExists = [],
    projectId
  ) => {
    let newDataLocal = [...dataUpdated];
    let newData = [];
    let packageItems = [];

    newDataLocal = newDataLocal.map(item => ({
      ...item,
      items: item.items.map(test => ({ ...test, isChecked: false }))
    }));

    newDataLocal
      .filter(it => projectId === it.id)
      .map(item => (packageItems = item.items));

    newData = packageItems.map(item =>
      item.id === testExists.id ? testExists : { ...item, isChecked: false }
    );
    newData.forEach(test => {
      const indexProject = findIndex(
        dataUpdated,
        it => it.id === test.projectId
      );
      if (indexProject < 0) return;
      const updatedProject = newDataLocal[indexProject];
      const indexTestChange = findIndex(
        updatedProject.items,
        it => it.keyCompare === test.keyCompare
      );

      if (indexTestChange < 0) return;
      updatedProject.items[indexTestChange] = { ...test };
    });

    return newDataLocal;
  };

  const onChangeDataSelected = dataItem => {
    if (isPackage) {
      const dataUpdated = updateCheckedPackageData(
        data,
        dataItem,
        dataItem.projectId
      );
      const dataLocalUpdated = updateCheckedPackageData(
        localData,
        dataItem,
        dataItem.projectId
      );

      setLocalData(dataLocalUpdated);
      setData(dataUpdated);
      handleChangeData(dataUpdated, filterKey);
    } else {
      const dataUpdated = updateCheckedData(data, [dataItem]);
      const dataLocalUpdated = updateCheckedData(localData, [dataItem]);

      setLocalData(dataLocalUpdated);
      setData(dataUpdated);
      handleChangeData(dataUpdated, filterKey);
    }
  };

  return (
    <Box py={2} px={2.5} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomSearchInput
            fullWidth
            variant="outlined"
            placeholder="Search by name"
            value={searchKey}
            onChange={e => setSearchKey(e.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        visibility: !isEmpty(searchKey) ? 'visible' : 'hidden'
                      }}
                      onClick={() => setSearchKey('')}
                    >
                      <CloseCircle
                        height={20}
                        width={20}
                        style={{
                          color: '#666666'
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </>
              )
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ height: 'calc(100vh - 330px)', overflowY: 'auto' }}
        >
          {loadingSearch && (
            <div className={classes.loadingWrapper}>
              <CircularProgress color="primary" />
            </div>
          )}
          {!loadingSearch &&
            localData.map(testItem => (
              <CustomCheckboxRowItem
                isProfile={isProfile}
                isPackage={isPackage}
                id={testItem.id}
                key={testItem.id}
                title={testItem.name}
                data={testItem.items}
                projectId={projectId}
                onChangeDataSelected={onChangeDataSelected}
                formValue={formValue}
                setFormValue={setFormValue}
                packageDetail={packageDetail}
                setPackageDetail={setPackageDetail}
                currentPackage={currentPackage}
                setCurrentPackage={setCurrentPackage}
                dataPackageExisted={dataPackageExisted}
                restrictedTestIds={restrictedTestIds}
              />
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServicesItem;
