/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import customToast from '@/new-components/CustomNotification';
import { Typography, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import authDispatcher from '../../action/auth';
import LoadingButton from '../../../../components/LoadingButton';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 395,
    width: 380,
    borderRadius: 10,
    backgroundColor: 'white',
    padding: '40px 20px'
  },
  qrCodeContainer: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    padding: '2rem'
  },
  wrapInputContainer: {
    display: 'flex',
    margin: '1rem auto',
    justifyContent: 'space-between',
    width: '100%'
  },
  inputContainer: {
    width: 40
  },
  input: {
    fontSize: 32,
    textAlign: 'center',
    margin: 0
  },
  verifyButton: {
    marginTop: 24
  },
  hiddenSubmitButton: {
    display: 'none'
  }
});

const Login2FA = ({ loginInfo = {} }) => {
  const classes = useStyles();

  const [isVerifying, setVerifying] = useState(false);
  // const [code, setCode] = useState('');
  const [codes, setCodes] = useState({
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: ''
  });

  const handleVerifyCode = evt => {
    evt.preventDefault();
    const code = Object.values(codes).join('');
    if (code.length !== 6) return;
    if (loginInfo?.accessToken) {
      const bodyData = { accessToken: loginInfo.accessToken, code };
      setVerifying(true);
      authDispatcher.validateAuthenticatorsCode(bodyData, data => {
        setVerifying(false);
        if (data) {
          authDispatcher.loginSuccess(loginInfo, loginInfo.email);
        } else {
          customToast('error', 'Invalid Code');
        }
      });
    } else {
      customToast('error', 'Something went wrong');
    }
  };

  const handlePaste = evt => {
    const currVal = evt.clipboardData.getData('text');
    if (currVal.length === 6) {
      const arrVal = currVal.split('');
      setCodes({
        code1: arrVal[0],
        code2: arrVal[1],
        code3: arrVal[2],
        code4: arrVal[3],
        code5: arrVal[4],
        code6: arrVal[5]
      });
      document.getElementById('code6').focus();
    }
  };

  const handleChangeInputOTP = (evt, key) => {
    const currVal = evt.target.value;

    // Delete
    if (currVal === '') {
    } else if (!/\d/.test(currVal)) {
      // only accept digit, if not replace to ""
      evt.target.value = '';
      return;
    }

    setCodes({ ...codes, [key]: currVal });

    const currIndex = parseInt(key.slice(-1), 10);
    let newIndex = 0;

    // Delete
    if (evt.target.value.length === 0) {
      newIndex = currIndex - 1 > 0 ? currIndex - 1 : currIndex;
    } else {
      newIndex = currIndex + 1 < 7 ? currIndex + 1 : currIndex;
    }

    document.getElementById(`code${newIndex}`).focus();
  };

  const renderQRImage = () => (
    <>
      <Typography variant="h5">Set up Authenticator</Typography>
      <div className={classes.qrCodeContainer}>
        <img src={loginInfo?.imageUrl} alt="qr-image" />
      </div>
      <Typography variant="subtitle2">
        After scanning the QR code, enter the verification code.
      </Typography>
    </>
  );

  return (
    <form className={classes.container} onSubmit={handleVerifyCode}>
      {loginInfo?.imageUrl ? (
        renderQRImage()
      ) : (
        <Typography variant="subtitle2">
          Enter the 6-digit code generated by Google Authenticator.
        </Typography>
      )}

      <div className={classes.wrapInputContainer}>
        {Object.keys(codes)?.map(key => (
          <TextField
            key={key}
            id={key}
            value={codes[key]}
            className={classes.inputContainer}
            inputProps={{
              className: classes.input,
              maxLength: 1,
              onPaste: handlePaste
            }}
            onChange={evt => handleChangeInputOTP(evt, key)}
          />
        ))}
      </div>
      <LoadingButton
        className={classes.verifyButton}
        showLoader={isVerifying}
        onClick={handleVerifyCode}
        disabled={Object.values(codes).some(c => !c) || isVerifying}
      >
        Verify
      </LoadingButton>
      <button className={classes.hiddenSubmitButton} type="submit">
        hiddenSubmit
      </button>
    </form>
  );
};

export default Login2FA;
