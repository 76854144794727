import React from 'react';
import ServiceForm from '../../common/ServiceForm';

const ReviewForm = props => (
  <ServiceForm
    {...{
      ...props,
      category: 'Reviews',
      prefixName: 'Review'
    }}
  />
);

export default ReviewForm;
