import React from 'react';
import styled from 'styled-components';
import { Grid, TextField, Typography } from '@material-ui/core';
import { get } from 'lodash';
import { Delete } from '@material-ui/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

const dummyRequest = () => {
  setTimeout(() => {}, 0);
};
const ListCertificate = ({
  listImage,
  onDelete,
  accept,
  onChange,
  error,
  label
}) => {
  return (
    <ImagesWrapped>
      <Grid md={12}>
        {(listImage || []).map((image, i) => (
          <Grid md={12} className="img-item">
            <Grid item md={10}>
              <TextField
                style={{ width: '100%', marginTop: 10 }}
                value={get(image, 'name') || image}
                inputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={1}>
              <Delete
                className="delete-icon"
                onClick={() => onDelete(image, i)}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item md={12}>
        <Upload
          onChange={onChange}
          customRequest={dummyRequest}
          listType="picture-card"
          showUploadList={false}
          accept={accept || '.png,.jpg,.jpeg'}
        >
          <div className="upload-btn">
            <PlusOutlined />
            <div className="text">{label}</div>
          </div>
        </Upload>
      </Grid>
      {error && <Typography color="error">{error}</Typography>}
    </ImagesWrapped>
  );
};

export default ListCertificate;

const ImagesWrapped = styled.div`
  .img-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .MuiOutlinedInput-input {
    padding: 12px 14px;
  }
  .ant-upload.ant-upload-select-picture-card {
    height: 40px;
    width: 100%;
  }
  .upload-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      margin-left: 8px;
    }
  }
  .delete-icon {
    cursor: pointer;
    margin-top: 20px;
    margin-left: 15px;
  }
`;
