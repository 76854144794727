import { synthesize } from 'redux-dispatcher';
import customToast from '@/new-components/CustomNotification';
import callDispatcher from '../booking-appointment/actions';

const mapDispatchToAC = {
  setState: (name, value) => ({ name, value }),
  resetState: () => ({}),
  updateDataAppointment: data => ({ data }),
  acceptCall: () => async ({ Api }) => {
    try {
      // const response = await Api.put(
      //   `DoctorAppointments/${appointmentId}/process`
      // );
      // if (response.status === 200) {
      callDispatcher.setState('openModalAcceptCall', false);
      callDispatcher.setState('calling', true);
      callDispatcher.setState('openLoadingAcceptance', false);
      customToast('success', 'Join call success');
      // }
    } catch (error) {
      customToast('error', 'Join call fail');
    }
  },
  cancelConsult: callId => async ({ Api, customToast }) => {
    let { status } = await Api.put(`Calls/${callId}/cancel`);
    if (status === 200) {
      callDispatcher.resetState();
      customToast('success', 'Cancel Success');
    }
    await callDispatcher.setState('openConsultModal', false);
  },
  completeConsult: callId => async ({ Api, customToast }) => {
    let { status } = await Api.put(`Calls/${callId}/complete`);
    if (status === 200) {
      callDispatcher.resetState();
      customToast('success', 'Complete Success');
    }
    await callDispatcher.setState('openConsultModal', false);
  },
  endCall: () => async ({ Api }) => {
    try {
      // const response = await Api.put(
      //   `DoctorAppointments/${appointmentId}/make-done`
      // );
      // if (response.status === 200) {
      customToast('success', 'End call success');
      await callDispatcher.setState('openConsultModal', true);

      // }
    } catch (error) {
      customToast('error', 'Reject call fail');
    }
  },
  rejectCall: appointmentId => async ({ Api }) => {
    try {
      const response = await Api.put(
        `DoctorAppointments/${appointmentId}/reject`
      );
      if (response.status === 200) {
        callDispatcher.resetState();
        customToast('success', 'Reject call success');
      }
    } catch (error) {
      customToast('error', 'Reject call fail');
    }
  },
  getMemberAppointment: (userId, cb) => async ({ Api }) => {
    const { status, result } = await Api.get(
      `DoctorAppointments/member-appointments?memberId=${userId}`
    );
    cb && cb();
    if (status === 200) {
      callDispatcher.getMemberAppointmentSuccess(result || []);
    } else callDispatcher.getMemberAppointmentSuccess([]);
  },
  getMemberAppointmentSuccess: data => ({ data })
};

const callAppointmentDispatch = synthesize(
  'callBookingAppointment',
  mapDispatchToAC
);
export default callAppointmentDispatch;
