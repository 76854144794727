import React, { useState, useEffect } from 'react';
import ModalPage, { ModalFooter } from './styled';
import { DialogContent, TextField, Grid } from '@material-ui/core';
import customToast from '@/new-components/CustomNotification';
import { ButtonEnhance } from '../../../common/componentUI/commonStyleComponents';
import LoadingButton from '../../../../components/LoadingButton';
import { ClearRounded } from '@material-ui/icons';
import { get } from 'lodash';
import { TYPE_MODAL } from '../../../common/constants/typeModal';
import validateData from '../../../../helpers/validationHelpers/validationSchema';
import UploadCompAnt from '../../../../components/UploadCompAnt';

import ControlDatepicker from '@/module/common/component/ControlDatepicker';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import moment from 'moment';

const FILE_SIZE = 2 * 1024 * 1024; // ~2mb

const LocationModal = ({
  onClose,
  selectedItem,
  typeModal,
  onSubmit,
  loading,
  totalItem
}) => {
  const initalFormValue = {
    startDate: null,
    endDate: null,
    order: '',
    name: '',
    url: ''
  };
  const [formValue, setFormValue] = useState(initalFormValue);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (typeModal === TYPE_MODAL.Edit) setFormValue(selectedItem);
  }, [typeModal, selectedItem]);

  const handleSubmit = async () => {
    try {
      await validateData(
        'bannerSchema',
        { ...formValue, totalItem: totalItem + 1 },
        data => {
          onSubmit({
            ...data,
            name: data.name.trim(),
            startDate: moment(data.startDate)
              .utc()
              .format(),
            endDate: moment(data.endDate)
              .utc()
              .format()
          });
        }
      );
    } catch (errs) {
      setErrors(errs);
    }
  };

  const isMatchedPattern = (key, value) => {
    switch (key) {
      case 'order':
        const reg = /^\d+$/;
        return value.match(reg) || value === '';

      default:
        return true;
    }
  };

  const handleChangeForm = key => e => {
    let value;
    if (key === 'url') {
      // const { size, type } = e;
      if (get(e, 'size') > FILE_SIZE) {
        customToast('error', 'Image is too large');
        return;
      }
      value = e;
    } else {
      value = get(e, 'target.value');
    }

    if (!isMatchedPattern(key, value)) return;

    setFormValue({ ...formValue, [key]: value });
  };

  const handleChangeDate = (key, value) => {
    setFormValue({
      ...formValue,
      [key]: value
    });
  };

  return (
    <ModalPage>
      <DialogContent>
        <div className="test-header">
          {typeModal === TYPE_MODAL.Create && 'Create New Banner'}
          {typeModal === TYPE_MODAL.Edit && selectedItem.name}
          <ClearRounded
            style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
            onClick={onClose}
          />
        </div>
        <div className="test-body">
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12}>
              <div style={{ height: 160, width: '100%' }}>
                <UploadCompAnt
                  error={errors.url}
                  isAddNew={true}
                  label="Upload banner"
                  fileData={formValue.url}
                  onChange={handleChangeForm('url')}
                  editable={true}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.order}
                helperText={errors.order}
                label="Order"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.order}
                onChange={handleChangeForm('order')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.name}
                helperText={errors.name}
                label="URL"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.name}
                onChange={handleChangeForm('name')}
              />
            </Grid>
          </Grid>
          <LocalizationProvider dateAdapter={MomentAdapter}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlDatepicker
                  grid={12}
                  ampm={false}
                  clearable
                  label="Start Date"
                  onChange={value => handleChangeDate('startDate', value)}
                  value={formValue.startDate}
                  name="startDate"
                  minutesStep={5}
                  disablePast
                  maxDate={formValue.endDate}
                  error={errors.startDate}
                  inputFormat="DD MMM YYYY"
                />
              </Grid>
              <Grid item xs={12}>
                <ControlDatepicker
                  grid={12}
                  ampm={false}
                  clearable
                  label="End Date"
                  onChange={value => handleChangeDate('endDate', value)}
                  value={formValue.endDate}
                  minDate={formValue.startDate}
                  name="endDate"
                  minutesStep={5}
                  disablePast
                  error={errors.endDate}
                  inputFormat="DD MMM YYYY"
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </div>
        <ModalFooter>
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={onClose}
          >
            Cancel
          </ButtonEnhance>

          <LoadingButton onClick={handleSubmit} showLoader={loading}>
            {typeModal === TYPE_MODAL.Edit ? 'Update' : 'Create'}
          </LoadingButton>
        </ModalFooter>
      </DialogContent>
    </ModalPage>
  );
};

export default LocationModal;
