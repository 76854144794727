/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import customToast from '@/new-components/CustomNotification';
import { BACK_KEY } from '../../module/common';
import { FileCopyRounded } from '@material-ui/icons';
const CodeInput = ({ initData, onComplete, disabled, onRefresh }) => {
  const [firstChar, setFirstChar] = useState(null);
  const [secondChar, setSecondChar] = useState(null);
  const [thirdChar, setThirdChar] = useState(null);
  const [fourthChar, setFourthChar] = useState(null);
  const [fifthChar, setFifthChar] = useState(null);
  const [sixthChar, setSixthChar] = useState(null);
  const [err, setErr] = useState('');

  useEffect(() => {
    if (typeof initData === 'string') {
      setFirstChar(initData.charAt(0));
      setSecondChar(initData.charAt(1));
      setThirdChar(initData.charAt(2));
      setFourthChar(initData.charAt(3));
      setFifthChar(initData.charAt(4));
      setSixthChar(initData.charAt(5));
    }
  }, []);

  useEffect(() => {
    if (isInit()) document.getElementById('first-char').focus();
    else if (firstChar) {
      document.getElementById('second-char').focus();
    }
  }, [firstChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (secondChar) document.getElementById('third-char').focus();
    else document.getElementById('first-char').focus();
  }, [secondChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (thirdChar) document.getElementById('fourth-char').focus();
    else document.getElementById('second-char').focus();
  }, [thirdChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (fourthChar) document.getElementById('fifth-char').focus();
    else document.getElementById('third-char').focus();
  }, [fourthChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (fifthChar) document.getElementById('sixth-char').focus();
    else document.getElementById('fourth-char').focus();
  }, [fifthChar]);
  useEffect(() => {
    if (isInit() || disabled || err === 'Success') return;
    else if (!sixthChar) document.getElementById('fifth-char').focus();
    else {
      const inputOTP = `${firstChar}${secondChar}${thirdChar}${fourthChar}${fifthChar}${sixthChar}`;
      onComplete(inputOTP, ({ message, status }) => {
        if (status !== 200) {
          setErr(message);
          resetInput();
        } else {
          onRefresh(inputOTP);
          setErr('Success');
        }
      });
    }
  }, [sixthChar]);

  const isInit = () =>
    !(
      firstChar ||
      secondChar ||
      thirdChar ||
      fourthChar ||
      fifthChar ||
      sixthChar
    );

  const resetInput = () => {
    setFirstChar('');
    setSecondChar('');
    setThirdChar('');
    setFourthChar('');
    setFifthChar('');
    setSixthChar('');
    document.getElementById('first-char').focus();
  };

  const onChangeValue = setState => event => {
    if (event.target.value.length <= 1) setState(event.target.value);
    else setState(event.target.value.substring(1));
  };
  const onBackKey = setState => e => {
    if (!e.target.value) setState(false);
  };

  const CustomInput = ({ id, value, setState, disabled }) => (
    <TextField
      disabled={!!disabled}
      id={id}
      value={value || ''}
      onChange={onChangeValue(setState)}
      onKeyUp={e => e.keyCode === BACK_KEY && onBackKey(setState)(e)}
      style={{ marginRight: 4 }}
      InputProps={{
        disableUnderline: true,
        style: {
          fontWeight: 600,
          height: 30,
          width: 30,
          textAlign: 'center',
          marginLeft: 0,
          color: '#192637',
          borderRadius: 4,
          backgroundColor: '#F7F7F7'
        }
      }}
    />
  );

  return (
    <div style={{ marginTop: 10 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CustomInput
          id={'first-char'}
          setState={setFirstChar}
          value={firstChar}
          disabled={disabled}
        />
        <CustomInput
          id="second-char"
          setState={setSecondChar}
          value={secondChar}
          disabled={disabled}
        />
        <CustomInput
          id="third-char"
          setState={setThirdChar}
          value={thirdChar}
          disabled={disabled}
        />
        <CustomInput
          id="fourth-char"
          setState={setFourthChar}
          value={fourthChar}
          disabled={disabled}
        />
        <CustomInput
          id="fifth-char"
          setState={setFifthChar}
          value={fifthChar}
          disabled={disabled}
        />
        <CustomInput
          id="sixth-char"
          setState={setSixthChar}
          value={sixthChar}
          disabled={disabled}
        />
        {(disabled || err === 'Success') && (
          <CopyToClipboard
            text={`${firstChar}${secondChar}${thirdChar}${fourthChar}${fifthChar}${sixthChar}`}
            onCopy={() => customToast('success', 'Code copied to clipboard')}
          >
            <FileCopyRounded
              className="clickable-icon"
              style={{ marginLeft: 4, color: '#CACFD3' }}
            />
          </CopyToClipboard>
        )}
      </div>

      <Typography
        style={{
          fontSize: 10,
          color: err !== 'Success' ? '#EA6B75' : '#62CAA4',
          marginTop: 4
        }}
      >
        {err}
      </Typography>
    </div>
  );
};

export default CodeInput;
