import React, { useState, useCallback } from 'react';
import TableContainer from '../../../components/Table/TableContainer';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Dialog,
  Modal,
  Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { MoreVertRounded, VisibilityRounded } from '@material-ui/icons';
import moment from 'moment';
import { ChipStatus } from '../../common/componentUI/commonStyleComponents';
import { startCase } from 'lodash';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import { SearchLivestream } from '../../livestream/components';
import { FORMAT_DATE_TIME } from '../../livestream/constants';
import { LivestreamIcon } from '../../../assets/svg';
import {
  initLiveStreamConnection,
  startLiveStream,
  hubConnection,
  joinLiveStreamGroup
} from '../../../helpers/liveStreamSignalR';
import { upcomingDispatcher } from '..';
import {
  LiveStreamStatusColor,
  LiveStreamStatus,
  StatusDisplay
} from '../../../enum/WorkoutEnum';
import LivestreamScreen from './LivestreamScreen';
import customToast from '@/new-components/CustomNotification';
import BasicFormModalPage from '../../livestream/pages/LivestreamListPage/BasicFormModalPage';
import LivestreamFormModalPage from '../../livestream/pages/LivestreamListPage/LivestreamFormModalPage';
import livestreamDispatcher from '../../livestream/actions';
import { TYPE_MODAL } from '../../livestream/constants';
import LottieControl from '@/components/LottieControl';
import * as loadingAnimation from '../../../assets/animation/loading.json';
import ModalPresenter from '@/components/ModalPresenter';

const ROLES_CAN_START_STREAM = ['Manager', 'TeleFITSuperAdmin', 'TeleFITAdmin'];

const UpcomingWorkout = () => {
  const { list: data, paging, calling, channelData } = useSelector(
    state => state.upcoming
  );
  const { id: userId, roleName } = useSelector(state => state.auth.userInfo);
  const waitingSetupLs = useSelector(state => state.upcoming.waitingSetupLs);
  const { accessToken } = useSelector(state => state.auth);
  const [selectedItem, setSelectedItem] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [showBasicModal, setShowBasicModal] = useState(false);
  const [showModalLivestream, setShowModalLivestream] = useState(false);

  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({});
  const [hiddenRows, setHiddenRows] = useState({});

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const Header = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper label="Workout Name" stateValue="title" />
        <TableCellFiltedWrapper label="Date Time" stateValue="date" />
        <TableCellFiltedWrapper label="Intensity" stateValue="intensity" />
        <TableCellFiltedWrapper label="Duration" stateValue="duration" />
        <TableCellFiltedWrapper label="Calories" stateValue="calories" />
        <TableCellFiltedWrapper label="Description" stateValue="description" />
        <TableCellFiltedWrapper label="Equipment" stateValue="equipment" />
        <TableCellFiltedWrapper label="Status" stateValue="status" />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const handleClick = item => event => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleShowBasicModal = () => {
    setShowBasicModal(true);
    getLivestreamByID(selectedItem.id);
    handleClose();
  };
  const onCloseBasicModal = () => {
    setShowBasicModal(false);
  };

  const onCloseModalLivestream = () => {
    setShowModalLivestream(false);
  };

  const handleBackToBasicForm = () => {
    setShowModalLivestream(!showModalLivestream);
    setShowBasicModal(!showBasicModal);
  };

  const getLivestreamByID = id => {
    livestreamDispatcher.getLivestreamByID(id, result => {
      setSelectedItem(result);
    });
  };
  const renderMenuItem = useCallback(() => {
    if (!selectedItem) return [];
    let options = [
      {
        key: 'view',
        icon: VisibilityRounded,
        label: 'View',
        onClick: handleShowBasicModal
      }
    ];
    if (
      ROLES_CAN_START_STREAM.includes(roleName) &&
      [LiveStreamStatus.Upcomming].includes(selectedItem.liveStreamStatus) &&
      moment().isBefore(moment(selectedItem.startLivestreamDate).add(2, 'h')) &&
      moment().isAfter(
        moment(selectedItem.startLivestreamDate).subtract(45, 'm')
      )
    ) {
      options.push({
        key: 'startLivestream',
        icon: LivestreamIcon,
        label: 'Start Livestream',
        onClick: async () => {
          handleClose();
          customToast('info', 'This feature will come back soon');
          // if (!hubConnection) {
          //   await initLiveStreamConnection(
          //     accessToken,
          //     selectedItem.id,
          //     userId
          //   );
          // }
          // upcomingDispatcher.setState('waitingSetupLs', true);
          // joinLiveStreamGroup(selectedItem.id);
          // startLiveStream(selectedItem.id);
          // setSearchKey('');
        }
      });
    } else if (
      [LiveStreamStatus.Live].includes(selectedItem.liveStreamStatus)
    ) {
      options.push({
        key: 'joinLivestream',
        icon: LivestreamIcon,
        label: 'Join Livestream',
        onClick: async () => {
          handleClose();
          customToast('info', 'This feature will come back soon');
          // check if livestream started before joining
          // await upcomingDispatcher.checkStartedLivestream(
          //   selectedItem.id,
          //   async isStarted => {
          //     if (!isStarted) customToast('error','Stream has not been started yet');
          //     handleClose();
          //     if (!hubConnection) {
          //       await initLiveStreamConnection(
          //         accessToken,
          //         selectedItem.id,
          //         userId
          //       );
          //     }
          //     joinLiveStreamGroup(selectedItem.id);
          //   }
          // );
        }
      });
    }
    return options.map(d => {
      let Icon = d.icon;
      return (
        <MenuItem
          key={d.key}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Source Sans Pro',
            color: '#192637'
          }}
          onClick={d.onClick}
        >
          <ListItemIcon style={{ minWidth: 34 }}>
            <Icon style={{ color: '#CACFD3' }} />
          </ListItemIcon>
          {d.label}
        </MenuItem>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);
  return (
    <>
      <Modal open={!!waitingSetupLs}>
        <div>
          <ModalPresenter
            style={{
              padding: 30,
              paddingLeft: 50,
              paddingRight: 50
            }}
            showFooter={false}
            onClose={() => {}}
            Icon={
              <LottieControl
                animationData={loadingAnimation}
                width={80}
                height={80}
              />
            }
            title="Waiting for starting Livestream"
            onClick={() => {}}
          >
            <Typography>
              Please wait, it may take a while for starting your livestream
            </Typography>
          </ModalPresenter>
        </div>
      </Modal>
      {calling && (
        <LivestreamScreen
          channelData={channelData}
          userId={userId}
          selectedLivestream={selectedItem}
        />
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderMenuItem()}
      </Menu>
      <Dialog
        maxWidth={false}
        open={showModalLivestream}
        onClose={onCloseModalLivestream}
        scroll="body"
      >
        <LivestreamFormModalPage
          livestreamDetail={selectedItem}
          typeModal={TYPE_MODAL.View}
          onCloseModal={onCloseModalLivestream}
          backToBasicForm={handleBackToBasicForm}
          notAdminView
        />
      </Dialog>
      <Dialog
        className=""
        open={showBasicModal}
        onClose={onCloseBasicModal}
        maxWidth="md"
        scroll="body"
      >
        <BasicFormModalPage
          typeModal={TYPE_MODAL.View}
          onCloseModal={onCloseBasicModal}
          livestreamDetail={selectedItem}
          onSaveData={handleBackToBasicForm}
          notAdminView
        />
      </Dialog>
      <TableContainer
        title="Upcoming Workouts"
        paging={paging}
        data={data}
        header={Header}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        query={upcomingDispatcher.getLivestreamList}
        ToolbarComponent={
          <SearchLivestream
            filterOption={filterOption}
            setFilterOption={setFilterOption}
          />
        }
        renderRow={row => (
          <>
            <TableCell>{!hiddenRows.title && row.title}</TableCell>
            <TableCell>
              {!hiddenRows.date &&
                moment(row.startLivestreamDate).format(FORMAT_DATE_TIME)}
            </TableCell>
            <TableCell>{!hiddenRows.intensity && row.intensity}</TableCell>
            <TableCell>
              {!hiddenRows.duration && <span>{row.duration} minutes</span>}
            </TableCell>
            <TableCell>
              {!hiddenRows.calories && <span>{row.calories} kcal</span>}
            </TableCell>
            <TableCell>{!hiddenRows.description && row.description}</TableCell>
            <TableCell>{!hiddenRows.equipment && row.equipment}</TableCell>
            <TableCell>
              {!hiddenRows.status && (
                <ChipStatus color={LiveStreamStatusColor[row.liveStreamStatus]}>
                  {startCase(StatusDisplay[row.liveStreamStatus])}
                </ChipStatus>
              )}
            </TableCell>
            <TableCell align="right">
              <IconButton onClick={handleClick(row)}>
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
      ></TableContainer>
    </>
  );
};

export default UpcomingWorkout;
