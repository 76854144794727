import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  .MuiCheckbox-root {
    padding-left: 0;
  }
`;

export const ButtonSecondary = styled(ButtonEnhance)`
  margin: 0;
  height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  background: #fff;
  color: #ad5e99;
  border: 1px solid #ad5e99;
  font-weight: 400;
  margin-top: ${(p) => p.mt} svg {
    margin-right: 5px;
  }
  &:hover {
    background-color: #ffe9fb;
  }
`;

export const UploadAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EditUploadAction = styled.div`
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 2px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  width: 32px;
  height: 32px;

  &::hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
