import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  Typography,
  styled,
  withStyles
} from '@material-ui/core';
import { ReceiptIcon } from '@/assets/svg';

const CustomDialog = withStyles({
  paper: {
    width: 400
  }
})(Dialog);

const CustomIcon = styled(ReceiptIcon)({
  display: 'block',
  margin: '40px auto 30px'
});

const CustomContent = withStyles({
  root: {
    margin: '30px 50px',
    textAlign: 'center'
  }
})(Box);

const CustomTitle = withStyles({
  root: {
    color: '#333',
    fontSize: 32,
    fontFamily: 'Lato',
    fontWeight: 600
  }
})(Typography);

const CustomQuestion = withStyles({
  root: {
    color: '#666',
    fontSize: 16,
    fontFamily: 'OpenSans',
    marginTop: 16,
    whiteSpace: 'pre-wrap'
  }
})(Typography);

const CustomResult = withStyles({
  root: {
    color: '#333333',
    fontSize: 16,
    fontFamily: 'OpenSans',
    marginTop: 6
  }
})(Typography);

const CustomButtonActions = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 24px 40px',
    gap: '8px'
  }
})(Box);

const CustomButton = withStyles({
  root: {
    flex: 1
  },
  label: {
    fontSize: 18
  }
})(Button);

const BillingTypeConfirmationModal = ({
  billingType,
  onClose,
  onConfirm,
  open
}) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      disableEnforceFocus
      scroll="body"
    >
      <CustomIcon />
      <CustomContent>
        <CustomTitle>Change billing type</CustomTitle>

        <CustomQuestion>
          {`Are you sure you want to change\nthe billing type?`}
        </CustomQuestion>

        <CustomResult>
          The billing type will be set as {billingType}.
        </CustomResult>
      </CustomContent>

      <CustomButtonActions>
        <CustomButton
          variant="outlined"
          color="primary"
          size="large"
          onClick={onClose}
        >
          Cancel
        </CustomButton>
        <CustomButton
          variant="contained"
          color="primary"
          size="large"
          onClick={onConfirm}
        >
          Confirm
        </CustomButton>
      </CustomButtonActions>
    </CustomDialog>
  );
};

BillingTypeConfirmationModal.propTypes = {
  billingType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default BillingTypeConfirmationModal;
