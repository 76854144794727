import React from 'react';
import styled from 'styled-components';
import { TextField, Typography } from '@material-ui/core';
import LoadingButton from '../../../../components/LoadingButton';
import PhoneInput from '../../../../components/PhoneInput';
import UploadListImages from '../../../../components/UploadListImages';

const PersonalInfo = ({
  onSubmit,
  onChange,
  values,
  errors,
  loading,
  onDelete
}) => {
  return (
    <PersonalStyled>
      <Typography
        style={{
          fontSize: 24,
          letterSpacing: 0,
          fontWeight: 600
        }}
      >
        Create New Trainer Account
      </Typography>
      <TextFieldStyle
        error={errors.firstName}
        helperText={errors.firstName}
        label="First Name"
        value={values.firstName}
        onChange={onChange('firstName')}
      />
      <TextFieldStyle
        error={errors.lastName}
        helperText={errors.lastName}
        label="Last Name"
        value={values.lastName}
        onChange={onChange('lastName')}
      />
      {/* <TextFieldStyle
        label="Phone number"
        error={errors.mobile}
        helperText={errors.mobile}
        value={values.mobile}
        onChange={onChange('mobile')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneInput
                country={values.countryCode}
                onChange={onChange('countryCode')}
              />
            </InputAdornment>
          )
        }}
      /> */}
      <UploadListImages
        listImage={values.lstCertificates}
        onChange={onChange('lstCertificates')}
        onDelete={onDelete}
        error={errors.lstCertificates}
        label="Certificate"
      />
      <LoadingButton onClick={onSubmit} showLoader={loading}>
        Sign Up
      </LoadingButton>
    </PersonalStyled>
  );
};

export default PersonalInfo;

const PersonalStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  border-radius: 10px;
  background-color: white;
  padding: 40px 20px;
  transition: all 0.1s;
  .upload-container {
    margin-bottom: 10px;
    margin-top: 15px;
  }
`;
const TextFieldStyle = styled(TextField)`
  height: 40px;
  width: 340px;
  height: 80px;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
`;
const WrapperButtonUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    color: #ccc;
  }
`;
