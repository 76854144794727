import React, { useState, useEffect } from 'react';
import ModalPage, { ModalFooter } from './styled';
import {
  DialogContent,
  TextField,
  Grid,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import LoadingButton from '../../../components/LoadingButton';
import { ClearRounded } from '@material-ui/icons';
import { TYPE_MODAL } from '../../common/constants/typeModal';
import validateData from '../../../helpers/validationHelpers/validationSchema';
import userDispatcher from '../action';

const UserModal = ({ onClose, selectedItem, typeModal, searchKey }) => {
  const [formValue, setFormValue] = useState({ canIgnore: false });
  const [errors, setErrors] = useState({});
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowPassword2, setIsShowPassword2] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const isEdit = typeModal === 'Edit' ? true : false;

  const schema = isEdit ? 'userSchemaEdit' : 'userSchema';

  useEffect(() => {
    if (isEdit) setFormValue({ ...selectedItem, password: '', password2: '' });
  }, [isEdit, selectedItem]);

  const handleClickShowPassword = type => {
    if (type === 'password') {
      setIsShowPassword(prevState => !prevState);
    } else if (type === 'password2') {
      setIsShowPassword2(prevState => !prevState);
    }
  };

  const handleSubmit = async () => {
    try {
      await validateData(schema, formValue, validData => {
        const { password2, ...restparam } = validData;
        setShowLoader(true);
        userDispatcher.editData(
          restparam,
          searchKey,
          onClose,
          () => setShowLoader(false),
          isEdit
        );
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const handleChangeForm = key => e => {
    setFormValue({ ...formValue, [key]: e.target.value });
  };

  return (
    <ModalPage>
      <DialogContent>
        <div className="test-header">
          {typeModal === TYPE_MODAL.Create && 'Create New User'}
          {typeModal === TYPE_MODAL.Edit && selectedItem.fullName}
          <ClearRounded
            style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
            onClick={onClose}
          />
        </div>
        <div className="test-body">
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12}>
              <TextField
                error={errors.firstName}
                helperText={errors.firstName}
                label="First name"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.firstName}
                onChange={handleChangeForm('firstName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.lastName}
                helperText={errors.lastName}
                label="Last name"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.lastName}
                onChange={handleChangeForm('lastName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.email}
                helperText={errors.email}
                label="Email"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.email}
                onChange={handleChangeForm('email')}
              />
            </Grid>
            {typeModal === TYPE_MODAL.Create && (
              <>
                <Grid item xs={12}>
                  <TextField
                    type={isShowPassword ? 'text' : 'password'}
                    error={errors.password}
                    helperText={errors.password}
                    label="Password"
                    style={{ width: '100%', marginTop: 10 }}
                    value={formValue.password}
                    onChange={handleChangeForm('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('password')}
                          >
                            {isShowPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={isShowPassword2 ? 'text' : 'password'}
                    error={errors.password2}
                    helperText={errors.password2}
                    label="Confirm Password"
                    style={{ width: '100%', marginTop: 10 }}
                    value={formValue.password2}
                    onChange={handleChangeForm('password2')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('password2')}
                          >
                            {isShowPassword2 ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
        <ModalFooter>
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={onClose}
          >
            Cancel
          </ButtonEnhance>

          <LoadingButton onClick={handleSubmit} showLoader={showLoader}>
            {typeModal === TYPE_MODAL.Edit ? 'Update' : 'Create'}
          </LoadingButton>
        </ModalFooter>
      </DialogContent>
    </ModalPage>
  );
};

export default UserModal;
