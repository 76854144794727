/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Paper, TextField, makeStyles, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import './styles.scss';
import TabsUI from '@/module/common/componentUI/TabsUI';
import GenderSection from './GenderSection';

const useStyles = makeStyles(theme => ({
  container: {
    border: '2px solid #ecedf0',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    boxShadow: 'none',
    borderRadius: 15,
    '&:hover': {
      border: '2px solid #ad5e99',
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      boxShadow: 'none',
      borderRadius: 15
    }
  },
  tabContainer: {
    border: '2px solid #ecedf0',
    padding: theme.spacing(2),
    boxShadow: 'none',
    borderRadius: 15
  },
  deleteIcon: {
    fontSize: 18,
    cursor: 'pointer',
    pointerEvents: 'auto',
    '&:hover': {
      color: 'red',
      fontSize: 18,
      cursor: 'pointer'
    }
  }
}));

const AnswerItem = ({
  data: { answer, suggestionByGenders },
  error,
  type,
  onChangeAnswer,
  onRemoveAnswer,
  ...props
}) => {
  const classes = useStyles();
  const maleSuggestion = suggestionByGenders[0];
  const femaleSuggestion = suggestionByGenders[1];
  const otherSuggestion = suggestionByGenders[2];

  const [currentTab, setCurrentTab] = useState(0);

  const userDetailTabs = [
    {
      label: 'Male',
      body: <GenderSection {...props} suggestion={maleSuggestion} />
    },
    {
      label: 'Female',
      body: <GenderSection {...props} suggestion={femaleSuggestion} />
    },
    {
      label: 'Other',
      body: <GenderSection {...props} suggestion={otherSuggestion} />
    }
  ];
  if (type === 'FreeText') return null;
  return (
    <Paper className={classes.container}>
      <DeleteIcon className="icon-delete-upload" onClick={onRemoveAnswer} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Answer"
            required
            multiline
            rowsMax={3}
            fullWidth
            value={answer || ''}
            onChange={e => onChangeAnswer(e.target.value)}
            error={error && !answer}
            helperText={error && !answer ? 'Answer is required' : null}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper fullWidth className={classes.tabContainer}>
            <Grid item container>
              <Grid item xs={12} md={12}>
                <TabsUI
                  listTab={userDetailTabs}
                  value={currentTab}
                  onChange={(e, val) => setCurrentTab(val)}
                  renderLabel="label"
                  renderKey="label"
                />
              </Grid>
              {userDetailTabs[currentTab].body}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AnswerItem;
