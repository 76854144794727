export const ROLE_TYPES = {
  SUPER_ADMIN: 'SuperAdmin',
  DOCTOR: 'Doctor',
  PATIENT: 'Patient',
  CA: 'CA',
  FAMILY_MEMBER: 'FamilyMember',
  MANAGER: 'Manager',
  TRAINER: 'Trainer',
  INTERNAL_TRAINER: 'InternalTrainer',
  MHS: 'MHS',
  SYSTEM_ADMIN: 'SystemAdmin',
  TELE_FIT_ADMIN: 'TeleFITAdmin',
  TELE_FIT_SUPER_ADMIN: 'TeleFITSuperAdmin',
  TELE_FIT_STUDIO: 'TeleFITStudio',
  CSO: 'CSO',
  SWABBER: 'Swabber',
  COUNSELLOR: 'Counsellor',
  COUNSELLOR_EXECUTIVE: 'CounsellorExecutive',
};

export const ROLES_CONSULT_TYPE_PAYMENT = [
  ROLE_TYPES.SYSTEM_ADMIN,
  ROLE_TYPES.CA,
  ROLE_TYPES.SUPER_ADMIN,
  ROLE_TYPES.SWABBER,
];

export const ROLES_NAMES_SPECIAL = [
  ROLE_TYPES.DOCTOR,
  ROLE_TYPES.SWABBER,
  ROLE_TYPES.COUNSELLOR,
];

export const ROLES_CANNOT_REVERT_CONSULT = [ROLE_TYPES.CA, ROLE_TYPES.SWABBER];
