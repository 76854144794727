import React, { useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import {
  makeStyles,
  TextField,
  ClickAwayListener,
  Grid
} from '@material-ui/core';
import { DatePicker, PickersDay } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { foundIndexOfDate } from '../../helpers';
import styled from 'styled-components';
import { map } from 'lodash';

const useStyles = makeStyles(theme => ({
  highlight: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  firstHighlight: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  },
  endHighlight: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  }
}));

const WeekPicker = ({
  onChange,
  listDates,
  label,
  disablePast,
  disableCloseOnSelect,
  error,
  grid
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const renderPickerDay = (date, _selectedDates, DayComponentProps) => {
    const foundIndex = foundIndexOfDate(listDates, date);

    return (
      <PickersDay
        {...DayComponentProps}
        listDates
        className={classnames(
          foundIndex >= 0 && {
            [classes.highlight]: true,
            [classes.firstHighlight]: true,
            [classes.endHighlight]: true
          }
        )}
      />
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Grid item xs={grid || 6}>
        <DatePicker
          disableCloseOnSelect={disableCloseOnSelect}
          showDaysOutsideCurrentMonth
          label={label}
          disablePast={disablePast}
          open={open}
          onOpen={() => setOpen(true)}
          onChange={onChange}
          value={listDates && !!listDates.length ? listDates : ''}
          renderDay={renderPickerDay}
          renderInput={props => {
            const value = map(listDates, date =>
              moment(date).format('DD/MM/YYYY')
            )
              .sort()
              .join(', ');
            return (
              <FieldWrapper
                style={{ width: '100%' }}
                className="date-picker-custom"
                {...props}
                inputProps={{ value: value }}
                error={error}
                helperText={error}
              />
            );
          }}
        />
      </Grid>
    </ClickAwayListener>
  );
};

const FieldWrapper = styled(TextField)`
  .MuiInputBase-input {
    text-overflow: ellipsis;
  }
`;

WeekPicker.defaultProps = {
  disablePast: true,
  disableCloseOnSelect: true
};

WeekPicker.propTypes = {
  listDates: PropTypes.array.isRequired,
  onchange: PropTypes.func.isRequired,
  disablePast: PropTypes.bool,
  label: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool
};

export default WeekPicker;
