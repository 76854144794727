export const LIST_FIELD = [
  { label: 'Text area', key: 'FreeText' },
  { label: 'Single select', key: 'SingleChoice' },
  { label: 'Multiple select', key: 'MultipleChoice' }
];

export const ANSWER_TYPE_LIST_FIELD = [
  'FreeText',
  'SingleChoice',
  'MultipleChoice'
];

export const FIELD_ARRAY_VALUE = ['SingleChoice', 'MultipleChoice'];

export const ANSWER_TYPE = {
  FreeText: 'Text area',
  SingleChoice: 'Single select',
  MultipleChoice: 'Multiple select'
};

export const COLUMN_SURVEY = [
  {
    label: 'Name',
    stateValue: 'name'
  },
  {
    label: 'Description',
    stateValue: 'description'
  },
  {
    label: 'Updated at',
    stateValue: 'updatedAt'
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 100
  }
];

export const COLUMN_CATEGORY = [
  {
    label: 'Category',
    stateValue: 'categoryName'
  },
  {
    label: 'Number of questions',
    stateValue: 'questions'
  },
  {
    label: '',
    stateValue: 'action'
  }
];
