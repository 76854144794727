import { FILTER_STATUSES, FILTER_TAGS } from './constants';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { Button, Toolbar, Box, withStyles } from '@material-ui/core';
import { isBoolean, isEmpty } from 'lodash';
import React, { useMemo } from 'react';

const CustomButton = withStyles({
  label: {
    fontWeight: 600,
  },
})(Button);

const GroupToolbar = ({
  isLocation = false,
  searchKey,
  filterOption,
  onFilterOptionClear,
  onFilterOptionChange,
  onResetFilterOps,
}) => {
  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(filterOption);
    return (
      isEmpty(searchKey) &&
      filterKeys.every((key) =>
        isBoolean(filterOption[key])
          ? !filterOption[key]
          : isEmpty(filterOption[key])
      )
    );
  }, [filterOption, searchKey]);

  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gridGap={12}>
          {isLocation && (
            <CustomSelectContainer
              isFullWidth
              filterValue={filterOption.locationTypes || []}
              data={FILTER_TAGS}
              renderValue={
                !isEmpty(filterOption.locationTypes)
                  ? `Tags (${filterOption.locationTypes.length})`
                  : 'All tags'
              }
              onClear={onFilterOptionClear('locationTypes')}
              onChange={onFilterOptionChange('locationTypes')}
              labelSelectAll={'All tags'}
              useSelectAll
              checkedAll={
                FILTER_TAGS.length === filterOption.locationTypes.length
              }
            />
          )}

          <CustomSelectContainer
            isFullWidth
            filterValue={filterOption?.statuses || []}
            data={FILTER_STATUSES}
            renderValue={
              !isEmpty(filterOption?.statuses)
                ? `Statuses (${filterOption?.statuses?.length})`
                : 'All statuses'
            }
            onClear={onFilterOptionClear('statuses')}
            onChange={onFilterOptionChange('statuses')}
            labelSelectAll={'All statuses'}
            useSelectAll
            checkedAll={
              FILTER_STATUSES.length === filterOption?.statuses?.length
            }
          />
        </Box>
        <CustomButton disabled={isEmptyFilterParams} onClick={onResetFilterOps}>
          Reset
        </CustomButton>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;
