import React, { useEffect, useState, memo, useRef } from 'react';
import JsPdfExtended from './JsPdfExtended';
import { Chart } from 'react-google-charts';
import { isMobile } from '@/helpers';

export const PdfExport = memo(({ paxScreening, onDone }) => {
  const [chartBMI, setChartBMI] = useState(null);
  const chartData = [['Year', 'Weight', 'BMI']].concat(
    paxScreening.trendingWeight.map((weight, index) => {
      return [
        `${weight.year}`,
        parseFloat(weight.value),
        parseFloat(paxScreening.trendingBMI[index].value)
      ];
    })
  );
  useEffect(() => {
    if (chartBMI !== null) {
      var _doc = new JsPdfExtended();
      _doc
        .addCover(paxScreening)
        .addHealthScreening()
        .addExaminationResults({ paxScreening, chartBMI, chartData })
        .addBloodPressure(paxScreening)
        .addFatAnalysis(paxScreening)
        .addCholesterol(paxScreening)
        .addGlucoseTest(paxScreening)
        .addAdditionalTest(paxScreening)
        .addReferralLetter(paxScreening)
        .addPagingAll();

      const fileName = 'Health Screening Report.pdf';

      if (isMobile()) {
        var oldBlob = _doc.output('blob');
        const blob = new Blob([oldBlob], {
          type: 'application/pdf;charset=utf-8'
        });

        const url = window.URL.createObjectURL(blob);

        var a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';

        a.href = url;
        a.download = fileName;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 2000);
      } else _doc.save(fileName);
      onDone();
    }
  }, [chartBMI]);
  return (
    <>
      <Chart
        style={{ position: 'absolute', top: -999 }}
        width={'600px'}
        height={'400px'}
        chartType="LineChart"
        data={chartData}
        options={{
          chartArea: { left: 50, top: 50, width: '90%', height: '75%' },
          vAxis: {
            maxValue: 160
          },
          series: {
            0: { curveType: 'function', color: '#e7728d' },
            1: { curveType: 'function', color: '#ffcdd9' }
          },
          legend: { position: 'top', alignment: 'start' }
        }}
        chartEvents={[
          {
            eventName: 'ready',
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart();
              setChartBMI(chart.getImageURI());
            }
          },
          {
            eventName: 'error',
            callback: () => {
              // catch when having issues with chart
              setChartBMI(undefined);
            }
          }
        ]}
      />
    </>
  );
});
