import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TableContainer from '../../../components/Table/TableContainer';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  Modal,
  Dialog,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import doctorDispatcher from '../action/doctor';
import {
  CallRounded,
  MailRounded,
  MoreVertRounded,
  DeleteRounded,
  CheckCircleRounded,
  VisibilityRounded,
  CreateRounded,
  CancelRounded
} from '@material-ui/icons';
import { UnselectIcon, SelectIcon } from '../../../assets/svg';
import styled from 'styled-components';
import { Avatar } from 'antd';
import { doctorStatus, userStatus } from '../../../enum/RequestEnum';
import DetailPresenter from './DetailPresenter';
import { UserOutlined } from '@ant-design/icons';
import Rating from '@material-ui/lab/Rating';
import { get } from 'lodash';
import { countries } from '../../../countries';
import ModalPresenter from '../../../components/ModalPresenter';
import moment from 'moment';
import { DATE_FORMAT_2 } from '../../common';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import { momentFormatted, getNationalNumber } from '../../../helpers';

const DoctorManagement = () => {
  const [searchKey, setSearchKey] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [deActiveModal, setDeactiveModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [hiddenRows, setHiddenRows] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const { list: data, paging, teleConsultStatus } = useSelector(
    state => state.doctor
  );
  const handleClick = item => event => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItem = () => {
    if (!selectedItem) return [];
    let options = [
      {
        key: 'delete',
        icon: DeleteRounded,
        label: 'Delete',
        onClick: () => {
          setDeleteModal(true);
          handleClose();
        }
      }
    ];
    if (selectedItem.approveStatus === doctorStatus.Pending) {
      options.unshift({
        key: 'approve',
        icon: CheckCircleRounded,
        label: 'Approve',
        onClick: () => {
          setDetailModal({
            isApprove: true
          });
          handleClose();
        }
      });
    } else if (selectedItem.approveStatus === doctorStatus.Approved) {
      if (selectedItem.activeStatus === userStatus.InActive)
        options.unshift({
          key: 'active',
          icon: CheckCircleRounded,
          label: 'Activate',
          onClick: () => {
            setActiveModal(true);
            handleClose();
          }
        });
      else
        options.unshift({
          key: 'deactive',
          icon: CancelRounded,
          label: 'Deactivate',
          onClick: () => {
            setDeactiveModal(true);
            handleClose();
          }
        });
      options.unshift({
        key: 'edit',
        icon: CreateRounded,
        label: 'Edit',
        onClick: () => {
          setDetailModal({
            isViewing: false
          });
          handleClose();
        }
      });
      options.unshift({
        key: 'view',
        icon: VisibilityRounded,
        label: 'View',
        onClick: () => {
          setDetailModal({
            isViewing: true
          });
          handleClose();
        }
      });
    }
    return options.map(d => {
      let Icon = d.icon;
      return (
        <MenuItem
          key={d.key}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Source Sans Pro',
            color: '#192637'
          }}
          onClick={d.onClick}
        >
          <ListItemIcon style={{ minWidth: 34 }}>
            <Icon style={{ color: '#CACFD3' }} />
          </ListItemIcon>
          {d.label}
        </MenuItem>
      );
    });
  };

  const onChangeDoctorStatus = () => {
    doctorDispatcher.onChangeTeleConsultStatus(!teleConsultStatus);
    setConfirmModal(false);
  };

  useEffect(() => {
    doctorDispatcher.getClinics();
    doctorDispatcher.getTeleConsultStatus();
    return () => {};
  }, []);

  const Header = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper
          style={{ paddingLeft: 20 }}
          label="MCR"
          stateValue="mrc"
        />
        <TableCellFiltedWrapper label="Doctor" stateValue="doctor" />
        <TableCellFiltedWrapper label="Contact" stateValue="contact" />
        <TableCellFiltedWrapper label="Last Consult" stateValue="lastConsult" />
        <TableCellFiltedWrapper label="Coverage" stateValue="coverage" />
        <TableCellFiltedWrapper
          label="Paynow/Paylah Status"
          stateValue="payStatus"
        />
        <TableCellFiltedWrapper label="Status" stateValue="status" />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
  return (
    <>
      <Modal open={!!deleteModal} onClose={() => setDeleteModal(false)}>
        <div>
          <ModalPresenter
            onClose={() => setDeleteModal(false)}
            Icon={<DeleteRounded style={{ fontSize: 80, color: '#EA6B75' }} />}
            title="Delete Profile?"
            onClick={() => {
              doctorDispatcher.deleteUser(selectedItem.id, searchKey);
              setDeleteModal(false);
            }}
          >
            <Typography>Click “Continue” to delete</Typography>
            <Typography>
              <Typography style={{ fontWeight: 600 }} display={'inline'}>
                {selectedItem.fullName}
              </Typography>
              ’s profile.
            </Typography>
          </ModalPresenter>
        </div>
      </Modal>
      <Modal open={confirmModal} onClose={() => setConfirmModal(false)}>
        <div>
          <ModalPresenter
            onClose={() => setConfirmModal(false)}
            Icon={teleConsultStatus ? <UnselectIcon /> : <SelectIcon />}
            title={`Doctors Are ${
              teleConsultStatus ? 'Unavailable' : 'Available'
            }?`}
            onClick={onChangeDoctorStatus}
          >
            <Typography>Click “Continue” to mark all doctors as</Typography>
            <Typography>
              {`${
                teleConsultStatus
                  ? 'unavailable and disable'
                  : 'available and enable'
              } teleconsult.`}
            </Typography>
          </ModalPresenter>
        </div>
      </Modal>
      <Dialog
        open={!!detailModal}
        onClose={() => setDetailModal(false)}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        style={{ padding: 0 }}
      >
        <DetailPresenter
          selectedItem={selectedItem}
          detailModal={detailModal}
          searchKey={searchKey}
          onClose={() => setDetailModal(false)}
        />
      </Dialog>
      <Modal open={!!activeModal} onClose={() => setActiveModal(false)}>
        <div>
          <ModalPresenter
            onClose={() => setActiveModal(false)}
            Icon={
              <CheckCircleRounded style={{ fontSize: 80, color: '#62CAA4' }} />
            }
            title="Activate Profile?"
            onClick={() => {
              doctorDispatcher.activeDoctor(selectedItem.id, true, searchKey);
              setActiveModal(false);
            }}
          >
            <Typography>Click “Continue” to activate</Typography>
            <Typography>
              <Typography style={{ fontWeight: 600 }} display={'inline'}>
                {selectedItem.fullName}
              </Typography>
              ’s profile.
            </Typography>
          </ModalPresenter>
        </div>
      </Modal>
      <Modal open={!!deActiveModal} onClose={() => setDeactiveModal(false)}>
        <div>
          <ModalPresenter
            onClose={() => setDeactiveModal(false)}
            Icon={<CancelRounded style={{ fontSize: 80, color: '#EA6B75' }} />}
            title="Deactivate Profile?"
            onClick={() => {
              doctorDispatcher.activeDoctor(selectedItem.id, false, searchKey);
              setDeactiveModal(false);
            }}
          >
            <Typography>Click “Continue” to deactive</Typography>
            <Typography>
              <Typography style={{ fontWeight: 600 }} display={'inline'}>
                {selectedItem.fullName}
              </Typography>
              ’s profile.
            </Typography>
          </ModalPresenter>
        </div>
      </Modal>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderMenuItem()}
      </Menu>
      <TableContainer
        title="Doctor Management"
        paging={paging}
        data={data}
        header={Header}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={doctorDispatcher.getData}
        className="justify-between"
        ToolbarComponent={
          <ControlLabelWrapper
            control={
              <Checkbox
                checked={!teleConsultStatus}
                onChange={() => setConfirmModal(true)}
                color="primary"
              />
            }
            label="Mark All Doctors As Unavailable"
            labelPlacement="start"
          />
        }
        onAddNew={() => {
          setSelectedItem({});
          setDetailModal({
            isAddNew: true
          });
        }}
        renderRow={d => (
          <>
            <TableCell
              style={{
                paddingLeft: 20,
                color: '#AD5E99',
                textDecoration: 'underline'
              }}
            >
              {!hiddenRows.mrc && d.mcr}
            </TableCell>
            <TableCell>{!hiddenRows.doctor && <DoctorInfo {...d} />}</TableCell>
            <TableCell style={{ textDecoration: 'underline' }}>
              {!hiddenRows.contact && <ContactInfo {...d} />}
            </TableCell>
            <TableCell>
              {!hiddenRows.lastConsult &&
                momentFormatted(d.lastConsult, DATE_FORMAT_2)}
            </TableCell>
            <TableCell>{!hiddenRows.coverage && d.coverage}</TableCell>
            <TableCell>
              {!hiddenRows.payStatus && (
                <div style={{ display: 'flex' }}>
                  <StastusInfo {...d}>
                    {d.approveStatus === doctorStatus.Approved
                      ? d.activeStatus
                      : d.approveStatus}
                  </StastusInfo>
                </div>
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.status && (
                <StastusInfo {...d}>
                  {d.approveStatus === doctorStatus.Approved
                    ? d.activeStatus
                    : d.approveStatus}
                </StastusInfo>
              )}
            </TableCell>
            <TableCell align="right">
              <IconButton onClick={handleClick(d)}>
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
      />
    </>
  );
};

const DoctorInfo = ({
  imageUrl,
  fullName,
  clinicName,
  clinicAddress,
  clinicUnitNumber,
  averageRate = 0
}) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          minWidth: 200
        }}
      >
        <div style={{ width: 40 }}>
          <Avatar src={imageUrl} size={40} icon={<UserOutlined />} />
        </div>
        <div style={{ marginLeft: 5 }}>
          <Typography style={{ fontSize: 16, fontWeight: 600 }}>
            {fullName}
          </Typography>
          <Typography style={{ color: '#8F9BA9' }}>
            {[clinicName, clinicAddress, clinicUnitNumber]
              .filter(e => e)
              .join(', ')}
          </Typography>
        </div>
      </div>
      <Rating
        name="simple-controlled"
        value={averageRate}
        style={{ marginLeft: 40, opacity: 1 }}
        disabled
      />
    </div>
  );
};

const ContactInfo = ({ email, mobile, countryCode }) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 3
        }}
      >
        <Avatar
          style={{
            backgroundColor: '#62CAA4',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 5
          }}
          size={20}
          icon={<CallRounded style={{ fontSize: 10 }} />}
        />
        <Typography style={{ color: '#192637' }}>
          {getNationalNumber(mobile, countryCode, true)}
        </Typography>
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Avatar
          style={{
            backgroundColor: '#33ADF0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 5
          }}
          size={20}
          icon={<MailRounded style={{ fontSize: 10 }} />}
        />
        <Typography style={{ color: '#192637' }}>{email}</Typography>
      </div>
    </div>
  );
};

const StastusInfo = styled.div`
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  width: fit-content;
  background-color: ${props =>
    props.approveStatus === doctorStatus.Approved
      ? props.activeStatus === userStatus.Active
        ? '#62CAA4'
        : '#EA6B75'
      : props.approveStatus === doctorStatus.Pending
      ? '#CACFD3'
      : '#EA6B75'};
`;

const ControlLabelWrapper = styled(FormControlLabel)`
  align-self: flex-end;
  margin-right: 20px !important;
`;
export default DoctorManagement;
