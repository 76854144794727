import { ArrowDownCircle, MailIcon } from '@/assets/svg';

const renderListAction = event => {
  const LIST_ACTIONS = [
    {
      key: 'email',
      icon: MailIcon,
      label: 'Email Lab',
      onClick: id => event('email', id)
    },
    {
      key: 'export',
      icon: ArrowDownCircle,
      label: 'Export',
      onClick: id => event('export', id)
    }
  ];
  return LIST_ACTIONS;
};

export default renderListAction;
