import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import PropTypes from 'prop-types';
import './styles.scss';

const SelectInlineEnhance = ({ options, label, value, onSelect, disabled }) => {
  const renderOption = () => {
    return options.map((option, index) => (
      <FormControlLabel
        className={`item ${option.value === value ? 'selected' : ''}`}
        key={option.value + index}
        value={option.value}
        control={<Radio color="primary" />}
        label={option.label}
      />
    ));
  };

  const handleChangeSelect = e => {
    const { value } = e.target;
    onSelect(value);
  };

  return (
    <FormControl
      disabled={disabled}
      className="select-inline-container"
      component="fieldset"
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        value={value}
        onChange={handleChangeSelect}
        className="select-inline-custom"
      >
        {renderOption()}
      </RadioGroup>
    </FormControl>
  );
};

SelectInlineEnhance.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.array,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SelectInlineEnhance.defaultProps = {
  options: [
    { label: 'Test 1', value: 'test1' },
    { label: 'Test 2', value: 'test2' }
  ],
  label: 'Select Stream Option',
  onSelect: () => null,
  value: 'test1',
  disabled: false
};

export default SelectInlineEnhance;
