import telecounselAppointmentDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0,
  },
  listAvailableDoctors: [],
};

const telecounselAppointmentReducer = telecounselAppointmentDispatcher(
  initialState,
  {
    [telecounselAppointmentDispatcher.getDataSuccess]: (state, payload) => ({
      list: payload.data.list,
      paging: {
        ...payload.data,
        list: undefined,
      },
    }),
    [telecounselAppointmentDispatcher.getAvailableDoctorSuccess]: (
      state,
      payload
    ) => ({
      listAvailableDoctors: payload.data.list,
    }),
    [telecounselAppointmentDispatcher.getAvailableUserSuccess]: (
      state,
      payload
    ) => ({
      listAvailableUsers: payload.data.list,
    }),
  }
);

export default telecounselAppointmentReducer;
