import React, { useState } from 'react';
import { Group as GroupsIcon } from '@material-ui/icons';
import { Typography, MenuItem, Checkbox } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CustomSelect from '@/components/CustomSelect';
import MultipleSelection from '../../components/CustomSelect/MutipleSelection';
import waitingRoomDispatcher from '../../module/waiting-room/action/waitingRoom';
import {
  selectMemberAppointment,
  unSelectAlltMemberAppointment
} from '../../helpers/waitingRoomSignalR';
import {
  selectMemberAppointment as apptSelectMemberAppointment,
  unSelectAlltMemberAppointment as apptUnSelectAlltMemberAppointment
} from '../../helpers/appointmentSignalR';

import callDispatcher from '../../redux/booking-appointment/actions';

export default function SelectChildrenParticipants({
  shouldInvokeEvent,
  isAppt
}) {
  let dispatcher = isAppt ? callDispatcher : waitingRoomDispatcher;
  const [open, setOpen] = useState(false);
  const memberAppointment = useSelector(state =>
    isAppt
      ? state.callBookingAppointment.memberAppointment
      : state.waitingRoom.memberAppointment
  );

  const selectMember = isAppt
    ? apptSelectMemberAppointment
    : selectMemberAppointment;
  const unselectAllMember = isAppt
    ? apptUnSelectAlltMemberAppointment
    : unSelectAlltMemberAppointment;

  const onFilterOptionChange = async event => {
    let { index, isSelected } = (event.target.value || [{}])[0];
    if (index === undefined) return;

    if (shouldInvokeEvent) {
      let rs = await selectMember(
        memberAppointment[index]?.appointmentId,
        isSelected
      );
    }
    memberAppointment[index].isSelected = isSelected;

    let newMemberAppointment = memberAppointment?.map((i, _index) => {
      let item = { ...i };
      if (_index === index) item.isSelected = isSelected;
      return item;
    });
    dispatcher.getMemberAppointmentSuccess(newMemberAppointment);
  };

  const onClearAll = async () => {
    if (shouldInvokeEvent) {
      let rs = await unselectAllMember();
    }
    let newMemberAppointment = memberAppointment?.map(i => ({
      ...i,
      isSelected: false
    }));
    dispatcher.getMemberAppointmentSuccess(newMemberAppointment);
  };
  if (memberAppointment?.length === 0) return null;

  return (
    <>
      <Typography style={{ fontWeight: '600', fontSize: 16, marginBottom: 8 }}>
        Select Participants:{' '}
      </Typography>
      <CustomSelect
        onClick={() => {
          setOpen(!open);
        }}
        clearable={memberAppointment?.some(i => i.isSelected)}
        onClear={onClearAll}
        prefixIcon={
          <GroupsIcon
            style={{
              fontSize: 20,
              color: '#E9E9E9',
              marginRight: 8
            }}
          />
        }
        renderValue={memberAppointment
          ?.filter(i => i.isSelected)
          ?.map(i => i.memberFullName)
          ?.join(', ')}
      >
        <MultipleSelection
          value={[]}
          open={open}
          onChange={onFilterOptionChange}
        >
          {memberAppointment?.map((item, index) => (
            <MenuItem
              key={item.memberId}
              value={{
                index,
                memberId: item.memberId,
                isSelected: !item.isSelected
              }}
              style={{ fontSize: 14 }}
            >
              <Checkbox
                color="primary"
                checked={item.isSelected}
                style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
              />
              {item.memberFullName}
            </MenuItem>
          ))}
        </MultipleSelection>
      </CustomSelect>
    </>
  );
}
