import { uploadImage } from '@/firebase';
import { cloneDeep } from 'lodash';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  saveDataSuccess: (name, value) => ({ name, value }),
  getDataSuccess: (data) => ({ data }),
  setState: (state, value) => ({ state, value }),
  getCategoriesData:
    () =>
    async ({ Api }) => {
      const { result, status } = await Api.get(`ECommerces/categories`, {});

      if (status === 200) {
        let newResult = cloneDeep(result);
        newResult.list = newResult.list.filter((it) => it?.name);
        ecommerceDispatcher.saveDataSuccess('categories', newResult.list);
      }
    },
  getUnitsData:
    () =>
    async ({ Api }) => {
      const { result, status } = await Api.get(`ECommerces/products/units`, {});

      if (status === 200) ecommerceDispatcher.saveDataSuccess('units', result);
    },
  getProductsData:
    (search, paging, filterOption = {}) =>
    async ({ Api, getState }) => {
      let pagingData = paging || getState().ecommerce.paging;
      const { result, status } = await Api.post(`ECommerces/products/filter`, {
        pageIndex: pagingData.pageIndex,
        pageSize: pagingData.pageSize,
        search,
        ...filterOption,
      });
      if (status === 200) ecommerceDispatcher.getDataSuccess(result);
    },
  deleteProduct:
    (id, onSuccess) =>
    async ({ Api, customToast }) => {
      let { status } = await Api.delete(`ECommerces/products/${id}`);
      if (status === 200) {
        customToast('success', 'Deleted successfully');
        onSuccess();
      }
    },
  createProduct:
    (data, onSuccess) =>
    async ({ Api }) => {
      const {
        name,
        description,
        requiredConsultation,
        categoryId,
        unit,
        productPrices,
        listDetailImageUrls: listDetailImages,
      } = data;

      const listDetailImageUrls = await Promise.all(
        listDetailImages.map(async (image) => {
          if (image?.constructor === File) {
            const urlImage = await uploadImage(image);
            return urlImage;
          } else return image;
        })
      );

      const payload = {
        name,
        description,
        requiredConsultation,
        categoryId,
        unit,
        productPrices,
        listDetailImageUrls,
      };
      const { status } = await Api.post(`ECommerces/products`, payload);
      ecommerceDispatcher.setState('loading', false);
      if (status === 200) {
        onSuccess();
      }
    },
  updateProduct:
    (data, selectedItem, onSuccess) =>
    async ({ Api }) => {
      const {
        name,
        description,
        requiredConsultation,
        categoryId,
        unit,
        productPrices,
        listDetailImageUrls: listDetailImages,
      } = data;
      const { id } = selectedItem;
      const listDetailImageUrls = await Promise.all(
        listDetailImages.map(async (image) => {
          if (image?.constructor === File) {
            const urlImage = await uploadImage(image);
            return urlImage;
          } else return image;
        })
      );

      const payload = {
        name,
        description,
        requiredConsultation,
        categoryId,
        unit,
        productPrices,
        listDetailImageUrls,
      };

      const { status } = await Api.put(`ECommerces/products/${id}`, payload);
      ecommerceDispatcher.setState('loading', false);

      if (status === 200) {
        onSuccess();
      }
    },
  activateProduct:
    (id, isActive = false, onSuccess) =>
    async ({ Api, customToast }) => {
      const { status } = await Api.put(
        `ECommerces/products/${id}/active?isActive=${isActive}`
      );
      if (status === 200) {
        customToast(
          'success',
          `${isActive ? 'Activate' : 'Deactivate'} successfully`
        );
        onSuccess();
      }
    },
  getDetailProduct:
    (id, onSuccess) =>
    async ({ Api }) => {
      const { status, result } = await Api.get(`ECommerces/products/${id}`);
      if (status === 200) {
        onSuccess && onSuccess(result);
      }
    },
  exportOrderHistory:
    (search, paging, formValue, onSuccess) =>
    async ({ Api, getState }) => {
      let pagingData = paging || getState().ecommerce.paging;
      const { startDate, endDate } = formValue;
      const { result, status } = await Api.post(`ECommerceOrders/csv-report`, {
        pageIndex: pagingData.pageIndex,
        pageSize: pagingData.pageSize,
        search,
        startDate,
        endDate,
      });
      if (status === 200) {
        onSuccess(result);
      }
    },
};

const ecommerceDispatcher = synthesize('ecommerce', mapDispatchToAC);
export default ecommerceDispatcher;
