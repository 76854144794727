import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, filterOption = {}, sortOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().package.paging;
    let { result, status } = await Api.post(`Packages/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption,
      ...sortOption
    });
    status === 200 && packageDispatcher.getDataSuccess(result);
  },
  getPackagesData: () => async ({ Api }) => {
    let profilesApi = Api.get(`Profiles`);
    let [profilesRes] = await Promise.all([profilesApi]);
    if (profilesRes.status === 200)
      packageDispatcher.getDataSuccess(profilesRes.result.list || []);
  },
  getAllProfiles: callback => async ({ Api }) => {
    let { result, status } = await Api.get(`Profiles`);
    status === 200 && callback && callback(result.list);
  },
  getAllPackages: callback => async ({ Api }) => {
    let { result, status } = await Api.post(`Packages/filter`, {});
    status === 200 && callback && callback(result.list);
  },
  getProfileById: (id, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.get(`Profiles/${id}`);
    if (status === 200) {
      callbackSuccess(result);
    }
  },
  addPackage: (payload, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.post(`Packages`, payload);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  getPackageById: (id, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.get(`Packages/${id}`);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  updatePackage: (id, payload, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.put(`Packages/${id}`, payload);
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  deletePackage: (id, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.delete(`Packages/${id}`);
    if (status === 200) {
      callbackSuccess && callbackSuccess();
      customToast('success', 'Package was deleted');
    }
  }
};

const packageDispatcher = synthesize('package', mapDispatchToAC);
export default packageDispatcher;
