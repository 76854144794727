import teleConsultNowDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0,
  },
  calling: false,
  localStream: [],
  remoteStreams: [],
  isMuteAudio: false,
  isMuteVideo: false,
  isMuteSpeaker: false,
  isPatientMuteAudio: false,
  isPatientMuteVideo: false,
  isSharing: false,
  showChat: false,
  showNRIC: false,
  showSharing: false,
  channelId: null,
  callId: null,
  patientId: null,
  memberAppointment: null,
  consConfirmVisibility: false,
  verifyModalVisibility: false,
  waitingModalVisibility: false,
  verifyingUserData: {},
  rejoinCallData: null,
  startCallTime: '',
  reconnectRejoinCall: false,
  completedCallVisibility: false,
};

const teleConsultNowReducer = teleConsultNowDispatcher(initialState, {
  [teleConsultNowDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined,
    },
  }),
  [teleConsultNowDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value,
  }),
  [teleConsultNowDispatcher.resetData]: (state, _) => ({
    ...initialState,
  }),
  [teleConsultNowDispatcher.updateLocalStream]: (state, payload) => ({
    localStream: [...payload.streams],
  }),
  [teleConsultNowDispatcher.updateRemoteStream]: (state, payload) => ({
    remoteStreams: [...payload.streams],
  }),
});

export default teleConsultNowReducer;
