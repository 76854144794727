import React, { useEffect, useState, useMemo } from 'react';
import {
  TableCell,
  withStyles,
  IconButton,
  InputLabel,
  Typography,
  Box
} from '@material-ui/core';
import { cloneDeep, findIndex, isEmpty } from 'lodash';

import { IOSSwitch } from '@/module/setup/components/mainScreen.styled';
import { TableWrapped } from '@/module/checkin-patient-information/styled';
import ServicesTable from '@/module/setup/components/ServiceItems/ServicesTable';
import CustomAutocompleteModal from '@/new-components/CustomAutocompleteModal';
import CustomTooltip from '@/new-components/CustomTooltip';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';

import { TABLE_STATE, renderColumns } from '@/module/setup/constants';
import { RemoveIcon } from '@/assets/svg';
import { mergeArrayByKey } from '@/helpers';

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 24
  }
})(Typography);

const CustomTableCell = withStyles({
  root: {
    fontSize: 16
  }
})(TableCell);

const CustomInputLabel = withStyles(theme => ({
  root: {
    fontWeight: 600,
    color: theme.palette.grey.subText,
    marginBottom: 8
  }
}))(InputLabel);

const OptionalServiceForm = ({
  column,
  outSiteColumn,
  titleTable,
  labelButton,
  placeholder,

  listKey,
  totalMandatoryKey,

  formValue,
  setFormValue,
  errors,
  getListOptions
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentState, setCurrentState] = useState(TABLE_STATE.Outside);
  const [listTests, setListTests] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const [numberSelected, setNumberSelected] = useState(0);
  const [unMandatoryData, setUnmandatoryData] = useState([]);
  const [mandatoryData, setMandatoryData] = useState([]);

  const columnsTable = renderColumns(column, ['price']);
  const isProfile = labelButton === 'profile';

  const listNumberOptional = useMemo(() => {
    let list = [{ label: 'None', value: 0 }];

    for (var i = 0; i < unMandatoryData.length; i++) {
      list.push({ label: String(i + 1), value: i + 1 });
    }

    return list;
  }, [unMandatoryData]);

  const onAddNewService = newSelected => {
    const selected = selectedItem.concat(newSelected);
    let newList = listTests
      .filter(item => selected.includes(item.id))
      .map(item => ({
        ...item,
        isRequired: true
      }));
    newList = mergeArrayByKey(newList, unMandatoryData, 'id');

    const mandatory = newList.filter(item => item.isRequired);
    const newMandatoryData = mandatory.map(item => ({
      ...item,
      isRequired: true
    }));

    const unMandatory = newList.filter(item => !item.isRequired);
    const newUnMandatoryData = unMandatory.map(item => ({
      ...item,
      isRequired: false
    }));

    setMandatoryData(newMandatoryData);
    setUnmandatoryData(newUnMandatoryData);

    const lstTests = newMandatoryData.concat(newUnMandatoryData);

    setFormValue({ ...formValue, [listKey]: lstTests });
    setCurrentState(TABLE_STATE.Outside);
    setShowModal(false);
    setSelectedItem(selected);
  };

  const onDeletePackage = (row, index) => {
    let newMandatoryData = cloneDeep(mandatoryData).filter(
      item => item.id !== row.id
    );
    let newUnMandatoryData = [...unMandatoryData].filter(
      item => item.id !== row.id
    );
    const listTests = newMandatoryData.concat(newUnMandatoryData);

    if (
      isEmpty(newUnMandatoryData) ||
      numberSelected > newUnMandatoryData.length
    ) {
      // Reset number selected optional if it > optional test
      setFormValue({
        ...formValue,
        [listKey]: listTests,
        [totalMandatoryKey]: 0
      });

      setNumberSelected(0);
    } else {
      setFormValue({ ...formValue, [listKey]: listTests });
    }

    setMandatoryData(newMandatoryData);
    setUnmandatoryData(newUnMandatoryData);
  };

  const onChangeValueRequired = e => {
    const value = e.target.value.value;

    setNumberSelected(value);
    setFormValue({ ...formValue, [totalMandatoryKey]: Number(value) });
  };

  const onChangeValueCheckbox = (value, row) => {
    const newData = { ...row, isRequired: value };
    let dataLocalUpdated = updateCheckedData(formValue[listKey], [newData]);
    const newListMandatory = [...dataLocalUpdated].filter(
      item => item.isRequired
    );
    const newListUnMandatory = [...dataLocalUpdated].filter(
      item => !item.isRequired
    );

    dataLocalUpdated = dataLocalUpdated.sort(
      (a, b) => b.isRequired - a.isRequired
    );
    if (
      isEmpty(newListUnMandatory) ||
      numberSelected > newListUnMandatory.length
    ) {
      setFormValue({
        ...formValue,
        [listKey]: dataLocalUpdated,
        [totalMandatoryKey]: 0
      });
      setNumberSelected(0);
    } else setFormValue({ ...formValue, [listKey]: dataLocalUpdated });
    setUnmandatoryData(newListUnMandatory);
    setMandatoryData(newListMandatory);
  };

  const updateCheckedData = (dataUpdated = [], testExists = []) => {
    const newDataLocal = [...dataUpdated];

    testExists.forEach(test => {
      const indexProject = findIndex(dataUpdated, it => it.id === test.id);

      if (indexProject < 0) return;
      const updatedProject = newDataLocal[indexProject];

      const indexTestChange = findIndex(
        newDataLocal,
        it => it.id === updatedProject.id
      );
      if (indexTestChange < 0) return;
      newDataLocal[indexTestChange] = { ...test };
    });

    return newDataLocal;
  };

  useEffect(() => {
    getListOptions(result => setListTests(result));
  }, [getListOptions]);

  useEffect(() => {
    if (!isEmpty(formValue)) {
      const newSelected = formValue[listKey].map(i => i.id);
      const mandatory = formValue[listKey].filter(item => item.isRequired);
      const unMandatoryData = formValue[listKey].filter(
        item => !item.isRequired
      );

      setMandatoryData(mandatory);
      setUnmandatoryData(unMandatoryData);
      setNumberSelected(formValue[totalMandatoryKey]);
      setSelectedItem(newSelected);
    }
  }, [formValue, listKey, totalMandatoryKey]);

  const renderRow = (row, i) => {
    return (
      <>
        <CustomTableCell>
          <CustomTooltip content={row.name} />
        </CustomTableCell>
        {isProfile ? (
          <>
            <CustomTableCell>{row.totalProfiles}</CustomTableCell>
            <CustomTableCell>{row.totalTests}</CustomTableCell>
          </>
        ) : (
          <CustomTableCell>
            <CustomTooltip content={row.stationName} />
          </CustomTableCell>
        )}

        {currentState === TABLE_STATE.Outside && (
          <CustomTableCell>
            <IOSSwitch
              checked={!row.isRequired}
              color="primary"
              name="isOptOut"
              size="medium"
              onChange={e => onChangeValueCheckbox(!e.target.checked, row)}
            />
          </CustomTableCell>
        )}
        <TableCell align="right">
          {currentState === TABLE_STATE.Outside && (
            <IconButton
              className="delete-icon"
              onClick={() => onDeletePackage(row, i)}
            >
              <RemoveIcon />
            </IconButton>
          )}
        </TableCell>
      </>
    );
  };

  return (
    <TableWrapped>
      <Box pt={2.5}>
        <CustomTitleLabel>Available {titleTable}</CustomTitleLabel>
        <Box width={'50%'}>
          <CustomInputLabel>
            Number of choice for optional tests
          </CustomInputLabel>
          <CustomSelectContainer
            isFullWidth
            filterValue={
              listNumberOptional.find(item => item.value === numberSelected) ||
              ''
            }
            data={listNumberOptional}
            renderValue={
              <>
                {listNumberOptional.find(item => item?.value === numberSelected)
                  ?.label || 'None'}
              </>
            }
            height="48px"
            isMutiple={false}
            onChange={onChangeValueRequired}
            disabled={isEmpty(listNumberOptional)}
            label="label"
          />
          {errors.numberSelected && (
            <Typography color="error">{errors.numberSelected}</Typography>
          )}
        </Box>
      </Box>
      <ServicesTable
        labelButton={`Add ${labelButton}`}
        columns={outSiteColumn}
        renderRow={renderRow}
        data={formValue[listKey].sort((a, b) => b.isRequired - a.isRequired)}
        onClick={e => {
          e && e.preventDefault();
          setCurrentState(TABLE_STATE.Modal);
          setShowModal(!showModal);
        }}
      />

      <CustomAutocompleteModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setCurrentState(TABLE_STATE.Outside);
        }}
        onSubmit={onAddNewService}
        data={listTests}
        submitLabel="Add"
        title={titleTable}
        columns={columnsTable}
        searchPlaceholder={placeholder}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        renderRow={renderRow}
      />
    </TableWrapped>
  );
};

export default OptionalServiceForm;
