import React, { useState } from 'react';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import TableContainer from '../../components/Table/TableContainer';
import styled from 'styled-components';
import {
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  Menu,
  IconButton
} from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { TYPE_MODAL } from '../common/constants/typeModal';
import MenuAction from '../../components/MenuAction';
import DeleteModal from '../../components/DeleteModal';
import mhsDispatcher from './action';
import MHSModal from './MHSModal';
import renderColumn from './renderColumn';
import renderListAction from './renderListAction';
import { COLOR_STATUS } from './constants';
import { getNationalNumber } from '../../helpers';

const MHSManagement = () => {
  const tableColumn = renderColumn();
  const { paging, list, loading } = useSelector(state => state.mhs);

  const [mhsSelected, setMHSSelected] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [typeModal, setTypeModal] = useState(null);

  const onCloseModal = () => {
    setShowModal(false);
    setTypeModal(null);
  };

  const onShowDeleteModal = () => {
    setDeleteModal(true);
    setAnchorEl(null);
  };

  const handleDeleteMHS = () => {
    mhsDispatcher.deleteData(mhsSelected.id, searchKey);
    setDeleteModal(false);
  };

  const onHandleModal = type => {
    switch (type) {
      case 'delete':
        onShowDeleteModal();
        break;
      case 'edit': {
        setShowModal(true);
        setTypeModal(TYPE_MODAL.Edit);
        setAnchorEl(null);
        break;
      }
      default:
        break;
    }
  };

  const onSubmitSuccess = () => {
    mhsDispatcher.getData(searchKey);
    setShowModal(false);
    setTypeModal(null);
  };

  const onSubmitMHS = formValue => {
    mhsDispatcher.setState('loading', true);
    if (typeModal === TYPE_MODAL.Create) {
      mhsDispatcher.createMHS(formValue, onSubmitSuccess);
    } else if (typeModal === TYPE_MODAL.Edit) {
      mhsDispatcher.updateMHS(
        { id: mhsSelected.id, data: formValue },
        onSubmitSuccess
      );
    }
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(({ label, stateValue }) => {
          if (!stateValue) return <TableCell key={label}></TableCell>;
          return (
            <TableCellFilted
              key={label}
              label={label}
              stateValue={stateValue}
              hiddenRows={hiddenRows}
              setHiddenRows={setHiddenRows}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );

  const listActions = renderListAction(onHandleModal);

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {<MenuAction listActions={listActions} />}
      </Menu>
      <TableContainer
        title="MHS Management"
        paging={paging}
        data={list}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={mhsDispatcher.getData}
        onAddNew={() => {
          setMHSSelected(null);
          setShowModal(true);
          setTypeModal(TYPE_MODAL.Create);
        }}
        renderRow={row => (
          <React.Fragment key={row.id}>
            <TableCell>
              {!hiddenRows.fullName && row.firstName + ' ' + row.lastName}
            </TableCell>
            <TableCell>{!hiddenRows.email && row.email}</TableCell>
            <TableCell>
              {!hiddenRows.mobile &&
                getNationalNumber(row.mobile, row.countryCode, true)}
            </TableCell>
            <TableCell>
              {!hiddenRows.activeStatus && (
                <StastusInfo status={row.activeStatus}>
                  {row.activeStatus}
                </StastusInfo>
              )}
            </TableCell>
            <TableCell align="right">
              <IconButton
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  setMHSSelected(row);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </React.Fragment>
        )}
      />
      <Dialog maxWidth="md" open={showModal} onClose={onCloseModal}>
        <MHSModal
          onClose={onCloseModal}
          selectedItem={mhsSelected}
          loading={loading}
          searchKey={searchKey}
          typeModal={typeModal}
          onSubmit={onSubmitMHS}
        />
      </Dialog>
      {deleteModal && (
        <DeleteModal
          keyTitle="fullName"
          showModal={deleteModal}
          selectedItem={mhsSelected}
          onClose={() => setDeleteModal(false)}
          onDelete={handleDeleteMHS}
          modalName="MHS"
        />
      )}
    </>
  );
};

const StastusInfo = styled.div`
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  width: fit-content;
  background-color: ${props => COLOR_STATUS[props.status]};
`;

export default MHSManagement;
