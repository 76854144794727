import { NewCalendar } from '@/assets/svg';
import { TextField } from '@material-ui/core';
import { EventRounded } from '@material-ui/icons';
import { LocalizationProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useRef } from 'react';
import { DATE_FORMAT_2 } from '../../module/common';
import CustomAdapter from '../CustomDate/CustomDateAdapter';
import CustomSelect from '../CustomSelect';
import { CustomDateRangePicker } from './styled';

const CustomDateRange = ({
  selectedDate,
  onChange,
  renderValue,
  clearable = true
}) => {
  const dateRangeRef = useRef();
  return (
    <LocalizationProvider dateAdapter={CustomAdapter}>
      <CustomDateRangePicker
        value={selectedDate}
        onChange={([startDate, endDate]) => {
          if (startDate && endDate) {
            onChange(startDate, moment(endDate).endOf('date'));
          }
        }}
        renderInput={(startProps, endProps) => (
          <CustomSelect
            onClick={() => {
              dateRangeRef.current.focus();
            }}
            clearable={clearable && selectedDate[0] && selectedDate[1]}
            onClear={onChange}
            suffixIcon={
              <NewCalendar
                style={{
                  marginLeft: 5
                }}
              />
            }
            height="36px"
            renderValue={
              renderValue ? (
                renderValue()
              ) : (
                <>
                  {`${
                    selectedDate[0]
                      ? selectedDate[0].format(DATE_FORMAT_2)
                      : ' From'
                  } - ${
                    selectedDate[1]
                      ? selectedDate[1].format(DATE_FORMAT_2)
                      : 'To'
                  }`}
                </>
              )
            }
          >
            <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
              <div className="header-border" />

              <TextField {...startProps} inputRef={dateRangeRef} />
              <TextField {...endProps} />
            </div>
          </CustomSelect>
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDateRange;
