import { ROLE_TYPES } from '@/constants/roles';
import { urlLabel } from '@/enum/PermissionEnum';
import { PrivateLayout } from '@/module/common';
import PrivatePatientLayout from '@/module/common/component/PrivatePatientLayout';
import TeleconsultNow from '@/module/teleconsult-now';
import { isEmpty } from 'lodash';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const isExistPath = (listPrivilege, path) => {
  //waiting for get privileges
  if (!listPrivilege.length) return true;
  //public route
  if ([urlLabel.home, urlLabel.profileManagement].includes(path)) return true;
  //check permission
  for (const index in listPrivilege) {
    if (listPrivilege[index].children) {
      if (path === listPrivilege[index].value) return true;
      //if path is parent route
      else {
        const result = isExistPath(listPrivilege[index].children, path);
        if (result) return result; //if route is exist in child => return, else continue
      }
    } else {
      if (path === listPrivilege[index].value) return true;
    }
  }
  return false;
};

const PrivateRoute = ({ component: Component, title, ...rest }) => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  // const patientInfo = useSelector((state) => state.globalState.patientInfo);

  return (
    <React.Fragment>
      <Route
        {...rest}
        render={(matchProps) => {
          if (rest.auth.accessToken) {
            if (userInfo.roleName === ROLE_TYPES.PATIENT) {
              return (
                <PrivatePatientLayout>
                  <Component {...matchProps} />
                </PrivatePatientLayout>
              );
            } else {
              return (
                <PrivateLayout title={title}>
                  <Component {...matchProps} />
                </PrivateLayout>
              );
            }
          }

          return (
            <Redirect
              to={`/${
                localStorage.getItem('roleName') === ROLE_TYPES.PATIENT ||
                userInfo.roleName === ROLE_TYPES.PATIENT
                  ? urlLabel.patientLogin
                  : urlLabel.login
              }`}
            />
          );
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    privilege: state.userPermission.privilege,
  };
};

export const WrapperPrivateRoute = connect(mapStateToProps)(PrivateRoute);
