import CustomButton from '../CustomButton';
import CustomDate from '../CustomDate';
import CustomModalTitle from './CustomModalTitle';
import { ArrowDown } from '@/assets/svg';
import validateData from '@/helpers/validationHelpers/validationSchema';
import { CustomAutocomplete } from '@/module/company/component/CreateAndEditCompany/styled';
import { getFullTimeInDay } from '@/module/setup/utils';
import CustomRadioBtn from '@/module/telecounsel-appointment-configuration/components/CustomRadioBtn';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  TextField,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 400,
  },
})(Dialog);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end',
  },
})(DialogActions);

const CustomInputLabel = withStyles((theme) => ({
  root: {
    color: '#666666',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}))(InputLabel);

const CustomPaper = withStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))(Paper);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
})(TextField);

const CustomFormHelperText = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'OpenSans',
    fontSize: 12,
    gap: 6,
    marginLeft: 4,
    marginTop: 0,

    '& .MuiSvgIcon-root': {
      fontSize: 'unset',
    },
  },
})(FormHelperText);

const ModalEditTimeslotAppt = ({ value, open, onClose, onSubmit }) => {
  const [formValue, setFormValue] = useState({
    date: moment(value.startDate),
    option: value.isActive ? 'open' : 'close',
    time: moment(value.startDate),
    baseSlot: value.baseSlot,
  });
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  let timeSlotsStartDefault = getFullTimeInDay(0, 24, 15);
  timeSlotsStartDefault.push({
    key: '11:59 PM',
    value: '11:59 PM',
  });

  const handleSubmit = async () => {
    try {
      await validateData(
        'validationEditBookingItem',
        formValue,
        (validData) => {
          if (Number(validData.baseSlot) < value.slot) {
            setErrors({
              ...errors,
              baseSlot: 'Total timeslots must be more than booked timeslots.',
            });
            return;
          }
          setErrors({ ...errors, baseSlot: '' });
          setShowLoader(true);
          onSubmit(
            {
              ...validData,
              date: moment.utc(validData.date).format(),
            },
            () => setShowLoader(false)
          );
        }
      );
    } catch (errs) {
      setErrors(errs);
    }
  };

  const handleChangeForm = (key) => (e, value) => {
    let newValue = '';

    switch (key) {
      case 'baseSlot':
        const regexNumber = /\D/;
        if (e.target.value && !e.target.value.match(regexNumber)) {
          newValue = e.target.value;
        }
        break;
      case 'option':
        newValue = e;
        break;
      default:
        newValue = e.target.value;
        break;
    }

    setFormValue({ ...formValue, [key]: newValue });
  };

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomModalTitle onClose={onClose}>Edit timeslot</CustomModalTitle>
      <CustomDialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomInputLabel>Date</CustomInputLabel>
            <CustomDate
              selectedDate={formValue.date ? moment(formValue.date) : ''}
              isFullWidth
              placeholder="Select date"
              clearable={false}
              disabled
              style={{ height: 48, fontSize: 16 }}
            />
            {errors.date && (
              <CustomFormHelperText error>{errors.date}</CustomFormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Start time</CustomInputLabel>
            <CustomAutocomplete
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option.value || ''}
              options={timeSlotsStartDefault || []}
              value={
                timeSlotsStartDefault.find(
                  (it) => it?.value === moment(formValue?.time).format('h:mm A')
                ) || ''
              }
              popupIcon={<ArrowDown />}
              renderInput={(params) => (
                <CustomTextField
                  variant="outlined"
                  placeholder="Select time"
                  {...params}
                />
              )}
              disableClearable
              PaperComponent={({ children, ...other }) => (
                <CustomPaper {...other}>{children}</CustomPaper>
              )}
              disabled
            />
            {errors.time && (
              <CustomFormHelperText error>{errors.time}</CustomFormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Number of slots</CustomInputLabel>
            <CustomTextField
              placeholder="Enter number"
              fullWidth
              variant="outlined"
              type="number"
              allowClear
              value={formValue.baseSlot}
              onChange={handleChangeForm('baseSlot')}
              InputProps={{
                endAdornment: null,
              }}
              error={errors.baseSlot}
              helperText={errors.baseSlot}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Select mode</CustomInputLabel>
            <CustomRadioBtn
              value={formValue.option}
              onChange={handleChangeForm}
            />
            {errors.option && (
              <CustomFormHelperText error>{errors.option}</CustomFormHelperText>
            )}
          </Grid>
        </Grid>
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={showLoader}
        >
          Save
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalEditTimeslotAppt;
