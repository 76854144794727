import ecommerceDispatcher from '../action';

const initialState = {
  list: [],
  categories: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0,
  },
  detail: {},
  loading: false,
};

const ecommerceReducer = ecommerceDispatcher(initialState, {
  [ecommerceDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined,
    },
  }),
  [ecommerceDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value,
  }),
  [ecommerceDispatcher.resetData]: (state, _) => ({
    ...initialState,
  }),
  [ecommerceDispatcher.saveDataSuccess]: (_, payload) => {
    const { name, value } = payload;
    return {
      [name]: value,
    };
  },
});

export default ecommerceReducer;
