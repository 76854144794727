import React from 'react';
import styled from 'styled-components';
import { CloudOutlineIcon } from '../../assets/svg';
import { InputAdornment, TextField } from '@material-ui/core';
import { get } from 'lodash';

const UploadVideo = ({
  id,
  file,
  onChange,
  label,
  name,
  error,
  value,
  helperText
}) => {
  const onInputChange = e => {
    onChange && onChange(get(e, 'target.files[0]'));
  };
  return (
    <UploadVideoStyle htmlFor={id}>
      <div>
        <input
          id={id}
          type="file"
          accept="video/*"
          onChange={onInputChange}
          name={name || 'video'}
          hidden
        />
      </div>
      <WrapperText
        variant="standard"
        label={label}
        value={(file && file.name) || value}
        error={error}
        helperText={helperText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CloudOutlineIcon
                height="16px"
                width="18px"
                color="#ccc"
                style={{ cursor: 'pointer' }}
              />
            </InputAdornment>
          )
        }}
        inputProps={{ readOnly: true }}
      />
    </UploadVideoStyle>
  );
};

export default UploadVideo;

const UploadVideoStyle = styled.label``;
const WrapperText = styled(TextField)`
  width: 100%;
  label {
    color: #8f9ba9;
  }
`;
