import { synthesize } from 'redux-dispatcher';

let initSearch = '';
let initFilterOption = {};

const mapDispatchToAC = {
  getDataSuccess: data => ({
    data
  }),
  getData: (
    search = initSearch,
    paging,
    filterOption = initFilterOption
  ) => async ({ Api, getState }) => {
    initSearch = search;
    initFilterOption = filterOption;
    let pagingData = paging || getState().meetingHistory.paging;
    let { result, status } = await Api.post(`Doctors/meeting-histories`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption
    });
    if (status === 200) {
      meetingHistoryDispatcher.getDataSuccess(result);
    }
  },
  cancelConsult: (id, search, filterOption = {}) => async ({
    Api,
    getState
  }) => {
    let { status } = await Api.put(`Calls/${id}/cancel`);
    if (status === 200) {
      let pagingData = getState().meetingHistory.paging;
      meetingHistoryDispatcher.getData(search, pagingData, filterOption);
    }
  },
  completeConsult: (id, search, filterOption = {}) => async ({
    Api,
    getState
  }) => {
    let { status } = await Api.put(`Calls/${id}/complete`);
    if (status === 200) {
      let pagingData = getState().meetingHistory.paging;
      meetingHistoryDispatcher.getData(search, pagingData, filterOption);
    }
  }
};

const meetingHistoryDispatcher = synthesize('meetingHistory', mapDispatchToAC);
export default meetingHistoryDispatcher;
