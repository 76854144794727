import React, { useState, useRef } from 'react';
import { Carousel } from 'antd';
import './styles.scss';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { CloseRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  buttonWrapper: {
    position: 'absolute',
    color: 'white',
    backgroundColor: 'transparent',
    top: 'calc(50% - 15px)',
    '&:hover': {
      '& $button': {
        backgroundColor: 'black',
        filter: 'brightness(120%)',
        opacity: 0.4
      }
    }
  },
  next: {
    right: 10,
    cursor: 'pointer'
  },
  prev: {
    left: 10,
    cursor: 'pointer'
  }
});

const CarouselAnt = ({
  disabled,
  data,
  onRemove,
  emptyLabel = 'No Upload Available'
}) => {
  const ref = useRef();
  const classes = useStyles();
  const [current, setCurrent] = useState(0);
  return (
    <div style={{ position: 'relative' }}>
      <Carousel
        ref={ref}
        afterChange={setCurrent}
        className="carousel-ant-custom"
      >
        {isEmpty(data) ? (
          <div className="section-empty" style={{ display: 'flex' }}>
            {emptyLabel}
          </div>
        ) : (
          data.map((item, index) => (
            <div key={`carousel-item-${index}`} style={{ height: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={item}
                  alt={index}
                  style={{ alignSelf: 'center', maxHeight: '200px' }}
                />
                {!disabled && (
                  <Fab
                    style={{
                      position: 'absolute',
                      right: 20,
                      top: 20,
                      width: 20,
                      height: 20,
                      minHeight: 20,
                      background: 'black',
                      opacity: 0.6
                    }}
                  >
                    <CloseRounded
                      style={{ fontSize: 16, color: 'white' }}
                      onClick={() => onRemove(current)}
                    />
                  </Fab>
                )}
              </div>
            </div>
          ))
        )}
      </Carousel>
      {!isEmpty(data) && (
        <>
          <div className={`${classes.buttonWrapper} ${classes.next}`}>
            <NavigateNextIcon
              onClick={() => ref.current.next()}
              style={{ fontSize: 35 }}
            />
          </div>

          <div className={`${classes.buttonWrapper} ${classes.prev}`}>
            <NavigateBeforeIcon
              onClick={() => ref.current.prev()}
              style={{ fontSize: 35 }}
            />
          </div>
        </>
      )}
    </div>
  );
};

CarouselAnt.propTypes = {
  disabled: PropTypes.bool,
  data: PropTypes.array
};

CarouselAnt.defaultProps = {
  disabled: false,
  data: [
    'https://i.imgur.com/GsNCBNJ.png',
    'https://i.imgur.com/GsNCBNJ.png',
    'https://i.imgur.com/GsNCBNJ.png',
    'https://i.imgur.com/GsNCBNJ.png'
  ]
};

export default CarouselAnt;
