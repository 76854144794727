import { StaticDatePicker } from '@material-ui/pickers';
import styled from 'styled-components';

export const CustomStaticDatePicker = styled(StaticDatePicker)`
  border-bottom: 2px solid #f1f1f1;

  & .MuiPickersCalendar-root {
    min-height: 170px;
  }
  & .MuiPickersDay-root {
    font-size: 14px;
    width: 27px;
    height: 30px;
  }
  & .MuiPickersDay-dayWithMargin {
    margin: 0 10px;
  }
  & .MuiButtonBase-root {
    border-radius: 4px;
  }
  & .MuiPickersBasePicker-pickerView {
    width: 352px;
  }
  & .MuiPickersArrowSwitcher-previousMonthButtonMargin {
    margin-right: 12px;
  }
  & .MuiPickersCalendar-weekDayLabel {
    margin: 0 5px;
    font-size: 14px;
    color: #333;
    font-weight: 600;
  }

  & .MuiPickersCalendarView-viewTransitionContainer {
    border-top: 2px solid #f1f1f1;
  }
  & .MuiPickersCalendarHeader-switchViewDropdown {
    display: none;
  }

  & .MuiPickersCalendarHeader-monthTitleContainer > .MuiTypography-subtitle1 {
    font-weight: 600 !important;
  }
`;

export const CustomWeekStaticDatePicker = styled(StaticDatePicker)`
  border-bottom: 2px solid #f1f1f1;

  & .MuiPickersCalendar-root {
    min-height: 170px;
  }
  & .MuiPickersDay-root {
    font-size: 14px;
    width: 27px;
    height: 30px;
  }
  & .MuiPickersDay-dayWithMargin {
    margin: 0 10px;
  }
  & .MuiButtonBase-root {
    border-radius: 4px;
  }
  & .MuiPickersBasePicker-pickerView {
    width: 352px;
  }
  & .MuiPickersArrowSwitcher-previousMonthButtonMargin {
    margin-right: 12px;
  }
  & .MuiPickersCalendar-weekDayLabel {
    margin: 0 5px;
    font-size: 14px;
    color: #333;
    font-weight: 600;
  }

  & .MuiPickersCalendarView-viewTransitionContainer {
    border-top: 2px solid #f1f1f1;
  }

  & .MuiPickersCalendarHeader-monthTitleContainer > .MuiTypography-subtitle1 {
    font-weight: 600 !important;
  }
`;
