import { makeStyles, Button } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    gap: 24,
  },
  active: {
    border: '1px solid #ad5e99',
    color: '#ad5e99',
    background: '#FFE9FB',
  },
  inactive: {
    border: '1px solid #e1e1e1',
  },
  btnContainer: {
    width: '100%',
    height: '40px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  txt: {
    fontSize: 16,
    lineHeight: '21px',
    textAlign: 'center',
    color: '#333333',
  },
  txtActive: {
    color: '#ad5e99',
  },
  txtInActive: {
    color: '#333333',
  },
});

const CustomRadioBtn = ({ onChange, value }) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Button
        className={classnames(
          classes.btnContainer,
          value === 'open' ? classes.active : classes.inactive
        )}
        onClick={() => onChange('option')('open')}
      >
        <span
          className={classnames(
            classes.txt,
            value === 'open' ? classes.txtActive : classes.txtInActive
          )}
        >
          Open
        </span>
      </Button>
      <Button
        className={classnames(
          classes.btnContainer,
          value === 'close' ? classes.active : classes.inactive
        )}
        onClick={() => onChange('option')('close')}
      >
        <span
          className={classnames(
            classes.txt,
            value === 'close' ? classes.txtActive : classes.txtInActive
          )}
        >
          Close
        </span>
      </Button>
    </div>
  );
};

CustomRadioBtn.defaultProps = {
  value: null,
};

CustomRadioBtn.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default CustomRadioBtn;
