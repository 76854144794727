import { DeleteRounded, Edit, CheckCircleOutline } from '@material-ui/icons';

export const COLOR_STATUS = {
  Approved: '#62CAA4',
  Reject: '#EA6B75',
  Pending: '#CACFD3'
};

export const LIST_ACTIONS = [
  {
    key: 'approve',
    icon: CheckCircleOutline,
    label: 'Approve'
  },
  {
    key: 'edit',
    icon: Edit,
    label: 'Edit'
  },
  {
    key: 'delete',
    icon: DeleteRounded,
    label: 'Delete'
  }
];

export const COLUMN_CONSENT_FORM = [
  {
    label: 'Consent form name',
    stateValue: 'name'
  },
  {
    label: 'Description',
    stateValue: 'description'
  },
  {
    label: 'Updated at',
    stateValue: 'updatedAt'
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 100
  }
];
