import React from 'react';
import serviceDispatcher from '@/module/services/action';
import ServiceModalContainer from '../../common/ServiceModalContainer';
import RadiologyForm from './RadiologyForm';
import { RADIOLOGY_SERVICES_STEPS } from '@/module/services/constants';

const RadiologyModalContainer = props => (
  <ServiceModalContainer
    {...{
      ...props,
      prefixName: 'Radiology',
      category: 'Radiologies',
      createDispatcher: serviceDispatcher.createTest,
      editDispatcher: serviceDispatcher.editTest,
      getServiceByID: serviceDispatcher.getServiceByID,
      schema: 'serviceSchema',
      requireLabCode: false,
      requireLoinc: false,
      ServicesForm: RadiologyForm,
      listValidKeys: ['name'],
      steps: RADIOLOGY_SERVICES_STEPS
    }}
  />
);

export default RadiologyModalContainer;
