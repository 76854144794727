import React from 'react';
import { MenuItem, ListItemIcon } from '@material-ui/core';
import {
  VisibilityRounded,
  CreateRounded,
  CheckCircleRounded,
  DeleteRounded,
  AccessTime
} from '@material-ui/icons';
import styled from 'styled-components';
import { TYPE_MODAL } from '../constants/index';
import { STATUS_CLINIC } from '../constants/index';
import { get } from 'lodash';
import { ErrorCircleOutline, ErrorCircleFilled } from '../../../assets/svg';
const MenuAction = ({
  onShowModal,
  updateStatus,
  clinicSelected,
  setDeleteModal,
  onShowOperatingHours
}) => {
  let listActions = [
    {
      key: 'view',
      icon: VisibilityRounded,
      label: 'View',
      onClick: () => onShowModal(TYPE_MODAL.View)
    },
    {
      key: 'edit',
      icon: CreateRounded,
      label: 'Edit',
      onClick: () => onShowModal(TYPE_MODAL.Edit)
    },
    {
      key: 'operatingHours',
      icon: AccessTime,
      label: 'Change Operating Hours',
      onClick: onShowOperatingHours
    }
  ];

  switch (get(clinicSelected, 'status')) {
    case STATUS_CLINIC.Open: {
      listActions = [
        ...listActions,
        {
          key: 'makeUnavailable',
          icon: ErrorCircleOutline,
          label: 'Make Unavailable',
          onClick: () => updateStatus({ status: 'Temp' })
        },
        {
          key: 'close',
          icon: ErrorCircleFilled,
          label: 'Close',
          onClick: () => updateStatus({ status: STATUS_CLINIC.Closed })
        }
      ];
      break;
    }

    case 'Temp': {
      listActions = [
        ...listActions,
        {
          key: 'open',
          icon: CheckCircleRounded,
          label: 'Open',
          onClick: () => updateStatus({ status: STATUS_CLINIC.Open })
        },
        {
          key: 'close',
          icon: ErrorCircleFilled,
          label: 'Close',
          onClick: () => updateStatus({ status: STATUS_CLINIC.Closed })
        }
      ];
      break;
    }
    case STATUS_CLINIC.Closed: {
      listActions = [
        ...listActions,
        {
          key: 'open',
          icon: CheckCircleRounded,
          label: 'Open',
          onClick: () => updateStatus({ status: STATUS_CLINIC.Open })
        },
        {
          key: 'makeUnavailable',
          icon: ErrorCircleOutline,
          label: 'Make Unavailable',
          onClick: () => updateStatus({ status: 'Temp' })
        }
      ];
      break;
    }
    default:
      break;
  }

  listActions.push({
    key: 'delete',
    icon: DeleteRounded,
    label: 'Delete',
    onClick: setDeleteModal
  });

  return listActions.map(action => {
    const Icon = action.icon;
    return (
      <MenuItemAction
        key={action.key}
        onClick={() => {
          action.onClick();
        }}
      >
        <ListItemMenuIcon style={{ minWidth: 34 }}>
          <Icon className="icon" />
        </ListItemMenuIcon>
        {action.label}
      </MenuItemAction>
    );
  });
};

const MenuItemAction = styled(MenuItem)`
  display: flex;
  align-items: center;
  color: #192637;
`;

const ListItemMenuIcon = styled(ListItemIcon)`
  .icon {
    color: #cacfd3;
  }
  svg {
    color: #cacfd3;
  }
`;

export default MenuAction;
