import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({
    data
  }),
  getData: (search, paging, filterOption = {}) => async ({ Api, getState }) => {
    let { result, status } = await Api.post(`QueueRequests/filter`, {
      search,
      ...filterOption
    });
    if (status === 200) {
      caQueueDispatcher.getDataSuccess(result);
    }
  },
  rejectQueue: (id, search, filterOption = {}) => async ({ Api, getState }) => {
    let { status } = await Api.put(`QueueRequests/${id}/reject`);
    if (status === 200) {
      let pagingData = getState().caQueue.paging;
      caQueueDispatcher.getData(search, pagingData, filterOption);
    }
  }
};

const caQueueDispatcher = synthesize('caQueue', mapDispatchToAC);
export default caQueueDispatcher;
