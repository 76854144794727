import React from 'react';
import {
  makeStyles,
  IconButton,
  Button,
  Typography,
  Fade,
  Dialog
} from '@material-ui/core';
import {
  KeyboardArrowRightSharp,
  KeyboardArrowLeftSharp,
  Event
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';

import WeekPicker from './WeekPicker';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '20px'
  },

  dateField: {
    display: 'flex'
  },
  buttonToday: {
    width: 80,
    height: 40,
    fontSize: 14,
    borderRadius: 20
  },
  icon: {
    height: 40,
    padding: 0,
    fontSize: 14,
    margin: '0 8px'
  },
  timeText: {
    width: 120,
    color: '#192637',
    lineHeight: '40px',
    textAlign: 'center',
    marginRight: 8,
    fontSize: 16,
    fontWeight: 'bold'
  }
});

const Filter = ({
  value,
  openModalPicker,
  changeWeekPicker,
  setOpenModalPicker,
  onCountWeekPicker,
  setToday,
  txtDatePicker
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.dateField}>
        <Button
          className={classes.buttonToday}
          variant="contained"
          color="primary"
          onClick={setToday}
        >
          Today
        </Button>
        <IconButton
          color="secondary"
          className={classes.icon}
          onClick={() => onCountWeekPicker(false)}
        >
          <KeyboardArrowLeftSharp fontSize="large" />
        </IconButton>
        <IconButton
          color="secondary"
          className={classes.icon}
          onClick={() => onCountWeekPicker(true)}
        >
          <KeyboardArrowRightSharp fontSize="large" />
        </IconButton>
        <Typography className={classes.timeText}>{txtDatePicker}</Typography>
        <IconButton
          color="secondary"
          className={classes.icon}
          onClick={() => setOpenModalPicker(true)}
        >
          <Event color="primary" />
        </IconButton>
      </div>

      <Dialog
        maxWidth="xs"
        open={openModalPicker}
        disableEnforceFocus
        TransitionComponent={Fade}
        onClose={() => setOpenModalPicker(false)}
      >
        <LocalizationProvider dateAdapter={MomentAdapter}>
          <WeekPicker
            onClose={() => setOpenModalPicker(false)}
            changeWeek={changeWeekPicker}
            value={value}
          />
        </LocalizationProvider>
      </Dialog>
    </div>
  );
};

Filter.propTypes = {
  value: PropTypes.object.isRequired,
  txtDatePicker: PropTypes.string.isRequired,
  openModalPicker: PropTypes.bool.isRequired,
  setOpenModalPicker: PropTypes.func.isRequired,
  changeWeekPicker: PropTypes.func.isRequired,
  onCountWeekPicker: PropTypes.func.isRequired,
  setToday: PropTypes.func.isRequired
};

export default Filter;
