/**
 * Author: Quan Vo
 * Date: 2020-01-08
 */
import { getBase64Promise } from '../helpers/CommonHelper';
import { DATE_FORMAT } from '../module/common';
import { SPECIAL_LOCATION } from '@/module/company/constants';
import { SPECIAL_TAGS_PACKAGE } from '@/module/package-management/constants';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import {
  isEmpty,
  get,
  isArray,
  isNil,
  lowerCase,
  map,
  isNumber,
  isInteger,
} from 'lodash';
import moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import UAParser from 'ua-parser-js';

export const devLog = (...args) =>
  process.env.NODE_ENV === 'development' && console.log(...args);

/*-------------------------------------------------------------------*/

/**
 * https://github.com/ajwhite/render-if/blob/master/renderIf.js
 */

const isFunction = (input) => typeof input === 'function';

export const renderIf = (predicate) => (elemOrThunk) =>
  predicate ? (isFunction(elemOrThunk) ? elemOrThunk() : elemOrThunk) : null;

/*-------------------------------------------------------------------*/

export const preventDecimalInput = (e) =>
  (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault();

const parser = new UAParser();
const userAgentInfo = parser.getResult();

export const isSafari = () => {
  return (
    userAgentInfo.browser.name === 'Safari' ||
    userAgentInfo.browser.name === 'Mobile Safari'
  );
};

export const isCompatibleChrome = () => {
  if (userAgentInfo.browser.name === 'Chrome') {
    const major = +userAgentInfo.browser.major;
    if (major >= 72) return true;
  }
  return false;
};

export const isFirefox = () => {
  return userAgentInfo.browser.name === 'Firefox';
};

export const isMobile = () => {
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    navigator.userAgent.toLowerCase()
  );
};
export const getNationalNumber = (
  text = '',
  countryCode,
  fullNumber = false,
  defaultValue = text
) => {
  if (text === null) return '';
  let rs = getNumberInfo(text.trim(), countryCode);
  if (!fullNumber) return get(rs, '[0]', defaultValue);
  else return get(rs, '[1]', defaultValue);
};

export const getNumberInfo = (text = '', countryCode) => {
  let rs = findPhoneNumbersInText(text.trim(), countryCode);
  if (isEmpty(rs)) {
    return [];
  }
  return [rs[0].number.nationalNumber, rs[0].number.number];
};

export const blobUrl = (blob) => {
  if (!blob.url) {
    blob.url = URL.createObjectURL(blob);
  }
  return blob.url;
};

export const momentFormatted = (date, formatType = DATE_FORMAT) => {
  let value = moment(date);
  if (!value.isValid()) return null;
  else return value.format(formatType);
};

export const durationFormatted = (startTime, endTime) => {
  if (!startTime || !endTime) return null;

  let startMoment = moment(startTime);
  let endMoment = moment(endTime);

  if (!startMoment.isValid() || !endMoment.isValid()) return null;
  let duration = moment.duration(endMoment.diff(startMoment)).asMilliseconds();
  return moment.utc(duration).format('HH:mm:ss');
};

export const isValidColor = (strColor) => {
  if (strColor.startWith('#')) {
    let regex = /^#[0-9A-F]{6}$/;
    return regex.test(strColor);
  }
  let s = new Option().style;
  s.color = strColor;

  // return 'false' if color wasn't assigned
  return s.color == strColor.toLowerCase();
};

export const mergeArrayByKey = (originArr, desArr, key) => {
  if (isArray(originArr)) {
    return isArray(desArr)
      ? originArr.map((originItem) => {
          const obj = desArr.find(
            (desItem) =>
              desItem[key] === originItem[key] || desItem[key] === originItem
          );
          if (!obj) return originItem;
          return isNil(originItem[key])
            ? { ...obj }
            : { ...originItem, ...obj };
        })
      : originArr;
  }
};

export const formatOperatingHours = (time = '00:00') => {
  const hours = time.split(':');
  const hour = parseInt(hours[0]);

  return (hour / 12) >> 0 > 0
    ? hour === 12
      ? `12:${hours[1]} PM`
      : `${hour % 12}:${hours[1]} PM`
    : `${hour % 12}:${hours[1]} AM`;
};

export const getStorage = (name) => {
  return localStorage.getItem(name);
};

export const setStorage = (name, value) => {
  return localStorage.setItem(name, value);
};

export const removeStorage = (listName = []) => {
  isArray(listName)
    ? listName.forEach((item) => localStorage.removeItem(item))
    : localStorage.removeItem(listName);
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const formatListImage = async (cert, callback) => {
  let data = [];
  for (const i in cert) {
    let url = '';
    const img = cert[i];
    if (img?.constructor === File) {
      url = await getBase64Promise(img);
      data.push(url);
    } else {
      img && data.push(img);
    }
  }
  callback(data);
};

export const filterObjectByKeys = (obj, keys) => {
  let result = {};
  for (const element in obj) {
    if (keys.indexOf(element) >= 0) result[element] = obj[element];
  }
  return result;
};

export const createDownloadableLink = (
  blobData,
  filename = 'result',
  // isPdf = false,
  typeDownload = 'csv'
) => {
  let url = URL.createObjectURL(blobData);
  var link = document.createElement('a');
  link.href = url;
  link.download = `${filename}.${typeDownload}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const mergePdfs = async (pdfsToMerge) => {
  const mergedPdf = await PDFDocument.create();

  const createInnerPromise = async (arrayBuffer) => {
    const pdf = await PDFDocument.load(arrayBuffer);
    return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  };

  const outerPromise = pdfsToMerge.map((arrayBuffer) => {
    const innerPromise = createInnerPromise(arrayBuffer);
    return innerPromise;
  });

  const resultOuterPromise = await Promise.all(outerPromise);

  resultOuterPromise.forEach((pageArray) => {
    pageArray.forEach((page) => {
      mergedPdf.addPage(page);
    });
  });

  return (await mergedPdf.save()).buffer;
};

export const createDownloadableMultiLinks = async (
  blobsData,
  filename = 'result',
  typeDownload = 'csv'
) => {
  const chunkPromises = blobsData.map(async (url) => {
    return fetch(url).then(function (res) {
      return res.arrayBuffer();
    });
  });

  const chunksArray = await Promise.all(chunkPromises);
  const arrayBuffer = await mergePdfs(chunksArray);
  const blob = new Blob([arrayBuffer], {
    type: 'application/pdf',
  });
  createDownloadableLink(blob, filename, typeDownload);
};

export const getDateRange = (rangeType) => {
  let firstDate,
    lastDate = new Date();
  switch (rangeType) {
    case 'Today':
      firstDate = moment().startOf('day');
      lastDate = moment().endOf('day');
      break;
    case 'Yesterday':
      firstDate = moment().subtract(1, 'days').startOf('day');
      lastDate = moment().subtract(1, 'days').startOf('day');
      break;
    case 'This week':
      firstDate = moment().startOf('week');
      lastDate = moment().endOf('week');
      break;
    case 'This month':
      firstDate = moment().startOf('month');
      lastDate = moment().endOf('month');
      break;
    case 'Last week':
      firstDate = moment().subtract(1, 'weeks').startOf('week');
      lastDate = moment().subtract(1, 'weeks').endOf('week');
      break;
    case 'Last month':
      firstDate = moment().subtract(1, 'months').startOf('month');
      lastDate = moment().subtract(1, 'months').endOf('month');
      break;
    case 'Next week':
      firstDate = moment().add(1, 'weeks').startOf('week');
      lastDate = moment().add(1, 'weeks').endOf('week');
      break;
    case 'Next month':
      firstDate = moment().add(1, 'months').startOf('month');
      lastDate = moment().add(1, 'months').endOf('month');
      break;
    default:
      break;
  }
  return [firstDate, lastDate];
};

export const filterArrayByArray = (originArr, desArr, key) => {
  const idValues = (desArr || []).map((val) => val[key]);
  //filter item in desArr
  let filterArr = [...originArr];
  filterArr = originArr.filter((item) => !idValues.includes(item[key]));
  //filter value not exist in options
  const idOptions = originArr.map((val) => val[key]);
  const existValue = (desArr || []).filter((item) =>
    idOptions.includes(item[key])
  );
  return { filterArr, existValue };
};

export const urlToFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

export const formatUTCDate = (
  date = moment(),
  { isStarOfDay = false, isEndOfDay = false }
) => {
  if (isStarOfDay) return moment(date).startOf('date').utc().format();
  if (isEndOfDay) return moment(date).endOf('date').utc().format();

  return moment(date).utc().format();
};

export const formatUnitNumber = (value) => {
  let floor = value.substring(0, 2);
  let unit = value.substring(2);
  if (floor.length === 2) {
    return `#${floor}-${unit}`;
  } else {
    return `#${floor}`;
  }
};

export const reOrderValue = (order, originArr) => {
  // re-order element in origin array based on order list
  if (isEmpty(order)) return originArr;
  let newVal = [];
  for (const index in order) {
    newVal[index] = originArr[order[index]];
  }
  return newVal;
};

export const formatOldData = (str) => {
  // Convert old json string data to htlm string, display new UI content editor with break word
  if (!str) return;
  let newStr;
  let regex = /[\n\n|\n]/g;

  if (regex.test(str)) {
    newStr = str.replace(regex, '<br >');
  } else newStr = str;
  return newStr;
};

export const generateDedicatedLink = (projectCode) => {
  const dedicatedLinkEnv =
    process.env.REACT_APP_MINC_CONNECT_DEDICATED_LINK || '';
  // Generate dedicated minc connect link based on project code
  return `${dedicatedLinkEnv}/?company=${projectCode}`;
};

export const renderUnitNumber = (floor, unitNumber) => {
  if (!floor && unitNumber) {
    // Handle old data - without floor value
    return unitNumber;
  } else if (floor && unitNumber) {
    // Handle full data - with both floor and unit number value
    return `#${floor}-${unitNumber}`;
  } else return '';
};

export const checkIsSpecialLocations = (locationName) =>
  lowerCase(locationName) === SPECIAL_LOCATION;

export const editTypeData = ({
  position,
  values,
  setValues,
  key,
  callback,
}) => {
  let newVal = [];
  newVal = map(values[key] || values, (item, index) => ({
    ...item,
    isEdit: index === position,
  }));
  callback && callback(newVal);
  setValues &&
    (key ? setValues({ ...values, [key]: newVal }) : setValues(newVal));
  return newVal;
};

export const formatPrice = (data = []) => {
  const result =
    map(data, (item) => ({
      ...item,
      price: isNumber(item.price) ? item.price : 0,
    })) || [];

  return result;
};

export const getCostRange = (rangeType, maxPriceDefault) => {
  let minCost, maxCost;
  switch (rangeType) {
    case 'Under $200':
      minCost = '0';
      maxCost = '200';
      break;
    case '$200 - $500':
      minCost = '200';
      maxCost = '500';
      break;

    case '$1,000 - $2,000':
      minCost = '1000';
      maxCost = '2000';
      break;

    case '$2,000 up':
      minCost = '2000';
      maxCost = maxPriceDefault;
      break;

    default:
      break;
  }
  return [minCost, maxCost];
};

export const transferHexColor = (hex, alpha) => {
  // Generate hex color with alpha value
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  const resultR = Math.round((1 - alpha) * 255 + alpha * r);
  const resultG = Math.round((1 - alpha) * 255 + alpha * g);
  const resultB = Math.round((1 - alpha) * 255 + alpha * b);

  return `#${((1 << 24) + (resultR << 16) + (resultG << 8) + resultB)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
};

export const handleSelectAll = (inputValue, originArr, newArr) => {
  let newValue = [];
  const isEqualLength = originArr?.length === newArr?.length;

  if (inputValue.includes('selectAll')) {
    if (isEqualLength) newValue = [];
    else newValue = [...originArr];
  } else {
    newValue = [...inputValue];
  }

  return newValue;
};

export const ignoredSelectedTags = (oriList, valueList) => {
  let newList = [...oriList];
  if (!isEmpty(valueList)) {
    const filterList = valueList.filter((it) =>
      SPECIAL_TAGS_PACKAGE.some((spec) => it.includes(spec))
    );
    // Check if lits value has special data
    if (filterList.length === 1) {
      // list value has 1 special data = > disable 2 another (in special List)
      newList = oriList.map((tag) => {
        if (
          tag.value !== filterList[0] &&
          SPECIAL_TAGS_PACKAGE.some((spec) => tag.value.includes(spec))
        ) {
          return { ...tag, isDisabled: true };
        }
        return tag;
      });
    } else {
      newList = oriList.map((tag) => ({ ...tag, isDisabled: false }));
    }
  }
  return newList;
};

export const rangeNumber = (start, end, step = 1) => {
  const len = Math.floor((end - start) / step) + 1;
  return Array(len)
    .fill()
    .map((_, idx) => start + idx * step);
};

export const formatTotalPrice = (price, currencySymbol = '$') => {
  const newPrice = isInteger(price)
    ? parseInt(price)
    : parseFloat(price).toFixed(2);
  if (currencySymbol && newPrice > 0) return `${currencySymbol}${newPrice}`;
  else return `${currencySymbol}0`;
};

export const renderListDuration = () => {
  const listMins = rangeNumber(60, 180, 15);
  return listMins.map((min) => {
    const hValue = moment().startOf('day').add(min, 'minutes').get('h');
    const mValue = moment().startOf('day').add(min, 'minutes').get('m');

    let newValue = moment().startOf('day').add(min, 'minutes').format('h:mm');

    // if (hValue === 1 && mValue === 0) {
    //   newValue = moment().startOf('day').add(min, 'minutes').format('h [hour]');
    // } else if (hValue === 1 && mValue !== 0) {
    //   newValue = moment()
    //     .startOf('day')
    //     .add(min, 'minutes')
    //     .format('h [hour] m [mins]');
    // } else if (hValue !== 1 && mValue !== 0) {
    //   newValue = moment()
    //     .startOf('day')
    //     .add(min, 'minutes')
    //     .format('h [hours] m [mins]');
    // } else {
    //   newValue = moment()
    //     .startOf('day')
    //     .add(min, 'minutes')
    //     .format('h [hours]');
    // }

    newValue = min + ' minutes';

    return {
      key: min,
      value: newValue,
    };
  });
};
