import styled from 'styled-components';

export const CustomNotificationContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .success {
    width: 45px;
    margin-right: 8px;
  }
  .info {
    width: 33px;
    margin-right: 12px;
  }
  .view {
    margin-left: 20px;
    color: #3776ab;
  }
  .close {
    width: 40px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
`;
