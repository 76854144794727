export const testColumn = [
  {
    label: 'Test',
    stateValue: 'test'
  },
  {
    label: 'Test Code',
    stateValue: 'labCode'
  },
  // {
  //   label: 'Minc Code',
  //   stateValue: 'mincCode'
  // },
  // {
  //   label: 'Station',
  //   stateValue: 'stationName'
  // },
  {
    label: '',
    stateValue: 'action'
  }
];

export const measurementColumn = [
  {
    label: 'Measurement',
    stateValue: 'name'
  },
  // {
  //   label: 'Station',
  //   stateValue: 'stationName'
  // },
  {
    label: '',
    stateValue: 'action'
  }
];

export const procedureColumn = [
  {
    label: 'Procedure',
    stateValue: 'name'
  },
  // {
  //   label: 'Station',
  //   stateValue: 'stationName'
  // },
  {
    label: '',
    stateValue: 'action'
  }
];

export const radiologyColumn = [
  {
    label: 'Radiology',
    stateValue: 'name'
  },
  // {
  //   label: 'Station',
  //   stateValue: 'stationName'
  // },
  {
    label: '',
    stateValue: 'action'
  }
];

export const vaccinationColumn = [
  {
    label: 'Vaccination',
    stateValue: 'name'
  },
  // {
  //   label: 'Station',
  //   stateValue: 'stationName'
  // },
  {
    label: '',
    stateValue: 'action'
  }
];

export const reviewColumn = [
  {
    label: 'Review',
    stateValue: 'name'
  },
  // {
  //   label: 'Station',
  //   stateValue: 'stationName'
  // },
  {
    label: '',
    stateValue: 'action'
  }
];

export const COLUMN_STATIONS = [
  {
    label: 'Station name',
    stateValue: 'name',
    width: '30%'
  },
  {
    label: 'Total',
    stateValue: 'total'
  },
  {
    label: 'Tests',
    stateValue: 'tests'
  },
  {
    label: 'Measurements',
    stateValue: 'measurements'
  },
  {
    label: 'Procedures',
    stateValue: 'procedures'
  },
  {
    label: 'Radioloies',
    stateValue: 'radiologies'
  },
  {
    label: 'Vaccinations',
    stateValue: 'vaccinations'
  },
  {
    label: 'Reviews',
    stateValue: 'reviews'
  },
  {
    label: 'Action',
    stateValue: 'action'
  }
];
