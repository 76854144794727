/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import {
  DialogContent,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  CircularProgress,
  Tooltip,
  TextField,
  MenuItem,
  withStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { ClearRounded, Info } from '@material-ui/icons';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import customToast from '@/new-components/CustomNotification';

import {
  ButtonEnhance,
  ModalPage,
  ModalFooter
} from '../../common/componentUI/commonStyleComponents';
import { WrapperInput } from '../../category/component/CategoryModal/styled';
import ControlDatepicker from '../../common/component/ControlDatepicker';
import { STATUSES_APPOINTMENT, tableColumnTimeSlot } from '../constants';

import locationDispatcher from '@/module/setup/action';
import { formatTimeShowUILocal } from '@/module/setup/utils';
import { validateDate } from '@/utils/validation';

import dispatcher from '../action/teleAppointment';
import companyDispatcher from '@/module/company/action';
import LoadingButton from '@/components/LoadingButton';
import checkInDispatcher from '@/module/checkin-patient-information/action';
import { checkIsSpecialLocations } from '@/helpers';

const CustomTooltips = withStyles(theme => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
    maxWidth: 500,
    background: '#fff',
    color: 'rgba(0, 0, 0, 0.87)'
  }
}))(Tooltip);

const EditHSAppointment = ({
  onClose,
  selectedItem = {},
  cbGetData,
  isViewDetail
}) => {
  const [formValue, setFormValue] = useState({
    startDate: '',
    appointmentTypes: [],
    nric: '',
    fullName: '',
    shortId: '',
    timeSlotId: '',
    endDate: '',
    healthScreeningStartDate: '',
    healthScreeningEndDate: '',
    isPaxscreenSuppression: false,
    location: ''
  });
  const [timeSlot, setTimeSlot] = useState([]);
  const [timeSlotChecked, setTimeSlotChecked] = useState();
  const [closureDate, setClosureDate] = useState([]);
  const [dateChecked, setDateChecked] = useState();
  const [loadingSearchTimeSlot, setLoadingSearchTimeSlot] = useState(false);
  const [showErrorInfo, setShowErrorInfo] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errors, setErrors] = useState({});
  const ref = useRef(false);
  const [lstHealthScreenings, setLstHealthScreenings] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({});
  const [isValidHealthScreening, setIsValidHealthScreening] = useState(false);
  const [isRestrictPackages, setIsRestrictPackages] = useState(false);

  const isFollowUp = get(selectedItem, 'isFollowUpAppointment', undefined);
  const hasChangedLocation = [
    STATUSES_APPOINTMENT.DEFAULTED,
    STATUSES_APPOINTMENT.UPCOMING
  ].includes(selectedItem.status);

  const {
    startDate,
    endDate,
    appointmentTypes,
    nric,
    fullName,
    shortId,
    healthScreeningStartDate,
    healthScreeningEndDate,
    isPaxscreenSuppression,
    isProjectSuppression,
    healthScreeningId
  } = formValue;

  useEffect(() => {
    if (isEmpty(selectedItem)) return;

    const {
      startDate = '',
      appointmentTypes = [],
      nric = '',
      fullName = '',
      shortId = '',
      endDate = '',
      healthScreeningStartDate,
      healthScreeningEndDate,
      isPaxscreenSuppression,
      isProjectSuppression,
      projectId,
      healthScreeningId,
      locationId
    } = selectedItem;

    let newFormValue = {
      appointmentTypes,
      nric,
      fullName,
      shortId,
      endDate,
      healthScreeningStartDate,
      healthScreeningEndDate,
      isPaxscreenSuppression,
      isProjectSuppression,
      healthScreeningId
    };

    let startDateValid = moment(startDate);
    let isStartDateValid = true;
    if (!isViewDetail && checkIsValidDate(startDateValid)) {
      isStartDateValid = false;
      startDateValid = isFollowUp ? moment().add(1, 'day') : moment();

      // Check if healthScreeningStartDate is after startDateValid
      if (moment(healthScreeningStartDate).isAfter(moment(startDateValid))) {
        startDateValid = moment(healthScreeningStartDate);
      }
    }
    ref.current = isPaxscreenSuppression;
    newFormValue.startDate = startDateValid;

    onGetDataTimeSlot(startDateValid, isStartDateValid, locationId);

    companyDispatcher.getProjectByID(projectId, result => {
      const newListActive = result.lstHealthScreenings.filter(item =>
        checkIsValidHealthScreening(item.endDate)
      );

      let currentLocation = newListActive.find(
        item => item.id === healthScreeningId
      );

      // set healthScreeningEndDate is an endDate of the project if the appointment is a follow-up
      if (isFollowUp) newFormValue.healthScreeningEndDate = result.endDate;

      if (isEmpty(newListActive)) {
        setErrors({
          location:
            'All health screenings are expired. Please update in your project!'
        });
      } else {
        setErrors({});
        if (!currentLocation && !isFollowUp) {
          currentLocation = newListActive[0];
          // Update new form value
          newFormValue.healthScreeningStartDate = currentLocation.startDate;
          newFormValue.healthScreeningEndDate = currentLocation.endDate;
          newFormValue.healthScreeningId = currentLocation.id;
          // Get data time slot with new locationId
          onGetDataTimeSlot(
            startDateValid,
            isStartDateValid,
            currentLocation.locationId
          );
        }
      }
      // Check healthScreeningEndDate is expired only with parent appointment
      setIsValidHealthScreening(!isFollowUp && isEmpty(newListActive));
      setCurrentLocation(currentLocation);
      setLstHealthScreenings(newListActive);
    });

    if (locationId) getDetailLocation(locationId);

    setFormValue(newFormValue);
  }, [selectedItem]);

  const onGetDataTimeSlot = (value, isStartDateValid, locationId) => {
    if (!value) return;

    if (checkIsValidDate(value)) {
      setShowErrorInfo(true);
    } else {
      setShowErrorInfo(false);
    }

    if (locationId) {
      setLoadingSearchTimeSlot(true);
      locationDispatcher.getDetailLocationTimeSlot(
        {
          id: locationId,
          viewAll: true,
          date: moment(value).format('YYYY-MM-DD')
        },
        result => {
          const { timeSlotId } = selectedItem;
          const startTimeSelected = result.find(it => it.id === timeSlotId);

          const dayOfWeek = moment(value).day();
          const timeSlots = result.filter(it => it.dayOfWeek === dayOfWeek);

          if (
            startTimeSelected &&
            (!dateChecked ||
              dateChecked ===
                moment(value)
                  .startOf('date')
                  .format())
          ) {
            isStartDateValid && setTimeSlotChecked(startTimeSelected.id);
            setDateChecked(
              moment(value)
                .startOf('date')
                .format()
            );
          }

          setTimeSlot(timeSlots);
          setLoadingSearchTimeSlot(false);
        }
      );
      locationDispatcher.getDetailLocation(selectedItem.locationId, result => {
        setClosureDate(result?.dayOffs);
      });
    }
  };

  const countTimeInterval = (startTime, endTime) => {
    if (!startTime || !endTime) return '';
    return moment(endTime).diff(moment(startTime), 'minutes');
  };

  const onChangeChecked = id => {
    setTimeSlotChecked(id);
    setDateChecked(
      moment(startDate)
        .startOf('date')
        .format()
    );
  };

  const resetCheckedValue = () => null;

  const checkIsValidDate = valueStartDate => {
    const startDateUnix = moment(valueStartDate)
      .startOf('date')
      .unix();

    const dateCompare = isFollowUp
      ? moment()
          .startOf('date')
          .add(1, 'day')
          .unix()
      : moment()
          .startOf('date')
          .unix();

    return startDateUnix < dateCompare;
  };

  const checkIsValidTime = startTime => {
    const startTimeUnix = moment(startTime).unix();
    const timeCompare = moment().unix();

    return startTimeUnix <= timeCompare;
  };

  const checkIsValidTime5Mins = endTime => {
    const endTime5Mins = moment(endTime).subtract(5, 'minutes');
    const timeCompare = moment();
    const diffTime = endTime5Mins.diff(timeCompare, 'minutes');

    return diffTime < 0;
  };

  const checkIsValidHealthScreening = endTime => {
    const endTimeUnix = moment(endTime)
      .endOf('date')
      .unix();
    const timeCompare = moment().unix();

    return endTimeUnix >= timeCompare;
  };

  const checkDisable = row => {
    const { startDate, endDate, isActive } = row;
    // Disable Time Slot:
    // 1 Start time valid is after current time
    // 2 Start date valid is after current date 2 days
    // 3 Timeslot not active
    // 3 Timeslot not active
    // 4 End time valid is before current 5 minutes

    if (checkIsValidDate(startDate)) return true;
    if (checkIsValidTime(endDate)) return true;
    if (checkIsValidTime5Mins(endDate)) return true;

    if (!isActive) return true;

    return;
  };

  const getDetailLocation = locationId => {
    locationDispatcher.getDetailLocation(locationId, result => {
      if (result.restrictedPackageIds) {
        checkInDispatcher.getData(selectedItem.paxScreeningId, resultPax => {
          const packageIds = resultPax.lstPackages.map(test => test.id);
          const restrictedPackageIds = result.restrictedPackageIds;
          const hasRestrictedIds = restrictedPackageIds.filter(id =>
            packageIds.includes(id)
          );
          setIsRestrictPackages(!isEmpty(hasRestrictedIds));
        });
      }
      setClosureDate(result?.dayOffs);
    });
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumnTimeSlot.map(({ label }) => (
          <TableCell align="center" key={label}>
            {label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const updatedData = () => {
    if (loadingSubmit) return;

    if (!timeSlotChecked) {
      return customToast('error', 'Please select time slots');
    }

    if (isRestrictPackages) {
      return customToast(
        'error',
        'The current package has been restricted to this location. Please select another location! '
      );
    }
    setLoadingSubmit(true);
    dispatcher.editTeleAppointment(
      {
        id: selectedItem.id,
        timeSlotId: timeSlotChecked,
        date: dateChecked,
        isSuppression: formValue.isPaxscreenSuppression,
        healthScreeningId
      },
      ({ isSuccess }) => {
        setLoadingSubmit(false);
        if (isSuccess) {
          customToast('success', 'Update Appointment Success');
          cbGetData();
          onClose();
        }
      }
    );
  };

  const renderContent = () => {
    if (loadingSearchTimeSlot)
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            minHeight: 300,
            maxHeight: 400,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress color="primary" />
        </div>
      );
    return (
      <div style={{ maxHeight: 'calc(100vh - 500px)', overflow: 'auto' }}>
        <TableContainer>
          <Table>
            <TableHeader />
            <TableBody>
              {timeSlot.map(row => {
                const isDisabled = checkDisable(row) || isViewDetail;
                return (
                  <TableRow
                    key={`${row.id}`}
                    style={{
                      cursor: isDisabled ? 'unset' : 'pointer',
                      backgroundColor: isDisabled ? '#fafafa' : 'inherit'
                    }}
                    onClick={() => !isDisabled && onChangeChecked(row.id)}
                  >
                    <TableCell
                      padding="checkbox"
                      style={{ verticalAlign: 'middle' }}
                    >
                      <Radio
                        disabled={checkDisable(row) || isViewDetail}
                        color="primary"
                        checked={
                          row.id === timeSlotChecked &&
                          dateChecked ===
                            moment(startDate)
                              .startOf('date')
                              .format()
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.startDate && formatTimeShowUILocal(row.startDate)}
                    </TableCell>
                    <TableCell align="center">
                      {row.endDate && formatTimeShowUILocal(row.endDate)}
                    </TableCell>
                    <TableCell align="center">
                      {countTimeInterval(row.startDate, row.endDate)}
                    </TableCell>
                    <TableCell align="center">{row.quotaUsed}</TableCell>
                    <TableCell align="center">{row.quotaBase}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  const handleChangeForm = key => value => {
    const utcValue = moment(value)
      .startOf('date')
      .utc()
      .format();

    const { valid, message } = validateDate(utcValue);

    if (!valid) {
      setErrors({ ...errors, startDate: message });
    }
    if (valid && key === 'startDate') {
      resetCheckedValue();
      const locationId =
        hasChangedLocation && !isFollowUp
          ? currentLocation?.locationId
          : selectedItem?.locationId;
      onGetDataTimeSlot(utcValue, false, locationId);
      delete errors.startDate;
      setErrors(errors);
    }

    setTimeSlotChecked();
    setFormValue({ ...formValue, [key]: utcValue });
  };

  const handleChangeHealthScreening = event => {
    const { name, value } = event.target;
    let formValueStartDate = undefined;
    const utcValue = moment(formValueStartDate)
      .startOf('date')
      .utc()
      .format();

    const { valid, message } = validateDate(utcValue);

    if (!valid) {
      setErrors({ ...errors, startDate: message });
    } else {
      resetCheckedValue();
      getDetailLocation(value.locationId);

      // Check if healthScreeningStartDate is after currentDate, then set startDate to healthScreeningStartDate
      if (moment(value.startDate).isAfter(moment(utcValue))) {
        formValueStartDate = moment(value.startDate);
        onGetDataTimeSlot(value.startDate, false, value.locationId);
      } else {
        onGetDataTimeSlot(utcValue, false, value.locationId);
      }

      delete errors.startDate;
      setErrors(errors);
    }

    setTimeSlotChecked();
    setCurrentLocation(value);

    setFormValue({
      ...formValue,
      [name]: value.id,
      startDate: formValueStartDate,
      healthScreeningStartDate: value.startDate,
      healthScreeningEndDate: value.endDate
    });
  };

  const getMinDatePicker = valueDate => {
    if (checkIsValidDate(valueDate)) {
      return isFollowUp ? moment().add(1, 'day') : moment();
    }
    return valueDate;
  };

  const getMaxDatePicker = () =>
    isFollowUp
      ? undefined
      : healthScreeningEndDate
      ? moment(healthScreeningEndDate)
      : undefined;

  return (
    <LocalizationProvider dateAdapter={MomentAdapter}>
      <ModalPage style={{ maxWidth: 'none' }}>
        <DialogContent>
          <div className="test-header">
            {isViewDetail ? 'Detail Appointment' : 'Edit Appointment'}
            <ClearRounded
              style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
              onClick={onClose}
            />
          </div>
          <div className="test-body">
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item xs={12} md={6}>
                <WrapperInput
                  label="Patient"
                  disabled
                  value={`${fullName} (${shortId})`}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <WrapperInput label="Patient NRIC" disabled value={nric} />
              </Grid>
              <Grid item xs={12} md={6}>
                <WrapperInput
                  label="Appointment Type"
                  disabled
                  value={appointmentTypes.join(', ')}
                />
              </Grid>
              <ControlDatepicker
                disabled={isViewDetail}
                value={formValue.startDate}
                label="Date"
                maxDate={getMaxDatePicker()}
                minDate={
                  checkIsSpecialLocations(currentLocation?.locationName)
                    ? moment().add(3, 'day')
                    : healthScreeningStartDate
                    ? getMinDatePicker(healthScreeningStartDate)
                    : undefined
                }
                onChange={handleChangeForm('startDate')}
                error={errors.startDate}
                selectedDates={closureDate}
              />
              {hasChangedLocation && !isFollowUp && (
                <>
                  <Grid item xs={12} md={6}>
                    {!isEmpty(lstHealthScreenings) ? (
                      <TextField
                        select
                        fullWidth
                        label="Location"
                        name={'healthScreeningId'}
                        value={currentLocation}
                        disabled={isViewDetail}
                        onChange={handleChangeHealthScreening}
                      >
                        {lstHealthScreenings.map(healthScreening => (
                          <MenuItem
                            key={healthScreening.id}
                            value={healthScreening}
                          >
                            {healthScreening.locationName}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <WrapperInput
                        label="Location"
                        disabled
                        error={!isViewDetail && !!errors.location}
                        helperText={!isViewDetail && errors.location}
                        value={currentLocation?.locationName || ''}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <WrapperInput
                      label="Location Address"
                      disabled
                      value={currentLocation?.locationAddress || ''}
                    />
                  </Grid>
                </>
              )}
              {!isFollowUp && (
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    label="Free Payment"
                    control={
                      <Checkbox
                        name="paymentSuppression"
                        checked={isPaxscreenSuppression}
                        disabled
                      />
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    fontSize: 14
                  }}
                >
                  <span
                    style={{ fontWeight: 600, marginRight: 4, fontSize: 16 }}
                  >
                    List of Time Slot ({timeSlot.length})
                  </span>
                  {showErrorInfo && !isViewDetail && (
                    <CustomTooltips
                      title={
                        <div style={{ fontSize: 14, padding: 4 }}>
                          <span>
                            Please choose start date is same or later than{' '}
                          </span>
                          <span style={{ fontWeight: 600 }}>
                            {isFollowUp
                              ? moment()
                                  .add(1, 'day')
                                  .format('DD-MM-YYYY')
                              : moment().format('DD-MM-YYYY')}
                          </span>
                        </div>
                      }
                      aria-label="info"
                      placement="right-start"
                    >
                      <Info fontSize="small" color="error" />
                    </CustomTooltips>
                  )}
                </div>
                {renderContent()}
              </Grid>
            </Grid>
          </div>
          <ModalFooter>
            <ButtonEnhance
              background="#E9E9E9"
              color="black"
              backgroundHover="#ccc"
              onClick={onClose}
            >
              {isViewDetail ? 'Close' : 'Cancel'}
            </ButtonEnhance>
            {!isViewDetail && (
              <LoadingButton
                onClick={updatedData}
                showLoader={loadingSubmit}
                disabled={isValidHealthScreening}
              >
                Save Changes
              </LoadingButton>
            )}
          </ModalFooter>
        </DialogContent>
      </ModalPage>
    </LocalizationProvider>
  );
};

export default EditHSAppointment;
