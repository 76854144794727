import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { formatTotalPrice } from '@/module/checkin-patient-information/util';
import { Chip } from '@material-ui/core';
import CustomTooltip from '@/new-components/CustomTooltip';

const CustomInputLabel = withStyles({
  root: {
    marginBottom: 16,
    color: '#333333',
    fontWeight: 600
  }
})(InputLabel);

const CustomPrice = withStyles({
  root: {
    color: '#333333',
    fontWeight: 600,
    fontSize: 16,

    '&.free': {
      fontSize: 14,
      color: '#999999'
    }
  }
})(Typography);

const CustomBox = withStyles({
  root: {
    marginTop: 6,
    marginBottom: 6,
    '&last-child': {
      marginBottom: 0
    }
  }
})(Box);

const useStyle = makeStyles({
  cardWrapper: {
    marginBottom: 12,
    border: '1px solid #e1e1e1'
  },
  category: {
    fontSize: 12,
    color: '#666666'
  }
});

const RowItem = ({ children, price }) => {
  return (
    <CustomBox
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      gridGap={8}
    >
      <Typography style={{ fontSize: 16 }}>{children}</Typography>
      <CustomPrice className={price || price > 0 ? '' : 'free'}>
        {!price ? 'Free' : formatTotalPrice(price, '$')}
      </CustomPrice>
    </CustomBox>
  );
};

const AddOnsItem = ({ listData, isProfile }) => {
  const classes = useStyle();

  return (
    <Box px={2}>
      <CustomInputLabel>{'Add-ons'}</CustomInputLabel>
      {isProfile ? (
        <Card variant="outlined" className={classes.cardWrapper}>
          <CardContent>
            {listData.map(data => (
              <RowItem key={data.id} price={data.price}>
                <CustomTooltip content={data.name} />
              </RowItem>
            ))}
          </CardContent>
        </Card>
      ) : (
        listData.map(data => (
          <Card variant="outlined" className={classes.cardWrapper}>
            <CardContent style={{ paddingBottom: 8 }}>
              <Typography className={classes.category} gutterBottom>
                {data.category}
              </Typography>
              <Box>
                {data?.items.map(test => (
                  <RowItem key={test.id} price={test.price}>
                    <Box display="flex" alignItems="center" gridGap={8}>
                      <CustomTooltip content={test.name} />
                      {test?.status && (
                        <Chip
                          style={{
                            color: '#0B8E5E',
                            backgroundColor: 'rgba(21,164,112,0.16)'
                          }}
                          size="small"
                          label={test?.status}
                        />
                      )}
                    </Box>
                  </RowItem>
                ))}
              </Box>
            </CardContent>
          </Card>
        ))
      )}
    </Box>
  );
};

export default AddOnsItem;
