import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import InlineTable from '@/module/common/component/InlineTable';
import TableCellFilted from '@/module/common/componentUI/TableCellFilted';
import FiltedBodyCell from '@/module/common/component/FiltedBodyCell';
import { ChipStatus } from '@/module/common/componentUI/commonStyleComponents';

import { renderConsultListColumn } from '../constants';
import { momentFormatted, durationFormatted } from '@/helpers';
import { DATE_FORMAT_2 } from '@/module/common';
import {
  ConsultStatusDisplay,
  ConsultType,
  StatusColor
} from '@/enum/ConsultEnum';

import get from 'lodash/get';

const CustomButton = withStyles({
  root: {
    margin: 5,
    paddingLeft: 40,
    paddingRight: 40,

    height: 40,
    minWidth: 160,

    textTransform: 'unset',
    fontWeight: 600,
    borderRadius: 50
  }
})(Button);

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: 400
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  },
  title: {
    fontWeight: 600
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalShowConsultList = ({ onClose, listConsult }) => {
  const [hiddenRows, setHiddenRows] = useState({});

  const tableColumn = renderConsultListColumn();

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(item =>
          item.label ? (
            <TableCellFiltedWrapper
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
            />
          ) : (
            <TableCell key={item.stateValue}></TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = row => (
    <>
      <FiltedBodyCell hidden={hiddenRows.consultantDate}>
        {momentFormatted(row.consultantDate, DATE_FORMAT_2)}
      </FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.consult}>
        <>
          <Typography>{ConsultType[row.consultType]}</Typography>
          <Typography style={{ color: '#8F9BA9' }}>
            {row.consultShortId}
          </Typography>
        </>
      </FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.doctorFullName}>
        {row.doctorFullName}
      </FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.patient}>
        <>
          <Typography>{row.patientFullName}</Typography>
          <Typography style={{ color: '#8F9BA9' }}>
            {row.patientShortId}
          </Typography>
        </>
      </FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.diagnosis}>
        {(get(row, 'diagnosis', []) || []).map(i => (
          <p>{i}</p>
        ))}
      </FiltedBodyCell>

      <FiltedBodyCell hidden={hiddenRows.duration}>
        {durationFormatted(row.startCallTime, row.endCallTime)}
      </FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.totalCost}>
        {`$${row.totalCost || '0'}`}
      </FiltedBodyCell>
      <FiltedBodyCell hidden={hiddenRows.consultStatus}>
        <ChipStatus color={StatusColor[row.consultStatus]}>
          {ConsultStatusDisplay[row.consultStatus] || 'No data'}
        </ChipStatus>
      </FiltedBodyCell>
    </>
  );

  return (
    <>
      <DialogTitle onClose={onClose}>{`Consult List`}</DialogTitle>
      <Divider />
      <DialogContent
        style={{ height: 'calc(100vh - 300px)', overflowY: 'auto' }}
      >
        <InlineTable
          Header={TableHeader}
          data={listConsult}
          noDataHelperText="No data"
          renderRow={renderTableBody}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <CustomButton
          onClick={onClose}
          variant="contained"
          color="primary"
          style={{ borderRadius: 50, margin: 5 }}
        >
          Close
        </CustomButton>
      </DialogActions>
    </>
  );
};

export default ModalShowConsultList;
