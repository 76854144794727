import React, { useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { ENTER_KEY } from '../../../common';
import styled from 'styled-components';
import {
  InputValidationError,
  EmailIcon,
  LockIcon,
  EyeHide,
  EyeShow
} from '../../../../assets/svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import LoadingButton from '../../../../components/LoadingButton';
import { getStorage } from '../../../../helpers';

const LoginInfo = ({
  errors,
  onChange,
  values,
  handleLogin,
  toForgotPassword,
  toRegister,
  loading
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(getStorage('isChecked') || false);

  return (
    <LoginInfoStyle>
      <Typography
        style={{
          fontSize: 24,
          letterSpacing: 0,
          fontWeight: 600
        }}
      >
        Log into your trainer account
      </Typography>
      {errors.api && (
        <div style={{ display: 'flex', marginTop: 20 }}>
          <InputValidationError style={{ marginRight: 8 }} />
          <CustomTypography style={{ color: '#EA6B75' }}>
            {errors.api || `Email or Password is incorrect`}
          </CustomTypography>
        </div>
      )}
      <FieldStyle>
        <EmailIcon style={{ marginRight: 13, marginTop: 40 }} />
        <TextFieldStyle
          name="email"
          label="Email"
          autoComplete="email"
          value={values.email}
          onChange={onChange('email')}
          error={errors.email}
          helperText={errors.email}
        />
      </FieldStyle>
      <FieldStyle>
        <LockIcon style={{ marginRight: 13, marginTop: 40 }} />
        <TextFieldStyle
          type={showPassword ? '' : 'password'}
          label="Password"
          error={errors.password}
          helperText={errors.password}
          value={values.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <>
                  <EyeHide
                    style={{ cursor: 'pointer' }}
                    hidden={showPassword}
                    onClick={() => setShowPassword(true)}
                  />
                  <EyeShow
                    style={{ cursor: 'pointer' }}
                    hidden={!showPassword}
                    onClick={() => setShowPassword(false)}
                  />
                </>
              </InputAdornment>
            )
          }}
          onChange={onChange('password')}
          onKeyDown={e => e.keyCode === ENTER_KEY && handleLogin()}
        />
      </FieldStyle>
      <div
        style={{
          display: 'flex',
          marginTop: 30,
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 60
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            style={{ height: 21 }}
          />
          <CustomTypography>Remember me</CustomTypography>
        </div>
        <CustomTypography
          style={{ fontWeight: 600, color: '#AD5E99', cursor: 'pointer' }}
          onClick={toForgotPassword}
        >
          Forgot Password?
        </CustomTypography>
      </div>
      <LoadingButton
        onClick={() => handleLogin(isChecked)}
        showLoader={loading}
      >
        Login
      </LoadingButton>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <CustomTypography style={{ color: '#8F9BA9' }}>
          Don't have an account yet?
        </CustomTypography>
        <CustomTypography
          style={{ marginLeft: 6, color: '#AD5E99', cursor: 'pointer' }}
          onClick={toRegister}
        >
          Sign up
        </CustomTypography>
      </div>
    </LoginInfoStyle>
  );
};

export default LoginInfo;

const LoginInfoStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  border-radius: 10px;
  background-color: white;
  padding: 40px 20px;
`;
const TextFieldStyle = styled(TextField)`
  height: 40px;
  width: 308px;
`;
const CustomTypography = styled(Typography)`
  letter-spacing: 0 !important;
  font-size: 14px !important;
`;
const FieldStyle = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 25px;
  align-items: center;
  height: 40px;
`;
