import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import CustomTabs from '@/new-components/CustomTabs';
import Tests from '@/module/setup/components/ServiceItems/Tests';
import ProfilesForm from './ProfilesForm';

const useStyles = makeStyles({
  leftContainer: {
    height: 'calc(100vh - 200px)'
  },
  rightContainer: {
    width: '100%',
    height: 'calc(100vh - 200px)',
    overflow: 'auto'
  }
});

const AvailableServicesStep = ({ errors, formValue, setFormValue }) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  // isPackage when update inside package
  const TABS = [
    {
      label: 'Tests',
      chipLabel: formValue?.lstTests?.length,
      body: (
        <Tests
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          requirePrice={false}
          removeColumns={['price']}
        />
      )
    },
    {
      label: 'Profiles',
      chipLabel: formValue?.lstOldProfiles?.length,
      body: (
        <ProfilesForm
          errors={errors}
          formValue={formValue}
          setFormValue={setFormValue}
          requirePrice={false}
        />
      )
    }
  ];

  return (
    <>
      <Box display="flex">
        <CustomTabs
          listTab={TABS}
          value={currentTab}
          onChange={(e, val) => {
            setCurrentTab(val);
          }}
          renderLabel="label"
          renderKey="label"
          orientation="vertical"
          height="calc(100vh - 200px)"
        />

        <main className={classes.rightContainer}>{TABS[currentTab].body}</main>
      </Box>
    </>
  );
};

export default AvailableServicesStep;
