import { Box, Card, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';

const CustomCard = withStyles({
  root: {
    boxShadow: 'none',
    borderRadius: 10,
    background: 'linear-gradient(to bottom, #FEFFFF 0%, #EDF7FF 100%)',
    width: props => (props.width ? props.width : 'unset'),
    zIndex: -1
  }
})(Card);

const CustomHeaderBox = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.light,

    '& .title': {
      fontWeight: 800,
      fontFamily: 'Lato',
      fontSize: 20,

      color: theme.palette.secondary.main
    }
  }
}))(Box);

const CustomBox = withStyles({
  root: {
    '& .divider': {
      height: 1,
      width: '100%',
      marginTop: 20,
      backgroundImage:
        'linear-gradient(to right, #3776ab 50%, transparent 50%)',
      backgroundPosition: 'top',
      backgroundSize: '10px 1px',
      backgroundRepeat: 'repeat-x'
    },

    '&:last-child .divider': {
      display: 'none'
    }
  }
})(Box);

const DividerBox = withStyles({
  root: {
    height: 1,
    width: '100%',
    backgroundColor: '#3776ab',
    margin: '16px 0'
  }
})(Box);

const DividerDashBox = withStyles({
  root: {
    height: 1,
    width: '100%',
    margin: '16px 0',
    backgroundImage:
      'linear-gradient(to right, #3776ab4D 50%, transparent 50%)',
    backgroundPosition: 'top',
    backgroundSize: '10px 1px',
    backgroundRepeat: 'repeat-x'
  }
})(Box);

const BigText = withStyles({
  root: {
    fontSize: 16,
    color: '#333333',
    wordBreak: 'break-all'
  }
})(Box);

const Text = withStyles({
  root: {
    fontSize: 16,
    color: prop => (prop.color ? prop.color : '#333333'),
    fontWeight: 700,
    wordBreak: 'break-all'
  }
})(Box);

const RowItem = ({ children, title, pb = 1 }) => {
  return (
    <Box display="flex" justifyContent="space-between" pb={pb}>
      <BigText>{title}</BigText>
      {children}
    </Box>
  );
};

const Invoice = ({ consultDetail }) => {
  const renderTotal = data => {
    const discountAmount = data?.discountAmount || 0;
    const additionalCost = data?.additionalCost || 0;
    const totalConsultFees = data?.counsellingFee || 0;

    return additionalCost + totalConsultFees - discountAmount;
  };

  const ConsultFees = ({ data }) => {
    return (
      <>
        <RowItem title="Counselling fee" b={0}>
          <NumberFormat
            prefix="$"
            thousandSeparator=" "
            decimalScale={2}
            value={data?.counsellingFee || 0}
            displayType="text"
            renderText={value => <Text>{value}</Text>}
          />
        </RowItem>
        <DividerDashBox />
      </>
    );
  };

  const OtherFees = ({ data }) => {
    return (
      <>
        <RowItem title="Deduction">
          <NumberFormat
            prefix={data?.discountAmount ? '-$' : '$'}
            thousandSeparator=" "
            decimalScale={2}
            value={data?.discountAmount || 0}
            displayType="text"
            renderText={value => <Text>{value}</Text>}
          />
        </RowItem>
        <RowItem title="Addition">
          <NumberFormat
            prefix="$"
            thousandSeparator=" "
            decimalScale={2}
            value={data?.additionalCost || 0}
            displayType="text"
            renderText={value => <Text>{value}</Text>}
          />
        </RowItem>
        <DividerBox />
        <RowItem title="GST (Inclusive)">
          <NumberFormat
            prefix="$"
            thousandSeparator=" "
            decimalScale={2}
            value={data?.gst || 0}
            displayType="text"
            renderText={value => <Text>{value}</Text>}
          />
        </RowItem>
        <RowItem title="Total">
          <NumberFormat
            prefix="$"
            thousandSeparator=" "
            decimalScale={2}
            value={data?.totalCost || 0}
            displayType="text"
            renderText={value => <Text>{value}</Text>}
          />
        </RowItem>
        <RowItem title="Deposit deduction" pb={0}>
          <NumberFormat
            prefix={data?.depositDeduction ? '-$' : '$'}
            thousandSeparator=" "
            decimalScale={2}
            value={data?.depositDeduction || 0}
            displayType="text"
            renderText={value => <Text>{value}</Text>}
          />
        </RowItem>
      </>
    );
  };

  return (
    <CustomCard color="#e2f2ffb3" width={360}>
      <CustomHeaderBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={1.625}
        px={2.5}
      >
        <Typography className="title" style={{ color: '#333333' }}>
          Invoice
        </Typography>
      </CustomHeaderBox>
      <Box overflow="auto">
        <CustomBox pt={2.5} px={2.5}>
          <ConsultFees data={consultDetail} />
          <OtherFees data={consultDetail} />
        </CustomBox>
      </Box>
      <Box pb={2.5} px={2.5}>
        <DividerBox />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text color={'#ad5e99'}>AMOUNT PAYABLE</Text>

          <NumberFormat
            prefix="$"
            thousandSeparator=" "
            decimalScale={2}
            value={consultDetail?.payableAmount || 0}
            displayType="text"
            renderText={value => <Text color={'#ad5e99'}>{value}</Text>}
          />
        </Box>
      </Box>
    </CustomCard>
  );
};

export default Invoice;
