import CustomSelect from '.';
import MutipleSelection from './MutipleSelection';
import React, { useState } from 'react';

const CustomSelectContainer = ({
  filterValue,
  data,
  renderValue,
  onClear,
  onChange,
  isFullWidth,
  useSelectAll,
  checkedAll,
  labelSelectAll,
  height,
  isMutiple,
  disabled,
  label,
  clearable,
  fontSize,
  width,
  colorInput,
  placeholder
}) => {
  const [open, setOpen] = useState(false);

  return (
    <CustomSelect
      onClick={() => {
        setOpen(!open);
      }}
      clearable={clearable}
      onClear={onClear}
      isShowArrow
      renderValue={renderValue}
      isFullWidth={isFullWidth}
      height={height}
      disabled={disabled}
      fontSize={fontSize}
      open={open}
      colorInput={colorInput}
      placeholder={placeholder}
    >
      <MutipleSelection
        label={label}
        isMutiple={isMutiple}
        value={filterValue}
        data={data}
        open={open}
        onChange={onChange}
        useSelectAll={useSelectAll}
        checkedAll={checkedAll}
        labelSelectAll={labelSelectAll}
        height={height}
        width={width}
      />
    </CustomSelect>
  );
};

export default CustomSelectContainer;
