import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

import LoadingButton from '@/components/LoadingButton';
import validateData from '@/helpers/validationHelpers/validationSchema';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: 400
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const ModalPayment = ({
  open,
  onClose,
  updateStatusPayment,
  code,
  isShowDelivery
}) => {
  const [formData, setFormData] = useState({
    isDelivery: false,
    remark: ''
  });
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const onChangeValue = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async () => {
    try {
      await validateData('consultListPaymentStatus', formData, validData => {
        setLoader(true);
        updateStatusPayment(validData, () => {
          setLoader(false);
        });
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>{`UPDATE PAYMENT ${code}`}</DialogTitle>
      <DialogContent dividers>
        <FormControl
          component="fieldset"
          style={{ width: '100%', marginBottom: 8 }}
        >
          <TextField
            error={!!errors?.remark}
            helperText={errors?.remark}
            value={formData.remark}
            required
            label="Remarks"
            id="payment-remark"
            name="remark"
            onChange={e => onChangeValue('remark', e.target.value)}
          />
        </FormControl>
        {isShowDelivery && (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={formData.isDelivery}
                onChange={e => onChangeValue('isDelivery', e.target.checked)}
                name="isDelivery"
              />
            }
            label="Delivery"
          />
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onSubmit} showLoader={loader}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ModalPayment.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateStatusPayment: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired
};

export default ModalPayment;
