import dispatcher from './actions';

const initialState = {
  teleConsultRevenueLoading: false,
  teleConsultEpisodesLoading: false,
  teleConsultPeakDayOfWeekLoading: false,
  teleConsultPeakHourOfDayLoading: false,
  categories: [],
  locations: [],
  stations: [],
  consents: [],
  tests: [],
  patientInfo: {},
};

const globalReducer = dispatcher(initialState, {
  [dispatcher.saveDataSuccess]: (_, payload) => {
    const { name, value } = payload;
    return {
      [name]: value,
    };
  },
  [dispatcher.showHideLoadingAction]: (_, payload) => {
    const { name, value } = payload;
    return {
      [name]: value,
    };
  },
});

export default globalReducer;
