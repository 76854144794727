import React from 'react';
import { TableCell, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    wordBreak: 'break-word'
  }
});

const FiltedBodyCell = ({ hidden, children, ...props }) => {
  const classes = useStyles();
  return (
    <TableCell {...props} className={classes.root}>
      {!hidden && children}
    </TableCell>
  );
};

export default FiltedBodyCell;
