import React, { useState, useEffect, useMemo } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  Dialog,
  Modal,
  Typography,
  Fab,
  Tooltip
} from '@material-ui/core';
import { get, startCase, set } from 'lodash';
import {
  MoreVertRounded,
  DeleteRounded,
  GetAppRounded
} from '@material-ui/icons';
import customToast from '@/new-components/CustomNotification';
import TableContainer from '../../../../components/Table/TableContainer';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SEARCH_PARAM, TYPE_MODAL, FORMAT_DATE_TIME } from '../../constants';
import { MenuAction, SearchLivestream } from '../../components';
import BasicFormModalPage from './BasicFormModalPage';
import LivestreamFormModalPage from './LivestreamFormModalPage';
import onDemandDispatcher from '../../../on-demand/actions/index';
import livestreamDispatcher from '../../actions/index';
import TableCellFilted from '../../../common/componentUI/TableCellFilted';
import ModalPresenter from '../../../../components/ModalPresenter';
import moment from 'moment';
import ChatPage from '../LivestreamPage/ChatPage';
import styled from 'styled-components';
import {
  leaveAllChannel,
  joinMultiChannel,
  formatChannels,
  mergeListDateTime
} from '../../helpers';
import { ChipStatus } from '../../../common/componentUI/commonStyleComponents';
import {
  LiveStreamStatusColor,
  StatusDisplay,
  LiveStreamStatus
} from '../../../../enum/WorkoutEnum';
import CustomTooltip from '@/components/CustomTooltip';

const LivestreamListPage = () => {
  const { list, paging } = useSelector(state => state.livestream);
  const userInfo = useSelector(state => state.auth.userInfo);
  const username =
    (userInfo.firstName + ' ' + userInfo.lastName || userInfo.fullName) +
    ',' +
    userInfo.id;

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState(SEARCH_PARAM);
  const [sortOption, setSortOption] = useState({
    by: 'Date',
    dir: 'desc'
  });

  const [hiddenRows, setHiddenRows] = useState({});
  const [livestreamSelected, setLivestreamSelected] = useState({});
  const [typeModal, setTypeModal] = useState(null);

  const [showModalStartLivestream, setShowModalStartLivestream] = useState(
    false
  );
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalBasicForm, setShowModalBasicForm] = useState(false);
  const [showModalLivestream, setShowModalLivestream] = useState(false);
  const [dataLivestream, setDataLivestream] = useState(null);

  useEffect(() => {
    livestreamDispatcher.getCategoryLivestream();
    onDemandDispatcher.getDurationIntensity();
    livestreamDispatcher.getListTrainer();
    livestreamDispatcher.getListHost();
  }, []);

  const TableCellFiltedWrapper = ({ ...props }) => {
    return (
      <TableCellFilted
        {...{
          hiddenRows,
          setHiddenRows
        }}
        {...props}
      />
    );
  };

  const onSortChange = name => {
    let newSortOption = {
      by: name,
      dir: sortOption.dir !== 'asc' ? 'asc' : 'desc'
    };
    setSortOption(newSortOption);
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper
          style={{ width: 110 }}
          label="Session ID"
          sortBy="SessionId"
          stateValue="id"
          isSort
          sortOption={sortOption}
          onSortChange={() => onSortChange('SessionId')}
        />
        <TableCellFiltedWrapper
          label="Workout Type"
          stateValue="categoryTitle"
          sortBy="CategoryTitle"
          isSort
          sortOption={sortOption}
          style={{ width: 120 }}
          onSortChange={() => onSortChange('CategoryTitle')}
        />
        <TableCellFiltedWrapper
          label="Workout Name"
          stateValue="title"
          style={{ width: 120 }}
        />
        <TableCellFiltedWrapper
          label="Date Time"
          stateValue="date"
          sortBy="Date"
          style={{ width: 120 }}
          isSort
          sortOption={sortOption}
          onSortChange={() => onSortChange('Date')}
        />
        <TableCellFiltedWrapper
          label="Trainer"
          stateValue="trainerName"
          sortBy="TrainerName"
          style={{ width: 120 }}
          isSort
          sortOption={sortOption}
          onSortChange={() => onSortChange('TrainerName')}
        />
        <TableCellFiltedWrapper
          label="Trainer's Phone"
          stateValue="trainerMobile"
          sortBy="TrainerMobile"
          style={{ width: 130 }}
          isSort
          sortOption={sortOption}
          onSortChange={() => onSortChange('TrainerMobile')}
        />
        <TableCellFiltedWrapper
          style={{ width: 100 }}
          label="Intensity"
          stateValue="intensity"
        />
        <TableCellFiltedWrapper
          style={{ width: 100 }}
          label="Duration"
          stateValue="duration"
        />
        <TableCellFiltedWrapper
          style={{ width: 100 }}
          label="Calories"
          stateValue="calories"
        />
        <TableCellFiltedWrapper
          style={{ width: 100 }}
          label="Description"
          stateValue="description"
        />
        <TableCellFiltedWrapper
          style={{ width: 100 }}
          label="Equipment"
          stateValue="equipment"
        />
        <TableCellFiltedWrapper
          style={{ width: 100 }}
          label="Status"
          stateValue="status"
        />
        <TableCellFiltedWrapper
          label="Max. Views"
          stateValue="numberOfMaxConcurrentViews"
          sortBy="NumberOfMaxConcurrentViews"
          style={{ width: 100 }}
          isSort
          sortOption={sortOption}
          onSortChange={() => onSortChange('NumberOfMaxConcurrentViews')}
        />
        <TableCellFiltedWrapper
          label="Unique Viewer"
          stateValue="totalNumberUniqueViewer"
          sortBy="TotalNumberUniqueViewer"
          style={{ width: 120 }}
          isSort
          sortOption={sortOption}
          onSortChange={() => onSortChange('TotalNumberUniqueViewer')}
        />
        <TableCellFiltedWrapper
          label="New Viewer"
          stateValue="totalNumberNewViewer"
          sortBy="TotalNumberNewViewer"
          style={{ width: 100 }}
          isSort
          sortOption={sortOption}
          onSortChange={() => onSortChange('TotalNumberNewViewer')}
        />
        <TableCellFiltedWrapper
          label="Avg. View (min)"
          stateValue="averageViewDuration"
          sortBy="AverageViewDuration"
          style={{ width: 120 }}
          isSort
          sortOption={sortOption}
          onSortChange={() => onSortChange('AverageViewDuration')}
        />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const onShowModalBasicForm = type => {
    setTypeModal(type);
    setShowModalBasicForm(true);
    type !== TYPE_MODAL.Create && getLivestreamByID(livestreamSelected.id);
    setAnchorEl(null);
  };

  const onCloseModalBasicForm = () => {
    setShowModalBasicForm(false);
    setDataLivestream(null);
  };

  const showModalConfirmDelete = () => {
    setShowModalConfirm(true);
    setAnchorEl(null);
  };

  const handleDeleteWorkout = () => {
    livestreamDispatcher.deleteLivestream(livestreamSelected.id, () => {
      setFilterOption({ ...filterOption });
    });
    setShowModalConfirm(false);
  };

  const onCloseModalLivestream = () => {
    setShowModalLivestream(false);
    setTypeModal(null);
    setDataLivestream(null);
  };

  const handleSubmitLivestream = data => {
    const payload = { ...dataLivestream, ...data };
    if (get(payload, 'id')) {
      livestreamDispatcher.editLivestream(
        { data: payload, id: payload.id },
        () => {
          setFilterOption({ ...filterOption });
          onCloseModalLivestream();
        }
      );
      return;
    }

    //format listDates before create livestream
    const listDates = mergeListDateTime(
      dataLivestream.listDates,
      dataLivestream.startHour
    );
    set(payload, 'listDates', listDates);

    livestreamDispatcher.addLivestream(payload, () => {
      setFilterOption({ ...filterOption });
      onCloseModalLivestream();
      setDataLivestream(null);
    });
  };

  const showStartLivestream = () => {
    setShowModalStartLivestream(true);
    setAnchorEl(null);
    joinMultiChannel(
      username,
      formatChannels(livestreamSelected.id, userInfo.roleName)
    );
  };

  const onCloseModalStartLivestream = () => {
    setShowModalStartLivestream(false);
    leaveAllChannel();
  };

  const handleBackToBasicForm = () => {
    setShowModalLivestream(false);
    setShowModalBasicForm(true);
  };

  const onSaveBasicForm = data => {
    onCloseModalBasicForm();
    setDataLivestream(data);
    setShowModalLivestream(true);
  };

  const getLivestreamByID = id => {
    livestreamDispatcher.getLivestreamByID(id, result => {
      setLivestreamSelected(result);
    });
  };

  const enforceLiveStream = id => {
    setAnchorEl(null);
    livestreamDispatcher.endForceLiveStream({
      livestreamId: id,
      search: searchKey,
      paging
    });
  };

  const showDataViewsTime = data => {
    if (!data) return null;
    const [viewNumber, time, timePrefix] = data.split(' ');
    if (!viewNumber || !time || !timePrefix) return null;
    return `${viewNumber} views: ${time} ${timePrefix}`;
  };

  const exportExcel = () => {
    if (!list || list?.length === 0) {
      customToast('info', 'No data to export');
      return;
    }
    livestreamDispatcher.getExportExcel({
      ...filterOption,
      sort: sortOption,
      search: searchKey
    });
  };

  const listTableButtons = [
    <Fab
      style={{
        backgroundColor: '#ad5e99',
        color: 'white',
        width: 40,
        height: 40,
        margin: 5
      }}
      aria-label="export"
      onClick={exportExcel}
    >
      <Tooltip title="Export excel">
        <GetAppRounded style={{ fontSize: 18 }} />
      </Tooltip>
    </Fab>
  ];

  return (
    <>
      {showModalConfirm && (
        <Modal
          open={showModalConfirm}
          onClose={() => setShowModalConfirm(false)}
        >
          <div>
            <ModalPresenter
              onClose={() => setShowModalConfirm(false)}
              Icon={
                <DeleteRounded style={{ fontSize: 80, color: '#EA6B75' }} />
              }
              title={`Delete '${get(livestreamSelected, 'title')}' Workout?`}
              onClick={handleDeleteWorkout}
            >
              <Typography>Click “Continue” to delete</Typography>
              <Typography>
                <Typography style={{ fontWeight: 600 }} display={'inline'}>
                  {livestreamSelected.fullName}
                </Typography>
              </Typography>
            </ModalPresenter>
          </div>
        </Modal>
      )}

      {showModalStartLivestream && (
        <WrapperModalLivestream
          maxWidth={false}
          open={showModalStartLivestream}
          onClose={onCloseModalStartLivestream}
          scroll="body"
        >
          <div className="content">
            <ChatPage
              username={username}
              roleName={userInfo.roleName}
              selectedLivestream={livestreamSelected}
              channelName={`${livestreamSelected.id},publicRoom`}
            />
          </div>
        </WrapperModalLivestream>
      )}

      {showModalLivestream && (
        <Dialog
          maxWidth={false}
          open={showModalLivestream}
          onClose={onCloseModalLivestream}
          scroll="body"
        >
          <LivestreamFormModalPage
            livestreamDetail={dataLivestream}
            typeModal={typeModal}
            onCloseModal={onCloseModalLivestream}
            onSaveData={handleSubmitLivestream}
            backToBasicForm={handleBackToBasicForm}
          />
        </Dialog>
      )}

      {showModalBasicForm && (
        <Dialog
          className=""
          open={showModalBasicForm}
          onClose={onCloseModalBasicForm}
          maxWidth="md"
          scroll="body"
          disableEnforceFocus
        >
          <BasicFormModalPage
            onSaveData={onSaveBasicForm}
            typeModal={typeModal}
            onCloseModal={onCloseModalBasicForm}
            livestreamDetail={livestreamSelected || dataLivestream}
          />
        </Dialog>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && (
          <MenuAction
            enforceLiveStream={enforceLiveStream}
            selectedLivestream={livestreamSelected}
            isLive={
              livestreamSelected.liveStreamStatus === LiveStreamStatus.Live
            }
            onShowModal={onShowModalBasicForm}
            onShowConfirmDelete={showModalConfirmDelete}
            onShowStartLivestream={showStartLivestream}
          />
        )}
      </Menu>

      <TableContainer
        title="Upcoming Workouts"
        paging={paging}
        data={list}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        query={livestreamDispatcher.getLivestreamList}
        sortOption={sortOption}
        onAddNew={() => {
          setLivestreamSelected(null);
          onShowModalBasicForm(TYPE_MODAL.Create);
        }}
        ToolbarComponent={
          <SearchLivestream
            filterOption={filterOption}
            setFilterOption={setFilterOption}
          />
        }
        renderRow={row => (
          <>
            <TableCell>
              {!hiddenRows.id && (
                <Link
                  style={{ textDecoration: 'underline' }}
                  to={{
                    pathname: `livestream-management/${row.sessionId}`,
                    state: { id: row.id }
                  }}
                >
                  {row.sessionId}
                </Link>
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.categoryTitle && (
                <CustomTooltip content={row.categoryTitle} />
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.title && (
                <CustomTooltip maxLength={15} content={row.title} />
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.date && moment(row.date).format(FORMAT_DATE_TIME)}
            </TableCell>
            <TableCell>{!hiddenRows.trainerName && row.trainerName}</TableCell>
            <TableCell>
              {!hiddenRows.trainerMobile && row.trainerMobile}
            </TableCell>
            <TableCell>{!hiddenRows.intensity && row.intensity}</TableCell>
            <TableCell>
              {!hiddenRows.duration && <span>{row.duration} minutes</span>}
            </TableCell>
            <TableCell>
              {!hiddenRows.calories && <span>{row.calories} kcal</span>}
            </TableCell>
            <TableCell>
              {!hiddenRows.description && (
                <CustomTooltip content={row.description} />
              )}
            </TableCell>
            <TableCell>{!hiddenRows.equipment && row.equipment}</TableCell>
            <TableCell>
              {!hiddenRows.status && (
                <ChipStatus color={LiveStreamStatusColor[row.liveStreamStatus]}>
                  {startCase(StatusDisplay[row.liveStreamStatus])}
                </ChipStatus>
              )}
            </TableCell>
            <TableCell align="center">
              {!hiddenRows.numberOfMaxConcurrentViews &&
                showDataViewsTime(row.numberOfMaxConcurrentViews)}
            </TableCell>
            <TableCell align="center">
              {!hiddenRows.totalNumberUniqueViewer &&
                row.totalNumberUniqueViewer}
            </TableCell>
            <TableCell align="center">
              {!hiddenRows.totalNumberNewViewer && row.totalNumberNewViewer}
            </TableCell>
            <TableCell align="center">
              {!hiddenRows.averageViewDuration && row.averageViewDuration}
            </TableCell>
            <TableCell align="center">
              <IconButton
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  setLivestreamSelected(row);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
        customButtons={listTableButtons}
      ></TableContainer>
    </>
  );
};

const WrapperModalLivestream = styled(Dialog)`
  max-width: unset;
  .content {
    width: 80vw;
  }
`;

export default LivestreamListPage;
