import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, filterOption = {}) => async ({ Api, getState }) => {
    let pagingData = paging || getState().surveyss.paging;
    let { result, status } = await Api.post(`Surveys/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption
    });
    status === 200 && surveysDispatcher.getDataSuccess(result);
  },
  createSurvey: (data, callback) => async ({ Api, customToast }) => {
    surveysDispatcher.setState('loading', true);
    const { status } = await Api.post(`Surveys`, data);
    surveysDispatcher.setState('loading', false);
    if (status === 200) {
      callback && callback();
    }
  },
  editSurvey: (id, data, callback) => async ({ Api, customToast }) => {
    surveysDispatcher.setState('loading', true);
    const { status } = await Api.put(`Surveys/${id}`, data);
    surveysDispatcher.setState('loading', false);
    if (status === 200) {
      callback && callback();
    }
  },
  deleteSurvey: (id, callback) => async ({ Api, customToast }) => {
    const response = await Api.delete(`Surveys/${id}`);
    if (response.status === 200) {
      customToast('success', 'Deleted successful');
      callback && callback();
    }
  },
  setState: (state, value) => ({ state, value })
};

const surveysDispatcher = synthesize('surveys', mapDispatchToAC);
export default surveysDispatcher;
