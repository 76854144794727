import React from 'react';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';

import CustomSidebarMenu from './CustomSidebarMenu';
import CustomTooltip from '@/components/CustomTooltip';

import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { LogoLarge, LogoSmall, Logout } from '@/assets/svg';

import { authDispatcher } from '@/module/auth';
import waitingRoomDispatcher from '@/module/waiting-room/action/waitingRoom';
import userPermissionDispatcher from '@/module/user-permission/action';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    height: '100vh',
    width: 256,
    backgroundColor: theme.palette.grey.disableBackground,

    position: 'sticky',
    top: 0,
    whiteSpace: 'nowrap',
    zIndex: 99,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(10),

    '& .drawer-header-close': {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      paddingBottom: 26,
      gap: 26
    }
  },
  drawerPaperHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  smallLogo: {
    borderRadius: 12,
    width: 40,
    height: 40
  },
  avatar: {
    color: '#ffffff',
    width: 40,
    height: 40,
    backgroundColor: theme.palette.grey.disableBackground
  },
  textUserName: {
    fontSize: 14
  },
  textUserEmail: {
    fontSize: 12
  }
}));

const CustomDrawer = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey.main
  }
}))(Drawer);

const CustomIconButton = withStyles(theme => ({
  root: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.common.white,
    borderRadius: 12
  }
}))(IconButton);

const SidebarMenuContainer = ({ open, onClose, userInfo, ...props }) => {
  const classes = useStyles(props);

  const renderUserName = infor => {
    if (!infor) return '';
    return infor.fullName || `${userInfo.firstName} ${userInfo.lastName}` || '';
  };

  const handleLogout = () => {
    waitingRoomDispatcher.clearWaitingList();
    userPermissionDispatcher.clearPermission();
    authDispatcher.logout();
  };

  return (
    <CustomDrawer
      variant="permanent"
      open={open}
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !open && classes.drawerPaperClose
        )
      }}
    >
      <Box overflow="scroll">
        <Box
          p={2}
          pd={3}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          className={open ? '' : 'drawer-header-close'}
          alignSelf="center"
          position="sticky"
          top="0"
          zIndex={2}
          bgcolor="#F1F1F1"
        >
          {open ? <LogoLarge /> : <LogoSmall className={classes.smallLogo} />}
          <CustomIconButton onClick={onClose}>
            {open ? <ArrowBack /> : <ArrowForward />}
          </CustomIconButton>
        </Box>
        <Box>
          <CustomSidebarMenu isSidebarOpen={open} />
        </Box>
      </Box>
      <Box
        p={2}
        width="100%"
        display="flex"
        flexDirection={open ? 'row' : 'column-reverse'}
        justifyContent={open ? 'space-between' : 'center'}
        alignItems="center"
      >
        <Avatar
          alt={renderUserName(userInfo)}
          src={userInfo.imageUrl}
          variant="rounded"
          size={40}
        ></Avatar>
        {open && (
          <>
            <Box display="flex" flexDirection="column" mx={1}>
              <CustomTooltip
                className={classes.textUserName}
                maxLength={20}
                content={renderUserName(userInfo)}
              />
              <CustomTooltip
                className={classes.textUserEmail}
                maxLength={20}
                content={userInfo?.email}
              />
            </Box>

            <IconButton style={{ borderRadius: 12 }} onClick={handleLogout}>
              <Logout width={20} height={20} />
            </IconButton>
          </>
        )}
      </Box>
    </CustomDrawer>
  );
};

export default SidebarMenuContainer;
