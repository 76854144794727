import { getNumberInfo } from '@/helpers';
import { useCountdown } from '@/helpers/useHookHelpers';
import { BACK_KEY } from '@/module/common';
import CustomLoadingButton from '@/new-components/CustomLoadingButton';
import {
  Box,
  Button,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';

const CustomTitle = withStyles({
  root: {
    fontSize: 32,
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#191919',
  },
})(Typography);

const CustomButton = withStyles({
  root: {
    padding: 0,
    minWidth: 50,
    height: 'auto',

    '&.Mui-disabled': {
      opacity: 0.3,
      color: '#AD5E99',
    },
  },
  label: {
    fontSize: 14,
  },
})(Button);

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
      width: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      marginLeft: 0,
      fontSize: 24,
      textAlign: 'center',
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'OpenSans',
      fontSize: 12,
      gap: 6,
      marginLeft: 2,

      '& .MuiSvgIcon-root': {
        fontSize: 'unset',
      },
    },
  },
})(TextField);

const VerifyOTP = ({ formValue, handleSubmit, reSendOTP, didSendOTP }) => {
  const [counter, reset, hasEnded] = useCountdown(3);
  const [firstChar, setFirstChar] = useState(null);
  const [secondChar, setSecondChar] = useState(null);
  const [thirdChar, setThirdChar] = useState(null);
  const [fourthChar, setFourthChar] = useState(null);
  const [fifthChar, setFifthChar] = useState(null);
  const [sixthChar, setSixthChar] = useState(null);

  useEffect(() => {
    if (isInit()) document.getElementById('first-char').focus();
    else if (firstChar) document.getElementById('second-char').focus();
  }, [firstChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (secondChar) document.getElementById('third-char').focus();
    else document.getElementById('first-char').focus();
  }, [secondChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (thirdChar) document.getElementById('fourth-char').focus();
    else document.getElementById('second-char').focus();
  }, [thirdChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (fourthChar) document.getElementById('fifth-char').focus();
    else document.getElementById('third-char').focus();
  }, [fourthChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (fifthChar) document.getElementById('sixth-char').focus();
    else document.getElementById('fourth-char').focus();
  }, [fifthChar]);
  useEffect(() => {
    if (isInit()) return;
    else if (!sixthChar) document.getElementById('fifth-char').focus();
  }, [sixthChar]);

  const isInit = () =>
    !(
      firstChar ||
      secondChar ||
      thirdChar ||
      fourthChar ||
      fifthChar ||
      sixthChar
    );

  const verifyOTP = () => {
    if (!didSendOTP) return;
    const inputOTP = `${firstChar}${secondChar}${thirdChar}${fourthChar}${fifthChar}${sixthChar}`;
    handleSubmit(inputOTP);
  };

  // const resetInput = () => {
  //   setFirstChar('');
  //   setSecondChar('');
  //   setThirdChar('');
  //   setFourthChar('');
  //   setFifthChar('');
  //   setSixthChar('');
  //   document.getElementById('first-char').focus();
  // };

  const onChangeValue = (setState) => (event) => {
    if (event.target.value.length <= 1) setState(event.target.value);
    else setState(event.target.value.substring(1));
  };

  const onBackKey = (setState) => (e) => {
    if (!e.target.value) setState(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height={'100%'}
      mt={6.25}
      alignItems="center"
    >
      <Box textAlign="center">
        <CustomTitle>Enter OTP</CustomTitle>
        <Box mt={2}>
          Enter the OTP sent to{' '}
          <span style={{ fontWeight: 600, color: '#000' }}>
            {getNumberInfo(formValue.phoneNumber, formValue.countryCode)[1]}
          </span>
        </Box>

        <Box mt={6.25} display="flex" alignItems="center">
          <CustomTextField
            id="first-char"
            value={firstChar || ''}
            onChange={onChangeValue(setFirstChar)}
            variant="outlined"
          />
          <CustomTextField
            id="second-char"
            value={secondChar || ''}
            style={{ marginLeft: 10 }}
            onKeyUp={(e) =>
              e.keyCode === BACK_KEY && onBackKey(setSecondChar)(e)
            }
            onChange={onChangeValue(setSecondChar)}
            variant="outlined"
          />
          <CustomTextField
            id="third-char"
            value={thirdChar || ''}
            style={{ marginLeft: 10 }}
            onKeyUp={(e) =>
              e.keyCode === BACK_KEY && onBackKey(setThirdChar)(e)
            }
            onChange={onChangeValue(setThirdChar)}
            variant="outlined"
          />
          <CustomTextField
            id="fourth-char"
            value={fourthChar || ''}
            style={{ marginLeft: 10 }}
            onKeyUp={(e) =>
              e.keyCode === BACK_KEY && onBackKey(setFourthChar)(e)
            }
            onChange={onChangeValue(setFourthChar)}
            variant="outlined"
          />
          <CustomTextField
            id="fifth-char"
            value={fifthChar || ''}
            style={{ marginLeft: 10 }}
            onKeyUp={(e) =>
              e.keyCode === BACK_KEY && onBackKey(setFifthChar)(e)
            }
            onChange={onChangeValue(setFifthChar)}
            variant="outlined"
          />
          <CustomTextField
            id="sixth-char"
            value={sixthChar || ''}
            onKeyUp={(e) =>
              e.keyCode === BACK_KEY && onBackKey(setSixthChar)(e)
            }
            style={{ marginLeft: 10 }}
            onChange={onChangeValue(setSixthChar)}
            variant="outlined"
          />
        </Box>
      </Box>

      <Box width="100%" textAlign="center" py={3}>
        <CustomLoadingButton
          onClick={verifyOTP}
          variant="contained"
          color="primary"
          fullWidth
          style={{ width: '100%' }}
          disabled={
            !(
              firstChar &&
              secondChar &&
              thirdChar &&
              fourthChar &&
              fifthChar &&
              sixthChar
            )
          }
        >
          Verify
        </CustomLoadingButton>

        <Box mt={2}>
          Didn’t receive OTP ({counter})?{' '}
          <CustomButton
            disabled={!hasEnded}
            onClick={() => {
              reSendOTP();
              reset();
            }}
            style={{ fontWeight: 600, color: '#AD5E99', cursor: 'pointer' }}
          >
            Resend
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default VerifyOTP;
