/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Drawer, Fab, Modal, Typography } from '@material-ui/core';
import { CallRounded, CallEndRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import ModalUI from '../../module/common/componentUI/ModalUI';
import NewAgoraCallAppointmentInstance from '../../agora/agoraCallAppointment';
import { setCurrentUserId } from '../../firebase';
import callDispatcher from '../../redux/booking-appointment/actions';
import {
  VideoIcon,
  MicIcon,
  SpeakerIcon,
  SpeakerOffIcon,
  MicOffIcon,
  VideoOffIcon,
  ShareScreenIcon,
  MessageIcon,
  NRICIcon
} from '../../assets/svg';
import ChatDrawer from './ChatPanel';
import NRICPanel from '../NRICPanel';
import { isEmpty } from 'lodash';
import { isFirefox } from '../../helpers';
import { useContainerDimensions } from '../../helpers/useHookHelpers';
import { doctorStatus } from '../../enum/RequestEnum';
import ModalPresenter from '../ModalPresenter';
import { getListChannels, listenChannels } from '../../firebase';
import styled from 'styled-components';

const useStyles = makeStyles({
  paper: {
    width: props => `calc(100% - ${props.modalWidth}px)`,
    height: '100%',
    top: 0,
    left: 0,
    transition: 'width 0.2s'
  },

  chatContainer: {
    width: 360,
    overflow: 'hidden'
  }
});

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  video {
    height: ${props => (props.isSharing ? `100%` : `200%`)} !important;
    transform: ${props => props.transform} translate(-50%, -50%);
    top: 50% !important;
    left: 50% !important;
  }
`;

let Agora;

const StreamPanel = ({
  // channelInfo,
  userInfo,
  channelId,
  isMuteVideo,
  isMuteAudio,
  isMuteSpeaker,
  isInitLocalStream,
  isSharing,
  showChat,
  isInitSuccess,
  onShareScreen,
  onEndCall,
  partner,
  callId,
  patientId,
  appointmentId,
  showNRIC,
  dataNRC,
  startCallTime
}) => {
  const [modalWidth, setModalWidth] = useState(showChat ? 360 : 0);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [chatChannel, setChatChannel] = useState({});
  const [endModal, setEndModal] = useState(false);
  const classes = useStyles({ modalWidth });
  const [listChannels, setListChannels] = useState([]);
  const listChannelsRef = useRef();
  listChannelsRef.current = listChannels;

  useEffect(() => {
    const chatChannel =
      listChannels.find(channel => channel.members.includes(partner.id)) || {};
    setChatChannel(chatChannel);
  }, [listChannels]);

  const updateListChannels = async () => {
    const listChannelsNew = await getListChannels(userInfo.id);
    listenChannels(userInfo.id, data => {
      if ([...listChannelsRef.current].find(channel => channel.id === data.id))
        setListChannels(
          [...listChannelsRef.current].map(i => (i.id === data.id ? data : i))
        );
      else {
        setListChannels([...listChannelsRef.current, data]);
      }
    });

    setListChannels(listChannelsNew);
  };

  let mainStreamRef = useRef(null);
  const [, , ratio] = useContainerDimensions(mainStreamRef);

  const leaveChannel = () => {
    callDispatcher.setState('calling', false);
    // callDispatcher.endCall(appointmentId);
    callDispatcher.endCall();
    onEndCall(patientId);
  };
  useEffect(() => {
    setCurrentUserId(userInfo.id, userInfo);
    updateListChannels();
    if (!Agora) {
      Agora = new NewAgoraCallAppointmentInstance(
        process.env.REACT_APP_AGORA_APP_ID,
        callDispatcher,
        onShareScreen,
        onEndCall,
        patientId
      );
    }
    return () => {
      // clean up memberAppointment
      callDispatcher.setState('memberAppointment', null);
      if (Agora) {
        Agora.leaveChannel({ leaveOnlyShareScreen: false });
      }
      Agora = null;
    };
  }, []);

  useEffect(() => {
    if (channelId) {
      Agora.joinChannel(channelId);
    }
  }, [channelId]);

  useEffect(() => {
    if (showChat || showNRIC) {
      setModalWidth(360);
    } else {
      setModalWidth(0);
    }
  }, [showChat, showNRIC]);

  const onClickMessageIcon = () => {
    callDispatcher.setState('showChat', !showChat);
    showNRIC && callDispatcher.setState('showNRIC', !showNRIC);
    setHasNewMessage(false);
  };

  const handleToggleNRIC = () => {
    callDispatcher.setState('showNRIC', !showNRIC);
    showChat && callDispatcher.setState('showChat', !showChat);
  };

  const StreamGroupBtn = () => {
    return (
      <div className="stream-group-btn">
        <div>
          <Fab
            className={
              isMuteSpeaker
                ? 'float-button'
                : 'float-button float-button-selected'
            }
            aria-label="mute-speaker"
            disabled={!isInitSuccess}
            onClick={() => Agora.handleSpeaker(!isMuteSpeaker)}
          >
            {isMuteSpeaker ? (
              <SpeakerOffIcon style={{ width: 28, height: 28 }} />
            ) : (
              <SpeakerIcon style={{ width: 28, height: 28 }} />
            )}
          </Fab>
          <Fab
            className={
              isMuteAudio
                ? 'float-button'
                : 'float-button float-button-selected'
            }
            disabled={!isInitSuccess}
            aria-label="mute-audio"
            onClick={() => Agora.handleAuditAudio(!isMuteAudio)}
          >
            {isMuteAudio ? (
              <MicOffIcon style={{ width: 28, height: 28 }} />
            ) : (
              <MicIcon style={{ width: 28, height: 28 }} />
            )}
          </Fab>
          <Fab
            className={
              isMuteVideo
                ? 'float-button'
                : 'float-button float-button-selected'
            }
            style={{ visibility: isInitLocalStream ? 'visible' : 'hidden' }}
            disabled={!isInitSuccess}
            aria-label="enable-video"
            onClick={() => Agora.handleAuditVideo(!isMuteVideo)}
          >
            {isMuteVideo ? (
              <VideoOffIcon style={{ width: 28, height: 28 }} />
            ) : (
              <VideoIcon style={{ width: 28, height: 28 }} />
            )}
          </Fab>
        </div>
        <Fab
          style={{
            backgroundColor: '#EA6B75',
            color: 'white',
            width: 80,
            height: 80
          }}
          aria-label="add"
          disabled={!isInitSuccess}
          onClick={() => {
            setEndModal(true);
          }}
        >
          <CallRounded style={{ fontSize: 32 }} />
        </Fab>

        <div>
          <Fab
            className={
              !isSharing ? 'float-button' : 'float-button float-button-selected'
            }
            aria-label="sharing"
            disabled={!isInitSuccess}
            onClick={() => {
              Agora.handleSharing(isSharing);
            }}
          >
            <ShareScreenIcon style={{ width: 28, height: 28 }} />
          </Fab>

          <Fab
            className={
              !showNRIC ? 'float-button' : 'float-button float-button-selected'
            }
            aria-label="NRIC"
            onClick={handleToggleNRIC}
          >
            <NRICIcon style={{ width: 28, height: 28 }} />
          </Fab>

          <Fab
            className={
              !showChat ? 'float-button' : 'float-button float-button-selected'
            }
            aria-label="chat"
            disabled={!isInitSuccess}
            onClick={() => onClickMessageIcon()}
          >
            {hasNewMessage && <div className="icon-new-message"></div>}
            <MessageIcon style={{ width: 28, height: 28 }} />
          </Fab>
        </div>
      </div>
    );
  };
  return (
    <>
      <ModalUI {...{ open: true, classes }}>
        <Modal open={!!endModal} onClose={() => setEndModal(false)}>
          <div>
            <ModalPresenter
              onClose={() => {
                setEndModal(false);
              }}
              Icon={
                <CallEndRounded style={{ fontSize: 80, color: '#EA6B75' }} />
              }
              title="End Call"
              onClick={leaveChannel}
            >
              <Typography>Click “Continue” to end call</Typography>
            </ModalPresenter>
          </div>
        </Modal>
        <StreamGroupBtn />
        <div
          ref={mainStreamRef}
          key="main_stream_appointment_call"
          id="main_stream_appointment_call"
          transform={
            isFirefox() && !isSharing
              ? `rotate($90deg) scale(${ratio})`
              : 'none'
          }
        >
          {isEmpty(Agora && Agora.getListRemoteStreams()) && (
            <LoadingOutlined
              style={{
                fontSize: 80,
                color: '#fff',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            />
          )}
        </div>

        <div className="local-stream-container">
          <StyledContainer
            key="sub_stream_appointment_call"
            id="sub_stream_appointment_call"
            isSharing={isSharing}
            style={{
              transform: isFirefox() && isSharing ? `rotate(90deg)` : 'none'
            }}
          />
        </div>
        <Drawer
          open={showNRIC}
          anchor="right"
          variant="persistent"
          classes={{ paper: classes.chatContainer }}
          onClose={handleToggleNRIC}
        >
          <NRICPanel
            onClose={handleToggleNRIC}
            data={dataNRC}
            partner={partner}
            isAppt
          />
        </Drawer>

        <Drawer
          open={showChat}
          anchor="right"
          variant="persistent"
          classes={{ paper: classes.chatContainer }}
          onClose={onClickMessageIcon}
        >
          <ChatDrawer
            startCallTime={startCallTime}
            setChatChannel={setChatChannel}
            updateListChannels={updateListChannels}
            partner={partner}
            isOpen={showChat}
            channelInfo={chatChannel}
            onClose={onClickMessageIcon}
            setHasNewMessage={setHasNewMessage}
            callId={callId}
          />
        </Drawer>
      </ModalUI>
    </>
  );
};

StreamPanel.propTypes = {
  isMuteVideo: PropTypes.bool.isRequired,
  isMuteAudio: PropTypes.bool.isRequired,
  isMuteSpeaker: PropTypes.bool.isRequired,
  isSharing: PropTypes.bool.isRequired,
  showChat: PropTypes.bool.isRequired,
  isInitSuccess: PropTypes.bool.isRequired,
  channelId: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired,
  callId: PropTypes.string.isRequired,
  isInitLocalStream: PropTypes.bool.isRequired,

  onShareScreen: PropTypes.func.isRequired,
  onEndCall: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired
};

export default StreamPanel;
