import { LockIcon, EyeHide, EyeShow } from '../../../../assets/svg';
import { ENTER_KEY } from '../../../common';
import { StyledButton } from '../../../common/componentUI/commonStyleComponents';
import authDispatcher from '../../action/auth';
import validateData from '@/helpers/validationHelpers/validationSchema';
import { TextField, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import React, { useState } from 'react';
import styled from 'styled-components';

const ResetPassword = ({ ...props }) => {
  const queryParams = new URLSearchParams(props.location.search);
  const token = queryParams.get('token');
  const userId = queryParams.get('userId');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formValue, setFormValue] = useState({
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});

  const formatUserId = (id) => {
    const regexUuid =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
    const regexSpecial = /[~`!@$%^*()={}\[\];:\'\"<>.,\/\\\?_]/;
    let newid = id;
    // Case 1:  userId contains special character
    if (!regexUuid.test(id)) {
      if (regexSpecial.test(id))
        newid = id.replace(/[~`!@$%^*()={}\[\];:\'\"<>.,\/\\\?_]/g, '');

      if (!regexUuid.test(newid) && newid.length > 36) {
        newid = newid.slice(0, 36);
      }
    }

    return newid;
  };
  const resetPassword = async () => {
    try {
      await validateData('resetPasswordSchema', formValue, () => {
        // Reset password for minc user
        if (userId) {
          authDispatcher.resetPassword(
            token,
            formatUserId(userId),
            formValue.password,
            () => setSuccess(true)
          );
        } else {
          // Reset password for old user to sync data
          authDispatcher.resetPasswordOldUser(token, formValue.password, () =>
            setSuccess(true)
          );
        }

        setErrors({});
      });
    } catch (err) {
      setErrors({ ...err });
    }
  };

  const onHandleChange = (key, e) => {
    const value = e.target.value;
    setFormValue({ ...formValue, [key]: value });
  };

  if (success)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 380,
          borderRadius: 10,
          backgroundColor: 'white',
          padding: '40px 20px',
        }}
      >
        <Typography
          style={{
            fontSize: 24,
            letterSpacing: 0,
            fontWeight: 600,
          }}
        >
          Reset Password
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            letterSpacing: 0,
            color: '#62CAA4',
            marginTop: 20,
          }}
        >
          Your password has been changed successfully!
        </Typography>
      </div>
    );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 427,
        width: 380,
        borderRadius: 10,
        backgroundColor: 'white',
        padding: '40px 20px',
      }}
    >
      <Typography
        style={{
          fontSize: 24,
          letterSpacing: 0,
          fontWeight: 600,
        }}
      >
        Reset Password
      </Typography>

      <div
        style={{
          display: 'flex',
          marginTop: 40,
          marginBottom: errors.password ? 16 : 0,
          alignItems: 'center',
          height: 40,
        }}
      >
        <LockIcon style={{ marginRight: 13, marginTop: 30 }} />
        <TextFieldStyle
          type={showPassword ? '' : 'password'}
          name="Password"
          margin="normal"
          label="Password"
          autoComplete="password"
          value={formValue.password}
          error={errors.password}
          helperText={errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <>
                  <EyeHide
                    style={{ cursor: 'pointer' }}
                    hidden={showPassword}
                    onClick={() => setShowPassword(true)}
                  />
                  <EyeShow
                    style={{ cursor: 'pointer' }}
                    hidden={!showPassword}
                    onClick={() => setShowPassword(false)}
                  />
                </>
              </InputAdornment>
            ),
          }}
          onChange={(e) => onHandleChange('password', e)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: 40,
          marginBottom: 60,
          alignItems: 'center',
          height: 40,
        }}
      >
        <LockIcon style={{ marginRight: 13, marginTop: 30 }} />
        <TextFieldStyle
          type={showConfirmPassword ? '' : 'password'}
          name="Confirm Password"
          margin="normal"
          label="Confirm Password"
          autoComplete="password"
          value={formValue.confirmPassword}
          error={errors.confirmPassword}
          helperText={errors.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <>
                  <EyeHide
                    style={{ cursor: 'pointer' }}
                    hidden={showConfirmPassword}
                    onClick={() => setShowConfirmPassword(true)}
                  />
                  <EyeShow
                    style={{ cursor: 'pointer' }}
                    hidden={!showConfirmPassword}
                    onClick={() => setShowConfirmPassword(false)}
                  />
                </>
              </InputAdornment>
            ),
          }}
          onChange={(e) => onHandleChange('confirmPassword', e)}
          onKeyDown={(e) => e.keyCode === ENTER_KEY && resetPassword()}
        />
      </div>
      <StyledButton onClick={resetPassword}>Save</StyledButton>
    </div>
  );
};

const TextFieldStyle = styled(TextField)`
  min-height: 40px;
  width: 308px;
`;

export default ResetPassword;
