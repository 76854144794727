import { renderGroupMenu } from '@/new-components/CustomSidebarMenu/renderGroupMenu';

import { groupRolePermission } from '@/module/user-permission/helper';
import { synthesize } from 'redux-dispatcher';
import customToast from '@/new-components/CustomNotification';

const mapDispatchToAC = {
  getState: (name, data) => ({ [name]: data }),
  getAllPermissions: () => async ({ Api }) => {
    let { result, status } = await Api.get(`Permissions`);
    status === 200 && roleManagementDispatcher.getState('permissions', result);
  },
  getAllRoles: callback => async ({ Api }) => {
    let { result, status } = await Api.get(`Permissions/roles`);
    status === 200 && roleManagementDispatcher.getState('roles', result);
  },
  getAllConfigs: () => async ({ Api }) => {
    let { result, status } = await Api.get(`Permissions/configs`);
    status === 200 && roleManagementDispatcher.getState('configs', result);
  },

  getUserPermissions: () => async ({ Api }) => {
    const { result, status } = await Api.get(`Permissions/by-user`);

    if (status === 200) {
      const groupedPermission = groupRolePermission(result, renderGroupMenu());
      roleManagementDispatcher.getUserPermissionSuccess(groupedPermission);
      roleManagementDispatcher.getState('userPermission', result);
    }
  },
  getUserPermissionSuccess: groupedPermission => ({
    groupedPermission
  }),
  updateRolePermissions: (rolePermissions, callback) => async ({ Api }) => {
    let { status } = await Api.put(`Permissions/configs`, {
      rolePermissions
    });
    if (status === 200) {
      callback();
      customToast('success', 'Update permission successful');
    }
  },
  clearPermission: payload => ({ payload })
};

const roleManagementDispatcher = synthesize('roleManagement', mapDispatchToAC);
export default roleManagementDispatcher;
