import React, { useState } from 'react';
import { ArtTrackOutlined } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { MenuItem, Checkbox } from '@material-ui/core';
import CustomSelect from '.';
import MutipleSelection from './MutipleSelection';

const CustomSelectContainer = ({
  filterValue,
  data,
  renderValue,
  onClear,
  onChange,
  StartAdornment = ArtTrackOutlined,
  isFullWidth
}) => {
  const [open, setOpen] = useState(false);

  return (
    <CustomSelect
      onClick={() => {
        setOpen(!open);
      }}
      clearable={!isEmpty(filterValue)}
      onClear={onClear}
      prefixIcon={
        <StartAdornment
          style={{
            fontSize: 20,
            color: '#E9E9E9',
            marginRight: 8
          }}
        />
      }
      renderValue={renderValue}
      isFullWidth={isFullWidth}
    >
      <MutipleSelection
        value={filterValue}
        data={data}
        open={open}
        onChange={onChange}
      >
        {data.map(item => (
          <MenuItem
            key={item.key || item.id}
            value={item.value || item.id}
            style={{ fontSize: 14 }}
          >
            <Checkbox
              color="primary"
              checked={filterValue.includes(item.value)}
              style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
            />
            {item.key}
          </MenuItem>
        ))}
      </MutipleSelection>
    </CustomSelect>
  );
};

export default CustomSelectContainer;
