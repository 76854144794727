import React, { useState, useEffect } from 'react';
import {
  DialogContent,
  TextField,
  Grid,
  DialogActions
} from '@material-ui/core';
import { ClearRounded } from '@material-ui/icons';
import { get } from 'lodash';
import { InputAddressEnhance } from '../../common/component';
import {
  ButtonEnhance,
  ModalPage,
  ModalFooter
} from '../../common/componentUI/commonStyleComponents';
import { TYPE_MODAL } from '../../common/constants/typeModal';
import validateData from '../../../helpers/validationHelpers/validationSchema';
import UploadCompAnt from '../../../components/UploadCompAnt';
import { studioDispatcher } from '..';
import LoadingButton from '../../../components/LoadingButton';

const StudioForm = ({
  onClose,
  selectedItem,
  typeModal,
  searchKey,
  filterOption
}) => {
  const [formValue, setFormValue] = useState({});
  const [errors, setErrors] = useState({});
  const [studioThumb, setStudioThumb] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (typeModal === TYPE_MODAL.Edit) {
      setFormValue(selectedItem);
      let studioThumb = get(selectedItem, 'imageUrls[0]');
      studioThumb && setStudioThumb(studioThumb);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    try {
      let data = {
        ...formValue,
        imageUrls: [studioThumb].filter(i => i)
      };
      await validateData('studioSchema', data, validData => {
        setShowLoader(true);
        typeModal === TYPE_MODAL.Create
          ? studioDispatcher.addStudio(
              validData,
              searchKey,
              filterOption,
              onClose,
              () => setShowLoader(false)
            )
          : studioDispatcher.editStudio(
              validData,
              searchKey,
              filterOption,
              onClose,
              () => setShowLoader(false)
            );
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const handleChangeForm = key => e => {
    if (key === 'address')
      setFormValue({
        ...formValue,
        address: e.description,
        latitude: e.lat,
        longitude: e.lng
      });
    else setFormValue({ ...formValue, [key]: get(e, 'target.value') });
  };

  return (
    <ModalPage>
      <DialogContent style={{ width: 400 }}>
        <div className="test-header">
          {typeModal === TYPE_MODAL.Create && 'Create New Studio'}
          {typeModal === TYPE_MODAL.Edit && selectedItem.name}
          <ClearRounded
            style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
            onClick={onClose}
          />
        </div>
        <div className="test-body">
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12}>
              <div style={{ height: 200 }}>
                <UploadCompAnt
                  error={errors.imageUrls}
                  id="upload_studio_image"
                  isAddNew={typeModal === TYPE_MODAL.Create}
                  label="Add Image"
                  fileData={studioThumb}
                  onChange={setStudioThumb}
                  editable
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.name}
                helperText={errors.name}
                label="Name"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.name}
                onChange={handleChangeForm('name')}
              />
            </Grid>
            <Grid item xs={12}>
              <InputAddressEnhance
                addressSelected={formValue.address}
                field="address"
                isSubmitted={!!errors.address}
                errors={errors}
                onSelect={handleChangeForm('address')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.unit}
                helperText={errors.unit}
                label="Unit"
                type="unit"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.unit}
                onChange={handleChangeForm('unit')}
              />
            </Grid>
          </Grid>
        </div>
        <ModalFooter>
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={onClose}
          >
            Cancel
          </ButtonEnhance>
          <LoadingButton showLoader={showLoader} onClick={handleSubmit}>
            {typeModal === TYPE_MODAL.Edit ? 'Update' : 'Create'}
          </LoadingButton>
        </ModalFooter>
      </DialogContent>
    </ModalPage>
  );
};

export default StudioForm;
