import React from 'react';
import { Grid } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import ControlTimePicker from '../../common/component/ControlTimepicker';
import { AddCircle, Delete } from '@material-ui/icons';
import moment from 'moment';

const OperatingHoursItem = ({
  data,
  onUpdateTimeFrame,
  addTimeFrame,
  deleteTimeFrame
}) => {
  return (
    <Grid container spacing={1} className="section">
      <Grid item md={3} className="section-title">
        <span>{data.name}</span>
      </Grid>
      <Grid item md={9} className="section-container">
        {data.items &&
          !!data.items.length &&
          data.items.map((child, index) => (
            <Grid item md={12} className="operate-item">
              <Grid item md={5} sm={12}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <ControlTimePicker
                    ampm={false}
                    value={moment(`2020-01-01 ${child.startTime}`)}
                    onChange={onUpdateTimeFrame('startTime', data, index)}
                    variant="outlined"
                    error={child.error}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={1}>
                <span className="text-color">To</span>
              </Grid>
              <Grid item md={5}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <ControlTimePicker
                    ampm={false}
                    value={moment(`2020-01-01 ${child.endTime}`)}
                    onChange={onUpdateTimeFrame('endTime', data, index)}
                    variant="outlined"
                    error={child.error}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={1}>
                <Delete
                  className="delete-icon"
                  onClick={() => deleteTimeFrame(data, index)}
                />
              </Grid>
            </Grid>
          ))}
        <ButtonEnhance
          background="#EFDFEB"
          color="#AD5E99"
          backgroundHover="#EFDFEB"
          onClick={addTimeFrame}
          className="add-btn"
        >
          <div className="add-group">
            <AddCircle />
            <span>Add time frame</span>
          </div>
        </ButtonEnhance>
      </Grid>
    </Grid>
  );
};

export default OperatingHoursItem;
