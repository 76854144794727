import React from 'react';
import { Grid } from '@material-ui/core';
import { get, startCase, upperCase } from 'lodash';

import DividerHeader from '@/module/common/componentUI/DividerHeader';
import { WrappedItem, RowItem } from './CommonStyles';

const MedicalHistory = ({ data = [], classes, title }) => {
  const MedicalHistoryItem = ({ data, name }) => {
    return (
      <>
        <RowItem>
          <span className={classes.title}>{startCase(name)}:</span>
          <span>{get(data, `${name}.checked`) ? 'Yes' : 'Nill'}</span>
        </RowItem>
        <RowItem>
          <span className={classes.title}>Detail: </span>
          <span>{startCase(get(data, `${name}.details`))}</span>
        </RowItem>
      </>
    );
  };

  const renderData = () => {
    const result = [];
    for (let name in data) {
      const rowItem = (
        <Grid item xs={6} key={name}>
          <WrappedItem>
            <MedicalHistoryItem data={data} name={name} />
          </WrappedItem>
        </Grid>
      );
      result.push(rowItem);
    }
    return result;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DividerHeader title={upperCase(title)} />
        </Grid>
        {renderData()}
      </Grid>
    </>
  );
};

export default MedicalHistory;
