import customToast from '@/new-components/CustomNotification';
import { isEmpty, get } from 'lodash';
import { upcomingDispatcher } from '../module/upcoming-workout';
import livestreamDispatcher from '../module/livestream/actions';
import AgoraLiveStream from '../agora/agoraLivestream';
import { configure } from '..';

const signalR = require('@aspnet/signalr');
let hubConnection;
let hubConnected = false;
let currentWorkoutId = '';
let isCalling = false;
let channelRawData = {};
let currentUserId = '';
export { hubConnection };
export const initLiveStreamConnection = (token = '', workoutId, userId) => {
  try {
    currentUserId = userId;
    stopConnection();
    hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_LIVE_STREAM_HUB_URL, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => token
      })
      .build();
    registerEvents();
    return hubConnection.start().then(() => {
      hubConnected = true;
    });
  } catch (err) {
    console.log(err);
  }
};

export const joinLiveStreamGroup = async workoutId => {
  try {
    currentWorkoutId = workoutId;
    await hubConnection.invoke('JoinGroup', workoutId);
  } catch (error) {
    console.log(error);
  }
};

export const startLiveStream = async workoutId => {
  try {
    currentWorkoutId = workoutId;
    await hubConnection.invoke('StartStream', workoutId);
  } catch (error) {
    console.log(error);
  }
};

export const endLiveStream = async () => {
  try {
    await hubConnection.invoke('EndStream', currentWorkoutId);
  } catch (error) {
    console.log(error);
  }
};

export const switchHost = async hostId => {
  try {
    await hubConnection.invoke('SwitchHost', currentWorkoutId, hostId);
  } catch (error) {
    console.log(error);
  }
};

export const updateAgoraStreamId = async agoraId => {
  try {
    await hubConnection.invoke('NotifyRemoteId', currentWorkoutId, agoraId);
  } catch (error) {
    console.log(error);
  }
};

export const leaveLiveStream = data => {
  try {
    stopConnection();
    AgoraLiveStream.instance.leaveChannel({
      leaveOnlyShareScreen: false
    });
    upcomingDispatcher.setState('calling', false);
    upcomingDispatcher.getLivestreamList();
    livestreamDispatcher.clearMember();
    isCalling = false;
    currentWorkoutId = '';
    customToast('info', 'Livestream has been ended');
  } catch (error) {
    console.log(error);
  }
};

export const stopConnection = async () => {
  try {
    if (hubConnection) {
      await hubConnection.stop();
      hubConnection = null;
    }
  } catch (err) {
    console.log(err);
  }
};

export const registerEvents = () => {
  hubConnection.onclose(function(errors) {
    if (!errors) return;
    customToast(
      'error',
      'Your connection has been disconnected. Try to reload your page to reconnect',
      '',
      {
        timeOut: 0,
        extendedTimeOut: 0
      }
    );
  });

  hubConnection.on('NotifyToAdmin', data => {
    try {
      livestreamDispatcher.memberJoined(get(data, 'CurrentUsers'));
      upcomingDispatcher.setState('channelData', data);
      upcomingDispatcher.setState('calling', true);
      isCalling = true;
      channelRawData = data;
    } catch (err) {
      console.log(err);
    }
  });

  hubConnection.on('NotifyToCohost', data => {
    try {
      upcomingDispatcher.setState('channelData', {
        ...channelRawData,
        ...data
      });
      if (!isCalling) {
        upcomingDispatcher.setState('calling', true);
        isCalling = true;
        channelRawData = {
          ...channelRawData,
          ...data
        };
      }
      if (data.NotificationStatus) {
        switch (data.NotificationStatus) {
          case NOTI_STATUS.SwitchHost:
            if (data.Host.Id === currentUserId) {
              AgoraLiveStream.instance.initStream(
                undefined,
                configure.store.getState().upcoming.videoMode
              );
              upcomingDispatcher.setState('isMuteAudio', false);
              upcomingDispatcher.setState('isMuteVideo', false);
              upcomingDispatcher.setState('isMuteSpeaker', false);
              upcomingDispatcher.setState('isSharing', false);
              upcomingDispatcher.setState('isSharingAudio', false);
              upcomingDispatcher.setState('showChat', false);
              customToast('info', `You have been set as streamer`);
            } else {
              // AgoraLiveStream.instance.unPublishStream();
              // AgoraLiveStream.instance.unPublishAudioSharing();
              AgoraLiveStream.instance.stopLiveStreaming();
            }
            break;
          case NOTI_STATUS.End:
            AgoraLiveStream.instance.leaveChannel({
              leaveOnlyShareScreen: false
            });
            upcomingDispatcher.setState('calling', false);
            upcomingDispatcher.updateStatusOnEndStream(data.WorkoutId);
            livestreamDispatcher.clearMember();
            stopConnection();
            isCalling = false;
            customToast('info', `Stream has been ended`);
            break;
          case NOTI_STATUS.NewUser:
            livestreamDispatcher.memberJoined(get(data, 'User'));
            break;
          case NOTI_STATUS.LeftUser:
            livestreamDispatcher.memberLeft(get(data, 'User'));
            break;
          case NOTI_STATUS.Ongoing:
          case NOTI_STATUS.WaitingRejoin:
            livestreamDispatcher.memberJoined(get(data, 'CurrentUsers'));
            break;
          case NOTI_STATUS.HostDisconnected:
            customToast(
              'info',
              `We ran into some issues, please wait a moment`
            );
            break;
          default:
            break;
        }
        upcomingDispatcher.setState('TotalUser', get(data, 'TotalUser'));
      }
    } catch (err) {
      console.log(err);
    }
  });

  hubConnection.on('NotifyToUser', data => {
    try {
      if (data.ForceStop) {
        leaveLiveStream(data);
      }
    } catch (err) {
      console.log(err);
    }
  });
};

const NOTI_STATUS = {
  SwitchHost: 'SwitchHost',
  End: 'End',
  UserCount: 'UserCount',
  NewUser: 'NewUser',
  LeftUser: 'LeftUser',
  Ongoing: 'Ongoing',
  WaitingRejoin: 'WaitingRejoin',
  HostDisconnected: 'HostDisconnected'
};
