import CustomWeekPicker from '../CustomWeekPicker';
import { DEFAULT_DATE_FILTER_TAB } from './ultils';
import {
  Card,
  CardHeader,
  CardContent,
  withStyles,
  Divider,
  ButtonGroup,
  Button,
  LinearProgress,
  Box
} from '@material-ui/core';
import { debounce, isEmpty, lowerCase } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';

// Set week start on Monday
moment.updateLocale('en', { week: { dow: 1 } });

const CustomCard = withStyles({
  root: {
    boxShadow: 'none',
    borderRadius: 8,
    borderColor: '#e1e1e1',

    overflow: 'auto',
    width: props => (props.width ? props.width : 'unset'),

    '& .MuiCardHeader-root': {
      padding: '24px 16px'
    },
    '& .MuiCardHeader-title': {
      fontSize: 16,
      fontWeight: 700
    }
  }
})(Card);

const CustomCardHeader = withStyles({
  title: { color: '#333333', fontSize: 18, fontFamily: 'Lato' }
})(CardHeader);

const CustomButtonGroup = withStyles(theme => ({
  root: {
    gap: 4,
    display: 'flex',
    flexDirection: 'row',
    padding: 4,
    borderRadius: 8,
    backgroundColor: theme.palette.grey.background,

    '& .MuiButtonGroup-groupedHorizontal:not(:last-child)': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8
    },
    '& .MuiButtonGroup-groupedHorizontal:not(:first-child)': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8
    }
  }
}))(ButtonGroup);

const CustomButton = withStyles(theme => ({
  root: {
    width: 90,
    height: 32
  },
  label: {
    fontSize: 14
  }
}))(Button);

const ChartWrapper = ({
  keyChart,
  title,
  showHeaderAction = true,
  buttonGroups = DEFAULT_DATE_FILTER_TAB,

  ChartComponent,
  chartOptions,
  chartData,
  chartPlugins,
  query,
  loading,
  renderChartData,
  barThickness = 16,
  showFilter = true,
  labelType,
  Filters
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [dates, setDates] = useState([]);
  const [quickRange, setQuickRange] = useState(null);
  const chartRef = useRef(null);

  const [weekPicker, setWeekPicker] = useState(
    moment()
      .startOf('week')
      .add('day', 1)
  );

  const [filterOptions, setFilterOptions] = useState({});

  const isAllTime = buttonGroups[currentTab].key === 'All time';

  const onChangeWeekPicker = (type, value) => {
    let startDate = moment(value).startOf('week');
    let endDate = moment(value)
      .endOf('week')
      .startOf('day');

    switch (type) {
      case 'day':
        startDate = moment(value).startOf('day');
        endDate = moment(value).endOf('day');

        break;
      case 'month':
        startDate = moment(value).startOf('month');
        endDate = moment(value).endOf('month');

        break;
      case 'year':
        startDate = moment(value).startOf('year');
        endDate = moment(value).endOf('year');

        break;
      default:
        break;
    }

    setFilterOptions({
      ...filterOptions,
      fromDate: startDate.toISOString(),
      toDate: endDate.toISOString()
    });
    setDates([startDate, endDate]);
    setWeekPicker(startDate);
  };

  const handleChangeFilter = (chartId, options) => {
    setQuickRange(null);
    setWeekPicker(options.fromDate);
    setDates([options.fromDate, options.toDate]);
    setFilterOptions({ ...filterOptions, ...options });
  };

  const formatChartData = useCallback(() => {
    let labels = [];
    let data = [];

    if (!isEmpty(chartData) && !loading) {
      labels = chartData.map(d => d.label);
      data = chartData.map(d => (d[keyChart] === null ? 0 : d[keyChart]));
    }

    return [labels, data];
  }, [loading, chartData, keyChart]);

  const fetchData = async (filterOptionData = filterOptions) => {
    query(filterOptionData);
  };

  const debounceLoadData = useCallback(
    debounce(filterOptions => {
      fetchData(filterOptions);
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  useEffect(() => {
    setWeekPicker(moment(buttonGroups[currentTab].filterOptions.fromDate));
    setDates([
      buttonGroups[currentTab].filterOptions.fromDate,
      buttonGroups[currentTab].filterOptions.toDate
    ]);

    setFilterOptions(buttonGroups[currentTab].filterOptions);
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      const chartArea = chartRef?.current?.chartArea;
      if (chartRef.current && chartArea) {
        const { left, right, top, bottom } = chartArea;
        if (
          event.clientX < left ||
          event.clientX > right ||
          event.clientY < top ||
          event.clientY > bottom
        ) {
          const chart = chartRef.current;
          chart.tooltip.setActiveElements([]);
          chart.update();
        }
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <CustomCard variant="outlined">
      {title && (
        <CustomCardHeader
          title={title}
          action={
            <CustomButtonGroup>
              {showHeaderAction &&
                buttonGroups.map((item, index) => (
                  <CustomButton
                    key={item.key}
                    variant="contained"
                    color={currentTab === index ? 'primary' : 'default'}
                    onClick={() => {
                      setCurrentTab(index);
                      handleChangeFilter(title, item.filterOptions);
                    }}
                    style={{
                      backgroundColor:
                        currentTab === index ? '#AD5E99' : '#F6F6F6'
                    }}
                  >
                    {item.key}
                  </CustomButton>
                ))}
            </CustomButtonGroup>
          }
        />
      )}

      <Divider light />
      <CardContent>
        {showFilter ? (
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            gridGap={8}
          >
            {Filters ? (
              <Filters
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
              />
            ) : (
              <Box></Box>
            )}

            {isAllTime ? null : (
              <CustomWeekPicker
                views={lowerCase(buttonGroups[currentTab].key)}
                setWeekPicker={setWeekPicker}
                selectedDate={weekPicker}
                onChange={onChangeWeekPicker}
                dates={dates}
                setDates={setDates}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
                quickRange={quickRange}
                setQuickRange={setQuickRange}
              />
            )}
          </Box>
        ) : (
          <Box height={64}></Box>
        )}

        {loading ? (
          <LinearProgress />
        ) : (
          <ChartComponent
            ref={chartRef}
            options={chartOptions}
            data={renderChartData(
              ...formatChartData(chartData),
              title,
              barThickness
            )}
            plugins={chartPlugins}
          />
        )}
      </CardContent>
    </CustomCard>
  );
};

export default ChartWrapper;
