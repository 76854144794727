import CustomTabs from '@/new-components/CustomTabs';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import CustomSurvey from '../ServiceItems/CustomSurvey';
import Measurements from '../ServiceItems/Measurements';
import Packages from '../ServiceItems/Packages';
import Procedures from '../ServiceItems/Procedures';
import Profiles from '../ServiceItems/Profiles';
import Radiologies from '../ServiceItems/Radiologies';
import Reviews from '../ServiceItems/Reviews';
import Tests from '../ServiceItems/Tests';
import Vacinations from '../ServiceItems/Vaccinations';

const useStyles = makeStyles({
  leftContainer: {
    height: 'calc(100vh - 200px)'
  },
  rightContainer: {
    width: '100%',
    height: 'calc(100vh - 200px)',
    overflow: 'auto'
  }
});

const AvailableServicesStep = ({
  errors,
  setErrors,
  formValue,
  setFormValue
}) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);

  const TABS = [
    {
      label: 'Packages',
      chipLabel: formValue?.lstPackages?.length,
      body: (
        <Packages
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      )
    },
    // {
    //   label: 'Tests',
    //   chipLabel: formValue?.lstTests?.length,
    //   body: (
    //     <Tests
    //       errors={errors}
    //       setErrors={setErrors}
    //       formValue={formValue}
    //       setFormValue={setFormValue}
    //     />
    //   )
    // },
    {
      label: 'Profiles',
      chipLabel: formValue?.lstProfiles?.length,
      body: (
        <Profiles
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      )
    },
    {
      label: 'Measurements',
      chipLabel: formValue?.lstMeasurements?.length,
      body: (
        <Measurements
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      )
    },
    {
      label: 'Procedures',
      chipLabel: formValue?.lstProcedures?.length,
      body: (
        <Procedures
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      )
    },
    {
      label: 'Radiologies',
      chipLabel: formValue?.lstRadiologies?.length,
      body: (
        <Radiologies
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      )
    },
    {
      label: 'Vaccinations',
      chipLabel: formValue?.lstVaccinations?.length,
      body: (
        <Vacinations
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      )
    },
    {
      label: 'Reviews',
      chipLabel: formValue?.lstReviews?.length,
      body: (
        <Reviews
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      )
    },
    {
      label: 'Custom survey',
      body: (
        <CustomSurvey
          errors={errors}
          setErrors={setErrors}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      )
    }
  ];

  return (
    <>
      <Box display="flex">
        <CustomTabs
          listTab={TABS}
          value={currentTab}
          onChange={(e, val) => {
            setCurrentTab(val);
          }}
          renderLabel="label"
          renderKey="label"
          orientation="vertical"
          height="calc(100vh - 200px)"
        />

        <main className={classes.rightContainer}>{TABS[currentTab].body}</main>
      </Box>
    </>
  );
};

export default AvailableServicesStep;
