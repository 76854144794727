import {
  Button,
  CircularProgress,
  lighten,
  withStyles,
} from '@material-ui/core';
import React from 'react';

const CustomButton = withStyles((theme) => ({
  root: {
    width: 166,
    height: 48,
    borderRadius: 8,

    textTransform: 'unset',
  },

  label: {
    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset',
  },

  containedPrimary: {
    boxShadow: 'none',
  },
  containedError: {
    boxShadow: 'none',

    backgroundColor: theme.palette.feedback.error,
    color: theme.palette.general.white,

    '&:hover': {
      backgroundColor: lighten(theme.palette.feedback.error, 0.3),
    },
  },
}))(Button);

const CustomLoadingButton = ({ children, loading, ...props }) => {
  return (
    <CustomButton
      {...props}
      startIcon={
        loading ? <CircularProgress color="inherit" size={20} /> : null
      }
    >
      {children}
    </CustomButton>
  );
};

export default CustomLoadingButton;
