import React, { useState, useEffect } from 'react';
import ModalPage, {
  ModalFooter,
  WrapperButtonUpload,
  WrapperFormControl,
  WrapperSelect,
  WrapperInput
} from './styled';
import {
  DialogContent,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import { ButtonEnhance } from '../../../common/componentUI/commonStyleComponents';
import LoadingButton from '../../../../components/LoadingButton';
import { ClearRounded } from '@material-ui/icons';
import { get } from 'lodash';
import { TYPE_MODAL } from '../../../common/constants/typeModal';
import validateData from '../../../../helpers/validationHelpers/validationSchema';
import { UploadSingle } from '../../../common/component';
import { AddImageOutlineIcon } from '../../../../assets/svg';

const LocationModal = ({
  onClose,
  selectedItem,
  typeModal,
  onSubmit,
  listIntensity,
  loading
}) => {
  const [formValue, setFormValue] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (typeModal === TYPE_MODAL.Edit) setFormValue(selectedItem);
  }, [typeModal, selectedItem]);

  const handleSubmit = async () => {
    try {
      await validateData('categorySchema', formValue, data => {
        onSubmit(data);
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const handleChangeForm = key => e => {
    const value = key === 'thumbnail' ? e : get(e, 'target.value');
    setFormValue({ ...formValue, [key]: value });
  };

  return (
    <ModalPage>
      <DialogContent>
        <div className="test-header">
          {typeModal === TYPE_MODAL.Create && 'Create New Category'}
          {typeModal === TYPE_MODAL.Edit && selectedItem.title}
          <ClearRounded
            style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
            onClick={onClose}
          />
        </div>
        <div className="test-body">
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={6}>
              <UploadSingle
                typeUpload="normal"
                onChange={handleChangeForm('thumbnail')}
                imageUrlSelected={formValue.thumbnail}
                accept=".png,.jpg,.jpeg"
                error={errors.thumbnail}
                uploadButton={
                  <WrapperButtonUpload>
                    <span className="icon">
                      <AddImageOutlineIcon color="#ccc" />
                    </span>{' '}
                    <span className="text">Add Picture</span>
                  </WrapperButtonUpload>
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={errors.title}
                helperText={errors.title}
                label="Category Name"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.title}
                onChange={handleChangeForm('title')}
              />
            </Grid>
            <Grid item xs={6}>
              <WrapperFormControl error={errors.intensity ? true : false}>
                <InputLabel id="intensity-label">Intensity</InputLabel>
                <WrapperSelect
                  className="select-custom"
                  labelId="intensity-label"
                  value={formValue.intensity || ''}
                  onChange={handleChangeForm('intensity')}
                >
                  {(listIntensity || []).map((item, index) => (
                    <MenuItem key={item.value + index} value={item.text}>
                      {item.text}
                    </MenuItem>
                  ))}
                </WrapperSelect>
                {errors.intensity && (
                  <FormHelperText>{errors.intensity}</FormHelperText>
                )}
              </WrapperFormControl>
            </Grid>
            <Grid item xs={6}>
              <WrapperInput
                error={errors.calories}
                value={formValue.calories}
                onChange={handleChangeForm('calories')}
                label="Calories"
                helperText={errors.calories}
                type="number"
                inputProps={{ min: '0' }}
              />
            </Grid>
            <Grid item xs={6}>
              <WrapperInput
                error={errors.equipment}
                helperText={errors.equipment}
                label="Equipment"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.equipment}
                onChange={handleChangeForm('equipment')}
              />
            </Grid>
            <Grid item xs={12}>
              <WrapperInput
                error={errors.description}
                helperText={errors.description}
                label="Description"
                style={{ width: '100%', marginTop: 10 }}
                value={formValue.description}
                onChange={handleChangeForm('description')}
              />
            </Grid>
          </Grid>
        </div>
        <ModalFooter>
          <ButtonEnhance
            background="#E9E9E9"
            color="black"
            backgroundHover="#ccc"
            onClick={onClose}
          >
            Cancel
          </ButtonEnhance>

          <LoadingButton onClick={handleSubmit} showLoader={loading}>
            {typeModal === TYPE_MODAL.Edit ? 'Update' : 'Create'}
          </LoadingButton>
        </ModalFooter>
      </DialogContent>
    </ModalPage>
  );
};

export default LocationModal;
