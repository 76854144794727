import React from 'react';
import { STATUS_COLOR_UI, STATUSES_APPOINTMENT } from '../constants';
import { startCase } from 'lodash';

export const StatusComponent = ({ status }) => {
  let color = STATUS_COLOR_UI[status]?.txt || '#000';
  let backgroundColor = STATUS_COLOR_UI[status]?.background || '#fff';

  const renderStatusTitle = () => {
    if (status === STATUSES_APPOINTMENT.UNASSIGNED) return 'Un-Assigned';
    if (status === STATUSES_APPOINTMENT.CHECK_IN) return 'Checked-In';
    return startCase(status);
  };

  return (
    <p
      style={{
        backgroundColor,
        color,
        textAlign: 'center',
        fontSize: 12,
        padding: '4px 8px',
        borderRadius: 50
      }}
    >
      {renderStatusTitle()}
    </p>
  );
};
