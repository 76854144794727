import { createDownloadableLink } from '@/helpers';
import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: (data) => ({
    data,
  }),
  getData:
    (search, paging, filterOption = {}) =>
    async ({ Api, getState }) => {
      let pagingData = paging || getState().caConsult.paging;
      let { result, status } = await Api.post(`Consultations`, {
        pageIndex: pagingData.pageIndex,
        pageSize: pagingData.pageSize,
        search,
        ...filterOption,
      });
      if (status === 200) {
        caConsultDispatcher.getDataSuccess(result);
      }
    },
  confirmPayment:
    (id, search, filterOption = {}) =>
    async ({ Api, getState }) => {
      let { status } = await Api.put(`Calls/${id}/confirm-payment`);
      if (status === 200) {
        let pagingData = getState().caConsult.paging;
        caConsultDispatcher.getData(search, pagingData, filterOption);
      }
    },
  processSummary:
    (id, search, filterOption = {}) =>
    async ({ Api, getState }) => {
      let { status } = await Api.put(`Calls/${id}/process-summary`);
      if (status === 200) {
        let pagingData = getState().caConsult.paging;
        caConsultDispatcher.getData(search, pagingData, filterOption);
      }
    },
  confirmOtp:
    (id, otp, callback) =>
    async ({ Api, getState }) => {
      let { status, message } = await Api.put(
        `Calls/${id}/confirm-otp`,
        otp,
        null,
        null,
        false
      );
      callback({ message, status });
    },
  updateDeliveryStatus:
    (id, data = {}, callback) =>
    async ({ Api }) => {
      let { status } = await Api.put(
        `MedicineDeliveries/${id}/delivery-info`,
        data
      );
      status === 200 && callback();
    },
  doneConsult:
    (id, search, filterOption = {}) =>
    async ({ Api, getState }) => {
      let { status } = await Api.put(`Calls/${id}/make-done`);
      if (status === 200) {
        let pagingData = getState().caConsult.paging;
        caConsultDispatcher.getData(search, pagingData, filterOption);
      }
    },
  updateDeliveryType:
    (id, deliveryType, callback) =>
    async ({ Api }) => {
      let { result, status } = await Api.post(
        `MedicineDeliveries/${id}/request-delivery`,
        {
          deliveryType,
        }
      );
      status === 200 && callback(result);
    },
  forceCancelConsult:
    (id, search, filterOption = {}) =>
    async ({ Api, getState }) => {
      let { status } = await Api.put(`Calls/${id}/force-cancel`);
      if (status === 200) {
        let pagingData = getState().caConsult.paging;
        caConsultDispatcher.getData(search, pagingData, filterOption);
      }
    },
  editConsultPrescription:
    (id, payload, callback) =>
    async ({ Api }) => {
      let { status } = await Api.put(`Calls/${id}/edit-price`, payload);
      if (status === 200) {
        callback();
      }
    },
  confirmReadyToPay:
    (id, search, filterOption = {}) =>
    async ({ Api, getState }) => {
      let { status } = await Api.put(`Calls/${id}/confirm-price`);
      if (status === 200) {
        let pagingData = getState().caConsult.paging;
        caConsultDispatcher.getData(search, pagingData, filterOption);
      }
    },
  updateStatusPayment:
    ({ id, search, filterOption = {}, data, onDone, onSuccess }) =>
    async ({ Api, getState }) => {
      let { status } = await Api.put(`Calls/${id}/pay-in-cash`, data);
      if (status === 200) {
        let { paging } = getState().caConsult;
        onSuccess();
        caConsultDispatcher.getData(search, paging, filterOption);
      }
      onDone();
    },
  getExportExcel:
    (search, filterOption = {}) =>
    async ({ Api }) => {
      let { result, status } = await Api.post(`Consultations/csv-report`, {
        search,
        ...filterOption,
      });
      status === 200 && createDownloadableLink(result);
    },
  revertStatusToResultCompleted:
    ({ id, onSuccess, search, filterOption = {} }) =>
    async ({ Api, getState }) => {
      const { status } = await Api.put(`Calls/${id}/reverse`);
      if (status === 200) {
        const { paging } = getState().caConsult;
        caConsultDispatcher.getData(search, paging, filterOption);
        onSuccess();
      }
    },
  revertCoPayment:
    ({ id, onSuccess, search, filterOption = {} }) =>
    async ({ Api, getState }) => {
      const { status } = await Api.delete(`Calls/${id}/co-payment`);
      if (status === 200) {
        const { paging } = getState().caConsult;
        caConsultDispatcher.getData(search, paging, filterOption);
        onSuccess();
      }
    },
  getConsultDetail:
    (id, onSuccess) =>
    async ({ Api }) => {
      const { status, result } = await Api.get(`Consultations/${id}`);
      if (status === 200) {
        onSuccess && onSuccess(result);
      }
    },
  updateCoPayment:
    ({ id, search, filterOption = {}, data, onSuccess }) =>
    async ({ Api, getState }) => {
      let { status } = await Api.put(`Calls/${id}/co-payment`, data);
      caConsultDispatcher.setState('loading', false);
      if (status === 200) {
        let { paging } = getState().caConsult;
        onSuccess();
        caConsultDispatcher.getData(search, paging, filterOption);
      }
    },

  setState: (state, value) => ({ state, value }),
};

const caConsultDispatcher = synthesize('caConsult', mapDispatchToAC);
export default caConsultDispatcher;
