import { isEmpty, uniqBy } from 'lodash';
const handleGetNotifySuccess = (statePrevious, stateNew) => {
  if (isEmpty(statePrevious)) {
    return stateNew;
  }
  return {
    ...statePrevious,
    list: statePrevious.list.concat(stateNew.list),
    totalItem: stateNew.totalItem
  };
};

const handleReadNotifySuccess = (statePrevious, payload) => {
  return {
    ...statePrevious,
    countNotifyUnread: statePrevious.countNotifyUnread - 1,
    list: statePrevious.list.map(item => {
      return {
        ...item,
        isRead: payload === item.id ? true : item.isRead
      };
    })
  };
};

const handleReadAllNotifySuccess = statePrevious => {
  return {
    ...statePrevious,
    countNotifyUnread: 0,
    list: statePrevious.list.map(item => ({
      ...item,
      isRead: true
    }))
  };
};

const handlePushNotification = (statePrevious, payload) => {
  const listNotify = [{ ...payload }, ...statePrevious.list];
  return {
    ...statePrevious,
    list: uniqBy(listNotify, item => item.title),
    totalItem: statePrevious.totalItem + 1,
    countNotifyUnread: statePrevious.countNotifyUnread + 1
  };
};

export {
  handleGetNotifySuccess,
  handleReadNotifySuccess,
  handleReadAllNotifySuccess,
  handlePushNotification
};
