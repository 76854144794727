import React from 'react';
import Lottie from 'react-lottie';

const LottieControl = ({ animationData, options, ...props }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
    width: 80,
    height: 80
  };
  return <Lottie {...props} options={options || defaultOptions} />;
};

export default LottieControl;
