import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { IconButton, Divider } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { StatusComponent } from '../StatusComponent';
import { STATUS_DISPLAY_STATION, STATUS_STATION } from '../../constants';

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600
  }
})(Typography);

const CustomCardHeader = withStyles({
  root: {
    backgroundColor: '#F6F6F6',
    borderTop: '1px solid #E1E1E1',
    borderBottom: '1px solid #E1E1E1'
  }
})(Box);
const CustomButton = withStyles({
  root: {
    minWidth: 62,
    height: 20,
    padding: 0,
    textTransform: 'unset'
  }
})(Button);

const useStyles = makeStyles({
  table: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 350px)',

    '& .MuiTableHead-root > .MuiTableRow-root': {
      height: 40,

      '& .MuiTableCell-root': {
        padding: '8px 16px',
        fontSize: 12,
        fontWeight: 500
      }
    }
  },
  tableBody: {
    '& .MuiTableRow-root': {
      height: 44,

      '& .MuiTableCell-root': {
        padding: '12px 16px'
      }
    }
  },
  disabledIcon: {
    color: '#AD5E994D'
  }
});

const ServicesTable = ({
  columns,
  renderRow,
  data,
  stationName,
  status,
  queueId,
  onDoneAll,
  onNotifyToPatient,
  stationId
}) => {
  const classes = useStyles();
  const hasMeasurementOrMandatory =
    !isEmpty(data) &&
    data.filter(
      it =>
        it.category === 'Measurements' ||
        (it.isMandatory && isEmpty(it.testValue))
    );

  const hasDoneTest =
    !isEmpty(data) && data.filter(it => it.status === STATUS_STATION.Done);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {columns.map(item => (
          <TableCell
            key={item.stateValue}
            style={{ minWidth: item.minWidth || '' }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  return (
    <Box>
      <Card variant="outlined" square>
        <CustomCardHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2.5}
          py={1.5}
        >
          <Box display="flex" alignItems="center" gridGap={12}>
            <CustomTitleLabel>{stationName}</CustomTitleLabel>
            <StatusComponent
              status={status}
              filterList={STATUS_DISPLAY_STATION}
            />
          </Box>
          <Box display="flex" alignItems="center" gridGap={12}>
            <IconButton
              size="small"
              disabled={hasDoneTest?.length === data?.length}
              onClick={() => onNotifyToPatient(queueId)}
            >
              <NotificationsIcon
                className={clsx({
                  [classes.disabledIcon]: hasDoneTest?.length === data?.length
                })}
                color="primary"
              />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <CustomButton
              color="primary"
              disabled={
                !isEmpty(hasMeasurementOrMandatory) ||
                ![STATUS_STATION.Awaiting, 'InProgress'].includes(status)
              }
              onClick={() => onDoneAll(queueId, stationId)}
            >
              Done all
            </CustomButton>
          </Box>
        </CustomCardHeader>

        <TableContainer square component={Paper} variant="outlined">
          <Table className={classes.table} stickyHeader>
            <TableHeader />

            <TableBody className={classes.tableBody}>
              {!isEmpty(data) ? (
                data.map((d, i) => (
                  <TableRow key={d.id}>{renderRow(d, i)}</TableRow>
                ))
              ) : (
                <TableRow>No data.</TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
};

export default ServicesTable;
