import { MAX_PERCENT } from './constants';

export const dummyRequest = input => {
  setTimeout(() => {}, 0);
};

export function convertToFormData(data, parentKey, formData) {
  if (data === null || data === undefined) return null;

  formData = formData || new FormData();

  if (
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach(key =>
      convertToFormData(
        data[key],
        !parentKey
          ? key
          : data[key] instanceof File
          ? parentKey
          : `${parentKey}[${key}]`,
        formData
      )
    );
  } else {
    formData.append(parentKey, data);
  }

  return formData;
}

export const calcProgressItem = (total = 0, sum = MAX_PERCENT) => {
  return parseFloat(sum / total);
};
