import bannerDispatcher from '../action/banner';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  }
};

const bannerReducer = bannerDispatcher(initialState, {
  [bannerDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [bannerDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value
  })
});

export default bannerReducer;
