import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getNotifyUnread: () => async ({ Api }) => {
    const { status, result } = await Api.get('Notifications/count-unread');
    if (status === 200) {
      notificationDispatcher.getNotifyUnreadSuccess({ result });
    }
  },
  getNotifyUnreadSuccess: payload => payload,
  getNotificationList: searchQuery => async ({ Api }) => {
    const { result, status } = await Api.get('Notifications', searchQuery);
    if (status === 200) {
      notificationDispatcher.getNotificationSuccess(result);
    }
  },
  getNotificationSuccess: payload => payload,

  readNotificationSuccess: payload => payload,
  readNotification: id => async ({ Api }) => {
    const { status } = await Api.put(`Notifications/${id}/read`);
    if (status === 200) {
      notificationDispatcher.readNotificationSuccess({ id });
    }
  },
  readAllNotificationSuccess: () => ({}),
  readAllNotification: () => async ({ Api }) => {
    const { status } = await Api.put(`Notifications/read-all`);
    if (status === 200) {
      notificationDispatcher.readAllNotificationSuccess();
    }
  },
  pushNotification: payload => payload
};

export const notificationDispatcher = synthesize(
  'notification',
  mapDispatchToAC
);
