import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';
import { Grid, InputLabel } from '@material-ui/core';
import { Upload } from 'antd';
import { isEmpty } from 'lodash';
import { dummyRequest } from '@/module/lab-result/utils';
import { DocumentIcon, TrashIcon, VectorIcon } from '@/assets/svg';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  },
  text: {
    fontWeight: 600
  }
});

const CustomDialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles(theme => ({
  paper: {
    borderRadius: 16
  },
  paperWidthMD: {
    maxWidth: 600
  }
}))(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset',
    '&.red': {
      background: '#DB3D49'
    }
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const CustomInputLabel = withStyles(theme => ({
  root: {
    color: '#333333',
    fontWeight: 600
  }
}))(InputLabel);

const CustomDialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center',

    '& .main-content': {
      marginTop: 30,
      fontSize: 32,
      fontWeight: 600
    },
    '& .sub-content': {
      fontSize: 16,
      color: '#666666'
    },
    '&:first-child': {
      paddingTop: theme.spacing(5)
    }
  }
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: '16px',
    justifyContent: 'flex-end'
  }
})(DialogActions);

const ModalBulkImport = ({ open, onClose, onSumit, dataInfor }) => {
  const [files, setFiles] = useState({});
  const handleChangeFiles = e => {
    setFiles(e.file);
  };
  return (
    <CustomDialog
      fullWidth
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomDialogTitle onClose={onClose}>Bulk import</CustomDialogTitle>
      <CustomDialogContent dividers>
        <Grid container alignItems="center" spacing={2}>
          {isEmpty(files) && (
            <Grid item xs={12}>
              <CustomInputLabel>Download template for import</CustomInputLabel>
            </Grid>
          )}

          <Grid item xs={12}>
            <Upload.Dragger
              customRequest={dummyRequest}
              onChange={handleChangeFiles}
              showUploadList={false}
              accept={'.xlsx'}
              file={files}
              progress={{
                strokeColor: {
                  '0%': '#108ee9',
                  '100%': '#87d068'
                },
                strokeWidth: 3,
                format: percent =>
                  percent && `${parseFloat(percent.toFixed(2))}%`
              }}
            >
              <Box p={2}>
                <div className="upload-btn">
                  {isEmpty(files) ? (
                    <div className="group">
                      <VectorIcon />
                      <div className="text">
                        <div>
                          <p style={{ fontSize: 16 }}>
                            Drag and drop or Browse
                          </p>
                          <span style={{ fontSize: 12 }}>
                            Supported format: xlsx files
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <DocumentIcon />
                        {files.name}
                      </Box>
                      <IconButton onClick={() => setFiles({})}>
                        <TrashIcon />
                      </IconButton>
                    </Box>
                  )}
                </div>
              </Box>
            </Upload.Dragger>
          </Grid>
        </Grid>
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => onSumit(files)}
        >
          Import
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalBulkImport;
