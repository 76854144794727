import { ButtonSecondary, UploadAction, EditUploadAction } from './styled';
import {
  EditIcon,
  EyeIcon,
  ImageIcon,
  TrashIcon,
  CloseIcon,
} from '@/assets/svg';
import Image from '@/components/Image';
import UploadCompAnt from '@/components/UploadCompAnt';
import { getFileNameByUrlFirebase } from '@/firebase';
import {
  Box,
  Chip,
  IconButton,
  Typography,
  withStyles,
  makeStyles,
  Tooltip,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

export const MAX_IMAGES = 3;

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const UploadText = withStyles({
  root: {
    color: '#333333',
  },
})(Typography);

const CustomChip = withStyles({
  root: {
    color: '#fff',
    backgroundColor: '#666666',
  },
})(Chip);

const PreviewContent = withStyles({
  root: {
    width: '100%',
    height: 48,

    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(51,51,51,0.4)',
    borderRadius: 8,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
})(Box);

const CustomDialogContent = withStyles({
  root: {
    padding: 40,
    position: 'relative',
    '& .icon': {
      position: 'absolute',
      top: 10,
      right: 10,
    },

    '&:first-child': {
      paddingTop: 40,
    },
  },
})(DialogContent);

const useStyles = makeStyles({
  image: {
    width: 73,
    height: 48,
    borderRadius: 8,
  },
  text: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'clip ellipsis clip 0 3ch',
  },
});

const MultipleImageUpload = ({
  isEdit = false,
  accept,
  handleChangeForm,
  formValue,
}) => {
  const classes = useStyles();

  const [images, setImages] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [imageSelected, setImageSelected] = useState('');

  const handleMouseEnter = (url) => {
    setImageSelected(url);
    setIsHovered(true);
  };
  const handleMouseLeave = () => setIsHovered(false);

  const handlePreviewOpen = (url) => {
    setImageSelected(url);
    setPreviewOpen(true);
    handleMouseLeave();
  };
  const handlePreviewClose = () => {
    setImageSelected('');
    setPreviewOpen(false);
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const listDetailImageUrls = [...formValue?.listDetailImageUrls]
      .concat(selectedFiles)
      .slice(0, MAX_IMAGES);

    handleChangeForm(listDetailImageUrls);
    event.target.value = '';
  };

  const handleEditFileChange = (file, inx) => {
    let listDetailImageUrls = [...formValue?.listDetailImageUrls];
    listDetailImageUrls[inx] = file;

    handleChangeForm(listDetailImageUrls);
  };

  const handleRemoveImage = (inx) => {
    let listDetailImageUrls = [...formValue?.listDetailImageUrls];
    listDetailImageUrls.splice(inx, 1);
    handleChangeForm(listDetailImageUrls);
  };

  const shortString = (str) => {
    if (str.length > 35) {
      return (
        str.substr(0, 20) + '...' + str.substr(str.length - 10, str.length)
      );
    }
    return str;
  };

  useEffect(() => {
    const newImages = [...formValue?.listDetailImageUrls].map((file) => {
      if (typeof file === 'string' && file.includes('firebasestorage')) {
        const fileName = getFileNameByUrlFirebase(file);
        return {
          fileName: fileName.slice(13),
          url: file,
        };
      } else if (file.constructor === File) {
        return {
          fileName: file.name,
          url: URL.createObjectURL(file),
        };
      }
      return file;
    });
    setImages(newImages);
  }, [formValue.listDetailImageUrls]);

  return (
    <Box>
      {formValue?.listDetailImageUrls?.length === 3 ? null : (
        <Box display="flex" gridGap={20}>
          <Box
            width={141}
            height={92}
            bgcolor="#f6f6f6"
            border="1px solid #e1e1e1"
            borderRadius="8px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ImageIcon />
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            gridGap={8}
          >
            <Box>
              <UploadText>Recommended format: PNG or JPEG</UploadText>
              <UploadText>Maximum number of photos: 3</UploadText>
            </Box>
            <Box display="flex" gridGap={16}>
              <label htmlFor={'product-images'}>
                <input
                  id="product-images"
                  type="file"
                  name="files[]"
                  accept={accept || '.png,.jpg,.jpeg'}
                  multiple
                  hidden
                  onChange={handleFileChange}
                />
                <UploadAction>
                  <ButtonSecondary>Upload</ButtonSecondary>
                </UploadAction>
              </label>
            </Box>
          </Box>
        </Box>
      )}

      {!isEmpty(images)
        ? images.map(({ fileName, url }, inx) => (
            <Box
              key={url}
              mt={1.25}
              borderRadius={8}
              bgcolor="#f6f6f6"
              p={1.25}
            >
              <Box display="flex" gridGap={10} alignItems="center">
                <Box
                  width={73}
                  position="relative"
                  onMouseEnter={() => handleMouseEnter(url)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Image src={url} alt={fileName} className={classes.image} />
                  {isHovered && imageSelected === url && (
                    <PreviewContent onClick={() => handlePreviewOpen(url)}>
                      <EyeIcon color="white" width={24} height={24} />
                    </PreviewContent>
                  )}
                </Box>

                <Box width="40%">
                  <LightTooltip title={fileName} aria-label={fileName}>
                    <span> {shortString(fileName) || ''}</span>
                  </LightTooltip>
                </Box>
                <Box width="40%">
                  {inx === 0 ? (
                    <CustomChip size="small" label="Cover photo" />
                  ) : null}
                </Box>
                <Box width="10%" display="flex" alignItems="center">
                  <UploadCompAnt
                    isAddNew={true}
                    label="Upload banner"
                    fileData={url}
                    onChange={(e) => handleEditFileChange(e, inx)}
                    editable={true}
                    ActionComponent={
                      <EditUploadAction>
                        <EditIcon color="#666666" />
                      </EditUploadAction>
                    }
                  />

                  <IconButton onClick={() => handleRemoveImage(inx)}>
                    <TrashIcon color="#666666" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          ))
        : null}
      {previewOpen && (
        <Dialog
          disableBackdropClick
          open={previewOpen}
          onClose={handlePreviewClose}
          maxWidth="lg"
        >
          <CustomDialogContent>
            <IconButton className="icon" onClick={handlePreviewClose}>
              <CloseIcon />
            </IconButton>

            <Image src={imageSelected} alt="Preview" />
          </CustomDialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default MultipleImageUpload;
