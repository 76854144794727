import React from 'react';
import serviceDispatcher from '@/module/services/action';
import ServiceModalContainer from '../../common/ServiceModalContainer';
import VaccinationForm from './VaccinationForm';

const VaccinationModalContainer = props => (
  <ServiceModalContainer
    {...{
      ...props,
      prefixName: 'Vaccination',
      category: 'Vaccinations',
      createDispatcher: serviceDispatcher.createTest,
      editDispatcher: serviceDispatcher.editTest,
      getServiceByID: serviceDispatcher.getServiceByID,
      schema: 'serviceSchema',
      requireLabCode: false,
      requireLoinc: false,
      ServicesForm: VaccinationForm,
      listValidKeys: ['name']
    }}
  />
);

export default VaccinationModalContainer;
