export const ROLE_NAME = {
  Cohost: 'Co-host',
  PublicUser: 'User',
  Admin: 'Admin'
};

export const ROLE_COLOR = {
  Cohost: '#AD5E99',
  PublicUser: '#8F9BA9',
  Admin: '#807BC9'
};
