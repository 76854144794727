import { CheckboxActive, CheckboxDefault } from '@/assets/svg';
import { Checkbox, makeStyles } from '@material-ui/core';
import React from 'react';
const useStyles = makeStyles({
  root: {
    '&.Mui-checked': {
      color: '#ad5e99'
    }
  }
});

const CustomCheckbox = props => {
  const classes = useStyles();
  return (
    <Checkbox
      icon={<CheckboxDefault width={20} height={20} />}
      checkedIcon={<CheckboxActive width={20} height={20} />}
      {...props}
      className={classes.root}
    />
  );
};

export default CustomCheckbox;
