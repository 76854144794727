import React, { useState, useEffect } from 'react';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import { TYPE_MODAL } from '../common/constants/typeModal';
import TableContainer from '../../components/Table/TableContainer';
import {
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  Menu,
  IconButton
} from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';

import MenuAction from '../../components/MenuAction';
import DeleteModal from '../../components/DeleteModal';
import PromotionModal from './PromotionModal';
import renderColumn from './renderColumn';
import renderListAction from './renderListAction';
import promotionDispatch from './action';
import usermanagementDispatcher from './action';

const FORMAT_DATE = 'DD/MM/YYYY';

const Promotion = () => {
  const { paging, list: data } = useSelector(state => state.promotion);
  const tableColumn = renderColumn();

  const [promotionId, setPromotionId] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    promotionDispatch.getData(searchKey, paging);
  }, []);

  const onShowModal = type => {
    setShowModal(true);
    setTypeModal(type);
    setAnchorEl(null);
  };

  const onShowDeleteModal = () => {
    setDeleteModal(true);
    setAnchorEl(null);
  };

  const onCloseModal = () => {
    setShowModal(false);
    setTypeModal(null);
  };

  const handleDeleteUser = () => {
    usermanagementDispatcher.deleteData(promotionId, searchKey);
    setDeleteModal(false);
  };

  const onHandleModal = (type, status) => {
    switch (type) {
      case 'delete':
        onShowDeleteModal();
        break;
      case 'edit': {
        onShowModal(TYPE_MODAL.Edit);
        break;
      }
      default:
        break;
    }
  };

  const listActions = renderListAction(onHandleModal);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {tableColumn.map(({ label, stateValue }) => {
          if (!stateValue) return <TableCell key={label}></TableCell>;
          return (
            <TableCellFilted
              key={label}
              label={label}
              stateValue={stateValue}
              hiddenRows={hiddenRows}
              setHiddenRows={setHiddenRows}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && <MenuAction listActions={listActions} />}
      </Menu>
      <TableContainer
        title="Promo Code"
        paging={paging}
        data={data}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={promotionDispatch.getData}
        onAddNew={() => onShowModal(TYPE_MODAL.Create)}
        renderRow={row => (
          <React.Fragment key={row.id}>
            <TableCell>{!hiddenRows.code && row.code}</TableCell>
            <TableCell>{!hiddenRows.name && row.name}</TableCell>
            <TableCell>{!hiddenRows.quota && row.quota}</TableCell>
            <TableCell>{!hiddenRows.totalUsed && row.totalUsed}</TableCell>
            <TableCell style={{ maxWidth: 200 }}>
              {!hiddenRows.description && row.description}
            </TableCell>
            <TableCell>
              {!hiddenRows.startDate &&
                row.startDate &&
                moment(row.startDate).format(FORMAT_DATE)}
            </TableCell>
            <TableCell>
              {!hiddenRows.endDate &&
                row.endDate &&
                moment(row.endDate).format(FORMAT_DATE)}
            </TableCell>
            <TableCell align="right">
              <IconButton
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  setPromotionId(row.id);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </React.Fragment>
        )}
      />
      <Dialog
        maxWidth="md"
        open={showModal}
        disableEnforceFocus
        onClose={onCloseModal}
      >
        <PromotionModal
          onClose={onCloseModal}
          id={promotionId}
          typeModal={typeModal}
          searchKey={searchKey}
          setPromotionId={setPromotionId}
        />
      </Dialog>
      {deleteModal && (
        <DeleteModal
          keyTitle="code"
          showModal={deleteModal}
          selectedItem={data.find(item => item.id === promotionId)}
          onClose={() => setDeleteModal(false)}
          onDelete={handleDeleteUser}
          modalName="Delete promotion"
        />
      )}
    </>
  );
};

export default Promotion;
