import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { NoDataHelperWhiteIcon } from '@/assets/svg';
import moment from 'moment';
import MinmedLogo from '@/assets/image/minmed-logo.png';
import { HS_PAXSCREENING_STATUS } from '../../constants';

const useStyles = makeStyles(theme => ({
  noDataWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    verticalAlign: 'middle',
    height: 'calc(100vh - 144px)',
    border: 'none',
    '& .title-text': {
      marginTop: 12,
      fontSize: 18,
      fontWeight: 600,
      fontFamily: 'Lato'
    }
  },
  nameSurvey: {
    fontSize: 16,
    fontWeight: 600
  },
  titleConsent: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 32
  },
  subTitleConsent: {
    fontWeight: 600,
    marginBottom: 16,
    marginTop: 32
  },
  headerConsentForm: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  bodyConsentForm: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  }
}));

const rowStyles = makeStyles({
  title: {
    verticalAlign: 'top',
    fontWeight: 400,
    color: '#666666'
  },
  rightWrapper: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8
  },
  text: {
    verticalAlign: 'top',
    fontWeight: 600,
    color: '#333333'
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 8,
    color: '#666666',
    width: 24,
    height: 24
  }
});

const RowItem = ({ title, children }) => {
  const classes = rowStyles();
  return (
    <Box display="flex" borderRadius={8} border={'1px solid #e1e1e1'}>
      <Box
        p={1.5}
        width="60%"
        bgcolor="#f1f1f1"
        className={classes.rightWrapper}
      >
        <Typography className={classes.title}>{title}</Typography>
      </Box>
      <Box p={1.5} width="40%">
        {children === 'None' ? (
          <Typography className={classes.text} style={{ color: '#999999' }}>
            {children}
          </Typography>
        ) : (
          <Typography className={classes.text}>{children}</Typography>
        )}
      </Box>
    </Box>
  );
};

const CustomTypography = withStyles({
  root: {
    fontFamily: 'OpenSans',
    fontSize: 12
  }
})(Typography);

const ModalContent = ({
  data = [],
  dataInfor,
  isConsentForm,
  isLifeStyle = false,
  Icon = NoDataHelperWhiteIcon,
  noDataHelperText = 'No lifestyle survey',
  noDataHelperSubText = 'This patient hasn’t completed the survey.'
}) => {
  const classes = useStyles();
  const htmlStringToText = htmlStr => htmlStr.replace(/<[^>]+>/g, '');

  return (
    <Box bgcolor="#f1f1f1" p={2}>
      {isEmpty(data) ||
      dataInfor?.paxScreenStatus === HS_PAXSCREENING_STATUS.CheckInLocation ? (
        <Box className={classes.noDataWrapper}>
          <Icon />
          <Typography className="title-text">{noDataHelperText}</Typography>
          <Typography>{noDataHelperSubText}</Typography>
        </Box>
      ) : isConsentForm ? (
        <Box
          display="flex"
          flexDirection="column"
          bgcolor="#f1f1f1"
          gridGap={12}
        >
          {data.map(row => (
            <Box key={row.consentId} width={568} m="0 auto" borderRadius={8}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bgcolor={'#AB5998'}
                width={568}
                height={60}
                className={classes.headerConsentForm}
              >
                <img
                  id="minmed-logo"
                  src={MinmedLogo}
                  width="120"
                  alt="minmed-logo"
                />
              </Box>
              <Box
                p={2.5}
                bgcolor={'#ffffff'}
                className={classes.bodyConsentForm}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.titleConsent}>
                      {row?.title}
                    </Typography>
                    <Box>
                      <CustomTypography
                        dangerouslySetInnerHTML={{
                          __html: row?.htmlContent || ''
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.subTitleConsent}>
                      Consented by
                    </Typography>

                    <Box display="flex" alignItems="center" gridGap={8} mb={1}>
                      <Box width="30%">
                        <Typography>Patient’s name</Typography>
                      </Box>
                      <Box
                        p={1}
                        width="70%"
                        borderRadius={4}
                        bgcolor="#f6f6f6"
                        minHeight={37}
                      >
                        <Typography className={classes.text}>
                          {row.submittedDate ? dataInfor?.fullName : ''}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" gridGap={8}>
                      <Box width="30%">
                        <Typography>Date submitted</Typography>
                      </Box>
                      <Box
                        p={1}
                        width="70%"
                        borderRadius={4}
                        bgcolor="#f6f6f6"
                        minHeight={37}
                      >
                        <Typography>
                          {row.submittedDate
                            ? moment(row.submittedDate).format('DD/MM/YYYY')
                            : ''}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          gridGap={12}
          borderRadius={8}
          bgcolor={'#ffffff'}
        >
          {isLifeStyle
            ? data?.map((row, rowIndex) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  gridGap={12}
                  key={`${rowIndex} ${row.name}`}
                >
                  <Typography className={classes.nameSurvey}>
                    {row.name}
                  </Typography>
                  {row?.questions?.map((ques, quesIndex) => (
                    <RowItem
                      key={`${quesIndex} ${ques.question}`}
                      title={`${quesIndex + 1}. ${htmlStringToText(
                        ques?.question
                      )}`}
                    >
                      {ques.answerType === 'FreeText' ? (
                        ques.freeTextAnswer || 'None'
                      ) : (
                        <>
                          {isEmpty(ques?.answers) ? (
                            <div style={{ color: '#999999' }}>None</div>
                          ) : (
                            ques?.answers?.map(ans =>
                              ans.isAdditional ? (
                                <div key={ans.id}>
                                  <p style={{ marginBottom: 0 }}>
                                    {ans.answer}:{' '}
                                    <span style={{ fontWeight: 400 }}>
                                      {row?.patientNote}
                                    </span>
                                  </p>
                                </div>
                              ) : (
                                <div key={ans.id}>{ans.answer || 'None'}</div>
                              )
                            )
                          )}
                        </>
                      )}
                    </RowItem>
                  ))}
                </Box>
              ))
            : null}
        </Box>
      )}
    </Box>
  );
};

export default ModalContent;
