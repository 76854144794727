import { synthesize } from 'redux-dispatcher';
import moment from 'moment';
import customToast from '@/new-components/CustomNotification';

import {
  DAYS_PER_WEEK,
  FORMAT_TIME,
  FORMAT_YYYY_MM_DD,
  DAYS_OF_WEEK,
  TIME_ZONE_SINGAPORE
} from '../constants';
import { generateData } from '../utils';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getDataBooking: params => async ({ Api }) => {
    const { week, type } = params;
    const startDateDefault =
      moment(week).format(FORMAT_YYYY_MM_DD) + 'T00:00:00Z';
    const endDateDefault =
      moment(week).format(FORMAT_YYYY_MM_DD) + 'T23:59:59Z';

    const response = await Api.post('BookingTimeConfigurations/filter', {
      fromDate: startDateDefault,
      toDate:
        moment(week)
          .add(DAYS_PER_WEEK - 1, 'day')
          .format(FORMAT_YYYY_MM_DD) + 'T23:59:59Z',
      type
    });

    let magicWeek = moment(startDateDefault).add(TIME_ZONE_SINGAPORE, 'hour');

    if (!response) return;

    let list = [];
    let defaultStartTime;
    let defaultEndTime;
    if (response.status === 200) {
      defaultStartTime = response.result?.startTime;
      list = response.result?.list;
      defaultEndTime = response.result?.endTime;
    }
    const [keys, bookings] = generateData(
      magicWeek,
      moment(startDateDefault)
        .subtract(TIME_ZONE_SINGAPORE, 'hour')
        .format(FORMAT_TIME),
      moment(endDateDefault)
        .subtract(TIME_ZONE_SINGAPORE, 'hour')
        .format(FORMAT_TIME)
    );
    const newBookings = bookings.map(bookingGen => {
      const bookingMap = list.find(bookingFrmServer =>
        moment(bookingFrmServer.startDate)
          .add(TIME_ZONE_SINGAPORE, 'hour')
          .isSame(moment(bookingGen.startDate))
      );
      if (bookingMap) {
        return {
          ...bookingMap,
          time: bookingGen.time,
          date: bookingGen.date,
          isUnavailable: false
        };
      }
      return bookingGen;
    });
    bookingDispatch.getDataSuccess({
      keys,
      bookings: newBookings,
      startTime: defaultStartTime,
      endTime: defaultEndTime
    });
  },
  updateBookingTime: (
    { date, option, baseSlot, type },
    resolve,
    reject
  ) => async ({ Api }) => {
    try {
      const isActive = option === 'open'; // open: true, close: false

      const response = await Api.put('BookingTimeConfigurations', {
        startDate: date,
        isActive,
        baseSlot: Number(baseSlot),
        type
      });
      if (!response) {
        reject();
        return;
      }

      if (response.status === 200) {
        bookingDispatch.updateListBooking(date, isActive, baseSlot);
        customToast('success', 'Update booking success');
        resolve();
      } else {
        reject();
      }
    } catch (error) {
      reject(error);
    }
  },
  updateListBooking: (startDate, isActive, baseSlot) => ({
    startDate,
    isActive,
    baseSlot
  }),
  updateBlockBooking: (data, resolve, reject) => async ({ Api }) => {
    try {
      const {
        option,
        startDate,
        endDate,
        endTime,
        startTime,
        baseSlot,
        daysOfWeek: valueDaysOfWeek,
        type
      } = data;
      const daysOfWeek = valueDaysOfWeek.map(d => DAYS_OF_WEEK[d]);

      const response = await Api.post('BookingTimeConfigurations/block', {
        isActive: option === 'open',
        startDate: moment(startDate).format('YYYY-MM-DD') + 'T00:00:00Z',
        endDate: moment(endDate).format('YYYY-MM-DD') + 'T23:59:59Z',
        startTime: moment(startTime).format(FORMAT_TIME),
        endTime: moment(endTime).format(FORMAT_TIME),
        baseSlot: Number(baseSlot),
        daysOfWeek,
        type
      });
      if (response && response.status === 200) {
        customToast('success', 'Update booking success');
        resolve();
      } else {
        reject();
      }
    } catch (error) {
      reject(error);
    }
  }
};

const bookingDispatch = synthesize('manageBookingTime', mapDispatchToAC);
export default bookingDispatch;
