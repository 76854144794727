import { synthesize } from 'redux-dispatcher';
import { formatUTCDate, createDownloadableLink } from '@/helpers';
import moment from 'moment';
import { FORMAT_DD_MM_YYYY } from '@/module/company/constants';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  resetData: () => ({}),
  setState: (state, value) => ({ state, value }),
  exportInnoquestOrderList: params => async ({ Api }) => {
    const { status, result } = await Api.post(
      `PatientAppointments/export-innoquest-order-list`,
      {
        ...params,
        healthScreeningDate: params?.healthScreeningDate
          ? formatUTCDate(params?.healthScreeningDate, { isStarOfDay: true })
          : undefined
      }
    );
    const fileName = `Order_List_${moment(params.healthScreeningDate).format(
      FORMAT_DD_MM_YYYY
    )}`;
    status === 200 && createDownloadableLink(result, fileName, 'xlsx');
  },
  getDataInnoquestOrderList: (paging, filterOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().eOrderList.paging;
    let { result, status } = await Api.post(
      `PatientAppointments/filter-innoquest-order-list`,
      {
        pageIndex: pagingData.pageIndex,
        pageSize: pagingData.pageSize,
        ...filterOption,
        date: filterOption?.date
          ? formatUTCDate(filterOption?.date, { isStarOfDay: true })
          : undefined
      }
    );

    status === 200 && eOrderListDispatcher.getDataSuccess(result);
  },
  emailOrderListToLab: params => async ({ Api, customToast }) => {
    const { status } = await Api.post(`PatientAppointments/email-lab`, {
      ...params,
      healthScreeningDate: params?.healthScreeningDate
        ? formatUTCDate(params?.healthScreeningDate, { isStarOfDay: true })
        : undefined
    });
    if (status === 200) {
      customToast('success', 'Email to lab successs');
    }
  },
  exportMultiInnoquestOrderList: params => async ({ Api }) => {
    const { status, result } = await Api.post(
      `PatientAppointments/bulk-export-innoquest-order-list`,
      {
        ...params,
        healthScreeningDate: params?.healthScreeningDate
          ? formatUTCDate(params?.healthScreeningDate, { isStarOfDay: true })
          : undefined
      }
    );
    const fileName = `Order_List_${moment(params.healthScreeningDate).format(
      FORMAT_DD_MM_YYYY
    )}`;
    status === 200 && createDownloadableLink(result, fileName, 'xlsx');
  },
  emailMultiOrderListToLab: params => async ({ Api, customToast }) => {
    const { result, status } = await Api.post(
      `PatientAppointments/bulk-sent-email-lab`,
      {
        ...params,
        healthScreeningDate: params?.healthScreeningDate
          ? formatUTCDate(params?.healthScreeningDate, { isStarOfDay: true })
          : undefined
      }
    );

    const fileName = `Order_List_${moment(params.healthScreeningDate).format(
      FORMAT_DD_MM_YYYY
    )}`;

    status === 200 && createDownloadableLink(result, fileName, 'txt');
  }
};

const eOrderListDispatcher = synthesize('eOrderList', mapDispatchToAC);

export default eOrderListDispatcher;
