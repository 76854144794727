/*
 * @Author: Phu Phan
 * @Date: 2021-01-08 14:47:07
 */
import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { DetailWrapped } from '../UserDetail/styled';
import DividerHeader from '@/module/common/componentUI/DividerHeader';
import { WrappedItem } from '../MedicalHistory/CommonStyles';
import moment from 'moment';
import { KeyboardArrowLeftSharp } from '@material-ui/icons';
const SurveyDetail = ({
  data: { groupedQues: data, name, company, submittedDate },
  onBack
}) => {
  const renderQADetail = qaData =>
    qaData.map(i => {
      const { answers, answerType } = i;
      let answerContent = '';
      switch (answerType) {
        case 'FreeText':
          answerContent = i.freeTextAnswer;
          break;
        case 'SingleChoice':
          answerContent = [answers[0].answer, answers[0].patientNote]
            .filter(i => i)
            .join(': ');
          break;
        case 'MultipleChoice':
          answerContent = answers.map(ans =>
            [ans.answer, ans.patientNote].filter(i => i).join(': ')
          );
          break;
        default:
          break;
      }

      const renderAnswer = () =>
        typeof answerContent === 'string'
          ? answerContent
          : answerContent.map(i => (
              <Grid item md={6} sm={12}>
                {i}
              </Grid>
            ));
      return (
        <Grid item md={6} sm={12}>
          <WrappedItem>
            <div dangerouslySetInnerHTML={{ __html: i.question }} />
            <Grid container>{renderAnswer()}</Grid>
          </WrappedItem>
        </Grid>
      );
    });
  return (
    <DetailWrapped>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton color="secondary" onClick={onBack}>
          <KeyboardArrowLeftSharp fontSize="medium" />
        </IconButton>
        <div>
          <Typography
            style={{
              fontSize: 18,
              fontWeight: 600
            }}
          >
            {name}
          </Typography>
          <Typography style={{ color: '#8F9BA9' }}>
            {company + ' - ' + moment(submittedDate).format('DD/MM/YYYY')}
          </Typography>
        </div>
      </div>
      {Object.keys(data).map(key => {
        let qaData = data[key];

        return (
          <Grid container spacing={3} key={key}>
            <Grid item xs={12}>
              <DividerHeader title={key?.toUpperCase()} />
            </Grid>
            {renderQADetail(qaData)}
          </Grid>
        );
      })}
    </DetailWrapped>
  );
};

export default SurveyDetail;
