import questionnairesDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  },
  currentQuestion: null
};

const questionnairesReducer = questionnairesDispatcher(initialState, {
  [questionnairesDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [questionnairesDispatcher.saveDataById]: (state, payload) => {
    return { ...state, currentQuestion: payload.data };
  }
});

export default questionnairesReducer;
