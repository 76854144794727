import React, { useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import styled from 'styled-components';
import customToast from '@/new-components/CustomNotification';
import { uploadToFirebase } from '../../../../firebase';
import { CloudIcon } from '../../../../assets/svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import generateUUID from '../../../../helpers/generateUUID';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import PhoneInput from '../../../../components/PhoneInput';
import LoadingButton from '../../../../components/LoadingButton';
import authDispatcher from '../../action/auth';
import { getNationalNumber, getNumberInfo } from '../../../../helpers';
import { isEmpty } from 'lodash';

const ProfessionInfo = ({
  step,
  setStep,
  phone,
  setPhone,
  countryCode,
  setCountryCode,
  mcr,
  setMcr,
  setCerPhotoUrl,
  cerExpDate,
  setCerExpDate
}) => {
  const [cerPhotoName, setCerPhotoName] = useState('');
  const [showLoading, setLoading] = useState(false);

  const onUploadCerPhoto = () => {
    const file = document.getElementById('upload-photo').files[0];
    if (!file) {
      setCerPhotoUrl('');
      setCerPhotoName('');
      return;
    }
    setLoading(true);
    uploadToFirebase({
      id: generateUUID(),
      uploadedFile: { file: file },
      callBack: downloadUrl => {
        setLoading(false);
        setCerPhotoUrl(downloadUrl);
        setCerPhotoName(file.name);
      }
    });
  };

  const toNextStep = () => {
    if (!phone || !mcr || !cerPhotoName) {
      customToast('error', 'Please fill all the fields!');
      return;
    } else if (isEmpty(getNumberInfo(phone, countryCode))) {
      customToast('error', 'Invalid phone number!');
      return;
    }
    authDispatcher.checkMobileExist(
      getNationalNumber(phone, countryCode),
      countryCode,
      false,
      () => {
        authDispatcher.checkMrcExist(mcr, () => {
          setStep(step + 1);
        });
      }
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 526,
        width: 380,
        borderRadius: 10,
        backgroundColor: 'white',
        padding: '40px 20px',
        transition: 'all 0.1s'
      }}
    >
      <Typography
        style={{
          fontSize: 24,
          letterSpacing: 0,
          fontWeight: 600
        }}
      >
        Create A New Account
      </Typography>
      <TextField
        label="Phone"
        style={{ width: 340, marginTop: 10, marginBottom: 20 }}
        value={phone}
        onChange={event => setPhone(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneInput
                country={countryCode}
                onChange={event => setCountryCode(event.target.value)}
              />
            </InputAdornment>
          )
        }}
      />
      <TextFieldStyle
        label="MCR"
        margin="normal"
        value={mcr}
        InputProps={{
          style: { fontSize: 14, color: '#192637', letterSpacing: 0 }
        }}
        onChange={e => setMcr(e.target.value)}
      />
      <TextFieldStyle
        id="cer-photo"
        label="Practising Certificate"
        margin="normal"
        value={cerPhotoName}
        onClick={() => document.getElementById('upload-photo').click()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {cerPhotoName ? (
                <CheckCircleOutlinedIcon
                  key={cerPhotoName}
                  style={{
                    height: 12,
                    width: 12,
                    color: 'green'
                  }}
                />
              ) : (
                <CloudIcon
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={() =>
                    document.getElementById('upload-photo').click()
                  }
                />
              )}
            </InputAdornment>
          ),
          style: { fontSize: 14, color: '#192637', letterSpacing: 0 },
          readOnly: true
        }}
      />
      <input id="upload-photo" type="file" hidden onChange={onUploadCerPhoto} />
      <LoadingButton
        style={{ marginTop: 50 }}
        onClick={toNextStep}
        showLoader={showLoading}
      >
        Next
      </LoadingButton>
    </div>
  );
};

const TextFieldStyle = styled(TextField)`
  height: 40px;
  width: 340px;
  height: 80px;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
`;

export default ProfessionInfo;
