import { Button, withStyles } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';

const CustomButton = withStyles({
  root: {
    padding: 0,
    minWidth: 50,
    height: 24,

    '&.Mui-disabled': {
      opacity: 0.3,
      color: '#AD5E99',
    },
  },
  label: {
    fontSize: 14,
  },
})(Button);

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    gap: theme.spacing(1.5),
  },
  grouped: {
    // margin: theme.spacing(1),
    padding: '5px 8px',
    border: `2px solid ${theme.palette.grey.disableBackground}`,
    backgroundColor: theme.palette.grey.disableBackground,
    color: theme.palette.grey.mainText,
    textTransform: 'capitalize',

    '&:focus': {
      backgroundColor: theme.palette.grey.disableBackground,
    },

    '&:not(:first-child)': {
      borderRadius: 35,
    },
    '&:first-child': {
      borderRadius: 35,
    },
    '&.Mui-selected': {
      border: `2px solid ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.show-more-btn': {
      borderColor: theme.palette.common.white,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  },
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles((theme) => ({
  root: {
    height: 24,
    textTransform: 'revert',
  },
}))(ToggleButton);

const CustomQuickRangeGroup = ({ value, handleChange, listButtons }) => {
  const [showMore, setShowMore] = useState(false);
  const [localList, setLocalList] = useState([]);

  useEffect(() => {
    const list = showMore ? listButtons : listButtons.slice(0, 8);
    setLocalList(list);
  }, [listButtons, showMore]);

  const onClickShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <StyledToggleButtonGroup size="small" value={value} onChange={handleChange}>
      {localList.map((btn) => (
        <StyledToggleButton key={btn} value={btn}>
          {btn}
        </StyledToggleButton>
      ))}
      <CustomButton
        color="primary"
        className="show-more-btn"
        onClick={onClickShowMore}
      >
        {showMore ? 'See less' : 'See more'}
      </CustomButton>
    </StyledToggleButtonGroup>
  );
};

export default CustomQuickRangeGroup;
