import React, { createRef, useState, useEffect } from 'react';
import { Header } from './styles/common';
import styled from 'styled-components';
import { Input } from 'antd';
import { SendMessage } from '../../../../assets/svg';
import { ContentMessage } from '../../components';
import RtmInstance from '../../../../agora/rtm-instance';
import livestreamDispatcher from '../../actions/index';
import { useSelector } from 'react-redux';
import {
  createMessageFromValue,
  createMessageFileFromValue
} from '../../utils';
import { uploadToFirebaseAsync } from '../../../../firebase';
import { get } from 'lodash';
import SelectCohost from './SelectCohost';
import { formatUsername } from '../../helpers';
import { isUserOnline } from './helper';

const rtm = RtmInstance.instance;
const chatBodyRef = createRef();

const ChatContentPage = ({
  channelName,
  selectPeerChat,
  isPeerChatting,
  onFocusInput,
  onSelectSearchChat
}) => {
  const userInfo = useSelector(state => state.auth.userInfo);
  const { listMessage, listMember, TotalUser } = useSelector(
    state => state.livestream
  );
  const totalUser = useSelector(state =>
    get(state, 'upcoming.channelData.TotalUser')
  );
  const ChannelId = useSelector(state =>
    get(state, 'upcoming.channelData.ChannelId')
  );

  const [keyword, setKeyword] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    const { current } = chatBodyRef;
    if (!current) {
      return null;
    }
    current.scrollTop = current.scrollHeight;
  }, [listMessage[channelName]]);

  const handleUploadText = (keyword, channelName) => {
    const HOST_ROLE = { Manager: 'Admin', Trainer: 'Cohost' };
    const message = {
      text: keyword,
      name: userInfo.firstName + ' ' + userInfo.lastName || userInfo.fullName,
      roleType: HOST_ROLE[userInfo.roleName]
    };
    rtm[isPeerChatting ? 'sendPeerMessage' : 'sendChannelMessage'](
      JSON.stringify(message),
      channelName
    )
      .then(() => {
        const contentMessage = createMessageFromValue(keyword, userInfo);
        livestreamDispatcher.sendMessageSuccess({
          contentMessage,
          channelName
        });
      })
      .catch(err => {
        console.error(err);
      });
  };
  const handleUploadFile = async channelName => {
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];
      const url = await uploadToFirebaseAsync({
        id: userInfo.email + channelName,
        uploadedFile: file
      });
      rtm[isPeerChatting ? 'sendPeerMessageFile' : 'sendChanelMessageFile'](
        url,
        file,
        channelName
      )
        .then(() => {
          const contentMessage = createMessageFileFromValue(
            `${file.fileName},${url}`,
            file.type,
            userInfo
          );
          livestreamDispatcher.sendMessageSuccess({
            contentMessage,
            channelName
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const handleSendData = () => {
    onFocusInput(channelName);
    if (!rtm._logined) {
      return;
    }
    if (!isPeerChatting) {
      if (
        !rtm.channels[channelName] ||
        (rtm.channels[channelName] && !rtm.channels[channelName].joined)
      ) {
        return;
      }
    }
    uploadedFiles && handleUploadFile(channelName); //Send files, image
    keyword && handleUploadText(keyword, channelName); //Send text message

    setKeyword('');
    setUploadedFiles([]);
    // document.querySelector('#add_image').value = '';
    // document.querySelector('#add_file').value = '';
  };
  // Upload image

  const handleSelectChat = chatSelected => {
    if (chatSelected) {
      selectPeerChat(
        get(chatSelected, 'user.name'),
        get(chatSelected, 'user._id'),
        get(chatSelected, 'user.roleType')
      );
    }
  };
  // Upload File

  const handleSelectSearchChat = member => {
    const name = member.FirstName + ' ' + member.LastName;
    onSelectSearchChat({
      name,
      id: formatUsername(name, member.Id),
      roleType: member.UserType
    });
  };

  const cantChat =
    isPeerChatting && !isUserOnline(listMember, channelName, ChannelId);

  return (
    <>
      <Header>
        <span className="header">
          {channelName.includes('cohostRoom') ? (
            <SelectCohost
              listMember={listMember}
              onSelect={handleSelectSearchChat}
            />
          ) : isPeerChatting ? (
            'Private Chat'
          ) : (
            `Public Chat (${TotalUser || totalUser || 1})`
          )}
        </span>
      </Header>
      <Content>
        <ChatBody ref={chatBodyRef}>
          {(listMessage[channelName] || []).map((item, index) => {
            return get(item, 'user.name') === 'toAllMember' ? (
              <div key={index} className="notify-chat">
                {item.text}
              </div>
            ) : (
              <ContentMessage
                index={index}
                data={item}
                key={item.date + index}
                selectChat={handleSelectChat}
              />
            );
          })}
        </ChatBody>

        <ChatFooter>
          {/* {showMessageFile()} */}

          <div className="wrapper-control">
            {/* <span className="icon">
              <AddFile onClick={showUploadDocument} />
              <input
                id="add_file"
                type="file"
                accept=".pdf,.doc,.docx,.mp3,.mp4"
                hidden
                onChange={onUploadDocument}
              />
            </span>
            <span className="icon" onClick={showUploadImage}>
              <AddImageOutlineIcon color="#AD5E99" />
              <input
                id="add_image"
                type="file"
                accept=".jpeg,.jpg,.png"
                hidden
                onChange={onUploadImage}
              />
            </span> */}
            <span className="search-input">
              <SearchInput
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
                onPressEnter={handleSendData}
                placeholder={
                  cantChat ? 'This user is offline' : 'Type Something...'
                }
                onMouseDown={() => onFocusInput(channelName)}
                disabled={cantChat}
              />
            </span>
            <span className="icon">
              <SendMessage onClick={handleSendData} />
            </span>
          </div>
        </ChatFooter>
      </Content>
    </>
  );
};

const SearchInput = styled(Input)`
  height: 43px;
  border-radius: 25px;
  background: #f6f7fa;
  border: none;
  &:focus {
    box-shadow: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #cacfd3;
  flex: 1;
  height: calc(100% - 60px);
  .notify-chat {
    color: #8f9ba9;
    font-size: 14px;
  }
`;
const ChatBody = styled.div`
  overflow: auto;
  background: #f7f7f7;
  padding: 8px;
  flex: 1;
  height: 100%;
  // Custom scroll bar
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #e9e9e9;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cacfd3;
  }
`;
const ChatFooter = styled.div`
  padding: 20px 15px;
  display: flex;
  border-top: 1px solid #cacfd3;
  flex-direction: column;
  justify-content: space-between;
  .wrapper-message {
    display: flex;
    flex: 1;
    background: white;
    align-items: center;
    overflow: auto;
    margin-bottom: 10px;
  }
  .wrapper-control {
    display: flex;
    align-items: center;
    .icon {
      width: 3%;
      margin-right: 1%;
      cursor: pointer;
    }
    .search-input {
      flex: 1;
      margin-right: 2%;
    }
  }
`;
export default ChatContentPage;
