const FORMAT_DATE_TIME = 'HH:mm, DD MMM YYYY';
const FORMAT_DATE = 'DD/MM/YYYY';

const SEARCH_PARAM = {
  workoutTypes: ['Livestream'],
  categoriesId: []
};

const TYPE_MODAL = {
  Create: 'Create',
  View: 'View',
  Edit: 'Edit'
};

const LIST_REQUIRED_LIVESTREAM_UPLOAD = [
  // 'vimeoClipId',
  'managerId',
  'trainerId'
];

const LIST_REQUIRED_LIVESTREAM_VIDEO = ['managerId', 'trainerId'];

const LIVE_STREAM_OPTION_VALUE = {
  UploadPrerecord: 'UploadPrerecord',
  LiveStreamVideo: 'LiveStreamVideo'
};

const FORM_VALUE_LIVESTREAM = {
  trainerId: [],
  managerId: '',
  vimeoClipId: '',
  liveStreamOption: LIVE_STREAM_OPTION_VALUE.UploadPrerecord
};

const FORM_VALUE = {
  title: '',
  thumbnail: '',
  price: 0,
  duration: '',
  calories: '',
  intensity: '',
  description: '',
  equipment: '',
  categoryId: '',
  date: '',
  listDates: [],
  startHour: ''
};

const STREAM_OPTIONS = [
  {
    label: 'Upload Prerecorded',
    value: LIVE_STREAM_OPTION_VALUE.UploadPrerecord
  },
  { label: 'Livestream Video', value: LIVE_STREAM_OPTION_VALUE.LiveStreamVideo }
];

const MENU_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null,
  Mui: {
    selected: {}
  }
};

const COLORS = [
  '#61bd4f',
  '#f2d600',
  '#ff9f1a',
  '#c377e0',
  '#0079bf',
  '#00c2e0',
  '#ed561b',
  '#fff263',
  '#6af9c4',
  '#6e97f9',
  '#ff998b',
  '#a4e295',
  '#ff93ae',
  '#da91f2',
  '#64e572',
  '#a29eff',
  '#dddf00',
  '#86d2f3',
  '#8dded3',
  '#ff9655',
  '#ffc0cb',
  '#a5dee4',
  '#058dc7',
  '#b28fce'
];

export {
  SEARCH_PARAM,
  TYPE_MODAL,
  FORM_VALUE,
  FORMAT_DATE_TIME,
  STREAM_OPTIONS,
  FORM_VALUE_LIVESTREAM,
  LIVE_STREAM_OPTION_VALUE,
  LIST_REQUIRED_LIVESTREAM_UPLOAD,
  LIST_REQUIRED_LIVESTREAM_VIDEO,
  MENU_PROPS,
  COLORS,
  FORMAT_DATE
};
