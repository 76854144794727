import React from 'react';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Editor } from '@tinymce/tinymce-react';
import {
  TINY_API_KEY,
  TINY_INIT_CONFIGURATION
} from '@/constants/editorConfig';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);
const CustomTitleLabel = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 4,
    color: '#666666'
  }
})(Typography);

const ConsentForm = ({ errors, setFormValue, formValue }) => {
  const onChange = key => e => {
    if (!setFormValue) return;

    let value;
    switch (key) {
      case 'htmlContent':
        value = e;
        break;
      default:
        value = e.target.value;
        break;
    }

    setFormValue({
      ...formValue,
      [key]: value
    });
  };

  return (
    <Box px={2.25} py={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTitleLabel>Consent form name</CustomTitleLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter name"
            error={!!errors.title}
            helperText={errors.title}
            value={formValue.title}
            onChange={onChange('title')}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTitleLabel>Description</CustomTitleLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter details"
            error={!!errors.description}
            helperText={errors.description}
            value={formValue.description}
            onChange={onChange('description')}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTitleLabel>Content</CustomTitleLabel>
          <Editor
            apiKey={TINY_API_KEY}
            value={formValue?.htmlContent || ''}
            init={{
              height: 380,
              ...TINY_INIT_CONFIGURATION
            }}
            onEditorChange={onChange('htmlContent')}
            cloudChannel="stable"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConsentForm;
