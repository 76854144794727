import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
  nrcVerify: {
    backgroundColor: '#f7f7f7',
    borderRadius: 10
  },
  title: {
    padding: 20
  },
  imageVerify: {
    padding: '0 20px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  image: {
    width: 540,
    height: 419,
    borderRadius: 4
  }
});

const NRCPanel = ({ children, imgFront, imgBack }) => {
  const classes = useStyle();
  return (
    <div className={classes.nrcVerify}>
      <div className={classes.title}>{children}</div>
      <div className={classes.imageVerify}>
        <div>
          <img alt="" className={classes.image} src={imgFront} />
          <p>ID Front</p>
        </div>
        <div>
          <img alt="" className={classes.image} src={imgBack} />
          <p>ID Back</p>
        </div>
      </div>
    </div>
  );
};

NRCPanel.propTypes = {
  children: PropTypes.node.isRequired,
  imgFront: PropTypes.string.isRequired,
  imgBack: PropTypes.string.isRequired
};

export default NRCPanel;
