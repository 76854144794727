import { AGORA_APP_ID } from './config';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { isFirefox, isCompatibleChrome, isSafari } from '../helpers';
import customToast from '@/new-components/CustomNotification';
import { isEmpty } from 'lodash';
import { upcomingDispatcher } from '../module/upcoming-workout';
import { configure } from '..';
import { updateAgoraStreamId } from '../helpers/liveStreamSignalR';

const VIDEO_PROFILE =
  process.env.NODE_ENV === 'development'
    ? '120p_1'
    : isSafari()
    ? process.env.REACT_APP_AGORA_SAFARI_VIDEO_RESOLUTION
    : process.env.REACT_APP_AGORA_VIDEO_RESOLUTION;

console.log('VIDEO_PROFILE', VIDEO_PROFILE);

const MAIN_CLIENT_PREFIX = 'main-client-';
const AUDIO_CLIENT_PREFIX = 'audio-client-';
class AgoraLiveStreamInstance {
  constructor(appId) {
    this.rtc = {
      client: null,
      stream: null,
      localStreamIds: '',
      isAdmin: false,
      streamSpec: {},
      streamSpecQueue: {},
      cdnURL: '',
      audioClient: null,
      audioStream: null,
      localAudioStreamIds: '',
      cdnStarted: false,
      funcQueue: []
    };
    this.option = {
      appID: appId,
      channel: '',
      uid: null,
      token: ''
    };
    this.id = '';
    // this.initClient(appId);
  }

  streamEventListener = () => {
    const {
      rtc,
      agoraCreateAndPublishStream,
      handleStartLiveStreaming,
      removeView,
      unPublishStream,
      unPublishAudioSharing
    } = this;

    rtc.client.on('stream-added', function(event) {
      var remoteStream = event.stream;
      var id = remoteStream.getId();
      // Prevent subscribing to your own streams (normal and share screen)
      if (![rtc.localStreamIds, rtc.localAudioStreamIds].includes(id)) {
        rtc.client.subscribe(remoteStream, function(err) {});
      }
    });

    rtc.client.on('stream-published', function(event) {
      var stream = event.stream;
      var id = stream.getId();
      // Notify new agora stream id for mobile's subscription
      updateAgoraStreamId(id);
      rtc.streamSpecQueue = {};

      // Remove stream view to prevent black screen issue
      removeView();
      rtc.stream.stop();
      rtc.stream.play('main_livestream', { fit: 'contain' });
      handleStartLiveStreaming();
    });

    rtc.client.on('stream-subscribed', function(event) {
      var remoteStream = event.stream;
      var remoteStreamId = remoteStream.getId();
      if (remoteStreamId.includes(AUDIO_CLIENT_PREFIX)) {
        rtc.audioStream = remoteStream;
        rtc.audioStream.play('audio_livestream', { fit: 'contain' });
        return;
      }
      if (rtc.stream) {
        rtc.stream.stop();
        rtc.stream.close();
        var currentStreamId = rtc.stream.getId();
        currentStreamId === rtc.localStreamIds &&
          rtc.client.unpublish(rtc.stream, function(err) {});
      }
      rtc.stream = remoteStream;
      rtc.stream.play('main_livestream', { fit: 'contain' });
    });

    // Will be triggered when a remote client disables the video
    rtc.client.on('mute-video', function(event) {
      customToast('info', 'The streamer has disabled video');
    });

    // Will be triggered when a remote client enables the video
    rtc.client.on('unmute-video', function(event) {
      customToast('info', 'The streamer has enabled video');
    });

    //  Will be triggered when screen-sharing stops
    rtc.client.on('stream-unpublished', function(event) {
      !isEmpty(rtc.streamSpecQueue) &&
        agoraCreateAndPublishStream(rtc.streamSpecQueue);
    });
    //  Will be triggered when error occured.
    rtc.client.on('error', function(evt) {
      evt.reason === 'SOCKET_DISCONNECTED' &&
        customToast(
          'error',
          'Disconnected with server due to network problem',
          '',
          {
            timeOut: 0,
            extendedTimeOut: 0
          }
        );
    });

    //  Will be triggered when the live streaming starts.
    rtc.client.on('liveStreamingStarted', function(evt) {
      console.log('liveStreamingStarted', evt);
      rtc.cdnStarted = true;
      customToast('success', 'Livestream started');
    });

    //  Will be triggered when the live streaming fails.
    rtc.client.on('liveStreamingFailed', function(evt) {
      console.log('liveStreamingFailed', evt);
      rtc.cdnStarted = false;

      if (evt.reason === 'WS_ABORT') return;
      if (evt.status !== 454)
        customToast('error', 'Livestream failed: ' + evt.reason);
    });

    //  Will be triggered when the live streaming stops.
    rtc.client.on('liveStreamingStopped', function(evt) {
      console.log('liveStreamingStopped', evt);
      rtc.cdnStarted = false;
      customToast('info', 'Livestream stopped');
      unPublishStream();
      unPublishAudioSharing();
    });

    //  Will be triggered when the live streaming transcoding updated.
    rtc.client.on('liveTranscodingUpdated', function(evt) {
      console.log('liveTranscodingUpdated');
      handleStartLiveStreaming();
    });
  };

  sharingEventListener = isAudioStream => {
    const { rtc, initStream } = this;
    //  Will be triggered when screen-sharing stops
    if (!isAudioStream) {
      rtc.stream.on('stopScreenSharing', function(event) {
        upcomingDispatcher.setState('isSharing', false);
        !configure.store.getState().upcoming.videoMode
          ? initStream()
          : initStream(undefined, true);
      });
      rtc.stream.on('audioMixingPlayed', () => {
        customToast('info', 'Audio played');
        console.log('audioMixingPlayed');
      });
    } else {
      rtc.audioStream.on('stopScreenSharing', function(event) {
        rtc.audioStream.stop();
        rtc.audioStream.close();
        rtc.audioClient.unpublish(rtc.audioStream, function(err) {
          console.log('audioStream unpublish failed');
          console.error(err);
        });
        upcomingDispatcher.setState('isSharingAudio', false);
      });
    }
  };

  initClient(appId = AGORA_APP_ID) {
    const { option, rtc, streamEventListener: subscribeStreamEvents } = this;
    option.appID = appId;
    rtc.client = AgoraRTC.createClient({ mode: 'live', codec: 'h264' });
    rtc.audioClient = AgoraRTC.createClient({ mode: 'live', codec: 'h264' });
    rtc.client.init(
      option.appID,
      () => {
        rtc.client.setClientRole('host');
        subscribeStreamEvents();
      },
      err => {
        console.error(err);
      }
    );
    rtc.audioClient.init(
      option.appID,
      () => {
        rtc.audioClient.setClientRole('host');
      },
      err => {
        console.error(err);
      }
    );
  }

  setOption = (option = {}) => {
    this.option = {
      ...this.option,
      ...option
    };
  };

  leaveChannel = ({ leaveOnlyShareScreen }) => {
    const { rtc } = this;
    if (rtc.client && !leaveOnlyShareScreen) {
      if (rtc.cdnStarted) {
        rtc.client.stopLiveStreaming(rtc.cdnURL);
      }
      rtc.audioClient.leave(() =>
        console.log('audioClient leaves channel success')
      );
      rtc.client.leave(
        function() {
          if (rtc.stream) {
            rtc.stream.stopAudioMixing();
            rtc.stream.stop();
            rtc.stream.close();
            rtc.stream = null;
          }
          if (rtc.audioStream) {
            rtc.audioStream.stop();
            rtc.audioStream.close();
            rtc.audioStream = null;
          }
          upcomingDispatcher.setState('calling', false);
          upcomingDispatcher.setState('isMuteAudio', false);
          upcomingDispatcher.setState('isMuteVideo', false);
          upcomingDispatcher.setState('isMuteSpeaker', false);
          upcomingDispatcher.setState('isSharing', false);
          upcomingDispatcher.setState('isSharingAudio', false);
          upcomingDispatcher.setState('showChat', false);
          upcomingDispatcher.setState('consConfirmVisibility', true);
          resetAgoraInstance();
          console.log('client leaves channel success');
        },
        function(err) {
          resetAgoraInstance();
          console.log('channel leave failed');
          console.error(err);
        }
      );
    }
  };

  joinChannel = (
    channel = this.option.channel,
    shareScreen = false,
    shouldPublicStream,
    isVideo = false,
    cdnURL,
    userId
  ) => {
    const { option, rtc, initStream } = this;
    this.setOption({
      channel
    });

    let client = rtc.client;
    let audioClient = rtc.audioClient;
    rtc.cdnURL = cdnURL;

    client.join(
      option.token,
      channel,
      MAIN_CLIENT_PREFIX + userId,
      function(uid) {
        console.log(
          'join channel: ' + option.channel + ' success, uid: ' + uid
        );
        rtc.localStreamIds = uid;
        shouldPublicStream && initStream(uid, isVideo);
      },
      function(err) {
        console.error('client join failed', err);
      }
    );

    audioClient.join(
      option.token,
      channel,
      AUDIO_CLIENT_PREFIX + userId,
      function(uid) {
        console.log(
          'audioClient join channel: ' +
            option.channel +
            ' success, uid: ' +
            uid
        );
        rtc.localAudioStreamIds = uid;
      },
      function(err) {
        console.error('audioClient client join failed', err);
      }
    );
  };

  initStream = (uid = this.rtc.localStreamIds, isVideo) => {
    let streamSpec = {
      streamID: uid,
      audio: true,
      video: true,
      screen: false
    };
    if (isVideo) {
      const sampleVideoDOM = document.getElementById('sample_video');
      // check whether video is valid
      if (!sampleVideoDOM.error) {
        const videoStream = sampleVideoDOM.captureStream
          ? sampleVideoDOM.captureStream(60)
          : sampleVideoDOM.mozCaptureStream();
        streamSpec = {
          streamID: uid,
          audio: true,
          video: true,
          videoSource: videoStream.getVideoTracks()[0],
          audioSource: videoStream.getAudioTracks()[0]
        };
      } else {
        return customToast('error', 'Invalid video url');
      }
    }
    this.publishStream(streamSpec);
  };

  publishSharingStream = () => {
    let streamSpec = {
      streamID: this.rtc.localStreamIds,
      audio: true,
      video: false,
      screen: true
    };
    if (isFirefox()) {
      streamSpec.mediaSource = 'window';
    } else if (isSafari()) {
    } else if (!isCompatibleChrome()) {
      return customToast(
        'error',
        'You need to update chrome to newer version to be able to sharing screen'
      );
    }
    this.publishStream(streamSpec);
  };

  publishAudioSharing = () => {
    let streamSpec = {
      streamID: this.rtc.localAudioStreamIds,
      audio: false,
      video: false,
      screen: true,
      screenAudio: true
    };
    if (isFirefox()) {
      streamSpec.mediaSource = 'window';
    } else if (isSafari()) {
    } else if (!isCompatibleChrome()) {
      return customToast(
        'error',
        'You need to update chrome to newer version to be able to sharing screen'
      );
    }
    this.publishStream(streamSpec, true);
  };

  publishStream = (streamSpec, isAudio = false) => {
    const {
      rtc,
      agoraCreateAndPublishStream,
      sharingEventListener,
      configTranscoding
    } = this;
    if (isAudio) {
      rtc.audioStream = AgoraRTC.createStream(streamSpec);
      configTranscoding();
      rtc.audioStream.init(
        function() {
          streamSpec.screenAudio &&
            upcomingDispatcher.setState('isSharingAudio', true);
          sharingEventListener(true);
          rtc.audioClient.publish(rtc.audioStream, function(err) {
            console.log('audioStream publish failed');
            console.error(err);
          });
        },

        function(err) {
          console.error('init local stream failed ', err);
        }
      );
    } else if (rtc.stream) {
      // Save next publish stream spec, will be created when 'stream-unpublished' trigger
      rtc.streamSpecQueue = streamSpec;
      rtc.stream.stop();
      rtc.stream.close();
      rtc.client.unpublish(rtc.stream, function(err) {
        console.log('unpublish failed');
        console.error(err);
        agoraCreateAndPublishStream(streamSpec);
      });
    } else agoraCreateAndPublishStream(streamSpec);
  };

  agoraCreateAndPublishStream = streamSpec => {
    const { rtc, sharingEventListener, initStream, configTranscoding } = this;

    rtc.stream = AgoraRTC.createStream(streamSpec);
    configTranscoding();
    rtc.stream.setVideoProfile(VIDEO_PROFILE);
    rtc.stream.setAudioProfile('high_quality_stereo');
    rtc.stream.init(
      function() {
        sharingEventListener();
        streamSpec.screen && upcomingDispatcher.setState('isSharing', true);
        rtc.client.publish(rtc.stream, function(err) {
          console.log('publish failed');
          console.error(err);
        });
      },

      function(err) {
        //init sharing stream fail
        if (streamSpec.screen && err.msg === 'NotAllowedError') {
          !configure.store.getState().upcoming.videoMode
            ? initStream()
            : initStream(undefined, true);
          upcomingDispatcher.setState('isSharing', false);
        }
        console.error('init local stream failed ', err);
      }
    );
  };

  unPublishStream = () => {
    const { rtc } = this;
    var id = rtc.stream.getId();
    if (id !== rtc.localStreamIds) return;
    else {
      if (rtc.cdnStarted) {
        rtc.client.stopLiveStreaming(rtc.cdnURL);
      }
      if (rtc.stream) {
        rtc.stream.stop();
        rtc.stream.close();
      }
      rtc.client.unpublish(rtc.stream, function(err) {
        console.log('stream unpublish failed');
        console.error(err);
      });
    }
  };

  unPublishAudioSharing = () => {
    const { rtc } = this;

    if (!rtc.audioStream) return;

    var id = rtc.audioStream.getId();
    if (id !== rtc.localAudioStreamIds) return;
    else {
      if (rtc.audioStream) {
        rtc.audioStream.stop();
        rtc.audioStream.close();
      }
      rtc.audioClient.unpublish(rtc.audioStream, function(err) {
        console.log('audioStream unpublish failed');
        console.error(err);
      });
    }
  };

  registerAudioFinishEvent = callback => {
    const { rtc } = this;
    rtc.stream.on('audioMixingFinished', callback);
  };

  addAudioMixing = (path, onErr) => {
    const { rtc } = this;
    var options = {
      filePath: path,
      cycle: 1,
      replace: false,
      playTime: 0
    };
    if (rtc.stream) {
      rtc.stream.startAudioMixing(options, e => {
        onErr(e);
      });
      rtc.stream.adjustAudioMixingVolume(100);
    }
  };
  pauseAudioMixing = () => {
    const { rtc } = this;
    if (rtc.stream) {
      rtc.stream.pauseAudioMixing();
    }
  };
  resumeAudioMixing = () => {
    const { rtc } = this;
    if (rtc.stream) {
      rtc.stream.resumeAudioMixing();
    }
  };
  stopAudioMixing = () => {
    const { rtc } = this;
    if (rtc.stream) {
      rtc.stream.stopAudioMixing();
    }
  };
  configTranscoding = () => {
    const { rtc } = this;
    var LiveTranscoding = {
      width: 1280,
      height: 720,
      videoBitrate: 402000,
      videoFramerate: 30,
      audioSampleRate: AgoraRTC.AUDIO_SAMPLE_RATE_48000,
      audioBitrate: 48,
      audioChannels: 2,
      videoGop: 30,
      videoCodecProfile: AgoraRTC.VIDEO_CODEC_PROFILE_HIGH,
      userCount: 2,
      userConfigExtraInfo: {},
      backgroundColor: 0x000000
    };

    LiveTranscoding.transcodingUsers = [];
    LiveTranscoding.transcodingUsers[0] = {
      x: 0,
      y: 0,
      width: 1280,
      height: 720,
      zOrder: 0,
      alpha: 1.0,
      uid: rtc.stream.getId()
    };
    if (rtc.audioStream)
      LiveTranscoding.transcodingUsers[1] = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        zOrder: 0,
        alpha: 1.0,
        uid: rtc.audioStream.getId()
      };
    rtc.client.setLiveTranscoding(LiveTranscoding);
  };

  removeView = () => {
    let streamView = document.getElementById('main_livestream_2');
    if (!streamView) streamView = document.getElementById('main_livestream');
    while (streamView.firstChild) {
      streamView.firstChild.remove();
    }
  };

  handleAudio = (isMuteAudio, dispatcher) => {
    const { rtc } = this;
    if (!isMuteAudio) {
      rtc.stream.muteAudio();
      // rtc.audioStream && rtc.audioStream.muteAudio(0);
    } else {
      rtc.stream.enableAudio();
      // rtc.audioStream && rtc.audioStream.enableAudio();
    }
    dispatcher('isMuteAudio', !isMuteAudio);
  };

  handleVideo = (isMuteVideo, dispatcher) => {
    const { rtc } = this;
    if (!isMuteVideo) {
      rtc.stream.muteVideo();
    } else {
      rtc.stream.unmuteVideo();
    }
    dispatcher('isMuteVideo', !isMuteVideo);
  };

  handleSpeaker = (isMuteSpeaker, dispatcher) => {
    const { rtc } = this;
    if (!isMuteSpeaker) {
      rtc.stream && rtc.stream.setAudioVolume(0);
      rtc.audioStream && rtc.audioStream.setAudioVolume(0);
    } else {
      rtc.stream && rtc.stream.setAudioVolume(100);
      rtc.audioStream && rtc.audioStream.setAudioVolume(100);
    }
    dispatcher('isMuteSpeaker', !isMuteSpeaker);
  };

  handleStartLiveStreaming = () => {
    const { rtc, startLiveStreaming } = this;
    if (isEmpty(rtc.funcQueue)) {
      rtc.funcQueue.push(startLiveStreaming);
    } else {
      rtc.funcQueue[0]();
      rtc.funcQueue = [];
    }
  };

  startLiveStreaming = () => {
    const { rtc } = this;
    if (
      !rtc.cdnStarted &&
      rtc.cdnURL &&
      rtc.stream.getId() === rtc.localStreamIds
    ) {
      console.log('startLiveStreaming with URL: ', rtc.cdnURL);
      rtc.client.startLiveStreaming(rtc.cdnURL, true);
    }
  };

  stopLiveStreaming = () => {
    const { rtc, unPublishStream, unPublishAudioSharing } = this;
    if (rtc.cdnStarted) {
      console.log('stopLiveStreaming START with URL: ', rtc.cdnURL);
      rtc.client.stopLiveStreaming(rtc.cdnURL);
    } else {
      unPublishStream();
      unPublishAudioSharing();
    }
  };
}

export default class AgoraLiveStream extends AgoraLiveStreamInstance {
  static instance = AgoraLiveStream.instance || new AgoraLiveStream();
}

// reset instance to fix some weird issues (black stream on wowza)
const resetAgoraInstance = () => {
  AgoraLiveStream.instance = new AgoraLiveStream();
};
