import styled from 'styled-components';

export const ButtonSecondary = styled.div`
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ad5e99;
  font-weight: 400;
  font-family: 'OpenSans';
`;

export const UploadAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LocalStreamContainer = styled.div`
  width: 96px;
  height: 144px;
  background-color: #333333;
  border-radius: 16px;
  position: absolute;
  bottom: 120px;
  right: 30px;
  // bottom: ${(props) => (props.isResponsive ? '88px' : '0px')};

  .mute-video-layer {
    width: 100%;
    height: 100%;
    background-color: #ad5e99;
    border: 1px solid #cccccc;
    border-radius: 12px;
    position: absolute;
    z-index: ${(props) => (props.isMuteVideo ? 1 : 0)};

    .text {
      color: white;
      position: absolute;
      bottom: 20px;
      left: 20px;

      font-size: 24px;
      font-family: 'Lato';
      font-weight: 600;
    }
  }

  video {
    z-index: ${(props) => (props.isMuteVideo ? 0 : 1)};
    transform: ${(props) => (props.transform ? props.transform : 'unset')};
    background-color: #333333 !important;
    object-fit: cover !important;
  }
  div {
    border-radius: 12px;
    border: ${(props) =>
      props.isHighlight ? '6px solid #50a5ed ' : 'inhiret'};
  }
`;

export const LocalSubUserStreamContainer = styled(LocalStreamContainer)`
  right: 140px !important;
  video {
    // height: 180% !important;
    // top: -90px !important;
    // object-fit: contain !important;

    transform: ${(props) =>
      props.deviceType === 'Android' ? 'rotate(-90deg)' : ''};
  }
`;
