import React, { useState } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Divider,
  Paper,
  Box
} from '@material-ui/core';
import { ClipboardIcon } from '@/assets/svg';
import { isEmpty } from 'lodash';
import ViewPackageItem from '../ViewPatientRegistration/ViewPackageItem';
import AddOnsItem from '../ViewPatientRegistration/AddOnsItem';
import Invoice from './ServiceItems/Invoice';

const useStyles = makeStyles({
  container: {
    display: 'flex'
  },

  leftContainer: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '36px 20px',
    flex: '1',
    borderRight: '1px solid #F1F1F1',
    height: 'calc(100vh - 283px)'
  },

  rightContainer: {
    overflow: 'scroll',
    padding: '16px 40px',
    display: 'flex',
    height: 'calc(100vh - 283px)'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    fontSize: 20,
    gap: 8
  },
  disabledSwitch: {
    pointerEvents: 'none',
    cursor: 'not-allowed'
  }
});

const ViewSelectPackage = ({
  totalCost,
  totalAmountDefault,
  formValue,
  paymentStatus,
  packageDetail,
  lstOptionalTests,
  hasOffSiteTest = true,
  dataInfo,
  restrictedTestIds
}) => {
  const classes = useStyles();

  return (
    <Paper square variant="outlined" className={classes.container}>
      <Box
        flexDirection="column"
        className={classes.leftContainer}
        gridGap={16}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className={classes.title}>
              <ClipboardIcon style={{ color: '#666666' }} />
              Selected packages
            </Typography>
          </Grid>
          {hasOffSiteTest && !isEmpty(formValue?.lstPackages) && (
            <Grid item xs={12}>
              <ViewPackageItem
                dataInfo={dataInfo}
                packageDetail={packageDetail}
                ignoredTests={formValue?.ignoredTests}
                ignoredProfiles={formValue?.ignoredProfiles}
                restrictedTestIds={restrictedTestIds}
              />
            </Grid>
          )}

          {hasOffSiteTest &&
            !isEmpty(formValue.lstPackages) &&
            !isEmpty(formValue.lstTests) && (
              <Grid item xs={12}>
                <Divider light variant="middle" />
              </Grid>
            )}

          {!isEmpty(formValue.lstTests) && (
            <Grid item xs={12}>
              <AddOnsItem listData={formValue.lstTests} />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box className={classes.rightContainer}>
        <Invoice
          totalCost={totalCost}
          totalAmountDefault={totalAmountDefault}
          dataExist={formValue}
          packageInfor={packageDetail}
          paymentStatus={paymentStatus}
          lstOptionalTests={lstOptionalTests}
        />
      </Box>
    </Paper>
  );
};

export default ViewSelectPackage;
