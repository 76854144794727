import _ from 'lodash';
import { FIELD_ARRAY_VALUE } from './constants';

export const formatCateFormQuestion = (questions = []) => {
  const listCate = _(questions)
    .groupBy(ques => ques.category)
    .map((value, key) => ({ categoryName: key, questions: value }))
    .value();

  return listCate;
};

export const convertCateToQuestion = (categories = []) => {
  let questions = [];

  _.forEach(categories, cate => {
    questions = questions.concat(cate.questions);
  });

  return questions;
};

export const filterEmptyData = questions => {
  //filter new questions were removed
  const filteredQuestions = _.filter(
    questions,
    ques => ques.id || !ques.isRemoved
  );

  const filteredArr = _.map(filteredQuestions, item => ({
    ...item,
    answers: _.map(
      //filter new answers were removed and empty new answers
      _.filter(
        item.answers,
        answer => answer.id || (answer.answer && !answer.isRemoved)
      ),
      ans => ({
        ...ans,
        isRemoved: ans.isRemoved || !ans.answer,
        answer: ans.answer || 'isRemoved'
      })
    )
  }));

  return filteredArr;
};

export const calculateQuestionPosition = (questions, index) => {
  let removedCount = 0; // count number of removed item
  for (let i = 0; i <= index; i++) {
    if (questions[i].isRemoved) removedCount++;
  }
  return index - removedCount;
};

export const validateCategory = category => {
  let error = {};
  //validate is empty category name
  // if (!category.category) {
  //   error.category = 'Question title is required';
  // }
  //questions
  if (_.isEmpty(_.filter(category.questions, ques => !ques.isRemoved))) {
    error.ques = 'Question title must have at least one question';
  } else {
    category.questions.forEach((item, index) => {
      if (!item.isRemoved) {
        if (!item.question) {
          _.set(
            error,
            ['questions', `${index}`, 'question'],
            'Question is required'
          );
        }
        if (!item.questionDesc) {
          _.set(
            error,
            ['questions', `${index}`, 'questionDesc'],
            'Description is required'
          );
        }
        //type belong to selection && empty list answers
        if (FIELD_ARRAY_VALUE.includes(item.answerType)) {
          const answers = _.filter(
            item.answers,
            item => item.answer && !item.isRemoved
          );
          if (_.isEmpty(answers)) {
            _.set(
              error,
              ['questions', `${index}`, 'answerType'],
              'Answer must have at least one option'
            );
          }
          //check additional question
        }
      }
    });
  }

  return error;
};
