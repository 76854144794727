/**
 *  Take object and make sare suggestionByGenders always have 3 items: [MaleObject, FemaleObject, OtherObject]
 */
export const cleanQuestionnaires = questionData => {
  if (!questionData) return questionData;
  let newData = {
    ...questionData
  };
  let { answers = [] } = newData;
  answers.forEach(answer => {
    const maleObject = (answer?.suggestionByGenders || []).find(
      suggest => suggest.gender === 'Male'
    ) || {
      gender: 'Male',
      note: ''
    };
    const femaleObject = (answer?.suggestionByGenders || []).find(
      suggest => suggest.gender === 'Female'
    ) || {
      gender: 'Female',
      note: ''
    };
    const otherObject = (answer?.suggestionByGenders || []).find(
      suggest => suggest.gender === 'Other'
    ) || {
      gender: 'Other',
      note: ''
    };
    answer.suggestionByGenders = [maleObject, femaleObject, otherObject];
  });
  return {
    ...newData,
    answers
  };
};
