import { Accordion } from '@material-ui/core';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)`
  border-radius: 12px !important;
  background: #f6f6f6 !important;
  box-shadow: none !important;
  padding: 0 16px;
  margin-bottom: 10px;
  .MuiAccordionSummary-root {
    padding: 0;
  }
  &::before {
    display: none;
  }
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
  }
  &.expanded {
    background-color: white !important;
    border: 1px solid #ad5e99;
    &.selected {
      border: 1px solid #ad5e99;
    }
    .MuiAccordionSummary-root {
      border-bottom: 1px solid #e1e1e1;
    }
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
    padding: 12px 0;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionDetails-root {
    padding: 16px 0 16px;
  }
  .MuiRadio-root {
    padding: 0 6px 0 0;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #ad5e99;
  }
`;
export const Price = styled.div`
  color: #333;
  font-size: 16px;
  font-weight: 600;
`;
export const HeaderTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CheckBoxRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .MuiCheckbox-root {
    padding: 0 4px 0 0;
  }
  .PrivateSwitchBase-checked-45 .MuiSvgIcon-root {
    color: #ad5e99;
  }
`;
export const CheckBoxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 30px;
  margin-top: 10px;
`;
