import { MenuItem, ListItemIcon, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const CustomSelect = withStyles({
  root: {
    minWidth: 224,
    padding: '12px 16px',
    color: '#333333',

    position: 'relative',
  },
})(MenuItem);

const CustomListItemIcon = withStyles({
  root: {
    minWidth: 20,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
})(ListItemIcon);

const CustomDivider = withStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 32px)',
  },
})(Divider);

const CustomMenuActions = ({ listActions }) => {
  return listActions.map((action) => {
    const Icon = action.icon;
    const styles = action.styles;
    const initialStyle = { color: action.color ? action.color : '#666666' };
    const checkLastItem = listActions[listActions.length - 1];

    return (
      <CustomSelect
        key={action.key}
        onClick={() => {
          action.onClick();
        }}
        style={{ color: action.color ? action.color : '#333333' }}
        disabled={action.disabled}
      >
        <CustomListItemIcon>
          <Icon
            style={styles ? { ...initialStyle, ...styles } : initialStyle}
          />
        </CustomListItemIcon>
        {action.label}
        {checkLastItem !== action ? <CustomDivider light /> : null}
      </CustomSelect>
    );
  });
};

export default CustomMenuActions;
