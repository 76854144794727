import AgoraRTM from 'agora-rtm-sdk';
import { AGORA_APP_ID } from './config';
import EventEmitter from 'events';
export default class RTMClient extends EventEmitter {
  constructor() {
    super();
    this.channels = {};
    this._logined = false;
    this.init();
  }

  init() {
    this.client = AgoraRTM.createInstance(AGORA_APP_ID);
    this.subscribeClientEvents();
  }

  // subscribe channel events
  subscribeChannelEvents(channelName) {
    const channelEvents = ['ChannelMessage', 'MemberJoined', 'MemberLeft'];
    channelEvents.forEach(eventName => {
      this.channels[channelName].channel.on(eventName, (...args) => {
        this.emit(eventName, { channelName, args: args });
      });
    });
  }

  subscribeClientEvents() {
    const clientEvents = ['MessageFromPeer'];
    clientEvents.forEach(eventName => {
      this.client.on(eventName, (...args) => {
        this.emit(eventName, ...args);
      });
    });
  }

  async login(accountName) {
    this.accountName = accountName;
    return this.client.login({ uid: this.accountName, token: null });
  }

  async logout() {
    return this.client.logout();
  }

  async joinChannel(name) {
    const channel = this.client.createChannel(name);
    this.channels[name] = {
      channel,
      joined: false // channel state
    };
    this.subscribeChannelEvents(name);
    return channel.join();
  }

  async leaveChannel(name) {
    if (
      !this.channels[name] ||
      (this.channels[name] && !this.channels[name].joined)
    ) {
      return;
    }
    return this.channels[name].channel.leave();
  }

  async sendChanelMessageFile(url, file, channelName) {
    if (!this.channels[channelName] || !this.channels[channelName].joined) {
      return;
    }
    let x = new Uint8Array(1);
    x[0] = file.type === 'IMAGE' ? 1 : 2;

    return this.channels[channelName].channel.sendMessage({
      description: `${file.fileName},${url}`,
      messageType: 'RAW',
      rawMessage: x
    });
  }

  async sendChannelMessage(text, channelName) {
    if (!this.channels[channelName] || !this.channels[channelName].joined) {
      return;
    }
    return this.channels[channelName].channel.sendMessage({ text });
  }

  async uploadImage(blob, channelName) {
    const mediaMessage = await this.client.createMediaMessageByUploading(blob, {
      messageType: 'IMAGE',
      fileName: 'agora.jpg',
      description: 'send image',
      thumbnail: blob
    });
    return this.client[channelName].channel.sendMessage(
      mediaMessage,
      channelName
    );
  }

  async sendPeerMessage(text, peerId) {
    return this.client.sendMessageToPeer({ text }, peerId.toString());
  }

  async sendPeerMessageFile(url, file, peerId) {
    let x = new Uint8Array(1);
    x[0] = file.type === 'IMAGE' ? 1 : 2;

    return this.client.sendMessageToPeer(
      {
        description: `${file.fileName},${url}`,
        messageType: 'RAW',
        rawMessage: x
      },
      peerId.toString()
    );
  }
}
