import React from 'react';
import { toast } from 'react-toastify';
import CustomNotificationContent from './CustomNotificationContent';

const customToast = (
  type,
  description,
  title,
  isAction,
  onClick,
  isClose,
  labelBtn
) => {
  toast[type](
    <CustomNotificationContent
      type={type}
      description={description}
      title={title}
      isAction={isAction}
      isClose={isClose}
      onClick={onClick}
      labelBtn={labelBtn}
    />,
    {
      autoClose: 5000
    }
  );
};

export default customToast;
