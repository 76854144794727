import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  Typography,
  makeStyles,
  Button,
  Dialog
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import customToast from '@/new-components/CustomNotification';

import bookingDispatch from './action';
import Filter from './components/Filter';
import TableHeadBooking from './components/TableHead';
import TableBodyBooking from './components/TableBody';
import { FORMAT_DATE_YEAR } from './constants';
import ModalEditBooking from './components/ModalEditBooking';
import ModalEditBookingItem from './components/ModalEditBookingItem';

import { groupDataByTime } from './utils';
import { TYPE_BOOKING_TIME } from '../appointment-management/constants';

const useStyles = makeStyles({
  container: {
    minHeight: 842,
    boxSizing: 'border-box',
    padding: '0 8px',
    marginBottom: 16
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 18,
    fontWeight: 600
  },
  btnBooking: {
    width: 200,
    height: 40,
    borderRadius: 20
  }
});

const toDay = moment()
  .startOf('week')
  .add('day', 1);

const renderTxtText = date => {
  if (!date) return '';
  return moment(date).format(FORMAT_DATE_YEAR);
};

const BookingManagement = () => {
  const classes = useStyles();
  const [weekPicker, setWeekPicker] = useState(toDay);
  const [txtDatePicker, setTxtDatePicker] = useState(renderTxtText(toDay));
  const [openModalPicker, setOpenModalPicker] = useState(false);
  const [openEditBookingTimeModal, setOpenEditBookingTimeModal] = useState(
    false
  );
  const [currentBookingItem, setCurrentBookingItem] = useState({});
  const [openEditBookingItem, setOpenEditBookingItem] = useState(false);

  const { bookings: bookingsDateRaw, headingKeys } = useSelector(
    state => state.manageBookingTime
  );

  const bookings = groupDataByTime(bookingsDateRaw);

  const setToday = () => {
    setWeekPicker(toDay);
  };

  useEffect(() => {
    bookingDispatch.getDataBooking({
      week: weekPicker,
      type: TYPE_BOOKING_TIME.Other
    });
    setTxtDatePicker(renderTxtText(weekPicker));
  }, [weekPicker]);

  const onChangeWeekPicker = value => {
    setWeekPicker(value);
    setOpenModalPicker(false);
  };

  const onCountWeekPicker = isAdd => {
    if (isAdd) {
      setWeekPicker(weekPicker => moment(weekPicker).add('week', 1));
    } else {
      setWeekPicker(weekPicker => moment(weekPicker).subtract('week', 1));
    }
  };

  const updateBookingItem = (item, cb) => {
    bookingDispatch.updateBookingTime(
      { ...item, type: TYPE_BOOKING_TIME.Other },
      () => {
        cb();
        setOpenEditBookingItem(false);
        setCurrentBookingItem({});
      },
      () => {
        customToast('error', 'Update booking fail');
      }
    );
  };

  const submitFormEditBooking = (data, cb) => {
    bookingDispatch.updateBlockBooking(
      { ...data, type: TYPE_BOOKING_TIME.Other },
      () => {
        cb();
        setOpenEditBookingTimeModal(false);
        bookingDispatch.getDataBooking({
          week: weekPicker,
          type: TYPE_BOOKING_TIME.Other
        });
      },
      () => {
        cb();
      }
    );
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <div className={`table-container-header ${classes.header}`}>
        <Typography className={classes.title}>Appointment Config</Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.btnBooking}
          onClick={() => setOpenEditBookingTimeModal(true)}
        >
          Edit Booking Time
        </Button>
      </div>
      <Filter
        value={weekPicker}
        txtDatePicker={txtDatePicker}
        openModalPicker={openModalPicker}
        changeWeekPicker={onChangeWeekPicker}
        setOpenModalPicker={setOpenModalPicker}
        onCountWeekPicker={onCountWeekPicker}
        setToday={setToday}
      />
      <Table aria-label="simple table" className={classes.table}>
        <TableHeadBooking keys={headingKeys} value={weekPicker} />
        <TableBodyBooking
          setCurrentBookingItem={setCurrentBookingItem}
          openEditModal={() => setOpenEditBookingItem(true)}
          keys={headingKeys}
          data={bookings}
        />
      </Table>
      <Dialog
        maxWidth="md"
        open={openEditBookingTimeModal}
        disableEnforceFocus
        onClose={() => setOpenEditBookingTimeModal(false)}
      >
        <ModalEditBooking
          submitForm={submitFormEditBooking}
          onClose={() => setOpenEditBookingTimeModal(false)}
        />
      </Dialog>
      <Dialog
        maxWidth="md"
        open={openEditBookingItem && currentBookingItem}
        disableEnforceFocus
        onClose={() => setOpenEditBookingItem(false)}
      >
        <ModalEditBookingItem
          value={currentBookingItem}
          submitForm={updateBookingItem}
          onClose={() => setOpenEditBookingItem(false)}
        />
      </Dialog>
    </TableContainer>
  );
};

export default BookingManagement;
