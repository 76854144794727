import React from 'react';
import {
  TextField,
  IconButton,
  withStyles,
  Box,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';

import { TYPE_MODAL } from '@/module/common/constants/typeModal';
import { CheckboxActive, CheckboxDefault, CloseIcon } from '@/assets/svg';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiInputBase-multiline': {
      height: 'auto',
      paddingLeft: 0
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    },

    '& p': {
      marginTop: 1
    }
  }
})(TextField);

const AnswerItem = ({
  onChangeAnswer,
  value,
  questionPos,
  answerPos,
  typeModal,
  errors
}) => {
  return (
    <React.Fragment>
      <Box display="flex" alignItems="center" gridGap={8}>
        <DragIndicator />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gridGap={8}
          width="100%"
        >
          <CustomTextField
            variant="outlined"
            value={value.answer}
            fullWidth
            disabled={typeModal === TYPE_MODAL.View}
            onChange={e =>
              onChangeAnswer(
                questionPos,
                TYPE_MODAL.Edit,
                answerPos,
                e,
                'answer'
              )
            }
          />
        </Box>

        {typeModal !== TYPE_MODAL.View && (
          <IconButton
            onClick={() =>
              onChangeAnswer(questionPos, TYPE_MODAL.Delete, answerPos)
            }
          >
            <CloseIcon style={{ color: '#8F9BA9' }} />
          </IconButton>
        )}
      </Box>
      {value.isOther && (
        <Box my={1} pl={4} pr={5}>
          <FormControlLabel
            className="custom-checkbox"
            style={{ marginBottom: 8 }}
            control={
              <Checkbox
                checked={value.isAdditional}
                color="primary"
                icon={<CheckboxDefault />}
                checkedIcon={<CheckboxActive />}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={e =>
                  onChangeAnswer(
                    questionPos,
                    TYPE_MODAL.EditCheck,
                    answerPos,
                    e
                  )
                }
              />
            }
            label="Additional Question"
          />
          <CustomTextField
            fullWidth
            placeholder="Enter your hint text"
            multiline
            rows={2}
            value={value.additionalNote}
            variant="outlined"
            disabled={!value.isAdditional}
            onChange={e =>
              onChangeAnswer(
                questionPos,
                TYPE_MODAL.EditAddition,
                answerPos,
                e,
                'additionalNote'
              )
            }
          />
        </Box>
      )}
    </React.Fragment>
  );
};

export default AnswerItem;
