import Enum from '../../../enum/Enum';

export const GENDER = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Non-specified', value: 'Other' }
];

export const PROFILE_TYPE = Enum({
  OptionalOnsite: 'OptionalOnsite',
  MandatoryOnsite: 'MandatoryOnsite'
});

export const PROFILE_STATUS = Enum({
  Active: 'Active',
  InActive: 'InActive'
});

export const EXPROFILE_TYPE = Enum({
  Profile: 'Profile',
  Test: 'Test'
});

export const testColumn = [
  {
    label: 'Test',
    stateValue: 'test'
  },
  {
    label: 'Test Code',
    stateValue: 'labCode'
  },
  // {
  //   label: 'Minc Code',
  //   stateValue: 'mincCode'
  // },
  {
    label: 'Station',
    stateValue: 'stationName'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const profileColumn = [
  {
    label: 'Profile',
    stateValue: 'name'
  },
  {
    label: 'Profile Code',
    stateValue: 'code'
  },
  {
    label: 'Tests',
    stateValue: 'totalTests'
  },
  {
    label: 'Profiles',
    stateValue: 'totalProfiles'
  },
  {
    label: '',
    stateValue: 'action'
  }
];
