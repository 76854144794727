import { get } from 'lodash';

const convertMessageFileFromAgora = (args, userId) => {
  const [contentMessage, userInfo, moreInfo] = args;
  const _userName = userInfo.split(',')[0];
  const _userId = userInfo.split(',')[1];

  const { description, rawMessage } = contentMessage;
  return {
    user: {
      _id: _userId,
      name: _userName
    },
    type: rawMessage[0] === 1 ? 'IMAGE' : 'FILE',
    text: description.split(',')[1],
    fileName: description.split(',')[0],
    date: moreInfo.serverReceivedTs,
    position: _userId === userId ? 'right' : 'left'
  };
};

const convertMessageFromAgora = (args, userId) => {
  const [message, userInfo, moreInfo] = args;
  const { text, name, roleType } = JSON.parse(get(message, 'text'));
  // const _userName = userInfo.split(',')[0];
  const _userId = userInfo.split(',')[1];
  return {
    user: {
      _id: _userId,
      name,
      roleType
    },
    type: message.messageType,
    text: text,
    date: moreInfo.serverReceivedTs,
    position: _userId === userId ? 'right' : 'left'
  };
};

const createMessageFileFromValue = (string, typeFile, userInfo) => {
  const fileUrl = string.split(',')[1];
  const fileName = string.split(',')[0];
  return {
    user: {
      _id: userInfo.id,
      name: userInfo.fullName || userInfo.firstName
    },
    type: typeFile,
    text: fileUrl,
    date: Date.parse(new Date()),
    position: 'right',
    fileName: fileName
  };
};

const createMessageFromValue = (value, userInfo) => {
  return {
    user: {
      _id: userInfo.id,
      name: userInfo.fullName || userInfo.firstName
    },
    type: 'TEXT',
    text: value,
    date: Date.parse(new Date()),
    position: 'right'
  };
};

export {
  createMessageFileFromValue,
  convertMessageFromAgora,
  createMessageFromValue,
  convertMessageFileFromAgora
};
