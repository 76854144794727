export const CONSENT_SERVICE = ['Tests'];

export const CONSENT_ACTION = {
  Add: 'ADD',
  Delete: 'DELETE',
  View: 'VIEW'
};

export const CONSENT_COL = [
  {
    label: 'Consent form',
    stateValue: 'title',
    width: '40%'
  },
  {
    label: 'Description',
    stateValue: 'description',
    width: '40%'
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 100
  }
];

export const COLUMN_TEST = [
  {
    label: 'Test name',
    stateValue: 'name',
    width: '30%'
  },
  {
    label: 'Test code',
    stateValue: 'testCode',
    width: '25%'
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '25%'
  },
  {
    label: 'Mandatory',
    stateValue: 'mandatory'
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 100
  }
];

export const COLUMN_MEASUREMENT = [
  {
    label: 'Test name',
    stateValue: 'name',
    width: '30%'
  },
  {
    label: 'Measurement code',
    stateValue: 'testCode',
    width: '25%'
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '25%'
  },
  {
    label: 'Mandatory',
    stateValue: 'mandatory'
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 100
  }
];

export const COLUMN_PROCEDURE = [
  {
    label: 'Procedure name',
    stateValue: 'name',
    width: '30%'
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '25%'
  },
  {
    label: 'Mandatory',
    stateValue: 'mandatory'
  },
  {
    label: 'Physical examination',
    stateValue: 'physicalExamination'
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 100
  }
];

export const COLUMN_RADIOLOGY = [
  {
    label: 'Radiology name',
    stateValue: 'name',
    width: '30%'
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '25%'
  },
  {
    label: 'Mandatory',
    stateValue: 'mandatory'
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 100
  }
];

export const COLUMN_REVIEW = [
  {
    label: 'Review name',
    stateValue: 'name',
    width: '30%'
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '25%'
  },
  {
    label: 'Mandatory',
    stateValue: 'mandatory'
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 100
  }
];
export const COLUMN_VACCINATION = [
  {
    label: 'Vaccination name',
    stateValue: 'name',
    width: '30%'
  },
  {
    label: 'Station',
    stateValue: 'station',
    width: '25%'
  },
  {
    label: 'Mandatory',
    stateValue: 'mandatory'
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 100
  }
];

export const SERVICE_STEP_TYPES = {
  GENERAL_INFORMATION: 'general_information',
  CONSENT_FORM: 'consent_form',
  RESTRICT_LOCATION: 'restrict_location'
};

export const TEST_SERVICES_STEPS = [
  {
    type: SERVICE_STEP_TYPES.GENERAL_INFORMATION,
    name: 'General information'
  },
  {
    type: SERVICE_STEP_TYPES.CONSENT_FORM,
    name: 'Consent form'
  }
];

export const PROCEDURE_SERVICES_STEPS = [
  {
    type: SERVICE_STEP_TYPES.GENERAL_INFORMATION,
    name: 'General information'
  },
  {
    type: SERVICE_STEP_TYPES.RESTRICT_LOCATION,
    name: 'Restrict locations'
  }
];

export const RADIOLOGY_SERVICES_STEPS = [
  {
    type: SERVICE_STEP_TYPES.GENERAL_INFORMATION,
    name: 'General information'
  },
  {
    type: SERVICE_STEP_TYPES.RESTRICT_LOCATION,
    name: 'Restrict locations'
  }
];

export const CATEGORY_LIST = [
  {
    id: 'Biometric',
    name: 'Biometric Parameters'
  },
  {
    id: 'UrineTool',
    name: 'Urine & tool'
  },
  {
    id: 'BloodAnalysis',
    name: 'Blood Analysis'
  }
];

export const GROUP_MEASUREMENT_CATEGORY = {
  Biometric: ['BMIWeight', 'BMIHeight'],
  UrineTool: ['UDSProtein', 'UDSGlucose', 'UDSBlood'],
  BloodAnalysis: ['DIASTOLIC', 'DIASTOLIC2', 'SYSTOLIC', 'SYSTOLIC2']
};

export const SPECIAL_MEASUREMENT = [
  // Urine Dipstick
  'UDSProtein',
  'UDSGlucose',
  'UDSBlood',
  // BMI
  'BMIWeight',
  'BMIHeight',
  // Waist Hip Ratio
  'WaistHipRatioWaist',
  'WaistHipRatioHip',
  // Visual Acuity
  'LeftUnaided',
  'RightUnaided',
  'LeftAided',
  'RightAided',
  // Blood Pressure
  'DIASTOLIC',
  'DIASTOLIC2',
  'SYSTOLIC',
  'SYSTOLIC2',
  // Peripheral (side) Vision Test
  'PVTRight',
  'PVTLeft',
  // Pupil Reflex
  'APRRight',
  'APRLeft'
];
