import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: (data) => ({ data }),
  saveDataById: (data) => ({ data }),
  getDataById:
    (id, cb) =>
    async ({ Api }) => {
      try {
        const response = await Api.get(`MotherhoodStatements/${id}`);
        if (response.status === 200) {
          motherStatementDispatcher.saveDataById(response.result);
        }
        cb();
      } catch (error) {
        console.log('Error: ', error);
        cb();
      }
    },
  getData:
    (search, paging, filterOption = {}) =>
    async ({ Api, getState }) => {
      let pagingData = paging || getState().consents.paging;
      let { result, status } = await Api.get(`MotherhoodStatements/filter`, {
        pageIndex: pagingData.pageIndex,
        pageSize: pagingData.pageSize,
        search,
        ...filterOption,
      });
      if (status === 200) {
        const { list, ...restParams } = result;
        const baseIndex = pagingData.pageIndex * pagingData.pageSize + 1;
        const dataAddIndex = list.map((item, index) => ({
          ...item,
          index: baseIndex + index,
        }));
        motherStatementDispatcher.getDataSuccess({
          ...restParams,
          list: dataAddIndex,
        });
      }
    },
  addMotherhood:
    (payload, callbackSuccess) =>
    async ({ Api }) => {
      const { status } = await Api.post(`MotherhoodStatements`, payload);
      motherStatementDispatcher.setState('loading', false);
      if (status === 200) {
        callbackSuccess && callbackSuccess();
      }
    },
  editMotherhood:
    (id, data, callback) =>
    async ({ Api, customToast }) => {
      const { status } = await Api.put(`MotherhoodStatements/${id}`, data);
      motherStatementDispatcher.setState('loading', false);
      if (status === 200) {
        callback && callback();
      }
    },

  setState: (state, value) => ({ state, value }),
};

const motherStatementDispatcher = synthesize(
  'motherhoodStatement',
  mapDispatchToAC
);
export default motherStatementDispatcher;
