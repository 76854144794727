import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { CustomTextField, getErrorMessage } from './index';

const CustomTypography = withStyles({
  root: {
    color: '#333333',
    marginBottom: 8
  }
})(Typography);

const ModalEditTestTonometry = ({ formValue, setFormValue, errors }) => {
  const onChangeTestValue = (name, value = '') => {
    if (!value) setFormValue(prevState => ({ ...prevState, [name]: '' }));
    // Regex test number contain decimal. Maximum length number of decimal is 9
    const regexNumber = /^(\d+\.?\d{0,9}|\.\d{1,9})$/g;
    if (regexNumber.test(value)) {
      setFormValue(prevState => ({ ...prevState, [name]: value }));
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <CustomTypography variant="subtitle2">Left eye IOP</CustomTypography>
        <CustomTextField
          size="small"
          fullWidth
          value={formValue.left}
          required
          placeholder="Left eye IOP"
          variant="outlined"
          type="number"
          onChange={e => onChangeTestValue('left', e.target.value)}
          error={!!errors.left}
          helperText={getErrorMessage(errors.left)}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTypography variant="subtitle2">Right eye IOP</CustomTypography>
        <CustomTextField
          size="small"
          fullWidth
          value={formValue.right}
          required
          placeholder="Right eye IOP"
          variant="outlined"
          type="number"
          onChange={e => onChangeTestValue('right', e.target.value)}
          error={!!errors.right}
          helperText={getErrorMessage(errors.right)}
        />
      </Grid>
    </>
  );
};

export default ModalEditTestTonometry;
