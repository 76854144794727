import { STAFF_SUBSIDY_TYPE } from '../../constants';
import { ArrowDown } from '@/assets/svg';
import { CustomAutocomplete } from '@/module/company/component/CreateAndEditCompany/styled';
import CustomSelectContainer from '@/new-components/CustomSelect/CustomSelectContainer';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { get, isEmpty } from 'lodash';
import React from 'react';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  text: {
    fontFamily: 'Lato',
    fontWeight: 700,
    paddingLeft: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500],
  },
});

const CustomInputLabel = withStyles({
  root: {
    color: '#666666',
    marginBottom: 8,
    fontWeight: 600,
  },
})(InputLabel);

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 40,
      borderRadius: 8,
      fontSize: 16,
    },
    '& input': {
      padding: 0,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 0,
        paddingLeft: 4,
        marginLeft: 0,
      },
  },
})(TextField);

const CustomDialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
  },
  paperWidthXs: {
    maxWidth: 400,
    minHeight: 512,
  },
})(Dialog);

const CustomButton = withStyles({
  root: {
    width: 166,
    height: 48,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset',
    '&.red': {
      background: '#DB3D49',
    },
  },
  containedPrimary: {
    boxShadow: 'none',
  },
})(Button);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
}))(DialogContent);

const CustomDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
}))(DialogActions);

const ModalCreateCompany = ({
  maxWidth = 'sm',
  open,
  title,
  submitLabel = 'Submit',
  otherActionsContent,
  onSubmit,
  onClose,
  formValue,
  setFormValue,
  errors,
  lstSurvey,
  loading,
}) => {
  const handleChangeForm = (key) => (e, newValue) => {
    let value = get(e, 'target.value');
    if (key === 'surveyId') value = get(newValue, 'id');
    setFormValue({ ...formValue, [key]: value });
  };

  return (
    <CustomDialog
      maxWidth={maxWidth}
      fullWidth
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
    >
      <CustomDialogTitle onClose={onClose}>{title}</CustomDialogTitle>
      <Divider light />
      <CustomDialogContent>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <CustomInputLabel>Company name</CustomInputLabel>
            <CustomTextField
              fullWidth
              error={errors?.name}
              helperText={errors?.name}
              value={formValue?.name || ''}
              onChange={handleChangeForm('name')}
              variant="outlined"
              placeholder="Enter company name"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Company size</CustomInputLabel>
            <CustomTextField
              error={errors.staffQuantity}
              helperText={errors.staffQuantity}
              placeholder="Enter number"
              value={formValue?.staffQuantity || ''}
              fullWidth
              onChange={handleChangeForm('staffQuantity')}
              type="number"
              variant="outlined"
              allowClear
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Custom survey</CustomInputLabel>

            <CustomAutocomplete
              getOptionSelected={(option, value) => option.id === value?.id}
              getOptionLabel={(option) => option.name || ''}
              options={lstSurvey || []}
              value={
                lstSurvey.find((survey) => survey.id === formValue?.surveyId) ||
                ''
              }
              onChange={handleChangeForm('surveyId')}
              popupIcon={<ArrowDown />}
              renderInput={(params) => (
                <CustomTextField
                  placeholder="Select survey"
                  {...params}
                  variant="outlined"
                  error={errors.surveyId}
                  helperText={errors.surveyId}
                />
              )}
              renderOption={(option) => (
                <span className="max-line">{option.name}</span>
              )}
            />
          </Grid>
        </Grid>
      </CustomDialogContent>
      <Divider light />
      <CustomDialogActions>
        {otherActionsContent ? (
          otherActionsContent()
        ) : (
          <>
            <CustomButton
              onClick={onSubmit}
              variant="contained"
              color="primary"
              disabled={isEmpty(formValue) || loading}
            >
              {submitLabel}
            </CustomButton>
          </>
        )}
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalCreateCompany;
