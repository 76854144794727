import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField, Typography } from '@material-ui/core';
import { EmailIcon, LockIcon, EyeHide, EyeShow } from '../../../../assets/svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import { StyledButton } from '../../../common/componentUI/commonStyleComponents';

const AccountInfo = ({ onChange, values, errors, toNextStep }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <AccountInfoStyle>
      <Typography
        style={{
          fontSize: 24,
          letterSpacing: 0,
          fontWeight: 600,
          marginBottom: 20
        }}
      >
        Create New Trainer Account
      </Typography>
      <FieldStyle>
        <EmailIcon style={{ marginRight: 13, marginTop: 30 }} />
        <TextFieldStyle
          error={errors.email}
          helperText={errors.email}
          label="Email"
          autoComplete="email"
          value={values.email}
          onChange={onChange('email')}
        />
      </FieldStyle>
      <FieldStyle>
        <LockIcon style={{ marginRight: 13, marginTop: 30 }} />
        <TextFieldStyle
          error={errors.password}
          helperText={errors.password}
          label="Password"
          style={{ width: '100%', marginTop: 10 }}
          value={values.password}
          onChange={onChange('password')}
          type={showPassword ? '' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <>
                  <EyeHide
                    style={{ cursor: 'pointer' }}
                    hidden={showPassword}
                    onClick={() => setShowPassword(true)}
                  />
                  <EyeShow
                    style={{ cursor: 'pointer' }}
                    hidden={!showPassword}
                    onClick={() => setShowPassword(false)}
                  />
                </>
              </InputAdornment>
            )
          }}
        />
      </FieldStyle>
      <FieldStyle className="bottom-element">
        <LockIcon style={{ marginRight: 13, marginTop: 30 }} />
        <TextFieldStyle
          type={showConfirmPassword ? '' : 'password'}
          error={errors.confirmPassword}
          helperText={errors.confirmPassword}
          style={{ width: '100%', marginTop: 10 }}
          value={values.confirmPassword}
          onChange={onChange('confirmPassword')}
          label="Confirm Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <>
                  <EyeHide
                    style={{ cursor: 'pointer' }}
                    hidden={showConfirmPassword}
                    onClick={() => setShowConfirmPassword(true)}
                  />
                  <EyeShow
                    style={{ cursor: 'pointer' }}
                    hidden={!showConfirmPassword}
                    onClick={() => setShowConfirmPassword(false)}
                  />
                </>
              </InputAdornment>
            )
          }}
        />
      </FieldStyle>
      <StyledButton onClick={toNextStep}>Next</StyledButton>
    </AccountInfoStyle>
  );
};

export default AccountInfo;

const AccountInfoStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 446px;
  width: 380px;
  border-radius: 10px;
  background-color: white;
  padding: 40px 20px;
  .bottom-element {
    margin-bottom: 60px;
  }
`;

const FieldStyle = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 25px;
  align-items: center;
  height: 40px;
`;

const TextFieldStyle = styled(TextField)`
  height: 40px;
  width: 308px;
`;
