import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import {
  CheckboxActive,
  CheckboxDefault,
  CheckboxDeselected
} from '@/assets/svg';
import { TableHead } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    position: 'relative',
    height: 500
  },
  table: {
    minWidth: 650,
    overflow: 'auto',
    '& .MuiTableRow-head': {
      height: 40,
      border: 'none',

      '& .MuiTableCell-root': {
        fontSize: 12,
        fontWeight: 400,

        padding: '8px 12px',
        backgroundColor: '#f6f6f6',
        border: 'none'
      }
    },

    '& .MuiTableRow-root.Mui-selected': {
      backgroundColor: 'unset',
      '&:hover': {
        backgroundColor: theme.palette.grey.background
      }
    }
  },
  noDataHelper: {
    verticalAlign: 'middle',
    height: 'calc(100vh - 340px)',
    border: 'none',
    '& .title-text': {
      marginTop: 12,
      fontSize: 18,
      fontWeight: 600,
      fontFamily: 'Lato'
    }
  },
  tablePagination: {
    borderTop: '2px solid #e1e1e1'
  }
}));

const CheckboxTable = ({
  data,
  columns,
  renderRow,
  selected,
  onSelectChange,
  onUnSelectAll,
  ...props
}) => {
  const classes = useStyles();
  const isSelected = id => selected.indexOf(id) !== -1;

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = data.map(n => n.id);
      onSelectChange(newSelected);
      return;
    }
    onUnSelectAll();
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    onSelectChange(newSelected);
  };

  return (
    <Paper square variant="outlined">
      <TableContainer className={classes.tableContainer} {...props}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: '0 4px 0' }}>
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < data.length
                  }
                  checked={data.length > 0 && selected.length === data.length}
                  onChange={handleSelectAllClick}
                  icon={<CheckboxDefault width={20} height={20} />}
                  checkedIcon={<CheckboxActive width={20} height={20} />}
                  indeterminateIcon={
                    <CheckboxDeselected width={20} height={20} />
                  }
                />
              </TableCell>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id || column.stateValue || column.label || index}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    width: column.width
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((d, i) => {
              const isItemSelected = isSelected(d.id);
              return (
                <TableRow key={d.id} hover selected={isItemSelected}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onClick={event => handleClick(event, d.id)}
                      inputProps={{
                        'aria-labelledby': `enhanced-table-checkbox-${i}`
                      }}
                      icon={<CheckboxDefault width={20} height={20} />}
                      checkedIcon={<CheckboxActive width={20} height={20} />}
                    />
                  </TableCell>
                  {renderRow(d, i)}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CheckboxTable;
