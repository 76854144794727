import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import './styles.scss';
import cn from 'classnames';
import { Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
// import Icon from '@ant-design/icons';

// const loadingIcon = <Icon spin style={{ fontSize: 24 }} type="loading" />;

class Image extends PureComponent {
  constructor() {
    super();
    this.state = {
      failed: false,
      isLoading: true
    };
  }

  loadImageFailed = () => {
    this.setState({
      failed: true,
      isLoading: false
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState({ isLoading: true });
    }
  }

  handleImageLoaded = () => {
    this.setState({
      isLoading: false
    });
  };

  render() {
    const {
      isHasBackgroundImage,
      src,
      alt,
      isParallax,
      type,
      title,
      urlFailed,
      className,
      classNameWrapper,
      style,
      wrapperStyle,
      ...restProps
    } = this.props;
    const { failed, isLoading } = this.state;
    let xhtml = null;
    if (isEmpty(src)) {
      xhtml = (
        <div
          className={className}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...style
          }}
        ></div>
      );
    } else if (isHasBackgroundImage) {
      const style = {
        backgroundImage: `url(${src})`,
        backgroundSize: type,
        backgroundPosition: 'center',
        backgroundAttachment: isParallax ? 'fixed' : ''
      };
      xhtml = <div className="image-cus-wrapper" style={style} title={title} />;
    } else {
      xhtml = !failed ? (
        <>
          {/* {isLoading && (
            <div
              className={className}
              {...restProps}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...style
              }}
            >
              <Spin />
            </div>
          )} */}
          <div className={classNameWrapper} style={wrapperStyle}>
            <img
              alt={alt}
              className={cn('img', className)}
              onError={this.loadImageFailed}
              onLoad={this.handleImageLoaded}
              src={src}
              title={title}
              style={style}
              {...restProps}
            />
          </div>
        </>
      ) : null;
    }
    return xhtml;
  }
}

Image.propTypes = {
  urlFailed: PropTypes.string
};

export default Image;
