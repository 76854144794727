import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    border: 'solid 1px #ffffff',
    padding: '8px 16px',
    boxSizing: 'border-box'
  },
  title: {
    color: '#192637',
    fontSize: 18,
    lineHeight: '27px',
    marginBottom: 16
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}));

const ModalInfo = props => {
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={props.onClose}
      BackdropComponent={Backdrop}
      disableBackdropClick
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <div className={classes.title}>{props.title}</div>
          <div className={classes.footer}>
            <Button variant="contained" color="primary" onClick={props.onClose}>
              Close
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

ModalInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ModalInfo;
