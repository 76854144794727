import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button, Radio } from '@material-ui/core';
import classnames from 'classnames';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  active: {
    border: '1px solid #ad5e99'
  },
  inactive: {
    border: '1px solid #e9e9e9'
  },
  btnContainer: {
    width: '175px',
    height: '40px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-start'
  },
  icon: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  iconActive: {
    color: '#62CAA4'
  },
  iconInactive: {
    color: '#CACFD3'
  },
  txt: {
    color: '#192637',
    fontSize: 14,
    lineHeight: '21px',
    textAlign: 'center'
  }
});

const CustomRadioBtn = ({ onChange, value }) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Button
        className={classnames(
          classes.btnContainer,
          value === 'open' ? classes.active : classes.inactive
        )}
        onClick={() => onChange('option')('open')}
      >
        <Radio
          checked={value === 'open'}
          color="primary"
          value="open"
          size="small"
        />
        <span className={classes.txt}>Open</span>
        <span className={classes.icon}>
          <FiberManualRecordIcon
            className={classnames(classes.iconActive)}
            fontSize="small"
          />
        </span>
      </Button>
      <Button
        className={classnames(
          classes.btnContainer,
          value === 'close' ? classes.active : classes.inactive
        )}
        onClick={() => onChange('option')('close')}
      >
        <Radio checked={value === 'close'} color="primary" size="small" />
        <span className={classes.txt}>Close</span>
        <span className={classes.icon}>
          <FiberManualRecordIcon
            className={classnames(classes.iconInactive)}
            fontSize="small"
          />
        </span>
      </Button>
    </div>
  );
};

CustomRadioBtn.defaultProps = {
  value: null
};

CustomRadioBtn.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default CustomRadioBtn;
