const STATUS_UPCOMING = 'Upcoming';
const STATUS_IN_PROGRESS = 'InProgress';
const STATUS_COMPLETED = 'Completed';
const STATUS_CANCELLED = 'Cancelled';
const STATUS_DEFAULTED = 'Missed';
const STATUS_CHECK_IN = 'CheckIn';
const STATUS_WAITING = 'Awaiting';
const STATUS_DONE = 'Done';
const STATUS_REJECTED = 'Rejected';
const STATUS_UNASSIGNED = 'UnAssigned';
const STATUS_ASSIGNED = 'Assigned';
const STATUS_READY_TO_PAY = 'ReadyToPay';
const STATUS_PAID = 'Paid';
const STATUS_CHEKIN_LOCATION = 'CheckInLocation';
const STATUS_PENDING = 'Pending';
const STATUS_INQUEUE = 'In Queue';

export const STATUSES_APPOINTMENT = {
  UPCOMING: STATUS_UPCOMING,
  IN_PROGRESS: STATUS_IN_PROGRESS,
  COMPLETED: STATUS_COMPLETED,
  CANCELLED: STATUS_CANCELLED,
  DEFAULTED: STATUS_DEFAULTED,
  CHECK_IN: STATUS_CHECK_IN,
  UNASSIGNED: STATUS_UNASSIGNED,
  AWAITING: STATUS_WAITING,
  DONE: STATUS_DONE,
  ASSIGNED: STATUS_ASSIGNED,
  READY_TO_PAY: STATUS_READY_TO_PAY,
  PAID: STATUS_PAID,
  CHECKIN_LOCATION: STATUS_CHEKIN_LOCATION
};

export const STATUS_COLOR_UI = {
  [STATUS_UPCOMING]: {
    txt: '#fff',
    background: '#2FC2D6'
  },
  [STATUS_COMPLETED]: {
    txt: '#fff',
    background: '#AD5E99'
  },
  [STATUS_IN_PROGRESS]: {
    txt: '#fff',
    background: '#E1BD43'
  },
  [STATUS_CANCELLED]: {
    txt: '#fff',
    background: '#8F9BA9'
  },
  [STATUS_REJECTED]: {
    txt: '#fff',
    background: '#8F9BA9'
  },
  [STATUS_DEFAULTED]: {
    txt: '#fff',
    background: '#EA6B75'
  },
  [STATUS_CHECK_IN]: {
    txt: '#fff',
    background: '#339900'
  },
  [STATUS_WAITING]: {
    txt: '#fff',
    background: '#E1BD43'
  },
  [STATUS_DONE]: {
    txt: '#fff',
    background: '#339900'
  },
  [STATUS_UNASSIGNED]: {
    txt: '#fff',
    background: '#EA6B75'
  },
  [STATUS_ASSIGNED]: {
    txt: '#fff',
    background: '#FA8F51'
  },
  [STATUS_READY_TO_PAY]: {
    txt: '#fff',
    background: '#2FC2D6'
  },
  [STATUS_PAID]: {
    txt: '#fff',
    background: '#AD5E99'
  },
  [STATUS_CHEKIN_LOCATION]: {
    txt: '#fff',
    background: '#EF6C00'
  },
  [STATUS_PENDING]: {
    txt: '#fff',
    background: '#EA6B75'
  },
  [STATUS_INQUEUE]: {
    txt: '#fff',
    background: '#E1BD43'
  }
};
export const consentColumn = [
  {
    label: 'Form Name',
    stateValue: 'title'
  },
  {
    label: 'Description',
    stateValue: 'description'
  },
  {
    label: 'Status',
    stateValue: 'status',
    style: { minWidth: 125 }
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const TABLE_STATE = {
  Outside: 'Outside',
  Modal: 'Modal'
};

export const FORM_STATUS = {
  New: {
    label: 'New',
    color: '#AD5E99',
    bgColor: '#FBF2F9'
  },
  Signed: {
    label: 'Signed',
    color: '#62CAA4',
    bgColor: '#DFFAF0'
  },
  NeedToSign: {
    label: 'Need to sign',
    color: '#E1BD43',
    bgColor: '#FBF4DD'
  }
};

export const tableColumnTimeSlot = [
  {
    label: ''
  },
  {
    label: 'Start time'
  },
  {
    label: 'End time'
  },
  {
    label: 'Time Interval (minutes)'
  },
  {
    label: 'Quota used'
  },
  {
    label: 'Quota base'
  }
];
