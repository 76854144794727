import { synthesize } from 'redux-dispatcher';
import { map } from 'lodash';
import { formatToTimeStamp, parseDataTimeMoment } from '@/module/setup/utils';
import { createDownloadableLink } from '@/helpers';

const mapDispatchToAC = {
  getTimeSlotsSuccess: data => ({ data }),
  getData: (search, paging, filterOption = {}, cb) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().timeSlotManagement.paging;
    const { startTime, endTime, interval } = filterOption;
    let { result, status } = await Api.post(`LocationTimeTemplates/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      interval,
      startTime: startTime ? formatToTimeStamp(startTime) : '',
      endTime: endTime ? formatToTimeStamp(endTime) : ''
    });

    if (status === 200) {
      const { list = [] } = result;
      const newList = map(list, item => ({
        ...item,
        startTime: parseDataTimeMoment(item.startTime),
        endTime: parseDataTimeMoment(item.endTime)
      }));
      timeslotTemplateDispatcher.getTimeSlotsSuccess({
        ...result,
        list: newList
      });
      cb && cb(newList);
    }
  },

  setState: (state, value) => ({ state, value }),
  addTemplate: (data, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.post('LocationTimeTemplates', data);
    if (status === 200) {
      callbackSuccess && callbackSuccess();
    }
  },
  updateTemplate: (id, data, callbackSuccess) => async ({ Api }) => {
    const { result, status } = await Api.put(
      `LocationTimeTemplates/${id}`,
      data
    );
    if (status === 200) {
      callbackSuccess && callbackSuccess(result);
    }
  },
  deleteTemplate: (id, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.delete(`LocationTimeTemplates/${id}`);
    if (status === 200) {
      customToast('success', 'Delete template success');
      callbackSuccess && callbackSuccess();
    }
  },
  getDetailTemplate: (id, callbackSuccess) => async ({ Api }) => {
    const { status, result } = await Api.get(`LocationTimeTemplates/${id}`);
    if (status === 200) {
      const newResult = { ...result };
      newResult.timeSlots = map(result.timeSlots, item => {
        const { startTime, endTime } = item;
        const startTimeMoment = parseDataTimeMoment(startTime);
        const endTimeMoment = parseDataTimeMoment(endTime);
        const interval = endTimeMoment.diff(startTimeMoment, 'minutes');
        return {
          ...item,
          startTime: startTimeMoment,
          endTime: endTimeMoment,
          interval
        };
      });
      newResult.startTime = parseDataTimeMoment(result.startTime);
      newResult.endTime = parseDataTimeMoment(result.endTime);
      callbackSuccess && callbackSuccess(newResult);
    }
  },
  getTemplateForLocation: (id, callbackSuccess) => async ({ Api }) => {
    const { status, result } = await Api.get(
      `LocationTimeTemplates/to-select/${id}`
    );
    if (status === 200) {
      const newResult = { ...result };
      newResult.timeSlots = map(result.timeSlots, item => {
        const { startTime, endTime } = item;
        const startTimeMoment = parseDataTimeMoment(startTime);
        const endTimeMoment = parseDataTimeMoment(endTime);
        const interval = endTimeMoment.diff(startTimeMoment, 'minutes');
        return {
          ...item,
          startTime: startTimeMoment,
          endTime: endTimeMoment,
          interval
        };
      });
      newResult.startTime = parseDataTimeMoment(result.startTime);
      newResult.endTime = parseDataTimeMoment(result.endTime);
      callbackSuccess && callbackSuccess(newResult);
    }
  },
  getExportExcel: params => async ({ Api }) => {
    let { result, status } = await Api.post(
      `LocationTimeTemplates/csv-report`,
      {
        ...params,
        startTime: params.startTime ? formatToTimeStamp(params.startTime) : '',
        endTime: params.endTime ? formatToTimeStamp(params.endTime) : ''
      }
    );
    status === 200 && createDownloadableLink(result, 'timeslot-report');
  }
};

const timeslotTemplateDispatcher = synthesize(
  'timeSlotManagement',
  mapDispatchToAC
);
export default timeslotTemplateDispatcher;
