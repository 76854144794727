const renderColumn = () => {
  const tableComlumn = [
    {
      label: 'Name',
      stateValue: 'fullName'
    },
    {
      label: 'Email',
      stateValue: 'email'
    },
    // {
    //   label: 'Certification',
    //   stateValue: 'praticisingCertificate'
    // },
    {
      label: 'Status',
      stateValue: 'approveStatus'
    },
    {
      label: 'Action',
      stateValue: null
    }
  ];
  return tableComlumn;
};

export default renderColumn;
