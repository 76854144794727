import { CONSENT_COL, CONSENT_ACTION } from '../../constants';
import { CloseCircle, EyeIcon } from '@/assets/svg';
import TableWithFooter from '@/new-components/CustomTable/TableWithFooter';
import CustomTooltip from '@/new-components/CustomTooltip';
import { IconButton, TableCell } from '@material-ui/core';
import React from 'react';

const ConsentTable = ({
  data,
  onDeleteConsent,
  onViewDetail,
  setItemSelected,
  itemSelected,
}) => {
  const renderRow = (row, i) => (
    <>
      <TableCell>
        <CustomTooltip content={row?.title} />
      </TableCell>
      <TableCell>
        <CustomTooltip content={row?.description} />
      </TableCell>
      <TableCell>
        <IconButton
          onClick={() => {
            setItemSelected({ item: row, position: i });
            onViewDetail();
          }}
        >
          <EyeIcon color="#666666" width={24} height={24} />
        </IconButton>
        <IconButton
          onClick={() => {
            onDeleteConsent(CONSENT_ACTION.Delete, i)();
          }}
        >
          <CloseCircle width={18} height={18} />
        </IconButton>
      </TableCell>
    </>
  );

  return (
    <React.Fragment>
      <TableWithFooter
        data={data}
        labelButton="Add"
        columns={CONSENT_COL}
        renderRow={renderRow}
        isFooter={false}
        noDataHelperText={'No consent form is selected'}
      />
    </React.Fragment>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.data?.length === nextProps.data?.length &&
    prevProps.itemSelected === nextProps.itemSelected
  );
}

export default React.memo(ConsentTable, areEqual);
