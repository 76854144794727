import { Box, Paper, TextField, withStyles } from '@material-ui/core';
import React from 'react';
import { CustomAutocomplete } from '@/module/company/component/CreateAndEditCompany/styled';
import { getFullTimeInDay } from '@/module/setup/utils';
import moment from 'moment';
import { ArrowDown } from '@/assets/svg';

const CustomPaper = withStyles(theme => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8
      },
      '&:last-child': {
        marginBottom: 8
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
      }
    }
  }
}))(Paper);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0
    }
  }
})(TextField);

const DayItem = ({ dataItem, dataIndex, handleChangeTime }) => {
  let timeSlotsStartDefault = getFullTimeInDay(0, 24, 15);
  timeSlotsStartDefault.push({
    key: '11:59 PM',
    value: '11:59 PM'
  });

  let timeSlotsEndDefault = getFullTimeInDay(0, 24, 15).filter(obj =>
    moment(obj.value, 'h:mm A').isAfter(moment(dataItem?.startTime, 'hh:mm:ss'))
  );
  timeSlotsEndDefault.push({
    key: '11:59 PM',
    value: '11:59 PM'
  });

  return (
    <Box display="flex" alignItems="center" gridGap={16}>
      <Box width={'16%'} fontSize={16} color="#333333">
        {dataItem.value}
      </Box>
      <CustomAutocomplete
        style={{ width: '42%' }}
        getOptionSelected={(option, value) => option === value}
        getOptionLabel={option => option.value || ''}
        options={timeSlotsStartDefault || []}
        value={
          timeSlotsStartDefault.find(
            it =>
              it?.value ===
              moment(dataItem?.startTime, 'hh:mm:ss').format('h:mm A')
          ) || ''
        }
        onChange={handleChangeTime('startTime', dataIndex)}
        popupIcon={<ArrowDown />}
        renderInput={params => (
          <CustomTextField
            variant="outlined"
            placeholder="Select start time"
            {...params}
          />
        )}
        disableClearable
        PaperComponent={({ children, ...other }) => (
          <CustomPaper {...other}>{children}</CustomPaper>
        )}
      />
      <div>—</div>
      <CustomAutocomplete
        style={{ width: '42%' }}
        fullWidth
        getOptionSelected={(option, value) => option === value}
        getOptionLabel={option => option.value || ''}
        options={timeSlotsEndDefault || []}
        value={
          timeSlotsEndDefault.find(
            it =>
              it?.value ===
              moment(dataItem?.endTime, 'hh:mm:ss').format('h:mm A')
          ) || ''
        }
        onChange={handleChangeTime('endTime', dataIndex)}
        popupIcon={<ArrowDown />}
        renderInput={params => (
          <CustomTextField
            variant="outlined"
            placeholder="Select end time"
            {...params}
          />
        )}
        disableClearable
        PaperComponent={({ children, ...other }) => (
          <CustomPaper {...other}>{children}</CustomPaper>
        )}
      />
    </Box>
  );
};

export default DayItem;
