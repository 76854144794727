import { DeleteRounded, Edit } from '@material-ui/icons';

const renderListAction = event => {
  const LIST_ACTIONS = [
    {
      key: 'edit',
      icon: Edit,
      label: 'Edit',
      onClick: () => event('edit')
    },
    {
      key: 'delete',
      icon: DeleteRounded,
      label: 'Delete',
      onClick: () => event('delete')
    }
  ];
  return LIST_ACTIONS;
};

export default renderListAction;
