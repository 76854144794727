import { ECOMMERCE_STATUSES_FILTER } from '../constants';
import { ArrowDown } from '@/assets/svg';
import { CustomAutocomplete } from '@/module/company/component/CreateAndEditCompany/styled';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import {
  Box,
  Button,
  Toolbar,
  withStyles,
  TextField,
  Paper,
} from '@material-ui/core';
import { isBoolean, isEmpty } from 'lodash';
import React, { useMemo } from 'react';

const CustomButton = withStyles({
  label: {
    fontWeight: 700,
  },
})(Button);

const CustomTextField = withStyles({
  root: {
    fontSize: 14,
    width: '190px',

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 14,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
})(TextField);

const CustomPaper = withStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))(Paper);

const GroupToolbar = ({
  filterOption,
  onResetFilterOps,
  activeStatuses,
  setActiveStatuses,
  categoryId,
  setCategoryId,
  onFilterOptionChange,
  categories,
}) => {
  const renderValue = (array, label, defaultLabel) => {
    let value = '';
    if (array.length === 0) value = defaultLabel;
    else if (array.length === 1) value = array[0];
    else value = `${label} (${array.length})`;

    return value;
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(filterOption);

    return filterKeys.every((key) =>
      isBoolean(filterOption[key])
        ? !filterOption[key]
        : isEmpty(filterOption[key])
    );
  }, [filterOption]);

  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gridGap={12}>
          <CustomSelectContainer
            isFullWidth
            filterValue={activeStatuses || []}
            data={ECOMMERCE_STATUSES_FILTER}
            renderValue={renderValue(
              activeStatuses,
              'Statuses',
              'Select status'
            )}
            onChange={onFilterOptionChange('activeStatuses', setActiveStatuses)}
            labelSelectAll={'All statuses'}
            useSelectAll
            checkedAll={
              ECOMMERCE_STATUSES_FILTER.length === activeStatuses.length
            }
          />
          <CustomAutocomplete
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={(option) => option.name || ''}
            options={categories || []}
            value={categories.find((test) => test.id === categoryId) || null}
            onChange={onFilterOptionChange('categoryId', setCategoryId)}
            popupIcon={<ArrowDown />}
            renderInput={(params) => (
              <CustomTextField
                variant="outlined"
                placeholder="Select category"
                {...params}
              />
            )}
            PaperComponent={({ children, ...other }) => (
              <CustomPaper {...other}>{children}</CustomPaper>
            )}
          />
        </Box>

        <CustomButton
          color="primary"
          disabled={isEmptyFilterParams}
          onClick={onResetFilterOps}
        >
          Reset
        </CustomButton>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;
