import fetchHelper from '@/helpers/FetchHelper';
import { styled, Link } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

const Wrapper = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  textAlign: 'center',
});

const ErrorCode = styled('div')({
  fontSize: '96px',
  color: '#AD5E99',
});

const Title = styled('div')({
  fontSize: '36px',
});

const Description = styled('div')({
  fontSize: '16px',
  marginTop: '24px',
});

const NormalRoute = ({ component: Component, path, ...rest }) => {
  useEffect(() => {
    const accessToken = get(rest, 'auth.accessToken');
    if (accessToken) {
      fetchHelper.addDefaultHeader('Authorization', `Bearer ${accessToken}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.auth.accessToken]);
  return (
    <React.Fragment>
      {Component ? (
        <Route path={path} exact component={Component} {...rest} />
      ) : (
        <Route path="*">
          <Wrapper>
            <ErrorCode>404</ErrorCode>
            <Title>Page not found</Title>
            <Description>
              The page you are looking for doesn't exist.
              <br />
              Back to <Link href="/">Home</Link>
            </Description>
          </Wrapper>
        </Route>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    privilege: state.userPermission.privilege,
  };
};
export const WrapperRoute = connect(mapStateToProps)(NormalRoute);
