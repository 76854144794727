import CustomButton from '../CustomButton';
import CustomDate from '../CustomDate';
import CustomModalTitle from './CustomModalTitle';
import { ArrowDown } from '@/assets/svg';
import validateData from '@/helpers/validationHelpers/validationSchema';
import { CustomAutocomplete } from '@/module/company/component/CreateAndEditCompany/styled';
import { OtherDay } from '@/module/setup/createAndEditLocation.styled';
import { dayOfWeeks, getFullTimeInDay } from '@/module/setup/utils';
import CustomRadioBtn from '@/module/telecounsel-appointment-configuration/components/CustomRadioBtn';
import {
  FORMAT_DATE_TIME,
  DAYS_OF_WEEK_TEMPLATE,
  MAX_TIME_PICK,
  MIN_TIME_PICK,
  DAYS_OF_WEEK,
} from '@/module/telecounsel-appointment-configuration/constants';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  TextField,
  withStyles,
} from '@material-ui/core';
import { cloneDeep, findIndex, isEmpty, lowerCase } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 600,
  },
})(Dialog);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end',
  },
})(DialogActions);

const CustomInputLabel = withStyles((theme) => ({
  root: {
    color: '#666666',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}))(InputLabel);

const CustomPaper = withStyles((theme) => ({
  root: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))(Paper);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      padding: '8px 5px',
      fontSize: 16,
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
})(TextField);

const CustomFormHelperText = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'OpenSans',
    fontSize: 12,
    gap: 6,
    marginLeft: 4,
    marginTop: 0,

    '& .MuiSvgIcon-root': {
      fontSize: 'unset',
    },
  },
})(FormHelperText);

const ModalAddTimeslotAppt = ({ open, onClose, onSubmit }) => {
  const [formValue, setFormValue] = useState({
    startDate: null,
    endDate: null,
    option: null,
    startTime: MIN_TIME_PICK,
    endTime: moment().endOf('date'),
    baseSlot: '',
    daysOfWeek: [],
  });
  const [errors, setErrors] = useState({});
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [listDaysOfWeek, setListDaysOfWeek] = useState(DAYS_OF_WEEK_TEMPLATE);
  const [showLoader, setShowLoader] = useState(false);

  const renderDayOfWeek = () => {
    const results = [];
    for (let key in DAYS_OF_WEEK) {
      results.push(key);
    }
    return results;
  };

  const DAY_OF_WEEK = renderDayOfWeek();

  let timeSlotsStartDefault = getFullTimeInDay(0, 24, 15);
  timeSlotsStartDefault.push({
    key: '11:59 PM',
    value: '11:59 PM',
  });

  let timeSlotsEndDefault = getFullTimeInDay(0, 24, 15).filter((obj) =>
    moment(obj.value, 'h:mm A').isAfter(formValue?.startTime)
  );
  timeSlotsEndDefault.push({
    key: '11:59 PM',
    value: '11:59 PM',
  });

  const handleChangeForm = (key) => (e, value) => {
    let newValue = '';

    switch (key) {
      case 'startDate':
        newValue = moment(e).startOf('date');
        break;
      case 'endDate':
        newValue = moment(e).endOf('date');
        break;
      case 'startTime':
        newValue = moment(value.value, 'h:mm A');
        break;
      case 'endTime':
        if (
          moment(value.value, 'h:mm A').format(FORMAT_DATE_TIME) ===
          moment(MIN_TIME_PICK).format(FORMAT_DATE_TIME)
        )
          newValue = MAX_TIME_PICK;
        newValue = moment(value.value, 'h:mm A');
        break;
      case 'baseSlot':
        const regexNumber = /\D/;
        if (e.target.value && !e.target.value.match(regexNumber)) {
          newValue = e.target.value;
        }
        break;
      case 'option':
        newValue = e;
        break;
      default:
        newValue = e.target.value;
        break;
    }

    setFormValue({ ...formValue, [key]: newValue });
  };

  const onChangeDayOfWeeks = (value) => {
    let results = cloneDeep(daysOfWeek);
    let newList = cloneDeep(listDaysOfWeek);
    const indexResults = findIndex(results, (it) => it.value === value.value);

    if (indexResults === -1) {
      results = results.concat(value);
    } else {
      results.splice(indexResults, 1);
    }
    newList = newList.map((item) =>
      value.key === item.key ? { ...item, isChecked: !item.isChecked } : item
    );
    setFormValue({ ...formValue, daysOfWeek: results.map((d) => d.value) });
    setDaysOfWeek(results);
    setListDaysOfWeek(newList);
  };

  const checkIsSelectAll = () => {
    return DAY_OF_WEEK.length === daysOfWeek.length;
  };

  const onSelectAllDayOfWeeks = () => {
    let results = cloneDeep(daysOfWeek);
    let newList = cloneDeep(listDaysOfWeek);

    if (checkIsSelectAll()) {
      results = [];
      newList = newList.map((item) => ({ ...item, isChecked: false }));
    } else {
      results = [...DAY_OF_WEEK];
      newList = newList.map((item) => ({ ...item, isChecked: true }));
    }

    setFormValue({ ...formValue, daysOfWeek: results });
    setDaysOfWeek(
      isEmpty(results)
        ? []
        : results.map((d) => ({
            key: DAYS_OF_WEEK_TEMPLATE.find((it) => it.value === d).key,
            value: d,
          }))
    );
    setListDaysOfWeek(newList);
  };

  const handleSubmit = async () => {
    try {
      await validateData('validationEditBooking', formValue, (validData) => {
        setShowLoader(true);
        onSubmit(validData, () => setShowLoader(false));
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <CustomModalTitle onClose={onClose}>Add timeslots</CustomModalTitle>
      <CustomDialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <CustomInputLabel>Start date</CustomInputLabel>
            <CustomDate
              selectedDate={
                formValue.startDate ? moment(formValue.startDate) : ''
              }
              minDate={moment()}
              maxDate={formValue.endDate}
              isFullWidth
              placeholder="Select date"
              clearable={false}
              onChange={handleChangeForm('startDate')}
              style={{ height: 48, fontSize: 16 }}
            />
            {errors.startDate && (
              <CustomFormHelperText error>
                {errors.startDate}
              </CustomFormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <CustomInputLabel>End date</CustomInputLabel>
            <CustomDate
              selectedDate={formValue.endDate ? moment(formValue.endDate) : ''}
              minDate={formValue.startDate || moment()}
              isFullWidth
              placeholder="Select date"
              clearable={false}
              onChange={handleChangeForm('endDate')}
              style={{ height: 48, fontSize: 16 }}
            />
            {errors.endDate && (
              <CustomFormHelperText error>
                {errors.endDate}
              </CustomFormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <CustomInputLabel>Start time</CustomInputLabel>
            <CustomAutocomplete
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option.value || ''}
              options={timeSlotsStartDefault || []}
              value={
                timeSlotsStartDefault.find(
                  (it) =>
                    it?.value === moment(formValue?.startTime).format('h:mm A')
                ) || ''
              }
              onChange={handleChangeForm('startTime')}
              popupIcon={<ArrowDown />}
              renderInput={(params) => (
                <CustomTextField
                  variant="outlined"
                  placeholder="Select time"
                  {...params}
                />
              )}
              disableClearable
              PaperComponent={({ children, ...other }) => (
                <CustomPaper {...other}>{children}</CustomPaper>
              )}
            />
            {errors.startTime && (
              <CustomFormHelperText error>
                {errors.startTime}
              </CustomFormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <CustomInputLabel>End time</CustomInputLabel>
            <CustomAutocomplete
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option) => option.value || ''}
              options={timeSlotsEndDefault || []}
              value={
                timeSlotsEndDefault.find(
                  (it) =>
                    it?.value === moment(formValue?.endTime).format('h:mm A')
                ) || ''
              }
              onChange={handleChangeForm('endTime')}
              popupIcon={<ArrowDown />}
              renderInput={(params) => (
                <CustomTextField
                  variant="outlined"
                  placeholder="Select time"
                  {...params}
                />
              )}
              disableClearable
              PaperComponent={({ children, ...other }) => (
                <CustomPaper {...other}>{children}</CustomPaper>
              )}
            />
            {errors.endTime && (
              <CustomFormHelperText error>
                {errors.endTime}
              </CustomFormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <CustomInputLabel>Number of slots</CustomInputLabel>
            <CustomTextField
              placeholder="Enter number"
              fullWidth
              variant="outlined"
              type="number"
              allowClear
              value={formValue.baseSlot}
              onChange={handleChangeForm('baseSlot')}
              InputProps={{
                endAdornment: null,
              }}
              error={errors.baseSlot}
              helperText={errors.baseSlot}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Select days of week</CustomInputLabel>
            <Box display="flex" gridGap={8}>
              <OtherDay
                className={checkIsSelectAll() && 'active'}
                onClick={(e) => {
                  e && e.preventDefault();
                  onSelectAllDayOfWeeks();
                }}
              >
                All
              </OtherDay>
              {listDaysOfWeek?.map((item, index) => {
                const indexResults = dayOfWeeks.find(
                  (it) => it.dayOfWeek === index
                );
                return (
                  <OtherDay
                    key={item.key}
                    className={item.isChecked && 'active'}
                    onClick={(e) => {
                      e && e.preventDefault();
                      onChangeDayOfWeeks(item);
                    }}
                    disabled={lowerCase(item.value) === indexResults.label}
                  >
                    {item.key}
                  </OtherDay>
                );
              })}
            </Box>
            {errors.daysOfWeek && (
              <CustomFormHelperText error>
                {errors.daysOfWeek}
              </CustomFormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <CustomInputLabel>Select mode</CustomInputLabel>
            <CustomRadioBtn
              value={formValue.option}
              onChange={handleChangeForm}
            />
            {errors.option && (
              <CustomFormHelperText error>{errors.option}</CustomFormHelperText>
            )}
          </Grid>
        </Grid>
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={showLoader}
        >
          Create
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ModalAddTimeslotAppt;
