import AgoraInstanceTeleconsultNow from '@/agora/agoraInstanceTeleconsultNow';
import { roomStatus } from '@/enum/RoomStatusEnum';
import teleConsultNowDispatcher from '@/module/teleconsult-now/action';

const signalR = require('@aspnet/signalr');
let hubConnection;

// Initial Hub
export const initHubConnection = (token = '') => {
  try {
    teleConsultNowDispatcher.resetData();
    stopConnection();

    hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_WAITING_ROOM_HUB_URL, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => token,
      })
      .build();
    registerEvents();
    hubConnection.start().then(() => joinGroup());
    return hubConnection;
  } catch (err) {
    console.log(err);
  }
};

// Patient Join Group
export const joinGroup = async () => {
  try {
    const rs = hubConnection.invoke('PatientJoinGroup');
    return rs;
  } catch (error) {
    console.log('JoinGroup', error);
  }
};

// Accept Doctor Call
export const joinCall = async () => {
  try {
    const rs = await hubConnection.invoke('AcceptDoctorCall');
    return rs;
  } catch (error) {
    console.log('JoinCall', error);
  }
};

// End Call Doctor
export const endCall = async (doctorId) => {
  try {
    const rs = await hubConnection.invoke('EndCallDoctor', doctorId);
    return rs;
  } catch (error) {
    console.log('EndCall', error);
  }
};

// Rejoin Call
// export const rejoinCall = async () => {
//   try {
//     const rs = await hubConnection.invoke('RejoinCall');
//     return rs;
//   } catch (error) {
//     console.log('RejoinCall', error);
//   }
// };

export const stopConnection = () => {
  try {
    if (hubConnection) hubConnection.stop();
  } catch (err) {
    console.log(err);
  }
};

export const rejoinCall = async (isJoin = true) => {
  try {
    // const reconnectRejoinCall =
    //   configure.store.getState().waitingRoom.reconnectRejoinCall;
    // if (reconnectRejoinCall)
    //   waitingRoomDispatcher.setState('reconnectRejoinCall', false);
    const rs = await hubConnection.invoke('RejoinCall', isJoin);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

// Re-Connected Network
export const tryReconnect = () => {};

export const registerEvents = () => {
  hubConnection.on('NotifyToPatient', (data) => {
    console.log('NotifyToPatient', data);
  });

  hubConnection.on('DoctorNotification', (data) => {
    teleConsultNowDispatcher.setState('StartCallTime', data.StartCallTime);

    try {
      switch (data.Action) {
        case roomStatus.WaitingRejoinCall:
          teleConsultNowDispatcher.setState('rejoinCallData', data);
          rejoinCall();
          break;

        case roomStatus.SuccessRejoinCall:
          teleConsultNowDispatcher.setState(
            'StartCallTime',
            data.StartCallTime
          );

          teleConsultNowDispatcher.setState('channelId', data.ChannelId);
          teleConsultNowDispatcher.setState('callId', data.CallId);
          teleConsultNowDispatcher.setState('calling', true);
          teleConsultNowDispatcher.setState('rejoinCallData', null);
          teleConsultNowDispatcher.setState('callData', data);
          teleConsultNowDispatcher.setState('partner', {
            id: data.DoctorId,
            name: `${data.DoctorFirstName} ${data.DoctorLastName}`,
            imageUrl: null,
          });

          break;
        case roomStatus.Call:
          teleConsultNowDispatcher.setState(
            'StartCallTime',
            data.StartCallTime
          );

          teleConsultNowDispatcher.setState('channelId', data.ChannelId);
          teleConsultNowDispatcher.setState('callId', data.CallId);
          teleConsultNowDispatcher.setState('calling', true);
          teleConsultNowDispatcher.setState('callData', data);
          teleConsultNowDispatcher.setState('partner', {
            id: data.DoctorId,
            name: `${data.DoctorFirstName} ${data.DoctorLastName}`,
            imageUrl: null,
          });

          break;

        case roomStatus.EndCall: {
          AgoraInstanceTeleconsultNow.instance.leaveChannel({
            leaveOnlyShareScreen: false,
          });
          teleConsultNowDispatcher.setState(
            'StartCallTime',
            data.StartCallTime
          );
          teleConsultNowDispatcher.setState('rejoinCallData', null);
          teleConsultNowDispatcher.setState('callId', data.CallId);
          teleConsultNowDispatcher.setState('calling', false);
          teleConsultNowDispatcher.setState('callData', data);
          teleConsultNowDispatcher.setState('completedCallVisibility', true);

          break;
        }

        case roomStatus.ShareScreen: {
          teleConsultNowDispatcher.setState('isSharing', true);
          break;
        }

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  });

  hubConnection.on('NotifyToUser', (data) => {
    console.log('NotifyToUser', data);
  });
};
