import { AppointmentStatusIcon, Location } from '@/assets/svg';
import CustomSelect from '@/components/CustomSelect';
import CustomSelectContainer from '@/components/CustomSelect/CustomSelectContainer';
import MutipleSelection from '@/components/CustomSelect/MutipleSelection';
import { getDateRange } from '@/helpers';
import {
  AllUsersAppointmentStatus,
  AllUsersAppointmentType,
  PAYMENT_SUPPRESSED,
  TeleAppointmentPaymentStatus,
  TeleAppointmentStatus,
  TeleAppointmentType
} from '@/module/appointment-management/constants';
import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import CustomChip from '@/new-components/CustomChip';
import CustomDate, { QUICK_RANGE } from '@/new-components/CustomDate';
import { sortAlphabetically } from '@/utils/sort';
import { Button, Grid, Hidden, withStyles } from '@material-ui/core';
import { ArtTrackOutlined, Autorenew } from '@material-ui/icons';
import { isBoolean, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

const CustomButton = withStyles({
  root: {
    margin: 5,
    paddingLeft: 40,
    paddingRight: 40,

    height: 40,
    minWidth: 160,

    textTransform: 'unset',
    fontWeight: 600,
    borderRadius: 50
  }
})(Button);

const GroupSearch = ({
  userId,
  appointmentTypes,
  setAppointmentTypes,
  filterOption,
  onFilterOptionClear,
  onFilterOptionChange,
  setFilterOption,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onResetFilterOps,
  listLocations = [],
  locationOpen,
  setLocationOpen,
  locationFilter,
  setLocationFilter,
  appointmentStatuses,
  setAppointmentStatuses,
  defaultStartDate = '',
  defaultEndDate = '',
  setPaxScreenPaymentStatus,
  paxScreenPaymentStatus,
  onCreateAppointment,
  paymentSupressed,
  setPaymentSupressed,
  createdStartDate,
  setCreatedStartDate,
  createdEndDate,
  setCreatedEndDate
}) => {
  const AppointmentType = userId
    ? AllUsersAppointmentType
    : TeleAppointmentType;
  const [quickRange, setQuickRange] = useState(null);
  const [open, setOpen] = useState(false);

  const AppointmentStatus = userId
    ? AllUsersAppointmentStatus
    : TeleAppointmentStatus;
  const onChangeStartDate = value => {
    const newStartDate = moment(value).startOf('date');
    setStartDate(newStartDate || defaultStartDate);
    setFilterOption({
      ...filterOption,
      startDate: newStartDate
    });
    setQuickRange(null);
    if (!newStartDate) {
      onChangeEndDate(null);
    }
  };

  const onChangeEndDate = value => {
    const newEndDate = moment(value).endOf('date');
    setEndDate(newEndDate || defaultEndDate);
    setFilterOption({
      ...filterOption,
      endDate: newEndDate
    });
    setQuickRange(null);
  };

  const onChangeCreatedDate = key => value => {
    if (key === 'createdStartDate') {
      setCreatedStartDate(value || '');
      setFilterOption({
        ...filterOption,
        createdStartDate: moment(value).startOf('date')
      });
    } else {
      setCreatedEndDate(value || '');
      setFilterOption({
        ...filterOption,
        createdEndDate: moment(value).endOf('date')
      });
    }
  };

  const onClickQuickRange = rangeType => () => {
    setQuickRange(rangeType);
    let [firstDate, lastDate] = getDateRange(rangeType);
    setStartDate(moment(firstDate));
    setEndDate(moment(lastDate));
    setFilterOption({
      ...filterOption,
      startDate: firstDate,
      endDate: lastDate
    });
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(filterOption);

    return filterKeys.every(key =>
      isBoolean(filterOption[key])
        ? !filterOption[key]
        : isEmpty(filterOption[key])
    );
  }, [filterOption]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginBottom: 16
      }}
    >
      <Grid container spacing={3}>
        <Grid item md={3} sm={6} xs={12}>
          <CustomSelect
            isFullWidth
            onClick={() => {
              setLocationOpen(!locationOpen);
            }}
            clearable={!isEmpty(locationFilter)}
            onClear={() => {
              setLocationFilter([]);
              setFilterOption({
                ...filterOption,
                locationIds: []
              });
            }}
            prefixIcon={
              <Location
                style={{
                  marginRight: 8
                }}
              />
            }
            renderValue={
              <>
                {listLocations
                  .filter(l => locationFilter.includes(l.id))
                  .map(c => c.address)
                  .join(', ') || 'Location'}
              </>
            }
          >
            <MutipleSelection
              value={locationFilter}
              data={sortAlphabetically(listLocations, 'address')}
              open={locationOpen}
              label="address"
              onChange={e => {
                setFilterOption({
                  ...filterOption,
                  locationIds: e.target.value
                });
                setLocationFilter(e.target.value);
              }}
            />
          </CustomSelect>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CustomSelectContainer
            isFullWidth
            filterValue={appointmentStatuses}
            data={AppointmentStatus}
            StartAdornment={AppointmentStatusIcon}
            renderValue={
              <>
                {appointmentStatuses
                  .map(status => {
                    if (status === 'CheckIn') {
                      return 'Checked-In';
                    }
                    return AppointmentStatus[status];
                  })
                  .join(', ') || 'Status'}
              </>
            }
            onClear={onFilterOptionClear(
              'appointmentStatuses',
              setAppointmentStatuses,
              []
            )}
            onChange={onFilterOptionChange(
              'appointmentStatuses',
              setAppointmentStatuses
            )}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CustomSelectContainer
            isFullWidth
            filterValue={paxScreenPaymentStatus}
            data={TeleAppointmentPaymentStatus}
            renderValue={
              <>
                {paxScreenPaymentStatus
                  .map(status => {
                    return TeleAppointmentPaymentStatus[status];
                  })
                  .join(', ') || 'Payment Status'}
              </>
            }
            onClear={onFilterOptionClear(
              'paxScreenPaymentStatus',
              setPaxScreenPaymentStatus,
              []
            )}
            onChange={onFilterOptionChange(
              'paxScreenPaymentStatus',
              setPaxScreenPaymentStatus
            )}
          />
        </Grid>
        <Hidden smDown>
          <Grid item md={3} sm={6} xs={12}></Grid>
        </Hidden>

        <Grid item md={3} sm={6} xs={12}>
          <CustomDate
            {...{
              selectedDate: startDate,
              onChange: onChangeStartDate,
              placeholder: 'Start Date',
              maxDate: endDate ? moment(endDate) : null,
              onClear: () => {
                setStartDate('');
                setEndDate('');
                setQuickRange(null);
                setFilterOption({
                  ...filterOption,
                  startDate: '',
                  endDate: ''
                });
              },
              customRange: (
                <div
                  style={{
                    display: 'flex',
                    padding: 16,
                    maxWidth: 352,
                    flexWrap: 'wrap',
                    borderBottom: '2px solid #ECEDF0'
                  }}
                >
                  {QUICK_RANGE.map(range => (
                    <CustomChip
                      label={range}
                      selectedDate={quickRange}
                      onChange={onClickQuickRange(range)}
                    />
                  ))}
                </div>
              )
            }}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CustomDate
            {...{
              selectedDate: endDate,
              onChange: onChangeEndDate,
              placeholder: 'End Date',
              minDate: startDate ? moment(startDate) : null,
              onClear: () => {
                setEndDate('');
                setQuickRange(null);
                setFilterOption({
                  ...filterOption,
                  endDate: ''
                });
              }
            }}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CustomSelect
            width="100%"
            onClick={() => {
              setOpen(!open);
            }}
            clearable={!isEmpty(paymentSupressed)}
            onClear={() => {
              setPaymentSupressed([]);
              setFilterOption({
                ...filterOption,
                isFreePayment: null
              });
            }}
            prefixIcon={
              <ArtTrackOutlined
                style={{
                  fontSize: 20,
                  color: '#E9E9E9',
                  marginRight: 8
                }}
              />
            }
            renderValue={
              <>
                {PAYMENT_SUPPRESSED.filter(c =>
                  paymentSupressed.includes(c.key)
                )
                  .map(c => c.key)
                  .join(', ') || 'Payment Suppressed'}
              </>
            }
          >
            <MutipleSelection
              isMutiple={false}
              value={paymentSupressed}
              data={PAYMENT_SUPPRESSED}
              open={open}
              onChange={e => {
                setFilterOption({
                  ...filterOption,
                  isFreePayment: true
                });
                setPaymentSupressed(e.target.value);
              }}
            />
          </CustomSelect>
        </Grid>
        <Hidden smDown>
          <Grid item md={3} sm={6} xs={12}></Grid>
        </Hidden>
        <Grid item md={3} sm={6} xs={12}>
          <CustomDate
            {...{
              selectedDate: createdStartDate,
              onChange: onChangeCreatedDate('createdStartDate'),
              placeholder: 'Created Start Date',
              maxDate: createdEndDate ? moment(createdEndDate) : null,
              onClear: () => {
                setCreatedStartDate('');
                setQuickRange(null);
                setFilterOption({
                  ...filterOption,
                  createdStartDate: ''
                });
              }
            }}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CustomDate
            {...{
              selectedDate: createdEndDate,
              onChange: onChangeCreatedDate('createdEndDate'),
              placeholder: 'Created End Date',
              minDate: createdStartDate ? moment(createdStartDate) : null,
              onClear: () => {
                setCreatedEndDate('');
                setQuickRange(null);
                setFilterOption({
                  ...filterOption,
                  createdEndDate: ''
                });
              }
            }}
          />
        </Grid>

        <Grid container justify="flex-end" style={{ marginRight: 16 }}>
          <CustomButton
            onClick={() => {
              onResetFilterOps();
              setQuickRange(null);
            }}
            variant="contained"
            color="primary"
            endIcon={<Autorenew />}
            style={{ borderRadius: 50, margin: 5 }}
            disabled={isEmptyFilterParams}
          >
            Reset Filter
          </CustomButton>

          <CustomButton
            onClick={onCreateAppointment}
            variant="contained"
            color="primary"
          >
            Create New
          </CustomButton>
        </Grid>
      </Grid>
    </div>
  );
};

export const StyledButtonEnhance = styled(ButtonEnhance)`
  max-width: 160px;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  margin: 12px;
  .add-group {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: -2px;
    span {
      margin-right: 8px;
      color: #cacfd3;
    }
  }
`;

export default GroupSearch;
