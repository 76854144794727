/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { DialogContent, Grid } from '@material-ui/core';
import styled from 'styled-components';

import { ClearRounded } from '@material-ui/icons';
import TabsUI from '@/module/common/componentUI/TabsUI';
import GeneralInformation from '@/module/health-profile/component/GeneralInformation';
import TestsInformation from './TestsInformation';
import ProfileInformation from './ProfileInformation';
import { healthProfileDispatcher } from '@/module/health-profile';

const ProfileDetailModal = ({ onClose, selectedProfile = {} }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [detailData, setDetailData] = useState({
    name: '',
    code: '',
    lstTests: [],
    lstOldProfiles: []
  });
  useEffect(() => {
    healthProfileDispatcher.getProfileById(selectedProfile.id, data => {
      setDetailData(data);
    });
  }, []);

  const profileDetailTabs = [
    {
      label: 'General Information',
      body: (
        <GeneralInformation
          price={selectedProfile?.price}
          disabled
          personalInfo={detailData}
        />
      )
    },
    {
      label: 'Tests',
      body: <TestsInformation disabled formValue={detailData} />
    },
    {
      label: 'Profiles',
      body: <ProfileInformation disabled formValue={detailData} />
    }
  ];

  return (
    <ModalPage>
      <DialogContent>
        <div className="test-header">
          {selectedProfile?.name}
          <ClearRounded
            style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
            onClick={onClose}
          />
        </div>
        <TabsUI
          style={{ marginLeft: 16, marginRight: 16 }}
          listTab={profileDetailTabs}
          value={currentTab}
          onChange={(e, val) => setCurrentTab(val)}
          renderLabel="label"
          renderKey="label"
        />
        {profileDetailTabs[currentTab].body}
      </DialogContent>
    </ModalPage>
  );
};

const ModalPage = styled.div`
  width: 1000px;
  height: 85vh;
  hr {
    background: #cacfd3;
    margin: 0;
  }
  .MuiDialogContent-root {
    padding: 0;
    &:first-child {
      padding-top: 0;
    }
  }
  .test-header {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #192637;
  }
  .test-body {
    padding: 0 20px 20px 20px;
  }
`;

export default ProfileDetailModal;
