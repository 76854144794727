const renderColumn = () => {
  let tableComlumn = [
    {
      label: 'Name',
      stateValue: 'name'
    },
    {
      label: 'NRIC',
      stateValue: 'identityNumber'
    },
    {
      label: 'Mobile',
      stateValue: 'mobile'
    },
    {
      label: 'Email',
      stateValue: 'email'
    },
    {
      label: 'Request Date',
      stateValue: 'requestDateTime'
    },
    {
      label: '',
      stateValue: null
    }
  ];

  return tableComlumn;
};

export default renderColumn;
