import React from 'react';
import ServiceManagement from '../../common/ServiceManagement';
import { COLUMN_MEASUREMENT } from '@/module/services/constants';
import MeasurementModalContainer from './MeasurementModalContainer';

const MeasurementManagement = props => (
  <ServiceManagement
    {...{
      ...props,
      prefixName: 'Measurement',
      category: 'Measurements',
      noDataHelperText: 'No measurement',
      noDataHelperSubText: 'Please create a new measurement',
      columns: COLUMN_MEASUREMENT,
      ModalContainer: MeasurementModalContainer,
      requireLabCode: true
    }}
  />
);

export default MeasurementManagement;
