import React from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core';

import { DAYS_PER_WEEK } from '../constants';
import BookingItem from './BookingItem';

const useStyles = makeStyles({
  itemContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box'
  },
  wrapItem: {
    marginBottom: 6
  }
});

const TableBodyComponent = ({
  data,
  keys,
  openEditModal,
  setCurrentBookingItem
}) => {
  const classes = useStyles();

  const renderChildren = (data, key) => {
    const lastIndex = data[key].length - 1;
    const onClickBtnUpdate = dataBooking => {
      openEditModal();
      setCurrentBookingItem(dataBooking);
    };
    return data[key].map((item, index) => {
      if (index === lastIndex) {
        return (
          <BookingItem
            key={item.startDate}
            item={item}
            onClick={() => onClickBtnUpdate(item)}
          />
        );
      }
      return (
        <BookingItem
          key={item.startDate}
          item={item}
          onClick={() => onClickBtnUpdate(item)}
          className={classes.wrapItem}
        />
      );
    });
  };

  const renderColumnByDay = rowData => {
    const arrElements = [
      <TableCell key={rowData.key} component="th" align="center" scope="row">
        {rowData.key}
      </TableCell>
    ];
    for (let i = 0; i < DAYS_PER_WEEK; i += 1) {
      arrElements.push(
        <TableCell align="center" key={keys[i]}>
          <div className={classes.itemContainer}>
            {renderChildren(rowData, keys[i])}
          </div>
        </TableCell>
      );
    }
    return arrElements;
  };

  const renderTableBody = () => {
    if (keys.length > 0) {
      return data.map(row => (
        <TableRow key={row.key}>{renderColumnByDay(row)}</TableRow>
      ));
    }
    return null;
  };

  return <TableBody>{renderTableBody()}</TableBody>;
};

TableBodyComponent.propTypes = {
  data: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  openEditModal: PropTypes.func.isRequired,
  setCurrentBookingItem: PropTypes.func.isRequired
};

export default React.memo(TableBodyComponent);
