import bookingDispatch from '../action';

const initialState = {
  bookings: [],
  headingKeys: [],
  startTime: '',
  endTime: ''
};

const bookingReducer = bookingDispatch(initialState, {
  [bookingDispatch.getDataSuccess]: (state, payload) => {
    const { bookings, keys, startTime, endTime } = payload.data;
    return { ...state, bookings, headingKeys: keys, startTime, endTime };
  },
  [bookingDispatch.updateListBooking]: (state, payload) => {
    const { startDate, isActive, baseSlot } = payload;
    const newBookings = state.bookings.map(item => {
      if (item.startDate === startDate) {
        return { ...item, isActive, baseSlot };
      }
      return item;
    });
    return { ...state, bookings: newBookings };
  }
});

export default bookingReducer;
