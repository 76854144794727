import React, { useState, useEffect } from 'react';
import { DeleteCircleRedIcon, EditGreyIcon } from '@/assets/svg';
import {
  TableHead,
  TableRow,
  TableCell,
  Menu,
  IconButton,
  Dialog,
  Typography,
  Grid,
  Box
} from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import { findIndex } from 'lodash';
import { v4 as uuidV4 } from 'uuid';

import { AddCircle, MoreVertRounded } from '@material-ui/icons';

import TableCellFilted from '@/module/common/componentUI/TableCellFilted';
import {
  FORMAT_DD_MM_YYYY,
  screeningDateColumn,
  COLUMN_SCREENING_DATES
} from '@/module/company/constants';

import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import CustomTooltip from '@/new-components/CustomTooltip';
import ModalShowTimeSlot from '@/module/company/component/ScreeningModal/ModalTimeSlot';
import ServicesTable from '../ServiceItems/ServicesTable';
import AddLocationModal from '@/module/company/component/AddLocationModal';
import CustomMenuActions from '@/new-components/CustomMenuActions';
// import { EditIcon, RemoveIcon } from '@/assets/svg';

export const CustomGridItem = styled.div`
  display: flex;
  align-items: center;
  &.grid-3 {
    flex-grow: 0;
    max-width: 30%;
    flex-basis: 30%;
  }
  &.grid-2 {
    flex-grow: 0;
    max-width: 15%;
    flex-basis: 15%;
    display: flex;
    align-items: center;
  }
  &.grid-1 {
    flex-grow: 0;
    max-width: 10%;
    flex-basis: 10%;
    display: flex;
    align-items: center;
  }
`;

export const LocationsContainer = styled.div`
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  margin-bottom: 40px;
`;

export const LocationsHeader = styled(Grid)`
  background: #f1f1f1;
  font-size: 12px;
  color: #333;
  width: 100%;
  padding: 10px 0 10px 15px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const LocationRow = styled(Grid)`
  padding: 2px 0 2px 15px;
  color: #333;
  font-size: 14px;
  border-bottom: 1px solid #e1e1e1;
  min-height: 30px;
  svg {
    cursor: pointer;
  }
  &:last-child {
    border: none;
  }
  .MuiGrid-item {
    display: flex;
    align-items: center;
  }
`;

export const AddLocation = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 15px;
  color: #ad5e99;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
`;

const formatDateBy = (value, format) => {
  const dateFormat = moment(value).format(format);
  return dateFormat;
};

const transformDateToSendServer = date => {
  return moment(date).toISOString();
};

const ScreeningDates = ({
  data = [],
  submitData,
  minDate,
  maxDate,
  errors,
  listOrganisationCodes
}) => {
  const [hiddenRows, setHiddenRows] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [screenDates, setScreenDates] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentValue, setCurrentValue] = useState(null);
  const [openModalShowTimeSlot, setOpenModalShowTimeSlot] = useState(false);

  const onSaveData = data => {
    const newScreenDates = [...screenDates];

    const idCompare = data.idTemp;

    const index = findIndex(screenDates, date => date.idTemp === idCompare);
    if (index >= 0) {
      newScreenDates[index] = data;
    } else {
      newScreenDates.push(data);
    }
    setScreenDates(newScreenDates);
    handleSubmitData(newScreenDates);
  };
  const removeDataScreeningDate = () => {
    if (!currentValue) return;
    const newScreeningDates = screenDates.filter(
      date => currentValue.idTemp !== date.idTemp
    );
    setScreenDates(newScreeningDates);
    handleSubmitData(newScreeningDates);
  };

  const listActions = [
    {
      key: 'editLocation',
      icon: EditGreyIcon,
      label: 'Edit Location',
      onClick: () => {
        setAnchorEl(null);
        setShowModal(true);
      }
    },
    {
      key: 'delete',
      icon: DeleteCircleRedIcon,
      label: 'Delete',
      color: '#DB3D49',
      onClick: () => {
        setAnchorEl(null);
        removeDataScreeningDate();
        setCurrentValue(null);
      }
    }
  ];

  const handleSubmitData = dataSubmitTransform => {
    const dataSubmit = dataSubmitTransform.map(item => {
      const {
        locationId,
        locationAddress,
        startDate,
        endDate,
        id,
        locationName,
        pmCode
      } = item;
      return {
        id,
        locationId,
        locationAddress,
        locationName,
        startDate: transformDateToSendServer(startDate),
        endDate: transformDateToSendServer(endDate),
        pmCode
      };
    });
    submitData(dataSubmit);
  };

  useEffect(() => {
    if (data) {
      const newData = data.map(date => {
        const { startDate, endDate } = date;
        const startDateUI = moment(startDate);
        const endDateUI = moment(endDate);
        return {
          ...date,
          startDate: startDateUI,
          endDate: endDateUI,
          idTemp: uuidV4()
        };
      });
      setScreenDates(newData);
    }
  }, [data]);

  const renderRow = () => row => (
    <React.Fragment key={row.idTemp || row.id}>
      <TableCell>
        <CustomTooltip content={row.locationName} />
      </TableCell>
      <TableCell>
        <CustomTooltip content={row.locationAddress} />
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        {formatDateBy(row.startDate, FORMAT_DD_MM_YYYY)}
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        {formatDateBy(row.endDate, FORMAT_DD_MM_YYYY)}
      </TableCell>
      <TableCell align="right">
        <IconButton
          className="delete-icon"
          onClick={e => {
            setAnchorEl(e.currentTarget);
            setCurrentValue(row);
          }}
        >
          <MoreVertRounded />
        </IconButton>
      </TableCell>
    </React.Fragment>
  );

  return (
    <Box px={2.25} py={2}>
      <ServicesTable
        title="Screening locations"
        labelButton="Add location"
        columns={COLUMN_SCREENING_DATES}
        renderRow={renderRow(true)}
        data={screenDates}
        onClick={e => {
          e && e.preventDefault();
          setCurrentValue(null);
          setShowModal(!showModal);
        }}
      />

      {errors.lstHealthScreenings && screenDates.length === 0 && (
        <Typography color="error" style={{ paddingTop: 16 }}>
          {errors.lstHealthScreenings}
        </Typography>
      )}
      <Dialog
        maxWidth="sm"
        open={showModal}
        onClose={() => setShowModal(false)}
        disableEnforceFocus
        disableBackdropClick
      >
        <AddLocationModal
          minDate={minDate}
          maxDate={maxDate}
          onSaveData={onSaveData}
          value={currentValue}
          resetCurrentValue={() => setCurrentValue(null)}
          onClose={() => setShowModal(false)}
          listOrganisationCodes={listOrganisationCodes}
        />
      </Dialog>

      <Dialog
        maxWidth="lg"
        open={openModalShowTimeSlot}
        onClose={() => setOpenModalShowTimeSlot(false)}
        disableEnforceFocus
      >
        <ModalShowTimeSlot
          currentValue={currentValue}
          onClose={() => setOpenModalShowTimeSlot(false)}
        />
      </Dialog>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && <CustomMenuActions listActions={listActions} />}
      </Menu>
    </Box>
  );
};

export default ScreeningDates;
