import { Chip, makeStyles } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import classnames from 'classnames';
import React from 'react';

const useStyles = makeStyles({
  chip: {
    border: 'none',
    minWidth: 82,
  },
  icon: {
    width: 10,
    height: 10,
  },
  iconActive: {
    color: '#0b8e5e',
  },
  iconInactive: {
    color: '#666666',
  },
});

const ChipConsultationStatus = ({ isRequired = false }) => {
  const classes = useStyles();
  return (
    <Chip
      icon={
        <FiberManualRecord
          className={classnames(
            classes.icon,
            isRequired ? classes.iconActive : classes.iconInactive
          )}
        />
      }
      label={isRequired ? 'Required' : 'Not required'}
      variant="outlined"
      className={classnames(
        classes.chip,
        isRequired ? classes.iconActive : classes.iconInactive
      )}
    />
  );
};

export default ChipConsultationStatus;
