import motherStatementDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0,
  },
  currentStatement: null,
};

const motherStatementReducer = motherStatementDispatcher(initialState, {
  [motherStatementDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined,
    },
  }),
  [motherStatementDispatcher.saveDataById]: (state, payload) => {
    return { ...state, currentStatement: payload.data };
  },
  [motherStatementDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value,
  }),
});

export default motherStatementReducer;
