import { configure } from '..';
import { roomStatus } from '../enum/RoomStatusEnum';
import minkConfig from '../mink.config.json';
import AgoraInstanceTelecounsel from '@/agora/agoraInstanceTelecounsel';
import { ROLE_TYPES } from '@/constants/roles';
import teleQueueRoomDispatcher from '@/module/telecounsel-queue/action';
import customToast from '@/new-components/CustomNotification';
import { isEmpty, transform, camelCase } from 'lodash';

const signalR = require('@aspnet/signalr');
let hubConnection;
let memberId;

export const initHubConnection = (token = '') => {
  try {
    teleQueueRoomDispatcher.resetState();
    stopConnection();
    hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_WAITING_ROOM_HUB_URL, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => token,
      })
      .build();
    registerEvents();
    hubConnection.start().then(() => joinGroup());
    return hubConnection;
  } catch (err) {
    console.log(err);
  }
};

async function tryReconnect(connection) {
  try {
    // Add triggle to call rejoin connection when reconnecting internet
    teleQueueRoomDispatcher.setState('reconnectRejoinCall', true);
    let rs = await connection.start().then(() => joinGroup());
    console.log('Reconnected successful!');
    return rs;
  } catch (e) {
    await new Promise((resolve) => setTimeout(resolve, 5000));
    return await tryReconnect(connection);
  }
}

export const stopConnection = () => {
  try {
    if (hubConnection) hubConnection.stop();
  } catch (err) {
    console.log(err);
  }
};

export const joinGroup = () => {
  try {
    hubConnection.invoke('DoctorJoinGroup');
  } catch (error) {
    console.log(error);
  }
};

export const registerEvents = () => {
  let stopTimer;
  hubConnection.onclose(function (errors) {
    if (!errors) return;
    const isCalling = configure.store.getState().teleQueueRoom.calling;
    if (isCalling) tryReconnect(hubConnection);
    customToast('error', 'Your connection has been disconnected.');
  });

  hubConnection.on('PatientWaitingList', (list) => {
    try {
      if (isEmpty(list)) return;
      const currentUser = configure.store.getState().auth.userInfo;
      let newList = list?.filter((i) => i.ConsultType === 'TeleCounsel');

      const newWaitingListTelecounsel = newList.filter(
        (i) => i.CounsellorId === currentUser.id
      );

      if (currentUser.roleName === ROLE_TYPES.COUNSELLOR)
        newList = newWaitingListTelecounsel;

      newList.forEach((i) => {
        let data = [i];
        let status = data[0].PatientRoomStatus;

        switch (status) {
          case roomStatus.Join:
            data.map((m) =>
              customToast('info', `${m.FullName} has joined room`)
            );
            teleQueueRoomDispatcher.onPTJoinRoom(data, true);
            break;
          case roomStatus.Leave:
            data.map((m) => customToast('info', `${m.FullName} has left room`));
            teleQueueRoomDispatcher.onPTJoinRoom(
              data.map((d) => d.UserId),
              false
              // requestMemberId === data[0].UserId
            );
            break;
          case roomStatus.Calling:
            data.map((m) =>
              customToast(
                'info',
                `${m.FullName} has picked up by another doctor`
              )
            );
            teleQueueRoomDispatcher.onRemovePT(
              data[0],
              true
              // requestMemberId === data[0].UserId
            );
            break;
          case roomStatus.EndCall: {
            customToast('info', `${data[0].FullName} has ended the consult`);
            AgoraInstanceTelecounsel.instance.leaveChannel({
              leaveOnlyShareScreen: false,
            });
            clearTimeout(stopTimer);
            teleQueueRoomDispatcher.setState('consConfirmVisibility', true);
            teleQueueRoomDispatcher.setState('rejoinCallData', null);
            teleQueueRoomDispatcher.setState('callId', data[0]?.CallId);
            break;
          }

          case roomStatus.ApproveIdentity:
            data.map((m) =>
              customToast('info', `${m.FullName} has been approved`)
            );
            teleQueueRoomDispatcher.onRemovePT(data[0]);
            break;
          case roomStatus.RejectIdentity:
            data.map((m) =>
              customToast('info', `${m.FullName} has been rejected by SO`)
            );
            teleQueueRoomDispatcher.onRemovePT(data[0]);
            break;
          // case roomStatus.AcceptCall:
          //   teleQueueRoomDispatcher.setState('patientId', data[0].UserId);
          //   joinCall(data[0].UserId);
          //   teleQueueRoomDispatcher.onRemovePT(data[0]);
          //   break;
          case roomStatus.RejectCall:
            teleQueueRoomDispatcher.setState('waitingModalVisibility', false);
            customToast('info', `${data[0].FullName} has rejected the consult`);
            break;
          case roomStatus.CancelRejoinCall:
            teleQueueRoomDispatcher.setState('rejoinCallData', null);

            customToast('info', `Your patient has cancelled the consult`);
            clearTimeout(stopTimer);
            AgoraInstanceTelecounsel.instance.leaveChannel({
              leaveOnlyShareScreen: false,
            });
            break;
          case roomStatus.PatientRejoinCall:
            customToast('info', `Your patient has rejoined`);
            clearTimeout(stopTimer);
            break;
          case roomStatus.CallProblem:
            stopTimer = setTimeout(() => {
              customToast(
                'info',
                `The consult was cancelled due to connection problem`
              );
              AgoraInstanceTelecounsel.instance.leaveChannel({
                leaveOnlyShareScreen: false,
              });
            }, minkConfig.PATIENT_REJOIN_TIMEOUT * 1000);
            customToast('info', `CallProblem happen`);
            break;
          default:
            teleQueueRoomDispatcher.onGetWaitingList(data);
            break;
        }
      });
    } catch (err) {
      console.log(err);
    }
  });

  hubConnection.on('CallInformation', (data) => {
    teleQueueRoomDispatcher.setState('startCallTime', data.StartCallTime);
    const currentUser = configure.store.getState().auth.userInfo;
    const accessToken = configure.store.getState().auth.accessToken;
    try {
      switch (data.NotificationType) {
        case roomStatus.WaitingRejoinCall:
          teleQueueRoomDispatcher.setState('rejoinCallData', data);
          break;
        case roomStatus.SuccessRejoinCall:
          teleQueueRoomDispatcher.setState('channelId', data.ChannelId);
          teleQueueRoomDispatcher.setState('callId', data.CallId);
          teleQueueRoomDispatcher.setState('calling', true);
          teleQueueRoomDispatcher.setState('rejoinCallData', null);
          // save memberAppointments
          let memberAppointments = data?.MemberAppointments?.map(
            (upperCaseData) => {
              return transform(upperCaseData, function (result, val, key) {
                result[camelCase(key)] = val;
              });
            }
          );
          teleQueueRoomDispatcher.setState(
            'memberAppointment',
            memberAppointments
          );
          break;
        case roomStatus.PatientUnavailable:
          customToast('info', `Your consult is expired`);
          clearTimeout(stopTimer);
          teleQueueRoomDispatcher.setState('callId', data.CallId);
          teleQueueRoomDispatcher.setState('consConfirmVisibility', true);
          teleQueueRoomDispatcher.setState('rejoinCallData', null);
          break;
        case roomStatus.CallInfo:
          teleQueueRoomDispatcher.setState('callId', data.CallId);
          teleQueueRoomDispatcher.setState('waitingModalVisibility', false);
          teleQueueRoomDispatcher.setState('channelId', data.ChannelId);
          teleQueueRoomDispatcher.setState('calling', true);
          teleQueueRoomDispatcher.onRemovePT(data);
          // bulk selectMemberAppointment
          const memberAppointment =
            configure.store.getState().teleQueueRoom.memberAppointment;
          bulkSelectMemberAppointment(memberAppointment);
          break;

        case roomStatus.PlatoError:
          customToast('error', `The Plato API not responding!`);
          //resset state teleQueueRoom
          // teleQueueRoomDispatcher.resetState();
          AgoraInstanceTelecounsel.instance.leaveChannel({
            leaveOnlyShareScreen: false,
            consConfirmVisibility: false,
          });
          // reconnect hub
          initHubConnection(accessToken);
          break;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  });
};

export const requestCall = async (memberId) => {
  try {
    const rs = await hubConnection.invoke('RequestCallPatient', memberId);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const joinCall = async (channelId) => {
  try {
    memberId = channelId;
    teleQueueRoomDispatcher.setState('patientId', channelId);
    teleQueueRoomDispatcher.onPTJoinRoom([channelId], false);
    const rs = await hubConnection.invoke('CallPatient', memberId);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const rejoinCall = async (isJoin = true) => {
  try {
    const reconnectRejoinCall =
      configure.store.getState().teleQueueRoom.reconnectRejoinCall;
    if (reconnectRejoinCall)
      teleQueueRoomDispatcher.setState('reconnectRejoinCall', false);
    const rs = await hubConnection.invoke('RejoinCall', isJoin);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const shareScreenToPatient = async (streamId = '') => {
  try {
    const memberId = configure.store.getState().teleQueueRoom.patientId;
    let rs = await hubConnection.invoke('ShareScreen', memberId, streamId);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const endCall = async (memberId) => {
  try {
    let rs = await hubConnection.invoke('EndCallPatient', memberId);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const approvePatient = async (memberId) => {
  try {
    let rs = await hubConnection.invoke('NotifyApprovedPatient', memberId);
    customToast('success', 'Approve Successful');
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const rejectPatient = async (memberId) => {
  try {
    let rs = await hubConnection.invoke('RejectPatient', memberId);
    teleQueueRoomDispatcher.onRemovePT(memberId);
    teleQueueRoomDispatcher.setState('consConfirmVisibility', false);
    customToast('success', 'Reject Successful');
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const selectMemberAppointment = async (apptId, isSelected) => {
  try {
    let rs = await hubConnection.invoke('SelectMemberAppt', apptId, isSelected);
    return rs;
  } catch (error) {
    console.log(error);
  }
};

export const bulkSelectMemberAppointment = async (memberAppointment) => {
  try {
    let rs = await Promise.all(
      memberAppointment
        ?.filter((data) => data.isSelected)
        ?.map((data) =>
          selectMemberAppointment(data?.appointmentId, data?.isSelected)
        )
    );
  } catch (error) {
    console.log(error);
  }
};

export const unSelectAlltMemberAppointment = async () => {
  try {
    let rs = await hubConnection.invoke('SelectAllMemberAppts', false);
    return rs;
  } catch (error) {
    console.log(error);
  }
};
