import { getDateRange } from '@/helpers';
import CustomQuickRangeGroup from '@/new-components/CustomChip/CustomGroupChips';
import CustomAdapter from '@/new-components/CustomDate/CustomDateAdapter';
import { CustomWeekStaticDatePicker } from '@/new-components/CustomDate/styled';
import { StyledArrowDown } from '@/new-components/CustomSelect/CustomeSelect.styled';
import {
  Box,
  Popover,
  TextField,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { LocalizationProvider, PickersDay } from '@material-ui/pickers';
import cn from 'classnames';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import React, { useState } from 'react';

export const QUICK_RANGE = ['This week', 'Last week', 'Next week'];

const moment = extendMoment(Moment);

const SelectContainer = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    fontFamily: 'Lato',
    fontSize: 20,
    fontWeight: 600,
  },
})(Box);

const QuickRangeContainer = withStyles({
  root: {
    display: 'flex',
    padding: 16,
    maxWidth: 352,
    flexWrap: 'wrap',
    borderBottom: '2px solid #ECEDF0',
  },
})(Box);

const useStyles = makeStyles((theme) => ({
  highlight: {
    margin: '0 !important',
    width: '47px !important',
    borderRadius: 'unset !important',
    backgroundColor: theme.palette.primary.light,

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },

    '&:focus': {
      backgroundColor: theme.palette.primary.light,
      color: '#333333',
    },

    '& .MuiTouchRipple-root': {
      width: 27,
      left: '50%',
      borderRadius: 4,
      transform: 'translate(-50%)',
    },

    '&.MuiPickersDay-root.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: '#333333',

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.MuiPickersDay-today:not(.Mui-selected)': {
      border: 'unset',

      '& .MuiTouchRipple-root': {
        border: '1px solid rgba(0, 0, 0, 0.54)',
      },
    },
  },
  firstHighlight: {
    background: 'linear-gradient(to right,white 50%,#ffe9fb 50%)',

    '& .MuiPickersDay-dayLabel': {
      zIndex: 1,
      color: theme.palette.common.white,
    },

    '& .MuiTouchRipple-root': {
      borderRadius: 4,

      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#333333',
    },
  },
  endHighlight: {
    background: 'linear-gradient(to left,white 50%,#ffe9fb 50%)',

    '& .MuiPickersDay-dayLabel': {
      zIndex: 1,
      color: theme.palette.common.white,
    },

    '& .MuiTouchRipple-root': {
      borderRadius: 4,

      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#333333',
    },
  },
}));

const CustomWeekPicker = ({ selectedDate, onChange, setWeekPicker }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [quickRange, setQuickRange] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeQuickRange = (event, rangeType) => {
    setQuickRange(rangeType);
    if (rangeType) {
      let [firstDate, lastDate] = getDateRange(rangeType);
      setWeekPicker(moment(firstDate).add('day', 1));
    } else {
      setWeekPicker(moment().startOf('week').add('day', 1));
    }
  };

  const renderWeekPickerDay = (date, _selectedDates, DayComponentProps) => {
    const dateClone = moment(date);
    const selectedDateClone = moment(selectedDate);

    const start = moment(selectedDateClone).startOf('week');
    const end = moment(selectedDateClone).endOf('week').startOf('day');

    const rangeDate = moment().range(start, end);

    const dayIsBetween = rangeDate.contains(dateClone);
    const isFirstDay = moment(dateClone).isSame(start);
    const isLastDay = moment(dateClone).isSame(end);

    return (
      <PickersDay
        {...DayComponentProps}
        // disableMargin
        className={cn({
          [classes.highlight]: dayIsBetween,
          [classes.firstHighlight]: isFirstDay,
          [classes.endHighlight]: isLastDay,
        })}
      />
    );
  };

  return (
    <SelectContainer onClick={handleClick}>
      {selectedDate
        ? typeof selectedDate === 'string'
          ? moment(selectedDate).format('MMMM YYYY')
          : selectedDate?.format('MMMM YYYY')
        : moment().format('MMMM YYYY')}
      <StyledArrowDown />
      <Popover
        id="date-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{
          borderRadius: 12,
          marginTop: 15,
        }}
        PaperProps={{
          style: {
            borderRadius: 12,
            border: '1px solid #E1E1E1',
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <LocalizationProvider dateAdapter={CustomAdapter}>
          <CustomWeekStaticDatePicker
            displayStaticWrapperAs="desktop"
            disableMaskedInput
            showDaysOutsideCurrentMonth
            value={selectedDate}
            renderDay={renderWeekPickerDay}
            renderInput={(props) => <TextField {...props} />}
            onChange={(value) => {
              setQuickRange(null);
              onChange(moment(value).startOf('week').add('day', 1));
            }}
          />

          <QuickRangeContainer>
            <CustomQuickRangeGroup
              value={quickRange}
              handleChange={handleChangeQuickRange}
              listButtons={QUICK_RANGE}
            />
          </QuickRangeContainer>
        </LocalizationProvider>
      </Popover>
    </SelectContainer>
  );
};

export default CustomWeekPicker;
