import { urlLabel } from '@/enum/PermissionEnum';
import AcknowledgeScreen from '@/module/acknowledge-result';
import {
  ForgotPassword,
  InternalLogin,
  InternalRegister,
  Login,
  Register,
  ResetPassword,
  PatientLogin,
} from '@/module/auth';
import PatientForgotPassword from '@/module/auth/component/PatientForgotPassword';
import PatientRegister from '@/module/auth/component/PatientRegister';

const authRoutes = [
  {
    path: `/${urlLabel.login}`,
    component: Login,
    exact: true,
  },
  {
    path: `/${urlLabel.internalLogin}`,
    component: InternalLogin,
    exact: true,
  },
  {
    path: `/${urlLabel.internalRegister}`,
    component: InternalRegister,
    exact: true,
  },
  {
    path: `/${urlLabel.register}`,
    component: Register,
    exact: true,
  },
  {
    path: `/${urlLabel.forgotPassword}`,
    component: ForgotPassword,
    exact: true,
  },
  {
    path: `/${urlLabel.resetPassword}`,
    component: ResetPassword,
    exact: true,
    publicComponent: true,
  },
  {
    path: `/${urlLabel.resetPasswordOldUser}`,
    component: ResetPassword,
    exact: true,
    publicComponent: true,
  },
  {
    path: `/${urlLabel.acknowledgeResult}`,
    component: AcknowledgeScreen,
    exact: true,
    publicComponent: true,
  },

  // Auth Patient
  {
    path: `/${urlLabel.patientLogin}`,
    component: PatientLogin,
    exact: true,
  },
  {
    path: `/${urlLabel.patientRegister}`,
    component: PatientRegister,
    exact: true,
  },
  {
    path: `/${urlLabel.patientForgotPassword}`,
    component: PatientForgotPassword,
    exact: true,
  },
];

export default authRoutes;
