import userPermissionDispatcher from '../action';

const initialState = {
  list: [],
  privilege: [],
  groupedPrivilege: []
};

const userPermissionReducer = userPermissionDispatcher(initialState, {
  [userPermissionDispatcher.getAllPermissionSuccess]: (state, payload) => ({
    list: payload.data
  }),
  [userPermissionDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value
  }),
  [userPermissionDispatcher.getUserPermissionSuccess]: (state, payload) => ({
    privilege: payload.filteredPermission,
    groupedPrivilege: payload.groupedPermission
  }),
  [userPermissionDispatcher.clearPermission]: () => ({ privilege: [] })
});

export default userPermissionReducer;
