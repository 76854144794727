export const COMPANY_TYPE = ['Internal', 'External'];

export const SUB_SERVICES_CONSTANT = {
  test: 'Tests',
  measurements: 'Measurements',
  procedures: 'Procedures',
  radiologies: 'Radiologies',
  vaccinations: 'Vaccinations',
  reviews: 'Reviews'
};

export const SUB_SERVICES = [
  'Tests',
  'Measurements',
  'Procedures',
  'Radiologies',
  'Vaccinations',
  'Reviews'
];

export const LIST_MEASUREMENT = [
  { label: 'BMI - Height (m) & Weight (kg)', value: false },
  { label: 'Blood Pressure', value: false },
  { label: 'Waist Hip Ratio (cm)', value: false },
  { label: 'Fat Analysis (%)', value: false },
  { label: 'Urine Dipstick', value: false },
  { label: 'Visual Acuity', value: false },
  { label: 'Ishihara', value: false },
  { label: 'Waist Circumference (cm)', value: false }
];

export const LIST_FIELD = [
  { label: 'Text', value: 'Text' },
  { label: 'Single Choice', value: 'DropdownList' },
  { label: 'Multiple Choice', value: 'MultiSelect' },
  { label: 'Rating', value: 'Rating' }
];

export const FIELD_ARRAY_VALUE = ['MultiSelect'];

export const FORMAT_DD_MM_YYYY = 'DD/MM/yyyy';
export const FORMAT_TIME = 'HH:mm';
export const FORMAT_TIME_TAMP = 'HH:mm:ss';

export const testColumn = [
  {
    label: 'Test',
    stateValue: 'name'
  },
  {
    label: 'Code',
    stateValue: 'labCode'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  },
  {
    label: 'Value',
    stateValue: 'testValue'
  }
];

export const noneTestColumn = [
  {
    label: 'Test',
    stateValue: 'name'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  },
  {
    label: 'Value',
    stateValue: 'testValue'
  }
];

export const addOnColumn = [
  {
    label: 'Service',
    stateValue: 'name'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  }
];

export const renderTestColumn = ({
  title,
  isUpcoming,
  isCheckIn,
  isInProgress,
  isCompleted,
  isViewDetailsInfo,
  isTestColumn,
  isCanChangeStatus,
  isCheckInLocation,
  isStaffRegister,
  isFollowUp
}) => {
  let columns = isTestColumn ? [...testColumn] : [...noneTestColumn];

  columns = columns.map(item =>
    item.stateValue === 'name' ? { label: title, stateValue: 'name' } : item
  );

  if (isViewDetailsInfo) return columns;
  if (isUpcoming && !isCheckInLocation) {
    columns.pop();
    return columns.concat({
      label: '',
      stateValue: 'action'
    });
  }

  if (isStaffRegister || isCheckInLocation) {
    columns.pop();
    return columns.concat({
      label: '',
      stateValue: 'action'
    });
  }
  if (isInProgress || isCompleted || isCanChangeStatus) {
    return columns.concat(
      { label: 'Optout', stateValue: 'isOptOut' },
      { label: 'Status', stateValue: 'status' },
      {
        label: '',
        stateValue: 'action'
      }
    );
  }
  if (isCheckIn || isCompleted) {
    return columns.concat(
      { label: 'Optout', stateValue: 'isOptOut' },
      { label: 'Status', stateValue: 'status' }
    );
  }
  return columns;
};

export const projectColumn = [
  {
    label: 'Project',
    stateValue: 'project'
  },
  {
    label: 'Code',
    stateValue: 'code'
  },
  {
    label: 'Start date',
    stateValue: 'startTime'
  },
  {
    label: 'End date',
    stateValue: 'endTime'
  },
  {
    label: '',
    stateValue: 'action'
  }
];

export const profileColumn = [
  {
    label: 'Profile',
    stateValue: 'profile'
  },
  {
    label: 'Tests',
    stateValue: 'tests'
  },
  {
    label: 'Profiles',
    stateValue: 'profiles'
  },
  {
    label: 'Price',
    stateValue: 'price'
  }
];

export const renderProfileColumn = isCheckIn => {
  if (isCheckIn)
    return profileColumn.concat({
      label: '',
      stateValue: 'action'
    });
  return profileColumn;
};

export const measurementColumn = [
  {
    label: 'Measurement',
    stateValue: 'measurement'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  }
];

export const renderMeasurementColumn = isCheckIn => {
  if (isCheckIn)
    return measurementColumn.concat({
      label: '',
      stateValue: 'action'
    });
  return measurementColumn;
};

export const procedureColumn = [
  {
    label: 'Procedure',
    stateValue: 'procedure'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  }
];

export const renderProcedureColumn = isCheckIn => {
  if (isCheckIn)
    return procedureColumn.concat({
      label: '',
      stateValue: 'action'
    });
  return procedureColumn;
};

export const radiologyColumn = [
  {
    label: 'Radiology',
    stateValue: 'radiology'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  }
];

export const renderRadiologyColumn = isCheckIn => {
  if (isCheckIn)
    return radiologyColumn.concat({
      label: '',
      stateValue: 'action'
    });
  return radiologyColumn;
};

export const vaccinationColumn = [
  {
    label: 'Vaccination',
    stateValue: 'vaccination'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  }
];

export const renderVaccinationColumn = isCheckIn => {
  if (isCheckIn)
    return vaccinationColumn.concat({
      label: '',
      stateValue: 'action'
    });
  return vaccinationColumn;
};

export const reviewColumn = [
  {
    label: 'Review',
    stateValue: 'review'
  },
  {
    label: 'Station',
    stateValue: 'station'
  },
  {
    label: 'Price',
    stateValue: 'price'
  }
];

export const renderReviewColumn = isCheckIn => {
  if (isCheckIn)
    return reviewColumn.concat({
      label: '',
      stateValue: 'action'
    });
  return reviewColumn;
};

export const staffTypeColumn = [
  {
    label: 'Value',
    stateValue: 'value'
  },
  {
    label: 'Sub-value',
    stateValue: 'subValue'
  }
];

export const categoryColumn = [
  {
    label: 'Custom',
    stateValue: 'category'
  },
  {
    label: 'Type of answer',
    stateValue: 'type'
  },
  {
    label: 'Answer',
    stateValue: 'answer'
  }
];

export const renderCategoriesColumn = isCheckIn => {
  if (isCheckIn)
    return categoryColumn.concat({
      label: '',
      stateValue: 'action'
    });
  return categoryColumn;
};

export const renderStaffTypeColumn = isCheckIn => {
  if (isCheckIn)
    return staffTypeColumn.concat({
      label: '',
      stateValue: 'action'
    });
  return staffTypeColumn;
};

export const packageColumn = [
  {
    label: 'Package',
    stateValue: 'package'
  },
  {
    label: 'Type',
    stateValue: 'type'
  },
  {
    label: 'Price',
    stateValue: 'price'
  }
];

export const renderPackageColumn = isCheckIn => {
  if (isCheckIn)
    return packageColumn.concat({
      label: '',
      stateValue: 'action'
    });
  return packageColumn;
};

export const REQUIRED_FIELD = [
  {
    label: 'General Information',
    field: ['name', 'startDate', 'endDate']
  },
  {
    label: 'Screening Dates',
    field: ['lstHealthScreenings']
  },
  {
    label: 'Tests',
    field: []
  },
  {
    label: 'Profiles',
    field: []
  },
  {
    label: 'Measurements',
    field: []
  },
  {
    label: 'Procedures',
    field: []
  },
  {
    label: 'Radiologies',
    field: []
  },
  {
    label: 'Vaccinations',
    field: []
  },
  {
    label: 'Reviews',
    field: []
  },
  {
    label: 'Packages',
    field: []
  },
  {
    label: 'Staff Types',
    field: ['lstStaffTypeItems', 'staffTypeLabel']
  },
  {
    label: 'Categories',
    field: []
  }
];

export const TABLE_STATE = {
  Outside: 'Outside',
  Modal: 'Modal'
};

export const STAFF_SUBSIDY_TYPE = [
  {
    label: '%',
    value: 'Percent'
  },
  {
    label: '$',
    value: 'Value'
  }
];

export const STATUS_STATION = {
  unAssigned: 'UnAssigned',
  awaiting: 'Awaiting',
  done: 'Done'
};

export const INIT_TESTS = {
  Tests: [],
  Measurements: [],
  Procedures: [],
  Radiologies: [],
  Vaccinations: [],
  Reviews: []
};

export const INIT_FORM_VALUE = {
  lstTests: [],
  lstProfiles: [],
  lstStaffTypeItems: [],
  lstProjectCustomFields: [],
  lstPackages: [],
  lstAddOn: []
};

export const ID_NO_STATION = 'no-station';

export const SUFFIX_LABNUMBER_TYPES = {
  SE_GF_SUFFIX: 'se-gf-suffix',
  UP_SUFFIX: 'up-suffix'
};
