import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import counsellorsManagementDispatcher from '../action';
import AvailabilityStep from './AvailabilityStep';
import CounsellingExperienceStep from './CounsellingExperienceStep';
import GeneralInformationStep from './GeneralInformationStep';
import { CloseIcon, UnavailableIcon } from '@/assets/svg';
import { STEPS_CONTROL } from '@/module/setup/constants';
import customToast from '@/new-components/CustomNotification';
import CustomStepper from '@/new-components/CustomStepper';
import { StepperButton } from '@/new-components/CustomStepper/styled';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import { get, isBoolean, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#333'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles({
  mainWrapper: {
    height: 'calc(100vh - 200px)',
    overflow: 'auto'
  }
});

const CustomDrawerTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

const CustomButton = withStyles({
  root: {
    width: props => (props.width ? props.width : 166),
    height: 48,
    borderRadius: 8,
    margin: props => (props.margin ? props.margin : 'initial'),

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset',
    '&.red': {
      color: '#ffffff',
      background: '#DB3D49'
    }
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const PublicCounsellorProfile = ({ onClose, selectedItem }) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [formValue, setFormValue] = useState({
    qualifications: [],
    firstName: '',
    lastName: '',
    countryCode: '',
    mobile: '',
    email: '',
    position: '',
    introduction: '',
    imageUrl: '',
    yearOfExperience: '',
    language: [],
    treatmentApproach: [],
    speciality: [],
    pricePerHour: 0,
    gender: '',
    availabilities: []
  });

  const handleToggleShowModalError = useCallback(() => {
    setShowModalError(!showModalError);
  }, [showModalError]);

  const handleChangeForm = key => (e, selectVal) => {
    if (!setFormValue) return;
    let value;

    switch (key) {
      case 'imageUrl':
        const FILE_SIZE = 2 * 1024 * 1024; // ~2mb
        if (get(e, 'size') > FILE_SIZE) {
          customToast('error', 'Image is too large');
          return;
        }
        value = e;

        break;

      case 'treatmentApproach':
      case 'speciality':
      case 'yearOfExperience':
        value = selectVal;
        break;
      case 'language':
        value = selectVal;
        break;
      case 'pricePerHour':
        value = e.value;
        break;

      default:
        value = e.target.value;
        break;
    }

    setFormValue(prevState => ({
      ...prevState,
      [key]: value
    }));
  };
  const renderToast = data => {
    return customToast(
      'success',
      <span>
        Public profile of counsellor{' '}
        <strong style={{ fontWeight: 600 }}>{data}</strong> has been
        successfully updated.
      </span>,
      'Public counsellor profile updated'
    );
  };

  const onSubmit = () => {
    const data = {
      ...formValue,
      yearOfExperience: Number(formValue.yearOfExperience),
      treatmentApproach: formValue.treatmentApproach.join(','),
      speciality: formValue.speciality.join(','),
      language: formValue.language.join(','),
      availabilities: formValue.availabilities.map(it => ({
        id: it.id,
        dayOfWeek: it.dayOfWeek,
        startTime:
          typeof it.startTime === 'string'
            ? it.startTime
            : moment(it.startTime).format('HH:mm:ss'),
        endTime:
          typeof it.endTime === 'string'
            ? it.endTime
            : moment(it.endTime).format('HH:mm:ss'),
        isActivated: it.isActivated
      }))
    };

    setLoading(true);
    counsellorsManagementDispatcher.editCounsellor(
      selectedItem.id,
      data,
      result => {
        setLoading(false);

        if (result.isSuccess) {
          renderToast(data.fullName);
          onClose();
        } else {
          setErrorMessage(result.errorMessage);
          handleToggleShowModalError();
        }
      },
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (selectedItem?.id) {
      counsellorsManagementDispatcher.getDetailCounsellor(
        selectedItem.id,
        data => {
          const treatmentApproach = data?.treatmentApproach
            ? data?.treatmentApproach?.split(',')
            : [];
          const speciality = data?.speciality
            ? data?.speciality?.split(',')
            : [];
          const language = data?.language ? data?.language?.split(',') : [];

          setFormValue({
            ...data,
            treatmentApproach,
            speciality,
            language,
            yearOfExperience: String(data.yearOfExperience)
          });
        }
      );
    }
  }, []);

  const renderListTabs = [
    {
      name: 'General information',
      validKey: [
        'firstName',
        'lastName',
        'position',
        'introduction',
        'pricePerHour',
        'gender'
      ],
      component: (
        <GeneralInformationStep
          formValue={formValue}
          setFormValue={setFormValue}
          handleChangeForm={handleChangeForm}
        />
      )
    },
    {
      name: 'Counselling experience',
      validKey: [
        'yearOfExperience',
        'language',
        'treatmentApproach',
        'speciality'
      ],
      component: (
        <CounsellingExperienceStep
          formValue={formValue}
          handleChangeForm={handleChangeForm}
        />
      )
    },
    {
      name: 'Availability',
      component: (
        <AvailabilityStep
          formValue={formValue}
          setFormValue={setFormValue}
          handleChangeForm={handleChangeForm}
        />
      )
    }
  ];

  const isEmptyFilterParams = useMemo(() => {
    if (renderListTabs[currentStep].validKey) {
      const filterKeys = renderListTabs[currentStep].validKey;
      return (
        !formValue['imageUrl'] ||
        filterKeys.some(key =>
          isBoolean(formValue[key]) ? !formValue[key] : isEmpty(formValue[key])
        )
      );
    }
    if (renderListTabs[currentStep].name === 'Availability')
      return formValue['availabilities'].every(d => !d.isActivated);

    return false;
  }, [renderListTabs, currentStep, formValue]);

  const StepperButtons = () => (
    <Box display="flex" justifyContent="flex-end" p={1.25}>
      {currentStep !== 0 && (
        <StepperButton
          className="secondary"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </StepperButton>
      )}

      <StepperButton
        disabled={isEmptyFilterParams || loading}
        onClick={() => {
          if (currentStep === renderListTabs.length - 1) {
            onSubmit(formValue);
          } else setCurrentStep(currentStep + 1);
        }}
      >
        {currentStep === renderListTabs.length - 1 ? 'Save' : 'Next'}
      </StepperButton>
    </Box>
  );

  return (
    <Paper square>
      <CustomDrawerTitle onClose={onClose}>
        Public counsellor profile
      </CustomDrawerTitle>

      <CustomStepper
        steps={renderListTabs}
        stepsControl={STEPS_CONTROL}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        allowSkip
        renderButtons={StepperButtons}
      >
        <main className={classes.mainWrapper}>
          {renderListTabs[currentStep].component}
        </main>
      </CustomStepper>

      {showModalError && !isEmpty(errorMessage) && (
        <ModalConfirmation
          disableBackdropClick
          open={showModalError}
          mainContent="Unable to change"
          Icon={<UnavailableIcon />}
          style={{ padding: '40px 29px' }}
          subContent={<span>{errorMessage}</span>}
          otherActionsContent={() => (
            <>
              <CustomButton
                onClick={handleToggleShowModalError}
                variant="contained"
                color="primary"
                width="100%"
                margin="0 16px"
              >
                Done
              </CustomButton>
            </>
          )}
        />
      )}
    </Paper>
  );
};

export default PublicCounsellorProfile;
