import companyDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  },
  allPackages: []
};

const companyReducer = companyDispatcher(initialState, {
  [companyDispatcher.getCompanySuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [companyDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value
  }),
  [companyDispatcher.getPackageDataSuccess]: (state, payload) => ({
    allPackages: payload.data
  })
});

export default companyReducer;
