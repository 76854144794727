import React from 'react';
import styled from 'styled-components';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Divider from '@material-ui/core/Divider';

const ImageContainer = styled.div`
  height: 60px;
  width: 60px;
  position: relative;
  margin-right: 10px;
  transition: all 1s;

  &:hover .remove-attatchment-button {
    display: initial;
  }

  .remove-attatchment-button {
    display: none;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
  }
`;

const DocumentContainer = styled.div`
  height: 50px;
  position: relative;
  margin-right: 10px;
  transition: all 1s;

  &:hover .remove-attatchment-button {
    display: initial;
  }

  .remove-attatchment-button {
    display: none;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
  }
`;

export const PreSendImage = ({ image, fileSource, removeAttatchment }) => {
  return (
    <ImageContainer>
      <CancelOutlinedIcon
        className="remove-attatchment-button"
        onClick={removeAttatchment}
      />
      <img
        src={fileSource}
        height={60}
        width={60}
        style={{ objectFit: 'cover', borderRadius: 5 }}
        alt={image.name}
      />
    </ImageContainer>
  );
};

export const PreSendDoc = ({ doc, removeAttatchment }) => {
  return (
    <DocumentContainer>
      <div
        style={{
          height: 50,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <CancelOutlinedIcon
          className="remove-attatchment-button"
          onClick={removeAttatchment}
        />
        <div
          style={{
            width: 50,
            backgroundColor: '#bec3c9',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '10px 0px 0px 10px'
          }}
        >
          <DescriptionOutlinedIcon
            style={{ height: 30, width: 30, marginLeft: 10 }}
          />
        </div>
        <Divider orientation="vertical" flexItem />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            borderRadius: '0px 10px 10px 0px',
            padding: '0px 10px',
            borderTop: '1px #bec3c9 solid',
            borderRight: '1px #bec3c9 solid',
            borderBottom: '1px #bec3c9 solid',
            fontSize: 16
          }}
        >
          {doc.name}
        </div>
      </div>
    </DocumentContainer>
  );
};
