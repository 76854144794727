import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

const VideoEnhanced = ({
  display,
  videoUrl,
  onError,
  onReady,
  videoMode,
  isSharing,
  isCurrentHost
}) => {
  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    if (!videoMode || !isCurrentHost || isSharing) setPlaying(false);
  }, [videoMode, isCurrentHost, isSharing]);

  return (
    <ReactPlayer
      playing={playing}
      onPlay={() => setPlaying(true)}
      onPause={() => setPlaying(false)}
      width="100%"
      height="calc(100% - 120px)"
      style={{
        display: display
      }}
      url={videoUrl}
      controls
      onError={onError}
      onReady={onReady}
      config={{
        file: {
          attributes: {
            crossOrigin: 'true',
            id: 'sample_video'
          }
        }
      }}
    />
  );
};

export default VideoEnhanced;
