import CertificateImage from '../../assets/image/certificate-image.png';
import {
  StatusColor,
  DeliveryMethod,
  DeliveryStatus,
  PickUpStatus,
  ConsultStatus,
  ConsultStatusDisplay,
  ConsultType,
} from '../../enum/ConsultEnum';
import { durationFormatted, momentFormatted } from '../../helpers';
import Api from '../../helpers/Api';
import caConsultDispatcher from '../../module/ca-consult/action/caConsult';
import {
  ChipStatus,
  ButtonEnhance,
} from '../../module/common/componentUI/commonStyleComponents';
import CodeInput from '../CodeInput';
import CustomSelect from '../CustomSelect';
import MutipleSelection from '../CustomSelect/MutipleSelection';
import Image from '../Image';
import ImageViewerEnhanced from './ImageViewerEnhanced';
import customToast from '@/new-components/CustomNotification';
import {
  Typography,
  Divider,
  Grid,
  Link,
  MenuItem,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { PrintRounded, CreateRounded } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/ClearRounded';
import { startCase, isEmpty, get, set, defaultTo } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

const titleContainerStyle = {
  height: 60,
  padding: '16px 20px 17px 20px',
  fontSize: 18,
  fontWeight: 600,
  color: '#192637',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px #CACFD3 solid',
};

const bodyContainerStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  padding: '20px 20px 30px 20px',
};

const footerContainerStyle = {
  display: 'flex',
  padding: '11px 20px 10px 0px',
  flexDirection: 'row-reverse',
  borderTop: '1px #CACFD3 solid',
};

const useStyles = makeStyles({
  root: {
    padding: '0px !important',
    minWidth: 800,
  },
});

const PRICE_NUMERAL_FORMAT = '0.0[0]';

const ConsultDetail = ({
  onClose,
  selectedItem,
  isDoctor = false,
  onRefresh,
}) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [reqDeliveryOpen, setReqDeliveryOpen] = useState(false);
  const [deliveryType, setDeliveryType] = useState([]);
  const [consultDetail, setConsultDetail] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [previewSrc, setPreviewSrc] = useState('');
  const [editMode, setEditMode] = useState(false);
  const onChangeEditMode = () => {
    if (editMode) {
      let payload = {
        consultFees: consultDetail.consultFees,
        invoices: consultDetail.invoices,
        discountAmount: consultDetail.discountAmount,
        adjustment: consultDetail.adjustment,
        discountDescription: consultDetail.discountDescription,
        additionalCostDescription: consultDetail.additionalCostDescription,
      };
      if (consultDetail.isChangeTotalCost)
        payload.totalCost = consultDetail.totalCost;
      if (consultDetail.isChangeAdditionCost)
        payload.additionalCost = consultDetail.additionalCost;
      caConsultDispatcher.editConsultPrescription(
        consultDetail.consultId,
        payload,
        () => {
          onRefresh();
          setEditMode(!editMode);
        }
      );
    } else {
      setEditMode(!editMode);
    }
  };

  const openImageViewer = (src) => {
    setPreviewSrc(src);
    setIsViewerOpen(true);
  };

  const closeImageViewer = (src) => {
    setPreviewSrc('');
    setIsViewerOpen(false);
  };
  useEffect(() => {
    getConsultDetail();
    if (selectedItem.consultStatus === ConsultStatus.Paid) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLocalState = (inputOTP) => {
    setConsultDetail({
      ...consultDetail,
      uniqueOtp: inputOTP,
      consultStatus: 'Done',
      pickUpStatus: PickUpStatus.Completed,
      deliveryStatus: DeliveryStatus.Completed,
    });
  };

  const storeTrackingNumer = (data) => {
    setConsultDetail({
      ...consultDetail,
      trackingNumbers: data,
    });
  };

  const getConsultDetail = async () => {
    let api = isDoctor ? 'Doctors/meeting-histories/' : 'Consultations/';
    let { result } = await Api.get(`${api}${selectedItem.id}`);
    result = result || {};

    if (isDoctor)
      setConsultDetail({
        ...result,
        invoices: result.invoices || [],
        ...selectedItem,
        diagnosis: selectedItem.diagnosis || [],
        totalCost: result.totalCost,
      });
    else
      setConsultDetail({
        ...result,
        diagnosis: result.diagnosis || [],
        invoices: result.invoices || [],
        ...selectedItem,
        totalCost: result.totalCost,
      });
  };

  const renderFooter = () => {
    return <ButtonEnhance onClick={onClose}>Close</ButtonEnhance>;
  };

  const onUpdateDeliveryTime = (date) => {
    setConsultDetail({
      ...consultDetail,
      deliveredTime: date,
    });
  };
  const StatusActionMenu = ({ currentStatus, deliveryMethod, children }) => {
    if (isDoctor) return children;
    if (deliveryMethod !== DeliveryMethod.Delivery) return children;
    else if (
      ![DeliveryStatus.AwaitingPickUp, DeliveryStatus.InTransit].includes(
        currentStatus
      )
    )
      return children;
    let options = [];
    if (currentStatus === DeliveryStatus.AwaitingPickUp) {
      options.push({
        key: 'toDelivery',
        label: 'Update Status to ‘In Transit',
        onClick: () => {
          caConsultDispatcher.updateDeliveryStatus(
            consultDetail.medicineDeliveryId,
            {
              deliveryStatus: 'InTransit',
            },
            () => {
              onRefresh();
              setConsultDetail({
                ...consultDetail,
                deliveryStatus: 'InTransit',
              });
            }
          );
        },
      });
    } else if (currentStatus === DeliveryStatus.InTransit) {
      options.push({
        key: 'toDone',
        label: 'Update Status to ‘Completed’',
        onClick: () => {
          if (!consultDetail.deliveredTime) {
            return customToast('error', 'Delivery Time is mandatory');
          }
          caConsultDispatcher.updateDeliveryStatus(
            consultDetail.medicineDeliveryId,
            {
              deliveryStatus: 'Completed',
              deliveredTime: consultDetail.deliveredTime,
            },
            () => {
              onRefresh();
              setConsultDetail({
                ...consultDetail,
                deliveryStatus: DeliveryStatus.Completed,
                deliveredTime: consultDetail.deliveredTime,
                consultStatus: ConsultStatus.Done,
              });
            }
          );
        },
      });
    }
    return (
      <CustomSelect
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
        borderBottom={false}
        arrowStyle={{
          fontSize: '20px !important',
          color: 'black !important',
          marginLeft: 5,
        }}
        renderValue={children}
        width="auto"
      >
        <MutipleSelection
          open={menuOpen}
          value={[]}
          onChange={() => {}}
          asolutePosition={false}
        >
          {options.map((d) => (
            <MenuItem
              key={d.key}
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Source Sans Pro',
                color: '#192637',
              }}
              onClick={d.onClick}
            >
              {d.label}
            </MenuItem>
          ))}
        </MutipleSelection>
      </CustomSelect>
    );
  };
  const formatDeliveryDateTime = (date, time) => {
    if (!date || !time) return '';
    return moment(date).format('DD MMM, YYYY ') + time;
  };

  const deliveredAddressInfo = useMemo(() => {
    if (!consultDetail || !consultDetail?.uniqueOtp) return null;
    let dataArr = [];
    consultDetail.deliveryFloor &&
      consultDetail.deliveryUnitNumber &&
      dataArr.push(
        `#${consultDetail.deliveryFloor}-${consultDetail.deliveryUnitNumber}`
      );

    consultDetail.deliveryAddress &&
      dataArr.push(consultDetail.deliveryAddress);
    consultDetail.deliveryPostalCode &&
      dataArr.push('S' + consultDetail.deliveryPostalCode);
    return dataArr.join(', ');
  }, [consultDetail]);

  const renderDeliveryInfo = () => {
    return (
      <InfoItem label="Delivery">
        <Typography style={{ color: '#8F9BA9' }}>Status:</Typography>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ color: '#AD5E99', fontWeight: 600 }}>
            {consultDetail.deliveryStatuses &&
              consultDetail.deliveryStatuses
                .map((item) => item.status)
                .join(', ')}
          </Typography>
        </div>

        {!isDoctor &&
          [
            ConsultStatus.Paid,
            ConsultStatus.Done,
            ConsultStatus.InDelivery,
          ].includes(consultDetail.consultStatus) &&
          consultDetail.invoices &&
          !!consultDetail.invoices.length && (
            <>
              <Typography style={{ color: '#8F9BA9', marginTop: 10 }}>
                Enter Code To Update Status
              </Typography>
              <CodeInput
                initData={consultDetail.uniqueOtp}
                disabled={consultDetail.uniqueOtp}
                onComplete={(inputOTP, callback) =>
                  caConsultDispatcher.confirmOtp(
                    consultDetail.id,
                    inputOTP,
                    callback
                  )
                }
                onRefresh={(inputOTP) => {
                  onRefresh();
                  updateLocalState(inputOTP);
                }}
              />
            </>
          )}
        {consultDetail.deliveryMethod === DeliveryMethod.Pickup ? (
          <>
            <SubTitle>Pick up location:</SubTitle>
            <Typography>
              {`${consultDetail.clinicName}, ${consultDetail.clinicAddress}, ${consultDetail.clinicUnitNumber}`}
            </Typography>
          </>
        ) : (
          <>
            <SubTitle>Delivered Address</SubTitle>
            <Typography>{deliveredAddressInfo}</Typography>
            <SubTitle>Estimated Delivery Time</SubTitle>

            <Typography>
              {formatDeliveryDateTime(
                consultDetail.estimatedDeliveryDate,
                consultDetail.estimatedDeliveryTime
              )}
            </Typography>

            {!isEmpty(consultDetail.trackingNumbers) && (
              <>
                <SubTitle>Tracking Number</SubTitle>
                <Typography>
                  {consultDetail.trackingNumbers &&
                    consultDetail.trackingNumbers.join(', ')}
                </Typography>
              </>
            )}
            <MutipleSelection
              open={reqDeliveryOpen}
              value={[]}
              asolutePosition={false}
              onClose={() => {
                setReqDeliveryOpen(false);
              }}
              onChange={() => null}
            >
              {deliveryType.map((d) => (
                <MenuItem
                  key={d.key}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontFamily: 'Source Sans Pro',
                    color: '#192637',
                  }}
                  onClick={() => {
                    caConsultDispatcher.updateDeliveryType(
                      consultDetail.medicineDeliveryId,
                      d.id_delivery_type,
                      (result) => {
                        onRefresh();
                        storeTrackingNumer(result);
                      }
                    );
                    setReqDeliveryOpen(false);
                  }}
                >
                  {d.delivery_type}
                </MenuItem>
              ))}
            </MutipleSelection>
          </>
        )}
      </InfoItem>
    );
  };

  const InfoItem = ({ children, label = '' }) => {
    return (
      <tr>
        <td style={{ verticalAlign: 'top', paddingTop: 20, paddingRight: 20 }}>
          <Typography style={{ fontWeight: 600, color: '#192637' }}>
            {label}
          </Typography>
        </td>
        <td
          style={{ display: 'flex', flexDirection: 'column', paddingTop: 20 }}
        >
          {children}
        </td>
      </tr>
    );
  };

  if (!consultDetail) return null;

  const CorporatePlanImages = () => {
    if (consultDetail.backCorperatePlan || consultDetail.frontCorperatePlan)
      return (
        <tr>
          <td
            style={{
              verticalAlign: 'top',
              paddingTop: 20,
              paddingRight: 20,
            }}
          >
            Front of Corporate Plan
            <Image
              onClick={() => openImageViewer(consultDetail.frontCorperatePlan)}
              src={consultDetail.frontCorperatePlan}
              alt="FrontCorperatePlan"
              style={{ borderRadius: 4, width: 180, height: 110 }}
            />
          </td>
          <td
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: 20,
            }}
          >
            Back of Corporate Plan
            <Image
              onClick={() => openImageViewer(consultDetail.backCorperatePlan)}
              src={consultDetail.backCorperatePlan}
              alt="FrontCorperatePlan"
              style={{ borderRadius: 4, width: 180, height: 110 }}
            />
          </td>
        </tr>
      );
    return null;
  };

  const onUpdateFee = (param) => (e) => {
    let payload = {
      ...consultDetail,
    };
    if (param === 'adjustment' && e === undefined) {
      payload.adjustment = -Math.abs(payload.adjustment) + '';
      return setConsultDetail(payload);
    }
    if (param === 'totalCost') payload.isChangeTotalCost = true;
    else if (param === 'additionalCost') payload.isChangeAdditionCost = true;
    let value = e.target.value;
    set(payload, param, value);
    // recalculate totalCost when change other fees
    !['totalCost', 'discountDescription', 'additionalCostDescription'].includes(
      param
    ) && reCalculateTotalCost(payload);
    setConsultDetail(payload);
  };

  const reCalculateTotalCost = (consultDetail) => {
    let fees = defaultTo(get(consultDetail, 'consultFees'), []).reduce(
      (acc, current) => acc + +current.fee,
      0
    );
    let invoices = defaultTo(get(consultDetail, 'invoices'), []).reduce(
      (acc, current) => acc + +current.subTotal,
      0
    );
    let { discountAmount = 0, adjustment, additionalCost } = consultDetail;
    // set new invoices total
    consultDetail.subTotal = numeral(invoices).format(PRICE_NUMERAL_FORMAT);

    let feeBeforeTax = fees + invoices - discountAmount + +additionalCost;
    let tax = (feeBeforeTax * 7) / 100;

    // set new gst
    consultDetail.gst = numeral(tax).format(PRICE_NUMERAL_FORMAT);

    // set new totalCost
    consultDetail.totalCost = numeral(
      fees +
        invoices +
        tax -
        Math.abs(adjustment) -
        discountAmount +
        +additionalCost
    ).format(PRICE_NUMERAL_FORMAT);
  };

  return (
    <>
      <ImageViewerEnhanced
        visible={isViewerOpen}
        disabled={!consultDetail.corperatePlan}
        src={previewSrc}
        onClose={closeImageViewer}
      />
      <DialogContent className={classes.root}>
        <div style={titleContainerStyle}>
          {'Consult Details'}
          <ClearIcon style={{ cursor: 'pointer' }} onClick={onClose} />
        </div>

        <div style={bodyContainerStyle}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <table style={{ width: '100%' }}>
                <tbody>
                  {consultDetail.corperatePlan && (
                    <>
                      <InfoItem label="Corporate Plan">
                        <Typography>{consultDetail.corperatePlan}</Typography>
                      </InfoItem>
                    </>
                  )}
                  <CorporatePlanImages />
                  <InfoItem label="Patient">
                    <Typography>{consultDetail.patientFullName}</Typography>
                    <Typography style={{ color: '#8F9BA9' }}>
                      {consultDetail.patientShortId}
                    </Typography>
                  </InfoItem>
                  <InfoItem label="Doctor">
                    <Typography>{consultDetail.doctorFullName}</Typography>
                  </InfoItem>
                  <InfoItem label="Diagnosis">
                    {consultDetail.diagnosis.map((d) => (
                      <Typography>{d}</Typography>
                    ))}
                  </InfoItem>
                  <InfoItem label="Duration">
                    {durationFormatted(
                      consultDetail.startCallTime,
                      consultDetail.endCallTime
                    )}
                  </InfoItem>
                  <InfoItem label="Date">
                    <Typography>
                      {momentFormatted(
                        consultDetail.consultantDate,
                        'DD MMM, YYYY'
                      )}
                    </Typography>
                  </InfoItem>
                  <InfoItem label="Consult">
                    <Typography>
                      {ConsultType[consultDetail.consultType]}
                    </Typography>
                    <Typography style={{ color: '#8F9BA9' }}>
                      {consultDetail.consultShortId}
                    </Typography>
                  </InfoItem>
                  <InfoItem label="Status">
                    {consultDetail.consultStatus ===
                    ConsultStatusDisplay.Cancelled ? (
                      <ChipStatus
                        color={
                          selectedItem?.cancelledReason ? '#db3d49' : '#8F9BA9'
                        }
                      >
                        {ConsultStatusDisplay[consultDetail.consultStatus] ||
                          'No data'}
                      </ChipStatus>
                    ) : (
                      <ChipStatus
                        color={StatusColor[consultDetail.consultStatus]}
                      >
                        {ConsultStatusDisplay[consultDetail.consultStatus] ||
                          'No data'}
                      </ChipStatus>
                    )}
                  </InfoItem>
                  <InfoItem label="Payment">
                    <Typography>
                      {[
                        consultDetail.payWith,
                        consultDetail.cardNumber
                          ? `(${consultDetail.cardNumber})`
                          : null,
                      ]
                        .filter((i) => i)
                        .join(' ')}
                    </Typography>
                  </InfoItem>
                  <InfoItem label="Remarks">
                    <Typography>
                      {consultDetail.deliveryStatuses &&
                        consultDetail.deliveryStatuses
                          .map((item) => item.remarks)
                          .join(', ')}
                    </Typography>
                  </InfoItem>
                  {consultDetail.promotionCode && (
                    <>
                      <InfoItem label="Promotion">
                        <Typography style={{ color: '#8F9BA9' }}>
                          {consultDetail.promotionCode}
                        </Typography>
                      </InfoItem>
                      <InfoItem label="Promotion Description">
                        <Typography>
                          {consultDetail.promotionDescription}
                        </Typography>
                      </InfoItem>
                    </>
                  )}
                  {renderDeliveryInfo()}
                </tbody>
              </table>
            </Grid>
            <Grid item xs={6}>
              <Invoice
                consultDetail={consultDetail}
                onUpdateFee={onUpdateFee}
                editMode={editMode}
              >
                {selectedItem.consultStatus === ConsultStatus.ProcessSummary &&
                  !isDoctor &&
                  (!editMode ? (
                    <ButtonEnhance
                      onClick={onChangeEditMode}
                      background="#EFDFEB"
                      color="#AD5E99"
                      backgroundHover="#c7b9c3"
                      style={{
                        margin: 0,
                        marginTop: 20,
                        paddingLeft: 0,
                        paddingRight: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CreateRounded />
                      Edit Prescription
                    </ButtonEnhance>
                  ) : (
                    <ButtonEnhance
                      onClick={
                        !!consultDetail.additionalCostDescription ^
                          !!consultDetail.additionalCost ||
                        !!consultDetail.discountDescription ^
                          !!consultDetail.discountAmount
                          ? () => null
                          : onChangeEditMode
                      }
                      style={{
                        margin: 0,
                        marginTop: 20,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      disabled={
                        !!consultDetail.additionalCostDescription ^
                          !!consultDetail.additionalCost ||
                        !!consultDetail.discountDescription ^
                          !!consultDetail.discountAmount
                      }
                    >
                      Done
                    </ButtonEnhance>
                  ))}
              </Invoice>
              {consultDetail.mcLink && (
                <div
                  style={{
                    backgroundImage: `url(${require('../../assets/svg/certificate-bg.svg')})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    padding: 20,
                    position: 'relative',
                  }}
                >
                  <img
                    src={CertificateImage}
                    style={{ visibility: 'hidden', width: '100%' }}
                    alt="abc"
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 20,
                      right: 20,
                      wordBreak: 'break-word',
                    }}
                  >
                    <FeeItem style={{ marginTop: 20 }}>
                      <Typography style={{ color: 'white', fontWeight: 600 }}>
                        Digital MC
                      </Typography>
                      <PrintRounded style={{ color: 'white' }} />
                    </FeeItem>
                    <Link
                      style={{ color: 'white', marginTop: 12 }}
                      target="_blank"
                      href={consultDetail.mcLink}
                    >
                      {consultDetail.mcLink}
                    </Link>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
        <div style={footerContainerStyle}>{renderFooter()}</div>
      </DialogContent>
    </>
  );
};

const Invoice = ({ children, ...props }) => {
  return (
    <div
      style={{
        backgroundColor: '#F7F7F7',
        borderRadius: 10,
        padding: 20,
        marginTop: 20,
        marginBottom: 20,
        width: '100%',
        height: 'fit-content',
      }}
    >
      <ConsultFees {...props} />
      <Prescription {...props} />
      <OtherFees {...props} />
      {children}
    </div>
  );
};

const FeeItem = ({ children, style = {} }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 10,
        alignItems: 'center',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

const ConsultFees = ({ consultDetail, editMode, onUpdateFee }) => {
  let fees = get(consultDetail, 'consultFees', []);
  if (isEmpty(fees)) return null;
  return (
    <>
      {fees.map((fee, index) =>
        !editMode ? (
          <FeeItem key={'consultFees' + index}>
            <Typography>{fee.name}</Typography>
            <Typography style={{ fontWeight: 600 }}>{`$${fee.fee}`}</Typography>
          </FeeItem>
        ) : (
          <FeeItem key={index}>
            <Typography>{fee.name}</Typography>
            <PriceTextField
              value={fee.fee}
              onChange={onUpdateFee(`consultFees[${index}].fee`)}
            />
          </FeeItem>
        )
      )}
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
    </>
  );
};

const Prescription = ({ consultDetail, editMode, onUpdateFee }) => {
  return (
    <>
      <FeeItem>
        <Typography>Prescription</Typography>
        <Typography style={{ fontWeight: 600 }}>
          {`$${consultDetail.subTotal}`}
        </Typography>
      </FeeItem>
      {consultDetail.invoices.map((i, index) => (
        <>
          <FeeItem
            style={{ marginLeft: 20 }}
            key={'prescription-medicineName' + index}
          >
            <Typography>{i.medicineName}</Typography>
            {!editMode ? (
              <Typography style={{ fontWeight: 600 }}>
                {`$${i.subTotal}`}
              </Typography>
            ) : (
              <PriceTextField
                value={i.subTotal}
                onChange={onUpdateFee(`invoices[${index}].subTotal`)}
              />
            )}
          </FeeItem>
          <FeeItem
            style={{ marginTop: 0, marginLeft: 20 }}
            key={'prescription-quantity' + index}
          >
            <Typography style={{ color: '#8F9BA9' }}>
              {`${i.quantity} ${i.unit} - ${i.dosage}`}
            </Typography>
          </FeeItem>
        </>
      ))}
      <Divider style={{ marginBottom: 20, marginTop: 20 }} />
    </>
  );
};
const OtherFees = ({ consultDetail, editMode, onUpdateFee }) => {
  return (
    <>
      {!editMode ? (
        <>
          {consultDetail.coPaymentCost !== null ? (
            <FeeItem style={{ marginTop: 0 }}>
              <DescriptionField
                label="Co-payment"
                isPriceField={false}
                disabled
              />
              <Typography style={{ fontWeight: 600 }}>
                {`$${consultDetail.coPaymentCost || 0}`}
              </Typography>
            </FeeItem>
          ) : null}

          <FeeItem style={{ marginTop: 0 }}>
            <DescriptionField
              label="Deduction"
              isPriceField={false}
              disabled
              value={consultDetail.discountDescription}
            />
            <Typography style={{ fontWeight: 600 }}>
              {`$${consultDetail.discountAmount || 0}`}
            </Typography>
          </FeeItem>
          <FeeItem style={{ marginTop: 0 }}>
            <DescriptionField
              label="Addition"
              disabled
              isPriceField={false}
              value={consultDetail.additionalCostDescription}
            />
            <Typography style={{ fontWeight: 600 }}>
              {`$${consultDetail.additionalCost || 0}`}
            </Typography>
          </FeeItem>
        </>
      ) : (
        <>
          <FeeItem>
            <DescriptionField
              label="Deduction"
              isPriceField={false}
              value={consultDetail.discountDescription}
              onChange={onUpdateFee(`discountDescription`)}
              error={
                !consultDetail.discountDescription &&
                consultDetail.discountAmount
              }
            />

            <PriceTextField
              value={consultDetail.discountAmount}
              onChange={onUpdateFee(`discountAmount`)}
              error={
                consultDetail.discountDescription &&
                !consultDetail.discountAmount
              }
            />
          </FeeItem>
          <FeeItem>
            <DescriptionField
              label="Addition"
              isPriceField={false}
              value={consultDetail.additionalCostDescription}
              onChange={onUpdateFee(`additionalCostDescription`)}
              error={
                !consultDetail.additionalCostDescription &&
                consultDetail.additionalCost
              }
            />
            <PriceTextField
              value={consultDetail.additionalCost}
              onChange={onUpdateFee(`additionalCost`)}
              error={
                consultDetail.additionalCostDescription &&
                !consultDetail.additionalCost
              }
            />
          </FeeItem>
        </>
      )}

      <FeeItem>
        <Typography>GST</Typography>
        <Typography style={{ fontWeight: 600 }}>
          {`$${consultDetail.gst}`}
        </Typography>
      </FeeItem>
      <FeeItem>
        <Typography>Adjustment</Typography>
        {!editMode ? (
          <Typography style={{ fontWeight: 600 }}>
            {`$${consultDetail.adjustment || 0}`}
          </Typography>
        ) : (
          <PriceTextField
            onBlur={() => onUpdateFee(`adjustment`)()}
            value={consultDetail.adjustment}
            onChange={onUpdateFee(`adjustment`)}
          />
        )}
      </FeeItem>
      <FeeItem>
        <Typography>Total Cost</Typography>
        {!editMode ? (
          <Typography style={{ fontWeight: 600 }}>
            {`$${consultDetail.totalCost || 0}`}
          </Typography>
        ) : (
          <PriceTextField
            value={consultDetail.totalCost}
            onChange={onUpdateFee(`totalCost`)}
          />
        )}
      </FeeItem>
    </>
  );
};

const PriceTextField = ({
  value,
  onChange,
  isPriceField = true,
  disabled,
  label,
  error,
  ...restProps
}) => {
  return (
    <TextField
      value={value || ''}
      onChange={onChange}
      label={label}
      variant={disabled ? 'standard' : 'outlined'}
      type={isPriceField ? 'number' : undefined}
      style={{ marginLeft: 10, maxWidth: 100, marginTop: !disabled ? 10 : 0 }}
      disabled={disabled}
      inputProps={{
        style: {
          fontSize: 14,
          fontWeight: 600,
          textAlign: isPriceField ? 'right' : 'initial',
          paddingTop: 8,
          paddingBottom: 8,
          marginLeft: 0,
        },
      }}
      InputProps={
        isPriceField
          ? {
              startAdornment: (
                <InputAdornment position="start" style={{ fontWeight: 600 }}>
                  $
                </InputAdornment>
              ),
            }
          : { disableUnderline: true }
      }
      error={!!error}
      {...restProps}
    />
  );
};

const DescriptionField = ({ value, onChange, disabled, label, error }) => {
  return (
    <StyledLabelTextField
      value={value || ''}
      onChange={onChange}
      label={label}
      style={{ marginTop: !disabled ? 10 : 0 }}
      variant={disabled ? 'standard' : 'outlined'}
      disabled={disabled}
      inputProps={{
        style: {
          fontSize: 14,
          paddingTop: 8,
          paddingBottom: 8,
          marginLeft: 0,
        },
      }}
      InputProps={{ disableUnderline: true }}
      error={!!error}
    />
  );
};
const SubTitle = styled(Typography)`
  color: #8f9ba9;
  margin-top: 10px !important;
`;

const StyledLabelTextField = styled(TextField)`
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(0px, 9px) scale(1);
    pointer-events: none;
  }
  .Mui-disabled {
    color: rgba(0, 0, 0, 0.87);
  }
  label + .MuiInput-formControl {
    margin-top: 0px;
  }
  .MuiInputLabel-formControl {
    top: -16px;
  }
`;
export default ConsultDetail;
