import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, filterOption = {}, sortOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().stuckAccounts.paging;
    let { result, status } = await Api.post(`Patients/stuck-accounts`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption,
      ...sortOption
    });

    status === 200 && stuckAccountsDispatcher.getDataSuccess(result || []);
  },
  resetData: () => ({}),
  getStuckAccountsDetails: (patientId, callback) => async ({ Api }) => {
    const { result, status } = await Api.get(
      `Patients/stuck-accounts/${patientId}`
    );
    status === 200 && callback && callback(result || []);
  }
};

const stuckAccountsDispatcher = synthesize('stuckAccounts', mapDispatchToAC);

export default stuckAccountsDispatcher;
