import React, { useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';
import { ClearRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import {
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputAdornment
} from '@material-ui/core';
import styled from 'styled-components';
import { ButtonEnhance } from '../../common/componentUI/commonStyleComponents';
import { TYPE_MODAL, FORM_VALUE, LIST_REQUIRED } from '../constants';
import LoadingButton from '../../../components/LoadingButton';
import { UploadSingle } from '../../common/component';
import { AddImageOutlineIcon, LinkIcon } from '../../../assets/svg';
import onDemandDispatcher from '../actions/index';
import './styles.scss';
import UploadVideo from '../../../components/UploadVideo';
import { uploadImage } from '../../../firebase';

const OnDemandFormPage = ({
  onDemandDetail,
  typeModal,
  onCloseModal,
  reGetOnDemandList
}) => {
  const {
    loading,
    listCategoryOnDemand,
    listDuration,
    listIntensity
  } = useSelector(state => state.onDemand);

  const [idSelected, setIdSelected] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [formValue, setFormValue] = useState(FORM_VALUE);

  useEffect(() => {
    typeModal === TYPE_MODAL.Edit && getOnDemandById(onDemandDetail.id);
  }, []);
  useEffect(() => {
    if (!isEmpty(onDemandDetail)) {
      setFormValue({
        title: onDemandDetail.title || '',
        thumbnail: onDemandDetail.thumbnail || '',
        price: onDemandDetail.price || 0,
        // video: onDemandDetail.video || null,
        duration: onDemandDetail.duration || '',
        calories: onDemandDetail.calories || '',
        intensity: onDemandDetail.intensityValue || '',
        description: onDemandDetail.description || '',
        equipment: onDemandDetail.equipment || '',
        categoryId: onDemandDetail.categoryId || {},
        vimeoClipId: onDemandDetail.vimeoClipId || ''
      });
      setIdSelected(onDemandDetail.id);
    }
  }, [listIntensity, onDemandDetail]);

  const getOnDemandById = async id => {
    await onDemandDispatcher.getOnDemandById(id, result => {
      setFormValue({ ...result, intensity: result.intensityValue });
    });
  };

  const handleChange = (data, field) => {
    if (field === 'categoryId') {
      const cateSelected = listCategoryOnDemand.find(item => item.id === data);
      setFormValue({
        ...formValue,
        categoryId: data,
        intensity: cateSelected.intensityValue,
        calories: cateSelected.calories,
        description: cateSelected.description,
        equipment: cateSelected.equipment
      });
    } else
      setFormValue({
        ...formValue,
        [field]: data
      });
  };

  const onCheckValidForm = () => {
    let valid = true;
    let errors = {};
    setIsSubmitted(true);
    LIST_REQUIRED.forEach(field => {
      // if (typeModal === TYPE_MODAL.Edit && field === 'video') return;
      if (!formValue[field]) {
        errors = { ...errors, [field]: 'Required' };
        valid = false;
      }
    });
    setErrors(errors);
    return valid;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!onCheckValidForm()) {
      return;
    }

    let formData = new FormData(e.target);
    onDemandDispatcher.showHideLoadingAction({ loading: true });
    //add thumbnail
    if (formValue.thumbnail?.constructor === File) {
      let storageUrl = await uploadImage(formValue.thumbnail);
      formData.set('thumbnail', storageUrl);
    } else formData.set('thumbnail', formValue.thumbnail);
    //use old video if don't edit video
    // if (typeModal === TYPE_MODAL.Edit && !formValue.video)
    //   formData.set('videoUrl', formValue.videoUrl);

    formData.set('calories', get(formValue, 'calories'));
    formData.set('workoutType', 'OnDemand');
    if (idSelected) {
      onDemandDispatcher.editOnDemand(
        {
          id: idSelected,
          data: formData
        },
        () => {
          reGetOnDemandList();
          onCloseModal();
        }
      );
      return;
    }
    onDemandDispatcher.addOnDemand(formData, () => {
      reGetOnDemandList();
      onCloseModal();
    });
  };

  return (
    <form enctype="multipart/form-data" onSubmit={handleSubmit}>
      <HeaderModal>
        <div className="title">
          {typeModal === TYPE_MODAL.Create && 'Create New Workout'}
          {typeModal === TYPE_MODAL.Edit && 'Update Workout'}
        </div>
        <ClearRounded onClick={() => onCloseModal()} className="icon-close" />
      </HeaderModal>

      <DialogContent>
        <WrapperForm>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <UploadSingle
                typeUpload="normal"
                onChange={data => handleChange(data, 'thumbnail')}
                imageUrlSelected={formValue.thumbnail}
                name="thumbnail"
                accept=".png,.jpg,.jpeg"
                uploadButton={
                  <WrapperButtonUpload>
                    <span className="icon">
                      <AddImageOutlineIcon color="#ccc" />
                    </span>{' '}
                    <span className="text">Add Programme Picture</span>
                  </WrapperButtonUpload>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <WrapperInput
                    error={isSubmitted && errors.title ? true : false}
                    variant="standard"
                    value={formValue.title}
                    onChange={e => handleChange(e.target.value, 'title')}
                    label="Workout Name"
                    helperText={isSubmitted && errors.title}
                    name="title"
                  />
                </Grid>

                <Grid item xs={12}>
                  {/* <UploadVideo
                    name="video"
                    label="Upload Video"
                    value={formValue.videoUrl}
                    file={formValue.video}
                    onChange={e => handleChange(e, 'video')}
                    error={isSubmitted && errors.video ? true : false}
                    helperText={isSubmitted && errors.video}
                  /> */}
                  <WrapperInput
                    error={isSubmitted && errors.vimeoClipId ? true : false}
                    value={formValue.vimeoClipId}
                    onChange={e => handleChange(e.target.value, 'vimeoClipId')}
                    label="Vimeo ID"
                    placeholder="Enter Vimeo ID"
                    name="vimeoClipId"
                    helperText={isSubmitted && errors.vimeoClipId}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkIcon width="16px" height="8px" />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              <WrapperFormControl
                error={isSubmitted && errors.categoryId ? true : false}
              >
                <InputLabel id="workout-select-label">Workout Type</InputLabel>
                <WrapperSelect
                  className="select-custom"
                  labelId="workout-select-label"
                  value={formValue.categoryId || {}}
                  name="categoryId"
                  onChange={e => handleChange(e.target.value, 'categoryId')}
                >
                  {listCategoryOnDemand.map((item, index) => (
                    <MenuItem key={item.id + index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </WrapperSelect>
                {isSubmitted && (
                  <FormHelperText>{errors.categoryId}</FormHelperText>
                )}
              </WrapperFormControl>
            </Grid>

            <Grid item xs={6}>
              <WrapperFormControl
                error={isSubmitted && errors.intensity ? true : false}
              >
                <InputLabel id="intensity-label">Intensity</InputLabel>
                <WrapperSelect
                  disabled={true}
                  className="select-custom"
                  labelId="intensity-label"
                  value={formValue.intensity || ''}
                  name="intensity"
                >
                  {listIntensity.map((item, index) => (
                    <MenuItem key={item.value + index} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </WrapperSelect>
                {isSubmitted && (
                  <FormHelperText>{errors.intensity}</FormHelperText>
                )}
              </WrapperFormControl>
            </Grid>

            <Grid item xs={6}>
              <WrapperFormControl
                error={isSubmitted && errors.duration ? true : false}
              >
                <InputLabel id="duration-label">Duration</InputLabel>
                <WrapperSelect
                  className="select-custom"
                  labelId="duration-label"
                  value={formValue.duration || ''}
                  onChange={e => handleChange(e.target.value, 'duration')}
                  name="duration"
                >
                  {listDuration.map((item, index) => (
                    <MenuItem key={item.value + index} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </WrapperSelect>
                {isSubmitted && (
                  <FormHelperText>{errors.duration}</FormHelperText>
                )}
              </WrapperFormControl>
            </Grid>

            <Grid item xs={6}>
              <WrapperInput
                error={isSubmitted && errors.calories ? true : false}
                disabled={true}
                value={formValue.calories}
                name="calories"
                label="Calories"
                helperText={isSubmitted && errors.calories}
              />
            </Grid>

            <Grid item xs={6}>
              <WrapperInput
                error={isSubmitted && errors.equipment ? true : false}
                variant="standard"
                value={formValue.equipment || ''}
                onChange={e => handleChange(e.target.value, 'equipment')}
                name="equipment"
                label="Equipment"
                helperText={isSubmitted && errors.equipment}
              />
            </Grid>

            <Grid item xs={12}>
              <WrapperInput
                error={isSubmitted && errors.description ? true : false}
                variant="standard"
                value={formValue.description}
                onChange={e => handleChange(e.target.value, 'description')}
                name="description"
                label="Description"
                helperText={isSubmitted && errors.description}
              />
            </Grid>
          </Grid>
        </WrapperForm>
      </DialogContent>

      <ModalFooter>
        <ButtonEnhance
          background="#E9E9E9"
          color="black"
          backgroundHover="#ccc"
          onClick={() => onCloseModal()}
        >
          Cancel
        </ButtonEnhance>

        <button className="submit-btn" type="submit">
          <LoadingButton showLoader={loading}>Save</LoadingButton>
        </button>
      </ModalFooter>
    </form>
  );
};

const WrapperButtonUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    color: #ccc;
  }
`;

const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 20px 0px;
  margin-top: 30px;
  .submit-btn {
    border: none;
    border-radius: 35px;
    background: none;
    outline: none;
  }
`;

const WrapperForm = styled.div`
  font-size: 14;
  flex-grow: 1;
  overflow: hidden;
`;
const HeaderModal = styled.div`
  border-bottom: 1px solid rgb(202, 207, 211);
  font-weight: 600;
  color: rgb(25, 38, 55);
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  .icon-close {
    cursor: pointer;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
  }
`;

const WrapperFormControl = styled(FormControl)`
  width: 100%;
`;

const WrapperSelect = styled(Select)`
  width: 100%;
`;
const WrapperInput = styled(TextField)`
  width: 100%;
  label {
    color: #8f9ba9;
  }
`;
export default OnDemandFormPage;
