const renderColumn = () => {
  const tableComlumn = [
    {
      label: 'Code',
      stateValue: 'code'
    },
    {
      label: 'Name',
      stateValue: 'name'
    },
    {
      label: 'Quota',
      stateValue: 'quota'
    },
    {
      label: 'Total Used',
      stateValue: 'totalUsed'
    },
    {
      label: 'Description',
      stateValue: 'description'
    },
    {
      label: 'Start Date',
      stateValue: 'startDate'
    },
    {
      label: 'End Date',
      stateValue: 'endDate'
    },
    {
      label: 'Action',
      stateValue: null
    }
  ];
  return tableComlumn;
};

export default renderColumn;
