import React, { useEffect, useState } from 'react';
import { authDispatcher } from '../auth';
import { Box, Typography, makeStyles } from '@material-ui/core';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import { CloseCircleOutlined } from '@ant-design/icons';

const useStyles = makeStyles({
  title: {
    fontSize: 20,
    letterSpacing: 0,
    fontWeight: 600,
    marginBottom: 8
  },
  successIcon: {
    fontSize: 64,
    color: '#66bb6a',
    marginBottom: 16
  },
  errorIcon: {
    fontSize: 64,
    color: '#eb6c75',
    marginBottom: 16
  }
});

const AcknowledgeScreen = ({ ...props }) => {
  const classes = useStyles();
  const [confirmSuccessfull, setConfirmSuccessfull] = useState();

  const queryParams = new URLSearchParams(props.location.search);
  const token = queryParams.get('token');
  const paxScreeningId = queryParams.get('referenceId');

  useEffect(() => {
    authDispatcher.confirmPatientAcknowledgesResult(
      {
        token,
        paxScreeningId
      },
      isSucces => {
        isSucces
          ? setConfirmSuccessfull('success')
          : setConfirmSuccessfull('failure');
      }
    );
  }, [token, paxScreeningId]);

  return (
    <div>
      {confirmSuccessfull === 'success' && (
        <Box p={3} borderRadius={10} textAlign="center" bgcolor="white">
          <CheckCircleOutlined className={classes.successIcon} />

          <Typography className={classes.title}>
            Follow up Reminder Acknowledged
          </Typography>

          <Typography variant="body2">
            It is important that you reviewed your results with a doctor.
          </Typography>
        </Box>
      )}
      {confirmSuccessfull === 'failure' && (
        <Box p={3} borderRadius={10} textAlign="center" bgcolor="white">
          <CloseCircleOutlined className={classes.errorIcon} />

          <Typography variant="body2">
            Something went wrong. Please check again
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default AcknowledgeScreen;
