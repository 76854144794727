import { StatusComponent } from '../all-screenings/component/StatusComponent';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import telecounselAllCounselsDispatcher from './action';
import GroupToolbar from './component/GroupToolbar';
import {
  ALL_COUNSELS_STATUS,
  COUNSEL_STATUS,
  COUNSEL_DISPLAY_STATUS,
  COUNSEL_STATUS_COLORS,
  renderAllCounselsCol
} from './constants';
import {
  EditIcon,
  ExportIcon,
  EyeIcon,
  MedkitIcon,
  NoDataAllCounsels
} from '@/assets/svg';
import { ROLE_TYPES } from '@/constants/roles';
import {
  durationFormatted,
  formatTotalPrice,
  handleSelectAll,
  momentFormatted
} from '@/helpers';
import CustomMenu from '@/new-components/CustomMenu';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import ModalCounselDetail from '@/new-components/CustomModal/ModalCounselDetail/index';
import ModalEditInvoice from '@/new-components/CustomModal/ModalEditInvoice';
import ModalGenerateInvoice from '@/new-components/CustomModal/ModalGenerateInvoice';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import {
  Button,
  IconButton,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const TelecounselAllCounsels = () => {
  const { list: data, paging } = useSelector(
    state => state.telecounselAllCounsels
  );
  const currentUser = useSelector(state => state.auth.userInfo);
  const isCounsellor = currentUser.roleName === ROLE_TYPES.COUNSELLOR;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});

  const [sortOption, setSortOption] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState({
    consultTypes: ['TeleCounsel'],
    consultStatuses: [
      'InProgress',
      'Cancelled',
      'Done',
      'ReadyToPay',
      'ConsultCompleted'
    ]
  });

  const [counselDate, setCounselDate] = useState([]);
  const [counselStatuses, setCounselStatuses] = useState([]);
  const [counselIds, setCounselIds] = useState([]);

  const [showViewDetailModal, setShowViewDetailModal] = useState(false);
  const [showEditInvoiceModal, setShowEditInvoiceModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFilterOptionClear = (key, defaultValue, setState) => () => {
    setFilterOption({
      ...filterOption,
      [key]: defaultValue
    });

    setState && setState(defaultValue);
  };

  const onFilterOptionChange = (key, setState) => (e, newValue) => {
    let value = e.target.value;

    switch (key) {
      case 'consultStatuses':
        value = handleSelectAll(
          value,
          ALL_COUNSELS_STATUS.map(item => item.key),
          counselStatuses
        );

        break;

      case 'counselIds':
        value = newValue.map(item => item.id);
        setCounselIds(value);
        break;
      default:
        break;
    }

    setFilterOption({
      ...filterOption,
      [key]: value
    });

    setState && setState(value);
  };

  const onResetFilterOps = () => {
    setCounselDate([]);
    setCounselStatuses([]);
    setCounselIds([]);
    setFilterOption({
      consultTypes: ['TeleCounsel'],
      consultStatuses: [
        'InProgress',
        'Cancelled',
        'Done',
        'ReadyToPay',
        'ConsultCompleted'
      ]
    });
  };

  const onExportExcel = () => {
    if (data.length === 0) {
      return customToast(
        'error',
        'There is no data available to export the excel file'
      );
    }

    telecounselAllCounselsDispatcher.getExportExcel(searchKey, filterOption);
  };

  const onSubmitEditInvoice = (id, data) => {
    setLoading(true);
    telecounselAllCounselsDispatcher.editInvoice(id, data, () => {
      setLoading(false);
      setShowEditInvoiceModal(false);
      fetchData(searchKey, paging, sortOption, filterOption);
    });
  };

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    sortOptionData = sortOption,
    filterOptionData = filterOption
  ) => {
    telecounselAllCounselsDispatcher.getData(
      search,
      pagingData,
      ...[sortOptionData, filterOptionData].filter(i => i)
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(searchKey, paging, sortOption, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, sortOption, filterOption]);

  const renderListActions = ({ consultStatus, duration }) => {
    let listActions = [
      {
        key: 'view-counsel-detail',
        icon: EyeIcon,
        label: 'View details',
        onClick: () => {
          setAnchorEl(null);
          setShowViewDetailModal(true);
        }
      }
    ];

    if (!isCounsellor && consultStatus === COUNSEL_STATUS.ConsultCompleted) {
      listActions.push({
        key: 'edit-invoice',
        icon: EditIcon,
        label: 'Update invoice',
        onClick: () => {
          setAnchorEl(null);
          setShowEditInvoiceModal(true);
        },
        styles: { width: 20, height: 20 }
      });

      if (duration && duration !== 0)
        listActions.push({
          key: 'process-summary',
          icon: MedkitIcon,
          label: 'Process summary',
          onClick: () => {
            setAnchorEl(null);
            telecounselAllCounselsDispatcher.processSummary(
              selectedItem.consultId,
              () => {
                fetchData(searchKey, paging, sortOption, filterOption);
              }
            );
          },
          styles: { width: 14, height: 14 }
        });
    }

    return listActions;
  };

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {renderAllCounselsCol(isCounsellor).map(item =>
          item.sortBy ? (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              sortBy="consultantDate"
              sortOption={sortOption}
              onSortChange={() => {
                let newSortOption = {
                  by: 'Date',
                  dir: sortOption.dir !== 'asc' ? 'asc' : 'desc'
                };
                setSortOption(newSortOption);
              }}
              style={{
                minWidth: item.minWidth || 'unset',
                width: item.width || 'unset'
              }}
              isHidden={false}
            />
          ) : (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              style={{
                minWidth: item.minWidth || 'unset',
                width: item.width || 'unset'
              }}
              isHidden={false}
            />
          )
        )}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = row => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.patientFullName || '—'} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.patientShortId} />
        </TableCell>
        <TableCell>{row.counsellingType || '—'}</TableCell>
        {!isCounsellor && (
          <TableCell>
            <CustomTooltip content={row.doctorFullName || '—'} />
          </TableCell>
        )}

        <TableCell>
          {momentFormatted(row.consultantDate, 'DD/MM/YYYY  HH:mm A')}
        </TableCell>
        <TableCell>
          {row.startCallTime && row.endCallTime
            ? durationFormatted(row.startCallTime, row.endCallTime)
            : '—'}
        </TableCell>
        <TableCell>{formatTotalPrice(row.totalCost)}</TableCell>

        <TableCell>
          <StatusComponent
            status={row.consultStatus}
            filterList={COUNSEL_DISPLAY_STATUS}
            filterListColor={COUNSEL_STATUS_COLORS}
          />
        </TableCell>
        <TableCell>
          <IconButton
            onClick={e => {
              setAnchorEl(e.currentTarget);
              setSelectedItem(row);
            }}
          >
            <MoreVertRounded />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <CustomMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <CustomMenuActions listActions={renderListActions(selectedItem)} />
      </CustomMenu>
      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder="Search by client name or ID number..."
            title="All counsellings"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            sortOption={sortOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                filterOption={filterOption}
                setFilterOption={setFilterOption}
                onFilterOptionClear={onFilterOptionClear}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
                counselDate={counselDate}
                setCounselDate={setCounselDate}
                counselStatuses={counselStatuses}
                setCounselStatuses={setCounselStatuses}
                counselIds={counselIds}
                setCounselIds={setCounselIds}
                isCounsellor={currentUser.roleName === ROLE_TYPES.COUNSELLOR}
              />
            )}
            renderButton={() => (
              <Button
                color="primary"
                variant="contained"
                startIcon={<ExportIcon width={20} height={20} />}
                onClick={onExportExcel}
              >
                Export
              </Button>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          noDataHelperText="No counsellings"
          noDataHelperSubText="Please come back at another time."
          Icon={NoDataAllCounsels}
          totalCols={renderAllCounselsCol(isCounsellor).length}
          fetchData={fetchData}
        />
      </CustomPaperContainer>

      {showViewDetailModal && (
        <ModalCounselDetail
          open={showViewDetailModal}
          onClose={() => setShowViewDetailModal(false)}
          selectedItem={selectedItem}
        />
      )}
      {/* {showEditInvoiceModal && (
        <ModalEditInvoice
          open={showEditInvoiceModal}
          onClose={() => setShowEditInvoiceModal(false)}
          selectedItem={selectedItem}
          onSubmit={onSubmitEditInvoice}
        />
      )} */}
      {showEditInvoiceModal && (
        <ModalGenerateInvoice
          counsellorData={currentUser}
          selectedItem={selectedItem}
          open={showEditInvoiceModal}
          onClose={() => setShowEditInvoiceModal(false)}
          onSubmit={onSubmitEditInvoice}
        />
      )}
    </>
  );
};

export default TelecounselAllCounsels;
