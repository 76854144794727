import React, { useState } from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import RoleCheckRow from './RoleCheckRow';

const CollapseRow = ({ row, onChange, hiddenRows, depthLevel, onCollapse }) => {
  const renderTableCollapseRow = (row, depthLevel) => {
    depthLevel = depthLevel + 1;

    const diffColor = 10 * depthLevel;
    return (
      <>
        <TableRow hover>
          <TableCell
            className="menu-item"
            style={{
              backgroundColor: `rgb( ${213 + diffColor}, ${214 +
                diffColor}, ${217 + diffColor})`,
              paddingLeft: 16 * depthLevel
            }}
          >
            {!hiddenRows.menuItem && (
              <>
                <span>{row.title}</span>
                {!row.isHidden && (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      onCollapse(row);
                    }}
                  >
                    {row.isOpen ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )}
              </>
            )}
          </TableCell>
          <RoleCheckRow
            row={row}
            onChange={(e, role, index) => onChange(e, row, 'parent', index)}
            isParent
            hiddenRows={hiddenRows}
            depthLevel={depthLevel}
          />
        </TableRow>
        {!row.isHidden &&
          row.isOpen &&
          row.child.map((subRowChild, childPosition) =>
            subRowChild.child ? (
              renderTableCollapseRow(subRowChild, depthLevel)
            ) : (
              <TableRow hover key={subRowChild.privilege}>
                <TableCell
                  className="menu-item child-item"
                  style={{
                    backgroundColor: `rgb( ${223 + diffColor}, ${224 +
                      diffColor}, ${227 + diffColor})`,
                    paddingLeft: 16 + 16 * depthLevel
                  }}
                >
                  {!hiddenRows.menuItem && subRowChild.title}
                </TableCell>
                <RoleCheckRow
                  row={subRowChild}
                  isParentPermission={true}
                  hiddenRows={hiddenRows}
                  onChange={(e, item, index) => {
                    onChange(e, item, 'normal', index, true, childPosition);
                  }}
                />
              </TableRow>
            )
          )}
      </>
    );
  };

  return (
    <React.Fragment>{renderTableCollapseRow(row, depthLevel)}</React.Fragment>
  );
};

export default CollapseRow;
