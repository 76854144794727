import { synthesize } from 'redux-dispatcher';
import customToast from '@/new-components/CustomNotification';
import { cleanQuestionnaires } from './../utils';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  saveDataById: data => ({ data }),
  getDataById: (id, cb) => async ({ Api }) => {
    try {
      const response = await Api.get(`ScreeningQuestionnaires/${id}`);
      if (response.status === 200) {
        questionnairesDispatcher.saveDataById(
          cleanQuestionnaires(response.result)
        );
      }
      cb();
    } catch (error) {
      console.log('Error: ', error);
      cb();
    }
  },
  getData: (search, paging, filterOption = {}) => async ({ Api, getState }) => {
    try {
      const pagingData = paging || getState().screeningQuestionnaires.paging;
      const { result, status } = await Api.get(
        'ScreeningQuestionnaires/filter',
        {
          pageIndex: pagingData.pageIndex,
          pageSize: pagingData.pageSize,
          search,
          ...filterOption
        }
      );

      if (status === 200 && Array.isArray(result.list)) {
        const { list, ...restParams } = result;
        const baseIndex = pagingData.pageIndex * pagingData.pageSize + 1;
        const dataAddIndex = list.map((item, index) => ({
          ...item,
          index: baseIndex + index
        }));
        questionnairesDispatcher.getDataSuccess({
          ...restParams,
          list: dataAddIndex
        });
      } else {
        customToast('error', 'Fetch data fail');
      }
    } catch (error) {
      customToast('error', 'Fetch data fail');
    }
  },
  editData: (data, onSuccess, onError, onFinish) => async ({
    Api,
    customToast
  }) => {
    try {
      let response;
      if (data.id) {
        response = await Api.put(`ScreeningQuestionnaires/${data.id}`, data);
      } else {
        response = await Api.post('ScreeningQuestionnaires', data);
      }

      if (response.status === 200) {
        onSuccess();
      } else {
        onError();
      }
      onFinish();
    } catch (error) {
      onError();
      onFinish();
    }
  },
  deleteData: (id, onSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.delete(`ScreeningQuestionnaires/${id}`);
    if (status === 200) {
      customToast('success', 'Deleted question');
      onSuccess && onSuccess();
    }
  }
};

const questionnairesDispatcher = synthesize(
  'screeningQuestionnaires',
  mapDispatchToAC
);
export default questionnairesDispatcher;
