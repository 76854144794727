import { Button, lighten, withStyles } from '@material-ui/core';

const CustomButton = withStyles((theme) => ({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    fontSize: 18,
    fontFamily: 'OpenSans',
    fontWeight: 400,
    textTransform: 'unset',
  },
  containedPrimary: {
    boxShadow: 'none',
  },
  containedError: {
    boxShadow: 'none',

    backgroundColor: theme.palette.feedback.error,
    color: theme.palette.general.white,

    '&:hover': {
      backgroundColor: lighten(theme.palette.feedback.error, 0.3),
    },
  },
}))(Button);

export default CustomButton;
