/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  TextField,
  ClickAwayListener,
  Grid,
  withStyles
} from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import { get } from 'lodash';

import moment from 'moment';
import { ArrowDown } from '@/assets/svg';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const FormTimePicker = ({
  label,
  onChange,
  value,
  error,
  variant,
  ampm = true,
  views,
  disabled,
  minutesStep = 1,
  minTime,
  maxTime,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [valuePicker, setValuePicker] = useState(value);

  const onChangeTimePicker = value => {
    setValuePicker(value);
  };

  const transformToSecondCompare = value => {
    if (!value) return 0;
    const hours = moment(value).get('hour');
    const minutes = moment(value).get('minutes');
    const seconds = moment(value).get('second');
    return hours * 3600 + minutes * 60 + seconds;
  };

  useEffect(() => {
    if (!open) {
      const minTimeTransform = transformToSecondCompare(minTime);
      const maxTimeTransform = transformToSecondCompare(maxTime);
      const valuePickerTransform = transformToSecondCompare(valuePicker);
      if (minTime && maxTime) {
        if (
          valuePickerTransform >= minTimeTransform &&
          maxTimeTransform >= valuePickerTransform
        ) {
          onChange(valuePicker);
        }
      } else if (minTime) {
        if (valuePickerTransform >= minTimeTransform) {
          onChange(valuePicker);
        }
      } else if (maxTime) {
        if (maxTimeTransform >= valuePickerTransform) {
          onChange(valuePicker);
        }
      } else if (!minTime && !maxTime) {
        onChange(valuePicker);
      }
    }
  }, [open]);

  const renderChildren = () => {
    const children = (
      <TimePicker
        open={open}
        disabled={disabled}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        minTime={minTime}
        maxTime={maxTime}
        label={label}
        ampm={ampm}
        views={views || ['hours', 'minutes']}
        value={value}
        onChange={onChangeTimePicker}
        minutesStep={minutesStep}
        openPickerIcon={<ArrowDown />}
        renderInput={props => (
          <CustomTextField
            fullWidth
            variant={variant || 'standard'}
            {...props}
            error={!!error}
            helperText={!variant && error}
            value={get(props, 'inputProps.value', '')}
            inputProps={{ readOnly: true }}
          />
        )}
        {...props}
      />
    );

    return (
      <Grid item xs={12}>
        {children}
      </Grid>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      {renderChildren()}
    </ClickAwayListener>
  );
};

export default FormTimePicker;
