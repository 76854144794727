export const CONTENT_NO_CORPORATE = [
  {
    label: '7:00 AM - 8:59 PM',
    value: '$21.80',
  },
  {
    label: '9:00 PM - 6:59 AM',
    value: '$43.60',
  },
];

export const CONTENT_DEFAULT = [
  {
    label: 'Medication fee',
    value: 'To be advised',
  },
  {
    label: 'Delivery fee',
    value: 'Included',
  },
];
