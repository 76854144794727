import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';

import { handleSelectAll } from '@/helpers';
import NumberFormat from 'react-number-format';
import { isEmpty } from 'lodash';
import { DEFAULT_COMPULSORY_TAGS, HS_SERVICE_TYPES } from '../../constants';
import { Editor } from '@tinymce/tinymce-react';
import {
  TINY_API_KEY,
  TINY_INIT_CONFIGURATION
} from '@/constants/editorConfig';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 4,
    color: '#666666'
  }
})(Typography);

const GeneralInformationStep = ({
  errors,
  formValue,
  setFormValue,
  listTags
}) => {
  const compulsoryTag = useMemo(() => {
    let list = [];
    if (!isEmpty(listTags)) {
      list = listTags.filter(spec =>
        DEFAULT_COMPULSORY_TAGS.some(el => spec.includes(el))
      );
    }
    return list;
  }, [listTags]);

  const optionalTags = useMemo(() => {
    let list = [];
    if (!isEmpty(listTags)) {
      list = listTags.filter(
        spec => !DEFAULT_COMPULSORY_TAGS.some(el => spec.includes(el))
      );
    }
    return list;
  }, [listTags]);

  const handleChange = key => e => {
    if (!setFormValue) return;

    let value;
    switch (key) {
      case 'description':
      case 'shortDesc':
        value = e;
        break;
      case 'optionalTags':
        value = handleSelectAll(
          e.target.value,
          optionalTags,
          formValue?.optionalTags
        );

        break;
      case 'price':
        value = e.value;
        break;
      default:
        value = e.target.value;
        break;
    }

    setFormValue(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  return (
    <Box px={2.25} py={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomTitleLabel>Package name</CustomTitleLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter name"
            error={errors.name}
            helperText={errors.name}
            value={formValue.name || ''}
            onChange={handleChange('name')}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTitleLabel>Service type</CustomTitleLabel>
          <CustomSelectContainer
            isFullWidth
            filterValue={formValue.packageType || []}
            data={HS_SERVICE_TYPES}
            renderValue={<>{formValue.packageType || 'Select type'}</>}
            height="48px"
            fontSize="16px"
            isMutiple={false}
            onChange={handleChange('packageType')}
          />
          {errors.packageType && (
            <Typography color="error">{errors.packageType}</Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <CustomTitleLabel>Test code</CustomTitleLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter code"
            error={errors.code}
            helperText={errors.code}
            value={formValue.code || ''}
            onChange={handleChange('code')}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomTitleLabel>Compulsory tag</CustomTitleLabel>
          <CustomSelectContainer
            isFullWidth
            filterValue={formValue.compulsoryTag || []}
            data={compulsoryTag}
            renderValue={<>{formValue.compulsoryTag || 'Select tag'}</>}
            height="48px"
            fontSize="16px"
            isMutiple={false}
            onChange={handleChange('compulsoryTag')}
            onClear={() => {
              setFormValue({ ...formValue, compulsoryTag: '' });
            }}
          />
          {errors.tags && <Typography color="error">{errors.tags}</Typography>}
        </Grid>
        <Grid item xs={6}>
          <CustomTitleLabel>Optional tags</CustomTitleLabel>
          <CustomSelectContainer
            isFullWidth
            filterValue={formValue?.optionalTags || []}
            data={optionalTags}
            renderValue={
              <>{formValue?.optionalTags?.join(', ') || 'Select tag'}</>
            }
            height="48px"
            fontSize="16px"
            onChange={handleChange('optionalTags')}
            onClear={() =>
              setFormValue({
                ...formValue,
                optionalTags: []
              })
            }
            useSelectAll
            checkedAll={
              optionalTags?.length === formValue?.optionalTags?.length
            }
          />

          {errors.optionalTags && (
            <Typography color="error">{errors.optionalTags}</Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <CustomTitleLabel>Default price</CustomTitleLabel>
          <NumberFormat
            isFullWidth
            customInput={CustomTextField}
            variant="outlined"
            placeholder="Enter number"
            error={errors.price}
            helperText={errors.price}
            prefix="$ "
            thousandSeparator=" "
            onValueChange={handleChange('price')}
            allowNegative={false}
            decimalScale={2}
            value={formValue.price || ''}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTitleLabel>Short description</CustomTitleLabel>
          <Editor
            apiKey={TINY_API_KEY}
            init={TINY_INIT_CONFIGURATION}
            value={formValue?.shortDesc || ''}
            onEditorChange={handleChange('shortDesc')}
            cloudChannel="stable"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTitleLabel>Long description</CustomTitleLabel>
          <Editor
            apiKey={TINY_API_KEY}
            init={TINY_INIT_CONFIGURATION}
            value={formValue?.description || ''}
            onEditorChange={handleChange('description')}
            cloudChannel="stable"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralInformationStep;
