import React from 'react';
import { Grid } from '@material-ui/core';
import { get, startCase, upperCase, isEmpty } from 'lodash';

import DividerHeader from '@/module/common/componentUI/DividerHeader';
import { WrappedItem, RowItem } from './CommonStyles';

const MedicalData = ({ data = {}, title, classes }) => {
  const renderData = () => {
    const result = [];
    for (let name in data) {
      if (name !== 'caName') {
        const rowItem = (
          <RowItem key={name}>
            <span className={classes.title}>{startCase(name)}:</span>
            <span>{startCase(get(data, name))}</span>
          </RowItem>
        );
        result.push(rowItem);
      }
    }
    return result;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DividerHeader title={upperCase(title)} />
        </Grid>
        {!isEmpty(data) && (
          <Grid item xs={6}>
            <WrappedItem>{renderData()}</WrappedItem>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MedicalData;
