export const COLUMN_EORDER_LIST = [
  {
    label: 'Project',
    stateValue: 'project'
  },
  {
    label: 'Location',
    stateValue: 'location'
  },
  {
    label: 'Date',
    stateValue: 'date'
  },
  {
    label: 'Order count',
    stateValue: 'order'
  },
  {
    label: 'Action',
    width: 80
  }
];
