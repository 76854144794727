import {
  HS_PAXSCREENING_DISPLAY_STATUS,
  HS_PAXSCREENING_STATUS_COLORS,
} from '../constants';
import { Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { startCase } from 'lodash';
import React, { useMemo } from 'react';

const useStyles = makeStyles({
  chip: {
    minWidth: 82,
    color: (props) => props.color,
    backgroundColor: (props) => props.backgroundColor,
  },
});

export const StatusComponent = ({
  status,
  filterList = HS_PAXSCREENING_DISPLAY_STATUS,
  filterListColor = HS_PAXSCREENING_STATUS_COLORS,
}) => {
  let displayStatus = filterList[status] || status;
  let color = filterListColor[displayStatus]?.txt || '#23A6CC';
  let backgroundColor =
    filterListColor[displayStatus]?.background || '#23a6cc29';
  const classes = useStyles({ color, backgroundColor });

  const statusStr = useMemo(() => {
    if (displayStatus && displayStatus?.length >= 30) {
      return displayStatus.substring(0, 30);
    }
    return displayStatus;
  }, [displayStatus]);

  return displayStatus?.length >= 30 ? (
    <Tooltip title={displayStatus}>
      <Chip
        size="small"
        className={classes.chip}
        label={startCase(statusStr) + '...'}
      />
    </Tooltip>
  ) : (
    <Chip
      size="small"
      className={classes.chip}
      label={startCase(displayStatus)}
    />
  );
};
