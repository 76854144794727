/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Paper,
  DialogContent,
  TextField,
  Grid,
  CircularProgress,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ClearRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import customToast from '@/new-components/CustomNotification';

import { ButtonEnhance } from '@/module/common/componentUI/commonStyleComponents';
import LoadingButton from '@/components/LoadingButton';
import validateData from '@/helpers/validationHelpers/validationSchema';

import ModalPage, { ModalFooter } from './modalStyled';
import dispatcher from '../action';
import { isEmpty, uniq } from 'lodash';
import { ChangeItemType, QUESTION_TYPES } from '../constants';
import AnswerItem from './AnswerItem';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#85868A',
    opacity: 1
  },
  answerContainer: {
    background: '#f7f7f7',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column'
  }
}));

const initFormValue = {
  question: '',
  type: 'FreeText',
  answers: []
};

const initSuggestionByGenders = [
  {
    gender: 'Male',
    note: ''
  },
  {
    gender: 'Female',
    note: ''
  },
  {
    gender: 'Other',
    note: ''
  }
];

const EditModal = ({
  onClose,
  title,
  id,
  resetId,
  fetchData,
  lstProfile,
  lstTest
}) => {
  const [formValue, setFormValue] = useState(cloneDeep(initFormValue));
  const [statusTitle, setStatusTitle] = useState(title);
  const [saveLabel, setSaveLabel] = useState('Save');
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const { currentQuestion } = useSelector(
    state => state.screeningQuestionnaires
  );

  const { answers = [], type } = formValue;

  const classes = useStyles();

  useEffect(() => {
    return () => {
      dispatcher.saveDataById(null);
      resetId();
    };
  }, []);

  useEffect(() => {
    if (id) {
      setLoadingModal(true);
      dispatcher.getDataById(id, () => {
        setLoadingModal(false);
      });
    }
  }, [id]);

  useEffect(() => {
    if (currentQuestion) {
      const newFormData = {
        ...currentQuestion
      };
      setStatusTitle('Update Question');
      setSaveLabel('Save Change');
      setFormValue(newFormData);
    }
  }, [currentQuestion]);

  const handleSubmit = async () => {
    try {
      await validateData('questionnairesSchema', formValue, validData => {
        setErrors({});
        setShowLoader(true);
        dispatcher.editData(
          validData,
          () => {
            customToast('success', 'Edit data successful');
            fetchData();
            onClose();
          },
          () => customToast('error', 'Edit data failure'),
          () => setShowLoader(false)
        );
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const handleChangeForm = key => e => {
    // reset answer when change type to FreeText
    if (key === 'type' && e.target.value === 'FreeText')
      return setFormValue({
        ...formValue,
        [key]: e.target.value,
        answers: []
      });
    else if (key === 'type' && isEmpty(formValue.answers))
      return setFormValue({
        ...formValue,
        [key]: e.target.value,
        answers: [
          {
            answer: '',
            suggestionByGenders: cloneDeep(initSuggestionByGenders)
          }
        ]
      });
    setFormValue({ ...formValue, [key]: e.target.value });
  };

  const onAddMore = () => {
    setFormValue({
      ...formValue,
      answers: [
        ...answers,
        {
          answer: '',
          suggestionByGenders: cloneDeep(initSuggestionByGenders)
        }
      ]
    });
  };

  const onMenuItemChange = (type, value, index, gender) => {
    if (value === null) return;
    let newFormValue = {
      ...formValue
    };
    let genderIndex = {
      Male: 0,
      Female: 1,
      Other: 2
    };
    genderIndex = genderIndex[gender];

    switch (type) {
      case ChangeItemType.ChangeAnswer:
        newFormValue.answers[index].answer = value;
        break;
      case ChangeItemType.RemoveAnswer:
        newFormValue.answers = (newFormValue.answers || []).filter(
          (_i, _index) => _index !== index
        );
        break;
      case ChangeItemType.AddTest:
        newFormValue.answers[index].suggestionByGenders[
          genderIndex
        ].testIds = uniq([
          ...(newFormValue.answers[index].suggestionByGenders[genderIndex]
            .testIds || []),
          value.id
        ]);
        break;
      case ChangeItemType.AddProfile:
        newFormValue.answers[index].suggestionByGenders[
          genderIndex
        ].profileIds = uniq([
          ...(newFormValue.answers[index].suggestionByGenders[genderIndex]
            .profileIds || []),
          value.id
        ]);
        break;
      // remove by index
      case ChangeItemType.RemoveTest:
        newFormValue.answers[index].suggestionByGenders[genderIndex].testIds = (
          newFormValue.answers[index].suggestionByGenders[genderIndex]
            .testIds || []
        ).filter((_i, index) => index !== value);
        break;
      case ChangeItemType.RemoveProfile:
        newFormValue.answers[index].suggestionByGenders[
          genderIndex
        ].profileIds = (
          newFormValue.answers[index].suggestionByGenders[genderIndex]
            .profileIds || []
        ).filter((_i, index) => index !== value);
        break;
      case ChangeItemType.ChangeNote:
        newFormValue.answers[index].suggestionByGenders[
          genderIndex
        ].note = value;
        break;
      default:
        break;
    }
    setFormValue({
      ...newFormValue
    });
  };

  const renderContentPage = () => {
    if (loadingModal) {
      return (
        <div className={classes.root}>
          <CircularProgress color="primary" />
        </div>
      );
    }
    return (
      <ModalPage>
        <DialogContent>
          <div className="test-header">
            {statusTitle}
            <ClearRounded
              style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
              onClick={onClose}
            />
          </div>
          <DialogContent className="style-body">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  label="Question"
                  multiline
                  rowsMax={3}
                  style={{ width: '100%', marginTop: 10 }}
                  value={formValue.question || ''}
                  onChange={handleChangeForm('question')}
                  error={errors.question}
                  helperText={errors.question}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  select
                  label="Type"
                  fullWidth
                  value={formValue.type || ''}
                  onChange={handleChangeForm('type')}
                  error={errors.type}
                  helperText={errors.type}
                >
                  {QUESTION_TYPES.map(t => (
                    <MenuItem key={'type_' + t} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.answerContainer} elevation={0}>
                {answers.map((i, index) => (
                  <AnswerItem
                    data={i}
                    lstProfile={lstProfile}
                    lstTest={lstTest}
                    itemIndex={index}
                    type={type}
                    error={errors.answers}
                    onChangeAnswer={(value, gender) =>
                      onMenuItemChange(
                        ChangeItemType.ChangeAnswer,
                        value,
                        index,
                        gender
                      )
                    }
                    onChangeNote={(value, gender) =>
                      onMenuItemChange(
                        ChangeItemType.ChangeNote,
                        value,
                        index,
                        gender
                      )
                    }
                    onAddTest={(value, gender) =>
                      onMenuItemChange(
                        ChangeItemType.AddTest,
                        value,
                        index,
                        gender
                      )
                    }
                    onRemoveTest={(value, gender) =>
                      onMenuItemChange(
                        ChangeItemType.RemoveTest,
                        value,
                        index,
                        gender
                      )
                    }
                    onAddProfile={(value, gender) =>
                      onMenuItemChange(
                        ChangeItemType.AddProfile,
                        value,
                        index,
                        gender
                      )
                    }
                    onRemoveProfile={(value, gender) =>
                      onMenuItemChange(
                        ChangeItemType.RemoveProfile,
                        value,
                        index,
                        gender
                      )
                    }
                    onRemoveAnswer={() =>
                      onMenuItemChange(ChangeItemType.RemoveAnswer, '', index)
                    }
                  />
                ))}
                {type !== 'FreeText' ? (
                  <ButtonEnhance
                    onClick={onAddMore}
                    style={{
                      width: 'fit-content',
                      margin: 'auto',
                      marginTop: 20,
                      marginBottom: 20
                    }}
                  >
                    Add More
                  </ButtonEnhance>
                ) : null}
              </Paper>
            </Grid>
          </DialogContent>
          <ModalFooter>
            <ButtonEnhance
              background="#E9E9E9"
              color="black"
              backgroundHover="#ccc"
              onClick={onClose}
            >
              Cancel
            </ButtonEnhance>
            <LoadingButton
              onClick={handleSubmit}
              showLoader={showLoader}
              disabled={isEmpty(answers) && type !== 'FreeText'}
            >
              {saveLabel}
            </LoadingButton>
          </ModalFooter>
        </DialogContent>
      </ModalPage>
    );
  };

  return <>{renderContentPage()} </>;
};

EditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  resetId: PropTypes.func.isRequired
};

export default EditModal;
