export const COLUMN_TIMESLOT_TEMPLATE = [
  {
    label: 'Template name',
    stateValue: 'templateNam',
    width: '40%'
  },
  {
    label: 'Opening hour',
    stateValue: 'openingHour'
  },
  {
    label: 'Closing hour',
    stateValue: 'closingHour'
  },
  {
    label: 'Time interval',
    stateValue: 'timeInterval'
  },
  {
    label: 'Quota',
    stateValue: 'quota'
  },
  {
    label: 'Updated at',
    stateValue: 'updatedAt'
  },
  {
    label: 'Action',
    stateValue: 'action',
    width: 120
  }
];
