import dispatcher from '../action';

const initialState = {
  progress: 0,
  progressItem: 0
};

const reducer = dispatcher(initialState, {
  [dispatcher.updateProgress]: (state, payload) => ({
    progress: payload.progress
  }),
  [dispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value
  })
});

export default reducer;
