import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import OnePageTable from './component/OnePageTable';
import {
  Paper,
  TableContainer as MUTableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from '@material-ui/core';
import { ButtonEnhance } from '../common/componentUI/commonStyleComponents';
import LoadingButton from '@/components/LoadingButton';
import { useSelector } from 'react-redux';

import TabsUI from '../common/componentUI/TabsUI';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import CollapseRow from './component/CollapseRow';
import RoleCheckRow from './component/RoleCheckRow';
import { ROLE_MERGE_TRAINER, ROLES_MANAGEMENT_TABS } from './constants';
import roleManagementDispatcher from './action';
import { findIndex, get, isEmpty, forEach, cloneDeep, chain } from 'lodash';
import { togglePermission } from './helper';
import { mergeArrayByKey } from '@/helpers';

const RolesManagement = () => {
  const { permissions, configs, roles, loading } = useSelector(
    state => state.roleManagement
  );
  const [listPermissionChange, setListPermissionChange] = useState([]);
  const [listPermission, setListPermission] = useState([]);

  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');

  const [userPermission, setUserPermission] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (isEmpty(dataTable)) return;
    const newData = dataTable.filter(
      item => item.key === dataTable[currentTab].key
    );
    let newTableData = [];
    newData.forEach(it => (newTableData = it.items));

    setUserPermission(newTableData);
    setFilterData(newTableData);
  }, [dataTable, currentTab]);

  useEffect(() => {
    roleManagementDispatcher.getAllPermissions();
    roleManagementDispatcher.getAllRoles();
  }, []);

  useEffect(() => {
    if (configs && permissions && roles) {
      const newTableData = formatRolePermissionConfig(configs);

      setDataTable(newTableData);
    }
    // eslint-disable-next-line
  }, [configs, permissions, roles]);
  const haveNoChange = !!listPermissionChange.length;

  const formatRolePermissionConfig = data => {
    const defaultData = roles.map(role => {
      const newPermissions = permissions.map(perm => {
        const index = findIndex(data, it => it.role === role);

        if (index !== -1)
          return {
            privilege: perm,
            roleType: role,
            isActive:
              findIndex(
                data,
                it => it.role === role && it.permissions.includes(perm)
              ) !== -1
          };
        else {
          return {
            privilege: perm,
            roleType: role,
            isActive: false
          };
        }
      });

      return {
        roleType: role,
        items: newPermissions
      };
    });
    let filterData = defaultData.filter(
      item => !['InternalTrainer'].includes(item.roleType)
    );
    filterData = mergeArrayByKey(ROLE_MERGE_TRAINER, filterData, 'roleType');
    let formattedData = [...ROLES_MANAGEMENT_TABS];
    let newListChange = [];

    ROLES_MANAGEMENT_TABS.forEach((role, roleInx) => {
      for (const index in role.items) {
        role.items[index] = { ...role.items[index] };
        const roleLv1 = role.items[index];
        const listChanged = deepFormatPermission(
          roleLv1,
          filterData,
          formattedData[roleInx].items[index]
        );

        if (!isEmpty(listChanged))
          newListChange = newListChange.concat(listChanged);

        const mergedData = mergeRoleByPermission(filterData, role.items[index]);
        const selectedItem = mergedData.find(data => data.isActive);
        selectedItem && newListChange.push(selectedItem);

        if (formattedData[roleInx].items[index].items)
          formattedData[roleInx].items[index].items = mergedData;
      }
    });

    let newListChangedExist = [];
    configs.forEach(item => {
      item.permissions.forEach(data => {
        newListChangedExist.push({
          isActive: true,
          roleType: item.role,
          privilege: data
        });
      });
    });

    setListPermission(newListChangedExist);
    return formattedData;
  };

  const deepFormatPermission = (roleData, filterData, formattedData) => {
    let newListChange = [];
    if (roleData?.child) {
      roleData.child.forEach((roleChild, roleDataInx) => {
        const mergedData = mergeRoleByPermission(
          filterData,
          roleData.child[roleDataInx]
        );
        const selectedItem = mergedData.find(data => data.isActive);
        selectedItem && newListChange.push(selectedItem);
        if (formattedData.child[roleDataInx].items)
          formattedData.child[roleDataInx].items = mergedData;

        deepFormatPermission(
          roleChild,
          filterData,
          formattedData?.child[roleDataInx]
        );
      });
    }
    return newListChange;
  };

  const mergeRoleByPermission = (data, permission) => {
    const foundIndex = findIndex(data[0].items, [
      'privilege',
      permission.privilege
    ]);
    let items = [];
    if (foundIndex !== -1) {
      items = data.map(role => {
        let content = get(role, `items[${foundIndex}]`);
        if (content) {
          content.roleType = role.roleType;
        }
        return content;
      });
    }

    return items;
  };

  const deepAllChecked = (permission, rolePosition) => {
    if (permission.child) {
      for (const childPos in permission.child) {
        const permissionChild = permission.child[childPos];
        deepAllChecked(permissionChild, rolePosition);
      }
    } else {
      const permissionChild = permission.items[rolePosition];
      if (!permissionChild.isActive) return false;
    }
  };

  const haveAllPermission = rolePosition => {
    if (!dataTable.length) {
      return false;
    }

    for (const index in userPermission) {
      const permission = userPermission[index];
      if (permission.child) {
        for (const childPos1 in permission.child) {
          const permissionChild1 = permission.child[childPos1];

          if (permissionChild1.child) {
            for (const childPos2 in permissionChild1.child) {
              const permissionChild2 = permissionChild1.child[childPos2];
              deepAllChecked(permissionChild2, rolePosition);
            }
          } else {
            const permissionChild2 = permissionChild1.items[rolePosition];
            if (!permissionChild2?.isActive) return false;
          }
        }
      } else {
        const permissionChild = permission?.items[rolePosition];
        if (!permissionChild?.isActive) return false;
      }
    }

    return true;
  };
  const handleChangePermission = rowPosition => (
    event,
    row,
    typeOfCheck,
    rolePosition,
    isChild,
    childPosition
  ) => {
    const value = get(event, 'target.checked');
    let arrChanged = [...listPermissionChange];
    let changedList = [...userPermission];
    switch (typeOfCheck) {
      case 'normal':
        let chosenItem = row;
        chosenItem.isActive = value;
        //update to list data change
        arrChanged = togglePermission(arrChanged, chosenItem);
        break;
      case 'parent':
        row.child.forEach(child => {
          let chosenItem = get(child, `items[${rolePosition}]`);
          if (chosenItem) {
            chosenItem.isActive = value;
            //update to list data change
            arrChanged = togglePermission(arrChanged, chosenItem);
          }
        });

        break;
      case 'all':
        forEach(changedList, permission => {
          if (permission.child) {
            arrChanged = deepTogglePermission(
              arrChanged,
              permission.child,
              rolePosition,
              value
            );
          } else {
            if (!isEmpty(permission.items)) {
              permission.items[rolePosition].isActive = value;
              //update to list data change
              arrChanged = togglePermission(
                arrChanged,
                permission.items[rolePosition]
              );
            }
          }
        });
        break;
      default:
        break;
    }

    setUserPermission(changedList);
    setListPermissionChange(arrChanged);
  };

  const deepTogglePermission = (arrChanged, dataList, rolePosition, value) => {
    if (dataList) {
      dataList.forEach(item => {
        if (item.child) {
          arrChanged = deepTogglePermission(
            arrChanged,
            item.child,
            rolePosition,
            value
          );
        } else {
          if (!isEmpty(item.items)) {
            item.items[rolePosition].isActive = value;
            //update to list data change
            arrChanged = togglePermission(arrChanged, item.items[rolePosition]);
          }
        }
      });
    }
    return arrChanged;
  };

  const removeAllChanges = () => {
    if (isEmpty(dataTable)) return;
    const dataConfig = formatRolePermissionConfig(configs);
    const newData = dataConfig.filter(
      item => item.key === dataConfig[currentTab].key
    );
    let newTableData = [];
    newData.forEach(it => (newTableData = it.items));
    setUserPermission(cloneDeep(newTableData));
    setListPermissionChange([]);
  };

  const handleSubmit = () => {
    const listPermissionActice = listPermission.filter(perm => perm.isActive);

    let newList = [...listPermissionActice];
    let newListChange = [];
    listPermissionActice.forEach((perm, index) => {
      const itemChange = listPermissionActice.find(
        it => perm.roleType === it.roleType && perm.privilege === it.privilege
      );

      if (itemChange && !itemChange.isActive) {
        // Get all RoleType and Permission existed
        newList[index] = itemChange;
      } else {
        // Filter new RoleType and Permission need to add

        const filterListChange = listPermissionChange.filter(
          it =>
            !(perm.roleType === it.roleType && perm.privilege === it.privilege)
        );

        if (!isEmpty(filterListChange)) newListChange = filterListChange;
        // Filter RoleType and Permission need to remove
        const inActive = listPermissionChange.find(
          it => perm.roleType === it.roleType && perm.privilege === it.privilege
        );
        if (inActive) {
          const foundItem = newList.findIndex(
            it =>
              it.roleType === inActive.roleType &&
              it.privilege === inActive.privilege
          );
          newList.splice(foundItem, 1);
        }
      }
    });

    newList = newList.concat(newListChange);

    const rolePermissions = chain(newList)
      .filter(perm => perm)
      .groupBy('roleType')
      .map((value, key) => ({
        role: key,
        permissions: [
          ...new Set(
            value.filter(perm => perm?.isActive).map(perm => perm?.privilege)
          )
        ]
      }))
      .value();
    roleManagementDispatcher.updateRolePermissions(rolePermissions, () => {
      setListPermissionChange([]);
      roleManagementDispatcher.getAllConfigs();
    });
  };

  const handleCollapse = menuItem => {
    const listMenu = [...userPermission];
    listMenu.forEach((menuItem1, index) => {
      if (menuItem1 === menuItem) {
        listMenu[index].isOpen = !listMenu[index].isOpen;
      } else if (menuItem1.child) {
        menuItem1.child.forEach((menuItem2, index2) => {
          if (menuItem2 === menuItem) {
            listMenu[index].child[index2].isOpen = !listMenu[index].child[
              index2
            ].isOpen;
          } else if (menuItem2.child) {
            menuItem2.child.forEach((menuItem3, index3) => {
              listMenu[index].child[index2].child[index3].isOpen = !listMenu[
                index
              ].child[index2].child[index3].isOpen;
            });
          }
        });
      }
    });

    setUserPermission(listMenu);
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...props}
      hiddenRows={hiddenRows}
      setHiddenRows={setHiddenRows}
    />
  );
  const TableHeader = () => (
    <TableHeadWrapped>
      <TableRow>
        <TableCellFiltedWrapper
          className="sticky-head-item"
          label="Menu Item"
          stateValue="menuItem"
          style={{ background: '#dfe0e3' }}
        />
        {ROLE_MERGE_TRAINER.map((role, index) => (
          <TableCellFiltedWrapper
            label={role.label}
            stateValue={role.roleType}
            onCheckboxChange={e =>
              handleChangePermission()(e, 'allRow', 'all', index)
            }
            isChecked={() => haveAllPermission(index)}
            useCheckBox
          />
        ))}
      </TableRow>
    </TableHeadWrapped>
  );

  const TableFooter = () => (
    <TableFooterWrapped>
      {haveNoChange && (
        <ButtonEnhance
          background="#E9E9E9"
          color="black"
          backgroundHover="#ccc"
          onClick={removeAllChanges}
        >
          Cancel
        </ButtonEnhance>
      )}

      <LoadingButton
        disabled={!haveNoChange}
        onClick={handleSubmit}
        showLoader={loading}
      >
        Save
      </LoadingButton>
    </TableFooterWrapped>
  );

  const renderListTabs = key => (
    <>
      <OnePageTable
        data={userPermission}
        setData={setUserPermission}
        filterData={filterData}
        setFilterData={setFilterData}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={roleManagementDispatcher.getAllConfigs}
        footer={TableFooter}
        stickyHeader={true}
        tableBody={
          <TableBodyWrapped>
            {filterData.map((row, rowPosition) => {
              let depthLevel = 0;
              return row.child ? (
                <CollapseRow
                  row={row}
                  depthLevel={depthLevel}
                  onChange={handleChangePermission(rowPosition)}
                  hiddenRows={hiddenRows}
                  key={row.privilege}
                  onCollapse={handleCollapse}
                />
              ) : (
                <TableRow hover key={row.privilege}>
                  <TableCell
                    className="menu-item"
                    style={{ background: '#dfe0e3' }}
                  >
                    {!hiddenRows.menuItem && row.title}
                  </TableCell>
                  <RoleCheckRow
                    row={row}
                    onChange={(e, item, index) =>
                      handleChangePermission(rowPosition)(
                        e,
                        item,
                        'normal',
                        index
                      )
                    }
                    hiddenRows={hiddenRows}
                  />
                </TableRow>
              );
            })}
          </TableBodyWrapped>
        }
      />
    </>
  );

  return (
    <MUTableContainer component={Paper}>
      <div className="table-container-header">
        <Typography
          style={{
            fontSize: 18,
            fontWeight: 600
          }}
        >
          Roles Management
        </Typography>
      </div>
      <TabHeadWrapped>
        <TabsUI
          listTab={ROLES_MANAGEMENT_TABS}
          value={currentTab}
          onChange={(e, val) => {
            setCurrentTab(val);
            setHiddenRows({});
          }}
          renderLabel="label"
          renderKey="label"
        />
        <main>{renderListTabs(ROLES_MANAGEMENT_TABS[currentTab].key)}</main>
      </TabHeadWrapped>
    </MUTableContainer>
  );
};

const TableBodyWrapped = styled(TableBody)`
  .menu-item {
    min-width: 330px;
    position: sticky;
    left: 0;
    z-index: 1;
    background: white;
    border-bottom: 2px solid white;
  }
  .child-item {
    padding-left: 45px;
  }
  .MuiTableCell-body {
    vertical-align: middle;
    padding: 8px 16px;
  }
`;
const TableFooterWrapped = styled.div`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 10px 0px;
  display: flex;
  justify-content: flex-end;
`;

const TableHeadWrapped = styled(TableHead)`
  .MuiTableCell-root {
    padding: 10px 16px;
  }
  .sticky-head-item {
    z-index: 10;
    vertical-align: middle;
    border-bottom: 2px solid white;
  }
  .MuiTableCell-stickyHeader {
    background: white;
  }
  .MuiTableCell-head {
    div {
      width: max-content;
    }
  }
`;
const TabHeadWrapped = styled.div`
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
`;

export default RolesManagement;
