const handleApprovePatientSuccess = (list, payload) => {
  const { userId, isApprove } = payload;
  return list.map(item => {
    if (item.id === userId) {
      return {
        ...item,
        approveStatus: isApprove ? 'Approved' : 'Reject'
      };
    }
    return item;
  });
};

const handleInActiveOrActiveSuccess = (list, payload) => {
  const { isActive, userId } = payload;
  return list.map(item => {
    if (item.id === userId) {
      return {
        ...item,
        activeStatus: isActive ? 'Active' : 'InActive'
      };
    }
    return item;
  });
};

export { handleApprovePatientSuccess, handleInActiveOrActiveSuccess };
