import testDispatcher from '../action';

const initialState = {
  Tests: {
    list: [],
    paging: {
      pageIndex: 0,
      pageSize: 10,
      totalItem: 0,
      totalPage: 0
    }
  },
  Measurements: {
    list: [],
    paging: {
      pageIndex: 0,
      pageSize: 10,
      totalItem: 0,
      totalPage: 0
    }
  },
  Procedures: {
    list: [],
    paging: {
      pageIndex: 0,
      pageSize: 10,
      totalItem: 0,
      totalPage: 0
    }
  },
  Radiologies: {
    list: [],
    paging: {
      pageIndex: 0,
      pageSize: 10,
      totalItem: 0,
      totalPage: 0
    }
  },
  Vaccinations: {
    list: [],
    paging: {
      pageIndex: 0,
      pageSize: 10,
      totalItem: 0,
      totalPage: 0
    }
  },
  Reviews: {
    list: [],
    paging: {
      pageIndex: 0,
      pageSize: 10,
      totalItem: 0,
      totalPage: 0
    }
  }
};

const testReducer = testDispatcher(initialState, {
  [testDispatcher.getTestsSuccess]: (state, payload) => ({
    [payload.key]: {
      list: payload.data.list,
      paging: {
        ...payload.data,
        list: undefined
      }
    }
  }),
  [testDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value
  })
});

export default testReducer;
