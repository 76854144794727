import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import isEmpty from 'lodash/isEmpty';
import { Add } from '@/assets/svg';

const useStyles = makeStyles({
  table: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 350px)',
    borderRadius: 11,
    color: '#333333',

    '& .MuiTableHead-root > .MuiTableRow-root': {
      height: 32,
      backgroundColor: '#f1f1f1',

      '& .MuiTableCell-root': {
        padding: '4px 16px',
        backgroundColor: 'transparent',

        fontSize: 12,
        fontWeight: 400
      }
    }
  },
  tableBody: {
    '& .MuiTableRow-root': {
      height: 44,

      '& .MuiTableCell-root': {
        padding: '12px 16px',
        fontSize: 16
      }
    }
  },
  tableCellFooter: {
    borderBottom: 'none'
  }
});

const CustomButton = withStyles({
  root: {
    paddingTop: 4,
    paddingBottom: 4,

    fontSize: 16,

    textTransform: 'unset'
  }
})(Button);

const TableWithFooter = ({
  data,
  columns,
  renderRow,
  isFooter = true,
  isFooterButtonDisabled = false,
  onClick,
  labelButton = 'Add',
  noDataHelperText,
  square = false
}) => {
  const classes = useStyles();

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {columns.map(item => (
          <TableCell
            key={item.stateValue}
            style={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              width: item.width
            }}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  return (
    <TableContainer component={Paper} square={square} variant="outlined">
      <Table className={classes.table} stickyHeader>
        <TableHeader />

        <TableBody className={classes.tableBody}>
          {!isEmpty(data) &&
            data.map((d, i) => (
              <TableRow key={d.id}>{renderRow(d, i)}</TableRow>
            ))}
          {noDataHelperText && isEmpty(data) && (
            <TableRow>
              <TableCell colSpan={columns.length}>{noDataHelperText}</TableCell>
            </TableRow>
          )}
        </TableBody>

        {isFooter && (
          <TableFooter>
            <TableRow>
              <TableCell
                className={classes.tableCellFooter}
                colSpan={columns.length}
              >
                <CustomButton
                  color="primary"
                  startIcon={<Add />}
                  onClick={onClick}
                  disabled={isFooterButtonDisabled}
                >
                  {labelButton}
                </CustomButton>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default TableWithFooter;
