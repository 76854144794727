const renderColumn = () => {
  const tableColumn = [
    {
      label: 'Start time',
      stateValue: 'startTime'
    },
    {
      label: 'End time',
      stateValue: 'name'
    },
    {
      label: 'Time Interval',
      stateValue: 'intervals'
    },
    {
      label: 'Quota',
      stateValue: 'quota',
      width: 144
    },
    {
      label: 'Status',
      stateValue: 'status'
    },
    {
      label: 'Action',
      stateValue: 'action'
    }
  ];
  return tableColumn;
};

export default renderColumn;
