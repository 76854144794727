import { ArrowDown } from '@/assets/svg';
import styled from 'styled-components';

export const SelectContainer = styled.div`
  position: relative;
  cursor: pointer;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 5px 11px;
  align-self: flex-end;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(p) => p.height || '36px'};
  margin-left: 0 !important;
  color: rgb(51, 51, 51, 0.3);
  &.disabled {
    background-color: #f6f6f6;
    pointer-events: none;
    > div > div {
      color: rgb(51, 51, 51, 0.3);
    }
  }
`;

export const SelectContent = styled.div`
  display: flex;
  color: ${(p) => p.color};
  font-size: ${(p) => p.fontSize};
  font-family: 'OpenSans';
  align-items: flex-end;
  overflow: hidden;
  margin-left: 5px;
`;

export const SelectRenderValue = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
export const IconGroup = styled.div`
  display: flex;
  align-items: center;
  .ant-input-clear-icon {
    margin-left: 8px;
  }
`;
export const StyledArrowDown = styled(ArrowDown)`
  color: #666;
  margin-left: 15px;
  margin-top: 2px;
`;
