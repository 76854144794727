import {
  TELECOUNSEL_DISPLAY_STATUS,
  TELECOUNSEL_STATUS_COLORS,
} from '../constants';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { startCase } from 'lodash';
import React from 'react';

const useStyles = makeStyles({
  chip: {
    minWidth: 82,
    color: (props) => props.color,
    backgroundColor: (props) => props.backgroundColor,
  },
});

export const StatusComponent = ({
  status,
  filterList = TELECOUNSEL_DISPLAY_STATUS,
}) => {
  let displayStatus = filterList[status] || status;
  let color = TELECOUNSEL_STATUS_COLORS[displayStatus]?.txt || '#23A6CC';
  let backgroundColor =
    TELECOUNSEL_STATUS_COLORS[displayStatus]?.background || '#23a6cc29';
  const classes = useStyles({ color, backgroundColor });

  return (
    <Chip
      size="small"
      className={classes.chip}
      label={startCase(displayStatus)}
    />
  );
};
