import React from 'react';
import { COLUMN_TEST } from '@/module/services/constants';
import ServiceManagement from '../../common/ServiceManagement';
import TestModalContainer from './TestModalContainer';

const TestManagement = props => (
  <ServiceManagement
    {...{
      ...props,
      prefixName: 'Test',
      category: 'Tests',
      noDataHelperText: 'No test',
      noDataHelperSubText: 'Please create a new test',
      columns: COLUMN_TEST,
      ModalContainer: TestModalContainer,
      requireLabCode: true
    }}
  />
);

export default TestManagement;
