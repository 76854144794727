import CustomButton from '@/new-components/CustomButton';
import CustomDate from '@/new-components/CustomDate';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  withStyles,
  InputLabel,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import moment from 'moment';
import React, { useMemo, useState } from 'react';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey.subText,
  },
  text: {
    fontWeight: 600,
  },
});

const CustomDialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialog = withStyles({
  paper: {
    borderRadius: 16,
    width: 480,
  },
})(Dialog);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    overflowY: 'auto',
  },
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    margin: 0,
    padding: 16,
    justifyContent: 'flex-end',
  },
})(DialogActions);

const CustomInputLabel = withStyles((theme) => ({
  root: {
    color: '#666666',
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}))(InputLabel);

const CustomFormHelperText = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'OpenSans',
    fontSize: 12,
    gap: 6,
    marginLeft: 4,
    marginTop: 0,

    '& .MuiSvgIcon-root': {
      fontSize: 'unset',
    },
  },
})(FormHelperText);

const ExportModal = ({ open, onClose, onSubmit, loading }) => {
  const [errors, setErrors] = useState({});
  const handleSubmit = async () => {
    try {
      onSubmit(formValue);
    } catch (errs) {
      setErrors(errs);
    }
  };

  const [formValue, setFormValue] = useState({
    startDate: moment().startOf('date'),
    endDate: moment().endOf('date'),
  });

  const handleChangeForm = (key) => (e) => {
    let newValue = '';
    switch (key) {
      case 'startDate':
        newValue = moment(e).startOf('date');
        break;
      case 'endDate':
        newValue = moment(e).endOf('date');
        break;
      default:
        newValue = moment(e).startOf('date');
    }
    setFormValue({ ...formValue, [key]: newValue });
  };

  const isEmptyParams = useMemo(() => {
    const filterKeys = ['startDate', 'endDate'];
    const checkValid = filterKeys.some((key) => !formValue[key]);

    return checkValid;
  }, [formValue]);

  return (
    <CustomDialog
      scroll="body"
      disableEnforceFocus={true}
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
    >
      <CustomDialogTitle onClose={onClose}>
        Download order history
      </CustomDialogTitle>
      <CustomDialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <CustomInputLabel>Start date</CustomInputLabel>
            <CustomDate
              selectedDate={
                formValue.startDate ? moment(formValue.startDate) : ''
              }
              maxDate={formValue.endDate}
              isFullWidth
              placeholder="Select date"
              clearable={false}
              onChange={handleChangeForm('startDate')}
              style={{ height: 48, fontSize: 16 }}
            />
            {errors.startDate && (
              <CustomFormHelperText error>
                {errors.startDate}
              </CustomFormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <CustomInputLabel>End date</CustomInputLabel>
            <CustomDate
              selectedDate={formValue.endDate ? moment(formValue.endDate) : ''}
              minDate={moment(formValue.startDate)}
              maxDate={moment()}
              isFullWidth
              placeholder="Select date"
              clearable={false}
              onChange={handleChangeForm('endDate')}
              style={{ height: 48, fontSize: 16 }}
            />
            {errors.endDate && (
              <CustomFormHelperText error>
                {errors.endDate}
              </CustomFormHelperText>
            )}
          </Grid>
        </Grid>
      </CustomDialogContent>
      <CustomDialogActions>
        <CustomButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isEmptyParams || loading}
        >
          Download
        </CustomButton>
      </CustomDialogActions>
    </CustomDialog>
  );
};

export default ExportModal;
