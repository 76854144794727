import styled from 'styled-components';
import { DialogActions } from '@material-ui/core';

const ModalPage = styled.div`
  hr {
    background: #cacfd3;
    margin: 0;
  }
  .MuiDialogContent-root {
    padding: 0;
    min &:first-child {
      padding-top: 0;
    }
  }
  .test-header {
    padding: 0 20px 10px;
    font-size: 18px;
    font-weight: 600;
    color: #192637;
    border-bottom: 1px solid rgb(202, 207, 211);
  }
  .style-body {
    padding: 0 20px;
    height: 72vh;
    overflow-y: auto;
  }
  .footer-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .delete-icon {
      margin-left: 3px;
      color: #8f9ba9;
    }
  }
`;

export const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 10px 0px;
`;

export const ParagraphWrapped = styled.div`
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export default ModalPage;
