import { DateRangePicker } from '@material-ui/pickers';
import styled from 'styled-components';

export const CustomDateRangePicker = styled(DateRangePicker)`
  .MuiPickersDay-root {
    font-size: 14px;
    width: 25px;
    height: 30px;
  }
  .MuiButtonBase-root {
    border-radius: 4px;
  }
  .MuiPickersDateRangeDay-rangeIntervalPreview {
    margin: 0;
  }
  .MuiPickersCalendar-weekDayLabel {
    margin: 0 6px;
    font-size: 14px;
    color: #333;
    font-weight: 500;
  }
  .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer {
    padding: 0;
    width: 360px;
  }
  .MuiPickersDesktopDateRangeCalendar-calendar {
    min-height: 256px;
  }
  .MuiPickersDateRangeDay-dayInsideRangeInterval {
    color: #333;
  }
  .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
    background-color: #ffe9fb;
  }
  .MuiPickersDateRangeDay-rangeIntervalDayHighlightStart {
    border-radius: 0;
  }
  .MuiPickersDateRangeDay-root {
    margin-top: 2px;
    padding: 0 10px;
  }
  .MuiPickersDateRangeDay-root.MuiPickersDateRangeDay-rangeIntervalDayHighlightStart.MuiPickersDateRangeDay-rangeIntervalDayHighlight:has(.Mui-selected) {
    background: linear-gradient(to right, white 50%, #ffe9fb 50%);
  }

  .MuiPickersDateRangeDay-root.MuiPickersDateRangeDay-rangeIntervalDayHighlightEnd.MuiPickersDateRangeDay-rangeIntervalDayHighlight:has(.Mui-selected) {
    background: linear-gradient(to left, white 50%, #ffe9fb 50%);
  }
  .MuiPickersDateRangeDay-rangeIntervalDayHighlightEnd {
    border-radius: 0;
  }

  .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:not(:last-child) {
    border-right: none;
  }
  .MuiPickersDateRangeDay-rangeIntervalDayHighlight:first-child {
    border-radius: 0;
  }
  .MuiPickersDateRangeDay-rangeIntervalDayHighlight:last-child {
    border-radius: 0;
  }
  .MuiPickersDesktopDateRangeCalendar-arrowSwitcher {
    padding: 16px 12px 8px;
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 8px;
  }
  .header-border {
    width: 100%;
    position: absolute;
    top: 20px;
    height: 2px;
    background: #333;
  }

  .MuiTypography-subtitle1 {
    font-size: 18px;
    font-weight: 500;
  }
`;
