import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    borderTop: '2px solid #e1e1e1',

    '& .Mui-selected': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },

    '& .MuiPaginationItem-root': {
      fontWeight: 600,
    },
  },
}));

const CustomSelect = withStyles({
  selectMenu: {
    padding: 8,
  },
})(Select);

const CustomButton = withStyles({
  root: {
    padding: 8,
    textTransform: 'unset',
    minWidth: 60,
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
  },
})(Button);

const CustomPagination = ({
  count,
  page,
  rowsPerPage,
  onChange,
  rowsPerPageOptions = [5, 10, 50, 100, 200],
  onChangeRowsPerPage,
  handleFirstPageButtonClick,
  handleLastPageButtonClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const pageDefault = parseInt(page || '1', 10);
  return (
    <Box
      display="flex"
      px={2}
      py={1.4}
      alignItems="center"
      justifyContent="space-between"
      className={classes.tablePagination}
    >
      <Box display="flex" alignItems="center">
        <CustomButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 1}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? 'Last' : 'First'}
        </CustomButton>

        <Pagination
          shape="rounded"
          count={Math.ceil(count / rowsPerPage)}
          page={pageDefault}
          onChange={onChange}
          color="primary"
        />

        <CustomButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage)}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? 'First' : 'Last'}
        </CustomButton>
      </Box>
      <Box display="flex" alignItems="center" gridGap={16}>
        <Typography>Per page</Typography>
        <CustomSelect
          value={rowsPerPage}
          defaultValue={10}
          variant="outlined"
          onChange={onChangeRowsPerPage}
          style={{ borderRadius: 8 }}
          IconComponent={ExpandMore}
        >
          {rowsPerPageOptions.map((perPage) => (
            <MenuItem
              key={`rows-per-page-${perPage}`}
              value={perPage}
            >{`${perPage}`}</MenuItem>
          ))}
        </CustomSelect>
        <Divider light flexItem orientation="vertical" />
        <Box>
          {rowsPerPage < count ? rowsPerPage : count} of {count}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomPagination;
