import ecommerceDispatcher from '../../action';
import { TYPE_MODAL, STEPS_CONTROL } from '../../constants';
import BundlePricingStep from './BundlePricingStep';
import GeneralInformationStep from './GeneralInformationStep';
import { CloseIcon } from '@/assets/svg';
import CustomLoadingButton from '@/new-components/CustomLoadingButton';
import CustomStepper from '@/new-components/CustomStepper';
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { cloneDeep, get, isEmpty, lowerCase } from 'lodash';
import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#333',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  mainWrapper: {
    height: 'calc(100vh - 200px)',
    overflow: 'auto',
  },
});

const CustomDrawerTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

const ProductModal = ({
  onSubmit,
  onClose,
  typeModal,
  selectedItem,
  loading,
}) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [formValue, setFormValue] = useState({
    name: '',
    description: '',
    requiredConsultation: false,
    categoryId: '',
    unit: '',
    productPrices: [],
    listDetailImageUrls: [],
  });

  const handleChangeForm = (key) => (e, newValue) => {
    let value;
    switch (key) {
      case 'description':
      case 'listDetailImageUrls':
        value = e;
        break;
      case 'requiredConsultation':
        value = e.target.checked;
        break;
      case 'categoryId':
        value = get(newValue, 'id');
        break;
      case 'unit':
        value = newValue;

        break;
      default:
        value = get(e, 'target.value');
    }

    if (key === 'unit') {
      const productPrices = cloneDeep(formValue.productPrices).map((d) => ({
        ...d,
        note: pluralize(lowerCase(value), d.quantity, true),
      }));

      setFormValue({ ...formValue, productPrices, [key]: value });
    } else setFormValue({ ...formValue, [key]: value });
  };

  const renderListTabs = [
    {
      name: 'General information',
      validKey: [
        'listDetailImageUrls',
        'name',
        'description',
        'categoryId',
        'unit',
      ],
      component: (
        <GeneralInformationStep
          formValue={formValue}
          typeModal={typeModal}
          handleChangeForm={handleChangeForm}
        />
      ),
    },
    {
      name: 'Bundle pricing',
      validKey: ['productPrices'],
      component: (
        <BundlePricingStep formValue={formValue} setFormValue={setFormValue} />
      ),
    },
  ];
  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = renderListTabs[currentStep]?.validKey;
    const checkValid = filterKeys.some((key) =>
      Array.isArray(formValue[key]) ? isEmpty(formValue[key]) : !formValue[key]
    );

    return checkValid;
  }, [renderListTabs, currentStep, formValue]);

  const getProductDetail = async () => {
    setIsLoadingData(true);
    ecommerceDispatcher.getDetailProduct(selectedItem.id, (data) => {
      const {
        name,
        description,
        requiredConsultation,
        categoryId,
        unit,
        productPrices,
        listDetailImageUrls,
      } = data;
      setIsLoadingData(false);
      setFormValue({
        name,
        description,
        requiredConsultation,
        categoryId,
        unit,
        productPrices,
        listDetailImageUrls: listDetailImageUrls || [],
      });
    });
  };

  useEffect(() => {
    if (typeModal === TYPE_MODAL.Create) return;
    getProductDetail();
  }, [selectedItem]);

  const StepperButtons = () => (
    <Box
      display="flex"
      justifyContent="flex-end"
      py={1.75}
      px={1.25}
      gridGap={16}
    >
      {currentStep !== 0 && (
        <CustomLoadingButton
          variant="outlined"
          color="primary"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Back
        </CustomLoadingButton>
      )}

      <CustomLoadingButton
        variant="contained"
        color="primary"
        loading={loading}
        disabled={isEmptyFilterParams || loading}
        onClick={() => {
          if (currentStep === renderListTabs.length - 1) {
            onSubmit(typeModal, formValue);
          } else setCurrentStep(currentStep + 1);
        }}
      >
        {currentStep === renderListTabs.length - 1 ? 'Save' : 'Next'}
      </CustomLoadingButton>
    </Box>
  );

  return (
    <Paper square elevation={0}>
      <CustomDrawerTitle onClose={onClose}>
        {typeModal === TYPE_MODAL.Create
          ? 'Create new product'
          : 'Edit product'}
      </CustomDrawerTitle>
      <CustomStepper
        steps={renderListTabs}
        stepsControl={STEPS_CONTROL}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        allowSkip
        renderButtons={StepperButtons}
      >
        <main className={classes.mainWrapper}>
          {isLoadingData ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            renderListTabs[currentStep].component
          )}
        </main>
      </CustomStepper>
    </Paper>
  );
};

export default ProductModal;
