import { COLUMN_QUALIFICATION, GENDERS, TYPE_MODAL } from '../constants';
import ModalAddOrEditQualification from './ModalAddOrEditQualification';
import {
  CloseIcon,
  EditIcon,
  EyeIcon,
  ImageIcon,
  RemoveIcon,
  TrashIcon,
} from '@/assets/svg';
import Image from '@/components/Image';
import UploadCompAnt from '@/components/UploadCompAnt';
import { blobUrl, momentFormatted } from '@/helpers';
import {
  ButtonSecondary,
  UploadAction,
} from '@/module/setup/createAndEditLocation.styled';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import TableWithFooter from '@/new-components/CustomTable/TableWithFooter';
import CustomTooltip from '@/new-components/CustomTooltip';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TableCell,
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

const CustomTitleLabel = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 24,
    color: '#333333',
  },
})(Typography);

const CustomTitleSubLabel = withStyles({
  root: {
    fontWeight: 600,
    color: '#666666',
  },
})(Typography);

const UploadText = withStyles({
  root: {
    color: '#333333',
  },
})(Typography);

const CustomAlert = withStyles({
  root: {
    color: '#3776ab',
    backgroundColor: '#e2f2ff',
    borderRadius: 8,

    '& .MuiAlert-icon': {
      color: '#3776ab',
    },
  },
})(Alert);

const PreviewContent = withStyles({
  root: {
    width: 141,
    height: 141,

    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(51,51,51,0.4)',
    borderRadius: 8,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
})(Box);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
    '& .MuiInputBase-adornedStart': {
      '& .MuiTypography-root': {
        fontSize: 16,
        color: '#333333',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& input': {
        marginLeft: 0,
      },
    },
  },
})(TextField);

const CustomMultilineTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      minHeight: 48,
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16,
    },

    '& .MuiInputBase-inputMultiline': {
      '&.MuiInputBase-input': {
        marginLeft: 0,
      },
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6',
      },
    },
  },
})(TextField);

const CustomDialogContent = withStyles({
  root: {
    padding: 40,
    position: 'relative',
    '& .icon': {
      position: 'absolute',
      top: 10,
      right: 10,
    },

    '&:first-child': {
      paddingTop: 40,
    },
  },
})(DialogContent);

const CustomIconButton = withStyles({
  root: {
    minWidth: 'unset',
    width: 36,
    height: 36,
    '&.MuiButton-outlined': {
      padding: 0,
    },
  },
})(Button);

const useStyles = makeStyles({
  image: {
    width: 141,
    height: 141,
    borderRadius: 8,
  },
});

const UploadImageContainer = ({
  imageUrl,
  setFormValue,
  formValue,
  handleChangeForm,
}) => {
  const classes = useStyles();

  const [isHovered, setIsHovered] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
    handleMouseLeave();
  };
  const handlePreviewClose = () => setPreviewOpen(false);

  return (
    <Box display="flex" gridGap={20}>
      <Box
        position="relative"
        onMouseEnter={() => handleMouseEnter('front')}
        onMouseLeave={handleMouseLeave}
      >
        {imageUrl ? (
          <>
            <Image
              src={
                imageUrl
                  ? `${
                      typeof imageUrl === 'object'
                        ? blobUrl(imageUrl)
                        : imageUrl
                    }`
                  : null
              }
              alt="Preview"
              className={classes.image}
            />
            {isHovered && (
              <PreviewContent onClick={handlePreviewOpen}>
                <EyeIcon color="white" width={24} height={24} />
              </PreviewContent>
            )}
          </>
        ) : (
          <Box
            width={141}
            height={141}
            bgcolor="#f6f6f6"
            border="1px solid #e1e1e1"
            borderRadius="8px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ImageIcon />
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        gridGap={16}
      >
        <Box>
          <UploadText>
            Upload a portrait of the counsellor with a clear background
          </UploadText>
          <UploadText>Recommended: PNG or JPEG</UploadText>
        </Box>
        <Box display="flex" gridGap={16}>
          {imageUrl && (
            <CustomIconButton
              color="primary"
              variant="outlined"
              onClick={() => setFormValue({ ...formValue, imageUrl: '' })}
            >
              <TrashIcon />
            </CustomIconButton>
          )}
          <UploadCompAnt
            isAddNew={true}
            label="Upload banner"
            fileData={imageUrl}
            onChange={handleChangeForm('imageUrl')}
            editable={true}
            ActionComponent={
              <UploadAction>
                <ButtonSecondary>
                  {imageUrl ? 'Replace' : 'Upload'}
                </ButtonSecondary>
              </UploadAction>
            }
          />
        </Box>
      </Box>
      {previewOpen && (
        <Dialog
          disableBackdropClick
          open={previewOpen}
          onClose={handlePreviewClose}
          maxWidth="lg"
        >
          <CustomDialogContent>
            <IconButton className="icon" onClick={handlePreviewClose}>
              <CloseIcon />
            </IconButton>

            <Image
              src={
                imageUrl
                  ? `${
                      typeof imageUrl === 'object'
                        ? blobUrl(imageUrl)
                        : imageUrl
                    }`
                  : null
              }
              alt="Preview"
            />
          </CustomDialogContent>
        </Dialog>
      )}
    </Box>
  );
};

const GeneralInformationStep = ({
  formValue,
  setFormValue,
  handleChangeForm,
}) => {
  const [showQualificationModal, setShowQualificationModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [typeModal, setTypeModal] = useState(null);

  const changeTableValue = (action, value, position) => {
    let qualifications = [...formValue.qualifications];
    switch (action) {
      case TYPE_MODAL.Create:
        qualifications.push(value);
        break;
      case TYPE_MODAL.Edit:
        qualifications[position] = value;
        break;
      case TYPE_MODAL.Delete:
        qualifications.splice(position, 1);
        break;
      default:
        break;
    }
    setFormValue({ ...formValue, qualifications });
    setShowQualificationModal(false);
  };

  const renderRow = (row, i) => (
    <>
      <TableCell>
        <CustomTooltip content={row.degree} />
      </TableCell>
      <TableCell>
        <CustomTooltip content={row.school} />
      </TableCell>
      <TableCell>
        {row.startDate && row.endDate
          ? `${momentFormatted(row.startDate, 'YYYY')} - ${momentFormatted(
              row.endDate,
              'YYYY'
            )}`
          : momentFormatted(row.startDate, 'YYYY')}
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={(e) => {
            setSelectedItem({ item: row, position: i });
            setShowQualificationModal(true);
            setTypeModal(TYPE_MODAL.Edit);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            changeTableValue(TYPE_MODAL.Delete, row, i);
          }}
        >
          <RemoveIcon />
        </IconButton>
      </TableCell>
    </>
  );

  return (
    <Box px={2.25} py={2.5}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomAlert severity="info">
            All information will be public for clients to view on Minmed Connect
            App.
          </CustomAlert>
        </Grid>
        <Grid item xs={12}>
          <CustomTitleLabel>Personal information</CustomTitleLabel>
          <UploadImageContainer
            formValue={formValue}
            setFormValue={setFormValue}
            handleChangeForm={handleChangeForm}
            imageUrl={formValue?.imageUrl}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTitleSubLabel>First name</CustomTitleSubLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter first name"
            value={formValue.firstName || ''}
            onChange={handleChangeForm('firstName')}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTitleSubLabel>Last name</CustomTitleSubLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter last name"
            value={formValue.lastName || ''}
            onChange={handleChangeForm('lastName')}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTitleSubLabel>Gender</CustomTitleSubLabel>
          <CustomSelectContainer
            data={GENDERS || []}
            height="48px"
            fontSize="16px"
            colorInput="#000000de"
            isMutiple={false}
            hideClear
            placeholder="Select"
            renderValue={
              formValue.gender
                ? GENDERS.find((it) => it.key === formValue.gender)?.value
                : 'Select'
            }
            filterValue={formValue.gender || ''}
            onChange={handleChangeForm('gender')}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTitleSubLabel>Position</CustomTitleSubLabel>
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter current position"
            value={formValue.position || ''}
            onChange={handleChangeForm('position')}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTitleSubLabel>Rate per hour</CustomTitleSubLabel>
          <NumberFormat
            fullWidth
            customInput={CustomTextField}
            variant="outlined"
            placeholder="0.00"
            onValueChange={handleChangeForm('pricePerHour')}
            allowNegative={false}
            value={formValue.pricePerHour || ''}
            decimalScale={2}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTitleSubLabel>Introduction</CustomTitleSubLabel>
          <CustomMultilineTextField
            rows={4}
            multiline
            fullWidth
            variant="outlined"
            placeholder="Enter details"
            value={formValue.introduction || ''}
            onChange={handleChangeForm('introduction')}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTitleLabel>Qualifications (optional)</CustomTitleLabel>
          <TableWithFooter
            data={formValue.qualifications || []}
            labelButton="Add qualification"
            columns={COLUMN_QUALIFICATION}
            onClick={(e) => {
              e && e.stopPropagation();
              setSelectedItem({});
              setShowQualificationModal(true);
              setTypeModal(TYPE_MODAL.Create);
            }}
            renderRow={renderRow}
          />
        </Grid>
      </Grid>
      {showQualificationModal && (
        <ModalAddOrEditQualification
          typeModal={typeModal}
          title={`${
            typeModal === TYPE_MODAL.Create ? 'Add' : 'Edit'
          } qualification`}
          open={showQualificationModal}
          onClose={(e) => {
            e.stopPropagation();
            setShowQualificationModal(false);
            setSelectedItem({});
            setTypeModal(null);
          }}
          onSubmit={changeTableValue}
          selectedItem={selectedItem}
        />
      )}
    </Box>
  );
};

export default GeneralInformationStep;
