import { isEmpty } from 'lodash';
import React from 'react';
import { CheckmarkIcon } from '../../assets/svg';
import {
  Number,
  Step,
  StepContent,
  StepperButton,
  StepperButtonGroup,
  StepperContainer,
  StepperControl,
  Text
} from './styled';
const CustomStepper = ({
  steps,
  stepsControl,
  disableNext = true,
  allowSkip,
  children,
  currentStep,
  setCurrentStep,
  defaultStep = 0,
  onSubmit,
  renderButtons
}) => {
  const renderClassName = (index, status) => {
    if (status) return status;
    if (index === currentStep) return 'current';
    if (index < currentStep) return 'passed';
    return 'incoming';
  };

  const STEPS_RENDER =
    !isEmpty(steps) &&
    steps.map((step, index) => (
      <Step
        key={index}
        onClick={() => {
          if (allowSkip) {
            if (!disableNext || index <= currentStep) setCurrentStep(index);
          } else {
            if (
              (!disableNext || index <= currentStep) &&
              index - currentStep < 2
            )
              setCurrentStep(index);
          }
        }}
        className={renderClassName(index, step.status)}
      >
        <StepContent>
          <Number className={renderClassName(index)}>
            {currentStep > index ? <CheckmarkIcon /> : index + 1}
          </Number>
          <Text className={renderClassName(index)}>{step.name}</Text>
        </StepContent>
      </Step>
    ));

  const STEPS_CONTROL_RENDER = stepsControl && (
    <StepperButtonGroup>
      <StepperButton
        className="secondary"
        onClick={() => setCurrentStep(currentStep - 1)}
        disabled={currentStep === defaultStep}
      >
        {stepsControl.back}
      </StepperButton>
      <StepperButton
        onClick={() => {
          setCurrentStep(currentStep + 1);
          if (currentStep === steps.length - 1) {
            onSubmit();
          }
        }}
        disabled={disableNext}
      >
        {currentStep === steps.length - 1
          ? stepsControl.finish
          : stepsControl.next}
      </StepperButton>
    </StepperButtonGroup>
  );
  return (
    <>
      <StepperContainer numberItem={steps.length}>
        {STEPS_RENDER}
      </StepperContainer>
      {children}
      <StepperControl>
        {renderButtons ? renderButtons() : STEPS_CONTROL_RENDER}
      </StepperControl>
    </>
  );
};

export default CustomStepper;
