import React from 'react';
import PropTypes from 'prop-types';
function LinkIcon({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <g
        id="🌈-Style-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icons"
          transform="translate(-862.000000, -205.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="Colors-Copy" transform="translate(22.000000, 20.000000)">
            <path
              d="M841.9,190 C841.9,188.29 843.29,186.9 845,186.9 L849,186.9 L849,185 L845,185 C842.24,185 840,187.24 840,190 C840,192.76 842.24,195 845,195 L849,195 L849,193.1 L845,193.1 C843.29,193.1 841.9,191.71 841.9,190 Z M846,191 L854,191 L854,189 L846,189 L846,191 Z M855,185 L851,185 L851,186.9 L855,186.9 C856.71,186.9 858.1,188.29 858.1,190 C858.1,191.71 856.71,193.1 855,193.1 L851,193.1 L851,195 L855,195 C857.76,195 860,192.76 860,190 C860,187.24 857.76,185 855,185 Z"
              id="Link"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

LinkIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

LinkIcon.defaultProps = {
  width: '20px',
  height: '10px',
  color: '#ccc'
};

export default LinkIcon;
