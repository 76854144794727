import { configure } from '@/index';
import { renderListEmails } from '@/module/api-support/utils';
import { find, findIndex, get, cloneDeep, isEmpty } from 'lodash';
import { INIT_PERMISSION } from '../constants';

export const findItemByKey = (list, key, value) => {
  return find(list, [key, value]);
};
export const formatRolePermission = data => {
  const filterData = data.filter(
    item => !['InternalTrainer'].includes(item.roleType)
  );
  let formattedData = [...INIT_PERMISSION];

  for (const index in INIT_PERMISSION) {
    if (INIT_PERMISSION[index].child) {
      for (const childIndex in INIT_PERMISSION[index].child) {
        const mergedData = mergeRoleByPrivilege(
          filterData,
          INIT_PERMISSION[index].child[childIndex]
        );
        formattedData[index].child[childIndex].items = mergedData;
      }
    } else {
      const mergedData = mergeRoleByPrivilege(
        filterData,
        INIT_PERMISSION[index]
      );
      formattedData[index].items = mergedData;
    }
  }
  return formattedData;
};

const mergeRoleByPrivilege = (data, permission) => {
  const foundIndex = findIndex(data[0].items, [
    'privilege',
    permission.privilege
  ]);

  const items = data.map(role => {
    let content = get(role, `items[${foundIndex}]`);

    if (content) {
      content.roleType = role.roleType;
    }

    return content;
  });

  return items;
};

export const togglePermission = (originArr = [], item) => {
  const foundItem = findIndex(originArr, ['privilege', item.privilege]);
  if (foundItem < 0) {
    originArr.push({ ...item });
  } else {
    if (originArr[foundItem].isActive !== item.isActive) {
      originArr.splice(foundItem, 1);
    }
  }
  return originArr;
};

export const filterUserPermission = (arr, permissions, roleName = '') => {
  let newArr = cloneDeep(arr);
  arr.forEach(item => {
    if (item.privilege) {
      //find item in newArr
      const itemPosition = findIndex(newArr, ['privilege', item.privilege]);
      if (item.children && item.children[0].privilege) {
        item.children.forEach(child => {
          const foundPer = find(permissions, per => per === child.privilege);
          if (!foundPer) {
            const foundChild = findIndex(newArr[itemPosition].children, [
              'privilege',
              child.privilege
            ]);
            newArr[itemPosition].children.splice(foundChild, 1);
          }
        });
        !newArr[itemPosition].children.length && newArr.splice(itemPosition, 1);
      } else {
        if (roleName === 'SystemAdmin' && item.privilege === 'UserPermission') {
        } else {
          const foundPer = find(permissions, per => per === item.privilege);
          !foundPer && newArr.splice(itemPosition, 1);
        }
      }
    }
  });
  return newArr;
};

export const groupUserPermission = (filteredPermission, menuItems) => {
  let newMenuItems = menuItems.map(i => ({
    ...i,
    children: []
  }));

  menuItems.forEach((menuItem, index) => {
    if (menuItem?.children && !menuItem.isItem)
      menuItem.children.forEach(subItem => {
        if (subItem?.children && subItem?.children[0].privilege) {
          let filteredPerItem = filteredPermission.find(
            i => i.privilege === subItem.privilege
          );

          if (filteredPerItem?.children) {
            newMenuItems[index].children.push({
              ...subItem,
              children: []
            });
            subItem.children.forEach(child => {
              const foundPer = find(
                filteredPerItem?.children,
                per => per.privilege === child.privilege
              );
              if (foundPer) {
                return newMenuItems[index]?.children[
                  newMenuItems[index]?.children.length - 1
                ].children.push(child);
              }
            });
          }
        } else {
          const foundPer = find(
            filteredPermission,
            per => per.privilege === subItem.privilege
          );
          if (foundPer) {
            return newMenuItems[index]?.children.push(subItem);
          }
        }
      });
    else if (!menuItem.privilege) {
    } else if (menuItem.isItem) {
      const foundPer = find(
        filteredPermission,
        per => per.privilege === menuItem.privilege
      );
      if (!foundPer) {
        newMenuItems = newMenuItems.map(i =>
          i.privilege === menuItem.privilege ? { ...i, shouldDel: true } : i
        );
      }
    }
  });
  newMenuItems = newMenuItems.filter(
    i => !i.shouldDel && (!i.isGroupItem || !isEmpty(i?.children))
  );
  return newMenuItems;
};

export const groupRolePermission = (filteredPermission, menuItems) => {
  let newMenuItems = menuItems.map(i => ({ ...i, children: [] }));

  const { email } = configure.store.getState().auth.userInfo;
  menuItems.forEach((menuItem, index) => {
    if (menuItem?.children && !menuItem.isItem) {
      menuItem.children.forEach(subItem => {
        // menu lv 1
        const foundPerSubItem = find(
          filteredPermission,
          per => per === subItem.privilege
        );

        if (foundPerSubItem) {
          // menu lv 2
          if (subItem?.children && subItem?.children[0].privilege) {
            newMenuItems[index].children.push({
              ...subItem,
              children: []
            });
            subItem.children.forEach(child => {
              const foundPer = find(
                filteredPermission,
                per => per === child.privilege
              );
              if (foundPer) {
                return newMenuItems[index]?.children[
                  newMenuItems[index]?.children.length - 1
                ].children.push(child);
              }
            });
          } else {
            const foundPer = find(
              filteredPermission,
              per => per === subItem.privilege
            );

            if (foundPer) {
              newMenuItems[index].children.push(subItem);
            }
          }
        } else {
          if (subItem?.children && subItem?.children[0].privilege) {
            let deepMenu3 = [];
            let deepMenu4 = [];

            subItem.children.forEach(child => {
              if (child) {
                const foundPer = find(
                  filteredPermission,
                  per => per === child.privilege
                );
                if (foundPer) {
                  // menu lv3
                  deepMenu3.push(child);
                } else {
                  // menu lv4
                  if (child?.children && child?.children[0].value) {
                    child.children.forEach(subChild => {
                      const foundPer = find(
                        filteredPermission,
                        per => per === subChild.value
                      );

                      if (foundPer) {
                        deepMenu4.push(child);
                      }
                    });
                  }
                  if (!isEmpty(deepMenu4)) {
                    deepMenu3 = deepMenu3.concat(child);
                  }
                }
              }
            });

            if (!isEmpty(deepMenu3))
              newMenuItems[index].children.push({
                ...subItem,
                children: deepMenu3
              });
          }
          // Show menu API support when role is 'SuperAdmin && based on list email
          if (subItem.isSupport && renderListEmails().includes(email)) {
            newMenuItems[index].children.push(subItem);
          }
        }
      });
    } else if (menuItem.isItem) {
      const foundPer = find(
        filteredPermission,
        per => per === menuItem.privilege
      );
      if (!foundPer) {
        newMenuItems = newMenuItems.map(i =>
          i.privilege === menuItem.privilege ? { ...i, shouldDel: true } : i
        );
      }
    }
  });

  newMenuItems = newMenuItems.filter(
    i => !i.shouldDel && (!i.isGroupItem || !isEmpty(i?.children))
  );

  return newMenuItems;
};
