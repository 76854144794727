import upcomingHSDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  }
};

const upcomingHSReducer = upcomingHSDispatcher(initialState, {
  [upcomingHSDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [upcomingHSDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value
  }),
  [upcomingHSDispatcher.resetData]: (state, _) => ({
    ...initialState
  })
});

export default upcomingHSReducer;
