import { notificationDispatcher } from '../actions/index';
import {
  handleGetNotifySuccess,
  handleReadNotifySuccess,
  handleReadAllNotifySuccess,
  handlePushNotification
} from './utils';
const initialState = {
  notificationData: {
    list: [],
    totalItem: 0,
    countNotifyUnread: 0
  }
};

const reducer = notificationDispatcher(initialState, {
  [notificationDispatcher.getNotifyUnreadSuccess]: (state, payload) => ({
    notificationData: {
      ...state.notificationData,
      countNotifyUnread: payload.result
    }
  }),
  [notificationDispatcher.getNotificationSuccess]: (state, payload) => ({
    notificationData: handleGetNotifySuccess(state.notificationData, payload)
  }),
  [notificationDispatcher.readNotificationSuccess]: (state, payload) => ({
    notificationData: handleReadNotifySuccess(
      state.notificationData,
      payload.id
    )
  }),
  [notificationDispatcher.readAllNotificationSuccess]: state => ({
    notificationData: handleReadAllNotifySuccess(state.notificationData)
  }),
  [notificationDispatcher.pushNotification]: (state, payload) => {
    return {
      notificationData: handlePushNotification(state.notificationData, payload)
    };
  }
});

export default reducer;
