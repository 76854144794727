import React, { useState, useRef, useEffect } from 'react';

import {
  VisibilityRounded,
  PlayCircleFilledRounded,
  PauseCircleFilledRounded,
  StopRounded
} from '@material-ui/icons';
import numeral from 'numeral';
import { IconButton, Select, MenuItem } from '@material-ui/core';
import customToast from '@/new-components/CustomNotification';
import AgoraLiveStream from '../../../agora/agoraLivestream';
import styled from 'styled-components';
import { get } from 'lodash';
import { isSafari } from '../../../helpers';

const AudioInjector = ({ fileList = [], isSharing, isCurrentHost }) => {
  const isHostRef = useRef();
  isHostRef.current = isCurrentHost;
  const [value, setValue] = useState();
  const valueRef = useRef();
  valueRef.current = value;
  const [step, setStep] = useState('init');
  const [initListener, setInitListener] = useState(false);
  const initListenerRef = useRef();
  initListenerRef.current = initListener;

  useEffect(() => {
    setInitListener(false);
    onStopAudioMixing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSharing]);

  useEffect(() => {
    if (!isCurrentHost) {
      setInitListener(false);
      onStopAudioMixing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentHost]);

  const onAudioFinish = () => {
    if (!isHostRef.current) {
      return setStep('init');
    } else {
      setStep('stopped');
    }
    if (valueRef.current.index < fileList.length - 1) {
      let newValue = {
        ...fileList[valueRef.current.index + 1],
        index: valueRef.current.index + 1
      };
      setValue(newValue);
      onStartAudioMixing(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onErr = err => {
    if (err) customToast('error', 'Problem occured when play this url');
    else {
      if (!initListenerRef.current) {
        AgoraLiveStream.instance.registerAudioFinishEvent(onAudioFinish);
        setInitListener(true);
      }
      setStep('playing');
    }
  };

  const onStartAudioMixing = val => {
    let currentValue = val || valueRef.current;
    if (!currentValue) return customToast('error', 'Empty url');
    AgoraLiveStream.instance.addAudioMixing(currentValue.url, onErr);
  };

  const onPauseAudioMixing = () => {
    if (!value) return customToast('error', 'Empty url');
    setStep('paused');
    AgoraLiveStream.instance.pauseAudioMixing();
  };

  const onResumeAudioMixing = () => {
    if (!value) return customToast('error', 'Empty url');
    setStep('playing');
    AgoraLiveStream.instance.resumeAudioMixing();
  };

  const onStopAudioMixing = () => {
    setStep('stopped');
    if (isSafari()) {
      AgoraLiveStream.instance.resumeAudioMixing();
    }
    AgoraLiveStream.instance.stopAudioMixing();
  };
  if (!isCurrentHost) return null;

  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 8,
        marginBottom: 20
      }}
    >
      <WrapperSelect
        key={get(value, 'index', '0')}
        value={get(value, 'index')}
        onChange={e =>
          setValue({
            ...fileList[e.target.value],
            index: e.target.value
          })
        }
        variant="outlined"
        style={{ minWidth: 300 }}
      >
        {fileList &&
          fileList.map((file, index) => (
            <MenuItem key={file.url} value={index}>
              {file.name}
            </MenuItem>
          ))}
      </WrapperSelect>
      <IconButton
        style={{ marginLeft: 8, marginRight: 8 }}
        disabled={!value}
        onClick={() => {
          if (step === 'init' || step === 'stopped') onStartAudioMixing();
          else if (step === 'playing') onPauseAudioMixing();
          else if (step === 'paused') onResumeAudioMixing();
        }}
      >
        {step !== 'playing' ? (
          <PlayCircleFilledRounded />
        ) : (
          <PauseCircleFilledRounded />
        )}
      </IconButton>
      <IconButton
        disabled={['init', 'stopped'].includes(step)}
        onClick={() => {
          !['init', 'stopped'].includes(step) && onStopAudioMixing();
        }}
      >
        <StopRounded />
      </IconButton>
    </div>
  );
};

const LiveCounter = ({ count, fileList, isCurrentHost, isSharing }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 20,
        left: 20,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 20
      }}
    >
      <AudioInjector
        fileList={fileList}
        isSharing={isSharing}
        isCurrentHost={isCurrentHost}
      />
      <div
        style={{
          display: 'flex'
        }}
      >
        <div
          style={{
            borderRadius: 10,
            paddingLeft: 15,
            paddingRight: 15,
            backgroundColor: '#EA6B75',
            color: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 12,
            marginRight: 8
          }}
        >
          LIVE
        </div>
        <div
          style={{
            borderRadius: 4,
            backgroundColor: 'rgba(0,0,0,0.4)',
            color: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 12,
            paddingRight: 12,
            fontSize: 12
          }}
        >
          <VisibilityRounded style={{ fontSize: 12, marginRight: 8 }} />
          {count < 1000 ? count : numeral(count).format('0.0a')}
        </div>
      </div>
    </div>
  );
};

export default LiveCounter;

export const WrapperSelect = styled(Select)`
  .MuiOutlinedInput-input {
    padding: 0;
  }
`;
