import styled from 'styled-components';

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f6f7fa;

  .message-list {
    flex: 1;
    overflow: auto;
  }

  .input-message {
    border-radius: 20px;
    padding: 0px 16px !important;
    background-color: #f6f7fa;
  }

  .rce-mlist > div:first-of-type {
    padding-top: 10px;
  }

  .rce-container-mbox {
    background-color: #f7f7f7;
  }

  .rce-container-input {
    min-width: 0%;
  }

  .rce-input {
    background-color: #f6f7fa;
    border-radius: 20px;
    height: 40px;
    width: 100%;
  }

  .rce-mbox-left-notch {
    display: none;
  }

  .rce-mbox-right-notch {
    display: none;
  }

  .rce-mbox-time {
    text-align: right;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    position: initial;
  }

  .rce-mbox-text {
    font-size: 14px;
    word-break: break-word;
    &:after {
      display: none;
    }
  }

  .rce-mbox-body {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .rce-mbox {
    max-width: 60%;
    min-width: 0px;
    border-radius: 20px !important;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    padding: 0px !important;
    box-shadow: none;
  }

  .rce-mbox-right {
    background-color: #ad5e99;
    color: #ffffff;

    .rce-mbox-file > button {
      background-color: #bd7ead !important;
      max-width: -webkit-fill-available;
      max-width: -moz-available;

      svg {
        color: white !important;
      }
    }

    .rce-mbox-file--text {
      color: white;
    }
    .rce-mbox-time {
      color: #ffffff;
    }
  }

  .rce-mbox-photo {
    border-radius: 20px;

    &:hover {
      background-color: black !important;
    }
  }

  .rce-mbox-photo--img {
    object-fit: cover;
    height: 150px;
    width: 150px;
    border-radius: 20px !important;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .rce-mbox--clear-padding {
    background-color: #f7f7f7 !important;
    box-shadow: none !important;
    padding: 6px 9px 8px 9px !important;
  }

  .rce-mbox-photo--img__block {
    display: none;
  }

  .rce-mbox-file {
    padding-bottom: 0px !important;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rce-mbox-file > button {
    background-color: #efdfeb !important;
    border-radius: 18px;
    min-height: 40px;
    width: 100%;

    svg {
      color: #ad5e99 !important;
    }
  }

  .rce-mbox-file--text {
    font-size: 14px;
    color: #ad5e99;
  }

  .rce-mbox .rce-mbox-right .rce-mbox-file {
    background-color: #f7f7f7 !important;
  }
`;
