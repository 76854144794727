import React, { useCallback, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { debounce } from 'lodash';
import { formatExpressTotalTestServices } from '@/module/all-screenings/utils';
import allScreeningsDispatcher from '@/module/all-screenings/action';

export function useCalculateCost({
  apptData = {},
  billableAmount,
  subsidy,
  projectInfo = {},
  lstTests = [],
  lstProfiles = [],
  lstPackages = [],
  ignoredProfiles = [],
  ignoredTests = [],
  staffType = null
}) {
  const [paxCost, setPaxCost] = useState(null);
  const [isPaxCostCalculating, setIsPaxCostCalculating] = useState(true);

  const formatParamsData = (data, projectId) => {
    return { price: data.price, id: data.id, projectId };
  };

  const calculateCost = useCallback(
    debounce(
      ({
        paxScreeningId,
        projectId,
        staffType,
        lstTests,
        lstProfiles,
        lstPackages,
        ignoredProfiles,
        ignoredTests,
        billableAmount,
        subsidy
      }) => {
        allScreeningsDispatcher.precalculateCost(
          {
            paxScreeningId,
            projectId,
            staffType,
            billableAmount,
            subsidy,
            lstTests: formatExpressTotalTestServices(
              lstTests.reduce(
                (previousValue, currentValue) =>
                  previousValue.concat(currentValue.items),
                []
              )
            ).map(test => formatParamsData(test, test.projectId)),
            lstProfiles: lstProfiles.map(test =>
              formatParamsData(test, test.projectId)
            ),
            lstPackages: lstPackages.map(test =>
              formatParamsData(test, test.projectId)
            ),
            ignoredTests,
            ignoredProfiles
            // lstScreeningFields: lstScreeningFields,
          },
          result => {
            if (result) {
              setPaxCost(result);
              setIsPaxCostCalculating(false);
            }
          }
        );
      },
      500
    ),
    []
  );

  useDeepCompareEffect(() => {
    if (apptData?.isFollowUpAppointment) {
      setPaxCost({
        totalCost: 0,
        remainingCost: 0,
        gst: 0,
        discount: 0,
        subTotal: 0,
        billableAmount: 0,
        subsidy: 0
      });

      setIsPaxCostCalculating(false);
    } else {
      setIsPaxCostCalculating(true);

      if (apptData?.id || projectInfo?.id) {
        calculateCost({
          paxScreeningId: apptData?.id,
          projectId: projectInfo?.id,
          lstTests,
          lstProfiles,
          lstPackages,
          ignoredProfiles,
          ignoredTests,
          staffType,
          billableAmount,
          subsidy
        });
      }
    }
  }, [
    calculateCost,
    lstTests,
    lstProfiles,
    lstPackages,
    ignoredProfiles,
    ignoredTests,
    staffType,
    projectInfo,
    apptData,
    billableAmount,
    subsidy
  ]);

  return { paxCost, isPaxCostCalculating };
}
