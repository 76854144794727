import { synthesize } from 'redux-dispatcher';
import { uploadImage } from '../../../firebase';
import { get } from 'lodash';

const mapDispatchToAC = {
  getDataSuccess: data => ({
    data
  }),
  getData: (search, paging) => async ({ Api, getState }) => {
    const pagingData = paging || getState().banner.paging;
    const { pageIndex, pageSize } = pagingData;

    let { result, status } = await Api.get(`Banners`, {
      pageIndex,
      pageSize,
      search
    });
    if (status === 200) {
      bannerDispatcher.getDataSuccess(result);
    }
  },
  setState: (state, value) => ({ state, value }),
  createBanner: (payload, callbackSuccess) => async ({ Api, customToast }) => {
    let data = {
      order: payload.order,
      name: payload.name,
      startDate: payload.startDate,
      endDate: payload.endDate
    };
    if (payload.url?.constructor === File) {
      bannerDispatcher.setState('loading', true);
      const timeStamp = new Date().getTime();
      data.fileName = timeStamp + get(payload, 'url.name');
      let storageUrl = await uploadImage(payload.url, timeStamp);
      data.url = storageUrl;
    }
    const { status, message } = await Api.post('Banners', data);
    bannerDispatcher.setState('loading', false);
    if (status === 200) {
      callbackSuccess();
      return customToast('success', message || 'Success');
    }
  },
  editBanner: (payload, callbackSuccess) => async ({ Api, customToast }) => {
    const { id, banner } = payload;
    let data = banner;
    if (banner.url?.constructor === File) {
      bannerDispatcher.setState('loading', true);
      const timeStamp = new Date().getTime();
      data.fileName = timeStamp + get(banner, 'url.name');
      let storageUrl = await uploadImage(banner.url, timeStamp);
      data.url = storageUrl;
    }

    const { status, message } = await Api.put(`Banners/${id}`, data);
    bannerDispatcher.setState('loading', false);
    if (status === 200) {
      callbackSuccess();
      return customToast('success', message || 'Edit Success');
    }
  },
  deleteBanner: (id, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.delete(`Banners/${id}`);
    if (status === 200) {
      customToast('success', 'Deleted');
      callbackSuccess && callbackSuccess();
    }
  }
};

const bannerDispatcher = synthesize('banner', mapDispatchToAC);
export default bannerDispatcher;
