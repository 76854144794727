import React from 'react';
import PropTypes from 'prop-types';
function LivestreamIcon({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with sketchtool.</desc>
      <g
        id="🌈-Style-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icons"
          transform="translate(-823.000000, -200.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="Colors-Copy" transform="translate(22.000000, 20.000000)">
            <path
              d="M811.6,180 C806.09,180 801.6,184.49 801.6,190 C801.6,195.51 806.09,200 811.6,200 C817.11,200 821.6,195.51 821.6,190 C821.6,184.49 817.11,180 811.6,180 Z M811.6,198 C807.19,198 803.6,194.41 803.6,190 C803.6,185.59 807.19,182 811.6,182 C816.01,182 819.6,185.59 819.6,190 C819.6,194.41 816.01,198 811.6,198 Z M814.6,190 C814.6,191.66 813.26,193 811.6,193 C809.94,193 808.6,191.66 808.6,190 C808.6,188.34 809.94,187 811.6,187 C813.26,187 814.6,188.34 814.6,190 Z"
              id="Livestream"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

LivestreamIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

LivestreamIcon.defaultProps = {
  width: '22px',
  height: '22px',
  color: '#ccc'
};

export default LivestreamIcon;
