import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, CircularProgress, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ArrowDown } from '@/assets/svg';

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 48
    },
    '& .MuiOutlinedInput-input': {
      marginRight: 0,
      paddingLeft: 5,
      fontSize: 16
    },
    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const CustomAutocomplete = withStyles({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"]': {
      padding: 0
    }
  },
  option: {
    '& span[class="max-line"]': {
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      display: '-webkit-inline-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2
    }
  }
})(Autocomplete);

const CustomAutoComplete = ({
  onSearch,
  onSelected,
  data,
  loading,
  label = '',
  optionKey = 'id',
  optionLabel = '',
  value,
  placeholder = '',
  disabled,
  disableClearable
}) => {
  const [open, setOpen] = useState(false);
  return (
    <CustomAutocomplete
      id="autocompleteTemplate"
      open={open}
      value={value}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) =>
        option[optionKey] === value[optionKey]
      }
      onInputChange={(_, value) => onSearch(value)}
      onChange={(e, value) => onSelected(value)}
      getOptionLabel={option => option[optionLabel] || ''}
      options={data}
      loading={loading}
      popupIcon={<ArrowDown />}
      renderInput={params => (
        <CustomTextField
          {...params}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
      renderOption={option => (
        <span className="max-line">{option[optionLabel] || ''}</span>
      )}
      disabled={disabled}
      disableClearable={disableClearable}
    />
  );
};

CustomAutoComplete.propTypes = {
  onSearch: PropTypes.func,
  onSelected: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string,
  optionKey: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disableClearable: PropTypes.bool
};

export default CustomAutoComplete;
