import { COUNSEL_APPOINTMENT_STATUS } from '../constants';
import { CheckboxActive, CheckboxDefault } from '@/assets/svg';
import CustomAsyncAutoComplete from '@/new-components/CustomAsyncAutoComplete';
import CustomDateRange from '@/new-components/CustomDateRange';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import {
  Box,
  Toolbar,
  TextField,
  Checkbox,
  Paper,
  Button,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { isBoolean, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

const CustomButton = withStyles({
  label: {
    fontWeight: 700,
  },
})(Button);

const CustomFilterInput = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 40,
      borderRadius: 8,

      '& input': {
        padding: 0,
        marginLeft: 0,
      },
      '& input::placeholder': {
        opacity: 1,
        color: '#999999',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  isBtnSelected: {
    border: 'none',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  checkboxIcon: {
    padding: 0,
    marginRight: 4,
  },
  tagStyle: {
    maxWidth: 'calc(100% - 60px)', // Adjust based on your needs
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selectWrapper: {
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },

    '& .MuiAutocomplete-option': {
      padding: 8,
      borderRadius: 8,
      margin: '2px 8px',
      '&:first-child': {
        marginTop: 8,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: '#ffffff',
      },
    },
  },
}));

const GroupToolbar = ({
  filterOption,
  setFilterOption,
  onFilterOptionClear,
  onFilterOptionChange,
  onResetFilterOps,

  counselDate,
  setCounselDate,
  appointmentStatuses,
  setAppointmentStatuses,
  counselIds,
  setCounselIds,
  isCounsellor,
}) => {
  const classes = useStyles();
  const renderValue = (array, label, defaultLabel) => {
    let value = '';
    if (array.length === 0) value = defaultLabel;
    else if (array.length === 1) value = array[0];
    else value = `${label} (${array.length})`;

    return value;
  };

  const renderDateValue = (array, defaultLabel) => {
    /* 
    1. !startDate && !endDate => defaultLabel
    2. startDate && !endDate => startDate format: DD/MM/YYYY
    3. startDate && endDate
      - if startDate and endDate in the same month, year => format(DD-DD/MM/YYYY)
      - else format(DD/MM/YYYY-DD/MM/YYYY)
    */

    if (isEmpty(array)) return defaultLabel;
    const startDate = moment(array[0]).format('DD/MM/YYYY');
    const endDate = moment(array[1]).format('DD/MM/YYYY');
    return `${startDate}-${endDate} `;
  };

  const isEmptyFilterParams = useMemo(() => {
    const filterKeys = Object.keys(filterOption).filter(
      (item) => !['appointmentTypes'].includes(item)
    );
    return filterKeys.every((key) =>
      isBoolean(filterOption[key])
        ? !filterOption[key]
        : isEmpty(filterOption[key])
    );
  }, [filterOption]);

  return (
    <Toolbar>
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gridGap={12}>
          <CustomDateRange
            selectedDate={counselDate || []}
            renderValue={() =>
              renderDateValue(counselDate || [], 'Select appointment date')
            }
            onChange={(startDate, endDate) => {
              if (startDate && endDate) {
                setCounselDate([startDate, endDate]);
                setFilterOption({
                  ...filterOption,
                  fromDate: startDate.toISOString(),
                  toDate: endDate.toISOString(),
                });
              } else {
                setCounselDate([]);
                setFilterOption({
                  ...filterOption,
                  fromDate: null,
                  toDate: null,
                });
              }
            }}
          />
          {isCounsellor ? null : (
            <CustomAsyncAutoComplete
              multiple
              disableCloseOnSelect
              endpoint="Counsellors/filter"
              method="post"
              nameKey="fullName"
              value={counselIds}
              onChange={(e, value) => {
                setFilterOption({
                  ...filterOption,
                  doctorIds: value.map((it) => it.id),
                });
                setCounselIds(value);
              }}
              defaultFilter={{
                sortByName: true,
              }}
              getOptionLabel={(option) => option?.fullName}
              renderInput={(params) => (
                <CustomFilterInput
                  {...params}
                  variant="outlined"
                  placeholder={isEmpty(counselIds) ? 'Select counsellor' : ''}
                />
              )}
              renderOption={(option, { selected }) => {
                return (
                  <Box display="flex" gridGap={4}>
                    <Checkbox
                      icon={<CheckboxDefault width={20} height={20} />}
                      checkedIcon={<CheckboxActive width={20} height={20} />}
                      checked={selected}
                      color="primry"
                      className={classes.checkboxIcon}
                    />
                    <span>{option.fullName}</span>
                  </Box>
                );
              }}
              renderTags={(value, getTagProps) => {
                let newValue = value.filter((it) => counselIds?.includes(it));

                return newValue.length === 1 ? (
                  <div className={classes.tagStyle}>{newValue[0].fullName}</div>
                ) : newValue.length > 1 ? (
                  <Box>Counsellors ({newValue.length})</Box>
                ) : (
                  ''
                );
              }}
              PaperComponent={({ children, ...other }) => (
                <Paper
                  {...other}
                  style={{ width: 320 }}
                  className={classes.selectWrapper}
                >
                  {children}
                </Paper>
              )}
              style={{ width: counselIds.length === 1 ? 200 : 160 }}
              isSelected={counselIds.length === 1}
            />
          )}

          <CustomSelectContainer
            isFullWidth
            filterValue={appointmentStatuses || []}
            data={COUNSEL_APPOINTMENT_STATUS}
            renderValue={renderValue(
              appointmentStatuses
                .filter((it) => it !== 'Assigned')
                .map(
                  (it) =>
                    COUNSEL_APPOINTMENT_STATUS.find((item) => item.key === it)
                      ?.value
                ),
              'Statuses',
              'Select status'
            )}
            onClear={onFilterOptionClear(
              'appointmentStatuses',
              COUNSEL_APPOINTMENT_STATUS,
              setAppointmentStatuses
            )}
            onChange={onFilterOptionChange(
              'appointmentStatuses',
              setAppointmentStatuses
            )}
            labelSelectAll={'All statuses'}
            useSelectAll
            checkedAll={
              COUNSEL_APPOINTMENT_STATUS.length ===
              appointmentStatuses?.filter((it) => it !== 'Assigned').length
            }
          />
        </Box>

        <CustomButton
          color="primary"
          disabled={isEmptyFilterParams}
          onClick={onResetFilterOps}
        >
          Reset
        </CustomButton>
      </Box>
    </Toolbar>
  );
};

export default GroupToolbar;
