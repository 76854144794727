import { get } from 'lodash';

let timer;

const debounced = (fn, timeout = 200) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    fn();
    timer = null;
  }, timeout);
};

const getBase64Promise = img => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result));
    reader.readAsDataURL(img);
  });
};

const getBase64Callback = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const parseNotifyFromFirebase = payload => {
  return {
    body: get(payload, 'notification.body'),
    id: get(payload, 'data.id'),
    isRead: false,
    notificationOf: get(payload, 'data.notificationOf'),
    notifiedTime: get(payload, 'data.notifiedTime'),
    title: get(payload, 'notification.title')
  };
};

export {
  debounced,
  getBase64Promise,
  getBase64Callback,
  parseNotifyFromFirebase
};
