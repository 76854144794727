import locationDispatcher from '../action';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  }
};

const locationReducer = locationDispatcher(initialState, {
  [locationDispatcher.getLocationSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  }),
  [locationDispatcher.setState]: (state, payload) => ({
    [payload.state]: payload.value
  })
});

export default locationReducer;
