import AvatarInCall from './AvatarInCall';
import { LocalStreamContainer, LocalSubUserStreamContainer } from './styled';
import { MincAdmin } from '@/assets/svg';
import { Box, Typography } from '@material-ui/core';
import React from 'react';

const StreamSharingPanel = ({
  data,
  isMuteVideo,
  transform,
  bgcolor,
  height,
  deviceType,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      justifyContent="center"
      alignItems="center"
      gridGap={24}
      bgcolor={bgcolor}
      px={2.5}
      height={height}
    >
      <LocalSubUserStreamContainer
        key="sub_user_stream"
        id="sub_user_stream"
        style={{ border: '6px solid #50a5ed', borderRadius: 22 }}
        deviceType={deviceType}
      >
        <Box className="mute-video-layer">
          <Box
            position="absolute"
            top="50%"
            left="50%"
            style={{ transform: 'translate(-50%, -50%)' }}
            zIndex={2}
            border="unset !important"
          >
            <AvatarInCall isPatient>
              <MincAdmin color={'#FFE9FB'} />
            </AvatarInCall>
          </Box>
          <Typography className="text">
            {data.FullName || data.PatientFullName}
          </Typography>
        </Box>
      </LocalSubUserStreamContainer>

      <LocalStreamContainer
        key="sub_stream"
        id="sub_stream"
        transform={transform}
        isMuteVideo={isMuteVideo}
        style={{ border: '1px solid #999999' }}
      >
        <Box className="mute-video-layer">
          <Box
            position="absolute"
            top="50%"
            left="50%"
            style={{ transform: 'translate(-50%, -50%)' }}
            zIndex={2}
            border="unset !important"
          >
            <AvatarInCall isPatient>
              <MincAdmin color={'#FFE9FB'} />
            </AvatarInCall>
          </Box>
          <Typography className="text">You</Typography>
        </Box>
      </LocalStreamContainer>
    </Box>
  );
};

export default StreamSharingPanel;
