import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button
} from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragIndicator } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import companyDispatcher from '../../action';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import customToast from '@/new-components/CustomNotification';

//#region --- Definition component parts style
const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    borderBottom: 'solid 1px #f0f0f0',
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  },
  titleText: {
    color: '#333333',
    fontWeight: 600,
    fontSize: 18,
    fontFamily: 'Lato'
  }
});

const CustomDialogTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.titleText}>{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
});

const EditSequenceDialog = withStyles(theme => ({
  paper: {
    borderRadius: 16
  },
  paperWidthXs: {
    maxWidth: 500
  },
  paperWidthSm: {
    width: 600,
    maxWidth: 600,
    minWidth: 400,
    height: 'fit-content'
  }
}))(Dialog);

const EditSequenceDialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2.5),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',

    '& .main-content': {
      marginTop: 30,
      fontSize: 32,
      fontWeight: 600
    },
    '& .sub-content': {
      fontSize: 16,
      color: '#666666'
    },
    '&:first-child': {
      paddingTop: theme.spacing(5)
    }
  }
}))(DialogContent);

const EditSequenceDialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2.5),
    borderTop: 'solid 1px #f0f0f0'
  }
}))(DialogActions);

const EditSequenceButton = withStyles({
  root: {
    width: 166,
    height: 40,
    borderRadius: 8,

    fontSize: 18,
    fontWeight: 400,
    textTransform: 'unset'
  },
  containedPrimary: {
    boxShadow: 'none'
  }
})(Button);

const EditSequenceTypography = withStyles({
  root: {
    width: '100%',
    textAlign: 'left'
  }
})(Typography);

const EditSequenceDragIndicator = withStyles(theme => ({
  root: {
    margin: theme.spacing(0, 2.75)
  }
}))(DragIndicator);

const Container = styled('ul')`
  display: inline;
  background-color: ${props => (props.isDraggingOver ? '#639ee2' : 'inherit')};
  li {
    list-style-type: none;
    text-align: center;
    border: solid 1px #d9d9d9;
    margin: auto 0px;
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin: 0px 0px 8px;
    justify-content: space-between;
  }
`;

const NoneDraggableItem = styled('li')`
  background-color: #f0f0f0;
  cursor: default;
  p {
    color: #bfbfbf;
  }
`;

//#endregion

export default function EditSequenceStationsModal(props) {
  const { isOpen, projectId, projectName, onCloseModal } = props;
  const [sequenceStations, setSequenceStations] = useState({
    draggable: [],
    noneDraggable: []
  });

  const handleDisableFollowUp = array => {
    const itemsDraggable = [],
      itemsNoneDrag = [];
    array.forEach(item => {
      if (item.isFollowUpStation) {
        itemsNoneDrag.push(item);
      } else itemsDraggable.push(item);
    });
    return {
      draggable: itemsDraggable,
      noneDraggable: itemsNoneDrag
    };
  };

  const getSequenceStationsData = () => {
    companyDispatcher.getStationsByProject(projectId, result => {
      const splitUpResult = handleDisableFollowUp(result);
      setSequenceStations(splitUpResult);
    });
  };

  useEffect(() => {
    getSequenceStationsData();
  }, []);

  const handleCloseModal = () => {
    onCloseModal && onCloseModal();
  };

  const handleDragEnd = result => {
    if (!result) {
      return;
    }
    let modifyArray = cloneDeep(sequenceStations.draggable);
    const sourceIndex = result.source.index,
      destinationIndex = result.destination.index;
    if (destinationIndex >= modifyArray.length) {
      var k = sourceIndex - modifyArray.length + 1;
      while (k--) {
        modifyArray.push(undefined);
      }
    }
    modifyArray.splice(
      destinationIndex,
      0,
      modifyArray.splice(sourceIndex, 1)[0]
    );
    setSequenceStations(prev => ({ ...prev, draggable: modifyArray }));
  };

  const renderToast = () => {
    return customToast(
      'success',
      <span>
        Station sequence for project{' '}
        <strong style={{ fontWeight: 600 }}>{projectName}</strong> has been
        updated
      </span>,
      'Station sequence updated'
    );
  };

  const handleOnsubmit = () => {
    const noneDrag = cloneDeep(sequenceStations.noneDraggable),
      draggable = cloneDeep(sequenceStations.draggable);

    const fullListSequences = draggable?.concat(noneDrag);
    const submitArray = fullListSequences.map((item, index) => ({
      ...item,
      order: ++index
    }));

    companyDispatcher.editSequencesStations(projectId, submitArray, () => {
      renderToast();
      handleCloseModal();
    });
  };

  const renderModalContent = renderData => {
    return (
      <EditSequenceDialogContent>
        <EditSequenceTypography style={{ fontWeight: 600, paddingTop: '20px' }}>
          Drag and drop stations to edit the sequence
        </EditSequenceTypography>
        {renderData?.draggable.length || renderData?.noneDraggable.length ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
              droppableId="all-column"
              type="column"
              direction="vertical"
            >
              {(provided, snapshot) => (
                <Container ref={provided.innerRef} {...provided.droppableProps}>
                  <div
                    style={{
                      marginBottom: `${
                        snapshot?.draggingFromThisWith ? '60px' : '0px'
                      }`
                    }}
                  >
                    {renderData.draggable.map((item, index) => (
                      <Draggable
                        key={item.stationId}
                        draggableId={item.stationId}
                        index={index}
                      >
                        {provided => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <EditSequenceDragIndicator />
                            <EditSequenceTypography>
                              {item.stationName}
                            </EditSequenceTypography>
                          </li>
                        )}
                      </Draggable>
                    ))}
                  </div>
                  <div>
                    {renderData.noneDraggable.map(item => {
                      return (
                        <NoneDraggableItem>
                          <EditSequenceDragIndicator />
                          <EditSequenceTypography>
                            {item.stationName}
                          </EditSequenceTypography>
                        </NoneDraggableItem>
                      );
                    })}
                  </div>
                </Container>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          ''
        )}
      </EditSequenceDialogContent>
    );
  };

  return (
    <EditSequenceDialog open={isOpen} onClose={e => handleCloseModal()}>
      <CustomDialogTitle onClose={e => handleCloseModal()}>
        Edit station sequence
      </CustomDialogTitle>
      {renderModalContent(sequenceStations)}
      <EditSequenceDialogActions>
        <EditSequenceButton
          onClick={e => handleOnsubmit()}
          variant="contained"
          color="primary"
          disabled={false}
        >
          Save
        </EditSequenceButton>
      </EditSequenceDialogActions>
    </EditSequenceDialog>
  );
}
