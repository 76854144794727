import serviceDispatcher from '@/module/services/action';
import React from 'react';
import ServiceModalContainer from '../../common/ServiceModalContainer';
import TestForm from './TestForm';
import { TEST_SERVICES_STEPS } from '@/module/services/constants';

const TestModalContainer = props => (
  <ServiceModalContainer
    {...{
      ...props,
      prefixName: 'Test',
      category: 'Tests',
      createDispatcher: serviceDispatcher.createTest,
      editDispatcher: serviceDispatcher.editTest,
      getServiceByID: serviceDispatcher.getServiceByID,
      schema: 'serviceSchema',
      requireLabCode: true,
      requireLoinc: true,
      labCodeLabel: 'Test Code',
      ServicesForm: TestForm,
      steps: TEST_SERVICES_STEPS,
      listValidKeys: ['name', 'labHeader', 'loinc', 'euroFinLOINC']
    }}
  />
);

export default TestModalContainer;
