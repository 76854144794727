import React from 'react';
import { TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { renderHeadTableBooking } from '../utils';

const useStyle = makeStyles({
  txtCell: {
    fontSize: '14px',
    lineHeight: '21px',
    color: '#192637'
  },
  txtDay: {
    fontSize: '24px',
    lineHeight: '32px',
    color: '#192637'
  }
});

const TableHeadBooking = ({ keys }) => {
  const classes = useStyle();

  return (
    <TableHead>
      <TableRow>
        <TableCell align="center">Hour</TableCell>
        {renderHeadTableBooking(keys, classes)}
      </TableRow>
    </TableHead>
  );
};

TableHeadBooking.propTypes = {
  keys: PropTypes.array.isRequired
};

export default TableHeadBooking;
