import serviceDispatcher from '@/module/services/action';
import React from 'react';
import ServiceModalContainer from '../../common/ServiceModalContainer';
import MeasurementForm from './MeasurementForm';

const MeasurementModalContainer = props => (
  <ServiceModalContainer
    {...{
      ...props,
      prefixName: 'Measurement',
      category: 'Measurements',
      createDispatcher: serviceDispatcher.createTest,
      editDispatcher: serviceDispatcher.editTest,
      getServiceByID: serviceDispatcher.getServiceByID,
      schema: 'serviceSchema',
      requireLabCode: true,
      requireLoinc: false,
      ServicesForm: MeasurementForm,
      listValidKeys: ['name', 'labHeader']
    }}
  />
);

export default MeasurementModalContainer;
