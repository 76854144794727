import Enum from '../../../enum/Enum';
const SEARCH_PARAM_CLINIC = {
  name: '',
  address: '',
  clinicStatus: []
};

const STATUS_CLINIC = Enum({
  Open: 'Open',
  TemporarilyUnavailable: 'Temp',
  Closed: 'Closed'
});

const TYPE_MODAL = {
  Create: 'Create',
  View: 'View',
  Edit: 'Edit',
  MakeUnavailable: 'MakeUnavailable',
  Close: 'Close'
};

const LIST_REQUIRED = [
  'name',
  'consultPlan',
  'address',
  'platoName',
  'unitNumber',
  'reviewLink'
];
const LIST_REQUIRED_EDIT = [
  'name',
  'consultPlan',
  'address',
  'platoName',
  'unitNumber',
  'reviewLink'
];

export {
  LIST_REQUIRED,
  LIST_REQUIRED_EDIT,
  SEARCH_PARAM_CLINIC,
  STATUS_CLINIC,
  TYPE_MODAL
};
