/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
  DialogContent,
  TextField,
  Grid,
  FormLabel,
  Typography
} from '@material-ui/core';
import {
  DatePicker,
  TimePicker,
  LocalizationProvider
} from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { ClearRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';

import LoadingButton from '@/components/LoadingButton';
import validateData from '@/helpers/validationHelpers/validationSchema';
import MultipleSelection from '@/components/CustomSelect/MutipleSelection';

import CustomRadioBtn from '../CustomRadioBtn';
import {
  FORMAT_DATE_TIME,
  FORMAT_DD_MM_YYYY,
  MIN_TIME_PICK,
  MAX_TIME_PICK,
  DAYS_OF_WEEK
} from '../../constants';
import ModalPage, { ModalFooter } from './styled';
import { ButtonEnhance } from '../../../common/componentUI/commonStyleComponents';
import moment from 'moment';

const regexNumber = /\D/;

const renderDayOfWeek = () => {
  const results = [];
  for (let key in DAYS_OF_WEEK) {
    results.push(key);
  }
  return results;
};

const DAY_OF_WEEK = renderDayOfWeek();

const ModalEditBookingBlock = ({ onClose, submitForm }) => {
  const [formValue, setFormValue] = useState({
    startDate: null,
    endDate: null,
    option: null,
    startTime: MIN_TIME_PICK,
    endTime: MAX_TIME_PICK,
    baseSlot: '',
    daysOfWeek: []
  });
  const [errors, setErrors] = useState({});
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const onChangeDayOfWeeks = event => {
    const { value = [] } = event.target;

    let results = [];

    if (value.includes('selectAll')) {
      if (checkIsSelectAll()) {
        results = [];
      } else {
        results = [...DAY_OF_WEEK];
      }
    } else {
      results = [...value];
    }
    setFormValue({ ...formValue, daysOfWeek: results });
    setDaysOfWeek(results);
  };

  const handleSubmit = async () => {
    try {
      await validateData('validationEditBooking', formValue, validData => {
        setShowLoader(true);
        submitForm(validData, () => setShowLoader(false));
      });
    } catch (errs) {
      setErrors(errs);
    }
  };

  const onChangeForm = key => value => {
    if (key === 'baseSlot') {
      if (!value) setFormValue({ ...formValue, [key]: '' });

      if (value && !value.match(regexNumber)) {
        setFormValue({ ...formValue, [key]: value });
      }
      return;
    }
    setFormValue({ ...formValue, [key]: value });
  };

  const handleChangeDate = key => value => {
    if (
      key === 'endTime' &&
      moment(value).format(FORMAT_DATE_TIME) ===
        moment(MIN_TIME_PICK).format(FORMAT_DATE_TIME)
    )
      value = MAX_TIME_PICK;
    setFormValue({ ...formValue, [key]: value });
  };

  const checkIsSelectAll = () => {
    return DAY_OF_WEEK.length === daysOfWeek.length;
  };

  const isSelectAll = checkIsSelectAll();

  const renderContentPage = () => {
    return (
      <ModalPage>
        <DialogContent>
          <div className="header">
            Edit Booking Time
            <ClearRounded
              style={{ cursor: 'pointer', float: 'right', color: '#CACFD3' }}
              onClick={onClose}
            />
          </div>
          <div className="body">
            <LocalizationProvider dateAdapter={MomentAdapter}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <DatePicker
                    value={formValue.startDate}
                    onChange={handleChangeDate('startDate')}
                    name="startDate"
                    disablePast
                    inputFormat={FORMAT_DD_MM_YYYY}
                    renderInput={props => (
                      <TextField
                        {...props}
                        label="Start date"
                        style={{ width: '100%', marginTop: 10 }}
                        error={errors.startDate}
                        helperText={errors.startDate}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="End date"
                    value={formValue.endDate}
                    onChange={handleChangeDate('endDate')}
                    name="endDate"
                    inputFormat={FORMAT_DD_MM_YYYY}
                    disablePast
                    renderInput={props => (
                      <TextField
                        {...props}
                        style={{ width: '100%', marginTop: 10 }}
                        error={errors.endDate}
                        helperText={errors.endDate}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultipleSelection
                    labelId="dayOfWeek"
                    inputLabel="Select days of week"
                    value={daysOfWeek}
                    data={DAY_OF_WEEK}
                    error={errors.daysOfWeek}
                    onChange={onChangeDayOfWeeks}
                    formClasses={{
                      width: '100%',
                      maxWidth: '100%',
                      margin: 0,
                      padding: 0
                    }}
                    renderValue={() => {
                      return daysOfWeek.join(', ');
                    }}
                    useSelectAll
                    checkedAll={isSelectAll}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    clearable
                    label="Start Time"
                    minTime={MIN_TIME_PICK}
                    maxTime={MAX_TIME_PICK}
                    value={formValue.startTime}
                    onChange={handleChangeDate('startTime')}
                    name="startDate"
                    minutesStep={15}
                    views={['hours', 'minutes']}
                    renderInput={props => (
                      <TextField
                        {...props}
                        style={{ width: '100%', marginTop: 10 }}
                        error={errors.startTime}
                        helperText={errors.startTime}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    clearable
                    label="End Time"
                    value={formValue.endTime}
                    onChange={handleChangeDate('endTime')}
                    minutesStep={15}
                    views={['hours', 'minutes']}
                    name="endTime"
                    minTime={MIN_TIME_PICK}
                    maxTime={MAX_TIME_PICK}
                    renderInput={props => (
                      <TextField
                        {...props}
                        style={{ width: '100%', marginTop: 10 }}
                        error={errors.endTime}
                        helperText={errors.endTime}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormLabel error={errors.option}>Select Option</FormLabel>
                  <CustomRadioBtn
                    value={formValue.option}
                    onChange={onChangeForm}
                  />
                  <Typography color="error" variant="caption">
                    {errors.option}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel error={errors.baseSlot}>Number of slots</FormLabel>
                  <TextField
                    error={errors.baseSlot}
                    helperText={errors.baseSlot}
                    style={{ width: '100%', height: 40, paddingTop: 4 }}
                    value={formValue.baseSlot}
                    onChange={e => onChangeForm('baseSlot')(e.target.value)}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </div>
          <ModalFooter>
            <ButtonEnhance
              background="#E9E9E9"
              color="black"
              backgroundHover="#ccc"
              onClick={onClose}
            >
              Close
            </ButtonEnhance>
            <LoadingButton onClick={handleSubmit} showLoader={showLoader}>
              Update
            </LoadingButton>
          </ModalFooter>
        </DialogContent>
      </ModalPage>
    );
  };

  return <>{renderContentPage()} </>;
};

ModalEditBookingBlock.propTypes = {
  onClose: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired
};

export default ModalEditBookingBlock;
