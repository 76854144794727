import React, { useState, useEffect, useMemo } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  Dialog,
  Modal,
  Typography,
  Fab,
  Tooltip
} from '@material-ui/core';
import { get } from 'lodash';
import {
  MoreVertRounded,
  DeleteRounded,
  GetAppRounded
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TableContainer from '../../../components/Table/TableContainer';
import { SEARCH_PARAM, TYPE_MODAL, FORMAT_DATE_TIME } from '../constants';
import { MenuAction, SearchStudioClass } from '../components';
import StudioClassFormPage from './StudioClassFormPage';
import studioClassDispatcher from '../actions/index';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import ModalPresenter from '../../../components/ModalPresenter';
import { formatCurrency } from '../../../helpers/formatCurrency';

import './styles.scss';

const StudioClassListPage = () => {
  const { list, paging } = useSelector(state => state.studioClass);

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [filterOption, setFilterOption] = useState(SEARCH_PARAM);

  const [studioClassSelected, setStudioClassSelected] = useState(null);

  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalForm, setShowModalForm] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});

  useEffect(() => {
    studioClassDispatcher.getCategoryStudioClass();
    studioClassDispatcher.getDurationIntensity();
    studioClassDispatcher.getAvailableTrainer();
    studioClassDispatcher.getStudio();
  }, []);

  const reGetStudioClassList = () => {
    setFilterOption({ ...filterOption });
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCellFiltedWrapper label="Workout Name" stateValue="title" />
        <TableCellFiltedWrapper
          label="Workout Type"
          stateValue="categoryTitle"
        />
        <TableCellFiltedWrapper
          label="Trainer"
          stateValue="trainerStudioClassName"
        />
        <TableCellFiltedWrapper label="Date Time" stateValue="date" />
        <TableCellFiltedWrapper label="Studio" stateValue="studioName" />
        <TableCellFiltedWrapper label="Price" stateValue="price" />
        <TableCellFiltedWrapper label="Slot" stateValue="slot" />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const onShowModalForm = type => {
    setTypeModal(type);
    setShowModalForm(true);
    setAnchorEl(null);
  };

  const onCloseModalForm = () => {
    setTypeModal(null);
    setShowModalForm(false);
    setStudioClassSelected(null);
  };

  const showModalConfirmDelete = () => {
    setShowModalConfirm(true);
    setAnchorEl(null);
  };

  const handleDeleteWorkout = () => {
    studioClassDispatcher.deleteStudioClass(studioClassSelected.id, () => {
      setFilterOption({ ...filterOption });
    });
    setShowModalConfirm(false);
  };

  const listTableButtons = useMemo(
    () => [
      <Fab
        style={{
          backgroundColor: '#ad5e99',
          color: 'white',
          width: 40,
          height: 40,
          margin: 5
        }}
        aria-label="export"
        onClick={studioClassDispatcher.getExportExcel}
      >
        <Tooltip title="Export excel">
          <GetAppRounded style={{ fontSize: 18 }} />
        </Tooltip>
      </Fab>
    ],
    []
  );

  return (
    <>
      {showModalConfirm && (
        <Modal
          open={showModalConfirm}
          onClose={() => setShowModalConfirm(false)}
        >
          <div>
            <ModalPresenter
              onClose={() => setShowModalConfirm(false)}
              Icon={
                <DeleteRounded style={{ fontSize: 80, color: '#EA6B75' }} />
              }
              title={`Delete '${get(studioClassSelected, 'title')}' Workout?`}
              onClick={handleDeleteWorkout}
            >
              <Typography>Click “Continue” to delete</Typography>
              <Typography>
                <Typography style={{ fontWeight: 600 }} display={'inline'}>
                  {studioClassSelected.fullName}
                </Typography>
              </Typography>
            </ModalPresenter>
          </div>
        </Modal>
      )}
      {showModalForm && (
        <Dialog
          open={showModalForm}
          onClose={() => setShowModalForm(false)}
          maxWidth="md"
          fullWidth
          scroll="body"
          disableEnforceFocus
        >
          <StudioClassFormPage
            reGetStudioClassList={reGetStudioClassList}
            typeModal={typeModal}
            onCloseModal={onCloseModalForm}
            StudioClassDetail={studioClassSelected}
          />
        </Dialog>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && (
          <MenuAction
            onShowModal={onShowModalForm}
            onShowConfirmDelete={showModalConfirmDelete}
          />
        )}
      </Menu>

      <TableContainer
        title="On Studio Classes Workouts"
        paging={paging}
        data={list}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterOption={filterOption}
        query={studioClassDispatcher.getStudioClassList}
        onAddNew={() => {
          setStudioClassSelected(null);
          onShowModalForm(TYPE_MODAL.Create);
        }}
        ToolbarComponent={
          <SearchStudioClass
            onSearch={data => {
              setFilterOption({ ...filterOption, ...data });
            }}
          />
        }
        renderRow={row => (
          <>
            <TableCell>{!hiddenRows.title && row.title}</TableCell>
            <TableCell>
              {!hiddenRows.categoryTitle && row.categoryTitle}
            </TableCell>
            <TableCell>
              {!hiddenRows.trainerStudioClassName && row.trainerStudioClassName}
            </TableCell>
            <TableCell>
              {!hiddenRows.date && moment(row.date).format(FORMAT_DATE_TIME)}
            </TableCell>
            <TableCell>{!hiddenRows.studioName && row.studioName}</TableCell>
            <TableCell>
              {!hiddenRows.price && formatCurrency(row.price)}
            </TableCell>
            <TableCell>{!hiddenRows.slot && row.slot}</TableCell>
            <TableCell align="right">
              <IconButton
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  setStudioClassSelected(row);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
        customButtons={listTableButtons}
      ></TableContainer>
    </>
  );
};

export default StudioClassListPage;
