/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  TableRow,
  TableContainer,
  Table,
  makeStyles,
  TableBody
} from '@material-ui/core';
import { chunk, isEmpty } from 'lodash';

import TablePagination from '@/module/common/componentUI/TablePagination';

const useStyles = makeStyles({
  container: {
    paddingTop: '20px'
  },
  btnAddTimeSlot: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  infoSecondary: {
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: 16,
    marginTop: 16
  },
  button: {
    height: 35,
    borderRadius: 50
  },
  btnText: {
    height: 18,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
    letterSpacing: '0.02px'
  },
  divTxtTable: {
    padding: '16px 0 16px',
    borderBottom: '1px solid #e9e9e9'
  },
  descTxtTable: {
    fontSize: 14,
    textAlign: 'center',
    color: '#8F9BA9',
    margin: 0
  }
});

const InlineTable = ({
  data = [],
  renderRow,
  Header,
  Footer,
  onDeleteValue,
  tableValue,
  setTableValue,
  noDataHelperText,
  isPagination = false,
  stickyHeader,
  pagingDefault,
  setPagingDefault,
  ...props
}) => {
  const classes = useStyles();

  const [paging, setPaging] = useState({
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0
  });

  const [localData, setLocalData] = useState(data);

  const onChangePaging = value => {
    setPaging(value);
    setPagingDefault && setPagingDefault(value);
  };

  useEffect(() => {
    if (data) {
      setLocalData(data);
      setPaging({ ...paging, totalItem: data.length });
    }
  }, [data]);

  useEffect(() => {
    if (isPagination) {
      const { pageIndex, pageSize } = paging;
      const newData = chunk(data, pageSize)[pageIndex] || [];
      setLocalData(newData);
    }
  }, [paging]);

  useEffect(() => {
    if (pagingDefault && !isEmpty(pagingDefault)) setPaging(pagingDefault);
  }, [pagingDefault]);

  return (
    <div className={classes.container} {...props}>
      <TableContainer style={{ overflowX: 'visible' }}>
        {Footer && <Footer />}
        <Table stickyHeader={stickyHeader}>
          {Header && <Header />}
          <TableBody>
            {!isEmpty(localData) &&
              localData.map((d, i) => (
                <TableRow key={`${d?.id}-${i}`} hover>
                  {renderRow(d, i, onDeleteValue, tableValue, setTableValue)}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {noDataHelperText && isEmpty(data) && (
        <div className={classes.divTxtTable}>
          <p className={classes.descTxtTable}>{noDataHelperText}</p>
        </div>
      )}
      {isPagination && (
        <TablePagination paging={paging} onChange={onChangePaging} />
      )}
    </div>
  );
};

export default InlineTable;
