import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  uploadTestResult: (data, { resolve, reject }) => async ({
    getState,
    Api
  }) => {
    const { status } = await Api.post(
      `TestResultFiles/upload`,
      data,
      '',
      false,
      true,
      true
    );
    if (status === 200) {
      const { progress, progressItem } = getState().labResult;
      labResultDispatcher.updateProgress(progress + progressItem);
      resolve && resolve();
    } else {
      reject && reject();
    }
  },
  submitTestsResult: (data, callback) => async ({ Api, customToast }) => {
    const { status } = await Api.post(`TestResultFiles/submit-files`, data);
    if (status === 200) {
      labResultDispatcher.updateProgress(100);
      customToast('success', 'Upload success');
    }
    callback && callback(status);
  },
  updateProgress: progress => ({ progress }),
  setState: (state, value) => ({ state, value })
};

const labResultDispatcher = synthesize('labResult', mapDispatchToAC);
export default labResultDispatcher;
