import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Paper,
  InputAdornment
} from '@material-ui/core';
import { EyeHide, EyeShow } from '../../../../assets/svg';
import styled from 'styled-components';
import './styles.scss';
import LoadingButton from '../../../../components/LoadingButton';
import profileDispatcher from '../action/profile';
const ChangePasswordForm = ({ onClose }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState({
    oldPasswordErr: '',
    newPasswordErr: '',
    reNewPasswordErr: ''
  });

  const validate = () => {
    let isValid = false;
    if (!oldPassword) {
      setError({
        oldPasswordErr: `Current Password is required`
      });
    } else if (!newPassword) {
      setError({
        newPasswordErr: `New Password is required`
      });
    } else if (!reNewPassword) {
      setError({
        reNewPasswordErr: `Confirm New Password is required`
      });
    } else if (newPassword !== reNewPassword) {
      setError({
        reNewPasswordErr: `Confirm New Password does not match!`
      });
    } else {
      isValid = true;
      setShowLoader(true);
    }
    return isValid;
  };

  const handleChangePassword = () => {
    validate() &&
      profileDispatcher.changePassword(
        {
          oldPassword,
          newPassword
        },
        isSuccess => {
          isSuccess ? onClose() : setShowLoader(false);
        }
      );
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: '#F6F7FA',
        padding: '100px 170px 60px 170px'
      }}
    >
      <Paper elevation={3} style={containerStyle}>
        <Typography
          style={{
            fontSize: 24,
            letterSpacing: 0,
            fontWeight: 600
          }}
        >
          Change Password
        </Typography>
        <PasswordField
          helperText={error.oldPasswordErr}
          value={oldPassword}
          onChange={setOldPassword}
          label="Current Password"
        />
        <PasswordField
          value={newPassword}
          onChange={setNewPassword}
          label="New Password"
          helperText={error.newPasswordErr}
        />
        <PasswordField
          value={reNewPassword}
          onChange={setReNewPassword}
          label="Confirm New Password"
          helperText={error.reNewPasswordErr}
        />
        <LoadingButton
          onClick={handleChangePassword}
          showLoader={showLoader}
          style={{ marginTop: 60, marginLeft: 0, marginRight: 0 }}
        >
          Change
        </LoadingButton>
      </Paper>
    </div>
  );
};

const TextFieldStyle = styled(TextField)`
  height: 40px;
  width: 308px;
`;

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  padding: '40px 20px',
  fontSize: 20
};

const PasswordField = ({ value, onChange, label, helperText }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextFieldStyle
      type={showPassword ? '' : 'password'}
      name={label}
      style={{ marginTop: 40 }}
      fullWidth
      label={label}
      value={value}
      error={helperText}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <>
              <EyeHide
                className="eye-custom-color"
                style={{ cursor: 'pointer' }}
                hidden={showPassword}
                onClick={() => setShowPassword(true)}
              />
              <EyeShow
                className="eye-custom-color"
                style={{ cursor: 'pointer' }}
                hidden={!showPassword}
                onClick={() => setShowPassword(false)}
              />
            </>
          </InputAdornment>
        )
      }}
      onChange={e => onChange(e.target.value)}
    />
  );
};

export default ChangePasswordForm;
