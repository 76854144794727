const renderColumn = () => {
  const tableComlumn = [
    {
      label: 'Name',
      stateValue: 'fullName'
    },
    {
      label: 'Email',
      stateValue: 'email'
    },
    {
      label: 'Action',
      stateValue: null
    }
  ];
  return tableComlumn;
};

export default renderColumn;
