import { useTimer } from '@/helpers/useHookHelpers';
import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  gridWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },

  textName: {
    fontSize: 24,
    fontFamily: 'Lato',
    fontWeight: 700,
    color: '#ffffff',
  },
  textTime: {
    fontFamily: 'OpenSans',
    fontSize: 16,
    color: '#ffffff',
  },

  callEndBtn: {
    width: 56,
    height: 56,
    backgroundColor: '#db3d49',
    borderRadius: 16,
    boxShadow: 'none',
  },
  btn: {
    width: 56,
    height: 56,
    backgroundColor: 'white',
    borderRadius: 16,
    boxShadow: 'none',
  },
  btnSelected: {
    backgroundColor: '#ad5e99',
  },

  localStream: {
    width: '100%',
    height: '100%',

    borderRadius: 16,
    // backgroundColor: "#333333",

    '&div:nth-child(1)': {
      zIndex: 0,
      borderRadius: 16,
      position: 'absolute',
    },

    '& .text': {
      zIndex: 1,
      color: 'white',
      position: 'absolute',
      bottom: 20,
      left: 20,

      fontSize: 24,
      fontFamily: 'Lato',
      fontWeight: 600,
    },
  },

  iconNewMessage: {
    position: 'absolute',
    width: 15,
    height: 15,
    borderRadius: '50%',
    background: '#ea6b75',
    top: 8,
    right: 8,
  },
  bgColor: {
    background:
      'linear-gradient(180deg, #0000001f 0%, rgba(115, 115, 115, 0) 100%)',
  },
});
const StreamMobileHeader = ({ callData, cameraSwitched }) => {
  const classes = useStyles();
  const timer = useTimer(callData?.startCallTime, 'HH:mm:ss');

  return (
    <Box
      position="absolute"
      p={5}
      textAlign="center"
      zIndex={1}
      width={'100%'}
      className={classes.bgColor}
    >
      <Typography className={classes.textName}>
        Dr. {callData?.DoctorFirstName} {callData?.DoctorLastName}
      </Typography>
      <Typography className={classes.textTime}>{timer}</Typography>

      {/* <Button
        color="primary"
        onClick={() => {
          AgoraInstanceTeleconsultNow.instance.switchCamera(!cameraSwitched);
        }}
      >
        Click
      </Button> */}
    </Box>
  );
};

export default StreamMobileHeader;
