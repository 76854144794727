/* eslint-disable no-array-constructor */
import CustomPagination from './CustomPagination';
import {
  CheckboxActive,
  CheckboxDefault,
  NoDataHelperIcon,
} from '@/assets/svg';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState, Suspense } from 'react';

const useStyles = makeStyles({
  tableContainer: {
    position: 'relative',
    height: 'calc(100vh - 271px)',
  },
  table: {
    minWidth: 650,
    overflow: 'auto',
    '& .MuiTableRow-head': {
      height: 40,
      border: 'none',

      '& .MuiTableCell-root': {
        padding: '8px 12px',
        backgroundColor: '#f6f6f6',
        borderWidth: 2,
      },
    },
    '& .MuiTableRow-root.Mui-selected': {
      backgroundColor: 'rgba(255,233,251,0.5)',
    },
  },
  noDataHelper: {
    verticalAlign: 'middle',
    height: 'calc(100vh - 340px)',
    border: 'none',
    color: '#666666',
    '& .title-text': {
      marginTop: 12,
      fontSize: 18,
      fontWeight: 600,
      fontFamily: 'Lato',
      color: '#333333',
    },
  },
  tablePagination: {
    borderTop: '2px solid #e1e1e1',
  },
});

const CustomTable = ({
  data,
  paging = {},
  fetchData = () => {},
  searchKey,

  header: Header,
  renderRow = () => {},
  noDataHelperText,
  noDataHelperSubText,
  totalCols,
  useHelperText,

  hasCheckbox = false,
  isItemSelected,
  handleCheckbox,

  tableValue,
  setTableValue,
  Icon = NoDataHelperIcon,
  nameKey,
  ...props
}) => {
  const classes = useStyles();
  const [profileData, setProfileData] = useState(data || new Array());

  useEffect(() => {
    setProfileData(data || new Array());
  }, [data]);

  const handleFirstPageButtonClick = () => {
    fetchData(searchKey, {
      ...paging,
      pageIndex: 0,
    });
  };

  const handleLastPageButtonClick = () => {
    fetchData(searchKey, {
      ...paging,
      pageIndex: paging.totalPage - 1,
    });
  };

  const handleChangePaging = (e, page) => {
    e &&
      fetchData(searchKey, {
        ...paging,
        pageIndex: page - 1,
      });
  };

  const handleChangeRowsPerPage = (e) => {
    fetchData(searchKey, {
      ...paging,
      pageIndex: 0,
      pageSize: e.target.value,
    });
  };

  return (
    <Paper square variant="outlined">
      <TableContainer className={classes.tableContainer} {...props}>
        <Table className={classes.table} stickyHeader>
          {Header && <Header />}
          <TableBody>
            {noDataHelperText && isEmpty(profileData) ? (
              <TableRow>
                <TableCell
                  colSpan={totalCols}
                  align="center"
                  className={classes.noDataHelper}
                >
                  <Icon />

                  <Typography className="title-text">
                    {noDataHelperText}
                  </Typography>
                  <Typography>{noDataHelperSubText}</Typography>
                </TableCell>
              </TableRow>
            ) : hasCheckbox ? (
              profileData?.map((d, i) => (
                <TableRow
                  key={d.id || d[nameKey]}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  selected={isItemSelected(d)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected(d)}
                      onChange={(e) => handleCheckbox(e.target.checked, d)}
                      inputProps={{
                        'aria-labelledby': `enhanced-table-checkbox-${i}`,
                      }}
                      icon={<CheckboxDefault width={20} height={20} />}
                      checkedIcon={<CheckboxActive width={20} height={20} />}
                    />
                  </TableCell>
                  {renderRow(d, i, tableValue, setTableValue)}
                </TableRow>
              ))
            ) : (
              profileData?.map((d, i) => (
                <TableRow key={d.id || d[nameKey]} hover>
                  {renderRow(d, i)}
                </TableRow>
              )) || null
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(!useHelperText || !isEmpty(profileData)) && !isEmpty(paging) && (
        <CustomPagination
          count={paging.totalItem || 0}
          page={paging.pageIndex + 1}
          rowsPerPage={paging.pageSize}
          onChange={handleChangePaging}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          handleFirstPageButtonClick={handleFirstPageButtonClick}
          handleLastPageButtonClick={handleLastPageButtonClick}
        />
      )}
    </Paper>
  );
};

export default CustomTable;
