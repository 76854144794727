import React, { useEffect, useState } from 'react';
import {
  Box,
  AccordionDetails,
  AccordionSummary,
  withStyles,
  Checkbox,
  Divider,
  TextField,
  makeStyles,
  IconButton,
  Button,
  Typography
} from '@material-ui/core';
import { chain, cloneDeep, isEmpty } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Add,
  EditIcon,
  ArrowDownCircle,
  ArrowUpCircle,
  CloseCircle,
  CheckboxDefault
} from '@/assets/svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  ButtonGroup,
  CardTitle,
  CheckboxContainer,
  Number,
  QuestionContainer,
  StyledAccordion,
  TextAreaType,
  Type
} from './styled';

import { DragIndicator, ExpandMore } from '@material-ui/icons';
import { ANSWER_TYPE } from '../constants';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    fontWeight: 500,

    '&:hover input': {
      fontWeight: 600
    }
    // marginBottom: theme.spacing(2)
  },
  divider: {
    transition: 'opacity 0.3s',
    opacity: 1,
    flexGrow: 1,
    width: '30%'
  },
  containerHide: {
    opacity: 0,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    marginBottom: theme.spacing(2),
    '&:hover': {
      opacity: 1
    }
  },

  test: {
    display: 'none'
  },

  input: {
    textAlign: 'center',
    fontSize: 12,

    '&::placeholder': {
      textAlign: 'center'
    }
  },
  icon: {
    width: 20,
    height: 20
  }
}));

const CustomTitleLabel = withStyles(theme => ({
  root: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 4,
    color: '#333333'
  }
}))(Typography);

const CustomTypography = withStyles({
  root: {
    fontFamily: 'OpenSans',
    fontWeight: 600,
    '& > p': {
      margin: 0,

      /* [class="max-line"] */
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      display: '-webkit-inline-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2
    }
  }
})(Typography);

const CustomBox = withStyles(theme => ({
  root: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 4,
    color: '#666666',
    height: 2,
    opacity: 0,
    transition: 'opacity 0.5s', // delay hover

    '&:hover': {
      height: 'auto',
      opacity: 1,

      '& input': {
        fontWeight: 600
      }
    }
  }
}))(Box);

const CustomTextField = withStyles({
  root: {
    fontSize: 20,

    '& .MuiInputBase-multiline': {
      borderRadius: 8,
      height: 'auto',
      paddingLeft: 0,
      fontSize: 16
    },

    '& .Mui-disabled': {
      '&.MuiOutlinedInput-root': {
        backgroundColor: '#f6f6f6'
      }
    }
  }
})(TextField);

const SurveyQuestionContainer = ({
  onShowAdd,
  isCreate,
  setFormValue,
  categories,
  setCategories,
  formValue,
  onShowEdit,
  currCategoryName,
  setCurrCategoryName,
  removedQuestions,
  setRemovedQuestions
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const { name } = formValue;

    if (!isEmpty(name)) {
      // if dont have category Name => Add category Name default : New category
      if (isEmpty(categories)) {
        setCategories([
          {
            categoryName: 'New category',
            questions: []
          }
        ]);
        setCurrCategoryName('New category');
      } else {
        const lastCategoryName =
          categories[categories.length - 1]?.categoryName;
        setCurrCategoryName(lastCategoryName);
      }
    }
  }, [categories, formValue]);

  const handleDividerClick = (categoryIndex, itemIndex) => {
    const newCategories = [...categories];
    const newQuestions = newCategories[categoryIndex].questions
      .splice(itemIndex)
      .map(it => ({
        ...it,
        category: currCategoryName
      }));
    const newCategory = {
      categoryName: currCategoryName,
      questions: newQuestions
    };

    newCategories.splice(categoryIndex + 1, 0, newCategory);
    const questions = transformData(newCategories);

    setFormValue({ ...formValue, questions });
    setCategories(newCategories);
  };

  const onDragEnd = result => {
    const { source, destination } = result;

    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;
    // Set start and end variables
    const start = categories.find(
      row => row.categoryName === source.droppableId
    );
    const end = categories.find(
      row => row.categoryName === destination.droppableId
    );
    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.questions.filter((_, idx) => idx !== source.index);

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.questions[source.index]);

      // Then create a new copy of the column object
      const newCol = {
        categoryName: start.categoryName,
        questions: newList.map((item, index) => ({
          ...item,
          order: index
        }))
      };
      let newRows = [...categories].map(row => {
        if (row.categoryName === newCol.categoryName) {
          return newCol;
        }
        return row;
      });

      // Update the state
      const questions = transformData(newRows);
      setFormValue({ ...formValue, questions });
      setCategories(newRows);

      // Update the state
      return null;
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.questions.filter(
        (_, idx) => idx !== source.index
      );

      // Create a new start column
      const newStartItem = {
        categoryName: start.categoryName,
        questions: newStartList.map((item, index) => ({
          ...item,
          order: index
        }))
      };

      // Make a new end list array
      const newEndList = end.questions;

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, start.questions[source.index]);

      // Create a new end column
      const newEndItem = {
        categoryName: end.categoryName,
        questions: newEndList.map((item, index) => ({
          ...item,
          order: index
        }))
      };

      let newRows = [...categories].map(row => {
        if (row.categoryName === newStartItem.categoryName) {
          return newStartItem;
        }
        if (row.categoryName === newEndItem.categoryName) {
          return newEndItem;
        }
        return row;
      });

      // Update the state
      const questions = transformData(newRows);
      setFormValue({ ...formValue, questions });
      setCategories(newRows);
      return null;
    }
  };

  const transformData = data => {
    if (isEmpty(data)) return [];
    return data.reduce((acc, curr) => {
      const questionsWithCategory = curr.questions.map(question => ({
        ...question,
        category: curr.categoryName
      }));
      return [...acc, ...questionsWithCategory];
    }, []);
  };

  const onMoveUp = index => {
    if (index > 0) {
      const updatedSections = [...categories];
      [updatedSections[index - 1], updatedSections[index]] = [
        updatedSections[index],
        updatedSections[index - 1]
      ];

      const newCategories = transformOrderCategories(updatedSections);
      const questions = transformData(newCategories);
      setFormValue({ ...formValue, questions });
      setCategories(newCategories);
    }
  };

  const onMoveDown = index => {
    if (index < categories.length - 1) {
      const updatedSections = [...categories];
      [updatedSections[index + 1], updatedSections[index]] = [
        updatedSections[index],
        updatedSections[index + 1]
      ];

      const newCategories = transformOrderCategories(updatedSections);
      const questions = transformData(newCategories);
      setFormValue({ ...formValue, questions });
      setCategories(newCategories);
    }
  };

  const transformOrderCategories = categories => {
    let questions = transformData(categories);
    questions = questions.map((item, index) => ({
      ...item,
      order: index
    }));

    const dataTest = chain(questions)
      .groupBy('category')
      .map((value, key) => ({
        categoryName: key,
        questions: value
      }))
      .value();
    return dataTest;
  };

  const handleDeleteCategory = (categoryIndex, category) => {
    let updatedSections = [...categories];
    let questions = transformData(updatedSections);
    updatedSections.splice(categoryIndex, 1);

    if (!isCreate) {
      let newRemoved = [...removedQuestions];
      const newQuesRemoved = cloneDeep(questions)
        .filter(item => category.categoryName === item.category && item.id)
        .map(it => ({ ...it, isRemoved: true }));
      newRemoved = newRemoved.concat(newQuesRemoved);
      questions = cloneDeep(questions).filter(
        item => category.categoryName !== item.category
      );
      setRemovedQuestions(newRemoved);
    }

    setFormValue({ ...formValue, questions });
    setCategories(updatedSections);
  };

  const renderIndx = (categoryIndex, itemIndex) => {
    if (!isEmpty(categories)) {
      let count = 0;
      for (let i = 0; i < categoryIndex; i++) {
        count += categories[i].questions.length;
      }

      return count + itemIndex + 1;
    }
  };

  const handleDelete = (categoryIndex, quesIndex) => {
    const updatedSections = [...categories];
    let questions = transformData(updatedSections);
    if (!isCreate) {
      let newRemoved = [...removedQuestions];
      let removedItem = categories[categoryIndex].questions[quesIndex];

      if (removedItem.id) {
        removedItem.isRemoved = true;
        newRemoved.push(removedItem);
      } else {
      }
      questions = cloneDeep(questions).filter(
        item => removedItem.id !== item.id
      );
      setRemovedQuestions(newRemoved);
      updatedSections[categoryIndex].questions.splice(quesIndex, 1);
      questions = transformData(updatedSections);
    } else {
      updatedSections[categoryIndex].questions.splice(quesIndex, 1);
      questions = transformData(updatedSections);
    }

    setFormValue({ ...formValue, questions });
    setCategories(updatedSections);
  };

  const handleChangeCategoryName = categoryIndex => e => {
    let newCategories = [...categories];
    newCategories[categoryIndex].categoryName = e.target.value;
    if (!isEmpty(newCategories[categoryIndex].questions)) {
      newCategories[categoryIndex].questions = newCategories[
        categoryIndex
      ].questions.map(it => ({ ...it, category: e.target.value }));
    }

    const questions = transformData(newCategories);
    setFormValue({ ...formValue, questions });
    setCategories(newCategories);
  };

  const handleShowAddModal = () => {
    const lastCategory = categories[categories.length - 1];
    const index = categories.indexOf(lastCategory);

    onShowAdd(index);
  };

  return (
    <Box pt={0} p={2}>
      <CustomTitleLabel>Question</CustomTitleLabel>
      <DragDropContext onDragEnd={onDragEnd}>
        {!isEmpty(categories) &&
          categories?.map((category, categoryIndex) => (
            <Droppable droppableId={category?.categoryName} key={categoryIndex}>
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {!category.isRemoved && (
                    <CustomBox
                      className={classes.container}
                      onMouseEnter={() => {
                        let newCategories = [...categories];
                        newCategories[categoryIndex].isHovering = true;
                        setCategories(newCategories);
                      }}
                      onMouseLeave={() => {
                        let newCategories = [...categories];
                        newCategories[categoryIndex].isHovering = false;
                        setCategories(newCategories);
                      }}
                      style={{ opacity: 1, height: 'auto' }}
                    >
                      <Divider light className={classes.divider} />
                      <TextField
                        value={category?.categoryName}
                        onChange={handleChangeCategoryName(categoryIndex)}
                        placeholder="New category"
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: classes.input
                          }
                        }}
                      />
                      <Divider
                        light
                        className={classes.divider}
                        style={{
                          width: category.isHovering
                            ? 'calc(30% - 60px)'
                            : '30%'
                        }}
                      />
                      {category?.isHovering && (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => onMoveUp(categoryIndex)}
                            disabled={categoryIndex === 0}
                            className={classes.icon}
                          >
                            <ArrowUpCircle />
                          </IconButton>

                          <IconButton
                            size="small"
                            onClick={() => onMoveDown(categoryIndex)}
                            disabled={categoryIndex === categories?.length - 1}
                            className={classes.icon}
                          >
                            <ArrowDownCircle />
                          </IconButton>

                          <IconButton
                            size="small"
                            color="danger"
                            onClick={() =>
                              handleDeleteCategory(categoryIndex, category)
                            }
                            className={classes.icon}
                            style={{ color: '#DB3D49' }}
                          >
                            <CloseCircle />
                          </IconButton>
                        </>
                      )}
                    </CustomBox>
                  )}

                  {category?.questions.map(
                    (item, itemIndex) =>
                      !item.isRemoved && (
                        <React.Fragment key={itemIndex}>
                          <Draggable
                            disableInteractiveElementBlocking
                            draggableId={`question-${item.question}-${itemIndex}`}
                            index={itemIndex}
                            key={`question-${itemIndex}`}
                          >
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <QuestionContainer>
                                  <DragIndicator className="icon-drag" />
                                  <StyledAccordion
                                    className={
                                      item.answerType === 'FreeText' &&
                                      'isTextarea'
                                    }
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMore />}
                                    >
                                      <Number>
                                        {renderIndx(categoryIndex, itemIndex)}
                                      </Number>
                                      <CardTitle>
                                        <CustomTypography
                                          dangerouslySetInnerHTML={{
                                            __html: item.question
                                          }}
                                        />
                                        {item.answerType ===
                                          'MultipleChoice' && (
                                          <Typography>
                                            Please concern all option below
                                          </Typography>
                                        )}
                                      </CardTitle>
                                      <Type>
                                        {ANSWER_TYPE[item.answerType]}
                                      </Type>
                                      <Divider
                                        light
                                        orientation="vertical"
                                        flexItem
                                      />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Box p={1.5}>
                                        {item.answerType !== 'FreeText' && (
                                          <CheckboxContainer>
                                            {item.answers
                                              ?.filter(answ => !answ.isRemoved)
                                              .map((answ, inx) => (
                                                <>
                                                  <div
                                                    key={`${inx.answer}-${inx}`}
                                                    style={{
                                                      alignItems: 'center',
                                                      display: 'flex',
                                                      marginBottom: 4
                                                    }}
                                                  >
                                                    <Checkbox
                                                      disabled
                                                      icon={<CheckboxDefault />}
                                                      style={{
                                                        marginRight: 8
                                                      }}
                                                    />
                                                    <span
                                                      style={{
                                                        /* [class="max-line"] */
                                                        whiteSpace: 'pre-wrap',
                                                        overflow: 'hidden',
                                                        display:
                                                          '-webkit-inline-box',
                                                        WebkitBoxOrient:
                                                          'vertical',
                                                        WebkitLineClamp: 2
                                                      }}
                                                    >
                                                      {answ.answer}
                                                    </span>
                                                  </div>
                                                  {answ.isAdditional && (
                                                    <Box pl={4}>
                                                      <CustomTextField
                                                        fullWidth
                                                        placeholder="Enter your answer"
                                                        multiline
                                                        disabled
                                                        variant="outlined"
                                                        maxRows={2}
                                                        value={
                                                          answ.additionalNote
                                                        }
                                                      />
                                                    </Box>
                                                  )}
                                                </>
                                              ))}
                                          </CheckboxContainer>
                                        )}

                                        {item.answerType === 'FreeText' && (
                                          <TextAreaType>
                                            Type your answer
                                          </TextAreaType>
                                        )}
                                      </Box>

                                      <ButtonGroup>
                                        <Button
                                          className="warning"
                                          onClick={() =>
                                            handleDelete(
                                              categoryIndex,
                                              itemIndex
                                            )
                                          }
                                        >
                                          <DeleteIcon />
                                          Delete
                                        </Button>
                                        <Button
                                          className="secondary"
                                          onClick={() =>
                                            onShowEdit(
                                              item,
                                              itemIndex,
                                              categoryIndex
                                            )
                                          }
                                        >
                                          <EditIcon />
                                          Edit
                                        </Button>
                                      </ButtonGroup>
                                    </AccordionDetails>
                                  </StyledAccordion>
                                </QuestionContainer>
                              </div>
                            )}
                          </Draggable>

                          {itemIndex !== category.questions.length - 1 && (
                            <CustomBox
                              className={classes.container}
                              onMouseEnter={() => setIsHovering(true)}
                              onMouseLeave={() => setIsHovering(false)}
                            >
                              <Divider light className={classes.divider} />
                              <TextField
                                value={item?.categoryName}
                                onChange={e =>
                                  setCurrCategoryName(e.target.value)
                                }
                                placeholder="New category"
                                InputProps={{
                                  disableUnderline: true,
                                  classes: {
                                    input: classes.input
                                  }
                                }}
                                onBlur={e => {
                                  handleDividerClick(
                                    categoryIndex,
                                    itemIndex + 1
                                  );
                                }}
                              />
                              <Divider
                                light
                                className={classes.divider}
                                style={{
                                  width: isHovering ? 'calc(30% - 60px)' : '30%'
                                }}
                              />
                              {isHovering && (
                                <>
                                  <IconButton
                                    size="small"
                                    onClick={() => onMoveUp(categoryIndex)}
                                    disabled
                                    className={classes.icon}
                                  >
                                    <ArrowUpCircle />
                                  </IconButton>

                                  <IconButton
                                    size="small"
                                    onClick={() => onMoveDown(categoryIndex)}
                                    disabled={
                                      categoryIndex === categories.length - 1
                                    }
                                    className={classes.icon}
                                  >
                                    <ArrowDownCircle />
                                  </IconButton>

                                  <IconButton
                                    size="small"
                                    disabled
                                    className={classes.icon}
                                    style={{ color: '#DB3D49' }}
                                  >
                                    <CloseCircle />
                                  </IconButton>
                                </>
                              )}
                            </CustomBox>
                          )}
                        </React.Fragment>
                      )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
      </DragDropContext>
      <Button
        color="primary"
        startIcon={<Add />}
        onClick={handleShowAddModal}
        disabled={isEmpty(formValue.name) && isEmpty(currCategoryName)}
      >
        Add question
      </Button>
    </Box>
  );
};

export default SurveyQuestionContainer;
