import React, { useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';
import { ClearRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import MomentUtils from '@material-ui/pickers/adapter/moment';

import {
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputAdornment
} from '@material-ui/core';
import moment from 'moment';
import { ButtonEnhance } from '../../../common/componentUI/commonStyleComponents';
import { TYPE_MODAL, FORM_VALUE } from '../../constants';
import LoadingButton from '../../../../components/LoadingButton';
import { UploadSingle } from '../../../common/component';
import { AddImageOutlineIcon, LinkIcon } from '../../../../assets/svg';
import { LocalizationProvider, DateTimePicker } from '@material-ui/pickers';
import styled from 'styled-components';
import './styles.scss';
import CustomMultiPicker from './CustomMultiPicker';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { foundIndexOfDate } from '../../helpers';
import ControlTimePicker from '../../../common/component/ControlTimepicker';
import validateData from '@/helpers/validationHelpers/validationSchema';

const BasicFormModalPage = ({
  livestreamDetail,
  typeModal,
  onCloseModal,
  onSaveData,
  notAdminView
}) => {
  const { listDuration, listIntensity } = useSelector(state => state.onDemand);
  const { listCategoryLivestream } = useSelector(state => state.livestream);

  // const handleRenderBanner = () => {
  //   const data = [];
  //   for (let i = 0; i < 5 - formValue.lstBanner.length; i++) {
  //     data.push('');
  //   }
  //   data.length &&
  //     setFormValue({
  //       ...formValue,
  //       lstBanner: [...formValue.lstBanner, ...data]
  //     });
  //   return formValue.lstBanner.map((item, index) => (
  //     <BannerItem key={item + index}>
  //       <UploadSingle
  //         index={index}
  //         disabled={typeModal === TYPE_MODAL.View}
  //         imageUrlSelected={item}
  //         onChange={data => handleChangeBanner(data, index)}
  //         typeUpload="inline"
  //         onRemove={onRemoveBanner}
  //       />
  //     </BannerItem>
  //   ));
  // };

  const [idSelected, setIdSelected] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [formValue, setFormValue] = useState(FORM_VALUE);

  useEffect(() => {
    if (!isEmpty(livestreamDetail)) {
      setFormValue({
        title: livestreamDetail.title || '',
        thumbnail: livestreamDetail.thumbnail || '',
        price: livestreamDetail.price || 0,
        duration: livestreamDetail.duration || '',
        calories: livestreamDetail.calories || '',
        intensity:
          livestreamDetail.intensityValue || livestreamDetail.intensity || '',
        description: livestreamDetail.description || '',
        equipment: livestreamDetail.equipment || '',
        categoryId: livestreamDetail.categoryId || '',
        date: livestreamDetail.date || '',
        trainerId: livestreamDetail.trainers || [],
        managerId: livestreamDetail.managerId || '',
        vimeoClipId: livestreamDetail.vimeoClipId || '',
        liveStreamOption: livestreamDetail.liveStreamOption,
        mp3Urls: livestreamDetail.mp3Urls || [],
        liveStreamStatus: livestreamDetail.liveStreamStatus,
        rtmpLink: livestreamDetail.rtmpLink,
        hlsLink: livestreamDetail.hlsLink,
        listDates: livestreamDetail.listDates || [],
        startHour: livestreamDetail.startHour || ''
      });
      setIdSelected(livestreamDetail.id);
    }
  }, [livestreamDetail, typeModal]);

  const handleChange = (data, field) => {
    if (field === 'categoryId') {
      const cateSelected = listCategoryLivestream.find(
        item => item.id === data
      );
      setFormValue({
        ...formValue,
        categoryId: data,
        intensity: cateSelected.intensityValue,
        calories: cateSelected.calories,
        description: cateSelected.description,
        equipment: cateSelected.equipment
      });
    } else
      setFormValue({
        ...formValue,
        [field]: data
      });
  };

  const handleChangeListDay = date => {
    let newListDates = [...formValue.listDates];

    const foundIndex = foundIndexOfDate(newListDates, date);
    if (foundIndex >= 0) {
      newListDates.splice(foundIndex, 1);
    } else {
      newListDates.push(date);
    }
    setFormValue({ ...formValue, listDates: newListDates });
  };

  const handleSubmit = async () => {
    try {
      await validateData('basicModalForm', { ...formValue, typeModal }, () => {
        if (idSelected) {
          onSaveData({ ...formValue, id: idSelected });
          return;
        }
        onSaveData(formValue);
      });
    } catch (errs) {
      setIsSubmitted(true);
      setErrors(errs);
    }
  };

  const customArrayData = (list, titleKey, titleValue, idKey, idValue) => {
    if (!notAdminView) return list;
    return list && !!list.length
      ? list
      : [{ [titleKey]: titleValue, [idKey]: idValue }];
  };
  return (
    <ModalPage>
      <HeaderModal>
        <div className="title">
          {typeModal === TYPE_MODAL.View && livestreamDetail?.title}
          {typeModal === TYPE_MODAL.Create && 'Create New Workout'}
          {typeModal === TYPE_MODAL.Edit && 'Update Workout'}
        </div>
        <ClearRounded onClick={() => onCloseModal()} className="icon-close" />
      </HeaderModal>

      <DialogContent>
        <WrapperForm>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <UploadSingle
                typeUpload="normal"
                disabled={typeModal === TYPE_MODAL.View}
                onChange={data => handleChange(data, 'thumbnail')}
                imageUrlSelected={formValue.thumbnail}
                accept=".png,.jpg,.jpeg"
                uploadButton={
                  <WrapperButtonUpload>
                    <span className="icon">
                      <AddImageOutlineIcon color="#ccc" />
                    </span>{' '}
                    <span className="text">Add Programme Picture</span>
                  </WrapperButtonUpload>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <WrapperInput
                    error={isSubmitted && errors.title ? true : false}
                    variant={
                      typeModal === TYPE_MODAL.View ? 'filled' : 'standard'
                    }
                    disabled={typeModal === TYPE_MODAL.View}
                    value={formValue.title}
                    onChange={e => handleChange(e.target.value, 'title')}
                    label="Workout Title"
                    helperText={isSubmitted && errors.title}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            {typeModal === TYPE_MODAL.Create && (
              <>
                <LocalizationProvider dateAdapter={MomentAdapter}>
                  <CustomMultiPicker
                    listDates={formValue.listDates}
                    onChange={handleChangeListDay}
                    label="Start Date"
                    error={errors.listDates}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={MomentAdapter}>
                  <ControlTimePicker
                    label="Start Hour"
                    value={formValue.startHour}
                    onChange={e => handleChange(e, 'startHour')}
                    error={errors.startHour}
                    ampm={false}
                  />
                </LocalizationProvider>
              </>
            )}
            {typeModal === TYPE_MODAL.Edit && (
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <DateTimePicker
                    disabled={typeModal === TYPE_MODAL.View}
                    label="Start Date Time"
                    minDate={new Date()}
                    renderInput={props => (
                      <TextField
                        className="date-picker-custom"
                        variant={
                          typeModal === TYPE_MODAL.View ? 'filled' : 'standard'
                        }
                        {...props}
                        helperText=""
                      />
                    )}
                    value={formValue.date ? moment(formValue.date) : null}
                    onChange={date => handleChange(date, 'date')}
                    ampm={false}
                    autoOk
                  />
                  {isSubmitted && (
                    <FormHelperText error>{errors.date}</FormHelperText>
                  )}
                </LocalizationProvider>
              </Grid>
            )}
            <Grid item xs={6}>
              <WrapperFormControl
                disabled={typeModal === TYPE_MODAL.View}
                variant={typeModal === TYPE_MODAL.View ? 'filled' : 'standard'}
                error={isSubmitted && errors.categoryId ? true : false}
              >
                <InputLabel id="workout-select-label">Workout Type</InputLabel>
                <WrapperSelect
                  className="select-custom"
                  labelId="workout-select-label"
                  value={formValue.categoryId || ''}
                  onChange={e => handleChange(e.target.value, 'categoryId')}
                >
                  {customArrayData(
                    listCategoryLivestream,
                    'title',
                    get(livestreamDetail, 'categoryTitle'),
                    'id',
                    get(livestreamDetail, 'categoryId')
                  ).map((item, index) => (
                    <MenuItem key={item.id + index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </WrapperSelect>
                {isSubmitted && (
                  <FormHelperText>{errors.categoryId}</FormHelperText>
                )}
              </WrapperFormControl>
            </Grid>
            <Grid item xs={6}>
              <WrapperFormControl
                error={isSubmitted && errors.intensity ? true : false}
                disabled={typeModal === TYPE_MODAL.View}
                variant={typeModal === TYPE_MODAL.View ? 'filled' : 'standard'}
              >
                <InputLabel id="intensity-label">Intensity</InputLabel>
                <WrapperSelect
                  className="select-custom"
                  disabled={true}
                  labelId="intensity-label"
                  value={formValue.intensity || ''}
                  onChange={e => handleChange(e.target.value, 'intensity')}
                >
                  {customArrayData(
                    listIntensity,
                    'text',
                    get(livestreamDetail, 'intensity'),
                    'value',
                    get(livestreamDetail, 'intensityValue')
                  ).map((item, index) => (
                    <MenuItem key={item.value + index} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </WrapperSelect>
                {isSubmitted && (
                  <FormHelperText>{errors.intensity}</FormHelperText>
                )}
              </WrapperFormControl>
            </Grid>
            <Grid item xs={6}>
              <WrapperFormControl
                error={isSubmitted && errors.duration ? true : false}
                disabled={typeModal === TYPE_MODAL.View}
                variant={typeModal === TYPE_MODAL.View ? 'filled' : 'standard'}
              >
                <InputLabel id="duration-label">Duration</InputLabel>
                <WrapperSelect
                  className="select-custom"
                  labelId="duration-label"
                  value={formValue.duration || ''}
                  onChange={e => handleChange(e.target.value, 'duration')}
                >
                  {customArrayData(
                    listDuration,
                    'text',
                    `${get(livestreamDetail, 'duration')} minutes`,
                    'value',
                    get(livestreamDetail, 'duration')
                  ).map((item, index) => (
                    <MenuItem key={item.value + index} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </WrapperSelect>
                {isSubmitted && (
                  <FormHelperText>{errors.duration}</FormHelperText>
                )}
              </WrapperFormControl>
            </Grid>
            <Grid item xs={6}>
              <WrapperInput
                error={isSubmitted && errors.calories ? true : false}
                variant={typeModal === TYPE_MODAL.View ? 'filled' : 'standard'}
                disabled={true}
                value={formValue.calories}
                onChange={e => handleChange(e.target.value, 'calories')}
                label="Calories"
                helperText={isSubmitted && errors.calories}
                type="number"
                inputProps={{ min: '0' }}
              />
            </Grid>
            <Grid item xs={6}>
              <WrapperInput
                error={isSubmitted && errors.equipment ? true : false}
                variant={typeModal === TYPE_MODAL.View ? 'filled' : 'standard'}
                disabled={typeModal === TYPE_MODAL.View}
                value={formValue.equipment || ''}
                onChange={e => handleChange(e.target.value, 'equipment')}
                label="Equipment"
                helperText={isSubmitted && errors.equipment}
              />
            </Grid>
            <Grid item xs={12}>
              <WrapperInput
                error={isSubmitted && errors.description ? true : false}
                variant={typeModal === TYPE_MODAL.View ? 'filled' : 'standard'}
                disabled={typeModal === TYPE_MODAL.View}
                value={formValue.description || ''}
                onChange={e => handleChange(e.target.value, 'description')}
                label="Description"
                helperText={isSubmitted && errors.description}
              />
            </Grid>
          </Grid>
        </WrapperForm>
      </DialogContent>

      <ModalFooter>
        <ButtonEnhance
          background="#E9E9E9"
          color="black"
          backgroundHover="#ccc"
          onClick={() => onCloseModal()}
        >
          Cancel
        </ButtonEnhance>

        <LoadingButton onClick={handleSubmit}>Next</LoadingButton>
      </ModalFooter>
    </ModalPage>
  );
};

const ModalPage = styled.div`
  max-width: 780px;
`;

const WrapperButtonUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    color: #ccc;
  }
`;

const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 20px 0px;
  margin-top: 30px;
`;

const WrapperForm = styled.div`
  font-size: 14;
  flex-grow: 1;
  overflow: hidden;
  .MuiIconButton-edgeEnd {
    margin-right: 0;
  }
`;
const HeaderModal = styled.div`
  border-bottom: 1px solid rgb(202, 207, 211);
  font-weight: 600;
  color: rgb(25, 38, 55);
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  .icon-close {
    cursor: pointer;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
  }
`;

const WrapperFormControl = styled(FormControl)`
  width: 100%;
`;

const WrapperSelect = styled(Select)`
  width: 100%;
`;
const WrapperInput = styled(TextField)`
  width: 100%;
  label {
    color: #8f9ba9;
  }
`;
export default BasicFormModalPage;
