import { synthesize } from 'redux-dispatcher';

const mapDispatchToAC = {
  getDataSuccess: data => ({ data }),
  getData: (search, paging, filterOption = {}, sortOption = {}) => async ({
    Api,
    getState
  }) => {
    let pagingData = paging || getState().teleAppoinment.paging;

    let { result, status } = await Api.post(`DeletionRequests/filter`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      ...filterOption,
      ...sortOption
    });

    status === 200 && deletionRequestDispatcher.getDataSuccess(result);
  },
  resetData: () => ({}),
  deleteAccountRequest: (userId, callback) => async ({ Api, customToast }) => {
    const { status } = await Api.delete(`DeletionRequests/approve/${userId}`);
    if (status === 200) {
      customToast('success', 'Delete user success');
      callback && callback();
    }
  }
};

const deletionRequestDispatcher = synthesize(
  'deletionRequest',
  mapDispatchToAC
);

export default deletionRequestDispatcher;
