import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import { Card } from '@material-ui/core';
import Draggable from 'react-draggable';
import ChatLeftPage from './ChatLeftPage';
import ChatContentPage from './ChatContentPage';
import styled from 'styled-components';
import upcomingDispatcher from '../../../upcoming-workout/action/upcomingWorkout';
import AgoraLiveStream from '../../../../agora/agoraLivestream';
import { joinChannel } from '../../helpers';
import {
  formatUsername,
  setAttributeBasedOnID,
  isChannel
} from '../../helpers';

const ChatPage = ({
  channelName = ',publicRoom',
  showChat,
  username,
  roleName,
  selectedLivestream,
  listRoom,
  setListRoom,
  receiveChat,
  receiveNewMessage,
  onFocusInput
}) => {
  let isDragging = useRef(false);
  const [currentRoom, setCurrentRoom] = useState(channelName);
  const isPeerChatting = useMemo(
    () =>
      !currentRoom.includes('publicRoom') &&
      !currentRoom.includes('cohostRoom'),
    [currentRoom]
  );
  useEffect(() => {
    !!showChat &&
      AgoraLiveStream.instance.rtc.stream &&
      AgoraLiveStream.instance.rtc.stream.play('main_livestream_2', {
        fit: 'contain'
      });
  }, [showChat]);
  const handleBackToStream = () => {
    upcomingDispatcher.setState('showChat', false);
    AgoraLiveStream.instance.rtc.stream &&
      AgoraLiveStream.instance.rtc.stream.stop();
  };
  const onDrag = () => {
    isDragging.current = true;
  };
  const onStop = () => {
    if (!isDragging.current) {
      handleBackToStream();
    }
    isDragging.current = false;
  };
  const handleSwitchChat = useCallback(
    roomId => {
      if (roomId.includes('cohostRoom') && roleName === 'Patient') return;
      isChannel(roomId) && joinChannel(username, roomId);
      setListRoom(
        setAttributeBasedOnID(listRoom, roomId, [
          { attr: 'hasNewChat', value: false }
        ])
      );
      setCurrentRoom(roomId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listRoom, username, roleName]
  );

  const handleSelectPeerChat = (name, id, roleType) => {
    const user = formatUsername(name, id);
    receiveChat(name, user, false, roleType, () => {
      setCurrentRoom(user);
    });
  };

  const onSelectSearchChat = chat => {
    receiveChat(chat.name, chat.id, false, chat.roleType, () => {
      setCurrentRoom(chat.id);
    });
  };

  return (
    <WrapperCard>
      <GridContainer>
        <GridColumnLeft>
          <ChatLeftPage
            switchChat={handleSwitchChat}
            currentRoom={currentRoom}
            selectedLivestream={selectedLivestream}
            roleName={roleName}
            listRoom={listRoom}
            onSelectSearchChat={onSelectSearchChat}
          />
        </GridColumnLeft>

        <GridColumnRight>
          <ChatContentPage
            channelName={currentRoom}
            selectPeerChat={handleSelectPeerChat}
            receiveNewMessage={receiveNewMessage}
            isPeerChatting={isPeerChatting}
            onFocusInput={onFocusInput}
            onSelectSearchChat={onSelectSearchChat}
          />
        </GridColumnRight>
      </GridContainer>
      {showChat && (
        <Draggable onDrag={onDrag} onStop={onStop}>
          <LivestreamContainer key="main_livestream_2" id="main_livestream_2" />
        </Draggable>
      )}
    </WrapperCard>
  );
};

const WrapperCard = styled(Card)`
  border-radius: 2px !important;
  margin-bottom: 10px;
  height: 100%;
`;
const GridContainer = styled.div`
  display: flex;
  height: 100%;
`;
const GridColumnLeft = styled.div`
  width: 20%;
`;
const GridColumnRight = styled.div`
  width: 80%;
`;
const LivestreamContainer = styled.div`
  width: 200px;
  height: 120px;
  position: absolute;
  top: 60px;
  right: 50px;
  z-index: 2;
  border: 1px solid black;
  border-radius: 10px;
  overflow: hidden;
  cursor: move;
  video {
    transform: ${props => props.transform};
  }
`;
export default ChatPage;
