import React, { useState, useEffect } from 'react';
import TableCellFilted from '../../common/componentUI/TableCellFilted';
import { TYPE_MODAL } from '../../common/constants/typeModal';
import TableContainer from '../../../components/Table/TableContainer';
import {
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  Menu,
  IconButton
} from '@material-ui/core';
import {
  DeleteRounded,
  CreateRounded,
  MoreVertRounded,
  CheckCircleOutline,
  HighlightOff
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import MenuAction from '../../../components/MenuAction';
import bannerDispatcher from '../action/banner';
import DeleteModal from '../../../components/DeleteModal';
import BannerModal from './BannerModal';
import PreviewImage from '../../../components/Image';
import ImageViewerEnhanced from '../../../components/ConsultDetail/ImageViewerEnhanced';
import styled from 'styled-components';
import moment from 'moment';

const Banner = () => {
  const { list, paging, loading } = useSelector(state => state.banner);

  const [bannerSelected, setBannerSelected] = useState(null);
  const [hiddenRows, setHiddenRows] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  //preview image control
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [previewSrc, setPreviewSrc] = useState('');

  const onShowModal = type => {
    setShowModal(true);
    setTypeModal(type);
    setAnchorEl(null);
  };

  const onCloseModal = () => {
    setShowModal(false);
    setTypeModal(null);
  };

  const onSubmitSuccess = () => {
    bannerDispatcher.getData();
    setShowModal(false);
    setTypeModal(null);
  };

  const handleSubmitBanner = formValue => {
    bannerDispatcher.setState('loading', true);
    if (typeModal === TYPE_MODAL.Create) {
      bannerDispatcher.createBanner(formValue, onSubmitSuccess);
    } else if (typeModal === TYPE_MODAL.Edit) {
      bannerDispatcher.editBanner(
        { id: bannerSelected.id, banner: formValue },
        onSubmitSuccess
      );
    }
  };

  const onChangeBannerStatus = () => {
    const { isActive } = bannerSelected;
    bannerDispatcher.editBanner(
      {
        id: bannerSelected.id,
        banner: { ...bannerSelected, isActive: !isActive }
      },
      () => {
        setAnchorEl(null);
        onSubmitSuccess();
      }
    );
  };

  const onShowDeleteModal = () => {
    setDeleteModal(true);
    setAnchorEl(null);
  };

  const handleDeleteBanner = () => {
    bannerDispatcher.deleteBanner(bannerSelected.id, () => {
      bannerDispatcher.getData();
      setDeleteModal(false);
    });
  };

  const openImageViewer = src => {
    setPreviewSrc(src);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setPreviewSrc('');
    setIsViewerOpen(false);
  };

  const TableCellFiltedWrapper = ({ ...props }) => (
    <TableCellFilted
      {...{
        hiddenRows,
        setHiddenRows
      }}
      {...props}
    />
  );
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>Order</TableCell>
        <TableCellFiltedWrapper label="URL" stateValue="name" />
        <TableCell>Preview</TableCell>
        <TableCellFiltedWrapper label="Status" stateValue="status" />
        <TableCellFiltedWrapper label="Start Date" stateValue="startDate" />
        <TableCellFiltedWrapper label="End Date" stateValue="endDate" />
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  const renderListActions = (onChangeStatus, isActive) => {
    const LIST_ACTION = [
      {
        key: 'active',
        icon: CheckCircleOutline,
        label: 'Active',
        onClick: onChangeStatus
      },
      {
        key: 'edit',
        icon: CreateRounded,
        label: 'Edit',
        onClick: () => onShowModal(TYPE_MODAL.Edit)
      },
      {
        key: 'delete',
        icon: DeleteRounded,
        label: 'Delete',
        onClick: onShowDeleteModal
      }
    ];
    if (isActive) {
      LIST_ACTION.shift();
      LIST_ACTION.unshift({
        key: 'deactive',
        icon: HighlightOff,
        label: 'DeActive',
        onClick: onChangeStatus
      });
    }
    return LIST_ACTION;
  };
  const listActions = renderListActions(
    onChangeBannerStatus,
    bannerSelected?.isActive
  );

  return (
    <>
      <ImageViewerEnhanced
        visible={isViewerOpen}
        // disabled={!consultDetail.corperatePlan}
        src={previewSrc}
        onClose={closeImageViewer}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {anchorEl && <MenuAction listActions={listActions} />}
      </Menu>
      <TableContainer
        title="Banner Management"
        paging={paging}
        data={list}
        header={TableHeader}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        query={bannerDispatcher.getData}
        onAddNew={() => onShowModal(TYPE_MODAL.Create)}
        renderRow={row => (
          <>
            <TableCell>{row.order}</TableCell>
            <TableCell>{!hiddenRows.name && row.name}</TableCell>
            <TableCell>
              {row.url && (
                <PreviewImage
                  onClick={() => openImageViewer(row.url)}
                  src={row.url}
                  alt="Preview"
                  style={{ borderRadius: 4, width: 420, height: 180 }}
                />
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.status && (
                <StatusInfo isActive={row.isActive}>
                  {row.isActive ? 'Active' : 'DeActive'}
                </StatusInfo>
              )}
            </TableCell>
            <TableCell>
              {!hiddenRows.startDate &&
                moment(row.startDate).format('DD MMM YYYY')}
            </TableCell>
            <TableCell>
              {!hiddenRows.endDate && moment(row.endDate).format('DD MMM YYYY')}
            </TableCell>
            <TableCell align="right">
              <IconButton
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  setBannerSelected(row);
                }}
              >
                <MoreVertRounded />
              </IconButton>
            </TableCell>
          </>
        )}
      />
      <Dialog
        disableEnforceFocus
        maxWidth="xl"
        open={showModal}
        onClose={onCloseModal}
      >
        <BannerModal
          onClose={onCloseModal}
          selectedItem={bannerSelected}
          typeModal={typeModal}
          onSubmit={handleSubmitBanner}
          loading={loading}
          totalItem={paging.totalItem}
        />
      </Dialog>
      {deleteModal && (
        <DeleteModal
          showModal={deleteModal}
          selectedItem={bannerSelected}
          onClose={() => setDeleteModal(false)}
          onDelete={handleDeleteBanner}
          modalName="Banner"
          title={bannerSelected.name}
        />
      )}
    </>
  );
};

export default Banner;

const StatusInfo = styled.div`
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  width: fit-content;
  background-color: ${({ isActive }) => (isActive ? '#62CAA4' : '#EA6B75')};
`;
