import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Popover,
  Badge,
  MenuItem,
  ListItemIcon,
  Box,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { get } from 'lodash';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import DoneIcon from '@material-ui/icons/Done';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { onReceiveMessage } from '../../../firebase';
import firebase from '../../../firebase';
import { notificationDispatcher } from '../../notification/actions';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DATE_FORMAT } from '..';
import { parseNotifyFromFirebase } from '../../../helpers/CommonHelper';
import { momentFormatted } from '../../../helpers';
import { useHistory, useLocation } from 'react-router-dom';
import { urlLabel } from '../../../enum/PermissionEnum';
import customToast from '@/new-components/CustomNotification';
import { withStyles } from '@material-ui/core/styles';
import { CombinedIcon } from '@/assets/svg';

const CustomBadge = withStyles({
  badge: {
    border: '2px solid #ffffff'
  }
})(Badge);

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 40,
      borderRadius: 8
    },
    '& input': {
      padding: 8
    }
  }
})(TextField);

const Notification = () => {
  const location = useLocation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [searchQuery, setSearchQuery] = useState({
    PageIndex: 0,
    PageSize: 10
  });
  const notificationData = useSelector(
    state => state.notification.notificationData
  );
  const { userPasscode } = useSelector(state => state.auth);

  const { privilege } = useSelector(state => state.userPermission);

  const roleType = useSelector(state => state.auth.roleType);

  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      onReceiveMessage(payload => {
        const notification = parseNotifyFromFirebase(payload);
        notificationDispatcher.pushNotification(notification);
        const permission = privilege.map(item => item.value);
        if (
          permission.includes(urlLabel.consultList) &&
          location.pathname.includes(urlLabel.consultList)
        ) {
          history.push('/');
          history.goBack();
        } else if (
          permission.includes(urlLabel.meetingHistory) &&
          location.pathname.includes(urlLabel.meetingHistory)
        ) {
          history.push('/');
          history.goBack();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleType, privilege]);

  useEffect(() => {
    notificationDispatcher.getNotificationList(searchQuery);
    notificationDispatcher.getNotifyUnread();
  }, [searchQuery]);

  const readNotification = item => {
    if (item.isRead) {
      return;
    }
    notificationDispatcher.readNotification(item.id);
  };

  const renderLineItem = () => {
    return (get(notificationData, 'list') || []).map((item, index) => (
      <LineItem
        onClick={() => readNotification(item)}
        key={item.id + index}
        isRead={item.isRead}
      >
        <div className="icon">
          <PersonIcon />
        </div>
        <div className="content">
          <div className="title">
            <div className="text">{item.title}</div>
            <div className="date">
              {momentFormatted(item.notifiedTime, DATE_FORMAT)}
            </div>
          </div>
          <div className="description">{item.body}</div>
        </div>
      </LineItem>
    ));
  };

  const handleLoadMore = () => {
    if (notificationData.list.length === notificationData.totalItem) {
      return;
    }
    setSearchQuery({
      ...searchQuery,
      PageIndex: searchQuery.PageIndex + 1
    });
  };

  const renderEmptyNotify = () => {
    if (!(get(notificationData, 'list') || []).length) {
      return <NotificationEmpty>There are no notification</NotificationEmpty>;
    }
  };

  const renderNotify = () => {
    if ((get(notificationData, 'list') || []).length) {
      return (
        <InfiniteScroll
          dataLength={(get(notificationData, 'list') || []).length}
          next={handleLoadMore}
          hasMore={true}
          height={400}
        >
          <LabelNotify>
            <label>Notifications</label>
            <MoreHorizIcon onClick={handleShowMenu} className="icon" />
          </LabelNotify>
          {renderLineItem()}
        </InfiniteScroll>
      );
    }
  };

  const readAllNotification = () => {
    const { countNotifyUnread } = notificationData;
    if (!countNotifyUnread) {
      customToast('info', 'You have no notification');
      setMenuAnchor(null);
      return;
    }
    notificationDispatcher.readAllNotification();
    setMenuAnchor(null);
    customToast('success', 'Done');
  };

  const handleShowMenu = e => {
    e.preventDefault();
    setMenuAnchor(e.currentTarget);
  };
  const copyLinkClipboard = async text => {
    if (!navigator?.clipboard) {
      customToast('error', 'Clipboard not supported!');
      return;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      customToast('success', 'Copy successfull!');
    } catch (error) {
      customToast('error', 'Copy failed!');
    }
  };

  return (
    <IconNotify>
      <Box display="flex" alignItems="center" gridGap={24}>
        {location.pathname.includes(urlLabel.allScreenings) ? (
          <CustomTextField
            variant="outlined"
            value={userPasscode}
            style={{ pointerEvents: 'none', width: 120 }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => copyLinkClipboard(userPasscode)}
                  >
                    <CombinedIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        ) : null}

        <CustomBadge
          badgeContent={notificationData.countNotifyUnread}
          color="primary"
        >
          <NotificationsIcon
            color="primary"
            onClick={e => setAnchorEl(e.currentTarget)}
          />
        </CustomBadge>
      </Box>

      <Popover
        open={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={readAllNotification}>
          <ListItemIcon style={{ minWidth: 34 }}>
            <DoneIcon className="icon" />
          </ListItemIcon>
          Mark all as read
        </MenuItem>
      </Popover>

      <Popover
        id={Boolean(anchorEl) ? 'simple-popover' : null}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {renderNotify()}
        {renderEmptyNotify()}
      </Popover>
    </IconNotify>
  );
};

const NotificationEmpty = styled.div`
  color: #192637;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  padding: 10px;
`;
const IconNotify = styled.span`
  margin-right: 15px;
  .icon-notify {
    color: #cacfd3;
    cursor: pointer;
  }
`;
const LabelNotify = styled.div`
  display: flex;
  background: white;
  border-bottom: 1px solid #e9e9e9;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  .icon {
    color: #8f9ba9;
    cursor: pointer;
  }
  label {
    font-weight: 600;
  }
`;
const LineItem = styled.div`
  display: flex;
  border-bottom: 1px solid #e9e9e9;
  width: 20vw;
  background: ${props => (props.isRead ? 'white' : '#F0F0F0')};
  cursor: pointer;
  &:hover {
    background: #dfdfdf;
  }
  .icon {
    width: 20%;
    padding: 20px;
    color: #2fc2d6;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 32px;
    }
  }
  .content {
    padding: 10px 20px;
    width: 80%;
    font-size: 14px;
    line-height: 21px;
    .title {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      .text {
        color: #192637;
        font-weight: 600;
      }
      .date {
        color: #8f9ba9;
      }
    }
    .description {
      color: #8f9ba9;
    }
  }
`;

Notification.propTypes = {};

export default Notification;
