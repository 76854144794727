import { CloseCircle } from '@/assets/svg';
import { IconButton, InputAdornment } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React from 'react';

export const ClearComponent = ({ value, onClear }) => {
  return (
    <InputAdornment position="end">
      <IconButton
        style={{
          visibility: !isEmpty(value) ? 'visible' : 'hidden',
        }}
        onClick={onClear}
      >
        <CloseCircle
          height={20}
          width={20}
          style={{
            color: '#666666',
          }}
        />
      </IconButton>
    </InputAdornment>
  );
};
