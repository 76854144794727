/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  CustomFormHelperText,
  CustomTextField,
  getErrorMessage
} from './index';

const CustomToggleButtonGroup = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16
  },
  grouped: {
    border: 'none',
    '&:not(:first-child)': {
      borderLeft: '1px solid #E1E1E1',
      borderRadius: 8
    },
    '&:first-child': {
      borderRadius: 8
    }
  }
}))(ToggleButtonGroup);

const CustomToggleButton = withStyles(theme => ({
  root: {
    flex: 1,
    border: '1px solid #E1E1E1',
    color: '#3333333',
    textTransform: 'unset',
    fontSize: 16,
    height: 40,

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.light
    },
    '&.error': {
      border: `1px solid ${theme.palette.feedback.error}`
    }
  }
}))(ToggleButton);

const CustomTypography = withStyles({
  root: {
    color: '#333333',
    marginBottom: 8
  }
})(Typography);

export const CHECKED_TYPE = {
  default: 'None',
  aided: 'Aided',
  unAided: 'UnAided'
};

const ModalEditAcuity = ({
  checkedType,
  setCheckedType,
  formValue,
  setFormValue,
  errors,
  setErrors
}) => {
  useEffect(() => {
    if (checkedType === 0) {
      setFormValue(prevState => ({
        ...prevState,
        leftUnaided: '',
        rightUnaided: ''
      }));
    } else if (checkedType === 1) {
      setFormValue(prevState => ({
        ...prevState,
        leftAided: '',
        rightAided: ''
      }));
    }
  }, [checkedType]);

  const onChangeTestValue = (name, value = '') => {
    if (!value) setFormValue(prevState => ({ ...prevState, [name]: '' }));
    // Regex test number contain decimal. Maximum length number of decimal is 9
    const regexNumber = /^(\d+\.?\d{0,9}|\.\d{1,9})$/g;
    if (regexNumber.test(value)) {
      setFormValue(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const handleChangeToggleValue = (event, newValue) => {
    setCheckedType(newValue);
    setErrors({});
    setFormValue({
      leftAided: '',
      leftUnaided: '',
      rightAided: '',
      rightUnaided: ''
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <CustomTypography variant="subtitle2">
          Select one option
        </CustomTypography>
        <CustomToggleButtonGroup
          exclusive
          value={checkedType}
          onChange={handleChangeToggleValue}
        >
          <CustomToggleButton
            value={0}
            className={errors.checkedType ? 'error' : ''}
          >
            Aided
          </CustomToggleButton>
          <CustomToggleButton
            value={1}
            className={errors.checkedType ? 'error' : ''}
          >
            Unaided
          </CustomToggleButton>
        </CustomToggleButtonGroup>
        {errors.checkedType && (
          <CustomFormHelperText error>
            {getErrorMessage(errors.checkedType)}
          </CustomFormHelperText>
        )}
      </Grid>
      {!checkedType || checkedType === 0 ? (
        <>
          <Grid item xs={6}>
            <CustomTypography variant="subtitle2">Left Aided</CustomTypography>
            <CustomTextField
              required
              name="leftAided"
              placeholder="Enter value"
              value={formValue?.leftAided}
              variant="outlined"
              fullWidth
              disabled={checkedType !== 0}
              type="number"
              onChange={e => onChangeTestValue('leftAided', e.target.value)}
              error={!!errors.leftAided}
              helperText={getErrorMessage(errors.leftAided)}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTypography variant="subtitle2">Right Aided</CustomTypography>
            <CustomTextField
              required
              name="rightAided"
              placeholder="Enter value"
              value={formValue?.rightAided}
              variant="outlined"
              fullWidth
              disabled={checkedType !== 0}
              type="number"
              onChange={e => onChangeTestValue('rightAided', e.target.value)}
              error={!!errors.rightAided}
              helperText={getErrorMessage(errors.rightAided)}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6}>
            <CustomTypography variant="subtitle2">
              Left Unaided
            </CustomTypography>
            <CustomTextField
              required
              name="leftUnaided"
              placeholder="Enter value"
              value={formValue?.leftUnaided}
              variant="outlined"
              fullWidth
              disabled={checkedType !== 1}
              type="number"
              onChange={e => onChangeTestValue('leftUnaided', e.target.value)}
              error={!!errors.leftUnaided}
              helperText={getErrorMessage(errors.leftUnaided)}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTypography variant="subtitle2">
              Right Unaided
            </CustomTypography>
            <CustomTextField
              required
              name="rightUnaided"
              placeholder="Enter value"
              value={formValue?.rightUnaided}
              variant="outlined"
              fullWidth
              disabled={checkedType !== 1}
              type="number"
              onChange={e => onChangeTestValue('rightUnaided', e.target.value)}
              error={!!errors.rightUnaided}
              helperText={getErrorMessage(errors.rightUnaided)}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default ModalEditAcuity;
