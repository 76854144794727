import CustomModalWithForm from '@/new-components/CustomModalWithForm/CustomModalWithForm';
import CustomSelectContainer from '@/new-components/CustomSelect/CustomSelectContainer';
import { DialogActions, FormControl, Select } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import styled from 'styled-components';

const ModalPage = styled.div`
  width: 1100px;
  hr {
    background: #cacfd3;
    margin: 0;
  }
  .MuiDialogContent-root {
    padding: 0;
    &:first-child {
      padding-top: 0;
    }
  }
  .test-header {
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #192637;
    border-bottom: 1px solid rgb(202, 207, 211);
  }
  .test-body {
    padding: 0 20px 20px 20px;
  }
`;

export const ModalFooter = styled(DialogActions)`
  border-top: 1px solid rgb(202, 207, 211);
  padding: 10px 0px;
`;

export const WrapperFormControl = styled(FormControl)`
  width: 100%;
`;

export const WrapperSelect = styled(Select)`
  width: 100%;
`;

export const StyledCustomModalWithForm = styled(CustomModalWithForm)`
  .custom-select {
    margin-right: 0 !important;
    margin-left: 0;
    background: red !important;
  }
  .search-input {
    margin-left: 0;
  }
`;
export const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root.MuiInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
    height: 100%;
  }
  .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
    width: 100%;
    height: 40px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    margin-left: 0 !important;
  }
  > * {
    border-bottom: none;
  }
  margin-left: 0 !important;
`;
export default ModalPage;
