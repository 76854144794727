import { DeleteIcon, DuplicateIcon, EditIcon } from '@/assets/svg';
import { formatOldData } from '@/helpers';
import { isEmpty } from 'lodash';
import { SUB_SERVICES } from '../checkin-patient-information/constants';
import { formatListTest } from '../checkin-patient-information/util';
import Enum from '@/enum/Enum';

export const COLUMN_PACKAGES = [
  {
    label: 'Package name',
    stateValue: 'packageName',
    minWidth: 240
  },
  {
    label: 'Test code',
    stateValue: 'testCode'
  },
  {
    label: 'Type',
    stateValue: 'type'
  },
  {
    label: 'Price',
    stateValue: 'price'
  },
  {
    label: 'Tests',
    stateValue: 'tests'
  },
  {
    label: 'Profiles',
    stateValue: 'profiles'
  },
  {
    label: 'Measure.',
    stateValue: 'measurement'
  },
  {
    label: 'Proced.',
    stateValue: 'procedure'
  },
  {
    label: 'Radiol.',
    stateValue: 'radilogy'
  },
  {
    label: 'Vaccin.',
    stateValue: 'vaccinate'
  },
  {
    label: 'Reviews',
    stateValue: 'review'
  },
  {
    label: 'Action',
    minWidth: 100
  }
];

export const COLUMN_PROFILES = [
  {
    label: 'Profile name',
    stateValue: 'profileName',
    minWidth: 240
  },
  {
    label: 'Profile code',
    stateValue: 'profileCode'
  },
  {
    label: 'Price',
    stateValue: 'price'
  },
  {
    label: 'Tests',
    stateValue: 'tests'
  },
  {
    label: 'Profiles',
    stateValue: 'profiles'
  },
  {
    label: 'Action',
    width: 100
  }
];

export const COLUMN_TESTS = [
  {
    label: 'Test name',
    stateValue: 'profileName',
    minWidth: 240
  },
  {
    label: 'Test code',
    stateValue: 'profileCode'
  },
  {
    label: 'Station',
    stateValue: 'price'
  },
  {
    label: 'Mandatory',
    stateValue: 'tests'
  },
  {
    label: 'Action',
    width: 150
  }
];

export const PACKAGE_STEPS = [
  {
    name: 'General information'
  },
  {
    name: 'Available services'
  }
];

export const refactorListData = data => {
  const {
    lstTests = [],
    lstMeasurements = [],
    lstProcedures = [],
    lstRadiologies = [],
    lstVaccinations = [],
    lstReviews = [],
    lstProfiles = []
  } = data;
  let lstProfileIds = lstProfiles.map(i => i.id);

  let listTotalTests = [].concat(
    lstTests,
    lstMeasurements,
    lstProcedures,
    lstRadiologies,
    lstVaccinations,
    lstReviews
  );

  let tests = listTotalTests.map(i => ({
    testId: i.id,
    isRequired: i.isRequired
  }));

  let lstTestIds = listTotalTests.map(i => i.id);
  return {
    lstProfileIds,
    lstTestIds,
    tests
  };
};

export const refactorDuplicateListData = duplicateData => {
  let lstProfileIds = duplicateData.lstProfiles.map(i => i.id);
  const {
    lstTests = [],
    lstMeasurements = [],
    lstProcedures = [],
    lstRadiologies = [],
    lstVaccinations = [],
    lstReviews = []
  } = duplicateData;

  let listTotalTests = [].concat(
    lstTests,
    lstMeasurements,
    lstProcedures,

    lstRadiologies,
    lstVaccinations,
    lstReviews
  );

  let listTotalTestsItems = [];
  listTotalTests.map(
    i => (listTotalTestsItems = listTotalTestsItems.concat(i.items))
  );
  let lstTestIds = listTotalTestsItems?.map(i => i.id);
  let tests = listTotalTestsItems.map(i => ({
    testId: i.id,
    isRequired: i.isRequired
  }));

  return {
    lstProfileIds,
    lstTestIds,
    tests
  };
};

export const FORMAT_LIST_TESTS = {
  Tests: 'lstTests',
  Measurements: 'lstMeasurements',
  Procedures: 'lstProcedures',
  Radiologies: 'lstRadiologies',
  Reviews: 'lstReviews',
  Vaccinations: 'lstVaccinations'
};

export const revertListData = data => {
  for (const [key, value] of Object.entries(data)) {
    if (FORMAT_LIST_TESTS[key] && !isEmpty(value)) {
      return {
        [FORMAT_LIST_TESTS[key]]: value
      };
    }
  }
};

export const formatPackageData = data => {
  let { lstTests } = data;

  const filterLstTests = formatListTest(lstTests, SUB_SERVICES);

  return {
    ...data,
    description: formatOldData(data.description),
    shortDesc: formatOldData(data.shortDesc),
    ...filterLstTests
  };
};

export const renderPackageActions = ({ onEdit, onDelete, onClone }) => {
  const LIST_ACTIONS = [
    {
      key: 'edit',
      icon: EditIcon,
      label: 'Edit package',
      onClick: onEdit
    },
    {
      key: 'clone',
      icon: DuplicateIcon,
      label: 'Duplicate package',
      onClick: onClone,
      styles: { padding: 2 }
    },
    {
      key: 'delete',
      icon: DeleteIcon,
      label: 'Delete package',
      onClick: onDelete,
      color: '#DB3D49'
    }
  ];
  return LIST_ACTIONS;
};

export const DEFAULT_COMPULSORY_TAGS = ['General', 'Male', 'Female'];

export const HS_SERVICE_TYPES = Enum({
  MMS: 'MMS',
  HBS: 'HBS',
  EHS: 'EHS',
  CHS: 'CHS'
});
