import React, { useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { ENTER_KEY } from '../../../common';
import styled from 'styled-components';
import {
  InputValidationError,
  EmailIcon,
  LockIcon,
  EyeHide,
  EyeShow
} from '../../../../assets/svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import LoadingButton from '../../../../components/LoadingButton';
import { getStorage } from '../../../../helpers';

const LoginInfo = ({
  apiErr,
  showError,
  setShowError,
  email,
  password,
  showPassword,
  setEmail,
  setShowPassword,
  setPassword,
  handleLogin,
  toForgotPassword,
  toRegister,
  handleGoogleLogin,
  GoogleLogo,
  showLoginLoading,
  errors
}) => {
  const [isChecked, setIsChecked] = useState(getStorage('isChecked') || false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 590,
        width: 380,
        borderRadius: 10,
        backgroundColor: 'white',
        padding: '0px 20px'
      }}
    >
      <Typography
        style={{
          fontSize: 24,
          letterSpacing: 0,
          fontWeight: 600,
          marginTop: 48
        }}
      >
        Log into your account
      </Typography>
      <div style={{ display: 'flex', marginTop: 20 }} hidden={!showError}>
        <InputValidationError style={{ marginRight: 8 }} />
        <CustomTypography style={{ color: '#EA6B75' }}>
          {apiErr || `Wrong username and password`}
        </CustomTypography>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: 40,
          alignItems: 'center',
          height: 40
        }}
      >
        <EmailIcon style={{ marginRight: 13, marginTop: 40 }} />
        <TextFieldStyle
          name="Email"
          margin="normal"
          label="Email"
          autoComplete="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: 30,
          alignItems: 'center',
          height: 40,
          marginBottom: errors.password ? 60 : 0
        }}
      >
        <LockIcon style={{ marginRight: 13, marginTop: 40 }} />
        <TextFieldStyle
          type={showPassword ? '' : 'password'}
          name="Password"
          margin="normal"
          label="Password"
          autoComplete="password"
          value={password}
          error={errors.password}
          helperText={errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <>
                  <EyeHide
                    style={{ cursor: 'pointer' }}
                    hidden={showPassword}
                    onClick={() => setShowPassword(true)}
                  />
                  <EyeShow
                    style={{ cursor: 'pointer' }}
                    hidden={!showPassword}
                    onClick={() => setShowPassword(false)}
                  />
                </>
              </InputAdornment>
            )
          }}
          onChange={e => setPassword(e.target.value)}
          onKeyDown={e => e.keyCode === ENTER_KEY && handleLogin()}
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: 30,
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 60
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            style={{ height: 21 }}
          />
          <CustomTypography>Remember me</CustomTypography>
        </div>
        <CustomTypography
          style={{ fontWeight: 600, color: '#AD5E99', cursor: 'pointer' }}
          onClick={toForgotPassword}
        >
          Forgot Password?
        </CustomTypography>
      </div>
      <LoadingButton
        onClick={() => handleLogin(isChecked)}
        showLoader={showLoginLoading}
      >
        Login
      </LoadingButton>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <CustomTypography style={{ color: '#8F9BA9' }}>
          Don't have an account yet?
        </CustomTypography>
        <CustomTypography
          style={{ marginLeft: 6, color: '#AD5E99', cursor: 'pointer' }}
          onClick={toRegister}
        >
          Sign up
        </CustomTypography>
      </div>
      <div style={{ height: 21, marginTop: 20, position: 'relative' }}>
        <div style={{ height: '50%', borderBottom: '1px #E9E9E9 solid' }}></div>
        <Typography
          style={{
            color: '#8F9BA9',
            width: 40,
            textAlign: 'center',
            position: 'absolute',
            top: 0,
            left: '45%',
            zIndex: 10,
            backgroundColor: 'white',
            fontSize: 12,
            letterSpacing: 0
          }}
        >
          OR
        </Typography>
      </div>
      <div
        style={{
          width: 346,
          height: 41,
          border: '1px #CACFD3 solid',
          borderRadius: 20,
          marginTop: 20,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          padding: '10px 40px'
        }}
        onClick={handleGoogleLogin}
      >
        <img src={GoogleLogo} alt="google-logo" height={20} width={20} />
        <CustomTypography
          style={{
            fontWeight: 600,
            marginLeft: 10,
            width: 235,
            textAlign: 'center'
          }}
        >
          Continue with Google
        </CustomTypography>
      </div>
    </div>
  );
};

const TextFieldStyle = styled(TextField)`
  height: 40px;
  width: 308px;
`;

const CustomTypography = styled(Typography)`
  letter-spacing: 0 !important;
  font-size: 14px !important;
`;

export default LoginInfo;
