import React, { useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { CONSENT_ACTION } from '../../constants';
import MultipleAutocomplete from '@/module/common/component/MultipleAutocomplete';
import ConsentModal from './ConsentModal';
import ConsentTable from './ConsentTable';

const ConsentFormStep = ({ formValue, setFormValue, errors }) => {
  const { consents: listConsents = [] } = useSelector(
    state => state.globalState
  );

  const [consentModal, setConsentModal] = useState(false);
  const [itemSelected, setItemSelected] = useState({});

  const onChangeConsent = (action, position) => (e, newValue) => {
    const { consents = [] } = formValue;
    let newConsents = [...consents];

    switch (action) {
      case CONSENT_ACTION.Add:
        newValue && newConsents.push(newValue);
        break;
      case CONSENT_ACTION.Delete:
        newConsents.splice(position, 1);
        break;
      default:
        break;
    }
    setFormValue({ ...formValue, consents: newConsents });
  };

  return (
    <Box px={2.25} py={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MultipleAutocomplete
            placeholder="Search consent form"
            options={listConsents}
            onChange={onChangeConsent(CONSENT_ACTION.Add)}
            optionLabel="title"
            value={formValue.consents}
            multiple={false}
            disableCloseOnSelect={true}
            height="40px"
          />
        </Grid>
        <Grid item xs={12}>
          <ConsentTable
            onViewDetail={() => setConsentModal(true)}
            onDeleteConsent={onChangeConsent}
            data={formValue.consents}
            setItemSelected={setItemSelected}
            itemSelected={itemSelected}
          />
        </Grid>
      </Grid>
      {consentModal && (
        <ConsentModal
          open={consentModal}
          onClose={() => setConsentModal(false)}
          itemSelected={itemSelected.item}
        />
      )}
    </Box>
  );
};

export default ConsentFormStep;
