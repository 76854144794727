import { synthesize } from 'redux-dispatcher';
import { createDownloadableLink } from '@/helpers';

const mapDispatchToAC = {
  getTestsSuccess: (data, key) => ({ data, key }),
  getTests: (search, paging, category) => async ({ Api, getState }) => {
    let pagingData = paging || getState().service[category].paging;
    let { result, status } = await Api.get(`Tests`, {
      pageIndex: pagingData.pageIndex,
      pageSize: pagingData.pageSize,
      search,
      category
    });
    status === 200 && serviceDispatcher.getTestsSuccess(result, category);
  },
  getAllServices: (
    category,
    isAssigned = true,
    callback,
    projectId = ''
  ) => async ({ Api }) => {
    if (!category) return;
    let { result, status } = await Api.get(`Tests`, {
      category,
      isAssigned,
      projectId
    });
    status === 200 && callback && callback(result.list);
  },
  getAllServicesWithNoneStation: (category, callback) => async ({ Api }) => {
    if (!category) return;
    let { result, status } = await Api.get(`Tests`, {
      category
    });
    status === 200 && callback && callback(result.list);
  },
  getServiceByID: (id, callback) => async ({ Api }) => {
    let { result, status } = await Api.get(`Tests/${id}`);
    status === 200 && callback && callback(result);
  },
  setState: (state, value) => ({ state, value }),
  createTest: (data, category, callbackSuccess) => async ({
    Api,
    customToast
  }) => {
    const { status } = await Api.post('Tests', {
      ...data,
      category
    });
    serviceDispatcher.setState('loading', false);
    if (status === 200) {
      callbackSuccess && callbackSuccess();
    }
  },

  editTest: (payload, category, callbackSuccess) => async ({ Api }) => {
    const { id } = payload;
    const { status } = await Api.put(`Tests/${id}`, {
      ...payload,
      category
    });
    serviceDispatcher.setState('loading', false);
    if (status === 200) {
      callbackSuccess && callbackSuccess();
    }
  },
  deleteTest: (id, callbackSuccess) => async ({ Api, customToast }) => {
    const { status } = await Api.delete(`Tests/${id}`);
    if (status === 200) {
      customToast('success', 'Deleted successful');
      callbackSuccess && callbackSuccess();
    }
  },
  getExportExcel: (category, search) => async ({ Api }) => {
    let { result, status } = await Api.post(`Tests/csv-report`, {
      category,
      search
    });
    status === 200 && createDownloadableLink(result, `${category}-report`);
  }
};

const serviceDispatcher = synthesize('service', mapDispatchToAC);
export default serviceDispatcher;
