import React from 'react';
import { ButtonEnhance } from '../../module/common/componentUI/commonStyleComponents';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingButton = ({
  showLoader = false,
  children,
  onClick,
  disabled,
  ...props
}) => {
  return (
    <ButtonEnhance
      {...props}
      disabled={showLoader || disabled}
      onClick={showLoader || disabled ? null : onClick}
    >
      {showLoader && (
        <LoadingOutlined
          style={{ fontSize: 16, color: '#fff', marginRight: 5 }}
        />
      )}
      {children}
    </ButtonEnhance>
  );
};

export default LoadingButton;
