/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import customToast from '@/new-components/CustomNotification';

import timeslotTemplateDispatcher from '@/module/time-slot-template/action';

import {
  DAYS_OF_WEEK,
  DAYS_OF_WEEK_TEMPLATE
} from '@/module/booking-management/constants';

import { INTERVAL, TIME_SELECT } from '../../constants';
import {
  CheckboxContainer,
  CustomSearchInputModal,
  InputLabel,
  OtherDay,
  OtherDayContainer
} from '../../createAndEditLocation.styled';
import {
  dayOfWeeks,
  findMaxtimeImportArray,
  formatTimeToSendServer,
  getTimeSlots
} from '../../utils';
import { StyledCustomModalWithForm } from './styled';
import CustomSelectContainer from '@/new-components/CustomSelectOld/CustomSelectContainer';
import { ArrowDown, CreateNewIcon } from '@/assets/svg';
import { Autocomplete } from '@material-ui/lab';
import CustomCheckbox from '@/new-components/CustomCheckbox';
import { cloneDeep, findIndex, isBoolean, isEmpty, lowerCase } from 'lodash';
import validateData from '@/helpers/validationHelpers/validationSchema';

const defaultFilter = {
  paging: {
    pageIndex: 0,
    pageSize: 20
  },
  filterOptions: {
    interval: [],
    startTime: moment().startOf('date'),
    endTime: moment().endOf('date')
  }
};

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 40,
      borderRadius: 8,
      fontSize: 16
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: 0,
      paddingLeft: 4,
      marginLeft: 0
    }
  }
})(TextField);

const ModalEditTimeSlot = ({
  onClose,
  saveDataImport,
  maxTimeImport,
  isModalOpen,
  dayIndex,
  dataTimeSlots,
  currentSubTab
}) => {
  const defaultFormValue = {
    startTime: '',
    endTime: '',
    interval: null,
    quotaBase: null,
    name: ''
  };
  const defaultAddTemplates = {
    startTime: '',
    endTime: '',
    interval: null,
    quotaBase: null,
    name: '',
    timeSlots: []
  };
  const [formValue, setFormValue] = useState(defaultFormValue);
  const [timeSlots, setTimeSlots] = useState([]);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [optionSelected, setOptionSelected] = useState('');
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [errors, setErrors] = useState({});
  const [addTemplateParams, setAddTemplateParams] = useState(
    defaultAddTemplates
  );
  const [isApplyOther, setIsApplyOther] = useState(false);
  const [listDaysOfWeek, setListDaysOfWeek] = useState(DAYS_OF_WEEK_TEMPLATE);

  const timeSlotsSelect = getTimeSlots(7, 25);

  const handleChangeAddTemplateParams = (key, value) =>
    setAddTemplateParams({
      ...addTemplateParams,
      [key]: value
    });
  useEffect(() => {
    if (isModalOpen) {
      let newList = cloneDeep(listDaysOfWeek);
      newList[currentSubTab].isChecked = true;
      setListDaysOfWeek(newList);
      setDaysOfWeek([DAYS_OF_WEEK_TEMPLATE[currentSubTab]]);
    }
  }, [isModalOpen]);
  const checkIsSelectAll = () => {
    return DAYS_OF_WEEK_TEMPLATE.length === daysOfWeek.length;
  };

  const onChangeDayOfWeeks = value => {
    let results = cloneDeep(daysOfWeek);
    let newList = cloneDeep(listDaysOfWeek);
    const indexResults = findIndex(results, it => it.value === value.value);

    if (indexResults === -1) {
      results = results.concat(value);
    } else {
      results.splice(indexResults, 1);
    }
    newList = newList.map(item =>
      value.key === item.key ? { ...item, isChecked: !item.isChecked } : item
    );
    setFormValue({ ...formValue, daysOfWeek: results });
    setDaysOfWeek(results);
    setListDaysOfWeek(newList);
  };

  useEffect(() => {
    setLoadingAutoComplete(true);
    timeslotTemplateDispatcher.getData(
      search,
      defaultFilter.paging,
      defaultFilter.filterOptions,
      data => {
        setOptions([{ name: 'Create new' }, ...data]);
        setLoadingAutoComplete(false);
      }
    );
  }, [search]);

  useEffect(() => {
    if (optionSelected && optionSelected?.id) {
      setLoadingTable(true);

      timeslotTemplateDispatcher.getTemplateForLocation(
        optionSelected.id,
        res => {
          const {
            timeSlots,
            startTime,
            endTime,
            interval,
            name,
            quotaBase
          } = res;
          setTimeSlots(timeSlots);
          setFormValue({
            startTime,
            endTime,
            quotaBase,
            name,
            interval
          });
          setLoadingTable(false);
        }
      );
    } else {
      // Clear data addTemplateParams
      // Clear data fromValue
      setFormValue(defaultFormValue);
      setFormValue(defaultFormValue);
    }

    if (optionSelected?.name === 'Create new') {
      setFormValue(defaultFormValue);
    } else setAddTemplateParams(defaultAddTemplates);
  }, [optionSelected]);

  const importData = timeSlotsInput => {
    const dataImport = timeSlotsInput || timeSlots;
    if (dataImport.length === 0) return;
    const startTimeCompare = moment(dataImport[0].startTime).unix();
    const selectedDay = daysOfWeek.map(i => DAYS_OF_WEEK[i.value]);
    let maxtimeImportArrr = findMaxtimeImportArray(dataTimeSlots, selectedDay);
    if (maxtimeImportArrr.some(time => time > startTimeCompare)) {
      customToast(
        'error',
        'List time slots import are not valid. Please check data time slots are imported.'
      );
      return;
    }

    saveDataImport({
      timeSlots: dataImport,
      selectedDay
    });
    onClose();
  };
  const isSelectAll = checkIsSelectAll();
  const FOOTER_BUTTONS = [
    {
      name: 'Submit',
      variant: 'contained',
      disabled: true,
      action: () => handleSubmit()
    }
  ];

  const handleSubmit = async () => {
    if (optionSelected?.name === 'Create new') {
      const timeSlotsGenerated = generateTimeSlotsArray();
      const params = {
        startTime: formatTimeToSendServer(addTemplateParams.startTime),
        endTime: formatTimeToSendServer(addTemplateParams.endTime),
        quotaBase: addTemplateParams.quotaBase,
        interval: addTemplateParams.interval,
        name: addTemplateParams.name,
        timeSlots: timeSlotsGenerated
      };
      timeslotTemplateDispatcher.addTemplate(params, () => {
        importData(
          timeSlotsGenerated?.map(item => ({
            ...item,
            startTime: moment(item.startTime, 'h:mm A'),
            endTime: moment(item.endTime, 'h:mm A')
          }))
        );
        setOpen(false);
      });
    } else {
      importData();
      setOpen(false);
    }
  };

  const generateTimeSlotsArray = () => {
    const { startTime, endTime, interval, quotaBase } = addTemplateParams;
    const timeArray = [];
    const currentTime = moment(startTime, 'h:mm A');

    while (currentTime.isSameOrBefore(moment(endTime, 'h:mm A'))) {
      const timeObject = {
        startTime: formatTimeToSendServer(currentTime),
        endTime: formatTimeToSendServer(currentTime.add(interval, 'minutes')),
        isActive: true,
        quotaBase,
        interval
      };
      timeArray.push(timeObject);
    }

    return timeArray;
  };

  const handleApplyOtherDays = e => {
    let isChecked = e.target.checked;
    let newDaysOfWeek = cloneDeep(DAYS_OF_WEEK_TEMPLATE);
    let newList = cloneDeep(listDaysOfWeek);
    if (isChecked) {
      // Remove 'Sunday'
      newDaysOfWeek.pop();
      newList = newList.map(item =>
        item.value !== 'Sunday' ? { ...item, isChecked } : item
      );
    } else {
      newDaysOfWeek = [DAYS_OF_WEEK_TEMPLATE[currentSubTab]];
      newList = newList.map(item => ({ ...item, isChecked }));
      newList[currentSubTab].isChecked = true;
    }
    setIsApplyOther(isChecked);
    setListDaysOfWeek(newList);
    setDaysOfWeek(newDaysOfWeek);
  };

  const isEmptyData = useMemo(() => {
    if (optionSelected?.name === 'Create new') {
      const filterKeys = Object.keys(addTemplateParams);
      return !filterKeys.every(key =>
        !isBoolean(addTemplateParams[key])
          ? addTemplateParams[key]
          : !isEmpty(addTemplateParams[key])
      );
    } else {
      const filterKeys = Object.keys(formValue);
      return !filterKeys.every(key =>
        !isBoolean(formValue[key]) ? formValue[key] : !isEmpty(formValue[key])
      );
    }
  }, [optionSelected, addTemplateParams, formValue]);

  return (
    <StyledCustomModalWithForm
      open={isModalOpen}
      title={'Use time slot template'}
      onClose={onClose}
      footerButtons={FOOTER_BUTTONS}
      width={'480px'}
      disabled={isEmptyData}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel>Select a time slot template</InputLabel>
          <Autocomplete
            id="autocompleteTemplate"
            style={{ width: '100%', marginLeft: 0 }}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            onInputChange={(_, value) => setSearch(value)}
            onChange={(e, value) => setOptionSelected(value)}
            getOptionLabel={option => option.name}
            options={options}
            loading={loadingAutoComplete}
            popupIcon={<ArrowDown />}
            renderInput={params => (
              <CustomTextField
                variant="outlined"
                placeholder="Select a template"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  endAdornment: (
                    <React.Fragment>
                      {loadingAutoComplete ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
            renderOption={option => (
              <Box display="flex" alignItems="center" gridGap={4}>
                {option.name === 'Create new' && (
                  <CreateNewIcon style={{ color: '#AD5E99' }} />
                )}

                <Typography variant="body2">{option.name}</Typography>
              </Box>
            )}
          />
        </Grid>

        {optionSelected?.name === 'Create new' ? (
          <>
            <Grid item xs={12}>
              <InputLabel style={{ marginLeft: 0 }}>Template name</InputLabel>
              <CustomSearchInputModal
                className="search-input"
                error={!!errors.name}
                helperText={errors.name}
                placeholder="Enter name"
                value={addTemplateParams.name || ''}
                fullWidth
                onChange={e =>
                  handleChangeAddTemplateParams('name', e.target.value)
                }
                variant="outlined"
                allowClear
                style={{ marginLeft: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Time interval</InputLabel>
              <CustomSelectContainer
                fontSize="16px"
                data={TIME_SELECT}
                isMutiple={false}
                renderValue={
                  <>
                    {addTemplateParams.interval
                      ? addTemplateParams.interval + ' min'
                      : 'Select time interval'}
                  </>
                }
                filterValue={addTemplateParams.interval || ''}
                onChange={e =>
                  handleChangeAddTemplateParams('interval', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Start time</InputLabel>
              <CustomSelectContainer
                fontSize="16px"
                data={timeSlotsSelect}
                isMutiple={false}
                renderValue={
                  <>
                    {addTemplateParams.startTime
                      ? moment(addTemplateParams.startTime)?.format('h:mm A')
                      : 'Select start time'}
                  </>
                }
                filterValue={[
                  moment(addTemplateParams.startTime || '').format('h:mm A')
                ]}
                onChange={e =>
                  handleChangeAddTemplateParams(
                    'startTime',
                    moment(e.target.value, 'h:mm A')
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>End time</InputLabel>
              <CustomSelectContainer
                fontSize="16px"
                data={timeSlotsSelect}
                isMutiple={false}
                renderValue={
                  <>
                    {addTemplateParams.endTime
                      ? moment(addTemplateParams.endTime).format('h:mm A')
                      : 'Select end time'}
                  </>
                }
                filterValue={[
                  moment(addTemplateParams.endTime || '').format('h:mm A')
                ]}
                onChange={e =>
                  handleChangeAddTemplateParams(
                    'endTime',
                    moment(e.target.value, 'h:mm A')
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Quota per slot</InputLabel>
              <CustomSearchInputModal
                className="search-input"
                error={!!errors.quotaBase}
                helperText={errors.quotaBase}
                placeholder="Enter number"
                value={addTemplateParams.quotaBase || ''}
                fullWidth
                onChange={e =>
                  handleChangeAddTemplateParams('quotaBase', +e.target.value)
                }
                variant="outlined"
                allowClear
                style={{ marginLeft: 0 }}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <InputLabel style={{ marginLeft: 0 }}>Template name</InputLabel>
              <CustomSearchInputModal
                className="search-input"
                error={!!errors.name}
                helperText={errors.name}
                placeholder="Enter name"
                value={formValue.name || ''}
                fullWidth
                disabled
                variant="outlined"
                allowClear
                style={{ marginLeft: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Start time</InputLabel>
              <CustomSelectContainer
                data={timeSlotsSelect}
                height="40px"
                fontSize="16px"
                isMutiple={false}
                disabled
                hideClear
                renderValue={
                  <>
                    {formValue.startTime
                      ? moment(formValue.startTime).format('h:mm A')
                      : 'Select start time'}
                  </>
                }
                filterValue={formValue.startTime || ''}
                error={errors.startTime}
                onChange={onChangeDayOfWeeks}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>End time</InputLabel>
              <CustomSelectContainer
                data={timeSlotsSelect}
                height="40px"
                fontSize="16px"
                isMutiple={false}
                disabled
                hideClear
                renderValue={
                  <>
                    {formValue.endTime
                      ? moment(formValue.endTime).format('h:mm A')
                      : 'Select end time'}
                  </>
                }
                filterValue={formValue.endTime || ''}
                error={errors.endTime}
                onChange={onChangeDayOfWeeks}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Time interval</InputLabel>
              <CustomSelectContainer
                data={INTERVAL}
                height="40px"
                fontSize="16px"
                isMutiple={false}
                disabled
                hideClear
                renderValue={
                  <>
                    {formValue.interval
                      ? formValue.interval + ' min'
                      : 'Select time interval'}
                  </>
                }
                filterValue={'' + formValue.interval || ''}
                error={errors.daysOfWeek}
                onChange={onChangeDayOfWeeks}
                useSelectAll
                labelId="dayOfWeek"
                checkedAll={isSelectAll}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Quota per slot</InputLabel>
              <CustomSearchInputModal
                className="search-input"
                error={!!errors.quotaBase}
                helperText={errors.quotaBase}
                placeholder="Enter number"
                value={formValue.quotaBase || ''}
                fullWidth
                disabled
                variant="outlined"
                allowClear
                style={{ marginLeft: 0 }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <CheckboxContainer>
            <CustomCheckbox
              checked={isApplyOther}
              onChange={handleApplyOtherDays}
            />
            Apply to other days of the week
          </CheckboxContainer>
          <OtherDayContainer>
            {listDaysOfWeek?.map((item, index) => {
              const indexResults = dayOfWeeks.find(
                it => it.dayOfWeek === dayIndex
              );
              return (
                <OtherDay
                  key={item.key}
                  className={item.isChecked && 'active'}
                  onClick={e => {
                    e && e.preventDefault();
                    onChangeDayOfWeeks(item);
                  }}
                  disabled={lowerCase(item.value) === indexResults.label}
                >
                  {item.key}
                </OtherDay>
              );
            })}
          </OtherDayContainer>
        </Grid>
      </Grid>
    </StyledCustomModalWithForm>
  );
};

export default ModalEditTimeSlot;
