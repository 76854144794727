import React, { useState } from 'react';
import { CloseIcon } from '@/assets/svg';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import TabsUI from '@/module/common/componentUI/TabsUI';
import ModalContent from './ModalContent';
import { generateLifestyle } from '../../utils';
import CustomSurveyContent from './CustomSurveyContent';

const titleStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    border: '1px solid #f1f1f1'
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: '#333'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 14,
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    width: '100%',
    backgroundColor: '#f1f1f1',
    height: 'calc(100vh - 112px)',
    overflowY: 'scroll'
  },
  stepsControlWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  }
}));

const CustomDrawerTitle = withStyles(titleStyles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Box className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

const ModalShowViewAll = ({
  dataInfor,
  onClose,
  lifestyleSurvey,
  customSurvey,
  consetForms
}) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);

  const TABS = [
    {
      label: 'Consent form',
      body: (
        <ModalContent
          isConsentForm
          dataInfor={dataInfor}
          data={consetForms}
          noDataHelperText="No consent form"
          noDataHelperSubText="There’s no consent form to view."
        />
      )
    },
    // {
    //   label: 'Custom survey',
    //   body: (
    //     <CustomSurveyContent
    //       dataInfor={dataInfor}
    //       data={customSurvey}
    //       noDataHelperText="No custom survey"
    //     />
    //   )
    // },
    {
      label: 'Lifestyle survey',
      body: (
        <ModalContent
          isLifeStyle
          dataInfor={dataInfor}
          data={generateLifestyle(lifestyleSurvey)}
          noDataHelperText="No lifestyle survey"
        />
      )
    }
  ];

  return (
    <Paper square elevation={0}>
      <CustomDrawerTitle onClose={onClose}>
        View all documents
      </CustomDrawerTitle>

      <TabsUI
        listTab={TABS}
        value={currentTab}
        onChange={(e, val) => {
          setCurrentTab(val);
        }}
        renderLabel="label"
        renderKey="label"
      />

      <main className={classes.mainWrapper}>{TABS[currentTab].body}</main>
    </Paper>
  );
};

export default ModalShowViewAll;
